import { Flex, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { FeatureCard } from './FeatureCard'
import { useSelector } from 'src/hooks'
import { IconType } from 'src/design_system/atoms/icons/types'

type PricingGridType = {
  title: string
  subtitle: string
  description: string
  disclaimer: string
  order: number
  rows: {
    title: string
    items: {
      icon: string
      title: string
      itemDescription: string
    }[]
  }[]
}

type FeatureCardGridProps = {
  gridData: PricingGridType
}

export const FeatureCardGrid: React.FC<FeatureCardGridProps> = ({
  gridData,
}) => {
  const isTablet = useSelector(state => state.window.width <= 1050)
  const isMobile = useSelector(state => state.window.width <= 626)

  const { title, description, rows } = gridData
  return (
    <Flex flexDirection="column" inset="x9">
      <Text
        type="title"
        content={title}
        alignment="center"
        style={{ fontSize: isTablet ? 28 : 32 }}
      />
      <Spacer space={isTablet ? 'x1' : 'x2'} />
      <Text
        alignment="center"
        type="body"
        content={description}
        style={{ fontSize: isTablet ? 14 : 16 }}
        inset={{ horizontal: isMobile ? 'x2' : '' }}
      />
      {rows.map((row, index) => (
        <Flex
          key={index}
          flexDirection="column"
          inset="x5"
          flexWrap="wrap"
          rowGap="x2"
        >
          <Text content={row.title} type="largeBody" weight="bold" />
          <Flex columnGap={'x2_5'} rowGap="x2_5" flexWrap="wrap">
            {row.items &&
              row.items.map((item, index) => {
                return (
                  <FeatureCard
                    key={index}
                    minWidth="350px"
                    icon={(item.icon.toLowerCase() as IconType) ?? '10_card'}
                    title={item.title}
                    description={item.itemDescription}
                    footnote="Subject to fair use policy"
                  />
                )
              })}
          </Flex>
        </Flex>
      ))}
    </Flex>
  )
}
