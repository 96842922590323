// eslint-disable-next-line no-restricted-imports
import { useCallback } from 'react'
import { AnyMemoized, Function, Memoized } from './dependencies'
import useMemoizedDeps from './useMemoizedDeps'

/**
 * `useCallback` will return a memoized version of the callback that only changes if one of the `inputs`
 * has changed.
 */
const useCallbackDeepCompare = <T extends Function>(
  callback: T,
  deps: AnyMemoized[],
): Memoized<T> =>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useCallback(callback, useMemoizedDeps(deps)) as Memoized<T>

export default useCallbackDeepCompare
