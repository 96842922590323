import {
  Button,
  Dialog,
  Flex,
  Image,
  Text,
} from '@sendoutcards/quantum-design-ui'
import React from 'react'

export type RemoveOrderItemDialogProps = {
  title: string
  subtitle: string
  image?: { url: string; isHorizontal?: boolean }
  onConfirm: () => void
  onCancel?: () => void
  setIsOpen: (isOpen: boolean) => void
  isOpen: boolean
  zIndex?: number
  itemType?: 'card' | 'gift'
}

export const RemoveOrderItemDialog: React.FC<RemoveOrderItemDialogProps> = ({
  title,
  subtitle,
  image,
  onConfirm,
  onCancel,
  setIsOpen,
  isOpen,
  zIndex,
  itemType,
}) => {
  const width = itemType === 'gift' ? 140 : image?.isHorizontal ? 140 : 102
  const height = itemType === 'gift' ? 140 : image?.isHorizontal ? 102 : 140

  return (
    <Dialog zIndex={zIndex} onClose={() => setIsOpen(false)} isOpen={isOpen}>
      {image?.url && (
        <Flex
          justifyContent="center"
          alignContent="center"
          outset={{ vertical: 'x_75' }}
          width="100%"
        >
          <Image
            isActive={true}
            height={`${height}px`}
            width={`${width}px`}
            image={{ url: image.url }}
          />
        </Flex>
      )}
      <Flex flexDirection="column">
        <Text
          outset={{ top: 'x1' }}
          alignment="center"
          type="largeBody"
          weight="bold"
          content={title}
        />
        <Text
          outset={{ top: 'x1' }}
          alignment="center"
          type="body"
          content={subtitle}
        />

        <Flex outset={{ top: 'x2' }} width="100%">
          <Flex width="100%" outset={{ right: 'x_75' }}>
            <Button
              fullWidth={true}
              outlined={true}
              title="Yes"
              onClick={onConfirm}
            />
          </Flex>
          <Flex width="100%" outset={{ left: 'x_75' }}>
            <Button
              fullWidth={true}
              outlined={true}
              title="No"
              onClick={() => {
                setIsOpen(false)
                onCancel?.()
              }}
            />
          </Flex>
        </Flex>
      </Flex>
    </Dialog>
  )
}
