import React from 'react'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

import COLUMNS, { Field } from '../columns'
import { FieldMapping, getMappedField } from '../helpers'

const failedStyle: React.CSSProperties = {
  color: 'rgb(255, 86, 137)',
  height: 56,
  top: -4,
  position: 'relative',
  verticalAlign: 'middle',
  width: 150,
}

const okStyle: React.CSSProperties = {
  color: '#000000',
  height: 56,
  top: -4,
  position: 'relative',
  verticalAlign: 'middle',
  width: 150,
}

interface Props {
  handleChange: (field: string, columnApiName: Field) => void
  userHeaders: string[]
  mapping: FieldMapping
}

const HeaderMapper: React.FC<Props> = props => {
  const { userHeaders, handleChange, mapping } = props

  return (
    <div>
      {COLUMNS.map((header, index) => {
        const mappedField = getMappedField(mapping, header.apiName)

        return (
          <div style={{ display: 'flex' }} key={index}>
            <div
              style={
                header.mappingRequired && !mappedField ? failedStyle : okStyle
              }
            >
              <span style={{ position: 'relative', top: 15 }}>
                {header.display}
              </span>
            </div>

            <TextField
              select={true}
              value={mappedField ?? ''}
              onChange={e => handleChange(e.target.value, header.apiName)}
              InputLabelProps={{
                style: { height: 53, lineHeight: 3 },
              }}
              name="header"
              InputProps={{
                style: {
                  bottom: 15,
                },
              }}
              SelectProps={{
                MenuProps: {
                  style: {
                    maxHeight: 200,
                  },
                },
              }}
            >
              {['', ...userHeaders].map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </div>
        )
      })}
    </div>
  )
}

export default HeaderMapper
