import { createQueryKeys, mergeQueryKeys } from '@lukemorales/query-key-factory'
import { getPlanMove } from './fetching'

const planMove = createQueryKeys('plan_move', {
  detail: (planIdFrom: number, planIdTo: number) => ({
    queryFn: () => getPlanMove(planIdFrom, planIdTo),
    queryKey: [planIdFrom, planIdTo],
  }),
})

export default mergeQueryKeys(planMove)
