import { Label } from '../graphql/generated/graphql'
import { EmailVerificationType } from 'src/email_verification/DuplicateOrInvalidEmailBlocker/DuplicateOrInvalidEmailBlocker'

const getEmailVerificationLabel = (
  labels: Label[],
): EmailVerificationType | undefined => {
  if (labels.includes(Label.HasDuplicateEmail)) {
    return Label.HasDuplicateEmail
  } else if (labels.includes(Label.HasInvalidEmail)) {
    return Label.HasInvalidEmail
  } else if (labels.includes(Label.HasDuplicateEmailClaimed)) {
    return Label.HasDuplicateEmailClaimed
  } else {
    return undefined
  }
}

export default getEmailVerificationLabel
