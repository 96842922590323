import React from 'react'
import { Anchor, Div, Flex, Sheet } from '@sendoutcards/quantum-design-ui'
import { usePersistedUserData, useState } from 'src/hooks'
import PhoneNumberVerification from 'src/onboarding/components/PhoneNumberVerification'
import FeaturedCardsGrid from 'src/catalog/components/FeaturedCardsGrid/FeaturedCardsGrid'
import { Portal } from 'src/portal/portal'
import HoverTooltip from 'src/chrome/HoverTooltip/HoverTooltip'

const styles = {
  wrapper: {
    marginRight: '0.5rem',
    '@media (max-width: 715px)': {
      gridArea: '1/1/1/4',
      justifySelf: 'center',
      marginRight: '0',
      marginBottom: '1rem',
    },
  },
  iconWrapper: {
    position: 'relative' as const,
    width: '19px',
    height: '19px',
    textAlign: 'center' as const,
    marginRight: '5px',
  },
  iconCircle: {
    position: 'absolute' as const,
    top: '5px',
    width: '19px',
    height: '19px',
    border: 'solid 1.5px #616161',
    borderRadius: '50%',
  },
}

interface PaymentPhoneNumberVerificationProps {
  onVerifySuccess?: () => void
}

const PaymentPhoneNumberVerification: React.FC<PaymentPhoneNumberVerificationProps> = props => {
  const { onVerifySuccess } = props
  const { phoneVerification } = usePersistedUserData()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      {isOpen && (
        <Portal>
          <Sheet
            isOpen={isOpen}
            setIsOpen={() => setIsOpen(false)}
            backgroundElement={<FeaturedCardsGrid />}
            zIndex={10002}
          >
            <PhoneNumberVerification
              loginPhoneNumber={phoneVerification.loginPhoneNumber}
              sponsorGenealogyIdOrSlug={undefined}
              isCheckingOut={true}
              onSuccess={() => {
                setIsOpen(false)
                onVerifySuccess?.()
              }}
            />
          </Sheet>
        </Portal>
      )}
      <HoverTooltip
        title="Click here to verify your phone number and get your first card for free!"
        tooltipDirection="bottom"
        background="dark"
      >
        <Flex
          onClick={() => {
            setIsOpen(true)
          }}
          css={styles.wrapper}
        >
          <Anchor
            onClick={() => {
              setIsOpen(true)
            }}
            outset={{ right: 'x1' }}
          >
            <Flex>
              <Div css={styles.iconWrapper}>
                $
                <Div css={styles.iconCircle} />
              </Div>
              <Div>Want to send your first card for free?</Div>
            </Flex>
          </Anchor>
        </Flex>
      </HoverTooltip>
    </>
  )
}

export default PaymentPhoneNumberVerification
