import getRandomNumber from './getRandomNumber'

class RandomRunner {
  timeout?: NodeJS.Timeout
  randomTime: () => number
  callback: () => void
  constructor(min: number, max: number, callback: () => void) {
    if (min > max) {
      throw Error('min must be < max')
    }
    this.randomTime = () => getRandomNumber(min, max)
    this.callback = callback
  }
  start = () => {
    this.stop()
    this.timeout = setTimeout(() => {
      this.start()
      this.callback()
    }, this.randomTime())
  }
  stop = () => {
    this.timeout && clearTimeout(this.timeout)
  }
}

export default RandomRunner
