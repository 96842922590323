import { GraphQLClient } from 'graphql-request'
import LogRocket from 'src/logrocket/logrocket'

const saleorGraphQLClient = new GraphQLClient(
  `${process.env.REACT_APP_SALEOR_API_URL}`,
  {
    requestMiddleware: request => {
      if (
        process.env.NODE_ENV === 'development' &&
        process.env.REACT_APP_USE_SALEOR_GQL_DEBUG === 'true'
      ) {
        console.log(
          '[saleor-graphql-debugger-request]',
          '\n' + JSON.parse(request.body as string).query,
          `\nvariables: ${JSON.stringify(request.variables)}`,
          `\nheaders: ${JSON.stringify(request.headers)}`,
        )
      }

      return request
    },
    responseMiddleware: response => {
      if (response instanceof Error) {
        console.error('[saleor-graphql-error]', `${response.message}\n`)
        LogRocket.captureException(response)
      } else {
        if (response.errors !== undefined && response.errors.length !== 0) {
          console.error('[saleor-graphql-errors]', response.errors)
        }
        if (
          process.env.NODE_ENV === 'development' &&
          process.env.REACT_APP_USE__SALEOR_GQL_RESPONSE_DEBUG === 'true'
        ) {
          console.log('[saleor-graphql-debugger-response]', response.data)
        }
      }
    },
  },
)

export { saleorGraphQLClient }
