import { Maybe, Operation } from '../../../__generated__/globalTypes'

import {
  DetailedContactFragment,
  detailedContactFragmentString,
} from '../../fragments/__generated__/DetailedContactFragment'

import { calendarDateFragmentString } from '../../fragments/__generated__/CalendarDateFragment'

import { contactFragmentString } from '../../fragments/__generated__/ContactFragment'

import { optionalYearDateFragmentString } from '../../fragments/__generated__/OptionalYearDateFragment'

import { relatedContactFragmentString } from '../../fragments/__generated__/RelatedContactFragment'

import { noteFragmentString } from '../../fragments/__generated__/NoteFragment'

import separateContactRawString from '../SeparateContact.graphql'

const separateContactString = [
  separateContactRawString,
  detailedContactFragmentString,
  calendarDateFragmentString,
  contactFragmentString,
  optionalYearDateFragmentString,
  relatedContactFragmentString,
  noteFragmentString,
].join('\n\n')

export type SeparateContactData = {
  separateContact: {
    contacts: Maybe<Maybe<DetailedContactFragment>[]>
  }
}

export type SeparateContactVariables = {
  spouseId?: Maybe<string>
  childId?: Maybe<string>
}

export type SeparateContact = Operation<
  SeparateContactData,
  SeparateContactVariables
>

export const SeparateContact = ({
  spouseId,
  childId,
}: SeparateContactVariables = {}): SeparateContact => ({
  query: separateContactString,
  variables: {
    spouseId,
    childId,
  },
})
