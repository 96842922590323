import { Maybe, Operation } from '../../../__generated__/globalTypes'

import {
  RecipientFragment,
  recipientFragmentString,
} from '../../fragments/__generated__/RecipientFragment'

import { addressFragmentString } from '../../fragments/__generated__/AddressFragment'

import getPaginatedRecipientsRawString from '../GetPaginatedRecipients.graphql'

const getPaginatedRecipientsString = [
  getPaginatedRecipientsRawString,
  recipientFragmentString,
  addressFragmentString,
].join('\n\n')

export type GetPaginatedRecipientsData = {
  paginatedRecipients: {
    recipients: RecipientFragment[]
    count: number
  }
}

export type GetPaginatedRecipientsVariables = {
  paymentFailures?: Maybe<boolean>
  offset: number
  limit: number
  search?: Maybe<string>
  contacts?: Maybe<string[]>
}

export type GetPaginatedRecipients = Operation<
  GetPaginatedRecipientsData,
  GetPaginatedRecipientsVariables
>

export const GetPaginatedRecipients = ({
  paymentFailures,
  offset,
  limit,
  search,
  contacts,
}: GetPaginatedRecipientsVariables): GetPaginatedRecipients => ({
  query: getPaginatedRecipientsString,
  variables: {
    paymentFailures,
    offset,
    limit,
    search,
    contacts,
  },
})
