import React from 'react'
import { Flex, Icon, Image, Text } from '@sendoutcards/quantum-design-ui'
import Price from 'src/chrome/Price/Price'
import CardItemOption from 'src/chrome/CardItemOption/CardItemOption'
import { GiftLineItemProps } from '../GiftLineItem/GiftLineItem'

export const OrderPreviewGiftLineItem = (
  props: Omit<GiftLineItemProps, 'onChangeGift'> & {
    isCardHorizontal: boolean
  },
) => {
  const { giftItem } = props

  const handleTruncateTitle = (title?: string) => {
    const MAX_TITLE_LENGTH = 24
    if (!title) return

    return title.length >= MAX_TITLE_LENGTH
      ? title.substring(0, MAX_TITLE_LENGTH) + '...'
      : title
  }

  const isGiftUnavailable = giftItem && !giftItem.isSendable

  return (
    <Flex width="100%" justifyContent="flex-start">
      {giftItem && (
        <Flex
          backgroundColor="background"
          height="94px"
          width="100px"
          borderRadius="20px"
          alignItems="center"
          justifyContent="center"
          css={isGiftUnavailable ? { filter: 'grayscale(100%)' } : {}}
        >
          <Image
            isActive={false}
            height={'100%'}
            width={'100%'}
            image={{ url: giftItem.thumbnailURL }}
          />
        </Flex>
      )}
      <Flex
        width="100%"
        flexDirection="column"
        alignItems="flex-start"
        inset={{ left: 'x2' }}
      >
        <Flex width="100%" alignItems="center" justifyContent="space-between">
          <Text
            type="body"
            weight="semiBold"
            color="primaryHeading"
            content={handleTruncateTitle(
              `${giftItem?.title}${
                isGiftUnavailable ? ' (Gift currently unavailable)' : ''
              }`,
            )}
            whiteSpace="nowrap"
            lineHeight={1.2}
            inset={{ top: 'x1_5', right: 'x1' }}
          />
          <CardItemOption
            width="48px"
            height="48px"
            borderRadius="medium"
            onClick={props.onRemoveGift}
            disabledMessage="Remove Gift"
          >
            <Icon
              name="close"
              primaryColor="primaryHeadingText"
              size="xSmall"
            />
          </CardItemOption>
        </Flex>
        {giftItem && (
          <Price
            price={giftItem?.price.price}
            discountPrice={giftItem.price.discountPrice}
          />
        )}
      </Flex>
    </Flex>
  )
}
