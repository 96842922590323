import { ContactFragment } from 'src/legacy_graphql'

import {
  CheckedMap,
  ColumnDefaults,
  FieldMapping,
  getMappedColumns,
  getRowError,
  getStepHash,
  getStepperIndex,
  LOCAL_STORAGE_KEY,
  MappedColumnWithField,
  parseCsvFromFile,
  Step,
  Steps,
} from './helpers'
import { useCallback, useEffect, useMutations, useState } from 'src/hooks'

import { country, Field, MappedRow, ParsedRow, Row } from './columns'

const useHooks = () => {
  const [step, setStep] = useState<Step>({ type: Steps.Upload })
  const stepperIndex = getStepperIndex(step)
  const mutations = useMutations()

  const cleanup = useCallback(
    (importedContacts: ContactFragment[], parsedRows: Partial<ParsedRow>[]) => {
      try {
        window.sessionStorage.removeItem(LOCAL_STORAGE_KEY)
      } catch {
        // do nothing
      }

      setStep({
        type: Steps.Completed,
        parsedRows,
        importedContacts,
      })
    },
    [],
  )

  const startImport = useCallback(
    (
      mappedRows: MappedRow[],
      checkedRows: CheckedMap,
      mapping: FieldMapping,
      defaults: ColumnDefaults,
      dateFormat: string,
    ) => {
      const mappedColumns = getMappedColumns(mapping).concat(
        mapping.country ? [] : [{ field: 'country', column: country }],
      )

      const parsedRows = mappedRows
        .filter((_, index) => checkedRows[index])
        .filter(row => !getRowError(row, mappedColumns, dateFormat, defaults))
        .map(mappedRow =>
          mappedColumns.reduce(
            <T extends Field>(
              parsedRow: Partial<ParsedRow>,
              mappedColumn: MappedColumnWithField<T>,
            ) => {
              const { column } = mappedColumn
              const key = column.apiName
              const value = mappedRow[key]
              return {
                ...parsedRow,
                [key]: value
                  ? column.parse(value ?? '', column, dateFormat)
                  : key === 'country' && value === undefined
                  ? column.parse(defaults.country, column, dateFormat)
                  : undefined,
              }
            },
            {},
          ),
        )

      setStep({
        type: Steps.Import,
        parsedRows,
      })
    },
    [],
  )

  const importChunk = useCallback(
    async (chunk: Partial<ParsedRow>[]) =>
      (
        await mutations.createContacts({
          contacts: chunk,
        })
      ).createContacts,
    [mutations],
  )

  const readFile = useCallback((file: File) => {
    setStep({ type: Steps.ReadFile })

    parseCsvFromFile(file, (rows, fields) => {
      try {
        window.sessionStorage.setItem(
          LOCAL_STORAGE_KEY,
          JSON.stringify({ rows, fields }),
        )
      } catch {
        // ignore errors, probably due to disabled localStorage or
        // file being over the size limit
      }

      setStep({
        type: Steps.MapHeaders,
        rows,
        fields,
      })
    })
  }, [])

  const processFile = useCallback(
    (
      mapping: FieldMapping,
      defaults: ColumnDefaults,
      dateFormat: string | null,
      fields: string[],
      rows: Row[],
    ) => {
      setStep({ type: Steps.ProcessFile })

      window.setTimeout(
        () =>
          setStep({
            type: Steps.EditData,
            mapping,
            defaults,
            dateFormat: dateFormat ?? 'YYYY-MM-DD',
            fields,
            rows,
          }),
        1000,
      )
    },
    [],
  )

  // attempt to resume previous import on mount
  // TODO: need to store mapping etc, and go back to ImportFile step
  useEffect(() => {
    try {
      const previousCsv = window.sessionStorage.getItem(LOCAL_STORAGE_KEY)
      if (!previousCsv) return
      const parsed = JSON.parse(previousCsv)
      if ('rows' in parsed && 'rows' in parsed) {
        setStep({
          type: Steps.MapHeaders,
          fields: parsed.fields,
          rows: parsed.rows,
        })
      }
    } catch {
      // ignore errors
    }
  }, [])

  // Set the window hash to the current step whenever it changes. This
  // is used to emulate some routing (see the popstate useEffect)
  const stepHash = getStepHash(step)
  useEffect(() => {
    if (stepHash) {
      const hash = `#${stepHash}`
      if (window.location.hash !== hash) {
        if (window.location.hash) {
          window.history.pushState(null, '', hash)
        } else {
          window.history.replaceState(null, '', hash)
        }
      }
    }
  }, [stepHash])

  // Implementing correct history back / forward behavior is especially hard
  // here as some of the state (the content of the file) is very likely to
  // overflow the maximum history.state capacity. Instead we'll support going
  // from a step to the previous one by calling setStep if the hash after
  // popstate matches the previous step. Going forward is not supported.
  useEffect(() => {
    const listener = (e: PopStateEvent) => {
      if (
        step.type === Steps.EditData &&
        window.location.hash === '#map-headers'
      ) {
        setStep({
          type: Steps.MapHeaders,
          fields: step.fields,
          rows: step.rows,
        })
      }

      if (
        step.type === Steps.MapHeaders &&
        window.location.hash === '#upload'
      ) {
        setStep({ type: Steps.Upload })
      }
    }

    window.addEventListener('popstate', listener)
    return () => window.removeEventListener('popstate', listener)
  }, [step])

  return {
    step,
    setStep,
    stepperIndex,
    startImport,
    readFile,
    processFile,
    importChunk,
    cleanup,
  }
}

export default useHooks

export type Hooks = ReturnType<typeof useHooks>
