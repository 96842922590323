import React from 'react'
import { Icon } from '../../atoms/icons/components/Icon'

type OrderTotalProps = {
  total?: string
  shouldDisplayLabelText?: boolean
}

const fontFamily = {
  fontFamily: 'Montserrat, sans-serif',
}

export const OrderTotal: React.FC<OrderTotalProps> = ({
  total = '$-.--',
  shouldDisplayLabelText = false,
}) => {
  const styles = {
    container: {
      display: 'flex',
      padding: '0.5rem 1rem',
      borderRadius: '3rem',
      backgroundColor: '#f5f6f7',
      span: {
        ...fontFamily,
      },
    },
    label: {
      fontSize: '0.9375rem',
      paddingLeft: '0.5rem',
      fontWeight: 600,
    },
    total: {
      fontSize: '0.8125rem',
      paddingLeft: '0.5rem',
      fontWeight: 500,
    },
  }
  return (
    <div css={styles.container}>
      <Icon name="shopping_cart" size={16} color="#323232" />
      {shouldDisplayLabelText && <span css={styles.label}>Shopping Card:</span>}
      <span css={styles.total}>{total}</span>
    </div>
  )
}
