import { Operation } from '../../../__generated__/globalTypes'

import {
  MarketingParagraphFragment,
  marketingParagraphFragmentString,
} from '../../fragments/__generated__/MarketingParagraphFragment'

import getMarketingParagraphByIdRawString from '../GetMarketingParagraphById.graphql'

const getMarketingParagraphByIdString = [
  getMarketingParagraphByIdRawString,
  marketingParagraphFragmentString,
].join('\n\n')

export type GetMarketingParagraphByIdData = {
  paragraph: MarketingParagraphFragment
}

export type GetMarketingParagraphByIdVariables = {
  id: string
}

export type GetMarketingParagraphById = Operation<
  GetMarketingParagraphByIdData,
  GetMarketingParagraphByIdVariables
>

export const GetMarketingParagraphById = ({
  id,
}: GetMarketingParagraphByIdVariables): GetMarketingParagraphById => ({
  query: getMarketingParagraphByIdString,
  variables: {
    id,
  },
})
