import { Maybe, Operation } from '../../../__generated__/globalTypes'

import {
  ColorFragment,
  colorFragmentString,
} from '../../fragments/__generated__/ColorFragment'

import getDesignColorsBySendableCardIdRawString from '../GetDesignColorsBySendableCardID.graphql'

const getDesignColorsBySendableCardIdString = [
  getDesignColorsBySendableCardIdRawString,
  colorFragmentString,
].join('\n\n')

export type GetDesignColorsBySendableCardIDData = {
  sendableCard: {
    designColors: Maybe<Maybe<ColorFragment>[]>
  }
}

export type GetDesignColorsBySendableCardIDVariables = {
  id: string
  quantity?: Maybe<number>
  quality?: Maybe<number>
}

export type GetDesignColorsBySendableCardID = Operation<
  GetDesignColorsBySendableCardIDData,
  GetDesignColorsBySendableCardIDVariables
>

export const GetDesignColorsBySendableCardID = ({
  id,
  quantity,
  quality,
}: GetDesignColorsBySendableCardIDVariables): GetDesignColorsBySendableCardID => ({
  query: getDesignColorsBySendableCardIdString,
  variables: {
    id,
    quantity,
    quality,
  },
})
