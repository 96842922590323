import { Maybe } from '../../../__generated__/globalTypes'

import userSettingsFragmentRawString from '../UserSettingsFragment.graphql'

export const userSettingsFragmentString = userSettingsFragmentRawString

export type UserSettingsFragment = {
  __typename: 'UserSettings'
  defaultBackPanel: Maybe<{
    id: string
  }>
  shouldReceivePromoEmails: boolean
  shouldReceiveNotificationEmails: boolean
  shouldReceiveReceiptEmails: boolean
  shouldReceiveReminderEmails: boolean
  shouldPrintRecipientCompany: boolean
  remindersAdvanceNoticeDays: number
  remindersAdvanceNoticeDaysInternational: number
  paymentHold: boolean
}

export const isUserSettingsFragment = (
  fragment: any,
): fragment is UserSettingsFragment =>
  fragment &&
  fragment.__typename === 'UserSettings' &&
  (fragment.defaultBackPanel === null ||
    (fragment.defaultBackPanel &&
      typeof fragment.defaultBackPanel.id === 'string')) &&
  typeof fragment.shouldReceivePromoEmails === 'boolean' &&
  typeof fragment.shouldReceiveNotificationEmails === 'boolean' &&
  typeof fragment.shouldReceiveReceiptEmails === 'boolean' &&
  typeof fragment.shouldReceiveReminderEmails === 'boolean' &&
  typeof fragment.shouldPrintRecipientCompany === 'boolean' &&
  typeof fragment.remindersAdvanceNoticeDays === 'number' &&
  typeof fragment.remindersAdvanceNoticeDaysInternational === 'number' &&
  typeof fragment.paymentHold === 'boolean'
