import React from 'react'
import {
  Anchor,
  Button,
  Flex,
  Icon,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { getFormattedNumber } from './PhoneNumberVerification'
import { useActions } from 'src/hooks'
import {
  PhoneNumberInput,
  VerifyPhoneNumberFailureFragment,
  VerifyPhoneNumberFailureType,
} from 'src/graphql/generated/graphql'

const IncorrectCode = (props: {
  phoneNumber: PhoneNumberInput
  failure?: VerifyPhoneNumberFailureFragment
  getNewCode: (phoneNumber?: PhoneNumberInput) => void
  tryAgain: () => void
}) => {
  const { getNewCode, tryAgain, phoneNumber, failure } = props

  const formattedNumber = getFormattedNumber(
    phoneNumber.countryIsoCode,
    phoneNumber.nationalNumber,
  )

  const actions = useActions()

  // KAIZEN: (shiggins) for future: handle each error type individually
  // Note: 11/19/20, waiting for updates from product/marketing with copy
  // for TOO_MANY_ATTEMPTY and CODE_EXPIRED

  const content =
    failure?.failureType === VerifyPhoneNumberFailureType.AlreadyVerified ? (
      <>
        <Text type="caption" alignment="left">
          The phone number {formattedNumber} has been found on an existing
          account. You may have:
        </Text>
        <Spacer space="x2" />
        <Text type="caption" alignment="left">
          <ul
            style={{
              fontFamily: 'Montserrat, sans-serif',
              listStylePosition: 'inside',
            }}
          >
            <li style={{ fontFamily: 'Montserrat, sans-serif' }}>
              sent a FREE card using this phone number
            </li>
            <li style={{ fontFamily: 'Montserrat, sans-serif' }}>
              created multiple accounts using this phone number
            </li>
          </ul>
        </Text>
        <Spacer space="x2" />
        <Text type="caption" alignment="left">
          This number can only be linked to one account. Would you like to
          reassign this number to this account? To reassign this number, you
          will need to complete the phone verification one more time.
        </Text>
      </>
    ) : (
      <>
        <Spacer space="x3" />
        <Text type="subtitle" weight="bold">
          Code Not Found
        </Text>
        <Spacer space="x2" />
        <Text type="caption" alignment={'center'}>
          Looks like the code you entered does not match the token we sent to:{' '}
          <b>{` ${formattedNumber} `}</b>
        </Text>
        <Spacer space="x4" />
      </>
    )

  const buttons =
    failure?.failureType === VerifyPhoneNumberFailureType.AlreadyVerified ? (
      <>
        <Flex flexDirection={'row'} justifyContent="space-between">
          <Button
            title={"No, don't do anything"}
            onClick={() => {
              actions.closePhoneNumberVerification()
              actions.openAccount()
            }}
            size="small"
            outlined={true}
            type="danger"
            hover={true}
          />
          <Spacer space="x4" orientation="horizontal" />
          <Button
            title={'Yes, reassign this number'}
            onClick={() => {
              actions.openPhoneNumberVerification(
                phoneNumber,
                false,
                false,
                true,
              )
              getNewCode(phoneNumber)
            }}
            size="small"
            outlined={true}
            type="danger"
            hover={true}
          />
        </Flex>
        <Spacer space="x3" />
        <Text type="caption" alignment="left">
          For further assistance, contact the Customer Success team at (801)
          463-3800 to resolve this issue.
        </Text>
      </>
    ) : (
      <>
        <Button
          title={'Get a new code'}
          onClick={() => getNewCode(undefined)}
          outlined={true}
          type="danger"
          hover={true}
        />
        <Spacer space="x2" />
        <Anchor
          title="Try my code again"
          size="small"
          onClick={tryAgain}
          isDecorated={true}
        />
      </>
    )

  return (
    <div>
      <Flex
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
      >
        <Icon name="exclamation" size="xLarge" primaryColor="danger" />
        {content}
      </Flex>
      <Spacer space="x2" />
      <Flex justifyContent="center" flexDirection="column" alignItems="center">
        {buttons}
      </Flex>
    </div>
  )
}

export default IncorrectCode
