import { Route } from 'src/routes/Route'
import { CustomCardRoute } from './CustomCardRoute'
import { selectedCatalogCard } from 'src/redux/actions/catalog'

export const CustomCardsRoute = Route({
  path: '/custom-cards',
  init: (subroute?: CustomCardRoute) => ({ subroute }),
  subroute: CustomCardRoute,
  reducer: (route, action) => {
    switch (action.type) {
      case selectedCatalogCard.type:
        return action.dataType === 'Card'
          ? { ...route, subroute: CustomCardRoute(action.id) }
          : undefined
      default:
        return route
    }
  },
})
export type CustomCardsRoute = Route<typeof CustomCardsRoute>
