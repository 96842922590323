import React from 'react'
import {
  Div,
  List,
  ListItem,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'

// @src imports
import { Button } from 'src/chrome'
import { useActions, usePlansSubscriptions, useQueries } from 'src/hooks'
import planContentStyles from '../PlanContentsGrid/planContentsGrid.module.scss'
import { getPlansDescriptions } from 'src/legacy_graphql'
import { PlansDescription } from '../../types'

type BasicPlanProps = {
  onSelect: (planId: string) => void
}

const BasicPlan: React.FC<BasicPlanProps> = props => {
  const { onSelect } = props
  const actions = useActions()
  const [plansDescriptions] = useQueries(getPlansDescriptions())
  const { currentPlan, newPlan } = usePlansSubscriptions()
  const plansDescription = plansDescriptions
    .map(PlansDescription.fromPlansDescriptionFragment)
    .find(p => p.description.plan === 'Basic')
  const planDescription = plansDescription?.description
  const hasBasicSubscription =
    newPlan?.title === 'Basic' || currentPlan?.title === 'Basic'

  const handleContinue = () => {
    hasBasicSubscription
      ? actions.openCatalog()
      : plansDescription && onSelect(plansDescription.id)
  }

  return (
    <Div
      className={`${planContentStyles.featureGridContainer} ${planContentStyles.offWhite}`}
    >
      <Div className={planContentStyles.featureGrid}>
        <Text type="subtitle">Or you can pay as you go!</Text>
        <Spacer space="x1" />
        <Text type="caption">
          Not ready to commit to a subscription? With a Pay As You Go Account,
          you can send cards for $2.75 a piece, plus postage! Once you try out
          our system, you can always upgrade later!
        </Text>
        <Spacer space="x1_5" />
        {planDescription && (
          <List
            alignment="left"
            orientation="vertical"
            css={{ paddingLeft: '2em' }}
          >
            {planDescription.details &&
              planDescription.details.map(detail => (
                <ListItem
                  key={detail.detail}
                  orientation="horizontal"
                  css={{ listStyle: 'disc' }}
                >
                  {detail.innerDetails && (
                    <>
                      <Text type="footnote">{detail.detail}</Text>
                      <List alignment="left" orientation="vertical">
                        {detail.innerDetails.map(innerDetail => (
                          <ListItem
                            orientation="horizontal"
                            key={innerDetail.detail}
                            css={{ listStyle: 'circle', marginLeft: '1em' }}
                          >
                            <Text type="footnote">{innerDetail.detail}</Text>
                          </ListItem>
                        ))}
                      </List>
                    </>
                  )}
                  {!detail.innerDetails && (
                    <Text type="footnote">{detail.detail}</Text>
                  )}
                </ListItem>
              ))}
          </List>
        )}
        <Spacer space="x4" />
        <Button
          title={`${hasBasicSubscription ? 'Continue with Basic' : 'select'}`}
          onClick={() => handleContinue()}
        />
      </Div>
    </Div>
  )
}

export default BasicPlan
