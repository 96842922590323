import get from 'lodash/get'

// result.ts
export interface ValueAndError<T> {
  readonly value?: T
  readonly error?: Error
}
const RESULT = 'RESULT'

export type NotNullOrUndefined<T> = Exclude<T, undefined | null>

export interface Result<T> extends ValueAndError<T> {
  type: typeof RESULT
  map<U>(transform: (value: T) => U): Result<U>
  match<U>(success: (value: T) => U, failure: (error: Error) => U): U
  select<K extends keyof T>(key: K): Result<NotNullOrUndefined<T[K]>>
}

const catchResult = <T, U>(result: () => T): T | Result<U> => {
  try {
    return result()
  } catch (error) {
    return failure(error as Error)
  }
}

export function isResult<T>(arg: unknown): arg is Result<T> {
  return get(arg, 'type') === RESULT
}

export function Result<T>(valueOrError: T | Error): Result<T> {
  if (valueOrError instanceof Error) {
    return failure(valueOrError)
  } else {
    return success(valueOrError)
  }
}

export const success = <T>(value: T): Result<T> => ({
  value,
  type: RESULT,
  map: <U>(transform: (value: T) => U): Result<U> =>
    catchResult(() => success(transform(value))),
  match: <U>(success: (value: T) => U, failure: (error: Error) => U) => {
    try {
      return success(value)
    } catch (error) {
      return failure(error as Error)
    }
  },
  select: <K extends keyof T>(key: K): Result<NotNullOrUndefined<T[K]>> => {
    const selected = value[key]
    return selected === null
      ? failure(Error(`${String(key)} is null on ${value}`))
      : selected === undefined
      ? failure(Error(`${String(key)} is undefined on ${value}`))
      : success<NotNullOrUndefined<T[K]>>(selected as NotNullOrUndefined<T[K]>)
  },
})

export const failure = <T>(error: Error): Result<T> => ({
  error,
  type: RESULT,
  map: () => failure(error),
  match: <U>(_: unknown, failure: (error: Error) => U) => failure(error),
  select: key => failure(error),
})

export const zip = <V>(
  results: { [K in keyof V]: Result<V[K]> },
): Result<V> => {
  const mutableValue: Partial<V> = {}
  for (const key in results) {
    const result = results[key]
    if (result.value) {
      mutableValue[key] = result.value
    } else if (result.error) {
      return failure(result.error)
    }
  }
  return success(mutableValue as V)
}

export default Result
