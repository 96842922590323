import React from 'react'
import { Button, Text } from '@sendoutcards/quantum-design-ui'
import { FlexColumn, FlexRow } from 'src/styled'
import { useAccount, useActions, useSelector } from 'src/hooks'
import { StepWrapperProps } from '../components/ProgressiveProfileActions'

type FreeCardNotificationProps = StepWrapperProps & {
  onComplete: () => void
  dismissModal: () => void
}

const FreeCardNotification = (props: FreeCardNotificationProps) => {
  const { dismissModal } = props
  const { isMobile } = useSelector(s => s.window)
  const account = useAccount()
  const actions = useActions()

  const onNow = account.verifiedPhoneNumber
    ? () => {
        actions.openCatalog()
        dismissModal()
      }
    : () => actions.openPhoneNumberVerification()

  return (
    <FlexColumn
      style={{
        justifyContent: 'center',
        marginBottom: isMobile ? '65px' : undefined,
      }}
    >
      <Text type="largeBody">Send your first card for free!</Text>
      <Text type="body">Do you want to send your free card now or later?</Text>
      <FlexRow
        style={{
          marginTop: '12px',
          marginBottom: '12px',
        }}
      >
        <div style={{ width: '30%' }}>
          <Button
            type="primary"
            title="Send It Now"
            onClick={onNow}
            fullWidth={true}
          />
        </div>
      </FlexRow>
      <Text type="body">
        You can share this link with anyone - if it’s their first card, it will
        be free!
      </Text>
      <Text type="body">
        {account.isAffiliate
          ? `https://www.sendoutcards.com/u/${account.genealogyId}/send`
          : `https://www.sendoutcards.com/u/${account.sponsor?.storefront?.slug}/send`}
      </Text>
    </FlexColumn>
  )
}

export default FreeCardNotification
