import React from 'react'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { IconType } from 'src/design_system/atoms/icons/types'
import { PlanFragment } from 'src/graphql/generated/graphql'

// TODO: Add Icon To Plans Backend
export const getIconFromPlan = (plan: PlanFragment, isActive?: boolean) => {
  const color = isActive ? '#fff' : plan.backgroundStartColor
  const iconMap: Record<
    string,
    { name: IconType; color: string; size: number }
  > = {
    // Subscription Icons
    '6': {
      name: 'smile_card_w_heart',
      color: color,
      size: 42,
    },
    '128': {
      name: 'smile_card_w_hearts',
      color: isActive ? '#fff' : plan.backgroundStartColor,
      size: 52,
    },
    '129': {
      name: 'smile_card_w_hearts',
      color: isActive ? '#fff' : plan.backgroundStartColor,
      size: 52,
    },
    // Automated System Package Icons
    '130': {
      name: 'solopreneur',
      color: color,
      size: 42,
    },
    '131': {
      name: 'entrepreneur',
      color: color,
      size: 42,
    },
    '132': {
      name: 'businesspreneur',
      color: color,
      size: 42,
    },
    // Card Packs Icons
    '133': {
      name: 'card_pack',
      color: color,
      size: 42,
    },
    '134': {
      name: 'card_pack',
      color: color,
      size: 42,
    },
    '135': {
      name: 'card_pack',
      color: color,
      size: 42,
    },
    '136': {
      name: 'card_pack',
      color: color,
      size: 42,
    },
    '137': {
      name: 'card_pack',
      color: color,
      size: 42,
    },
    // TODO change [170, 171, 172] > [140, 141, 142]
    '170': {
      name: 'time_block',
      color: color,
      size: 34,
    },
    '171': {
      name: 'time_block',
      color: color,
      size: 34,
    },
    '172': {
      name: 'time_block',
      color: color,
      size: 34,
    },
    '141': {
      name: 'personal_hand_writing',
      color: color,
      size: 70,
    },
    '142': {
      name: 'personal_signature',
      color: color,
      size: 70,
    },
    '143': {
      name: 'essential_packs',
      color: color,
      size: 70,
    },
    '146': {
      name: 'ticket',
      color: color,
      size: 34,
    },
    '144': {
      name: 'ticket',
      color: color,
      size: 34,
    },
    '145': {
      name: 'ticket',
      color: color,
      size: 34,
    },
    '122': {
      name: 'ticket',
      color: color,
      size: 34,
    },
    default: {
      name: 'double_check',
      color: isActive ? '#fff' : plan.backgroundStartColor,
      size: 42,
    },
  }

  const iconProps = iconMap[plan.id] || iconMap.default
  return (
    <Icon name={iconProps.name} color={iconProps.color} size={iconProps.size} />
  )
}
