import React from 'react'
import { Div, Text } from '@sendoutcards/quantum-design-ui'
import HoverTooltip from 'src/chrome/HoverTooltip/HoverTooltip'

export const QuantityCounter = (props: {
  quantity: number
  tooltip?: string
}) => {
  const tooltip =
    `You have attached ${props.quantity} recipients` ?? props.tooltip
  return (
    <HoverTooltip
      title={tooltip}
      shouldDisplayTooltip={true}
      isFullWidth={true}
      background="dark"
    >
      <Div
        borderRadius="small"
        backgroundColor="background"
        inset={{ vertical: 'x_5', horizontal: 'x1' }}
      >
        <Text content={`x${props.quantity}`} type="footnote" weight="bold" />
      </Div>
    </HoverTooltip>
  )
}
