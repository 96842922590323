import { TextFragment } from 'src/editor/types'
import {
  ComputedElementText,
  ComputedSelection,
  Paragraph,
  TextEditorState,
} from '../../editor/text/types'
import { ColorFragment } from 'src/legacy_graphql'

export const paragraphStart = (state: TextEditorState) =>
  state.text
    .slice(0, state.selection.start)
    .reduce((acc, fragment, index) => (fragment.textAlign ? index : acc), 0)

export const insertionTextAlign = (state: TextEditorState) =>
  state.text[paragraphStart(state)].textAlign

export const insertionProperties = (state: TextEditorState): TextFragment => {
  const insertionProperties = {
    ...state.text[
      Math.min(
        Math.max(
          state.selection.start === state.selection.end
            ? state.selection.start - 1
            : state.selection.start,
          0,
        ),
        state.text.length - 1,
      )
    ],
    ...state.selection.overrideProperties,
  }
  const {
    textAlign,
    signatureFontId,
    text,
    ...remainingProperties
  } = insertionProperties
  return remainingProperties
}

export const paragraphs = (fragments: TextFragment[]) =>
  fragments.reduce((paragraphs: Paragraph[], fragment, index) => {
    const lastIndex = paragraphs.length - 1
    const lastParagraph = paragraphs[lastIndex]
    return fragment.textAlign
      ? [
          ...paragraphs,
          {
            index,
            fontId: fragment.fontId,
            fontSize: fragment.fontSize,
            color: fragment.color,
            textAlign: fragment.textAlign,
            fragments: [],
          },
        ]
      : [
          ...paragraphs.slice(0, lastIndex),
          {
            ...lastParagraph,
            fragments: [...lastParagraph.fragments, { ...fragment, index }],
          },
        ]
  }, [])

const selectedFragments = (state: TextEditorState) =>
  state.text.slice(state.selection.start, state.selection.end)

const selectedText = (state: TextEditorState): string =>
  selectedFragments(state)
    .map(fragment => fragment.text ?? (fragment.signatureFontId ? '' : '\n'))
    .join('')

const selectedProperty = <K extends keyof TextFragment>(
  state: TextEditorState,
  key: K,
) =>
  state.selection.start === state.selection.end
    ? insertionProperties(state)[key]
    : state.text
        .slice(state.selection.start, state.selection.end)
        .map(fragment => fragment[key])
        .reduce((acc, value) => (acc === value ? acc : value))

export const selectedFontId = (state: TextEditorState) =>
  selectedProperty(state, 'fontId')

export const selectedFontSize = (state: TextEditorState) =>
  selectedProperty(state, 'fontSize')

export const selectedColor = (state: TextEditorState) =>
  selectedProperty(state, 'color')

export const selectedTextAlign = (state: TextEditorState) =>
  state.text
    .slice(paragraphStart(state), state.selection.end)
    .map(fragment => fragment.textAlign)
    .filter(align => align)
    .reduce((acc, align) => (align === acc ? acc : undefined))

const selectionPaths = (state: TextEditorState) =>
  state.text.reduce(
    ({ paragraph, offset, selectionPaths }, fragment) => {
      const newParagraph = fragment.textAlign ? paragraph + 1 : paragraph
      const newOffset = fragment.textAlign ? 0 : offset + 1
      return {
        paragraph: newParagraph,
        offset: newOffset,
        selectionPaths: [
          ...selectionPaths,
          { paragraph: newParagraph, offset: newOffset },
        ],
      }
    },
    {
      paragraph: -1,
      offset: -1,
      selectionPaths: Array<{ paragraph: number; offset: number }>(),
    },
  ).selectionPaths

const paragraphSelection = (state: TextEditorState): ComputedSelection => {
  const paths = selectionPaths(state)
  return {
    start: {
      ...paths[state.selection.start - 1],
      index: state.selection.start,
    },
    end: { ...paths[state.selection.end - 1], index: state.selection.end },
    fragments: selectedFragments(state),
    text: selectedText(state),
  }
}

export const computedElementText = (
  state: TextEditorState,
  isSelected: boolean,
): ComputedElementText => ({
  paragraphs: paragraphs(state.text),
  selectionUpperBound: state.text.length,
  selection: isSelected ? paragraphSelection(state) : undefined,
})

export const rgbString = (color: ColorFragment): string =>
  `rgb(${color.red}, ${color.green}, ${color.blue})`
