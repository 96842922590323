import { Div, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { CartWithCounter } from './CartWithCounter'

interface CartButtonProps {
  onClick: () => void
  isActive?: boolean
  total?: number
  height?: string
  id?: string
  isMobile?: boolean
  backgroundColor?: string
  actionType?: 'mobile' | 'desktop'
}

const CartButton: React.FC<CartButtonProps> = props => {
  const {
    onClick,
    isActive,
    total,
    height = '42px',
    id,
    isMobile,
    backgroundColor,
    actionType,
  } = props
  return (
    <Div
      display="flex"
      justifyContent="center"
      alignItems="center"
      backgroundColor={
        backgroundColor
          ? backgroundColor
          : isMobile
          ? undefined
          : isActive
          ? '#000'
          : '#f5f6f7'
      }
      borderRadius={isMobile ? 'small' : '12px'}
      width={isMobile ? 'unset' : '150px'}
      height={isMobile ? 'unset' : height}
      inset={isMobile ? 'x1' : undefined}
      overflow="hidden"
      onClick={onClick}
      cursor="pointer"
      id={id}
    >
      <CartWithCounter
        isMobile={isMobile}
        count={total}
        isActive={isActive ?? false}
        backgroundColor={backgroundColor}
        actionType={actionType}
      />
      {!isMobile && (
        <>
          <Spacer orientation="horizontal" space={'x1'} />
          <Text
            type="caption"
            weight="bold"
            content={isActive ? 'Close Cart' : 'View My Cart'}
            color={isActive ? 'inverseHeading' : 'primaryHeading'}
          />
        </>
      )}
    </Div>
  )
}

export default CartButton
