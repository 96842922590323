import React from 'react'
// @src imports
import { Dialog } from 'src/chrome'
import { CreateContactInput, GroupFragment, Maybe } from 'src/legacy_graphql'

import AddressForm, { FormContact } from './AddressForm'
import styles from './addressForm.module.scss'
import { DialogContent } from '@material-ui/core'

interface Props {
  isModal?: boolean
  isSaving?: boolean
  contact?: FormContact
  shouldShowAddressOnly?: boolean
  showAddSpouse?: boolean
  showAddChildren?: boolean
  showAddGroup?: boolean
  checkboxText?: string
  secondaryCheckboxText?: string
  disableCountryReason?: string
  isSecondaryCheckboxChecked?: boolean
  confirmButtonText?: string
  errorMessages?: Maybe<string>[]
  groups?: GroupFragment[]
  close: () => void
  onSubmit?: (
    contact: CreateContactInput,
    isCheckboxChecked: boolean,
    isSecondaryCheckboxChecked: boolean,
  ) => void
}

const AddressFormModal: React.FC<Props> = props => {
  const {
    close,
    isModal,
    isSaving,
    contact,
    groups,
    onSubmit,
    shouldShowAddressOnly,
    checkboxText,
    disableCountryReason,
    secondaryCheckboxText,
    isSecondaryCheckboxChecked,
    errorMessages,
    confirmButtonText,
    showAddSpouse,
    showAddChildren,
    showAddGroup,
  } = props
  return (
    <Dialog
      disableBackdropClick={isModal ?? false}
      open={true}
      onClose={close}
      scroll={'paper'}
      style={{ zIndex: 199 }}
    >
      <DialogContent className={styles.addressModal}>
        <AddressForm
          isTitleVisible={!shouldShowAddressOnly}
          contact={contact}
          close={close}
          onSubmit={onSubmit}
          isSaving={isSaving}
          shouldShowDates={!shouldShowAddressOnly}
          checkboxText={checkboxText}
          secondaryCheckboxText={secondaryCheckboxText}
          isSecondaryCheckboxChecked={isSecondaryCheckboxChecked}
          disableCountryReason={disableCountryReason}
          errorMessages={errorMessages}
          confirmButtonText={confirmButtonText}
          showAddSpouse={showAddSpouse}
          showAddChildren={showAddChildren}
          showAddGroup={showAddGroup}
          groups={groups}
        />
      </DialogContent>
    </Dialog>
  )
}

export default AddressFormModal
