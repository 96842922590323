import { Maybe, Operation } from '../../../__generated__/globalTypes'

import shareCampaignString from '../ShareCampaign.graphql'

export type ShareCampaignData = {
  shareCampaign: {
    shared: boolean
  }
}

export type ShareCampaignVariables = {
  campaign: string
  username: string
  allowShare?: Maybe<boolean>
}

export type ShareCampaign = Operation<ShareCampaignData, ShareCampaignVariables>

export const ShareCampaign = ({
  campaign,
  username,
  allowShare,
}: ShareCampaignVariables): ShareCampaign => ({
  query: shareCampaignString,
  variables: {
    campaign,
    username,
    allowShare,
  },
})
