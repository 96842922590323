import React from 'react'

import { formatOptionalYearDate } from 'src/helpers/dates'
import { NoteFragment } from 'src/legacy_graphql'

import styles from './detailViews.module.scss'
import { Div, Spacer, Text, WeightType } from '@sendoutcards/quantum-design-ui'

type ContactNamesProps = {
  firstName: string
  lastName: string
  company: string
  weight?: WeightType
}

export const ContactNames: React.FC<ContactNamesProps> = props => {
  const { firstName, lastName, company, weight } = props
  return (
    <div className={styles.name}>
      {(firstName || lastName) && (
        <div>
          <Text
            type="largeBody"
            weight={weight}
            color="primaryHeading"
          >{`${firstName} ${lastName}`}</Text>
        </div>
      )}
      {company ? (
        <div>
          <Div display="inline-block">
            <Text type="body">Company</Text>
          </Div>
          <Spacer space="x1" orientation="horizontal" />
          <Text
            type="largeBody"
            weight={weight}
            color="primaryHeading"
          >{`${company}`}</Text>
        </div>
      ) : null}
    </div>
  )
}

type ContactAddressProps = {
  address1: string
  address2: string
  city: string
  state: string
  postalCode: string
  country: string | null
}

export const ContactAddress: React.FC<ContactAddressProps> = props => {
  const { address1, address2, city, state, postalCode, country } = props
  return (
    <div className={styles.block}>
      {address1 && (
        <div>
          <Text type="largeBody" color="primaryHeading">{`${address1}`}</Text>
        </div>
      )}
      {address2 && (
        <div>
          <Text type="largeBody" color="primaryHeading">{`${address2}`}</Text>
        </div>
      )}
      {city && (
        <div>
          <Text type="largeBody" color="primaryHeading">{`${city}, ${
            state !== '' ? state + ' ' : ''
          }${postalCode}`}</Text>
        </div>
      )}
      {country && (
        <div>
          <Text type="largeBody" color="primaryHeading">{`${country}`}</Text>
        </div>
      )}
    </div>
  )
}

type ContactNumbersProps = {
  homePhone: string | null
  cellPhone: string | null
  workPhone: string | null
  faxNumber: string | null
  emailAddress: string | null
  website: string | null
}

export const ContactNumbers: React.FC<ContactNumbersProps> = props => {
  const {
    homePhone,
    cellPhone,
    workPhone,
    faxNumber,
    emailAddress,
    website,
  } = props
  return (
    <div className={styles.numbers}>
      {homePhone && (
        <div>
          <Div display="inline-block">
            <Text type="caption">Home</Text>
          </Div>
          <Text type="largeBody" color="primaryHeading">{`${homePhone}`}</Text>
        </div>
      )}
      {cellPhone && (
        <div>
          <Div display="inline-block">
            <Text type="caption">Cell</Text>
          </Div>
          <Text type="largeBody" color="primaryHeading">{`${cellPhone}`}</Text>
        </div>
      )}
      {workPhone && (
        <div>
          <Div display="inline-block">
            <Text type="caption">Work</Text>
          </Div>
          <Text type="largeBody" color="primaryHeading">{`${workPhone}`}</Text>
        </div>
      )}
      {faxNumber && (
        <div>
          <Div display="inline-block">
            <Text type="caption">Fax</Text>
          </Div>
          <Text type="largeBody" color="primaryHeading">{`${faxNumber}`}</Text>
        </div>
      )}
      {emailAddress && (
        <div>
          <Div display="inline-block">
            <Text type="caption">Email Address</Text>
          </Div>
          <Text
            type="largeBody"
            color="primaryHeading"
          >{`${emailAddress}`}</Text>
        </div>
      )}
      {website && (
        <div>
          <Div display="inline-block">
            <Text type="caption">Website</Text>
          </Div>
          <Text type="largeBody" color="primaryHeading">{`${website}`}</Text>
        </div>
      )}
    </div>
  )
}

type ContactDatesProps = {
  birthday?: { day: number; month: number; year: number | null }
  anniversary?: { day: number; month: number; year: number | null }
}

export const ContactDates: React.FC<ContactDatesProps> = props => {
  const { birthday, anniversary } = props
  return (
    <div className={styles.dates}>
      {birthday && (
        <Text
          type="largeBody"
          color="primaryHeading"
        >{`Birthday ${formatOptionalYearDate(birthday)}`}</Text>
      )}
      {anniversary && (
        <Text type="largeBody" color="primaryHeading">
          {`Anniversary ${formatOptionalYearDate(anniversary)}`}
        </Text>
      )}
    </div>
  )
}

type ContactNoteProps = {
  note: NoteFragment
  onEdit: () => void
  onDelete: () => void
}

export const ContactNote: React.FC<ContactNoteProps> = props => {
  const { note, onEdit, onDelete } = props
  return (
    <div className={styles.noteBlock}>
      <Text type="body" color="primaryHeading" weight="bold">
        {note.date
          ? `${note.date.month}/${note.date.day}/${note.date.year}`
          : ''}
      </Text>
      <Text type="body">{note ? note.text : ''}</Text>
      <div className={styles.actions}>
        <span onClick={() => onEdit()}>
          <Text type="caption" color="primaryBrand">
            Edit
          </Text>
        </span>
        <span onClick={() => onDelete()}>
          <Text type="caption" color="primaryBrand">
            Delete
          </Text>
        </span>
      </div>
    </div>
  )
}
