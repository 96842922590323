import { Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import HoverTooltip from 'src/chrome/HoverTooltip/HoverTooltip'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { Button } from 'src/design_system/components/Button/Button'

export type WidgetHeaderProps = {
  title: string
  tooltip?: string
  action?: {
    title: string
    onClick: () => void
    titleColor?: string
    backgroundColor?: string
  }
}

export const WidgetHeader: React.FC<WidgetHeaderProps> = ({
  title,
  tooltip,
  action,
}) => {
  return (
    <Flex
      width="100%"
      height="50px"
      justifyContent={'flex-start'}
      alignItems="center"
      outset={{ bottom: 'x3' }}
      position="relative"
    >
      <Flex alignItems="center">
        <Text
          type="body"
          weight="bold"
          color="primaryHeading"
          content={title}
        />

        {tooltip && (
          <HoverTooltip
            title={tooltip}
            background="dark"
            shouldDisplayTooltip={true}
            tooltipDirection="bottom"
          >
            <Flex cursor="pointer" inset={{ left: 'x_25' }}>
              <Icon name="info" size={18} />
            </Flex>
          </HoverTooltip>
        )}
      </Flex>
      {!!action && (
        <Flex style={{ marginLeft: 'auto' }}>
          <Button
            textSize="12px"
            title={action.title}
            textColor={action.titleColor ?? '#82898E'}
            backgroundColor={action.backgroundColor ?? '#FFFFFF'}
            onClick={action.onClick}
          />
        </Flex>
      )}
    </Flex>
  )
}
