import React from 'react'

const NotYourCard = () => (
  <svg viewBox="0 0 366.182 282.343">
    <defs>
      <style>
        {
          '.a{fill:#fff;}.b{fill:none;stroke:#686b89;stroke-miterlimit:10;stroke-width:2px;stroke-dasharray:2 9;opacity:0.4;}.c{fill:#7277a7;font-size:67px;font-family:SegoeUI, Segoe UI;}.c,.j{opacity:0.5;}.d{opacity:0.809;}.e{fill:url(#a);}.f{fill:rgba(216,216,216,0.08);}.g{fill:url(#b);}.h{fill:url(#e);}.i{fill:#9ea4b8;}.k{fill:url(#h);}.l{filter:url(#f);}.m{filter:url(#c);}'
        }
      </style>
      <linearGradient
        id="a"
        x1="0.253"
        y1="0.5"
        x2="1"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#aaabc9" stopOpacity="0" />
        <stop offset="1" stopColor="#0e154b" stopOpacity="0.502" />
      </linearGradient>
      <linearGradient
        id="b"
        x1="1.234"
        y1="0.335"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#fefefe" />
        <stop offset="1" stopColor="#fefefe" />
      </linearGradient>
      <filter
        id="c"
        x="24.546"
        y="80.198"
        width="178.429"
        height="202.145"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" result="d" />
        <feFlood floodColor="#9ea4b8" />
        <feComposite operator="in" in2="d" />
        <feComposite in="SourceGraphic" />
      </filter>
      <linearGradient
        id="e"
        x1="1.234"
        y1="0.335"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#9ea4b8" />
        <stop offset="1" stopColor="#9ea4b8" />
      </linearGradient>
      <filter
        id="f"
        x="164.632"
        y="75.076"
        width="178.582"
        height="201.651"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" result="g" />
        <feFlood floodColor="#9d9d9d" floodOpacity="0.157" />
        <feComposite operator="in" in2="g" />
        <feComposite in="SourceGraphic" />
      </filter>
      <linearGradient
        id="h"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#8fa1b0" />
        <stop offset="1" stopColor="#5f7083" />
      </linearGradient>
    </defs>
    <g transform="translate(25.182 1)">
      <g transform="translate(48)">
        <circle
          className="a"
          cx="57"
          cy="57"
          r="57"
          transform="translate(42 42)"
        />
        <circle className="b" cx="99" cy="99" r="99" />
        <text className="c" transform="translate(101.5 129)">
          <tspan x="-9.52" y="0">
            !
          </tspan>
        </text>
      </g>
      <g className="d" transform="translate(1 5)">
        <ellipse
          className="e"
          cx="92.416"
          cy="5"
          rx="92.416"
          ry="5"
          transform="translate(32.259 241)"
        />
        <ellipse
          className="f"
          cx="92.416"
          cy="5"
          rx="92.416"
          ry="5"
          transform="translate(32.259 241)"
        />
      </g>
      <g transform="translate(33.016 123.655) rotate(-25)">
        <g transform="translate(-9.063 -4.226)">
          <g
            className="m"
            transform="matrix(0.91, 0.42, -0.42, 0.91, 9, -133.34)"
          >
            <path
              className="g"
              d="M.009,18.769C.009,8.4.063,2.205.126-.78L98.112,0V15.893L105.1,32.817,98.112,51.962,99.4,71.643,92.948,91.069,105.1,118.637l-6.984,21.122v13.518l-98.143.1c.173-18.859.041-8.506.041-18.872Z"
              transform="translate(33.9 131.32) rotate(-25)"
            />
          </g>
        </g>
      </g>
      <g transform="translate(189.433 130) rotate(25)">
        <g transform="translate(0 -55.318)">
          <g
            className="l"
            transform="matrix(0.91, -0.42, 0.42, 0.91, -249.87, 27.29)"
          >
            <path
              className="h"
              d="M6.216,0l99.428.007c.034,18.732.024,8.4.024,18.766V134.545c0,10.368-.008.117-.048,18.819l-99.4-.045V138.732l7.676-19.713L6.216,103.526,0,88.349,6.216,71.582V49.348l7.676-15.077L6.216,15.5Z"
              transform="translate(238.45 81.08) rotate(25)"
            />
          </g>
        </g>
        <rect
          className="a"
          width="62.609"
          height="5"
          transform="translate(24 70)"
        />
        <rect
          className="a"
          width="62.609"
          height="5"
          transform="translate(24.667 61.965)"
        />
        <rect
          className="a"
          width="62.609"
          height="5"
          transform="translate(25.334 53.931)"
        />
        <rect
          className="a"
          width="22"
          height="5"
          transform="translate(64.609 79)"
        />
        <g transform="translate(-196.854 61.64) rotate(-49)">
          <rect
            className="i"
            width="72.914"
            height="38.17"
            transform="translate(24 44)"
          />
          <rect
            className="i"
            width="37"
            height="5"
            transform="translate(59.914 86.17)"
          />
        </g>
      </g>
      <g className="j" transform="matrix(0.017, 1, -1, 0.017, 340.743, 88.531)">
        <path
          className="k"
          d="M8.938,10.874,3.893,1.121A2.051,2.051,0,0,0,1.107.239,2.1,2.1,0,0,0,.236,3.06l5.045,9.753a2.051,2.051,0,0,0,2.786.882A2.1,2.1,0,0,0,8.938,10.874Z"
          transform="translate(15.676 5.312)"
        />
        <path
          className="k"
          d="M8.938,10.874,3.893,1.121A2.051,2.051,0,0,0,1.107.239,2.1,2.1,0,0,0,.236,3.06l5.045,9.753a2.051,2.051,0,0,0,2.786.882A2.1,2.1,0,0,0,8.938,10.874Z"
          transform="matrix(0.914, -0.407, 0.407, 0.914, 0.547, 18.971)"
        />
        <path
          className="k"
          d="M8.938,10.874,3.893,1.121A2.051,2.051,0,0,0,1.107.239,2.1,2.1,0,0,0,.236,3.06l5.045,9.753a2.051,2.051,0,0,0,2.786.882A2.1,2.1,0,0,0,8.938,10.874Z"
          transform="translate(35.188 0.357) rotate(30)"
        />
      </g>
      <g className="j" transform="translate(-23.697 98) rotate(93)">
        <path
          className="k"
          d="M-8.938,10.874l5.045-9.753A2.051,2.051,0,0,1-1.107.239,2.1,2.1,0,0,1-.236,3.06l-5.045,9.753a2.051,2.051,0,0,1-2.786.882A2.1,2.1,0,0,1-8.938,10.874Z"
          transform="translate(15.676 -5.312) rotate(-180)"
        />
        <path
          className="k"
          d="M-8.938,10.874l5.045-9.753A2.051,2.051,0,0,1-1.107.239,2.1,2.1,0,0,1-.236,3.06l-5.045,9.753a2.051,2.051,0,0,1-2.786.882A2.1,2.1,0,0,1-8.938,10.874Z"
          transform="matrix(-0.914, -0.407, 0.407, -0.914, 0.547, -18.971)"
        />
        <path
          className="k"
          d="M-8.938,10.874l5.045-9.753A2.051,2.051,0,0,1-1.107.239,2.1,2.1,0,0,1-.236,3.06l-5.045,9.753a2.051,2.051,0,0,1-2.786.882A2.1,2.1,0,0,1-8.938,10.874Z"
          transform="translate(35.188 -0.357) rotate(150)"
        />
      </g>
    </g>
  </svg>
)

export default NotYourCard
