import React from 'react'
import moment from 'moment'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'

import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

// @src imports
import { Button, Dialog } from 'src/chrome'
import { days, months } from 'src/helpers/dates'
import {
  SendDelayDelayType,
  SendDelayFragment,
  SendDelayTimeType,
  SendDelayType,
} from 'src/graphql/generated/graphql'
import { useCallback, useState } from 'src/hooks'

import * as SendDelayOptions from 'src/orders/sendDelayOptions'
import { Spacer, Text } from '@sendoutcards/quantum-design-ui'

const styles = {
  delayForm: {
    background: '#fff',
    overflow: 'hidden',
    'input[type="text"]': {
      width: 'calc(100% - 7.5px)',
      background: '#f0f0f0',
      padding: '9px',
      boxShadow: 'none !important',
    },
    '@media (max-width: 550px)': {
      padding: '0px !important',
    },
  },
  formContainer: {
    width: '100%',
  },
  controlsSelect: {
    width: '20% !important',
    marginRight: '15px !important',
    '@media (max-width: 550px)': {
      width: '100% !important',
    },
  },
  optionsButton: {
    padding: '8px',
    border: 'none',
    background: 'none',
    color: '#000000b3',
    transition: '0.4s',
    cursor: 'pointer',
    ':hover': {
      background: '#f0f0f0',
      transition: '0.3s',
    },
  },
  modalContainer: {
    '@media (max-width: 550px)': {
      width: '100% !important',
      transform: 'translate(0px, 0px) !important',
    },
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 35,
    marginBottom: 12,
    '@media (max-width: 350px)': {
      flexDirection: 'column' as const,
    },
  },
  actionsFirstButton: {
    marginRight: '25px',
    '@media (max-width: 350px)': {
      margin: '0 10px 15px',
    },
  },
}

const isSequenceDelay = (sendDelay: SendDelayFragment) =>
  sendDelay.type === SendDelayType.Imm &&
  sendDelay.delayNumber &&
  sendDelay.delayNumber > 0

const setSequence = (shouldShowAdvancedControls: boolean) => {
  return {
    type: SendDelayType.Imm,
    timeType: SendDelayTimeType.Aft,
    delayType: SendDelayDelayType.Mon,
    shouldShowAdvancedControls: shouldShowAdvancedControls,
    delayNumber: 0,
  }
}

const setSendDelay = (sendDelay: SendDelayFragment) => {
  return {
    ...sendDelay,
    specificDate: moment(sendDelay.specificDate, 'YYYY-MM-DD').local(),
    shouldShowAdvancedControls:
      sendDelay.type !== SendDelayType.Spe &&
      !!sendDelay.delayNumber &&
      sendDelay.delayNumber > 0,
  }
}

interface Props {
  isOpen: boolean
  isSequence?: boolean
  isMultiLine?: boolean
  onSubmit: (delay: SendDelayFragment, type: SendDelayType) => void
  close: () => void
  sendDelay?: SendDelayFragment
}

interface State {
  shouldShowAdvancedControls: boolean
  type: SendDelayType
  delayNumber: number
  delayType: SendDelayDelayType
  timeType: SendDelayTimeType
  month: number
  day: number
}

const initialState: State = {
  shouldShowAdvancedControls: false,
  type: SendDelayType.Imm,
  delayNumber: 0,
  delayType: SendDelayDelayType.Day,
  timeType: SendDelayTimeType.Aft,
  day: 0,
  month: 0,
}

const SendDelayForm: React.FC<Props> = props => {
  const { sendDelay, isSequence, close, onSubmit, isOpen, isMultiLine } = props

  const getInitialState = (): State => {
    if (sendDelay) {
      const delay =
        isSequence && !isSequenceDelay(sendDelay)
          ? setSequence(true)
          : setSendDelay(sendDelay)

      return {
        ...delay,
        delayNumber: delay.delayNumber ?? 0,
        ...SendDelayOptions.monthDayDefaults(sendDelay),
      }
    } else {
      return initialState
    }
  }

  const [state, setState] = useState(getInitialState())

  const {
    delayType,
    month,
    day,
    type,
    delayNumber,
    timeType,
    shouldShowAdvancedControls,
  } = state

  const getNumberContext = () => {
    switch (delayType) {
      case SendDelayDelayType.Day:
        return 'Days'
      case SendDelayDelayType.Wee:
        return 'Weeks'
      case SendDelayDelayType.Mon:
        return 'Months'
    }
  }

  const formattedSpecificDate = () => {
    return month && day
      ? SendDelayOptions.setDateString(month, day, 'MMMM Do, YYYY')
      : null
  }

  const delayDescription = SendDelayOptions.verboseDescription({
    ...state,
    specificDate: moment().format('YYYY-MM-DD'),
    __typename: 'SendDelay',
  } as SendDelayFragment)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value
    const delayNumber: number = parseInt(value.replace(/\D/g, ''), 10)

    setState({
      ...state,
      delayNumber,
      timeType: delayNumber > 0 ? SendDelayTimeType.Aft : SendDelayTimeType.Bef,
    })
  }

  const handleSelectTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value as SendDelayType

    setState({
      ...state,
      type: value,
      delayNumber: value !== SendDelayType.Imm ? 0 : state.delayNumber,
      shouldShowAdvancedControls:
        value === SendDelayType.Imm ? state.shouldShowAdvancedControls : false,
    })
  }

  const handleSelectDelayTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value as SendDelayDelayType

    setState({
      ...state,
      delayType: value,
    })
  }

  const handleDateChange = (
    name: 'month' | 'day',
    event: React.ChangeEvent<HTMLInputElement>,
  ) =>
    setState({
      ...state,
      [name]: event.target.value,
    })

  const toggleControls = () => {
    setState({
      ...state,
      shouldShowAdvancedControls: !state.shouldShowAdvancedControls,
    })
  }

  const handleClose = () => {
    setState({ ...initialState })
    close()
  }

  const handleSubmit = () => {
    const delay: SendDelayFragment = {
      type: type,
      delayType: delayType,
      delayNumber: delayNumber,
      timeType: timeType,
      specificDate: SendDelayOptions.setDateString(month, day),
      __typename: 'SendDelay',
    }

    onSubmit(delay, type)
    close()
  }

  const dateIsToday = useCallback(() => {
    const today = moment()
    const currentDay = today.date()
    const currentMonth = today.month() + 1

    return (
      type === SendDelayType.Spe && month === currentMonth && day === currentDay
    )
  }, [type, month, day])

  return (
    <Dialog
      disableBackdropClick={true}
      open={isOpen}
      onClose={close}
      scroll={'paper'}
      fullWidth={true}
      maxWidth={'xs'}
    >
      <DialogTitle disableTypography={true}>
        {
          <Text type="body" color="primaryHeading">
            Edit Send Delay
          </Text>
        }
      </DialogTitle>
      <DialogContent css={styles.modalContainer}>
        <div css={styles.delayForm}>
          <div css={styles.formContainer}>
            <Text type="caption" color="primaryHeading">
              An immediate send date to a single recipient will insure the card
              is a Heartfelt Prompting Card.
            </Text>
            <Spacer space="x2" />
            <Text type="caption" color="primaryHeading">
              A send date that is in the future will change the card send type
              to be an System Send and may change the price of the card.
            </Text>
            <Spacer space="x2" />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {!isSequence && (
                <TextField
                  select={true}
                  id={'send_delay_options_menu'}
                  label="Send Delay"
                  value={type}
                  onChange={handleSelectTypeChange}
                >
                  {SendDelayOptions.delayTypeOptions.map((option, index) => {
                    return (
                      <MenuItem
                        id={`${option.type.toLowerCase()}_btn`}
                        key={index}
                        value={option.type}
                      >
                        {option.text}
                      </MenuItem>
                    )
                  })}
                </TextField>
              )}
              {type === SendDelayType.Imm && !isSequence && isMultiLine && (
                <button css={styles.optionsButton} onClick={toggleControls}>
                  {shouldShowAdvancedControls
                    ? 'close options'
                    : 'advanced options'}
                </button>
              )}
            </div>
            {shouldShowAdvancedControls && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Text type="body" color="primaryBrand">
                  Schedule a relative delay
                </Text>
                <Text type="caption">
                  *This is relative to the date that the first immediate card in
                  your campaign or order is sent.
                </Text>
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    paddingTop: 15,
                  }}
                >
                  <TextField
                    name="delayNumber"
                    label={`Number of ${getNumberContext()}`}
                    value={delayNumber}
                    onChange={handleChange}
                    css={styles.controlsSelect}
                  />
                  <TextField
                    select={true}
                    label="Interval"
                    value={delayType}
                    onChange={handleSelectDelayTypeChange}
                    css={styles.controlsSelect}
                  >
                    <MenuItem key={1} value={SendDelayDelayType.Day}>
                      Day(s)
                    </MenuItem>
                    <MenuItem key={2} value={SendDelayDelayType.Wee}>
                      Week(s)
                    </MenuItem>
                    <MenuItem key={3} value={SendDelayDelayType.Mon}>
                      Month(s)
                    </MenuItem>
                  </TextField>
                </div>
              </div>
            )}
            {shouldShowAdvancedControls && (
              <>
                <Spacer space="x2" />
                <Text type="caption" alignment="center">
                  {delayDescription}
                </Text>
              </>
            )}
            {type === SendDelayType.Spe && (
              <div style={{ marginTop: '30px' }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingBottom: 8,
                  }}
                >
                  <Text
                    type="body"
                    color="primaryBrand"
                    outset={{ right: 'x2' }}
                  >
                    Choose a Specific Date
                  </Text>
                  {formattedSpecificDate && (
                    <>
                      <Text type="footnote">-- Will send on</Text>
                      <Text
                        type="footnote"
                        weight="bold"
                        outset={{ left: 'x_5' }}
                      >
                        {formattedSpecificDate}
                      </Text>
                    </>
                  )}
                </div>
                {dateIsToday() && (
                  <Text type="caption">
                    *Specific dates set for today will delay for a year. If you
                    want to send your cards today, just select ‘Send
                    Immediately’ from the drop-down menu above.
                  </Text>
                )}
                <div>
                  <TextField
                    select={true}
                    label="Month"
                    style={{ width: 'auto' }}
                    value={month}
                    onChange={handleDateChange.bind(null, 'month')}
                    SelectProps={{
                      MenuProps: {
                        style: {
                          maxHeight: 200,
                        },
                      },
                    }}
                  >
                    {months &&
                      months.map((month, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={month === null ? undefined : index}
                          >
                            {month}
                          </MenuItem>
                        )
                      })}
                  </TextField>
                  <TextField
                    select={true}
                    label="Day"
                    value={day}
                    onChange={handleDateChange.bind(null, 'day')}
                    SelectProps={{
                      MenuProps: {
                        style: {
                          maxHeight: 200,
                        },
                      },
                    }}
                    style={{ marginLeft: 10, width: 'auto' }}
                  >
                    {days &&
                      days(month).map((day, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={day === null ? undefined : index}
                          >
                            {day}
                          </MenuItem>
                        )
                      })}
                  </TextField>
                </div>
              </div>
            )}
            <div css={styles.actionsContainer}>
              <Button
                title={'Cancel'}
                onClick={handleClose}
                css={styles.actionsFirstButton}
              />
              <Button
                id={'send_delay_submit_btn'}
                title={'Submit'}
                onClick={handleSubmit}
                buttonColor={'pink'}
              />
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default SendDelayForm
