import React, { FC } from 'react'
import {
  AnimatedCheckMark,
  Button,
  Div,
  Flex,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { AppLinks } from './appLinks/AppLinks'
import { useActions } from 'src/hooks'

export type UpsaleSuccessProps = {
  title: string
  productTitle: string
  icon: 'smile_card_w_hearts' | 'smile_card_w_heart' | 'smile_card'
  iconColor?: string
  onClose?: () => void
}

export const UpsaleSuccess: FC<UpsaleSuccessProps> = ({
  title,
  productTitle,
  icon = 'smile_card_w_hearts',
  iconColor = '#4AB4D8',
  onClose,
}) => {
  const actions = useActions()
  return (
    <Flex
      position="relative"
      width="100%"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      inset={{ bottom: '40px' }}
    >
      <Flex position="relative" outset={{ bottom: 'x2' }}>
        <Icon size={200} name={icon} color={iconColor} />
        <Div position="absolute" bottom="25px" right="12px">
          <AnimatedCheckMark
            filled={true}
            strokeWidth="default"
            size="medium"
            color="success"
          />
        </Div>
      </Flex>
      <Flex flexDirection="column" justifyContent="center">
        <Text content={title} type="caption" alignment="center" />
        <Text
          content={productTitle}
          type="largeBody"
          weight="extraBold"
          alignment="center"
          color="primaryHeading"
        />
      </Flex>
      <AppLinks />
      <Flex
        columnGap="x2"
        width="100%"
        height="auto"
        justifyContent="flex-end"
        inset={{ top: 'x2', horizontal: 'x6' }}
        alignItems="center"
      >
        <Button
          outlined={true}
          fullWidth={true}
          size="small"
          title="Back to Pricing"
          onClick={onClose}
        />
        <Button
          fullWidth={true}
          size="small"
          title="Send a Card"
          onClick={actions.openCatalog}
        />
      </Flex>
    </Flex>
  )
}
