import { Operation } from '../../../__generated__/globalTypes'

import getGiftCategoriesString from '../GetGiftCategories.graphql'

export type GetGiftCategoriesData = {
  giftCategories: {
    __typename: 'GiftCategory'
    id: string
    name: string
    children: {
      id: string
      name: string
      children: {
        id: string
        name: string
        children: {
          id: string
          name: string
        }[]
      }[]
    }[]
  }[]
}

export type GetGiftCategories = Operation<GetGiftCategoriesData>

export const GetGiftCategories = (): GetGiftCategories => ({
  query: getGiftCategoriesString,
  variables: undefined,
})
