import { useUpdateAccount } from 'src/react_query'
import {
  useActions,
  useCallback,
  useEffect,
  usePersistedUserData,
  useSelector,
} from '.'

const useUpdateSponsorEffect = () => {
  const route = useSelector(state => state.route)
  const { account } = usePersistedUserData()
  const actions = useActions()
  const updateAccountMutation = useUpdateAccount()

  const handleUpdateAccount = useCallback(
    async (sponsorGenealogyIdOrSlug: string) => {
      const {
        updateAccount: { account },
      } = await updateAccountMutation.mutateAsync({
        account: parseInt(sponsorGenealogyIdOrSlug, 10)
          ? { sponsorGenealogyId: sponsorGenealogyIdOrSlug }
          : { sponsorSlug: sponsorGenealogyIdOrSlug },
      })
      actions.updatedAccount(account)
    },
    [updateAccountMutation, actions],
  )

  useEffect(() => {
    if (
      account &&
      account.username === '' &&
      route.args.sponsor &&
      (parseInt(route.args.sponsor, 10)
        ? account.sponsor?.genealogyId !== parseInt(route.args.sponsor, 10)
        : account.sponsor?.storefront?.slug !== route.args.sponsor)
    ) {
      handleUpdateAccount(route.args.sponsor)
    }
  }, [account, route.args.sponsor, handleUpdateAccount])
}

export default useUpdateSponsorEffect
