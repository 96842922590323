import { VerifyPhoneNumberFailureType } from '../../../__generated__/globalTypes'

import verifyPhoneNumberFailureFragmentRawString from '../VerifyPhoneNumberFailureFragment.graphql'

export const verifyPhoneNumberFailureFragmentString = verifyPhoneNumberFailureFragmentRawString

export type VerifyPhoneNumberFailureFragment = {
  __typename: 'VerifyPhoneNumberFailure'
  failureType: VerifyPhoneNumberFailureType
  failureMessage: string
}

export const isVerifyPhoneNumberFailureFragment = (
  fragment: any,
): fragment is VerifyPhoneNumberFailureFragment =>
  fragment &&
  fragment.__typename === 'VerifyPhoneNumberFailure' &&
  (fragment.failureType === 'CODE_EXPIRED' ||
    fragment.failureType === 'INCORRECT_CODE' ||
    fragment.failureType === 'ALREADY_VERIFIED' ||
    fragment.failureType === 'TOO_MANY_ATTEMPTS' ||
    fragment.failureType === 'UNVERIFIED_ACCOUNT_FOUND' ||
    fragment.failureType === 'NO_VERIFIED_ACCOUNTS_FOUND') &&
  typeof fragment.failureMessage === 'string'
