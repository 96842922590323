import React from 'react'

import { Icon } from 'src/chrome'

import { IconTypeOrIconString } from 'src/app/types'

import styles from './fullWindowModal.module.scss'
import { Div, Text } from '@sendoutcards/quantum-design-ui'

type FullWindowModalProps = {
  close?: () => void
  closeIcon?: IconTypeOrIconString
  closeMessage?: string
  closeStyle?: React.CSSProperties
  style?: React.CSSProperties
  isReallyFull?: boolean
}

const FullWindowModal: React.FC<FullWindowModalProps> = props => {
  const {
    children,
    close,
    closeIcon,
    closeMessage,
    closeStyle,
    style,
    isReallyFull,
  } = props
  return (
    <div
      className={`${styles.fullWindow} ${
        isReallyFull ? styles.reallyFull : ''
      }`}
      style={style}
    >
      {!!close && (
        <div
          className={styles.close}
          style={closeStyle}
          onClick={() => close()}
        >
          <div className={styles.icon}>
            <Div display="inline-block">
              <Text type="body" color="primaryHeading">
                {closeMessage || 'Close'}
              </Text>
            </Div>
            &nbsp;
            <Icon icon={closeIcon || 'CLOSE'} size={16} />
          </div>
        </div>
      )}
      {children}
    </div>
  )
}

export default FullWindowModal
