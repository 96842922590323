import React, { CSSProperties } from 'react'
import Checkbox from '@material-ui/core/Checkbox'

import moment from 'moment'
// @src imports
import { Icon, RwdCell, RwdHeader, RwdRow, RwdTable } from 'src/chrome'
import { ContactFragment, OptionalYearDateInput } from 'src/legacy_graphql'

import styles from './contacts.module.scss'
import { Capsule, Text } from '@sendoutcards/quantum-design-ui'
import { H4 } from 'src/styled'
import { ContactReqData } from 'src/contact_request/components/ViewContactRequest'
import { Button } from 'src/design_system/molecules/button/Button'
import { contactRequestUrlFormatter } from 'src/contact_request/helpers/helpers'

const selectAllStyles: CSSProperties = {
  textDecoration: 'underline',
  cursor: 'pointer',
}

const isSoon = (dateObj: OptionalYearDateInput) => {
  const a = moment([moment().year(), dateObj.month - 1, dateObj.day])
  const b = moment()
  const diff = a.diff(b, 'days')
  return diff < 21 && diff >= 0
}

const Address = (props: { contact: ContactFragment }) => {
  const { address1, address2, city, state, postalCode } = props.contact
  return (
    <div className={styles.Address}>
      <Text type="body">{`${address1}`}</Text>
      <Text type="body">{`${address2}`}</Text>
      <Text type="body">{`${city}, ${
        state !== '' ? state + ' ' : ''
      }${postalCode}`}</Text>
    </div>
  )
}

const Helper = (props: { contact: ContactFragment }) => {
  const { birthday, anniversary } = props.contact
  const isBirthdaySoon = !!birthday && isSoon(birthday)
  const isAnniversarySoon = !!anniversary && isSoon(anniversary)
  return (
    <>
      {(!birthday || !anniversary) &&
        false && ( // TODO: decide if we show contacts missing information
          <span style={{ margin: 5 }}>
            <Icon icon={'ALARM'} size={28} color={'#6f8394'} />
          </span>
        )}
      {isBirthdaySoon && (
        <span style={{ margin: 5 }}>
          <Icon
            icon={'CONFETTI'}
            size={28}
            color={'#6db0ff'}
            viewBox={'0 0 31 32'}
          />
        </span>
      )}
      {isAnniversarySoon && (
        <span style={{ margin: 5 }}>
          <Icon
            icon={'CHEERS'}
            size={28}
            color={'#F758A1'}
            viewBox={'0 0 33 32'}
          />
        </span>
      )}
    </>
  )
}

const formatDate = (
  dateObject: {
    day: number | null
    month: number | null
    year: number | null
  } | null,
) =>
  dateObject && dateObject.year
    ? `${dateObject.month}/${dateObject.day}/${dateObject.year}`
    : dateObject
    ? `${dateObject.month}/${dateObject.day}`
    : ``

interface Props {
  isEveryContactSelected?: boolean
  contacts: ContactFragment[]
  goToContact: (id: string) => void
  handleSelectAllClick: () => void
  onSelect: (contact: ContactFragment) => void
  selectedContacts: Record<string, true>
  originalContactId?: string
  onCreateNewContactReq: (contactId: string) => void
  onViewContactReq: (contactReq: ContactReqData) => void
}

const Contacts = (props: Props) => {
  const {
    isEveryContactSelected,
    goToContact,
    handleSelectAllClick,
    onSelect,
    selectedContacts,
    originalContactId,
    onCreateNewContactReq,
    onViewContactReq,
  } = props

  // Move original contact to the start of the list
  const contactSortKey = (contact: ContactFragment) =>
    contact.id === originalContactId ? -1 : 0
  const contacts = originalContactId
    ? [...props.contacts].sort((a, b) => contactSortKey(a) - contactSortKey(b))
    : props.contacts

  return (
    <RwdTable>
      <RwdHeader>
        <th
          id={'select_all_btn'}
          style={selectAllStyles}
          onClick={() => handleSelectAllClick()}
        >
          <Text type="body" color="primaryBrand">
            {isEveryContactSelected ? 'Deselect All' : 'Select All'}
          </Text>
        </th>
        <th>
          <Text type="body" color="primaryBrand">
            Name
          </Text>
        </th>
        <th>
          <Text type="body" color="primaryBrand">
            Company Name
          </Text>
        </th>
        <th>
          <Text type="body" color="primaryBrand">
            Address
          </Text>
        </th>
        <th>
          <Text type="body" color="primaryBrand">
            Upcoming
          </Text>
        </th>
        <th>
          <Text type="body" color="primaryBrand">
            Birthday
          </Text>
        </th>
        <th>
          <Text type="body" color="primaryBrand">
            Anniversary
          </Text>
        </th>
        <th>
          <Text type="body" color="primaryBrand">
            Contact
          </Text>
          <Text type="body" color="primaryBrand">
            Request
          </Text>
        </th>
      </RwdHeader>

      <tbody>
        {contacts.map((contact, i) => {
          const cReq = contact.contactRequest
          return (
            <React.Fragment key={contact.id}>
              <RwdRow
                id={`contact_${i}`}
                style={
                  contact.id in selectedContacts
                    ? { backgroundColor: '#e8e8e8' }
                    : {}
                }
              >
                <RwdCell mapToHeader="Select" className={styles.selectCell}>
                  <div className={styles.contactActions}>
                    <Checkbox
                      id={`checkbox_${i}`}
                      name={contact.lastName}
                      checked={contact.id in selectedContacts}
                      onChange={() => onSelect(contact)}
                      className={styles.checkbox}
                      size="small"
                    />
                  </div>
                </RwdCell>
                <RwdCell mapToHeader="Name">
                  {!contact.isPrimary && <H4>Secondary Contact</H4>}
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={contact ? () => goToContact(contact.id) : () => {}}
                  >
                    <Text type="body">
                      {`${contact.lastName}, ${contact.firstName}`}
                    </Text>
                    {contact.id === originalContactId && (
                      <Capsule
                        title={'original'}
                        backgroundColor={'#54D28B'}
                        fontColor={undefined}
                        boxShadow={'mediumLight'}
                      />
                    )}
                  </div>
                </RwdCell>
                <RwdCell mapToHeader="Company Name">
                  <Text type="body">{contact.company}</Text>
                </RwdCell>
                <RwdCell mapToHeader="Address">
                  <Address contact={contact} />
                </RwdCell>
                <RwdCell mapToHeader="Upcoming">
                  <Helper contact={contact} />
                </RwdCell>
                <RwdCell mapToHeader="Birthday">
                  <Text type="body">{formatDate(contact.birthday)}</Text>
                </RwdCell>
                <RwdCell mapToHeader="Anniversary">
                  <Text type="body">{formatDate(contact.anniversary)}</Text>
                </RwdCell>
                <RwdCell mapToHeader="ContactRequest">
                  <Button
                    title={{
                      content: `${!!cReq ? 'View' : 'Create'} Contact Request`,
                      fontColor: '#fff',
                      fontSize: '12px',
                    }}
                    padding="0.5rem 0.75rem"
                    onClick={() => {
                      if (!!cReq) {
                        onViewContactReq({
                          firstName: contact.firstName,
                          lastName: contact.lastName,
                          reqUrl: contactRequestUrlFormatter(cReq.token),
                          expiresAt: cReq.expiresAt,
                          status: cReq.status,
                        })
                      } else {
                        onCreateNewContactReq(contact.id)
                      }
                    }}
                    fill="#e841b3"
                    borderRadius="6px"
                  />
                </RwdCell>
              </RwdRow>
              <tr className={styles.tableSpacer} />
            </React.Fragment>
          )
        })}
      </tbody>
    </RwdTable>
  )
}

export default Contacts
