import flagFragmentRawString from '../FlagFragment.graphql'

export const flagFragmentString = flagFragmentRawString

export type FlagFragment = {
  __typename: 'Flag'
  id: string
  name: string
}

export const isFlagFragment = (fragment: any): fragment is FlagFragment =>
  fragment &&
  fragment.__typename === 'Flag' &&
  typeof fragment.id === 'string' &&
  typeof fragment.name === 'string'
