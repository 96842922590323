import useSelector from './useSelector'
import { orphanSponsorsGenealogyIds } from 'src/app/constants'
import { AccountFragment } from 'src/graphql/generated/graphql'

type AccountExtras = {
  isOrphan: boolean
}

const useAccount = (): AccountFragment & AccountExtras => {
  const storedData = useSelector(state => state.user)

  const account = storedData.account
  if (!storedData || !account || !storedData.controlData.token) {
    throw new Error('unauthorized')
  }

  const isOrphan = account.sponsor
    ? orphanSponsorsGenealogyIds.find(
        value => account.sponsor && account.sponsor.genealogyId === value,
      ) !== undefined
    : false

  return {
    ...account,
    isOrphan,
  }
}

export default useAccount
