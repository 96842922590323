import React from 'react'
import styles from './responsiveTable.module.scss'

const RwdHeader: React.FC = props => (
  <thead className={styles.rwdHeader}>
    <tr>{props.children}</tr>
  </thead>
)

export default RwdHeader
