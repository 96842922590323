import { Text } from '@sendoutcards/quantum-design-ui'
import React, { CSSProperties } from 'react'
import { Icon } from 'src/chrome'

import styles from './blankCardModal.module.scss'

interface BlankCardModalProps {
  onClick: (id: string) => void
  onClose: () => void
  style: CSSProperties
}

const blankCards = {
  portrait: '3666592',
  landscape: '3666591',
}

const BlankCardModal: React.FC<BlankCardModalProps> = props => {
  const { onClick, onClose, style } = props

  const selectCard = (id: string) => {
    onClose()
    onClick(id)
  }

  return (
    <div className={styles.modal}>
      <div className={styles.modalBody} style={style}>
        <div className={styles.close} onClick={() => onClose()}>
          <Icon icon={'CLOSE'} size={14} />
        </div>
        <div className={styles.modalContent}>
          <Text type="subtitle" color="primaryHeading" alignment="center">
            Build your own card
          </Text>
          <Text type="caption" color="primaryHeading" alignment="center">
            Start from scratch and build the perfect card for your loved one.
            Select orientation below.
          </Text>
          <div className={styles.blankCardOptions}>
            <div
              className={styles.portrait}
              id={'vertical_build_a_card_btn'}
              onClick={() => selectCard(blankCards.portrait)}
            />
            <div
              className={styles.landscape}
              id={'horizontal_build_a_card_btn'}
              onClick={() => selectCard(blankCards.landscape)}
            />
          </div>
        </div>
      </div>
      <div className={styles.modalWindow} onClick={() => onClose()} />
    </div>
  )
}

export default BlankCardModal
