import React from 'react'
import TextField from '@material-ui/core/TextField'
import { Button } from 'src/chrome'
import { useState } from 'src/hooks'
import { NoteFragment, NoteInput } from 'src/legacy_graphql'

import styles from './noteForm.module.scss'
import uuid from 'src/utils/uuid'

type Props = {
  fields: object
  note?: NoteFragment
  onCancel: () => void
  onSubmit: (note: NoteInput) => void
}

const parseCalendarDate = (date: string) => {
  const calendarDateRegex = /(\d{1,2})\/(\d{1,2})\/(\d{4})/
  const result = calendarDateRegex.exec(date)
  if (result) {
    try {
      const month = parseInt(result[1], 10)
      const day = parseInt(result[2], 10)
      const year = parseInt(result[3], 10)
      if (month < 1 || month > 12 || day < 1 || day > 31) {
        return undefined
      } else {
        return { month, day, year }
      }
    } catch {
      return undefined
    }
  } else {
    return undefined
  }
}

const NoteForm: React.FC<Props> = props => {
  const { fields, note, onCancel, onSubmit } = props

  const [text, setText] = useState(note?.text ?? '')
  const [date, setDate] = useState(
    note?.date ? `${note.date.month}/${note.date.day}/${note.date.year}` : '',
  )

  const validDate = parseCalendarDate(date)

  const handleSubmit = () =>
    onSubmit(NoteInput({ id: note?.id ?? uuid(), text, date: validDate }))

  const isDateValid = !(date && !validDate)
  const isValid = text && isDateValid

  return (
    <div className={styles.container}>
      <div className={styles.fields}>
        <TextField
          value={text}
          label={'Note'}
          multiline={true}
          rows={1}
          rowsMax={5}
          onChange={e => setText(e.target.value)}
          {...fields}
        />
        <TextField
          value={date}
          label={'Date (Optional)'}
          placeholder={'MM/DD/YYYY'}
          onChange={e => setDate(e.target.value)}
          error={!isDateValid}
          helperText={'MM/DD/YYYY'}
          {...fields}
        />
      </div>
      <div className={styles.buttons}>
        <Button
          title="Submit"
          buttonColor="pink"
          onClick={handleSubmit}
          disabled={!isValid}
        />
        <Button title="Cancel" onClick={onCancel} />
      </div>
    </div>
  )
}

export default NoteForm
