import { AccessLevel, Route } from 'src/routes/Route'
import { Subroute } from 'src/routes/Subroute'
import { AccountRoute } from 'src/dashboard/routes/AccountRoute'
import { CardHistoryRoute } from 'src/dashboard/routes/CardHistoryRoute'
import { DraftedCardsRoute } from 'src/dashboard/routes/DraftedCardsRoute'
import { DraftedOrdersRoute } from 'src/dashboard/routes/DraftedOrdersRoute'
import { InvitesRoute } from 'src/dashboard/routes/InvitesRoute'
import { NotificationsRoute } from 'src/dashboard/routes/NotificationsRoute'
import { PaymentSettingsRoute } from 'src/dashboard/routes/PaymentSettingsRoute'
import { PurchasePointsRoute } from 'src/dashboard/routes/PurchasePointsRoute'
import { PricingRoute } from 'src/marketing/plans/routes/PricingRoute'
import { LeaderBoardRoute } from './LeaderBoardRoute'
import { ShareSettingsRoute } from './ShareSettingsRoute'
import { DashboardSettingsRoute } from './Settings'

export const DashboardSubroute = Subroute(
  AccountRoute,
  CardHistoryRoute,
  DraftedCardsRoute,
  DraftedOrdersRoute,
  InvitesRoute,
  NotificationsRoute,
  PricingRoute,
  PaymentSettingsRoute,
  PurchasePointsRoute,
  LeaderBoardRoute,
  ShareSettingsRoute,
  DashboardSettingsRoute,
)
export type DashboardSubroute = Subroute<typeof DashboardSubroute>

export const DashboardRoute = Route({
  path: '/dashboard',
  init: (subroute: DashboardSubroute) => ({ subroute }),
  fromPath: () => ({ subroute: AccountRoute() }),
  subroute: DashboardSubroute,
  subrouteIsRequired: true,
  accessLevel: AccessLevel.LOGGED_IN,
  hideSidebar: false,
})
export type DashboardRoute = Route<typeof DashboardRoute>
