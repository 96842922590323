import React from 'react'

import { Button } from 'src/chrome'
import styles from './insertNames.module.scss'

interface InsertNamesProps {
  insertPlaceholder: (placeholder: string) => void
}

const InsertNames: React.FC<InsertNamesProps> = props => {
  const { insertPlaceholder } = props
  return (
    <div className={styles.buttonInserts}>
      <Button
        id="editor-insert-first-name"
        title="First Name"
        onClick={() => insertPlaceholder('First Name')}
      />
      <Button
        id="editor-insert-last-name"
        title="Last Name"
        onClick={() => insertPlaceholder('Last Name')}
      />
      <Button
        id="editor-insert-spouse-name"
        title="Spouse Name"
        onClick={() => insertPlaceholder('Spouse Name')}
      />
      <Button
        id="editor-insert-first-name-spouse-name"
        title={'First Name & Spouse Name'}
        onClick={() => insertPlaceholder('First Name & Spouse Name')}
      />
    </div>
  )
}

export default InsertNames
