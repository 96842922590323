import { Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { IconType } from 'src/design_system/atoms/icons/types'
import { useSelector } from 'src/hooks'

export type SelectedItemCardProps = {
  id: string
  title: string
  description?: { content: string; color: string }[]
  price: string
  icon: IconType
  recurrence?: string
  backgroundColor?: string
  itemStatus?: string
}

export const SelectedItemCard = (props: SelectedItemCardProps) => {
  const {
    icon,
    title,
    description,
    price,
    recurrence,
    backgroundColor,
    itemStatus,
  } = props

  const isMobile = useSelector(state => state.window.width <= 850)

  return (
    <Flex
      flexDirection="row"
      width="100%"
      borderRadius="default"
      alignItems="center"
      inset={{ vertical: 'x2', horizontal: 'x4' }}
      boxShadow="light"
      style={{ borderTop: `4px solid ${backgroundColor}` }}
      position="relative"
      columnGap="x1"
    >
      {itemStatus && (
        <Div
          position="absolute"
          top="0"
          right="0"
          style={{
            background: backgroundColor,
            borderRadius: '0px 8px 0px 20px',
            padding: '4px 20px',
          }}
        >
          <Text
            type="caption"
            content={itemStatus}
            color="inverseHeading"
            weight="semiBold"
          />
        </Div>
      )}
      <Flex outset={{ right: 'x2' }}>
        <Icon size={40} color={backgroundColor} name={icon} />
      </Flex>
      <Flex
        flexDirection={isMobile ? 'column' : 'row'}
        columnGap="x1"
        alignItems="center"
        justifyContent={isMobile ? 'center' : undefined}
        width={'100%'}
      >
        <Flex flexDirection="column">
          <Text
            alignment="left"
            type="largeBody"
            weight="bold"
            color="primaryHeading"
            content={title}
            lineHeight={1.1}
            whiteSpace="nowrap"
          />
          {description && (
            <Flex>
              {description.map((content, index) => (
                <Text
                  key={index}
                  type="caption"
                  color="primaryBody"
                  alignment="left"
                  content={content.content}
                  style={{ color: content.color }}
                />
              ))}
            </Flex>
          )}
        </Flex>
        <Flex style={{ marginLeft: 'auto' }}>
          <Text
            type="largeBody"
            weight="semiBold"
            color="primaryBody"
            content={price}
          />
          {recurrence && (
            <Text
              type="caption"
              content={`/${recurrence}`}
              outset={{ left: 'x_25' }}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}
