import { SignInRoute } from './routes/SignIn'
import { LoginForm } from 'src/saleor/components/account/checkoutModal/LoginForm'
import { useActions } from 'src/hooks'

type SignInProps = {
  route?: SignInRoute
}

const SignIn: React.FC<SignInProps> = props => {
  const actions = useActions()

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: '80px',
        height: '100dhv',
        width: '100dwv',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div style={{ margin: 'auto', minWidth: '300px', maxWidth: '600px' }}>
        <LoginForm
          handleNotAUserYet={actions.openSignUp}
          onComplete={() => actions.openAccount()}
        />
      </div>
    </div>
  )
}

export default SignIn
