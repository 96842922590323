import { Operation } from 'src/legacy_graphql'

export const operationName = <Variables>(
  operation: Operation<unknown, Variables>,
) => /(?:query|mutation) (\w*)/.exec(operation.query)?.[1] ?? operation.query

export const prettyPrintOperation = <Variables>(
  operation: Operation<unknown, Variables>,
) => {
  const args = operation.variables
    ? Object.entries(operation.variables)
        .filter(([, value]) => value !== undefined)
        .map(([key, value]) => `${key}: ${JSON.stringify(value)}`)
        .join(', ')
    : ''
  return `${operationName(operation)}(${args})`
}
