import React from 'react'

import { SimpleLoadingSpinner } from 'src/chrome'
import * as SendDelayOptions from 'src/orders/sendDelayOptions'

import { CampaignLineFragment, Maybe } from 'src/graphql/generated/graphql'
import { useState } from 'src/hooks'
import { sharedStyles, styles } from './styles'
import { Div, Spacer, Text } from '@sendoutcards/quantum-design-ui'

export type CampaignLine = {
  card?: Maybe<{
    id: string
    isHorizontal: boolean
    frontPreviewUrl: string
  }>
  giftVariation?: Maybe<{
    defaultImageUrl: string
  }>
}

interface Props {
  id?: string
  isActive?: boolean
  title?: string
  image?: string
  line?: CampaignLineFragment
  lines?: CampaignLine[]
  total?: string
  isCampaign?: boolean
  buttons?: {
    primaryButton: {
      id?: string
      label: string
      onClick: () => void
    }
    secondaryButton?: {
      id?: string
      label: string
      onClick: () => void
    }
  }
}

type LineImage = {
  image?: string
  isHorizontal?: boolean
}

const getFrontImage = (line: CampaignLine) => {
  if (!line?.card) {
    return {}
  }
  const card = line.card
  return {
    image: card.frontPreviewUrl,
    isHorizontal: card.isHorizontal,
  }
}

const CampaignLineCard: React.FC<Props> = props => {
  const {
    id,
    isActive,
    image,
    line,
    lines,
    buttons,
    title,
    total,
    isCampaign,
  } = props
  const [isLoading, setIsLoading] = useState(true)
  const linesLength = lines?.length ?? 0

  const frontImage: LineImage =
    isCampaign && !lines
      ? { image }
      : lines
      ? getFrontImage(lines[0])
      : line
      ? getFrontImage({
          card: line.card ?? null,
          giftVariation: line.giftVariation ?? null,
        })
      : {}

  const handleSecondaryButtonClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.stopPropagation()
    buttons?.secondaryButton?.onClick()
  }

  return (
    <div id={id} css={[sharedStyles.itemContainer, styles.itemContainer]}>
      <div>
        <div
          css={{
            ...styles.itemView,
            ...(isActive && styles.activeItemView),
          }}
        >
          <img
            src={frontImage?.image ?? ''}
            style={{ display: 'none' }}
            alt="Loading campaign line card."
            onLoad={() => setIsLoading(false)}
          />
          <div
            css={{
              ...styles.card,
              ...(frontImage?.isHorizontal && styles.horizontalCard),
            }}
            style={{
              backgroundImage: `url(${frontImage?.image ?? ''})`,
            }}
          >
            {line?.giftVariation && (
              <div
                css={styles.giftThumbnail}
                style={{
                  backgroundImage: `url(${line.giftVariation.defaultImageUrl})`,
                }}
              />
            )}
          </div>
        </div>
        <section css={styles.itemDetails}>
          <div css={styles.category} id={`send_delay_type_${props.id}`}>
            <Text type="caption" color="accent" alignment="center">
              {isCampaign && !lines
                ? 'Product'
                : line
                ? SendDelayOptions.ribbonDescription(line.sendDelay)
                : `${linesLength} Touch${linesLength > 1 ? 'es' : ''}`}
            </Text>
          </div>
          {buttons && (
            <div css={styles.buttonContainer}>
              <div
                id={`view_items_btn_${props.id}`}
                css={styles.button}
                style={buttons.secondaryButton ? {} : { width: '100%' }}
                onClick={() => buttons.primaryButton.onClick()}
              >
                <Text type="caption" color="primaryHeading" alignment="center">
                  {buttons.primaryButton.label}
                </Text>
              </div>
              <div
                id={
                  buttons.secondaryButton?.id ?? `open_campaign_btn_${props.id}`
                }
                css={styles.button}
                style={buttons.secondaryButton ? {} : { display: 'none' }}
                onClick={handleSecondaryButtonClick}
              >
                <Text type="caption" color="primaryHeading" alignment="center">
                  {buttons.secondaryButton?.label}
                </Text>
              </div>
            </div>
          )}
          <Div overflow="hidden" maxHeight="x9">
            <Text type="largeBody" color="primaryHeading" weight="bold">
              {title}
            </Text>
          </Div>
          {total && isCampaign && (
            <>
              <Spacer space="x_5" />
              <Text type="body">{`Price: ${total}`}</Text>
              <Spacer space="x2" />
            </>
          )}
          {line && (
            <div css={styles.description}>
              {line.giftVariation && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Text type="body">Gift</Text>
                  <Spacer space="x2" orientation="horizontal" />
                  <Text type="caption">{line.giftVariation.name}</Text>
                </div>
              )}
            </div>
          )}
        </section>
        {isLoading && (
          <div css={styles.loader}>
            <SimpleLoadingSpinner />
          </div>
        )}
      </div>
    </div>
  )
}

export default CampaignLineCard
