import React, { FC } from 'react'
import { SVGProps } from './types'

export const SmileCard: FC<SVGProps> = ({ size, color, onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 37 33"
      width={size}
      height={size}
      onClick={onClick}
    >
      <path
        fill={color}
        d="M36.212 11.11a.404.404 0 0 0-.046-.15c-.01-.02-.032-.038-.046-.056a1.938 1.938 0 0 0-1.213-.978c-.057-.043-.11-.086-.182-.129l-1.483-.928V4.568a2.204 2.204 0 0 0-2.202-2.202h-8.208L19.662.384C19.254.128 18.71 0 18.169 0c-.54 0-1.081.128-1.494.384l-3.17 1.982H5.037a2.204 2.204 0 0 0-2.202 2.202v4.475c-.8.502-1.387.872-1.405.886-.63.434-.992.683-1.195.96-.022.026-.046.047-.064.079-.004.01-.004.018-.007.028-.121.21-.164.449-.164.797v18.268c0 1.071.868 1.943 1.942 1.943h32.456c1.07 0 1.942-.868 1.942-1.943V11.793c0-.238-.046-.47-.128-.68v-.003Zm-1.466-.31c.14.05.257.132.363.228l-1.867.996V9.87l1.504.932ZM17.144 1.129c.256-.16.63-.253 1.028-.253s.772.093 1.028.253l1.974 1.234H15.17l1.975-1.234ZM3.718 4.564c0-.729.594-1.32 1.32-1.32h26.005c.73 0 1.32.595 1.32 1.32v7.542c0 .125.053.235.135.317l-11.75 6.279a.453.453 0 0 0-.153.142c-1.516-.818-3.213-.829-4.743-.046a.45.45 0 0 0-.117-.096l-11.982-6.34c.01-.341-.003-.853-.039-.925V4.564h.004Zm-1.953 6.201 1.07-.672v1.473c0 .05.015.177.036.33l-1.63-.864c.146-.128.32-.231.524-.267Zm33.697 19.293a1.06 1.06 0 0 1-1.06 1.06H1.941a1.06 1.06 0 0 1-1.06-1.06V11.836l14.134 7.481c-.025.018-.05.032-.074.05l-11.57 9.065a.441.441 0 0 0 .545.694l11.565-9.062c1.687-1.298 3.697-1.298 5.376 0l11.569 9.065a.437.437 0 0 0 .27.093.441.441 0 0 0 .27-.786L21.396 19.37l-.01-.007 14.073-7.521v18.218l.004-.003Z"
      />
      <path
        fill={color}
        d="M14.209 10.616a1.761 1.761 0 1 0 0-3.522 1.761 1.761 0 0 0 0 3.522ZM22.131 10.616a1.761 1.761 0 1 0 0-3.522 1.761 1.761 0 0 0 0 3.522ZM21.174 11.608h-5.909c-1.255 0-1.953 1.42-1.17 2.38 2.21 2.697 5.824 2.697 8.03 0l.134-.163c.73-.893.082-2.213-1.088-2.213l.003-.004Zm-3.226 3.522s-1.761 0-2.643-.882c0 0 2.472-2.643 5.638 0 0 0-.356.882-2.999.882h.004Z"
      />
    </svg>
  )
}
