import { Operation } from '../../../__generated__/globalTypes'

import {
  DetailedGroupFragment,
  detailedGroupFragmentString,
} from '../../fragments/__generated__/DetailedGroupFragment'

import { optionalYearDateFragmentString } from '../../fragments/__generated__/OptionalYearDateFragment'

import { groupFragmentString } from '../../fragments/__generated__/GroupFragment'

import { contactFragmentString } from '../../fragments/__generated__/ContactFragment'

import getGroupRawString from '../GetGroup.graphql'

const getGroupString = [
  getGroupRawString,
  detailedGroupFragmentString,
  optionalYearDateFragmentString,
  groupFragmentString,
  contactFragmentString,
].join('\n\n')

export type GetGroupData = {
  group: DetailedGroupFragment
}

export type GetGroupVariables = {
  id: string
}

export type GetGroup = Operation<GetGroupData, GetGroupVariables>

export const GetGroup = ({ id }: GetGroupVariables): GetGroup => ({
  query: getGroupString,
  variables: {
    id,
  },
})
