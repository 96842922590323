import { Div, Flex, Separator, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { Widget } from 'src/dashboard/components/Widgets/Widget'
import { useGetCurrentWeekDays } from 'src/dashboard/hooks/useCurrentWeekDays'
import { ProgressBar } from 'src/design_system/components/ProgressBar/ProgressBar'
import { SentCardSendType } from 'src/graphql/generated/graphql'

import { useActions, useContainerWidth } from 'src/hooks'
import { useSentCardsCount } from 'src/react_query'

export const SendingMetricsWidget = () => {
  const actions = useActions()
  const currentWeek = useGetCurrentWeekDays()
  const [containerRef, containerWidth] = useContainerWidth()
  const shouldShrink = containerWidth <= 301

  // Ensure the hook useQueries is called unconditionally
  const startDate = currentWeek.length > 0 ? currentWeek[0].isoDate : ''
  const endDate = currentWeek.length > 0 ? currentWeek[6].isoDate : ''

  const { data, isLoading } = useSentCardsCount(
    { startDate, endDate, sendType: SentCardSendType.Heartfelt },
    { enabled: startDate && endDate ? true : false },
  )

  // Early return if currentWeek is not populated yet
  if (currentWeek.length === 0) {
    return null // or a loading indicator
  }

  const resolveCardNumbers = (day: string): number => {
    const sentCard = data?.sentCards.sentCards.find(dateInfo => {
      // Normalize both dates to ISO string format
      const parsedDate1 = new Date(dateInfo.date).toISOString().split('T')[0]
      const parsedDate2 = new Date(day).toISOString().split('T')[0]
      return parsedDate1 === parsedDate2
    })
    return sentCard ? sentCard.cardsSent : 0
  }

  const weekTotals = data?.sentCards.count ?? 0
  const currentDay = currentWeek.find(day => day.isToday)
  const todayTotals = currentDay ? resolveCardNumbers(currentDay.isoDate) : 0

  return (
    <Widget
      header={{
        title: 'Weekly Heartfelt Cards Sent',
        tooltip:
          'This section tracks the amount of heartfelt cards you’ve sent each week.',
        action: {
          title: 'Send a Card',
          onClick: () => actions.openCatalog(),
        },
      }}
      isLoading={isLoading}
      isEmpty={false}
      emptyState={{
        title: 'Nothings featured',
        description: 'Were working on making things actually cool',
        icon: 'drafts',
        contentMaxWidth: '280px',
        action: {
          title: 'Send a card today',
          onClick: () => alert('Method called from DraftWidget.tsx'),
        },
      }}
      paddingOverride="8px 16px 16px"
    >
      <Div
        ref={containerRef}
        display="flex"
        width="100%"
        flexDirection="column"
      >
        <Flex
          id="chart_container"
          flexDirection="row"
          justifyContent="space-between"
        >
          {currentWeek.map(day => {
            const cardCount = resolveCardNumbers(day.isoDate)
            const cardCap = 10 // TODO: Wire this up to the account subscription card cap
            const percentage = (cardCount / cardCap) * 100
            return (
              <Flex
                key={day.dateString}
                flexDirection="column"
                height="150px"
                alignItems="center"
                style={{ transform: shouldShrink ? 'scale(.9)' : '' }}
              >
                <ProgressBar
                  metricCount={`${cardCount}`}
                  percentage={percentage}
                  isVertical={true}
                  barHeight="40px"
                  barColor="#F3F4F6"
                  fillColor="#bcd8fc"
                  border={day.isToday ? '2.5px solid #bcd8fc' : 'none'}
                />
                <Text
                  content={day.shortDayOfWeek}
                  type="caption"
                  weight={day.isToday ? 'bold' : 'regular'}
                  color={day.isToday ? 'primaryHeading' : 'primaryBody'}
                />
              </Flex>
            )
          })}
        </Flex>
        <Flex
          id="send_totals"
          width="100%"
          justifyContent="center"
          alignItems="center"
          backgroundColor={'#EFF6FF'}
          inset="x1"
          outset={{ top: 'x_75' }}
          style={{
            borderRadius: 9,
            transform: shouldShrink ? 'scale(.9)' : '',
          }}
        >
          <Flex outset={{ right: 'x1' }}>
            <Text
              type="caption"
              content="Today Totals:"
              color="primaryBody"
              outset={{ right: 'x_75' }}
            />
            <Text
              type="caption"
              content={`${todayTotals}`}
              color="primaryHeading"
              weight="bold"
            />
          </Flex>
          <Separator orientation="vertical" />
          <Flex outset={{ left: 'x1' }}>
            <Text
              type="caption"
              content="Week Totals:"
              color="primaryBody"
              outset={{ right: 'x_75' }}
            />
            <Text
              type="caption"
              content={`${weekTotals}`}
              color="primaryHeading"
              weight="bold"
            />
          </Flex>
        </Flex>
      </Div>
    </Widget>
  )
}
