import { Operation } from '../../../__generated__/globalTypes'

import {
  CardCategoryFragment,
  cardCategoryFragmentString,
} from '../../fragments/__generated__/CardCategoryFragment'

import { sendableCardImageFragmentString } from '../../fragments/__generated__/SendableCardImageFragment'

import { minimalSendableCardFragmentString } from '../../fragments/__generated__/MinimalSendableCardFragment'

import getCardCategoryRawString from '../GetCardCategory.graphql'

const getCardCategoryString = [
  getCardCategoryRawString,
  cardCategoryFragmentString,
  sendableCardImageFragmentString,
  minimalSendableCardFragmentString,
].join('\n\n')

export type GetCardCategoryData = {
  cardCategory: CardCategoryFragment
}

export type GetCardCategoryVariables = {
  id: string
}

export type GetCardCategory = Operation<
  GetCardCategoryData,
  GetCardCategoryVariables
>

export const GetCardCategory = ({
  id,
}: GetCardCategoryVariables): GetCardCategory => ({
  query: getCardCategoryString,
  variables: {
    id,
  },
})
