import { Div, Flex, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { Button } from 'src/design_system/components/Button/Button'
import { useContainerWidth, usePlansSubscriptions } from 'src/hooks'

type SubscriptionWidgetProps = {
  onClick: () => void
  maxWidth?: string
}
const formatDate = (date?: string) => {
  if (!date) return
  const [datePart] = date.split('T')
  const [year, month, day] = datePart.split('-')
  return `${month}/${day}/${year}`
}

export const SubscriptionWidget: React.FC<SubscriptionWidgetProps> = ({
  onClick,
  maxWidth,
}) => {
  const { newPlan, currentSubscription, currentPlan } = usePlansSubscriptions()
  const hasSubscription = currentSubscription?.isRenewable
  const isSetToCancel = currentSubscription?.isSetToCancel
  const isSetToChange = !!newPlan?.id

  const isAccountBasic = currentPlan?.id === '1'
  const subscriptionName = isAccountBasic
    ? '$3 per Greeting Card'
    : currentPlan?.title

  const [containerRef, containerWidth] = useContainerWidth()
  const shouldShrink = containerWidth <= 195

  return (
    <Div
      ref={containerRef}
      display="flex"
      width="100%"
      flexDirection="column"
      overflow="hidden"
      position="relative"
      backgroundColor={'foreground'}
      borderRadius="default"
      maxWidth={maxWidth ?? '350px'}
      inset={{ horizontal: shouldShrink ? 'x2' : 'x4' }}
      alignItems="center"
      style={{ borderRadius: 24, borderTop: '6px solid #4AB4D8' }}
    >
      <Flex
        id="content_block"
        flexDirection="column"
        alignItems="flex-start"
        width="100%"
        outset={{ top: 'x2_5' }}
      >
        <Text
          type="body"
          content="My Membership"
          alignment="left"
          inset={{ bottom: 'x1' }}
          style={{
            fontSize: shouldShrink ? '.925rem' : '1.125rem',
            fontWeight: 700,
            fontFamily: 'Montserrat, san serif',
            color: '#404040',
          }}
        />
        <Flex
          id="icon_text"
          alignItems="center"
          columnGap="x1"
          outset={{ bottom: 'x2' }}
          flexDirection={shouldShrink ? 'column' : 'row'}
        >
          <Icon name="smile_card_w_heart" size={28} color="#67E5DD" />
          <Text
            type="caption"
            weight="bold"
            color="primaryBody"
            content={
              hasSubscription ? 'Monthly Membership Status' : 'No Membership'
            }
            alignment={shouldShrink ? 'center' : 'left'}
            lineHeight={1.5}
          />
        </Flex>
        <Text
          type="caption"
          color="primaryHeading"
          weight="bold"
          content={
            hasSubscription
              ? subscriptionName
              : 'Get our best pricing possible.'
          }
          style={{ fontSize: shouldShrink ? '.7rem' : '.9rem' }}
          lineHeight={1.5}
        />
        <Spacer space="x_25" />
        {hasSubscription && (
          <Flex>
            <Text
              type="caption"
              content={
                isSetToCancel
                  ? 'Cancels on:'
                  : isSetToChange
                  ? 'Changes on:'
                  : 'Renews on:'
              }
              weight="semiBold"
            />
            <Text
              type="caption"
              content={formatDate(currentSubscription?.currentPeriodEnd)}
              outset={{ left: 'x_25' }}
              weight="semiBold"
              style={{ color: 'rgb(43 215 251)' }}
            />
          </Flex>
        )}
      </Flex>
      <Div
        style={{
          backgroundRepeat: 'no-repeat',
          height: 185,
          width: 200,
          backgroundSize: 'contain',
          backgroundImage:
            'url(https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/cards.png)',
          transform: shouldShrink ? 'scale(.85)' : '',
        }}
      />
      <Div
        position="absolute"
        bottom="x3"
        left={'50%'}
        width="85%"
        style={{ transform: 'translateX(-50%)' }}
      >
        <Button
          background="linear-gradient(133.66deg, #4AB5D9 8.49%, #67E5DD 115.99%), #00AEEF;"
          title={hasSubscription ? 'Review Subscription' : 'Unlock Memberships'}
          onClick={onClick}
          width="100%"
          textSize={shouldShrink ? '10px' : '1rem'}
          whiteSpace="nowrap"
        />
      </Div>
    </Div>
  )
}
/* Buttons */
