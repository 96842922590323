import React from 'react'

import { OrderWithPartialLines } from 'src/redux/reducers/orders'
import { Dialog, Icon, Invoice } from 'src/chrome'

import styles from './invoiceModal.module.scss'
import { DialogContent } from '@material-ui/core'

type InvoiceModalProps = {
  onClose: () => void
  isOpen: boolean
  order: OrderWithPartialLines | undefined
}

const InvoiceModal: React.FC<InvoiceModalProps> = props => {
  const { onClose, isOpen, order } = props

  return (
    <Dialog
      disableBackdropClick={false}
      open={isOpen}
      onClose={onClose}
      scroll={'paper'}
    >
      <DialogContent className={styles.dialog}>
        <div className={styles.modalContainer}>
          <span className={styles.closeButton} onClick={() => onClose()}>
            <Icon icon={'CLOSE'} color={'#6f8394'} size={18} />
          </span>
          {order && <Invoice order={order} />}
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default InvoiceModal
