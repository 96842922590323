import React from 'react'

import { InvalidMagicLinkRoute } from 'src/routes/InvalidMagicLinkRoute'
import { Button, UiCard } from 'src/chrome'

import styles from './invalidMagicLink.module.scss'
import { useActions } from 'src/hooks'
import { Text } from '@sendoutcards/quantum-design-ui'

type InvalidMagicLinkProps = {
  route: InvalidMagicLinkRoute
}

const InvalidMagicLink: React.FC<InvalidMagicLinkProps> = () => {
  const actions = useActions()
  return (
    <div className={styles.configWindow}>
      <UiCard title={'Sign in link error'}>
        <div>
          <div>
            <Text type="body">
              Sorry! It looks like you have used an invalid or stale link. Links
              expire after 30 minutes.
            </Text>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '35px',
            }}
          >
            <Button
              title={'Go to login'}
              onClick={() => actions.openCatalog()}
            />
          </div>
        </div>
      </UiCard>
    </div>
  )
}

export default InvalidMagicLink
