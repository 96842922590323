import { Maybe, CardSendType } from '../../../__generated__/globalTypes'

import { LineFragment, isLineFragment } from './LineFragment'

import { AddressFragment, isAddressFragment } from './AddressFragment'

import {
  ItemizedCostFragment,
  isItemizedCostFragment,
} from './ItemizedCostFragment'

import { Error, isError } from './Error'

import detailedOrderFragmentRawString from '../DetailedOrderFragment.graphql'

export const detailedOrderFragmentString = detailedOrderFragmentRawString

export type DetailedOrderFragment = {
  __typename: 'Order'
  id: string
  isSendable: boolean
  createdAt: string
  lines: LineFragment[]
  contacts: {
    __typename: 'Contact'
    id: string
  }[]
  groups: {
    __typename: 'Group'
    id: string
    name: string
    members: {
      __typename: 'Contact'
      id: string
    }[]
  }[]
  returnAddress: Maybe<AddressFragment>
  campaign: Maybe<{
    __typename: 'Campaign'
    id: string
    name: string
  }>
  cost: ItemizedCostFragment
  warnings: Error[]
  invalidContacts: {
    __typename: 'Contact'
    id: string
  }[]
  invalidSendDelayContacts: {
    __typename: 'Contact'
    id: string
  }[]
  isSent: boolean
  sendType: CardSendType
  isBulk: boolean
  bulkUpgradeShippingAddress: Maybe<AddressFragment>
  bulkUpgradeCardQty: Maybe<number>
}

export const isDetailedOrderFragment = (
  fragment: any,
): fragment is DetailedOrderFragment =>
  fragment &&
  fragment.__typename === 'Order' &&
  typeof fragment.id === 'string' &&
  typeof fragment.isSendable === 'boolean' &&
  typeof fragment.createdAt === 'string' &&
  Array.isArray(fragment.lines) &&
  fragment.lines
    .slice(0, 5)
    .reduce(
      (accum: any, next: any) => accum && (isLineFragment(next) as boolean),
      true,
    ) &&
  Array.isArray(fragment.contacts) &&
  fragment.contacts
    .slice(0, 5)
    .reduce(
      (accum: any, next: any) =>
        accum &&
        next &&
        next.__typename === 'Contact' &&
        typeof next.id === 'string',
      true,
    ) &&
  Array.isArray(fragment.groups) &&
  fragment.groups
    .slice(0, 5)
    .reduce(
      (accum: any, next: any) =>
        accum &&
        next &&
        next.__typename === 'Group' &&
        typeof next.id === 'string' &&
        typeof next.name === 'string' &&
        Array.isArray(next.members) &&
        next.members
          .slice(0, 5)
          .reduce(
            (accum: any, next: any) =>
              accum &&
              next &&
              next.__typename === 'Contact' &&
              typeof next.id === 'string',
            true,
          ),
      true,
    ) &&
  (fragment.returnAddress === null ||
    (isAddressFragment(fragment.returnAddress) as boolean)) &&
  (fragment.campaign === null ||
    (fragment.campaign &&
      fragment.campaign.__typename === 'Campaign' &&
      typeof fragment.campaign.id === 'string' &&
      typeof fragment.campaign.name === 'string')) &&
  (isItemizedCostFragment(fragment.cost) as boolean) &&
  Array.isArray(fragment.warnings) &&
  fragment.warnings
    .slice(0, 5)
    .reduce(
      (accum: any, next: any) => accum && (isError(next) as boolean),
      true,
    ) &&
  Array.isArray(fragment.invalidContacts) &&
  fragment.invalidContacts
    .slice(0, 5)
    .reduce(
      (accum: any, next: any) =>
        accum &&
        next &&
        next.__typename === 'Contact' &&
        typeof next.id === 'string',
      true,
    ) &&
  Array.isArray(fragment.invalidSendDelayContacts) &&
  fragment.invalidSendDelayContacts
    .slice(0, 5)
    .reduce(
      (accum: any, next: any) =>
        accum &&
        next &&
        next.__typename === 'Contact' &&
        typeof next.id === 'string',
      true,
    ) &&
  typeof fragment.isSent === 'boolean' &&
  (fragment.sendType === 'HEARTFELT' || fragment.sendType === 'SYSTEM') &&
  typeof fragment.isBulk === 'boolean' &&
  (fragment.bulkUpgradeShippingAddress === null ||
    (isAddressFragment(fragment.bulkUpgradeShippingAddress) as boolean)) &&
  (fragment.bulkUpgradeCardQty === null ||
    typeof fragment.bulkUpgradeCardQty === 'number')
