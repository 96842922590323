import { compact, isCardCostFree, recipientCount } from 'src/helpers'
import {
  DetailedOrderFragment,
  SendDelayType,
} from 'src/graphql/generated/graphql'

const validateFreeSend = (order: DetailedOrderFragment) =>
  isCardCostFree(order.cost.entries)
    ? compact(
        recipientCount(order) > 1 &&
          'Cards with more than one recipient do not qualify as a FREE send.',
        order.lines.length === 1 &&
          order.lines[0].sendDelay.type !== SendDelayType.Imm &&
          'Cards with a send delay do not qualify as a FREE send.',
        order.lines.length > 1 &&
          recipientCount(order) === 1 &&
          'Multiple cards to a single recipient do not qualify as a FREE send.',
      )
    : []

export default validateFreeSend
