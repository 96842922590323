import { ImageFragment, isImageFragment } from './ImageFragment'

import userImageFragmentRawString from '../UserImageFragment.graphql'

export const userImageFragmentString = userImageFragmentRawString

export type UserImageFragment = ImageFragment & {
  createdAt: string
}

export const isUserImageFragment = (
  fragment: any,
): fragment is UserImageFragment =>
  (isImageFragment(fragment) as boolean) &&
  typeof fragment.createdAt === 'string'
