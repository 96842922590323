import React from 'react'
import moment from 'moment'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
// @src imports
import { Button, Confirm, Icon } from 'src/chrome'
import { days, months } from 'src/helpers/dates'
import * as SendDelayOptions from 'src/orders/sendDelayOptions'
import { getPaperTypes } from 'src/app/constants'
import {
  CampaignFragment,
  CardPaperType,
  SendDelayFragment,
  SendDelayType,
} from 'src/graphql/generated/graphql'

import { drawerStyles, styles } from './styles'
import { Collapse } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { useActions, useMutations, useState } from 'src/hooks'
import { SendDelayForm } from 'src/orders/components'
import { Div, Spacer, Text, TextProps } from '@sendoutcards/quantum-design-ui'
import { useUpdateCard } from 'src/react_query'

const paperTypes: { [key in CardPaperType]: 'Standard' | 'Pearl' | 'Satin' } = {
  STD: 'Standard',
  PRE: 'Pearl',
  HVY: 'Satin',
}

const listItemStyle = (active: boolean): TextProps => {
  return {
    type: 'caption',
    color: active ? 'primaryBrand' : 'primaryBody',
    weight: active ? 'bold' : 'regular',
  }
}

interface Props {
  campaign: CampaignFragment
  lineIndex: number
  onClose: () => void
  onReplaceCard: (cardId: string) => void
  onUpdateCampaign: (campaign: CampaignFragment) => void
  onUpdatePaperType: () => void
  onEditCard: (cardId: string, isNewEditorCard: boolean) => void
}

const CampaignDrawer: React.FC<Props> = props => {
  const {
    campaign,
    lineIndex,
    onClose,
    onReplaceCard,
    onEditCard,
    onUpdateCampaign,
    onUpdatePaperType,
  } = props

  const actions = useActions()
  const mutations = useMutations()
  const updateCardMutation = useUpdateCard()

  // Menu visibility control
  const [isSendDelayMenuOpen, setIsSendDelayMenuOpen] = useState(false)
  const [isSendDelayModalOpen, setIsSendDelayModalOpen] = useState(false)
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)
  const [isPaperOptionsMenuOpen, setIsPaperOptionsMenuOpen] = useState(false)
  const [isGiftMenuOpen, setIsGiftMenuOpen] = useState(true)

  const [
    isSendOnSpecificDateMenuOpen,
    setIsSendOnSpecificDateMenuOpen,
  ] = useState(false)

  const line = campaign.lines[lineIndex]

  const { month, day } = SendDelayOptions.monthDayDefaults(line.sendDelay)
  const [delayMonth, setDelayMonth] = useState(month)
  const [delayDay, setDelayDay] = useState(day)

  const { card } = line

  const handleAddGift = () => {
    actions.editingCampaign(campaign, lineIndex)
    actions.openGiftStore()
  }

  const handleUpdateSendDelay = async (
    delay: SendDelayFragment,
    type: SendDelayType,
    date: string = moment().format('YYYY-MM-DD'),
  ) =>
    onUpdateCampaign({
      ...campaign,
      lines: campaign.lines.map(cLine => {
        if (cLine.card?.id === line?.card?.id) {
          return {
            ...cLine,
            sendDelay:
              type !== SendDelayType.Spe
                ? delay
                : {
                    ...delay,
                    specificDate: date,
                  },
          }
        }
        return cLine
      }),
    })

  const handleDate = (month: number, day: number) =>
    handleUpdateSendDelay(
      SendDelayOptions.updateDelay(SendDelayType.Spe),
      SendDelayType.Spe,
      SendDelayOptions.setDateString(month, day),
    )

  const handleRemoveGift = () =>
    onUpdateCampaign({
      ...campaign,
      lines: campaign.lines.map(cLine => {
        if (cLine.card?.id === line?.card?.id) {
          return {
            ...cLine,
            giftVariation: null,
          }
        }
        return cLine
      }),
    })

  const handleRemoveLine = () => {
    if (campaign && campaign.lines.length > 1) {
      onUpdateCampaign({
        ...campaign,
        lines: campaign.lines.filter(currentLine => currentLine !== line),
      })

      setIsRemoveModalOpen(false)
      onClose()
    } else {
      mutations.deleteCampaign({ id: campaign.id })
      actions.openCampaigns()
    }
  }

  const handleUpdatePaperType = async (paperType: CardPaperType) => {
    const cardId = line.card?.id
    if (!cardId) {
      throw Error('No active card')
    }

    await updateCardMutation.mutateAsync({
      card: {
        id: cardId,
        paperType,
      },
    })

    onUpdatePaperType()
  }

  if (!card) return null

  return (
    <>
      <Drawer
        open={true}
        classes={{
          root: drawerStyles().campaignDrawer,
          paper: drawerStyles().drawerPaper,
        }}
      >
        <div css={styles.drawerHeader}>
          <div css={styles.titleContainer}>
            <Div maxWidth="284px">
              <Text type="largeBody" color="primaryHeading" weight="bold">
                Card:{' '}
                {card.sendableCard ? card.sendableCard.title : 'Custom Card'}
              </Text>
            </Div>
            <span
              id="close_drawer_btn"
              onClick={() => onClose()}
              style={{ paddingRight: 15, cursor: 'pointer' }}
            >
              <Icon icon={'CLOSE'} color={'#6f8394'} size={18} />
            </span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              css={styles.cardThumbnail}
              style={{
                backgroundImage: `url(${card.frontPreviewUrl})`,
                width: card.isHorizontal ? 70 : 50,
                height: card.isHorizontal ? 50 : 70,
              }}
            />
            {card.id && (
              <span
                onClick={() => onEditCard(card.id, card.isNewEditorCard)}
                style={{ paddingRight: 15, cursor: 'pointer' }}
              >
                <Icon icon={'EDIT'} color={'#ff5689'} size={18} />
                <Div display="inline-block" outset={{ left: 'x_5' }}>
                  <Text type="body">Edit Card</Text>
                </Div>
              </span>
            )}
          </div>
        </div>
        <div css={styles.drawerContents}>
          <div css={styles.details}>
            <h1
              css={styles.subTitle}
              onClick={() => setIsSendDelayMenuOpen(!isSendDelayMenuOpen)}
            >
              <span>
                <Icon
                  icon={'CALENDAR'}
                  color={'#6f8394'}
                  size={18}
                  viewBox={'0 0 32 32'}
                />
              </span>
              <Text type="body">
                {SendDelayOptions.shortDescription(line.sendDelay)}
              </Text>
            </h1>
            {line.giftVariation && (
              <h1
                css={styles.subTitle}
                onClick={() => setIsGiftMenuOpen(!isGiftMenuOpen)}
              >
                <span>
                  <Icon icon={'BAG'} color={'#6f8394'} size={18} />
                </span>
                <Text type="body">
                  {`Gift: ${line.giftVariation.price.asString}`}
                </Text>
              </h1>
            )}
            <h1
              css={styles.subTitle}
              onClick={() => setIsPaperOptionsMenuOpen(!isPaperOptionsMenuOpen)}
            >
              <span>
                <Icon icon={'FLATCARD'} color={'#6f8394'} size={18} />
              </span>
              <Text type="body">Card Type:&nbsp;</Text>
              <Text type="body" weight="bold">
                {card.type}
              </Text>
            </h1>
            <h1
              css={styles.subTitle}
              style={{ marginLeft: 28 }}
              onClick={() => setIsPaperOptionsMenuOpen(!isPaperOptionsMenuOpen)}
            >
              <Text type="body">Paper Type:&nbsp;</Text>
              <Text type="body" weight="bold">
                {paperTypes[card.paperType]}
              </Text>
            </h1>
          </div>
          <List>
            <ListItem
              id="send_delay_menu"
              style={{ cursor: 'pointer' }}
              onClick={() => setIsSendDelayMenuOpen(!isSendDelayMenuOpen)}
            >
              <ListItemText
                primary={<Text type="body">Send Delay</Text>}
                disableTypography={true}
              />
              <div css={styles.sendDelayInfo}>
                <Icon icon={'INFO'} size={19} color={'#6f8394'} />
                <span css={styles.sendDelayTooltip}>
                  <Div>
                    <Text type="footnote">
                      Cards with an anniversary or birthday delay are scheduled
                      to print and send 7 days before the scheduled date.
                    </Text>
                  </Div>
                </span>
              </div>
              {isSendDelayMenuOpen ? (
                <ExpandLess fontSize="inherit" />
              ) : (
                <ExpandMore fontSize="inherit" />
              )}
            </ListItem>
            <Collapse
              in={isSendDelayMenuOpen}
              timeout="auto"
              unmountOnExit={true}
            >
              <List component="div">
                <ListItem
                  key={1}
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    handleUpdateSendDelay(
                      SendDelayOptions.updateDelay(SendDelayType.Bir),
                      SendDelayType.Bir,
                    )
                  }
                >
                  <ListItemText
                    id="arrive_on_birthday_option"
                    primary={
                      <Text
                        {...listItemStyle(
                          line.sendDelay.type === SendDelayType.Bir,
                        )}
                      >
                        Arrive on Birthday
                      </Text>
                    }
                    disableTypography={true}
                  />
                </ListItem>
                <ListItem
                  id="arrive_on_anniversary_option"
                  key={2}
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    handleUpdateSendDelay(
                      SendDelayOptions.updateDelay(SendDelayType.Ann),
                      SendDelayType.Ann,
                    )
                  }
                >
                  <ListItemText
                    primary={
                      <Text
                        {...listItemStyle(
                          line.sendDelay.type === SendDelayType.Ann,
                        )}
                      >
                        Arrive on Anniversary
                      </Text>
                    }
                    disableTypography={true}
                  />
                </ListItem>
                <ListItem
                  key={3}
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    handleUpdateSendDelay(
                      SendDelayOptions.updateDelay(SendDelayType.Imm),
                      SendDelayType.Imm,
                    )
                  }
                >
                  <ListItemText
                    id="send_immediately_option"
                    primary={
                      <Text
                        {...listItemStyle(
                          line.sendDelay.type === SendDelayType.Imm &&
                            line.sendDelay.delayNumber === 0,
                        )}
                      >
                        Send Immediately
                      </Text>
                    }
                    disableTypography={true}
                  />
                </ListItem>
                <ListItem
                  key={4}
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    setIsSendOnSpecificDateMenuOpen(
                      !isSendOnSpecificDateMenuOpen,
                    )
                  }
                >
                  <ListItemText
                    id="specific_date_option"
                    primary={
                      <Text
                        {...listItemStyle(
                          line.sendDelay.type === SendDelayType.Spe,
                        )}
                      >
                        {line.sendDelay.type === SendDelayType.Spe
                          ? `Specific Date: ${SendDelayOptions.ribbonDescription(
                              line.sendDelay,
                            )}`
                          : 'Send on Specific Date'}
                      </Text>
                    }
                    disableTypography={true}
                  />
                  <div css={styles.sendDelayInfo}>
                    <Icon icon={'INFO'} size={19} color={'#6f8394'} />
                    <span css={styles.sendDelayTooltip}>
                      <Div>
                        <Text type="footnote">
                          Reminder: If the date you choose has already passed,
                          the card(s) will send on that date next year.
                        </Text>
                      </Div>
                    </span>
                  </div>
                  {isSendOnSpecificDateMenuOpen ? (
                    <ExpandLess fontSize="inherit" />
                  ) : (
                    <ExpandMore fontSize="inherit" />
                  )}
                </ListItem>
                <Collapse
                  in={isSendOnSpecificDateMenuOpen}
                  timeout="auto"
                  unmountOnExit={true}
                >
                  <List component="div">
                    <ListItem key={1}>
                      <TextField
                        select={true}
                        id={'send_delay_month'}
                        label="Month"
                        fullWidth={true}
                        value={delayMonth}
                        onChange={event => {
                          setDelayMonth(parseInt(event.target.value, 10))
                        }}
                        name="month"
                        InputLabelProps={{
                          style: { fontWeight: 400 },
                        }}
                        SelectProps={{
                          MenuProps: {
                            style: {
                              maxHeight: 200,
                            },
                          },
                        }}
                        style={{ width: '50%' }}
                      >
                        {months &&
                          months.map((month, index) => {
                            return (
                              <MenuItem
                                id={month ? month : undefined}
                                key={index}
                                value={month === null ? undefined : index}
                              >
                                {month}
                              </MenuItem>
                            )
                          })}
                      </TextField>
                      <TextField
                        select={true}
                        label="Day"
                        fullWidth={true}
                        value={delayDay}
                        onChange={event => {
                          setDelayDay(parseInt(event.target.value, 10))
                        }}
                        name="day"
                        SelectProps={{
                          MenuProps: {
                            style: {
                              maxHeight: 200,
                            },
                          },
                        }}
                        InputLabelProps={{
                          style: { fontWeight: 400 },
                        }}
                        style={{ width: '30%', marginLeft: 10 }}
                      >
                        {days &&
                          days(delayMonth).map((day, index) => {
                            return (
                              <MenuItem
                                key={index}
                                value={day === null ? undefined : index}
                              >
                                {day}
                              </MenuItem>
                            )
                          })}
                      </TextField>
                      <Button
                        id={'submit_send_delay_btn'}
                        title="Submit"
                        onClick={() => handleDate(delayMonth, delayDay)}
                      />
                    </ListItem>
                  </List>
                </Collapse>
                <ListItem
                  key={5}
                  style={{ cursor: 'pointer' }}
                  onClick={() => setIsSendDelayModalOpen(!isSendDelayModalOpen)}
                >
                  <ListItemText
                    id="advanced_options_option"
                    primary={
                      <Text
                        {...listItemStyle(
                          line.sendDelay.type === SendDelayType.Imm &&
                            (line.sendDelay.delayNumber
                              ? line.sendDelay.delayNumber
                              : 0) > 0,
                        )}
                      >
                        Advanced Options
                      </Text>
                    }
                    disableTypography={true}
                  />
                </ListItem>
              </List>
            </Collapse>
          </List>
          <List>
            <ListItem
              style={{ cursor: 'pointer' }}
              onClick={() => setIsPaperOptionsMenuOpen(!isPaperOptionsMenuOpen)}
            >
              <ListItemText
                primary={<Text type="body">Paper Options</Text>}
                disableTypography={true}
              />
              {isPaperOptionsMenuOpen ? (
                <ExpandLess fontSize="inherit" />
              ) : (
                <ExpandMore fontSize="inherit" />
              )}
            </ListItem>
            <Collapse
              in={isPaperOptionsMenuOpen}
              timeout="auto"
              unmountOnExit={true}
            >
              <List component="div">
                <List component="div">
                  {getPaperTypes(card.type).map((type, index) => (
                    <ListItem
                      key={index}
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleUpdatePaperType(type.value)}
                    >
                      <ListItemText
                        primary={
                          <Text
                            {...listItemStyle(card?.paperType === type.value)}
                          >
                            {type.label}
                          </Text>
                        }
                        disableTypography={true}
                      />
                    </ListItem>
                  ))}
                </List>
              </List>
            </Collapse>
          </List>
          {card.type !== 'POSTCARD' && (
            <List>
              <ListItem
                style={{ cursor: 'pointer' }}
                onClick={() => setIsGiftMenuOpen(!isGiftMenuOpen)}
              >
                <ListItemText
                  primary={<Text type="body">Gift</Text>}
                  disableTypography={true}
                />
                {isGiftMenuOpen ? (
                  <ExpandLess fontSize="inherit" />
                ) : (
                  <ExpandMore fontSize="inherit" />
                )}
              </ListItem>
              <Collapse in={isGiftMenuOpen} timeout="auto" unmountOnExit={true}>
                <List component="div">
                  <ListItem key={1}>
                    <div css={styles.gift}>
                      {line.giftVariation ? (
                        <div>
                          <div
                            css={styles.giftImage}
                            style={{
                              backgroundImage: `url(${line.giftVariation.defaultImageUrl}`,
                            }}
                          />
                          <div css={styles.giftDescription}>
                            <div css={styles.giftTitle}>
                              <Text type="largeBody" alignment="center">
                                {line.giftVariation.name}
                              </Text>
                            </div>
                            <div>
                              <Text type="largeBody">
                                {line.giftVariation.price.asString}
                              </Text>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <Text type="title">Add a gift?</Text>
                          <Spacer space="x2" />
                          <Button
                            title="Yes Please"
                            style={{ margin: 'auto' }}
                            onClick={handleAddGift}
                          />
                        </div>
                      )}
                    </div>
                  </ListItem>
                  <ListItem
                    key={2}
                    style={!line.giftVariation ? { display: 'none' } : {}}
                    onClick={handleRemoveGift}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                      }}
                    >
                      <Icon icon={'TRASH'} color={'#6f8394'} size={20} />
                      <Text type="caption">Remove Gift</Text>
                    </div>
                  </ListItem>
                </List>
              </Collapse>
            </List>
          )}
        </div>
        <div css={styles.drawerActions}>
          <Button
            title={'Replace Card'}
            buttonColor={'pink'}
            css={styles.drawerActionsButton}
            onClick={() => onReplaceCard(card.id)}
          />
          <Button
            title={'Delete Card'}
            css={styles.drawerActionsButton}
            onClick={() => setIsRemoveModalOpen(!isRemoveModalOpen)}
          />
        </div>
        {isRemoveModalOpen && (
          <Confirm
            title={'Remove card from campaign?'}
            message={
              'Are you sure you want to remove this item from your campaign?'
            }
            confirmTitle={'Remove'}
            onDecline={() => setIsRemoveModalOpen(!isRemoveModalOpen)}
            onConfirm={handleRemoveLine}
          />
        )}
      </Drawer>
      {isSendDelayModalOpen && (
        <SendDelayForm
          isOpen={isSendDelayModalOpen}
          close={() => setIsSendDelayModalOpen(!isSendDelayModalOpen)}
          onSubmit={handleUpdateSendDelay}
          sendDelay={line.sendDelay}
          isSequence={true}
        />
      )}
    </>
  )
}

export default CampaignDrawer
