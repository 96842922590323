import { useEffect, useState } from '.'

const useAndroidOrientation = () => {
  const [orientation, setOrientation] = useState<OrientationType>(
    window.screen.orientation
      ? window.screen.orientation.type
      : 'portrait-primary',
  )

  useEffect(() => {
    const handleOrientationChange = () =>
      setOrientation(
        window.screen.orientation
          ? window.screen.orientation.type
          : 'portrait-primary',
      )
    window.addEventListener('orientationchange', handleOrientationChange)
    return () =>
      window.removeEventListener('orientationchange', handleOrientationChange)
  }, [])

  return {
    orientation,
  }
}

export default useAndroidOrientation
