import React from 'react'

import { Icon } from 'src/chrome'

import { flexCenter, style } from './pricingTableStyles'
import { Text } from '@sendoutcards/quantum-design-ui'
import Disclaimer from './Disclaimer'

interface Props {
  isOpen: boolean
  messages?: string[]
  actionTitle: string
  isChartAvailable?: boolean
  shouldDisclaimerBeVisible?: boolean
  onClick: () => void
}

const RoundedFooter: React.FC<Props> = props => {
  const {
    isOpen,
    messages,
    actionTitle,
    isChartAvailable = true,
    shouldDisclaimerBeVisible = true,
    onClick,
  } = props

  return (
    <div
      style={{
        ...style.mobileActionBar,
        ...(messages ? { justifyContent: 'space-between' } : {}),
        ...(isOpen ? { borderRadius: 0 } : { borderRadius: '0 0 9px 9px' }),
      }}
      onClick={() => onClick()}
    >
      {shouldDisclaimerBeVisible && messages && (
        <Disclaimer messages={messages} />
      )}
      <div
        style={flexCenter}
        css={{
          cursor: 'pointer',
          marginLeft: '10px',
          display: isChartAvailable ? 'flex' : 'none !important',
        }}
      >
        <Text type="body" weight="bold" inset={{ right: 'x1' }}>
          {actionTitle}
        </Text>
        <div style={{ paddingBottom: 5 }}>
          <Icon
            color={'rgba(0, 0, 0, .55)'}
            size={15}
            icon={isOpen ? 'ARROWUP' : 'ARROWDOWN'}
          />
        </div>
      </div>
    </div>
  )
}

export default RoundedFooter
