import useSelector from './useSelector'

const usePersistedUserData = () => {
  const storedData = useSelector(state => state.user)

  if (!storedData) {
    throw new Error('no stored data')
  }

  return storedData
}

export default usePersistedUserData
