import { Interpolation } from '@emotion/css'

import { SmallOrLarge } from 'src/app/types'

const sizes = {
  per: {
    small: 16,
    large: 20,
  },
  perMarginTop: {
    small: -2,
    large: -8,
  },
}

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    lineHeight: 'normal',
  } as Interpolation,
  per: (size: SmallOrLarge, color: string) => ({
    color: color,
    marginTop: sizes.perMarginTop[size],
    fontSize: sizes.per[size],
    fontWeight: 300,
  }),
}

export default styles
