import {
  Anchor,
  Button,
  Flex,
  Icon,
  PinInput,
  Text,
  Transition,
  TransitionProps,
  VStack,
} from '@sendoutcards/quantum-design-ui'
import React, { FC } from 'react'
import { RequestEmailVerificationCodeData } from 'src/legacy_graphql/mutations/__generated__/RequestEmailVerificationCode'
import { useMutations, useState } from 'src/hooks'
import { parseError } from 'src/utils/parseError'
import { useValidateEmailVerification } from 'src/react_query'
import {
  ValidateEmailVerificationCodeFailureType,
  ValidateEmailVerificationCodeMutation,
} from 'src/graphql/generated/graphql'

enum Steps {
  Transition,
  RequestVerification,
  ConfirmVerification,
}

export type VerifyEmailProps = {
  email: string
  onComplete: (verifiedEmail: string) => void
}

export const VerifyEmail: FC<VerifyEmailProps> = ({ email, onComplete }) => {
  const [transitionState, setTransitionState] = useState<TransitionProps>({
    title: '',
    isLoading: false,
  })
  const [step, setStep] = useState<Steps>(Steps.RequestVerification)
  const [verificationCode, setVerificationCode] = useState('')
  const [
    validationFailureType,
    setValidationFailureType,
  ] = useState<ValidateEmailVerificationCodeFailureType | null>(null)
  const mutations = useMutations()
  const validateEmailMutation = useValidateEmailVerification()

  const requestVerification = async () => {
    setTransitionState({ title: `Sending code to ${email}`, isLoading: true })
    try {
      const {
        requestEmailVerificationCode,
      }: RequestEmailVerificationCodeData = await mutations.requestEmailVerificationCode(
        {
          doNotShowDefaultTransition: false,
          email,
        },
      )

      setTransitionState({
        title: `Successfully sent verification code to ${email}`,
        isLoading: false,
        loadedStatus: requestEmailVerificationCode.success
          ? 'success'
          : 'error',
      })
      if (
        requestEmailVerificationCode.__typename ===
        'RequestEmailVerificationCodeSuccess'
      ) {
        setTimeout(() => {
          setStep(Steps.ConfirmVerification)
        }, 3000)
      }
    } catch (error) {
      setTransitionState({
        title:
          error instanceof Error
            ? error.message
            : typeof error === 'string'
            ? error
            : 'An error occured',
        isLoading: false,
        loadedStatus: 'error',
      })
    }
  }

  const confirmVerificationCode = async (verificationCode: string) => {
    setStep(Steps.Transition)
    setTransitionState({
      title: 'Validating Verification Code...',
      isLoading: true,
    })
    try {
      const {
        validateEmailVerificationCode,
      }: ValidateEmailVerificationCodeMutation = await validateEmailMutation.mutateAsync(
        {
          email,
          code: verificationCode,
        },
      )
      setTransitionState({
        title: validateEmailVerificationCode.message,
        isLoading: false,
        loadedStatus: validateEmailVerificationCode.success
          ? 'success'
          : 'error',
      })

      if (
        validateEmailVerificationCode.__typename ===
        'ValidateEmailVerificationCodeSuccess'
      ) {
        setTimeout(() => {
          onComplete(email)
        }, 3000)
      } else {
        setValidationFailureType(validateEmailVerificationCode.failureType)
      }
    } catch (error) {
      setTransitionState({
        title: parseError(error),
        isLoading: false,
        loadedStatus: 'error',
      })
    }
  }

  return (
    <Flex>
      {step === Steps.RequestVerification ? (
        <Flex
          justifyContent="space-evenly"
          flexDirection="column"
          alignItems="center"
        >
          <div style={{ width: '60px' }}>
            <Icon name="envelope" size="60px" primaryColor="primaryBrand" />
          </div>
          <Text
            type="subtitle"
            content={'Verify Email'}
            weight="bold"
            outset={{ top: 'x3', bottom: 'x1' }}
          />
          <Text type="largeBody" content={email} color="anchorBlue" />
          <Text
            type="largeBody"
            content="Click below to request a one-time verification code to be emailed to the email above. This code will expire in 10 minutes."
            outset={{ top: 'x1', bottom: 'x2' }}
          />
          <Flex outset={'x2'}>
            <Button
              title="Get Code"
              onClick={() => {
                setStep(Steps.Transition)
                requestVerification()
                setTransitionState({
                  title: 'Sending Code To',
                  subtitle: email,
                  isLoading: true,
                })
              }}
            />
          </Flex>
          <Anchor
            isDecorated={true}
            size="xSmall"
            title="Already received a code?"
            onClick={() => setStep(Steps.ConfirmVerification)}
          />
        </Flex>
      ) : step === Steps.ConfirmVerification ? (
        <form
          onSubmit={event => {
            event.preventDefault()
            confirmVerificationCode(verificationCode)
          }}
          autoComplete="on"
        >
          <Flex
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <Text type="body">
              Please enter the six-digit code emailed to
              <Text weight="bold" type="body">
                {email}
              </Text>
            </Text>
            <Flex outset="x4">
              <PinInput
                length={6}
                hasFocus={true}
                onChange={setVerificationCode}
                onComplete={confirmVerificationCode}
                message={undefined}
              />
            </Flex>
            <VStack gap="x2">
              <Button onClick="submitForm" title="Proceed" fullWidth={true} />
              <Button
                onClick={() => setStep(Steps.RequestVerification)}
                fullWidth={true}
                outlined={true}
                title="Request Another Code"
              />
            </VStack>
          </Flex>
        </form>
      ) : (
        <VStack gap="x3" justifyContent="center" alignItems="center">
          <Transition {...transitionState} />
          {transitionState.loadedStatus === 'error' &&
            validationFailureType !==
              ValidateEmailVerificationCodeFailureType.TooManyAttempts && (
              <Button
                outlined={true}
                type="shadow"
                fullWidth={true}
                title={
                  validationFailureType ===
                  ValidateEmailVerificationCodeFailureType.IncorrectCode
                    ? 'Try Code Again'
                    : 'Resend Code'
                }
                onClick={() =>
                  setStep(
                    validationFailureType ===
                      ValidateEmailVerificationCodeFailureType.IncorrectCode
                      ? Steps.ConfirmVerification
                      : Steps.RequestVerification,
                  )
                }
              />
            )}
        </VStack>
      )}
    </Flex>
  )
}
