import React, { FC } from 'react'
import { Div, Flex, Icon, Text } from '@sendoutcards/quantum-design-ui'
import { Features } from 'src/upsale/components/pricingTile/components/Tile'

type SubscriptionFeaturesListProps = {
  features: Features[]
}

export const SubscriptionFeaturesList: FC<SubscriptionFeaturesListProps> = ({
  features,
}) => {
  // Filter out empty strings and invalid feature objects
  const filteredFeatures = features.filter(
    feature =>
      (typeof feature === 'string' && feature.trim() !== '') ||
      (typeof feature === 'object' && feature.feature.trim() !== ''),
  )

  return (
    <Flex width="100%" flexDirection="column">
      {filteredFeatures.map((feature, index) => (
        <Flex key={index} flexDirection="column" outset={{ bottom: 'x_75' }}>
          <Flex flexDirection="row" alignItems="flex-start" columnGap="x1_5">
            <Div
              width="fit-content"
              height="fit-content"
              inset={{ top: 'x_5' }}
              outset={{ right: 'x_5' }}
            >
              <Icon name="check" primaryColor={'#be9cdd'} size="xSmall" />
            </Div>
            <Text
              type="caption"
              content={typeof feature === 'string' ? feature : feature.feature}
              color={'primaryBody'}
              style={{
                fontWeight: 500,
              }}
            />
          </Flex>
        </Flex>
      ))}
    </Flex>
  )
}
