import React from 'react'
import { Icon } from 'src/chrome'
// @src imports
import { IconTypeOrIconString } from 'src/app/types'

import styles from './filterButtons.module.scss'
import { Text } from '@sendoutcards/quantum-design-ui'

type FilterButtonProps = {
  id: string
  filter: {
    name: string
    isPremium?: boolean
    icon: {
      icon: IconTypeOrIconString
      color?: string
      viewBox?: string
      size?: number
    }
  }
  onClick: (isPremium?: boolean) => void
}

const FilterButton: React.FC<FilterButtonProps> = props => {
  const { id, filter, onClick } = props
  return (
    <div
      id={id}
      className={styles.filterButton}
      onClick={() => onClick(filter.isPremium)}
    >
      <div className={styles.badge}>
        <Icon
          icon={filter.icon.icon || 'ADD'}
          color={filter.icon.color}
          viewBox={filter.icon.viewBox}
          size={filter.icon.size ?? 30}
        />
      </div>
      <Text type="caption">{filter.name}</Text>
    </div>
  )
}

export default FilterButton
