import React from 'react'
import moment from 'moment'

// @src imports
import { Modal, RwdCell, RwdRow } from 'src/chrome'

import HoverTooltip from 'src/chrome/HoverTooltip/HoverTooltip'
import { CreateContactInput } from 'src/legacy_graphql'

import styles from './invites.module.scss'
import {
  Anchor,
  Button,
  Dialog,
  Flex,
  Icon,
  Logo,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { useMutations, useState } from 'src/hooks'
import { formatLocation } from 'src/helpers'
import Checkbox from '@material-ui/core/Checkbox'
import { FormContact } from 'src/contacts/components/AddressForm/AddressForm'
import { AddressFormModal, ContactInfo } from 'src/contacts/components'
import { InviteFragment } from 'src/graphql/generated/graphql'

interface ProspectProps {
  index: number
  invite: InviteFragment
  isChecked: boolean
  onSelect: (invite: InviteFragment) => void
}

const convertStreamToBrandIcon = (stream: string | null | undefined) => {
  if (!stream || stream === '') {
    return null
  }

  switch (stream) {
    case 'blue42':
      return <Logo brand="blue42" color="color" type="icon" width="25px" />

    case 'promptings':
      return <Logo brand="promptings" color="color" type="icon" width="25px" />

    case 'bellaCadeaux':
      return (
        <Logo brand="bellaCadeaux" color="color" type="icon" width="25px" />
      )

    case 'promptingU':
      return (
        <Logo
          brand="promptingsAcademy"
          color="color"
          type="icon"
          width="25px"
        />
      )

    case 'soc':
      return <Logo brand="soc" color="color" type="icon" width="25px" />

    case 'streamMarketing':
      return (
        <Logo brand="streamMarketing" color="color" type="icon" width="25px" />
      )

    case 'streamVA':
      return <Logo brand="streamVA" color="color" type="icon" width="25px" />

    case 'gspro':
      return <Logo brand="promptings" color="color" type="icon" width="25px" />

    default:
      return null
  }
}

const Prospect: React.FC<ProspectProps> = props => {
  const { index, invite, isChecked, onSelect } = props
  const { createdAt, prospect, socProContact, contact } = invite

  const mutations = useMutations()

  const [showErrorDialog, setShowErrorDialog] = useState(false)
  const [showAddForm, setShowAddForm] = useState(false)
  const [showEditForm, setShowEditForm] = useState(false)
  const [addedContact, setAddedContact] = useState({
    name: '',
    id: '',
  })

  const firstName =
    contact?.firstName ?? socProContact?.firstName ?? prospect?.firstName ?? ''
  const lastName =
    contact?.lastName ?? socProContact?.lastName ?? prospect?.lastName ?? ''
  const phoneNumber =
    contact?.homePhone ??
    socProContact?.homePhone ??
    socProContact?.smsPhone ??
    socProContact?.workPhone ??
    prospect?.phoneNumber ??
    ''
  const email =
    contact?.emailAddress ?? socProContact?.email ?? prospect?.email ?? ''
  const address1 =
    contact?.address1 ??
    socProContact?.address ??
    prospect?.address?.address1 ??
    ''
  const address2 =
    contact?.address2 ??
    socProContact?.address2 ??
    prospect?.address?.address2 ??
    ''
  const city =
    contact?.city ?? socProContact?.city ?? prospect?.address?.city ?? ''
  const state =
    contact?.state ?? socProContact?.state ?? prospect?.address?.state ?? ''
  const country =
    contact?.country ??
    socProContact?.country ??
    prospect?.address?.country ??
    ''
  const cityAndState = formatLocation(city, state, '')
  const postalCode =
    contact?.postalCode ??
    socProContact?.zip ??
    prospect?.address?.postalCode ??
    ''
  const location = [cityAndState, postalCode].join(' ')

  const formContact: FormContact = {
    company: '',
    firstName,
    lastName,
    address1,
    address2,
    city,
    state,
    postalCode,
    country,
    homePhone: phoneNumber,
    email,
  }

  const streamIcon = convertStreamToBrandIcon(invite.utmStream)
  const activityIcons = [
    invite.hasProspectJoined && (
      <Icon name="star" size="small" primaryColor="primaryBrand" />
    ),
    invite.hasSentCard && (
      <Icon name="envelope" size="small" primaryColor="primaryBrand" />
    ),
    prospect?.ordersSent ? (
      <HoverTooltip
        title="Number of cards sent."
        shouldDisplayTooltip={true}
        isFullWidth={true}
        background="dark"
      >
        <Text type="body" color="primaryHeading" outset={{ horizontal: 'x1' }}>
          {prospect?.ordersSent}
        </Text>
      </HoverTooltip>
    ) : null,
  ]

  const handleEditProspectInfo = () => setShowAddForm(true)

  const createContact = async () => {
    try {
      const {
        createContactWithInvite,
      } = await mutations.createContactWithInvite({ inviteId: invite.id })
      return createContactWithInvite.contact
    } catch (error) {
      setShowErrorDialog(true)
      console.error(error)
      return
    }
  }

  const createInviteContact = async (newContact: CreateContactInput) => {
    setShowAddForm(false)
    setShowErrorDialog(false)
    const {
      createContactWithSpouseChildren: result,
    } = await mutations.createContactWithSpouseAndChildren({
      contact: newContact,
    })
    const createdContact = result.contacts ? result.contacts[0] : undefined
    if (createdContact) {
      updateInvite(createdContact.id)
      setAddedContact({ name: createdContact.firstName, id: createdContact.id })
    }
  }

  const updateInvite = (contactId: string) =>
    mutations.updateInvite({
      invite: {
        inviteId: invite.id,
        contactId,
      },
    })

  const onCreateContact = async () => {
    if (!contact) {
      const createdContact = await createContact()
      if (createdContact) {
        setAddedContact({
          name: createdContact.firstName,
          id: createdContact.id,
        })
      }
      return
    }
  }

  return (
    <>
      <Dialog
        isOpen={!!addedContact.name}
        onClose={() => setAddedContact({ ...addedContact, name: '' })}
      >
        <Text type="subtitle">
          {addedContact.name} has been added to your contacts!
        </Text>
      </Dialog>
      <Dialog
        isOpen={showErrorDialog}
        onClose={() => setShowErrorDialog(false)}
      >
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Text type="body">
            {invite.socProContact?.lastName ?? invite.prospect?.lastName},{' '}
            {(invite.socProContact?.firstName, invite.prospect?.firstName)} is
            missing either name or address information. Please make the needed
            change(s) and try again.
          </Text>
          <Button onClick={() => handleEditProspectInfo()}>Edit Info</Button>
        </Flex>
      </Dialog>
      {showAddForm && (
        <AddressFormModal
          contact={formContact}
          close={() => setShowAddForm(false)}
          onSubmit={createInviteContact}
        />
      )}
      {showEditForm && (
        <Modal
          title=""
          onClose={() => {
            setShowEditForm(false)
          }}
          bodyStyles={{
            maxHeight: 800,
          }}
          bodyChildren={
            <ContactInfo
              contactId={
                contact?.id
                  ? contact.id
                  : socProContact?.socContact?.id
                  ? socProContact.socContact.id
                  : addedContact.id
                  ? addedContact.id
                  : ''
              }
            />
          }
        />
      )}
      <RwdRow id={`invite_${index}`}>
        <RwdCell mapToHeader="Select" className={styles.selectCell}>
          <div className={styles.contactActions}>
            <Checkbox
              id={`checkbox_${index}`}
              name={lastName}
              checked={isChecked}
              onChange={() => onSelect(invite)}
              className={styles.checkbox}
            />
          </div>
        </RwdCell>
        <RwdCell mapToHeader="Name">
          <Text type="body" color="primaryHeading">
            {firstName} {lastName}
          </Text>
        </RwdCell>
        <RwdCell mapToHeader="Date">
          <Text type="body" color="primaryHeading">
            {moment(createdAt).format('MM/DD/YY')}
          </Text>
        </RwdCell>
        <RwdCell mapToHeader="Phone">
          <Text type="body" color="primaryHeading">
            {phoneNumber}
          </Text>
        </RwdCell>
        <RwdCell mapToHeader="Email">
          <Text type="body" color="primaryHeading">
            {email}
          </Text>
        </RwdCell>
        <RwdCell mapToHeader="Address">
          <Text type="caption">{address1}</Text>
          <Text type="caption">{address2}</Text>
          <Text type="caption">{location}</Text>
          <Text type="caption">{prospect?.address?.country}</Text>
          {!!contact && (
            <Anchor isDecorated={true} onClick={() => setShowEditForm(true)}>
              Edit
            </Anchor>
          )}
          {!contact && (
            <Anchor isDecorated={true} onClick={() => onCreateContact()}>
              Add
            </Anchor>
          )}
        </RwdCell>
        <RwdCell
          mapToHeader="Activity"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {activityIcons}
        </RwdCell>
        <RwdCell mapToHeader="Stream">{streamIcon}</RwdCell>
      </RwdRow>
      <tr className={styles.tableSpacer} />
    </>
  )
}

export default Prospect
