import {
  useAvailableDailyHeartfelt,
  useAvailableUnlimitedDailyHeartfelt,
} from 'src/react_query'
import { useActions, useEffect } from '.'
import { useState } from './useState'

const useHeartfeltWarningBannerInfo = () => {
  const invalidateQueriesByName = useActions().invalidatedQueriesByName
  const { data: availableUDHeartfelt } = useAvailableUnlimitedDailyHeartfelt()
  const { data: availableDHeartfelt } = useAvailableDailyHeartfelt()
  const [shouldDisplayWarningBanner, setShouldDisplayWarningBanner] = useState(
    (availableUDHeartfelt?.hasUnlimitedHeartfeltSends &&
      availableUDHeartfelt?.heartfeltDailyCap.available === 0) ||
      (availableDHeartfelt?.hasDailyHeartfeltSends &&
        availableDHeartfelt?.dailyHeartfeltCap.available === 0),
  )
  const warningBannerCap = availableUDHeartfelt?.hasUnlimitedHeartfeltSends
    ? availableUDHeartfelt?.heartfeltDailyCap.cap
    : availableDHeartfelt?.hasDailyHeartfeltSends
    ? availableDHeartfelt?.dailyHeartfeltCap.cap
    : 0

  useEffect(() => {
    invalidateQueriesByName('GetAvailableUnlimitedDailyHeartfelt')
    invalidateQueriesByName('GetAvailableDailyHeartfelt')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    shouldDisplayWarningBanner,
    warningBannerCap,
    setShouldDisplayWarningBanner,
  }
}

export default useHeartfeltWarningBannerInfo
