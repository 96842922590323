import React, { FC } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'

import Avatar from '@material-ui/core/Avatar'

import { Item } from 'src/campaign_store/types'
import { SimpleLoadingSpinner } from 'src/chrome'
import StripeOrderTotal, { StripeOrder } from './StripeOrderTotal'
import { Text } from '@sendoutcards/quantum-design-ui'

interface ItemsContextProps {
  items: Item[]
  stripeOrder?: StripeOrder
}

// TODO: [ListItem] secondaryTextLines={2}
const ItemsContext: FC<ItemsContextProps> = props => {
  const { items, stripeOrder } = props

  return (
    <>
      <Text type="largeBody">Items</Text>
      <List>
        {items.map(item => (
          <ListItem key={item.id}>
            {item.image && (
              <ListItemAvatar>
                <Avatar
                  src={item.image}
                  style={{
                    width: 55,
                    height: 'auto',
                    borderRadius: 'none',
                    boxShadow: '0 2px 12px 0 #E4E4E4',
                  }}
                />
              </ListItemAvatar>
            )}
            <ListItemText
              primary={
                <Text
                  type="footnote"
                  color="primaryHeading"
                  inset={{ left: '92px' }}
                >
                  {item.name}
                </Text>
              }
              secondary={
                <>
                  <Text
                    type="caption"
                    color="primaryHeading"
                    inset={{ left: '92px' }}
                  >
                    ${item.price}
                  </Text>
                </>
              }
              disableTypography={true}
            />
          </ListItem>
        ))}
      </List>
      <div style={{ marginLeft: 18, width: 200 }}>
        {stripeOrder ? (
          <StripeOrderTotal stripeOrder={stripeOrder} />
        ) : (
          <SimpleLoadingSpinner />
        )}
      </div>
    </>
  )
}

export default ItemsContext
