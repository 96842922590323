import { Route } from 'src/routes/Route'
import { Subroute } from 'src/routes/Subroute'

import { EditOrderCardRoute } from './EditOrderCardRoute'
import { ReplaceOrderCardRoute } from './ReplaceOrderCardRoute'
import { AddCardToGiftRoute } from './AddCardToGiftRoute'
import { AddOrderCardRoute } from './AddOrderCardRoute'
import { AddGiftToCardRoute } from './AddGiftToCardRoute'
import { AddOrderGiftRoute } from './AddOrderGiftRoute'

export const OrdersSubroute = Subroute(
  AddOrderCardRoute,
  EditOrderCardRoute,
  ReplaceOrderCardRoute,
  AddCardToGiftRoute,
  AddGiftToCardRoute,
  AddOrderGiftRoute,
)

export type OrdersSubroute = Subroute<typeof OrdersSubroute>

type OrdersRouteContent = {
  orderId: string
  subroute?: OrdersSubroute
}

export const OrdersRoute = Route({
  path: '/orders/:orderId',
  init: (orderId: string, subroute?: OrdersSubroute): OrdersRouteContent => ({
    orderId,
    subroute,
  }),
  fromPath: ({ orderId }) => (orderId ? { orderId } : undefined),
  subroute: OrdersSubroute,
})

export type OrdersRoute = Route<typeof OrdersRoute>
