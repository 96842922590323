import minimalPanelFragmentRawString from '../MinimalPanelFragment.graphql'

export const minimalPanelFragmentString = minimalPanelFragmentRawString

export type MinimalPanelFragment = {
  __typename: 'Panel'
  name: string
  previewUrl: string
  isFullBleed: boolean
  width: number
  height: number
}

export const isMinimalPanelFragment = (
  fragment: any,
): fragment is MinimalPanelFragment =>
  fragment &&
  fragment.__typename === 'Panel' &&
  typeof fragment.name === 'string' &&
  typeof fragment.previewUrl === 'string' &&
  typeof fragment.isFullBleed === 'boolean' &&
  typeof fragment.width === 'number' &&
  typeof fragment.height === 'number'
