import { Route } from 'src/routes/Route'
import { SendableCardRoute } from './SendableCardRoute'
import { selectedSendableCardReducer } from './reducers'

export const CatalogCardsRoute = Route({
  path: '/catalog-cards',
  init: (subroute?: SendableCardRoute) => ({ subroute }),
  subroute: SendableCardRoute,
  reducer: (route, action) => selectedSendableCardReducer(route, action),
})
export type CatalogCardsRoute = Route<typeof CatalogCardsRoute>
