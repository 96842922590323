import { Operation } from '../../../__generated__/globalTypes'

import deleteImageString from '../DeleteImage.graphql'

export type DeleteImageData = {
  deleteImage: {
    deleted: boolean
  }
}

export type DeleteImageVariables = {
  id: string
}

export type DeleteImage = Operation<DeleteImageData, DeleteImageVariables>

export const DeleteImage = ({ id }: DeleteImageVariables): DeleteImage => ({
  query: deleteImageString,
  variables: {
    id,
  },
})
