import {
  CampaignLineFragment,
  isCampaignLineFragment,
} from './CampaignLineFragment'

import campaignFragmentRawString from '../CampaignFragment.graphql'

export const campaignFragmentString = campaignFragmentRawString

export type CampaignFragment = {
  __typename: 'Campaign'
  id: string
  name: string
  isShareable: boolean
  lines: CampaignLineFragment[]
}

export const isCampaignFragment = (
  fragment: any,
): fragment is CampaignFragment =>
  fragment &&
  fragment.__typename === 'Campaign' &&
  typeof fragment.id === 'string' &&
  typeof fragment.name === 'string' &&
  typeof fragment.isShareable === 'boolean' &&
  Array.isArray(fragment.lines) &&
  fragment.lines
    .slice(0, 5)
    .reduce(
      (accum: any, next: any) =>
        accum && (isCampaignLineFragment(next) as boolean),
      true,
    )
