import { Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import HoverTooltip, {
  HoverTooltipProps,
} from 'src/chrome/HoverTooltip/HoverTooltip'

import styles from '../../styles/account.module.scss'

interface RemainingRewardsProps {
  label: string
  remaining: number
  total: number
  tooltip?: HoverTooltipProps
}

const RemainingRewards: React.FC<RemainingRewardsProps> = props => {
  const {
    label,
    remaining,
    total,
    tooltip = { shouldDisplayTooltip: false },
  } = props
  return (
    <div className={`${styles.status} ${styles.progress}`}>
      <div className={styles.details}>
        <Text type="caption" weight="bold" color="primaryHeading">
          {label}
        </Text>
        <div className={styles.amount}>
          <HoverTooltip {...tooltip}>
            <Text type="caption" weight="bold" color="primaryHeading">
              {remaining} / {total}
            </Text>
          </HoverTooltip>
        </div>
      </div>
    </div>
  )
}

export default RemainingRewards
