import React from 'react'
import {
  AnimatedCheckMark,
  Dialog,
  Flex,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'

type SuccessProps = {
  onClose: () => void
}

const Success: React.FC<SuccessProps> = props => {
  const { onClose } = props
  return (
    <Dialog isOpen={true} onClose={onClose}>
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <Text type="subtitle">Success!</Text>
        <Spacer space="x3" />
        <AnimatedCheckMark size="large" />
      </Flex>
    </Dialog>
  )
}

export default Success
