import { Operation } from '../../../__generated__/globalTypes'

import getDoubleBonusReportsString from '../GetDoubleBonusReports.graphql'

export type GetDoubleBonusReportsData = {
  doubleBonusReports: {
    bonusDescription: string
    personalQualifyingVolume: string
    doubleBonusPercentAchieved: string
    additionalPqvNeededToHit50PercentBonus: string
    additionalPqvNeededToHit100PercentBonus: string
    earnedAffiliateCommissionsMonthToDate: string
    doubleBonusEarnings: string
  }[]
}

export type GetDoubleBonusReports = Operation<GetDoubleBonusReportsData>

export const GetDoubleBonusReports = (): GetDoubleBonusReports => ({
  query: getDoubleBonusReportsString,
  variables: undefined,
})
