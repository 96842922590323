import { Div, Flex, Text, Tooltip } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import {
  DiscountItem,
  TotalDiscounts,
} from 'src/dashboard/components/TotalDiscounts/TotalDiscounts'
import { CardSendType } from 'src/graphql/generated/graphql'
import { formatCost } from 'src/helpers'
import { useAccount, useSelector, useSubscriptions } from 'src/hooks'
import { OrderApiType } from 'src/orders/api'
import {
  getGrandTotal,
  getOrderCardBaseCost,
  getPrice,
  getShippingInfo,
  getSubTotalText,
  getTotalGiftDiscounts,
  getTotalGiftFeeAmount,
  getTotalPointsDiscount,
} from 'src/orders/helpers'
import { OrderWithPartialLines } from 'src/redux/reducers/orders'

export interface OrderTotalProps {
  order?: OrderWithPartialLines
  api?: OrderApiType
}

const styles = {
  item: {
    '@media (max-width: 840px)': {
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  grandTotal: {
    '@media (max-width: 840px)': {
      flexDirection: 'column-reverse' as const,
    },
  },
  cancelOrder: {
    '@media (max-width: 840px)': {
      marginLeft: '0',
    },
  },
}

const OrderTotal: React.FC<OrderTotalProps> = props => {
  const { order, api } = props

  const toggle = (prevState: boolean): boolean => !prevState
  const totalsContainerWidth = '534px'

  // Hooks
  const account = useAccount()
  const subscription = useSubscriptions()
  const { isMobile } = useSelector(state => state.window)

  const shippingInfo = getShippingInfo(account.giftShippingDiscount, order)

  const totalGiftFeeAmount = getTotalGiftFeeAmount(order)

  const grandTotal = getGrandTotal(order)

  const isMissingRecipient = order && !order.cost.entries[0]

  const totals = [
    {
      id: 'subtotal',
      title: 'Subtotal:',
      cost: getSubTotalText(order),
      shouldDisplay: true,
    },
    {
      id: 'giftProcessingFee',
      title: 'Gift Processing Fees:',
      cost: formatCost(totalGiftFeeAmount),
      shouldDisplay: totalGiftFeeAmount > 0,
      shouldDisplayTooltip: isMissingRecipient,
      tooltipMessage: 'Add Recipient(s) to calculate shipping.',
    },
    {
      id: 'shipping',
      title: 'Shipping:',
      cost: shippingInfo.totalShippingText,
      shouldDisplay: true,
    },
  ]

  const getDiscountBreakDown = () => {
    const recipientCount =
      order && order.contacts.length > 0 ? order.contacts.length : 1
    const totalGiftDiscounts = getTotalGiftDiscounts(recipientCount, order) ?? 0
    const cardBaseCost = getOrderCardBaseCost(order)
    const { systemSends, heartfeltSystemSends } = account

    const pointsDiscount = getTotalPointsDiscount(order)

    const pointsDiscountItem =
      pointsDiscount > 0
        ? ({
            title: 'Points Discount',
            value: pointsDiscount,
            shouldNotFormat: true,
          } as DiscountItem)
        : undefined

    const orderSystemCards = getPrice(order?.cost.entries, 'System Sends')
      ?.amount

    const employeeSendsDiscounts = getPrice(
      order?.cost.entries,
      'Employee Sends',
    )?.amount

    const orderHeartfeltSystemCards = getPrice(
      order?.cost.entries,
      'Card Sends',
    )?.amount

    const systemCardsDiscounts =
      orderSystemCards && orderHeartfeltSystemCards
        ? orderSystemCards + orderHeartfeltSystemCards
        : orderSystemCards ?? orderHeartfeltSystemCards

    // System cards discounts are determined by how many system card tokens will
    // be used times the card base cost
    const systemCards =
      cardBaseCost && systemCardsDiscounts && systemCardsDiscounts > 0
        ? ({
            title: `${systemSends + heartfeltSystemSends} Free System Cards`,
            subtitle: ` applying  ${systemCardsDiscounts} of ${
              systemSends + heartfeltSystemSends
            }`,
            value: systemCardsDiscounts * cardBaseCost,
          } as DiscountItem)
        : undefined

    // Heartfelt cards can only be sent to one person, thus the discount should always be
    // the card base cost.
    const cardSendDiscounts =
      order?.sendType === CardSendType.Heartfelt &&
      subscription.hasAvailableHeartfeltSendTokens
        ? ({
            title: 'Heartfelt Discount',
            value: cardBaseCost,
          } as DiscountItem)
        : undefined

    const employeeSends =
      cardBaseCost && employeeSendsDiscounts && employeeSendsDiscounts > 0
        ? ({
            title: `Free Employee Cards`,
            value: employeeSendsDiscounts * cardBaseCost,
          } as DiscountItem)
        : undefined

    const giftDiscounts =
      totalGiftDiscounts > 0
        ? ({
            title: 'Discount on Gifts',
            info:
              'Discount is calculated based on product and subscription type.',
            value: totalGiftDiscounts,
          } as DiscountItem)
        : undefined

    const shippingDiscounts = shippingInfo.hasShippingDiscount
      ? ({
          title: 'Discount on Shipping',
          info:
            'Discount is calculated based on product and subscription type.',
          value: shippingInfo.shippingDiscountAmount,
        } as DiscountItem)
      : undefined

    const discountItems = [
      ...(pointsDiscountItem ? [pointsDiscountItem] : []),
      ...(systemCards ? [systemCards] : []),
      ...(cardSendDiscounts ? [cardSendDiscounts] : []),
      ...(employeeSends ? [employeeSends] : []),
      ...(giftDiscounts ? [giftDiscounts] : []),
      ...(shippingDiscounts ? [shippingDiscounts] : []),
    ]

    return discountItems
  }

  return (
    <Div
      display="flex"
      width="100%"
      flexDirection="column"
      backgroundColor="background"
      borderRadius="0 0 28px 28px"
      alignItems="flex-end"
      css={styles.item}
    >
      {totals.map((total, index) => {
        return (
          <>
            {total.shouldDisplay && (
              <Flex
                width="100%"
                maxWidth={totalsContainerWidth}
                key={index}
                justifyContent="space-between"
                alignItems="flex-end"
                flexDirection="row"
                inset={{
                  vertical: 'x2',
                  right: !isMobile ? 'x6' : 'x3',
                  left: !isMobile ? undefined : 'x3',
                }}
              >
                <Text
                  color="primaryHeading"
                  weight="semiBold"
                  type="largeBody"
                  content={total.title}
                  whiteSpace="nowrap"
                />
                <Div>
                  <Text
                    color="primaryHeading"
                    weight="bold"
                    type="subtitle"
                    content={total.cost}
                  />
                </Div>

                {total.shouldDisplayTooltip && (
                  <Flex position="absolute" bottom="-36px">
                    <Tooltip title={total.tooltipMessage} direction="top" />
                  </Flex>
                )}
              </Flex>
            )}
          </>
        )
      })}
      {getDiscountBreakDown().length > 0 && (
        <Div
          display="flex"
          width="100%"
          justifyContent="space-between"
          alignItems="flex-end"
          flexDirection={isMobile ? 'column' : 'row'}
          css={styles.item}
        >
          <Div
            width="100%"
            justifyContent="flex-end"
            css={styles.item}
            display="flex"
          >
            <Div
              width="100%"
              maxWidth={`calc(${totalsContainerWidth} - 20px)`}
              outset={{
                right: !isMobile ? 'x4' : 'x2',
                bottom: 'x2',
                left: isMobile ? 'x2' : undefined,
              }}
            >
              <TotalDiscounts
                title="Discounts & Savings Breakdown"
                planDescription={subscription.currentPlan.title}
                discountData={getDiscountBreakDown()}
              />
            </Div>
          </Div>
        </Div>
      )}
      <Flex
        width="100%"
        justifyContent="space-between"
        alignItems="flex-end"
        flexDirection={isMobile ? 'column' : 'row'}
      >
        <Div
          display="flex"
          alignItems="center"
          inset={{ vertical: '32px' }}
          backgroundColor="#EBEBEB"
          borderRadius={{ bottom: '16px' }}
          width="100%"
          justifyContent="space-between"
          flexWrap="wrap-reverse"
          css={styles.grandTotal}
        >
          <Flex
            height="auto"
            justifyContent="space-between"
            position="relative"
            cursor="pointer"
            onClick={() => api?.setIsDeleteModalOpen(toggle)}
          >
            <Div outset={{ left: 'x4' }} css={styles.cancelOrder}>
              <Text
                type={isMobile ? 'caption' : 'body'}
                whiteSpace="nowrap"
                weight="bold"
                content="Cancel Order"
                color="danger"
              />
            </Div>
          </Flex>
          <Flex
            width="100%"
            maxWidth={totalsContainerWidth}
            key="final"
            justifyContent="space-between"
            alignItems="center"
            inset={{
              right: !isMobile ? 'x6' : 'x3',
              left: !isMobile ? undefined : 'x3',
            }}
          >
            <Text
              color="primaryHeading"
              weight="semiBold"
              type="largeBody"
              content="Total:"
              whiteSpace="nowrap"
              alignment="right"
            />
            <Div>
              <Text
                color="primaryHeading"
                weight="bold"
                type="subtitle"
                content={grandTotal}
              />
            </Div>
          </Flex>
        </Div>
      </Flex>
    </Div>
  )
}

export default OrderTotal
