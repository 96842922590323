import {
  UpdateContactInput,
  Operation,
} from '../../../__generated__/globalTypes'

import {
  DetailedContactFragment,
  detailedContactFragmentString,
} from '../../fragments/__generated__/DetailedContactFragment'

import { calendarDateFragmentString } from '../../fragments/__generated__/CalendarDateFragment'

import { contactFragmentString } from '../../fragments/__generated__/ContactFragment'

import { optionalYearDateFragmentString } from '../../fragments/__generated__/OptionalYearDateFragment'

import { relatedContactFragmentString } from '../../fragments/__generated__/RelatedContactFragment'

import { noteFragmentString } from '../../fragments/__generated__/NoteFragment'

import createOrUpdateContactRawString from '../CreateOrUpdateContact.graphql'

const createOrUpdateContactString = [
  createOrUpdateContactRawString,
  detailedContactFragmentString,
  calendarDateFragmentString,
  contactFragmentString,
  optionalYearDateFragmentString,
  relatedContactFragmentString,
  noteFragmentString,
].join('\n\n')

export type CreateOrUpdateContactData = {
  createOrUpdateContact: {
    contact: DetailedContactFragment
  }
}

export type CreateOrUpdateContactVariables = {
  contact: UpdateContactInput
}

export type CreateOrUpdateContact = Operation<
  CreateOrUpdateContactData,
  CreateOrUpdateContactVariables
>

export const CreateOrUpdateContact = ({
  contact,
}: CreateOrUpdateContactVariables): CreateOrUpdateContact => ({
  query: createOrUpdateContactString,
  variables: {
    contact: UpdateContactInput(contact),
  },
})
