import { Maybe, Operation } from '../../../__generated__/globalTypes'

import {
  OnlyIdsContactFragment,
  onlyIdsContactFragmentString,
} from '../../fragments/__generated__/OnlyIdsContactFragment'

import getContactsIdsRawString from '../GetContactsIds.graphql'

const getContactsIdsString = [
  getContactsIdsRawString,
  onlyIdsContactFragmentString,
].join('\n\n')

export type GetContactsIdsData = {
  paginatedContacts: {
    allContacts: OnlyIdsContactFragment[]
  }
}

export type GetContactsIdsVariables = {
  search?: Maybe<string>
  showSecondaryContacts?: Maybe<boolean>
}

export type GetContactsIds = Operation<
  GetContactsIdsData,
  GetContactsIdsVariables
>

export const GetContactsIds = ({
  search,
  showSecondaryContacts,
}: GetContactsIdsVariables = {}): GetContactsIds => ({
  query: getContactsIdsString,
  variables: {
    search,
    showSecondaryContacts,
  },
})
