export const GS_SALEOR_MONTHLY_AFFILIATE_ID = 'GS00017'
export const GS_SALEOR_ANNUAL_AFFILIATE_ID = 'GS00018'
export const GS_AFFILIATE_ID = 'GS00001'
const STREAM_VA_ID = 'SV00004'
const STREAM_VA_BASIC_ID = 'SV00005'
const STREAM_VA_PRO_ID = 'SV00006'
const STREAM_VA_REL_PRO = 'price_1IIcmFASHJWgnbKEDbjhVrd8'
const ENHANCED_STRIPE_ID = '13705'
export const CARDADAY_STRIPE_ID = '14547'
export const UNLIMITED_STRIPE_ID = '15005'
export const UNLIMITED_STRIPE_ID_PLUS_GIFT = '15395'
const STREAM_MARKETING_UNLIMITED_PLUS_100 = 'GS00009'
const STREAM_MARKETING_UNLIMITED_PLUS_250 = 'GS00010'

export const UNLIMITED_100_STRIPE_ID = '13707'
export const UNLIMITED_250_STRIPE_ID = '14866'

export const SOC_SKUS = [
  GS_AFFILIATE_ID,
  ENHANCED_STRIPE_ID,
  CARDADAY_STRIPE_ID,
  UNLIMITED_STRIPE_ID,
  UNLIMITED_100_STRIPE_ID,
  UNLIMITED_250_STRIPE_ID,
  'legacy',
]
export const STREAM_VA_SKUS = [
  STREAM_VA_ID,
  STREAM_VA_BASIC_ID,
  STREAM_VA_PRO_ID,
  STREAM_VA_REL_PRO,
]

export const SENDOGO_RP_STRIPE_ID = '14528'

const ELITE_STRIPE_ID = '14233'
export const ELITE_PLUS_STRIPE_ID = '14234'
const SYSTEM_SEND_STRIPE_ID = '14535'
export const CARD_PACKAGE_100 = '15053'
export const CARD_PACKAGE_250 = '15054'
export const CARD_PACKAGE_500 = '15027'
export const CARD_PACKAGE_1000 = '15028'
export const CARD_PACKAGE_2000 = '15029'
export const LEGACY_980_1 = '11103'
export const LEGACY_980_2 = '11124'
export const LEGACY_39 = '11121'
export const LEGACY_31 = '11095'

export const ELITE_PACKAGES = [ELITE_STRIPE_ID, ELITE_PLUS_STRIPE_ID]
const ADDON_PACKAGES = [
  ...ELITE_PACKAGES,
  SYSTEM_SEND_STRIPE_ID,
  CARD_PACKAGE_500,
  CARD_PACKAGE_1000,
  CARD_PACKAGE_2000,
]
export const UNLIMITED_FREE_CAMPAIGN_PACKAGES = [
  ...ADDON_PACKAGES,
  STREAM_VA_BASIC_ID,
  STREAM_VA_PRO_ID,
  UNLIMITED_100_STRIPE_ID,
  UNLIMITED_250_STRIPE_ID,
]
export const ELITE_ELIGIBLE_PLANS = [
  ENHANCED_STRIPE_ID,
  CARDADAY_STRIPE_ID,
  UNLIMITED_STRIPE_ID,
  UNLIMITED_100_STRIPE_ID,
  UNLIMITED_250_STRIPE_ID,
]

export const UNLIMITED_100_PLANS = [
  UNLIMITED_100_STRIPE_ID,
  STREAM_MARKETING_UNLIMITED_PLUS_100,
]

export const UNLIMITED_250_PLANS = [
  UNLIMITED_250_STRIPE_ID,
  STREAM_MARKETING_UNLIMITED_PLUS_250,
]

export const PLAN_SKUS = {
  limited: 'Basic',
  [ENHANCED_STRIPE_ID]: 'Enhanced',
  [CARDADAY_STRIPE_ID]: 'Card A Day',
  [UNLIMITED_STRIPE_ID]: 'Unlimited',
  [UNLIMITED_100_STRIPE_ID]: 'Unlimited +100',
  [UNLIMITED_250_STRIPE_ID]: 'Unlimited +250',
  legacy: 'Legacy',
}

export const asSku = (value: string) => {
  switch (value) {
    case 'limited':
    case 'legacy':
    case ENHANCED_STRIPE_ID:
    case CARDADAY_STRIPE_ID:
    case UNLIMITED_STRIPE_ID:
    case UNLIMITED_100_STRIPE_ID:
    case UNLIMITED_250_STRIPE_ID:
      return value
    default:
      return undefined
  }
}
