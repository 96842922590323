import { AccountFragment } from 'src/graphql/generated/graphql'
import LogRocket from 'src/logrocket/logrocket'
import Result from 'src/utils/Result'

export const setLogrocketUser = (
  accountResult: Result<AccountFragment | null>,
) => {
  const account = accountResult.value
  // Check for a username so we don't set sparse accounts
  if (account?.username) {
    LogRocket.identify(account.id, {
      name: `${account.firstName} ${account.lastName}`,
      username: account.username,
      email: account.email,
    })
  }
}

export function noop() {}
