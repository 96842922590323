import { NormalizedData, Operation } from 'src/legacy_graphql'
import omit from 'lodash/omit'
import get from 'lodash/get'
import { Set } from 'immutable'
import memoize from 'memoizee'
import Result from 'src/utils/Result'
import { Dispatch } from 'redux'
import Action from 'src/redux/action'

type GraphQLData =
  | string
  | number
  | boolean
  | null
  | GraphQLObject
  | GraphQLArray

export type GraphQLObject = {
  [key: string]: GraphQLData
}

type GraphQLArray = GraphQLData[]

export type Changes = {
  [Key in keyof NormalizedData]?: {
    created: NormalizedData[Key]['id'][]
    updated: NormalizedData[Key]['id'][]
    deleted: Set<string>
  }
}

type UpdateState<State, Variables> = (
  state: State,
  changes: Changes,
  variables: Variables,
) => State

type MapState<State, View, Variables> = (
  state: State,
  offset: number | undefined,
  limit: number | undefined,
  operation: Operation<
    unknown,
    Variables extends {} ? Omit<Variables, 'offset' | 'limit'> : Variables
  >,
  dispatch: Dispatch<Action>,
) => View

type MapResult<State> = (
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  result: Result<any>,
  offset: number | null | undefined,
  limit: number | null | undefined,
  previousState: State | undefined,
) => Result<State>

type Page = { offset: number; limit: number }

type AdditionalPagesToLoad<State> = (state: State) => Page[]

export type QueryProperties<State, Variables, View> = {
  mapResult: MapResult<State>
  updateState: UpdateState<State, Variables>
  loadMore: (state: State) => State
  mapState: MapState<State, View, Variables>
  additionalPagesToLoad: AdditionalPagesToLoad<State>
  hitCDNCache?: true
}

export type Query<State, Variables, View = State> = {
  operation: Operation<
    unknown,
    Variables extends {} ? Omit<Variables, 'offset' | 'limit'> : Variables
  >
  offset?: number | null
  limit?: number | null
} & QueryProperties<State, Variables, View>

export type OperationFactory<Data, Variables> = (
  variables: Variables,
) => Operation<Data, Variables>

type QueryFactoryArguments<Data, Variables, State, Args, View> = {
  operation: OperationFactory<Data, Variables>
  comapVariables?: (args: Args) => Variables
  updateState?: UpdateState<State, Omit<Variables, 'offset' | 'limit'>>
  loadMore?: (state: State) => State
  mapState?: MapState<State, View, Variables>
  additionalPagesToLoad?: AdditionalPagesToLoad<State>
  hitCDNCache?: true
} & (
  | {
      mapData?: undefined
      mapResult?: MapResult<State>
    }
  | {
      mapData?: (data: Data) => State
      mapResult?: undefined
    }
)

export type QueryFactory<
  State,
  Variables,
  Args = Variables,
  View = State
> = Args extends undefined
  ? undefined extends Args
    ? () => Query<State, Variables, View>
    : (args?: Args) => Query<State, Variables, View>
  : {} extends Args
  ? (args?: Args) => Query<State, Variables, View>
  : (args: Args) => Query<State, Variables, View>

const extractOffsetAndLimit = <Data, Variables>({
  query,
  variables,
}: Operation<Data, Variables>) => ({
  operation: {
    query,
    variables: (typeof variables === 'object'
      ? omit((variables as unknown) as object, ['offset', 'limit'])
      : variables) as Variables extends {}
      ? Omit<Variables, 'offset' | 'limit'>
      : Variables,
  },
  offset: get(variables, 'offset'),
  limit: get(variables, 'limit'),
})

const factoryMapResult = memoize(
  <State, Data>(
    mapResult: MapResult<State> | undefined,
    mapData: ((data: Data) => State) | undefined,
  ): MapResult<State> =>
    mapResult ?? (mapData ? result => result.map(mapData) : id),
)

export const Query = <
  Data,
  Variables,
  State = Data,
  Args = Variables,
  View = State
>({
  operation,
  comapVariables,
  mapResult,
  mapData,
  updateState,
  loadMore,
  mapState,
  additionalPagesToLoad,
  hitCDNCache,
}: QueryFactoryArguments<Data, Variables, State, Args, View>): QueryFactory<
  State,
  Variables,
  Args,
  View
> =>
  ((comappedVariables: Args | undefined) => {
    const variables = comapVariables
      ? comapVariables(comappedVariables ?? ({} as Args))
      : ((comappedVariables as unknown) as Variables)
    const op = variables
      ? operation(variables)
      : (operation as () => Operation<State, Variables>)()
    return {
      ...extractOffsetAndLimit(op),
      mapResult: factoryMapResult(mapResult, mapData),
      updateState: updateState ?? id,
      loadMore: loadMore ?? id,
      mapState: mapState ?? id,
      additionalPagesToLoad: additionalPagesToLoad ?? empty,
      hitCDNCache,
    } as Query<State, Variables, View>
  }) as QueryFactory<State, Variables, Args, View>

const id = <T>(value: T) => value
const empty = <T>() => Array<T>()
