import { useEffect, useState } from '.'
import { Memoized } from './dependencies'

const DEFAULT_OPTIONS = {
  config: {
    childList: true,
    characterData: true,
    subtree: true,
  },
}

const useMutationObservable = (
  targetEl: Node | undefined,
  cb: Memoized<MutationCallback>,
  options = DEFAULT_OPTIONS,
) => {
  const [observer, setObserver] = useState<MutationObserver | null>(null)

  useEffect(() => {
    const obs = new MutationObserver(cb)
    setObserver(obs)
  }, [cb, options, setObserver])

  useEffect(() => {
    if (!observer) return
    const { config } = options
    if (targetEl) observer.observe(targetEl, config)
    return () => {
      if (observer) {
        observer.disconnect()
      }
    }
  }, [observer, targetEl, options])
}

export default useMutationObservable
