import {
  ReminderType,
  NewReminderType,
  Maybe,
} from '../../../__generated__/globalTypes'

import {
  DetailedOrderFragment,
  isDetailedOrderFragment,
} from './DetailedOrderFragment'

import minimalReminderFragmentRawString from '../MinimalReminderFragment.graphql'

export const minimalReminderFragmentString = minimalReminderFragmentRawString

export type MinimalReminderFragment = {
  __typename: 'Reminder'
  id: string
  type: ReminderType
  newType: NewReminderType
  message: Maybe<string>
  title: string
  eventDate: Maybe<string>
  campaign: Maybe<{
    id: string
    name: string
  }>
  contact: {
    id: string
    firstName: string
    lastName: string
    country: Maybe<string>
    birthday: Maybe<{
      day: number
      month: number
    }>
    anniversary: Maybe<{
      day: number
      month: number
    }>
  }
  order: Maybe<DetailedOrderFragment>
}

export const isMinimalReminderFragment = (
  fragment: any,
): fragment is MinimalReminderFragment =>
  fragment &&
  fragment.__typename === 'Reminder' &&
  typeof fragment.id === 'string' &&
  (fragment.type === 'ANNIVERSARY' ||
    fragment.type === 'BIRTHDAY' ||
    fragment.type === 'PROJECT') &&
  (fragment.newType === 'ANNIVERSARY' ||
    fragment.newType === 'BIRTHDAY' ||
    fragment.newType === 'CAMPAIGN') &&
  (fragment.message === null || typeof fragment.message === 'string') &&
  typeof fragment.title === 'string' &&
  (fragment.eventDate === null || typeof fragment.eventDate === 'string') &&
  (fragment.campaign === null ||
    (fragment.campaign &&
      typeof fragment.campaign.id === 'string' &&
      typeof fragment.campaign.name === 'string')) &&
  fragment.contact &&
  typeof fragment.contact.id === 'string' &&
  typeof fragment.contact.firstName === 'string' &&
  typeof fragment.contact.lastName === 'string' &&
  (fragment.contact.country === null ||
    typeof fragment.contact.country === 'string') &&
  (fragment.contact.birthday === null ||
    (fragment.contact.birthday &&
      typeof fragment.contact.birthday.day === 'number' &&
      typeof fragment.contact.birthday.month === 'number')) &&
  (fragment.contact.anniversary === null ||
    (fragment.contact.anniversary &&
      typeof fragment.contact.anniversary.day === 'number' &&
      typeof fragment.contact.anniversary.month === 'number')) &&
  (fragment.order === null ||
    (isDetailedOrderFragment(fragment.order) as boolean))
