import { Operation } from '../../../__generated__/globalTypes'

import createContactsWithInvitesString from '../CreateContactsWithInvites.graphql'

export type CreateContactsWithInvitesData = {
  createContactsWithInvites: {
    contactIds: string[]
    failedInviteIds: string[]
  }
}

export type CreateContactsWithInvitesVariables = {
  inviteIds: string[]
}

export type CreateContactsWithInvites = Operation<
  CreateContactsWithInvitesData,
  CreateContactsWithInvitesVariables
>

export const CreateContactsWithInvites = ({
  inviteIds,
}: CreateContactsWithInvitesVariables): CreateContactsWithInvites => ({
  query: createContactsWithInvitesString,
  variables: {
    inviteIds,
  },
})
