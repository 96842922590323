import { CurrencyType } from 'src/graphql/generated/graphql'
import { OrderWithPartialLines } from 'src/redux/reducers/orders'

const willUsePoints = (order: OrderWithPartialLines) => {
  if (order.isBulk) return false
  for (const total of order.cost.total) {
    if (
      total.currency === CurrencyType.Point ||
      total.currency === CurrencyType.Expense
    ) {
      return true
    }
  }
  return false
}

export default willUsePoints
