import {
  Anchor,
  Button,
  Checkbox,
  Flex,
  Input,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import React, { FC } from 'react'
import { AccountFragment } from 'src/graphql/generated/graphql'
import {
  useActions,
  usePersistedUserData,
  useQueryParams,
  useState,
  useVertical,
} from 'src/hooks'
import { useLoginUser } from 'src/react_query'
import Result from 'src/utils/Result'

import { LoginPhoneInput } from './LoginPhoneNumber/LoginPhoneInput'

interface LoginFormState {
  username: string
  password: string
  phoneNumber: string
}

type LoginFormProps = {
  handleNotAUserYet: () => void
  onComplete: (account: AccountFragment) => void
}

export const LoginForm: FC<LoginFormProps> = ({
  handleNotAUserYet,
  onComplete,
}) => {
  const { controlData, phoneVerification } = usePersistedUserData()
  const loginMutation = useLoginUser()
  const vertical = useVertical()
  const actions = useActions()
  const queryParams = useQueryParams()
  const [shouldLoginWithPhone, setShouldLoginWithPhone] = useState(false)

  const [didForgetLoginInfo, setDidForgetLoginInfo] = useState(false)
  const [isLoggingIn, setIsLoggingIn] = useState(false)
  const [loginErrorMessage, setLoginErrorMessage] = useState<
    string | undefined
  >()
  const [isRememberMeChecked, setIsRememberMeChecked] = useState(true)

  const loginPhoneNumber = !controlData.isLoggedIn
    ? phoneVerification.loginPhoneNumber
    : undefined

  const [values, setValues] = useState<LoginFormState>({
    username: '',
    password: '',
    phoneNumber: loginPhoneNumber?.nationalNumber ?? '',
  })

  const handleSetPhoneNumber = (pNumber: string) => {
    handleChange('phoneNumber', pNumber)
  }

  const handleChange = (name: keyof LoginFormState, value: string) => {
    setValues({ ...values, [name]: value })
  }

  const loginUser = async () => {
    setIsLoggingIn(true)
    setLoginErrorMessage(undefined)
    const {
      loginUser: { account, message },
    } = await loginMutation.mutateAsync({
      username: values.username,
      password: values.password,
      verticalId: vertical.graphqlVerticalID,
      queryParams: {
        redirect_uri: queryParams.redirect_uri,
      },
    })

    if (account) {
      if (values) {
        setValues(x => ({ ...x, password: '' }))
      }

      actions.saveToken(account.token)
      actions.loginDone(Result(account), isRememberMeChecked)
      onComplete(account)

      window._cio.identify({
        id: account.id,
        first_name: account.firstName,
        last_name: account.lastName,
      })
    } else {
      setLoginErrorMessage(
        message === 'Invalid Credentials'
          ? 'Wrong username or password'
          : message
          ? message
          : 'Unable to login.',
      )
    }
    setIsLoggingIn(false)
  }

  return (
    <Flex flexDirection="column" style={{ height: '100%' }}>
      <Flex justifyContent="center" outset={{ bottom: 'x2' }}>
        {/* TODO: properly add pill selector */}
        <Button
          type="primary"
          size="small"
          onClick={() => setShouldLoginWithPhone(!shouldLoginWithPhone)}
          backgroundColorOverride="#00aeef"
          title={
            shouldLoginWithPhone
              ? 'Login with username & password'
              : 'Login with phone number'
          }
        />
      </Flex>
      <Flex justifyContent="center">
        <Flex flexDirection="column">
          {shouldLoginWithPhone ? (
            <LoginPhoneInput
              phoneNumber={values.phoneNumber}
              handleSetPhoneNumber={handleSetPhoneNumber}
              onLoginSuccess={onComplete}
            />
          ) : (
            <>
              <Text type="body" outset={{ vertical: 'x2' }}>
                We are part of Promptings Company, so if you <i>already</i> have
                an account from any of our product streams you can use those
                credentials to sign in
              </Text>
              <Input
                onChange={username => handleChange('username', username)}
                type="text"
                name="username"
                value={values.username}
                placeholder="Username"
                icon="user"
                isFullWidth={true}
                id="username_input"
              />
              <Text
                type="footnote"
                content={'Usernames are case sensitive'}
                outset="x1"
              />
              <Spacer space="x1" />
              <Input
                onChange={password => handleChange('password', password)}
                type="password"
                name="password"
                value={values.password}
                placeholder="Password"
                message={
                  loginErrorMessage
                    ? {
                        type: 'danger',
                        content: loginErrorMessage, // TODO: handle error message here
                      }
                    : undefined
                }
                icon="lock"
                isFullWidth={true}
                id="password_input"
              />
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Anchor
                  size="xSmall"
                  title="Forgot password?"
                  color="anchorBlue"
                  isDecorated={true}
                  onClick={() => {
                    setValues({ ...values })
                    setDidForgetLoginInfo(!didForgetLoginInfo)
                  }}
                />
              </div>
              <div style={{ display: 'flex', marginBottom: '1rem' }}>
                <Checkbox
                  onChange={setIsRememberMeChecked}
                  isChecked={isRememberMeChecked}
                  label="Remember Me"
                  type="primary"
                />
              </div>
              <Flex
                outset={{ top: 'auto' }}
                justifyContent="flex-end"
                alignItems="center"
                columnGap="x2"
              >
                <Anchor
                  title="Not a user yet?"
                  onClick={handleNotAUserYet}
                  isDecorated={true}
                />
                <div style={{ width: '50%' }}>
                  <Button
                    fullWidth={true}
                    disabled={false}
                    size="small"
                    disabledCaption={
                      'Please fill in the required information to continue'
                    }
                    title={!isLoggingIn ? 'Log In' : 'Logging in...'}
                    backgroundColorOverride="#00aeef"
                    onClick={() => {
                      loginUser()
                    }}
                  />
                </div>
              </Flex>
            </>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}
