import { Flex } from '@sendoutcards/quantum-design-ui'
import React from 'react'

type NumberInputProps = {
  lineQuantity: number
  onChange: (value: number) => void
  min?: number
  quantityAvailable?: number
  step?: number
  isDisabled?: boolean
}

export const NumberInput = (props: NumberInputProps) => {
  const {
    lineQuantity,
    onChange,
    min = 1,
    quantityAvailable = 100,
    step = 1,
    isDisabled = false,
  } = props

  const handleIncrease = () => {
    if (lineQuantity < quantityAvailable && !isDisabled) {
      onChange(lineQuantity + step)
    }
  }

  const handleDecrease = () => {
    if (lineQuantity > min && !isDisabled) {
      onChange(lineQuantity - step)
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value, 10)
    if (newValue >= min && newValue <= quantityAvailable && !isDisabled) {
      onChange(newValue)
    }
  }

  const buttonStyles = {
    backgroundColor: isDisabled ? '#e0e0e0' : '#eaebec',
    color: '#000',
    border: 'none',
    padding: '.25rem .6rem',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    transition: 'background-color 0.3s',
    fontWeight: 600,
  }

  return (
    <Flex
      alignItems="center"
      flexDirection="row"
      style={{ fontFamily: 'Montserrat, san serif' }}
    >
      <button
        onClick={handleDecrease}
        disabled={isDisabled}
        style={{
          ...buttonStyles,
          borderTopLeftRadius: '10px',
          borderBottomLeftRadius: '10px',
        }}
      >
        -
      </button>
      <input
        value={lineQuantity.toString()}
        onChange={handleChange}
        min={min}
        max={quantityAvailable}
        step={step}
        disabled={isDisabled}
        style={{
          width: '50px',
          textAlign: 'center',
          border: '1px solid #ccc',
          borderRadius: '4px',
          padding: '.25rem 1rem',
          fontSize: '.875rem',
          color: isDisabled ? '#aaa' : '#000',
          backgroundColor: isDisabled ? '#f9f9f9' : 'f5f6f7',
          fontFamily: 'Montserrat, san serif',
          fontWeight: 600,
        }}
      />
      <button
        onClick={handleIncrease}
        disabled={isDisabled}
        style={{
          ...buttonStyles,
          borderTopRightRadius: '10px',
          borderBottomRightRadius: '10px',
        }}
      >
        +
      </button>
    </Flex>
  )
}
