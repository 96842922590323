import React from 'react'

type HeartFeltStepThreeType = {
  height?: number
  width?: number
  primaryColor?: string
  secondaryColor?: string
}

const HeartFeltStepThree: React.FC<HeartFeltStepThreeType> = ({
  height = 276,
  width = 358,
  primaryColor = '#29D2E1',
  secondaryColor = '#56DEE2',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 360 276"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#a)">
      <path
        d="M304.214 125.263c1.708 0 3.093-1.51 3.093-3.374 0-1.863-1.385-3.373-3.093-3.373s-3.092 1.51-3.092 3.373c0 1.864 1.384 3.374 3.092 3.374Zm-3.602 7.119c1.312 0 2.375-1.157 2.375-2.583 0-1.427-1.063-2.583-2.375-2.583s-2.375 1.156-2.375 2.583c0 1.426 1.063 2.583 2.375 2.583Zm-5.755-7.119c1.312 0 2.375-1.156 2.375-2.583 0-1.426-1.063-2.583-2.375-2.583s-2.375 1.157-2.375 2.583c0 1.427 1.063 2.583 2.375 2.583Zm-1.945 10.282c1.594 0 2.886-1.402 2.886-3.131 0-1.73-1.292-3.132-2.886-3.132-1.593 0-2.885 1.402-2.885 3.132 0 1.729 1.292 3.131 2.885 3.131Zm8.066 4.762c1.515 0 2.742-1.337 2.742-2.986 0-1.649-1.227-2.986-2.742-2.986-1.514 0-2.741 1.337-2.741 2.986 0 1.649 1.227 2.986 2.741 2.986Zm7.557 3.955c1.311 0 2.375-1.156 2.375-2.583 0-1.426-1.064-2.582-2.375-2.582-1.312 0-2.376 1.156-2.376 2.582 0 1.427 1.064 2.583 2.376 2.583Zm1.434-9.992c1.471 0 2.663-1.293 2.663-2.889 0-1.596-1.192-2.89-2.663-2.89-1.47 0-2.662 1.294-2.662 2.89s1.192 2.889 2.662 2.889Z"
        fill="#EAF3F3"
      />
      <path
        d="M289.596 143.277s-26.207 23.955-34.847 87.264m51.41-80.242s2.439 18.096 0 32.559m14.842-44.907s19.448 3.164 25.203 14.253m-25.936-27.7s7.891-1.646 11.876.759m-23.386-17.385s2.375-13.672-2.057-23.454m-15.957 26.618s-5.93-13.172-15.208-14.9m11.876 31.074s-10.346-5.101-20.421.436"
        stroke="#EAF3F3"
        strokeWidth={5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M225.412 64.599c.952-1.926.142-4.263-1.81-5.218-1.953-.956-4.308-.17-5.261 1.757-.953 1.926-.143 4.263 1.81 5.218 1.952.956 4.308.17 5.261-1.757Zm-.739 9.799c.733-1.48.107-3.277-1.398-4.014-1.504-.736-3.317-.133-4.05 1.348-.732 1.48-.106 3.277 1.398 4.014 1.505.736 3.318.133 4.05-1.348Zm10.22-4.135c.732-1.48.106-3.278-1.398-4.014-1.504-.736-3.318-.133-4.05 1.347-.732 1.48-.107 3.278 1.398 4.014 1.504.737 3.318.133 4.05-1.347ZM232.7 81.685c.886-1.791.132-3.964-1.685-4.853-1.816-.89-4.007-.158-4.893 1.633-.886 1.79-.132 3.963 1.685 4.853 1.816.889 4.007.158 4.893-1.633Zm-11.885.498c.843-1.704.125-3.77-1.604-4.616-1.728-.846-3.813-.151-4.655 1.552-.843 1.704-.125 3.77 1.604 4.616 1.728.846 3.812.151 4.655-1.552Zm-11.288.064c.733-1.48.107-3.278-1.397-4.014-1.505-.736-3.318-.133-4.05 1.347-.733 1.481-.107 3.278 1.397 4.014 1.505.737 3.318.134 4.05-1.347Zm3.955-11.293c.819-1.656.121-3.665-1.56-4.487-1.68-.823-3.706-.148-4.525 1.508-.819 1.656-.121 3.665 1.559 4.487 1.681.823 3.707.147 4.526-1.508Z"
        fill="#EAF3F3"
      />
      <path
        d="M227.637 93.182s17.698 39.435-4.828 109.759M205.126 91.174s-12.046 17.348-16.665 33.685m6.024-54.759s-23.835-7.598-36.091.642m43.793-14.152s-8.187-6.12-13.952-5.846m35.623-4.9s4.28-15.469 14.356-23.114m4.602 36.463s13.516-10.297 24.998-6.907m-29.471 25.508s14.437.514 23.124 11.886"
        stroke="#EAF3F3"
        strokeWidth={5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.726 111.962c1.17-1.237 1.01-3.274-.355-4.55-1.366-1.276-3.422-1.308-4.591-.07-1.17 1.237-1.01 3.275.355 4.551 1.366 1.276 3.421 1.307 4.59.069Zm2.751 7.485c.898-.95.779-2.512-.267-3.489-1.046-.977-2.621-.998-3.52-.048-.898.95-.779 2.512.267 3.489 1.046.977 2.621.999 3.52.048Zm-9.159-.706c.898-.95.779-2.512-.267-3.489-1.046-.977-2.622-.998-3.52-.048-.898.95-.779 2.513.267 3.489 1.045.977 2.621.999 3.52.048Zm6.205 8.451c1.092-1.155.948-3.051-.32-4.235-1.267-1.184-3.18-1.209-4.27-.054-1.092 1.154-.949 3.05.319 4.235 1.268 1.184 3.18 1.208 4.271.054Zm9.015-2.583c1.037-1.097.897-2.902-.312-4.031-1.209-1.13-3.03-1.156-4.066-.059-1.037 1.097-.898 2.902.311 4.031 1.209 1.13 3.03 1.156 4.067.059Zm8.073-2.766c.899-.95.78-2.512-.266-3.489-1.046-.977-2.622-.998-3.52-.048-.899.95-.78 2.512.266 3.489 1.046.977 2.622.999 3.52.048Zm-6.341-7.882c1.006-1.065.874-2.814-.296-3.907s-2.934-1.115-3.94-.05c-1.008 1.065-.876 2.815.294 3.907 1.17 1.093 2.935 1.116 3.941.05Z"
        fill="#EAF3F3"
      />
      <path
        d="M53.92 134.889s-.387 35.39 40.105 85.003M70.41 127.698s14.935 10.625 23.867 22.296M71.522 108.49s15.637-11.922 27.707-8.497m-38.066-.18s4.196-6.844 8.689-8.084m-28.759 5.037s-8.396-11.084-18.601-14.572m8.584 29.788s-13.716-4.724-21.336.815m30.911 12.675s-10.824 4.002-13.665 15.092"
        stroke="#EAF3F3"
        strokeWidth={5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M191.153 94.74c4.691 3.052 9.07 6.466 12.719 10.707-7.871 1.086-14.074 5.017-18.557 11.431-5.004 7.19-5.942 15.569-6.463 23.948-.522 7.811.312 15.673-.469 23.535-.47 4.913-1.564 9.568-3.389 14.12-1.46 5.165-6.016 14.324-5.108 23.276.73 7.189 3.91 13.034 11.103 15.206 2.606.776 5.317 1.294 7.871 2.225 4.118 1.5 7.766 3.568 7.975 8.689-11.363 9.621-24.499 15.724-39.094 18.31-21.579 3.828-41.804-.103-60.517-11.534-.417-.259-.73-.569-1.094-.879.73-.466.782-1.345.99-2.069 1.355-4.811 4.431-8.121 8.966-10.19 3.231-1.5 6.567-2.534 9.903-3.672 4.014-1.345 7.558-3.259 10.477-6.414 2.815-3.052 3.597-6.983 1.981-10.758-1.512-3.518-4.431-5.742-7.193-8.173-2.45-2.172-5.213-4.034-7.454-6.362-3.232-3.31-5.213-7.189-3.753-12 1.095-3.62 3.336-6.517 5.786-9.207 5.681-6.362 9.851-13.241 9.226-22.189-.313-4.448-2.137-8.069-5.786-10.707-4.431-3.155-9.747-3.62-14.855-4.655-10.009-2.017-16.42-6.827-17.202-16.551.157-.207.26-.414.417-.569 15.846-15.931 35.132-24.57 57.598-25.914 14.96-.88 29.19 2.224 42.638 8.793.574.31 1.147.88 1.929.414 0 .776.417.776.938.517-.365.259-.156.517 0 .672.052.052.313-.103.469-.155l-.052.156Zm-71.046 19.552h17.201c.522 0 1.303 0 1.512-.259.469-.569-.209-1.138-.521-1.603-2.085-3-5.004-4.552-8.601-4.448-1.512 0-2.346-.311-3.18-1.604-3.075-4.81-9.017-7.086-14.595-5.844-5.681 1.293-9.903 6.051-10.633 11.793-.209 1.655.261 2.017 1.824 2.017 5.682-.052 11.311 0 16.993 0v-.052Z"
        fill={primaryColor}
      />
      <path
        d="M189.695 93.758c-.782.465-1.355-.155-1.929-.414-13.448-6.62-27.678-9.672-42.638-8.793-22.466 1.293-41.752 9.93-57.598 25.914-.156.155-.26.362-.417.568-6.15 5.845-10.686 12.828-14.647 20.224-.573 1.035-.313 1.914.156 2.845 2.138 4.19 5.265 7.345 9.383 9.517 3.492 1.811 7.037 3.466 10.477 5.328 6.828 3.672 9.33 8.948 8.653 15.724-.417 3.931-1.303 7.81-1.981 11.741-.99 5.586-1.407 11.069 2.71 15.724 1.616 1.81 3.389 3.466 5.057 5.224 5.16 5.379 4.065 11.276-2.763 14.431-3.18 1.5-6.62 1.759-10.112 1.914-4.483.207-8.966.517-12.77 3.362-.94.724-1.93 1.345-1.721 2.793-2.763-2.224-4.691-5.172-6.724-7.965-35.55-49.81-6.62-120.258 53.897-131.223 21.788-3.931 42.117 0 60.986 11.586.678.414 1.564.672 1.929 1.552l.052-.052Z"
        fill="#FDFDFD"
      />
      <path
        d="M79.66 219.86c-.208-1.397.782-2.069 1.72-2.793 3.805-2.793 8.288-3.155 12.77-3.362 3.493-.155 6.881-.414 10.113-1.914 6.776-3.207 7.923-9.103 2.763-14.431-1.668-1.758-3.493-3.414-5.056-5.224-4.118-4.655-3.701-10.189-2.71-15.724.677-3.931 1.563-7.81 1.98-11.741.678-6.776-1.825-12.052-8.653-15.724-3.44-1.862-7.037-3.517-10.477-5.328-4.17-2.172-7.297-5.379-9.382-9.517-.47-.931-.73-1.81-.157-2.845 3.962-7.396 8.549-14.379 14.647-20.224.782 9.724 7.246 14.483 17.201 16.552 5.109 1.034 10.425 1.5 14.856 4.655 3.649 2.586 5.473 6.207 5.786 10.707.625 8.948-3.545 15.827-9.226 22.189-2.45 2.742-4.691 5.638-5.786 9.207-1.46 4.862.521 8.741 3.753 12 2.293 2.328 5.004 4.19 7.454 6.362 2.762 2.431 5.681 4.655 7.193 8.172 1.616 3.828.834 7.707-1.981 10.759-2.919 3.155-6.515 5.017-10.477 6.414-3.336 1.138-6.724 2.172-9.904 3.672-4.534 2.121-7.61 5.379-8.965 10.19-.209.724-.26 1.551-.99 2.068-5.421-3.362-10.478-7.137-14.595-12.103-.417-.517-1.46-.31-1.356-1.293 0-.362 0-.672-.52-.724Z"
        fill={secondaryColor}
      />
      <path
        d="M216.122 121.792c5.473 8.896 8.653 18.569 10.216 28.81 2.815 18.31-.208 35.741-8.861 52.189-.313.569-.625 1.034-.99 1.603-1.981-.982-3.597-2.482-5.369-3.775-3.44-2.483-7.089-3.466-11.311-2.483-2.867.672-5.786 1.552-8.757 1.758-7.35.466-11.103-4.189-8.913-10.862.677-2.017 1.824-3.827 3.231-5.379 5.734-6.517 7.923-14.172 7.663-22.706-.157-5.897-.469-11.845-.417-17.742 0-5.12.677-10.241 5.056-13.81 5.369-4.345 11.467-6.931 18.452-7.552v-.051Z"
        fill="#FEFDFD"
      />
      <path
        d="M216.122 121.792c-6.932.621-13.083 3.207-18.452 7.552-4.378 3.569-5.056 8.637-5.056 13.81 0 5.896.261 11.845.417 17.741.261 8.483-1.928 16.19-7.662 22.707-1.408 1.603-2.554 3.362-3.232 5.379-2.189 6.672 1.564 11.327 8.913 10.862 2.972-.207 5.838-1.086 8.757-1.759 4.222-.982 7.923 0 11.311 2.483 1.773 1.293 3.389 2.793 5.369 3.776-4.952 9.207-11.571 17.069-19.703 23.638-.208-5.121-3.857-7.19-7.975-8.69-2.554-.931-5.265-1.448-7.871-2.224-7.193-2.172-10.425-8.017-11.102-15.207-.678-6.672 2.293-15.39 3.7-19.034 1.408-3.644.746-2.156 1.408-4.293 1.824-4.552 2.919-9.259 3.388-14.121.782-7.862 0-15.724.469-23.534.573-8.379 1.46-16.758 6.464-23.948 4.482-6.465 10.633-10.345 18.556-11.431a66.785 66.785 0 0 1 12.197 16.293h.104Z"
        fill={secondaryColor}
      />
      <path
        d="M142.261 247.48c46.838 0 84.807-37.677 84.807-84.154s-37.969-84.154-84.807-84.154c-46.837 0-84.807 37.677-84.807 84.154s37.97 84.154 84.807 84.154Z"
        stroke={secondaryColor}
        strokeMiterlimit={10}
      />
      <path
        d="M125.669 164.727c5.138 0 9.304-4.142 9.304-9.251 0-5.109-4.166-9.251-9.304-9.251-5.139 0-9.304 4.142-9.304 9.251 0 5.109 4.165 9.251 9.304 9.251Zm41.879 0c5.138 0 9.304-4.142 9.304-9.251 0-5.109-4.166-9.251-9.304-9.251-5.139 0-9.305 4.142-9.305 9.251 0 5.109 4.166 9.251 9.305 9.251Zm-5.049 5.202h-31.211c-6.633 0-10.336 7.456-6.191 12.494 11.663 14.179 30.769 14.179 42.432 0l.718-.861c3.851-4.69.424-11.633-5.748-11.633Zm-17.043 18.502s-9.304 0-13.965-4.634c0 0 13.081-13.886 29.81 0 0 0-1.879 4.634-15.845 4.634Z"
        fill="#fff"
      />
      <path
        d="M193.658 211.274s-31.536 30.678-23.365 37.574c8.171 6.895 31.556-17.209 31.556-17.209l-8.191-20.365Z"
        fill="#A47F4F"
      />
      <path
        d="M291.726 159.106s-53.612 7.599-57.597 8.745l-36.969 3.337s-9.902-1.105-9.902 7.68 5.595 66.381 5.595 66.381-.624 8.725 8.996 7.961c9.619-.764 97.565-9.549 97.565-9.549s5.152 0 3.663-7.679c-1.489-7.68-6.963-70.302-6.963-70.302s0-5.488-4.408-6.594l.02.02Z"
        fill="#BC985E"
      />
      <path
        d="M196.073 174.485s2.193 14.274 3.3 24.165c1.107 9.891 3.301 35.14 3.301 35.14l1.107 15.38m-1.429-74.685 6.923 5.489m-1.127 69.195 6.621-7.699m76.957-76.876-4.407 5.489m12.095 69.196-4.387-4.383"
        stroke="#A47F4F"
        strokeWidth={0.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M281.825 173.38s-52.767 6.594-67.056 7.679c0 0-2.194 0-2.194 3.297 0 6.453.845 21.973 4.106 52.169 0 0 .281 3.839 5.232 3.297 4.951-.543 68.707-6.032 68.707-6.032s2.193 0 2.193-3.296c0-2.192-5.494-45.032-6.601-56.009-.201-2.11-4.045-.924-4.407-1.105h.02Z"
        fill="#fff"
        stroke="#A47F4F"
        strokeWidth={0.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M218.069 228.302s27.491-28.547 30.791-30.758c3.301-2.212 5.495-1.106 5.495 4.402 0 5.509-.866 17.571-1.53 19.762-.664 2.191 11.431-19.762 15.818-21.953 4.387-2.191 4.408 1.106 4.408 2.191 0 1.086 0 10.977-1.107 13.188-1.107 2.212 12.095-10.976 15.395-10.976"
        stroke="#4E6868"
        strokeWidth={0.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M237.852 208.52s4.407 2.191 10.988-1.105c0 0 .805 3.699 5.353-.342m10.184-2.875s.905 5.75 8.633.644"
        stroke="#4E6868"
        strokeWidth={0.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M230.164 200.841a3.299 3.299 0 0 0 3.301-3.297 3.299 3.299 0 0 0-3.301-3.297 3.299 3.299 0 0 0-3.3 3.297 3.299 3.299 0 0 0 3.3 3.297Z"
        stroke="#FBD832"
        strokeMiterlimit={10}
      />
      <path
        d="M217.526 208.158c0-.181 5.917-1.085 5.917-1.085m14.973-17.57c0-.181 5.916-1.086 5.916-1.086M271.4 185.1c0-.181 5.917-1.086 5.917-1.086m-14.268 11.781 2.857-.523m13.544-3.96 2.858-.523m-64.339 6.111.644.04"
        stroke="#72CFD9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m67.998 195.775-26.706-.865s-11.672-.965-11.45 11.339c.2 12.303-.464 33.331-.464 33.331s-.523 9.811 13.363 9.831c13.886.04 52.144.301 52.144.301s9.116.503 9.459-11.78c.342-12.284.764-32.548.764-32.548s.765-7.921-12.115-8.564c-12.88-.623-25.015-1.045-25.015-1.045h.02Z"
        fill="#EDEDED"
      />
      <path
        opacity={0.5}
        d="M100.359 203.173a.815.815 0 0 0-1.107.12c-.201.242-19.26 24.185-32.1 24.084-13.322-.1-32.3-24.968-32.48-25.209a.775.775 0 0 0-1.088-.161.791.791 0 0 0-.16 1.085c.04.061 4.93 6.474 11.43 12.806a80.45 80.45 0 0 0 5.796 5.167c-3.26 2.271-11.733 14.776-12.095 15.319a.793.793 0 0 0 .201 1.085.93.93 0 0 0 .202.101c.322.1.684 0 .865-.302 2.435-3.598 9.258-13.288 11.733-14.937.12-.08.16-.201.221-.321 4.045 3.136 7.728 5.227 11.049 6.232 1.51.462 2.938.683 4.286.703 4.408.041 9.781-2.432 16-7.277 6.319 4.101 12.94 16.545 13.02 16.666a.785.785 0 0 0 .463.382c.181.06.403.04.584-.061a.772.772 0 0 0 .322-1.045c-.282-.523-6.621-12.424-13.142-16.967a96.491 96.491 0 0 0 4.65-4.041c6.48-6.071 11.37-12.243 11.43-12.303a.813.813 0 0 0-.121-1.106l.041-.02Z"
        fill="#000"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.355 0h359.29v276H.355z" />
      </clipPath>
    </defs>
  </svg>
)
export default HeartFeltStepThree
