import {
  CardFragment,
  CardPaperType,
  CurrencyType,
  LabeledPrice,
  Maybe,
  SendDelayFragment,
  SendDelayTimeType,
  SendDelayType,
} from 'src/graphql/generated/graphql'
import { getAfterDelayDate, ordinalDate } from 'src/orders/sendDelayOptions'
import { PartialLine } from 'src/helpers/multitouch'
import { formatCost } from 'src/helpers'
import { OrderWithPartialLines } from 'src/redux/reducers/orders'
import { isFileUnavailable } from 'src/helpers/isFileUnavailable'

export const countLineItems = (lines: PartialLine[]) => {
  if (lines.length === 0) return 0
  return lines
    .map(line => {
      let mutableCurrentCount = 0
      if (line.card) {
        mutableCurrentCount += 1
      }
      if (line.giftVariation) {
        mutableCurrentCount += 1
      }
      return mutableCurrentCount
    })
    .reduce((prev, curr) => prev + curr)
}

export const getSendDelayText = (sendDelay: SendDelayFragment) => {
  switch (sendDelay.type) {
    case SendDelayType.Imm:
      if (
        sendDelay.timeType === SendDelayTimeType.Aft &&
        !!sendDelay.delayNumber
      )
        return getAfterDelayDate(sendDelay)
      else {
        return 'Immediate'
      }
    case SendDelayType.Ann:
      return 'Arrive on anniversary'
    case SendDelayType.Bir:
      return 'Arrive on birthday'
    case SendDelayType.Spe:
      return 'Send ' + ordinalDate(sendDelay, 'MMM Do, YYYY')
  }
}

export const getPanelSize = (card: CardFragment) => {
  if (card.type === 'TWO_PANEL_BIG') {
    return 'L'
  } else {
    return 'M'
  }
}

export const getCardType = (card: CardFragment) => {
  switch (card.type) {
    case 'POSTCARD':
      return 'Postcard'
    case 'FLATCARD':
      return 'Flat Card'
    case 'THREE_PANEL':
      return '3 Panel'
    case 'TWO_PANEL_BIG':
      return '2 Panel Big'
    default:
      return '2 Panel'
  }
}

export const mapPanelSize = (card: CardFragment) =>
  card.type === 'TWO_PANEL_BIG' ? 'L' : 'M'

export const getPrice = (entries: LabeledPrice[] | undefined, label: string) =>
  entries ? entries.find(entry => entry.label === label) : undefined

// Gifting Fee
export const getTotalGiftFeeAmount = (order?: OrderWithPartialLines) => {
  return order
    ? order.lines.reduce((acc, line) => {
        const lineGiftFee = line.cost?.entries.find(
          entry => entry.label === 'Gift Processing Fees',
        )?.amount
        return lineGiftFee ? acc + lineGiftFee : acc
      }, 0)
    : 0
}

/**
 *
 * @param hasSubShippingDiscount Taken from the user subscription
 * @param order
 */
export const getShippingInfo = (
  giftShippingDiscount: number,
  order?: OrderWithPartialLines,
) => {
  // Only orders with gifts can have discounts in shipping
  const hasGift = order?.lines.some(line => !!line.giftVariation)

  const shippingAmount =
    order && order.cost.entries.length > 0
      ? order.cost.entries.filter(entry => entry.label === 'Shipping')[0].amount
      : 0

  // TODO: Currrently we are using an approximate discount, lets update later to receive it from the backend
  const totalAmount =
    hasGift && giftShippingDiscount
      ? Math.floor(shippingAmount / (1 - giftShippingDiscount))
      : shippingAmount

  const shippingDiscountAmount = totalAmount - shippingAmount

  const hasShippingDiscount = shippingDiscountAmount > 0

  const totalShippingText =
    order && order.cost.entries.length > 0 ? formatCost(totalAmount) : '$-.--'

  return {
    shippingAmount,
    shippingDiscountAmount,
    totalShippingText,
    hasShippingDiscount,
  }
}

// Even though OrderLines.baseCost returns single card costs, it doesn't return FREE cards values there
// we need to get those from OrderLines.cost
export const getSingleCardFinalCost = (orderLine: PartialLine) => {
  const totalCardCost = getPrice(orderLine.cost?.entries, 'Card Cost')
  if (totalCardCost?.currency === CurrencyType.Free) {
    return totalCardCost
  } else {
    return getPrice(orderLine.baseCost?.entries, 'Card Cost')
  }
}

export const getTotalCardCost = (
  cardCost?: LabeledPrice,
  cardUpgradesCost?: LabeledPrice,
) => {
  if (cardCost && cardUpgradesCost) {
    if (
      cardCost.currency === CurrencyType.Usd ||
      cardCost.currency === CurrencyType.Expense
    ) {
      return formatCost(cardCost.amount + cardUpgradesCost.amount)
    } else if (cardCost.currency === CurrencyType.Point) {
      return `${cardCost.amount} Points + ${cardUpgradesCost.asString}`
    } else {
      return cardUpgradesCost.asString
    }
  } else if (cardCost) {
    return cardCost.asString
  } else {
    return 'Could not calculate price.'
  }
}

export const getDiscountedPrice = (
  cardBaseCost: LabeledPrice,
  cardFinalCost: LabeledPrice,
  cardUpgradesCost?: LabeledPrice,
) => {
  const totalFinalCost = getTotalCardCost(cardFinalCost, cardUpgradesCost)
  const totalBaseCost = getTotalCardCost(cardBaseCost, cardUpgradesCost)
  if (
    (cardFinalCost.currency === CurrencyType.Free &&
      totalFinalCost !== totalBaseCost) ||
    ((cardBaseCost.currency === CurrencyType.Usd ||
      cardBaseCost.currency === CurrencyType.Expense) &&
      (cardFinalCost.currency === CurrencyType.Usd ||
        cardFinalCost.currency === CurrencyType.Expense) &&
      cardBaseCost.amount > cardFinalCost.amount)
  ) {
    return totalFinalCost
  } else {
    return undefined
  }
}

export const getTotalFromLines = (order?: OrderWithPartialLines) => {
  return order
    ? order.lines.reduce((acc, line) => {
        const lineCost =
          line.cost && line.cost.total[0]
            ? line.cost.total
                .filter(
                  total =>
                    total.currency === CurrencyType.Usd ||
                    total.currency === CurrencyType.Expense,
                )
                .reduce((acc, total) => {
                  return acc + total.amount
                }, 0)
            : 0
        return acc + lineCost
      }, 0)
    : 0
}

/**
 * Will return a formated string combining USD amount and Points amount accordingly
 * @param usdAmount (number) USD amount
 * @param pointAmount (number) Point amount
 */
export const getTotalText = (usdAmount: number, pointAmount: number) => {
  if (usdAmount > 0 && pointAmount > 0) {
    return `${pointAmount} Points + ${formatCost(usdAmount)}`
  } else if (usdAmount === 0 && pointAmount > 0) {
    return `${pointAmount} Points`
  } else {
    return formatCost(usdAmount)
  }
}

export const getSubTotalText = (order?: OrderWithPartialLines) => {
  const subTotalUSDAmount = order
    ? getOrderCardAndGiftFullUSDCost(order.lines)
    : 0

  const subTotalPointsAmout = order
    ? getOrderCardAndGiftFullCost(order.lines, CurrencyType.Point)
    : 0
  return getTotalText(subTotalUSDAmount, subTotalPointsAmout)
}

// Get order USD subtotal with discounts applied and without shipping
export const getSubTotal = (order?: OrderWithPartialLines) => {
  return order && order.cost.entries.length > 0
    ? order.cost.entries
        .filter(
          entry =>
            entry.label !== 'Shipping' &&
            entry.currency !== 'POINT' &&
            entry.currency !== 'CARDTOKEN',
        )
        .reduce((acc, entry) => {
          return acc + entry.amount
        }, 0)
    : getTotalFromLines(order)
}

export const getUSDGrandTotalArr = (order: OrderWithPartialLines) => {
  return order.cost.total.filter(
    total => total.currency === 'USD' || total.currency === 'EXPENSE',
  )
}

export const getUSDGrandTotalAmmount = (
  order: OrderWithPartialLines | undefined,
) => {
  const usdGrandTotalArr = order ? getUSDGrandTotalArr(order) : undefined
  return usdGrandTotalArr &&
    usdGrandTotalArr.reduce((acc, price) => acc + price.amount, 0) > 0
    ? usdGrandTotalArr.reduce((acc, price) => acc + price.amount, 0)
    : order
    ? getSubTotal(order)
    : 0
}

// Grand total might return non USD values (e.g: Other currencies, Free orders)
// and might not be available under certain scenarios (e.g: orders without recipients)
export const getGrandTotal = (order: OrderWithPartialLines | undefined) => {
  const usdGrandTotalArr = order ? getUSDGrandTotalArr(order) : undefined
  const pointGrandTotal = order?.cost.total.find(
    total => total.currency === CurrencyType.Point,
  )

  const usdGrandTotal =
    usdGrandTotalArr && usdGrandTotalArr.length > 0
      ? usdGrandTotalArr[0].asString
      : undefined

  if (pointGrandTotal && usdGrandTotal) {
    return `${pointGrandTotal.amount} Points + ${usdGrandTotal}`
  } else if (!usdGrandTotal && pointGrandTotal) {
    return `${pointGrandTotal.amount} Points`
  }

  return order && order.cost.total.length > 0
    ? usdGrandTotal ?? order.cost.total[0].asString
    : '$-.--'
}

export const getOrderCardBaseCost = (order?: OrderWithPartialLines) => {
  const lineWithCard = order?.lines.find(line =>
    line.baseCost?.entries.some(entry => entry.label === 'Card Cost'),
  )
  return getPrice(lineWithCard?.baseCost?.entries, 'Card Cost')?.amount
}

export const getTotalGiftDiscounts = (
  recipientCount: number,
  order?: OrderWithPartialLines,
) => {
  return order?.lines.reduce((acc, line) => {
    // The baseCost does not account for recipients but the cost does when evaluating gifts
    // Therefore we have to manually account for the number of recipients on the baseCost
    // to calculate the correct discount total
    const baseCost =
      (getPrice(line.baseCost?.entries, 'Gift Cost')?.amount ?? 0) *
      recipientCount
    const cost = getPrice(line.cost?.entries, 'Gift Cost')?.amount ?? 0
    return acc + (baseCost - cost)
  }, 0)
}

export const getTotalPointsDiscount = (order?: OrderWithPartialLines) => {
  const totalPointsWithoutDiscounts = order
    ? getOrderCardAndGiftFullCost(order.lines, CurrencyType.Point)
    : 0

  const totalPoints = order?.cost.total.find(
    total => total.currency === CurrencyType.Point,
  )?.amount

  const discountPoints = totalPoints
    ? totalPointsWithoutDiscounts - totalPoints
    : 0

  return discountPoints
}

export const getTotalDiscount = (order: OrderWithPartialLines) => {
  const grandTotalUSD = getUSDGrandTotalArr(order).reduce((acc, price) => {
    return price.amount + acc
  }, 0)
  const orderFullUSDCost = getOrderCardAndGiftFullUSDCost(order.lines)

  return orderFullUSDCost - grandTotalUSD
}

export const getOrderCardAndGiftFullCost = (
  orderLines: PartialLine[],
  currency: CurrencyType,
) => {
  const totalCost = orderLines.reduce((acc, line) => {
    const recipientNo =
      line.recipientCount && line.recipientCount > 0 ? line.recipientCount : 1
    const card = line.card
    const cardTotal = card ? getTotalFullCardCost(card, currency) : 0

    const giftPrice = line.baseCost
      ? getPrice(line.baseCost.entries, 'Gift Cost')
      : undefined
    const giftTotal =
      giftPrice && giftPrice.currency === currency ? giftPrice.amount : 0

    return (cardTotal + giftTotal) * recipientNo + acc
  }, 0)
  return totalCost
}

export const getOrderCardAndGiftFullUSDCost = (orderLines: PartialLine[]) => {
  const usdCost = getOrderCardAndGiftFullCost(orderLines, CurrencyType.Usd)
  const expenseCost = getOrderCardAndGiftFullCost(
    orderLines,
    CurrencyType.Expense,
  )

  return usdCost + expenseCost
}

const getTotalFullCardCost = (card: CardFragment, currency: CurrencyType) => {
  return card.cost.total
    .filter(total => total.currency === currency)
    .reduce((acc, total) => {
      return acc + total.amount
    }, 0)
}

const cardPaperTypeLookup: { [key: string]: CardPaperType } = {
  STD: CardPaperType.Std,
  PRE: CardPaperType.Pre,
  HVY: CardPaperType.Hvy,
}

const getCardPaperTypeFromValue = (value: string) => {
  return cardPaperTypeLookup[value] ?? CardPaperType.Std
}

export const onPaperTypeSelected = (
  card: CardFragment,
  optionIndex: number,
  onUpdatePaperType?: (paperType: CardPaperType, card: CardFragment) => void,
) => {
  const paperUpgradeCosts = card.paperUpgradeCosts
    ?.filter(paper => paper.value !== 'STA')
    .find((_, index) => index === optionIndex)

  if (paperUpgradeCosts) {
    const paperUpgradeType = getCardPaperTypeFromValue(paperUpgradeCosts.value)
    onUpdatePaperType?.(paperUpgradeType, card)
  }
}

export const getPaperOptions = (card: CardFragment) => {
  return card.paperUpgradeCosts
    .filter(paper => paper.value !== 'STA')
    .map(paper => ({
      ...paper,
      label:
        paper.value === 'HVY'
          ? `Satin +$${paper.upgrade.padEnd(4, '0')}`
          : paper.value === 'PRE'
          ? `Pearl +$${paper.upgrade.padEnd(4, '0')}`
          : 'Standard',
    }))
}

export const didCardFlattenFail = async (card?: Maybe<CardFragment>) => {
  if (card) {
    const cardPreviewsUrls = [
      card.frontPreviewUrl,
      card.backPreviewUrl,
      ...(card.insidePreviewUrl ? [card.insidePreviewUrl] : []),
      ...(card.flapPreviewUrl ? [card.flapPreviewUrl] : []),
    ]
    const checkArr = await Promise.all(
      cardPreviewsUrls.map(url => isFileUnavailable(url)),
    )
    return checkArr.some(isUnavailable => isUnavailable)
  } else {
    return false
  }
}
