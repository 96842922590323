import React from 'react'
// tslint:disable:no-array-mutation
// tslint:disable:no-object-mutation
// tslint:disable:no-parameter-reassignment
// tslint:disable:no-import-side-effect

import { useAccount, useActions, useSelector, useState } from 'src/hooks'
import {
  Button,
  Flex,
  LoadingSpinner,
  Overlay,
  Text,
} from '@sendoutcards/quantum-design-ui'
import styles from './affiliateCreationForm.module.scss'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { ToasterNotification } from 'src/editor/components/MobileEditorToolbar/components/ToasterNotification'
import { useUpgradeToAffiliate } from 'src/react_query'
import { AFFILIATE_BRANDING } from 'src/app/constants'
import { ClientError } from 'graphql-request'

type StatusType = 'warning' | 'success'

type AffiliateCreationFormProps = {
  onSuccess: () => void
  onCancel?: () => void
  isLoading?: boolean
  hasStatus?: StatusType
}

export const AffiliateCreationForm: React.FC<AffiliateCreationFormProps> = ({
  onSuccess,
  isLoading,
  onCancel,
  hasStatus,
}) => {
  const upgradeToAffiliateMutation = useUpgradeToAffiliate()
  const actions = useActions()
  const account = useAccount()
  const isUSBased = account.shippingAddress?.country === 'United States'

  const [isIndividual, setIsIndividual] = useState(true)
  const [ssn, setSSN] = useState('')
  const [govId2, setGovId2] = useState('')
  const [ein, setEIN] = useState('')
  const [businessName, setBusinessName] = useState('')
  const [displaySSN, setDisplaySSN] = useState('')
  const [displayEIN, setDisplayEIN] = useState('')
  const [hasDownloadedAgreement, setHasDownloadedAgreement] = useState(false)
  const [hasDownloadedCompPlan, setHasDownloadedCompPlan] = useState(false)
  const [hasAgreed, setHasAgreed] = useState(false)
  const [updateAffiliateError, setUpdateAffiliateError] = useState<
    string | null
  >(null)
  const isMobile = useSelector(state => state.window.isMobile)
  const [isUpgradingToAffiliate, setIsUpgradingToAffiliate] = useState(false)

  const formatInput = (value: string, fieldType: 'ssn' | 'ein'): string => {
    const numbers = value.replace(/\D/g, '')

    if (fieldType === 'ssn') {
      // Reformat displaySSN as XXX-XX-XXXX
      return numbers
        .split('')
        .reduce((acc, cur, idx) => {
          if (idx === 3 || idx === 5) acc += '-'
          return acc + cur
        }, '')
        .slice(0, 11)
    } else if (fieldType === 'ein') {
      // Reformat displayEIN as XX-XXXXXXX
      return numbers
        .split('')
        .reduce((acc, cur, idx) => {
          if (idx === 2) acc += '-'
          return acc + cur
        }, '')
        .slice(0, 10)
    }

    return value
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldType: 'ssn' | 'ein' | 'businessName' | 'govId2',
  ) => {
    const { value } = event.target

    if (fieldType === 'ssn' || fieldType === 'ein') {
      // Handle SSN and EIN input fields
      const cleanValue = value.replace(/\D/g, '') // Remove non-numeric characters
      const formattedValue = formatInput(cleanValue, fieldType) // Format SSN or EIN

      if (isUSBased) {
        // Update SSN or EIN state based on fieldType
        if (fieldType === 'ssn') {
          setSSN(cleanValue)
          setDisplaySSN(formattedValue)
        } else if (fieldType === 'ein') {
          setEIN(cleanValue)
          setDisplayEIN(formattedValue)
        }
      } else {
        // Handle non-US based scenario
        if (fieldType === 'ssn') {
          setSSN(cleanValue)
        } else if (fieldType === 'ein') {
          setEIN(cleanValue)
        }
      }
    } else if (fieldType === 'businessName' && !isIndividual) {
      // Handle businessName input field
      setBusinessName(value)
    } else if (fieldType === 'govId2' && !isUSBased) {
      // Handle govId2 input field for non-US based scenario
      setGovId2(value)
    }
  }

  const handleDownloadAgreement = () => {
    window.open(
      `https://www.sendoutcards.com/resources/documents/578/Promptings_Affiliate_Agreement_Documents_August_2022.pdf`,
    )
    setHasDownloadedAgreement(true)
  }

  const handleDownloadCompPlan = () => {
    window.open(
      `https://www.sendoutcards.com/resources/documents/573/Promptings_CompensationPlanDetails_August2022_v1.0.0.pdf`,
    )
    setHasDownloadedCompPlan(true)
  }

  const upgradeToAffiliate = async () => {
    const govId1 = isIndividual ? ssn : ein
    if (isUpgradingToAffiliate) return
    try {
      setIsUpgradingToAffiliate(true)
      const {
        upgradeToAffiliate: { account },
      } = await upgradeToAffiliateMutation.mutateAsync({
        govId1,
        govId2,
      })
      if (account) {
        actions.updatedAccount(account)
        onSuccess()
      }
    } catch (error) {
      const errorMessage =
        error instanceof ClientError &&
        error.response.errors &&
        error.response.errors.length > 0
          ? error.response.errors[0].message
          : 'Failed to upgrade account.'
      setUpdateAffiliateError(errorMessage)
      setTimeout(() => {
        setUpdateAffiliateError(null)
      }, 3000)
    } finally {
      setIsUpgradingToAffiliate(false)
    }
  }

  const isDisabled = isIndividual
    ? ssn.length !== 9 || !hasAgreed
    : ein.length !== 9 || !businessName || !hasAgreed

  if (isLoading) return <div>Updating your Information...</div>

  const renderInputField = (
    id: string,
    label: string,
    value: string,
    placeholder: string,
    fieldType: 'ssn' | 'ein' | 'businessName' | 'govId2',
  ) => (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="flex-start"
      width="100%"
      backgroundColor="foreground"
      borderColor="#F3F4F6"
      borderWidth="default"
      borderStyle="solid"
      inset={{ left: '12px' }}
      borderRadius="14px"
      height="64px"
      style={{
        boxShadow:
          '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
      }}
    >
      <label htmlFor={id}>
        <Text type="footnote" weight="regular">
          {label}
        </Text>
      </label>
      <input
        className={styles.input}
        type="text"
        id={id}
        value={
          fieldType === 'ssn'
            ? displaySSN
            : fieldType === 'ein'
            ? displayEIN
            : value
        }
        onChange={e => {
          if (isUSBased) {
            handleInputChange(e, fieldType)
          } else {
            setSSN(e.target.value)
          }
        }}
        placeholder={placeholder}
        style={{
          border: 'none',
        }}
      />
    </Flex>
  )

  return (
    <Overlay zIndex={5}>
      <Flex flexDirection="column" width="100%" maxWidth="680px">
        <Flex
          style={{
            backgroundColor:
              hasStatus === 'warning'
                ? '#FEFCE8'
                : hasStatus === 'success'
                ? '#ECFDF5'
                : '#F3F4F6',
            width: '100%',
            height: '62px',
            borderTopLeftRadius: '24px',
            borderTopRightRadius: '24px',
            alignItems: 'center',
            justifyContent: 'flex-start',
            display: 'flex',
          }}
        >
          <Flex
            style={{
              marginLeft: '24px',
              marginRight: '8px',
              backgroundColor: 'white',
              padding: '8px',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '12px',
            }}
          >
            <Icon
              name="person"
              color={
                hasStatus === 'warning'
                  ? '#F59E0B'
                  : hasStatus === 'success'
                  ? '#059669'
                  : '#404040'
              }
            />
          </Flex>
          <Flex
            style={{ width: '100%', paddingLeft: '12px', paddingRight: '12px' }}
          >
            <Text
              color="primaryBody"
              type="body"
              weight="semiBold"
              style={{
                color:
                  hasStatus === 'warning'
                    ? '#F59E0B'
                    : hasStatus === 'success'
                    ? '#059669'
                    : undefined,
              }}
            >
              {AFFILIATE_BRANDING.capitalized} Profile Form
            </Text>
          </Flex>
          <Flex
            onClick={onCancel}
            style={{
              marginRight: '24px',
              backgroundColor: 'white',
              padding: '8px',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '12px',
            }}
          >
            <Icon
              name="x"
              fill={
                hasStatus === 'warning'
                  ? '#F59E0B'
                  : hasStatus === 'success'
                  ? '#059669'
                  : undefined
              }
              size={17}
            />
          </Flex>
        </Flex>
        <Flex
          flexDirection="column"
          boxShadow="mediumLight"
          borderRadius={{ bottom: '"24px"' }}
          backgroundColor="foreground"
          inset={{ horizontal: '24px' }}
          style={{
            borderBottomLeftRadius: '24px',
            borderBottomRightRadius: '24px',
          }}
        >
          <Flex
            flexDirection="column"
            borderRadius="24px"
            backgroundColor="foreground"
            maxWidth="630px"
          >
            <Text
              inset={{ top: '20px', bottom: '12px' }}
              type={'caption'}
              style={{ fontSize: isMobile ? '14px' : '16px' }}
            >
              We are missing some important information in order to fulfill your
              payments as an {AFFILIATE_BRANDING.capitalized}.
            </Text>
            <Flex
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Flex
                style={{
                  width: '264px',
                  height: '65px',
                  backgroundColor: 'white',
                  borderRadius: '9999px',
                  border: '2px solid #F3F4F6',
                  boxShadow:
                    '0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Flex
                  onClick={() => setIsIndividual(true)}
                  style={{
                    padding: '12px 16px',
                    backgroundColor: isIndividual ? 'white' : 'transparent',
                    boxShadow: isIndividual
                      ? '2px 2px 10px 2px rgba(0, 0, 0, 0.1), 2px 2px 10px 2px rgba(0, 0, 0, 0.06)'
                      : 'none',
                    opacity: isIndividual ? 0.95 : 0.5,
                    borderRadius: '9999px',
                    cursor: 'pointer',
                  }}
                >
                  <Text
                    color="primaryBody"
                    style={{ fontSize: '12px' }}
                    type="caption"
                    weight="bold"
                    whiteSpace="nowrap"
                  >
                    As an Individual
                  </Text>
                </Flex>
                <Flex
                  onClick={() => setIsIndividual(false)}
                  style={{
                    padding: '12px 16px',
                    backgroundColor: !isIndividual ? 'white' : 'transparent',
                    boxShadow: !isIndividual
                      ? '2px 2px 10px 2px rgba(0, 0, 0, 0.1), 2px 2px 10px 2px rgba(0, 0, 0, 0.06)'
                      : 'none',
                    opacity: !isIndividual ? 0.95 : 0.5,
                    borderRadius: '9999px',
                    cursor: 'pointer',
                  }}
                >
                  <Text
                    color="primaryBody"
                    type="caption"
                    style={{ fontSize: '12px' }}
                    weight="bold"
                    whiteSpace="nowrap"
                  >
                    As a Company
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex outset={{ vertical: '12px' }}>
            <Text
              color="primaryBody"
              style={{ color: '#686868' }}
              type="body"
              weight="bold"
              whiteSpace="nowrap"
            >
              Tax Account Validation
            </Text>
          </Flex>
          {/* -------------------- Form Section -------------------- */}
          <>
            <form>
              <Flex
                inset="24px"
                flexDirection="column"
                rowGap={'x3'}
                style={{
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#F9FAFB',
                  padding: '16px',
                  borderRadius: '32px',
                  display: 'flex',
                }}
              >
                {isIndividual ? (
                  <>
                    {renderInputField(
                      'ssn',
                      isUSBased ? 'Social Security Number' : 'Government ID 1',
                      ssn,
                      'xxx-xx-xxxx',
                      'ssn',
                    )}
                    {!isUSBased &&
                      renderInputField(
                        'govId2',
                        'Government ID 2',
                        govId2,
                        'xxxx-xx-xxxx',
                        'govId2',
                      )}
                  </>
                ) : (
                  <Flex
                    style={{
                      width: '100%',
                      flexDirection: 'column',
                      gap: '16px',
                    }}
                  >
                    {renderInputField(
                      'businessName',
                      'Business Name',
                      businessName,
                      'Your Business Name',
                      'businessName',
                    )}
                    {renderInputField(
                      'ein',
                      'Employer Identification Number',
                      ein,
                      'xx-xxxxxxx',
                      'ein',
                    )}
                  </Flex>
                )}
                <Flex flexDirection="column" rowGap={'8px'} width="100%">
                  <Flex
                    style={{
                      alignSelf: 'stretch',
                      height: '63px',
                      paddingRight: '14px',
                      backgroundColor: 'white',
                      borderRadius: '14px',
                      justifyContent: 'space-between',
                      boxShadow:
                        '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
                      alignItems: 'center',
                    }}
                  >
                    <Flex
                      onClick={handleDownloadAgreement}
                      cursor="pointer"
                      justifyContent="center"
                      alignItems="center"
                      outset={{ left: 'x2' }}
                    >
                      <Flex
                        style={{
                          backgroundColor: '#F9FAFB',
                          borderRadius: '8px',
                          marginRight: '8px',
                          display: 'flex',
                          padding: '8px',
                          alignItems: 'center',
                        }}
                      >
                        <Icon name="form" />
                      </Flex>
                      <Text
                        color="primaryBody"
                        type={isMobile ? 'footnote' : 'caption'}
                        weight="semiBold"
                        content={`${AFFILIATE_BRANDING.capitalized} Agreement`}
                        whiteSpace="nowrap"
                        outset={{ right: 'x_5' }}
                      />
                      {!isMobile && <Icon name="download" />}
                    </Flex>
                    <Flex
                      onClick={handleDownloadAgreement}
                      justifyContent="center"
                      cursor="pointer"
                      alignItems="center"
                      style={{
                        backgroundColor: hasDownloadedAgreement
                          ? '#ECFDF5'
                          : '#F9FAFB',
                        padding: '12px 20px',
                        borderRadius: '44px',
                      }}
                    >
                      <Icon name="edit_document" />
                      <Text
                        color="primaryHeading"
                        type="footnote"
                        weight="bold"
                        content="Download"
                        whiteSpace="nowrap"
                        outset={{ left: 'x_5' }}
                      />
                    </Flex>
                  </Flex>
                  <Flex
                    style={{
                      width: '100%',
                      height: '63px',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      display: 'flex',
                    }}
                  >
                    <Flex
                      style={{
                        alignSelf: 'stretch',
                        height: '63px',
                        paddingRight: '14px',
                        paddingTop: '8px',
                        paddingBottom: '8px',
                        backgroundColor: 'white',
                        borderRadius: '14px',
                        justifyContent: 'space-between',
                        boxShadow:
                          '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
                        alignItems: 'center',
                      }}
                    >
                      <Flex
                        onClick={handleDownloadCompPlan}
                        cursor="pointer"
                        justifyContent="center"
                        alignItems="center"
                        outset={{ left: 'x2' }}
                      >
                        <Flex
                          style={{
                            backgroundColor: '#F9FAFB',
                            borderRadius: '8px',
                            marginRight: '8px',
                            display: 'flex',
                            padding: '8px',
                            alignItems: 'center',
                          }}
                        >
                          <Icon name="form" />
                        </Flex>
                        <Text
                          color="primaryBody"
                          type={isMobile ? 'footnote' : 'caption'}
                          weight="semiBold"
                          content="Compensation Plan Details"
                          whiteSpace="nowrap"
                          outset={{ right: 'x_5' }}
                        />
                        {!isMobile && <Icon name="download" />}
                      </Flex>
                      <Flex
                        onClick={handleDownloadCompPlan}
                        justifyContent="center"
                        alignItems="center"
                        cursor="pointer"
                        style={{
                          backgroundColor: hasDownloadedCompPlan
                            ? '#ECFDF5'
                            : '#F9FAFB',
                          padding: '12px 20px',
                          borderRadius: '44px',
                          marginLeft: '4px',
                        }}
                      >
                        <Icon name="edit_document" />
                        <Text
                          outset={{ left: 'x_5' }}
                          color="primaryHeading"
                          type="footnote"
                          weight="bold"
                          content="Download"
                          whiteSpace="nowrap"
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
                <Flex
                  justifyContent="start"
                  alignItems="center"
                  style={{
                    gap: '8px',
                    width: '100%',
                    height: 'auto',
                  }}
                >
                  <input
                    id="agreeCheckbox"
                    name="agree"
                    type="checkbox"
                    checked={hasAgreed}
                    onChange={e => setHasAgreed(e.target.checked)}
                    className={styles.checkbox}
                    style={{
                      width: '24px',
                      height: '24px',
                      minHeight: '24px',
                      minWidth: '24px',
                      marginLeft: '8px',
                      alignItems: 'center',
                    }}
                  />
                  <label
                    htmlFor="agreeCheckbox"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '4px',
                    }}
                  >
                    <Text
                      color="primaryBody"
                      type="caption"
                      style={{ fontSize: '12px' }}
                      weight="semiBold"
                      content={`I agree to the ${AFFILIATE_BRANDING.capitalized} Agreement, Compensation Plan, and Terms and Conditions.`}
                      whiteSpace={isMobile ? 'normal' : 'nowrap'}
                      lineHeight={isMobile ? 1.3 : 1.75}
                    />
                  </label>
                </Flex>
              </Flex>
              <Flex
                width="100%"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Flex
                  justifyContent="flex-end"
                  width={isMobile ? '100%' : '442px'}
                  columnGap="x2"
                  outset={{ bottom: '24px' }}
                  flexDirection={isMobile ? 'column-reverse' : 'row'}
                  rowGap={isMobile ? 'x2' : 'x0'}
                >
                  <Button
                    onClick={onCancel}
                    outlined={true}
                    type="shadow"
                    fullWidth={true}
                  >
                    Skip
                  </Button>
                  <Button
                    fullWidth={true}
                    type="shadow"
                    size="medium"
                    textColorOverride={
                      !isDisabled && !isUpgradingToAffiliate
                        ? '#fff'
                        : undefined
                    }
                    backgroundColorOverride={
                      isDisabled || isUpgradingToAffiliate
                        ? '#D1D5DB'
                        : '#00AEEF'
                    }
                    disabled={isDisabled || isUpgradingToAffiliate}
                    onClick={() => upgradeToAffiliate()}
                  >
                    {isUpgradingToAffiliate ? (
                      <LoadingSpinner size="xSmall" />
                    ) : (
                      'Continue'
                    )}
                  </Button>
                </Flex>
              </Flex>
            </form>
          </>
        </Flex>
      </Flex>
      {updateAffiliateError && (
        <ToasterNotification
          backgroundColor={{
            swatch: 'danger',
            shade: '_500',
          }}
          icon={{
            size: 'xSmall',
            name: 'information',
            primaryColor: 'inverseHeadingText',
            iconContainerColor: { swatch: 'success', shade: '_400' },
          }}
          label={{
            color: 'inverseHeading',
            type: 'footnote',
            content: updateAffiliateError,
          }}
        />
      )}
    </Overlay>
  )
}
