import React from 'react'

const size = '44px'
const styles = {
  ldsDualRing: {
    width: size,
    height: size,
    '&:after': {
      content: '" "',
      display: 'block',
      width: size,
      height: size,
      borderRadius: '50%',
      animation: 'ldsDualRing 1.2s linear infinite',
      border: `5px solid #ff5689`,
      borderColor: `#ff5689 transparent #ff5689 transparent`,
    },
    '@keyframes ldsDualRing': {
      from: {
        transform: 'rotate(0deg)',
      },
      to: {
        transform: 'rotate(360deg)',
      },
    },
  },
}

const SimpleLoadingSpinner: React.FC<{}> = () => (
  <div css={styles.ldsDualRing} />
)

export default SimpleLoadingSpinner
