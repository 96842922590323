import { Maybe } from '../../../__generated__/globalTypes'

import { StorefrontFragment, isStorefrontFragment } from './StorefrontFragment'

import traineeFragmentRawString from '../TraineeFragment.graphql'

export const traineeFragmentString = traineeFragmentRawString

export type TraineeFragment = {
  __typename: 'User'
  firstName: string
  lastName: string
  email: string
  genealogyId: number
  storefront: Maybe<StorefrontFragment>
  profileImageUrl: Maybe<string>
  promptingsCoach: Maybe<{
    trainingVideosCompleted: number
    dateTrainingExpires: Maybe<string>
    dateTrainingCompleted: Maybe<string>
  }>
}

export const isTraineeFragment = (fragment: any): fragment is TraineeFragment =>
  fragment &&
  fragment.__typename === 'User' &&
  typeof fragment.firstName === 'string' &&
  typeof fragment.lastName === 'string' &&
  typeof fragment.email === 'string' &&
  typeof fragment.genealogyId === 'number' &&
  (fragment.storefront === null ||
    (isStorefrontFragment(fragment.storefront) as boolean)) &&
  (fragment.profileImageUrl === null ||
    typeof fragment.profileImageUrl === 'string') &&
  (fragment.promptingsCoach === null ||
    (fragment.promptingsCoach &&
      typeof fragment.promptingsCoach.trainingVideosCompleted === 'number' &&
      (fragment.promptingsCoach.dateTrainingExpires === null ||
        typeof fragment.promptingsCoach.dateTrainingExpires === 'string') &&
      (fragment.promptingsCoach.dateTrainingCompleted === null ||
        typeof fragment.promptingsCoach.dateTrainingCompleted === 'string')))
