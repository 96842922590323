import { createBrowserHistory } from 'history'

import { trackPath } from 'src/redux/middleware/segmentAnalytics'
import { Store } from 'src/redux/store'
import action from 'src/routes/actionForLocation'
import { BaseRoute } from 'src/routes/BaseRoute'

// Direct usage of history is considered legacy behavior and should be strongly avoided.
export const browserHistory = createBrowserHistory()

export const startListeningToBrowserHistory = () => {
  // Initialize route
  Store.dispatch(action(browserHistory.location))

  // Track starting path
  trackPath(BaseRoute.toPath(Store.getState().route))

  // Listen to changes in browserHistory
  browserHistory.listen(({ location }) => {
    const state = Store.getState()
    const currentPath = BaseRoute.toPath(state.route)
    if (location.pathname !== currentPath) {
      Store.dispatch(action(location))
    }
  })

  const mutableState: { previousRoute: BaseRoute } = {
    previousRoute: Store.getState().route,
  }

  // Observe the route
  Store.subscribe(() => {
    const route = Store.getState().route
    const newPath = BaseRoute.toPath(route)
    if (newPath && browserHistory.location.pathname !== newPath) {
      // Reset the window scroll if neither the previous route or the current route objects
      if (
        !(
          BaseRoute.doNotResetWindowScroll(mutableState.previousRoute) ||
          BaseRoute.doNotResetWindowScroll(route)
        )
      ) {
        window.scrollTo(0, 0)
      }
      // Update browser history
      browserHistory.push(newPath)
      // Track new path
      if (window.Appcues) {
        window.Appcues.page()
      }
      trackPath(newPath)
    }
    mutableState.previousRoute = route
  })
}
