/**
 * Copies a sparse array including the preservation of gaps
 * @param array  A sparse array
 * @returns A copy of the sparse array
 */
const copySparseArray = <T>(array: T[]) => {
  const mutableArray = Array<T>()
  array.forEach((element, index) => {
    if (element !== undefined) {
      mutableArray[index] = element
    }
  })
  return mutableArray
}

/**
 * Returns the ranges of elements in a sparse array
 * @param array  A sparse array
 * @returns An array of range tuples
 */
const sparseArrayRanges = <T>(array: T[]): [number, number][] => {
  const mutableRanges = Array<[number, number]>()
  let mutableRange: [number, number] | undefined
  array.forEach((_, index) => {
    if (mutableRange === undefined) {
      mutableRange = [index, index + 1]
    } else if (mutableRange[1] < index) {
      mutableRanges.push(mutableRange)
      mutableRange = [index, index + 1]
    } else {
      mutableRange[1] = index + 1
    }
  })
  if (mutableRange) {
    mutableRanges.push(mutableRange)
  }
  return mutableRanges
}

/**
 * Returns a filtered copy of a sparse array while preserving gaps
 * @param array  A sparse array
 * @param predicate A function that returns `true` if the element should be included
 * @returns A filtered copy of the sparse array
 */
export const filterSparseArray = <T>(
  array: T[],
  predicate: (element: T) => boolean,
) => {
  const mutableArray = copySparseArray(array)
  array
    .map((element, index): [T, number] => [element, index])
    .reverse()
    .forEach(([element, index]) => {
      if (!predicate(element)) {
        mutableArray.splice(index, 1)
      }
    })
  return mutableArray
}

/**
 * Returns a sorted sparse array with new elements inserted
 * @param array  An already sorted sparse array
 * @param elements An array of new elements to insert
 * @param key A function that returns the sort key for a given element
 * @returns A copy of the sparse array with the new elements inserted in sorted position
 */
export const insertIntoSortedSparseArray = <T>(
  array: T[],
  elements: T[],
  key: (element: T) => string,
) => {
  // Estimated Time Complexity: O(max(log(N)*N, log(M)*N, M))
  // I believe this basically reduces to O(N*log(N)) where N
  // is either the array or the elements being inserted, whichever dominates
  const mutableRanges = sparseArrayRanges(array)
  const mutableArray = copySparseArray(array)
  const mutableElements = [...elements]
  mutableElements
    .sort((a, b) => key(a).localeCompare(key(b)))
    .reverse()
    .forEach(element => {
      const elementKey = key(element)
      let mutableStart = 0
      let mutableEnd = mutableRanges.length
      while (mutableStart < mutableEnd) {
        const middle = Math.floor(
          mutableStart + (mutableEnd - mutableStart) / 2,
        )
        const mutableRange = mutableRanges[middle]
        let mutableRangeStart = mutableRange[0]
        let mutableRangeEnd = mutableRange[1]
        if (
          elementKey > key(mutableArray[mutableRangeStart]) &&
          elementKey < key(mutableArray[mutableRangeEnd - 1])
        ) {
          while (mutableRangeStart < mutableRangeEnd) {
            const rangeMiddle = Math.floor(
              mutableRangeStart + (mutableRangeEnd - mutableRangeStart) / 2,
            )
            if (key(mutableArray[rangeMiddle]) < elementKey) {
              mutableRangeStart = rangeMiddle + 1
            } else {
              mutableRangeEnd = rangeMiddle
            }
          }
          mutableArray.splice(mutableRangeStart, 0, element)
          mutableRange[1] += 1
          return
        } else if (key(mutableArray[mutableRangeStart]) < elementKey) {
          mutableStart = middle + 1
        } else {
          mutableEnd = middle
        }
      }
      if (mutableStart === 0) {
        mutableArray.splice(0, 0, element)
      } else if (mutableStart === mutableRanges.length) {
        mutableArray.push(element)
        mutableRanges[mutableRanges.length - 1][1] += 1
      } else {
        mutableArray.splice(
          mutableRanges[mutableStart][0],
          0,
          (undefined as unknown) as T,
        )
      }
    })
  return copySparseArray(mutableArray)
}
