import { Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { GreetingCardImage } from '../../CallToActionCards/components/GreetingCardImage'
import { IconType } from 'src/design_system/atoms/icons/types'
import { getFormatedDate } from 'src/helpers/formatDate'
import { useContainerWidth } from 'src/hooks'
import { CardStatus } from 'src/graphql/generated/graphql'

type CardLineItemProps = {
  color: string
  card: { name: string; isHorizontal: boolean; thumbnail: string }
  date: string
  status?: CardStatus
}

const statusColorMap: Record<CardStatus, string> = {
  [CardStatus.Pending]: '#006FEE',
  [CardStatus.Fulfilled]: '#7828C8',
  [CardStatus.Rejected]: '#12A150',
  [CardStatus.Held]: '#F87171',
  [CardStatus.PaymentError]: '#F87171',
  [CardStatus.AwaitingFulfillment]: '#F87171',
  [CardStatus.Refunded]: '#F87171',
  [CardStatus.Cancelled]: '#F87171',
}

const statusIconNameMap: Record<CardStatus, IconType> = {
  [CardStatus.Pending]: 'info',
  [CardStatus.Fulfilled]: 'download',
  [CardStatus.Rejected]: 'danger',
  [CardStatus.Held]: 'info',
  [CardStatus.PaymentError]: 'card_error',
  [CardStatus.AwaitingFulfillment]: 'edit_document',
  [CardStatus.Refunded]: '10_card',
  [CardStatus.Cancelled]: '10_card',
}

const getActiveStatusAndColor = (status?: CardLineItemProps['status']) => {
  return status
    ? { key: status, color: statusColorMap[status] }
    : { key: undefined, color: undefined }
}

const formatStatusKey = (key: string | undefined) =>
  key?.replace(/^is/, '') ?? ''

export const CardLineItem: React.FC<CardLineItemProps> = ({
  color,
  card,
  status,
  date,
}) => {
  const {
    key: activeStatusKey,
    color: activeStatusColor,
  } = getActiveStatusAndColor(status)

  const formattedDate = getFormatedDate(date)
  const dateString = `${formattedDate.month} ${formattedDate.day}, ${formattedDate.year}`
  const [containerRef, containerWidth] = useContainerWidth()
  const shouldStack = containerWidth <= 450

  const convertDateString = (inputDateString: string): string => {
    const date = new Date(inputDateString)

    if (!isNaN(date.getTime())) {
      const year = date.getFullYear().toString().slice(-2)
      const month = date.getMonth() + 1
      const day = date.getDate()

      return `${month}/${day}/${year}`
    } else {
      return 'Invalid date'
    }
  }

  return (
    <Div
      ref={containerRef}
      display="grid"
      justifyContent="center"
      alignItems="center"
      width="100%"
      outset={{ bottom: 'x2_5' }}
      style={{ gridTemplateColumns: '75px 1fr', gridTemplateRows: '1fr' }}
    >
      <Flex width="75px" justifyContent="center">
        <GreetingCardImage
          previewUrl={card.thumbnail}
          cardId={'1'}
          width={card.isHorizontal ? '75px' : '55px'}
          height={card.isHorizontal ? '55px' : '75px'}
        />
      </Flex>
      <Div
        outset={{ left: 'x2' }}
        width="100%"
        alignItems="center"
        inset={{ horizontal: 'x2', vertical: 'x1_5' }}
        style={{ backgroundColor: '#eef0f2', borderRadius: 16 }}
      >
        <Div
          display="grid"
          width="100%"
          justifyItems="start"
          style={{
            gridTemplateColumns: activeStatusKey ? '10px 1fr 1fr' : '10px 1fr',
            gridTemplateRows: '1fr 1fr',
          }}
        >
          <Flex
            backgroundColor={color}
            width="3px"
            height="35px"
            outset={{ right: 'x2' }}
            borderRadius="default"
            style={{
              gridColumnStart: 1,
              gridRowStart: 1,
              gridColumnEnd: 1,
              gridRowEnd: 3,
              alignSelf: 'center',
            }}
          />
          <Div
            style={{
              gridColumnStart: 2,
              gridRowStart: 1,
              gridColumnEnd: shouldStack ? 4 : 2,
              gridRowEnd: 1,
              alignSelf: 'center',
            }}
          >
            <Text
              type="caption"
              content={card.name}
              lineHeight={1.8}
              weight="bold"
              color="primaryHeading"
            />
          </Div>
          <Div
            style={{
              gridColumnStart: 2,
              gridRowStart: 2,
              gridColumnEnd: 2,
              gridRowEnd: 2,
              alignSelf: 'center',
            }}
          >
            <Flex alignItems="center">
              <Icon name="exp_calendar" size={12} color={color} />
              <Text
                outset={{ left: 'x_5' }}
                type="footnote"
                weight="bold"
                content={
                  shouldStack && activeStatusKey
                    ? convertDateString(dateString)
                    : dateString
                }
                lineHeight={1.2}
                style={{ color: color }}
              />
            </Flex>
          </Div>
          {activeStatusKey && (
            <Div
              style={{
                gridColumnStart: 3,
                gridRowStart: shouldStack ? 2 : 1,
                gridColumnEnd: 3,
                gridRowEnd: 3,
                alignSelf: shouldStack ? 'end' : 'center',
                justifySelf: 'end',
              }}
            >
              <Flex
                alignItems="center"
                style={{ marginLeft: 'auto', color: activeStatusColor }}
              >
                <Icon
                  name={statusIconNameMap[activeStatusKey]}
                  color={activeStatusColor}
                />
                <Text
                  outset={{ left: 'x_5' }}
                  type="footnote"
                  weight="bold"
                  content={formatStatusKey(activeStatusKey)}
                  style={{ color: activeStatusColor }}
                />
              </Flex>
            </Div>
          )}
        </Div>
      </Div>
    </Div>
  )
}
