const elementInViewport = (element?: Element | null) => {
  const html = document.documentElement
  if (element && html) {
    const rect = element.getBoundingClientRect()
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || html.clientHeight) &&
      rect.right <= (window.innerWidth || html.clientWidth)
    )
  }
  return false
}

export default elementInViewport
