import { Div, Flex, Icon, Text } from '@sendoutcards/quantum-design-ui'
import React, { useRef } from 'react'
import styles from './carousel.module.scss'
import { useEffect, useSelector, useState } from 'src/hooks'
import { useSwipeable } from 'react-swipeable'

export type CarouselGap = {
  mobileGap: number
  tabletGap: number
  desktopGap: number
}

type CarouselPropType = {
  childWidth: number
  initialMobileElement?: number
  shouldContainOptions?: boolean
  gap?: CarouselGap
  pillTitles?: string[]
  shouldDisplayDotSlider?: boolean
  isInfiniteScroll?: boolean
  wrapperWidth?: string
  childIds?: string[]
  onActiveChild?: (id: string) => void
}

const standardGap: CarouselGap = {
  mobileGap: 16,
  tabletGap: 48,
  desktopGap: 64,
}

/**
 *
 * @param shouldContainOptions When TRUE, will force the carousel width to be at most the
 * width of all options, meaning that the caroulsel wont display repeated items.
 * @param pillTitles When using this, beware of the size of your content as it may overflow weirdly
 * @param shouldDisplayDotSlider Will display the dot slider when in mobile (< 520px)
 */
const Carousel: React.FC<CarouselPropType> = props => {
  const {
    childWidth,
    initialMobileElement,
    shouldContainOptions,
    gap = standardGap,
    pillTitles,
    shouldDisplayDotSlider,
    isInfiniteScroll = true,
    wrapperWidth = '100%',
    childIds,
    onActiveChild,
  } = props
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const mutableCarouselRef = useRef<HTMLDivElement | null>(null)
  const isTablet = useSelector(state => state.window.width < 950)
  const isMobile = useSelector(state => state.window.width <= 520)
  const [canScroll, setCanScroll] = useState(true)
  const totalElem = React.Children.count(props.children)
  const scrollGap = isMobile
    ? gap.mobileGap
    : isTablet
    ? gap.tabletGap
    : gap.desktopGap
  const scrollAmount = childWidth + scrollGap
  const [currentElemFocus, setCurrentElemFocus] = useState(
    initialMobileElement && isMobile
      ? initialMobileElement
      : isInfiniteScroll
      ? totalElem
      : 0,
  )
  const swipeHandler = useSwipeable({
    onSwipedLeft: () => {
      if (canScroll) isInfiniteScroll ? slide('+') : notInfiniteSlide('+')
    },
    onSwipedRight: () => {
      if (canScroll) isInfiniteScroll ? slide('-') : notInfiniteSlide('-')
    },
    preventScrollOnSwipe: true,
  })

  const refPassthrough = (el: HTMLDivElement | null) => {
    swipeHandler.ref(el)
    mutableCarouselRef.current = el
  }

  const [isCarouselNotOverflowing, setIsCarouselNotOverflowing] = useState(
    wrapperRef.current
      ? wrapperRef.current.offsetWidth > totalElem * scrollAmount
      : window.innerWidth > totalElem * scrollAmount,
  )

  const shouldUseContainOptions =
    shouldContainOptions && isCarouselNotOverflowing

  const containedStyles = {
    wrapper: {
      display: 'flex',
      flexDirection: 'column' as const,
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
    },
    carousel: {
      maxWidth: `${totalElem * scrollAmount}px`,
    },
  }

  const getActiveClass = () => {
    const focusElement = isMobile ? 1 : 2
    const activeLeft =
      styles[`active${currentElemFocus - totalElem + focusElement}`]
    const activeCenter = styles[`active${currentElemFocus + focusElement}`]
    const activeRight =
      styles[`active${currentElemFocus + totalElem + focusElement}`]
    return `${activeCenter} ${activeLeft} ${activeRight} `
  }

  const getTwinPosition = (position: number) => {
    // We are using the remainder to find the core position of the current element
    const basePosition = position % totalElem

    // We use the second group as the base to avoid stuttering due scrolling boundaries
    const referenceGroup = totalElem * 2

    return referenceGroup + basePosition
  }

  const slide = (operation: '-' | '+') => {
    const newPosition = +operation.concat(`1`)
    const newElemFocus = currentElemFocus + newPosition
    if (childIds) {
      onActiveChild?.(childIds[(newElemFocus % totalElem) + 1])
    }
    setCurrentElemFocus(newElemFocus)
    setCanScroll(false)
    if (mutableCarouselRef.current) {
      mutableCarouselRef.current.scrollTo({
        left: newElemFocus * scrollAmount,
        behavior: 'smooth',
      })
    }
    setTimeout(() => {
      if (mutableCarouselRef.current) {
        const twin = getTwinPosition(newElemFocus)
        setCurrentElemFocus(twin)
        mutableCarouselRef.current.scrollTo({
          left: twin * scrollAmount,
          behavior: 'auto',
        })
      }
      setCanScroll(true)
    }, 500)
  }

  useEffect(() => {
    if (mutableCarouselRef.current) {
      mutableCarouselRef.current.scrollTo({
        left:
          initialMobileElement && isMobile
            ? initialMobileElement * scrollAmount
            : isInfiniteScroll
            ? totalElem * scrollAmount
            : 0,
        behavior: 'auto',
      })
    }
    if (wrapperRef.current) {
      setIsCarouselNotOverflowing(
        wrapperRef.current.offsetWidth > totalElem * scrollAmount,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const moveToNewItem = (index: number) => {
    const newElemFocus = isInfiniteScroll ? getTwinPosition(index) : index
    setCurrentElemFocus(newElemFocus)
    setCanScroll(false)
    if (mutableCarouselRef.current) {
      mutableCarouselRef.current.scrollTo({
        left: newElemFocus * scrollAmount,
        behavior: 'smooth',
      })
    }
    setTimeout(() => {
      setCanScroll(true)
    }, 500)
  }

  const getActivePill = (index: number) => {
    if (getTwinPosition(currentElemFocus) === getTwinPosition(index))
      return true
    else return false
  }

  const getCarouselChildrens = () => {
    if (isInfiniteScroll) {
      return (
        <>
          {props.children}
          {props.children}
          {props.children}
          {props.children}
          {props.children}
        </>
      )
    } else {
      return <>{props.children}</>
    }
  }

  const notInfiniteSlide = (operation: '-' | '+') => {
    const focusElement = isMobile ? 1 : 2
    const newPosition = +operation.concat(`1`)
    const initialNewElemFocus = currentElemFocus + newPosition
    const newElemFocus =
      initialNewElemFocus + focusElement === 0
        ? totalElem - focusElement
        : initialNewElemFocus + focusElement > totalElem
        ? -focusElement + 1
        : initialNewElemFocus

    if (childIds) {
      onActiveChild?.(childIds[(newElemFocus % totalElem) + focusElement - 1])
    }
    setCurrentElemFocus(newElemFocus)
    setCanScroll(false)
    if (mutableCarouselRef.current) {
      mutableCarouselRef.current.scrollTo({
        left: newElemFocus * scrollAmount,
        behavior: 'smooth',
      })
    }
    setTimeout(() => {
      setCanScroll(true)
    }, 500)
  }

  return (
    <Div
      position="relative"
      height="auto"
      width={wrapperWidth}
      css={shouldUseContainOptions ? containedStyles.wrapper : undefined}
      outset={{ top: 'x3' }}
      ref={wrapperRef}
    >
      {pillTitles && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            zIndex: 97,
            border: 'solid 3px #e1e2e3',
            borderRadius: '30px',
            backgroundColor: '#fbfbfc',
            padding: '4px',
          }}
        >
          {pillTitles.map((title, index) => (
            <div
              key={index}
              className={`
                ${styles.pillItem} 
                ${getActivePill(index) ? styles.activePill : ''}
              `}
              onClick={() => moveToNewItem(index)}
            >
              <Text type="footnote" color="primaryBody" weight="bold">
                {title}
              </Text>
            </div>
          ))}
        </div>
      )}
      <div
        style={{
          marginTop: '1rem',
          marginBottom: '.5rem',
          position: 'relative',
          display: 'grid',
          gridAutoColumns: `${childWidth}px`,
          gridAutoFlow: 'column dense',
          columnGap: `${scrollGap}px`,
          overflow: 'hidden',
        }}
        css={shouldUseContainOptions ? containedStyles.carousel : undefined}
        className={`${styles.car} ${getActiveClass()}`}
        {...swipeHandler}
        ref={refPassthrough}
      >
        {getCarouselChildrens()}
      </div>
      {isInfiniteScroll ||
        (!isInfiniteScroll && !isCarouselNotOverflowing && (
          <Flex justifyContent="center" alignItems="center" width="100%">
            <Flex
              alignItems="center"
              justifyContent="center"
              style={{ gap: '15px' }}
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                onClick={() => {
                  if (canScroll) {
                    isInfiniteScroll ? slide('-') : notInfiniteSlide('-')
                  }
                }}
                cursor="pointer"
                backgroundColor="foreground"
                borderRadius="circle"
                boxShadow="light"
                className={`${styles.arrow} ${styles.arrowLeft}`}
              >
                <Icon
                  size="medium"
                  primaryColor="primaryHeadingText"
                  name="leftChevron"
                />
              </Flex>
              {shouldDisplayDotSlider && (
                <div className={styles.dotContainer}>
                  {Array.from({ length: totalElem }, (_, index) => {
                    return (
                      <div
                        key={index}
                        className={`${styles.dotItem}  ${
                          getActivePill(index) ? styles.activeDot : ''
                        }`}
                        onClick={() => moveToNewItem(index)}
                      >
                        <div />
                      </div>
                    )
                  })}
                </div>
              )}
              <Flex
                justifyContent="center"
                alignItems="center"
                onClick={() => {
                  if (canScroll) {
                    isInfiniteScroll ? slide('+') : notInfiniteSlide('+')
                  }
                }}
                cursor="pointer"
                backgroundColor="foreground"
                borderRadius="circle"
                boxShadow="light"
                className={`${styles.arrow} ${styles.arrowRight}`}
              >
                <Icon
                  size="medium"
                  primaryColor="primaryHeadingText"
                  name="rightChevron"
                />
              </Flex>
            </Flex>
          </Flex>
        ))}
      <Flex
        width="100%"
        justifyContent="center"
        alignItems="center"
        outset={{ vertical: 'x2' }}
      />
    </Div>
  )
}

export default Carousel
