import { CardPaperType, CardType } from 'src/graphql/generated/graphql'
import { Route } from 'src/routes/Route'

const getCardType = (string: string | undefined) => {
  switch (string) {
    case 'POSTCARD':
      return CardType.Postcard
    case 'FLATCARD':
      return CardType.Flatcard
    case 'TWO_PANEL':
      return CardType.TwoPanel
    case 'THREE_PANEL':
      return CardType.ThreePanel
    case 'TWO_PANEL_BIG':
      return CardType.TwoPanelBig
    default:
      return undefined
  }
}

const getPaperType = (string: string | undefined) => {
  switch (string) {
    case 'STD':
      return CardPaperType.Std
    case 'PRE':
      return CardPaperType.Pre
    case 'HVY':
      return CardPaperType.Hvy
    default:
      return undefined
  }
}

export const SendableCardRoute = Route({
  path: '/preview/:sendableCardId',
  init: (
    sendableCardId: string,
    cardType?: CardType,
    paperType?: CardPaperType,
  ) => ({
    sendableCardId,
    cardType,
    paperType,
  }),
  fromPath: ({ sendableCardId, card_type, paper_type }) =>
    sendableCardId
      ? {
          sendableCardId,
          cardType: getCardType(card_type),
          paperType: getPaperType(paper_type),
        }
      : undefined,
  doNotResetWindowScroll: true,
})
export type SendableCardRoute = Route<typeof SendableCardRoute>
