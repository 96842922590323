import React from 'react'
import { Icon } from 'src/chrome'

import styles from '../../styles/components/Drawer.module.scss'

type TextTriggerProps = {
  color?: string
  id?: string
  textFunction?: (event: React.MouseEvent<HTMLDivElement>) => void
}

const TextTrigger: React.FC<TextTriggerProps> = props => {
  const { color, id, textFunction } = props
  const style: React.CSSProperties = {
    backgroundColor: color,
    borderLeftColor: color,
  }
  return (
    <div
      id={id}
      style={style}
      className={styles.launchImageDrawer}
      onClick={textFunction}
    >
      <Icon icon={'EDIT'} />
    </div>
  )
}

export default TextTrigger
