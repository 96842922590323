import React from 'react'
import { Set } from 'immutable'
import Checkbox from '@material-ui/core/Checkbox'
// @src imports
import { RwdCell, RwdHeader, RwdRow, RwdTable } from 'src/chrome'
import { RecipientFragment } from 'src/graphql/generated/graphql'

import styles from './historyTable.module.scss'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Div, Text } from '@sendoutcards/quantum-design-ui'

type RecipientTableProps = {
  history: RecipientFragment[]
  selectName: string
  checkedItems: Set<string>
  isMobile: boolean
  onUpdateCheck: (id: string) => void
  onCardClicked: (recipient: RecipientFragment) => void
  onSelectAllClick: () => void
  context: 'processing' | 'paused'
}

function isNotUndefined<T>(value: T): value is Exclude<T, undefined> {
  return value !== undefined
}

const RecipientTable: React.FC<RecipientTableProps> = props => {
  const {
    history,
    selectName,
    checkedItems,
    isMobile,
    onUpdateCheck,
    onCardClicked,
    onSelectAllClick,
    context,
  } = props
  return (
    <div>
      {context === 'paused' && (
        <>
          <Text type="caption">
            * A card is paused after 5 failed attempts to charge your method of
            payment.
          </Text>
          <br />
          <Text type="caption">
            These cards can either be canceled or re-sent after your payment
            information has been updated.
          </Text>
        </>
      )}
      {history.length < 1 ? (
        <Text
          type="subtitle"
          alignment="center"
          outset={{ top: 'x7' }}
          color="primaryHeading"
        >
          {`You have no cards here right now`}
        </Text>
      ) : (
        <RwdTable>
          <RwdHeader>
            {context === 'paused' && (
              <th
                onClick={() => onSelectAllClick()}
                className={styles.selectAllHeader}
              >
                <Text type="body" color="primaryBrand">
                  {isMobile ? 'Select' : selectName}
                </Text>
              </th>
            )}
            <th>
              <Text type="body" color="primaryBrand">
                Recipient
              </Text>
            </th>
            <th>
              <Text type="body" color="primaryBrand">
                Status
              </Text>
            </th>
            <th>
              <Text type="body" color="primaryBrand">
                Card
              </Text>
            </th>
          </RwdHeader>
          {history &&
            history
              .map(recipient =>
                recipient.card && recipient.address
                  ? {
                      ...recipient,
                      address: recipient.address,
                      card: recipient.card,
                    }
                  : undefined,
              )
              .filter(isNotUndefined)
              .map((recipient, index) => (
                <tbody key={index}>
                  <RwdRow>
                    {context === 'paused' && (
                      <RwdCell
                        mapToHeader={isMobile ? 'Select' : selectName}
                        style={{ width: 90 }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={!!checkedItems.has(recipient.id)}
                              onChange={() => onUpdateCheck(recipient.id)}
                              style={styles.checkbox}
                            />
                          }
                          label=""
                        />
                      </RwdCell>
                    )}
                    <RwdCell mapToHeader={'Recipient'}>
                      <div className={styles.addressBlock}>
                        <Text type="caption">
                          {recipient.address.firstName}{' '}
                          {recipient.address.lastName}
                        </Text>
                        <Text type="caption">{recipient.address.address1}</Text>
                        <Text type="caption">{recipient.address.address2}</Text>
                        <Text type="caption">
                          {recipient.address.city}, {recipient.address.state}{' '}
                          {recipient.address.postalCode}
                        </Text>
                        <Text type="caption">{recipient.address.country}</Text>
                      </div>
                    </RwdCell>
                    <RwdCell mapToHeader={'Status'}>
                      <Text type="caption">
                        {context === 'paused' ? 'Paused' : 'Processing'}
                      </Text>
                    </RwdCell>
                    <RwdCell mapToHeader={'Card'}>
                      <div
                        onClick={() => onCardClicked(recipient)}
                        className={`${styles.cardActions}`}
                      >
                        <div className={`${styles.cardPreview}`}>
                          <img
                            style={{ height: '150px' }}
                            id={`resend_card_btn_${index}`}
                            src={recipient.card.frontPreviewUrl}
                            alt={'card preview'}
                          />
                          {recipient.gift && (
                            <div
                              className={styles.giftPreview}
                              style={{
                                backgroundImage: `url(${recipient.gift.defaultImageUrl})`,
                              }}
                            />
                          )}
                        </div>
                        {recipient.card.sendableCard && (
                          <Div maxWidth="125px">
                            <Text type="caption" alignment="center">
                              {recipient.card.sendableCard.title}
                            </Text>
                          </Div>
                        )}
                      </div>
                    </RwdCell>
                  </RwdRow>
                  <tr className={styles.tableSpacer} />
                </tbody>
              ))}
        </RwdTable>
      )}
    </div>
  )
}

export default RecipientTable
