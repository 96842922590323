import {
  CardFragment,
  CardType,
  LayoutDimensions,
} from 'src/graphql/generated/graphql'

const getCardDimensions = (card: CardFragment): LayoutDimensions => {
  if (card.type === CardType.Postcard) {
    return LayoutDimensions.Postcard
  } else if (card.isHorizontal) {
    return LayoutDimensions.Horizontal
  } else {
    return LayoutDimensions.Vertical
  }
}

export default getCardDimensions
