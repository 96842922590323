import { Maybe, Operation } from '../../../__generated__/globalTypes'

import {
  GroupFragment,
  groupFragmentString,
} from '../../fragments/__generated__/GroupFragment'

import getPaginatedGroupsRawString from '../GetPaginatedGroups.graphql'

const getPaginatedGroupsString = [
  getPaginatedGroupsRawString,
  groupFragmentString,
].join('\n\n')

export type GetPaginatedGroupsData = {
  paginatedGroups: {
    groups: GroupFragment[]
    count: number
  }
}

export type GetPaginatedGroupsVariables = {
  search?: Maybe<string>
  offset: number
  limit: number
  groups?: Maybe<string[]>
  contacts?: Maybe<string[]>
}

export type GetPaginatedGroups = Operation<
  GetPaginatedGroupsData,
  GetPaginatedGroupsVariables
>

export const GetPaginatedGroups = ({
  search,
  offset,
  limit,
  groups,
  contacts,
}: GetPaginatedGroupsVariables): GetPaginatedGroups => ({
  query: getPaginatedGroupsString,
  variables: {
    search,
    offset,
    limit,
    groups,
    contacts,
  },
})
