import React from 'react'

// @src imports
import { useActions } from 'src/hooks'
import { Icon, NavLink, Transition } from 'src/chrome'
import suspenseBoundary from 'src/chrome/SuspenseBoundary/suspenseBoundaryHOC'
import { AccountRoute } from 'src/dashboard/routes/AccountRoute'
import { NotificationsRoute } from 'src/dashboard/routes/NotificationsRoute'
import { DraftedOrdersRoute } from 'src/dashboard/routes/DraftedOrdersRoute'
import { DraftedCardsRoute } from 'src/dashboard/routes/DraftedCardsRoute'
import { CardHistoryRoute } from 'src/dashboard/routes/CardHistoryRoute'
import { PaymentSettingsRoute } from 'src/dashboard/routes/PaymentSettingsRoute'
import { PricingRoute } from 'src/marketing/plans/routes/PricingRoute'

import styles from './dashboardNav.module.scss'
import {
  Icon as QDSIcon,
  Logo,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { RelationshipManagerRoute } from '../../../contacts/routes/RelationshipManagerRoute'
import {
  useNotificationCount,
  useReceivedPendingMembershipInvites,
} from 'src/react_query'

const DashboardNav: React.FC<{}> = () => {
  const actions = useActions()

  const { data: notificationCount } = useNotificationCount()
  const {
    data: pendingMembershipInvites,
  } = useReceivedPendingMembershipInvites()

  const notificationsCounter =
    (notificationCount ?? 0) + (pendingMembershipInvites?.length ?? 0)

  const notificationCountText =
    notificationsCounter > 0
      ? notificationsCounter <= 10
        ? notificationsCounter
        : '10+'
      : null

  const IconColor = '#6F8394'

  return (
    <div className={styles.dashboardNav}>
      <div className={styles.logo}>
        <NavLink onClick={actions.openCatalog}>
          <Logo brand={'promptings'} color="color" type="icon" width="50px" />
        </NavLink>
      </div>
      <div>
        <ul>
          <li className={styles.categoryTitle}>
            <Text
              type="body"
              color="primaryHeading"
              weight="semiBold"
              style={{ marginLeft: 6 }}
            >
              Account
            </Text>
          </li>
          <li>
            <span className={styles.linkIcon}>
              <QDSIcon name="home" size={18} primaryColor={IconColor} />
            </span>
            <NavLink
              id="my_account_dashboard_nav_button"
              activeClassName={styles.active}
              route={AccountRoute}
              onClick={() => actions.openAccount()}
            >
              Dashboard
            </NavLink>
          </li>
          <li>
            <span className={styles.linkIcon}>
              <Icon icon={'CREDITCARD'} size={16} color={IconColor} />
            </span>
            <NavLink
              id={'goto_payment_settings_btn'}
              activeClassName={styles.active}
              route={PaymentSettingsRoute}
              onClick={actions.openPaymentSettings}
            >
              Payment Settings
            </NavLink>
          </li>
        </ul>

        <ul>
          <Spacer space="x1" />
          <li className={styles.categoryTitle}>
            <Text
              type="body"
              color="primaryHeading"
              weight="semiBold"
              style={{ marginLeft: 6 }}
            >
              SendOutCards
            </Text>
          </li>
          <li className={styles.backToEditor}>
            <span className={styles.linkIcon}>
              <Icon
                icon={'SOCLOGO'}
                viewBox={'0 0 77 72'}
                size={24}
                color={'#e687ff'}
              />
            </span>
            <NavLink id="goto_catalog_btn" onClick={actions.openCatalog}>
              Send a Card
            </NavLink>
          </li>
          <li>
            {notificationCountText !== null && (
              <NavLink onClick={actions.openNotifications}>
                <span
                  id="notifications_badge"
                  className={`${styles.notificationBadge} ${styles.linkIcon}`}
                  style={{ bottom: 0, right: 2 }}
                >
                  {notificationCountText}
                </span>
              </NavLink>
            )}
            <NavLink
              id="goto_notifications_btn"
              activeClassName={styles.active}
              route={NotificationsRoute}
              onClick={actions.openNotifications}
            >
              Notifications
            </NavLink>
          </li>
          <li>
            <span className={styles.linkIcon}>
              <Icon icon={'CAMPAIGN'} size={16} color={IconColor} />
            </span>
            <NavLink
              id="goto_campaigns_btn"
              activeClassName={styles.active}
              onClick={() => actions.openCampaigns({ path: 'my-campaigns' })}
            >
              Campaigns
            </NavLink>
          </li>
          <li>
            <span className={styles.linkIcon}>
              <Icon icon={'CONTACT'} size={16} color={IconColor} />
            </span>
            <NavLink
              id="goto_contact_manager_btn"
              activeClassName={styles.active}
              route={RelationshipManagerRoute}
              onClick={actions.openRelationshipManager}
            >
              Contact Manager
            </NavLink>
          </li>
          <li>
            <span className={styles.linkIcon}>
              <Icon icon={'CATALOG'} size={16} color={IconColor} />
            </span>
            <NavLink
              id="goto_drafted_cards_btn"
              activeClassName={styles.active}
              route={DraftedCardsRoute}
              onClick={actions.openDraftedCards}
            >
              Drafted Cards
            </NavLink>
          </li>
          <li>
            <span className={styles.linkIcon}>
              <Icon icon={'LIST'} size={15} color={IconColor} />
            </span>
            <NavLink
              id="goto_drafted_orders_btn"
              activeClassName={styles.active}
              route={DraftedOrdersRoute}
              onClick={actions.openDraftedOrders}
            >
              Drafted Orders
            </NavLink>
          </li>
          <li>
            <span className={styles.linkIcon} style={{ marginRight: 3 }}>
              <Icon
                icon={'ENVELOPE'}
                height={15}
                width={25}
                viewBox={'200 0 1024 1024'}
                color={IconColor}
              />
            </span>
            <NavLink
              id={'goto_card_history_btn'}
              activeClassName={styles.active}
              route={CardHistoryRoute}
              onClick={actions.openCardHistory}
            >
              Card History
            </NavLink>
          </li>
          <li>
            <span className={styles.linkIcon}>
              <Icon icon={'DOLLARSIGN'} size={16} color={IconColor} />
            </span>
            <NavLink
              id={'goto_pricing_btn'}
              activeClassName={styles.active}
              route={PricingRoute}
              onClick={actions.openPricing}
            >
              Pricing
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default suspenseBoundary({
  component: DashboardNav,
  unresolved: <Transition />,
  failure: error => `Failed loading: ${error}`,
})
