import React from 'react'
// @src imports
import {
  useAccount,
  useActions,
  useLoading,
  useMeasurements,
  useQueries,
  useState,
} from 'src/hooks'
import { PurchaseTypes } from 'src/payments/containers/Payment'
import { Transition } from 'src/chrome'
import Payment, { ExpenseReload } from 'src/plans/components/Payment'
import { PlansSlider } from 'src/marketing/plans/components'
import { getPlans, PlanFragment } from 'src/legacy_graphql'
import { useUpdateAccount } from 'src/react_query'

type Props = {
  initialPlanId: string
  onAfterPurchase: () => void
  onCancel: () => void
}

const styles = {
  paymentContainer: {
    padding: '10px 0 20px',
    '@media (max-width: 650px)': {
      paddingBottom: '100px',
    },
  },
}

const PlanBuyer: React.FC<Props> = props => {
  const { initialPlanId, onAfterPurchase, onCancel } = props

  const account = useAccount()
  const actions = useActions()
  const updateAccountMutation = useUpdateAccount()
  const [plans] = useQueries(getPlans())
  const { windowWidth } = useMeasurements()
  const [planId, setPlanId] = useState(initialPlanId)
  const purchasing = useLoading()

  const isMediumScreen = windowWidth <= 1060

  const filteredPlans = plans.filter(
    p => p.title !== 'Basic' && p.title !== 'Legacy',
  )

  const tabs = filteredPlans.map(p => p.id)

  const index = tabs.indexOf(planId)

  const changePlan = (planId: string) => {
    setPlanId(planId)
  }

  const purchase = async (
    planId: string,
    expenseReload: ExpenseReload | undefined,
  ) => {
    const account = {
      plan: planId,

      ...(expenseReload
        ? {
            ...expenseReload,
            threshold: expenseReload.threshold / 100,
            isEnabled: true,
          }
        : {}),
    }

    purchasing.start()

    try {
      const {
        updateAccount: { account: updatedAccount },
      } = await updateAccountMutation.mutateAsync({ account })
      actions.updatedAccount(updatedAccount)
      onAfterPurchase()
      purchasing.end()
    } catch (error) {
      console.error(error)
      purchasing.end()
    }
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <PlansSlider
          plans={filteredPlans}
          tabs={tabs}
          currentIndex={index}
          onRightArrowClick={() => changePlan(tabs[index + 1])}
          onLeftArrowClick={() => changePlan(tabs[index - 1])}
          onTabClick={changePlan}
          shouldSkipPlansSliderAnimation={false}
          isMediumScreen={isMediumScreen}
        />
      </div>

      {purchasing.isLoading && (
        <Transition
          message={'Purchasing plan...'}
          subMessage={undefined}
          style={{
            position: 'absolute',
            height: '100%',
          }}
        />
      )}
      <div css={styles.paymentContainer}>
        <Payment
          account={account}
          onCancel={onCancel}
          onPurchase={expenseReload => purchase(planId, expenseReload)}
          paymentInfo={{
            purchaseType: PurchaseTypes.PLAN,
            items: [plans.find(p => p.id === planId), undefined].filter(
              Boolean,
            ) as Array<PlanFragment>,
          }}
        />
      </div>
    </>
  )
}

export default PlanBuyer
