import React, { FC } from 'react'
import { SVGProps } from './types'

export const BusinessPreneur: FC<SVGProps> = ({ size, color, onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 36 30"
      width={size}
      height={size}
      onClick={onClick}
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M25.363 29.5V2.054A1.554 1.554 0 0023.809.5H12.547a1.554 1.554 0 00-1.553 1.554V29.5m14.369 0h-14.37m14.37 0h7.883a2.11 2.11 0 002.126-2.11V13.689L25.362 9.44V29.5zm-14.37 0H3.11A2.11 2.11 0 011 27.39V13.689l9.994-4.248V29.5zM3.858 16.422h4.28m-4.28 3.946h4.28m-4.28 3.942h4.28M32.5 16.423h-4.28m4.28 3.945h-4.28m4.28 3.942h-4.28M14.289 4.827h7.78v4.36h-7.78v-4.36zm0 7.991h7.78v4.36h-7.78v-4.36zm0 7.991h7.78v4.36h-7.78v-4.36z"
      />
    </svg>
  )
}
