import React, { Fragment } from 'react'

import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'

import { Set } from 'immutable'

import { DefaultError, Dialog, Icon, Transition } from 'src/chrome'

import { CampaignFragment, getAllContacts } from 'src/legacy_graphql'
import { ContactSelector } from 'src/contacts/components'
import { CampaignLineCard } from 'src/campaigns/components'
import { PartialLine } from 'src/helpers/multitouch'

import styles from './saveAsModal.module.scss'
import { useQueries, useSelector, useState } from 'src/hooks'
import suspenseBoundary from 'src/chrome/SuspenseBoundary/suspenseBoundaryHOC'
import { Input, Text } from '@sendoutcards/quantum-design-ui'
import { Button } from 'src/design_system/molecules/button/Button'

interface Props {
  recipientIds: Set<string>
  existingCampaign?: Partial<CampaignFragment> | null
  lines: PartialLine[]
  onClose: () => void
  onSubmit: (result: {
    group: {
      name: string
      members: string[]
    }
    campaign: string
  }) => void
}

interface State {
  campaignName: string
  groupName: string
  context: 'SAVE_CAMPAIGN' | 'SAVE_GROUP'
  selected: Set<string>
}

const SaveAsModal: React.FC<Props> = props => {
  const {
    recipientIds: recipients,
    onSubmit,
    existingCampaign,
    lines,
    onClose,
  } = props

  const isSmallMobile = useSelector(state => state.window.width < 426)

  const [allContacts] = useQueries(
    getAllContacts({ showSecondaryContacts: true }),
  )

  const initialState: State = {
    campaignName: '',
    groupName: '',
    context: 'SAVE_CAMPAIGN',
    selected: recipients,
  }

  const [state, setState] = useState(initialState)

  const { campaignName, groupName, selected, context } = state

  const isEveryRecipientSelected = selected.count() === recipients.count()

  const buttonFontSize = { fontSize: isSmallMobile ? '13px' : undefined }

  const handleCampaignNameChange = (newValue: string) =>
    setState({ ...state, campaignName: newValue })

  const handleGroupNameChange = (newValue: string) =>
    setState({ ...state, groupName: newValue })

  const handleContextChange = () =>
    setState(state => ({
      ...state,
      context: context === 'SAVE_CAMPAIGN' ? 'SAVE_GROUP' : 'SAVE_CAMPAIGN',
    }))

  const handleSelect = (id: string) =>
    setState(state => ({
      ...state,
      selected: selected.has(id) ? selected.delete(id) : selected.add(id),
    }))

  const handleSelectAll = () =>
    setState(state => ({
      ...state,
      selected: isEveryRecipientSelected ? Set() : recipients,
    }))

  const handleSubmit = () => {
    onSubmit({
      campaign: campaignName,
      group: {
        name: groupName,
        members: selected.toArray(),
      },
    })
    setState({ ...initialState })
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      style={{ transition: '300ms' }}
      scroll={'paper'}
    >
      <DialogTitle disableTypography={true}>
        <Text type="largeBody" weight="semiBold" inset={{ right: 'x5' }}>
          {context === 'SAVE_CAMPAIGN'
            ? 'Save Cards & Gifts as Campaign'
            : 'Save your contacts as a Group'}
        </Text>
        <span className={styles.close}>
          <Icon icon={'CLOSE'} color={'#6f8394'} size={18} onClick={onClose} />
        </span>
      </DialogTitle>
      <DialogContent className={styles.formContainer}>
        {context === 'SAVE_CAMPAIGN' && (
          <div className={styles.formInner}>
            <div>
              <Input
                type="text"
                name="campaignName"
                placeholder="Campaign Name"
                isFullWidth={true}
                value={campaignName}
                onChange={handleCampaignNameChange}
              />
              <CampaignLineCard title="Order items" lines={lines} />
            </div>
            {!!existingCampaign && (
              <div className={styles.campaignOverlay}>
                <Text type="largeBody" alignment="center" inset="x2">
                  There is already a campaign associated with this order.
                </Text>
                <Text type="caption" alignment="center" weight="bold">
                  {existingCampaign.name}
                </Text>
                <Text
                  type="caption"
                  alignment="center"
                >{`Click next to continue and "Save Contacts"`}</Text>
              </div>
            )}
          </div>
        )}
        {context === 'SAVE_GROUP' && (
          <div className={styles.formInner}>
            <div>
              <Input
                type="text"
                name="groupName"
                placeholder="Group Name"
                isFullWidth={true}
                value={groupName}
                onChange={handleGroupNameChange}
              />
              <Fragment>
                <Text
                  type="caption"
                  outset={{ top: 'x2' }}
                >{`This order has ${recipients.count()} recipient${
                  recipients.count() === 1 ? '' : 's'
                }`}</Text>
                <ContactSelector
                  contacts={allContacts.filter(contact =>
                    recipients.contains(contact.id),
                  )}
                  selected={selected}
                  isEveryContactSelected={isEveryRecipientSelected}
                  onSelect={handleSelect}
                  onSelectAll={handleSelectAll}
                />
                <Text type="caption"> {`${selected.size} selected`}</Text>
              </Fragment>
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions style={{ padding: '0 16px 16px' }}>
        {context !== 'SAVE_CAMPAIGN' && (
          <Button
            key={'Previous pane'}
            onClick={handleContextChange}
            fill="#000"
            padding="14px 36px"
            borderRadius="small"
            isOutlined={true}
            title={{
              ...buttonFontSize,
              content: 'Back',
            }}
          />
        )}
        <div key={'Submit save'} id={'submit_saved_as_campaign'}>
          <Button
            key={'Submit save'}
            fill="#323334"
            padding="16px 36px"
            borderRadius="small"
            isDisabled={campaignName === '' && groupName === ''}
            onClick={handleSubmit}
            title={{
              ...buttonFontSize,
              content: 'Submit',
              fontColor: '#fff',
            }}
          />
        </div>
        {context === 'SAVE_CAMPAIGN' && (
          <Button
            key={'Next dialog pane'}
            onClick={handleContextChange}
            fill="#000"
            padding="14px 36px"
            borderRadius="small"
            isOutlined={true}
            title={{
              ...buttonFontSize,
              content: 'Next',
            }}
          />
        )}
      </DialogActions>
    </Dialog>
  )
}

export default suspenseBoundary({
  component: SaveAsModal,
  unresolved: <Transition />,
  failure: DefaultError,
})
