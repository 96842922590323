import { AddressFragment, getDetailedCountries } from 'src/legacy_graphql'
import { formatCountriesAndRegions } from 'src/helpers/formatCountriesAndRegions'
import { useQueryFutures, useSelector } from 'src/hooks'

const useIsAddressValid = (orderRAddress?: AddressFragment) => {
  const account = useSelector(state => state.user.account)
  const returnAddress = orderRAddress ? orderRAddress : account?.shippingAddress

  const [{ isUnresolved, error, value: detailedCountries }] = useQueryFutures(
    getDetailedCountries(),
  )
  const { regions, isRegionsUnavailable } =
    !isUnresolved && !error && detailedCountries !== undefined
      ? formatCountriesAndRegions(detailedCountries, returnAddress?.country)
      : { regions: undefined, isRegionsUnavailable: false }
  return (
    (regions && regions.includes(returnAddress?.state ?? '')) ||
    isRegionsUnavailable
  )
}

export default useIsAddressValid
