import { Operation } from '../../../__generated__/globalTypes'

import {
  CollectionFragment,
  collectionFragmentString,
} from '../../fragments/__generated__/CollectionFragment'

import { sendableCardImageFragmentString } from '../../fragments/__generated__/SendableCardImageFragment'

import { minimalSendableCardFragmentString } from '../../fragments/__generated__/MinimalSendableCardFragment'

import getCollectionRawString from '../GetCollection.graphql'

const getCollectionString = [
  getCollectionRawString,
  collectionFragmentString,
  sendableCardImageFragmentString,
  minimalSendableCardFragmentString,
].join('\n\n')

export type GetCollectionData = {
  collection: CollectionFragment
}

export type GetCollectionVariables = {
  id: string
}

export type GetCollection = Operation<GetCollectionData, GetCollectionVariables>

export const GetCollection = ({
  id,
}: GetCollectionVariables): GetCollection => ({
  query: getCollectionString,
  variables: {
    id,
  },
})
