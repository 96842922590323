import React, { FC } from 'react'

import { Invoice } from 'src/chrome'
import { getPointsExpenseCreditsNeeded } from 'src/helpers/payments'
import { OrderWithPartialLines } from 'src/redux/reducers/orders'

import styles from '../containers/styles'
import { useAccount, useSelector } from 'src/hooks'
import { Text } from '@sendoutcards/quantum-design-ui'
import { Amount } from 'src/graphql/generated/graphql'

type OrderContextProps = {
  order: OrderWithPartialLines
  credits: Amount
  isUsingCredits: boolean
}

const OrderContext: FC<OrderContextProps> = props => {
  const { order, credits, isUsingCredits } = props

  const account = useAccount()

  const card = useSelector(state => state.orders.activeLine?.card)

  const accountReady = account.username || card

  const neededCurrency = getPointsExpenseCreditsNeeded(
    order,
    account,
    credits,
    isUsingCredits,
  )

  return (
    <div style={accountReady ? {} : { order: 2 }}>
      <div css={styles.sectionTitle}>
        <Text type="body">
          {accountReady ? 'YOUR ORDER' : "Let's finish setting up your account"}
        </Text>
      </div>
      <Invoice
        order={order}
        neededCurrency={neededCurrency}
        credits={credits}
        isUsingCredits={isUsingCredits}
      />
    </div>
  )
}

export default OrderContext
