import { Maybe } from '../../../__generated__/globalTypes'

import leaderFragmentRawString from '../AssetLeaderFragment.graphql'

export const leaderFragmentString = leaderFragmentRawString

export type LeaderFragment = {
  __typename: 'Leader'
  profileImageUrl: Maybe<string>
  firstName: string
  lastName: string
  earnedCount: number
  dateJoined: string
}

export const isLeaderFragment = (fragment: any): fragment is LeaderFragment =>
  fragment &&
  fragment.__typename === 'Leader' &&
  (fragment.profileImageUrl === null ||
    typeof fragment.profileImageUrl === 'string') &&
  typeof fragment.firstName === 'string' &&
  typeof fragment.lastName === 'string' &&
  typeof fragment.earnedCount === 'number' &&
  typeof fragment.dateJoined === 'string'
