import React from 'react'
import {
  Button,
  Div,
  Flex,
  LoadingSpinner,
  Select,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { useCallback, useEffect, useState } from 'src/hooks'
import { CampaignStoreProductFragment } from 'src/saleor_graphql/generated/graphql'
import { CampaignCarousel } from '../CampaignCarousel/CampaignCarousel'
import useHandleProductActions from 'src/pricing_page/hooks/useHandleProductActions'
import { useFeatureAccess } from 'src/hooks/useFeatureAccess'
import { FullAccessFeatureType } from 'src/graphql/generated/graphql'
import { useAddFreeCampaign } from 'src/react_query'
import { ToasterNotification } from 'src/editor/components/MobileEditorToolbar/components/ToasterNotification'
import { AnimatePresence } from 'framer-motion'

type Media = {
  url: string
  alt: string
  orientation: 'landscape' | 'portrait'
}

export const CampaignSubcategoryProduct = (props: {
  product: CampaignStoreProductFragment
  onView: (id: string) => void
}) => {
  const [activeVariantIndex, setActiveVariantIndex] = useState(0)
  const { product } = props

  const [media, setMedia] = useState<Media[]>([])
  const [isLoadingMedia, setIsLoadingMedia] = useState(false)

  const { hasFeatureAccess } = useFeatureAccess([
    FullAccessFeatureType.CampaignStore,
  ])

  const activeVariant = props.product.variants?.[activeVariantIndex]

  const loadAndAssignImageOrientation = useCallback((url: string): Promise<
    Media
  > => {
    return new Promise((res, rej) => {
      const image = new Image()

      // tslint:disable:no-object-mutation
      image.onload = () => {
        const width = image.width
        const height = image.height

        res({
          url: image.src,
          alt: image.alt,
          orientation: width >= height ? 'landscape' : 'portrait',
        })
      }
      // tslint:disable:no-object-mutation
      image.onerror = () => {
        rej(new Error('Could not load image'))
      }
      // tslint:disable:no-object-mutation
      image.src = url
    })
  }, [])

  const processVariantMedia = useCallback(async () => {
    const media = activeVariant?.media

    if (media) {
      const promises = media.map(async m => {
        try {
          const img = await loadAndAssignImageOrientation(m.url)

          return img
        } catch (err) {
          console.log(err)
          throw err
        }
      })

      return Promise.allSettled(promises)
    }

    return null
  }, [activeVariant, loadAndAssignImageOrientation])

  const { addToCart } = useHandleProductActions()
  const { mutate, data, isLoading, reset } = useAddFreeCampaign()
  const campaignId = activeVariant?.metadata.find(
    meta => meta.key === 'campaign_id',
  )

  useEffect(() => {
    ;(async () => {
      try {
        setIsLoadingMedia(true)
        const m = await processVariantMedia()

        const successfulMedia = m
          ?.map(m => {
            if (m.status === 'fulfilled') {
              return m.value
            }
            return null
          })
          .filter((m): m is Media => Boolean(m))

        if (successfulMedia) {
          setMedia(successfulMedia)
        }
      } catch (err) {
        console.error(err)
      } finally {
        setIsLoadingMedia(false)
      }
    })()
  }, [processVariantMedia])

  useEffect(() => {
    setTimeout(() => {
      reset()
    }, 5000)
  }, [data]) // eslint-disable-line

  return (
    <Div
      key={product.id}
      boxShadow="mediumLight"
      borderRadius={'small'}
      backgroundColor={'foreground'}
      inset={{ top: 'x1', bottom: 'x1', horizontal: 'x1' }}
      display="flex"
      flexDirection="column"
      width={'280px'}
      minWidth={'280px'}
      alignItems="start"
      justifyContent={'start'}
      position="relative"
      style={{ gap: '12px' }}
    >
      <Div
        height="200px"
        minHeight="200px"
        width={'100%'}
        inset={'x2'}
        backgroundColor="background"
        display={'flex'}
        justifyContent={'center'}
        alignItems="center"
      >
        {isLoadingMedia ? (
          <LoadingSpinner size="medium" />
        ) : (
          media && media.length > 0 && <CampaignCarousel images={media} />
        )}
      </Div>
      <Div>
        <Text outset={'0px'} type={'body'}>
          {product.name}
        </Text>
        <Text type="caption" weight="bold">
          {`Price: ${
            hasFeatureAccess
              ? 'Free'
              : `${activeVariant?.pricing?.price?.gross.amount.toFixed(2)}`
          }`}
        </Text>
      </Div>
      {product.variants && product.variants.length > 0 && (
        <Select
          isDropDownAbove={true}
          shouldAlignDropDownToLeft={true}
          scrollBarWidth="thin"
          alignment="left"
          selectHeight="32px"
          onChange={value => setActiveVariantIndex(value)}
          selectedOptionIndex={activeVariantIndex}
          type="text"
          options={product.variants?.map(variant => ({
            value: variant.id,
            label: variant.name,
            hasSeparator: false,
          }))}
        />
      )}
      <Flex
        className="flex"
        justifyContent="space-between"
        width="100%"
        height={'100%'}
        alignItems="flex-end"
        inset={{ top: 'x2' }}
      >
        <Button
          backgroundColorOverride="#000"
          outlined={true}
          size="small"
          disabled={!!!activeVariant?.sku}
          onClick={() => props.onView(`${activeVariant?.sku}`)}
        >
          View Items
        </Button>
        <Button
          size="small"
          backgroundColorOverride="#000"
          disabled={isLoading || (!!!campaignId && !!!activeVariant?.id)}
          onClick={() => {
            if (hasFeatureAccess && campaignId) {
              mutate({ campaignId: campaignId.value })
            } else {
              addToCart(activeVariant?.id)
            }
          }}
        >
          {isLoading ? (
            <Flex
              style={{ gap: '8px' }}
              justifyContent="center"
              alignItems={'center'}
            >
              <Text type="caption" lineHeight={1}>
                Processing
              </Text>
              <LoadingSpinner size="xSmall" />
            </Flex>
          ) : hasFeatureAccess ? (
            'Add to Account'
          ) : (
            'Add to Cart'
          )}
        </Button>
      </Flex>
      {data && (
        <AnimatePresence>
          <ToasterNotification
            label={{
              color: 'accent',
              type: 'caption',
              content: data.addFreeCampaign.message,
            }}
            backgroundColor={
              data.addFreeCampaign.success
                ? {
                    swatch: 'success',
                    shade: '_500',
                  }
                : { swatch: 'danger', shade: '_500' }
            }
            icon={
              data?.addFreeCampaign.success
                ? {
                    name: 'check',
                    primaryColor: 'success',
                    size: 12,
                    iconContainerColor: 'background',
                  }
                : {
                    name: 'exclamation',
                    primaryColor: 'danger',
                    size: 12,
                    iconContainerColor: 'background',
                  }
            }
          />
        </AnimatePresence>
      )}
    </Div>
  )
}
