import React from 'react'
import { SVGProps } from './types'

export const VCheck: React.FC<SVGProps> = ({
  size = 24,
  color = '#323232',
  onClick,
}) => {
  return (
    <svg
      width={`${size}px`}
      height="auto"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.54467 2.92591C9.70739 3.1049 9.70739 3.3951 9.54467 3.57409L4.54467 9.07409C4.38195 9.25308 4.11813 9.25308 3.95541 9.07409L1.45541 6.32409C1.29269 6.1451 1.29269 5.8549 1.45541 5.67591C1.61813 5.49692 1.88195 5.49692 2.04467 5.67591L4.25004 8.10182L8.95541 2.92591C9.11813 2.74692 9.38195 2.74692 9.54467 2.92591Z"
        fill={color}
      />
    </svg>
  )
}
