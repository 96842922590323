import React from 'react'

import { useEffect, useState } from 'src/hooks'

import { Group } from '../../contactTypes'
import {
  Button,
  Dialog,
  Flex,
  Input,
  Text,
} from '@sendoutcards/quantum-design-ui'

type Props = {
  title: string
  onClose: () => void
  onSubmit: (group: Group) => void
  group: Group
}

const GroupEditForm: React.FC<Props> = props => {
  const { title, onClose, onSubmit, group } = props

  const [name, setName] = useState(group.name)
  const [description, setDescription] = useState(group.description ?? '')

  useEffect(() => {
    setName(group.name)
    setDescription(group.description ?? '')
  }, [group])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    onSubmit({
      id: group.id,
      name,
      description,
    })
  }

  return (
    <Dialog isOpen={true} onClose={onClose} maxWidth="380px" width="100%">
      <form onSubmit={handleSubmit}>
        <Flex flexDirection="column" rowGap="x3" width="100%">
          <Text
            type="largeBody"
            color="primaryHeading"
            inset={{ bottom: 'x2' }}
            alignment="center"
            weight="bold"
            style={{ fontSize: '2.125rem' }}
          >
            {title}
          </Text>
          <Input
            type="text"
            name="name"
            label="Group Name"
            placeholder="Group Name"
            value={name}
            onChange={setName}
            isFullWidth={true}
          />
          <Input
            type="text"
            name="description"
            label="Description"
            placeholder="Description"
            value={description}
            onChange={setDescription}
            isFullWidth={true}
          />
          <Button
            title="Save Group"
            fontWeight="extraBold"
            backgroundColorOverride="#e841b3"
            onClick={'submitForm'}
            size="small"
          />
        </Flex>
      </form>
    </Dialog>
  )
}

export default GroupEditForm
