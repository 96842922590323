import { Maybe, Operation } from '../../../__generated__/globalTypes'

import {
  GiftFragment,
  giftFragmentString,
} from '../../fragments/__generated__/GiftFragment'

import { priceFragmentString } from '../../fragments/__generated__/PriceFragment'

import { giftVariationFragmentString } from '../../fragments/__generated__/GiftVariationFragment'

import { amountFragmentString } from '../../fragments/__generated__/AmountFragment'

import getGiftsRawString from '../GetGifts.graphql'

const getGiftsString = [
  getGiftsRawString,
  giftFragmentString,
  priceFragmentString,
  giftVariationFragmentString,
  amountFragmentString,
].join('\n\n')

export type GetGiftsData = {
  gifts: GiftFragment[]
}

export type GetGiftsVariables = {
  category?: Maybe<string>
  offset: number
  limit: number
  search?: Maybe<string>
  isEligibleFreeGift?: Maybe<boolean>
}

export type GetGifts = Operation<GetGiftsData, GetGiftsVariables>

export const GetGifts = ({
  category,
  offset,
  limit,
  search,
  isEligibleFreeGift,
}: GetGiftsVariables): GetGifts => ({
  query: getGiftsString,
  variables: {
    category,
    offset,
    limit,
    search,
    isEligibleFreeGift,
  },
})
