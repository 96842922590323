import { ContactFragment, isContactFragment } from './ContactFragment'

import contactShareFragmentRawString from '../ContactShareFragment.graphql'

export const contactShareFragmentString = contactShareFragmentRawString

export type ContactShareFragment = {
  __typename: 'ContactShare'
  id: string
  contact: ContactFragment
  fromUser: {
    __typename: 'User'
    id: string
    username: string
    firstName: string
    lastName: string
    email: string
  }
  createdAt: string
}

export const isContactShareFragment = (
  fragment: any,
): fragment is ContactShareFragment =>
  fragment &&
  fragment.__typename === 'ContactShare' &&
  typeof fragment.id === 'string' &&
  (isContactFragment(fragment.contact) as boolean) &&
  fragment.fromUser &&
  fragment.fromUser.__typename === 'User' &&
  typeof fragment.fromUser.id === 'string' &&
  typeof fragment.fromUser.username === 'string' &&
  typeof fragment.fromUser.firstName === 'string' &&
  typeof fragment.fromUser.lastName === 'string' &&
  typeof fragment.fromUser.email === 'string' &&
  typeof fragment.createdAt === 'string'
