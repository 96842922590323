import { Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import randomColor from 'randomcolor'
import { Trophy } from '../svg/Trophy'
import { Ribbon } from '../svg/Ribbon'

type LeaderAvatarType = {
  profileImageUrl?: string
  size: string
  firstName: string
  lastName: string
  badge?: {
    placement: string
    type: 'gold' | 'silver' | 'bronze'
    size: string
    color?: string
    textSize?: 'footnote' | 'caption' | 'body'
    isBold?: boolean
  }
  isPastChallenge?: boolean
}

export const LeaderAvatar: React.FC<LeaderAvatarType> = ({
  profileImageUrl,
  size,
  badge,
  firstName,
  lastName,
  isPastChallenge,
}) => {
  const initials = `${firstName && firstName[0].toUpperCase()}${
    lastName && lastName[0].toUpperCase()
  }`

  return (
    <Div
      position="relative"
      borderRadius="circle"
      maxHeight={size}
      maxWidth={size}
      height={'100%'}
      width={'100%'}
      outset={badge ? 'x_5' : 'x0'}
      style={
        profileImageUrl
          ? {
              backgroundImage: `url(${profileImageUrl ?? ''})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              filter: 'drop-shadow(0px 4px 4px rgba(87,83,83, 25%))',
              aspectRatio: '1/1',
              width: size,
              height: size,
              position: 'relative',
            }
          : {}
      }
      justifyContent="center"
      backgroundColor={profileImageUrl}
    >
      {!profileImageUrl && (
        <Div
          display="flex"
          backgroundColor={randomColor()}
          width={size}
          height={size}
          borderRadius="circle"
          justifyContent="center"
          alignItems="center"
          style={{ fontFamily: "'Montserrat',sans-serif" }}
        >
          {initials}
        </Div>
      )}
      {badge && (
        <Flex
          justifyContent="center"
          alignItems="center"
          position="absolute"
          borderRadius="circle"
          inset="x_25"
          bottom="0"
          right={badge.type === 'gold' ? '2px' : '-6px'}
          style={{
            background: 'rgba(255, 255, 255, 0.4)',
            boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
            backdropFilter: 'blur(15px)',
          }}
        >
          <Flex
            justifyContent="center"
            alignItems={'center'}
            borderRadius="circle"
            width={badge.size}
            height={badge.size}
            backgroundColor={badge.color}
          >
            {badge.type === 'gold' && isPastChallenge ? (
              <Trophy
                backgroundColor={badge.color}
                width={badge.size}
                height={badge.size}
              />
            ) : badge.type !== 'gold' && isPastChallenge ? (
              <Ribbon width={'15px'} height={'15px'} />
            ) : (
              <Text
                type={badge?.textSize ?? 'body'}
                weight={badge?.isBold ? 'extraBold' : 'regular'}
                color="inverseHeading"
                content={badge.placement}
              />
            )}
          </Flex>
        </Flex>
      )}
    </Div>
  )
}
