import { Route } from 'src/routes/Route'

export const EditCampaignCardRoute = Route({
  path: '/cards/:cardId/edit',
  init: (cardId: string) => ({ cardId }),
  fromPath: ({ cardId }) => (cardId ? { cardId } : undefined),
  hideSidebar: true,
})

export type EditCampaignCardRoute = Route<typeof EditCampaignCardRoute>
