import { Spacer, Text } from '@sendoutcards/quantum-design-ui'
import React, { FC } from 'react'
import { ExpenseReloadFragment } from 'src/graphql/generated/graphql'

import { FlexColumn } from 'src/styled'

import DollaPicka from '../DollaPicka'
import styles from './styles'

interface ExpenseReloadFormProps {
  expenseReload: ExpenseReloadFragment
  onChange: (name: 'purchaseAmount' | 'threshold', value: number) => void
}

const ExpenseReloadForm: FC<ExpenseReloadFormProps> = props => {
  const {
    expenseReload: { purchaseAmount, threshold },
    onChange,
  } = props
  return (
    <div css={styles.expenseReload}>
      <div css={styles.column}>
        <Text type="body" color="primaryHeading">
          Expense amount
        </Text>
        <Spacer space="x1" />
        <Text type="footnote">
          select the amount you would like in your expense bucket (Maximum of
          $50)
        </Text>
        <DollaPicka
          css={styles.picka}
          amount={purchaseAmount}
          min={1000}
          max={5000}
          step={500}
          shouldShowDecimal={true}
          onChange={amount => onChange('purchaseAmount', amount)}
        />
      </div>
      <FlexColumn css={styles.threshold}>
        <Text type="body" color="primaryHeading">
          Threshold
        </Text>
        <Spacer space="x1" />
        <Text type="footnote">Reload when my bucket balance reaches:</Text>
        <DollaPicka
          css={styles.picka}
          amount={threshold}
          min={100}
          max={purchaseAmount - 100}
          step={100}
          shouldShowDecimal={true}
          onChange={amount => onChange('threshold', amount)}
        />
      </FlexColumn>
    </div>
  )
}

export default ExpenseReloadForm
