import { Route } from 'src/routes/Route'
import { closeGiftBeingViewed } from 'src/redux/actions/giftstore'

export const GiftRoute = Route({
  path: '/gifts/:giftId',
  init: (giftId: string) => ({ giftId }),
  fromPath: ({ giftId }) => (giftId ? { giftId } : undefined),
  reducer: (route, action) =>
    action.type === closeGiftBeingViewed.type ? undefined : route,
})
export type GiftRoute = Route<typeof GiftRoute>
