import { Maybe, Operation } from '../../../__generated__/globalTypes'

import bulkDeleteOrdersString from '../BulkDeleteOrders.graphql'

export type BulkDeleteOrdersData = {
  bulkDeleteOrders: {
    successful: boolean
    failedCount: Maybe<number>
  }
}

export type BulkDeleteOrdersVariables = {
  orders: string[]
}

export type BulkDeleteOrders = Operation<
  BulkDeleteOrdersData,
  BulkDeleteOrdersVariables
>

export const BulkDeleteOrders = ({
  orders,
}: BulkDeleteOrdersVariables): BulkDeleteOrders => ({
  query: bulkDeleteOrdersString,
  variables: {
    orders,
  },
})
