import React from 'react'
import posed from 'react-pose'
import { Interpolation } from '@emotion/css'
import { withTheme } from 'emotion-theming'

import { ColorChoices } from 'src/theme/colors'
import { Theme } from 'src/theme/theme'
import { Text } from '@sendoutcards/quantum-design-ui'

// types __________________________________________________
type ComponentProps = {
  selected: string
  options: string[]
  groupName: string
  onSelect: (radioValue: string) => void
  groupHeader?: string
  theme: Theme
} & Partial<typeof defaultProps>

const defaultProps = {
  color: 'pink' as ColorChoices,
  labelPlacement: 'end' as 'start' | 'end',
  labelColor: 'mainText' as ColorChoices,
  direction: 'column' as 'column' | 'row',
  size: 'large' as 'large' | 'medium',
}

// types __________________________________________________

// styles _________________________________________________
const styles = {
  mainWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minWidth: 0,
  } as Interpolation,
  wrapper: (direction: 'column' | 'row'): Interpolation => ({
    display: 'flex',
    flexDirection: direction,
    flexWrap: 'wrap',
    justifyContent: 'center',
  }),
  input: {
    opacity: 0,
    position: 'absolute',
    left: '3px',
    cursor: 'pointer',
  },
  circle: (
    theme: Theme,
    size: 'large' | 'medium',
    color: ColorChoices,
  ): Interpolation => ({
    width: size === 'large' ? 20 : 15,
    height: size === 'large' ? 20 : 15,
    border: `solid ${theme.colors[color]} 2px`,
    borderRadius: 20,
  }),
  innerCircle: (
    theme: Theme,
    size: 'large' | 'medium',
    color: ColorChoices,
  ): Interpolation => ({
    width: size === 'large' ? 10 : 7,
    height: size === 'large' ? 10 : 7,
    borderRadius: 15,
    position: 'relative',
    top: size === 'large' ? 3 : 2,
    left: size === 'large' ? 3 : 2,
    backgroundColor: theme.colors[color],
  }),
  buttonAndLabel: (labelPlacement: 'start' | 'end'): Interpolation => ({
    cursor: 'pointer',
    alignItems: 'center',
    display: 'inline-flex',
    margin: 5,
    position: 'relative',
    flexDirection: labelPlacement === 'start' ? 'row' : 'row-reverse',
  }),
  label: {
    margin: '0 10px',
  },
}
// styles _________________________________________________

const CircleAnimator = posed.div({
  show: {
    scale: 1,
    transition: {
      type: 'spring',
      stiffness: 1000,
      damping: 30,
    },
  },
  hide: { scale: 0 },
})

// component ______________________________________________
const RadioButtons: React.FC<ComponentProps> = props => {
  const {
    options,
    direction = defaultProps.direction,
    groupName,
    onSelect,
    selected,
    groupHeader,
    size = defaultProps.size,
    color = defaultProps.color,
    labelPlacement = defaultProps.labelPlacement,
    theme,
  } = props
  return (
    <div css={styles.mainWrapper}>
      {groupHeader && <Text type="body">{groupHeader}</Text>}
      <div css={styles.wrapper(direction)}>
        {options.map(option => (
          <div
            css={theme => styles.buttonAndLabel(labelPlacement)}
            onClick={() => onSelect(option)}
            key={option}
          >
            <input
              checked={option === selected}
              type="radio"
              value={option}
              name={groupName}
              css={{
                opacity: 0,
                position: 'absolute',
                left: '3px',
                cursor: 'hand',
              }}
            />
            <div css={theme => styles.circle(theme, size, color)}>
              <CircleAnimator
                pose={option === selected ? 'show' : 'hide'}
                css={styles.innerCircle(theme, size, color)}
              />
            </div>
            <Text type="caption" outset={{ horizontal: 'x1_5' }}>
              {option}
            </Text>
          </div>
        ))}
      </div>
    </div>
  )
}

export default withTheme(RadioButtons)
