import React from 'react'
import range from 'lodash/range'

import { Fade } from 'src/chrome'
import { compact } from 'src/helpers'

const rightBorder: React.CSSProperties = {
  borderRightStyle: 'dashed',
  borderRightColor: '#ececec',
  borderRightWidth: 3,
}
const bottomBorder: React.CSSProperties = {
  // borderBottomStyle: 'dashed',
  borderBottomColor: '#ececec',
  borderBottomWidth: 3,
}
const labelStyle: React.CSSProperties = {
  background: 'gray',
  color: '#fff',
  padding: 8,
  borderRadius: 10,
}

type Range = {
  min: number
  max: number
}

export type Entry = {
  names?: string[]
  previewUrl?: string
  numSections: number
  height: number
  width: number
}

const SliderConfig = (
  config: Entry[],
  margin: number,
  verticalScroll: boolean,
) => {
  const axis = verticalScroll ? 'height' : 'width'

  const flatNameList = config.flatMap(con => con.names ?? [])

  const maxElementHeight = config.reduce(
    (max: number, item) =>
      item.height / item.numSections > max ? item.height : max,
    0,
  )

  const maxElementWidth = config.reduce(
    (max: number, item) =>
      item.width / item.numSections > max ? item.width : max,
    0,
  )

  const totalLength =
    config.reduce((length: number, item) => length + item[axis], 0) +
    (config.length - 1) * margin

  const totalSections = config.reduce(
    (n: number, item) => n + item.numSections,
    0,
  )

  const allSections = config.flatMap(item =>
    range(1, item.numSections + 1).map(section => ({ item, section })),
  )

  const getSectionRanges = (
    sectionLength: number,
    numSections: number,
    currentMin: number,
    border = false,
  ): Range[] =>
    range(1, numSections + 1).reduce((ranges: Range[], i) => {
      const min = ranges.length > 0 ? ranges[ranges.length - 1].max : currentMin
      const slideSize = sectionLength / numSections
      const tempMax = min + sectionLength / numSections
      return [
        ...ranges,
        {
          min,
          max:
            border && i === numSections
              ? min + slideSize * 1.5
              : i === 0 || i === numSections
              ? tempMax + margin * 2
              : tempMax,
        },
      ]
    }, [])

  const borderOverlayStyles = (
    height: number,
    width: number,
    numSections: number,
  ) => ({
    height: verticalScroll ? height / numSections : height,
    width: width / numSections,
    opacity: 1,
  })

  const marginStyles = (height: number, width: number) => ({
    height: verticalScroll ? margin : height,
    width: verticalScroll ? width : margin,
  })

  const ranges = (() => {
    const { allRanges } = config.reduce(
      ({ allRanges, currentMin, boundary }, item, i) => {
        const min = i === 0 ? item[axis] * -1 : currentMin
        const ranges = getSectionRanges(
          item[axis],
          item.numSections,
          min,
          boundary,
        )
        return {
          allRanges: [...allRanges, ...ranges],
          currentMin: ranges.length > 0 ? ranges[ranges.length - 1].max : min,
          boundary: i === config.length - 1,
        }
      },
      { allRanges: Array<Range>(), currentMin: 0, boundary: true },
    )
    return [
      ...allRanges.slice(0, -1),
      { ...allRanges[allRanges.length - 1], max: totalLength },
    ]
  })()

  const calculateScale = (containerHeight: number, containerWidth: number) => {
    const computedMargin = verticalScroll ? margin * 2 : margin * 4
    const maxWidth = maxElementWidth + computedMargin
    const maxHeight = maxElementHeight
    const scale = Math.min(containerWidth / maxWidth, 1)
    const scaledHeight = maxElementHeight * scale
    return scaledHeight <= containerHeight ? scale : containerHeight / maxHeight
  }
  const getSnapIndex = (currentPos: number): number =>
    ranges.findIndex(
      range => currentPos <= range.max && currentPos >= range.min,
    )
  const fadingLabels = (index: number) =>
    flatNameList.map((name: string, i: number) => (
      <Fade
        key={name}
        id={name}
        fadeIn={500}
        fadeOut={100}
        loop={1}
        isVisible={index === i}
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: maxElementWidth,
          position: 'absolute',
          top: 40,
        }}
      >
        <div style={labelStyle}>{flatNameList[index]}</div>
      </Fade>
    ))
  const marginDivList = () =>
    allSections.flatMap(({ item, section }, n) => {
      const styles = borderOverlayStyles(
        item.height,
        item.width,
        item.numSections,
      )
      return compact(
        <div
          key={n}
          style={
            item.numSections > 1 && section !== item.numSections
              ? {
                  ...styles,
                  ...(verticalScroll ? bottomBorder : rightBorder),
                }
              : styles
          }
        />,
        config.indexOf(item) !== config.length - 1 && (
          <div
            key={Math.random()}
            style={marginStyles(item.height, item.width)}
          />
        ),
      )
    })

  const sumLengthToIndex = (targetIndex: number): number =>
    allSections.reduce(
      (length, { item, section }, index) =>
        index < targetIndex
          ? length +
            item[axis] / item.numSections +
            (section === item.numSections ? margin : 0)
          : length,
      0,
    )

  return {
    axis,
    flatNameList,
    maxElementHeight,
    maxElementWidth,
    totalLength,
    totalSections,
    allSections,
    ranges,
    calculateScale,
    getSnapIndex,
    fadingLabels,
    marginDivList,
    sumLengthToIndex,
  }
}

export default SliderConfig
