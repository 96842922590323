import { useUpgradeToReferringCustomer } from 'src/react_query'
import { useActions, useCallback, useEffect, useState } from '.'
import usePersistedUserData from './usePersistedUserData'

const useConvertToRep = () => {
  const { account } = usePersistedUserData()
  const actions = useActions()
  const createAffiliateMutation = useUpgradeToReferringCustomer()

  const [hasSetToConvert, setHasSetToConvert] = useState(false)

  const accType = account?.type
  const username = account?.username

  const createAff = useCallback(async () => {
    if (!account) return
    setHasSetToConvert(true)
    const {
      upgradeToReferringCustomer: { account: updatedAccount },
    } = await createAffiliateMutation.mutateAsync({})

    if (updatedAccount) {
      actions.updatedAccount(updatedAccount)
    }
  }, [account, actions, createAffiliateMutation])

  useEffect(() => {
    if (!hasSetToConvert && accType === 'C' && username && username !== '') {
      createAff()
    }
  }, [
    accType,
    username,
    actions,
    hasSetToConvert,
    setHasSetToConvert,
    createAff,
  ])
}

export default useConvertToRep
