import {
  UpdateContactInput,
  Operation,
} from '../../../__generated__/globalTypes'

import {
  DetailedContactFragment,
  detailedContactFragmentString,
} from '../../fragments/__generated__/DetailedContactFragment'

import { calendarDateFragmentString } from '../../fragments/__generated__/CalendarDateFragment'

import { contactFragmentString } from '../../fragments/__generated__/ContactFragment'

import { optionalYearDateFragmentString } from '../../fragments/__generated__/OptionalYearDateFragment'

import { relatedContactFragmentString } from '../../fragments/__generated__/RelatedContactFragment'

import { noteFragmentString } from '../../fragments/__generated__/NoteFragment'

import updateContactRawString from '../UpdateContact.graphql'

const updateContactString = [
  updateContactRawString,
  detailedContactFragmentString,
  calendarDateFragmentString,
  contactFragmentString,
  optionalYearDateFragmentString,
  relatedContactFragmentString,
  noteFragmentString,
].join('\n\n')

export type UpdateContactData = {
  createOrUpdateContact: {
    contact: DetailedContactFragment
  }
}

export type UpdateContactVariables = {
  contact: UpdateContactInput
}

export type UpdateContact = Operation<UpdateContactData, UpdateContactVariables>

export const UpdateContact = ({
  contact,
}: UpdateContactVariables): UpdateContact => ({
  query: updateContactString,
  variables: {
    contact: UpdateContactInput(contact),
  },
})
