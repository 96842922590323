import { Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { NumberInput } from 'src/design_system/molecules/numberInput/NumberInput'
import { useUpdateCheckoutLineQuantity } from 'src/react_query'
import { CheckoutLineFragment } from 'src/saleor_graphql/generated/graphql'

type ProductSummaryCardProps = {
  product: CheckoutLineFragment
  onDelete: () => void
}

export const ProductCard = (props: ProductSummaryCardProps) => {
  const { product, onDelete } = props
  const { mutate } = useUpdateCheckoutLineQuantity()
  const [main] = product.variant.media ?? []

  const productLimit = product.variant.quantityLimitPerCustomer

  const hasDiscount =
    product.totalPrice.net.amount !== product.undiscountedTotalPrice.amount

  const handleTruncateTitle = (title?: string) => {
    const MAX_TITLE_LENGTH = 25
    if (!title) return

    return title.length >= MAX_TITLE_LENGTH
      ? title.substring(0, MAX_TITLE_LENGTH) + '...'
      : title
  }

  return (
    <Flex
      width="100%"
      inset="16px 24px"
      backgroundColor="#fff"
      flexDirection="row"
      alignItems="center"
      columnGap="x2_5"
      justifyContent="center"
      style={{ fontFamily: 'Montserrat, san serif', borderRadius: '.75rem' }}
    >
      <Flex
        minHeight="60px"
        minWidth="60px"
        width="70px"
        height="70px"
        justifyContent="center"
        alignItems="center"
      >
        <Flex
          height="100%"
          width="100%"
          backgroundPosition="center"
          backgroundSize="contain"
          backgroundRepeat="no-repeat"
          backgroundImage={
            main
              ? main.url !== ''
                ? main.url
                : main.alt !== ''
                ? main.alt
                : undefined
              : undefined
          }
        />
      </Flex>
      <Flex alignItems="center" width="100%" justifyContent="space-between">
        <Flex flexDirection="column" rowGap="x1">
          <Text
            type="body"
            content={handleTruncateTitle(product.variant.name)}
            style={{
              fontSize: '1rem',
              color: '#000',
            }}
          />
          {productLimit === 1 ? (
            <Div
              height="fit-content"
              width="fit-content"
              inset="0 12px"
              backgroundColor="background"
              style={{
                borderRadius: '30px',
                fontFamily: 'Montserrat, san serif',
                fontSize: '.875rem',
              }}
            >
              Qty: <b>{product.quantity}</b>
            </Div>
          ) : (
            <NumberInput
              lineQuantity={product.quantity}
              onChange={quantity => {
                mutate({
                  lines: [
                    {
                      quantity: quantity,
                      lineId: product.id,
                    },
                  ],
                })
              }}
              min={1}
              quantityAvailable={productLimit ?? undefined}
              step={1}
              isDisabled={product.quantity === productLimit}
            />
          )}
        </Flex>
        <Flex flexDirection="row" columnGap="x3" alignItems="center">
          <Flex
            justifyContent="center"
            width="4rem"
            style={{
              flex: 'none',
              flexDirection: 'column',
            }}
          >
            {hasDiscount && (
              <Text
                type="body"
                content={`$${product.undiscountedTotalPrice.amount.toFixed(2)}`}
                style={{
                  fontSize: '1rem',
                  fontWeight: 700,
                  color: '#000',
                }}
                isStrikeThrough={true}
              />
            )}
            <Text
              type="body"
              content={`$${product.totalPrice.net.amount.toFixed(2)}`}
              style={{ fontSize: '1rem', fontWeight: 700, color: '#000' }}
            />
          </Flex>
          <Div
            display="flex"
            height="fit-content"
            justifyContent="center"
            alignItems="center"
            backgroundColor="#F87171"
            inset=".625rem"
            style={{ borderRadius: '.65rem', cursor: 'pointer' }}
            onClick={onDelete}
          >
            <Icon color="#fff" name="trash" size={16} />
          </Div>
        </Flex>
      </Flex>
    </Flex>
  )
}
