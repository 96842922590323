import { Route } from 'src/routes/Route'
import { Subroute } from 'src/routes/Subroute'
import { GiftCategoryRoute } from 'src/gift_store/routes/GiftCategoryRoute'
import { GiftRoute } from 'src/gift_store/routes/GiftRoute'
import {
  dismissChildCategory,
  selectChildCategory,
  selectGiftToView,
  selectHighLevelCategory,
} from 'src/redux/actions/giftstore'

export const GiftStoreSubroute = Subroute(GiftCategoryRoute, GiftRoute)
export type GiftStoreSubroute = Subroute<typeof GiftStoreSubroute>

export const GiftStoreRoute = Route({
  path: '/store',
  init: (subroute?: GiftStoreSubroute, highLevelCategory?: string) => ({
    subroute,
    highLevelCategory,
  }),
  fromPath: () => ({ highLevelCategory: undefined }),
  subroute: GiftStoreSubroute,
  reducer: (route, action) => {
    switch (action.type) {
      case dismissChildCategory.type:
        return { ...route, subroute: undefined }
      case selectHighLevelCategory.type:
        return {
          ...route,
          subroute: undefined,
          highLevelCategory: action.categoryId,
        }
      case selectChildCategory.type:
        return { ...route, subroute: GiftCategoryRoute(action.categoryId) }
      case selectGiftToView.type:
        return {
          ...route,
          subroute: route.subroute ? route.subroute : GiftRoute(action.giftId),
        }
      default:
        return route
    }
  },
})

export type GiftStoreRoute = Route<typeof GiftStoreRoute>
