import { Operation } from '../../../__generated__/globalTypes'

import {
  SponsorFragment,
  sponsorFragmentString,
} from '../../fragments/__generated__/SponsorFragment'

import { storefrontFragmentString } from '../../fragments/__generated__/StorefrontFragment'

import { minimalSponsorFragmentString } from '../../fragments/__generated__/MinimalSponsorFragment'

import getSponsorsRawString from '../GetSponsors.graphql'

const getSponsorsString = [
  getSponsorsRawString,
  sponsorFragmentString,
  storefrontFragmentString,
  minimalSponsorFragmentString,
].join('\n\n')

export type GetSponsorsData = {
  sponsors: SponsorFragment[]
}

export type GetSponsorsVariables = {
  search: string
}

export type GetSponsors = Operation<GetSponsorsData, GetSponsorsVariables>

export const GetSponsors = ({ search }: GetSponsorsVariables): GetSponsors => ({
  query: getSponsorsString,
  variables: {
    search,
  },
})
