import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import DialogContent from '@material-ui/core/DialogContent'
import { Button, Dialog } from 'src/chrome'

import styles from './progressModal.module.scss'
import { useEffect, useState } from 'src/hooks'
import { Text } from '@sendoutcards/quantum-design-ui'

interface Props {
  size: number
  isOpen: boolean
  currentCount: number
  totalCount: number | undefined
  errorTitle: string
  errorList?: string[]
  message: string
  infoChild?: React.ReactNode
  onClose?: () => void
  close?: () => void
  isDone?: boolean
}

const ProgressModal: React.FC<Props> = props => {
  const [completed, setCompleted] = useState(0)
  const {
    isOpen,
    close,
    currentCount,
    totalCount,
    message,
    errorList,
    size,
    errorTitle,
    infoChild,
    onClose,
    isDone,
  } = props

  useEffect(() => {
    setCompleted(totalCount ? Math.ceil((currentCount / totalCount) * 100) : 0)
  }, [currentCount, totalCount])

  return (
    <Dialog disableBackdropClick={true} open={isOpen} onClose={close}>
      <DialogContent className={styles.progressModal}>
        <div className={styles.progressTitle}>
          <Text type="title">
            {currentCount} {totalCount === undefined ? '' : `/ ${totalCount}`}{' '}
            {message}
          </Text>
        </div>
        {isDone !== true && (
          <div className={styles.body}>
            <CircularProgress
              variant={'determinate'}
              classes={{
                root: styles.circularProgress,
              }}
              value={completed}
              thickness={10}
              size={size || 50}
            />
          </div>
        )}
        {errorList && errorList.length > 0 && (
          <div className={styles.errors}>
            <Text type="body" alignment="center" color="primaryBrand">
              {errorTitle}
            </Text>
            <div className={styles.errorList}>
              <List>
                {errorList.map((error, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      disableTypography={true}
                      primary={<Text type="caption">{error}</Text>}
                    />
                  </ListItem>
                ))}
              </List>
            </div>
          </div>
        )}
        {infoChild && <div>{infoChild}</div>}
        {onClose && (
          <div className={styles.actions}>
            {(totalCount === undefined ||
              (errorList && errorList.length + currentCount >= totalCount)) && (
              <Button id={'okay_btn'} title={'Okay'} onClick={onClose} />
            )}
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ProgressModal
