import {
  Div,
  Flex,
  Input,
  LoadingSpinner,
  Text,
} from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { useSelector } from 'src/hooks'
import { Memoized } from 'src/hooks/dependencies'
import AccountSponsorSelector from 'src/onboarding/components/AccountSponsorSelector'

type InputType = {
  email: string
  username: string
  password: string
}
type ErrorType = {
  email: string | undefined
  username: string | null | undefined
  password: string | null | undefined
  universal?: string | null | undefined
}

interface Props {
  setData: (input: InputType) => void
  data: InputType
  errors?: ErrorType
  isLoading: boolean
  isLoadingEmailValidation: boolean
  shouldNotAutoComplete?: boolean
  shouldStackForm?: boolean
  handleSponsorChanges: Memoized<(userId: string, genealogyId?: string) => void>
}

export const RawCreateAccountForm: React.FC<Props> = props => {
  const {
    setData,
    data,
    isLoading,
    isLoadingEmailValidation,
    errors,
    shouldNotAutoComplete,
    shouldStackForm = false,
    handleSponsorChanges,
  } = props
  const isMobile = useSelector(state => state.window.width < 980)
  const shouldStack = isMobile || shouldStackForm

  return (
    <Flex flexDirection="column">
      {isLoading ? (
        <Flex
          justifyContent="center"
          alignItems="center"
          minHeight={shouldStack ? '372px' : '314px'}
          flexDirection="column"
        >
          <Div outset={{ bottom: 'x4' }}>
            <LoadingSpinner size="large" />
          </Div>
          <Text
            type={shouldStack ? 'body' : 'subtitle'}
            content="Creating account . . ."
          />
        </Flex>
      ) : (
        <>
          <Flex
            justifyContent="space-between"
            width="100%"
            flexDirection={shouldStack ? 'column' : 'row'}
          >
            <Div
              width={shouldStack ? '100%' : 'calc(100% / 2)'}
              outset={shouldStack ? { bottom: 'x_75' } : { right: 'x1_5' }}
            >
              <Input
                message={
                  errors?.email && !errors?.universal
                    ? { type: 'danger', content: errors.email }
                    : undefined
                }
                type="email"
                onChange={email => setData({ ...data, email })}
                value={data.email}
                placeholder="Email"
                isFullWidth={true}
                icon="envelope"
                isLoading={isLoadingEmailValidation}
              />
            </Div>
            <Div
              width={shouldStack ? '100%' : 'calc(100% / 2)'}
              outset={shouldStack ? { vertical: 'x_75' } : { left: 'x1_5' }}
            >
              <Input
                message={
                  errors?.username && !errors?.universal
                    ? { type: 'danger', content: errors.username }
                    : undefined
                }
                isFullWidth={true}
                type="text"
                onChange={username => setData({ ...data, username })}
                value={data.username}
                placeholder="Username"
                icon="groups"
                autoComplete={shouldNotAutoComplete ? 'false' : undefined}
              />
            </Div>
          </Flex>
          <Div width="100%" outset={{ top: shouldStack ? 'x_75' : 'x2' }}>
            <Input
              message={
                errors?.password && !errors?.universal
                  ? { type: 'danger', content: errors.password }
                  : undefined
              }
              type="password"
              onChange={password => setData({ ...data, password })}
              value={data.password}
              placeholder="Password"
              isFullWidth={true}
              icon="lock"
              autoComplete={shouldNotAutoComplete ? 'new-password' : undefined}
            />
          </Div>
          <Div
            maxWidth={shouldStack ? '100%' : '450px'}
            outset={{ top: shouldStack ? 'x1_5' : 'x2' }}
          >
            <AccountSponsorSelector
              isAffiliateJoining={false}
              handleSponsorChanges={handleSponsorChanges}
            />
          </Div>
        </>
      )}
      {errors?.universal && (
        <Text
          outset={{ top: 'x1', left: 'x_5' }}
          content={errors?.universal?.toString()}
          type="caption"
          color="danger"
        />
      )}
    </Flex>
  )
}
