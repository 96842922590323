import {
  Anchor,
  Button,
  Div,
  Flex,
  Icon,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { useSelector } from 'src/hooks'
import {
  SelectedItemCard,
  SelectedItemCardProps,
} from 'src/upsale/components/shared/SelectedItemCard'

type PlanDiffDialogProps = {
  title: string
  description: string
  currentPlan: SelectedItemCardProps
  newPlan: SelectedItemCardProps
  action: {
    title: string
    onClick: () => void
  }
  onClose: () => void
}

export const PlanDiffDialog: React.FC<PlanDiffDialogProps> = ({
  title,
  description,
  currentPlan,
  newPlan,
  action,
  onClose,
}) => {
  const isMobile = useSelector(state => state.window.width <= 850)
  return (
    <Flex width="100%" flexDirection="column" inset="x2">
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        inset={{ horizontal: 'x6', top: 'x5', bottom: 'x3' }}
      >
        <Text type="subtitle" content={title} weight="bold" />
        <Spacer space="x2_5" />
        <Text type="body" content={description} />
        <Flex
          width="100%"
          rowGap={isMobile ? '' : 'x3'}
          columnGap="x3"
          justifyContent="center"
          alignItems="center"
          outset={{ top: 'x3' }}
          flexDirection={isMobile ? 'column' : undefined}
        >
          <Flex flexDirection="column" width="100%" style={{ flex: '1' }}>
            <Text
              type="caption"
              content="Moving From (current plan):"
              weight="bold"
              style={{ color: '#D1D5DB' }}
              alignment={isMobile ? 'center' : undefined}
            />
            <Spacer space="x2" />
            <SelectedItemCard
              key={currentPlan.id}
              id={currentPlan.id}
              title={currentPlan.title}
              description={currentPlan.description}
              price={currentPlan.price}
              recurrence={currentPlan.recurrence}
              backgroundColor={currentPlan.backgroundColor}
              icon={currentPlan.icon}
              // itemStatus="Current Plan"
            />
          </Flex>
          <Div
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ transform: isMobile ? 'rotate(90deg)' : '' }}
            inset={{
              top: isMobile ? 'x1' : 'x4',
              bottom: isMobile ? 'x4' : '',
            }}
          >
            <Icon name="swap" size="medium" primaryColor="default" />
          </Div>
          <Flex flexDirection="column" width="100%" style={{ flex: '1' }}>
            <Text
              type="caption"
              weight="bold"
              content="Moving To (new plan):"
              style={{ color: '#D1D5DB' }}
              alignment={isMobile ? 'center' : undefined}
            />
            <Spacer space="x2" />
            <SelectedItemCard
              key={newPlan.id}
              id={newPlan.id}
              title={newPlan.title}
              description={newPlan.description}
              price={newPlan.price}
              recurrence={newPlan.recurrence}
              backgroundColor={newPlan.backgroundColor}
              icon={newPlan.icon}
            />
          </Flex>
        </Flex>
        <Spacer space="x5" />
        <Flex
          width="100%"
          rowGap="x3"
          columnGap="x3"
          justifyContent="end"
          alignItems="center"
        >
          <Anchor onClick={onClose} title="Cancel" isDecorated={true} />
          <Button
            onClick={action.onClick}
            title={action.title}
            backgroundColorOverride="#404040"
          />
        </Flex>
      </Flex>
    </Flex>
  )
}
