import {
  BrandTypes,
  QuantumThemeProviderProps,
} from '@sendoutcards/quantum-design-ui'
import { useMemo, useQueryParams } from '.'
import { VerticalId as GraphQLVerticalID } from 'src/graphql/generated/graphql'

type VerticalID =
  | 'soc'
  | 'blue42'
  | 'bella'
  | 'streamva'
  | 'streammrkt'
  | 'promptingsacademy'
  | 'bydesign'
  | 'promptings'
  | 'greenerstill'

type Vertical = {
  id: VerticalID
  graphqlVerticalID: GraphQLVerticalID
  name: string
  theme: QuantumThemeProviderProps['theme']
  brand?: BrandTypes
  primaryColor?: string
  secondaryColor?: string
}

const getVertical = (id: string | undefined): Vertical => {
  switch (id) {
    case 'blue42':
      return {
        id,
        graphqlVerticalID: GraphQLVerticalID.Blue_42,
        name: 'Blue 42',
        theme: 'blue42',
        brand: 'blue42',
        primaryColor: '#140fee',
        secondaryColor: '#35a4bc',
      }
    case 'bella':
      return {
        id,
        graphqlVerticalID: GraphQLVerticalID.BellaCadeaux,
        name: 'Bella Cadeaux',
        theme: 'bellaCadeaux',
        brand: 'bellaCadeaux',
        primaryColor: '#ce8e47',
        secondaryColor: '#525a59',
      }
    case 'streamva':
      return {
        id,
        graphqlVerticalID: GraphQLVerticalID.StreamVa,
        name: 'Stream VA',
        theme: 'streamVA',
        brand: 'streamVA',
        primaryColor: '#61dce8',
        secondaryColor: '#1f4177',
      }
    case 'promptingsacademy':
      return {
        id,
        graphqlVerticalID: GraphQLVerticalID.PromptingU,
        name: 'Promptings Academy',
        theme: 'promptingU',
        brand: 'promptingsAcademy',
        primaryColor: '#1f4177',
        secondaryColor: '#a99a67',
      }
    case 'bydesign':
      return {
        id,
        graphqlVerticalID: GraphQLVerticalID.Bydesign,
        name: 'ByDesign',
        theme: 'greenerStill',
        brand: 'greenerStill',
      }
    case 'promptings':
    case 'greenerstill':
      return {
        id,
        graphqlVerticalID: GraphQLVerticalID.GreenerStill,
        name: 'Promptings',
        theme: 'promptings',
        brand: 'promptings',
        primaryColor: '#00e0e0',
        secondaryColor: '#6a7177',
      }
    default:
      return {
        id: 'soc',
        graphqlVerticalID: GraphQLVerticalID.Sendoutcards,
        name: 'SendOutCards',
        theme: 'sendOutCards',
        brand: 'soc',
      }
  }
}

const useVertical = (): Vertical => {
  const { client } = useQueryParams()
  const id = client
  return useMemo(() => getVertical(id), [id])
}

export default useVertical
