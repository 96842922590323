import React from 'react'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import { CloseButton } from 'src/chrome'

import { SearchFilter, SearchFilterType } from '../types'
import FilterChip from './FilterChip'
import FilterList from './FilterList'

import styles from '../filteredSearchBar.module.scss'
import { useCallback, useEffect, useRef, useState } from 'src/hooks'
import { Div, Text } from '@sendoutcards/quantum-design-ui'

type Props = {
  filters: SearchFilter[]
  onClear: () => void
  updateFilter: <T extends SearchFilter>(filter: T, update: Partial<T>) => void
  setEditedFilter: React.Dispatch<
    React.SetStateAction<SearchFilter | undefined>
  >
  deleteFilter: (filter: SearchFilter) => void
  onSearch: (terms: string) => void
  autocomplete?: 'on' | 'off'
  defaultValue?: string
  clearDefaultValue?: () => void
}

const TextInput: React.FC<Props> = ({
  updateFilter,
  setEditedFilter,
  deleteFilter,
  onClear,
  filters,
  onSearch,
  autocomplete,
  defaultValue = '',
  clearDefaultValue = () => {},
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [highlightIndex, setHighlightIndex] = useState<number>()
  const [inputValue, setInputValue] = useState('')
  const [isFocused, setIsFocused] = useState(false)
  const [showEnterTooltip, setShowEnterTooltip] = useState(false)

  const handleClear = () => {
    setInputValue('')
    clearDefaultValue()
    onClear()
  }

  useEffect(() => {
    setInputValue(defaultValue)
  }, [defaultValue])

  const onSearchInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
    setShowEnterTooltip(true)
  }
  const removeLastFilter = () => {
    const lastDefinedIndex = filters.reduce<number | undefined>(
      (definedIndex, filter, index) =>
        filter.value === undefined ? definedIndex : index,
      undefined,
    )

    if (lastDefinedIndex !== undefined) {
      updateFilter(filters[lastDefinedIndex], { value: undefined })
    }
  }

  const handleAddFilter = (filter: SearchFilter) => {
    if (filter.type === SearchFilterType.BOOLEAN) {
      updateFilter(filter, { value: true })
      return
    }

    setEditedFilter(filter)
  }

  // TODO: exclude already defined filters that accept a single value
  const matchingFilters = filters
  const definedFilters = filters.filter(filter => filter.value !== undefined)
  const shouldShowFilters = !inputValue

  const restoreFocus = useCallback(
    () => window.setTimeout(() => inputRef.current?.focus(), 50),
    [],
  )

  const handleKeyUp = (e: React.KeyboardEvent) => {
    const { key } = e

    switch (key) {
      case 'Backspace':
        if (!inputValue) {
          onSearch('')
          removeLastFilter()
        }
        break
      case 'ArrowDown':
        setHighlightIndex(
          x =>
            ((x ?? -1) + 1 + matchingFilters.length) % matchingFilters.length,
        )
        break
      case 'ArrowUp':
        setHighlightIndex(
          x =>
            ((x ?? matchingFilters.length) - 1 + matchingFilters.length) %
            matchingFilters.length,
        )
        break
      case 'Enter':
        if (shouldShowFilters) {
          if (highlightIndex !== undefined) {
            handleAddFilter(matchingFilters[highlightIndex])
          }
        } else {
          onSearch(inputValue)
          setShowEnterTooltip(false)
        }
        break
    }
  }

  useEffect(() => {
    setHighlightIndex(undefined)
  }, [matchingFilters])

  return (
    <>
      <div>
        {isFocused && (
          <div className={styles.filterTypeContainer} ref={dropdownRef}>
            <Div backgroundColor="#fff" width="100%" borderRadius="medium">
              {shouldShowFilters && (
                <FilterList
                  highlightIndex={highlightIndex}
                  filters={matchingFilters}
                  onSelect={handleAddFilter}
                  onClick={restoreFocus}
                />
              )}
              {!shouldShowFilters && showEnterTooltip && (
                <Paper style={{ width: 200 }}>
                  <List>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Text type="footnote" color="primaryHeading">
                            Hit enter to search
                          </Text>
                        }
                        disableTypography={true}
                      />
                    </ListItem>
                  </List>
                </Paper>
              )}
            </Div>
          </div>
        )}
        <div className={styles.filterChips}>
          <div className={styles.chips}>
            {filters.map((filter, i) => (
              <div key={`filter_chip_${i}`}>
                <FilterChip
                  key={Math.random()}
                  filter={filter}
                  onDelete={deleteFilter}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <input
        id={'filtered_search_bar'}
        style={{ height: 45 }}
        type="text"
        value={inputValue}
        onChange={e => onSearchInputChanged(e)}
        className={styles.searchInput}
        placeholder="Search or filter"
        onKeyUp={handleKeyUp}
        onBlur={() => setIsFocused(false)}
        onFocus={() => setIsFocused(true)}
        ref={inputRef}
        autoComplete={autocomplete}
      />

      {(definedFilters.length > 0 || inputValue) && (
        <CloseButton onClose={() => handleClear()} size={18} />
      )}
    </>
  )
}

export default TextInput
