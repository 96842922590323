import React from 'react'
import {
  Button,
  Flex,
  QuantumColorPicker,
  QuantumRGB,
} from '@sendoutcards/quantum-design-ui'
import { useState } from 'src/hooks'
import styles from './colorPicker.module.scss'
import { ColorFragment } from 'src/legacy_graphql'
import { DesignColors } from 'src/editor/api'

interface ColorPickerProps {
  selectColor: (color: ColorFragment) => void
  selectedColor?: ColorFragment
  className?: string
  removeColor?: () => void
  designColors?: DesignColors
}

const ColorPicker: React.FC<ColorPickerProps> = props => {
  const {
    className,
    selectColor,
    selectedColor,
    removeColor,
    designColors,
  } = props

  const rgbToHex = (r: number, g: number, b: number) =>
    '#' + [r, g, b].map(x => x.toString(16).padStart(2, '0')).join('')

  const defaultColor = selectedColor
    ? rgbToHex(selectedColor.red, selectedColor.green, selectedColor.blue)
    : '#fff'

  const [color, setColor] = useState(defaultColor)

  const handleSelectColor = (color: QuantumRGB) => {
    const rgb: ColorFragment = {
      __typename: 'Color',
      red: color.r,
      green: color.g,
      blue: color.b,
    }
    selectColor(rgb)
  }

  const hexDesignColors = designColors?.palette
    ? designColors.palette.map(designColor =>
        rgbToHex(
          designColor ? designColor?.red : 0,
          designColor ? designColor?.green : 0,
          designColor ? designColor?.blue : 0,
        ),
      )
    : undefined

  return (
    <div
      className={
        className ? `${styles.colorPicker} ${className}` : styles.colorPicker
      }
    >
      <QuantumColorPicker
        color={color}
        onChange={setColor}
        convertedColorCallback={color => handleSelectColor(color as QuantumRGB)}
        convertedColorType="rgb"
        palette={{
          isLoading: designColors?.isLoading,
          title: 'Card Design Colors',
          colors: hexDesignColors,
        }}
      />
      {selectedColor && !className && (
        <Flex width="100%" justifyContent="center" inset={{ top: 'x2' }}>
          <Button
            onClick={() => removeColor?.()}
            title="Remove Color"
            size="medium"
            type="primary"
            fullWidth={true}
          />
        </Flex>
      )}
    </div>
  )
}

export default ColorPicker
