import { Operation } from '../../../__generated__/globalTypes'

import {
  MinimalSendableCardFragment,
  minimalSendableCardFragmentString,
} from '../../fragments/__generated__/MinimalSendableCardFragment'

import { sendableCardImageFragmentString } from '../../fragments/__generated__/SendableCardImageFragment'

import unfavoriteCardRawString from '../UnfavoriteCard.graphql'

const unfavoriteCardString = [
  unfavoriteCardRawString,
  minimalSendableCardFragmentString,
  sendableCardImageFragmentString,
].join('\n\n')

export type UnfavoriteCardData = {
  unfavoriteSendableCard: {
    sendableCard: MinimalSendableCardFragment
  }
}

export type UnfavoriteCardVariables = {
  id: string
}

export type UnfavoriteCard = Operation<
  UnfavoriteCardData,
  UnfavoriteCardVariables
>

export const UnfavoriteCard = ({
  id,
}: UnfavoriteCardVariables): UnfavoriteCard => ({
  query: unfavoriteCardString,
  variables: {
    id,
  },
})
