import React from 'react'
import { Interpolation } from '@emotion/css'

import { PillTabs, ViewSlider } from 'src/chrome'
import { ColorChoices } from 'src/theme/colors'

interface Props {
  width: number | string
  currentIndex: number
  onRightArrowClick?: () => void
  onLeftArrowClick?: () => void
  onTabClick: (name: string) => void
  tabs: string[]
  arrowColor?: ColorChoices
  arrowIconColor?: ColorChoices
  shouldSkipAnimation?: boolean
}

const styles = {
  wrapper: {
    position: 'relative',
    display: 'inline-block',
  } as Interpolation,
  pillTabs: {
    position: 'absolute',
    top: 10,
    left: 0,
    right: 0,
    marginLeft: 'auto',
    width: 'fit-content',
    marginRight: 'auto',
  } as Interpolation,
}

const PillTabsViewSlider: React.FC<Props> = props => {
  const {
    onRightArrowClick,
    onLeftArrowClick,
    onTabClick,
    currentIndex,
    width,
    tabs,
    children,
    arrowColor = 'pink',
    arrowIconColor = 'white',
    shouldSkipAnimation = false,
  } = props

  const viewSliderProps = {
    onRightArrowClick,
    onLeftArrowClick,
    currentIndex,
    width,
    arrowIconColor,
    arrowColor,
    shouldSkipAnimation,
  }

  const pillTabsProps = {
    handleClick: onTabClick,
    currentIndex,
    tabs,
  }
  return (
    <div css={styles.wrapper}>
      <ViewSlider {...viewSliderProps}>{children}</ViewSlider>
      <div css={styles.pillTabs}>
        <PillTabs {...pillTabsProps} />
      </div>
    </div>
  )
}

export default PillTabsViewSlider
