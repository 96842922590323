import React, { ComponentProps, FC } from 'react'
import MaterialUIDialog from '@material-ui/core/Dialog'
import { Provider } from 'react-redux'
import { Store } from 'src/redux/store'

const Dialog: FC<ComponentProps<typeof MaterialUIDialog>> = props => (
  <MaterialUIDialog maxWidth={'lg'} {...props}>
    <Provider store={Store}>{props.children}</Provider>
  </MaterialUIDialog>
)

export default Dialog
