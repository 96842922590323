import { Route } from 'src/routes/Route'
import { GiftRoute } from 'src/gift_store/routes/GiftRoute'
import { selectGiftToView } from 'src/redux/actions/giftstore'

type GiftCategoryRouteContent = { giftCategoryId: string; subroute?: GiftRoute }

export const GiftCategoryRoute = Route({
  path: '/categories/:giftCategoryId',
  init: (giftCategoryId: string): GiftCategoryRouteContent => ({
    giftCategoryId,
  }),
  fromPath: ({ giftCategoryId }) =>
    giftCategoryId ? { giftCategoryId } : undefined,
  subroute: GiftRoute,
  reducer: (route, action) => {
    switch (action.type) {
      case selectGiftToView.type:
        return { ...route, subroute: GiftRoute(action.giftId) }
      default:
        return route
    }
  },
})
export type GiftCategoryRoute = Route<typeof GiftCategoryRoute>
