import { useEffect, useState } from 'src/hooks'

export type DayInfoType = {
  isoDate: string
  dateString: string
  dayOfMonth: number
  dayOfWeek: string
  shortDayOfWeek: string
  month: string
  shortMonth: string
  isToday: boolean
}

export const useGetCurrentWeekDays = (): DayInfoType[] => {
  const getClosestMonday = (date: Date): Date => {
    const dayOfWeek = date.getDay()
    const daysUntilMonday = (dayOfWeek + 6) % 7 // Adjusting for Sunday being 0
    const closestMonday = new Date(date)
    closestMonday.setDate(date.getDate() - daysUntilMonday)
    return closestMonday
  }

  const [currentWeek, setCurrentWeek] = useState<DayInfoType[]>([])

  useEffect(() => {
    const today: Date = new Date()

    const generateWeekDaysInfo = (startDate: Date): DayInfoType[] => {
      const weekDays: string[] = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ]
      const months: string[] = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ]
      const abbr = (string: string): string => string.substring(0, 3)

      return Array.from({ length: 7 }, (_, i) => {
        const currentDate: Date = new Date(
          startDate.getTime() + i * 24 * 60 * 60 * 1000,
        )
        const isToday: boolean =
          currentDate.toDateString() === today.toDateString()
        return {
          isoDate: currentDate.toISOString(),
          dateString: currentDate.toLocaleDateString(),
          dayOfMonth: currentDate.getDate(),
          dayOfWeek: weekDays[currentDate.getDay()],
          shortDayOfWeek: abbr(weekDays[currentDate.getDay()]),
          month: months[currentDate.getMonth()],
          shortMonth: abbr(months[currentDate.getMonth()]),
          isToday: isToday,
        }
      })
    }

    const closestMonday = getClosestMonday(today)
    const weekDaysInfo = generateWeekDaysInfo(closestMonday)
    setCurrentWeek(weekDaysInfo)
  }, []) // Empty dependency array ensures this effect runs only once

  return currentWeek
}
