import React from 'react'
import Select from '@material-ui/core/Select'
import TabsContent from '../Tabs/Tabs'
import { useSelector } from 'src/hooks'

type GiftStoreControlsProps = {
  value?: string
  onChange: (value: string) => void
  selectChildren: React.ReactNode
}

export const GiftTabs = ({
  value,
  onChange,
  selectChildren,
}: GiftStoreControlsProps) => {
  const { isMobile } = useSelector(state => state.window)

  return isMobile ? (
    <Select
      value={value}
      onChange={e => onChange(e.target.value as string)}
      style={{ width: '100%' }}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: 300,
          },
        },
      }}
    >
      {selectChildren}
    </Select>
  ) : (
    <TabsContent value={value} onChange={categoryId => onChange(categoryId)} />
  )
}
