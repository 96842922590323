import { Div, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'

import PlanContentsItem from '../PlanContentsItem/PlanContentsItem'

import styles from './planContentsGrid.module.scss'

const PlanContentsGrid: React.FC = props => {
  return (
    <Div className={`${styles.featureGridContainer} ${styles.offWhite}`}>
      <Div className={styles.featureGrid}>
        <Text type="subtitle" weight="bold">
          All Card Types
        </Text>
        <Spacer space="x1" />
        <Div className={styles.featureRow}>
          <PlanContentsItem
            title={'Catalog Cards'}
            text={
              'Our new card catalog features thousands of beautiful designs in numerous categories.'
            }
            icon={'CATALOGCARD'}
            iconViewBox={'0 0 32 32'}
          />
          <PlanContentsItem
            title={'Photo Drop Cards'}
            text={
              'These cards feature beautifully designed templates that you can drop your own photos into.'
            }
            icon={'PHOTODROP'}
            iconViewBox={'0 0 19 32'}
          />
          <PlanContentsItem
            title={'Build Your Own Cards'}
            text={
              'Add your own pictures, headlines, and text on the front, inside, and back panels.'
            }
            icon={'TEMPLATES'}
            iconSize={35}
            iconViewBox={'0 0 838 1024'}
            isStrokedIcon={true}
          />
        </Div>
      </Div>
    </Div>
  )
}

export default PlanContentsGrid
