import {
  AnimatedCheckMark,
  Button,
  Flex,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { useAccount, useSelector, useState } from 'src/hooks'
import { FormHeader } from '../FormHeader'
import { OrderDetailsSection } from '../OrderDetail/OrderDetailsSection'

type OrderPersonalDetailsType = {
  toggleSaveAccount?: boolean
  isAccCreationSuccessful?: boolean
  returnAddress: React.ReactNode
  basicCreateAccountForm?: React.ReactNode
}

export const OrderPersonalDetails: React.FC<OrderPersonalDetailsType> = ({
  isAccCreationSuccessful,
  returnAddress,
  basicCreateAccountForm,
}) => {
  const isDescriptionBreakpoint = useSelector(state => state.window.width < 820)
  const account = useAccount()
  const [showPersonalDetailsButton, setShowPersonalDetailsButton] = useState(
    !account.shippingAddress,
  )
  // This is being added as a quick fix. Currently the Save Account form does not trigger the upsale dialog
  // through the free card send flow which is problematic. Therefore we are hiding the Save Account form on the
  // checkout for now
  const shouldShowSaveAccount = false

  return (
    <OrderDetailsSection borderRadius="16px">
      <Flex
        backgroundColor="foreground"
        borderRadius="16px"
        flexDirection="column"
        width="100%"
        inset="x3"
      >
        <Flex
          flexDirection={showPersonalDetailsButton ? 'row' : 'column'}
          alignItems={!showPersonalDetailsButton ? 'flex-start' : 'center'}
          width="100%"
          justifyContent={
            !showPersonalDetailsButton ? 'flex-start' : 'space-between'
          }
        >
          <FormHeader
            step={2}
            title="Sender Details"
            description="Enter your personal information here."
          />

          {showPersonalDetailsButton && !account.shippingAddress ? (
            <Flex outset={{ right: 'x_5' }}>
              <Button
                backgroundColorOverride="#323334"
                title={'Add Personal Details'}
                onClick={() => setShowPersonalDetailsButton(false)}
                outlined={false}
              />
            </Flex>
          ) : (
            <>{returnAddress}</>
          )}
        </Flex>
        {isAccCreationSuccessful && (
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Spacer space="x2" />
            <AnimatedCheckMark size="medium" filled={true} />
            <Text type="body" alignment="center">
              Account created successfully.
            </Text>
          </Flex>
        )}
        {!showPersonalDetailsButton &&
          !account.username &&
          shouldShowSaveAccount && (
            <>
              <Flex
                width={isDescriptionBreakpoint ? undefined : '350px'}
                flexDirection="column"
                outset={{ top: 'x4' }}
              >
                <Text
                  type="largeBody"
                  weight="semiBold"
                  color="primaryHeading"
                  content="Save Account"
                  style={{ fontSize: '1.25rem', fontWeight: 600 }}
                />
                <Text
                  type="body"
                  content="Save your account by entering the information below to access and track this order."
                  outset={{ bottom: 'x1' }}
                  whiteSpace="normal"
                />
              </Flex>
              <Flex justifyContent="center" width="100%">
                {basicCreateAccountForm}
              </Flex>
            </>
          )}
      </Flex>
    </OrderDetailsSection>
  )
}
