import React from 'react'

import styles from './printBarCode.module.scss'

interface PrintBarCodeProps {
  size?: number
  style?: React.CSSProperties
  width?: string
  height?: string
}

const PrintBarCode: React.FC<PrintBarCodeProps> = props => {
  const { size, style, width, height } = props
  return (
    <div
      className={styles.barCode}
      style={style ?? (size ? { width: size } : {})}
    >
      <img
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFwAAABcAQMAAADZIUAbAAAABlBMVEX///8AAABVwtN+AAAAnUlEQVQ4ja3TsQ3DIBAF0EMUlB6BTcxiSEZiMbIJI1BSoPwccezUfPkK0GtOxwdEHi1b+77luSQWAJIX0yIJiEFG37/jUOim2eqKRBJzP6q2TCxQtiFddOEAvHQcOROlIKEdcPgluo6Kt2ahEBY9aKPrcATmOBau3OdZhXbD8BrIYCFBr9PhvFMC0Eb5H+86qj7L6O9vtg5cNTg8Wh8UBr371xULZAAAAABJRU5ErkJggg=="
        alt=""
        style={{
          height: height ?? '26.67px',
          width: width ?? '26.67px',
          border: '2px solid white',
          marginBottom: '13.33px',
        }}
      />
    </div>
  )
}

export default PrintBarCode
