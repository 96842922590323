import imageFragmentRawString from '../ImageFragment.graphql'

export const imageFragmentString = imageFragmentRawString

export type ImageFragment = {
  __typename: 'Image'
  id: string
  url: string
  smallThumb: string
  width: number
  height: number
}

export const isImageFragment = (fragment: any): fragment is ImageFragment =>
  fragment &&
  fragment.__typename === 'Image' &&
  typeof fragment.id === 'string' &&
  typeof fragment.url === 'string' &&
  typeof fragment.smallThumb === 'string' &&
  typeof fragment.width === 'number' &&
  typeof fragment.height === 'number'
