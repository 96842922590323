import { OrderWithPartialLines } from 'src/redux/reducers/orders'

const recipientCount = (order: OrderWithPartialLines) => {
  return order.isBulk && order.bulkUpgradeCardQty
    ? order.bulkUpgradeCardQty
    : order.groups.reduce(
        (count, group) => count + group.members.length,
        order.contacts.length,
      )
}

export default recipientCount
