import { FullAccessFeatureType } from 'src/graphql/generated/graphql'
import { usePersistedUserData, useState } from 'src/hooks'

/**
 * Checks if the user has the provided FullAccessFeature flags
 *
 * @param requiredFeatures
 * @returns {
 *  hasFeatureAccess: (boolean) immediately returns true/false whether user has the flag(s) or not.
 *  lazyFeatureAccess: {
 *    hasAccess: returns true if the user has access and the lazy check was isLazyEnabled
 *    enable: callback that will set the enabled state and immediately return true/false based on user having access
 *    disable: callback to disable the lazy check
 * }
 * }
 */
export const useFeatureAccess = (requiredFeatures: FullAccessFeatureType[]) => {
  const [isLazyEnabled, setIsLazyEnabled] = useState(false)
  const { account } = usePersistedUserData()
  const userFeatures = account?.fullAccessFeatures

  const hasFeatureAccess = userFeatures?.some(feat =>
    requiredFeatures.includes(feat),
  )

  return {
    hasFeatureAccess,
    lazyFeatureAccess: {
      enable: () => {
        setIsLazyEnabled(true)
        return hasFeatureAccess
      },
      disable: () => {
        setIsLazyEnabled(false)
        return true
      },
      hasAccess: isLazyEnabled ? hasFeatureAccess : true,
    },
  }
}
