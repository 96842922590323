import { Flex } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { Widget } from 'src/dashboard/components/Widgets/Widget'
import { SentCardSendType } from 'src/graphql/generated/graphql'
import { useActions } from 'src/hooks'
import { usePaginatedSentCards } from 'src/react_query'
import { CardLineItem } from './CardLineItem'

export const CardHistoryWidget = () => {
  const actions = useActions()
  const cardHistoryQuery = usePaginatedSentCards({
    page: 1,
    search: '',
    contacts: [],
    sendType: SentCardSendType.Heartfelt,
  })

  // TODO: Set a threshold of 1 month, show empty state if cards have not been sent in over 6 months

  const cards = cardHistoryQuery.data?.sentCards.slice(0, 4).map(res => {
    const card = res?.recipient?.card
    return {
      preview: card?.frontPreviewUrl ?? '',
      isHorizontal: card?.isHorizontal ?? false,
      sendableCard: { title: card?.sendableCard?.title ?? 'My Card' },
      date: res?.dateToSend ?? '',
      status: res?.status ?? undefined,
    }
  })

  return (
    <Widget
      isLoading={cardHistoryQuery.isLoading}
      isEmpty={(cards?.length ?? 0) === 0} // If results is 0
      header={{
        title: 'Recent Cards Sent',
        action: {
          title: 'View More',
          onClick: () => actions.openCardHistory(),
        },
      }}
      emptyState={{
        title: "Let's start sending!",
        description:
          'Who could you reach out to today? There is always a reason to reach out and share gratitude with others. Send a card to the first person you think of and make their day today!',
        icon: 'exp_calendar',
        contentMaxWidth: '480px',
        action: {
          title: 'Send a Card',
          onClick: () => actions.openCatalog(),
        },
      }}
    >
      <Flex flexDirection="column" width="100%" inset={{ right: '16px' }}>
        {cards &&
          cards.map((card, index) => {
            return (
              <CardLineItem
                key={index}
                color="#378487"
                card={{
                  name: card.sendableCard.title,
                  isHorizontal: card.isHorizontal,
                  thumbnail: card?.preview,
                }}
                status={card?.status}
                date={card.date}
              />
            )
          })}
      </Flex>
    </Widget>
  )
}
