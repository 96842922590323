import { Maybe } from '../../../__generated__/globalTypes'

import { ItemFragment, isItemFragment } from './ItemFragment'

import itemCategoryFragmentRawString from '../ItemCategoryFragment.graphql'

export const itemCategoryFragmentString = itemCategoryFragmentRawString

export type ItemCategoryFragment = {
  __typename: 'ItemCategory'
  id: string
  name: string
  backgroundImageUrl: Maybe<string>
  items: ItemFragment[]
}

export const isItemCategoryFragment = (
  fragment: any,
): fragment is ItemCategoryFragment =>
  fragment &&
  fragment.__typename === 'ItemCategory' &&
  typeof fragment.id === 'string' &&
  typeof fragment.name === 'string' &&
  (fragment.backgroundImageUrl === null ||
    typeof fragment.backgroundImageUrl === 'string') &&
  Array.isArray(fragment.items) &&
  fragment.items
    .slice(0, 5)
    .reduce(
      (accum: any, next: any) => accum && (isItemFragment(next) as boolean),
      true,
    )
