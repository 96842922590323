import React from 'react'
import omit from 'lodash/omit'
import { css } from '@emotion/core'
import { withTheme } from 'emotion-theming'

import { Icon, Icons, IconType } from 'src/chrome'
import theme from 'src/theme/theme'
import { ColorChoices } from 'src/theme/colors'

// types __________________________________________________
type RoundIconButtonProps = {
  icon: IconType | Icons
  onClick: () => void
  size?: number
  iconColor?: ColorChoices
  iconRight?: number
  iconLeft?: number
  iconUp?: number
  iconDown?: number
  color: ColorChoices
}

// types __________________________________________________

// styles _________________________________________________

const wrapper = (props: RoundIconButtonProps) =>
  css({
    backgroundColor: theme.colors[props.color],
    width: props.size ? props.size + 10 : 10,
    height: props.size ? props.size + 10 : 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: props.size ? props.size * 5 : '50%',
    cursor: 'pointer',
    boxShadow: theme.shadows.lowLightShadow,
    paddingLeft: props.iconRight,
    paddingRight: props.iconLeft,
    paddingBottom: props.iconUp,
    paddingTop: props.iconDown,
  })

// styles _________________________________________________

// component ______________________________________________
const RoundIconButton: React.FC<RoundIconButtonProps> = props => {
  const { icon, onClick, size = 25, iconColor = 'white' } = props
  return (
    <div
      {...omit(props, [
        'iconColor',
        'iconRight',
        'iconLeft',
        'iconUp',
        'iconDown',
      ])}
      css={wrapper(props)}
      onClick={() => onClick()}
    >
      <Icon
        icon={icon}
        size={size}
        color={theme.colors[iconColor]}
        onClick={onClick}
      />
    </div>
  )
}

// export _________________________________________________
export default withTheme(RoundIconButton)
