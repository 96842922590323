import {
  DirectionType,
  Div,
  Flex,
  Tooltip,
} from '@sendoutcards/quantum-design-ui'
import { Display } from '@sendoutcards/quantum-design-ui/dist/src/helpers/hoc-types/cssValueTypes'
import { ColorModeType } from '@sendoutcards/quantum-design-ui/dist/src/types/global'
import React from 'react'
import { useState } from 'src/hooks'

export interface HoverTooltipProps {
  title?: string
  shouldDisplayTooltip?: boolean
  tooltipDirection?: DirectionType
  tooltipChildren?: React.ReactNode
  isFullWidth?: boolean
  background?: ColorModeType
  positioning?: {
    left?: string
    top?: string
    bottom?: string
    right?: string
  }
  shouldSupportOnTapAsHover?: boolean
}

const HoverTooltip: React.FC<HoverTooltipProps> = props => {
  const [isHoveringOn, setIsHoveringOn] = useState(false)
  const {
    title = '',
    shouldDisplayTooltip = true,
    tooltipChildren,
    tooltipDirection = 'top',
    isFullWidth = false,
    background = 'light',
    children,
    positioning,
    shouldSupportOnTapAsHover = false,
  } = props

  const tooltipVariants = {
    hoverOn: {
      opacity: 1,
    },
    hoverOff: {
      opacity: 0,
    },
  }

  const onTap = () => {
    setIsHoveringOn(true)
    setTimeout(() => {
      setIsHoveringOn(false)
    }, 3000)
  }

  return (
    <Div
      onHoverStart={() => {
        setIsHoveringOn(true)
      }}
      onHoverEnd={() => {
        setIsHoveringOn(false)
      }}
      position="relative"
      display={isFullWidth ? ('block' as Display) : ('inline-block' as Display)}
      onTap={shouldSupportOnTapAsHover ? onTap : undefined}
    >
      {children}
      {shouldDisplayTooltip && isHoveringOn && (
        <Flex
          variants={tooltipVariants}
          animate={isHoveringOn ? 'hoverOn' : 'hoverOff'}
          initial={'hoverOff'}
          transition={{ ease: 'easeIn', duration: 0.2 }}
          top={
            positioning?.top ??
            (tooltipDirection === 'top' ? '135%' : undefined)
          }
          bottom={
            positioning?.bottom ??
            (tooltipDirection === 'bottom' ? '135%' : undefined)
          }
          left={positioning?.left ?? '50%'}
          transform={
            tooltipDirection === 'left' || tooltipDirection === 'right'
              ? undefined
              : 'translate(-50%)'
          }
          position="absolute"
          zIndex={10000}
          width={isFullWidth ? 'max-content' : 'auto'}
        >
          <Tooltip
            title={title}
            direction={tooltipDirection}
            background={background}
            whiteSpace="pre"
          >
            {tooltipChildren}
          </Tooltip>
        </Flex>
      )}
    </Div>
  )
}

export default HoverTooltip
