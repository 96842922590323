import React, { FC } from 'react'
import { SVGProps } from './types'

export const Upload: FC<SVGProps> = ({ size, onClick, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick && (() => onClick())}
    >
      <path
        d="M6 20C5.45 20 4.979 19.804 4.587 19.412C4.195 19.02 3.99934 18.5493 4 18V16C4 15.4477 4.44772 15 5 15C5.55229 15 6 15.4477 6 16V17C6 17.5523 6.44772 18 7 18H17C17.5523 18 18 17.5523 18 17V16C18 15.4477 18.4477 15 19 15C19.5523 15 20 15.4477 20 16V18C20 18.55 19.804 19.021 19.412 19.413C19.02 19.805 18.5493 20.0007 18 20H6Z"
        fill={color}
      />
      <path
        d="M12.8485 3.84853C12.38 3.37995 11.62 3.37995 11.1515 3.84853L6.90883 8.09117C6.44026 8.55974 6.44026 9.31965 6.90883 9.78822C7.37741 10.2568 8.13731 10.2568 8.60589 9.78822L10.7998 7.59431L10.8007 14.8794C10.7998 15.5422 11.3372 16.0796 12.0009 16.0796C12.6628 16.0796 13.2002 15.5422 13.2002 14.8803L13.2002 7.59431L15.3941 9.78822C15.8627 10.2568 16.6226 10.2568 17.0912 9.78822C17.5597 9.31965 17.5597 8.55974 17.0912 8.09117L12.8485 3.84853Z"
        fill={color}
      />
    </svg>
  )
}
