import React from 'react'

// @src imports
import { EditOrderCardRoute } from 'src/orders/routes/EditOrderCardRoute'
import { ReplaceOrderCardRoute } from 'src/orders/routes/ReplaceOrderCardRoute'
import { AddCardToGiftRoute } from 'src/orders/routes/AddCardToGiftRoute'
import { AddOrderCardRoute } from 'src/orders/routes/AddOrderCardRoute'

import { CreateGroup } from 'src/contacts/components'
import {
  Alert,
  Button,
  Confirm,
  FullWindowModal,
  Icon,
  Transition,
} from 'src/chrome'
import ImportManager from 'src/import-contacts'
import Pricing from 'src/marketing/plans/containers/Pricing'
import DrawerTopControls from 'src/editor/components/DrawerTopControls'

import { ContactFragment } from 'src/legacy_graphql'

import { PaymentModal, ValidateAddress } from 'src/payments/components'

// relative imports
import {
  AddCard,
  DetailDrawer,
  EditCard,
  OrderDetail,
  OrderFooter,
  SaveAsModal,
} from 'src/orders/components'
import { drawerState } from '../drawerState'

import drawerStyles from 'src/editor/styles/components/Drawer.module.scss'
import styles from './orders.module.scss'
import { Props } from '../types'
import useApi from '../api'
import { useActions, useEffect, useRef, useSelector, useState } from 'src/hooks'
import { AddGiftToCardRoute } from '../routes/AddGiftToCardRoute'
import GiftStore from 'src/gift_store/containers/GiftStore'
import { AddOrderGiftRoute } from '../routes/AddOrderGiftRoute'
import CardPreviewModal from '../components/CardPreviewModal/CardPreviewModal'
import { ConfirmDialog } from '@sendoutcards/quantum-design-ui'
import { CardFragment } from 'src/graphql/generated/graphql'

const toggle = (prevState: boolean): boolean => !prevState

const Orders: React.FC<Props> = props => {
  const { activeLine, activeLineIndex } = useSelector(state => state.orders)
  const { route } = props
  const { subroute } = route
  const api = useApi(route.orderId)
  const {
    order,
    actions,
    orders,
    isShowingCardPreview,
    setIsShowingCardPreview,
    handleReplaceCard,
    canShowNewCardEditor,
    shouldShowEditorChoiceModal,
    setShouldShowEditorChoiceModal,
    didFlattenFail,
  } = api

  const { isMobile, width } = useSelector(state => state.window)
  const shouldShowSendCardBottomButton = isMobile || width <= 756
  const reduxActions = useActions()
  const orderLines = order?.lines
  const [didFF, setDidFF] = useState(false)
  const [showPaymentMethodForm, setShowPaymentMethodForm] = useState(false)

  /**
   * We have to check for bulk order and default active line index/order card due to the ability to launch
   * Card Preview from the bulk order card via View/Edit button
   */
  const orderLineIndex = order?.isBulk ? -1 : activeLineIndex
  const orderCard = order?.isBulk
    ? order.lines.length > 0
      ? order.lines[0].card
      : undefined
    : activeLine?.card

  useEffect(() => {
    if (
      orderLines &&
      subroute &&
      'lineIndex' in subroute &&
      subroute.lineIndex &&
      activeLineIndex !== +subroute.lineIndex
    ) {
      reduxActions.setActiveLine(
        orderLines[+subroute.lineIndex],
        +subroute.lineIndex,
      )
    }
  }, [subroute, orderLines, activeLineIndex, reduxActions])

  useEffect(() => {
    ;(async () => {
      const didIt = await didFlattenFail()
      setDidFF(didIt)
    })()
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  const mutableModifyingRef = useRef(false)

  const modifyOrder = (callback: () => void) => {
    mutableModifyingRef.current = true
    // WARNING: This used to be called asynchronously in the callback of
    // setState, now that we're using `useState` we don't have
    // this functionality anymore.
    callback()
  }

  const handleEditCardOnCurrentEditor = (card: CardFragment) => {
    if (!order) {
      return
    } else {
      return modifyOrder(() => {
        actions.openOrder(order.id, EditOrderCardRoute(card.id))
      })
    }
  }
  const handleEditCard = (card: CardFragment) => {
    if (canShowNewCardEditor && !card.isNewEditorCard) {
      setShouldShowEditorChoiceModal(true)
    } else {
      handleEditCardOnCurrentEditor(card)
    }
  }

  switch (subroute?.path) {
    case AddCardToGiftRoute.path:
    case ReplaceOrderCardRoute.path:
    case AddOrderCardRoute.path: {
      if (!order) return <Transition message={'Loading your order...'} />
      return (
        <AddCard
          isLoading={orders.isLoading}
          onReload={api.reloadAndOpenOrder}
          order={order}
          subroute={subroute}
          onClose={() => actions.openOrder(order.id)}
          orderApi={api}
        />
      )
    }
    case AddGiftToCardRoute.path:
    case AddOrderGiftRoute.path: {
      if (!order) return <Transition message={'Loading your order...'} />
      return <GiftStore route={subroute} orderApi={api} />
    }
    case EditOrderCardRoute.path: {
      if (!order) return <Transition message={'Loading your order...'} />
      return (
        <EditCard
          isLoading={orders.isLoading}
          onReload={api.reloadAndOpenOrder}
          order={order}
          subroute={subroute}
          onClose={() => actions.openOrder(order.id)}
          orderApi={api}
        />
      )
    }

    case undefined: {
      if (orders.isUpdatingOrder) {
        return <Transition message="Saving order..." />
      }
      return (
        <div className={styles.orderWrapper} id={'tour-orderWrapper'}>
          {api.isDeleting && <Transition message={'Deleting your order...'} />}
          <div className={styles.orderContents}>
            {order && route.orderId && (
              <OrderDetail
                shouldBlink={!!api.shouldBlink.returnAddress}
                order={order}
                shouldBlinkAddRecipients={api.shouldBlink.addRecipients}
                openImportManager={() => api.setIsImportModalOpen(toggle)}
                saveAs={() => api.setIsSaveAsModalOpen(toggle)}
                isSaving={orders.isSaving}
                api={api}
                shouldShowEditorChoiceModal={shouldShowEditorChoiceModal}
                paymentFormDisplay={{
                  shouldShowPaymentForm: showPaymentMethodForm,
                  setShouldShowPaymentMethodForm: setShowPaymentMethodForm,
                }}
              />
            )}
          </div>
          {shouldShowSendCardBottomButton && (
            <div style={{ height: '50px', opacity: 0 }} />
          )}
          {orders.drawerState !== drawerState.closed &&
            orders.drawerState !== drawerState.detail && (
              <DetailDrawer
                className={
                  orders.drawerState === 'addressDrawer'
                    ? drawerStyles.fullDrawer
                    : false
                }
              >
                <DrawerTopControls className={styles.addresses}>
                  <div
                    className={`${styles.flexButton} ${styles.contactActions}`}
                  >
                    {api.drawerTriggerText && api.drawerTriggerIcon && (
                      <div style={{ display: 'flex' }}>
                        {api.drawerTriggerText.map((_, index) => {
                          return (
                            <Button
                              id={`add_new_address_btn`}
                              key={index}
                              className={index === 1 ? styles.hideMobile : ''}
                              title={api.drawerTriggerText[index]}
                              style={
                                index === 0
                                  ? { marginRight: 15 }
                                  : { width: 149 }
                              }
                              onClick={api.drawerActionTop[index]}
                            />
                          )
                        })}
                      </div>
                    )}
                    <span
                      style={{ marginLeft: 'auto!important' }}
                      className={styles.attachButton}
                    >
                      <Icon
                        icon={'CLOSE'}
                        color={'#6f8394'}
                        size={18}
                        onClick={api.handleCloseDrawer}
                      />
                    </span>
                  </div>
                </DrawerTopControls>
                {api.drawerContent}
              </DetailDrawer>
            )}
          {api.isDeleteModalOpen && (
            <ConfirmDialog
              title={'Cancel Order?'}
              description={'Are you sure you want to cancel this order?'}
              isOpen={api.isDeleteModalOpen ? true : false}
              accept={{
                title: 'Yes, cancel this order',
                onClick: api.handleDelete,
              }}
              decline={{
                title: 'No',
                onClick: () => api.setIsDeleteModalOpen(toggle),
              }}
            />
          )}
          {order && (
            <>
              {shouldShowSendCardBottomButton && (
                <div className={styles.sendButton}>
                  <Button
                    id={'checkout_btn'}
                    title={
                      api.isLoadingCredits ? 'Loading Credits' : 'Send Card'
                    }
                    className={styles.cta}
                    style={{ margin: 'auto' }}
                    onClick={() => api.handleOrderVerification()}
                    buttonColor={'pink'}
                    disabled={api.isLoadingCredits}
                  />
                </div>
              )}
              <OrderFooter
                isSaving={orders.isSaving}
                isLoadingCredits={api.isLoadingCredits}
                addRecipients={api.handleOpenDrawer}
                toggleAddressBlinker={api.toggleAddressBlinker}
                onSendOrder={() => {
                  setShowPaymentMethodForm(false)
                  api.handleOrderVerification()
                }}
                order={order}
                didFlattenFail={didFF}
              />
            </>
          )}
          <CreateGroup
            isOpen={orders.isGroupModalOpen}
            onClose={actions.closeGroupModal}
            onSubmit={api.createGroup}
            contacts={orders.groupMembers as ContactFragment[]}
          />
          {orders.uiContext === 'CONFIRM_BLANK_PANELS' && (
            <Confirm
              title={'Blank Card Panels'}
              message={`It looks like you may have empty panels on your card.
                  Click Confirm to continue anyway, or Cancel to take one last look before you send it out`}
              confirmTitle={'Confirm'}
              onDecline={() => actions.setOrderUiContext(null)}
              onConfirm={() => {
                actions.setOrderUiContext(null)
                api.handleOrderSend(true)
              }}
            />
          )}
          {orders.order &&
            orders.order?.invalidSendDelayContacts.length > 0 &&
            !orders.isSaving &&
            orders.showSendDelayError && (
              <Confirm
                title={'Missing Birthday/Anniversary'}
                message={`There are some contacts in the recipient list that do not have a birthday/anniversary date. These contacts will be removed from the recipient list for this specific card send and no card will be sent. Do you want to edit these contacts or continue sending this order?`}
                confirmTitle={'Continue to Send'}
                declineTitle={'Edit Contact(s)'}
                onDecline={() => actions.setShowSendDelayError(false)}
                onConfirm={() => {
                  actions.setShowSendDelayError(false)
                  api.handleRemoveSendDelayRecipients()
                }}
              />
            )}
          {!!orders.freeStatusChanged && (
            <Alert
              isOpen={!!orders.freeStatusChanged}
              title={'Free Send Status Changed'}
              message={
                orders.freeStatusChanged
                  ? orders.freeStatusChanged.join(' ')
                  : 'There was a change'
              }
              type={'info'}
              onClose={actions.changeFreeStatus}
              timeout={12000}
            />
          )}
          {api.isImportAlertOpen && (
            <Alert
              isOpen={api.isImportAlertOpen}
              title={'This import is limited to 100 contacts'}
              message={[
                'You are currently on a limited subscription.',
                'In order to access the full functionality of the relationship manager,',
                'please upgrade to a Premium or Enterprise plan.',
              ].join(' ')}
              onClose={api.closeImportAlert}
              type={'info'}
            >
              <span
                onClick={() => {
                  api.closeImportAlert()
                  api.setIsUpsaleModalOpen(toggle)
                }}
              >
                Click here to view your plan options
              </span>
            </Alert>
          )}
          {api.isShowingCardPreview &&
            typeof orderLineIndex === 'number' &&
            orderCard && (
              <CardPreviewModal
                card={orderCard}
                itemIndex={orderLineIndex}
                isOpen={isShowingCardPreview}
                onClose={() => setIsShowingCardPreview(!isShowingCardPreview)}
                onEditCard={card => handleEditCard(card)}
                onReplaceCard={card => handleReplaceCard(orderCard.id)}
              />
            )}
          {order && api.isSaveAsModalOpen && (
            <SaveAsModal
              existingCampaign={order.campaign}
              lines={order.lines}
              recipientIds={api.recipientIds}
              onClose={() => api.setIsSaveAsModalOpen(toggle)}
              onSubmit={api.handleSubmit}
            />
          )}
          <PaymentModal
            isOpen={api.isPaymentModalOpen}
            onSuccess={() => api.setIsPaidSuccessfully(true)}
            paymentInfo={api.paymentInfo}
            onClose={() => {
              api.setIsPaymentModalOpen(false)
              api.setPaymentInfo(undefined)
              if (api.isPaidSuccessfully) {
                window.analytics.track('Send Order')
                actions.clearOrder()
                actions.openCatalog()
              }
            }}
          />
          {api.isUpsaleModalOpen && (
            <FullWindowModal
              style={{ zIndex: 505 }}
              closeStyle={{ zIndex: 506 }}
              closeIcon={'ARROWLEFT'}
              closeMessage={'Back to Import'}
              close={() => api.setIsUpsaleModalOpen(toggle)}
            >
              <Pricing />
            </FullWindowModal>
          )}
          {api.isImportModalOpen && (
            <FullWindowModal
              style={{ zIndex: 505 }}
              closeStyle={{ zIndex: 506 }}
              close={() => api.setIsImportModalOpen(toggle)}
              closeIcon={'ARROWLEFT'}
              closeMessage={'To Order'}
            >
              <ImportManager
                additionalAction={{
                  onClick: api.handleImportContacts,
                  title: 'Attach to order',
                }}
                rowLimit={undefined}
              />
            </FullWindowModal>
          )}
          {api.isAddressModalOpen && api.account && (
            <ValidateAddress
              isOpen={true}
              onClose={() => api.setIsAddressModalOpen(false)}
              account={api.account}
              onSubmit={updatedAddress =>
                updatedAddress && api.onUpdateAddress(updatedAddress)
              }
            />
          )}
        </div>
      )
    }
  }
}

export default Orders
