import {
  Button,
  Dialog,
  Flex,
  Icon,
  LoadingSpinner,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { CardFragment } from 'src/graphql/generated/graphql'
import { useMeasurements, useState } from 'src/hooks'
import { useCreateCard } from 'src/react_query'

type Props = {
  title: string
  description: string
  tryNewEditor: {
    title: string
    onClick?: (card: CardFragment, prevCardId: string) => void
    onOverrideClick?: () => void
    shouldOverrideOnClick?: boolean
  }
  useCurrentEditor: { title: string; onClick: () => void; id?: string }
  onClose: () => void
  isOpen: boolean
  cardId?: string | undefined
}

const NewEditorOptionModal: React.FC<Props> = props => {
  const {
    title,
    description,
    tryNewEditor,
    useCurrentEditor,
    onClose,
    isOpen: isOpenProp,
    cardId,
  } = props

  const [isOpen, setIsOpen] = useState(isOpenProp)

  const { mutateAsync: createCardMutation } = useCreateCard()
  const { windowWidth } = useMeasurements()
  const isScreenTooSmall = windowWidth < 960
  const [isConverting, setIsConverting] = useState(false)

  const createNewEditorCard = async () => {
    setIsConverting(true)
    if (cardId) {
      try {
        const {
          createCard: { card },
        } = await createCardMutation({
          card: cardId,
          isNewEditorCard: true,
        })
        tryNewEditor.onClick?.(card, cardId)
      } catch (error) {
        console.log(error)
      } finally {
        setIsOpen(false)
      }
    } else {
      tryNewEditor.onOverrideClick?.()
    }
  }

  const mobileTitle = 'Beta Card Editor Notice'
  const mobileSubtitle =
    'The beta testing for the new card editor does not yet support editing on mobile devices. To continue editing your card on your mobile device, please click “Current Editor” below. Happy sending!'

  return (
    <Dialog
      isOpen={isOpen}
      onClose={isConverting ? undefined : onClose}
      zIndex={10000}
      closeButtonId="close_button"
    >
      {isConverting ? (
        <>
          <LoadingSpinner size="large" />
          <Spacer space="x3" />
          <Text type="largeBody" alignment="center" color="primaryBody">
            Converting card for New Editor...
          </Text>
        </>
      ) : (
        <>
          {isScreenTooSmall ? (
            <>
              <Flex flexDirection="column" alignItems="center">
                <Flex justifyContent="center">
                  <Icon
                    name="imageSwap"
                    size={50}
                    primaryColor="primaryBodyText"
                  />
                </Flex>
                <Spacer space="x5" />
                <Text
                  type="largeBody"
                  alignment="center"
                  color="primaryHeading"
                  weight="bold"
                >
                  {mobileTitle}
                </Text>
                <Spacer space="x1" />
                <Text type="footnote" alignment="center" color="primaryBody">
                  {mobileSubtitle}
                </Text>
              </Flex>
              <Spacer space="x4" />
            </>
          ) : (
            <>
              <Text
                type="largeBody"
                weight="bold"
                alignment="center"
                style={{ whiteSpace: 'pre-line' }}
                color="primaryHeading"
              >
                {title}
              </Text>
              <Spacer space="x2" />
              <Text
                type="body"
                style={{ whiteSpace: 'pre-line' }}
                alignment="center"
              >
                {description}
              </Text>
              <Spacer space="x4" />
            </>
          )}
          <Flex flexDirection="row" justifyContent="space-around">
            <Button
              outlined={true}
              type="smoke"
              onClick={createNewEditorCard}
              disabled={isScreenTooSmall}
              size={isScreenTooSmall ? 'xSmall' : 'medium'}
            >
              {tryNewEditor.title}
            </Button>
            <Flex outset={{ left: 'x2' }}>
              <Button
                id="use_current_editor_btn"
                onClick={useCurrentEditor.onClick}
                size={isScreenTooSmall ? 'xSmall' : 'medium'}
              >
                {useCurrentEditor.title}
              </Button>
            </Flex>
          </Flex>
        </>
      )}
    </Dialog>
  )
}

export default NewEditorOptionModal
