import { SearchFilter } from './types'

type Defined<T> = T extends undefined ? never : T

export const asDefinedValue = <T extends SearchFilter>(
  filter: T,
): Defined<T['value']> => {
  if (filter.value === undefined) {
    throw new Error(`filter ${filter.name} has no value`)
  }

  return filter.value as Defined<T['value']>
}
