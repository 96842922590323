import React from 'react'

import styles from './editableTable.module.scss'

type EditableTableProps = {}

const EditableTable: React.FC<EditableTableProps> = props => {
  return (
    <div
      className={styles.tableContainer}
      style={{
        overflowX: 'auto',
      }}
    >
      <table className={styles.table}>{props.children}</table>
    </div>
  )
}

export default EditableTable
