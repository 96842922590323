import {
  Button,
  ConfirmDialog,
  Flex,
  Icon,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { useState } from 'src/hooks'
import {
  MiniCart,
  MiniCartProps,
} from 'src/orders/components/MiniCart/MiniCart'

type Action = {
  id: string
  label: string
  onClick: () => void
  backgroundColor?: string
  textColor?: string
}

interface OrderContextBarProps {
  label?: { title: string; count?: number }
  nodeLabel?: JSX.Element | null
  miniCart?: MiniCartProps
  isMiniCartButtonLeft?: boolean
  saveAction?: Action
  extraActions?: Action[]
}

const OrderContextBar: React.FC<OrderContextBarProps> = props => {
  const {
    label,
    nodeLabel,
    extraActions,
    miniCart,
    isMiniCartButtonLeft = false,
    saveAction,
  } = props
  const [shouldShowSaveConfirmation, setShouldShowSaveConfirmation] = useState(
    false,
  )
  return (
    <Flex
      width="100%"
      backgroundColor="foreground"
      justifyContent="space-between"
      alignItems="center"
      position="fixed"
      bottom="0px"
      left="0px"
      zIndex={10000}
      inset={{ vertical: 'x1', horizontal: 'x2' }}
    >
      {saveAction && (
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          backgroundColor="#F3F3F3"
          width="65px"
          inset={{ vertical: 'x1' }}
          position="absolute"
          top="0"
          left="0"
          cursor="pointer"
          onClick={() => setShouldShowSaveConfirmation(true)}
        >
          <Icon name="save" primaryColor="primaryBodyText" size="medium" />
          <Text type="caption" lineHeight={1}>
            save
          </Text>
        </Flex>
      )}
      <Flex inset={saveAction ? { left: 'x8' } : {}}>
        {label && !nodeLabel && (
          <>
            <Text
              type="body"
              content={label.title}
              weight="semiBold"
              color="primaryHeading"
            />
            {label.count && (
              <>
                <Spacer orientation="horizontal" space="x1" />
                <Text
                  type="body"
                  content={`${label.count}`}
                  weight="semiBold"
                  color="primaryHeading"
                />
              </>
            )}
          </>
        )}
        {nodeLabel}
      </Flex>
      <Flex alignItems="center">
        {miniCart && (
          <Flex
            style={{
              order:
                isMiniCartButtonLeft && extraActions
                  ? extraActions.length + 2
                  : 1,
            }}
          >
            <MiniCart {...miniCart} />
            <Spacer space="x3" orientation="horizontal" />
          </Flex>
        )}
        {extraActions &&
          extraActions.map((extraAction, index) => (
            <Flex key={extraAction.id} style={{ order: index + 1 }}>
              <Button
                id={extraAction.id}
                type="success"
                backgroundColorOverride={
                  extraAction.backgroundColor ?? '#323334'
                }
                textColorOverride={extraAction.textColor ?? '#FFFFFF'}
                title={extraAction.label}
                onClick={() => extraAction.onClick()}
              />
              <Spacer space="x3" orientation="horizontal" />
            </Flex>
          ))}
      </Flex>
      {saveAction && shouldShowSaveConfirmation && (
        <ConfirmDialog
          title="Save Card and Exit The Editor?"
          description="Continuing with this action will Exit the Editing Process and save your card as a draft for you to send on a future order. **Please note: Your card is automatically saved when you select proceed to the checkout."
          isOpen={saveAction && shouldShowSaveConfirmation}
          primaryAction="accept"
          accept={{
            title: 'Save and Exit',
            onClick: () => {
              saveAction.onClick()
              setShouldShowSaveConfirmation(false)
            },
            id: saveAction.id,
          }}
          decline={{
            title: 'Return to Editor',
            onClick: () => setShouldShowSaveConfirmation(false),
            id: 'show_exit_editor_confirm',
          }}
          onClose={() => setShouldShowSaveConfirmation(false)}
        />
      )}
    </Flex>
  )
}
export default OrderContextBar
