import moment from 'moment'
import {
  SendDelayDelayType,
  SendDelayFragment,
  SendDelayTimeType,
  SendDelayType,
} from 'src/graphql/generated/graphql'

const typeDescription = {
  IMM: 'immediate',
  BIR: 'birthday',
  ANN: 'anniversary',
  SPE: 'specific date',
}

const timeType = {
  BEF: 'before',
  AFT: 'after',
}

const delayType = {
  DAY: 'day',
  WEE: 'week',
  MON: 'month',
}

const getDelayType = (delayType: SendDelayDelayType) => {
  switch (delayType) {
    case SendDelayDelayType.Day:
      return 'days'
    case SendDelayDelayType.Wee:
      return 'weeks'
    case SendDelayDelayType.Mon:
      return 'months'
  }
}

export const delayTypeOptions = [
  {
    type: 'IMM' as 'IMM',
    text: 'Send Immediately',
    short: 'immediate',
  },
  {
    type: 'BIR' as 'BIR',
    text: 'Arrive on Birthday',
    short: 'birthday',
  },
  {
    type: 'ANN' as 'ANN',
    text: 'Arrive on Anniversary',
    short: 'anniversary',
  },
  {
    type: 'SPE' as 'SPE',
    text: 'Send on Specific Date',
    short: 'specific date',
  },
]

export const updateDelay = (type: SendDelayType): SendDelayFragment => {
  return {
    type,
    timeType: SendDelayTimeType.Bef,
    delayNumber: 0,
    delayType: SendDelayDelayType.Day,
    specificDate: moment().format('YYYY-MM-DD'),
    __typename: 'SendDelay',
  }
}

export const defaultFormat = 'YYYY-MM-DD'

export const updatedYear = (specificDate: string) => {
  const today = moment()
  const currentYear = parseInt(today.format('YYYY'), 10)
  const date = moment(specificDate, defaultFormat).date()
  const month = moment(specificDate, defaultFormat).month()
  return moment({
    year:
      month < today.month() || (month === today.month() && date <= today.date())
        ? currentYear + 1
        : currentYear,
    month,
    date,
  }).format(defaultFormat)
}

export const getDay = (sendDelay: SendDelayFragment) => {
  return parseInt(moment(sendDelay.specificDate, defaultFormat).format('D'), 10)
}

export const getMonth = (sendDelay: SendDelayFragment) => {
  return parseInt(moment(sendDelay.specificDate, defaultFormat).format('M'), 10)
}

export const getMonthAbbr = (sendDelay: SendDelayFragment) => {
  return moment(sendDelay.specificDate, defaultFormat).format('MMM')
}

export const formatDate = (sendDelay: SendDelayFragment) => {
  return moment(updatedYear(sendDelay.specificDate), defaultFormat).format(
    'M/D/YYYY',
  )
}

export const ordinalDate = (
  sendDelay: SendDelayFragment,
  format: string = 'MMM Do',
) => {
  return moment(updatedYear(sendDelay.specificDate), defaultFormat).format(
    format,
  )
}

export const getAfterDelayDate = (sendDelay: SendDelayFragment) => {
  return moment()
    .add(sendDelay.delayNumber, getDelayType(sendDelay.delayType))
    .format('MMM Do, YYYY')
}

export const formatForSave = (sendDelay: SendDelayFragment) => {
  return moment(sendDelay.specificDate, 'M/D/YYYY').format(defaultFormat)
}

export const ribbonDescription = (
  sendDelay: SendDelayFragment,
  format?: string,
) => {
  switch (sendDelay.type) {
    case SendDelayType.Imm:
      if (sendDelay.delayNumber && sendDelay.delayNumber > 0) {
        return `${sendDelay.timeType === SendDelayTimeType.Aft ? '+' : '-'} \
                       ${sendDelay.delayNumber} \
                       ${delayType[sendDelay.delayType]}${
          sendDelay.delayNumber === 1 ? '' : 's'
        }`
      } else {
        return 'Immediate'
      }
    case SendDelayType.Bir:
      return 'Birthday'
    case SendDelayType.Ann:
      return 'Anniversary'
    case SendDelayType.Spe:
      return ordinalDate(sendDelay, format)
  }
}

export const shortDescription = (
  sendDelay: SendDelayFragment,
  dateFormat?: string,
) => {
  switch (sendDelay.type) {
    case SendDelayType.Imm:
      return 'Send ' + typeDescription[sendDelay.type]
    case SendDelayType.Bir:
    case SendDelayType.Ann:
      return 'Arrive on ' + typeDescription[sendDelay.type]
    case SendDelayType.Spe:
      return 'Send ' + ordinalDate(sendDelay, dateFormat)
  }
}

export const verboseDescription = (
  sendDelay: SendDelayFragment,
  dateFormat?: string,
) => {
  if (sendDelay.delayNumber === 0 || sendDelay.type === SendDelayType.Spe) {
    return shortDescription(sendDelay, dateFormat)
  }
  const plural =
    sendDelay.delayNumber === 0 ||
    (sendDelay.delayNumber && sendDelay.delayNumber > 1)
      ? 's'
      : ''
  const description = `Send ${
    sendDelay.delayNumber ? sendDelay.delayNumber : 0
  } \
  ${delayType[sendDelay.delayType]}${plural} \
  ${timeType[sendDelay.timeType]} \
  ${typeDescription[sendDelay.type]}`
  return description
}

export const validateSequence = (lines: { sendDelay: SendDelayFragment }[]) => {
  const [isValid, isImmediatePresent] = lines.reduce<[boolean, boolean]>(
    ([isValid, immediateIsPresent], line) => [
      (line.sendDelay.type === SendDelayType.Imm &&
        line.sendDelay.delayNumber === 0) ||
        isValid,
      line.sendDelay.type === SendDelayType.Imm || immediateIsPresent,
    ],
    [false, false],
  )
  return isValid === isImmediatePresent
}

export const monthDayDefaults = (sendDelay: SendDelayFragment) => {
  // Get month and day integers
  // If type is not SPE, return today's month/day
  const today = new Date()
  return sendDelay.type === SendDelayType.Spe
    ? { month: getMonth(sendDelay), day: getDay(sendDelay) }
    : {
        month: parseInt(moment(today).format('M'), 10),
        day: parseInt(moment(today).format('D'), 10),
      }
}

export const setDateString = (
  month: number,
  day: number,
  format: string = 'YYYY-MM-DD',
) => {
  // Takes month, day from select fields and returns
  // YYYY-MM-DD string with current or next year
  const currentMonth = parseInt(moment().format('M'), 10)
  const currentDay = parseInt(moment().format('D'), 10)
  const currentYear = parseInt(moment().format('YYYY'), 10)

  const year =
    month < currentMonth || (month === currentMonth && day <= currentDay)
      ? currentYear + 1
      : currentYear

  const selectedDate = new Date(year, month - 1, day)
  return moment(selectedDate).utc().format(format)
}
