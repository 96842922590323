import React from 'react'
import {
  AnimatedCheckMark,
  Button,
  Flex,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import {
  VerifyPhoneNumberSuccessFragment,
  VerifyPhoneNumberSuccessType,
} from 'src/graphql/generated/graphql'

const VerifyPhoneNumberSuccess = (props: {
  success: VerifyPhoneNumberSuccessFragment
  onCompletion: (success: VerifyPhoneNumberSuccessFragment) => void
  didVerifyExistingAccount: boolean
}) => {
  const {
    success: { successType, verifiedAccount },
    onCompletion,
    didVerifyExistingAccount,
  } = props
  const message = (() => {
    switch (successType) {
      case VerifyPhoneNumberSuccessType.CreatedNewAccount:
        return {
          title: 'You did it!',
          description:
            'You’ve activated your free card and are on your way to sending kindness to anyone, anywhere in the world! Start thinking of who you want to send a card to!',
        }
      case VerifyPhoneNumberSuccessType.UpdatedExistingAccount:
        return {
          title: 'Update my existing account info',
          description:
            'Your phone number has been verified and updated in your account',
        }
      case VerifyPhoneNumberSuccessType.SignedIntoExistingAccount:
        return didVerifyExistingAccount
          ? {
              title: 'Phone Number Verified!',
              description:
                'Your phone number has been successfully verified. You will now be able to log in to your account using just your phone number!',
            }
          : {
              title: `Welcome back${
                verifiedAccount.firstName ? ` ${verifiedAccount.firstName}` : ''
              }!`,
              description:
                'It looks like you started creating a card - would you like to finish it?',
            }
    }
  })()

  return (
    <form onSubmit={() => onCompletion(props.success)}>
      <Spacer space="x4" />
      <Flex
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
      >
        <AnimatedCheckMark size={'large'} />
        <Spacer space="x3" />
        <Text type="footnote" weight="bold">
          {message.title}
        </Text>
        <Spacer space="x2" />
        <Text type="caption" alignment={'center'}>
          {message.description}
        </Text>
        <Spacer space="x4" />
      </Flex>
      <Spacer space="x2" />
      <Flex justifyContent="center">
        <Button
          title={
            didVerifyExistingAccount
              ? 'Continue to account'
              : 'Continue to card catalog'
          }
          onClick="submitForm"
          size="large"
        />
      </Flex>
    </form>
  )
}

export default VerifyPhoneNumberSuccess
