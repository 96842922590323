import React from 'react'
import { PricingSection } from './components/PricingSection'
import { TaxedMoneyFragment } from 'src/saleor_graphql/generated/graphql'

export const PriceBreakdown = (props: { totalPrice?: TaxedMoneyFragment }) => {
  return (
    <div
      style={{
        boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.04)',
        backgroundColor: '#fff',
        borderRadius: '16px',
        border: '4px solid #fff',
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr',
          gridAutoRows: 'auto',
        }}
      >
        {/* {subtotal && (
          <PricingSection
            title={'Subtotal'}
            price={subtotalNoDiscount}
            // price={subtotalNoDiscount}
            subtitle="Before Savings & Discounts"
          />
        )}
        {hasDiscount && total && (
          <PricingSection title={'Discounts & Savings'} price={total} />
        )}
        {shippingPrice && (
          <PricingSection title={'Shipping & Handling'} price={shippingPrice} />
        )} */}
        <PricingSection title={'Taxes'} price={props.totalPrice?.tax} />
      </div>
      <PricingSection
        title={'Total Due Today'}
        price={props.totalPrice?.gross}
        hasInvertedBackground={true}
      />
    </div>
  )
}
