import React, { FC } from 'react'
import { Flex, UserCard } from '@sendoutcards/quantum-design-ui'
import { usePersistedUserData, useState } from 'src/hooks'
import { BasicCreateAccountForm } from './BasicCreateAccountForm'
import { LoginForm } from './LoginForm'
import { CreditCardManager } from 'src/payments/components'
import { formatLocation } from 'src/helpers'
import { FinishSparseAccountSetupForm } from './FinishSparseAccountSetupForm'
import { CCBillingAddress } from 'src/payments/components/CreditCardForm/CreditCardBillingAddressForm'
import {
  AccountFragment,
  StripeSourceFragment,
} from 'src/graphql/generated/graphql'

type CheckoutAccountWrapperProps = {
  handleLoginOrCreateAccSuccess: (account: AccountFragment) => void
  isSponsorExpanded: boolean
  handleIsSponsorExpanded: (isExpanded: boolean) => void
  shouldShowAffiliateCountriesOnly?: boolean
  onAddedCardSuccess?: (stripeSource?: StripeSourceFragment) => void
  handleUpdateBillingAddressSuccess: (address: CCBillingAddress) => void
}

export const CheckoutAccountWrapper: FC<CheckoutAccountWrapperProps> = ({
  handleLoginOrCreateAccSuccess,
  isSponsorExpanded,
  handleIsSponsorExpanded,
  shouldShowAffiliateCountriesOnly,
  onAddedCardSuccess,
  handleUpdateBillingAddressSuccess,
}) => {
  const { account } = usePersistedUserData()
  const [view, setView] = useState<
    'create' | 'login' | 'authenticated' | 'sparse'
  >(
    account === undefined
      ? 'create'
      : account && account.username
      ? 'authenticated'
      : 'sparse',
  )

  const views = {
    authenticated: (
      <Flex
        justifyContent={'start'}
        alignItems="start"
        width={'100%'}
        flexDirection="column"
        style={{ gap: '24px' }}
      >
        {account?.firstName && account?.lastName && (
          <UserCard
            firstName={account.firstName}
            lastName={account.lastName}
            profileImage={account.profileImageUrl ?? undefined}
            size="small"
            caption={formatLocation(
              account.shippingAddress?.city,
              account.shippingAddress?.state,
              account.shippingAddress?.country,
            )}
          />
        )}

        <CreditCardManager
          shouldMinimizeCard={true}
          shouldShowAffiliateCountriesOnly={shouldShowAffiliateCountriesOnly}
          onAddedCardSuccess={onAddedCardSuccess}
          onUpdateCreditCardBillingAddressSuccess={address =>
            handleUpdateBillingAddressSuccess(address)
          }
        />
      </Flex>
    ),
    login: (
      <LoginForm
        handleNotAUserYet={() => setView('create')}
        onComplete={handleLoginOrCreateAccSuccess}
      />
    ),
    create: (
      <BasicCreateAccountForm
        shouldStackForm={true}
        handleAlreadyUser={() => setView('login')}
        handleSuccessfullAccCreation={handleLoginOrCreateAccSuccess}
        isSponsorExpanded={isSponsorExpanded}
        handleIsSponsorExpanded={handleIsSponsorExpanded}
      />
    ),
    sparse: (
      <FinishSparseAccountSetupForm
        shouldStackForm={true}
        handleSuccessfullAccUpdate={account => {
          setView('authenticated')
          handleLoginOrCreateAccSuccess(account)
        }}
        isSponsorExpanded={isSponsorExpanded}
        handleIsSponsorExpanded={handleIsSponsorExpanded}
      />
    ),
  }

  return views[view]
}
