const mapNullEntries = (
  obj: {
    [key: string]: string | number | null | undefined
  },
  defaults?: {
    [key: string]: string | number
  },
) =>
  Object.keys(obj).reduce(
    (
      result: {
        [key: string]: string | number
      },
      key: string,
    ) => {
      const val = obj[key]
      return {
        ...result,
        [key]:
          !val && typeof val !== 'number' && defaults && key in defaults
            ? defaults[key]
            : val !== undefined && val !== null
            ? val
            : '',
      }
    },
    {},
  )

export default mapNullEntries
