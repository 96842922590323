import React from 'react'

import { Button } from 'src/chrome'
import { DetailedContactFragment } from 'src/legacy_graphql'
import DrawerState, { DrawerStateTypes } from 'src/editor/DrawerState'

import styles from './OrderFooter/orderFooter.module.scss'
import { Div, Spacer, Text } from '@sendoutcards/quantum-design-ui'

interface ContextFooterProps {
  isCustom?: boolean
  isNewOrder?: boolean
  isGiftOnly?: boolean
  cards: number
  drawerState?: DrawerStateTypes
  toRecipient?:
    | DetailedContactFragment
    | DetailedContactFragment[]
    | string[]
    | null
  isInEditor?: boolean
  total: string | number | null
  isInCatalog: boolean
  cancelContext: () => void
  giftTotal?: string
  footerType: string
  name: string
}

const ContextFooter: React.FC<ContextFooterProps> = props => {
  const {
    isCustom,
    isNewOrder,
    isGiftOnly,
    cards,
    drawerState,
    toRecipient,
    isInEditor,
    total,
    isInCatalog,
    cancelContext,
    giftTotal,
    footerType,
    name,
  } = props
  const recipient =
    toRecipient && 'length' in toRecipient
      ? `${toRecipient.length} recipient${toRecipient.length === 1 ? '' : 's'}`
      : `Recipient: ${
          toRecipient ? `${toRecipient.firstName} ${toRecipient.lastName}` : ''
        }`

  return (
    <footer
      className={
        drawerState !== DrawerState.closed && isInEditor
          ? `${styles.orderFooter} ${styles.openDrawer}`
          : styles.orderFooter
      }
      style={
        {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingLeft: '17px',
        } as React.CSSProperties
      }
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {isInCatalog && (
          <Button
            title={'Cancel'}
            onClick={() => cancelContext()}
            buttonColor={'pink'}
            style={{ marginRight: 25 }}
          />
        )}
        {toRecipient && (
          <div>
            <Text type="body" weight="bold">
              {recipient}
            </Text>
          </div>
        )}
        {!toRecipient && (
          <div>
            {!isNewOrder && !isGiftOnly && (
              <Div display="inline-block">
                <Text type="body" weight="bold" color="primaryHeading">
                  {cards === 1 ? `${cards} card` : `${cards} cards`}
                </Text>
              </Div>
            )}
            {total && !isGiftOnly && (
              <Div display="inline-block">
                <Text
                  type="body"
                  color="primaryHeading"
                  outset={{ left: 'x2' }}
                >{`Total: ${total}`}</Text>
              </Div>
            )}
            {isGiftOnly && (
              <Div display="inline-block">
                <Text type="body" weight="bold" color="primaryHeading">
                  {!isInEditor ? 'Pick a card' : 'Edit your card'}
                </Text>
              </Div>
            )}
            {isGiftOnly && (
              <Div display="inline-block">
                <Text
                  type="body"
                  color="primaryHeading"
                  outset={{ left: 'x2' }}
                >
                  {`Total per recipient: ${giftTotal}`}
                </Text>
              </Div>
            )}
          </div>
        )}
      </div>
      {!isInCatalog ? (
        <div
          className={styles.rightControls}
          style={{ display: 'flex', marginRight: 20, alignItems: 'center' }}
        >
          {isCustom && (
            <Text type="body" color="primaryHeading" outset="x2">
              Editing Custom Card
            </Text>
          )}
          {isCustom && (
            <Button
              id="save_custom_card_btn"
              title="Save & Close"
              style={{ marginRight: 15 }}
            />
          )}
          <Button
            id={'add_or_update_card_btn'}
            buttonColor={'pink'}
            title={
              isNewOrder || isGiftOnly
                ? isCustom
                  ? `Save & Send`
                  : 'Next'
                : 'Update'
            }
            style={{ marginRight: 15 }}
          />
        </div>
      ) : (
        <div
          className={styles.rightControls}
          style={{ display: 'flex', marginRight: 20, alignItems: 'center' }}
        >
          <Text type="body" color="primaryHeading" weight="bold">
            {footerType === 'order' ? 'Order:' : 'Campaign:'}
          </Text>
          <Spacer space="x1" orientation="horizontal" />
          <Text type="body">{`${name}`}</Text>
        </div>
      )}
    </footer>
  )
}

export default ContextFooter
