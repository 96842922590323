import React from 'react'

import get from 'lodash/get'
import {
  Checkbox,
  Div,
  Flex,
  Input,
  Select,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { useAccount, useActions, useMutations, useState } from 'src/hooks'
import { AddressFragment } from 'src/legacy_graphql'
import { NO_STATE_SELECTION } from 'src/contacts/components/AddressForm/AddressForm'
import useCountryAndRegions from 'src/hooks/useCountryAndRegions'

export type CCBillingAddress = {
  postalCode: string
  line1: string
  line2: string
  city: string
  state: string
  country: string
}

interface CreditCardBillingAddressFormProps {
  onSuccess: (address: CCBillingAddress) => void
  onError: (msg: string) => void
  isCCFormComplete: (isComplete: boolean) => void
  handleIsLoading: (isLoading: boolean) => void
  returnAddress?: AddressFragment
  overrideChildContCSS?: string
  overrideCountryOptions?: { value: string; label: string }[]
}

const CreditCardBillingAddressForm: React.FC<CreditCardBillingAddressFormProps> = props => {
  const {
    onError,
    onSuccess,
    children,
    isCCFormComplete,
    handleIsLoading,
    returnAddress,
    overrideChildContCSS,
    overrideCountryOptions,
  } = props

  const [address, setAddress] = useState<CCBillingAddress>({
    postalCode: '',
    line1: '',
    line2: '',
    city: '',
    state: '',
    country: 'United States',
  })

  const [shouldPrefillAddress, setShouldPrefillAddress] = useState(false)

  const mutations = useMutations()
  const actions = useActions()
  const account = useAccount()

  const {
    countries,
    regions,
    isRegionsUnavailable,
    generatedStateOptions,
    generatedCountryOptions,
  } = useCountryAndRegions(address.country)

  const countryOptions = overrideCountryOptions ?? generatedCountryOptions

  const isAddressComplete = (address: CCBillingAddress) =>
    !!address.line1 &&
    !!address.city &&
    (isRegionsUnavailable || !!address.state) &&
    !!address.country &&
    !!address.postalCode

  const onSubmit = async (formEvent: React.FormEvent<HTMLFormElement>) => {
    if (formEvent) {
      formEvent.preventDefault()
    }

    if (!isAddressComplete(address)) {
      onError('Invalid billing address')
      return
    }

    handleIsLoading(true)

    try {
      const {
        updateSourceBillingAddress: {
          account: { stripeSource },
        },
      } = await mutations.updateSourceBillingAddress({
        billingAddress: {
          addressZip: address.postalCode,
          addressLine1: address.line1,
          addressLine2: address.line2,
          addressCity: address.city,
          addressState: isRegionsUnavailable ? '' : address.state,
          addressCountry: address.country,
        },
      })
      actions.updatedAccount({ ...account, stripeSource })
    } catch (error) {
      const message = get(
        error,
        'message',
        'There has been an error adding your billing address.',
      )
      return onError(message)
    } finally {
      handleIsLoading(false)
    }

    return onSuccess(address)
  }

  const handleAddressFormOnChange = (
    eventKey: keyof typeof address,
    value: string,
  ) => {
    const newAddress = { ...address, [eventKey]: value }
    setAddress(newAddress)
    isCCFormComplete(isAddressComplete(newAddress))
  }

  return (
    <form id="addCreditCardForm" onSubmit={onSubmit}>
      <Div
        alignItems="center"
        css={{
          gridTemplateColumns: '1fr 1fr',
          display: 'grid',
          gap: '16px',
        }}
      >
        <Div style={{ gridColumn: 'span 2' }}>
          <Text
            type="caption"
            content="Billing Address"
            outset={{ left: 'x1' }}
            color="primaryHeading"
            weight="semiBold"
          />
        </Div>
        <Div
          css={{
            display: 'grid',
            gap: '16px',
            gridColumn: 'span 2',
            '@media (max-width: 1055px)': {
              gridTemplateColumns: '1fr',
              gridColumn: 'span 2',
            },
          }}
        >
          <Input
            isFullWidth={true}
            type="text"
            placeholder="Address Line 1"
            value={address.line1}
            onChange={value => handleAddressFormOnChange('line1', value)}
          />
          <Input
            isFullWidth={true}
            type="text"
            placeholder="Address Line 2"
            value={address.line2}
            onChange={value => handleAddressFormOnChange('line2', value)}
          />
        </Div>
        <Div
          css={{
            gridTemplateColumns: '1fr 1fr',
            gridColumn: 'span 2',
            display: 'grid',
            gap: '16px',
          }}
        >
          <Input
            isFullWidth={true}
            type="text"
            placeholder="City"
            value={address.city}
            onChange={value => handleAddressFormOnChange('city', value)}
          />
          {!isRegionsUnavailable && (
            <Div>
              <Select
                selectedOptionIndex={
                  regions &&
                  [NO_STATE_SELECTION, ...regions]?.indexOf(address.state) !==
                    -1
                    ? regions &&
                      [NO_STATE_SELECTION, ...regions]?.indexOf(address.state)
                    : 0
                }
                onChange={selectedIndex => {
                  const selectedState =
                    generatedStateOptions.map(option => option.label)[
                      selectedIndex
                    ] || NO_STATE_SELECTION
                  handleAddressFormOnChange('state', selectedState)
                }}
                alignment="center"
                options={generatedStateOptions}
                type="text"
                dropDownMaxHeight="250px"
                isDropDownAbove={true}
                focusColor={
                  address.state && address.state !== NO_STATE_SELECTION
                    ? 'danger'
                    : undefined
                }
                message={
                  address.state && address.state !== NO_STATE_SELECTION
                    ? undefined
                    : { type: 'danger', content: 'this field is required' }
                }
              />
            </Div>
          )}
        </Div>
        <Div
          css={{
            gridTemplateColumns: '1fr 1fr',
            gridColumn: 'span 2',
            display: 'grid',
            gap: '16px',
          }}
        >
          <Div>
            <Input
              isFullWidth={true}
              id="postal"
              type="text"
              placeholder="Zip Code"
              value={address.postalCode}
              onChange={value => handleAddressFormOnChange('postalCode', value)}
            />
          </Div>
          <Div
            css={{
              '@media (max-width: 1055px)': {
                gridColumn: 'span 2',
              },
            }}
          >
            <Select
              selectedOptionIndex={
                countries?.indexOf(address.country) !== -1
                  ? countries?.indexOf(address.country)
                  : undefined
              }
              onChange={selectedIndex => {
                const country = countries?.[selectedIndex] || ''
                handleAddressFormOnChange('country', country)
              }}
              alignment="center"
              options={countryOptions}
              type="text"
              dropDownMaxHeight="250px"
              isDropDownAbove={true}
            />
          </Div>
          {returnAddress && (
            <Flex
              justifyContent="flex-start"
              width={'max-content'}
              outset={{ left: '-13px' }}
            >
              <Checkbox
                onChange={shouldPrefill => {
                  const {
                    postalCode,
                    address1,
                    address2,
                    city,
                    state,
                    country,
                  } = returnAddress
                  setShouldPrefillAddress(shouldPrefill)
                  const addressAsRAddress = {
                    name: '',
                    postalCode: shouldPrefill ? postalCode : '',
                    line1: shouldPrefill ? address1 : '',
                    line2: shouldPrefill ? address2 : '',
                    city: shouldPrefill ? city : '',
                    state: shouldPrefill ? state : '',
                    country: shouldPrefill ? country : '',
                  }
                  setAddress(addressAsRAddress)
                  isCCFormComplete(isAddressComplete(addressAsRAddress))
                }}
                isChecked={shouldPrefillAddress}
                type="primary"
                label="Same as Return Address"
              />
            </Flex>
          )}
        </Div>
        <Div
          css={{
            gridColumn: overrideChildContCSS ?? 'span 2',
            '@media (max-width: 1055px)': {
              gridColumn: 'span 1',
            },
          }}
        >
          <Flex justifyContent="flex-end">{children}</Flex>
        </Div>
      </Div>
    </form>
  )
}

export default CreditCardBillingAddressForm
