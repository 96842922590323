import Action from '../action'
import {
  completedQuery,
  deprioritizedQueries,
  invalidatedQueries,
  invalidatedQueriesByName,
  loadMore,
  mutationFailed,
  mutationSucceeded,
  prioritizedQueries,
  scheduledOptimisticMutation,
  startedBlockingMutation,
} from '../actions/graphql'
import Future from 'src/utils/Future'
import { objectSize } from 'src/helpers'
import {
  deepmergeOptions,
  denormalizeValue,
  gatherNormalizedData,
  normalizeValue,
} from 'src/normalized-data/normalization'
import deepmerge from 'deepmerge'
import {
  Changes,
  GraphQLObject,
  QueryProperties,
} from 'src/legacy_graphql/Query'
import { Set } from 'immutable'
import mapValues from 'lodash/mapValues'
import { getFlags, NormalizedData, Operation } from 'src/legacy_graphql'
import ObjectMap from 'src/utils/ObjectMap'
import { prettyPrintOperation } from 'src/legacy_graphql/utils'
import { loginDone } from '../actions'

type DenormalizedElement = { id: string }

type GraphQLState = {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  queryStates: ObjectMap<Operation<unknown, unknown>, Future<any>>
  queryProperties: ObjectMap<
    Operation<unknown, unknown>,
    QueryProperties<GraphQLObject, unknown, unknown>
  >
  queryRequests: ObjectMap<
    Operation<unknown, unknown>,
    ObjectMap<{ offset?: number | null; limit?: number | null }, number>
  >
  optimisticMutations: {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    operation: Operation<any, unknown>
    failureMessage: string
    revert: Changes
  }[]
  blockingMutationTransitionMessage?: string
  normalizedData: NormalizedData
}

const id = <T>(value: T) => value
const empty = () => []

const initialState: GraphQLState = {
  queryStates: ObjectMap(Future(), prettyPrintOperation),
  queryProperties: ObjectMap(
    {
      mapResult: id,
      updateState: id,
      loadMore: id,
      mapState: id,
      additionalPagesToLoad: empty,
    },
    prettyPrintOperation,
  ),
  queryRequests: ObjectMap(ObjectMap(0), prettyPrintOperation),
  optimisticMutations: [],
  normalizedData: NormalizedData(),
}

type DenormalizedData = {
  [Key in keyof NormalizedData]: {
    [id: string]: DenormalizedElement
  }
}

const queriesReducer = (state = initialState, action: Action): GraphQLState => {
  switch (action.type) {
    case prioritizedQueries.type:
    case deprioritizedQueries.type:
      const queries =
        action.type === prioritizedQueries.type
          ? action.queries
          : action.queries.filter(query =>
              state.queryProperties.contains(query.operation),
            )
      return {
        ...state,
        queryProperties: queries.reduce(
          (
            queryProperties,
            {
              operation,
              mapResult,
              updateState,
              loadMore,
              mapState,
              additionalPagesToLoad,
              hitCDNCache,
            },
          ) =>
            queryProperties.setIfNotPresent(operation, {
              mapResult,
              updateState,
              loadMore,
              mapState,
              additionalPagesToLoad,
              hitCDNCache,
            }),
          state.queryProperties,
        ),
        queryRequests: queries.reduce(
          (requests, { operation, offset, limit }) =>
            requests.update(operation, requests =>
              requests.update({ offset, limit }, requests =>
                action.type === prioritizedQueries.type
                  ? requests + action.increment
                  : requests - action.decrement,
              ),
            ),
          state.queryRequests,
        ),
      }
    case invalidatedQueries.type:
      return {
        ...state,
        queryStates: action.queries.reduce(
          (queryStates, { operation }) => queryStates.set(operation, Future()),
          state.queryStates,
        ),
      }
    case invalidatedQueriesByName.type:
      const queryNameRegex = /query ([A-Za-z]*)/
      return {
        ...state,
        queryStates: state.queryStates
          .entries()
          .reduce((queryStates, [operation, future]) => {
            const match = operation.query.match(queryNameRegex)
            if (match && action.queryNames.includes(match[1])) {
              return queryStates.set(operation, Future())
            } else {
              return queryStates
            }
          }, state.queryStates),
      }
    case loginDone.type:
      // This is intended for all the queries we should reload after obtaining a valid user token
      const queriesToReload = [getFlags()]
      return {
        ...state,
        queryStates: queriesToReload.reduce(
          (queryStates, { operation }) => queryStates.set(operation, Future()),
          state.queryStates,
        ),
      }
    case loadMore.type:
      return {
        ...state,
        queryStates: state.queryStates.update(action.operation, value =>
          value.map(state.queryProperties.get(action.operation).loadMore),
        ),
      }
    case completedQuery.type:
      return {
        ...state,
        queryStates: state.queryStates.update(action.operation, queryState =>
          Future(
            state.queryProperties
              .get(action.operation)
              .mapResult(
                action.result,
                action.offset,
                action.limit,
                queryState.value !== undefined
                  ? denormalizeValue(queryState.value, state.normalizedData)
                  : undefined,
              )
              .map(normalizeValue),
          ),
        ),
      }
    case startedBlockingMutation.type:
      return {
        ...state,
        blockingMutationTransitionMessage: action.transitionMessage,
      }
    case mutationSucceeded.type:
      return {
        ...state,
        optimisticMutations: action.wasOptimistic
          ? state.optimisticMutations.slice(1, state.optimisticMutations.length)
          : state.optimisticMutations,
        blockingMutationTransitionMessage: undefined,
      }
    case mutationFailed.type:
      return {
        ...state,
        optimisticMutations: [],
        queryStates: state.optimisticMutations.reduceRight(
          (queryStates, { revert }) =>
            updateQueryStates({ ...state, queryStates }, revert),
          state.queryStates,
        ),
        blockingMutationTransitionMessage: undefined,
      }
    default:
      return state
  }
}
const updateQueryStates = (
  { queryStates, queryProperties, normalizedData }: GraphQLState,
  changes: Changes,
): ObjectMap<Operation<unknown, unknown>, Future<unknown>> =>
  queryStates.mapValues((queryState, operation) =>
    queryState.map(state =>
      normalizeValue(
        queryProperties
          .get(operation)
          .updateState(
            denormalizeValue(state, normalizedData),
            changes,
            operation.variables as Pick<unknown, never>,
          ),
      ),
    ),
  )

// This is only currently neccessary when converting sticker assets from soc-editor hosted images
// into soc-backend. In the future if you need to block cache updates you can add this flag to the
// action you need to block or all action types (if necessary)
const getOrBlockNormalizedData = (action: Action) =>
  action.type === 'MUTATION_SUCCEEDED' &&
  action.shouldBlockGatheringNormalizedData
    ? {}
    : gatherNormalizedData(action)

const graphql = (state = initialState, action: Action): GraphQLState => {
  const deletedResources =
    action.type === mutationSucceeded.type ||
    action.type === scheduledOptimisticMutation.type
      ? action.deletedResources
      : {}

  const newNormalizedData = getOrBlockNormalizedData(action)

  if (
    objectSize(newNormalizedData) === 0 &&
    objectSize(deletedResources) === 0
  ) {
    return queriesReducer(state, action)
  }

  const normalizedState = {
    ...state,
    normalizedData: deepmerge(
      state.normalizedData,
      newNormalizedData,
      deepmergeOptions,
    ),
  }

  const updatedState = queriesReducer(normalizedState, action)

  const newDenormalizedData: Partial<DenormalizedData> = denormalizeValue(
    normalizeValue(newNormalizedData),
    updatedState.normalizedData,
  )

  const createUpdateChanges =
    action.type === mutationSucceeded.type
      ? mapValues(newDenormalizedData, elements => ({
          created:
            action.didCreateResources && elements
              ? Object.values(elements)
              : [],
          updated:
            !action.didCreateResources && elements
              ? Object.values(elements)
              : [],
          deleted: Set(),
        }))
      : action.type === scheduledOptimisticMutation.type
      ? mapValues(newDenormalizedData, (elements, __typename) => ({
          created: elements
            ? Object.values(elements).filter(element =>
                action.createdResources.find(
                  resource =>
                    resource.__typename === __typename &&
                    resource.id === element.id,
                ),
              )
            : [],
          updated: elements
            ? Object.values(elements).filter(element =>
                action.updatedResources.find(
                  resource =>
                    resource.__typename === __typename &&
                    resource.id === element.id,
                ),
              )
            : [],
          deleted: Set(),
        }))
      : {}

  const changes = Object.entries(deletedResources).reduce(
    (changes, [typename, deleted]) => ({
      ...changes,
      [typename]: {
        ...(changes[typename as keyof NormalizedData] ?? {
          created: [],
          updated: [],
        }),
        deleted,
      },
    }),
    createUpdateChanges,
  ) as Changes

  const revert = mapValues(
    changes,
    (
      {
        created,
        updated,
        deleted,
      }: {
        created: DenormalizedElement[]
        updated: DenormalizedElement[]
        deleted: Set<string>
      },
      __typename,
    ) => ({
      created: denormalizeValue(
        deleted.toArray().map(id => ({ __typename, id })),
        state.normalizedData,
      ),
      updated: denormalizeValue(
        updated.map(({ id }) => ({ __typename, id })),
        state.normalizedData,
      ),
      deleted: Set(created.map(({ id }) => id)),
    }),
  ) as Changes

  return {
    ...updatedState,
    optimisticMutations:
      action.type === scheduledOptimisticMutation.type
        ? [
            {
              operation: action.operation,
              failureMessage: action.failureMessage,
              revert,
            },
            ...updatedState.optimisticMutations,
          ]
        : updatedState.optimisticMutations,
    queryStates:
      action.type === scheduledOptimisticMutation.type ||
      action.type === mutationSucceeded.type
        ? updateQueryStates(updatedState, changes)
        : updatedState.queryStates,
  }
}

export default graphql
