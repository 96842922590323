import { Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { useSelector } from 'src/hooks'
import LeaderBoard from './LeaderBoard'
import { useLeaderBoards } from 'src/react_query'
import {
  LeaderBoardFragment,
  LeaderFragment,
} from 'src/graphql/generated/graphql'
export type LeaderType = Omit<LeaderFragment, '__typename'>

type LeaderBoardListProps = {}

export const LeaderBoardList: React.FC<LeaderBoardListProps> = () => {
  const isTablet = useSelector(state => state.window.width <= 1500)
  const { data: leaderBoards } = useLeaderBoards()

  const groupedData:
    | Record<string, LeaderBoardFragment[]>
    | undefined = leaderBoards?.reduce((acc, board: LeaderBoardFragment) => {
    const { userAccess } = board
    const updatedAcc = {
      ...acc,
      [userAccess]: [...(acc[userAccess] || []), board],
    }
    return updatedAcc
  }, {} as Record<string, LeaderBoardFragment[]>)

  return (
    <Flex
      flexDirection="column"
      inset={isTablet ? '0' : 'x3'}
      outset={isTablet ? { top: 'x3' } : 'x0'}
      justifyContent={'center'}
      alignItems={'center'}
      position="relative"
      width="100%"
    >
      {groupedData ? (
        Object.keys(groupedData).map((userAccess: string, index: number) => (
          <Flex key={index} width="100%" flexWrap="wrap">
            <Flex outset={{ bottom: 'x3' }}>
              <Text
                type="subtitle"
                weight="extraBold"
                content={`${userAccess} Users`}
                isInline={true}
              />
            </Flex>
            <Flex
              flexDirection="row"
              width="100%"
              alignContent="space-evenly"
              flexWrap="wrap"
            >
              {groupedData[userAccess].map(
                (board: LeaderBoardFragment, groupIndex: number) => (
                  <div key={groupIndex} className="flex-item">
                    <LeaderBoard {...board} isListOpen={false} />
                  </div>
                ),
              )}
            </Flex>
            <Div
              borderRadius="circle"
              width="100%"
              height="1px"
              backgroundColor={'#CECECE'}
              outset={{ bottom: 'x4' }}
            />
          </Flex>
        ))
      ) : (
        <Flex>No Live Leader Boards</Flex>
      )}
    </Flex>
  )
}
