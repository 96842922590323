import { Anchor, Icon, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'

export const ImageErrorDisclaimer: React.FC = () => {
  return (
    <>
      <div style={{ minWidth: '18px', display: 'flex' }}>
        <Icon name="warning" size="xSmall" primaryColor="danger" />
      </div>
      <Spacer space="x1" orientation="horizontal" />
      <Text type="caption" color="danger">
        We encountered an error while preparing your card due to an issue with
        the pictures or images used. Click{' '}
        <Anchor
          size="xSmall"
          color="anchorBlue"
          target="_blank"
          href="https://help.sendoutcards.com/sendoutcards/en/articles/433062-what-is-the-optimum-size-for-photos-to-achieve-the-best-print-quality"
        >
          here
        </Anchor>{' '}
        for image recommendations.
      </Text>
    </>
  )
}
