import {
  CardStatus,
  ProductionInfoFragment,
} from 'src/graphql/generated/graphql'

const selectableCards = (card: ProductionInfoFragment) => {
  return (
    card.status !== CardStatus.Fulfilled && card.status !== CardStatus.Rejected
  )
}

export default selectableCards
