import React from 'react'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
// @src imports
import { Button, DefaultError, InstantSearch, Transition } from 'src/chrome'
import { CampaignPreview, CampaignsTabContent } from 'src/campaigns/components'
import CampaignStore from 'src/campaign_store/containers/CampaignStore'
import { CampaignStoreRoute } from 'src/campaign_store/routes/CampaignStoreRoute'
import { campaignsPageSize, MinimalCampaignFragment } from 'src/legacy_graphql'
import suspenseBoundary from 'src/chrome/SuspenseBoundary/suspenseBoundaryHOC'
// relative imports
import { CampaignsRoute } from '../routes/CampaignsRoute'
import { PRICING_CONTEXT } from 'src/context/types'
import styles from './campaigns.module.scss'
import {
  useActions,
  useAppcues,
  useEffect,
  useSelector,
  useState,
} from 'src/hooks'
import { MyCampaignsRoute } from '../routes/MyCampaignsRoute'
import { PurchasedCampaignsRoute } from '../routes/PurchasedCampaignsRoute'
import { Div, Text } from '@sendoutcards/quantum-design-ui'
import AnnouncementBanners from 'src/marketing/AnnouncementBanners/AnnouncementBanners'
import { useFeatureAccess } from 'src/hooks/useFeatureAccess'
import { LockedFeatureModal } from 'src/LockedFeatureModal/LockedFeatureModal'
import { ShoppingCart } from 'src/saleor/components/shopping_cart/ShoppingCart'
import { SubscriptionCheckout } from 'src/saleor/components/subscription_checkout/SubscriptionCheckout'
import { FullAccessFeatureType } from 'src/graphql/generated/graphql'
import { browserHistory } from 'src/redux/browserHistory'

interface Props {
  route: CampaignsRoute
}

const Campaigns: React.FC<Props> = props => {
  const { route } = props

  const tabStyle = {
    className: styles.className,
    style: { fontSize: 16 },
  }

  const featureAccessArray = [FullAccessFeatureType.CampaignStoreAccess]

  const { hasFeatureAccess } = useFeatureAccess(featureAccessArray)
  const { hasFeatureAccess: hasFreeCampaignDownloads } = useFeatureAccess([
    FullAccessFeatureType.CampaignStore,
  ])

  const actions = useActions()
  const isMobile = useSelector(state => state.window.isMobile)
  const [search, setSearch] = useState('')
  const [selectedMyCampaignsPage, setSelectedMyCampaignsPage] = useState(1)
  const [selectedPurchasedPage, setSelectedPurchasedPage] = useState(1)
  const [campaignPreviewId, setCampaignPreviewId] = useState<string>()
  const context = useSelector(state => state.context)
  const isCheckoutOpen =
    context.kind === PRICING_CONTEXT ? context.isCheckoutOpen : false

  const tabContent = route.subroute

  useAppcues('-L9R-PVAOXEbML-UowTv')

  const changeMyCampaignsPage = (page: number) =>
    setSelectedMyCampaignsPage(page > 0 ? page : 1)

  const changePurchasedPage = (page: number) =>
    setSelectedPurchasedPage(page > 0 ? page : 1)

  const previewCampaign = (campaign: MinimalCampaignFragment) =>
    setCampaignPreviewId(campaign.id)

  const closeCampaignPreview = () => setCampaignPreviewId(undefined)

  const campaignsTabContentProps = {
    pageSize: campaignsPageSize,
    setPage: changeMyCampaignsPage,
    onPreview: previewCampaign,
    openCampaign: actions.openCampaign,
    searchTerm: search,
  }
  useEffect(() => {
    const expectedStore = 'campaigns'

    const searchParams = new URLSearchParams(window.location.search)
    const store = searchParams.get('store')

    if (store !== expectedStore) {
      searchParams.delete('checkout')
      searchParams.delete('store')
      searchParams.append('store', expectedStore)
      const newUrl = `${window.location.pathname}?${searchParams.toString()}`
      browserHistory.push(newUrl)
      // Window popstate event doesn't trigger when history state changes so we dispatch a custom event
      // See Checkout context for this event listener
      window.dispatchEvent(new Event('update_checkout'))
    }
  }, [])

  if (!hasFeatureAccess)
    return (
      <LockedFeatureModal
        actions={{
          mainAction: {
            execute: () => console.log('clicked main action'),
            title: 'Unlock This & More Features',
          },
          secondaryAction: {
            execute: () => console.log('closed'),
            title: 'Not Now',
          },
        }}
        textContent={{
          title: 'Unlock Me!',
          subTitle: 'The Campaign Manager is a Full System Feature.',
          description:
            'Upgrade your account from a Basic account to a Monthly Membership or any of our other plans to access this great feature!',
        }}
        image={{
          url:
            'https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/images/locked-feature-modal/image-4.png',
          height: '150%',
          width: '100%',
          bottom: '0px',
        }}
        featureAccessToGrant={featureAccessArray}
      />
    )

  return (
    <div className={styles.campaignWrapper}>
      <SubscriptionCheckout
        isOpen={!!isCheckoutOpen}
        setIsOpen={actions.checkoutOpenState}
      />
      <AnnouncementBanners />
      <div className={styles.header}>
        {!hasFreeCampaignDownloads && (
          <Div position={'absolute'} top={'48px'} right="40px" zIndex={400}>
            <ShoppingCart
              onProceed={() => {
                actions.checkoutOpenState(true)
              }}
            />
          </Div>
        )}
        <Text
          type="subtitle"
          color="accent"
          inset={
            isMobile
              ? {
                  top: { xSmall: 'x2', medium: 'x7' },
                  left: 'x1_5',
                  bottom: 'x6',
                }
              : { top: { xSmall: 'x2', medium: 'x7' }, left: 'x4' }
          }
        >
          My Campaigns
        </Text>
        <Fab
          id={'create_new_campaign_btn'}
          color="secondary"
          className={styles.addCampaign}
          onClick={actions.openCreateCampaign}
        >
          <AddIcon fontSize="small" />
        </Fab>
        <div
          css={{
            opacity: tabContent.path === CampaignStoreRoute.path ? 0 : 100,
          }}
        >
          <InstantSearch
            id={'campaigns_search_bar'}
            onSearch={setSearch}
            className={styles.search}
            autocomplete={'off'}
          />
        </div>
        <Tabs
          value={route.subroute.path}
          style={{
            position: 'relative',
            bottom: 105,
            color: '#fff',
          }}
          onChange={(_, value) => {
            switch (value) {
              case MyCampaignsRoute.path:
                return actions.openCampaigns(MyCampaignsRoute())
              case PurchasedCampaignsRoute.path:
                return actions.openCampaigns(PurchasedCampaignsRoute())
              case CampaignStoreRoute.path:
                return actions.openCampaigns(CampaignStoreRoute())
              default:
                return
            }
          }}
          TabIndicatorProps={{
            style: {
              height: 3,
              width: 145,
              bottom: 3,
              backgroundImage: 'linear-gradient(115deg,#ff5689,#e687ff)',
            },
          }}
        >
          <Tab
            {...tabStyle}
            id={'my_campaigns_tab'}
            label="My Campaigns"
            value={MyCampaignsRoute.path}
          />
          <Tab
            {...tabStyle}
            id={'purchased_campaigns_tab'}
            label={
              <Text type="caption" color="accent">
                Purchased
              </Text>
            }
            value={PurchasedCampaignsRoute.path}
          />
          <Tab
            {...tabStyle}
            id={'store_campaigns_tab'}
            label={
              <Text type="body" color="accent">
                Store
              </Text>
            }
            value={CampaignStoreRoute.path}
          />
        </Tabs>
      </div>
      {tabContent.path === MyCampaignsRoute.path && (
        <CampaignsTabContent
          {...campaignsTabContentProps}
          page={selectedMyCampaignsPage}
          setPage={changeMyCampaignsPage}
          emptyTitle={
            "Uh oh! It looks like you haven't started any campaigns yet."
          }
          emptySubtitle={'Click + to create one'}
          isShareable={true}
        />
      )}
      {tabContent.path === PurchasedCampaignsRoute.path && (
        <CampaignsTabContent
          {...campaignsTabContentProps}
          page={selectedPurchasedPage}
          setPage={changePurchasedPage}
          emptyTitle={"It looks like you haven't purchased any campaigns yet."}
          emptySubtitle={'Check out the Campaign Store'}
          isShareable={false}
        >
          <Button
            title={'Campaign Store'}
            buttonColor={'pink'}
            onClick={() => actions.openCampaignStore()}
          />
        </CampaignsTabContent>
      )}
      {tabContent.path === CampaignStoreRoute.path && <CampaignStore />}
      {campaignPreviewId && (
        <CampaignPreview
          campaignId={campaignPreviewId}
          onClose={closeCampaignPreview}
        />
      )}
    </div>
  )
}

export default suspenseBoundary({
  component: Campaigns,
  unresolved: <Transition />,
  failure: DefaultError,
})
