import React from 'react'

import { OrderLine } from 'src/editor/types'
import CardCatalog from 'src/catalog/containers/CardCatalog'
import { CardFragment } from 'src/graphql/generated/graphql'

import { CatalogSubroute } from 'src/catalog/routes/CatalogRoute'
import { OrderApiType } from 'src/orders/api'

type Props = {
  onClose?: () => void
  context: {
    headerText?: string
    headerSubText?: string
    lines?: OrderLine[]
    activeCardId?: string
    footerLabel: (card: CardFragment) => JSX.Element
  }
  onSelectCard: (id: string) => void
  onCatalogNavigate: (subroute?: CatalogSubroute) => void
  catalogSubroute: CatalogSubroute | undefined
  orderApi?: OrderApiType
  actionContext?: string
}

const CardPicker: React.SFC<Props> = props => {
  const {
    context,
    onClose,
    onSelectCard,
    onCatalogNavigate,
    catalogSubroute,
    orderApi,
    actionContext,
  } = props

  return (
    <CardCatalog
      onNavigate={onCatalogNavigate}
      context={context}
      onCancel={onClose}
      onSelect={onSelectCard}
      subroute={catalogSubroute}
      orderApi={orderApi}
      actionContext={actionContext}
    />
  )
}

export default CardPicker
