import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useRef, useScroll, useState } from 'src/hooks'
import styles from '../styles/components/images.module.scss'
import CloseButton from 'src/chrome/CloseButton/CloseButton'
import { Div, Image } from '@sendoutcards/quantum-design-ui'
import { css, SerializedStyles } from '@emotion/core'
export interface ImageBase {
  smallThumb: string
  id: string
  isMobile?: boolean
}
type StyleType = SerializedStyles | string

type Props<T extends ImageBase> = {
  result: T[]
  isLoading: boolean
  hasMore: boolean
  loadMore: () => void
  onSelect?: (image: T) => void
  deleteImage?: (id: string) => void
  deleting?: string
  isMinimized?: boolean
  isMobile?: boolean
  isExpanded?: boolean
}
const Images = <T extends ImageBase>(
  props: React.PropsWithChildren<Props<T>>,
) => {
  const {
    isLoading,
    hasMore,
    loadMore,
    onSelect,
    deleteImage,
    result,
    deleting,
    isExpanded,
    isMobile,
  } = props

  const triggerElement = useRef<HTMLDivElement>(null)
  const [activeElementState, setActiveElementState] = useState([-1, false])
  const handleScroll = useScroll({
    isLoadingMore: isLoading,
    hasMore,
    loadMore,
    triggerElement,
  })

  const mobileContainerStyles = css`
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    height: auto;
    width: 100%;
    padding-bottom: 20px;
    height: 110px;
    scroll-snap-type: x mandatory;
    scroll-padding-inline: 0.5rem;
    > div {
      scroll-snap-align: start;
    }
  `

  const containerStyles = css`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    height: ${isMobile ? '480px' : '100%'};
    overflow-y: auto;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    scroll-padding-inline: 0.5rem;
    > div {
      scroll-snap-align: start;
    }
  `
  const applyStyles = <T extends StyleType>(
    mobileStyles: T,
    desktopStyles: T,
  ) => {
    return isMobile
      ? !isExpanded
        ? mobileStyles
        : desktopStyles
      : desktopStyles
  }
  // eslint-disable-next-line @typescript-eslint/naming-convention
  let mutableTimer: NodeJS.Timeout | null = null

  const onTouchStart = (index: number) => {
    mutableTimer = setTimeout(() => onLongTouch(index), 500)
  }

  const onLongTouch = (index: number) => {
    const [activeIndex, isOn] = activeElementState
    const isOnCheck =
      activeIndex === index && isOn
        ? false
        : activeIndex === index && !isOn
        ? true
        : activeIndex !== index && isOn
        ? true
        : false
    setActiveElementState([index, isOnCheck])
  }

  const onTouchEnd = () => {
    if (mutableTimer) {
      clearTimeout(mutableTimer)
    }
  }
  const [activeElementIndex, isDeleteOn] = activeElementState

  return (
    <div
      css={applyStyles(mobileContainerStyles, containerStyles)}
      onScroll={handleScroll}
    >
      {result.map((image, index) => {
        const backgroundImage = {
          backgroundImage: `url(${image.smallThumb})`,
        }
        const mobileBackgroundImage = image.smallThumb

        const isDeleting = deleting === image.id
        return (
          <Div
            width={isMobile && !isExpanded ? '85px' : 'calc(50% - 24px)'}
            height={isMobile && !isExpanded ? '75px' : '120px'}
            className={
              isMobile && !isExpanded
                ? styles.imageAsset
                : `${styles.imageAsset} + ${styles.imageSquare}`
            }
            key={index}
            outset="x1"
          >
            {deleteImage &&
              !isDeleting &&
              ((activeElementIndex === index && isDeleteOn) || !isMobile) && (
                <Div
                  zIndex={100}
                  className={`${!isMobile && styles.deleteButtonDesktop} ${
                    styles.deleteButton
                  }`}
                >
                  <CloseButton
                    size={22}
                    onClose={() => deleteImage(image.id)}
                  />
                </Div>
              )}
            {onSelect && (
              <div
                id={`select_photo_${index}`}
                className={styles.backgroundImage}
                style={backgroundImage}
                onClick={() => {
                  setActiveElementState([index, false])
                  onSelect(image)
                }}
                onTouchStart={() => onTouchStart(index)}
                onTouchEnd={() => onTouchEnd()}
              >
                <Image
                  isActive={true}
                  width="100%"
                  height="100%"
                  image={{ url: mobileBackgroundImage }}
                />
                {isDeleting && (
                  <div style={{ display: 'flex' }}>
                    <CircularProgress />
                  </div>
                )}
              </div>
            )}
          </Div>
        )
      })}
      {hasMore && (
        <Div
          ref={triggerElement}
          width={applyStyles('85px', '140px')}
          height={applyStyles('75px', '120px')}
          display="inline-block"
          outset="x1"
        >
          <Div
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
            position="relative"
          >
            <Div
              width="100%"
              height="100%"
              position="absolute"
              display={!isMobile ? 'flex' : undefined}
              alignItems="center"
              justifyContent="center"
              top={isMobile ? 'calc(25% + 0.5rem)' : undefined}
            >
              <CircularProgress />
            </Div>
          </Div>
        </Div>
      )}
    </div>
  )
}
export default Images
