import {
  Div,
  Flex,
  Icon,
  Image,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import React from 'react'
import Price from 'src/chrome/Price/Price'
import { useSelector, useState } from 'src/hooks'
import { GiftLineItemCallbacksType, GiftLineItemProps } from '../GiftLineItem'
import { Button } from 'src/design_system/molecules/button/Button'
import { Icon as DSIcon } from 'src/design_system/atoms/icons/components/Icon'

type MobileGiftLineItemProps = GiftLineItemProps & GiftLineItemCallbacksType

const MobileGiftLineItem: React.FC<MobileGiftLineItemProps> = props => {
  const { giftItem, onChangeGift, onRemoveGift, lineIndex, quantity } = props

  const [isHovering, setIsHovering] = useState(false)

  const { width } = useSelector(state => state.window)
  const isMobile = width <= 542
  const isSmallMobile = width <= 418
  const typeTitle = isMobile ? 'body' : 'largeBody'

  const handleTruncateTitle = (title?: string) => {
    const MAX_TITLE_LENGTH = 17
    if (!title) return

    return title.length >= MAX_TITLE_LENGTH && isSmallMobile
      ? title.substring(0, MAX_TITLE_LENGTH) + '...'
      : title
  }

  return (
    <Flex width="100%">
      {quantity > 1 && (
        <Div
          position="absolute"
          zIndex={1}
          borderRadius="circle"
          width="fit-content"
          height="fit-content"
          backgroundColor="black"
          left={'158px'}
          inset={{ vertical: 'x_25', horizontal: 'x_5' }}
        >
          <Text
            type="footnote"
            content={`x${quantity}`}
            color="inverseHeading"
            alignment="center"
          />
        </Div>
      )}
      <Div display="flex" alignItems="center" justifyContent="flex-start">
        <Image
          width="120px"
          height="168px"
          image={{ url: giftItem?.thumbnailURL ?? '' }}
          isActive={true}
        />
      </Div>
      <Spacer space="x2_5" orientation="horizontal" />
      <Flex width="100%" flexWrap={'wrap'}>
        <Flex
          columnGap="x1_5"
          rowGap={isSmallMobile ? 'x1' : 'x0'}
          width="100%"
          justifyContent="flex-end"
          flexDirection={isSmallMobile ? 'column' : undefined}
        >
          <Button
            id={`change_gift_btn_${lineIndex}`}
            title={
              width <= 390
                ? undefined
                : {
                    content: 'Swap Gift',
                    fontSize: '.75rem',
                    fontColor: '#fff',
                  }
            }
            onClick={onChangeGift}
            padding="5px 10px"
            fill="#c68de8"
            borderRadius="default"
            gap="x_5"
            isFullWidth={isSmallMobile}
          >
            <DSIcon name="replace" size={20} color="#fff" />
          </Button>
          <Div
            cursor="pointer"
            display="flex"
            justifyContent="center"
            alignItems="center"
            width={isSmallMobile ? 'unset' : '32px'}
            height="32px"
            borderColor={isHovering ? '#f26464' : '#c2c2c2'}
            borderWidth="default"
            borderStyle="solid"
            borderRadius="12px"
            inset={isSmallMobile ? { horizontal: 'x2' } : 'x0'}
            onClick={onRemoveGift}
            onHoverStart={() => setIsHovering(true)}
            onHoverEnd={() => setIsHovering(false)}
          >
            <Icon
              name="delete"
              primaryColor={isHovering ? '#f26464' : '#c2c2c2'}
              size={20}
            />
          </Div>
        </Flex>
        <Flex
          flexDirection="column"
          width="100%"
          inset={{ top: 'x1' }}
          id={`gift_item_${lineIndex}_${giftItem?.title}`}
        >
          <Text
            type={typeTitle}
            weight="bold"
            color="primaryHeading"
            content={handleTruncateTitle(giftItem?.title)}
            lineHeight={1.5}
            whiteSpace="normal"
          />
          <Div>
            <Price
              price={giftItem?.price.price ?? ''}
              discountPrice={giftItem?.price.discountPrice}
              discountMessage={giftItem?.price.discountMessage}
              direction="row"
            />
          </Div>
        </Flex>
      </Flex>
    </Flex>
  )
}
export default MobileGiftLineItem
