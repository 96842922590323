import React from 'react'

import { Button } from 'src/chrome'

interface EmptyStateProps {
  action: () => void
  actionTitle: string
  message: string
  readonly artWorkWidth?: number | string
  readonly artWorkMaxWidth?: number | string
}

const EmptyState: React.FC<EmptyStateProps> = props => {
  const {
    action,
    actionTitle,
    children,
    message,
    artWorkWidth = 400,
    artWorkMaxWidth = 1000,
  } = props
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        height: '100vh',
        width: '100%',
        zIndex: 1000,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'rgb(245, 245, 245)',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          padding: 25,
          alignItems: 'center',
          width: '100%',
        }}
      >
        <div
          style={{
            marginBottom: 50,
            width: artWorkWidth,
            maxWidth: artWorkMaxWidth,
          }}
        >
          {children}
        </div>
        <p
          style={{
            fontSize: 18,
            marginBottom: 50,
          }}
        >
          {message}
        </p>
        <Button
          title={actionTitle}
          onClick={action}
          style={{ maxWidth: 200 }}
        />
      </div>
    </div>
  )
}

export default EmptyState
