import React, { FC } from 'react'

import {
  AccountFragment,
  Amount,
  Currency,
  CurrencyType,
} from 'src/graphql/generated/graphql'
import { PurchaseTypes } from 'src/payments/containers/Payment'
import { creditCardNeeded } from 'src/helpers'
import { OrderWithPartialLines } from 'src/redux/reducers/orders'
import { useAccount } from 'src/hooks'
import HoverTooltip from 'src/chrome/HoverTooltip/HoverTooltip'
import { Button, Flex } from '@sendoutcards/quantum-design-ui'

interface BuyButtonProps {
  account: Pick<
    AccountFragment,
    'stripeSource' | 'shippingAddress' | 'points' | 'expense'
  >
  onPurchase: () => void
  order: OrderWithPartialLines | undefined
  purchaseType?: PurchaseTypes
  credits?: Amount
  isUsingCredits?: true
}

const BuyButton: FC<BuyButtonProps> = props => {
  const {
    purchaseType,
    account,
    onPurchase: purchase,
    order,
    credits: referralCredits,
    isUsingCredits,
  } = props
  const isNewUser = useAccount().username === ''

  const hasCardOnFile = !!account.stripeSource

  const isOrder = purchaseType === PurchaseTypes.ORDER

  const isBuyingAffiliate = purchaseType === PurchaseTypes.DISTRIBUTOR

  const isBuyingPlan = purchaseType === PurchaseTypes.PLAN

  const isAddressEmpty =
    !account.shippingAddress || !account.shippingAddress.address1

  const credits = referralCredits ?? {
    amount: 0,
    currency: Currency.Unavailable,
    description: 'Unavailable',
    __typename: 'Amount',
  }

  const isCreditCardNeeded =
    isOrder &&
    !!order &&
    creditCardNeeded(order, account, credits, !!isUsingCredits)

  const isWrongCurrency = () => {
    if (isOrder && !!order && order.isBulk) {
      for (const { currency } of order.cost.total) {
        if (
          currency === CurrencyType.Point ||
          (currency === CurrencyType.Usd && isUsingCredits)
        ) {
          return true
        }
      }
    }
    return false
  }

  const isDisabled: boolean =
    ((isBuyingAffiliate && !hasCardOnFile) ||
      (isBuyingPlan && !hasCardOnFile) ||
      (!isBuyingAffiliate && isAddressEmpty) ||
      isCreditCardNeeded ||
      isWrongCurrency()) &&
    !isNewUser

  return (
    <div
      style={
        {
          marginLeft: 'auto',
          display: 'flex',
          alignItems: 'center',
        } as React.CSSProperties
      }
    >
      {isAddressEmpty && !isBuyingAffiliate && (
        <span
          style={
            {
              marginRight: '20px',
              color: 'red',
              cursor: 'initial',
            } as React.CSSProperties
          }
        >
          Return Address Required
        </span>
      )}
      <HoverTooltip
        title="Credit Card Required"
        shouldDisplayTooltip={isCreditCardNeeded}
        tooltipDirection="bottom"
        background="dark"
      >
        <Flex outset={{ left: 'x2', right: 'x4' }}>
          <Button
            id={'send_out_card_btn'}
            title={isOrder ? 'Confirm and Send' : 'Buy'}
            type="success"
            disabled={isDisabled}
            onClick={isDisabled ? () => {} : purchase}
          />
        </Flex>
      </HoverTooltip>
    </div>
  )
}

export default BuyButton
