import { useActions, useEffect } from '.'

const useAppcues = (tourId: string | undefined) => {
  const actions = useActions()

  useEffect(() => {
    if (window.Appcues && tourId) {
      actions.setTourId(tourId)
      window.Appcues.start()
    }

    return () => {
      actions.setTourId(undefined)
    }
  }, [actions, tourId])
}

export default useAppcues
