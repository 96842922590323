import React from 'react'
import { Div, Spacer, Text } from '@sendoutcards/quantum-design-ui'

import { Icon } from 'src/chrome'
import { Icons } from 'src/chrome/Icon/icons'

import styles from './contentSplitter.module.scss'

type PropsIcon = {
  size: number
  viewBox: string
  color: string
  icon: Icons
}

type ContentSplitterProps = {
  title: string
  description: string
  icon: PropsIcon
}

const ContentSplitter: React.FC<ContentSplitterProps> = props => {
  const { description, icon, title } = props
  return (
    <Div className={styles.splitter}>
      <Div className={styles.icon}>
        <Icon
          icon={icon.icon}
          size={icon.size}
          viewBox={icon.viewBox}
          color={icon.color}
        />
      </Div>
      <Spacer space="x3" orientation="horizontal" />
      <Text type="body" weight="bold" color="primaryHeading">
        {title}
      </Text>
      <Spacer space="x1" orientation="horizontal" />
      <Text type="body">—</Text>
      <Spacer space="x1" orientation="horizontal" />
      <Text type="body">{description}</Text>
    </Div>
  )
}

export default ContentSplitter
