import { Operation } from '../../../__generated__/globalTypes'

import {
  UserImageFragment,
  userImageFragmentString,
} from '../../fragments/__generated__/UserImageFragment'

import { imageFragmentString } from '../../fragments/__generated__/ImageFragment'

import uploadImageRawString from '../UploadImage.graphql'

const uploadImageString = [
  uploadImageRawString,
  userImageFragmentString,
  imageFragmentString,
].join('\n\n')

export type UploadImageData = {
  uploadImage: {
    image: UserImageFragment
  }
}

export type UploadImage = Operation<UploadImageData>

export const UploadImage = (): UploadImage => ({
  query: uploadImageString,
  variables: undefined,
})
