import React from 'react'
import { useQueries } from 'src/hooks'
import { getFeaturedCards } from 'src/legacy_graphql'
import { Grid, GridItem, Image } from '@sendoutcards/quantum-design-ui'

const FeaturedCardsGrid = () => {
  const [featuredCards] = useQueries(getFeaturedCards())

  return (
    <Grid height="100%" columnSize={49} rowSize={35} gap={12}>
      {[...featuredCards, ...featuredCards, ...featuredCards].map(
        (card, index) => (
          <GridItem
            gridColSpan={2}
            gridRowSpan={card.isHorizontal ? 2 : 4}
            borderRadius={'medium'}
            key={index}
          >
            <Image
              image={{ url: card.frontImage.smallThumb }}
              width={'100%'}
              height={'100%'}
              isActive={true}
              borderRadius={'none'}
            />
          </GridItem>
        ),
      )}
    </Grid>
  )
}

export default FeaturedCardsGrid
