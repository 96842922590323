import React from 'react'
import TextField from '@material-ui/core/TextField'

import { Icon } from 'src/chrome'
import { useMutations, useState } from 'src/hooks'
import { FormModal } from 'src/campaigns/components'
import { Spacer, Text } from '@sendoutcards/quantum-design-ui'
import { cssStyles } from 'src/styled'

const styles = cssStyles({
  header: {
    position: 'sticky',
    top: 0,
    height: '60px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    background: '#FAFAFA',
    boxShadow: '0 2px 28px -2px #dedede',
    zIndex: 105,
  },
  innerContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '2rem',
    '@media (max-width: 600px)': {
      margin: '0 auto',
      padding: 0,
      justifyContent: 'space-between',
      width: 'calc(100% - 20px)',
    },
  },
  title: {
    paddingRight: '1rem',
  },
  editIcon: {
    cursor: 'pointer',
  },
})

type CampaignHeaderProps = {
  campaignId: string
  name: string
}

const CampaignHeader: React.FC<CampaignHeaderProps> = props => {
  const { campaignId, name } = props

  const mutations = useMutations()

  const [editableCampaignName, setEditableCampaignName] = useState(name)
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setEditableCampaignName(event.currentTarget.value)

  const handleRenameCancel = () => {
    setIsRenameModalOpen(!isRenameModalOpen)
    setEditableCampaignName(name)
  }

  const handleRenameSubmit = async () => {
    setIsRenameModalOpen(!isRenameModalOpen)
    await mutations.updateCampaign({
      campaign: {
        id: campaignId,
        name: editableCampaignName,
      },
    })
  }

  return (
    <>
      <header css={styles.header}>
        <div css={styles.innerContainer}>
          <Text type="largeBody" color="primaryHeading">
            Campaign:
          </Text>
          <Spacer space="x1" orientation="horizontal" />
          <span id={'campaign_name'}>
            <Text type="largeBody" weight="bold" color="primaryHeading">
              {name}
            </Text>
          </span>
          <Spacer space="x1" orientation="horizontal" />
          <span
            css={styles.editIcon}
            id={'edit_campaign_name_btn'}
            onClick={() => setIsRenameModalOpen(!isRenameModalOpen)}
          >
            <Icon icon={'EDIT'} color={'#333'} />
          </span>
        </div>
      </header>
      <FormModal
        title="Rename Campaign"
        isOpen={isRenameModalOpen}
        onClose={handleRenameCancel}
        onSubmit={handleRenameSubmit}
      >
        <TextField
          name={'campaignName'}
          value={editableCampaignName}
          placeholder={'Campaign Name'}
          fullWidth={true}
          onChange={handleChange}
          style={{ marginRight: 15 }}
        />
      </FormModal>
    </>
  )
}

export default CampaignHeader
