import React, { FC } from 'react'
import { SVGProps } from './types'

export const Pin: FC<SVGProps> = ({ size, color, onClick }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 5a3.333 3.333 0 1 0 0 6.667A3.333 3.333 0 0 0 10 5M8.332 8.333a1.667 1.667 0 1 1 3.333 0 1.667 1.667 0 0 1-3.333 0"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0C5.414 0 1.665 3.639 1.665 8.167c0 3.055 1.83 5.999 3.639 8.105a21 21 0 0 0 2.646 2.597c.39.315.748.575 1.05.76.15.093.3.175.441.237.115.05.323.134.557.134s.443-.083.557-.134c.142-.062.291-.144.441-.236.302-.186.661-.446 1.05-.761a21 21 0 0 0 2.647-2.597c1.808-2.106 3.639-5.05 3.639-8.105C18.333 3.639 14.584 0 9.999 0M3.332 8.167c0-3.573 2.967-6.5 6.666-6.5s6.667 2.927 6.667 6.5c0 2.444-1.503 5-3.236 7.02a19.5 19.5 0 0 1-2.432 2.387c-.353.286-.651.5-.874.636l-.125.073-.125-.073A9 9 0 0 1 9 17.574a19.5 19.5 0 0 1-2.432-2.388c-1.733-2.018-3.236-4.575-3.236-7.02"
        fill={color}
      />
    </svg>
  )
}
