import {
  UpdateCampaignInput,
  Operation,
} from '../../../__generated__/globalTypes'

import {
  CampaignFragment,
  campaignFragmentString,
} from '../../fragments/__generated__/CampaignFragment'

import { sendDelayFragmentString } from '../../fragments/__generated__/SendDelayFragment'

import { campaignLineFragmentString } from '../../fragments/__generated__/CampaignLineFragment'

import { minimalPanelFragmentString } from '../../fragments/__generated__/MinimalPanelFragment'

import { giftVariationFragmentString } from '../../fragments/__generated__/GiftVariationFragment'

import { amountFragmentString } from '../../fragments/__generated__/AmountFragment'

import { priceFragmentString } from '../../fragments/__generated__/PriceFragment'

import updateCampaignRawString from '../UpdateCampaign.graphql'

const updateCampaignString = [
  updateCampaignRawString,
  campaignFragmentString,
  sendDelayFragmentString,
  campaignLineFragmentString,
  minimalPanelFragmentString,
  giftVariationFragmentString,
  amountFragmentString,
  priceFragmentString,
].join('\n\n')

export type UpdateCampaignData = {
  updateCampaign: {
    campaign: CampaignFragment
  }
}

export type UpdateCampaignVariables = {
  campaign: UpdateCampaignInput
}

export type UpdateCampaign = Operation<
  UpdateCampaignData,
  UpdateCampaignVariables
>

export const UpdateCampaign = ({
  campaign,
}: UpdateCampaignVariables): UpdateCampaign => ({
  query: updateCampaignString,
  variables: {
    campaign: UpdateCampaignInput(campaign),
  },
})
