import { Flex, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import AddItemToLine from 'src/chrome/AddItemToLine/AddItemToLine'
import CardLineItem, {
  CardLineItemCallbacksType,
} from 'src/orders/components/CardLineItem/CardLineItem'
import { PartialLine } from 'src/helpers/multitouch'
import GiftLineItem, {
  GiftLineItemCallbacksType,
} from '../GiftLineItem/GiftLineItem'
import { useSelector } from 'src/hooks'
import { OrderWithPartialLines } from 'src/redux/reducers/orders'
import { RemoveButton } from '../RemoveButton/RemoveButton'
import useOrderLinePricing from 'src/hooks/useOrderLinePricing'
import useDidFlattenFail from 'src/orders/hooks/useDidFlattenFail'

export interface OrderItemProps {
  orderLine?: PartialLine
  cardItem: CardLineItemCallbacksType
  giftItem: GiftLineItemCallbacksType
  title: string
  lineIndex: number
  onAddCard?: () => void
  onAddGift?: () => void
  onRemoveLineAlert?: (event?: React.MouseEvent, line?: PartialLine) => void
  order?: OrderWithPartialLines
  id?: string
  shouldNotAllowRemoval: boolean
}

const OrderItem: React.FC<OrderItemProps> = props => {
  const {
    orderLine,
    lineIndex,
    cardItem,
    giftItem,
    title,
    onAddCard,
    onAddGift,
    onRemoveLineAlert,
    order,
    id,
  } = props

  const { width } = useSelector(state => state.window)
  const isSmallMobile = width <= 440
  const { isMobile } = useSelector(state => state.window)

  const quantity = orderLine?.recipientCount ?? 0

  const orderLinePricing = useOrderLinePricing(order?.sendType, orderLine)

  const { didFlattenFail } = useDidFlattenFail(
    orderLine && orderLine.card ? orderLine.card : undefined,
  )

  return (
    <Flex
      flexDirection="column"
      backgroundColor="foreground"
      boxShadow="mediumLight"
      inset="x2"
      outset="x2"
      borderRadius="24px"
      id={id}
    >
      <Flex
        width="100%"
        inset={{ left: 'x_5' }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Text
          type={isSmallMobile ? 'largeBody' : 'subtitle'}
          color="primaryHeading"
          content={title}
          weight="bold"
          whiteSpace="nowrap"
        />
        <RemoveButton
          title={'Remove Card & Gift(s)'}
          isMobile={isMobile}
          onClick={onRemoveLineAlert}
          color="#E7515D"
          size={13}
        />
      </Flex>
      <Spacer orientation="vertical" space="x2" />
      {orderLine && orderLine.card ? (
        <CardLineItem
          quantity={quantity}
          lineIndex={lineIndex}
          card={orderLine.card}
          sendDelay={orderLine.sendDelay}
          cost={{
            price: orderLinePricing.cardPrice,
            discountPrice: orderLinePricing.cardDiscountedPrice,
            discountMessage: orderLinePricing.cardDiscountMessage,
          }}
          onEditCard={cardItem.onEditCard}
          onUpdatePaperType={cardItem.onUpdatePaperType}
          onToggleSendDelay={cardItem.onToggleSendDelay}
          onRemoveCard={cardItem.onRemoveCard}
          onReplaceCard={cardItem.onReplaceCard}
          order={order}
          didFlattenFail={didFlattenFail}
        />
      ) : (
        <AddItemToLine
          itemType="card"
          message={'No Attached Card'}
          isInvalidOrder={!orderLine?.card}
          action={{
            type: 'withAction',
            onClick: onAddCard,
            title: 'Add Card',
          }}
        />
      )}
      <Spacer orientation="vertical" space="x2" />
      {orderLine && orderLine.giftVariation ? (
        <GiftLineItem
          quantity={quantity}
          lineIndex={lineIndex}
          giftItem={{
            title: orderLine.giftVariation.name,
            thumbnailURL: orderLine.giftVariation.defaultImageUrl ?? '',
            isSendable: orderLine.giftVariation.isSendable,
            price: {
              price: orderLinePricing.giftPrice,
              discountPrice: orderLinePricing.giftDiscountPrice,
              discountMessage: orderLinePricing.giftDiscountMessage,
            },
          }}
          onRemoveGift={giftItem.onRemoveGift}
          onChangeGift={giftItem.onChangeGift}
        />
      ) : (
        <AddItemToLine
          itemType="gift"
          message={'Add a Gift'}
          action={{
            type: 'withAction',
            onClick: onAddGift,
            title: 'Browse Gifts',
            id: `add_a_gift_btn_${lineIndex}`,
          }}
          isDisabled={order?.isBulk || orderLine?.card?.type === 'POSTCARD'}
          disabledMessage={
            order?.isBulk
              ? 'Gifts are not available for a bulk card order.'
              : 'Gifts are not available for Postcard orders'
          }
        />
      )}
    </Flex>
  )
}

export default OrderItem
