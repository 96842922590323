import React, { FC } from 'react'
import {
  Accordion,
  Anchor,
  Button,
  ComparisonIncludesList,
  ConfigurationCard,
  Div,
  Flex,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { useSelector, useState } from 'src/hooks'
import { PlansDescription } from 'src/marketing/plans/types'
import planContentStyles from 'src/marketing/plans/components/PlanContentsGrid/planContentsGrid.module.scss'

const styles = {
  planCopy: {
    maxWidth: '1050px',
    textAlign: 'center' as const,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}

export type AcceptSelectedOptionType = {
  title: string
  onClick: () => void
}

export type PlanAddonsProps = {
  onSelect: (plan: string) => void
  planAddonDescriptions: PlansDescription[]
}

export const PlanAddons: FC<PlanAddonsProps> = ({
  onSelect,
  planAddonDescriptions,
}) => {
  const isMobile = useSelector(state => state.window.isMobile)

  const [activeOption, setActiveOption] = useState<string | undefined>(
    planAddonDescriptions[0]?.id,
  )
  const activeOptionDescription = planAddonDescriptions.find(
    option => option.id === activeOption,
  )?.description

  return (
    <Div width="100%">
      {planAddonDescriptions.length > 0 && (
        <Div className={planContentStyles.featureGridContainer}>
          <Div className={planContentStyles.featureGrid}>
            <Div
              css={styles.planCopy}
              outset={{ vertical: 'x2' }}
              alignItems="center"
            >
              <Text type="title" alignment="center">
                Expand your reach with
              </Text>
              <Text type="title" alignment="center" color="primaryBrand">
                Card Packages.
              </Text>
              <Spacer space="x2" />
              <Text type="body" alignment="center">
                Having more options allows you to reach out in kindness to even
                more people! Purchase a card package for discounted card and
                gift sending without committing to a monthly subscription. Or
                add onto your subscriptions for the additional ways to send!
              </Text>
              <Spacer space="x4" />
            </Div>
            <Flex
              inset="x2"
              justifyContent="center"
              alignItems="flex-start"
              width="100%"
              flexWrap="wrap"
              overflowY="hidden"
              flexDirection={isMobile ? 'column' : 'row'}
            >
              <Flex
                flexWrap="wrap"
                overflowY="auto"
                width={isMobile ? '100%' : 'calc(50% - 12px)'}
                order={isMobile ? 1 : 3}
              >
                {planAddonDescriptions.map(option => {
                  const optionDescription = option.description
                  const title =
                    optionDescription.secondaryTitle !== undefined
                      ? `${optionDescription.plan} ${optionDescription.secondaryTitle}`
                      : optionDescription.plan
                  return (
                    <Div
                      position="relative"
                      width="100%"
                      outset={{ bottom: 'x2' }}
                      key={`configuration_card_div_${option.id}`}
                    >
                      <ConfigurationCard
                        title={title}
                        key={option.id}
                        type="upsale"
                        description={optionDescription.description}
                        configurationId={option.id}
                        onSelect={() => setActiveOption(option.id)}
                        isSelected={activeOption === option.id}
                        backgroundColor={'foreground'}
                      />
                    </Div>
                  )
                })}
              </Flex>
              {!isMobile && <Div width="x3" order={2} />}
              <Flex
                overflowY="auto"
                width={isMobile ? '100%' : 'calc(50% - 12px)'}
                justifyContent="flex-end"
                order={isMobile ? 3 : 1}
              >
                <Div width="100%" outset={{ bottom: 'x2' }}>
                  {activeOptionDescription?.details && (
                    <Accordion
                      defaultActiveKeys={[1]}
                      arrowLocation="right"
                      background="background"
                      inset="x0"
                      isExclusive={true}
                      sections={[
                        {
                          heading: (
                            <Div
                              width="100%"
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Text
                                weight="bold"
                                type="caption"
                                content="Card Package Includes"
                              />
                              <Div
                                width="34px"
                                height="34px"
                                borderRadius="small"
                                backgroundSize="cover"
                                backgroundPosition="center"
                                outset={{ right: 'x2' }}
                              />
                            </Div>
                          ),
                          body: (
                            <ComparisonIncludesList
                              items={activeOptionDescription.details.map(
                                detail => {
                                  const description =
                                    detail.innerDetails &&
                                    detail.innerDetails?.length > 1
                                      ? detail.innerDetails?.map(
                                          innerDetail => innerDetail.detail,
                                        )
                                      : detail.innerDetails?.[0].detail
                                  return {
                                    title: detail.detail,
                                    description,
                                    isIncluded: true,
                                  }
                                },
                              )}
                            />
                          ),
                          key: 1,
                        },
                      ]}
                    />
                  )}
                </Div>
              </Flex>
            </Flex>
          </Div>
        </Div>
      )}
      <Flex
        position={'relative'}
        alignItems="center"
        justifyContent="space-around"
        inset={{ bottom: 'x8' }}
        width="100%"
        backgroundColor="foreground"
        boxShadow={isMobile ? 'mediumDark' : undefined}
        zIndex={isMobile ? 10 : undefined}
      >
        <Anchor
          href="https://www.sendoutcards.com/resources/documents/559/Card_Package_Overview_Comparison_final_2.1.pdf"
          color="anchorBlue"
          isDecorated={true}
        >
          View the Comparison Overview
        </Anchor>
        <Button
          type={'primary'}
          size="large"
          title={
            activeOptionDescription?.purchaseButtonText ??
            'Continue to Checkout'
          }
          disabled={activeOption === undefined}
          onClick={() => {
            if (activeOption !== undefined) {
              onSelect(activeOption)
            }
          }}
        />
      </Flex>
    </Div>
  )
}

export default PlanAddons
