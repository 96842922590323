import React from 'react'
import { LoginForm } from 'src/user/components'
import CreateAccountForm from 'src/dashboard/components/CreateAccountForm'
import { useState, useVertical } from 'src/hooks'
import { Sheet } from '@sendoutcards/quantum-design-ui'
import FeaturedCardsGrid from 'src/catalog/components/FeaturedCardsGrid/FeaturedCardsGrid'

interface JoinModalProps {
  onAfterSave?: () => void
  onLogin?: () => void
  onClose?: () => void
  initialAction: LoginJoinForgot
  isJoiningAffiliate?: boolean
}

export type LoginJoinForgot = 'login' | 'join' | 'forgot'

const JoinModal: React.FC<JoinModalProps> = props => {
  const {
    initialAction,
    onAfterSave,
    onLogin,
    onClose,
    isJoiningAffiliate,
  } = props

  const vertical = useVertical()

  const [isJoining, setIsJoining] = useState(initialAction === 'join')

  const [didForgetPassword, setDidForgetPassword] = useState(false)

  const setIsOpen = onClose
    ? (isOpen: boolean) => !isOpen && onClose()
    : undefined

  return (
    <Sheet
      setIsOpen={setIsOpen}
      isOpen={true}
      backgroundElement={
        vertical.id === 'soc' ? (
          <FeaturedCardsGrid />
        ) : // TODO: Uncomment this when QDS fixes the bug that renders background component size incorrectly
        // vertical.backgroundSrc ? (
        //   <img
        //     src={vertical.backgroundSrc}
        //     alt={vertical.name}
        //     style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        //   />
        // ) :
        null
      }
    >
      <div style={{ height: '100%', width: '100%' }} id="join_modal_container">
        {isJoining ? (
          <CreateAccountForm
            onAfterSave={onAfterSave}
            onToggleJoinLogin={() => setIsJoining(!isJoining)}
            isAffiliateJoining={!!isJoiningAffiliate}
          />
        ) : (
          <LoginForm
            title="Welcome to SendOutCards"
            didForgetLoginInfo={didForgetPassword}
            setForgotPassword={setDidForgetPassword}
            onCreateAccount={() => setIsJoining(true)}
            isAffiliateJoining={!!isJoiningAffiliate}
            onComplete={onLogin}
          />
        )}
      </div>
    </Sheet>
  )
}

export default JoinModal
