import React, { FC } from 'react'
import { SVGProps } from './types'

export const DraftsIcon: FC<SVGProps> = ({
  size = 24,
  onClick,
  color = '#323232',
}) => {
  return (
    <svg
      height={size}
      width={size}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick && (() => onClick())}
    >
      <path
        d="M12 21.25H7C3.35 21.25 1.25 19.15 1.25 15.5V8.5C1.25 4.85 3.35 2.75 7 2.75H17C20.65 2.75 22.75 4.85 22.75 8.5V11.5C22.75 11.91 22.41 12.25 22 12.25C21.59 12.25 21.25 11.91 21.25 11.5V8.5C21.25 5.64 19.86 4.25 17 4.25H7C4.14 4.25 2.75 5.64 2.75 8.5V15.5C2.75 18.36 4.14 19.75 7 19.75H12C12.41 19.75 12.75 20.09 12.75 20.5C12.75 20.91 12.41 21.25 12 21.25Z"
        fill={color}
      />
      <path
        d="M11.9988 12.87C11.1588 12.87 10.3088 12.61 9.6588 12.08L6.5288 9.57996C6.2088 9.31996 6.14881 8.84997 6.4088 8.52997 6.66881 8.20997 7.13879 8.15997 7.45879 8.40997L10.5888 10.91C11.3488 11.52 12.6388 11.52 13.3988 10.91L16.5288 8.40997C16.8488 8.14997 17.3188 8.19997 17.5788 8.52997 17.8388 8.84997 17.7888 9.32996 17.4588 9.57996L14.3288 12.08C13.6888 12.61 12.8388 12.87 11.9988 12.87zM15.8196 21.7801C15.4396 21.7801 15.0796 21.6401 14.8196 21.3801 14.5096 21.0701 14.3696 20.6201 14.4396 20.1501L14.6296 18.8001C14.6796 18.4501 14.8896 18.0301 15.1396 17.7801L18.6796 14.2401C19.1596 13.7601 19.6296 13.5101 20.1396 13.4601 20.7596 13.4001 21.3796 13.6601 21.9596 14.2401 22.5396 14.8201 22.7996 15.4301 22.7396 16.0601 22.6896 16.5601 22.4296 17.0401 21.9596 17.5201L18.4196 21.0601C18.1696 21.3101 17.7496 21.5201 17.3996 21.5701L16.0495 21.7601C15.9695 21.7701 15.8996 21.7801 15.8196 21.7801zM20.3096 14.9501C20.2996 14.9501 20.2896 14.9501 20.2796 14.9501 20.1396 14.9601 19.9496 15.0901 19.7396 15.3001L16.1996 18.8401C16.1696 18.8701 16.1196 18.9701 16.1196 19.0101L15.9396 20.2601 17.1896 20.0801C17.2296 20.0701 17.3295 20.0201 17.3595 19.9901L20.8996 16.4501C21.1096 16.2301 21.2396 16.0501 21.2496 15.9101 21.2696 15.7101 21.0696 15.4701 20.8996 15.3001 20.7396 15.1401 20.5096 14.9501 20.3096 14.9501z"
        fill={color}
      />
      <path
        d="M20.9206 18.2499C20.8506 18.2499 20.7806 18.2399 20.7206 18.2199C19.4006 17.8499 18.3506 16.7999 17.9806 15.4799C17.8706 15.0799 18.1006 14.6699 18.5006 14.5499C18.9006 14.4399 19.3106 14.6699 19.4206 15.0699C19.6506 15.8899 20.3006 16.5399 21.1206 16.7699C21.5206 16.8799 21.7506 17.2999 21.6406 17.6999C21.5506 18.0299 21.2506 18.2499 20.9206 18.2499Z"
        fill={color}
      />
    </svg>
  )
}
