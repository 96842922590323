import { NormalizedData } from 'src/legacy_graphql'
import { OperationFactory, Query, QueryFactory } from './Query'
import { updatedSparseResults } from './PaginatedQuery'
import { loadMore } from 'src/redux/actions/graphql'

type InfiniteState<
  Result extends { __typename: keyof NormalizedData; id: string }
> = { results: Result[]; hasMore: boolean; shouldLoadMore: boolean }

type InfiniteView<
  Result extends { __typename: keyof NormalizedData; id: string }
> = {
  results: Result[]
  isLoadingMore: boolean
  hasMore: boolean
  loadMore: () => void
}

export const InfiniteQuery = <
  Data,
  Typename extends keyof NormalizedData,
  Result extends { __typename: Typename; id: string },
  Variables extends { offset: number; limit: number }
>({
  operation,
  mapData,
  pageSize,
  resultTypename,
  isValidResult,
  filter,
  orderBy,
}: {
  operation: OperationFactory<Data, Variables>
  mapData: (data: Data) => Result[]
  pageSize: number
  resultTypename: Typename
  isValidResult: (result: unknown) => result is Result
  filter: (
    result: Result,
    variables: Omit<Variables, 'offset' | 'limit'>,
  ) => boolean
  orderBy: (result: Result) => string
}): QueryFactory<
  InfiniteState<Result>,
  Variables,
  Omit<Variables, 'offset' | 'limit'>,
  InfiniteView<Result>
> => {
  return Query({
    operation,
    comapVariables: (
      variables: Omit<Variables, 'offset' | 'limit'>,
    ): Variables =>
      ({
        ...variables,
        offset: 0,
        limit: pageSize,
      } as Variables),
    mapResult: (result, offset, limit, previousState) =>
      result.map(mapData).map(results => ({
        results: previousState?.results.concat(results) ?? results,
        hasMore: results.length === limit,
        shouldLoadMore: false,
      })),
    updateState: (state, allChanges, variables) => {
      const { results } = updatedSparseResults(
        state.results,
        allChanges,
        variables,
        resultTypename,
        isValidResult,
        filter,
        orderBy,
      )

      return {
        ...state,
        results,
      }
    },
    loadMore: state => ({ ...state, shouldLoadMore: true }),
    mapState: (
      { results, hasMore, shouldLoadMore },
      offset = 0,
      limit = pageSize,
      operation,
      dispatch,
    ) => {
      return {
        results,
        isLoadingMore: hasMore && shouldLoadMore,
        hasMore,
        loadMore: () => dispatch(loadMore(operation)),
      }
    },
    additionalPagesToLoad: ({ results, hasMore, shouldLoadMore }) =>
      hasMore && shouldLoadMore
        ? [{ offset: results.length, limit: pageSize }]
        : [],
  })
}
