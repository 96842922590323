import {
  Flex,
  Icon as QIcon,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'

export type UpsaleTopBarProps = {
  onClose: () => void
  prevStepTitle?: string
  handlePrevStep?: () => void
  backgroundColor?: string
}
// TODO: Create Upsale top bar for navigating back or closing upsale
export const UpsaleTopBar = (props: UpsaleTopBarProps) => {
  const {
    onClose,
    prevStepTitle = 'Go Back',
    handlePrevStep,
    backgroundColor = '#FFFFFF',
  } = props

  return (
    <Flex
      flexDirection="row"
      width="100%"
      borderRadius="large"
      inset={{ vertical: 'x2', horizontal: 'x4' }}
      backgroundColor={backgroundColor}
    >
      {handlePrevStep && (
        <Flex
          flexDirection="row"
          alignItems="center"
          onClick={handlePrevStep}
          id="upsalePrevStepButton"
          cursor="pointer"
        >
          <QIcon
            name="leftChevron"
            size="small"
            primaryColor="primaryHeadingText"
          />
          <Spacer space="x_25" orientation="horizontal" />
          <Text
            color="primaryHeading"
            alignment="left"
            type="caption"
            weight="bold"
            content={prevStepTitle}
            lineHeight={1.1}
            style={{ borderBottom: '1px solid #000' }}
          />
        </Flex>
      )}

      <Flex
        outset={{ left: 'auto' }}
        alignItems="center"
        onClick={onClose}
        id="upsaleCloseButton"
        backgroundColor="#F3F4F6"
        inset="x1_5"
        borderRadius="small"
        cursor="pointer"
      >
        <Icon size={14} color={'black'} name="x" />
      </Flex>
    </Flex>
  )
}
