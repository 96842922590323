import React, { FC } from 'react'
import {
  Anchor,
  Button,
  Flex,
  LoadingSpinner,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import {
  SelectedItemCard,
  SelectedItemCardProps,
} from '../shared/SelectedItemCard'
import { TotalsTable } from './TotalsTable'
import { PaymentMethod } from 'src/upsale/components/checkout/payment/PaymentMethod'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import {
  useAccount,
  useActions,
  useEffect,
  useMutations,
  useState,
} from 'src/hooks'
import { PaymentMethodForm } from './payment/PaymentMethodForm'
import {
  ChangeSubscriptionMutationVariables,
  FullAccessFeatureType,
  PlanFragment,
  PurchaseIntent,
} from 'src/graphql/generated/graphql'
import { parseError } from 'src/utils/parseError'
import { PlanDiffDialog } from 'src/pricing_page/components/planDiff/PlanDiffDialog'
import { usePlanMove } from 'src/pricing_page/queries/hooks'
import { ProcessingPurchase } from './ProcessingPurchase'
import { useChangeSubscription } from 'src/react_query/mutations/hooks'
import {
  affiliateUpgradePlanIds,
  offeredAffiliateStripeSkus,
} from 'src/helpers/supportedUpsaleProducts'
import getRecurrence from 'src/helpers/getRecurrence'

export type UpsaleCheckoutProps = {
  title: string
  description: string
  selectedItem: PlanFragment
  actionTitle: string
  onPurchaseSuccess: (successMsg: string) => void
  onCancel: () => void
  featureAccessToGrant?: FullAccessFeatureType[]
}

export const UpsaleCheckout: FC<UpsaleCheckoutProps> = ({
  title,
  description,
  selectedItem,
  actionTitle,
  onPurchaseSuccess,
  onCancel,
  featureAccessToGrant,
}) => {
  // State values
  const [
    shouldShowEditPaymentMethod,
    setShouldShowEditPaymentMethod,
  ] = useState(false)
  const [shouldShowLoad, setShouldShowLoad] = useState(true)
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [isUpdatingAccount, setIsUpdatingAccount] = useState(false)
  const [isCommitingPurchase, setIsCommitingPurchase] = useState(false)
  const [isLoadingPIntent, setIsLoadingPIntent] = useState(false)
  const [pIntent, setPItent] = useState<PurchaseIntent | undefined>()

  // Hooks
  const account = useAccount()
  const mutations = useMutations()
  const { mutateAsync: changeSubscription } = useChangeSubscription()
  const actions = useActions()

  // Constants
  const paymentMethod = account.stripeSource
  const supportedAffiliatePlanIds = affiliateUpgradePlanIds

  // SelectedItem types
  const isMonthlySubscriptionItem = !selectedItem.isAddon
  const isTenCardChallengeItem = selectedItem.id === '122'
  const isAddonItem =
    selectedItem.isAddon &&
    !supportedAffiliatePlanIds.some(plan => plan.id === selectedItem.id)

  // Affiliate
  const isSelectedAffiliatePlanItem = supportedAffiliatePlanIds.some(
    idObj => idObj.id === selectedItem.id,
  )

  const currentAffiliateSubscription = account?.activePlansAndAddons?.find(
    plan =>
      offeredAffiliateStripeSkus.some(idObj => idObj.id === plan?.stripeId),
  )

  const isAffiliateSubscriptionChange =
    currentAffiliateSubscription && isSelectedAffiliatePlanItem

  // TODO: Revisit this in the future to see what is stopping us from accepting all addons
  const allowedAddonItemIds = ['122', '165', '112'] // 165: yearly affiliate, //112: monthly affiliate, //122: 10 card challenge

  // currentPlan ensures the proper plan information is displayed on the change confirmation screen.
  const currentPlan = isAffiliateSubscriptionChange
    ? currentAffiliateSubscription
    : account.plan

  // For some odd reason, the query keeps firing after some actions (like switch tabs, hiting the tab key, etc)
  // For now I will add a small blocker so this doesnt break the user flow but we need to revisit this soon

  const { isLoading: isPlanMoveIsLoading, data: planMoveData } = usePlanMove(
    Number(currentPlan.id),
    Number(selectedItem.id),
  )

  const isPaymentDisabled =
    isLoadingPIntent ||
    isCommitingPurchase ||
    !paymentMethod ||
    (isAddonItem &&
      !pIntent &&
      !allowedAddonItemIds.includes(selectedItem.id)) ||
    isUpdatingAccount

  const shouldShowChangePlanDialog =
    isMonthlySubscriptionItem &&
    planMoveData &&
    !planMoveData.planMove.isImmediate &&
    !isTenCardChallengeItem

  // Methods
  const handlePurchase = (isDowngrade: boolean) => {
    const isPlanChange =
      isAffiliateSubscriptionChange || isMonthlySubscriptionItem
    if (isPlanChange) {
      handleChangePlan(isDowngrade)
    } else handlePurchaseProduct(isDowngrade)
  }

  const updateAccountSubscription = async (
    changeSubscriptionArgs: ChangeSubscriptionMutationVariables,
    isDowngrade: boolean,
    error: (error: Error | string) => void = () => {},
  ) => {
    try {
      const {
        changeSubscription: { account: updatedAccount },
      } = await changeSubscription(changeSubscriptionArgs)
      actions.updatedAccount(
        featureAccessToGrant
          ? {
              ...updatedAccount,
              fullAccessFeatures: updatedAccount.fullAccessFeatures.concat(
                featureAccessToGrant,
              ),
            }
          : updatedAccount,
      )
      handlePlanChangeSuccess(isDowngrade)
    } catch (err) {
      error(parseError(err))
      setErrorMessage(
        err instanceof Error && err.message.split(': ')[1] !== undefined
          ? err.message.split(': ')[1]
          : 'Account failed to update. Contact Support for assistance.',
      )
    } finally {
      setIsUpdatingAccount(false)
    }
  }

  const handleChangePlan = (isDowngrade: boolean) => {
    const usersPlanSubscriptionInStripe = account.subscriptions.find(
      value => currentPlan.stripeId === value.sku,
    )
    if (!usersPlanSubscriptionInStripe) return // No Stripe Subscription match found from currentPlan.stripeId
    const changeSubscriptionData = {
      sku: selectedItem.stripeId,
      subscriptionId: usersPlanSubscriptionInStripe.id,
    }
    setIsUpdatingAccount(true)
    updateAccountSubscription(changeSubscriptionData, isDowngrade)
  }

  const handlePurchaseProduct = async (isDowngrade: boolean) => {
    if (pIntent) {
      setIsCommitingPurchase(true)
      try {
        const { commitPurchase } = await mutations.commitPurchase({
          purchaseIntentId: pIntent.id,
          doNotShowDefaultTransition: true,
        })

        // TODO: How should we update cache with the paid info?
        console.log('paid: ', commitPurchase.paid)
        handlePlanChangeSuccess(isDowngrade)
      } catch (error) {
        console.log(error)
      } finally {
        setIsCommitingPurchase(false)
      }
    }
  }

  const handlePlanChangeSuccess = (isDowngrade: boolean) => {
    const successMsg = isDowngrade
      ? 'You have successfully downgraded to'
      : 'Way to go! You have successfully upgraded to'
    onPurchaseSuccess(successMsg)
  }

  useEffect(() => {
    const createPurchaseIntent = async () => {
      try {
        setIsLoadingPIntent(true)
        const {
          createPurchase: { purchaseIntent },
        } = await mutations.createPurchase({
          itemsToPurchase: [{ itemCode: selectedItem.stripeId, quantity: 1 }],
          doNotShowDefaultTransition: true,
        })
        setPItent(purchaseIntent)
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoadingPIntent(false)
      }
    }
    if (isAddonItem && !isTenCardChallengeItem) createPurchaseIntent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isPlanMoveIsLoading && shouldShowLoad) {
      setShouldShowLoad(false)
    }
  }, [isPlanMoveIsLoading, shouldShowLoad])

  const currentPlanCardProps: SelectedItemCardProps = {
    id: currentPlan.id,
    title: currentPlan.title,
    price: currentPlan.price.description,
    backgroundColor: '#4ab4d8',
    icon: 'smile_card_w_heart',
    recurrence: getRecurrence(currentPlan.id, currentPlan.isAddon),
  }

  const newPlanCardProps: SelectedItemCardProps = {
    id: selectedItem.id,
    title: selectedItem.title,
    price: selectedItem.price.description,
    backgroundColor: '#D66DE0',
    icon: 'smile_card_w_heart',
    recurrence: getRecurrence(selectedItem.id, selectedItem.isAddon),
  }

  return (
    <>
      {shouldShowLoad && account.plan.id !== '1' ? (
        <Flex
          minWidth="300px"
          minHeight="300px"
          width="100%"
          height="calc(100% - 70px)"
          justifyContent="center"
          alignItems="center"
        >
          <LoadingSpinner size="xLarge" />
        </Flex>
      ) : shouldShowChangePlanDialog ? (
        <>
          <PlanDiffDialog
            title="You’ve chosen to change your subscription."
            description={
              planMoveData.planMove.type === 'DOWN'
                ? 'If you’re sure you want to downgrade, that’s okay! There are many great ways to share heartfelt kindness. Getting extra benefits and discounts on top of your promptings are just better! Click below to confirm the changes to your subscription and benefits.'
                : 'Review the information below and confirm your upgrade.'
            }
            currentPlan={currentPlanCardProps}
            newPlan={newPlanCardProps}
            action={{
              title: 'Yes, I confirm',
              onClick: () => {
                handlePurchase(planMoveData.planMove.type === 'DOWN')
              },
            }}
            onClose={onCancel}
          />
          {isUpdatingAccount && <ProcessingPurchase />}
        </>
      ) : (
        <Flex width="100%" flexDirection="column" inset="x2">
          <Flex
            position={shouldShowEditPaymentMethod ? 'absolute' : 'relative'}
            width="100%"
            flexDirection="column"
            left={shouldShowEditPaymentMethod ? '0' : undefined}
            bottom={shouldShowEditPaymentMethod ? '0' : undefined}
            top={shouldShowEditPaymentMethod ? '0' : undefined}
            overflow={shouldShowEditPaymentMethod ? 'hidden' : undefined}
          >
            <Flex flexDirection="column" justifyContent="flex-start">
              <Text
                content={title}
                type="largeBody"
                weight="extraBold"
                alignment="left"
                color="primaryHeading"
              />
              <Text content={description} type="body" alignment="left" />
            </Flex>
            <Spacer space="x4" />
            <Flex flexDirection="column">
              <Text
                content="Selected Product"
                type="largeBody"
                weight="bold"
                color="primaryHeading"
              />
              <Spacer space="x_75" />
              <SelectedItemCard
                id={selectedItem.id}
                icon="smile_card_w_heart"
                backgroundColor="#4AB4D8"
                title={selectedItem.title}
                price={selectedItem.price.description}
                recurrence={
                  isAddonItem
                    ? undefined
                    : getRecurrence(selectedItem.id, selectedItem.isAddon)
                }
              />
            </Flex>
            {/* Payment */}
            <Spacer space="x5" />
            <Flex flexDirection="row" alignItems="center">
              <Text
                content="Payment Method"
                type="largeBody"
                weight="bold"
                color="primaryHeading"
              />
              <Flex
                alignItems="center"
                style={{ marginLeft: 'auto', marginRight: '8px' }}
                onClick={() => setShouldShowEditPaymentMethod(true)}
                cursor="pointer"
              >
                <Icon name="edit" size={16} />
                <Spacer space="x1" orientation="horizontal" />
                <Text
                  type="caption"
                  weight="bold"
                  color="primaryHeading"
                  content="Edit"
                  style={{ textDecoration: 'underline' }}
                />
              </Flex>
            </Flex>
            <Flex flexDirection="column">
              <Spacer space="x2_5" />
              <PaymentMethod
                cardHolderName={`${account.firstName} ${account.lastName}`}
                paymentMethod={paymentMethod ?? undefined}
                handlePaymentMethod={() =>
                  setShouldShowEditPaymentMethod(!shouldShowEditPaymentMethod)
                }
              />
            </Flex>
            <Spacer space="x4" />
            <Flex flexDirection="column">
              {pIntent && (
                <>
                  <TotalsTable
                    subtotal={pIntent.subtotal}
                    taxTotal={pIntent.tax}
                    orderTotal={pIntent.total}
                  />
                  <Spacer space="x1" />
                </>
              )}
            </Flex>
            {errorMessage && (
              <Flex width="100%" alignItems="center" flexDirection="column">
                <Text type="largeBody" weight="bold" color="danger">
                  An error occurred
                </Text>
                <Spacer space="x1" />
                <Text type="body">{errorMessage}</Text>
                <Spacer space="x2_5" />
                <Button
                  type="secondary"
                  title="Dismiss"
                  onClick={() => setErrorMessage(undefined)}
                />
                <Spacer space="x3" />
              </Flex>
            )}
            <Flex width="100%" justifyContent={'end'} alignItems="center">
              <Flex alignItems="center">
                <Anchor title="cancel" isDecorated={true} onClick={onCancel} />
                <Spacer space="x4" orientation="horizontal" />
                <Button
                  title={actionTitle}
                  onClick={() => handlePurchase(false)}
                  backgroundColorOverride="#404040"
                  disabled={isPaymentDisabled}
                />
              </Flex>
            </Flex>
          </Flex>
          {isUpdatingAccount && <ProcessingPurchase />}
          {shouldShowEditPaymentMethod && (
            <PaymentMethodForm
              title="Add/Edit Payment"
              onDismissModal={() => {
                setShouldShowEditPaymentMethod(false)
              }}
            />
          )}
        </Flex>
      )}
    </>
  )
}
