import { Maybe } from '../../../__generated__/globalTypes'

import { AmountFragment, isAmountFragment } from './AmountFragment'

import { PriceFragment, isPriceFragment } from './PriceFragment'

import giftVariationFragmentRawString from '../GiftVariationFragment.graphql'

export const giftVariationFragmentString = giftVariationFragmentRawString

export type GiftVariationFragment = {
  __typename: 'GiftVariation'
  id: string
  isSendable: boolean
  isEligibleFreeGift: boolean
  name: string
  description: Maybe<string>
  contents: Maybe<string>
  shipping: Maybe<string>
  points: AmountFragment
  imageUrls: string[]
  packaging: Maybe<string>
  price: PriceFragment
  defaultImageUrl: string
}

export const isGiftVariationFragment = (
  fragment: any,
): fragment is GiftVariationFragment =>
  fragment &&
  fragment.__typename === 'GiftVariation' &&
  typeof fragment.id === 'string' &&
  typeof fragment.isSendable === 'boolean' &&
  typeof fragment.isEligibleFreeGift === 'boolean' &&
  typeof fragment.name === 'string' &&
  (fragment.description === null || typeof fragment.description === 'string') &&
  (fragment.contents === null || typeof fragment.contents === 'string') &&
  (fragment.shipping === null || typeof fragment.shipping === 'string') &&
  (isAmountFragment(fragment.points) as boolean) &&
  Array.isArray(fragment.imageUrls) &&
  fragment.imageUrls
    .slice(0, 5)
    .reduce(
      (accum: any, next: any) => accum && typeof next === 'string',
      true,
    ) &&
  (fragment.packaging === null || typeof fragment.packaging === 'string') &&
  (isPriceFragment(fragment.price) as boolean) &&
  typeof fragment.defaultImageUrl === 'string'
