import React from 'react'

import { days, months, years } from 'src/helpers/dates'
import { Icon } from 'src/chrome'
import { useEffect, useState } from 'src/hooks'

import styles from './dateDropdown.module.scss'
import { Div, Select, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import { OptionItems } from '../ContactForm/ContactForm'

type Props = {
  label?: string
  initialValue?: { day: number; month: number; year?: number | null } | null
  onChange: (
    date: { day: number; month: number; year?: number | null } | null,
  ) => void
  style?: React.CSSProperties
  fields?: {
    style?: {
      marginTop: number
    }
    floatingLabelFixed: boolean
    floatingLabelStyle: React.CSSProperties
  }
}

const DateDropdown: React.FC<Props> = props => {
  const { label, initialValue, onChange } = props

  const mutableOnChangeRef = React.useRef(onChange)
  mutableOnChangeRef.current = onChange

  const [value, setValue] = useState<{
    day: number | null
    month: number | null
    year?: number | null
  }>(initialValue || { day: null, month: null, year: null })

  const getIndex = (
    number: number | null | undefined,
    array: (number | null)[],
  ) => {
    if (array && number) {
      const index = array.indexOf(number)
      if (index >= 0) {
        return index
      }
    }
    return undefined
  }

  const handleChange = (key: 'day' | 'month' | 'year', value: string | null) =>
    setValue(x => ({ ...x, [key]: value != null ? parseInt(value, 10) : null }))

  const handleRemove = () => {
    setValue({ day: null, month: null, year: null })
  }

  useEffect(() => {
    const { day, month, year } = value
    if (day && month) {
      mutableOnChangeRef.current({ day, month, year })
    } else if (day == null && month == null && year == null) {
      mutableOnChangeRef.current(null)
    }
  }, [value])

  return (
    <div className={styles.container}>
      {label && <Text type="caption">{label}</Text>}
      <div style={{ display: 'flex' }}>
        <Div display="flex" flexDirection="column" flexGrow={1}>
          <Text type="caption">Month</Text>
          <Select
            selectedOptionIndex={value.month != null ? value.month : undefined}
            onChange={selectedOptionIndex => {
              handleChange('month', `${selectedOptionIndex}`)
            }}
            alignment="center"
            options={months.map((month, index) => {
              const optionItem: OptionItems = {
                value: `${index}`,
                label: month ? month : '',
              }
              return optionItem
            })}
            type="text"
            isDropDownAbove={true}
            dropDownMaxHeight="250px"
            selectHeight="50px"
          />
        </Div>
        <Spacer orientation="horizontal" space="x2" />
        <Div display="flex" flexDirection="column" flexGrow={1}>
          <Text type="caption">Day</Text>
          <Select
            selectedOptionIndex={getIndex(value.day, days(value.month))}
            onChange={selectedOptionIndex => {
              handleChange('day', `${selectedOptionIndex}`)
            }}
            alignment="center"
            options={
              days &&
              days(value.month).map((day, index) => {
                const optionItem: OptionItems = {
                  value: `${index}`,
                  label: day ? `${day}` : '',
                }
                return optionItem
              })
            }
            type="text"
            isDropDownAbove={true}
            dropDownMaxHeight="250px"
            selectHeight="50px"
          />
        </Div>
        <Spacer orientation="horizontal" space="x2" />
        <Div display="flex" flexDirection="column" flexGrow={1}>
          <Text type="caption">Year</Text>
          <Select
            selectedOptionIndex={getIndex(value.year, years)}
            onChange={selectedOptionIndex => {
              handleChange('year', `${years[selectedOptionIndex]}`)
            }}
            alignment="center"
            options={
              years &&
              years.map((year, index) => {
                const optionItem: OptionItems = {
                  value: `${index}`,
                  label: year ? `${year}` : '',
                }
                return optionItem
              })
            }
            type="text"
            isDropDownAbove={true}
            dropDownMaxHeight="250px"
            selectHeight="50px"
          />
        </Div>
        {value.day !== null && value.month !== null && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: 8,
              paddingTop: 20,
            }}
          >
            <Icon
              icon={'CLOSECIRCLE'}
              size={24}
              color={'#ff5689'}
              onClick={handleRemove}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default DateDropdown
