import {
  Maybe,
  PanelInput,
  Operation,
} from '../../../__generated__/globalTypes'

import {
  LayoutFragment,
  layoutFragmentString,
} from '../../fragments/__generated__/LayoutFragment'

import { imageFragmentString } from '../../fragments/__generated__/ImageFragment'

import { elementFragmentString } from '../../fragments/__generated__/ElementFragment'

import { textFragmentFragmentString } from '../../fragments/__generated__/TextFragmentFragment'

import { elementPictureFragmentString } from '../../fragments/__generated__/ElementPictureFragment'

import { colorFragmentString } from '../../fragments/__generated__/ColorFragment'

import { panelFragmentString } from '../../fragments/__generated__/PanelFragment'

import { elementTextFragmentString } from '../../fragments/__generated__/ElementTextFragment'

import { paragraphFragmentString } from '../../fragments/__generated__/ParagraphFragment'

import createOrUpdateBackPanelRawString from '../CreateOrUpdateBackPanel.graphql'

const createOrUpdateBackPanelString = [
  createOrUpdateBackPanelRawString,
  layoutFragmentString,
  imageFragmentString,
  elementFragmentString,
  textFragmentFragmentString,
  elementPictureFragmentString,
  colorFragmentString,
  panelFragmentString,
  elementTextFragmentString,
  paragraphFragmentString,
].join('\n\n')

export type CreateOrUpdateBackPanelData = {
  createOrUpdateBackPanel: {
    layout: Maybe<LayoutFragment>
  }
}

export type CreateOrUpdateBackPanelVariables = {
  panel: PanelInput
  layoutId: string
}

export type CreateOrUpdateBackPanel = Operation<
  CreateOrUpdateBackPanelData,
  CreateOrUpdateBackPanelVariables
>

export const CreateOrUpdateBackPanel = ({
  panel,
  layoutId,
}: CreateOrUpdateBackPanelVariables): CreateOrUpdateBackPanel => ({
  query: createOrUpdateBackPanelString,
  variables: {
    panel: PanelInput(panel),
    layoutId,
  },
})
