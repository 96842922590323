import { LayoutFragment, isLayoutFragment } from './LayoutFragment'

import { PanelFragment, isPanelFragment } from './PanelFragment'

import detailedLayoutFragmentRawString from '../DetailedLayoutFragment.graphql'

export const detailedLayoutFragmentString = detailedLayoutFragmentRawString

export type DetailedLayoutFragment = LayoutFragment & {
  panel: PanelFragment
}

export const isDetailedLayoutFragment = (
  fragment: any,
): fragment is DetailedLayoutFragment =>
  (isLayoutFragment(fragment) as boolean) &&
  (isPanelFragment(fragment.panel) as boolean)
