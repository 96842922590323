import { Operation } from '../../../__generated__/globalTypes'

import {
  CustomCardFragment,
  customCardFragmentString,
} from '../../fragments/__generated__/CustomCardFragment'

import getCustomCardsRawString from '../GetCustomCards.graphql'

const getCustomCardsString = [
  getCustomCardsRawString,
  customCardFragmentString,
].join('\n\n')

export type GetCustomCardsData = {
  cards: CustomCardFragment[]
}

export type GetCustomCardsVariables = {
  offset: number
  limit: number
}

export type GetCustomCards = Operation<
  GetCustomCardsData,
  GetCustomCardsVariables
>

export const GetCustomCards = ({
  offset,
  limit,
}: GetCustomCardsVariables): GetCustomCards => ({
  query: getCustomCardsString,
  variables: {
    offset,
    limit,
  },
})
