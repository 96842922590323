import { AccessLevel, Route } from 'src/routes/Route'
import { Subroute } from 'src/routes/Subroute'
import { CampaignStoreRoute } from 'src/campaign_store/routes/CampaignStoreRoute'
import { CreateCampaignRoute } from 'src/campaigns/routes/CreateCampaignRoute'
import { CampaignRoute } from 'src/campaigns/routes/CampaignRoute'
import { PurchasedCampaignsRoute } from './PurchasedCampaignsRoute'
import { MyCampaignsRoute } from './MyCampaignsRoute'

export const CampaignsSubroute = Subroute(
  CreateCampaignRoute,
  CampaignStoreRoute,
  MyCampaignsRoute,
  PurchasedCampaignsRoute,
  CampaignRoute,
)
export type CampaignsSubroute = Subroute<typeof CampaignsSubroute>

type CampaignsRouteContent = {
  subroute: CampaignsSubroute
}

export const CampaignsRoute = Route({
  path: '/campaigns',
  init: (
    subroute: CampaignsSubroute = MyCampaignsRoute(),
  ): CampaignsRouteContent => ({
    subroute,
  }),
  fromPath: (): CampaignsRouteContent => ({
    subroute: MyCampaignsRoute(),
  }),
  subroute: CampaignsSubroute,
  subrouteIsRequired: true,
  accessLevel: AccessLevel.PRIVATE,
})
export type CampaignsRoute = Route<typeof CampaignsRoute>
