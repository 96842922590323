import React from 'react'
import {
  Div,
  Icon as QDSIcon,
  LoadingSpinner,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { useCallback, useState } from 'src/hooks'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'

interface CardPreviewProps {
  panelUrls: string[]
  isHorizontal: boolean
  onEdit?: () => void
  onView?: () => void
  width?: number
  height?: number
  didFlattenFail?: boolean
}

const CardPreview: React.FC<CardPreviewProps> = props => {
  const { panelUrls, isHorizontal, onEdit, onView, didFlattenFail } = props
  const [didLoad, setDidLoad] = useState(false)

  const width = props.width ?? (isHorizontal ? 168 : 120)
  const height = props.height ?? (isHorizontal ? 120 : 168)

  const wrapperWidth = width + 10
  const wrapperHeight = height + 10

  const onLoad = useCallback(() => {
    setDidLoad(true)
  }, [setDidLoad])

  return (
    <Div
      position="relative"
      display="flex"
      justifyContent="center"
      alignItems="center"
      width={`${wrapperWidth}px`}
      height={`${wrapperHeight}px`}
      borderRadius="16px"
      backgroundColor={didFlattenFail ? '#D83120' : 'background'}
    >
      {didFlattenFail ? (
        <Div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 'calc(100% - 12px)',
            height: 'calc(100% - 12px)',
            justifyContent: 'space-between',
            borderRadius: '12px',
            backgroundColor: 'white',
            padding: '8px',
          }}
        >
          <div />

          <Icon name="card_error" color="#D83120" size={32} />

          <Div
            onClick={onView}
            backgroundColor="foreground"
            inset={{ horizontal: 'x1_5', vertical: 'x_5' }}
            borderRadius="circle"
            display="flex"
            alignItems="center"
            justifyContent="center"
            cursor="pointer"
            whileHover={{ y: -2 }}
            style={{ boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.08)' }}
          >
            <Div
              id="order_preview_view_edit"
              display="flex"
              style={{ transform: 'rotate(45deg)' }}
            >
              <QDSIcon
                name="drag"
                size="xSmall"
                primaryColor="primaryHeadingText"
              />
            </Div>
            <Spacer space="x_25" orientation="horizontal" />
            <Text type="footnote" color="primaryHeading" content="Edit Card" />
          </Div>
        </Div>
      ) : (
        <>
          <img
            src={panelUrls[0]}
            alt="Card Preview"
            onLoad={() => onLoad()}
            width={width}
            height={height}
            style={didLoad ? { borderRadius: '12px' } : { display: 'none' }}
          />
          {didLoad ? (
            <>
              <Div
                onClick={onView}
                position="absolute"
                bottom="x2"
                backgroundColor="foreground"
                inset={{ horizontal: 'x1_5', vertical: 'x_5' }}
                borderRadius="circle"
                display="flex"
                alignItems="center"
                justifyContent="center"
                cursor="pointer"
                whileHover={{ y: -2 }}
              >
                <Div
                  id="order_preview_view_edit"
                  display="flex"
                  style={{ transform: 'rotate(45deg)' }}
                >
                  <QDSIcon
                    name="drag"
                    size="xSmall"
                    primaryColor="primaryHeadingText"
                  />
                </Div>
                <Spacer space="x_25" orientation="horizontal" />
                <Text
                  type="footnote"
                  color="primaryHeading"
                  content="View/Edit"
                />
              </Div>
              <Div onClick={onEdit} />
            </>
          ) : (
            <LoadingSpinner size="medium" />
          )}
        </>
      )}
    </Div>
  )
}

export default CardPreview
