import { Maybe } from '../../../__generated__/globalTypes'

import { ProspectFragment, isProspectFragment } from './ProspectFragment'

import {
  SocProContactFragment,
  isSocProContactFragment,
} from './SocProContactFragment'

import {
  DetailedContactFragment,
  isDetailedContactFragment,
} from './DetailedContactFragment'

import inviteFragmentRawString from '../InviteFragment.graphql'

export const inviteFragmentString = inviteFragmentRawString

export type InviteFragment = {
  __typename: 'Invite'
  id: string
  createdAt: string
  prospect: Maybe<ProspectFragment>
  socProContact: Maybe<SocProContactFragment>
  contact: Maybe<DetailedContactFragment>
  utmStream: Maybe<string>
  hasProspectJoined: boolean
  hasSentCard: boolean
}

export const isInviteFragment = (fragment: any): fragment is InviteFragment =>
  fragment &&
  fragment.__typename === 'Invite' &&
  typeof fragment.id === 'string' &&
  typeof fragment.createdAt === 'string' &&
  (fragment.prospect === null ||
    (isProspectFragment(fragment.prospect) as boolean)) &&
  (fragment.socProContact === null ||
    (isSocProContactFragment(fragment.socProContact) as boolean)) &&
  (fragment.contact === null ||
    (isDetailedContactFragment(fragment.contact) as boolean)) &&
  (fragment.utmStream === null || typeof fragment.utmStream === 'string') &&
  typeof fragment.hasProspectJoined === 'boolean' &&
  typeof fragment.hasSentCard === 'boolean'
