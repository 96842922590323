import {
  CARDADAY_STRIPE_ID,
  CARD_PACKAGE_100,
  CARD_PACKAGE_1000,
  CARD_PACKAGE_2000,
  CARD_PACKAGE_250,
  CARD_PACKAGE_500,
  LEGACY_39,
  LEGACY_980_1,
  UNLIMITED_STRIPE_ID,
} from 'src/marketing/plans/constants'
import {
  ActivePlan,
  ActivePlans,
  FeatureType,
  LegacyPlan,
  LegacyPlans,
} from './type'

export const tableFeatures: FeatureType[] = Object.values(FeatureType)

export const activePlans: ActivePlans = {
  // -------------------BASIC Subscription------------------------------------
  [ActivePlan.BASIC]: {
    id: '',
    name: ActivePlan.BASIC,
    sku: '',
    card: {
      price: '',
      features: [
        '20 Monthly Heartfelt Prompting Cards. <b>(Stamps Included)</b>',
        '20% Discount on Gifts.',
        '$2.95 System Cards',
      ],
      incentive: {
        title: 'Upgrade today and get',
        subtitle: '+10 Extra Cards good for 6 months',
        icon: '10_card',
      },
    },
    tableFeatures: {
      [FeatureType.ISF]: { main: 'excluded' },
      [FeatureType.OMSF]: { main: 'excluded' },
      [FeatureType.HPC]: {
        main: { content: '$2.95', type: 'stamps_excluded' },
      },
      [FeatureType.SC]: {
        main: { content: '$2.95', type: 'stamps_excluded' },
      },
      [FeatureType.AC]: { main: '$2.95' },
      [FeatureType.PC]: {
        main: '$0.98',
      },
      [FeatureType.DSOG]: { main: 'excluded' },
      [FeatureType.SOS]: { main: 'excluded' },
      [FeatureType.MGF]: { main: 'excluded' },
      [FeatureType.CSFD]: { main: 'excluded' },
      [FeatureType.CM]: { main: 'included' },
      [FeatureType.PS]: { main: 'excluded' },
    },
  },
  // -------------------Heartfelt Subscription $37------------------------------------
  [ActivePlan.HRFLT]: {
    id: '6',
    sku: CARDADAY_STRIPE_ID,
    tableHeadingBgColor: '#FE5991',
    name: ActivePlan.HRFLT,
    card: {
      price: '$37/mo',
      features: [
        '20 Monthly Heartfelt Prompting Cards. <b>(Stamps Included)</b>',
        '20% Discount on Gifts.',
        '$1.75 System Cards',
      ],
      incentive: {
        title: 'Upgrade today and get',
        subtitle: '+10 Extra Cards good for 6 months',
        icon: '10_card',
      },
    },
    tableFeatures: {
      [FeatureType.ISF]: { main: '$37' },
      [FeatureType.OMSF]: { main: '$37mo' },
      [FeatureType.HPC]: {
        main: { content: '20/mo', type: 'stamps_included' },
      },
      [FeatureType.SC]: {
        main: 'excluded',
        secondary: {
          content: '',
          info:
            'Pay $1.75 for additional cards sent that are not Heartfelt Cards.',
        },
      },
      [FeatureType.AC]: { main: '$1.75' },
      [FeatureType.PC]: {
        main: '$0.58',
        secondary: undefined,
      },
      [FeatureType.DSOG]: { main: '20%' },
      [FeatureType.SOS]: { main: 'excluded' },
      [FeatureType.MGF]: {
        main: 'Monthly',
        secondary: { content: 'Subscription' },
      },
      [FeatureType.CSFD]: { main: 'excluded' },
      [FeatureType.CM]: { main: 'included' },
      [FeatureType.PS]: { main: 'included' },
    },
  },
  // -------------------Heartfelt Plus Subscription------------------------------------
  [ActivePlan.HRFLT_PLUS]: {
    id: '',
    name: ActivePlan.HRFLT_PLUS,
    sku: 'UNLIMITED_STRIPE_ID_PLUS_GIFT',
    tableHeadingBgColor: '#277AD4',
    tableFeatures: {
      [FeatureType.ISF]: { main: '$97' },
      [FeatureType.OMSF]: { main: '$97mo' },
      [FeatureType.HPC]: {
        main: { content: 'Up to 5/day', type: 'stamps_included' },
      },
      [FeatureType.SC]: {
        main: 'excluded',
        secondary: {
          content: '',
          info:
            'Pay $1.50 for additional cards sent that are not Heartfelt Cards.',
        },
      },
      [FeatureType.AC]: { main: '$1.50' },
      [FeatureType.PC]: {
        main: '$0.50',
        secondary: undefined,
      },
      [FeatureType.DSOG]: { main: '30%' },
      [FeatureType.SOS]: { main: '20%' },
      [FeatureType.MGF]: {
        main: 'Monthly',
        secondary: { content: 'Subscription' },
      },
      [FeatureType.CSFD]: { main: 'excluded' },
      [FeatureType.CM]: { main: 'included' },
      [FeatureType.PS]: { main: 'included' },
    },
    card: {
      price: '',
      features: [],
      incentive: undefined,
    },
  },
  // -------------------Heartfelt Plus Gifts $147------------------------------------
  [ActivePlan.HRFLT_PLUS_GIFT]: {
    id: '42',
    sku: UNLIMITED_STRIPE_ID,
    tableHeadingBgColor: '#9859FE',
    name: ActivePlan.HRFLT_PLUS_GIFT,
    card: {
      price: '$147/mo',
      features: [
        'Unlimited Heartfelt Promptings Cards & Photo Postcards! <b>(Stamps Included)</b>',
        '30% Off Gifts and Shipping Average.',
        '$1.50 System Cards',
      ],
      incentive: {
        title: 'Upgrade today and get',
        subtitle: '+20 Extra Cards good for 6 months',
        icon: '20_card',
      },
    },
    tableFeatures: {
      [FeatureType.ISF]: { main: '$147' },
      [FeatureType.OMSF]: { main: '$147mo' },
      [FeatureType.HPC]: {
        main: { content: 'Up to 5/day', type: 'stamps_included' },
      },
      [FeatureType.SC]: {
        main: 'excluded',
        secondary: {
          content: '',
          info:
            'Pay $1.25 for additional cards sent that are not Heartfelt Cards.',
        },
      },
      [FeatureType.AC]: { main: '$1.25' },
      [FeatureType.PC]: {
        main: '$0.41',
        secondary: undefined,
      },
      [FeatureType.DSOG]: { main: '30%' },
      [FeatureType.SOS]: { main: '20%' },
      [FeatureType.MGF]: {
        main: 'Monthly',
        secondary: { content: 'Subscription' },
      },
      [FeatureType.CSFD]: { main: 'excluded' },
      [FeatureType.CM]: { main: 'included' },
      [FeatureType.PS]: { main: 'included' },
    },
  },
  // -------------------100 Card Package $157------------------------------------
  [ActivePlan.CRD_PKG_100]: {
    id: '78',
    sku: CARD_PACKAGE_100,
    tableHeadingBgColor: '#FF5689',
    name: ActivePlan.CRD_PKG_100,
    card: {
      price: '$187 per package',
      features: [
        '100 Card Credits. <b>(Good for 6 months and can be applied to: Heartfelt Prompting Cards, System Cards, and Bulk Cards)</b>',
        '$1.55 per Additional Card.',
        '15% Discount on Gifts.',
      ],
      incentive: {
        title: 'Upgrade today and get',
        subtitle: '+10 Extra Cards good for 6 months',
        icon: '10_card',
      },
    },
    tableFeatures: {
      [FeatureType.ISF]: { main: '$187' },
      [FeatureType.OMSF]: { main: 'excluded' },
      [FeatureType.HPC]: {
        main: {
          content: 'Up to 100',
          type: 'stamps_excluded',
        },
      },
      [FeatureType.SC]: {
        main: 'included',
        secondary: {
          content: '',
          info:
            'Once the 100 credits are used, additional cards sent will be $1.95.',
        },
      },
      [FeatureType.AC]: { main: '$1.95' },
      [FeatureType.PC]: {
        main: '$0.65',
        secondary: { content: 'After initial 100' },
      },
      [FeatureType.DSOG]: { main: '15%' },
      [FeatureType.SOS]: { main: 'excluded' },
      [FeatureType.MGF]: { main: '6/mo' },
      [FeatureType.CSFD]: { main: 'excluded' },
      [FeatureType.CM]: { main: 'included' },
      [FeatureType.PS]: { main: 'included' },
    },
  },
  // -------------------250 Card Package $347------------------------------------
  [ActivePlan.CRD_PKG_250]: {
    id: '44',
    sku: CARD_PACKAGE_250,
    tableHeadingBgColor: '#55D09E',
    name: ActivePlan.CRD_PKG_250,
    card: {
      price: '$397 per package',
      features: [
        '250 Card Credits. <b>(Good for 6 months and can be applied to: Heartfelt Prompting Cards, System Cards, and Bulk Cards)</b>',
        '$1.75 per Additional Card.',
        '20% Discount on Gifts.',
      ],
      incentive: {
        title: 'Upgrade today and get',
        subtitle: '+15 Extra Cards good for 6 months',
        icon: '15_card',
      },
    },
    tableFeatures: {
      [FeatureType.ISF]: { main: '$397' },
      [FeatureType.OMSF]: { main: 'excluded' },
      [FeatureType.HPC]: {
        main: {
          content: 'Up to 250',
          type: 'stamps_excluded',
        },
      },
      [FeatureType.SC]: {
        main: 'included',
        secondary: {
          content: '',
          info:
            'Once the 250 credits are used, additional cards sent will be $1.75.',
        },
      },
      [FeatureType.AC]: { main: '$1.75' },
      [FeatureType.PC]: {
        main: '$0.58',
        secondary: { content: 'After initial 250' },
      },
      [FeatureType.DSOG]: { main: '20%' },
      [FeatureType.SOS]: { main: 'excluded' },
      [FeatureType.MGF]: { main: '6/mo' },
      [FeatureType.CSFD]: { main: 'excluded' },
      [FeatureType.CM]: { main: 'included' },
      [FeatureType.PS]: { main: 'included' },
    },
  },
  // -------------------500 Card Package $597------------------------------------
  [ActivePlan.CRD_PKG_500]: {
    id: '45',
    sku: CARD_PACKAGE_500,
    tableHeadingBgColor: '#02BAD8',
    name: ActivePlan.CRD_PKG_500,
    card: {
      price: '$597 per package',
      features: [
        '500 Card Credits. <b>(Good for 6 months and can be applied to: Heartfelt Prompting Cards, System Cards, and Bulk Cards)</b>',
        '$1.55 per Additional Card.',
        '20% Discount on Gifts.',
        'Unlimited Campaign Store Free Downloads',
      ],
      incentive: {
        title: 'Upgrade today and get',
        subtitle: '+20 Extra Cards good for 6 months ',
        icon: '20_card',
      },
    },
    tableFeatures: {
      [FeatureType.ISF]: { main: '$697' },
      [FeatureType.OMSF]: { main: 'excluded' },
      [FeatureType.HPC]: {
        main: {
          content: 'Up to 500',
          type: 'stamps_excluded',
        },
      },
      [FeatureType.SC]: {
        main: 'included',
        secondary: {
          content: '',
          info:
            'Once the 500 credits are used, additional cards sent will be $1.95.',
        },
      },
      [FeatureType.AC]: { main: '$1.55' },
      [FeatureType.PC]: {
        main: '$0.51',
        secondary: { content: 'After initial 500' },
      },
      [FeatureType.DSOG]: { main: '20%' },
      [FeatureType.SOS]: { main: 'excluded' },
      [FeatureType.MGF]: { main: '6/mo' },
      [FeatureType.CSFD]: { main: 'included' },
      [FeatureType.CM]: { main: 'included' },
      [FeatureType.PS]: { main: 'included' },
    },
  },
  // -------------------1000 Card Package $997------------------------------------
  [ActivePlan.CRD_PKG_1000]: {
    id: '46',
    sku: CARD_PACKAGE_1000,
    tableHeadingBgColor: '#176BE0',
    name: ActivePlan.CRD_PKG_1000,
    card: {
      price: '$997 per package',
      features: [
        '1000 Card Credits. <b>(Good for 12 months and can be applied to: Heartfelt Prompting Cards, System Cards, and Bulk Cards)</b>',
        '$1.35 per Additional Card.',
        '30% Discount on Gifts.',
        'Unlimited Campaign Store Free Downloads',
      ],
      incentive: {
        title: 'Upgrade today and get',
        subtitle: '+25 Extra Cards good for 6 months',
        icon: '25_card',
      },
    },
    tableFeatures: {
      [FeatureType.ISF]: { main: '$997' },
      [FeatureType.OMSF]: { main: 'excluded' },
      [FeatureType.HPC]: {
        main: {
          content: 'Up to 1000',
          type: 'stamps_excluded',
        },
      },
      [FeatureType.SC]: {
        main: 'included',
        secondary: {
          content: '',
          info:
            'Once the 1000 credits are used, additional cards sent will be $1.95.',
        },
      },
      [FeatureType.AC]: { main: '$1.35' },
      [FeatureType.PC]: {
        main: '$0.45',
        secondary: { content: 'After initial 1000' },
      },
      [FeatureType.DSOG]: { main: '30%' },
      [FeatureType.SOS]: { main: 'excluded' },
      [FeatureType.MGF]: { main: '12/mo' },
      [FeatureType.CSFD]: { main: 'included' },
      [FeatureType.CM]: { main: 'included' },
      [FeatureType.PS]: { main: 'included' },
    },
  },
  // -------------------2000 Card Package $1797------------------------------------
  [ActivePlan.CRD_PKG_2000]: {
    id: '47',
    sku: CARD_PACKAGE_2000,
    tableHeadingBgColor: '#752FD1',
    name: ActivePlan.CRD_PKG_2000,
    card: {
      price: '$1797 per package',
      features: [
        '2000 Card Credits. <b>(Good for 12 months and can be applied to: Heartfelt Prompting Cards, System Cards, and Bulk Cards)</b>',
        '$1.15 per Additional Card.',
        '30% Discount on Gifts.',
        'Unlimited Campaign Store Free Downloads',
      ],
      incentive: {
        title: 'Upgrade today and get',
        subtitle: '+30 Extra Cards good for 6 months',
        icon: '30_card',
      },
    },
    tableFeatures: {
      [FeatureType.ISF]: { main: '$2197' },
      [FeatureType.OMSF]: { main: 'excluded' },
      [FeatureType.HPC]: {
        main: {
          content: 'Up to 2000',
          type: 'stamps_excluded',
        },
      },
      [FeatureType.SC]: {
        main: 'included',
        secondary: {
          content: '',
          info:
            'Once the 1000 credits are used, additional cards sent will be $1.95.',
        },
      },
      [FeatureType.AC]: { main: '$1.15' },
      [FeatureType.PC]: {
        main: '$0.38',
        secondary: { content: 'After initial 2000' },
      },
      [FeatureType.DSOG]: { main: '30%' },
      [FeatureType.SOS]: { main: '20%' },
      [FeatureType.MGF]: { main: '12/mo' },
      [FeatureType.CSFD]: { main: 'included' },
      [FeatureType.CM]: { main: 'included' },
      [FeatureType.PS]: { main: 'included' },
    },
  },
}
// todo should be able to remove legacyPlans and all of its deps
export const legacyPlans: LegacyPlans = {
  // -------------------Legacy $9.80------------------------------------
  [LegacyPlan.LGCY_980]: {
    id: '',
    sku: LEGACY_980_1,
    name: LegacyPlan.LGCY_980,
    card: {
      price: '$9.80 per month',
      features: [
        '4 Cards per month <b>(Good for 6 months and can be used toward Heartfelt Prompting Cards, System Cards, and Bulk Cards)</b>',
        'Stamps not included',
        '$2.45 per additional card',
      ],
    },
    tableFeatures: {
      [FeatureType.ISF]: { main: '$9.80/mo' },
      [FeatureType.OMSF]: { main: 'excluded' },
      [FeatureType.MGF]: { main: '6/mo' },
      [FeatureType.HPC]: {
        main: 'included',
        secondary: {
          content: '4 HF or System',
          info:
            'Send 4 cards a month. Good for 6 months. Can be used toward HF Prompting Cards, System Cards and Bulk Cards. Stamps not included.',
        },
      },
      [FeatureType.SC]: {
        main: 'included',
        secondary: {
          content: '2000 HF or System',
          info:
            'Send 4 cards a month. Good for 6 months. Can be used toward HF Prompting Cards, System Cards and Bulk Cards. $2.45 per additional card.',
        },
      },
      [FeatureType.AC]: { main: '$2.45' },
      [FeatureType.CM]: { main: 'included' },
      [FeatureType.PS]: { main: 'excluded' },
      [FeatureType.DSOG]: { main: 'excluded' },
      [FeatureType.SOS]: { main: 'excluded' },
      [FeatureType.CSFD]: { main: 'excluded' },
      [FeatureType.PC]: {
        main: 'included',
        secondary: undefined,
      },
    },
  },
  // -------------------Legacy $39------------------------------------
  [LegacyPlan.LGCY_39]: {
    id: '',
    sku: LEGACY_39,
    name: LegacyPlan.LGCY_39, // Todo get rid of legacy plan
    card: {
      price: '$39 per month',
      features: [
        '20 Cards per month <b>(Good for 6 months and can be used toward Heartfelt Prompting Cards, System Cards, and Bulk Cards)</b>',
        'Stamps not included',
        '15% off gifts.',
        '$1.95 per additional card',
      ],
    },
    tableFeatures: {
      [FeatureType.ISF]: { main: '$39/mo' },
      [FeatureType.OMSF]: { main: 'excluded' },
      [FeatureType.MGF]: { main: '12/mo' },
      [FeatureType.HPC]: {
        main: 'included',
        secondary: {
          content: 'Up to 20/mo',
          info: '//TODO add info content',
        },
      },
      [FeatureType.SC]: {
        main: 'included',
        secondary: {
          content: 'Up to 20/mo',
          info: '//TODO add info content',
        },
      },
      [FeatureType.AC]: { main: '$1.95' },
      [FeatureType.CM]: { main: 'included' },
      [FeatureType.PS]: { main: 'excluded' },
      [FeatureType.DSOG]: { main: 'excluded' },
      [FeatureType.SOS]: { main: 'excluded' },
      [FeatureType.CSFD]: { main: 'excluded' },
      [FeatureType.PC]: {
        main: 'included',
        secondary: undefined,
      },
    },
  },
}
