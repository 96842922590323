import { Component, ErrorInfo, ReactNode } from 'react'

type Props = {
  fallback: (error: Error, reset: () => void) => NonNullable<ReactNode>
}

type State = {
  error?: Error
}

class ErrorBoundary extends Component<Props, State> {
  state: State = {}

  static getDerivedStateFromError(error: Error) {
    return { error }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(`An error has occurred: ${error.toString()}\n${error.stack}`)
    console.error(`Error info: ${errorInfo}`)
    console.trace()
  }

  render() {
    const { fallback, children } = this.props
    const { error } = this.state
    const reset = () => this.setState({ error: undefined })
    return error ? fallback(error, reset) : children
  }
}

export default ErrorBoundary
