import { Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'

import { Icon, Icons, IconType } from 'src/chrome'

const styles = {
  feature: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '400px',
    '@media (max-width: 1290px)': {
      marginBottom: '60px',
      marginLeft: '60px',
    },
    '@media (max-width: 650px)': {
      marginLeft: 0,
    },
  },
  featureCopy: {
    marginLeft: '22px',
  },
}

type Props = {
  title: string
  text: string
  icon: IconType | Icons
  iconSize?: number
  iconViewBox?: string
  isStrokedIcon?: boolean
}

const PlanContentsItem: React.FC<Props> = props => {
  const { title, text, icon, iconSize, iconViewBox, isStrokedIcon } = props

  const iconColor = '#9C9CA0'
  const iconProps = {
    icon: icon,
    viewBox: iconViewBox,
    size: iconSize ? iconSize : 50,
    color: !isStrokedIcon ? iconColor : 'none',
    stroke: isStrokedIcon ? iconColor : undefined,
    strokeWidth: isStrokedIcon ? '46.5455' : undefined,
  }

  return (
    <div css={styles.feature}>
      <div>
        <Icon {...iconProps} />
      </div>
      <div css={styles.featureCopy}>
        <Text type="largeBody" color="primaryHeading" weight="semiBold">
          {title}
        </Text>
        <Text type="caption">{text}</Text>
      </div>
    </div>
  )
}

export default PlanContentsItem
