import React from 'react'
import { Set } from 'immutable'
import { Dialog } from 'src/chrome'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'

import { ContactFragment } from 'src/legacy_graphql'
import { useEffect, useState } from 'src/hooks'

import ContactSelector from '../ContactSelector/ContactSelector'
import styles from './createGroup.module.scss'

const labelStyle: React.CSSProperties = {
  color: '#6F8394',
  fontWeight: 500,
}

interface Props {
  isOpen: boolean
  contacts: ContactFragment[]
  onClose: () => void
  onSubmit: (group: { name: string; members: string[] }) => void
}

const CreateGroup: React.FC<Props> = props => {
  const { isOpen, contacts, onClose, onSubmit } = props

  const [groupName, setGroupName] = useState('')
  const [selected, setSelected] = useState(Set<string>())

  useEffect(() => {
    if (contacts) {
      setSelected(Set(contacts.map(contact => contact.id)))
    }
  }, [contacts])

  const toggleSelected = (id: string) =>
    setSelected(x => (x.has(id) ? x.delete(id) : x.add(id)))

  const isEveryContactSelected = selected.count() === contacts.length

  const toggleSelectAll = () => {
    if (isEveryContactSelected) {
      setSelected(Set())
    } else {
      setSelected(Set(contacts.map(contact => contact.id)))
    }
  }

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    onSubmit({
      name: groupName,
      members: contacts
        .filter(contact => selected.has(contact.id))
        .map(contact => contact.id),
    })
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      scroll={'paper'}
      style={{ transition: '300ms' }}
    >
      <DialogTitle>{'Create New Group'}</DialogTitle>
      <DialogContent className={styles.dialogContainer}>
        <div className={styles.innerContainer}>
          <div className={styles.body}>
            <TextField
              name="groupName"
              label="Group Name"
              InputLabelProps={{
                style: labelStyle,
              }}
              InputProps={{
                style: {
                  color: 'black',
                },
              }}
              value={groupName}
              onChange={e => setGroupName(e.target.value)}
              style={{ marginTop: '-11px' }}
            />
            <div className={styles.selector}>
              <span>
                {`${contacts.length} contact${
                  contacts.length === 1 ? '' : 's'
                }`}
              </span>
              <ContactSelector
                contacts={contacts}
                selected={selected}
                isEveryContactSelected={isEveryContactSelected}
                onSelect={toggleSelected}
                onSelectAll={toggleSelectAll}
              />
              <span style={{ cursor: 'default' }}>
                {`${selected.size} selected`}
              </span>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button key={'Close'} color={'secondary'} onClick={onClose}>
          Cancel
        </Button>
        <Button
          key={'Submit save'}
          color={'primary'}
          disabled={groupName === '' || selected.size < 1}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateGroup
