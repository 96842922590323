import {
  useEffect,
  useMutations,
  usePersistedUserData,
  useState,
} from 'src/hooks'
import { parseError } from 'src/utils/parseError'

const useUsernameValidation = (username: string) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  )
  const [isLoading, setIsLoading] = useState(false)
  const [hasPerformedValidation, setHasPerformedValidation] = useState(false)
  const [isValid, setIsValid] = useState(false)

  const mutations = useMutations()
  const { account } = usePersistedUserData()

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    let mutableCanceling = false

    if (username) {
      if (account && username === account.username) {
        setIsValid(true)
        setErrorMessage(undefined)
        return
      }

      const validate = async () => {
        setIsLoading(true)
        setErrorMessage(undefined)
        setIsValid(false)
        try {
          const {
            validateUsername: { valid: isValid },
          } = await mutations.validateUsername({
            username,
            doNotShowDefaultTransition: true,
          })
          setHasPerformedValidation(true)
          if (mutableCanceling) {
            return
          }
          setIsValid(isValid)
        } catch (e) {
          setErrorMessage(parseError(e))
          setIsValid(false)
        } finally {
          setIsLoading(false)
        }
      }
      validate()
    }

    return () => {
      mutableCanceling = true
    }
  }, [username, mutations, account])

  return {
    errorMessage,
    hasPerformedValidation,
    isLoading,
    isValid,
  }
}

export default useUsernameValidation
