import { GiftVariationFragment } from 'src/graphql/generated/graphql'

const getGiftTotal = (giftVariation: GiftVariationFragment, multiplier = 1) => {
  if (giftVariation.price.asString.indexOf('$') > -1) {
    return giftVariation.price.asString.replace(
      (giftVariation.price.amount / 100).toFixed(2),
      ((giftVariation.price.amount / 100) * multiplier).toFixed(2),
    )
  }
  return giftVariation.price.asString.replace(
    giftVariation.price.amount.toString(),
    (giftVariation.price.amount * multiplier).toString(),
  )
}

export default getGiftTotal
