import React from 'react'
// @src imports
import { Button, Spacer } from '@sendoutcards/quantum-design-ui'
import { ColorFragment } from 'src/legacy_graphql'
// relative imports
import ColorPicker from '../ColorPicker/ColorPicker'

import styles from './backgroundPicker.module.scss'
import { Api } from '../../api'
import { Steps } from '../../types'

type Props = {
  api: Api & { step: { type: Steps.EditBackgroundColor } }
}

const BackgroundPicker: React.FC<Props> = props => {
  const {
    step,
    card,
    selectedFullBleed,
    getSelectedPanel,
    editBackgroundPicture,
    updatePanel,
    removeBackgroundPicture,
    setPanelView,
    designColors,
  } = props.api
  const { fullBleedIndex, panelIndex } = step

  const backgroundElement = selectedFullBleed?.backgroundElement

  const setBackgroundColor = (color: ColorFragment) =>
    updatePanel(fullBleedIndex, panelIndex, panel => ({
      ...panel,
      backgroundColor: color,
    }))

  const removeBackgroundColor = () =>
    updatePanel(fullBleedIndex, panelIndex, panel => ({
      ...panel,
      backgroundColor: null,
    }))

  return (
    <div className={styles.componentContainer}>
      <ColorPicker
        selectedColor={getSelectedPanel(step)?.backgroundColor ?? undefined}
        selectColor={setBackgroundColor}
        removeColor={removeBackgroundColor}
        designColors={designColors}
      />
      {/*TODO: fix the image span on three panel then remove it from the conditional below when it works*/}
      {backgroundElement &&
        card &&
        card.type !== 'THREE_PANEL' &&
        (!card.detailedSendableCard ||
          (card.detailedSendableCard &&
            !card.detailedSendableCard.insideRightImage)) && (
          <div>
            <div className={styles.backgroundImageActions}>
              <Button
                id={'add_image_btn'}
                title={
                  backgroundElement.image && backgroundElement.image.image
                    ? 'Change Background Image'
                    : 'Add Background Image'
                }
                onClick={() => {
                  setPanelView('fullbleed')
                  editBackgroundPicture(fullBleedIndex, 'own')
                }}
                fullWidth={true}
                outlined={true}
              />
              <Spacer orientation="vertical" space="x1_5" />
              {backgroundElement.image && backgroundElement.image.image && (
                <Button
                  title={'Remove'}
                  onClick={() => removeBackgroundPicture(fullBleedIndex)}
                  type="primary"
                  fullWidth={true}
                />
              )}
            </div>
          </div>
        )}
    </div>
  )
}

export default BackgroundPicker
