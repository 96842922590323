import React from 'react'
import {
  Anchor,
  Button,
  Div,
  Flex,
  List,
  ListItem,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import {
  useEffect,
  usePersistedUserData,
  useQueries,
  useState,
} from 'src/hooks'
import { getPossibleSponsors, SponsorFragment } from 'src/legacy_graphql'
import { Memoized } from 'src/hooks/dependencies'
import { SponsorCard } from './SponsorCard'
import { sendoutcardsPromotionsGenealogyId } from 'src/app/constants'

const SelectMySponsor = (props: {
  onSelectedSponsor: Memoized<(sponsorId?: string) => void>
  onHasNoPossibleSponsors: Memoized<() => void>
  onFindMySponsor: () => void
}) => {
  const {
    onSelectedSponsor,
    onHasNoPossibleSponsors,
    onFindMySponsor: onAddSponsor,
  } = props
  const [selectedSponsor, setSelectedSponsor] = useState<SponsorFragment>()
  const [possibleSponsors] = useQueries(getPossibleSponsors())
  const { account } = usePersistedUserData()

  useEffect(() => {
    if (
      possibleSponsors.length === 1 &&
      possibleSponsors[0].genealogyId !== sendoutcardsPromotionsGenealogyId &&
      selectedSponsor === undefined
    ) {
      setSelectedSponsor(possibleSponsors[0])
    } else if (
      account &&
      account.sponsor &&
      account.sponsor.genealogyId !== sendoutcardsPromotionsGenealogyId &&
      selectedSponsor === undefined
    ) {
      setSelectedSponsor(account.sponsor as SponsorFragment)
    } else {
      onHasNoPossibleSponsors()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Flex width="100%" flexDirection="column" justifyContent="space-between">
      <Div>
        <Text
          type="body"
          weight={'semiBold'}
          color="primaryHeading"
          alignment={'left'}
        >
          One Last Thing...
        </Text>
        <Spacer orientation="horizontal" space="x3" />
        <Text type="caption" outset={{ horizontal: 'auto' }}>
          We believe in celebrating the people who invite others to use
          SendOutCards. Choose the person from the list below so we can
          congratulate them!
        </Text>
      </Div>
      <Spacer space="x2" />
      {account &&
      account.sponsor &&
      account.sponsor.genealogyId !== sendoutcardsPromotionsGenealogyId ? (
        <SponsorCard
          sponsor={account.sponsor as SponsorFragment}
          isActive={selectedSponsor?.id === account.sponsor.id}
          onClick={() => {
            setSelectedSponsor(
              selectedSponsor?.id !== account.sponsor?.id
                ? (account.sponsor as SponsorFragment)
                : undefined,
            )
          }}
        />
      ) : (
        <List orientation="vertical" alignment="center" overflow="auto">
          {possibleSponsors.map(sponsor => (
            <ListItem key={sponsor.id} orientation="vertical" inset="x0">
              <SponsorCard
                sponsor={sponsor}
                isActive={selectedSponsor?.id === sponsor.id}
                onClick={() => {
                  setSelectedSponsor(
                    selectedSponsor?.id !== sponsor.id ? sponsor : undefined,
                  )
                }}
              />
            </ListItem>
          ))}
        </List>
      )}
      <Spacer space="x4" />
      <Flex flexDirection="column" alignItems="center">
        <Div>
          <Button
            title={
              selectedSponsor
                ? `Select ${selectedSponsor.firstName}`
                : `Select a Sponsor`
            }
            onClick={() => onSelectedSponsor(selectedSponsor?.id)}
            disabled={!selectedSponsor}
            fullWidth={false}
          />
        </Div>
        <Spacer space="x2" />
        <Anchor size="small" onClick={() => onAddSponsor()}>
          Find My Sponsor
        </Anchor>
      </Flex>
    </Flex>
  )
}

export default SelectMySponsor
