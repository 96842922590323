import { Route } from './Route'
import { Subroute } from './Subroute'
import { InvalidMagicLinkRoute } from './InvalidMagicLinkRoute'
import { CatalogRoute } from 'src/catalog/routes/CatalogRoute'
import { GiftStoreRoute } from 'src/gift_store/routes/GiftStoreRoute'
import { CampaignsRoute } from 'src/campaigns/routes/CampaignsRoute'
import { DashboardRoute } from 'src/dashboard/routes/DashboardRoute'
import { ImportContactsRoute } from 'src/contacts/routes/ImportContactsRoute'
import { OrdersRoute } from 'src/orders/routes/OrdersRoute'
import { PricingRoute } from 'src/marketing/plans/routes/PricingRoute'
import { CardRoute } from 'src/card/routes/CardRoute'
import { RelationshipManagerRoute } from 'src/contacts/routes/RelationshipManagerRoute'
import { SubmitRequestedContactRoute } from 'src/contacts/routes/SubmitRequestedContact'
import { GroupsRoute } from 'src/contacts/routes/GroupsRoute'
import { PromoRoute } from 'src/promo/routes/PromoRoute'
import { FourOFourRoute } from 'src/404/routes/404Route'
import { CertifiedCoachingRoute } from 'src/coaching/routes/CoachingCertificationRoute'
import { PointsConversionRoute } from 'src/points_conversion/routes/PointsConversionRoute'
import { ConfirmPaymentRoute } from 'src/saleor/routes/ConfirmPaymentRoute'
import { ConfirmOrderRoute } from 'src/saleor/routes/ConfirmOrderRoute'
import { SignInRoute } from 'src/sign_in/routes/SignIn'
import { SignUpRoute } from 'src/sign_up/routes/SignUp'
import { ResetPasswordRoute } from 'src/reset_password/routes/ResetPassword'

export const BaseSubroute = Subroute(
  InvalidMagicLinkRoute,
  CampaignsRoute,
  CatalogRoute,
  DashboardRoute,
  GiftStoreRoute,
  ImportContactsRoute,
  OrdersRoute,
  PricingRoute,
  GroupsRoute,
  RelationshipManagerRoute,
  SubmitRequestedContactRoute,
  CardRoute,
  PromoRoute,
  FourOFourRoute,
  CertifiedCoachingRoute,
  PointsConversionRoute,
  ConfirmPaymentRoute,
  ConfirmOrderRoute,
  SignInRoute,
  SignUpRoute,
  ResetPasswordRoute,
)
export type BaseSubroute = Subroute<typeof BaseSubroute>

export const BaseRoute = Route({
  path: '/',
  init: (
    subroute: BaseSubroute = CatalogRoute(),
    args: {
      [key: string]: string | undefined
    } = {},
  ): {
    subroute: BaseSubroute
    args: {
      [key: string]: string | undefined
    }
  } => ({
    subroute,
    args,
  }),
  fromPath: () => ({ args: {} }),
  subroute: BaseSubroute,
  subrouteIsRequired: true,
})
// Declared as an interface to avoid self-reference issues
export interface BaseRoute {
  path: '/'
  subroute: BaseSubroute
  args: {
    [key: string]: string | undefined
  }
}
