import React from 'react'
import moment from 'moment'
// @src imports
import { Details, MoreInfo, SubDate } from 'src/styled'
import { formatCost, sortedSubscriptionFeatures } from 'src/helpers'
import {
  NormalizedSubscriptionFeature,
  SubscriptionType,
} from 'src/user/fragments'
import { Feature } from 'src/dashboard/components'
import { Flex, Text } from '@sendoutcards/quantum-design-ui'
import { StripeSubscriptionFragment } from 'src/graphql/generated/graphql'
import omit from 'lodash/omit'

interface Props {
  subscription: SubscriptionType | StripeSubscriptionFragment
  shouldShowDates: boolean
  shouldShowPrice?: boolean
}

const Addon: React.FC<Props> = props => {
  const {
    subscription: {
      name,
      price,
      startDate,
      currentPeriodEnd,
      isSetToCancel,
      features,
    },
    shouldShowDates,
    shouldShowPrice = true,
  } = props

  const isSystemPackage = name.includes('System')
  const isCardPackage = name.includes('Card Package')
  const is10for20 = name === '10 for $20 Heartfelt Cards'

  return (
    <>
      <Flex justifyContent="space-between">
        <Text type="caption" color="primaryHeading" weight="bold">
          {name}
          {name === 'Enterprise Grandfathered' ? (
            <MoreInfo
              href={
                'https://www.sendoutcards.com/resources/enterprise-subscription-details/'
              }
              target="_blank"
            >
              More Information
            </MoreInfo>
          ) : null}
        </Text>
        {shouldShowPrice && <Text type="caption">{formatCost(price)}</Text>}
      </Flex>
      {shouldShowDates && (
        <Details>
          {startDate && currentPeriodEnd && (
            <>
              <SubDate>
                <Text type="caption" color="primaryHeading" weight="bold">
                  Created
                </Text>
                <Text type="caption">
                  {moment(startDate).format('MM/DD/YYYY')}
                </Text>
              </SubDate>
              <SubDate style={isSetToCancel ? { color: 'red' } : {}}>
                <Text
                  type="caption"
                  color={isSetToCancel ? 'danger' : 'primaryHeading'}
                  weight="bold"
                >
                  {is10for20
                    ? ''
                    : isSystemPackage || isCardPackage
                    ? 'Expires'
                    : 'Renewal Date'}
                </Text>
                <Flex width="100%" justifyContent="space-between">
                  <Text
                    type="caption"
                    color={isSetToCancel ? 'danger' : 'primaryHeading'}
                    alignment="right"
                  >
                    {is10for20
                      ? 'Ends On '
                      : isSetToCancel && !isSystemPackage && !isCardPackage
                      ? 'Account will convert on: '
                      : ''}
                  </Text>
                  <Text
                    type="caption"
                    color={isSetToCancel ? 'danger' : 'primaryHeading'}
                    alignment="right"
                  >
                    {moment(currentPeriodEnd).format('MM/DD/YYYY')}
                  </Text>
                </Flex>
              </SubDate>
            </>
          )}
        </Details>
      )}
      {features &&
        sortedSubscriptionFeatures(omit(features, '__typename'))
          .filter(key => features[key] !== null)
          .map(key => (
            <Feature
              key={key}
              feature={features[key] as NormalizedSubscriptionFeature}
            />
          ))}
    </>
  )
}

export default Addon
