import React from 'react'

import { SubscriptionType } from 'src/user/fragments'
import { Addon, Feature } from 'src/dashboard/components'

import styles from '../../styles/account.module.scss'
import { useAccount, useActions, useEffect } from 'src/hooks/index'
import { Result } from '@sendoutcards/core'
import deepEqual from 'deep-equal'
import { useAvailableDailyHeartfelt, useUserCardTokens } from 'src/react_query'

const Addons = () => {
  const actions = useActions()
  const storedAccount = useAccount()
  const { data: value } = useUserCardTokens()
  const { data: availableDailyHeartfelts } = useAvailableDailyHeartfelt()

  const {
    heartfeltSends,
    heartfeltSystemSends,
    systemSends,
    cardPackageSends,
    pointsTransitionSends,
    employeeSends,
    giftSends,
    cardSends,
  } = value ? value : storedAccount

  useEffect(() => {
    if (value) {
      const newAccount = {
        ...storedAccount,
        heartfeltSends: value.heartfeltSends,
        heartfeltSystemSends: value.heartfeltSystemSends,
        systemSends: value.systemSends,
        cardPackageSends: value.cardPackageSends,
        pointsTransitionSends: value.pointsTransitionSends,
        employeeSends: value.employeeSends,
        giftSends: value.giftSends,
        cardSends: value.cardSends,
      }
      if (!deepEqual(storedAccount, newAccount)) {
        actions.loadedUser(Result(newAccount))
      }
    }
  }, [value, actions, storedAccount])

  return (
    <div className={styles.accountActivePlan}>
      {storedAccount.subscriptions &&
        storedAccount.subscriptions
          .filter(_sub => !_sub.isRenewable)
          .map((sub: SubscriptionType) => (
            <div
              key={sub.id}
              className={styles.subscription}
              style={{ padding: '15px 5px' }}
            >
              <Addon subscription={sub} shouldShowDates={true} />
            </div>
          ))}

      {/* {storedAccount.activePlansAndAddons && (
        <div
          key={'addons'}
          className={styles.subscription}
          style={{ padding: '15px 5px' }}
        >
          <Addon
            subscription={storedAccount.activePlansAndAddons}
            shouldShowDates={false}
            shouldShowPrice={false}
          />
        </div>
      )} */}
      {heartfeltSends > 0 && (
        <Feature
          feature={{
            label: 'Available Heartfelt Sends',
            remaining: heartfeltSends,
          }}
        />
      )}
      {systemSends > 0 && (
        <Feature
          feature={{
            label: 'Available System Sends',
            remaining: systemSends,
          }}
        />
      )}
      {(heartfeltSystemSends > 0 || cardSends > 0) && (
        <Feature
          feature={{
            label: 'Available Card Sends',
            remaining: heartfeltSystemSends + cardSends,
          }}
        />
      )}
      {cardPackageSends > 0 && (
        <Feature
          feature={{
            label: 'Available Card Package Sends',
            remaining: cardPackageSends,
          }}
        />
      )}
      {pointsTransitionSends > 0 && (
        <Feature
          feature={{
            label: 'Available Card Sends',
            remaining: pointsTransitionSends,
          }}
        />
      )}
      {employeeSends > 0 && (
        <Feature
          feature={{
            label: 'Available Employee Sends',
            remaining: employeeSends,
          }}
        />
      )}
      {availableDailyHeartfelts?.hasDailyHeartfeltSends && (
        <Feature
          feature={{
            label: 'Available Heartfelt Sends',
            remaining: availableDailyHeartfelts.dailyHeartfeltCap.available,
            total: availableDailyHeartfelts.dailyHeartfeltCap.cap,
            tooltip: {
              title:
                'Send up to 5 Heartfelt Prompting Cards per day with your current subscription. This card count resets each day at 12:00 a.m. (MT).',
              shouldDisplayTooltip: true,
              shouldSupportOnTapAsHover: true,
              background: 'dark',
            },
          }}
        />
      )}
      {giftSends > 0 && (
        <Feature
          feature={{
            label: 'Available Gift Sends',
            remaining: giftSends,
          }}
        />
      )}
    </div>
  )
}

export default Addons
