import React from 'react'
import TextField from '@material-ui/core/TextField'
// @src imports
import { Button, Transition } from 'src/chrome'
import { CreateCampaignRoute } from 'src/campaigns/routes/CreateCampaignRoute'

import styles from './createCampaign.module.scss'
import { useActions, useAppcues, useMutations, useState } from 'src/hooks'
import suspenseBoundary from 'src/chrome/SuspenseBoundary/suspenseBoundaryHOC'
import { Spacer, Text } from '@sendoutcards/quantum-design-ui'

type CreateCampaignProps = {
  route: CreateCampaignRoute
}

const CreateCampaign: React.FC<CreateCampaignProps> = props => {
  const [name, setName] = useState('')

  const mutations = useMutations()
  const actions = useActions()

  useAppcues('-L9HF6PDCTLQR3QYlQqc')

  const handleChange = () => (event: React.ChangeEvent<HTMLInputElement>) =>
    setName(event.currentTarget.value)

  const handleSaveCampaign = async () => {
    const {
      createCampaign: { campaign },
    } = await mutations.createCampaign({ campaign: { name } })
    actions.openCampaign(campaign.id)
  }

  return (
    <div className={styles.campaignEntry}>
      <div className={styles.campaign}>
        <div>
          <Text type="title">Give it a Name</Text>
          <Spacer space="x4" />
        </div>
        <div className={styles.campaignInput}>
          <TextField
            helperText="What do you want to call your campaign?"
            value={name}
            onChange={handleChange()}
            id={'input_campaign_name'}
            style={{ width: 300 }}
          />
          <Button
            title={'Create Campaign'}
            id={'confirm_campaign_name_btn'}
            buttonColor={'pink'}
            disabled={name.length < 1}
            onClick={handleSaveCampaign}
          />
        </div>
      </div>
      <div className={styles.bottomGradient}>
        <svg
          id={styles.welcomeGradient}
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <defs>
            <linearGradient
              x1="0"
              y1="0"
              x2="1"
              y2="1"
              id="linearGradientSj78eeriu1"
            >
              <stop offset="0%" stopColor="#ff3874" />
              <stop offset="100%" stopColor="#da60fa" />
            </linearGradient>
          </defs>
          <path
            d="M0,80L100,68L100,100L0,100z"
            fill="url('#linearGradientSj78eeriu1')"
            style={{
              opacity: 0.85,
            }}
          />
        </svg>
      </div>
    </div>
  )
}

export default suspenseBoundary({
  component: CreateCampaign,
  unresolved: <Transition message={'Creating your campaign...'} />,
  failure: error => `Failed creating your campaign: ${error}`,
})
