import { PhoneNumberInput, Operation } from '../../../__generated__/globalTypes'

import validatePhoneNumberString from '../ValidatePhoneNumber.graphql'

export type ValidatePhoneNumberData = {
  validatePhoneNumber: {
    isValid: boolean
  }
}

export type ValidatePhoneNumberVariables = {
  phoneNumber: PhoneNumberInput
}

export type ValidatePhoneNumber = Operation<
  ValidatePhoneNumberData,
  ValidatePhoneNumberVariables
>

export const ValidatePhoneNumber = ({
  phoneNumber,
}: ValidatePhoneNumberVariables): ValidatePhoneNumber => ({
  query: validatePhoneNumberString,
  variables: {
    phoneNumber: PhoneNumberInput(phoneNumber),
  },
})
