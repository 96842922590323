import { Spacer, Text } from '@sendoutcards/quantum-design-ui'
import React, { FC } from 'react'

import { FlexColumn } from 'src/styled'
import DollaPicka from '../DollaPicka'

interface AddExpenseProps {
  expenseAmount: number
  expenseLimit: number
  onChange: (amount: number) => void
}

const AddExpense: FC<AddExpenseProps> = props => {
  const { expenseAmount, expenseLimit, onChange, children } = props
  return (
    <FlexColumn css={{ alignItems: 'center' }}>
      <Spacer space="x2" />
      <Text type="body" color="primaryHeading">
        Add Expense
      </Text>
      <Text type="footnote" alignment="center">
        We can handle your expense purchases for you with auto-reload. Enable it
        here.
      </Text>
      <Text type="footnote" alignment="center">
        Select the amount of expense you would like to purchase (Maximum of $50)
      </Text>
      <Spacer space="x2" />
      <DollaPicka
        amount={expenseAmount}
        min={0}
        max={expenseLimit}
        step={1}
        onChange={onChange}
      />
      {children}
    </FlexColumn>
  )
}

export default AddExpense
