import { TextFragment } from '../types'
import { ColorFragment, TextAlignment } from 'src/graphql/generated/graphql'

export type TextProperties = {
  fontId?: string
  fontSize?: number
  color?: ColorFragment
}

export type TextSelection = {
  start: number
  end: number
  overrideProperties: TextProperties
}

export const defaultTextSelection: TextSelection = {
  start: 1,
  end: 1,
  overrideProperties: {},
}

export type TextEditorState = {
  text: TextFragment[]
  selection: TextSelection
}

export type IndexedTextFragment = TextFragment & { index: number }

export type Paragraph = {
  index: number
  fontId: string
  fontSize: number
  color: ColorFragment
  textAlign: TextAlignment
  fragments: IndexedTextFragment[]
}

type ComputedSelectionPosition = {
  index: number
  paragraph: number
  offset: number
}

export type ComputedSelection = {
  start: ComputedSelectionPosition
  end: ComputedSelectionPosition
  fragments: TextFragment[]
  text: string
}

export type ComputedElementText = {
  paragraphs: Paragraph[]
  selectionUpperBound: number
  selection?: ComputedSelection
}
