import { Operation } from '../../../__generated__/globalTypes'

import {
  DetailedContactFragment,
  detailedContactFragmentString,
} from '../../fragments/__generated__/DetailedContactFragment'

import { calendarDateFragmentString } from '../../fragments/__generated__/CalendarDateFragment'

import { contactFragmentString } from '../../fragments/__generated__/ContactFragment'

import { optionalYearDateFragmentString } from '../../fragments/__generated__/OptionalYearDateFragment'

import { relatedContactFragmentString } from '../../fragments/__generated__/RelatedContactFragment'

import { noteFragmentString } from '../../fragments/__generated__/NoteFragment'

import createContactWithSocProContactRawString from '../CreateContactWithSocProContact.graphql'

const createContactWithSocProContactString = [
  createContactWithSocProContactRawString,
  detailedContactFragmentString,
  calendarDateFragmentString,
  contactFragmentString,
  optionalYearDateFragmentString,
  relatedContactFragmentString,
  noteFragmentString,
].join('\n\n')

export type CreateContactWithSocProContactData = {
  createContactWithSocProContact: {
    contact: DetailedContactFragment
  }
}

export type CreateContactWithSocProContactVariables = {
  socProContactId: string
}

export type CreateContactWithSocProContact = Operation<
  CreateContactWithSocProContactData,
  CreateContactWithSocProContactVariables
>

export const CreateContactWithSocProContact = ({
  socProContactId,
}: CreateContactWithSocProContactVariables): CreateContactWithSocProContact => ({
  query: createContactWithSocProContactString,
  variables: {
    socProContactId,
  },
})
