import { Maybe } from '../../../__generated__/globalTypes'

import minimalCampaignFragmentRawString from '../CampaignFragmentMinimal.graphql'

export const minimalCampaignFragmentString = minimalCampaignFragmentRawString

export type MinimalCampaignFragment = {
  __typename: 'Campaign'
  id: string
  name: string
  minimalLines: {
    __typename: 'CampaignLine'
    card: Maybe<{
      __typename: 'Card'
      id: string
      isHorizontal: boolean
      frontPreviewUrl: string
    }>
    giftVariation: Maybe<{
      __typename: 'GiftVariation'
      id: string
      defaultImageUrl: string
    }>
  }[]
  isShareable: boolean
  createdAt: string
}

export const isMinimalCampaignFragment = (
  fragment: any,
): fragment is MinimalCampaignFragment =>
  fragment &&
  fragment.__typename === 'Campaign' &&
  typeof fragment.id === 'string' &&
  typeof fragment.name === 'string' &&
  Array.isArray(fragment.minimalLines) &&
  fragment.minimalLines
    .slice(0, 5)
    .reduce(
      (accum: any, next: any) =>
        accum &&
        next &&
        next.__typename === 'CampaignLine' &&
        (next.card === null ||
          (next.card &&
            next.card.__typename === 'Card' &&
            typeof next.card.id === 'string' &&
            typeof next.card.isHorizontal === 'boolean' &&
            typeof next.card.frontPreviewUrl === 'string')) &&
        (next.giftVariation === null ||
          (next.giftVariation &&
            next.giftVariation.__typename === 'GiftVariation' &&
            typeof next.giftVariation.id === 'string' &&
            typeof next.giftVariation.defaultImageUrl === 'string')),
      true,
    ) &&
  typeof fragment.isShareable === 'boolean' &&
  typeof fragment.createdAt === 'string'
