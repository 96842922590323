import {
  Div,
  Flex,
  Icon,
  Image,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import {
  HOCSpacingKeys,
  LiteralUnion,
  SpacingDirection,
} from '@sendoutcards/quantum-design-ui/dist/src/helpers/hoc-types/entityValueTypes'
import React from 'react'
import { useSelector } from 'src/hooks'
import HoverTooltip from '../HoverTooltip/HoverTooltip'
import { Button } from 'src/design_system/molecules/button/Button'
import { Icon as DSIcon } from 'src/design_system/atoms/icons/components/Icon'

type WithAction = {
  type: 'withAction'
  title: string
  onClick?: () => void
  id?: string
}

type WithoutAction = {
  type: 'withoutAction'
  subtitle: string
}

type SecondaryAction = {
  title: string
  onClick: () => void
}
interface AddItemToLineProps {
  action: WithAction | WithoutAction
  secondaryAction?: SecondaryAction
  message: string
  size?: number
  inset?: SpacingDirection | LiteralUnion<HOCSpacingKeys>
  isDisabled?: boolean
  disabledMessage?: string
  isInvalidOrder?: boolean
  itemType: 'gift' | 'card'
  url?: string
}
const AddItemToLine: React.FC<AddItemToLineProps> = props => {
  const {
    action,
    secondaryAction,
    message,
    size = 128,
    inset,
    isDisabled = false,
    disabledMessage,
    isInvalidOrder,
    itemType,
    url,
  } = props
  const { isMobile, width } = useSelector(state => state.window)
  const isSmallMobile = width <= 418

  const typeTitle = isMobile ? 'body' : 'largeBody'

  const mobileWarning = isInvalidOrder && (
    <Div zIndex={5} position="absolute" inset={{ left: 'x4', top: 'x4' }}>
      <HoverTooltip
        title={'Please add a card to continue.'}
        shouldDisplayTooltip={isInvalidOrder}
        isFullWidth={true}
        background="dark"
      >
        <Icon name="warning" size="large" primaryColor="danger" />
      </HoverTooltip>
    </Div>
  )

  const desktopWarning = isInvalidOrder && (
    <Flex>
      <Icon name="warning" size="small" primaryColor="danger" />
      <Spacer orientation="horizontal" space="x_75" />
      <Text
        type={'caption'}
        color="danger"
        content="Please add a card to continue."
      />
    </Flex>
  )

  return (
    <Flex
      inset={inset}
      width="100%"
      justifyContent="space-between"
      alignItems="center"
    >
      <Div display="flex" alignItems="center">
        <Div
          borderColor={
            isInvalidOrder ? { swatch: 'danger', shade: 'base' } : 'transparent'
          }
          borderWidth={isInvalidOrder ? 'bold' : undefined}
          borderStyle={isInvalidOrder ? 'solid' : 'hidden'}
          borderRadius="default"
        >
          {isMobile && mobileWarning}
          {itemType === 'card' ? (
            <Image
              width={`${size}px`}
              height={`${size}px`}
              image={{ url: url ?? '' }}
              isActive={true}
            />
          ) : (
            <Div
              display="flex"
              justifyContent="center"
              alignItems="center"
              width={`${size}px`}
              height={`${size}px`}
              borderRadius="default"
              style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }}
            >
              <DSIcon name="empty_gift" size={75} color="#C0C0C0" />
            </Div>
          )}
        </Div>
        <Flex
          flexWrap="wrap"
          inset={{ left: 'x2' }}
          maxWidth={isMobile ? '220px' : 'unset'}
        >
          <Div>
            <Text
              type={typeTitle}
              weight="bold"
              color="primaryHeading"
              content={message}
              lineHeight={1.5}
            />
            {!isMobile && desktopWarning}
          </Div>
          <Spacer orientation="vertical" space="x1" />
          {action.type === 'withAction' ? (
            <HoverTooltip
              title={disabledMessage}
              shouldDisplayTooltip={isDisabled}
              isFullWidth={true}
              background="dark"
            >
              <Button
                id={action.id}
                title={{
                  content: action.title,
                  fontSize: isSmallMobile ? '.75rem' : '1rem',
                }}
                padding={isSmallMobile ? '10px 16px' : '14px 20px'}
                onClick={action.onClick}
                borderRadius="small"
                fill="#000"
                isOutlined={true}
                isDisabled={isDisabled}
                isFullWidth={isSmallMobile}
              >
                {width >= 390 && itemType !== 'card' && (
                  <DSIcon name="gift" size={isSmallMobile ? 16 : 20} />
                )}
              </Button>
            </HoverTooltip>
          ) : (
            <Text content={action.subtitle} type="footnote" lineHeight={1.2} />
          )}
          {secondaryAction && (
            <Div
              onClick={secondaryAction.onClick}
              style={{ cursor: 'pointer' }}
            >
              <text
                style={{
                  fontSize: '0.75rem',
                  fontWeight: 'bold',
                  fontFamily: 'Montserrat-Bold, Montserrat',
                  color: '#E7515D',
                  textDecoration: 'underline',
                }}
              >
                {secondaryAction.title}
              </text>
            </Div>
          )}
        </Flex>
      </Div>
    </Flex>
  )
}
export default AddItemToLine
