import React from 'react'

import styles from './responsiveTable.module.scss'

interface RwdRowProps {
  style?: React.CSSProperties
  id?: string
}

const RwdRow: React.FC<RwdRowProps> = props => {
  const { children, id, ...restProps } = props
  return (
    <tr id={id} className={styles.rwdRow} {...restProps}>
      {children}
    </tr>
  )
}

export default RwdRow
