import { Query } from 'src/legacy_graphql/Query'

import {
  useActions,
  useEffect,
  useGetQueryFutures,
  useMemo,
  useSelector,
} from '.'
import { isNotUndefined } from 'src/helpers'

const prioritizedUseQueryFuturesHook = (priority: number) => <
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  Qs extends (Query<any, any> | undefined)[]
>(
  ...optionalQueries: Qs
) => {
  // Prioritize queries
  const actions = useActions()
  const queries = useMemo(() => optionalQueries.filter(isNotUndefined), [
    optionalQueries,
  ])

  useEffect(() => {
    actions.prioritizedQueries(queries, priority)
    return () => {
      actions.deprioritizedQueries(queries, priority)
    }
  }, [actions, queries])

  // Return query futures
  const queryStates = useSelector(state => state.graphql.queryStates)
  const normalizedData = useSelector(state => state.graphql.normalizedData)

  return useGetQueryFutures(...optionalQueries)(queryStates, normalizedData)
}

export default prioritizedUseQueryFuturesHook
