import {
  useAddCheckoutPromoCode,
  useRemoveCheckoutPromoCode,
} from 'src/react_query/queries/hooks'
import { CheckoutFragment } from 'src/saleor_graphql/generated/graphql'
import usePersistedUserData from './usePersistedUserData'

const useUpdateCheckoutVouchers = () => {
  const { account } = usePersistedUserData()
  const {
    mutate: addCheckoutPromoCode,
    isLoading: isAddLoading,
    isError: isAddError,
  } = useAddCheckoutPromoCode()
  const {
    mutate: removeCheckoutPromoCode,
    isLoading: isRemoveLoading,
    isError: isRemoveError,
  } = useRemoveCheckoutPromoCode()

  const updateCheckoutVouchers = (checkout: CheckoutFragment) => {
    const affiliateFreeVoucherCode = 'affiliatediscount'
    const freeAffiliateProductTypeId = 'UHJvZHVjdFR5cGU6NzA='
    const freeSubscriptionProductTypeId = 'UHJvZHVjdFR5cGU6NDQ='

    const hasFullAccessFreeAffiliate =
      account?.hasFullAccessFreeAffiliate ||
      checkout.lines.some(line => {
        return line.variant.gives90DayFreeAffiliate?.toLowerCase() === 'true'
      })

    const lineVoucherCodes = Array.from(
      new Set(
        checkout.lines
          .map(line => {
            return line.variant.voucherCode?.toLowerCase() ?? ''
          })
          .filter(voucher => voucher !== '')
          .concat(hasFullAccessFreeAffiliate ? [affiliateFreeVoucherCode] : []),
      ),
    )

    const shouldHaveVoucher =
      lineVoucherCodes.length > 0 &&
      checkout.lines.some(line => {
        return [freeAffiliateProductTypeId, freeSubscriptionProductTypeId].some(
          productTypeId =>
            line.variant.product.productType.id === productTypeId,
        )
      })

    if (!checkout.voucherCode && shouldHaveVoucher) {
      addCheckoutPromoCode({
        id: checkout.id,
        promoCode: lineVoucherCodes[0],
      })
    }
    if (checkout.voucherCode && !shouldHaveVoucher) {
      removeCheckoutPromoCode({
        id: checkout.id,
        promoCode: checkout.voucherCode,
      })
    }
  }

  return {
    updateCheckoutVouchers,
    isLoading: isAddLoading || isRemoveLoading,
    isError: isAddError || isRemoveError,
  }
}

export default useUpdateCheckoutVouchers
