import {
  Maybe,
  LayoutCategory,
  LayoutDimensions,
  Owner,
  Operation,
} from '../../../__generated__/globalTypes'

import {
  DetailedLayoutFragment,
  detailedLayoutFragmentString,
} from '../../fragments/__generated__/DetailedLayoutFragment'

import { paragraphFragmentString } from '../../fragments/__generated__/ParagraphFragment'

import { imageFragmentString } from '../../fragments/__generated__/ImageFragment'

import { elementFragmentString } from '../../fragments/__generated__/ElementFragment'

import { textFragmentFragmentString } from '../../fragments/__generated__/TextFragmentFragment'

import { elementPictureFragmentString } from '../../fragments/__generated__/ElementPictureFragment'

import { colorFragmentString } from '../../fragments/__generated__/ColorFragment'

import { panelFragmentString } from '../../fragments/__generated__/PanelFragment'

import { elementTextFragmentString } from '../../fragments/__generated__/ElementTextFragment'

import { layoutFragmentString } from '../../fragments/__generated__/LayoutFragment'

import getDetailedLayoutsRawString from '../GetDetailedLayouts.graphql'

const getDetailedLayoutsString = [
  getDetailedLayoutsRawString,
  detailedLayoutFragmentString,
  paragraphFragmentString,
  imageFragmentString,
  elementFragmentString,
  textFragmentFragmentString,
  elementPictureFragmentString,
  colorFragmentString,
  panelFragmentString,
  elementTextFragmentString,
  layoutFragmentString,
].join('\n\n')

export type GetDetailedLayoutsData = {
  layouts: DetailedLayoutFragment[]
}

export type GetDetailedLayoutsVariables = {
  category?: Maybe<LayoutCategory>
  dimensions?: Maybe<LayoutDimensions>
  owner?: Maybe<Owner>
  offset: number
  limit: number
}

export type GetDetailedLayouts = Operation<
  GetDetailedLayoutsData,
  GetDetailedLayoutsVariables
>

export const GetDetailedLayouts = ({
  category,
  dimensions,
  owner,
  offset,
  limit,
}: GetDetailedLayoutsVariables): GetDetailedLayouts => ({
  query: getDetailedLayoutsString,
  variables: {
    category,
    dimensions,
    owner,
    offset,
    limit,
  },
})
