import React from 'react'
// @src imports
import { Button, Icon } from 'src/chrome'
import {
  CustomCardFragment,
  MinimalSendableCardFragment,
} from 'src/legacy_graphql'
// relative imports
import CardFlip from '../CardFlip/CardFlip'
import styles from './headers.module.scss'

interface Props {
  backgroundStartColor: string
  backgroundEndColor: string
  card?: MinimalSendableCardFragment | CustomCardFragment
  cardPreview: (id: string, typename: 'Card' | 'SendableCard') => void
  handleFavoriteCard: (id: string, isFavorite: boolean) => void
  button?: {
    id: string
    onClick: (id: string) => void
  }
  info: {
    title: string
    description?: string
  }
  onBack?: () => void
  textColor: string
  hasContextBar?: boolean
}

const CatalogHeader: React.FC<Props> = props => {
  const {
    backgroundEndColor,
    backgroundStartColor,
    card,
    cardPreview,
    handleFavoriteCard,
    button,
    info,
    onBack,
    textColor,
    hasContextBar,
  } = props

  return (
    <div
      className={styles.reasonToSend}
      style={{
        backgroundImage: `linear-gradient(115deg, ${backgroundStartColor}, ${backgroundEndColor})`,
      }}
    >
      {onBack && (
        <div
          className={styles.closeCollection}
          style={{ top: hasContextBar ? '56px' : '10px' }}
          onClick={() => onBack()}
        >
          <Icon icon={'ARROWLEFT'} color={textColor} size={18} />
          <span style={{ color: textColor, fontSize: '0.8rem' }}>Back</span>
        </div>
      )}
      <div className={styles.reasonBackground}>
        <div className={styles.featureContent}>
          {card && (
            <div
              id={`reasons_card_preview_${card.id}`}
              className={styles.feature}
              onClick={() => cardPreview(card.id, card.__typename)}
            >
              <CardFlip
                card={card}
                handleFavoriteCard={handleFavoriteCard}
                isButtonEnabled={true}
              />
            </div>
          )}
          <div className={styles.reason}>
            <h2
              id={`${info.title.replace(' ', '_')}_title_id`}
              style={{ color: textColor, fontSize: '2.8rem' }}
            >
              {info.title}
            </h2>
            {info.description && (
              <p style={{ color: textColor, fontSize: '1.1rem' }}>
                {info.description}
              </p>
            )}
            {button && (
              <Button
                titleStyle={{
                  color: '#fff',
                }}
                style={
                  {
                    border: `1px solid ${textColor}`,
                    background: 'transparent',
                  } as React.CSSProperties
                }
                innerStyle={
                  {
                    background: 'transparent',
                  } as React.CSSProperties
                }
                id={`reasons_${button.id}_btn`}
                title={'More Cards'}
                onClick={() => button.onClick(button.id)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CatalogHeader
