import { CreateOrderInput, Operation } from '../../../__generated__/globalTypes'

import {
  DetailedOrderFragment,
  detailedOrderFragmentString,
} from '../../fragments/__generated__/DetailedOrderFragment'

import { amountFragmentString } from '../../fragments/__generated__/AmountFragment'

import { itemizedCostFragmentString } from '../../fragments/__generated__/ItemizedCostFragment'

import { cardFragmentString } from '../../fragments/__generated__/CardFragment'

import { sendableCardWithTemplateFragmentString } from '../../fragments/__generated__/SendableCardWithTemplateFragment'

import { sendableCardFragmentString } from '../../fragments/__generated__/SendableCardFragment'

import { addressFragmentString } from '../../fragments/__generated__/AddressFragment'

import { lineFragmentString } from '../../fragments/__generated__/LineFragment'

import { labeledPriceFragmentString } from '../../fragments/__generated__/LabeledPriceFragment'

import { errorString } from '../../fragments/__generated__/Error'

import { paperCostsFragmentString } from '../../fragments/__generated__/PaperCostsFragment'

import { imageFragmentString } from '../../fragments/__generated__/ImageFragment'

import { sendDelayFragmentString } from '../../fragments/__generated__/SendDelayFragment'

import { elementFragmentString } from '../../fragments/__generated__/ElementFragment'

import { textFragmentFragmentString } from '../../fragments/__generated__/TextFragmentFragment'

import { elementPictureFragmentString } from '../../fragments/__generated__/ElementPictureFragment'

import { giftVariationFragmentString } from '../../fragments/__generated__/GiftVariationFragment'

import { colorFragmentString } from '../../fragments/__generated__/ColorFragment'

import { panelFragmentString } from '../../fragments/__generated__/PanelFragment'

import { priceFragmentString } from '../../fragments/__generated__/PriceFragment'

import { sendableCardImageFragmentString } from '../../fragments/__generated__/SendableCardImageFragment'

import { elementTextFragmentString } from '../../fragments/__generated__/ElementTextFragment'

import { paragraphFragmentString } from '../../fragments/__generated__/ParagraphFragment'

import createOrderRawString from '../CreateOrder.graphql'

const createOrderString = [
  createOrderRawString,
  detailedOrderFragmentString,
  amountFragmentString,
  itemizedCostFragmentString,
  cardFragmentString,
  sendableCardWithTemplateFragmentString,
  sendableCardFragmentString,
  addressFragmentString,
  lineFragmentString,
  labeledPriceFragmentString,
  errorString,
  paperCostsFragmentString,
  imageFragmentString,
  sendDelayFragmentString,
  elementFragmentString,
  textFragmentFragmentString,
  elementPictureFragmentString,
  giftVariationFragmentString,
  colorFragmentString,
  panelFragmentString,
  priceFragmentString,
  sendableCardImageFragmentString,
  elementTextFragmentString,
  paragraphFragmentString,
].join('\n\n')

export type CreateOrderData = {
  createOrder: {
    order: DetailedOrderFragment
  }
}

export type CreateOrderVariables = {
  order: CreateOrderInput
}

export type CreateOrder = Operation<CreateOrderData, CreateOrderVariables>

export const CreateOrder = ({ order }: CreateOrderVariables): CreateOrder => ({
  query: createOrderString,
  variables: {
    order: CreateOrderInput(order),
  },
})
