import React from 'react'
import { Div } from '@sendoutcards/quantum-design-ui'
import { useState } from 'src/hooks'
import { BorderRadius } from '@sendoutcards/quantum-design-ui/dist/src/helpers/hoc-types/hocBaseUnion'
import HoverTooltip from '../HoverTooltip/HoverTooltip'

interface CardItemOptionProps {
  width?: string
  height?: string
  borderRadius?: BorderRadius
  onClick?: () => void
  id?: string
  isDisabled?: boolean
  disabledMessage?: string
  horizontalInset?: string
}

const styles = {
  wrapper: {
    '&:active': {
      background: '#fcfcfc',
    },
    '&:focus': {
      border: 'solid 1.5px hsl(323,100%,51%)',
    },
  },
}

const CardItemOption: React.FC<CardItemOptionProps> = props => {
  const {
    children,
    width,
    height,
    borderRadius = 'default',
    onClick,
    id,
    isDisabled,
    disabledMessage,
    horizontalInset,
  } = props

  const [isHover, setIsHover] = useState(false)

  return (
    <HoverTooltip
      title={disabledMessage}
      shouldDisplayTooltip={isDisabled}
      isFullWidth={true}
      background="dark"
    >
      <Div
        id={id}
        onClick={isDisabled ? undefined : onClick}
        display="flex"
        alignItems="center"
        justifyContent="center"
        onHoverStart={
          onClick && !isDisabled ? () => setIsHover(true) : undefined
        }
        onHoverEnd={onClick ? () => setIsHover(false) : undefined}
        whileHover={onClick && !isDisabled ? { y: -2 } : undefined}
        backgroundColor={isHover ? 'foreground' : 'background'}
        borderRadius={borderRadius}
        boxShadow={isHover ? 'light' : 'none'}
        height={height ? height : '50px'}
        width={width ? width : 'auto'}
        inset={{
          vertical: 'x1',
          horizontal: horizontalInset ? horizontalInset : 'x2',
        }}
        cursor={onClick ? 'pointer' : undefined}
        css={onClick ? styles.wrapper : { userSelect: 'none' }}
        tabIndex={0}
        opacity={isDisabled ? 0.35 : 1}
      >
        {children}
      </Div>
    </HoverTooltip>
  )
}

export default CardItemOption
