import React from 'react'
import { SVGProps } from './types'

export const NotificationIcon: React.FC<SVGProps> = ({
  size = 24,
  color = '#323232',
  onClick,
}) => {
  return (
    <svg
      height={size}
      width={size}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick && (() => onClick())}
    >
      <path
        d="M11.9999 0.25C12.4142 0.25 12.7499 0.585786 12.7499 1V2.2912C16.1249 2.66428 18.7499 5.52558 18.7499 9V10.1036C18.7499 11.7996 19.3446 13.442 20.4303 14.7449L20.8425 15.2395C22.3351 17.0306 21.0614 19.75 18.7299 19.75H15.7499V20C15.7499 22.0711 14.071 23.75 11.9999 23.75C9.92888 23.75 8.24995 22.0711 8.24995 20V19.75H5.27003C2.93848 19.75 1.66479 17.0306 3.15742 15.2395L3.56955 14.7449C4.65533 13.442 5.24994 11.7996 5.24994 10.1036V9C5.24994 5.52558 7.87498 2.66428 11.2499 2.2912V1C11.2499 0.585786 11.5857 0.25 11.9999 0.25ZM9.74995 19.75V20C9.74995 21.2426 10.7573 22.25 11.9999 22.25C13.2426 22.25 14.2499 21.2426 14.2499 20V19.75H9.74995ZM5.27003 18.25C4.21023 18.25 3.63129 17.0139 4.30975 16.1998L4.72188 15.7052C6.0323 14.1327 6.74995 12.1505 6.74995 10.1036V9C6.74995 6.10051 9.10045 3.75 11.9999 3.75C14.8994 3.75 17.2499 6.10051 17.2499 9V10.1036C17.2499 12.1505 17.9676 14.1327 19.278 15.7052L19.6901 16.1998C20.3686 17.0139 19.7897 18.25 18.7299 18.25H5.27003Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  )
}
