import { Flex } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { useSelector, useState } from 'src/hooks'
import TopLeaders from './components/TopLeaders'
import LeaderList from './components/LeaderList'
import {
  LeaderBoardFragment,
  LeaderFragment,
} from 'src/graphql/generated/graphql'
export type LeaderType = Omit<LeaderFragment, '__typename'>

const LeaderBoard: React.FC<LeaderBoardFragment & { isListOpen?: boolean }> = ({
  title,
  description,
  listTitle,
  metricTitle,
  rankTitle,
  challenges,
  shouldShowPastChallenge,
  gradient,
  userAccess,
  type,
  isListOpen = true,
}) => {
  const [shouldShowCurrentChallenge, setShouldShowCurrentChallenge] = useState(
    true,
  )
  const isTablet = useSelector(state => state.window.width <= 1500)
  const [activeChallenge, pastChallenge] = challenges

  const isPastChallenge = pastChallenge && shouldShowPastChallenge

  const currentLeaders = activeChallenge.leaders
  const pastLeaders = pastChallenge?.leaders
  const currentEarnedCount = activeChallenge.authUserEarnedCount

  const leaderList =
    !shouldShowCurrentChallenge && pastLeaders ? pastLeaders : currentLeaders
  const filteredLeaders = leaderList?.filter(
    (leader: LeaderFragment) =>
      leader?.firstName !== 'SendOutCards' && leader?.lastName !== 'Promotions',
  )
  const [first, second, third, ...contestants] = filteredLeaders

  const formatDateRange = (startDate: string, endDate: string) => {
    const formatSingleDate = (date: string): string => {
      const dateObj = new Date(date)
      const day = dateObj.getDate().toString().padStart(2, '0') // Ensure two-digit day
      const month = (dateObj.getMonth() + 1).toString().padStart(2, '0') // Ensure two-digit month
      const year = dateObj.getFullYear().toString().slice(-2) // Get last two digits of the year
      return `${month}/${day}/${year}`
    }
    const formattedStartDate = formatSingleDate(startDate)
    const formattedEndDate = formatSingleDate(endDate)
    return `${formattedStartDate} - ${formattedEndDate}`
  }

  const activeChallengeDateRange = formatDateRange(
    shouldShowCurrentChallenge
      ? activeChallenge.startDate
      : pastChallenge.startDate,
    shouldShowCurrentChallenge
      ? activeChallenge.endDate
      : pastChallenge.endDate,
  )
  const handleToggle = () => {
    if (pastLeaders && pastLeaders.length > 0) {
      setShouldShowCurrentChallenge(!shouldShowCurrentChallenge)
    }
    return
  }

  return (
    <Flex
      flexDirection="column"
      inset={isTablet ? '0' : 'x3'}
      outset={isTablet ? { top: 'x3' } : 'x0'}
      justifyContent={'center'}
      alignItems={'center'}
      position="relative"
      width="100%"
    >
      <TopLeaders
        leaders={[first, second, third].filter(leader => leader !== undefined)}
        title={title}
        description={description}
        dateRange={activeChallengeDateRange}
        metricDescription={metricTitle}
        onToggle={handleToggle}
        isSelected={shouldShowCurrentChallenge}
        isPastChallenge={isPastChallenge}
        gradient={gradient ?? '102deg, #FFE6D6 0%, #F7E7FF 100%'}
      />
      <LeaderList
        title={listTitle}
        metricDescription={metricTitle}
        placementTitle={rankTitle}
        leaderList={contestants.filter(
          (leader: LeaderFragment): leader is LeaderFragment => leader !== null,
        )}
        authUserEarnedCount={currentEarnedCount ?? 0}
        isListOpen={isListOpen}
        leaderboardType={type}
      />
    </Flex>
  )
}

export default LeaderBoard
