import React from 'react'

type RibbonType = {
  height?: string
  width?: string
  fill?: string
}

export function Ribbon({
  height = '8px',
  width = '10px',
  fill = '#fff',
}: RibbonType) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 12 16"
    >
      <path
        fill={fill}
        d="M6 10.79C2.92 10.79.419 8.373.419 5.396.419 2.42 2.919 0 6 0c3.08 0 5.581 2.419 5.581 5.395 0 2.977-2.5 5.396-5.581 5.396Zm0-9.674c-2.463 0-4.465 1.92-4.465 4.28 0 2.358 2.002 4.278 4.465 4.278s4.465-1.92 4.465-4.279S8.463 1.116 6 1.116Z"
      />
      <path
        fill={fill}
        d="M8.694 16c-.208 0-.417-.052-.633-.149l-2.001-.945a.406.406 0 0 0-.134 0l-1.987.938c-.44.208-.9.2-1.258-.03-.372-.238-.588-.677-.58-1.198l.007-5.492c0-.305.238-.573.558-.558.305 0 .558.253.558.558l-.007 5.492c0 .164.044.245.067.253.015.007.082.015.186-.037l1.994-.945a1.36 1.36 0 0 1 1.08 0l2.001.945c.104.052.171.044.186.037.023-.015.067-.097.067-.253V8.99c0-.305.253-.558.558-.558.305 0 .558.253.558.558v5.626c0 .528-.215.96-.587 1.198a1.14 1.14 0 0 1-.633.186Z"
      />
    </svg>
  )
}
