import React from 'react'
import { Flex, Text } from '@sendoutcards/quantum-design-ui'
import useSelector from 'src/hooks/useSelector'

type SheetHeaderProps = {
  title: string
  elementLocation: string
  actionItem?: React.ReactNode
}

export const SheetHeader: React.FC<SheetHeaderProps> = ({
  title,
  elementLocation,
  actionItem,
}) => {
  const uploadImageBreakpoint = useSelector(state => state.window.width)
  return (
    <Flex
      id="sheetHeader-main-container"
      width="100%"
      flexDirection="row"
      flexWrap={uploadImageBreakpoint < 360 ? 'wrap' : 'nowrap'}
      data-should-drag="true"
      justifyContent="space-between"
      outset={{ bottom: 'x3' }}
    >
      <Flex
        flexDirection="column"
        data-should-drag="true"
        outset={{ left: 'x1', right: 'x2' }}
      >
        <Text
          type="largeBody"
          color="primaryHeading"
          weight="bold"
          content={title}
        />
        <Text
          type="caption"
          color="primaryBody"
          weight="semiBold"
          content={`Element Location: ${elementLocation} panel `}
        />
      </Flex>
      {actionItem && (
        <Flex
          justifyContent={
            uploadImageBreakpoint > 338 ? 'flex-start' : 'flex-end'
          }
          alignItems="center"
          width="162px"
          data-should-drag="true"
        >
          {actionItem}
        </Flex>
      )}
    </Flex>
  )
}
