import bankAccountFragmentRawString from '../BankAccountFragment.graphql'

export const bankAccountFragmentString = bankAccountFragmentRawString

export type BankAccountFragment = {
  __typename: 'BankAccount'
  id: string
  bankName: string
  object: string
  last4: string
  accountHolderName: string
}

export const isBankAccountFragment = (
  fragment: any,
): fragment is BankAccountFragment =>
  fragment &&
  fragment.__typename === 'BankAccount' &&
  typeof fragment.id === 'string' &&
  typeof fragment.bankName === 'string' &&
  typeof fragment.object === 'string' &&
  typeof fragment.last4 === 'string' &&
  typeof fragment.accountHolderName === 'string'
