import React from 'react'

import {
  Button,
  Div,
  Flex,
  LoadingSpinner,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { CampaignSubcategoryProduct } from './CampaignSubcategoryProduct'
import {
  CampaignStoreProductFragment,
  CampaignStoreSubcategoryFragment,
} from 'src/saleor_graphql/generated/graphql'
import { useCampaignStoreSubcategoryProducts } from 'src/react_query/queries/hooks'

export const CampaignSubcategory = (props: {
  edge: CampaignStoreSubcategoryFragment
  cursor: string
  onView: (id: string) => void
}) => {
  const hasAdditionalProducts = !!props.edge.products?.pageInfo.hasNextPage
  const cursor = props.edge.products?.pageInfo.endCursor
  const {
    data,
    status,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useCampaignStoreSubcategoryProducts(
    { id: props.edge.id, productsFirst: 10, productsAfter: cursor },
    hasAdditionalProducts,
  )

  const nodes = props.edge.products?.edges.map(edge => edge.node) ?? []
  const products =
    status === 'success'
      ? [
          ...nodes,
          ...data.pages.flatMap(page => page?.edges.map(edge => edge.node)),
        ]
      : nodes

  return (
    <Div>
      <Text type="largeBody" weight="semiBold">
        {props.edge.name}
      </Text>
      <Flex
        inset={{ vertical: 'x2' }}
        style={{ gap: '16px' }}
        flexWrap="wrap"
        justifyContent={{ xSmall: 'center', medium: 'flex-start' }}
        width="100%"
      >
        {products
          ?.filter((node): node is CampaignStoreProductFragment =>
            Boolean(node),
          )
          .map(node => {
            return (
              <CampaignSubcategoryProduct
                key={node.id}
                product={node}
                onView={props.onView}
              />
            )
          })}
      </Flex>
      {hasNextPage && (
        <Flex justifyContent={'center'}>
          {isFetchingNextPage ? (
            <LoadingSpinner size="large" />
          ) : (
            <Button
              type="primary"
              outlined={true}
              onClick={() => fetchNextPage()}
            >
              {`Load More ${props.edge.name}`}
            </Button>
          )}
        </Flex>
      )}
    </Div>
  )
}
