export interface Gradients {
  orange: string
  pink: string
  limeade: string
  blue: string
  facebook: string
  pink2: string
  green: string
  brown: string
  blueGreen: string
  bella: string
  blue42: string
  greenerstill: string
  promptingu: string
  streammrkt: string
  streamva: string
  bellaPrimary: string
  blue42Primary: string
  greenerstillPrimary: string
  promptinguPrimary: string
  streammrktPrimary: string
  streamvaPrimary: string
}

export type GradientChoices = keyof Gradients

const gradients: Gradients = {
  orange: 'linear-gradient(28deg, #dd2476 0%, #fe5030 100%)',
  pink: 'linear-gradient(115deg, #ff5689, #e687ff)',
  limeade: 'linear-gradient(137deg, #75deb0, #cce4a8)',
  blue: 'linear-gradient(115deg, #02C3E2, #02C3E2)',
  facebook: 'linear-gradient(9deg, #176be0, #262b9a)',
  pink2: 'linear-gradient(111deg, #f237ef, #fc5252)',
  green: 'linear-gradient(109deg, #56DCDE, #61E2AD)',
  brown: 'linear-gradient(109deg, #E094C0, #D5776C)',
  blueGreen: 'linear-gradient(99deg, #2DC9EB, #14D2B8)',
  bella: 'linear-gradient(99deg, #525a59, #525a59)',
  blue42: 'linear-gradient(99deg, #35a4bc, #35a4bc)',
  greenerstill: 'linear-gradient(99deg, #52C1D1, #52C1D1)',
  promptingu: 'linear-gradient(99deg, #a99a67, #a99a67)',
  streammrkt: 'linear-gradient(99deg, #314a51, #314a51)',
  streamva: 'linear-gradient(99deg, #1f4177, #1f4177)',
  bellaPrimary: 'linear-gradient(99deg, #ce8e47, #ce8e47)',
  blue42Primary: 'linear-gradient(99deg, #140fee, #140fee)',
  greenerstillPrimary: 'linear-gradient(99deg, #74C468, #74C468)',
  promptinguPrimary: 'linear-gradient(99deg, #1f4177, #1f4177)',
  streammrktPrimary: 'linear-gradient(99deg, #b38048, #b38048)',
  streamvaPrimary: 'linear-gradient(99deg, #61dce8, #61dce8)',
}

export default gradients
