import React from 'react'
import { Div, Loader } from '@sendoutcards/quantum-design-ui'

export const CampaignSubcategoryProductLoader = () => {
  return (
    <Div
      boxShadow="mediumLight"
      borderRadius={'small'}
      backgroundColor={'foreground'}
      inset={{ top: 'x1', bottom: 'x1', horizontal: 'x1' }}
      display="flex"
      flexDirection="column"
      width={'280px'}
      minWidth={'280px'}
      alignItems="start"
      justifyContent={'start'}
      position="relative"
      style={{ gap: '12px' }}
    >
      <Loader width={264} height={372}>
        <rect width={264} height={200} />
        <rect width={224} height={20} y={220} x={0} rx={6} ry={6} />
        <rect width={264} height={8} y={254} x={0} rx={6} ry={6} />
        <rect width={200} height={8} y={274} x={0} rx={6} ry={6} />
        <rect width={264} height={8} y={292} x={0} rx={6} ry={6} />
        <rect width={100} height={32} y={340} x={180} rx={6} ry={6} />
      </Loader>
    </Div>
  )
}
