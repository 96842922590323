import { Maybe } from '../../../__generated__/globalTypes'

import minimalOrderFragmentRawString from '../MinimalOrderFragment.graphql'

export const minimalOrderFragmentString = minimalOrderFragmentRawString

export type MinimalOrderFragment = {
  __typename: 'Order'
  id: string
  minimalLines: {
    __typename: 'Line'
    card: Maybe<{
      __typename: 'Card'
      id: string
      frontPreviewUrl: string
    }>
    giftVariation: Maybe<{
      __typename: 'GiftVariation'
      id: string
      imageUrls: string[]
    }>
  }[]
  updatedAt: string
}

export const isMinimalOrderFragment = (
  fragment: any,
): fragment is MinimalOrderFragment =>
  fragment &&
  fragment.__typename === 'Order' &&
  typeof fragment.id === 'string' &&
  Array.isArray(fragment.minimalLines) &&
  fragment.minimalLines
    .slice(0, 5)
    .reduce(
      (accum: any, next: any) =>
        accum &&
        next &&
        next.__typename === 'Line' &&
        (next.card === null ||
          (next.card &&
            next.card.__typename === 'Card' &&
            typeof next.card.id === 'string' &&
            typeof next.card.frontPreviewUrl === 'string')) &&
        (next.giftVariation === null ||
          (next.giftVariation &&
            next.giftVariation.__typename === 'GiftVariation' &&
            typeof next.giftVariation.id === 'string' &&
            Array.isArray(next.giftVariation.imageUrls) &&
            next.giftVariation.imageUrls
              .slice(0, 5)
              .reduce(
                (accum: any, next: any) => accum && typeof next === 'string',
                true,
              ))),
      true,
    ) &&
  typeof fragment.updatedAt === 'string'
