import React from 'react'
import { Icon } from 'src/chrome'

import styles from './panelTrigger.module.scss'

interface PanelTriggerProps {
  textFunction: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
  templateFunction: (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) => void
  optionOneColor?: string
  optionTwoColor?: string
}

const PanelTrigger: React.FC<PanelTriggerProps> = props => (
  <div className={styles.options}>
    <span
      className={styles.optionOne}
      id={'edit_text_btn'}
      onClick={props.textFunction}
    >
      <Icon icon={'EDIT'} size={18} color={props.optionOneColor ?? '#A9979D'} />
    </span>
    <span
      className={styles.optionTwo}
      id={'edit_panel_btn'}
      onClick={props.templateFunction}
    >
      <Icon
        icon={'TEMPLATES'}
        size={18}
        stroke={props.optionTwoColor ?? '#A9979D'}
        color={'none'}
        strokeWidth={'35.5455'}
        viewBox={'0 0 838 1024'}
      />
    </span>
  </div>
)

export default PanelTrigger
