import { Card, Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import { BorderStyles } from '@sendoutcards/quantum-design-ui/dist/src/helpers/hoc-types/cssValueTypes'
import React from 'react'
import { useActions, useSelector, useState } from 'src/hooks'
import AddItemToLine from 'src/chrome/AddItemToLine/AddItemToLine'
import { OrderItemProps } from '../OrderItem/OrderItem'
import { OrderPreviewCardLineItem } from './OrderPreviewCardLineItem'
import { OrderPreviewGiftLineItem } from './OrderPreviewGiftLineItem'
import CardPreviewModal from '../CardPreviewModal/CardPreviewModal'
import { PartialLine } from 'src/helpers/multitouch'
import { RemoveButton } from '../RemoveButton/RemoveButton'
import useOrderLinePricing from 'src/hooks/useOrderLinePricing'
import useDidFlattenFail from 'src/orders/hooks/useDidFlattenFail'
import { CardFragment, CardSendType } from 'src/graphql/generated/graphql'

export type OrderPreviewItemProps = {
  isActive: boolean
  onRemoveCard?: (line: PartialLine) => void
  onChangeCard?: (card: CardFragment) => void
  onAddCard?: (line: PartialLine, index: number) => void
  isCurrentlyAdding: 'gift' | 'card'
  isAddingNewItem?: boolean
  replaceCardId?: string
  orderId?: string
  orderSendType?: CardSendType
  editorCard?: CardFragment
}

export const OrderPreviewItem = (
  props: OrderItemProps & OrderPreviewItemProps,
) => {
  const {
    title,
    orderLine,
    giftItem,
    cardItem,
    onRemoveLineAlert,
    isActive,
    onAddCard,
    onAddGift,
    lineIndex,
    onChangeCard,
    isCurrentlyAdding,
    isAddingNewItem = false,
    replaceCardId,
    orderId,
    orderSendType,
    editorCard,
  } = props

  const [isShowingCardPreview, setIsShowingCardPreview] = useState(false)

  const lineNumber = lineIndex + 1
  const isCurrentlyReplacing = orderLine?.card?.id === replaceCardId

  const isSelected = isCurrentlyReplacing || isActive

  const borderStyles: {
    borderColor?: string
    borderStyle?: BorderStyles
    borderWidth?: 'default' | 'thin' | 'bold'
  } = {
    borderColor: isSelected ? '#000000' : undefined,
    borderStyle: isSelected ? 'solid' : undefined,
    borderWidth: isSelected ? 'default' : undefined,
  }

  const quantity = orderLine?.recipientCount ?? 0
  const { isMobile } = useSelector(state => state.window)
  const actions = useActions()

  const orderLinePricing = useOrderLinePricing(orderSendType, orderLine)

  const { didFlattenFail } = useDidFlattenFail(
    orderLine && orderLine.card ? orderLine.card : undefined,
  )

  const defaultCardLineItem =
    orderLine && orderLine.card && !isCurrentlyReplacing ? (
      <OrderPreviewCardLineItem
        quantity={quantity}
        card={orderLine.card}
        cost={{
          price: orderLinePricing.cardPrice,
          discountPrice: orderLinePricing.cardDiscountedPrice,
          discountMessage: orderLinePricing.cardDiscountMessage,
        }}
        onRemoveCard={cardItem.onRemoveCard}
        onReplaceCard={cardItem.onReplaceCard}
        onEditCard={() => setIsShowingCardPreview(true)}
        didFlattenFail={didFlattenFail}
      />
    ) : orderLine && orderLine.card && isCurrentlyReplacing ? (
      <AddItemToLine
        itemType="card"
        inset={{ left: 'x1' }}
        size={94}
        message={'Replacing Your Card'}
        action={{
          type: 'withoutAction',
          subtitle: `Select and edit a new card to replace your current card.`,
        }}
        url={orderLine.card.frontPreviewUrl}
        secondaryAction={{
          title: 'Cancel Replacing This Card',
          onClick: () => {
            if (orderId) actions.openOrder(orderId)
          },
        }}
      />
    ) : (
      <AddItemToLine
        itemType="card"
        inset={{ left: 'x1' }}
        size={94}
        message={'No Attached Card'}
        action={{ type: 'withAction', onClick: onAddCard, title: 'Add Card' }}
      />
    )

  const defaultGiftLineItem =
    orderLine && orderLine.giftVariation ? (
      <OrderPreviewGiftLineItem
        quantity={quantity}
        lineIndex={lineIndex}
        isCardHorizontal={orderLine.card?.isHorizontal ?? false}
        onRemoveGift={giftItem.onRemoveGift}
        giftItem={{
          title: orderLine.giftVariation.name,
          thumbnailURL: orderLine.giftVariation.defaultImageUrl ?? '',
          isSendable: orderLine.giftVariation.isSendable,
          price: {
            price: orderLinePricing.giftPrice,
            discountPrice: orderLinePricing.giftDiscountPrice,
            discountMessage: orderLinePricing.giftDiscountMessage,
          },
        }}
      />
    ) : (
      <AddItemToLine
        itemType="gift"
        size={94}
        message={'Add a Gift'}
        action={{
          type: 'withAction',
          onClick: onAddGift,
          title: 'Add Gift',
          id: 'add_gift_from_preview',
        }}
      />
    )

  return (
    <Card
      {...borderStyles}
      backgroundColor="#FFFFFF"
      inset={{ horizontal: 'x1', top: 'x2', bottom: 'x3' }}
      borderRadius="large"
      position="relative"
    >
      <Flex
        id={`order_preview_item_${lineIndex + 1}`}
        justifyContent="space-between"
        width="100%"
        inset={{ left: 'x1', right: 'x_5', bottom: 'x2' }}
        alignItems="center"
      >
        <Text
          outset={{ left: 'x_5' }}
          type="body"
          color="primaryHeading"
          content={title}
          weight="bold"
        />
        <RemoveButton
          title={'Remove Card & Gift(s)'}
          isMobile={isMobile}
          onClick={onRemoveLineAlert}
          color="#E7515D"
          size={13}
          id={`remove-order_preview_line_item_${lineIndex + 1}`}
        />
      </Flex>
      {orderLine?.card && isShowingCardPreview && (
        <CardPreviewModal
          card={orderLine.card}
          itemIndex={lineIndex}
          isOpen={isShowingCardPreview}
          onClose={() => setIsShowingCardPreview(!isShowingCardPreview)}
          onEditCard={card => {
            onChangeCard?.(card)
            setIsShowingCardPreview(false)
          }}
          onReplaceCard={card => {
            cardItem?.onReplaceCard?.(card)
            setIsShowingCardPreview(false)
          }}
        />
      )}
      {isActive ? (
        isAddingNewItem && isCurrentlyAdding !== 'card' ? (
          <AddItemToLine
            itemType="card"
            inset={{ left: 'x1' }}
            size={94}
            message={'No Attached Card'}
            action={{
              type: 'withoutAction',
              subtitle: `A card has not yet been selected for Card ${lineNumber}`,
            }}
          />
        ) : isCurrentlyAdding === 'card' ? (
          orderLine && orderLine.card ? (
            <AddItemToLine
              itemType="card"
              inset={{ left: 'x1' }}
              size={94}
              message={'Editing Your Card'}
              url={editorCard?.frontPreviewUrl}
              action={{
                type: 'withoutAction',
                subtitle: `When you are finished editing, click “checkout” to continue.`,
              }}
            />
          ) : (
            <AddItemToLine
              itemType="card"
              inset={{ left: 'x1' }}
              size={94}
              message={'Currently Adding Card'}
              url={editorCard?.frontPreviewUrl}
              action={{
                type: 'withoutAction',
                subtitle: `Adding your personalized card to Card ${lineNumber}`,
              }}
            />
          )
        ) : (
          defaultCardLineItem
        )
      ) : (
        defaultCardLineItem
      )}
      <Div width="100%" minHeight="18px" />
      {isActive ? (
        isAddingNewItem ? (
          <AddItemToLine
            itemType="gift"
            inset={{ left: 'x1' }}
            size={94}
            message={'Add a Gift'}
            action={{
              type: 'withoutAction',
              subtitle: `A gift has not yet been selected for Card ${lineNumber}`,
            }}
          />
        ) : isCurrentlyAdding === 'gift' &&
          orderLine &&
          orderLine.giftVariation ? (
          defaultGiftLineItem
        ) : (
          <AddItemToLine
            itemType="gift"
            inset={{ left: 'x1' }}
            size={94}
            message={'Currently Adding Gift'}
            action={{
              type: 'withoutAction',
              subtitle: `Attaching Gift on Card ${lineNumber}`,
            }}
          />
        )
      ) : (
        defaultGiftLineItem
      )}
      {isActive && (
        <Div
          backgroundColor="#000000"
          inset={{ horizontal: 'x2_5', vertical: 'x1' }}
          borderRadius="large"
          position="absolute"
          bottom="-14px"
        >
          <Text
            lineHeight={1}
            type="footnote"
            weight="bold"
            color="inverseBody"
            content="Currently Shopping"
          />
        </Div>
      )}
    </Card>
  )
}
