import React from 'react'
import {
  Flex,
  LoadingSpinner,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { emptyShippingAddress } from 'src/app/constants'

import { useCallback, useEffect, useState, useVertical } from 'src/hooks'
import { ShippingAddress, UserProfileForm } from 'src/dashboard/components'
import { ProfileAvatar, SuspenseBoundary, UiCard } from 'src/chrome'
import { ButtonColorChoices } from 'src/theme/buttonColors'
import { useAccountQuery, useUpdateAccount } from 'src/react_query'
import { AddressFragment, AddressInput } from 'src/graphql/generated/graphql'
import omit from 'lodash/omit'
import { mapNullEntries } from 'src/helpers'

export const AccountInfoSettingsView = () => {
  const { data: account } = useAccountQuery()
  const vertical = useVertical()
  const updateAccount = useUpdateAccount()
  const [isAddressVisible, setIsAddressVisible] = useState(false)
  const [isSavingAddress, setIsSavingAddress] = useState(false)

  const [shippingAddress, setShippingAddress] = useState<
    AddressInput | undefined
  >()

  useEffect(() => {
    if (account && !shippingAddress) {
      setShippingAddress(
        omit(
          {
            ...emptyShippingAddress,
            ...(account.shippingAddress
              ? (mapNullEntries(account.shippingAddress, {
                  country: 'United States',
                }) as AddressFragment)
              : {}),
          },
          '__typename',
        ),
      )
    }
  }, [account, shippingAddress])

  const handleShowAddress = useCallback(() => {
    setIsAddressVisible(prevShowAddress => !prevShowAddress)
  }, [])

  const submitAddress = useCallback(
    async (address: AddressInput) => {
      setIsSavingAddress(true)
      try {
        await updateAccount.mutateAsync({
          account: {
            shippingAddress: address,
          },
        })
        setShippingAddress(address)
        setIsAddressVisible(false)
      } finally {
        setIsSavingAddress(false)
      }
    },
    [updateAccount],
  )

  return (
    <Flex flexDirection={'column'} width="100%" height="100%">
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <Flex
          flexDirection="column"
          backgroundColor="foreground"
          inset={{ top: 'x3', bottom: 'x4' }}
          width="100%"
          justifyContent="center"
          alignItems="center"
        >
          <ProfileAvatar
            account={account}
            size={100}
            style={
              {
                fontSize: '2.8rem',
                color: '#fff',
                letterSpacing: 1,
              } as React.CSSProperties
            }
            isEditable={true}
          />
          <Flex>
            <Text type="body" color="primaryHeading">
              {`My ID Number:`}
            </Text>
            <Spacer space="x1" orientation="horizontal" />
            <Text type="body">{account?.genealogyId ?? 'Loading'}</Text>
          </Flex>
        </Flex>
        <Flex
          inset={{ vertical: 'x2', horizontal: 'x3' }}
          width="100%"
          flexDirection="column"
        >
          <SuspenseBoundary
            unresolved={<LoadingSpinner size="medium" />}
            failure={error => `Failed loading: ${error}`}
          >
            <UserProfileForm />
          </SuspenseBoundary>
          <UiCard
            title={'My Address'}
            actions={[
              {
                title: !isAddressVisible ? 'Edit address' : 'Close',
                onClick: handleShowAddress,
              },
            ]}
            buttonColor={vertical.id as ButtonColorChoices}
          >
            {shippingAddress ? (
              <ShippingAddress
                isSavingAddress={isSavingAddress}
                shippingAddress={shippingAddress}
                isAddressVisible={isAddressVisible}
                onSubmitAddress={submitAddress}
              />
            ) : (
              <LoadingSpinner size="medium" />
            )}
          </UiCard>
        </Flex>
      </Flex>
    </Flex>
  )
}
