import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import {
  closePhoneNumberVerification,
  loadedUser,
  loginDone,
  logout,
  needsRefreshedAccount,
  openPhoneNumberVerification,
  refreshedAccount,
  saveToken,
  setNoPhoneFound,
  setProgressiveProfilingDisplayedAt,
  setUnverifiedAccountsFound,
  setUpsaleDisplayedAt,
  toggleIsBlankCardModalOpen,
  unloadUser,
  updatedAccount,
  updatedAccountShippingAddress,
} from 'src/redux/actions/user'
import Action from 'src/redux/action'
import { openRoute } from 'src/redux/actions/routes'
import {
  AccountFragment,
  PhoneNumberInput,
} from 'src/graphql/generated/graphql'

const LOGIN_EXPIRATION_TIME = 1000 * 60 * 15 // 15 minutes

type PhoneVerification = {
  loginPhoneNumber: PhoneNumberInput | null
  unverifiedAccountsFound?: true
  noPhoneFound?: true
  shouldNotCreateNewAccount?: boolean | undefined
  shouldReassignNumber?: boolean | undefined
}

type ControlData = {
  isLoggedIn: boolean
  token: string | null | undefined
  shouldNotRememberMe?: true
  loginExpirationDate?: number
  shouldShowPhoneVerification?: true
  isBlankCardModalOpen?: true
  progressiveProfilingDisplayedAt: string
  byDesignLoginLink?: string
  upsaleDisplayedAt?: string
  needsAccountRefresh?: true
}

type UnpersistedAccountState = {
  account?: AccountFragment
  controlData: ControlData
  phoneVerification: PhoneVerification
}

const initialState: UnpersistedAccountState = {
  phoneVerification: {
    loginPhoneNumber: null,
  },
  controlData: {
    isLoggedIn: false,
    token:
      // To specify a token from the environment make sure you first clear
      // any persisted account state (i.e. by logging out)
      (process.env.NODE_ENV === 'development' && process.env.REACT_APP_TOKEN) ||
      undefined,
    progressiveProfilingDisplayedAt: '',
    upsaleDisplayedAt: '',
    needsAccountRefresh: undefined,
  },
}

const unpersistedAccount = (
  state: UnpersistedAccountState = initialState,
  action: Action,
): UnpersistedAccountState => {
  switch (action.type) {
    case openRoute.type:
      if (
        (action.args.access_token &&
          action.args.access_token !== state.controlData.token) ||
        state.controlData.token === undefined
      ) {
        return {
          controlData: {
            isLoggedIn: false,
            token: action.args.access_token ?? null,
            shouldShowPhoneVerification:
              'verify' in action.args ? true : undefined,
            progressiveProfilingDisplayedAt: '',
            upsaleDisplayedAt: '',
            needsAccountRefresh: undefined,
          },
          phoneVerification: {
            loginPhoneNumber: null,
            unverifiedAccountsFound: state.phoneVerification
              .unverifiedAccountsFound
              ? true
              : undefined,
          },
        }
      } else if (!state.controlData.isLoggedIn) {
        return {
          controlData: {
            isLoggedIn: false,
            token: state.controlData.token,
            shouldShowPhoneVerification:
              'verify' in action.args
                ? true
                : state.controlData.shouldShowPhoneVerification,
            progressiveProfilingDisplayedAt: '',
            upsaleDisplayedAt: '',
            needsAccountRefresh: undefined,
          },
          phoneVerification: {
            loginPhoneNumber: null,
            unverifiedAccountsFound: state.phoneVerification
              .unverifiedAccountsFound
              ? true
              : undefined,
          },
        }
      } else if (state.controlData.shouldNotRememberMe) {
        return {
          ...state,
          controlData: {
            ...state.controlData,
            loginExpirationDate: Date.now() + LOGIN_EXPIRATION_TIME,
          },
        }
      } else {
        return state
      }
    case logout.type:
      return {
        controlData: {
          isLoggedIn: false,
          token: null,
          progressiveProfilingDisplayedAt: '',
          upsaleDisplayedAt: '',
          needsAccountRefresh: undefined,
        },
        phoneVerification: {
          loginPhoneNumber: null,
        },
      }
    case loadedUser.type:
      return action.account.value
        ? {
            account: action.account.value,
            controlData: {
              isLoggedIn: true,
              token: action.account.value.token,
              shouldNotRememberMe: state.controlData.shouldNotRememberMe,
              loginExpirationDate: state.controlData.shouldNotRememberMe
                ? Date.now() + LOGIN_EXPIRATION_TIME
                : undefined,
              progressiveProfilingDisplayedAt:
                state.controlData.progressiveProfilingDisplayedAt,
              upsaleDisplayedAt: state.controlData.upsaleDisplayedAt,
              needsAccountRefresh: undefined,
            },
            phoneVerification: {
              loginPhoneNumber: null,
            },
          }
        : state
    case unloadUser.type:
      return {
        account: undefined,
        controlData: {
          isLoggedIn: true,
          token: undefined,
          shouldNotRememberMe: state.controlData.shouldNotRememberMe,
          loginExpirationDate: state.controlData.shouldNotRememberMe
            ? Date.now() + LOGIN_EXPIRATION_TIME
            : undefined,
          progressiveProfilingDisplayedAt:
            state.controlData.progressiveProfilingDisplayedAt,
          upsaleDisplayedAt: state.controlData.upsaleDisplayedAt,
          needsAccountRefresh: undefined,
        },
        phoneVerification: {
          loginPhoneNumber: null,
        },
      }
    case loginDone.type:
      return action.result.value
        ? {
            account: action.result.value,
            controlData: {
              isLoggedIn: true,
              token: action.result.value.token,
              shouldNotRememberMe: action.rememberMe ? undefined : true,
              loginExpirationDate: !action.rememberMe
                ? Date.now() + LOGIN_EXPIRATION_TIME
                : undefined,
              progressiveProfilingDisplayedAt: '',
              upsaleDisplayedAt: '',
              needsAccountRefresh: undefined,
            },
            phoneVerification: {
              loginPhoneNumber: null,
              unverifiedAccountsFound:
                state.phoneVerification.unverifiedAccountsFound,
            },
          }
        : state
    case updatedAccount.type:
      return action.account
        ? {
            account: action.account,
            controlData: {
              isLoggedIn: true,
              token: action.account.token,
              progressiveProfilingDisplayedAt:
                state.controlData.progressiveProfilingDisplayedAt,
              upsaleDisplayedAt: state.controlData.upsaleDisplayedAt,
              needsAccountRefresh: true,
            },
            phoneVerification: {
              loginPhoneNumber: null,
            },
          }
        : state
    case updatedAccountShippingAddress.type:
      return state.account
        ? {
            ...state,
            account: {
              ...state.account,
              shippingAddress: action.shippingAddress,
            },
          }
        : state
    case needsRefreshedAccount.type:
      return {
        ...state,
        controlData: {
          ...state.controlData,
          needsAccountRefresh: true,
        },
      }
    case refreshedAccount.type:
      return action.account
        ? {
            ...state,
            account: action.account,
            controlData: {
              ...state.controlData,
              needsAccountRefresh: undefined,
            },
          }
        : state
    case saveToken.type:
      return action.token
        ? {
            ...state,
            controlData: {
              ...state.controlData,
              token: action.token,
            },
          }
        : state
    case openPhoneNumberVerification.type:
      return {
        ...state,
        controlData: {
          ...state.controlData,
          shouldShowPhoneVerification: action.needsNewVerificationCode
            ? undefined
            : true,
          shouldNotRememberMe: action.shouldRememberMe ? undefined : true,
        },
        phoneVerification: {
          ...state.phoneVerification,
          loginPhoneNumber: action.loginPhoneNumber,
          shouldReassignNumber: action.shouldReassignNumber,
          shouldNotCreateNewAccount: action.shouldNotCreateNewAccount,
        },
      }
    case closePhoneNumberVerification.type:
      return {
        ...state,
        controlData: {
          ...state.controlData,
          shouldShowPhoneVerification: undefined,
        },
        phoneVerification: {
          ...state.phoneVerification,
          loginPhoneNumber: null,
        },
      }
    case setUnverifiedAccountsFound.type:
      return {
        ...state,
        phoneVerification: {
          ...state.phoneVerification,
          unverifiedAccountsFound: action.unverifiedAccountsFound
            ? true
            : undefined,
        },
      }
    case setNoPhoneFound.type:
      return {
        ...state,
        phoneVerification: {
          ...state.phoneVerification,
          noPhoneFound: action.noPhoneFound ? true : undefined,
        },
      }
    case toggleIsBlankCardModalOpen.type:
      return {
        ...state,
        controlData: {
          ...state.controlData,
          isBlankCardModalOpen: state.controlData.isBlankCardModalOpen
            ? undefined
            : true,
        },
      }
    case setProgressiveProfilingDisplayedAt.type:
      return {
        ...state,
        controlData: {
          ...state.controlData,
          progressiveProfilingDisplayedAt: action.timestamp,
        },
      }
    case setUpsaleDisplayedAt.type:
      return {
        ...state,
        controlData: {
          ...state.controlData,
          upsaleDisplayedAt: action.timestamp,
        },
      }
    default:
      return state
  }
}

const isLoggedIn = (persistedState: UnpersistedAccountState) => {
  // has account information?
  if (!persistedState.account) {
    return false
  }

  // does login expire?
  if (!persistedState.controlData.shouldNotRememberMe) {
    return true
  }

  // is it expired?
  if (persistedState.controlData.loginExpirationDate) {
    const now = Date.now()
    return now - persistedState.controlData.loginExpirationDate < 0
  }

  // return false by default
  return false
}

const stateReconciler = (
  persistedState: UnpersistedAccountState & {
    token?: string /* backwards compatibility*/
  },
  currentState: UnpersistedAccountState,
): UnpersistedAccountState => {
  if (persistedState && !persistedState.controlData) {
    return {
      phoneVerification: {
        loginPhoneNumber: null,
      },
      controlData: {
        isLoggedIn: false,
        token: persistedState.token,
        progressiveProfilingDisplayedAt: '',
        upsaleDisplayedAt: '',
        needsAccountRefresh: undefined,
      },
    }
  } else if (currentState.controlData.token) {
    return {
      ...currentState,
      controlData: {
        ...currentState.controlData,
        progressiveProfilingDisplayedAt:
          persistedState.controlData.token === currentState.controlData.token
            ? persistedState.controlData.progressiveProfilingDisplayedAt
            : '',
        upsaleDisplayedAt:
          persistedState.controlData.token === currentState.controlData.token
            ? persistedState.controlData.upsaleDisplayedAt
            : '',
      },
    }
  } else if (isLoggedIn(persistedState)) {
    return {
      ...persistedState,
      controlData: {
        ...persistedState.controlData,
      },
    }
  } else {
    return {
      phoneVerification: {
        loginPhoneNumber: null,
      },
      controlData: {
        isLoggedIn: false,
        token:
          currentState.controlData.token ||
          persistedState.controlData.shouldNotRememberMe
            ? undefined
            : persistedState.controlData.token,
        shouldShowPhoneVerification:
          currentState.controlData.shouldShowPhoneVerification,
        progressiveProfilingDisplayedAt: '',
        upsaleDisplayedAt: '',
        needsAccountRefresh: undefined,
      },
    }
  }
}

export default persistReducer(
  {
    key: 'account',
    storage,
    stateReconciler,
  },
  unpersistedAccount,
)
