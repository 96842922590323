import React from 'react'
import { Loader, Text } from '@sendoutcards/quantum-design-ui'
import suspenseBoundary from 'src/chrome/SuspenseBoundary/suspenseBoundaryHOC'
import { DefaultError } from 'src/chrome'
import { StepWrapperProps } from './ProgressiveProfileActions'

export const ContentType = {
  TaxInfo: 'taxInfo',
}

type Props = StepWrapperProps & {
  content: string
  onComplete: () => void
}

const generateModalBody = (contentKey: string) => {
  if (contentKey === ContentType.TaxInfo) {
    return (
      <Text type="body">
        Welcome! Don't forget to fill out your{' '}
        <a
          href={
            'https://secure.rightsignature.com/templates/a59ccffc-cbe2-4c5d-9767-b5d588ad2c79/template-signer-link/c0c1f68ea77ece4b0613462a832f6e0d'
          }
          target="_blank"
          rel="noreferrer"
        >
          W9 (United States)
        </a>{' '}
        or{' '}
        <a
          href={
            'https://secure.rightsignature.com/templates/ca068f04-991d-43b4-9d69-8688c13b93e1/template-signer-link/ee0b27a3ed497cabef6c9c91cca86c91'
          }
          target="_blank"
          rel="noreferrer"
        >
          W-8BEN (International)
        </a>
        to receive compensation and finish setting up your account! Visit the
        Account section now.
      </Text>
    )
  }

  return undefined
}

const NonActionStep = (props: Props) => {
  const { onComplete, content } = props

  onComplete()

  return <>{generateModalBody(content)}</>
}

export default suspenseBoundary({
  component: NonActionStep,
  unresolved: <Loader width={30} height={30} />,
  failure: DefaultError,
})
