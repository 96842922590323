import React from 'react'
import get from 'lodash/get'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Popover from '@material-ui/core/Popover'

import { Button as ChromeButton } from 'src/chrome'
import { useRef, useState } from 'src/hooks'

import styles from '../../../orders/components/OrderFooter/orderFooter.module.scss'
import addressStyles from './addressFooter.module.scss'
import { Div, Text } from '@sendoutcards/quantum-design-ui'

type Props = {
  contacts: {
    id: string
  }[]
  groups: {
    id: string
  }[]
  onAttach: () => void
}

const AddressFooter: React.FC<Props> = props => {
  const { contacts, groups, onAttach } = props

  const mutableAnchorRef = useRef<HTMLSpanElement | null>(null)
  const [popover, setPopover] = useState<'contacts' | 'groups'>()

  const openPopover = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    type: 'contacts' | 'groups',
  ) => {
    event.preventDefault()
    mutableAnchorRef.current = event.currentTarget
    setPopover(type)
  }

  const closePopover = () => setPopover(undefined)

  const numContacts = contacts.length
  const numGroups = groups.length
  const isDisabled = numContacts < 1 && numGroups < 1

  return (
    <footer className={`${styles.orderFooter} ${addressStyles.footer}`}>
      <div className={addressStyles.counts}>
        <Div display="inline-block">
          <Text type="caption" color="primaryHeading" weight="bold">
            Selected:
          </Text>
        </Div>
        <Div display="inline-block" outset={{ left: 'x1' }}>
          <span onClick={e => openPopover(e, 'contacts')}>
            <Text type="caption" color="primaryHeading">
              {numContacts === 1
                ? `${numContacts} contact`
                : `${numContacts} contacts`}
            </Text>
          </span>
        </Div>
        <Div display="inline-block" outset={{ left: 'x1' }}>
          <span onClick={e => openPopover(e, 'groups')}>
            <Text type="caption" color="primaryHeading">
              {numGroups === 1 ? `${numGroups} group` : `${numGroups} groups`}
            </Text>
          </span>
        </Div>
      </div>
      <div className={addressStyles.actionButtons}>
        <div className={`${styles.orderInfo} ${styles.cta}`}>
          <ChromeButton
            id={'attach_recipient_btn'}
            title={'Done'}
            buttonColor={'pink'}
            disabled={isDisabled}
            className={isDisabled ? styles.disabled : styles.cta}
            onClick={onAttach}
          />
        </div>
      </div>
      <Popover
        open={!!popover}
        anchorEl={mutableAnchorRef.current}
        onClose={closePopover}
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        style={{ marginBottom: 15 }}
      >
        <List>
          {popover === 'contacts'
            ? contacts.map((item, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={
                      <Text type="caption">
                        {get(item, 'firstName')} {get(item, 'lastName')}
                      </Text>
                    }
                    disableTypography={true}
                  />
                </ListItem>
              ))
            : null}
        </List>
      </Popover>
    </footer>
  )
}

export default AddressFooter
