import {
  Button,
  Dialog,
  Div,
  Flex,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { CardFragment } from 'src/graphql/generated/graphql'
import CardPanelView from '../CardPanelView/CardPanelView'

interface CardPreviewModalProps {
  isOpen: boolean
  onClose?: () => void
  itemIndex: number
  card: CardFragment
  shouldRotateBackPanels?: boolean
  onEditCard?: (card: CardFragment) => void
  onReplaceCard: (cardId: string) => void
}

const CardPreviewModal: React.FC<CardPreviewModalProps> = props => {
  const {
    isOpen,
    onClose,
    itemIndex,
    card,
    shouldRotateBackPanels,
    onReplaceCard,
    onEditCard,
  } = props
  const isSmallMobileWindow = window.innerWidth <= 450
  const isMobileWindow = window.innerWidth <= 588

  const headingDisplay = isMobileWindow ? 'flex' : undefined
  const headingAlignment = isMobileWindow ? 'center' : undefined
  const contentWrap = isMobileWindow ? 'wrap' : 'nowrap'
  const titleType = isMobileWindow ? '1.25rem' : '1.75rem'
  const descriptionType = isMobileWindow ? '.75rem' : '.875rem'
  const actionSize = isMobileWindow ? 'xSmall' : 'small'
  const alignAction = isMobileWindow ? 'center' : 'end'

  return (
    <Dialog
      closeButtonId="on_close_button"
      isOpen={isOpen}
      width="100%"
      maxWidth="1180px"
      maxHeight="765px"
      onClose={onClose}
      borderRadius="30px"
    >
      <Div
        width="100%"
        inset={{ bottom: isMobileWindow ? 'x6' : 'x8' }}
        display={headingDisplay}
        justifyContent={headingAlignment}
        flexWrap={contentWrap}
      >
        <Flex
          flexWrap="nowrap"
          justifyContent={!isSmallMobileWindow ? undefined : 'center'}
        >
          <Text
            type={'subtitle'}
            color="primaryHeading"
            weight="bold"
            content={
              itemIndex === -1 ? 'Bulk Order Card: ' : `Card ${itemIndex + 1}:`
            }
            whiteSpace="nowrap"
            style={{ fontSize: titleType }}
          />
          <Spacer orientation="horizontal" space="x1" />
          <Text
            type={'subtitle'}
            color="primaryHeading"
            weight="bold"
            content={card.detailedSendableCard?.title}
            whiteSpace="nowrap"
            style={{ fontSize: titleType }}
          />
        </Flex>
        <Div maxWidth="465px" inset={{ top: 'x1' }}>
          <Text
            type={'body'}
            content="Preview your card and choose if you would like to edit the current card or delete the card you have created"
            lineHeight={1.2}
            alignment={headingAlignment}
            style={{ fontSize: descriptionType }}
          />
        </Div>
      </Div>
      <Div display="flex" width="100%" justifyContent="center">
        <CardPanelView
          card={card}
          shouldRotateBackPanels={shouldRotateBackPanels}
          maxHeight={isMobileWindow ? '350px' : '475px'}
          width="770px"
        />
      </Div>
      <Flex
        inset={{ top: 'x4' }}
        width="100%"
        justifyContent={alignAction}
        flexWrap={contentWrap}
      >
        <Button
          type="danger"
          size={actionSize}
          title="Replace card"
          onClick={() => onReplaceCard?.(card.id)}
          outlined={true}
        />
        <Spacer
          orientation={isMobileWindow ? 'vertical' : 'horizontal'}
          space={isMobileWindow ? 'x2' : 'x4'}
        />
        <Button
          id="make_changes_to_your_card"
          type="secondary"
          size={actionSize}
          title="Make Changes to your card"
          onClick={() => onEditCard?.(card)}
        />
      </Flex>
    </Dialog>
  )
}
export default CardPreviewModal
