import { Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import styles from './selectionCard.module.scss'
import { useSelector } from 'src/hooks'
import { IconType } from 'src/design_system/atoms/icons/types'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'

type SelectionCardProps = {
  title: string
  icon?: IconType
  price: string | number
  pill?: string
  hasSelect?: boolean
  isSelected: boolean
  onSelect?: (isSelected: boolean) => void
  hasInvertedBackground?: boolean
  occurrence?: 'yr' | 'mo' | 'pkg'
}

export const SelectionCard: React.FC<SelectionCardProps> = ({
  title,
  icon,
  price,
  pill,
  hasSelect,
  isSelected = false,
  hasInvertedBackground,
  onSelect,
  occurrence = 'mo',
}) => {
  const isMobile = useSelector(state => state.window.isMobile)

  const handleSelect = (isChecked: boolean) => {
    if (onSelect) {
      onSelect(isChecked)
    }
  }
  return (
    <Flex
      width="100%"
      minWidth="340px"
      height="100px"
      borderRadius="default"
      backgroundColor={hasInvertedBackground ? 'background' : 'foreground'}
      justifyContent="flex-start"
      alignItems="center"
      inset="x2"
    >
      {!icon && (
        <Flex
          width="4px"
          minWidth="4px"
          height="40px"
          style={{
            background:
              'linear-gradient(to bottom, rgb(170, 97, 246), rgb(218, 124, 227)',
          }}
          outset={{ right: 'x1' }}
        />
      )}
      {icon && (
        <Flex width="60px" height="60px" outset={{ right: 'x1' }}>
          <Icon
            fill="linear-gradient(to bottom, rgb(170, 97, 246), rgb(218, 124, 227)"
            size={60}
            name={icon}
          />
        </Flex>
      )}
      {hasSelect && (
        <Flex
          justifyContent="start"
          alignItems="center"
          outset={{ right: 'x1' }}
        >
          <input
            id="agreeCheckbox"
            name="agree"
            type="checkbox"
            checked={isSelected}
            onChange={e => handleSelect(e.target.checked)}
            className={styles.checkbox}
            style={{
              width: '24px',
              height: '24px',
              marginLeft: '8px',
              alignItems: 'center',
            }}
          />
        </Flex>
      )}
      <Flex width="100%">
        <Text type={'body'} weight="semiBold">
          {title}
        </Text>
      </Flex>
      <Flex
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent={isMobile ? 'center' : 'flex-end'}
        alignItems={isMobile ? 'center' : undefined}
        width="100%"
      >
        {pill && (
          <Flex
            height="24px"
            borderRadius="28px"
            inset="x2"
            outset={{ horizontal: 'x1' }}
            backgroundColor={
              hasInvertedBackground ? 'foreground' : 'background'
            }
            justifyContent="center"
            alignItems="center"
          >
            <Text
              whiteSpace="nowrap"
              style={{ color: '#C76AE7' }}
              type="footnote"
              weight="bold"
            >
              {pill}
            </Text>
          </Flex>
        )}
        <Flex alignItems="center">
          <Text whiteSpace="nowrap" weight="bold" type="largeBody">
            {price}
          </Text>
          <Text
            weight="bold"
            outset={{ top: '4px', left: '2px' }}
            style={{ color: 'grey' }}
            type="body"
            whiteSpace="nowrap"
          >
            {`/${occurrence}`}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}
