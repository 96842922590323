import orderBy from 'lodash/orderBy'

import { SubscriptionFeatures } from 'src/user/fragments'

const sortedSubscriptionFeatures = (
  features: SubscriptionFeatures,
): (keyof SubscriptionFeatures)[] => {
  const priority: {
    [key: string]: number
  } = {
    loyaltyRewards: 0,
    groupSends: 1,
    scheduledSends: 2,
  }
  return orderBy(
    Object.keys(features) as (keyof SubscriptionFeatures)[],
    [e => priority[e]],
    ['asc', 'asc', 'asc'],
  )
}

export default sortedSubscriptionFeatures
