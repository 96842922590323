import { Operation } from '../../../__generated__/globalTypes'

import {
  CoachFragment,
  coachFragmentString,
} from '../../fragments/__generated__/CoachFragment'

import { storefrontFragmentString } from '../../fragments/__generated__/StorefrontFragment'

import getCoachRawString from '../GetCoach.graphql'

const getCoachString = [
  getCoachRawString,
  coachFragmentString,
  storefrontFragmentString,
].join('\n\n')

export type GetCoachData = {
  user: CoachFragment
}

export type GetCoachVariables = {
  slug: string
}

export type GetCoach = Operation<GetCoachData, GetCoachVariables>

export const GetCoach = ({ slug }: GetCoachVariables): GetCoach => ({
  query: getCoachString,
  variables: {
    slug,
  },
})
