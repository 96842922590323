import {
  Flex,
  Icon,
  IconType,
  Text,
  TextType,
} from '@sendoutcards/quantum-design-ui'
import {
  LiteralUnion,
  Responsive,
} from '@sendoutcards/quantum-design-ui/dist/src/helpers/hoc-types/entityValueTypes'
import { SizeType } from '@sendoutcards/quantum-design-ui/dist/src/types/global'
import React from 'react'

type ElementSettingsProps = {
  icon: {
    size: LiteralUnion<SizeType> | number
    name: IconType
  }
  label: {
    type: TextType | Responsive<TextType>
    content: string | number
  }

  isSelected?: boolean
  onClick?: () => void
}

export const ElementSettings: React.FC<ElementSettingsProps> = ({
  icon,
  label,
  isSelected,
  onClick,
}) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      width="fit-content"
      onClick={onClick}
    >
      <Flex
        backgroundColor="background"
        height="45px"
        width="45px"
        boxShadow="mediumDark"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        inset="x_5"
        borderRadius="default"
        outset={{ bottom: 'x1' }}
      >
        <Icon
          name={icon.name}
          size={icon.size}
          primaryColor={isSelected ? 'primaryBrand' : 'primaryHeadingText'}
        />
      </Flex>
      <Text
        weight="bold"
        color={isSelected ? 'primaryBrand' : 'primaryHeading'}
        type={label.type}
        content={label.content}
        isItalic={true}
        whiteSpace="nowrap"
      />
    </Flex>
  )
}
