import { Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { LeaderType } from '../LeaderBoard'
import { LeaderAvatar } from './LeaderAvatar'
import { useSelector } from 'src/hooks'
type TopEarnerBadgeProps = LeaderType & {
  type: 'gold' | 'silver' | 'bronze'
  metricDescription: string
  isEmptyState?: boolean
  isPastChallenge?: boolean
}

const badgeStyles = {
  gold: {
    badgeColor: '#dcad52',
    badgeSize: '25px',
    userImageSize: '90px',
    nameColor: '#dcad52',
    placement: '1',
  },
  silver: {
    badgeColor: '#B0B0B0',
    badgeSize: '20px',
    userImageSize: '65px',
    nameColor: '#B0B0B0',
    placement: '2',
  },
  bronze: {
    badgeColor: '#B28D62',
    badgeSize: '20px',
    userImageSize: '65px',
    nameColor: '#B28D62',
    placement: '3',
  },
}

export const TopEarnerBadge: React.FC<TopEarnerBadgeProps> = ({
  type,
  firstName,
  lastName,
  profileImageUrl,
  earnedCount,
  metricDescription,
  dateJoined,
  isEmptyState = false,
  isPastChallenge,
}) => {
  const {
    badgeColor,
    badgeSize,
    userImageSize,
    nameColor,
    placement,
  } = badgeStyles[type]

  const isTablet = useSelector(state => state.window.width <= 990)
  const isSmallMobile = useSelector(state => state.window.width <= 570)
  const shouldMinifyBadges = isTablet || isSmallMobile
  return (
    <Flex
      width="fit-content"
      flexDirection="column"
      alignItems={'center'}
      order={type === 'gold' ? 2 : type === 'silver' ? 3 : 1}
      position="relative"
      left={
        type === 'bronze' && shouldMinifyBadges
          ? '25px'
          : type === 'silver' && shouldMinifyBadges
          ? '-25px'
          : '0'
      }
      top={type !== 'gold' ? (shouldMinifyBadges ? '150px' : '100px') : '0'}
      transform={
        shouldMinifyBadges
          ? `scale(${type === 'gold' ? '.85' : '.82'})`
          : 'initial'
      }
    >
      <Div style={{ border: `3px solid ${badgeColor}` }} borderRadius="circle">
        <LeaderAvatar
          firstName={firstName}
          lastName={lastName}
          profileImageUrl={profileImageUrl ?? ''}
          size={userImageSize}
          isPastChallenge={isPastChallenge}
          badge={{
            type: type,
            placement: placement,
            size: badgeSize,
            color: badgeColor,
          }}
        />
      </Div>
      {!isEmptyState && (
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent={'center'}
          maxWidth="125px"
          outset={{ top: '6px' }}
        >
          <Text
            alignment="center"
            type="caption"
            content={`${firstName} ${lastName}`}
            weight="semiBold"
            style={{ color: nameColor, fontSize: '16px' }}
            lineHeight={1.4}
          />
          <Text
            alignment="center"
            type="footnote"
            content={`${earnedCount} ${metricDescription}`}
            weight="regular"
            lineHeight={0.75}
            style={{ color: '#B0B0B0', fontSize: '14px', marginTop: 8 }}
          />
        </Flex>
      )}
    </Flex>
  )
}
