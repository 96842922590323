import {
  CreateContactInput,
  Operation,
} from '../../../__generated__/globalTypes'

import {
  DetailedContactFragment,
  detailedContactFragmentString,
} from '../../fragments/__generated__/DetailedContactFragment'

import { calendarDateFragmentString } from '../../fragments/__generated__/CalendarDateFragment'

import { contactFragmentString } from '../../fragments/__generated__/ContactFragment'

import { optionalYearDateFragmentString } from '../../fragments/__generated__/OptionalYearDateFragment'

import { relatedContactFragmentString } from '../../fragments/__generated__/RelatedContactFragment'

import { noteFragmentString } from '../../fragments/__generated__/NoteFragment'

import createContactRawString from '../CreateContact.graphql'

const createContactString = [
  createContactRawString,
  detailedContactFragmentString,
  calendarDateFragmentString,
  contactFragmentString,
  optionalYearDateFragmentString,
  relatedContactFragmentString,
  noteFragmentString,
].join('\n\n')

export type CreateContactData = {
  createContact: {
    contact: DetailedContactFragment
  }
}

export type CreateContactVariables = {
  contact: CreateContactInput
}

export type CreateContact = Operation<CreateContactData, CreateContactVariables>

export const CreateContact = ({
  contact,
}: CreateContactVariables): CreateContact => ({
  query: createContactString,
  variables: {
    contact: CreateContactInput(contact),
  },
})
