import {
  Button,
  Div,
  Flex,
  Icon,
  Text,
  VStack,
} from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { ValidateEmailVerificationCodeFailureType } from 'src/graphql/generated/graphql'

export type ValidationError = {
  type: ValidateEmailVerificationCodeFailureType
  message: string
}

export type ValidationFailureEmailVerificationProps = {
  onRequestAgain: () => void
  onRetry: () => void
  validationFailure: ValidationError
}

export const ValidationFailureEmailVerification = (
  props: ValidationFailureEmailVerificationProps,
) => {
  const { onRequestAgain, onRetry, validationFailure } = props

  return (
    <Flex justifyContent="center" flexDirection="column" alignItems="center">
      <Div
        width="84px"
        height="84px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderWidth="default"
        borderColor={{ swatch: 'danger', shade: 'base' }}
        borderStyle="solid"
        borderRadius="circle"
      >
        <Icon name="exclamation" primaryColor="danger" size="xLarge" />
      </Div>
      <Text
        type="largeBody"
        alignment="center"
        content={validationFailure.message}
        outset={{ vertical: 'x3' }}
      />
      <VStack gap="x3" justifyContent="center" alignItems="center">
        {validationFailure.type !==
          ValidateEmailVerificationCodeFailureType.TooManyAttempts && (
          <Button
            outlined={true}
            type="shadow"
            fullWidth={true}
            title={
              validationFailure.type ===
              ValidateEmailVerificationCodeFailureType.IncorrectCode
                ? 'Try Code Again'
                : 'Resend Code'
            }
            onClick={() =>
              validationFailure.type ===
              ValidateEmailVerificationCodeFailureType.IncorrectCode
                ? onRetry()
                : onRequestAgain()
            }
          />
        )}
      </VStack>
    </Flex>
  )
}
