import { Anchor, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import ReactHtmlParser from 'react-html-parser'

interface GiftDetailsProps {
  content: string
}

const GiftDetails: React.FC<GiftDetailsProps> = props => {
  const { content } = props

  const parsedContent = ReactHtmlParser(content, {
    transform: node => {
      if (node.name === 'a') {
        return (
          <Anchor href={node.attribs.href} color="anchorBlue">
            {node.children[0].data}
          </Anchor>
        )
      } else if (node.name === 'br') {
        return <Spacer space="x1" />
      } else if (node.type === 'text' && node.parent === null) {
        return <Text type="body">{node.data}</Text>
      } else if (node.type === 'text') {
        return node
      } else {
        return null
      }
    },
  })

  return <>{parsedContent.map(item => item)}</>
}

export default GiftDetails
