import React from 'react'
import {
  Card,
  ConfirmDialog,
  Dialog,
  Flex,
  Icon,
  InfoBlock,
  Span,
} from '@sendoutcards/quantum-design-ui'
import { SponsorFragment } from 'src/legacy_graphql'
import {
  useEffect,
  usePersistedUserData,
  useQueryParams,
  useState,
  useVertical,
} from 'src/hooks'
import FindMySponsor from './FindMySponsor'
import { Portal } from 'src/portal/portal'
import { Memoized } from 'src/hooks/dependencies'
import { SponsorCard } from 'src/onboarding/components/SponsorCard'
import {
  sendoutcardsPromotionsGenealogyId,
  sendoutcardsPromotionsUserId,
} from 'src/app/constants'
import { useSponsor, useUsers } from 'src/react_query'

export const sponsorSelectionCopy = {
  title: 'Who showed you this product?',
  message: (clientName: string) =>
    `We celebrate and give back to the people who invite others to experience ${clientName}. Let us know who showed you our products and we will make sure they are given the credit they deserve!`,
}

export interface SponsorInfo {
  userId: string | undefined
  genealogyId: number | undefined
  slug: string | undefined
}

type AccountSponsorSelectorProps = {
  isAffiliateJoining: boolean
  dialogsZIndex?: number
  shouldPickSponsor?: boolean
  handleSponsorChanges: Memoized<(userId: string, genealogyId?: string) => void>
  handleShouldPickSponsor?: Memoized<(isPickingSponsor: boolean) => void>
}

const AccountSponsorSelector = (props: AccountSponsorSelectorProps) => {
  const {
    isAffiliateJoining,
    dialogsZIndex = 10000,
    handleSponsorChanges,
    shouldPickSponsor,
    handleShouldPickSponsor,
  } = props

  const [isPickingSponsor, setIsPickingSponsor] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)

  const [sponsorInfo, setSponsorInfo] = useState<SponsorInfo>() // will be a callback

  const { account } = usePersistedUserData()
  const queryParams = useQueryParams()
  const vertical = useVertical()

  const sponsorInfoFromURL =
    !account && queryParams.sponsor ? queryParams.sponsor : undefined

  const { data: fetchedSponsor } = useSponsor(
    { id: sponsorInfo?.userId ?? sendoutcardsPromotionsUserId },
    !!sponsorInfo &&
      !!sponsorInfo.userId &&
      sponsorInfo.userId !== sendoutcardsPromotionsUserId,
  )

  const { data: sponsors } = useUsers(
    {
      search: sponsorInfoFromURL ?? '',
      offset: 0,
      limit: 10,
      isAffiliate: false, // includes RC types
    },
    !!sponsorInfoFromURL && !sponsorInfo,
  )

  const sponsorFragment =
    Array.isArray(sponsors) && sponsors.length > 0
      ? sponsors[0]
      : fetchedSponsor

  const sponsor = sponsorFragment || account?.sponsor

  const handleIsPickingSponsorStates = (isPicking: boolean) => {
    setIsPickingSponsor(isPicking)
    handleShouldPickSponsor?.(isPicking)
  }

  const onSelectedSponsor = (id: string) => {
    setSponsorInfo({ userId: id, genealogyId: undefined, slug: undefined })
    handleSponsorChanges(id)
    handleIsPickingSponsorStates(false)
  }

  useEffect(() => {
    setSponsorInfo({
      userId: sponsorFragment?.id,
      genealogyId: sponsorFragment?.genealogyId,
      slug: sponsorFragment?.storefront?.slug
        ? sponsorFragment.storefront.slug
        : undefined,
    })
    if (sponsorFragment) {
      handleSponsorChanges(
        sponsorFragment.id,
        sponsorFragment.genealogyId.toString(),
      )
    }
  }, [setSponsorInfo, sponsorFragment, handleSponsorChanges])

  return (
    <>
      <ConfirmDialog
        zIndex={dialogsZIndex}
        isOpen={isInfoModalOpen}
        title={sponsorSelectionCopy.title}
        description={sponsorSelectionCopy.message(vertical.name)}
        accept={{
          title: 'Got it',
          onClick: () => setIsInfoModalOpen(false),
        }}
        primaryAction={'accept'}
        onClose={() => setIsInfoModalOpen(false)}
      />
      {(isPickingSponsor || !!shouldPickSponsor) && (
        <Portal>
          <Dialog
            isOpen={isPickingSponsor || !!shouldPickSponsor}
            zIndex={dialogsZIndex}
          >
            <FindMySponsor
              onSelectedSponsor={onSelectedSponsor}
              isAffiliateJoining={isAffiliateJoining}
            />
          </Dialog>
        </Portal>
      )}
      {sponsor && sponsor.genealogyId !== sendoutcardsPromotionsGenealogyId ? (
        <SponsorCard
          sponsor={sponsor as SponsorFragment}
          onClick={() => handleIsPickingSponsorStates(true)}
          isActive={true}
        />
      ) : (
        <Card width="100%" borderRadius="medium" inset="x2" cursor="pointer">
          <Flex
            width="100%"
            justifyContent="space-between"
            alignItems="center"
            onClick={() => handleIsPickingSponsorStates(true)}
          >
            <Flex alignItems="center">
              <Span
                display="flex"
                justifyContent="center"
                alignItems="center"
                inset={{ right: 'x1' }}
              >
                <Icon
                  name="userSuccess"
                  primaryColor="default"
                  secondaryColor="background"
                  size="medium"
                />
              </Span>
              <InfoBlock
                size="small"
                heading={
                  isAffiliateJoining
                    ? 'Who introduced you to our products?'
                    : 'Who showed you this product?'
                }
                caption="Optional"
              />
            </Flex>
            <span
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
              }}
              onClick={e => {
                e.stopPropagation()
                setIsInfoModalOpen(true)
              }}
            >
              <Icon
                name="information"
                primaryColor="primaryHeadingText"
                size="small"
              />
            </span>
          </Flex>
        </Card>
      )}
    </>
  )
}

export default AccountSponsorSelector
