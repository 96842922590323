import React from 'react'

import { Button } from 'src/chrome'
import { useState } from 'src/hooks'

import styles from './invitesActionBar.module.scss'
import { Flex, Spacer, Text, Tooltip } from '@sendoutcards/quantum-design-ui'

interface Props {
  title: string
  totalCount: number
  selectedCount: number
  onSendCard: () => void
}
interface ActionButtonProps {
  buttonName: string
  actionButton: React.ReactNode
  canSelectHowMany: boolean
  message: React.ReactNode
}

const InvitesActionBar: React.FC<Props> = props => {
  const { onSendCard, title, selectedCount, totalCount } = props

  const [hoveringOn, setHoveringOn] = useState('')

  const tooltipVariants = {
    hoverOn: {
      opacity: 1,
    },
    hoverOff: {
      opacity: 0,
    },
  }
  const tooltipMessageForMany = (
    <Text type="caption">
      You must select one or more invites to make this button active. All
      selected contacts also must have complete address.
    </Text>
  )

  const shouldSelectOneOrMore = selectedCount === 0

  const ActionButton: React.FC<ActionButtonProps> = props => {
    const { buttonName, actionButton, canSelectHowMany, message } = props
    return (
      <Flex
        onHoverStart={() => setHoveringOn(buttonName)}
        onHoverEnd={() => setHoveringOn('')}
        justifyContent="center"
        alignItems="center"
        position="relative"
      >
        {actionButton}
        {hoveringOn === buttonName && canSelectHowMany && (
          <Flex
            variants={tooltipVariants}
            animate={hoveringOn ? 'hoverOn' : 'hoverOff'}
            initial={'hoverOff'}
            transition={{ ease: 'easeIn', duration: 0.2 }}
            top="45px"
            position="absolute"
            zIndex={50}
          >
            <Tooltip title="" children={message} direction="top" />
          </Flex>
        )}
      </Flex>
    )
  }

  return (
    <div className={styles.container}>
      {selectedCount >= 0 && (
        <>
          <div className={styles.actions}>
            <div className={styles.desktopActions}>
              <ActionButton
                buttonName="SendCard"
                actionButton={
                  <Button
                    id={'send_card_btn'}
                    title={'Send Card'}
                    icon={'SEND'}
                    iconColor={'#6f8394'}
                    iconSize={15}
                    disabled={shouldSelectOneOrMore}
                    onClick={() => onSendCard()}
                  />
                }
                canSelectHowMany={shouldSelectOneOrMore}
                message={tooltipMessageForMany}
              />
            </div>
          </div>
          <div className={styles.contextDetails}>
            <Text type="largeBody">{title}</Text>
            <Spacer space="x2" orientation="horizontal" />
            <Text type="body">{totalCount} total</Text>
            <Spacer space="x2" orientation="horizontal" />
            <Text type="body">{selectedCount} selected</Text>
          </div>
        </>
      )}
    </div>
  )
}

export default InvitesActionBar
