import {
  RequestPhoneNumberVerificationFailureType,
  If,
  PhoneNumberInput,
  Operation,
} from '../../../__generated__/globalTypes'

import requestPhoneNumberVerificationString from '../RequestPhoneNumberVerification.graphql'

export type RequestPhoneNumberVerificationData = {
  requestPhoneNumberVerification: {
    __typename:
      | 'RequestPhoneNumberVerificationSuccess'
      | 'RequestPhoneNumberVerificationFailure'
  } & (
    | If<
        'RequestPhoneNumberVerificationSuccess',
        {
          successMessage: string
        }
      >
    | If<
        'RequestPhoneNumberVerificationFailure',
        {
          failureType: RequestPhoneNumberVerificationFailureType
          failureMessage: string
        }
      >
  )
}

export type RequestPhoneNumberVerificationVariables = {
  phoneNumber: PhoneNumberInput
}

export type RequestPhoneNumberVerification = Operation<
  RequestPhoneNumberVerificationData,
  RequestPhoneNumberVerificationVariables
>

export const RequestPhoneNumberVerification = ({
  phoneNumber,
}: RequestPhoneNumberVerificationVariables): RequestPhoneNumberVerification => ({
  query: requestPhoneNumberVerificationString,
  variables: {
    phoneNumber: PhoneNumberInput(phoneNumber),
  },
})
