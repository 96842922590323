import { State } from 'src/redux/reducers'
import { CatalogRoute } from 'src/catalog/routes/CatalogRoute'
import { SendableCardRoute } from 'src/catalog/routes/SendableCardRoute'
import { findRoute } from 'src/routes/utilities'

const getCatalogRoute = ({ route }: State): CatalogRoute | undefined =>
  findRoute(route, CatalogRoute)

const getSendableCardRoute = (state: State) => {
  const route = getCatalogRoute(state)
  if (!route) {
    return undefined
  }
  return findRoute(route, SendableCardRoute)
}

export const getRouteCardType = (state: State) =>
  getSendableCardRoute(state)?.cardType

export const getRoutePaperType = (state: State) =>
  getSendableCardRoute(state)?.paperType
