// eslint-disable-next-line no-restricted-imports
import { EffectCallback, useEffect } from 'react'
import { AnyMemoized } from './dependencies'
import useMemoizedDeps from './useMemoizedDeps'

/**
 * Accepts a function that contains imperative, possibly effectful code.
 *
 * @param effect Imperative function that can return a cleanup function.
 * @param deps If present, effect will only activate if the values in the list change.
 */
const useEffectDeepCompare = (callback: EffectCallback, deps?: AnyMemoized[]) =>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, useMemoizedDeps(deps))

export default useEffectDeepCompare
