import {
  AnimatedCheckMark,
  Button,
  Flex,
  Sheet,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { useActions, useState } from 'src/hooks'
import Result from 'src/utils/Result'
import FeaturedCardsGrid from 'src/catalog/components/FeaturedCardsGrid/FeaturedCardsGrid'
import { AccountFragment } from 'src/graphql/generated/graphql'

interface AffiliateJoinSuccessProps {
  createdAccount: AccountFragment
  isFreeAffiliate: boolean
}

const AffiliateJoinSuccess: React.FC<AffiliateJoinSuccessProps> = props => {
  const { createdAccount, isFreeAffiliate } = props
  const [isSheetOpen, setIsSheetOpen] = useState(true)
  const actions = useActions()

  const freeAffiliateMessage =
    'Your opportunity starts here! Start sharing our products and services with others today.'
  const upgradedAffiliateMessage =
    'Download the Promptings Pro app from your confirmation email. Continue sharing our products and services with your new business building tools!'

  const handleContinueToAccount = () => {
    actions.loginDone(Result(createdAccount))
    setIsSheetOpen(false)
    window.location.href =
      'https://app.sendoutcards.com/dashboard/account?client=promptings'
  }

  return (
    <Sheet isOpen={isSheetOpen} backgroundElement={<FeaturedCardsGrid />}>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
      >
        <Spacer space="x6" />
        <AnimatedCheckMark size="large" />
        <Spacer space="x3" />
        <Text type="largeBody">Your selection has been made successfully.</Text>
        <Spacer space="x2" />
        <Text type="largeBody">
          {isFreeAffiliate ? freeAffiliateMessage : upgradedAffiliateMessage}
        </Text>
        <Spacer space="x3" />
        <Button onClick={() => handleContinueToAccount()}>
          Continue to Account
        </Button>
      </Flex>
    </Sheet>
  )
}

export default AffiliateJoinSuccess
