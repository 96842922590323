import React from 'react'

import drawerStyles from '../../../editor/styles/components/Drawer.module.scss'
import styles from './detailDrawer.module.scss'
import { useAccount } from 'src/hooks'

type DetailDrawerProps = {
  className: string
  children: React.ReactNode
}

const DetailDrawer: React.FC<DetailDrawerProps> = props => {
  const { className, children } = props
  const account = useAccount()
  const isSparse = !account.username
  return (
    <section className="openDrawer">
      <div
        className={`${styles.detailDrawer} ${drawerStyles.imageDrawer} ${
          className && className
        }
         ${isSparse ? styles.detailDrawerSparse : ''}`}
      >
        {children}
      </div>
    </section>
  )
}

export default DetailDrawer
