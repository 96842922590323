import React from 'react'
import { SVGProps } from './types'

export const Trash: React.FC<SVGProps> = ({
  size = 24,
  color = '#323232',
  onClick,
}) => {
  return (
    <svg
      width={`${size}px`}
      height="auto"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g id="Icon">
        <path
          id="Vector"
          d="M13.9995 4.48663C13.9861 4.48663 13.9661 4.48663 13.9461 4.48663C10.4195 4.1333 6.89948 3.99996 3.41279 4.3533L2.05279 4.48663C1.77279 4.5133 1.52612 4.3133 1.49946 4.0333C1.47279 3.7533 1.67279 3.5133 1.94612 3.48663L3.30612 3.3533C6.85281 2.9933 10.4461 3.1333 14.0461 3.48663C14.3195 3.5133 14.5195 3.75996 14.4928 4.0333C14.4728 4.2933 14.2528 4.48663 13.9995 4.48663Z"
          fill={color}
        />
        <path
          id="Vector_2"
          d="M5.66748 3.81331C5.64082 3.81331 5.61415 3.81331 5.58082 3.80665C5.31415 3.75998 5.12748 3.49998 5.17415 3.23331L5.32082 2.35998C5.42748 1.71998 5.57415 0.833313 7.12746 0.833313H8.87412C10.4341 0.833313 10.5808 1.75331 10.6808 2.36665L10.8275 3.23331C10.8741 3.50665 10.6875 3.76665 10.4208 3.80665C10.1475 3.85331 9.88746 3.66665 9.84746 3.39998L9.70079 2.53331C9.60746 1.95331 9.58746 1.83998 8.88079 1.83998H7.13412C6.42748 1.83998 6.41415 1.93331 6.31415 2.52665L6.16082 3.39331C6.12082 3.63998 5.90748 3.81331 5.66748 3.81331ZM10.1406 15.1667H5.86058C3.53392 15.1667 3.44058 13.88 3.36725 12.84L2.93392 6.12669C2.91392 5.85336 3.12725 5.61337 3.40058 5.59337C3.68058 5.58003 3.91392 5.7867 3.93392 6.06003L4.36725 12.7734C4.44058 13.7867 4.46725 14.1667 5.86058 14.1667H10.1406C11.5406 14.1667 11.5673 13.7867 11.6339 12.7734L12.0673 6.06003C12.0873 5.7867 12.3273 5.58003 12.6006 5.59337C12.8739 5.61337 13.0873 5.84669 13.0673 6.12669L12.6339 12.84C12.5606 13.88 12.4673 15.1667 10.1406 15.1667Z"
          fill={color}
        />
        <path
          id="Vector_3"
          d="M9.10641 11.5H6.88641C6.61306 11.5 6.38639 11.2733 6.38639 11C6.38639 10.7266 6.61306 10.5 6.88641 10.5H9.10641C9.37974 10.5 9.60641 10.7266 9.60641 11C9.60641 11.2733 9.37974 11.5 9.10641 11.5ZM9.66634 8.83331H6.33301C6.05967 8.83331 5.83301 8.60665 5.83301 8.33331C5.83301 8.05998 6.05967 7.83331 6.33301 7.83331H9.66634C9.93967 7.83331 10.1663 8.05998 10.1663 8.33331C10.1663 8.60665 9.93967 8.83331 9.66634 8.83331Z"
          fill={color}
        />
      </g>
    </svg>
  )
}
