import React from 'react'

export function LeaderListEmpty({ height = 166, width = 155 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${width}px`}
      height={`${height}px`}
      fill="none"
    >
      <path
        fill="#EAEEF9"
        d="M88.217 125.151c32.562 0 58.959-26.398 58.959-59.075C147.176 33.398 120.662 7 88.217 7 55.656 7 29.258 33.398 29.258 66.076c0 32.677 26.398 59.075 58.96 59.075zm66.444-77.793a4.768 4.768 0 100-9.536 4.768 4.768 0 000 9.536zm6.977-18.606a3.256 3.256 0 10.001-6.513 3.256 3.256 0 00-.001 6.513zM31.933 27.35a3.256 3.256 0 100-6.512 3.256 3.256 0 000 6.513zM12.047 90.148a6.047 6.047 0 100-12.095 6.047 6.047 0 000 12.095z"
      />
      <g filter="url(#a)">
        <path
          fill="url(#b)"
          d="M136.216 121.858H37.579a3.512 3.512 0 01-3.498-3.498V94.267a3.512 3.512 0 013.498-3.498h98.637a3.512 3.512 0 013.498 3.498v24.093a3.512 3.512 0 01-3.498 3.498z"
        />
      </g>
      <path
        fill="#D6DCE8"
        d="M119.858 102.446H64.924c-1.183 0-2.119-.718-2.119-1.626 0-.907.936-1.626 2.119-1.626h54.934c1.183 0 2.119.719 2.119 1.626 0 .908-.936 1.626-2.119 1.626zm-19.707 9.903H64.924c-1.183 0-2.119-.718-2.119-1.626 0-.907.936-1.626 2.119-1.626h35.227c1.182 0 2.118.719 2.118 1.626 0 .908-.936 1.626-2.118 1.626z"
      />
      <path
        fill="#989FB0"
        d="M48.665 115.059a8.77 8.77 0 100-17.54 8.77 8.77 0 000 17.54z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M52.128 104.289l-5.518 5.469-1.408-1.42 5.518-5.469 1.408 1.42z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M46.61 102.869l5.518 5.469-1.408 1.42-5.518-5.469 1.408-1.42z"
        clipRule="evenodd"
      />
      <g filter="url(#c)">
        <path
          fill="url(#d)"
          d="M139.53 42.51H40.894a3.512 3.512 0 01-3.498-3.499V14.918a3.512 3.512 0 013.498-3.498h98.636a3.512 3.512 0 013.498 3.498v24.093a3.512 3.512 0 01-3.498 3.498z"
        />
      </g>
      <path
        fill="#D6DCE8"
        d="M123.173 23.097H68.238c-1.183 0-2.119-.719-2.119-1.626 0-.907.936-1.626 2.119-1.626h54.935c1.182 0 2.118.719 2.118 1.626 0 .907-.936 1.626-2.118 1.626zM103.465 33H68.238c-1.183 0-2.119-.718-2.119-1.626 0-.907.936-1.626 2.119-1.626h35.227c1.183 0 2.119.719 2.119 1.626 0 .908-.936 1.626-2.119 1.626z"
      />
      <path
        fill="#989FB0"
        d="M51.979 35.71a8.77 8.77 0 100-17.54 8.77 8.77 0 000 17.54z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M55.442 24.94l-5.518 5.47-1.408-1.421 5.518-5.469 1.408 1.42z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M49.924 23.52l5.518 5.469-1.408 1.42-5.518-5.468 1.408-1.421z"
        clipRule="evenodd"
      />
      <g filter="url(#e)">
        <path
          fill="url(#f)"
          d="M124.391 81.997H25.706a3.512 3.512 0 01-3.498-3.498V54.407a3.512 3.512 0 013.498-3.499h98.636a3.512 3.512 0 013.498 3.498V78.5c.049 1.97-1.527 3.498-3.449 3.498z"
        />
      </g>
      <path
        fill="#989FB0"
        d="M36.84 75.247a8.77 8.77 0 100-17.54 8.77 8.77 0 000 17.54z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M41.686 64.425l-6.225 6.226-3.466-3.466 1.414-1.415 2.052 2.052 4.81-4.81 1.415 1.413z"
        clipRule="evenodd"
      />
      <path
        fill="#D6DCE8"
        d="M108.034 62.585H53.099c-1.182 0-2.118-.718-2.118-1.626 0-.907.936-1.626 2.118-1.626h54.935c1.182 0 2.119.719 2.119 1.626 0 .908-.986 1.626-2.119 1.626zm-19.708 9.952H53.1c-1.182 0-2.118-.718-2.118-1.625 0-.908.936-1.626 2.118-1.626h35.227c1.183 0 2.119.718 2.119 1.626-.05.907-.985 1.625-2.119 1.625z"
      />
      <defs>
        <linearGradient
          id="b"
          x1="86.863"
          x2="86.863"
          y1="90.05"
          y2="122.193"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDFEFF" />
          <stop offset="0.996" stopColor="#ECF0F5" />
        </linearGradient>
        <linearGradient
          id="d"
          x1="90.177"
          x2="90.177"
          y1="10.701"
          y2="42.844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDFEFF" />
          <stop offset="0.996" stopColor="#ECF0F5" />
        </linearGradient>
        <linearGradient
          id="f"
          x1="74.99"
          x2="74.99"
          y1="50.189"
          y2="82.332"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDFEFF" />
          <stop offset="0.996" stopColor="#ECF0F5" />
        </linearGradient>
        <filter
          id="a"
          width="149.632"
          height="75.089"
          x="12.081"
          y="79.769"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="11" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_426_29781"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_426_29781"
            result="shape"
          />
        </filter>
        <filter
          id="c"
          width="149.632"
          height="75.089"
          x="15.396"
          y="0.42"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="11" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_426_29781"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_426_29781"
            result="shape"
          />
        </filter>
        <filter
          id="e"
          width="149.634"
          height="75.089"
          x="0.208"
          y="39.908"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="11" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_426_29781"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_426_29781"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}
