import { ActionCreator } from '@sendoutcards/core'

export const toggleIsNewContactModalOpen = ActionCreator(
  'SET_IS_NEW_CONTACT_MODAL_OPEN',
)

export const toggleIsNewGroupModalOpen = ActionCreator(
  'TOGGLED_IS_NEW_GROUP_MODAL_OPEN',
)

export const openEditContact = ActionCreator(
  'OPEN_EDIT_CONTACT',
  (contactId: string) => ({ contactId }),
)

export const closeEditContact = ActionCreator('CLOSE_EDIT_CONTACT')
