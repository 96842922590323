import { CurrencyType } from '../../../__generated__/globalTypes'

import priceFragmentRawString from '../PriceFragment.graphql'

export const priceFragmentString = priceFragmentRawString

export type PriceFragment = {
  __typename: 'Price'
  asString: string
  currency: CurrencyType
  amount: number
}

export const isPriceFragment = (fragment: any): fragment is PriceFragment =>
  fragment &&
  fragment.__typename === 'Price' &&
  typeof fragment.asString === 'string' &&
  (fragment.currency === 'POINT' ||
    fragment.currency === 'EXPENSE' ||
    fragment.currency === 'FREE' ||
    fragment.currency === 'USD' ||
    fragment.currency === 'CREDIT' ||
    fragment.currency === 'CARDTOKEN') &&
  typeof fragment.amount === 'number'
