import { Maybe, Operation } from '../../../__generated__/globalTypes'

import bulkDeleteCardsString from '../BulkDeleteCards.graphql'

export type BulkDeleteCardsData = {
  bulkDeleteCards: {
    successful: boolean
    failedCount: Maybe<number>
  }
}

export type BulkDeleteCardsVariables = {
  cards: string[]
}

export type BulkDeleteCards = Operation<
  BulkDeleteCardsData,
  BulkDeleteCardsVariables
>

export const BulkDeleteCards = ({
  cards,
}: BulkDeleteCardsVariables): BulkDeleteCards => ({
  query: bulkDeleteCardsString,
  variables: {
    cards,
  },
})
