import { Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import React, { FC } from 'react'

type DateBadgeProps = {
  color: string
  weekday: string
  day: number
  month: string
  isActive?: boolean
  hasEvent: boolean
  isMobile?: boolean
}

export const DateBadge: FC<DateBadgeProps> = ({
  color,
  weekday,
  day,
  isActive,
  hasEvent = false,
  isMobile,
}) => {
  // const typeColor =
  //   type === 'birthday'
  //     ? '#BE9CDD'
  //     : type === 'anniversary'
  //     ? '#f6ce81'
  //     : '#616161'

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      backgroundColor={'foreground'}
      inset={{ top: 'x1_5', bottom: 'x1', horizontal: 'x2' }}
      style={{
        borderRadius: '12px',
        backgroundColor: isActive ? '#404040' : '#FFFFFF',
        transform: isMobile ? 'scale(.9)' : '',
      }}
    >
      <Text
        type="footnote"
        style={{ fontSize: '.7rem' }}
        content={weekday}
        color={isActive ? 'inverseBody' : 'primaryBody'}
        lineHeight={1.3}
        outset={{ bottom: 'x_25' }}
      />
      <Text
        type="largeBody"
        style={{ fontSize: '1rem' }}
        content={day}
        weight="semiBold"
        color={isActive ? 'inverseHeading' : 'primaryHeading'}
        lineHeight={1.3}
      />
      {hasEvent && (
        <Div
          outset={{ top: 'x_25' }}
          width={'5px'}
          height={'5px'}
          borderRadius={'circle'}
          style={{ backgroundColor: color }}
        />
      )}
    </Flex>
  )
}
