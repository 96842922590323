import React from 'react'

import { useAppcues, useMemo, useState } from 'src/hooks'
import styles from './campaignStore.module.scss'

import { useCampaignStoreSubcategories } from 'src/react_query/queries/hooks'
import { CampaignSubcategory } from '../components/CampaignSubcategory/CampaignSubcategory'
import {
  Button,
  Div,
  Flex,
  LoadingSpinner,
} from '@sendoutcards/quantum-design-ui'
import { CampaignSubcategoryLoader } from '../components/CampaignSubcategory/CampaignSubcategoryLoader'
import ItemComponent from '../components/Item/Item'
import {
  CampaignStoreSubcategoryFragment,
  CategoryConnectionFragment,
} from 'src/saleor_graphql/generated/graphql'

const CampaignStore = () => {
  const [activeVariantID, setActiveVariantID] = useState<string | null>(null)
  const initialCategoryFetchCount = 10
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
  } = useCampaignStoreSubcategories({
    resultsPerPage: initialCategoryFetchCount,
  })

  useAppcues('-L8DaP3XvZmu5XZ88mgo')

  // Results are returned alphabetically but we also added order metadata to override result order.
  const subcategories = useMemo(() => {
    const filtered =
      data?.pages
        .filter((page): page is CategoryConnectionFragment => Boolean(page))
        .flatMap(page => page.subcategories) ?? []

    const sorted = [...filtered].sort((a, b) => {
      const getOrder = (node: CampaignStoreSubcategoryFragment) =>
        node.metadata.find(meta => meta.key === 'order')

      const aOrder = getOrder(a.node)?.value
      const bOrder = getOrder(b.node)?.value

      if (aOrder && bOrder) {
        return parseInt(aOrder, 10) - parseInt(bOrder, 10)
      }

      if (aOrder) {
        return -1
      }

      if (bOrder) {
        return 1
      }

      // If no order key is provided we fallback on alphabetical order
      const aName = a.node.name.toUpperCase()
      const bName = b.node.name.toUpperCase()

      return aName.localeCompare(bName)
    })

    return sorted
  }, [data])

  return (
    <div className={styles.container}>
      <div className={styles.header} />
      <Div position="absolute" zIndex={400} top="160px" right="120px" />
      <Flex
        style={{ gap: '32px' }}
        flexDirection="column"
        inset={{ vertical: '24px', horizontal: '16px' }}
      >
        {isLoading
          ? [...Array(initialCategoryFetchCount).keys()].map(num => (
              <CampaignSubcategoryLoader productLoaderCount={4} key={num} />
            ))
          : subcategories.map(edge => (
              <CampaignSubcategory
                key={edge.cursor}
                edge={edge.node}
                cursor={edge.cursor}
                onView={id => setActiveVariantID(id)}
              />
            ))}
        <Flex justifyContent={'center'}>
          {isFetchingNextPage ? (
            <LoadingSpinner size="large" />
          ) : (
            <Button
              type="primary"
              outlined={true}
              onClick={() => fetchNextPage()}
              disabled={!hasNextPage}
            >
              {hasNextPage ? 'Load More Campaigns' : 'Viewing all Campaigns'}
            </Button>
          )}
        </Flex>
      </Flex>
      {activeVariantID && (
        <ItemComponent
          onClose={() => setActiveVariantID(null)}
          id={activeVariantID}
        />
      )}
    </div>
  )
}

export default CampaignStore
