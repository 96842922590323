import { DropdownLink, Link, LogoProps } from '@sendoutcards/quantum-design-ui'
import { AccountFragment } from 'src/graphql/generated/graphql'
import { getSponsorSuffix } from 'src/helpers/getSponsorSuffix'

export const sponsorParam = (account?: AccountFragment) => {
  if (!account?.sponsor) return

  return `?sponsor=${getSponsorSuffix(account?.sponsor)}`
}

export const navLinks = (
  account?: AccountFragment,
): (DropdownLink | Link)[] => {
  const sponsorLinkParam = account ? sponsorParam(account) : ''

  return [
    {
      title: '3 Card Sampler',
      href: `https://www.promptings.com/3cardsampler/${sponsorLinkParam}`,
    },
    {
      title: 'Premium Membership Plans',
      href: `https://www.promptings.com/solopreneur/${sponsorLinkParam}`,
    },
    {
      title: 'Pricing',
      href: '/pricing',
      target: '_self',
    },
  ]
}

export const navLogo: LogoProps = {
  brand: 'soc',
  color: 'color',
  href: '/',
  width: '200px',
  shouldShowParentBrand: true,
  secondaryLogo: {
    width: '80px',
  },
}
