import React from 'react'
import { Transition } from 'react-transition-group'

import animations from 'src/chrome/Fade/animations'
import AnimateHOC from 'src/chrome/Fade/AnimateHOC'

interface Props {
  fadeIn: number // number from 1-6 (1 being the shortest animation time)
  fadeOut: number
  style?: React.CSSProperties
  loop?: number
  id?: string
}

class Fade extends React.Component<Props, {}> {
  el?: HTMLDivElement | null

  componentWillAppear(cb: () => void) {
    if (this.el) {
      this.el.style.opacity = '0'
    }
    cb()
  }

  componentWillEnter(cb: () => void) {
    if (this.el) {
      this.el.style.opacity = '0'
    }
    cb()
  }

  componentDidAppear() {
    if (!this.el) return
    if (this.props.loop === 1) {
      animations.timeline([
        animations.fade.in(this.el, this.props.fadeIn),
        animations.fade.out(this.el, this.props.fadeOut),
      ])
    } else {
      animations.fade.in(this.el, this.props.fadeIn).animate()
    }
  }

  componentDidEnter() {
    if (!this.el) return
    if (this.props.loop === 1) {
      animations.timeline([
        animations.fade.in(this.el, this.props.fadeIn),
        animations.fade.out(this.el, this.props.fadeOut),
      ])
    } else {
      animations.fade.in(this.el, this.props.fadeIn).animate()
    }
  }

  componentWillLeave(cb: () => void) {
    if (!this.el) return
    animations.fade.out(this.el, this.props.fadeOut, undefined, cb).animate()
  }

  render() {
    const { children, fadeIn, fadeOut, style, ...restProps } = this.props
    return (
      <Transition
        in={true}
        timeout={{ enter: fadeIn, exit: fadeOut }}
        {...restProps}
      >
        <div
          id={this.props.id}
          ref={el => (this.el = el)}
          style={style ? style : {}}
        >
          {children}
        </div>
      </Transition>
    )
  }
}

export default AnimateHOC(Fade)
