import {
  useEffect,
  useMutations,
  usePersistedUserData,
  useState,
} from 'src/hooks'

const rfc5322EmailRegex = /([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|"(!#-[^-~ \t]|(\\[\t -~]))+")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)+|\[[\t -Z^-~]*])/

const useEmailValidation = (email: string) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  )
  const [isLoading, setIsLoading] = useState(false)
  const [hasPerformedValidation, setHasPerformedValidation] = useState(false)
  const [isValid, setIsValid] = useState(false)

  const mutations = useMutations()
  const { account } = usePersistedUserData()

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    let mutableCanceling = false

    const isFormatValid = rfc5322EmailRegex.test(email)

    if (email) {
      if (account && email === account.email) {
        setIsValid(true)
        setErrorMessage(undefined)
        return
      }
      if (!isFormatValid) {
        setIsValid(false)
        setErrorMessage(
          'Email format is invalid. Please enter a valid email address to continue.',
        )
        return
      } else {
        const validate = async () => {
          setIsLoading(true)
          setErrorMessage(undefined)
          setIsValid(false)
          try {
            const {
              validateEmail: { isValid, errorMsg },
            } = await mutations.validateEmail({
              email,
              doNotShowDefaultTransition: true,
            })
            setHasPerformedValidation(true)
            if (mutableCanceling) {
              return
            }
            setIsValid(isValid)
            if (errorMsg) {
              setErrorMessage(errorMsg)
            }
          } catch (e) {
            setIsValid(false)
          } finally {
            setIsLoading(false)
          }
        }
        validate()
      }
    }

    return () => {
      mutableCanceling = true
    }
  }, [email, mutations, account])

  return {
    errorMessage,
    hasPerformedValidation,
    isLoading,
    isValid,
  }
}

export default useEmailValidation
