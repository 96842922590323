import React, { useRef } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
// @src imports
import { GiftTile } from 'src/gift_store/components'
import { GiftFragment } from 'src/legacy_graphql'
import suspenseBoundary from 'src/chrome/SuspenseBoundary/suspenseBoundaryHOC'
import { useEffect, useScroll } from 'src/hooks'

interface Props {
  id?: number
  viewGift: (slug: string) => void
  addGift: (gift: GiftFragment) => void
  gifts: GiftFragment[]
  isLoadingMore: boolean
  hasMore: boolean
  loadMore: () => void
  isChangingGift: boolean
}

const GiftTiles: React.FC<Props> = props => {
  const {
    addGift,
    viewGift,
    gifts,
    isLoadingMore,
    hasMore,
    loadMore,
    isChangingGift,
  } = props

  const triggerElement = useRef<HTMLDivElement>(null)
  const handleScroll = useScroll({
    isLoadingMore,
    hasMore,
    loadMore,
    triggerElement,
  })

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  return (
    <>
      {gifts.map((item, index) => (
        <GiftTile
          isChangingGift={isChangingGift}
          id={`gift_tile_${props.id}_${index}`}
          item={item}
          key={item.id}
          viewGift={() => viewGift(item.id)}
          addGift={() => addGift(item)}
        />
      ))}
      {(!!loadMore || !!hasMore) && (
        <div
          ref={triggerElement}
          style={{ background: 'transparent', boxShadow: 'none' }}
        >
          {hasMore && <CircularProgress />}
        </div>
      )}
    </>
  )
}

const Unresolved = () => (
  <div style={{ background: 'transparent', boxShadow: 'none' }}>
    <CircularProgress />
  </div>
)

export default suspenseBoundary({
  component: GiftTiles,
  failure: error => `Failed loading gifts: ${error}`,
  unresolved: <Unresolved />,
})
