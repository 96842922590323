import { Flex } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { Widget } from 'src/dashboard/components/Widgets/Widget'
import { useActions } from 'src/hooks'
import { useDraftedCards } from 'src/react_query'
import { CardLineItem } from '../History/CardLineItem'

type DraftWidgetProps = {}

export const DraftWidget: React.FC<DraftWidgetProps> = () => {
  const actions = useActions()
  const { data: draftedCards, isLoading } = useDraftedCards({
    offset: 0,
    limit: 2,
  })

  const drafts = draftedCards?.slice(0, 2).map(res => {
    return {
      preview: res.frontPreviewUrl ?? '',
      isHorizontal: res.isHorizontal ?? false,
      title: 'Draft',
      date: res?.updatedAt ?? '',
    }
  })

  return (
    <Widget
      alignment="stretch"
      isLoading={isLoading}
      isEmpty={!drafts || drafts.length === 0} // If results is 0
      header={{
        title: 'My Drafts',
        action: {
          title: 'View More',
          onClick: () => actions.openDraftedOrders(),
        },
      }}
      emptyState={{
        title: 'No Drafted Orders',
        description: 'You currently have no drafted orders',
        icon: 'drafts',
        contentMaxWidth: '280px',
        action: {
          title: 'Send a card today',
          onClick: () => actions.openCatalog(),
        },
      }}
    >
      <Flex flexDirection="column" width="100%" inset={{ right: 'x2' }}>
        {drafts &&
          drafts.map((draft, index) => {
            return (
              <CardLineItem
                key={index}
                color="#D8B330"
                card={{
                  name: draft.title,
                  isHorizontal: draft.isHorizontal,
                  thumbnail: draft.preview,
                }}
                date={draft.date}
              />
            )
          })}
      </Flex>
    </Widget>
  )
}
