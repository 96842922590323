import { Operation } from '../../../__generated__/globalTypes'

import {
  DraftFragment,
  draftFragmentString,
} from '../../fragments/__generated__/DraftFragment'

import getDraftsRawString from '../GetDrafts.graphql'

const getDraftsString = [getDraftsRawString, draftFragmentString].join('\n\n')

export type GetDraftsData = {
  drafts: DraftFragment[]
}

export type GetDraftsVariables = {
  offset: number
  limit: number
}

export type GetDrafts = Operation<GetDraftsData, GetDraftsVariables>

export const GetDrafts = ({
  offset,
  limit,
}: GetDraftsVariables): GetDrafts => ({
  query: getDraftsString,
  variables: {
    offset,
    limit,
  },
})
