import React from 'react'
import { LeaderType } from '../LeaderBoard'
import { LeaderAvatar } from './LeaderAvatar'
import { useSelector } from 'src/hooks'
import HoverTooltip from 'src/chrome/HoverTooltip/HoverTooltip'
import { Flex, Icon, Text } from '@sendoutcards/quantum-design-ui'
import { LeaderboardLeaderBoardTypeChoices } from 'src/graphql/generated/graphql'
import { useLeaderBoardMarketingContent } from 'src/react_query'

type UserMetricPillType = LeaderType & {
  title: string
  leaderboardType: LeaderboardLeaderBoardTypeChoices
}

const UserMetricToolTip: React.FC<{
  leaderboardType: LeaderboardLeaderBoardTypeChoices
}> = ({ leaderboardType }) => {
  const { data: marketingContent } = useLeaderBoardMarketingContent()

  if (!marketingContent) {
    return <div />
  }

  const tooltipMessage =
    leaderboardType === LeaderboardLeaderBoardTypeChoices.Trans
      ? marketingContent.totalNewTransactions.content
      : leaderboardType === LeaderboardLeaderBoardTypeChoices.Dist
      ? marketingContent.totalTransactionsPerAssetOnePerUser.content
      : leaderboardType === LeaderboardLeaderBoardTypeChoices.First
      ? marketingContent.totalTransactionsPerAsset.content
      : ''

  return (
    <HoverTooltip
      tooltipDirection="bottom"
      tooltipChildren={
        <div style={{ maxWidth: '300px' }}>
          <Text type="footnote" color="accent">
            {tooltipMessage}
          </Text>
        </div>
      }
      shouldDisplayTooltip={tooltipMessage !== ''}
      isFullWidth={true}
      background="dark"
      shouldSupportOnTapAsHover={true}
    >
      <Flex justifyContent="center" alignItems="center" inset={{ left: 'x0' }}>
        <Icon name="information" size="xSmall" primaryColor="default" />
      </Flex>
    </HoverTooltip>
  )
}

const UserMetricPill: React.FC<UserMetricPillType> = ({
  title = 'My Current Sales',
  profileImageUrl,
  firstName,
  lastName,
  earnedCount,
  leaderboardType,
  dateJoined,
}) => {
  const isSmallMobile = useSelector(state => state.window.width <= 450)

  return (
    <Flex
      height="65px"
      width={isSmallMobile ? '99%' : '95%'}
      borderRadius={'100px'}
      backgroundColor={'#323338'}
      alignItems={'center'}
      inset="9px"
    >
      <LeaderAvatar
        profileImageUrl={profileImageUrl ?? ''}
        size={isSmallMobile ? '40px' : '50px'}
        firstName={firstName}
        lastName={lastName}
      />

      <Flex flexDirection="column" outset={{ horizontal: 'x2' }}>
        <Flex alignItems="center">
          <Text
            content={title}
            type="footnote"
            style={{ color: '#9E97AB' }}
            lineHeight={1.2}
          />
          <UserMetricToolTip leaderboardType={leaderboardType} />
        </Flex>
        <Text
          content={`${firstName} ${lastName}`}
          type={isSmallMobile ? 'footnote' : 'body'}
          weight={isSmallMobile ? 'semiBold' : 'extraBold'}
          color="inverseHeading"
          style={{ letterSpacing: '2%' }}
        />
      </Flex>
      <Flex
        alignItems="center"
        justifyContent="center"
        style={{ marginLeft: 'auto' }}
        width="45px"
        height="45px"
        backgroundColor="#27282C"
        borderRadius="circle"
      >
        <Text
          type="body"
          alignment="center"
          color="inverseHeading"
          weight="semiBold"
          content={earnedCount}
        />
      </Flex>
    </Flex>
  )
}

export default UserMetricPill
