import { Maybe } from '../../../__generated__/globalTypes'

import {
  SendableCardImageFragment,
  isSendableCardImageFragment,
} from './SendableCardImageFragment'

import {
  ItemizedCostFragment,
  isItemizedCostFragment,
} from './ItemizedCostFragment'

import sendableCardFragmentRawString from '../SendableCardFragment.graphql'

export const sendableCardFragmentString = sendableCardFragmentRawString

export type SendableCardFragment = {
  __typename: 'SendableCard'
  id: string
  title: string
  description: string
  variationColor: string
  isFavorite: boolean
  isHorizontal: boolean
  isPremium: boolean
  frontImage: SendableCardImageFragment
  insideRightImage: Maybe<SendableCardImageFragment>
  cost: ItemizedCostFragment
}

export const isSendableCardFragment = (
  fragment: any,
): fragment is SendableCardFragment =>
  fragment &&
  fragment.__typename === 'SendableCard' &&
  typeof fragment.id === 'string' &&
  typeof fragment.title === 'string' &&
  typeof fragment.description === 'string' &&
  typeof fragment.variationColor === 'string' &&
  typeof fragment.isFavorite === 'boolean' &&
  typeof fragment.isHorizontal === 'boolean' &&
  typeof fragment.isPremium === 'boolean' &&
  (isSendableCardImageFragment(fragment.frontImage) as boolean) &&
  (fragment.insideRightImage === null ||
    (isSendableCardImageFragment(fragment.insideRightImage) as boolean)) &&
  (isItemizedCostFragment(fragment.cost) as boolean)
