import React from 'react'
import moment, { Moment } from 'moment'
import Button from '@material-ui/core/Button'
import range from 'lodash/range'

import { compact } from 'src/helpers'
import PickerHeading from './PickerHeading/PickerHeading'

import styles from './calendarDateRangePicker.module.scss'
import { useCallback, useEffect, useState } from 'src/hooks'

interface Props {
  onChangeDate?: (startDate?: Moment, endDate?: Moment) => void
  onSubmit: (startDate?: Moment, endDate?: Moment) => void
  shouldHideYear?: boolean
  currentDate?: string
  isSingleDateOnly?: boolean
  isPastNotSelectable?: boolean
  onCancel?: () => void
}

const Day = ({
  currentDate,
  date,
  startDate,
  endDate,
  onMouseDown,
  pastNotSelectable,
}: {
  currentDate: Moment
  date: Moment
  startDate?: Moment
  endDate?: Moment
  onMouseDown: (selectedDate: Moment) => void
  pastNotSelectable: boolean
}) => {
  const className = compact(
    pastNotSelectable && date.isBefore(moment()) && styles.notSelectable,
    moment().isSame(date, 'day') && styles.active,
    date.isSame(startDate, 'day') && styles.start,
    date.isBetween(startDate, endDate, 'day') && styles.between,
    date.isSame(endDate, 'day') && styles.end,
    !date.isSame(currentDate, 'month') && styles.muted,
  ).join(' ')
  return (
    <span
      id={`calendar_day_${date.date()}`}
      onMouseDown={() => onMouseDown(date)}
      className={className}
    >
      {date.date()}
    </span>
  )
}

const Days = ({
  date,
  startDate,
  endDate,
  onMouseDown,
  pastNotSelectable,
}: {
  date: Moment
  startDate?: Moment
  endDate?: Moment
  onMouseDown: (selectedDate: Moment) => void
  pastNotSelectable: boolean
}) => {
  const thisDate = moment(date)
  const daysInMonth = moment(date).daysInMonth()
  const firstDayDate = moment(date).startOf('month')
  const previousMonth = moment(date).subtract(1, 'month')
  const previousMonthDays = previousMonth.daysInMonth()
  const nextMonth = moment(date).add(1, 'month')

  const labels = range(0, 7).map(i => (
    <span key={i} className={styles.label}>
      {moment().weekday(i).format('ddd')}
    </span>
  ))

  const weekday = firstDayDate.day()
  const daysCount = weekday + daysInMonth
  const fillCal = daysCount > 35 ? 42 - daysCount : 35 - daysCount
  const days = [
    // Map days of previous month to start this calendar month on correct day of the week
    ...range(weekday, 0, -1).map(i => {
      previousMonth.date(previousMonthDays - i + 1) // TODO: Remove side-effect here
      return (
        <Day
          key={moment(previousMonth).format('ddd DD MMM YYYY')}
          onMouseDown={onMouseDown}
          currentDate={date}
          date={moment(previousMonth)}
          startDate={startDate}
          endDate={endDate}
          pastNotSelectable={pastNotSelectable}
        />
      )
    }),
    // Map days of current month
    ...range(1, daysInMonth + 1).map(i => {
      thisDate.date(i) // TODO: Remove side-effect here
      return (
        <Day
          key={moment(thisDate).format('ddd DD MMM YYYY')}
          onMouseDown={onMouseDown}
          currentDate={date}
          date={moment(thisDate)}
          startDate={startDate}
          endDate={endDate}
          pastNotSelectable={pastNotSelectable}
        />
      )
    }),
    // Map days of next month to fill calendar
    ...range(1, fillCal + 1).map(i => {
      nextMonth.date(i) // TODO: Remove side-effect here
      return (
        <Day
          key={moment(nextMonth).format('ddd DD MMM YYYY')}
          onMouseDown={onMouseDown}
          currentDate={date}
          date={moment(nextMonth)}
          startDate={startDate}
          endDate={endDate}
          pastNotSelectable={pastNotSelectable}
        />
      )
    }),
  ]

  return (
    <nav className={styles['calendar--days']}>
      {labels.concat()}
      {days.concat()}
    </nav>
  )
}

const startAndEndDates = (
  selectedDate: Moment,
  startDate?: Moment,
  endDate?: Moment,
) => {
  if (
    startDate === undefined ||
    selectedDate.isBefore(startDate, 'day') ||
    !startDate.isSame(endDate, 'day')
  ) {
    return {
      startDate: moment(selectedDate),
      endDate: moment(selectedDate),
    }
  } else if (
    selectedDate.isSame(startDate, 'day') &&
    selectedDate.isSame(endDate, 'day')
  ) {
    return { startDate: undefined, endDate: undefined }
  } else if (selectedDate.isAfter(startDate, 'day')) {
    return { startDate, endDate: moment(selectedDate) }
  } else {
    return { startDate, endDate }
  }
}

const isSelectable = (selectedDate: Moment): boolean =>
  moment(selectedDate).isAfter(moment())

const CalendarDateRangePicker: React.FC<Props> = props => {
  const {
    onChangeDate,
    isPastNotSelectable,
    isSingleDateOnly,
    onCancel,
    shouldHideYear,
  } = props

  const currentDate = props.currentDate ? moment(props.currentDate) : moment()

  const [date, setDate] = useState<Moment>(currentDate)
  const [startDate, setStartDate] = useState<Moment | undefined>(currentDate)
  const [endDate, setEndDate] = useState<Moment | undefined>(currentDate)

  useEffect(() => {
    onChangeDate?.(startDate, endDate)
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [startDate, endDate])

  const changeMonth = useCallback(
    (month: number) => {
      const newDate = moment(date)
      newDate.month(month)
      setDate(newDate)
    },
    [date],
  )

  const changeYear = useCallback(
    (year: number) => {
      const newDate = moment(date)
      newDate.year(year)
      setDate(newDate)
    },
    [date],
  )

  const changeDate = useCallback(
    (selectedDate: Moment) => {
      if (!isPastNotSelectable || isSelectable(selectedDate)) {
        if (isSingleDateOnly) {
          setStartDate(selectedDate)
          setEndDate(selectedDate)
        } else {
          const newRange = startAndEndDates(selectedDate, startDate, endDate)

          setStartDate(newRange.startDate)
          setEndDate(newRange.endDate)
        }
      }
    },
    [startDate, endDate, isSingleDateOnly, isPastNotSelectable],
  )

  return (
    <div className={styles.calendar}>
      <PickerHeading
        shouldHideYear={shouldHideYear}
        date={date}
        changeMonth={changeMonth}
        changeYear={changeYear}
      />
      <Days
        onMouseDown={changeDate}
        date={date}
        startDate={startDate}
        endDate={endDate}
        pastNotSelectable={!!isPastNotSelectable}
      />
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {onCancel && <Button onClick={onCancel}>Cancel</Button>}
        <div id={`date_range_picker_submit_btn`}>
          <Button
            onClick={() => props.onSubmit(startDate, endDate)}
            classes={{
              label: styles.submitButtonLabel,
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CalendarDateRangePicker
