import { UpdateGroupInput, Operation } from '../../../__generated__/globalTypes'

import {
  DetailedGroupFragment,
  detailedGroupFragmentString,
} from '../../fragments/__generated__/DetailedGroupFragment'

import { optionalYearDateFragmentString } from '../../fragments/__generated__/OptionalYearDateFragment'

import { groupFragmentString } from '../../fragments/__generated__/GroupFragment'

import { contactFragmentString } from '../../fragments/__generated__/ContactFragment'

import updateGroupRawString from '../UpdateGroup.graphql'

const updateGroupString = [
  updateGroupRawString,
  detailedGroupFragmentString,
  optionalYearDateFragmentString,
  groupFragmentString,
  contactFragmentString,
].join('\n\n')

export type UpdateGroupData = {
  updateGroup: {
    group: DetailedGroupFragment
  }
}

export type UpdateGroupVariables = {
  group: UpdateGroupInput
}

export type UpdateGroup = Operation<UpdateGroupData, UpdateGroupVariables>

export const UpdateGroup = ({ group }: UpdateGroupVariables): UpdateGroup => ({
  query: updateGroupString,
  variables: {
    group: UpdateGroupInput(group),
  },
})
