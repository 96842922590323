import { Maybe } from '../../../__generated__/globalTypes'

import {
  DetailedContactFragment,
  isDetailedContactFragment,
} from './DetailedContactFragment'

import socProContactFragmentRawString from '../SocProContactFragment.graphql'

export const socProContactFragmentString = socProContactFragmentRawString

export type SocProContactFragment = {
  __typename: 'SocProContact'
  id: string
  contactId: string
  email: string
  firstName: string
  lastName: string
  fullName: string
  smsPhone: string
  homePhone: string
  workPhone: string
  address: string
  address2: string
  zip: string
  city: string
  state: string
  country: string
  metadataBlob: string
  socContact: Maybe<DetailedContactFragment>
}

export const isSocProContactFragment = (
  fragment: any,
): fragment is SocProContactFragment =>
  fragment &&
  fragment.__typename === 'SocProContact' &&
  typeof fragment.id === 'string' &&
  typeof fragment.contactId === 'string' &&
  typeof fragment.email === 'string' &&
  typeof fragment.firstName === 'string' &&
  typeof fragment.lastName === 'string' &&
  typeof fragment.fullName === 'string' &&
  typeof fragment.smsPhone === 'string' &&
  typeof fragment.homePhone === 'string' &&
  typeof fragment.workPhone === 'string' &&
  typeof fragment.address === 'string' &&
  typeof fragment.address2 === 'string' &&
  typeof fragment.zip === 'string' &&
  typeof fragment.city === 'string' &&
  typeof fragment.state === 'string' &&
  typeof fragment.country === 'string' &&
  typeof fragment.metadataBlob === 'string' &&
  (fragment.socContact === null ||
    (isDetailedContactFragment(fragment.socContact) as boolean))
