import groupFragmentRawString from '../GroupFragment.graphql'

export const groupFragmentString = groupFragmentRawString

export type GroupFragment = {
  __typename: 'Group'
  id: string
  name: string
  description: string
}

export const isGroupFragment = (fragment: any): fragment is GroupFragment =>
  fragment &&
  fragment.__typename === 'Group' &&
  typeof fragment.id === 'string' &&
  typeof fragment.name === 'string' &&
  typeof fragment.description === 'string'
