import React from 'react'
import { AsYouType } from 'libphonenumber-js'

import { CreateContactInput } from 'src/legacy_graphql'
import { Div, Flex, Input, Text } from '@sendoutcards/quantum-design-ui'
import styles from './contactGroupsForm.module.scss'

const formatPhoneNumber = (numbers: string) =>
  new AsYouType('US').input(numbers)

const handlePhoneNumberChange = (
  value: string,
  cb: (number: string) => void,
  previous: string | null | undefined,
) => {
  cb(
    previous && value.length > previous.length
      ? formatPhoneNumber(value)
      : value,
  )
}

type Props<T extends CreateContactInput> = {
  contact: T
  onChange: (update: Partial<T>) => void
  fields: object
}

const ContactNumbersForm = <T extends CreateContactInput>(props: Props<T>) => {
  const {
    contact: {
      homePhone,
      workPhone,
      cellPhone,
      faxNumber,
      website,
      emailAddress,
    },
    onChange,
  } = props

  const handleChange = <K extends keyof T>(key: K, value: T[K]) =>
    onChange(({ [key]: value } as unknown) as Partial<T>)

  const inputStyle = styles.input

  return (
    <Flex flexDirection="column" rowGap="x2">
      <Div className={inputStyle}>
        <Text type="caption">Home Phone</Text>
        <Input
          type="text"
          value={homePhone ? homePhone : ''}
          isFullWidth={true}
          placeholder="Home Phone"
          onChange={value =>
            handlePhoneNumberChange(
              value,
              number => handleChange('homePhone', number),
              homePhone,
            )
          }
          name="home_phone_field"
          maxLength={30}
        />
      </Div>
      <Div className={inputStyle}>
        <Text type="caption">Work Phone</Text>
        <Input
          type="text"
          value={workPhone ? workPhone : ''}
          isFullWidth={true}
          placeholder="Work Phone"
          onChange={value =>
            handlePhoneNumberChange(
              value,
              number => handleChange('workPhone', number),
              workPhone,
            )
          }
          name="work_phone_field"
          maxLength={30}
        />
      </Div>
      <Div className={inputStyle}>
        <Text type="caption">Cell Phone</Text>
        <Input
          type="text"
          value={cellPhone ? cellPhone : ''}
          isFullWidth={true}
          placeholder="Cell Phone"
          onChange={value =>
            handlePhoneNumberChange(
              value,
              number => handleChange('cellPhone', number),
              cellPhone,
            )
          }
          name="cell_phone_field"
          maxLength={30}
        />
      </Div>
      <Div className={inputStyle}>
        <Text type="caption">Fax Number</Text>
        <Input
          type="text"
          value={faxNumber ? faxNumber : ''}
          isFullWidth={true}
          placeholder="Fax Number"
          onChange={value =>
            handlePhoneNumberChange(
              value,
              number => handleChange('faxNumber', number),
              faxNumber,
            )
          }
          name="fax_number_field"
          maxLength={30}
        />
      </Div>
      <Div className={inputStyle}>
        <Text type="caption">Website</Text>
        <Input
          type="text"
          value={website ? website : ''}
          isFullWidth={true}
          placeholder="Website"
          onChange={value => handleChange('website', value)}
          name="website_field"
          maxLength={50}
        />
      </Div>
      <Div className={inputStyle}>
        <Text type="caption">Email Address</Text>
        <Input
          type="text"
          value={emailAddress ? emailAddress : ''}
          placeholder="Email Address"
          isFullWidth={true}
          onChange={value => handleChange('emailAddress', value)}
          name="email_address_field"
          maxLength={100}
        />
      </Div>
    </Flex>
  )
}

export default ContactNumbersForm
