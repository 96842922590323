import React from 'react'
import moment from 'moment'

import { Line } from 'src/styled'
import { Button } from 'src/chrome'

import styles from './notification.module.scss'

interface Props<T> {
  dateShared: string
  from: string
  notification: T
  onAccept: (notification: T) => void
  onDeny: (notification: T) => void
  sharedItemName: string
  title: string
}

const Notification = <T extends {}>(
  props: React.PropsWithChildren<Props<T>>,
) => {
  const {
    dateShared,
    from,
    notification,
    onAccept,
    onDeny,
    sharedItemName,
    title,
  } = props
  return (
    <div className={styles.notification}>
      <div style={{ display: 'flex', flexFlow: 'row wrap' }}>
        <Line>
          <p>{`${title}:`}</p>
          <span>{sharedItemName}</span>
        </Line>
        <Line>
          <p>Shared by:</p>
          <span>{from}</span>
        </Line>
        <Line>
          <p>Shared on:</p>
          <span>{moment(dateShared).format('MMM Do, YYYY')}</span>
        </Line>
      </div>
      <div className={styles.notificationActions}>
        <Button title="Decline" onClick={() => onDeny(notification)} />
        <Button
          title="Accept"
          buttonColor={'pink'}
          onClick={() => onAccept(notification)}
        />
      </div>
    </div>
  )
}

export default Notification
