import React, { CSSProperties, FC } from 'react'
import SwipeableViews from 'react-swipeable-views'
import {
  getComputedFullBleeds,
  getComputedSingleFullBleeds,
  getSinglePanelIndex,
  getSinglePanelIndexMap,
  variations as getVariations,
} from '../../../redux/selectors/editor'
import FullBleedComponent from '../FullBleed'
import styles from '../../styles/editor.module.scss'
import { Api } from '../../api'
import { useEffect } from 'src/hooks'

const tabStyles = {
  swipeableView: {
    position: 'relative',
    height: '100%',
  } as CSSProperties,
  slide: {
    position: 'relative',
    height: '100%',
    overflow: 'initial',
  } as CSSProperties,
  containerStyle: {
    height: '100%',
    width: '100vw',
    alignItems: 'start',
    paddingBottom: '2.5rem',
  } as CSSProperties,
  mobileContaierStyle: {
    height: '100%',
    width: '100vw',
    alignItems: 'start',
  } as CSSProperties,
  mobileHorizontalContainerStyles: {
    height: '100%',
    width: '100vw',
    alightItems: 'center',
  },
}

type Props = {
  swipeableViewWidth: string
  api: Api
  saveButtonLabel: string
}

const EditorTabs: FC<Props> = props => {
  const { swipeableViewWidth, api } = props
  const {
    card,
    panelView,
    step,
    fullBleeds,
    isMobile,
    setStep,
    resolveLocation,
  } = api
  const { fullBleedIndex, panelIndex } = step

  // TODO: React Query Refactor not sure if this is how we want to handle undefined results in RQ
  if (!card) throw Error('No card provided!')

  const computedFullBleeds =
    panelView === 'fullbleed'
      ? getComputedFullBleeds(card, fullBleeds, step)
      : getComputedSingleFullBleeds(card, fullBleeds, step)

  const mobilePanelLocation = getSinglePanelIndex(
    fullBleeds,
    fullBleedIndex,
    panelIndex,
  )

  const handleIndexChange = (index: number) => {
    if (panelView === 'fullbleed') {
      setStep({ ...step, fullBleedIndex: index })
    } else {
      const { fullBleedIndex, panelIndex } = getSinglePanelIndexMap(fullBleeds)[
        index
      ]
      setStep({ ...step, fullBleedIndex, panelIndex })
    }
  }

  const element = document.getElementById('editor-window')
  const clientHeight = element?.clientHeight
  const smallMobileBreakPoint = 765

  const hasVariations = getVariations(card)
  const isHorizontalCard = card.isHorizontal

  const isFrontPanel = resolveLocation(card.type) === 'Front'
  const isBackPanel = resolveLocation(card.type) === 'Back'
  const isOutsideFlap = resolveLocation(card.type) === 'Outside Flap'
  const isInnerFullBleed = resolveLocation(card.type) === 'Inside'

  const isFullBleed = panelView === 'fullbleed'

  const hasHorizontalOrientation =
    (isHorizontalCard && isFullBleed && isFrontPanel) ||
    (isHorizontalCard && !isFullBleed && !isBackPanel) ||
    (isHorizontalCard && isFullBleed && isOutsideFlap)

  const hasVerticalOrientation =
    !isHorizontalCard &&
    isFullBleed &&
    !isFrontPanel &&
    !isBackPanel &&
    !isOutsideFlap

  const isHorizontalVariationCard =
    hasVariations && isHorizontalCard && !isInnerFullBleed && !isBackPanel

  const isVerticalVariationCard =
    hasVariations &&
    !isHorizontalCard &&
    isFullBleed &&
    !isFrontPanel &&
    !isBackPanel

  useEffect(() => {
    api.setShouldLoadDesignColors(true)
  }, []) // eslint-disable-line

  return (
    <section
      id={'editor-window'}
      className={styles.tabContent}
      style={
        {
          display: 'flex',
          justifyContent: 'center',
          verticalAlign: 'middle',
          alignItems: 'center',
          alignContent: 'center',
          overflowY: 'hidden',
        } as CSSProperties
      }
    >
      <SwipeableViews
        index={panelView === 'fullbleed' ? fullBleedIndex : mobilePanelLocation}
        onChangeIndex={handleIndexChange}
        style={tabStyles.swipeableView}
        containerStyle={
          clientHeight && clientHeight >= smallMobileBreakPoint
            ? ({
                ...tabStyles.containerStyle,
                width: swipeableViewWidth,
              } as CSSProperties)
            : isVerticalVariationCard && isMobile
            ? ({
                ...tabStyles.mobileContaierStyle,
                paddingTop: '100px',
                width: swipeableViewWidth,
              } as CSSProperties)
            : isHorizontalVariationCard && isMobile
            ? ({
                ...tabStyles.containerStyle,
                paddingTop: '100px',
                width: swipeableViewWidth,
              } as CSSProperties)
            : hasHorizontalOrientation || hasVerticalOrientation
            ? ({
                ...tabStyles.mobileHorizontalContainerStyles,
                width: swipeableViewWidth,
              } as CSSProperties)
            : ({
                ...tabStyles.mobileContaierStyle,
                width: swipeableViewWidth,
              } as CSSProperties)
        }
        slideStyle={
          {
            ...tabStyles.slide,
            ...(isMobile && { height: 'initial' }),
          } as CSSProperties
        }
      >
        {computedFullBleeds.map(
          (fullBleed, index): JSX.Element => {
            return (
              <div
                key={index}
                style={
                  {
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  } as CSSProperties
                }
              >
                <div
                  style={
                    { position: 'relative', width: '100%' } as CSSProperties
                  }
                >
                  <FullBleedComponent api={api} fullBleed={fullBleed} />
                </div>
              </div>
            )
          },
        )}
      </SwipeableViews>
      {/*
           NOTES:
           We do not allow design overrides for catalog cards in the Prompt Editor. If element.locked do not try to replace elements!
           Users May select a custom template layout on any panel that is not supplied a design or element.locked is false.
           All editor actions are preformed within a <Drawer /> component <Images/ImageFilters/Text/Templates>.
           Variations are supplied by the { SendableCard } object and only if a Variations [array] exists.
           A Variation is Supplied by the card designer and provides the ability to change the card designs; color/font/assets.
        */}
    </section>
  )
}

export default EditorTabs
