import {
  Maybe,
  CardBillingAddressInput,
  Operation,
} from '../../../__generated__/globalTypes'

import {
  StripeSourceFragment,
  stripeSourceFragmentString,
} from '../../fragments/__generated__/StripeSourceFragment'

import { bankAccountFragmentString } from '../../fragments/__generated__/BankAccountFragment'

import { creditCardFragmentString } from '../../fragments/__generated__/CreditCardFragment'

import { creditCardBillingAddressFragmentString } from '../../fragments/__generated__/CreditCardBillingAddressFragment'

import updateSourceBillingAddressRawString from '../UpdateSourceBillingAddress.graphql'

const updateSourceBillingAddressString = [
  updateSourceBillingAddressRawString,
  stripeSourceFragmentString,
  bankAccountFragmentString,
  creditCardFragmentString,
  creditCardBillingAddressFragmentString,
].join('\n\n')

export type UpdateSourceBillingAddressData = {
  updateSourceBillingAddress: {
    account: {
      stripeSource: Maybe<StripeSourceFragment>
    }
  }
}

export type UpdateSourceBillingAddressVariables = {
  billingAddress: CardBillingAddressInput
}

export type UpdateSourceBillingAddress = Operation<
  UpdateSourceBillingAddressData,
  UpdateSourceBillingAddressVariables
>

export const UpdateSourceBillingAddress = ({
  billingAddress,
}: UpdateSourceBillingAddressVariables): UpdateSourceBillingAddress => ({
  query: updateSourceBillingAddressString,
  variables: {
    billingAddress: CardBillingAddressInput(billingAddress),
  },
})
