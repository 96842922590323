import { Operation } from '../../../__generated__/globalTypes'

import {
  MarketingParagraphFragment,
  marketingParagraphFragmentString,
} from '../../fragments/__generated__/MarketingParagraphFragment'

import {
  MarketingMultiParagraphFragment,
  marketingMultiParagraphFragmentString,
} from '../../fragments/__generated__/MarketingMultiParagraphFragment'

import getMarketingContentRawString from '../GetMarketingContent.graphql'

const getMarketingContentString = [
  getMarketingContentRawString,
  marketingParagraphFragmentString,
  marketingMultiParagraphFragmentString,
].join('\n\n')

export type GetMarketingContentData = {
  complianceMessage: MarketingParagraphFragment
  whyMyPhoneNumberMessage: MarketingParagraphFragment
  unverifiedAccountsFoundCopy: MarketingParagraphFragment
  phoneNotFoundCopy: MarketingParagraphFragment
  customerReferralOptInMessage: MarketingParagraphFragment
  greenerStillAffiliateOptInMessage: MarketingParagraphFragment
  sharingInformationCopy: MarketingParagraphFragment
  createAccountMessage: MarketingParagraphFragment
  bulkPromoJoinTitle: MarketingParagraphFragment
  bulkPromoJoinMessage: MarketingParagraphFragment
  loginWelcomeMessage: MarketingParagraphFragment
  affiliateLoginMessage: MarketingParagraphFragment
  customerReferralOptInTermsAndConditions: MarketingMultiParagraphFragment
  greenerStillAffiliateOptInTermsAndConditions: MarketingMultiParagraphFragment
  immediateSendEditingMessage: MarketingParagraphFragment
  futureSendEditingMessage: MarketingParagraphFragment
  subscriptionPurchaseSuccess: MarketingParagraphFragment
  cardPackagePurchaseSuccess: MarketingParagraphFragment
  compPlanTrainingLink: MarketingParagraphFragment
  createAccountRateLimitReached: MarketingParagraphFragment
  streamVaPromo: MarketingParagraphFragment
  affiliateProfileCompleteTitle: MarketingParagraphFragment
  affiliateProfileWarningTitle: MarketingParagraphFragment
  affiliateProfileWarningBody: MarketingParagraphFragment
}

export type GetMarketingContent = Operation<GetMarketingContentData>

export const GetMarketingContent = (): GetMarketingContent => ({
  query: getMarketingContentString,
  variables: undefined,
})
