import React, { useRef } from 'react'

// @src imports
import { Button } from 'src/chrome'
import {
  ConfirmDialog,
  Dialog,
  Div,
  Flex,
  LoadingSpinner,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { noop } from 'src/helpers/appHelpers'
import { useAccount, useActions, useSelector, useState } from 'src/hooks'
// relative imports
import { BaseCreditCard, EmptyCard } from '..'
import CreditCard from '../CreditCard/CreditCard'

import styles from '../../../dashboard/containers/Views/PlansAndPayment/components/PaymentSettings/styles'
import CreditCardForm, { CCAddress } from '../CreditCardForm/CreditCardForm'
import { Button as DSButton } from 'src/design_system/molecules/button/Button'
import { Token } from '@stripe/stripe-js'
import { parseError } from 'src/utils/parseError'
import { affiliateCountryOptionsForQDS } from 'src/pricing_page/components/singlePlanTile/SinglePlanTile'
import { MarketingParagraphBanner } from 'src/chrome/MarketingParagraphBanner/MarketingParagraphBanner'
import CreditCardBillingAddressForm, {
  CCBillingAddress,
} from '../CreditCardForm/CreditCardBillingAddressForm'
import useSubmitCard from 'src/hooks/useSubmitCard'
import {
  AccountFragment,
  AccountInput,
  StripeSourceFragment,
} from 'src/graphql/generated/graphql'
import {
  useAddPaymentSettingsDailyCap,
  useCreditCardManagerMarketingContent,
  useUpdateAccount,
} from 'src/react_query'
import { Portal } from '@material-ui/core'

export type CreditCardManagerProps = {
  shouldMinimizeCard?: boolean
  shouldShowAffiliateCountriesOnly?: boolean
  onAddedCardSuccess?: (stripeSource?: StripeSourceFragment) => void
  onUpdateCreditCardBillingAddressSuccess?: (address: CCBillingAddress) => void
}

const MP_TOOLTIP_FOR_AFFILIATE_COUNTRY_SEL = '162'

const CreditCardManager: React.FC<CreditCardManagerProps> = props => {
  const [isEditingCard, setIsEditingCard] = useState(false)
  const [isAddingCard, setIsAddingCard] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isAddingBillingAddress, setIsAddingBillingAddress] = useState(false)
  const [isRemoveConfirmationOpen, setIsRemoveConfirmationOpen] = useState(
    false,
  )
  const [errorMessage, setErrorMessage] = useState<string | undefined>()

  const actions = useActions()
  const account = useAccount()

  const addPaymentSettingsDailyCapQuery = useAddPaymentSettingsDailyCap()
  const addPaymentSettingsDailyCap =
    addPaymentSettingsDailyCapQuery.data?.addPaymentSettingsDailyCap
  const marketingContentQuery = useCreditCardManagerMarketingContent({
    suspense: true,
  })
  const marketingContent = marketingContentQuery.data
  const updateAccountMutation = useUpdateAccount()
  const submitCard = useSubmitCard()
  const submitCardRef = useRef<HTMLButtonElement>(null)
  const { width } = useSelector(state => state.window)
  const isMobile = width <= 950
  const [isFormComplete, setIsFormComplete] = useState(false)

  const stripeSource = account.stripeSource
  const hasBillingAddress =
    stripeSource &&
    stripeSource.__typename === 'CreditCard' &&
    stripeSource.billingAddress &&
    stripeSource.billingAddress.line1
  const accountName = `${account.firstName} ${account.lastName}`

  const shouldStackConfirmDialogActions = useSelector(
    state => state.window.width < 700,
  )

  if (
    addPaymentSettingsDailyCapQuery.isLoading ||
    marketingContentQuery.isLoading ||
    !marketingContent
  ) {
    return <LoadingSpinner size="medium" />
  }

  const updateAccount = async (
    data: AccountInput,
    success: (updatedAccount: AccountFragment) => void,
    error: (error: Error | string) => void = noop,
  ) => {
    try {
      const {
        updateAccount: { account: updatedAccount },
      } = await updateAccountMutation.mutateAsync({ account: { ...data } })
      actions.updatedAccount(updatedAccount)
      success(updatedAccount)
    } catch (err) {
      error(parseError(err))
    }
  }

  const removeCard = () => {
    setIsLoading(true)
    updateAccount(
      { stripeToken: null },
      (updatedAccount: AccountFragment) => {
        setIsLoading(false)
        setIsAddingCard(false)
        setIsEditingCard(false)
        setErrorMessage(undefined)
      },
      () => {
        setIsLoading(false)
        const message = 'Failed to remove credit card.'
        setErrorMessage(message)
      },
    )
  }

  const confirmRemoveCard = () => {
    if (
      addPaymentSettingsDailyCap &&
      addPaymentSettingsDailyCap.available > 0
    ) {
      removeCard()
    } else {
      setIsRemoveConfirmationOpen(true)
    }
  }

  const setCard = (token: Token, address: CCAddress) => {
    setIsLoading(true)
    submitCard.submitCard(
      token,
      address,
      undefined,
      updatedAccount => {
        setIsLoading(false)
        setIsAddingCard(false)
        setIsEditingCard(false)
        setErrorMessage(undefined)
        actions.invalidatedQueriesByName('GetAddPaymentSettingsDailyCap')
        props.onAddedCardSuccess?.(updatedAccount.stripeSource ?? undefined)
      },
      () => {
        setIsLoading(false)
        setIsAddingCard(false)
        setIsEditingCard(false)
        const message: string = marketingContent?.creditCardUpdateRateLimitReached
          ? marketingContent.creditCardUpdateRateLimitReached.content
          : 'Failed to add credit card.'
        setErrorMessage(message)
      },
    )
  }

  const addCard = () => {
    submitCardRef.current?.click()
  }

  return !stripeSource ? (
    <Flex width="100%" justifyContent={isMobile ? 'center' : undefined}>
      {!isAddingCard && (
        <div
          style={{
            transform: props.shouldMinimizeCard ? 'scale(.75)' : '',
          }}
        >
          <BaseCreditCard onClick={() => setIsAddingCard(true)}>
            <EmptyCard message={'Add a Payment Source'} />
          </BaseCreditCard>
        </div>
      )}
      {isAddingCard && (
        <Portal>
          <Dialog
            width={isMobile ? '100dvw' : undefined}
            height={isMobile ? '100dvh' : undefined}
            isOpen={isAddingCard}
            onClose={() => setIsAddingCard(false)}
          >
            <Flex
              height={isMobile ? '100%' : undefined}
              width={isMobile ? '100%' : undefined}
              overflow={'auto'}
            >
              {props.shouldShowAffiliateCountriesOnly && (
                <Div outset={{ bottom: 'x3' }}>
                  <MarketingParagraphBanner
                    id={MP_TOOLTIP_FOR_AFFILIATE_COUNTRY_SEL}
                  />
                </Div>
              )}
              <CreditCardForm
                onSuccess={(token, address) => setCard(token, address)}
                onError={() => null}
                isCCFormComplete={isComplete => {
                  setIsFormComplete(isComplete)
                }}
                isLoading={isLoading}
                overrideCountryOptions={
                  props.shouldShowAffiliateCountriesOnly
                    ? affiliateCountryOptionsForQDS
                    : undefined
                }
              >
                <button
                  form="addCreditCardForm"
                  type="submit"
                  ref={submitCardRef}
                  style={{ display: 'none' }}
                />
                <DSButton
                  fill="#f5f6f7"
                  gap="x_5"
                  padding={'12px 16px'}
                  borderRadius="small"
                  onClick={addCard}
                  isDisabled={!isFormComplete}
                  title={{ content: 'Add Card', fontSize: '12px' }}
                />
              </CreditCardForm>
            </Flex>
          </Dialog>
        </Portal>
      )}
      {errorMessage !== undefined && (
        <Text type="caption" color="danger">
          {errorMessage}
        </Text>
      )}
    </Flex>
  ) : (
    <div>
      <div css={{ position: 'relative', zIndex: 1 }}>
        <div
          style={{ transform: props.shouldMinimizeCard ? 'scale(.75)' : '' }}
        >
          <CreditCard
            cardType={
              stripeSource.__typename === 'CreditCard'
                ? stripeSource.brand
                : 'Bank'
            }
            exp={
              stripeSource.__typename === 'CreditCard'
                ? `${stripeSource.expMonth}/${stripeSource.expYear}`
                : '****'
            }
            last4={stripeSource.last4}
            name={accountName}
          />
        </div>
        {!hasBillingAddress && (
          <CreditCardBillingAddressForm
            onSuccess={address => {
              props.onUpdateCreditCardBillingAddressSuccess?.(address)
            }}
            onError={error => setErrorMessage(error)}
            isCCFormComplete={() => setErrorMessage(undefined)}
            handleIsLoading={value => setIsAddingBillingAddress(value)}
            returnAddress={account.shippingAddress ?? undefined}
            overrideChildContCSS="span 1"
          >
            <Div
              alignItems="center"
              css={{
                gridTemplateColumns: '1fr 1fr',
                display: 'grid',
                gap: '16px',
              }}
            >
              <Button
                title={isLoading ? 'Removing Card...' : 'Remove Card'}
                onClick={confirmRemoveCard}
                disabled={isLoading || isAddingBillingAddress}
              />
              <Button
                buttonColor={'pink'}
                title={
                  isAddingBillingAddress
                    ? 'Adding billing address...'
                    : 'Add billing address'
                }
                disabled={isLoading || isAddingBillingAddress}
              />
            </Div>
          </CreditCardBillingAddressForm>
        )}
        {!isEditingCard && hasBillingAddress && (
          <div
            css={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}
          >
            <Button
              onClick={() => setIsEditingCard(true)}
              title={'Edit Card'}
            />
          </div>
        )}
        {isEditingCard && (
          <div css={styles.formCard}>
            <div
              css={{
                display: 'flex',
                width: 400,
                justifyContent: 'space-around',
              }}
            >
              <Button
                title={isLoading ? 'Removing Card...' : 'Remove Card'}
                onClick={confirmRemoveCard}
                disabled={isLoading}
              />
              <Button
                onClick={() => setIsEditingCard(false)}
                buttonColor={'pink'}
                title={'Done'}
              />
            </div>
          </div>
        )}
      </div>
      {errorMessage !== undefined && (
        <Text type="caption" color="danger">
          {errorMessage}
        </Text>
      )}
      <ConfirmDialog
        zIndex={4000}
        maxWidth={'700px'}
        hasStackedActions={shouldStackConfirmDialogActions}
        isOpen={isRemoveConfirmationOpen}
        title={marketingContent.lastCardRemovalDisclaimerTitle.content}
        description={marketingContent.lastCardRemovalDisclaimerMessage.content
          .replaceAll(
            '{available}',
            addPaymentSettingsDailyCap?.available.toString() ?? 'N/A',
          )
          .replaceAll(
            '{cap}',
            addPaymentSettingsDailyCap?.cap.toString() ?? 'N/A',
          )}
        decline={{
          title: marketingContent.lastCardRemovalDisclaimerDecline.content,
          onClick: () => setIsRemoveConfirmationOpen(false),
        }}
        accept={{
          title: marketingContent.lastCardRemovalDisclaimerAccept.content,
          onClick: () => {
            setIsRemoveConfirmationOpen(false)
            removeCard()
          },
        }}
        primaryAction={'accept'}
        onClose={() => setIsRemoveConfirmationOpen(false)}
        data-mktcontent="lastCardRemovalDisclaimerTitle | lastCardRemovalDisclaimerMessage | lastCardRemovalDisclaimerAccept | lastCardRemovalDisclaimerDecline"
      />
    </div>
  )
}

export default CreditCardManager
