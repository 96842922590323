const maskField = (value: string) => {
  const match = /(.*?)(\d{4}$)/.exec(value)
  if (match !== null) {
    return `${'x'.repeat(match[1].length)}-${match[2]}`
  } else {
    return value
  }
}

export default maskField
