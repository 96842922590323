import { useStore } from 'react-redux'

import { Query } from 'src/legacy_graphql/Query'
import { State } from 'src/redux/reducers'
import Action from 'src/redux/action'

import { useActions, useCallback, useMemo, useQueryFutures } from './'
import { success, zip } from 'src/utils/Future'
import { isNotUndefined } from 'src/helpers'
import fastDeepEqual from 'fast-deep-equal'
import useGetQueryFutures from './useGetQueryFutures'

type Subscription = {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  queries: Query<any, any, any>[]
  promise: Promise<unknown>
}

const mutableSubscriptions = Array<Subscription>()

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
type Result<Q> = Q extends Query<any, any, infer View>
  ? Exclude<View, undefined>
  : undefined

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
type Results<Qs> = Qs extends (Query<any, any> | undefined)[]
  ? { [K in keyof Qs]: Result<Qs[K]> }
  : never

const UNDEFINED_QUERY_PLACEHOLDER = 'UNDEFINED_QUERY_PLACEHOLDER'

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const useQueries = <Qs extends (Query<any, any> | undefined)[]>(
  ...optionalQueries: Qs
): Results<Qs> => {
  const store = useStore<State, Action>()
  const actions = useActions()
  const queryFutures = useQueryFutures(...optionalQueries)

  const getQueriesFuture = useCallback(
    (futures: typeof queryFutures) =>
      zip(
        futures.map(future =>
          future ? future : success(UNDEFINED_QUERY_PLACEHOLDER),
        ),
      ),
    [],
  )

  const queriesFuture = useMemo(() => getQueriesFuture(queryFutures), [
    queryFutures,
    getQueriesFuture,
  ])

  const queries = useMemo(() => optionalQueries.filter(isNotUndefined), [
    optionalQueries,
  ])

  const getQueryFutures = useGetQueryFutures(...optionalQueries)

  if (queriesFuture.isUnresolved) {
    const promise = mutableSubscriptions.find(subscription =>
      fastDeepEqual(subscription.queries, queries),
    )?.promise
    if (promise) {
      throw promise
    } else {
      const promise = new Promise<void>((resolve, reject) => {
        setTimeout(() => actions.prioritizedQueries(queries))
        const mutable = { unsubscribe: () => {}, deprioritized: false }
        mutable.unsubscribe = store.subscribe(() => {
          const { queryStates, normalizedData } = store.getState().graphql
          const queriesFuture = getQueriesFuture(
            getQueryFutures(queryStates, normalizedData),
          )
          if (queriesFuture.isUnresolved) {
            return
          } else if (queriesFuture.error) {
            reject(queriesFuture.error)
          } else {
            resolve()
          }
          mutable.unsubscribe()
          if (!mutable.deprioritized) {
            mutable.deprioritized = true
            actions.deprioritizedQueries(queries)
          }
          const index = mutableSubscriptions.findIndex(subscription =>
            fastDeepEqual(subscription.queries, queries),
          )
          mutableSubscriptions.splice(index, 1)
        })
      })
      mutableSubscriptions.push({ queries, promise })
      throw promise
    }
  }

  if (queriesFuture.error) {
    console.log('err')
    throw queriesFuture.error
  }

  return (queriesFuture.value.map(state =>
    state === UNDEFINED_QUERY_PLACEHOLDER ? undefined : state,
  ) as unknown) as Results<Qs>
}

export default useQueries
