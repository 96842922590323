import { Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
// @src imports
import AddressForm, {
  FormContact,
} from 'src/contacts/components/AddressForm/AddressForm'
import { useCallback } from 'src/hooks'
import { CreateContactInput } from 'src/legacy_graphql'
import { Memoized } from 'src/hooks/dependencies'
import { AddressInput } from 'src/__generated__/globalTypes'

interface Props {
  isSavingAddress?: boolean
  shippingAddress: FormContact // TODO: Update this when photo-manager is merged
  isAddressVisible: boolean
  onSubmitAddress: Memoized<(address: AddressInput) => void>
}

const ShippingAddress: React.FC<Props> = props => {
  const {
    isSavingAddress,
    shippingAddress,
    isAddressVisible,
    onSubmitAddress,
  } = props
  const {
    address1,
    address2,
    firstName,
    lastName,
    city,
    state,
    postalCode,
  } = shippingAddress
  const submitAddress = useCallback(
    async (contact: CreateContactInput) => {
      if (
        contact.firstName != null &&
        contact.lastName != null &&
        contact.companyName != null &&
        contact.address1 != null &&
        contact.address2 != null &&
        contact.city != null &&
        contact.state != null &&
        contact.postalCode != null &&
        contact.country != null
      ) {
        const address: AddressInput = {
          firstName: contact.firstName,
          lastName: contact.lastName,
          company: contact.companyName,
          address1: contact.address1,
          address2: contact.address2,
          city: contact.city,
          state: contact.state,
          postalCode: contact.postalCode,
          country: contact.country,
        }
        onSubmitAddress(address)
      }
    },
    [onSubmitAddress],
  )

  return (
    <div>
      {isAddressVisible && (
        <AddressForm
          contact={shippingAddress}
          shouldShowDates={false}
          isTitleVisible={false}
          isSaving={isSavingAddress}
          onSubmit={submitAddress}
          style={{
            paddingRight: 30,
          }}
        />
      )}
      {!isAddressVisible &&
        (address1 ? (
          <div>
            <Text type="caption">{`${firstName} ${lastName}`}</Text>
            <Text type="caption">{`${address1}`}</Text>
            <Text type="caption">{`${address2}`}</Text>
            <Text type="caption">{`${city}${
              state !== '' ? ' ' + state : ''
            }, ${postalCode}`}</Text>
          </div>
        ) : (
          <Text type="caption">No return address set</Text>
        ))}
    </div>
  )
}

export default ShippingAddress
