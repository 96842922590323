import React, { FC } from 'react'
import { SVGProps } from './types'

export const Person: FC<SVGProps> = ({ size, color, onClick }) => {
  return (
    <svg
      width={size ?? 24}
      height={size ?? 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.1342 22H7.86377C6.04426 22 4.528 21.8424 3.24608 21.503C2.77742 21.3818 2.47417 20.9818 2.50174 20.5576C2.84634 16.9332 7.02293 14.0847 11.999 14.0847C16.975 14.0847 21.1379 16.9211 21.4962 20.5576C21.5376 20.9939 21.2343 21.3818 20.7519 21.503C19.47 21.8424 17.9537 22 16.1342 22ZM4.72098 19.9515C5.63074 20.1091 6.66455 20.1818 7.86377 20.1818H16.1342C17.3334 20.1818 18.3672 20.1091 19.277 19.9515C18.5464 17.6242 15.5277 15.9029 11.999 15.9029C8.47027 15.9029 5.45154 17.6242 4.72098 19.9515Z"
        fill={color ?? '#404040'}
      />
      <path
        d="M11.9978 12.5093C8.70342 12.5093 6.0293 10.1456 6.0293 7.24857C6.0293 4.35158 8.70342 2 11.9978 2C15.2922 2 17.9663 4.35158 17.9663 7.24857C17.9663 10.1456 15.2922 12.5093 11.9978 12.5093ZM11.9978 3.81821C9.84748 3.81821 8.09692 5.35766 8.09692 7.24857C8.09692 9.15163 9.84748 10.6911 11.9978 10.6911C14.1481 10.6911 15.8987 9.15163 15.8987 7.24857C15.8987 5.35766 14.1481 3.81821 11.9978 3.81821Z"
        fill={color ?? '#404040'}
      />
    </svg>
  )
}
