import { Route } from 'src/routes/Route'

export const EditContactRoute = Route({
  path: '/:contactId',
  init: (contactId: string) => ({ contactId }),
  fromPath: ({ contactId }) => (contactId ? { contactId } : undefined),
  doNotResetWindowScroll: true,
})

export type EditContactRoute = Route<typeof EditContactRoute>
