import { useContext } from 'react'
import { usePersistedUserData, useQueries } from 'src/hooks'
import { getDetailedCountries } from 'src/legacy_graphql'
import {
  useAddCheckoutLines,
  useCreateCheckout,
  useDeleteCheckoutLines,
} from 'src/react_query/queries/hooks'
import { CheckoutContext } from 'src/saleor/context/CheckoutContext'
import {
  CheckoutFragment,
  CheckoutLineInput,
} from 'src/saleor_graphql/generated/graphql'
import { mapStripeSourceAddress, mapToCheckoutAddress } from '../utils'

const useHandleProductActions = (stripeId?: string) => {
  const { account } = usePersistedUserData()
  const { state } = useContext(CheckoutContext)
  const { mutate: deleteCheckoutLines } = useDeleteCheckoutLines()
  const {
    mutate: createCheckoutWithLine,
    isLoading: isCreatingCheckout,
    isError: hasCreateError,
    error: createCheckoutError,
  } = useCreateCheckout()

  const {
    mutate: addCheckoutLine,
    isLoading: isUpdatingCheckout,
    isError: hasAddLineError,
    error: addCheckoutLineError,
  } = useAddCheckoutLines()

  const [detailedCountries] = useQueries(getDetailedCountries())
  const hasCheckoutError = hasCreateError || hasAddLineError
  const checkoutErrorMessage = hasCreateError
    ? (createCheckoutError as Error).message
    : hasAddLineError
    ? (addCheckoutLineError as Error).message
    : undefined

  const checkIsInCart = (checkout?: CheckoutFragment | null) => {
    if (!checkout) return false
    return checkout.lines.some(line => {
      return line.variant.sku === stripeId
    })
  }

  const removeProductFromCart = (checkout?: CheckoutFragment | null) => {
    if (!checkout) return
    const product = checkout.lines.find(line => {
      return line.variant.sku === stripeId
    })
    if (product) {
      deleteCheckoutLines({ linesIds: product.id })
    }
  }
  const stripeBilling =
    account &&
    account.stripeSource &&
    account.stripeSource.__typename === 'CreditCard'
      ? account.stripeSource.billingAddress
      : null

  const stripeSourceAddress = mapStripeSourceAddress(stripeBilling ?? undefined)

  const addToCart = async (
    variants?: string | CheckoutLineInput[],
    onSuccess?: () => void,
  ) => {
    if (!variants) {
      console.error(`Missing variants`)
      return
    }

    if (Array.isArray(variants) && variants.length === 0) {
      console.error(`Variants is empty`)
      return
    }

    const checkoutLines: CheckoutLineInput[] = Array.isArray(variants)
      ? variants
      : [{ variantId: variants, quantity: 1 }]

    if (state.checkout) {
      addCheckoutLine({
        id: state.checkout,
        lines: checkoutLines,
      })
    } else {
      createCheckoutWithLine(
        {
          input: {
            channel: 'default-channel',
            billingAddress: stripeSourceAddress
              ? mapToCheckoutAddress(stripeSourceAddress, detailedCountries)
              : account?.shippingAddress?.address1
              ? mapToCheckoutAddress(account.shippingAddress, detailedCountries)
              : undefined,
            shippingAddress: account?.shippingAddress?.address1
              ? mapToCheckoutAddress(account.shippingAddress, detailedCountries)
              : undefined,
            lines: checkoutLines,
          },
        },
        {
          onSettled: data => {
            if (data?.checkout) {
              onSuccess?.()
            }
            if (data?.errors && data.errors.length > 0) {
              throw new Error('Could not add checkout line')
            }
          },
        },
      )
    }
  }

  return {
    hasCheckoutError,
    checkIsInCart,
    addToCart,
    checkoutErrorMessage,
    isCreatingCheckout,
    isUpdatingCheckout,
    removeProductFromCart,
  }
}

export default useHandleProductActions
