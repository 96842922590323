import { Route } from 'src/routes/Route'

export const CustomCardRoute = Route({
  path: '/my-cards/:cardId',
  init: (cardId: string) => ({ cardId }),
  fromPath: ({ cardId }) => (cardId ? { cardId } : undefined),
  doNotResetWindowScroll: true,
})

export type CustomCardRoute = Route<typeof CustomCardRoute>
