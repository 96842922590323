import React from 'react'

import { Transition, UiCard } from 'src/chrome'
import {
  useCallback,
  useEffect,
  useMemo,
  useQueries,
  useState,
} from 'src/hooks'
import { findDateFormat } from 'src/helpers/dates'

import styles from '../importContacts.module.scss'
import HeaderMapper from './HeaderMapper'
import AddDefaults from './AddDefaults'
import DateFormat from './DateFormat'
import Preview from './Preview'
import BottomControls from './BottomControls'
import {
  ColumnDefaults,
  FieldMapping,
  generateMapping,
  getDateColumns,
  getMappedField,
  getMissingRequiredColumns,
  hasAnyDateColumn as getHasAnyDateColumn,
} from '../helpers'
import { Field, Row } from '../columns'
import Hooks from '../hooks'
import { getDetailedCountries } from 'src/legacy_graphql'
import { formatCountriesAndRegions } from 'src/helpers/formatCountriesAndRegions'

interface Props {
  rows: Row[]
  fields: string[]
  processFile: ReturnType<typeof Hooks>['processFile']
  onBack: () => void
}

const MapHeaders: React.FC<Props> = props => {
  const { rows, fields, processFile, onBack } = props

  const [defaults, setDefaults] = useState<ColumnDefaults>({
    country: 'United States',
  })

  const defaultFields = Object.keys(defaults)

  const [mapping, setMapping] = useState<FieldMapping>(
    generateMapping([...fields, ...defaultFields]),
  )

  // undefined initially, set to null if date format cannot be detected
  const [dateFormat, setDateFormat] = useState<string | undefined | null>(
    undefined,
  )

  const [detailedCountries] = useQueries(getDetailedCountries())
  const { countries } = formatCountriesAndRegions(detailedCountries)
  const hasAnyDateColumn = getHasAnyDateColumn(mapping)

  useEffect(() => {
    if (hasAnyDateColumn && dateFormat === undefined) {
      const fields = getDateColumns(mapping).map(
        mappedColumn => mappedColumn.field,
      )
      const format = rows.reduce(
        (format: string | null, row) =>
          format ||
          fields.reduce(
            (format, field) => format || findDateFormat(row[field] ?? ''),
            null as string | null,
          ),
        null as string | null,
      )

      if (format) {
        setDateFormat(format)
      }
    }
  }, [dateFormat, mapping, hasAnyDateColumn, rows])

  const associateColumn = useCallback(
    (field: string, columnApiName: Field) => {
      if (!mapping) return

      const mutableMapping = { ...mapping }
      const associatedField = Object.keys(mutableMapping).find(
        key => mapping[key] === columnApiName,
      )
      // remove possible other association for that column
      if (associatedField) {
        delete mutableMapping[associatedField]
      }

      if (field) {
        mutableMapping[field] = columnApiName
      }

      setMapping(mutableMapping)
    },
    [mapping],
  )

  const setDefault = useCallback(
    (columnApiName: string, value: string) =>
      setDefaults(x => ({ ...x, [columnApiName]: value })),
    [],
  )

  const missingRequiredColumns = useMemo(
    () => getMissingRequiredColumns(mapping).map(column => column.display),
    [mapping],
  )

  const errors = missingRequiredColumns.length
    ? [
        `The following columns need to be mapped: ${missingRequiredColumns.join(
          ', ',
        )}`,
      ]
    : []

  if (!countries) {
    return (
      <Transition
        subMessage={undefined}
        style={{
          position: 'absolute',
          height: '100%',
        }}
      />
    )
  }

  return (
    <>
      <div
        className={styles.headerMap}
        style={{ display: 'flex', width: '100%' }}
      >
        <div style={{ display: 'flex' }}>
          <UiCard
            title={'Match Our Headers to Yours'}
            className={styles.headers}
            style={{ width: '350px' }}
          >
            <HeaderMapper
              handleChange={associateColumn}
              userHeaders={fields}
              mapping={mapping}
            />
          </UiCard>
        </div>

        <UiCard
          title={'Data Preview'}
          className={styles.preview}
          style={{
            width: '50%',
            flexGrow: 1,
            marginLeft: 15,
          }}
        >
          <Preview
            contacts={rows.slice(0, 10)}
            userFields={fields}
            mapping={mapping}
          />

          {!getMappedField(mapping, 'country') && (
            <AddDefaults
              defaults={['country']}
              handleChange={setDefault}
              countries={countries}
              country={defaults.country}
            />
          )}

          {hasAnyDateColumn && (
            <DateFormat onSelect={setDateFormat} value={dateFormat ?? null} />
          )}
        </UiCard>
      </div>

      <BottomControls
        errors={errors}
        next={{
          onClick: () =>
            processFile(mapping, defaults, dateFormat ?? null, fields, rows),
          disabled: errors.length > 0,
        }}
        back={{
          onClick: onBack,
          disabled: false,
        }}
      />
    </>
  )
}

export default MapHeaders
