import { Operation } from '../../../__generated__/globalTypes'

import getBasicPostageCostsString from '../GetBasicPostageCosts.graphql'

export type GetBasicPostageCostsData = {
  nationalBasicPostageCost: number
  internationalBasicPostageCost: number
}

export type GetBasicPostageCosts = Operation<GetBasicPostageCostsData>

export const GetBasicPostageCosts = (): GetBasicPostageCosts => ({
  query: getBasicPostageCostsString,
  variables: undefined,
})
