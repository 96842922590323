import React, { FC } from 'react'
import { FlexColumn } from 'src/styled'
import { Text } from '@sendoutcards/quantum-design-ui'

interface EmptyCardProps {
  message: string
}

const EmptyCard: FC<EmptyCardProps> = props => {
  return (
    <FlexColumn
      css={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        css={{
          width: 30,
          height: 30,
          borderRadius: 3,
          textAlign: 'center',
          border: 'solid 2px #929090',
          marginBottom: 20,
          fontSize: 35,
          lineHeight: '15px',
          color: '#929090',
        }}
      >
        +
      </div>
      <Text type="subtitle" style={{ color: '#929090' }}>
        {props.message}
      </Text>
    </FlexColumn>
  )
}

export default EmptyCard
