import React from 'react'

type HeartFeltStepTwoType = {
  height?: number
  width?: number
  primaryColor?: string
  secondaryColor?: string
}

const HeartFeltStepTwo: React.FC<HeartFeltStepTwoType> = ({
  height = 276,
  width = 378,
  primaryColor = '#29D2E1',
  secondaryColor = '#56DEE2',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 378 276"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#a)">
      <path
        opacity={0.2}
        d="M137.893 54.995c-1.219-2.128-.827-7.96.479-9.28 2.982-3.034 28.083-1.754 29.869-.591 1.785 1.162 1.502 6.797 1.458 7.782-.043.985-.065 2.542-3.788 3.625-1.088.316-1.589.572-2.699.828-.479.118-1.045.236-1.089.788l-.109 2.206c-.109 2.207-.653 1.084-.718.828-.131-.571-1.241-1.596-2.242-2.6-1.176-1.143-4.05-.828-4.659-.868-.936-.039-6.771.395-7.729.414-1.393-.118-6.008 1.754-8.795-3.133h.022Z"
        fill="#E9F2F2"
      />
      <g opacity={0.2} fill="#404040">
        <path d="M161.949 48.276c-.936 0-1.677.69-1.677 1.537s.762 1.517 1.699 1.517c.936 0 1.676-.69 1.676-1.537s-.762-1.517-1.698-1.517Zm0 2.522c-.61 0-1.111-.433-1.111-.985s.479-1.005 1.089-1.005 1.11.434 1.11.985c0 .552-.479 1.005-1.088 1.005Z" />
        <path d="M161.949 46.602c-2.308 0-4.158 1.714-4.158 3.802 0 1.202.631 2.246 1.589 2.936.522.374 1.132.63 1.807.748.261.04.522.06.805.06.283 0 .545-.02.806-.08a4.407 4.407 0 0 0 1.807-.768c.958-.709 1.567-1.773 1.546-2.955 0-2.088-1.894-3.763-4.202-3.763v.02Zm.805 6.954c-.239.04-.5.08-.74.08-.261 0-.501-.02-.74-.06a3.647 3.647 0 0 1-1.132-.414c.348-.492 1.045-.847 1.872-.847.827 0 1.524.335 1.872.828a3.647 3.647 0 0 1-1.132.413Zm1.59-.729c-.458-.63-1.35-1.044-2.352-1.024-1.001 0-1.894.433-2.351 1.064-.762-.591-1.262-1.478-1.262-2.463 0-1.793 1.589-3.27 3.57-3.27 1.981 0 3.614 1.438 3.614 3.23 0 .986-.479 1.872-1.241 2.463h.022Z" />
      </g>
      <path
        d="M147.69 50.778c-.436 0-.806-.315-.806-.71 0-.413.327-.748.784-.767l8.555-.316c.458-.02.828.296.849.71 0 .413-.326.748-.783.768l-8.556.315h-.043Zm-7.206 3.369c-.436 0-.784-.295-.806-.69-.022-.413.327-.748.762-.768l15.718-.788c.392-.059.827.296.871.69.022.414-.327.748-.762.768l-15.718.788h-.065Z"
        fill="#404040"
        style={{
          mixBlendMode: 'multiply',
        }}
        opacity={0.4}
      />
      <path
        d="M262.392 27.266c3.706 3.597.724 29.439-3.837 32.334-2.5 1.575-67.099-3.598-70.519-4.896-.878-.32-.943.362-1.206.809-1.097 1.958-5.11 8.876-6.052 7.663-.943-1.213-.636-6.194-.812-8.046-.066-.852-.438-1.235-1.469-1.363-1.14-.191-4.846-1.383-6.074-4.98-1.601-4.662-1.228-14.348 1.228-26.31 1.403-6.855 78.61-.512 88.719 4.789h.022Z"
        fill="#E9F2F2"
        fillOpacity={0.74}
      />
      <g
        style={{
          mixBlendMode: 'multiply',
        }}
        opacity={0.4}
        fill="#404040"
      >
        <path d="M190.075 30.629c-2.522-.128-4.693 1.745-4.824 4.215-.132 2.448 1.798 4.555 4.341 4.704 2.544.149 4.693-1.767 4.824-4.215.132-2.448-1.798-4.555-4.341-4.683v-.021Zm-.417 7.365c-1.644-.085-2.916-1.469-2.828-3.065.087-1.597 1.513-2.831 3.157-2.746 1.645.085 2.916 1.469 2.829 3.065-.088 1.618-1.513 2.852-3.158 2.767v-.02Z" />
        <path d="M190.36 25.69c-6.272-.319-11.644 4.364-11.995 10.452-.197 3.491 1.316 6.684 3.816 8.834a11.47 11.47 0 0 0 4.78 2.47c.702.17 1.425.276 2.171.319.745.042 1.469 0 2.193-.086a11.455 11.455 0 0 0 5.021-1.958c2.719-1.894 4.561-4.917 4.758-8.408.329-6.088-4.495-11.303-10.766-11.644l.022.021Zm.899 20.435a9.167 9.167 0 0 1-2.039.107 10.213 10.213 0 0 1-2.018-.32 9.335 9.335 0 0 1-3.004-1.362c1.031-1.383 3.026-2.277 5.241-2.15 2.214.107 4.1 1.214 4.977 2.704a9.858 9.858 0 0 1-3.135 1.043l-.022-.022Zm4.473-1.873c-1.162-1.894-3.486-3.257-6.227-3.384-2.719-.15-5.197.958-6.557 2.724-1.973-1.852-3.157-4.491-3.004-7.365.285-5.236 4.912-9.28 10.306-8.983 5.394.298 9.561 4.769 9.254 10.005-.154 2.874-1.623 5.364-3.794 7.003h.022Z" />
      </g>
      <path
        d="m231.342 36.355-26.51-1.085m48.745 9.408-48.811-1.639"
        stroke="#404040"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M189.045 74.998c2.093 1.3 4.047 2.756 5.675 4.565-3.512.463-6.28 2.14-8.28 4.874-2.233 3.065-2.652 6.638-2.884 10.211-.233 3.33.139 6.682-.21 10.035a20.68 20.68 0 0 1-1.512 6.02c-.651 2.203-2.685 6.108-2.279 9.925.326 3.065 1.744 5.557 4.954 6.484 1.163.33 2.373.551 3.513.948 1.837.64 3.465 1.522 3.558 3.705-5.07 4.102-10.932 6.705-17.445 7.807-9.629 1.632-18.654-.044-27.005-4.918-.186-.11-.325-.242-.488-.375.325-.198.349-.573.442-.882.604-2.051 1.977-3.462 4-4.345 1.443-.639 2.931-1.08 4.42-1.565 1.791-.574 3.373-1.39 4.675-2.735 1.256-1.301 1.605-2.977.884-4.587-.674-1.5-1.977-2.448-3.21-3.485-1.093-.926-2.326-1.72-3.326-2.713-1.442-1.411-2.326-3.065-1.675-5.116.489-1.544 1.489-2.779 2.582-3.926 2.535-2.712 4.396-5.646 4.117-9.461-.139-1.897-.954-3.44-2.582-4.565-1.977-1.346-4.349-1.544-6.629-1.985-4.466-.86-7.327-2.911-7.676-7.058.07-.088.117-.176.186-.242 7.071-6.793 15.678-10.476 25.703-11.05 6.675-.374 13.025.95 19.027 3.75.255.132.511.375.86.177 0 .33.186.33.419.22-.163.11-.07.22 0 .287.023.022.139-.044.209-.067l-.023.067Zm-31.704 8.336h7.676c.233 0 .582 0 .675-.11.209-.243-.093-.485-.233-.684-.93-1.279-2.233-1.94-3.838-1.896-.674 0-1.046-.133-1.419-.684-1.372-2.051-4.024-3.021-6.512-2.492-2.536.551-4.42 2.58-4.745 5.028-.094.706.116.86.814.86 2.535-.022 5.047 0 7.582 0v-.022Z"
        fill="#29D2E1"
      />
      <path
        d="M188.394 74.579c-.348.198-.604-.066-.86-.177-6.001-2.823-12.351-4.124-19.027-3.749-10.025.551-18.631 4.234-25.702 11.05-.07.065-.117.154-.186.242-2.745 2.492-4.769 5.47-6.537 8.623-.255.441-.139.816.07 1.213.954 1.786 2.349 3.132 4.187 4.058 1.558.772 3.14 1.478 4.675 2.272 3.047 1.565 4.164 3.815 3.861 6.704-.186 1.676-.581 3.33-.883 5.006-.442 2.382-.628 4.72 1.209 6.705.721.772 1.512 1.477 2.256 2.227 2.303 2.294 1.815 4.808-1.232 6.153-1.419.64-2.954.75-4.513.816-2 .089-4.001.221-5.699 1.434-.418.309-.86.573-.767 1.191-1.233-.949-2.094-2.206-3.001-3.397-15.863-21.238-2.954-51.276 24.051-55.95 9.723-1.677 18.794 0 27.215 4.94.302.176.697.286.86.66l.023-.021Z"
        fill="#FDFDFD"
      />
      <path
        d="M139.293 128.347c-.093-.596.348-.882.767-1.191 1.698-1.191 3.699-1.345 5.699-1.434 1.558-.066 3.07-.176 4.512-.816 3.024-1.367 3.536-3.881 1.233-6.153-.744-.75-1.558-1.455-2.256-2.227-1.838-1.985-1.652-4.345-1.21-6.705.303-1.676.698-3.33.884-5.006.303-2.889-.814-5.139-3.861-6.704-1.535-.794-3.14-1.5-4.675-2.272-1.861-.926-3.257-2.294-4.187-4.058-.209-.397-.326-.772-.07-1.213 1.768-3.154 3.815-6.131 6.536-8.623.349 4.146 3.233 6.175 7.676 7.057 2.28.441 4.652.64 6.629 1.985 1.628 1.103 2.443 2.647 2.582 4.565.279 3.816-1.582 6.749-4.117 9.461-1.093 1.169-2.093 2.404-2.582 3.926-.651 2.073.233 3.727 1.675 5.117 1.023.992 2.233 1.786 3.326 2.712 1.233 1.037 2.536 1.985 3.21 3.485.721 1.632.372 3.286-.884 4.587-1.302 1.345-2.907 2.139-4.675 2.735-1.489.485-3.001.926-4.42 1.566-2.023.904-3.396 2.293-4 4.344-.093.309-.117.662-.442.883-2.419-1.434-4.676-3.044-6.513-5.161-.186-.221-.651-.132-.605-.551 0-.155 0-.287-.232-.309Z"
        fill="#56DEE2"
      />
      <path
        d="M200.188 86.532c2.442 3.794 3.861 7.918 4.559 12.284 1.256 7.808-.093 15.24-3.955 22.253-.139.243-.279.441-.442.684-.883-.419-1.605-1.059-2.395-1.61-1.536-1.059-3.164-1.478-5.048-1.059-1.279.287-2.582.662-3.908.75-3.279.198-4.954-1.786-3.977-4.631.302-.86.814-1.632 1.442-2.294 2.559-2.779 3.536-6.043 3.419-9.682-.069-2.514-.209-5.05-.186-7.564 0-2.184.303-4.367 2.257-5.889 2.395-1.852 5.117-2.955 8.234-3.22v-.022Z"
        fill="#FEFDFD"
      />
      <path
        d="M200.187 86.532c-3.093.265-5.838 1.367-8.234 3.22-1.954 1.522-2.256 3.683-2.256 5.888 0 2.515.116 5.051.186 7.565.116 3.617-.861 6.903-3.419 9.682-.628.683-1.14 1.433-1.442 2.293-.977 2.845.697 4.83 3.977 4.632 1.326-.088 2.605-.463 3.908-.75 1.884-.419 3.535 0 5.047 1.058.791.552 1.512 1.191 2.396 1.61-2.21 3.926-5.164 7.278-8.792 10.079-.093-2.183-1.722-3.065-3.559-3.705-1.14-.397-2.349-.617-3.512-.948-3.21-.927-4.652-3.419-4.955-6.484-.302-2.845 1.024-6.562 1.652-8.116.628-1.554.332-.919.628-1.831a20.89 20.89 0 0 0 1.512-6.02c.349-3.353 0-6.705.209-10.035.256-3.573.651-7.145 2.884-10.211 2.001-2.757 4.745-4.41 8.281-4.874a28.635 28.635 0 0 1 5.443 6.947h.046Z"
        fill="#56DEE2"
      />
      <path
        d="M157.342 83.334h-7.583c-.698 0-.907-.154-.814-.86.349-2.426 2.233-4.477 4.745-5.028 2.489-.53 5.14.441 6.513 2.492.372.551.744.706 1.418.684 1.605-.044 2.908.617 3.838 1.896.14.199.442.442.233.684-.093.132-.442.11-.675.11h-7.675v.022Z"
        fill="#FDFDFD"
      />
      <path
        d="M167.228 140.124c20.9 0 37.844-16.065 37.844-35.882 0-19.817-16.944-35.882-37.844-35.882-20.901 0-37.845 16.065-37.845 35.882 0 19.817 16.944 35.882 37.845 35.882Z"
        stroke="#56DEE2"
        strokeMiterlimit={10}
      />
      <path
        d="M159.824 104.839c2.293 0 4.152-1.766 4.152-3.945 0-2.178-1.859-3.944-4.152-3.944s-4.152 1.766-4.152 3.944c0 2.179 1.859 3.945 4.152 3.945Zm18.687 0c2.293 0 4.152-1.766 4.152-3.945 0-2.178-1.859-3.944-4.152-3.944s-4.152 1.766-4.152 3.944c0 2.179 1.859 3.945 4.152 3.945Zm-2.253 2.218h-13.927c-2.96 0-4.613 3.179-2.763 5.327 5.205 6.046 13.731 6.046 18.935 0l.321-.367c1.718-2 .189-4.96-2.566-4.96Zm-7.605 7.889s-4.152 0-6.232-1.976c0 0 5.838-5.921 13.303 0 0 0-.839 1.976-7.071 1.976Zm-10.92-61.85c0-1.634 0-11-2.876-12.843-2.876-1.844-42.526-2.326-47.027 2.891-1.954 2.263-2.13 11.985-.066 15.442 4.742 7.94 11.878 4.525 14.073 4.63 1.493-.105 10.648-1.173 12.141-1.173.966 0 5.489-.712 7.421 1.131 1.669 1.593 3.469 3.248 3.732 4.17.11.398 1.076 2.242 1.076-1.425v-3.667c0-.922.9-1.152 1.647-1.382 1.734-.503 2.503-.943 4.193-1.55 5.796-2.033 5.708-4.631 5.686-6.245v.021Z"
        fill="#fff"
      />
      <path
        opacity={0.4}
        d="M107.764 58.586c-2.064-3.457-1.888-13.158.066-15.442 4.501-5.238 44.129-4.735 47.027-2.891 2.898 1.844 2.854 11.21 2.876 12.843.022 1.635.11 4.233-5.686 6.244-1.69.587-2.481 1.048-4.193 1.55-.747.21-1.647.462-1.647 1.383v3.667c0 3.667-.966 1.844-1.076 1.425-.263-.922-2.063-2.577-3.732-4.17-1.932-1.843-6.455-1.131-7.421-1.131-1.493 0-10.648 1.09-12.141 1.173-2.195-.105-9.331 3.31-14.073-4.63v-.021Z"
        fill="#E9F2F2"
      />
      <path
        d="M122.847 50.54c-.417 0-.79-.314-.812-.712-.044-.44.307-.817.746-.86l13.48-1.068c.44-.042.857.294.879.713.044.44-.308.817-.747.859l-13.48 1.068h-.066Z"
        fill="#404040"
        style={{
          mixBlendMode: 'multiply',
        }}
        opacity={0.4}
      />
      <path
        opacity={0.4}
        d="M111.738 56.595a.798.798 0 0 1-.812-.712c-.044-.44.285-.817.746-.859l24.721-2.326c.439-.042.856.273.9.713.044.44-.285.817-.746.859l-24.721 2.325h-.088Z"
        fill="#404040"
      />
      <g opacity={0.4} fill="#404040">
        <path
          d="M147.239 46.643c-1.361.063-2.393 1.153-2.327 2.452.066 1.299 1.207 2.284 2.568 2.22 1.362-.062 2.393-1.173 2.328-2.45-.066-1.279-1.208-2.284-2.569-2.222Zm.197 3.877c-.878.041-1.624-.608-1.668-1.446-.044-.838.637-1.55 1.515-1.593.878-.041 1.624.608 1.668 1.446.044.838-.636 1.572-1.515 1.614v-.021Z"
          style={{
            mixBlendMode: 'multiply',
          }}
        />
        <path
          d="M147.107 44.045c-3.359.168-5.95 2.892-5.774 6.076.088 1.823 1.076 3.416 2.525 4.421.79.545 1.69.901 2.678 1.048.374.063.769.063 1.164.063.395 0 .79-.084 1.164-.168a6.24 6.24 0 0 0 2.568-1.278c1.34-1.131 2.152-2.807 2.064-4.63-.176-3.206-3.03-5.678-6.367-5.51l-.022-.022Zm1.647 10.623a6.053 6.053 0 0 1-1.076.168c-.373 0-.746 0-1.098-.063-.592-.084-1.163-.294-1.668-.545.461-.775 1.471-1.34 2.656-1.404 1.186-.063 2.24.42 2.789 1.153a5.463 5.463 0 0 1-1.603.712v-.02Zm2.261-1.215c-.724-.922-2.042-1.509-3.491-1.446-1.449.063-2.7.775-3.337 1.76-1.163-.859-1.932-2.179-2.02-3.687-.153-2.745 2.086-5.092 4.962-5.238 2.876-.147 5.335 1.99 5.489 4.735.088 1.508-.549 2.891-1.625 3.855l.022.02Z"
          style={{
            mixBlendMode: 'multiply',
          }}
        />
      </g>
      <path
        d="M3.607 83.198c-5.11-8.637-4.647-32.948.154-38.642C14.84 31.462 112.633 32.7 119.748 37.306c7.114 4.606 7.07 28.043 7.114 32.116.044 4.073.242 10.577-14.03 15.653-4.163 1.471-6.101 2.623-10.352 3.86-1.851.533-4.053 1.151-4.053 3.433v9.17c0 9.17-2.379 4.585-2.643 3.583-.639-2.325-5.088-6.462-9.207-10.45-4.735-4.585-15.902-2.815-18.281-2.836-3.656 0-26.299 2.708-29.955 2.943-5.44-.277-22.995 8.274-34.712-11.601l-.022.021Z"
        fill="#E9F2F2"
      />
      <g opacity={0.5} fill="#000">
        <path
          d="M95.916 51.444c-3.612.171-6.41 3.157-6.211 6.654.198 3.497 3.26 6.206 6.872 6.035 3.612-.17 6.409-3.177 6.211-6.675-.198-3.497-3.26-6.206-6.872-6.014Zm.529 10.493c-2.357.106-4.383-1.664-4.494-3.946-.11-2.282 1.718-4.243 4.075-4.35 2.357-.107 4.383 1.663 4.493 3.945.11 2.303-1.696 4.244-4.074 4.372v-.021Z"
          style={{
            mixBlendMode: 'multiply',
          }}
        />
        <path
          d="M95.564 44.428c-8.965.427-15.88 7.848-15.44 16.506.264 4.97 2.885 9.277 6.74 11.985a16.563 16.563 0 0 0 7.18 2.815c1.013.15 2.07.192 3.127.15 1.058-.043 2.093-.214 3.106-.448a16.117 16.117 0 0 0 6.85-3.498c3.568-3.07 5.749-7.613 5.484-12.582-.44-8.68-8.105-15.376-17.047-14.949v.021Zm4.383 28.832a14.25 14.25 0 0 1-2.886.427c-.99.043-1.96 0-2.907-.15a14.485 14.485 0 0 1-4.471-1.492c1.233-2.111 3.942-3.647 7.114-3.796 3.172-.15 6.013 1.13 7.467 3.114a14.375 14.375 0 0 1-4.295 1.919l-.022-.022Zm6.079-3.305c-1.916-2.516-5.441-4.094-9.34-3.924-3.898.192-7.246 2.111-8.897 4.798-3.084-2.345-5.176-5.907-5.375-10.001-.374-7.464 5.573-13.84 13.282-14.203 7.709-.363 14.294 5.395 14.669 12.86.22 4.094-1.498 7.847-4.339 10.47Z"
          style={{
            mixBlendMode: 'multiply',
          }}
        />
      </g>
      <path
        opacity={0.5}
        d="M40.83 62.683c-.859 0-1.564-.64-1.652-1.471a1.619 1.619 0 0 1 1.52-1.728l33.236-2.687c.925-.064 1.718.576 1.785 1.45a1.619 1.619 0 0 1-1.52 1.728l-33.237 2.687h-.132v.021Zm-27.4 15.12c-.837 0-1.563-.619-1.652-1.45-.088-.875.573-1.664 1.476-1.75l60.989-5.8c.903-.106 1.718.555 1.806 1.43.088.873-.573 1.662-1.476 1.748l-60.988 5.8h-.155v.022Z"
        fill="#000"
      />
      <path
        d="M374.035 19.825c7.125 6.92 1.39 56.703-7.367 62.294-4.787 3.063-129.221-6.92-135.839-9.404-1.676-.621-1.831.707-2.316 1.564-2.095 3.77-9.816 17.094-11.669 14.738-1.853-2.357-1.235-11.91-1.544-15.488-.155-1.65-.86-2.356-2.824-2.614-2.206-.364-9.331-2.677-11.713-9.575-3.088-8.997-2.382-27.655 2.382-50.705 2.714-13.196 151.434-.985 170.912 9.211l-.022-.021Z"
        fill="#E9F2F2"
      />
      <g
        style={{
          mixBlendMode: 'multiply',
        }}
        opacity={0.55}
        fill="#000"
      >
        <path d="M234.778 26.294c-4.875-.257-9.044 3.385-9.309 8.12-.265 4.733 3.485 8.803 8.36 9.06 4.875.258 9.044-3.406 9.309-8.14.265-4.734-3.485-8.783-8.36-9.04Zm-.794 14.203c-3.177-.172-5.625-2.806-5.449-5.913.177-3.084 2.89-5.462 6.088-5.29 3.177.17 5.625 2.806 5.449 5.912-.176 3.106-2.89 5.484-6.088 5.312v-.021Z" />
        <path d="M235.307 16.783c-12.088-.621-22.434 8.397-23.095 20.136-.375 6.727 2.536 12.875 7.323 17.03 2.581 2.25 5.735 3.9 9.221 4.756 1.345.322 2.735.557 4.169.621 1.434.086 2.845 0 4.213-.192a22.394 22.394 0 0 0 9.684-3.77c5.25-3.642 8.801-9.469 9.176-16.174.64-11.739-8.647-21.786-20.735-22.428l.044.021ZM223.44 53.157c1.963-2.678 5.801-4.391 10.08-4.156 4.28.215 7.897 2.314 9.574 5.184a18.692 18.692 0 0 1-6.044 1.992c-1.28.193-2.603.279-3.927.193a20.928 20.928 0 0 1-3.882-.6 19.245 19.245 0 0 1-5.779-2.613h-.022Zm29.558-14.095c-.308 5.526-3.132 10.346-7.323 13.495-2.228-3.641-6.728-6.255-11.978-6.533-5.25-.279-10.015 1.863-12.64 5.248-3.816-3.578-6.088-8.654-5.779-14.181.551-10.09 9.463-17.866 19.875-17.33 10.389.535 18.397 9.19 17.845 19.3Z" />
      </g>
      <path
        d="M314.234 39.49h-.088l-51.067-2.12c-1.213-.044-2.161-1.05-2.117-2.229.044-1.178 1.103-2.099 2.294-2.056l51.066 2.12c1.213.044 2.162 1.05 2.118 2.229-.044 1.156-1.037 2.056-2.206 2.056Zm42.838 16.002h-.088l-94.015-3.127c-1.213-.043-2.162-1.029-2.118-2.207.044-1.178 1.081-2.142 2.272-2.056l94.015 3.127c1.214.043 2.162 1.029 2.118 2.207-.044 1.157-1.015 2.078-2.206 2.078l.022-.022Z"
        fill="#000"
        style={{
          mixBlendMode: 'multiply',
        }}
        opacity={0.55}
      />
      <path
        d="M225.799 213.893s-7.189-2.349-9.571 2.348c-2.381 4.697 19.163 2.348 19.163 2.348h21.544s2.404-7.023-7.189-4.675c0 0 0-9.349-9.57 0 0 0 4.785-9.349-2.403-9.349-7.189 0-11.974 9.349-11.974 9.349v-.021Zm-89.016-17.859s6.584-2.348 8.779 2.348c2.195 4.697-17.559 2.348-17.559 2.348h-19.755s-2.195-7.023 6.585-4.674c0 0 0-9.35 8.78 0 0 0-4.39-9.35 2.195-9.35 6.585 0 10.975 9.35 10.975 9.35v-.022Z"
        fill="#91D5DD"
      />
      <path
        d="M132.462 209.272s-26.089 2.288-16.592 29.643c0 0-18.972-15.955-26.09 9.112-7.117 25.067 47.419 22.799 47.419 22.799l89.615.064s21.814 2.225 21.814-6.887c0-9.111-21.329-6.844-21.329-6.844s15.138 0 15.138-14.811-22.255-17.1-34.11-5.7c0 0 2.38-31.911-18.972-22.799-21.329 9.111-56.915-4.556-56.915-4.556l.022-.021Z"
        fill="#91D5DD"
      />
      <path
        d="M139.579 163.673s-15.909-10.362-26.927-12.015c0 0-8.637.615-6.279 7.459 2.357 6.844 21.329 18.244 23.709 36.488 2.38 18.243 0 45.599 0 45.599s26.089-13.688 37.944 11.4c0 0 2.379-9.112 11.854-9.112s9.894 9.112 9.894 9.112 1.961-6.845 6.698-6.845v-59.287c0-2.288 35.564-31.911 28.447-38.755 0 0 4.737-6.844-4.737-11.4-9.475-4.555-16.593 2.289-16.593 2.289s-2.379-4.556-14.234 0c-11.855 4.555-45.061 18.244-49.798 25.088"
        fill="#FF9BA5"
      />
      <path
        opacity={0.5}
        d="M226.417 148.416s-7.491-4.703-14.829 0c-6.192 3.984-11.568 7.904-12.802 8.836-2.843-1.419-5.421-2.5-7.778-3.305 1.322-.911 4.847-3.263 14.08-9.196 12.934-8.306 15.821-6.95 15.931-6.887.22.17.551.17.749-.021.22-.212.22-.551 0-.742-.441-.423-3.393-2.14-17.297 6.781-11.017 7.077-13.948 9.09-14.741 9.662-3.438-1.102-6.302-1.631-8.572-1.864.066 0 .132 0 .199-.043l23.709-13.688a.512.512 0 0 0 .198-.72.555.555 0 0 0-.749-.191l-23.709 13.688a.512.512 0 0 0-.199.721.538.538 0 0 0 .243.19c-3.636-.339-5.641.106-5.817.149a.531.531 0 0 0-.397.635.54.54 0 0 0 .661.382c.088 0 9.519-2.098 25.67 6.78a.6.6 0 0 0 .265.064.535.535 0 0 0 .485-.276.511.511 0 0 0-.199-.72c-.573-.318-1.102-.572-1.652-.869 1.74-1.271 6.698-4.873 12.361-8.497 6.721-4.301 13.331-.169 13.617 0 .265.17.595.106.772-.148a.51.51 0 0 0-.155-.742l-.044.021Zm-71.546 15.871a.52.52 0 0 0-.419.636.54.54 0 0 0 .639.402c.11-.021 11.546-2.14 16.019 9.874a.571.571 0 0 0 .529.361c.066 0 .132 0 .176-.022.287-.105.441-.402.331-.678-4.782-12.883-17.165-10.573-17.275-10.552v-.021Zm6.522 27.356h-4.032a.535.535 0 0 0-.551.529c0 .297.242.53.551.53h4.032a.535.535 0 0 0 .551-.53.535.535 0 0 0-.551-.529Z"
        fill="#000"
      />
      <path
        d="M122.987 245.76s8.307-10.256 23.709-9.112c9.453.699 23.709 9.112 21.33 20.511 0 0 1.19-12.544 10.664-12.544 9.475 0 10.665 7.989 10.665 7.989s2.38-9.112 14.235-6.844"
        stroke="#4C5D5E"
        strokeWidth={0.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m239.459 162.251-5.852-2.814s-2.509-1.345-3.679 1.386c-1.171 2.732-3.407 7.305-3.407 7.305s-1.087 2.111 1.986 3.476a8760.407 8760.407 0 0 1 11.579 5.173s1.986 1.014 3.282-1.676 3.386-7.097 3.386-7.097.962-1.676-1.839-3.083a320.933 320.933 0 0 0-5.456-2.67Z"
        fill="#EDEDED"
      />
      <path
        opacity={0.5}
        d="M245.918 167.052s-.167-.124-.251-.083c-.062.041-6.688 3.435-9.531 2.173-2.947-1.325-4.703-8.67-4.724-8.732a.192.192 0 0 0-.23-.145.19.19 0 0 0-.146.227s.46 1.904 1.275 3.953c.251.62.522 1.179.773 1.696-.94.166-4.076 2.111-4.222 2.194-.084.062-.125.165-.063.269l.042.041c.063.062.146.062.23 0 .899-.559 3.386-2.028 4.097-2.152.042 0 .062-.041.083-.062.586 1.076 1.192 1.924 1.84 2.462.292.249.585.435.878.58.982.434 2.424.414 4.285-.041 1.003 1.51 1.254 4.924 1.254 4.945 0 .062.021.103.062.124.042.042.084.042.147.042a.176.176 0 0 0 .167-.207c0-.145-.251-3.394-1.254-5.029.46-.124.94-.248 1.442-.434 2.048-.704 3.742-1.594 3.762-1.594.084-.041.126-.165.084-.248v.021Z"
        fill="#000"
      />
      <path
        d="m276.587 96.532-17.078-7.914s-7.297-3.82-10.713 3.882c-3.416 7.703-9.914 20.582-9.914 20.582s-3.172 5.941 5.811 9.802c9.005 3.862 33.757 14.598 33.757 14.598s5.766 2.822 9.537-4.71c3.77-7.532 9.914-20.03 9.914-20.03s2.795-4.71-5.39-8.657a1520.608 1520.608 0 0 0-15.924-7.553Z"
        fill="#EDEDED"
      />
      <path
        opacity={0.5}
        d="M295.439 110.069c-.133-.255-.488-.361-.754-.234-.2.106-19.495 9.697-27.791 6.09-8.605-3.756-13.751-24.443-13.795-24.634a.574.574 0 0 0-.665-.403c-.289.063-.488.36-.422.636 0 .064 1.331 5.39 3.726 11.118a56.348 56.348 0 0 0 2.263 4.817c-2.773.509-11.888 5.941-12.288 6.174-.266.149-.332.488-.177.743a.548.548 0 0 0 .111.127c.177.149.443.17.665.043 2.617-1.57 9.848-5.708 11.933-6.047.088 0 .155-.085.244-.149 1.707 3.055 3.504 5.389 5.367 6.938.843.701 1.708 1.252 2.573 1.613 2.861 1.231 7.053 1.188 12.487-.106 2.905 4.286 3.615 13.855 3.637 13.961 0 .149.089.276.2.361a.637.637 0 0 0 .399.127c.31 0 .532-.276.51-.573-.022-.403-.71-9.548-3.615-14.173a64.656 64.656 0 0 0 4.192-1.231c5.966-1.994 10.934-4.477 10.978-4.498a.539.539 0 0 0 .244-.721l-.022.021Z"
        fill="#000"
      />
      <path
        d="m73.363 126.678-9.18 4.459s-4.119 1.77-1.745 5.696c2.373 3.925 6.067 10.815 6.067 10.815s1.657 3.264 6.358.79c4.702-2.475 17.664-9.173 17.664-9.173s3.18-1.451.986-5.483c-2.195-4.032-5.822-10.624-5.822-10.624s-1.208-2.688-5.686-.597a664.353 664.353 0 0 0-8.642 4.117Z"
        fill="#EDEDED"
      />
      <path
        opacity={0.5}
        d="M91.162 134.913c-.202-.128-4.567-2.816-7.612-3.115.29-.661.56-1.365.828-2.155 1.052-3.114 1.545-5.973 1.567-5.994a.288.288 0 0 0-.246-.32c-.157-.021-.313.085-.336.235 0 .106-1.992 11.221-6.336 13.461-4.522 2.325-15.582-2.304-15.694-2.347a.31.31 0 0 0-.403.149c-.067.15 0 .32.157.384.022 0 2.866 1.216 6.246 2.091a30.98 30.98 0 0 0 2.933.619c-.672 1.301-1.209 6.848-1.231 7.104 0 .149.112.298.268.32h.09a.302.302 0 0 0 .246-.256c.157-1.6.65-5.931 1.164-6.891.023-.064 0-.107 0-.171 1.926.299 3.605.342 4.903.064a6.515 6.515 0 0 0 1.59-.533c1.5-.768 2.843-2.517 4.052-5.184 2.888.192 7.456 3.029 7.523 3.051.067.042.156.064.224.042.067 0 .134-.064.179-.128.09-.128.044-.32-.09-.405l-.022-.021Z"
        fill="#000"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.358 0h377.284v276H.358z" />
      </clipPath>
    </defs>
  </svg>
)
export default HeartFeltStepTwo
