import { Maybe, Operation } from '../../../__generated__/globalTypes'

import {
  ImageFragment,
  imageFragmentString,
} from '../../fragments/__generated__/ImageFragment'

import getOrCreateImageForStickerUrlRawString from '../GetOrCreateImageForStickerUrl.graphql'

const getOrCreateImageForStickerUrlString = [
  getOrCreateImageForStickerUrlRawString,
  imageFragmentString,
].join('\n\n')

export type GetOrCreateImageForStickerUrlData = {
  getOrCreateImageForStickerUrl: {
    image: ImageFragment & {
      path: string
      createdAt: string
      stickerUrl: Maybe<string>
      isSticker: boolean
    }
  }
}

export type GetOrCreateImageForStickerUrlVariables = {
  url: string
}

export type GetOrCreateImageForStickerUrl = Operation<
  GetOrCreateImageForStickerUrlData,
  GetOrCreateImageForStickerUrlVariables
>

export const GetOrCreateImageForStickerUrl = ({
  url,
}: GetOrCreateImageForStickerUrlVariables): GetOrCreateImageForStickerUrl => ({
  query: getOrCreateImageForStickerUrlString,
  variables: {
    url,
  },
})
