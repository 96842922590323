import React, { CSSProperties } from 'react'
import {
  AnimatedCheckMark,
  Flex,
  Overlay,
  Text,
} from '@sendoutcards/quantum-design-ui'

type primaryAction = {
  title: string
  onClick?: () => void
}

type RequestSuccessProps = {
  isOpen: boolean
  title?: string
  description?: string
  onClose?: () => void
  primaryAction?: primaryAction
}

export const RequestSuccess = (props: RequestSuccessProps) => {
  const { title, description, onClose, primaryAction, isOpen } = props
  const fontFamily = 'Montserrat, san serif'
  const closeBtnStyles: CSSProperties = {
    position: 'absolute',
    cursor: 'pointer',
    display: 'flex',
    borderRadius: '50px',
    padding: '1px 12px',
    color: '#fff',
    backgroundColor: '#0000008a',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px',
    fontFamily: fontFamily,
    top: '-27px',
    right: '-25px',
  }

  const buttonStyles = {
    borderRadius: '10px',
    backgroundColor: '#44d5a4',
    color: '#fff',
    fontWeight: 700,
    padding: '4px 8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    fontFamily: fontFamily,
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 2px 0px',
    width: '100%',
  }

  return (
    <>
      {isOpen && (
        <Overlay backgroundVariant="blur" zIndex={1000}>
          <Flex
            inset="x2"
            backgroundColor="#fff"
            width="100%"
            maxWidth="600px"
            style={{
              borderRadius: '8px',
              boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
              margin: '0 auto ',
            }}
          >
            <Flex
              width="100%"
              position="relative"
              flexDirection="column"
              rowGap="x1"
              justifyContent="center"
              alignItems="center"
            >
              {onClose && (
                <div style={{ ...closeBtnStyles }} onClick={onClose}>
                  x
                </div>
              )}
              <AnimatedCheckMark filled={true} size="large" />
              <Text
                type="body"
                content={title}
                style={{
                  fontSize: '20px',
                  color: '#151515',
                  fontWeight: 700,
                  fontFamily: fontFamily,
                }}
              />
              <Text
                type="body"
                content={description}
                style={{
                  fontSize: '15px',
                  color: '#616161',
                  fontWeight: 500,
                  fontFamily: fontFamily,
                }}
              />
              <div style={{ ...buttonStyles }} onClick={primaryAction?.onClick}>
                {primaryAction?.title}
              </div>
            </Flex>
          </Flex>
        </Overlay>
      )}
    </>
  )
}
