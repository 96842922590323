import React from 'react'

// @src imports
import useActions from 'src/hooks/useActions'
import { Confirm } from 'src/chrome'

import {
  useAccount,
  useEffect,
  useMutations,
  useSelector,
  useState,
} from 'src/hooks'

import {
  LayoutCategory,
  LayoutDimensions,
  Owner,
} from 'src/graphql/generated/graphql'

import { EditorElement } from 'src/editor/EditorCard'

// relative imports
import layoutStyles from './layouts.module.scss'
import styles from '../../styles/components/Drawer.module.scss'
import { Api } from '../../api'
import { Steps } from '../../types'
import { Memoized } from 'src/hooks/dependencies'
import { Button, Flex, Text } from '@sendoutcards/quantum-design-ui'
import { getCardDimensions } from 'src/helpers'
import { useLayout, useLayouts, useUpdateAccount } from 'src/react_query'

type Props = {
  api: Api & { step: { type: Steps.EditLayout } }
  onChangeTransitionType: Memoized<
    (shouldUseRegularTransition: boolean) => void
  >
}

const Layouts: React.FC<Props> = props => {
  const { onChangeTransitionType } = props
  const {
    card,
    fullBleeds,
    step,
    updatePanel,
    selectedLayoutId,
    setSelectedLayoutId,
    isCreatingBackPanel,
    setIsCreatingBackPanel,
    setSelectedBackLayoutId,
    setEditorDuvetOpenValue,
    currentLayout,
    setCurrentLayout,
  } = props.api
  const { fullBleedIndex, panelIndex } = step

  const actions = useActions()

  const mutations = useMutations()
  const updateAccountMutation = useUpdateAccount()

  const account = useAccount()

  const defaultBackPanel = account.settings?.defaultBackPanel ?? null

  const [defaultBackPanelId, setDefaultBackPanelId] = useState<
    string | undefined
  >(defaultBackPanel?.id)
  const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] = useState(false)
  const [currentDeleteLayoutId, setCurrentDeleteLayoutId] = useState<
    string | null
  >(null)

  const isBackPanel =
    fullBleeds.length > 2 && fullBleedIndex === fullBleeds.length - 1

  // TODO: React Query Refactor returns possibly undefined results
  // Not sure if this is the behavior we want?
  if (!card) throw Error('No card provided')

  const category = isBackPanel
    ? LayoutCategory.BackPanels
    : LayoutCategory.BasicLayouts
  const dimensions = isBackPanel
    ? LayoutDimensions.Vertical
    : getCardDimensions(card)

  const { data: layouts } = useLayouts({
    category,
    dimensions,
    owner: Owner.System,
    offset: 0,
    limit: 100,
  })

  const { data: userBackPanels = [] } = useLayouts({
    category: LayoutCategory.BackPanels,
    dimensions: LayoutDimensions.Vertical,
    owner: Owner.User,
    offset: 0,
    limit: 100,
  })

  const { data: layout } = useLayout(
    selectedLayoutId ? { id: selectedLayoutId } : undefined,
  )
  const currentLayouts =
    !isCreatingBackPanel && isBackPanel ? userBackPanels : layouts

  const toggleBackPanels = () => setIsCreatingBackPanel(!isCreatingBackPanel)

  const handleSelectLayout = (id: string) => {
    onChangeTransitionType(true)
    setSelectedLayoutId(id)
    isBackPanel && setSelectedBackLayoutId(id)
  }

  useEffect(() => {
    setCurrentLayout({
      fullBleedIndex: fullBleedIndex,
      panelIndex: panelIndex,
      layoutId: undefined,
    })
  }, [fullBleedIndex, panelIndex, setCurrentLayout])

  useEffect(() => {
    if (
      selectedLayoutId !== undefined &&
      selectedLayoutId !== currentLayout?.layoutId &&
      layout
    ) {
      onChangeTransitionType(false)
      setSelectedLayoutId(undefined)
      setCurrentLayout({
        fullBleedIndex: fullBleedIndex,
        panelIndex: panelIndex,
        layoutId: selectedLayoutId,
      })

      if (!isBackPanel) {
        updatePanel(fullBleedIndex, panelIndex, panel => {
          const elementImages = panel.elements
            .filter(element => element.image)
            .map(element => element.image)
          const elementTexts = panel.elements
            .filter(element => element.text)
            .map(element => element.text)
          return {
            ...panel,
            elements: layout.panel.elements
              .map(EditorElement)
              .map((element: EditorElement) => {
                if (element.image) {
                  return element.image.image
                    ? element
                    : {
                        ...element,
                        image:
                          elementImages.length > 0
                            ? // tslint:disable-next-line:no-array-mutation
                              elementImages.shift() || null
                            : element.image,
                      }
                } else {
                  return element.text && element.text.length > 1
                    ? element
                    : {
                        ...element,
                        text:
                          elementTexts.length > 0
                            ? // tslint:disable-next-line:no-array-mutation
                              elementTexts.shift() || null
                            : element.text,
                      }
                }
              }),
          }
        })
      } else {
        updatePanel(fullBleedIndex, panelIndex, panel => ({
          ...panel,
          backgroundColor: layout.panel.backgroundColor ?? null,
          elements: layout.panel.elements.map(EditorElement),
        }))
      }
    }
  }, [
    selectedLayoutId,
    setSelectedLayoutId,
    currentLayout,
    setCurrentLayout,
    layout,
    actions,
    isBackPanel,
    onChangeTransitionType,
    updatePanel,
    fullBleedIndex,
    panelIndex,
  ])

  const closeConfirm = () => {
    setIsDeleteConfirmVisible(false)
    setCurrentDeleteLayoutId(null)
  }

  const deleteLayout = () => {
    if (!currentDeleteLayoutId) {
      return
    }
    mutations.deleteLayout({ id: currentDeleteLayoutId })
    closeConfirm()
  }

  const showConfirmDelete = (id: string) => {
    setIsDeleteConfirmVisible(true)
    setCurrentDeleteLayoutId(id)
  }

  const setBackPanelDefaultLayout = async (id: string) => {
    setDefaultBackPanelId(id)
    const result = await updateAccountMutation.mutateAsync({
      account: { settings: { defaultBackPanelId: id } },
    })
    actions.updatedAccount(result.updateAccount.account)
  }

  const { isMobile } = useSelector(state => state.window)

  // We omit the horizontal card check when user is on back panel layouts as we don't support horizontal backs (yet).
  const isHorizontalCard =
    card.isHorizontal && category !== LayoutCategory.BackPanels

  return (
    <div
      className={
        dimensions === LayoutDimensions.Horizontal
          ? `${styles.images} ${styles.horizontal}`
          : styles.images
      }
    >
      {isBackPanel && (
        <Flex
          outset={{ bottom: 'x2' }}
          justifyContent="center"
          flexDirection="column"
          width="100%"
        >
          {isCreatingBackPanel ? (
            <>
              <Text type="body" alignment="center">
                {userBackPanels.length > 0
                  ? 'Create new custom back panel or'
                  : 'Create a custom back panel'}
              </Text>
              {userBackPanels.length > 0 && (
                <div onClick={toggleBackPanels}>
                  <Text type="body" color="primaryBrand" alignment="center">
                    select existing
                  </Text>
                </div>
              )}
            </>
          ) : (
            <>
              <Text type="body" alignment="center">
                Select an existing panel or
              </Text>
              <div onClick={toggleBackPanels}>
                <Text type="body" color="primaryBrand" alignment="center">
                  create a new one
                </Text>
              </div>
            </>
          )}
        </Flex>
      )}
      <Flex
        justifyContent="center"
        flexDirection="row"
        flexWrap="wrap"
        id="layouts_container"
      >
        {currentLayouts?.map((layout, i) => {
          return (
            <div
              id={`layout_${i}`}
              className={`${layoutStyles.closeXHoverParent} ${styles.layoutContainer}`}
              key={layout.id}
              title={`Layout #${layout.id}`}
              style={{
                backgroundImage: `url(${layout.thumbnail.url})`,
                backgroundSize: card.isHorizontal ? 'contain' : 'cover',
                minWidth: 120,
                width: isHorizontalCard ? 150 : 120,
                height:
                  dimensions === LayoutDimensions.Postcard
                    ? 140
                    : isHorizontalCard
                    ? 120
                    : 168,
                marginBottom: isMobile ? 15 : 5,
                backgroundPosition: card.isHorizontal ? 'center' : '',
                backgroundRepeat: card.isHorizontal ? 'no-repeat' : '',
              }}
              onClick={() => {
                handleSelectLayout(layout.id)
                if (isMobile) {
                  setEditorDuvetOpenValue(0)
                }
              }}
            >
              {category === LayoutCategory.BackPanels && !isCreatingBackPanel && (
                <div>
                  {defaultBackPanelId !== layout.id && [
                    <div
                      key={1}
                      className={layoutStyles.closeXButton}
                      onClick={() => showConfirmDelete(layout.id)}
                    >
                      x
                    </div>,
                    <div
                      key={2}
                      className={styles.defaultButton}
                      onClick={() => setBackPanelDefaultLayout(layout.id)}
                    >
                      set as default
                    </div>,
                  ]}
                  {defaultBackPanelId === layout.id && (
                    <div
                      className={styles.defaultLayout}
                      onClick={() => {
                        handleSelectLayout(layout.id)
                      }}
                    >
                      <div className={styles.defaultLabel}>Default</div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )
        })}
      </Flex>
      {category === LayoutCategory.BackPanels && (
        <div>
          <div className={`${styles.drawerBarBottom} ${styles.layout}`}>
            <div className={styles.choices}>
              {(!isCreatingBackPanel ||
                (isCreatingBackPanel && !!userBackPanels.length)) && (
                <Button
                  id="create_or_choose_btn"
                  onClick={toggleBackPanels}
                  size="medium"
                  title={
                    !isCreatingBackPanel ? 'Create New' : 'Choose Existing'
                  }
                />
              )}
            </div>
          </div>
          {isDeleteConfirmVisible && (
            <Confirm
              title={'Delete Layout'}
              message={
                'Are you sure you want to delete this custom back panel layout?'
              }
              onConfirm={deleteLayout}
              onDecline={closeConfirm}
              confirmTitle={'Delete'}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default Layouts
