import orderBy from 'lodash/orderBy'

const sortCosts = (
  costs: Array<{
    label: string
    amount: string | number
  }>,
) => {
  const priority = {
    'Group Sends': 0,
    'Loyalty Rewards': 1,
    'Scheduled Sends': 2,
    'Card Upgrades': 3,
    Processing: 4,
    'Gift Handling Fees': 5,
    '*Volume Discount': 6,
    Shipping: 7,
  }
  return orderBy(
    costs,
    [e => (priority as Record<string, number | undefined>)[e.label]],
    ['asc'],
  )
}

export default sortCosts
