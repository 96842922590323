import { Operation } from '../../../__generated__/globalTypes'

import declineMembershipInviteString from '../DeclineMembershipInvite.graphql'

export type DeclineMembershipInviteData = {
  declineMembershipInvite: {
    success: boolean
  }
}

export type DeclineMembershipInviteVariables = {
  organizationName: string
}

export type DeclineMembershipInvite = Operation<
  DeclineMembershipInviteData,
  DeclineMembershipInviteVariables
>

export const DeclineMembershipInvite = ({
  organizationName,
}: DeclineMembershipInviteVariables): DeclineMembershipInvite => ({
  query: declineMembershipInviteString,
  variables: {
    organizationName,
  },
})
