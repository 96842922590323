import { Maybe } from '../../../__generated__/globalTypes'

import {
  SendableCardFragment,
  isSendableCardFragment,
} from './SendableCardFragment'

import { PanelFragment, isPanelFragment } from './PanelFragment'

import sendableCardWithTemplateFragmentRawString from '../SendableCardWithTemplateFragment.graphql'

export const sendableCardWithTemplateFragmentString = sendableCardWithTemplateFragmentRawString

export type SendableCardWithTemplateFragment = SendableCardFragment & {
  cardTemplate: Maybe<{
    __typename: 'Card'
    id: string
    backPanelLocation: number
    panels: PanelFragment[]
  }>
}

export const isSendableCardWithTemplateFragment = (
  fragment: any,
): fragment is SendableCardWithTemplateFragment =>
  (isSendableCardFragment(fragment) as boolean) &&
  (fragment.cardTemplate === null ||
    (fragment.cardTemplate &&
      fragment.cardTemplate.__typename === 'Card' &&
      typeof fragment.cardTemplate.id === 'string' &&
      typeof fragment.cardTemplate.backPanelLocation === 'number' &&
      Array.isArray(fragment.cardTemplate.panels) &&
      fragment.cardTemplate.panels
        .slice(0, 5)
        .reduce(
          (accum: any, next: any) =>
            accum && (isPanelFragment(next) as boolean),
          true,
        )))
