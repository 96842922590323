import React, { FC } from 'react'
import { SVGProps } from './types'

export const User: FC<SVGProps> = ({ size, onClick, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick && (() => onClick())}
    >
      <path
        d="M12.9166 19.9998H5.08146C3.35772 19.9998 1.92127 19.8422 0.706811 19.5028C0.262817 19.3816 -0.0244735 18.9816 0.00164385 18.5573C0.32811 14.933 4.28488 12.0845 8.99902 12.0845C13.7132 12.0845 17.6569 14.9209 17.9964 18.5573C18.0356 18.9937 17.7483 19.3816 17.2913 19.5028C16.0768 19.8422 14.6404 19.9998 12.9166 19.9998ZM2.10409 17.9512C2.96596 18.1088 3.94536 18.1815 5.08146 18.1815H12.9166C14.0527 18.1815 15.0321 18.1088 15.894 17.9512C15.2019 15.6239 12.342 13.9027 8.99902 13.9027C5.65604 13.9027 2.7962 15.6239 2.10409 17.9512Z"
        fill={color}
      />
      <path
        d="M8.99812 10.5093C5.87713 10.5093 3.34375 8.14559 3.34375 5.24857C3.34375 2.35158 5.87713 0 8.99812 0C12.1191 0 14.6525 2.35158 14.6525 5.24857C14.6525 8.14559 12.1191 10.5093 8.99812 10.5093ZM8.99812 1.81821C6.96097 1.81821 5.30255 3.35766 5.30255 5.24857C5.30255 7.15163 6.96097 8.69105 8.99812 8.69105C11.0353 8.69105 12.6937 7.15163 12.6937 5.24857C12.6937 3.35766 11.0353 1.81821 8.99812 1.81821Z"
        fill={color}
      />
    </svg>
  )
}
