import React from 'react'
// @src imports
import { CatalogRibbon } from 'src/catalog/components'
import { getCollection, getSendableCards } from 'src/legacy_graphql'
// relative imports
import styles from './collection.module.scss'
import CollectionHeader from '../Headers/CollectionHeader'
import { useActions, useMutations, useQueries } from 'src/hooks'
import suspenseBoundary from 'src/chrome/SuspenseBoundary/suspenseBoundaryHOC'
import { DefaultError, Transition } from 'src/chrome'

interface CollectionProps {
  collectionId: string
}

const Collection: React.FC<CollectionProps> = props => {
  const { collectionId } = props
  const mutations = useMutations()
  const actions = useActions()

  const [
    collection,
    {
      results: collectionCards,
      hasMore: hasMoreCollectionCards,
      loadMore: loadMoreCollectionCards,
      isLoadingMore: isLoadingMoreCollectionCards,
    },
  ] = useQueries(
    getCollection({ id: collectionId }),
    getSendableCards({
      search: '',
      category: undefined,
      collection: collectionId,
      isPremium: undefined,
      favorite: undefined,
    }),
  )

  const handleFavoriteCard = async (id: string, isFavorite: boolean) => {
    try {
      if (isFavorite) {
        await mutations.unfavoriteCard({ id })
      } else {
        await mutations.favoriteCard({ id })
      }
    } catch (error) {
      console.error('Failed to favorite/unfavorite card.')
    }
  }

  const handleOnClickCard = (id: string, typename?: string | undefined) => {
    if (typename !== 'SendableCard') {
      return
    }
    actions.selectedCatalogCard(typename, id)
  }

  const handleClose = () => actions.dismissedCatalogSubroute()

  return (
    <div className={styles.collectionContainer}>
      <CollectionHeader
        title={collection.title}
        textColor={collection.textColor}
        backgroundStartColor={collection.backgroundStartColor}
        backgroundEndColor={collection.backgroundEndColor}
        card={collection.cards[0]}
        handleFavoriteCard={handleFavoriteCard}
        onClose={handleClose}
      />
      <div className={styles.cardGrid}>
        <CatalogRibbon
          type={`collection_${collection.id}_card`}
          items={collectionCards}
          isWrapContentEnabled={true}
          onClick={handleOnClickCard}
          onClose={handleClose}
          canFavorite={true}
          handleFavoriteCard={handleFavoriteCard}
          isLoadingMore={isLoadingMoreCollectionCards}
          hasMore={hasMoreCollectionCards}
          loadMore={
            hasMoreCollectionCards ? loadMoreCollectionCards : undefined
          }
        />
      </div>
    </div>
  )
}

export default suspenseBoundary({
  component: Collection,
  unresolved: <Transition />,
  failure: DefaultError,
})
