import React from 'react'

type RankSVGType = {
  height?: number
  width?: number
}

export function RankSVG({ height = 22, width = 25 }: RankSVGType) {
  const increasedWidth = `${width * 1.2}px`
  const increasedHeight = `${height * 1.2}px`

  return (
    <svg
      width={increasedWidth}
      height={increasedHeight}
      viewBox="0 0 22 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3889 1.44662C11.1482 1.30779 10.8519 1.30779 10.6111 1.44662L1.62197 6.63673C1.38124 6.77557 1.23308 7.03223 1.23308 7.31029V17.6897C1.23308 17.9678 1.38124 18.2245 1.62197 18.3633L10.6111 23.5534C10.8519 23.6922 11.1482 23.6922 11.3889 23.5534L20.3781 18.3633C20.6188 18.2245 20.767 17.9678 20.767 17.6897V7.31029C20.767 7.03223 20.6188 6.77557 20.3781 6.63673L11.3889 1.44662Z"
        fill="#E8E8E9"
        stroke="#E8E8E9"
        strokeWidth={2}
      />
    </svg>
  )
}
