import { Div } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { CallToActionCard } from 'src/dashboard/components'
import { CallToActionCardProps } from 'src/dashboard/components/CallToActionCards/CallToActionCard'

type PromoItemProps = {
  title: string
  item: CallToActionCardProps
  onClick: () => void
}

const PromoItem: React.FC<PromoItemProps> = props => {
  const { item, onClick } = props

  return (
    <>
      <Div onClick={onClick}>
        <CallToActionCard
          theme={item.theme}
          tag={item.tag}
          title={item.title}
          caption={item.caption}
          graphic={item.graphic}
          graphicPadding={item.graphicPadding}
          graphicBackground={item.graphicBackground}
          actions={item.actions}
          isStacked={item.isStacked}
        />
      </Div>
    </>
  )
}

export default PromoItem
