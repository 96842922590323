import React from 'react'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'

import { CloseButton } from 'src/chrome'
import {
  useDebounce,
  useEffect,
  useQueryFutures,
  useRef,
  useState,
} from 'src/hooks'

import { SearchFilter } from '../types'
import FilterChip from '../lib/FilterChip'

import styles from '../filteredSearchBar.module.scss'
import { Query } from 'src/legacy_graphql/Query'
import {
  PaginatedState,
  PaginatedView,
} from 'src/legacy_graphql/PaginatedQuery'
import { NormalizedData } from 'src/legacy_graphql'
import { Text } from '@sendoutcards/quantum-design-ui'

type Props<T extends { __typename: keyof NormalizedData; id: string }> = {
  filters: SearchFilter[]
  filterName: string
  deleteFilter: (filter: SearchFilter) => void
  onClear: () => void
  onCancel: () => void
  onSave?: (item: T) => void
  getQuery: (
    terms: string,
  ) => Query<
    PaginatedState<T>,
    { offset: number; limit: number },
    PaginatedView<T>
  >
  getKey: (item: T) => string
  getLabel: (item: T) => string
  autocomplete?: 'on' | 'off'
  setGroupId?: (id: string) => void
  setGroupName?: (name: string) => void
}

const ListFilterInput = <
  T extends { __typename: keyof NormalizedData; id: string; name?: string }
>(
  props: Props<T>,
) => {
  const {
    filterName,
    filters,
    deleteFilter,
    onClear,
    onCancel,
    onSave,
    getKey,
    getLabel,
    autocomplete,
    getQuery,
    setGroupId,
    setGroupName,
  } = props

  const inputRef = useRef<HTMLInputElement>(null)
  const [isFocused, setIsFocused] = useState(false)
  const [highlightIndex, setHighlightIndex] = useState<number>()
  const [inputValue, setInputValue] = useState('')
  const definedFilters = filters.filter(filter => filter.value !== undefined)

  const debouncedValue = useDebounce(inputValue, 500)

  const [{ value, isUnresolved }] = useQueryFutures(getQuery(debouncedValue))

  const data = value?.results

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  useEffect(() => {
    setHighlightIndex(undefined)
  }, [data])

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const { key } = e

    switch (key) {
      case 'Backspace':
        if (inputValue === '') {
          onCancel()
        }
        break
      case 'ArrowDown':
        e.preventDefault()
        if (!data) return
        setHighlightIndex(x => ((x ?? -1) + 1 + data.length) % data.length)
        break
      case 'ArrowUp':
        e.preventDefault()
        if (!data) return
        setHighlightIndex(
          x => ((x ?? data.length) - 1 + data.length) % data.length,
        )
        break
      case 'Enter':
        if (!data) return
        if (highlightIndex !== undefined) {
          if (onSave) {
            onSave(data[highlightIndex] as T)
          } else if (setGroupId && setGroupName) {
            setGroupId(data[highlightIndex].id)
            setGroupName(data[highlightIndex].name ?? '')
          }
        }
        break
    }
  }

  return (
    <>
      <div className={styles.filterChips}>
        {isFocused && debouncedValue && (
          <div className={styles.filterTypeContainer}>
            <Paper style={{ width: 200 }}>
              {isUnresolved || !data ? (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <div
                  style={{
                    maxHeight: 300,
                    overflow: 'auto',
                    width: 200,
                  }}
                >
                  <List>
                    {data.length ? (
                      data
                        .map(item => item as T)
                        .map((item, index) => (
                          <ListItem
                            id={`filtered_list_item_${index}`}
                            key={getKey(item)}
                            onMouseDown={() => {
                              if (onSave) {
                                onSave(item)
                              } else if (setGroupId && setGroupName) {
                                setGroupId(data[index].id)
                                setGroupName(data[index].name ?? '')
                              }
                            }}
                            onTouchStart={() => {
                              if (onSave) {
                                onSave(item)
                              } else if (setGroupId && setGroupName) {
                                setGroupId(data[index].id)
                                setGroupName(data[index].name ?? '')
                              }
                            }}
                            style={{
                              cursor: 'pointer',
                              background:
                                index === highlightIndex ? '#eee' : '',
                            }}
                          >
                            <ListItemText primary={getLabel(item)} />
                          </ListItem>
                        ))
                    ) : (
                      <ListItem>
                        <ListItemText primary={'Not found'} />
                      </ListItem>
                    )}
                  </List>
                </div>
              )}
            </Paper>
          </div>
        )}

        <div className={styles.chips}>
          {filters.map(filter => (
            <FilterChip
              key={Math.random()}
              filter={filter}
              onDelete={deleteFilter}
            />
          ))}

          <Text
            type="caption"
            style={{
              padding: '2px 5px 2px 10px',
              backgroundColor: '#F7F7F7',
              borderRadius: 15,
            }}
          >
            {filterName}:
          </Text>
        </div>
      </div>

      <input
        id={'contact_search_bar'}
        style={{ height: 45 }}
        type="text"
        value={inputValue}
        ref={inputRef}
        onChange={e => setInputValue(e.target.value)}
        className={styles.searchInput}
        placeholder="Type to search your contacts"
        onKeyDown={handleKeyDown}
        onBlur={() => setIsFocused(false)}
        onFocus={() => setIsFocused(true)}
        autoComplete={autocomplete}
      />

      {(definedFilters.length > 0 || inputValue) && (
        <CloseButton onClose={() => onClear()} size={18} />
      )}
    </>
  )
}

export default ListFilterInput
