import { Interpolation } from '@emotion/css'
import { CardType, ColorFragment } from 'src/graphql/generated/graphql'
import { rgbString } from 'src/redux/selectors/text'

const aspectRatio = (width: number, height: number): Interpolation => ({
  position: 'relative',
  '&:before': {
    display: 'block',
    content: "'\\a0'",
    width: '100%',
    height: '0',
    paddingTop: `${(height / width) * 100}%`,
  },
})

const getDimensions = (
  cardType: CardType,
  panelName: string,
  orientation: string,
) => {
  const landscape = { width: 7, height: 5 }
  const portrait = { width: 5, height: 7 }

  if (cardType === CardType.Postcard) {
    if (panelName === 'Front') {
      return orientation === 'LANDSCAPE'
        ? { width: 6, height: 4.25 }
        : { width: 4.25, height: 6 }
    }
    return { width: 3.67, height: 4.25 }
  }
  return (panelName === 'Back' && cardType !== CardType.Flatcard) ||
    orientation === 'PORTRAIT'
    ? portrait
    : landscape
}

const getAspectRatio = (
  cardType: CardType,
  panelName: string,
  orientation: string,
): Interpolation => {
  const { width, height } = getDimensions(cardType, panelName, orientation)
  return aspectRatio(width, height)
}

const styles = {
  container: (
    isSelected: boolean,
    cardType: CardType,
    panelName: string,
    orientation: string,
  ) => ({
    width: '100%',
    cursor: 'pointer',
    margin: '1px auto',
    boxShadow: isSelected
      ? '1px 2px 8px 0 #f766b1'
      : '0 8px 22px 0 rgba(0, 0, 0, 0.175)',
    ...(getAspectRatio(cardType, panelName, orientation) as {}),
  }),
  panelBackground: (
    hasBackgroundSpread: boolean,
    backgroundColor: ColorFragment | null,
  ) => ({
    backgroundColor: backgroundColor
      ? rgbString(backgroundColor)
      : hasBackgroundSpread
      ? 'transparent'
      : '#fff',
  }),
  panelContent: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
  } as Interpolation,
}

export default styles
