import React from 'react'
import { CircularProgress } from '@material-ui/core'
import { Div } from '@sendoutcards/quantum-design-ui'

// @src imports
import { Confirm } from 'src/chrome'
import { useActions, useEffect, useState } from 'src/hooks'
import { CardPanelView } from 'src/orders/components'
import suspenseBoundary from '../../../../chrome/SuspenseBoundary/suspenseBoundaryHOC'
import styles from './sentCard.module.scss'
import { CardHistoryResendCardRoute } from 'src/dashboard/routes/CardHistoryRoute'
import { Memoized } from 'src/hooks/dependencies'
import { useCreateCard, useGetCard, useProductionInfo } from 'src/react_query'

interface ViewSentCardProps {
  onClose: () => void
  sentOrderId: string | undefined
  sentContactId: string | undefined
}

interface ContentProps {
  sentOrderId: string | undefined
  sentContactId: string | undefined
  cardId: string
  setCardId: Memoized<(id: string) => void>
}

const Content: React.FC<ContentProps> = props => {
  const { sentOrderId, sentContactId, cardId, setCardId } = props
  const { data: productionInfo } = useProductionInfo(
    { orderId: sentOrderId ?? '', contactId: sentContactId ?? '' },
    { enabled: !!(sentOrderId && sentContactId) },
  )

  const { data } = useGetCard({ id: cardId }, { suspense: true })
  const card = data?.card

  useEffect(() => {
    if (productionInfo?.recipient?.card) {
      setCardId(productionInfo.recipient.card.id)
    }
  }, [productionInfo, setCardId])

  return (
    <>
      {card && (
        <Div style={{ position: 'relative', width: '100%' }}>
          <CardPanelView card={card} />
        </Div>
      )}
    </>
  )
}

const ViewSentCardContent = suspenseBoundary({
  component: Content,
  unresolved: <CircularProgress />,
  failure: error => `Failed loading notifications: ${error}`,
})

const ViewSentCard: React.FC<ViewSentCardProps> = props => {
  const { onClose, sentOrderId, sentContactId } = props
  const [cardId, setCardId] = useState('')
  const { mutateAsync: createCard } = useCreateCard()
  const actions = useActions()

  const onResendCard = async () => {
    try {
      const result = await createCard({
        card: cardId,
        isResend: true,
      })
      if (result.createCard.card) {
        actions.openCardHistory(
          undefined,
          undefined,
          CardHistoryResendCardRoute(result.createCard.card.id),
        )
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Div>
      <Confirm
        title={'View your card'}
        onDecline={() => onClose()}
        childType={'image'}
        isModalEnabled={true}
        confirmTitle={cardId ? 'Resend' : undefined}
        onConfirm={cardId ? () => onResendCard() : () => {}}
        wrapperClassName={styles.confirmWrapper}
        actionsClassName={styles.confirmActions}
        confirmMessageClassName={styles.confirmMessage}
      >
        <ViewSentCardContent
          sentOrderId={sentOrderId}
          sentContactId={sentContactId}
          cardId={cardId}
          setCardId={setCardId}
        />
      </Confirm>
    </Div>
  )
}

export default ViewSentCard
