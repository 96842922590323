import {
  Maybe,
  LayoutCategory,
  Owner,
  LayoutDimensions,
} from '../../../__generated__/globalTypes'

import { PanelFragment, isPanelFragment } from './PanelFragment'

import layoutFragmentRawString from '../LayoutFragment.graphql'

export const layoutFragmentString = layoutFragmentRawString

export type LayoutFragment = {
  __typename: 'Layout'
  id: string
  category: Maybe<LayoutCategory>
  owner: Owner
  dimensions: Maybe<LayoutDimensions>
  thumbnail: {
    url: string
  }
  panel: PanelFragment
}

export const isLayoutFragment = (fragment: any): fragment is LayoutFragment =>
  fragment &&
  fragment.__typename === 'Layout' &&
  typeof fragment.id === 'string' &&
  (fragment.category === null ||
    fragment.category === 'BASIC_LAYOUTS' ||
    fragment.category === 'BACK_PANELS') &&
  (fragment.owner === 'USER' || fragment.owner === 'SYSTEM') &&
  (fragment.dimensions === null ||
    fragment.dimensions === 'VERTICAL' ||
    fragment.dimensions === 'HORIZONTAL' ||
    fragment.dimensions === 'POSTCARD') &&
  fragment.thumbnail &&
  typeof fragment.thumbnail.url === 'string' &&
  (isPanelFragment(fragment.panel) as boolean)
