import React, { FC } from 'react'
import { SVGProps } from './types'

export const Download: FC<SVGProps> = ({ size, color, onClick }) => {
  return (
    <svg
      width={size ?? 14}
      height={size ?? 16}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1C8 0.792893 8.16789 0.625 8.375 0.625H12.3743H12.375C12.5821 0.625 12.75 0.792893 12.75 1V5C12.75 5.20711 12.5821 5.375 12.375 5.375C12.1679 5.375 12 5.20711 12 5V1.90468L5.30618 8.5985C5.15973 8.74494 4.9223 8.74494 4.77585 8.5985C4.6294 8.45205 4.6294 8.21461 4.77585 8.06817L11.469 1.375H8.375C8.16789 1.375 8 1.20711 8 1ZM1.70833 3.375C1.45417 3.375 1.21041 3.47597 1.03069 3.65569C0.850967 3.83541 0.75 4.07917 0.75 4.33333V11.6667C0.75 11.9208 0.850967 12.1646 1.03069 12.3443C1.21041 12.524 1.45417 12.625 1.70833 12.625H9.04167C9.29583 12.625 9.53959 12.524 9.71931 12.3443C9.89903 12.1646 10 11.9208 10 11.6667V7.66667C10 7.45956 10.1679 7.29167 10.375 7.29167C10.5821 7.29167 10.75 7.45956 10.75 7.66667V11.6667C10.75 12.1197 10.57 12.5543 10.2496 12.8746C9.92927 13.195 9.49475 13.375 9.04167 13.375H1.70833C1.25525 13.375 0.820734 13.195 0.500359 12.8746C0.179985 12.5543 0 12.1197 0 11.6667V4.33333C0 3.88026 0.179985 3.44573 0.500359 3.12536C0.820734 2.80498 1.25526 2.625 1.70833 2.625H5.70833C5.91544 2.625 6.08333 2.79289 6.08333 3C6.08333 3.20711 5.91544 3.375 5.70833 3.375H1.70833Z"
        fill={color ?? '#404040'}
      />
    </svg>
  )
}
