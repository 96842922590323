import React, { CSSProperties } from 'react'
import ResizeDetector from 'react-resize-detector'
// @src imports
import TextEditor from 'src/editor/text/TextEditor'
// relative imports
import TextTrigger from './TextTrigger/TextTrigger'
import PanelTrigger from './PanelTrigger/PanelTrigger'

import Styles from '../styles/editor.module.scss'
import {
  ComputedElement,
  ComputedFullBleed,
  ComputedPanel,
} from '../../redux/selectors/editor'
import { ComputedElementText } from 'src/editor/text/types'
import { Api } from '../api'
import { Steps } from '../types'

import {
  useCallback,
  useDeviceInfo,
  useEffect,
  useRef,
  useSelector,
} from 'src/hooks'
import { Memoized } from 'src/hooks/dependencies'
import { TEXT_SETTING_OPEN_VALUE } from '../constants'
import TextEditorAndroid from '../text/TextEditorAndroid'

const clickableDivStyle: CSSProperties = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 101,
}

type TextElementProps = {
  api: Api
  element: ComputedElement
  panel: ComputedPanel
  elementText: ComputedElementText
  elementDisplayStyles: React.CSSProperties
  fullBleed: ComputedFullBleed
  handleMobileEditing?: Memoized<(shouldPortal: boolean) => void>
}

const TextElement: React.FC<TextElementProps> = props => {
  const {
    api,
    element,
    panel,
    elementText,
    elementDisplayStyles,
    fullBleed,
    handleMobileEditing,
  } = props
  const { setStep, selectText, updateElement, setEditorDuvetOpenValue } = api
  const deviceInfo = useDeviceInfo()
  const isMobile = useSelector(state => state.window.isMobile)

  // avoid making all functions from api callbacks
  const mutableUpdateElementRef = useRef(updateElement)
  mutableUpdateElementRef.current = updateElement

  const updateElementPosition = useCallback(
    (x: number, y: number) =>
      mutableUpdateElementRef.current(
        fullBleed.location,
        panel.location,
        element.location,
        element => ({ ...element, x: x, y: y }),
      ),
    [fullBleed.location, panel.location, element.location],
  )

  const updateElementSize = useCallback(
    (width: number, height: number) =>
      mutableUpdateElementRef.current(
        fullBleed.location,
        panel.location,
        element.location,
        element => ({
          ...element,
          width: width,
          height: height,
        }),
      ),
    [fullBleed.location, panel.location, element.location],
  )

  const editLayout = () => {
    setStep({
      type: Steps.EditLayout,
      fullBleedIndex: fullBleed.location,
      panelIndex: panel.location,
      elementIndex: undefined,
      textSelection: undefined,
    })
    if (isMobile) setEditorDuvetOpenValue(TEXT_SETTING_OPEN_VALUE)
  }

  const elementId =
    `${panel.name}-${element.location}`.toLowerCase().replace(' ', '_') +
    (isMobile ? '_mobile' : '')

  const wrapperId = `text_element_wrapper_${elementId}`
  const panelId = `${panel.name}_panel`
  const textEditorId = `text_editor_${elementId}`
  const resizeElementId = wrapperId + '_resize'

  const mutableDidMountRef = useRef(false)

  const dragElement = useCallback(
    (
      panelEl: HTMLElement,
      borderEls: NodeListOf<Element>,
      mutableElement: HTMLElement,
      computedElement: ComputedElement,
    ) => {
      const mutableState = { pos1: 0, pos2: 0, pos3: 0, pos4: 0 }

      const onDragElement = (event: MouseEvent) => {
        const e = event || window.event

        if (e.which === 1) {
          // calculate the new cursor position:
          mutableState.pos1 = mutableState.pos3 - e.clientX
          mutableState.pos2 = mutableState.pos4 - e.clientY
          mutableState.pos3 = e.clientX
          mutableState.pos4 = e.clientY

          // set the element's new position:
          const leftBound = 0
          const topBound = 0
          const rightBound = panelEl.offsetWidth
          const bottomBound = panelEl.offsetHeight

          mutableElement.style.top =
            mutableElement.offsetTop - mutableState.pos2 + 'px'
          mutableElement.style.left =
            mutableElement.offsetLeft - mutableState.pos1 + 'px'

          if (mutableElement.offsetTop - mutableState.pos2 < topBound) {
            mutableElement.style.top = topBound + 'px'
          }
          if (
            mutableElement.offsetTop +
              mutableElement.offsetHeight +
              mutableState.pos2 >
            bottomBound
          ) {
            mutableElement.style.top =
              bottomBound - mutableElement.offsetHeight + 'px'
          }
          if (mutableElement.offsetLeft - mutableState.pos1 < leftBound) {
            mutableElement.style.left = leftBound + 'px'
          }
          if (
            mutableElement.offsetLeft +
              mutableElement.offsetWidth +
              mutableState.pos1 >
            rightBound
          ) {
            mutableElement.style.left =
              rightBound - mutableElement.offsetWidth + 'px'
          }
        }
      }

      const stopDrag = () => {
        /* stop moving when mouse button is released:*/
        updateElementPosition(
          mutableElement.offsetLeft / panelEl.offsetWidth,
          mutableElement.offsetTop / panelEl.offsetHeight,
        )
        document.onmouseup = null
        document.onmousemove = null
      }

      const startDrag = (event: MouseEvent) => {
        const e = event || window.event

        if (e.which === 1) {
          mutableState.pos3 = e.clientX
          mutableState.pos4 = e.clientY
          document.onmousemove = onDragElement
          document.onmouseup = stopDrag
        }
      }

      if (computedElement.isCustom) {
        Array.from(borderEls).forEach(mutableBorder => {
          if (mutableBorder instanceof HTMLElement) {
            mutableBorder.onmousedown = startDrag
          }
        })
      }
    },
    [updateElementPosition],
  )

  const resizeElement = useCallback(
    (
      panel: HTMLElement,
      mutableElement: HTMLElement,
      mutableResizeEl: HTMLElement,
      editorId: string,
      computedElement: ComputedElement,
      computedPanel: ComputedPanel,
    ) => {
      const mutableState = { pos1: 0, pos2: 0, pos3: 0, pos4: 0 }

      const onResizeDrag = (event: MouseEvent) => {
        const e = event || window.event
        e.stopPropagation()

        const panelEl = panel.getBoundingClientRect()
        if (!(panelEl instanceof DOMRect)) {
          return
        }
        mutableState.pos1 = mutableState.pos3 - e.clientX
        mutableState.pos2 = mutableState.pos4 - e.clientY
        mutableState.pos3 = e.clientX
        mutableState.pos4 = e.clientY

        const panelWidth = panelEl.width
        const panelHeight = panelEl.height

        const leftBound = computedPanel.x * panelWidth
        const topBound = computedPanel.y * panelHeight
        const rightBound = leftBound + panelWidth
        const bottomBound = topBound + panelHeight

        mutableElement.style.width =
          e.clientX - (panelEl.x + mutableElement.offsetLeft) + 'px'
        mutableElement.style.height =
          e.clientY - (mutableElement.offsetTop + panelEl.y) + 'px'

        if (
          mutableElement.offsetTop +
            mutableElement.offsetHeight +
            mutableState.pos2 >=
          bottomBound
        ) {
          mutableElement.style.height =
            bottomBound - mutableElement.offsetTop + 'px'
        }
        if (
          mutableElement.offsetLeft +
            mutableElement.offsetWidth +
            mutableState.pos1 >=
          rightBound
        ) {
          mutableElement.style.width =
            rightBound - mutableElement.offsetLeft + 'px'
        }
      }

      const stopResize = () => {
        updateElementSize(
          mutableElement.offsetWidth / panel.offsetWidth,
          mutableElement.offsetHeight / panel.offsetHeight,
        )

        const mutableEditorEl = document.getElementById(editorId)
        if (mutableEditorEl) {
          mutableEditorEl.style.visibility = 'visible'
        }
        mutableElement.style.backgroundColor = 'transparent'

        document.onmouseup = null
        document.onmousemove = null
      }

      const startResize = (event: MouseEvent) => {
        const e = event || window.event
        e.stopPropagation()

        mutableState.pos3 = e.clientX
        mutableState.pos4 = e.clientY

        const mutableEditorEl = document.getElementById(editorId)
        if (mutableEditorEl) {
          mutableEditorEl.style.visibility = 'hidden'
        }
        mutableElement.style.backgroundColor = 'rgba(247, 247, 247, .8)'

        document.onmousemove = onResizeDrag
        document.onmouseup = stopResize
      }

      if (computedElement.isCustom) {
        mutableResizeEl.onmousedown = startResize
      } else {
        try {
          mutableResizeEl.onmousedown = null
        } catch (e) {}
      }
    },
    [updateElementSize],
  )

  useEffect(() => {
    if (mutableDidMountRef.current) {
      const wrapperEl = document.getElementById(wrapperId)
      const borderEls = document.querySelectorAll('.drag-border')
      const panelEl = document.getElementById(panelId)
      const resizeEl = document.getElementById(resizeElementId)

      if (!element.locked && panelEl && wrapperEl && resizeEl) {
        dragElement(panelEl, borderEls, wrapperEl, element)
        resizeElement(
          panelEl,
          wrapperEl,
          resizeEl,
          textEditorId,
          element,
          panel,
        )
      }
    } else mutableDidMountRef.current = true
  }, [
    dragElement,
    resizeElement,
    mutableDidMountRef,
    wrapperId,
    panelId,
    resizeElementId,
    element,
    panel,
    textEditorId,
  ])

  const paragraphs = element.text?.paragraphs
  const hasSomeText =
    paragraphs &&
    paragraphs.length > 0 &&
    (paragraphs[0].fragments.length > 1 || paragraphs.length > 1)

  const handleSelect = (start: number, end?: number) => {
    selectText(fullBleed.location, panel.location, element.location, {
      start,
      end: end ?? start,
      overrideProperties: {},
    })

    if (isMobile) {
      document.getElementById(textEditorId)?.focus()
    }
  }

  const selectElementForEvent = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) => {
    event.stopPropagation()
    event.preventDefault()
    handleSelect(elementText.selectionUpperBound)
  }

  const isFullTextPanel = panel.elements.length === 1

  useEffect(() => {
    handleMobileEditing?.(element.isSelected)
  }, [element.isSelected, handleMobileEditing])
  return (
    <div
      id={wrapperId}
      className={Styles.textComponent}
      style={{
        ...elementDisplayStyles,
        ...(element.locked
          ? { pointerEvents: 'none', border: 'none' }
          : { cursor: 'text' }),
        ...(element.isCustom ? { overflow: 'hidden' } : {}),
        ...(hasSomeText && !element.isSelected ? { border: 'none' } : {}),
      }}
      onClick={event => {
        event.stopPropagation()
        event.preventDefault()
      }}
      key={element.location}
    >
      {element.isCustom && [
        <div // Draggable div for resize on custom card
          id={resizeElementId}
          style={
            {
              position: 'absolute',
              height: 15,
              width: 15,
              cursor: 'se-resize',
              bottom: 0,
              right: 0,
              zIndex: 1002,
            } as React.CSSProperties
          }
          key={0}
        />,
        <div
          style={
            {
              position: 'absolute',
              height: 10,
              width: '100%',
              top: 0,
              cursor: 'move',
              zIndex: 1,
            } as React.CSSProperties
          }
          className={'drag-border'}
          key={1}
        />,
        <div
          style={
            {
              position: 'absolute',
              height: 10,
              width: 'calc(100% - 15px)',
              bottom: 0,
              left: 0,
              cursor: 'move',
              zIndex: 1,
            } as React.CSSProperties
          }
          className={'drag-border'}
          key={2}
        />,
        <div
          style={
            {
              position: 'absolute',
              height: 'calc(100% - 15px)',
              width: 10,
              top: 0,
              right: 0,
              cursor: 'move',
              zIndex: 1,
            } as React.CSSProperties
          }
          className={'drag-border'}
          key={3}
        />,
        <div
          style={
            {
              position: 'absolute',
              height: '100%',
              width: 10,
              top: 0,
              left: 0,
              cursor: 'move',
              zIndex: 1,
            } as React.CSSProperties
          }
          className={'drag-border'}
          key={4}
        />,
      ]}
      {!element.isSelected && isFullTextPanel ? (
        !hasSomeText ? (
          <PanelTrigger
            textFunction={selectElementForEvent}
            templateFunction={event => {
              event.stopPropagation()
              editLayout()
            }}
          />
        ) : null
      ) : (
        // Not full text panel
        !element.locked &&
        !element.isSelected &&
        !hasSomeText && [
          <TextTrigger
            id={`text_trigger_${panel.location}_${element.location}`}
            key={`text_trigger_${element.location}`}
            color={'#FFADCC'}
            textFunction={e => {
              selectElementForEvent(e)
            }}
          />,
          !element.isCustom ? (
            <div
              key={`clickable_div_${element.location}`}
              style={clickableDivStyle}
              onClick={selectElementForEvent}
            />
          ) : (
            <div
              key={`clickable_div_${element.location}`}
              style={{ ...clickableDivStyle, cursor: 'move' }}
            />
          ),
        ]
      )}
      <div
        onClick={event => {
          event.stopPropagation()
          event.preventDefault()
          handleSelect(1)
        }}
        style={{ flex: 1, width: '100%' }}
      />
      <ResizeDetector
        handleWidth={true}
        handleHeight={true}
        querySelector={`#${wrapperId}`}
      >
        {({ width }: { width: number }) => {
          const fontScale = (width / element.points.width) * 1.4 || 1
          if (deviceInfo.os === 'Android' && deviceInfo.browser === 'Chrome') {
            return (
              <TextEditorAndroid
                textEditorId={textEditorId}
                elementText={elementText}
                selectText={handleSelect}
                fontScale={fontScale}
                api={api}
                isSelected={element.isSelected}
                isLocked={element.locked}
              />
            )
          } else {
            return (
              <TextEditor
                textEditorId={textEditorId}
                elementText={elementText}
                selectText={handleSelect}
                fontScale={fontScale}
                api={api}
                isSelected={element.isSelected}
                isLocked={element.locked}
                panelName={panel.name}
              />
            )
          }
        }}
      </ResizeDetector>
      <div onClick={selectElementForEvent} style={{ flex: 1, width: '100%' }} />
    </div>
  )
}

export default TextElement
