import React, { FC } from 'react'
import { SVGProps } from './types'

export const Gift: FC<SVGProps> = ({ size, color, onClick }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick && (() => onClick())}
    >
      <path
        d="M15.9707 22.75H7.9707C4.5507 22.75 3.2207 21.42 3.2207 18V10C3.2207 9.59 3.5607 9.25 3.9707 9.25H19.9707C20.3807 9.25 20.7207 9.59 20.7207 10V18C20.7207 21.42 19.3907 22.75 15.9707 22.75ZM4.7207 10.75V18C4.7207 20.58 5.3907 21.25 7.9707 21.25H15.9707C18.5507 21.25 19.2207 20.58 19.2207 18V10.75H4.7207Z"
        fill={color}
      />
      <path
        d="M19.5 10.75H4.5C2.75 10.75 1.75 9.75 1.75 8V7C1.75 5.25 2.75 4.25 4.5 4.25H19.5C21.2 4.25 22.25 5.3 22.25 7V8C22.25 9.7 21.2 10.75 19.5 10.75ZM4.5 5.75C3.59 5.75 3.25 6.09 3.25 7V8C3.25 8.91 3.59 9.25 4.5 9.25H19.5C20.38 9.25 20.75 8.88 20.75 8V7C20.75 6.12 20.38 5.75 19.5 5.75H4.5Z"
        fill={color}
      />
      <path
        d="M11.6391 5.74988H6.11912C5.90912 5.74988 5.70912 5.65988 5.56912 5.50988C4.95912 4.83988 4.97912 3.80988 5.61912 3.16988L7.03912 1.74988C7.69912 1.08988 8.78912 1.08988 9.44912 1.74988L12.1691 4.46988C12.3791 4.67988 12.4491 5.00988 12.3291 5.28988C12.2191 5.56988 11.9491 5.74988 11.6391 5.74988ZM6.66912 4.24988H9.83912L8.38912 2.80988C8.30912 2.72988 8.17912 2.72988 8.09912 2.80988L6.67912 4.22988C6.67912 4.23988 6.66912 4.23988 6.66912 4.24988Z"
        fill={color}
      />
      <path
        d="M17.8701 5.74988H12.3501C12.0501 5.74988 11.7701 5.56988 11.6601 5.28988C11.5401 5.00988 11.6101 4.68988 11.8201 4.46988L14.5401 1.74988C15.2001 1.08988 16.2901 1.08988 16.9501 1.74988L18.3701 3.16988C19.0101 3.80988 19.0401 4.83988 18.4201 5.50988C18.2801 5.65988 18.0801 5.74988 17.8701 5.74988ZM14.1701 4.24988H17.3401C17.3301 4.23988 17.3301 4.23988 17.3201 4.22988L15.9001 2.80988C15.8201 2.72988 15.6901 2.72988 15.6101 2.80988L14.1701 4.24988ZM9.93945 16.9C9.65945 16.9 9.36945 16.83 9.10945 16.69C8.53945 16.38 8.18945 15.79 8.18945 15.15V10C8.18945 9.59 8.52945 9.25 8.93945 9.25H14.9795C15.3895 9.25 15.7295 9.59 15.7295 10V15.13C15.7295 15.78 15.3795 16.37 14.8095 16.67C14.2395 16.98 13.5495 16.94 13.0095 16.58L12.1195 15.98C12.0395 15.92 11.9295 15.92 11.8395 15.98L10.8995 16.6C10.6095 16.8 10.2695 16.9 9.93945 16.9ZM9.68945 10.75V15.14C9.68945 15.27 9.76945 15.33 9.81945 15.36C9.86945 15.39 9.96945 15.42 10.0795 15.35L11.0195 14.73C11.6095 14.34 12.3695 14.34 12.9495 14.73L13.8395 15.33C13.9495 15.4 14.0495 15.37 14.0995 15.34C14.1495 15.31 14.2295 15.25 14.2295 15.12V10.74H9.68945V10.75Z"
        fill={color}
      />
    </svg>
  )
}
