import { Operation } from '../../../__generated__/globalTypes'

import {
  SocProContactFragment,
  socProContactFragmentString,
} from '../../fragments/__generated__/SocProContactFragment'

import { calendarDateFragmentString } from '../../fragments/__generated__/CalendarDateFragment'

import { detailedContactFragmentString } from '../../fragments/__generated__/DetailedContactFragment'

import { contactFragmentString } from '../../fragments/__generated__/ContactFragment'

import { optionalYearDateFragmentString } from '../../fragments/__generated__/OptionalYearDateFragment'

import { relatedContactFragmentString } from '../../fragments/__generated__/RelatedContactFragment'

import { noteFragmentString } from '../../fragments/__generated__/NoteFragment'

import createContactsWithSocProContactsRawString from '../CreateContactsWithSocProContacts.graphql'

const createContactsWithSocProContactsString = [
  createContactsWithSocProContactsRawString,
  socProContactFragmentString,
  calendarDateFragmentString,
  detailedContactFragmentString,
  contactFragmentString,
  optionalYearDateFragmentString,
  relatedContactFragmentString,
  noteFragmentString,
].join('\n\n')

export type CreateContactsWithSocProContactsData = {
  createContactsWithSocProContacts: {
    contactsIds: string[]
    failedSocProContacts: SocProContactFragment[]
  }
}

export type CreateContactsWithSocProContactsVariables = {
  socProContactsIds: string[]
}

export type CreateContactsWithSocProContacts = Operation<
  CreateContactsWithSocProContactsData,
  CreateContactsWithSocProContactsVariables
>

export const CreateContactsWithSocProContacts = ({
  socProContactsIds,
}: CreateContactsWithSocProContactsVariables): CreateContactsWithSocProContacts => ({
  query: createContactsWithSocProContactsString,
  variables: {
    socProContactsIds,
  },
})
