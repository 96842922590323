import { Route } from 'src/routes/Route'

export const EditOrderCardRoute = Route({
  path: '/card/:cardId/edit',
  init: (cardId: string) => ({ cardId }),
  fromPath: ({ cardId }) => (cardId ? { cardId } : undefined),
  hideSidebar: true,
})

export type EditOrderCardRoute = Route<typeof EditOrderCardRoute>
