import React from 'react'
import { Set } from 'immutable'
import suspenseBoundary from 'src/chrome/SuspenseBoundary/suspenseBoundaryHOC'
import { Button, Confirm, DefaultError, Search, Transition } from 'src/chrome'
import { CatalogHeader, CatalogRibbon } from '..'
import { useActions, useMutations, useQueries, useState } from 'src/hooks'
import {
  CardCategoryFragment,
  getCardCategories,
  getCardCategory,
  getCustomCards,
  getLegacyPicturePlusCards,
  getSendableCards,
} from 'src/legacy_graphql'
import styles from './cardCategory.module.scss'
import { isMinimalSendableCardFragment } from 'src/catalog/types'

import { Api } from '../../api'

interface Props {
  api: Api
  displayedCards:
    | {
        type: 'category'
        id: string
      }
    | { type: 'premium' }
    | { type: 'favorites' }
    | { type: 'custom' }
    | { type: 'legacy-picture-plus' }
    | { type: 'catalog-card' }
  hasContextBar: boolean
}

const CardCategory: React.FC<Props> = props => {
  const { displayedCards, api, hasContextBar } = props

  const actions = useActions()
  const mutations = useMutations()

  const cardCategoriesQuery =
    displayedCards.type === 'custom' ||
    displayedCards.type === 'legacy-picture-plus'
      ? getCardCategories()
      : undefined

  const cardCategoryQuery =
    displayedCards.type === 'category'
      ? getCardCategory({ id: displayedCards.id })
      : undefined

  const [search, setSearch] = useState('')
  const [selectedCardsToDelete, setSelectedCardsToDelete] = useState(Set())
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const {
    query: displayedCardsQuery,
    showSearch,
    canDelete,
    canFavorite,
  } = (() => {
    switch (displayedCards.type) {
      case 'custom':
        return {
          query: getCustomCards(),
          showSearch: false,
          canDelete: true,
          canFavorite: true,
        }
      case 'legacy-picture-plus':
        return {
          query: getLegacyPicturePlusCards(),
          showSearch: false,
          canDelete: true,
          canFavorite: true,
        }
      case 'favorites':
        return {
          query: getSendableCards({
            search: search,
            favorite: true,
          }),
          showSearch: false,
          canDelete: false,
          canFavorite: false,
        }
      case 'premium':
        return {
          query: getSendableCards({
            search: search,
            isPremium: true,
          }),
          showSearch: false,
          canDelete: false,
          canFavorite: false,
        }
      case 'category':
        return {
          query: getSendableCards({
            search: search,
            category: displayedCards.id,
          }),
          showSearch: false,
          canDelete: false,
          canFavorite: false,
        }
      case 'catalog-card':
        return {
          query: getSendableCards({
            search: search,
            isPremium: false,
          }),
          showSearch: true,
          canDelete: false,
          canFavorite: true,
        }
    }
  })()

  const [cardCategories, category, fetchedCards] = useQueries(
    cardCategoriesQuery,
    cardCategoryQuery,
    displayedCardsQuery,
  )

  const headerTitle = (() => {
    switch (displayedCards.type) {
      case 'category':
        return {
          title: category?.description ?? '',
        }
      case 'premium':
        return {
          title: 'Photo Drop Cards',
          description:
            'Put your own image in these cards and add the perfect personal touch.',
        }
      case 'catalog-card':
        return {
          title: 'Catalog Cards',
          description:
            'Browse our card catalog for quality designs to send to your loved ones.',
        }
      case 'custom':
        return {
          title: '',
        }
      case 'favorites':
        return {
          title: 'My Favorites',
        }
      default:
        const firstCard = fetchedCards.results[0]
        const title = isMinimalSendableCardFragment(firstCard)
          ? firstCard.title
          : ''
        return {
          title,
        }
    }
  })()

  const handleOnSearch = (value: string) => setSearch(value)

  const handleClearSearch = () => setSearch('')

  const handleOnClose = () => actions.dismissedCatalogSubroute()

  const onSelectCategory = (cardCategory: CardCategoryFragment) => {
    actions.selectedCardCategory(cardCategory.id)
  }

  const handleOpenCardPreview = (id: string, typename?: string) => {
    if (typename === 'Card') {
      actions.selectedCatalogCard(typename, id)
    } else {
      actions.selectedCatalogCard('SendableCard', id)
    }
  }

  const handleFavoriteCard = async (id: string, isFavorite: boolean) => {
    try {
      if (isFavorite) {
        await mutations.unfavoriteCard({ id })
      } else {
        await mutations.favoriteCard({ id })
      }
    } catch (error) {
      console.error('Failed to favorite/unfavorite card.')
    }
  }

  const selectMyCard = (sendableCardId: string) =>
    setSelectedCardsToDelete(
      selectedCardsToDelete.has(sendableCardId)
        ? selectedCardsToDelete.delete(sendableCardId)
        : selectedCardsToDelete.add(sendableCardId),
    )

  const deleteSelectedCustomCards = () => {
    switch (displayedCards.type) {
      case 'custom':
        mutations.deleteCards({ cards: Array.from(selectedCardsToDelete) })
        break
      case 'legacy-picture-plus':
      case 'favorites':
      case 'premium':
      case 'category':
        mutations.deleteSendableCards({
          cards: Array.from(selectedCardsToDelete),
        })
        break
    }
    setSelectedCardsToDelete(Set())
    toggleDeleteModal()
  }

  const toggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen)

  return (
    <div>
      <CatalogHeader
        hasContextBar={hasContextBar}
        info={headerTitle}
        textColor="#fff"
        backgroundStartColor="#e687ff"
        backgroundEndColor="#ff5689"
        card={fetchedCards.results[0]}
        cardPreview={handleOpenCardPreview}
        onBack={handleOnClose}
        handleFavoriteCard={handleFavoriteCard}
      />
      {showSearch && (
        <Search
          categories={cardCategories}
          activeCategory={category}
          handleCategory={onSelectCategory}
          autocomplete={'off'}
          buttonClick={() => api.setShowBuildYourOwnModal(true)}
          onSearch={handleOnSearch}
          clearSearch={handleClearSearch}
          className={styles.catalogSearchBar}
          inputClassName={styles.catalogSearchField}
          tagsClassName={styles.catalogSearchTags}
        />
      )}
      {canDelete && selectedCardsToDelete.size > 0 && (
        <Button
          title={'Delete'}
          buttonColor={'pink'}
          style={{ width: 126, marginTop: 35 }}
          className={styles.deleteButton}
          onClick={toggleDeleteModal}
        />
      )}
      <CatalogRibbon
        type={'search_results'}
        canFavorite={canFavorite}
        title={category?.description}
        items={fetchedCards.results}
        isSearchResultEmpty={
          search !== '' &&
          fetchedCards.hasMore &&
          fetchedCards.results.length <= 0
        }
        search={search}
        category={category?.description}
        isWrapContentEnabled={true}
        onClick={handleOpenCardPreview}
        onClose={handleOnClose}
        handleSearchAll={handleClearSearch}
        handleCheckbox={canDelete ? selectMyCard : undefined}
        selectedSet={selectedCardsToDelete}
        isLoadingMore={fetchedCards.isLoadingMore}
        hasMore={fetchedCards.hasMore}
        loadMore={fetchedCards.hasMore ? fetchedCards.loadMore : undefined}
        handleFavoriteCard={handleFavoriteCard}
      />
      {isDeleteModalOpen && (
        <Confirm
          title={`Delete ${selectedCardsToDelete.size} Cards`}
          message={'Are you sure you want to delete the selected card(s)?'}
          confirmTitle={'Delete'}
          onConfirm={deleteSelectedCustomCards}
          onDecline={toggleDeleteModal}
        />
      )}
    </div>
  )
}

export default suspenseBoundary({
  component: CardCategory,
  unresolved: <Transition />,
  failure: DefaultError,
})
