import React from 'react'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import TextField from '@material-ui/core/TextField'

import { useDebounce, useMemo, useState } from 'src/hooks'

import { Group } from '../../contactTypes'
import styles from './groupSelect.module.scss'
import { Text } from '@sendoutcards/quantum-design-ui'

interface Props {
  onGroupChange: (group: Group) => void
  groups: Group[]
  shouldHighlightSelected?: boolean
}

const GroupSelect: React.FC<Props> = props => {
  const { onGroupChange, groups, shouldHighlightSelected } = props

  const [searchTerm, setSearchTerm] = useState('')
  const [selectedId, setSelectedId] = useState<string>()

  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  const filteredGroups = useMemo(
    () =>
      debouncedSearchTerm === ''
        ? groups
        : groups.filter(
            group =>
              group.name
                .toLowerCase()
                .indexOf(debouncedSearchTerm.toLowerCase()) > -1,
          ),
    [groups, debouncedSearchTerm],
  )

  const handleClick = (group: Group) => {
    setSelectedId(group.id)
    onGroupChange(group)
  }

  return (
    <div className={styles.container}>
      <div className={styles.textInput}>
        <TextField
          placeholder="Search for group"
          fullWidth={true}
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          style={{ marginTop: 0 }}
        />
      </div>
      <div
        className={styles.groupList}
        style={{
          height: 300,
          overflow: 'auto',
        }}
      >
        <List>
          {filteredGroups.map((group: Group) => {
            return (
              <ListItem
                key={group.id}
                className={styles.listItem}
                style={
                  shouldHighlightSelected && group.id === selectedId
                    ? { backgroundColor: '#e8e8e8' }
                    : {}
                }
                onClick={() => handleClick(group)}
              >
                <ListItemText
                  primary={
                    <Text type="body" color="primaryHeading">
                      {group.name}
                    </Text>
                  }
                  secondary={
                    group.description ? (
                      <Text type="caption">{`${group.description}`}</Text>
                    ) : null
                  }
                />
              </ListItem>
            )
          })}
        </List>
      </div>
    </div>
  )
}

export default GroupSelect
