import { Flex, Image, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { useSelector } from 'src/hooks'

export type ItemThumbnailProps = {
  ImageURL?: string
  title?: string
  price?: string
}

const ItemThumbnail: React.FC<ItemThumbnailProps> = props => {
  const { ImageURL, title, price } = props

  const { isMobile } = useSelector(state => state.window)

  const handleTruncateTitle = (title?: string) => {
    const MAX_TITLE_LENGTH = 14
    if (!title) return

    return title.length >= MAX_TITLE_LENGTH
      ? title.substring(0, MAX_TITLE_LENGTH) + '...'
      : title
  }
  const titleType = isMobile ? 'caption' : 'body'
  const priceType = isMobile ? 'footnote' : 'caption'
  return (
    <Flex
      width="200px"
      height="210px"
      backgroundColor="background"
      flexDirection="column"
      borderRadius="large"
      justifyContent="center"
      alignItems="center"
      inset="x2"
    >
      <Image
        isActive={true}
        width="110px"
        height="115px"
        image={{ url: ImageURL ?? '' }}
      />
      <Text
        type={titleType}
        content={handleTruncateTitle(title)}
        weight="bold"
        color="primaryHeading"
        inset={{ top: 'x1_5' }}
      />
      <Text
        type={priceType}
        content={price}
        weight="bold"
        color="primaryHeading"
      />
    </Flex>
  )
}

export default ItemThumbnail
