import React, { useRef } from 'react'
import {
  Button,
  Card,
  ConfirmDialog,
  Dialog,
  Div,
  Flex,
  HStack,
  Icon as QDSIcon,
  InfoBlock,
  Input,
  Logo,
  PhoneInput,
  Separator,
  Spacer,
  Span,
  Text,
  Transition as QDSTransition,
  TransitionProps,
} from '@sendoutcards/quantum-design-ui'
import { Icon, Transition } from 'src/chrome'

import { SponsorFragment } from 'src/legacy_graphql'

import {
  useActions,
  useDebounce,
  useEffect,
  useMutations,
  useQueryParams,
  useSelector,
  useState,
  useVertical,
} from 'src/hooks'
import { LoginJoinForgot } from 'src/user/components/JoinModal/JoinModal'
import { getFormattedNumber } from 'src/onboarding/components/PhoneNumberVerification'
import {
  createPhoneNumberInput,
  getValidatedNumber,
} from 'src/helpers/createPhoneNumberInput'
import { AffiliateFormInfo } from 'src/marketing/plans/components/AffiliateForm/AffiliateForm'
import AffiliateAddress from './AffiliateJoin/AffiliateAddress'
import PaymentMethod from './AffiliateJoin/PaymentMethod'
import { Result } from 'src/utils/Result'
import CreditCardLogo, {
  creditCardBrand,
} from 'src/payments/components/CardLogo/CreditCardLogo'
import { buildShoppingCartURL } from 'src/user/components/LoginForm/ShoppingCartUtils'
import IdenfiticationNumber from './AffiliateJoin/IdentificationNumber'
import FindMySponsor from 'src/onboarding/components/FindMySponsor'
import AffiliateJoinSuccess from './AffiliateJoin/AffiliateJoinSuccess'
import { IS_AFFILIATE_FREE } from '../constants'
import { useRemoveSearchParams } from 'src/hooks/useRemoveSearchParams'
import useEmailValidation from 'src/hooks/useEmailValidation'
import useUsernameValidation from 'src/hooks/useUsernameValidation'
import { sanitizeUsername } from 'src/helpers/sanitizeUsername'
import ReCAPTCHA from 'react-google-recaptcha'
import { EmailValidationStep, VerifyClient } from './VerifyClient'
import { ValidationError } from 'src/email_verification/VerifyEmail/ValidationFailureEmailVerification'
import { SponsorCard } from 'src/onboarding/components/SponsorCard'
import usePhoneNumberCountries, {
  PhoneNumberCountries,
} from 'src/hooks/usePhoneNumberCountries'
import { getMachineCountryCode } from 'src/helpers'
import {
  AFFILIATE_BRANDING,
  sendoutcardsPromotionsGenealogyId,
  sendoutcardsPromotionsUserId,
} from 'src/app/constants'
import { parseError } from 'src/utils/parseError'
import {
  AccountFragment,
  AccountInput,
  CreateAffiliateMutationVariables,
  CreateFreeAffiliateMutationVariables,
  UserType,
} from 'src/graphql/generated/graphql'
import {
  useCreateAccount,
  useCreateAffiliate,
  useCreateFreeAffiliate,
  useCreateUserLoginLink,
} from 'src/react_query/mutations/hooks'
import {
  useAccountCreatedContents,
  useMarketingContent,
  useSponsor,
  useUsers,
} from 'src/react_query'

const sponsorSelectionCopy = {
  title: 'Who showed you this product?',
  message: (clientName: string) =>
    `We celebrate and give back to the people who invite others to experience ${clientName}. Let us know who showed you our products and we will make sure they are given the credit they deserve!`,
}

interface TitleInfo {
  title: String
  step?: String
}

const buildTitleText = (title: TitleInfo) => {
  return (
    <Div outset={{ left: 'x1' }}>
      <HStack justify="flex-start" gap="x1" shouldWrap={true}>
        <Text type="largeBody" weight="semiBold" color="primaryHeading">
          {title.title}
          {title.step && ':'}
        </Text>
        {title.step && (
          <Text type="body" weight="regular" isItalic={true}>
            {title.step}
          </Text>
        )}
      </HStack>
    </Div>
  )
}

const usernameTakenCopy = {
  title: 'This Username is Taken',
  message:
    'If you have an existing SendOutCards login, use the same username and password to sign in to any of our stream websites.',
}

const phoneNumberCollisionCopy = {
  title: 'Phone Number Already Exists',
  message:
    'Because we use a single-sign-in process you only need one account and login for all of our streams. We ask for your phone number to ensure duplicate accounts are not being created. You can also use your phone number to log in instead of your username and password if you’d prefer.',
}

const styles = {
  accountFormWrapper: {
    maxHeight: 'initial',
    '@media (max-height: 860px) and (min-width: 769px)': {
      overflowY: 'auto' as const,
      maxHeight: '60vh',
    },
  },
}

interface Props {
  onAfterSave?: () => void
  onToggleJoinLogin?: (name: LoginJoinForgot) => void
  isAffiliateJoining?: boolean
}

interface CreateAccountFormState {
  email: String
  username: String
  password: String
}

interface SponsorInfo {
  userId: string | undefined
  genealogyId: number | undefined
  slug: string | undefined
}

type AffiliateJoinSteps =
  | 'createAccount'
  | 'affiliateAddress'
  | 'identificationNumber'
  | 'verifyEmail'
  | 'paymentMethod'

const CreateAccountForm: React.FC<Props> = props => {
  const { onAfterSave, onToggleJoinLogin, isAffiliateJoining } = props
  const { isMobile } = useSelector(state => state.window)

  const account = useSelector(state => state.user.account)
  const queryParams = useQueryParams()
  const vertical = useVertical()
  const clearSearchParams = useRemoveSearchParams()
  const recaptchaRef = useRef<ReCAPTCHA>(null)

  const [details, setDetails] = useState({
    email: account?.email ?? '',
    username: account?.username ?? '',
    password: '',
  })

  const [accountInput, setAccountInput] = useState<AccountInput | null>(null)
  const [validationFailure, setValidationFailure] = useState<ValidationError>()

  const [isTransitionDialogOpen, setIsTransitionDialogOpen] = useState(false)
  const [transition, setTransition] = useState<TransitionProps>({
    title: '',
    isLoading: false,
  })

  const [isCheckingPhoneNumber, setIsCheckingPhoneNumber] = useState(false)

  const [isPickingSponsor, setIsPickingSponsor] = useState(false)
  const [isDoingCCDetails, setIsDoingCCDetails] = useState(false)
  const [shouldVerifyAccount, setShouldVerifyAccount] = useState(false)
  const [shouldProceedCreateAcc, setShouldProceedCreateAcc] = useState(false)

  const [sponsorInfo, setSponsorInfo] = useState<SponsorInfo>()

  const sponsorInfoFromURL =
    !account && queryParams.sponsor ? queryParams.sponsor : undefined

  const { data: fetchedSponsor } = useSponsor(
    { id: sponsorInfo?.userId ?? sendoutcardsPromotionsUserId },
    !!sponsorInfo &&
      !!sponsorInfo.userId &&
      sponsorInfo.userId !== sendoutcardsPromotionsUserId,
  )

  const { data: sponsors } = useUsers(
    {
      search: sponsorInfoFromURL ?? '',
      offset: 0,
      limit: 10,
      isAffiliate: false, // includes RC types
    },
    !!sponsorInfoFromURL && !sponsorInfo,
  )

  const {
    data: marketingCopy,
    isLoading: isLoadingMarketingContent,
  } = useMarketingContent()

  const {
    data: accountCreatedContents,
    isLoading: isLoadingAccountCreatedContents,
  } = useAccountCreatedContents()

  const phoneNumberCountriesAll = usePhoneNumberCountries()

  const sponsorFragment =
    Array.isArray(sponsors) && sponsors.length > 0
      ? sponsors[0]
      : fetchedSponsor

  const [affiliateStep, setAffiliateStep] = useState<AffiliateJoinSteps>(
    'createAccount',
  )
  const [createdAccount, setCreatedAccount] = useState<AccountFragment>()
  const [isWelcomeMessageOpen, setIsWelcomeMessageOpen] = useState(false)

  const [affiliateFormInfo, setAffiliateFormInfo] = useState<AffiliateFormInfo>(
    {
      user: {
        firstName: '',
        lastName: '',
        company: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
        profileImage: '',
      },
      govId1: '',
      govId2: '',
      phoneNumber: '',
      accepted: false,
      purchased: false,
    },
  )

  // These are for Promptings Gratitude Consultant join only, will need ALL phoneNumberCountries for all other applications
  const phoneNumberCountriesLimited: PhoneNumberCountries[] = [
    {
      __typename: 'PhoneNumberCountry',
      callingCode: 61,
      isoCode: 'AU',
      name: 'Australia',
    },
    {
      __typename: 'PhoneNumberCountry',
      callingCode: 1,
      isoCode: 'CA',
      name: 'Canada',
    },
    {
      __typename: 'PhoneNumberCountry',
      callingCode: 353,
      isoCode: 'IE',
      name: 'Ireland',
    },
    {
      __typename: 'PhoneNumberCountry',
      callingCode: 64,
      isoCode: 'NZ',
      name: 'New Zealand',
    },
    {
      __typename: 'PhoneNumberCountry',
      callingCode: 1,
      isoCode: 'US',
      name: 'United States',
    },
    {
      __typename: 'PhoneNumberCountry',
      callingCode: 44,
      isoCode: 'GB',
      name: 'United Kingdom',
    },
  ]

  const phoneNumberCountries =
    vertical.id === 'promptings'
      ? phoneNumberCountriesLimited
      : phoneNumberCountriesAll

  const [selectedCountryIndex, setSelectedCountryIndex] = useState(
    phoneNumberCountries.findIndex(country => country.isoCode === 'US'),
  )
  const [isCountrySelectedManually, setIsCountrySelectedManually] = useState<
    boolean
  >(false)

  const [phoneNumber, setPhoneNumber] = useState('')

  const selectedCountry = phoneNumberCountries[selectedCountryIndex]
  const selectedCountryCode = selectedCountry.isoCode
  const formattedNumber = getFormattedNumber(selectedCountryCode, phoneNumber)
  const validatedNumber = getValidatedNumber(selectedCountryCode, phoneNumber)

  const onSelectedSponsor = (id: string) => {
    setSponsorInfo({ userId: id, genealogyId: undefined, slug: undefined })
    setAccountInput({ ...accountInput, sponsor: id })
    setIsPickingSponsor(false)
  }

  const [isPhoneNumberTaken, setIsPhoneNumberTaken] = useState(false)
  const [isSaving, setIsSaving] = useState(0)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isUsernameTakenModalOpen, setIsUsernameTakenModalOpen] = useState(
    false,
  )
  const [
    isRedirectTransitionVisible,
    setIsRedirectTransitionVisible,
  ] = useState(false)
  const [
    isPhoneNumberCollisionModalOpen,
    setIsPhoneNumberCollisionModalOpen,
  ] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()

  const mutations = useMutations()
  const { mutateAsync: createAccount } = useCreateAccount()
  const createAffiliateMutation = useCreateAffiliate()
  const createFreeAffiliateMutation = useCreateFreeAffiliate()
  const createUserLoginLinkMutation = useCreateUserLoginLink()

  const actions = useActions()
  useEffect(() => {
    setSponsorInfo({
      userId: sponsorFragment?.id,
      genealogyId: sponsorFragment?.genealogyId,
      slug: sponsorFragment?.storefront?.slug
        ? sponsorFragment.storefront.slug
        : undefined,
    })
  }, [setSponsorInfo, sponsorFragment])

  const debouncedUsername = useDebounce(details.username, 500)
  const usernameValidation = useUsernameValidation(debouncedUsername)

  useEffect(() => {
    const updateCountryCode = async () => {
      for await (const countryCode of getMachineCountryCode()) {
        const index = phoneNumberCountries.findIndex(country => {
          return country.isoCode === countryCode
        })
        if (index !== -1 && !isCountrySelectedManually) {
          setSelectedCountryIndex(index)
        }
      }
    }
    if (!isCountrySelectedManually) {
      updateCountryCode()
    }
  }, [phoneNumberCountries, isCountrySelectedManually])

  useEffect(() => {
    setIsPhoneNumberTaken(false)
  }, [phoneNumber, selectedCountry])

  const debouncedPhoneNumber = useDebounce(phoneNumber, 500)
  const phoneNumberInput = createPhoneNumberInput(
    debouncedPhoneNumber,
    selectedCountry,
  )
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    let mutableCanceling = false
    const isPhoneNumberPristine =
      account && phoneNumberInput.nationalNumber === account.phoneNumber

    if (phoneNumberInput.nationalNumber && !isPhoneNumberPristine) {
      const validate = async () => {
        setIsCheckingPhoneNumber(true)

        const {
          validatePhoneNumber: { isValid },
        } = await mutations.validatePhoneNumber({
          phoneNumber: phoneNumberInput,
          doNotShowDefaultTransition: true,
        })
        setIsCheckingPhoneNumber(false)
        if (mutableCanceling) {
          return
        }
        setIsPhoneNumberTaken(!isValid)
      }
      validate()
    }

    return () => {
      mutableCanceling = true
    }
  }, [account, mutations, selectedCountry, phoneNumberInput])

  const debouncedEmail = useDebounce(details.email, 500)
  const emailValidation = useEmailValidation(debouncedEmail)

  const isDisabled =
    isSaving > 0 ||
    !details.username ||
    !details.password ||
    !details.email ||
    isPhoneNumberTaken ||
    details.email !== debouncedEmail ||
    !emailValidation.isValid ||
    emailValidation.isLoading ||
    details.username !== debouncedUsername ||
    !usernameValidation.isValid ||
    usernameValidation.isLoading ||
    (!validatedNumber && !queryParams.bulkOrderPromo) ||
    isCheckingPhoneNumber ||
    (queryParams.payment && !affiliateFormInfo.stripeToken)

  const createAccountTitle =
    affiliateStep === 'createAccount'
      ? { title: 'Set Up Your Account' }
      : affiliateStep === 'affiliateAddress'
      ? { title: 'Set Up Your Account', step: 'Profile Info' }
      : affiliateStep === 'identificationNumber'
      ? { title: 'Start Your Business', step: 'Tax Info' }
      : { title: 'Start Your Business', step: 'Payment Info' }

  const handleChange = (name: keyof CreateAccountFormState, value: string) => {
    const sanitizedValue = name === 'username' ? sanitizeUsername(value) : value
    setDetails({ ...details, [name]: sanitizedValue })
  }
  const hasAllRequiredFields = (info: AffiliateFormInfo): boolean => {
    return !(!details.username ||
    !details.password ||
    !details.email ||
    !phoneNumber ||
    !info.user.firstName ||
    !info.user.lastName ||
    !info.user.address1 ||
    !info.user.postalCode ||
    !info.user.city ||
    !info.user.state ||
    !info.user.country ||
    !info.govId1 ||
    !info.accepted ||
    IS_AFFILIATE_FREE
      ? true
      : !info.stripeToken)
  }

  const createAffiliate = async (
    variables:
      | CreateAffiliateMutationVariables
      | CreateFreeAffiliateMutationVariables,
  ) => {
    if (IS_AFFILIATE_FREE) {
      const res = await createFreeAffiliateMutation.mutateAsync(variables)
      return {
        account: res.createFreeAffiliate.account,
        error: res.createFreeAffiliate.error,
        validation: undefined,
      }
    } else {
      const res = await createAffiliateMutation.mutateAsync(variables)

      return {
        account: res?.createDistributor.account,
        error: res?.createDistributor.error,
        validation: res?.createDistributor.validation,
      }
    }
  }

  const onSubmitAffiliateJoin = async (info: AffiliateFormInfo) => {
    if (
      usernameValidation.isLoading ||
      emailValidation.isLoading ||
      isCheckingPhoneNumber
    )
      return

    setAffiliateFormInfo(info)

    if (!sponsorInfo?.userId) {
      setIsPickingSponsor(true)
      return
    }

    if (isAffiliateJoining) {
      switch (affiliateStep) {
        case 'createAccount':
          setAffiliateStep('affiliateAddress')
          return
        case 'affiliateAddress':
          setAffiliateStep('identificationNumber')
          return
        case 'identificationNumber':
          setAffiliateStep('paymentMethod')
          return
        case 'paymentMethod':
          if (!info.verificationCode) {
            setShouldVerifyAccount(true)
            return
          } else {
            setShouldVerifyAccount(false)
          }

          if (!hasAllRequiredFields(info)) {
            return
          }
          setIsSaving(x => x + 1)

          const accountInput: AccountInput = {
            ...details,
            sponsor: sponsorInfo?.userId,
            firstName: info.user.firstName,
            lastName: info.user.lastName,
            shippingAddress: { ...info.user },
            phoneNumber: `${selectedCountry.callingCode} ${phoneNumber}`,
            sponsorGenealogyId: sponsorInfo
              ? sponsorInfo.genealogyId?.toString()
              : String(sendoutcardsPromotionsGenealogyId),
            stripeToken: info.stripeToken?.id,
            profileImage: info.user.profileImage,
          }

          try {
            const { govId1, govId2 } = info
            const args = {
              account: {
                ...accountInput,
              },
              govId1,
              govId2,
              verticalId: vertical.graphqlVerticalID,
              verificationCode: info.verificationCode,
              queryParams: {
                redirect_uri: queryParams.redirect_uri,
              },
            }

            const { account, error, validation } = await createAffiliate(args)

            if (
              validation &&
              validation.__typename === 'ValidateEmailVerificationCodeFailure'
            ) {
              setValidationFailure({
                type: validation.failureType,
                message: validation.message,
              })
              setIsTransitionDialogOpen(false)
              setShouldVerifyAccount(true)
            }

            if (account) {
              setCreatedAccount(account)
              actions.saveToken(account.token)
              setIsWelcomeMessageOpen(true)
            } else {
              setErrorMessage(
                error ??
                  `Failed to create ${AFFILIATE_BRANDING.lowerCase} account.`,
              )
            }
          } catch (error) {
            if (error instanceof Error)
              if (error.message === '0') {
                setErrorMessage(
                  `The SSN value ${info.govId1} already exists in our system. Please go back and log in to your existing ${AFFILIATE_BRANDING.lowerCase} account. If you need further assistance, please contact support.`,
                )
              } else {
                setErrorMessage(
                  error?.message ??
                    `Failed to create ${AFFILIATE_BRANDING.lowerCase} account.`,
                )
              }
          } finally {
            setIsSaving(x => x - 1)
          }
          return
      }
    }
  }

  const onSubmit = () => {
    if (
      !usernameValidation.isValid ||
      usernameValidation.isLoading ||
      isCheckingPhoneNumber ||
      emailValidation.isLoading ||
      !emailValidation.isValid
    )
      return

    setAccountInput({
      ...details,
      sponsor: sponsorInfo?.userId,
      firstName: '',
      lastName: '',
      phoneNumber: `${selectedCountry.callingCode} ${phoneNumber}`,
      sponsorGenealogyId: sponsorInfo
        ? sponsorInfo.genealogyId?.toString()
        : String(sendoutcardsPromotionsGenealogyId),
      stripeToken: affiliateFormInfo.stripeToken?.id,
    })

    if (!sponsorInfo?.userId) {
      setIsPickingSponsor(true)
      setShouldProceedCreateAcc(true)
      return
    }

    if (queryParams.payment && !affiliateFormInfo.stripeToken) {
      setErrorMessage('Payment method is required.')
      return
    }

    setShouldVerifyAccount(true)
  }

  const onCreateAccount = async (code: string) => {
    setShouldVerifyAccount(false)
    setValidationFailure(undefined)
    setShouldProceedCreateAcc(false)

    const token = process.env.REACT_APP_RECAPTCHA_KEY
      ? await recaptchaRef?.current?.executeAsync()
      : undefined

    setIsTransitionDialogOpen(true)
    setTransition({
      title: 'Verifying & Creating Account...',
      isLoading: true,
    })

    if (!accountInput) {
      setTransition({
        title: 'No Account Details Provided!',
        isLoading: false,
        loadedStatus: 'error',
      })
    } else {
      setIsSaving(x => x + 1)
      try {
        const { account, validation } = (
          await createAccount({
            account: accountInput,
            verificationCode: code,
            verticalId: vertical.graphqlVerticalID,
            recaptchaToken: token,
          })
        ).createAccount
        if (
          validation &&
          validation.__typename === 'ValidateEmailVerificationCodeFailure'
        ) {
          setValidationFailure({
            type: validation.failureType,
            message: validation.message,
          })
          setIsTransitionDialogOpen(false)
          setShouldVerifyAccount(true)
        } else {
          if (account) {
            setCreatedAccount(account)
            if (queryParams.isJoiningPromo || queryParams.postcardPromo) {
              actions.loginDone(Result(account))
            } else {
              setIsWelcomeMessageOpen(true)
            }
            if (queryParams.goToPricing) {
              actions.openPricing()
              actions.clearRouteArgs()
              clearSearchParams()
            }
          }
          setTransition({
            title: 'Account was Successfully created',
            isLoading: false,
            loadedStatus: 'success',
          })
        }
      } catch (err) {
        setTransition({
          title: parseError(err),
          isLoading: false,
          loadedStatus: 'error',
        })
      } finally {
        recaptchaRef.current?.reset()
        setIsSaving(x => x - 1)
        setTimeout(() => {
          setIsTransitionDialogOpen(false)
        }, 2000)
      }
    }
  }

  const onWelcomeMessageFinish = () => {
    if (!createdAccount) return
    const shouldGoToByDesign =
      queryParams.shoppingCartCategory || queryParams.shoppingCartProduct
    if (!shouldGoToByDesign) {
      actions.loginDone(Result(createdAccount))
    }

    if (onAfterSave) {
      onAfterSave()
    }

    setDetails(x => ({ ...x, password: '' }))
    queryParams.join
      ? actions.loginDone(Result(createdAccount))
      : queryParams.bulkOrderPromo
      ? actions.openPromo()
      : queryParams.selected
      ? actions.openPricing()
      : actions.openAccount()
  }

  const onNonRedirectJoinFlowFinish = async (shouldGoToAccount?: boolean) => {
    if (!createdAccount) return
    if (shouldGoToAccount) {
      actions.loginDone(Result(createdAccount))
    } else {
      const {
        createUserLoginLink: { link },
      } = await createUserLoginLinkMutation.mutateAsync({
        verticalId: vertical.graphqlVerticalID,
        queryParams: {
          redirect_uri:
            'https%3A%2F%2Fshop.bydesign.com%2FSendOutCards%2F%23%2Fseamless%2F',
        },
      })

      if (link) {
        const iframe = document.createElement('iframe')
        // eslint-disable-next-line
        iframe.src = link // tslint:disable-line
        document.body.appendChild(iframe)

        setIsRedirectTransitionVisible(true)
        setTimeout(() => {
          // New Starter Package Category URL: 69
          window.location.href = buildShoppingCartURL(
            '69',
            undefined,
            createdAccount.sponsor?.genealogyId,
          )
        }, 5000)
      } else {
        window.location.href = buildShoppingCartURL(
          '69',
          undefined,
          createdAccount.sponsor?.genealogyId,
        )
      }
    }
  }

  if (isLoadingMarketingContent || isLoadingAccountCreatedContents) {
    return <Transition />
  }

  return (
    <>
      {isTransitionDialogOpen && (
        <Dialog isOpen={isTransitionDialogOpen}>
          <QDSTransition {...transition} />
        </Dialog>
      )}
      {shouldVerifyAccount && (
        <VerifyClient
          onClose={() => {
            if (isAffiliateJoining) setAffiliateStep('identificationNumber')
            setShouldVerifyAccount(false)
          }}
          email={details?.email}
          confirm={{
            onSubmit: async code => {
              isAffiliateJoining
                ? onSubmitAffiliateJoin({
                    ...affiliateFormInfo,
                    verificationCode: code,
                  })
                : onCreateAccount(code)
            },
          }}
          initialStep={
            validationFailure ? EmailValidationStep.ValidationError : undefined
          }
          validationFailure={validationFailure}
        />
      )}
      {isWelcomeMessageOpen ? (
        isRedirectTransitionVisible ? (
          <Transition
            message="Redirecting..."
            css={{ width: '100%', height: '100%' }}
          />
        ) : queryParams.isJoiningAffiliate &&
          createdAccount &&
          createdAccount.type === UserType.Fgsa ? (
          <AffiliateJoinSuccess
            createdAccount={createdAccount}
            isFreeAffiliate={true}
          />
        ) : queryParams.isJoiningAffiliate ? (
          <div>
            <Text type="largeBody" weight="semiBold" color="primaryHeading">
              Congratulations! Your Business has been activated
            </Text>
            <Spacer space="x2" />
            <Text type="body">
              {queryParams.isNewJoiningAffiliateFlow
                ? "Now let's complete the process and purchase your Starter Package."
                : 'If you would like to see some of our favorite packages to get started, please click below.'}
            </Text>
            <Spacer space="x4" />
            <Button
              fullWidth={true}
              onClick={() =>
                queryParams.isNewJoiningAffiliateFlow
                  ? onWelcomeMessageFinish()
                  : onNonRedirectJoinFlowFinish(false)
              }
              title={
                queryParams.isNewJoiningAffiliateFlow
                  ? 'Get My Package'
                  : 'See Favorite Packages'
              }
            />
            {!queryParams.isNewJoiningAffiliateFlow && (
              <>
                <Spacer space="x2" />
                <Button
                  fullWidth={true}
                  onClick={() => onNonRedirectJoinFlowFinish(true)}
                  type="shadow"
                  title="Or Continue to My Account"
                />
              </>
            )}
          </div>
        ) : (
          <div>
            <Text type="subtitle">
              {accountCreatedContents!.accountCreatedTitle.content}
            </Text>
            <Spacer space="x2" />
            <Text type="body" style={{ whiteSpace: 'pre-line' }}>
              {accountCreatedContents!.accountCreatedBody.content}
            </Text>
            <Spacer space="x6" />
            <Button
              fullWidth={true}
              onClick={onWelcomeMessageFinish}
              title="Finish"
            />
          </div>
        )
      ) : isPickingSponsor ? (
        <FindMySponsor
          onSelectedSponsor={id => {
            onSelectedSponsor(id)
            if (!isAffiliateJoining && shouldProceedCreateAcc) {
              setShouldVerifyAccount(true)
            }
          }}
          isAffiliateJoining={isAffiliateJoining}
        />
      ) : isDoingCCDetails ? (
        <PaymentMethod
          shouldHideAffiliateFields={true}
          affiliateInfo={affiliateFormInfo}
          onFinishAccount={info =>
            setAffiliateFormInfo({ ...affiliateFormInfo, ...info })
          }
          onBack={info => {
            setAffiliateFormInfo(info)
            setIsDoingCCDetails(false)
          }}
        />
      ) : (
        <Div css={styles.accountFormWrapper}>
          {!account &&
            (!isAffiliateJoining ? (
              <div>
                <Flex
                  style={{
                    textAlign: isMobile ? 'center' : undefined,
                  }}
                  flexDirection={isMobile ? 'column' : 'row'}
                  justifyContent="flex-start"
                  alignItems="center"
                  rowGap="x1"
                  columnGap="x1"
                  data-mktcontent={
                    queryParams.bulkOrderPromo
                      ? 'bulkPromoJoinMessage'
                      : 'createAccountMessage'
                  }
                >
                  {vertical.id === 'soc' && !isMobile ? (
                    <Icon
                      icon="SOCLOGO"
                      color="blueViolet"
                      size={50}
                      style={{ margin: '0px 0px 8px 0px' }}
                    />
                  ) : vertical.brand ? (
                    <Logo brand={vertical.brand} color="color" width="240px" />
                  ) : null}
                  <Text
                    type={'subtitle'}
                    outset={{ vertical: isMobile ? 'x1' : undefined }}
                    weight="bold"
                    whiteSpace="nowrap"
                  >
                    {queryParams.bulkOrderPromo
                      ? marketingCopy!.bulkPromoJoinTitle.content
                      : 'Create an Account'}
                  </Text>
                </Flex>
                <Text
                  type="caption"
                  alignment={isMobile ? 'center' : 'left'}
                  inset={{ bottom: 'x1' }}
                >
                  {queryParams.bulkOrderPromo
                    ? marketingCopy!.bulkPromoJoinMessage.content
                    : marketingCopy!.createAccountMessage.content}
                </Text>
                <Spacer space="x1" />
              </div>
            ) : (
              <>
                {buildTitleText(createAccountTitle)}
                {isMobile ? <Spacer space="x1" /> : <Spacer space="x2" />}
              </>
            ))}
          {affiliateStep === 'createAccount' && (
            <form>
              <Flex width="100%" flexDirection="column">
                <Input
                  onChange={email => handleChange('email', email)}
                  type="email"
                  name="email"
                  value={details.email}
                  icon="envelope"
                  placeholder="Email"
                  focusColor="primaryBrand"
                  isFullWidth={true}
                  id="email_input"
                  message={
                    emailValidation.errorMessage && details.email
                      ? {
                          type: 'danger',
                          content: emailValidation.errorMessage,
                          icon: 'information',
                        }
                      : undefined
                  }
                  isLoading={emailValidation.isLoading}
                />
                <Spacer space={'x1'} />
                <Input
                  onChange={username => handleChange('username', username)}
                  type="text"
                  name="username"
                  value={details.username}
                  icon="user"
                  placeholder="Username"
                  focusColor="primaryBrand"
                  message={
                    !usernameValidation.isValid &&
                    !!details.username &&
                    !usernameValidation.isLoading
                      ? {
                          type: 'danger',
                          content: 'This username is taken',
                          icon: 'information',
                          onClick: () => setIsUsernameTakenModalOpen(true),
                        }
                      : undefined
                  }
                  isFullWidth={true}
                  isLoading={usernameValidation.isLoading}
                  id="username_input"
                />
                <Text
                  type="footnote"
                  content={'Usernames are case sensitive'}
                  outset="x1"
                />
                <Spacer space={'x1'} />
                <Input
                  onChange={password => handleChange('password', password)}
                  type="password"
                  name="password"
                  value={details.password}
                  icon="lock"
                  placeholder="Password"
                  focusColor="primaryBrand"
                  isFullWidth={true}
                  id="password_input"
                />
                <Spacer space={'x1'} />
                {!queryParams.bulkOrderPromo && (
                  <div id="phone_input">
                    <PhoneInput
                      countries={phoneNumberCountries}
                      selectedCountryIndex={selectedCountryIndex}
                      onSelectedCountryIndexChange={index => {
                        setIsCountrySelectedManually(true)
                        setSelectedCountryIndex(index)
                      }}
                      phoneNumber={formattedNumber}
                      onPhoneNumberChange={setPhoneNumber}
                      maxHeight="125px"
                      message={
                        !validatedNumber && debouncedPhoneNumber !== ''
                          ? {
                              type: 'danger',
                              content: 'Please enter a valid phone number.',
                            }
                          : isPhoneNumberTaken
                          ? {
                              type: 'danger',
                              content:
                                'This phone number exists on another account. Please log in with those account credentials.',
                              icon: 'information',
                              onClick: () =>
                                setIsPhoneNumberCollisionModalOpen(true),
                            }
                          : undefined
                      }
                    />
                  </div>
                )}
                <Spacer space={isPhoneNumberTaken ? 'x5' : 'x1'} />
                {queryParams.payment && (
                  <Card
                    width="100%"
                    borderRadius="medium"
                    inset="x2"
                    cursor="pointer"
                  >
                    <Flex
                      width="100%"
                      justifyContent="space-between"
                      alignItems="center"
                      onClick={() => setIsDoingCCDetails(true)}
                    >
                      <Flex alignItems="center">
                        <Span
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          inset={{ right: 'x1' }}
                        >
                          <div
                            style={{
                              backgroundImage: `linear-gradient(115deg, #c7c7c7, #878787)`,
                            }}
                          >
                            <CreditCardLogo
                              brand={creditCardBrand(
                                affiliateFormInfo.stripeToken?.card?.brand ??
                                  '',
                              )}
                            />
                          </div>
                        </Span>
                        <InfoBlock
                          size="small"
                          heading={
                            affiliateFormInfo.stripeToken?.id
                              ? `${
                                  affiliateFormInfo.stripeToken.card?.last4
                                    ? `XXXX XXXX XXXX ${affiliateFormInfo.stripeToken.card?.last4}`
                                    : 'Credit Card'
                                }`
                              : 'Payment Source'
                          }
                          caption={
                            affiliateFormInfo.stripeToken?.id
                              ? `${affiliateFormInfo.stripeToken.card?.brand}`
                              : 'Required'
                          }
                        />
                      </Flex>
                      <span
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignContent: 'center',
                        }}
                      >
                        <QDSIcon
                          name="information"
                          primaryColor="primaryHeadingText"
                          size="small"
                        />
                      </span>
                    </Flex>
                  </Card>
                )}
                <Spacer space={'x1'} />
                {sponsorFragment &&
                sponsorInfo?.genealogyId !==
                  sendoutcardsPromotionsGenealogyId ? (
                  <SponsorCard
                    sponsor={sponsorFragment as SponsorFragment}
                    onClick={() => setIsPickingSponsor(true)}
                    isActive={true}
                  />
                ) : (
                  <Card
                    width="100%"
                    borderRadius="medium"
                    inset="x2"
                    cursor="pointer"
                  >
                    <Flex
                      width="100%"
                      justifyContent="space-between"
                      alignItems="center"
                      onClick={() => setIsPickingSponsor(true)}
                    >
                      <Flex alignItems="center">
                        <Span
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          inset={{ right: 'x1' }}
                        >
                          <QDSIcon
                            name="userSuccess"
                            primaryColor="default"
                            secondaryColor="background"
                            size="medium"
                          />
                        </Span>
                        <InfoBlock
                          size="small"
                          heading={
                            isAffiliateJoining
                              ? 'Who introduced you to our products?'
                              : 'Who showed you this product?'
                          }
                          caption="Optional"
                        />
                      </Flex>
                      <span
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignContent: 'center',
                        }}
                        onClick={e => {
                          e.stopPropagation()
                          setIsInfoModalOpen(true)
                        }}
                      >
                        <QDSIcon
                          name="information"
                          primaryColor="primaryHeadingText"
                          size="small"
                        />
                      </span>
                    </Flex>
                  </Card>
                )}
              </Flex>

              <Flex width="100%" flexDirection="column">
                <Spacer space={isMobile ? 'x1' : 'x4'} />
                {process.env.REACT_APP_RECAPTCHA_KEY && (
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                  />
                )}
                <Div width="100%" id="create_my_account_btn">
                  <Button
                    fullWidth={true}
                    type={'primary'}
                    onClick={
                      isAffiliateJoining
                        ? () => onSubmitAffiliateJoin(affiliateFormInfo)
                        : () => onSubmit()
                    }
                    title={
                      isSaving
                        ? 'Creating account...'
                        : isAffiliateJoining
                        ? 'Continue'
                        : 'Create My Account'
                    }
                    disabled={isDisabled}
                  />
                </Div>
                {errorMessage !== undefined && (
                  <Text type="caption" color="danger">
                    {errorMessage}
                  </Text>
                )}
              </Flex>
              <Spacer space="x2" />
              <Div width="100%">
                <Separator
                  orientation="horizontal"
                  dashed={true}
                  caption="Or"
                />
              </Div>
              <Spacer space="x2" />
              <Flex width="100%" flexDirection="column">
                {vertical.id === 'soc' &&
                  !queryParams.isJoiningPromo &&
                  !queryParams.bulkOrderPromo && (
                    <>
                      <Div width="100%" id="send_a_free_card_btn">
                        <Button
                          hover={true}
                          fullWidth={true}
                          title="Send Your First Card Free"
                          onClick={() => {
                            actions.openPhoneNumberVerification()
                          }}
                          type="secondary"
                        />
                      </Div>
                      <Spacer space="x2" />
                    </>
                  )}

                {onToggleJoinLogin && (
                  <>
                    {isAffiliateJoining && (
                      <div>
                        <Text type="caption" weight="thin">
                          {marketingCopy!.affiliateLoginMessage.content}
                        </Text>
                        <Spacer space="x2" />
                      </div>
                    )}
                    <Div width="100%" id="log_in_existing_account_btn">
                      <Button
                        hover={true}
                        type="secondary"
                        outlined={true}
                        onClick={() => onToggleJoinLogin?.('login')}
                        fullWidth={true}
                        title="Already a User"
                      />
                    </Div>
                  </>
                )}
              </Flex>
              <ConfirmDialog
                zIndex={1}
                isOpen={isInfoModalOpen}
                title={sponsorSelectionCopy.title}
                description={sponsorSelectionCopy.message(vertical.name)}
                accept={{
                  title: 'Got it',
                  onClick: () => setIsInfoModalOpen(false),
                }}
                primaryAction={'accept'}
                onClose={() => setIsInfoModalOpen(false)}
              />
              <ConfirmDialog
                zIndex={1}
                isOpen={isUsernameTakenModalOpen}
                title={usernameTakenCopy.title}
                description={usernameTakenCopy.message}
                accept={{
                  title: 'Got it',
                  onClick: () => setIsUsernameTakenModalOpen(false),
                }}
                primaryAction={'accept'}
                onClose={() => setIsUsernameTakenModalOpen(false)}
              />
              <ConfirmDialog
                zIndex={1}
                isOpen={isPhoneNumberCollisionModalOpen}
                title={phoneNumberCollisionCopy.title}
                description={phoneNumberCollisionCopy.message}
                accept={{
                  title: 'Got it',
                  onClick: () => setIsPhoneNumberCollisionModalOpen(false),
                }}
                primaryAction={'accept'}
                onClose={() => setIsPhoneNumberCollisionModalOpen(false)}
              />
            </form>
          )}
          {affiliateStep === 'affiliateAddress' && (
            <AffiliateAddress
              affiliateInfo={affiliateFormInfo}
              onFinishAccount={affiliateInfo => {
                onSubmitAffiliateJoin(affiliateInfo)
              }}
              onBack={() => {
                setAffiliateStep('createAccount')
              }}
            />
          )}
          {affiliateStep === 'identificationNumber' && (
            <IdenfiticationNumber
              affiliateInfo={affiliateFormInfo}
              onFinishAccount={affiliateInfo =>
                onSubmitAffiliateJoin(affiliateInfo)
              }
              onBack={info => {
                setAffiliateFormInfo(info)
                setAffiliateStep('affiliateAddress')
              }}
            />
          )}
          {affiliateStep === 'paymentMethod' && (
            <PaymentMethod
              affiliateInfo={affiliateFormInfo}
              onFinishAccount={affiliateInfo => {
                onSubmitAffiliateJoin(affiliateInfo)
              }}
              onBack={info => {
                setAffiliateFormInfo(info)
                setAffiliateStep('identificationNumber')
              }}
              isFreeAffiliateSignup={false}
            />
          )}
          <ConfirmDialog
            zIndex={1}
            isOpen={!!errorMessage}
            title="Failed to Join"
            description={errorMessage ?? ''}
            accept={{
              title: 'OK',
              onClick: () => {
                window.location.href =
                  'https://app.sendoutcards.com/dashboard/account?client=promptings'
              },
            }}
          />
        </Div>
      )}
    </>
  )
}

export default CreateAccountForm
