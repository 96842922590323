import React, { useRef } from 'react'
import xor from 'lodash/xor'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import TextField from '@material-ui/core/TextField'

import { getInfiniteMinimalContacts } from 'src/legacy_graphql'
import { Button, Icon } from 'src/chrome'
import { Group } from 'src/contacts/contactTypes'
import { useQueries, useScroll, useState } from 'src/hooks'

import GroupSelect from '../GroupSelect/GroupSelect'
import styles from './groupAddCreate.module.scss'
import { CircularProgress } from '@material-ui/core'
import { Checkbox, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import { Button as DSButton } from 'src/design_system/molecules/button/Button'

type GroupAddCreateProps = {
  initialMode: 'create' | 'select'
  contactsIds?: string[]
  groups?: Group[]
  onClose: () => void
  onCreateGroup?: (group: Omit<Group, 'id'>) => void
  onSelect?: (group: Group, newMembers: string[]) => void
  isModeToggleOn?: boolean
}

const GroupAddCreate: React.FC<GroupAddCreateProps> = ({
  initialMode,
  contactsIds = [],
  groups = [],
  onClose,
  onCreateGroup,
  onSelect,
  isModeToggleOn = true,
}) => {
  const [mode, setMode] = useState(initialMode)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [selectedGroupId, setSelectedGroupId] = useState<string>()
  const [selectedContacts, setSelectedContacts] = useState<string[]>(
    initialMode === 'select' ? contactsIds : [],
  )
  const toggle = (id: string) => setSelectedContacts(x => xor(x, [id]))

  const [{ results: contacts, ...scrollOptions }] = useQueries(
    getInfiniteMinimalContacts({
      contacts: contactsIds,
      showSecondaryContacts: true,
    }),
  )

  const selectedGroup = groups.find(group => group.id === selectedGroupId)
  const triggerElement = useRef<HTMLDivElement>(null)
  const handleScroll = useScroll({
    ...scrollOptions,
    triggerElement,
  })

  const getNumberOfContactsSelected = () => {
    const contactList = selectedContacts
    const contactWord = contactList.length === 1 ? 'contact' : 'contacts'
    return `${contactList.length} ${contactWord} selected`
  }

  return (
    <div className={styles.container}>
      <span className={styles.close} onClick={() => onClose()}>
        <Icon icon={'CLOSE'} color={'#6f8394'} size={18} />
      </span>
      <div className={styles.body}>
        <div className={styles.selectorsBlock}>
          {onSelect && mode !== 'select' && isModeToggleOn && (
            <Button
              id={'create_or_select_group_btn'}
              title={'Select Group'}
              iconColor={'white'}
              iconSize={16}
              buttonColor={'pink'}
              style={{ marginBottom: 20 }}
              onClick={() => setMode('select')}
            />
          )}
          {onCreateGroup && mode !== 'create' && isModeToggleOn && (
            <DSButton
              id={'create_or_select_group_btn'}
              title={{
                content: 'Create Group',
                fontSize: '15px',
                fontColor: '#fff',
              }}
              borderRadius="10px"
              fill="#e841b3"
              onClick={() => setMode('create')}
              padding="10px 30px"
            />
          )}
          {mode === 'create' && (
            <div className={styles.newGroupContainer}>
              <Text type="largeBody" color="primaryHeading">
                New Group
              </Text>
              <Spacer space="x1" />
              <TextField
                id={'group_name_field'}
                label="Group Name"
                InputLabelProps={{
                  classes: {
                    root: styles.inputLabel,
                  },
                }}
                InputProps={{
                  classes: {
                    root: styles.input,
                  },
                }}
                value={name}
                onChange={e => setName(e.target.value)}
                className={styles.textField}
              />
              <TextField
                id={'group_description_field'}
                label="Description"
                InputLabelProps={{
                  classes: {
                    root: styles.inputLabel,
                  },
                }}
                InputProps={{
                  classes: {
                    root: styles.input,
                  },
                }}
                value={description}
                multiline={true}
                onChange={e => setDescription(e.target.value)}
                className={styles.textField}
              />
            </div>
          )}
          {mode === 'select' && (
            <div className={styles.groupSelectContainer}>
              <GroupSelect
                groups={groups}
                onGroupChange={group => setSelectedGroupId(group.id)}
                shouldHighlightSelected={true}
              />
            </div>
          )}
        </div>
        {isModeToggleOn && (
          <div className={styles.selectedContacts}>
            <Text type="body" color="primaryHeading" outset={{ bottom: 'x1' }}>
              {getNumberOfContactsSelected()}
            </Text>
            <List className={styles.contactSelector} onScroll={handleScroll}>
              {contacts.map(contact => {
                const isSelected = selectedContacts.includes(contact.id)
                return (
                  <div
                    onClick={e => {
                      e.preventDefault()
                      toggle(contact.id)
                    }}
                    key={contact.id}
                    className={styles.contactLi}
                    style={{
                      backgroundColor: `${isSelected ? '#e8e8e8' : 'initial'}`,
                    }}
                  >
                    <Checkbox
                      isChecked={selectedContacts.includes(contact.id)}
                      onChange={() => {}}
                      id={`GroupAddCreateContactSelectorLi-${contact.id}`}
                    />
                    <ListItem>
                      <ListItemText
                        primary={
                          <Text type="largeBody" style={{ userSelect: 'none' }}>
                            {contact.firstName} {contact.lastName}
                          </Text>
                        }
                      />
                    </ListItem>
                  </div>
                )
              })}
              {scrollOptions.hasMore && (
                <div
                  ref={triggerElement}
                  style={{ background: 'transparent', boxShadow: 'none' }}
                >
                  <CircularProgress />
                </div>
              )}
            </List>
          </div>
        )}
      </div>
      <div className={styles.actions}>
        {mode === 'select' && selectedGroup && (
          <Text type="caption" outset={{ right: 'x6' }}>
            Add contacts to group: {selectedGroup.name}
          </Text>
        )}
        {onCreateGroup && mode === 'create' && (
          <Button
            id="submit_new_group_btn"
            title="Submit"
            buttonColor="pink"
            disabled={!name}
            onClick={() =>
              onCreateGroup({ name, description, members: selectedContacts })
            }
          />
        )}
        {onSelect && mode === 'select' && (
          <DSButton
            id="submit_new_group_btn"
            title={{
              content: 'Submit',
              fontSize: '15px',
              fontColor: '#fff',
            }}
            borderRadius="10px"
            fill="#e841b3"
            onClick={
              selectedGroup && selectedContacts.length > 0
                ? () => onSelect(selectedGroup, selectedContacts)
                : undefined
            }
            padding="10px 30px"
          />
        )}
      </div>
    </div>
  )
}

export default GroupAddCreate
