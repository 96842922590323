import { CircularProgress } from '@material-ui/core'
import React from 'react'
import NotYourCard from 'src/assets/svg/NotYourCard'

// @src imports
import { EmptyState, Modal } from 'src/chrome'
import { getGift, GiftFragment } from 'src/legacy_graphql'
import { useActions, useQueryFutures, useSelector, useState } from 'src/hooks'

import GiftDetails from './GiftDetails'
import ModalFooter from './ModalFooter/ModalFooter'

type Props = {
  slug: string
  onClose: () => void
  addGift: (gift: GiftFragment) => void
  isChangingGift: boolean
}

type ContentKey = 'description' | 'contents' | 'packaging' | 'shipping'

const contentKeys: { [slideIndex: number]: ContentKey } = {
  0: 'description',
  1: 'contents',
  2: 'packaging',
  3: 'shipping',
}

const GiftModal: React.FC<Props> = props => {
  const [slideIndex, setSlideIndex] = useState(0)
  const { addGift, slug, onClose, isChangingGift } = props
  const actions = useActions()
  const isMobile = useSelector(state => state.window.isMobile)

  const [giftQuery] = useQueryFutures(getGift({ slug }))

  const gift: GiftFragment | undefined = giftQuery.value
  const error: Error | undefined = giftQuery.error

  return error ? (
    <EmptyState
      action={() => {
        onClose()
        actions.openGiftStore()
      }}
      actionTitle={'Find a new gift'}
      message={"Ooops, it looks like this gift doesn't exist"}
      artWorkWidth={'70%'}
      artWorkMaxWidth={600}
    >
      <NotYourCard />
    </EmptyState>
  ) : gift ? (
    <Modal
      title={gift.name}
      imageUrl={gift.defaultVariation?.defaultImageUrl ?? ''}
      bodyChildren={
        <GiftDetails
          content={gift.defaultVariation?.[contentKeys[slideIndex]] ?? ''}
        />
      }
      footerChildren={
        <ModalFooter
          gift={gift}
          index={slideIndex}
          handleChange={setSlideIndex}
          addGift={addGift}
          isMobile={isMobile}
          isChangingGift={isChangingGift}
        />
      }
      onClose={onClose}
      animationStyle={'fade'}
      mobileBodyStyles={{ maxHeight: '20vh' }}
      mobileContainerStyles={{
        height: 'calc(100vh - 58px)',
        top: 0,
        left: 0,
        transform: 'initial',
        overflow: 'auto',
      }}
    />
  ) : (
    <Modal
      title=""
      onClose={() => onClose()}
      bodyChildren={<CircularProgress />}
    />
  )
}

export default GiftModal
