import useSelector from './useSelector'

const useAccountHasGiftSends = () => {
  const {
    user: { account },
  } = useSelector(state => state)

  const hasAvailableGiftSends = account && account.giftSends > 0

  return {
    hasAvailableGiftSends,
  }
}

export default useAccountHasGiftSends
