import React from 'react'
import { MinimalCampaignFragment } from 'src/legacy_graphql'

import SelectCampaign from './SelectCampaign'
import styles from './GroupAddCreate/groupAddCreate.module.scss'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import { Dialog, Text } from '@sendoutcards/quantum-design-ui'

type SelectCampaignModalProps = {
  isOpen: boolean
  onClose: () => void
  onSubmit: (campaign: MinimalCampaignFragment) => void
}

const SelectCampaignModal: React.FC<SelectCampaignModalProps> = props => {
  const { onSubmit, onClose, isOpen } = props
  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <DialogTitle>
        <Text type="body" color="primaryHeading">
          {'Select Campaign to Send'}
        </Text>
      </DialogTitle>
      <DialogContent className={styles.modalContent}>
        <SelectCampaign onClose={onClose} onSubmit={onSubmit} />
      </DialogContent>
    </Dialog>
  )
}

export default SelectCampaignModal
