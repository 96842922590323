import { CardSendType } from 'src/graphql/generated/graphql'
import { PartialLine } from 'src/helpers/multitouch'
import {
  getDiscountedPrice,
  getPrice,
  getTotalCardCost,
} from 'src/orders/helpers'
import useSubscriptions from './useSubscriptions'

const useOrderLinePricing = (
  orderSendType?: CardSendType,
  orderLine?: PartialLine,
) => {
  const sub = useSubscriptions()

  const cardCost = {
    baseCost: getPrice(orderLine?.card?.cost.entries, 'Card Cost'),
    finalCost: getPrice(orderLine?.cost?.entries, 'Card Cost'),
    upgradesCost: getPrice(orderLine?.card?.cost.entries, 'Card Upgrades'),
  }

  const cardPrice = getTotalCardCost(cardCost.baseCost, cardCost.upgradesCost)

  const cardDiscountedPrice =
    cardCost.baseCost && cardCost.finalCost
      ? getDiscountedPrice(
          cardCost.baseCost,
          cardCost.finalCost,
          cardCost.upgradesCost,
        )
      : undefined

  const cardDiscountMessage =
    cardDiscountedPrice !== undefined
      ? sub.hasAvailableHeartfeltSends
        ? 'This card is $0.00 because you have Heartfelt Promptings Cards Sends Available'
        : sub.hasAvailableCardSends
        ? 'This card is $0.00 because you have Cards Sends Available'
        : cardCost.finalCost?.currency === 'FREE'
        ? 'This card is $0.00 because it is your first card'
        : 'This card is discounted because you have a subscription or package'
      : undefined

  const giftPrices = {
    baseCost:
      getPrice(orderLine?.baseCost?.entries, 'Gift Cost')?.asString ??
      getPrice(orderLine?.baseCost?.entries, 'Gift Card Cost')?.asString,
    cost:
      getPrice(orderLine?.cost?.entries, 'Gift Cost')?.asString ??
      getPrice(orderLine?.cost?.entries, 'Gift Card Cost')?.asString,
  }
  const hasGiftDiscount = giftPrices.baseCost !== giftPrices.cost

  const giftPrice = hasGiftDiscount
    ? giftPrices.baseCost ?? ''
    : giftPrices.cost ?? ''

  const giftDiscountPrice = hasGiftDiscount ? giftPrices.cost : undefined

  const giftDiscountMessage = hasGiftDiscount
    ? `Discounts from your ${sub.giftDiscountPlanTitle} subscription have been applied.`
    : undefined

  return {
    cardPrice,
    cardDiscountedPrice,
    cardDiscountMessage,
    giftPrice,
    giftDiscountPrice,
    giftDiscountMessage,
  }
}

export default useOrderLinePricing
