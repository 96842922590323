import React from 'react'
import { Flex, Icon, Spacer, Text } from '@sendoutcards/quantum-design-ui'

const NoResultsPlaceholder = (props: { search: string }) => {
  const { search } = props
  return (
    <Flex
      flexDirection="column"
      outset={{ top: 'x2' }}
      justifyContent="center"
      alignItems="center"
      inset="x4"
    >
      <Icon name={'noUsers'} size="large" primaryColor="default" />
      <Spacer space="x1" />
      <Text type="body" alignment="center">
        No user was found with the name
        <Text
          type="body"
          alignment="center"
          weight={'bold'}
          color="primaryHeading"
        >
          {search}
        </Text>
        Want to try searching for another name?
      </Text>
    </Flex>
  )
}

export default NoResultsPlaceholder
