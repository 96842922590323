import React from 'react'
// @src imports
import { ProfileAvatar } from 'src/chrome'
import { FlexColumn, SponsorName } from 'src/styled'
import { Spacer, Text } from '@sendoutcards/quantum-design-ui'
import { usePersistedUserData, useQueries, useSelector } from 'src/hooks'
import { MinimalSponsorFragment, UserType } from 'src/graphql/generated/graphql'
import { getMarketingContent } from 'src/legacy_graphql'

const container: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'right',
  alignItems: 'left',
  padding: '5px 0',
  WebkitAlignItems: 'baseline',
}
const avatar: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'left',
  marginRight: 10,
  alignItems: 'baseline',
  color: '#FFF',
  fontWeight: 'bold',
  fontSize: '1rem',
}

interface SponsorCardProps {
  sponsor: MinimalSponsorFragment
}

const SponsorCard: React.FC<SponsorCardProps> = props => {
  const { sponsor } = props
  const { isMobile } = useSelector(s => s.window)

  const [{ sharingInformationCopy }] = useQueries(getMarketingContent())
  const { account } = usePersistedUserData()

  return (
    <>
      <FlexColumn
        style={{
          width: '100%',
        }}
      >
        <div style={container}>
          <Text type="body" color="primaryHeading">
            I was referred by
          </Text>
          <ProfileAvatar
            avatarWrapperStyles={{
              width: 'initial',
              alignItems: 'baseline',
            }}
            style={avatar}
            image={sponsor.storefront?.mugshot?.url ?? ''}
            name={`${sponsor.firstName} ${sponsor.lastName}`}
            size={50}
          />
          <SponsorName id={'sponsor_name_' + sponsor.genealogyId.toString()}>
            <Text type="caption" color="primaryHeading">
              {`${sponsor.firstName} ${sponsor.lastName}`}
            </Text>
          </SponsorName>
          {account && account.type !== UserType.Gsa && (
            <>
              <Text
                type={isMobile ? 'caption' : 'body'}
                color="primaryHeading"
                data-mktcontent="sharingInformationCopy"
              >
                {sharingInformationCopy.content}
              </Text>
              <Spacer space="x2" />
            </>
          )}
        </div>
        <Spacer space="x1" />
      </FlexColumn>
    </>
  )
}

export default SponsorCard
