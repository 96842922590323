import React from 'react'
import { Blinker } from 'src/chrome'
import { useActions, useCallback, useMutations, useState } from 'src/hooks'
import { useCreateCampaign, useCreateOrder } from 'src/react_query'
import { cssStyles } from 'src/styled'
import { CampaignShare, CreateOrderModal } from '..'
import { Button } from 'src/design_system/components/Button/Button'
import { CampaignLineFragment } from 'src/graphql/generated/graphql'
import omit from 'lodash/omit'

const styles = cssStyles({
  campaignHeader: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    padding: '45px 0 60px 0',
    '@media (max-width: 500px)': {
      padding: '15px 0 10px',
    },
  },
  campaignActions: {
    display: 'flex',
    columnGap: '1rem',
    'div:nth-of-type(2) ': {
      marginLeft: '25px',
    },
    '@media (max-width: 500px)': {
      flexDirection: 'column',
      button: {
        margin: '10px',
      },
    },
  },
})

const pinkColor = '#FF059F'

type CampaignHeaderProps = {
  hasValidSequence: boolean
  campaignId: string
  campaignLines: CampaignLineFragment[]
  campaignName: string
  isCampaignShareable: boolean
}

const CampaignHeaderActions: React.FC<CampaignHeaderProps> = props => {
  const {
    hasValidSequence,
    campaignId,
    campaignLines,
    campaignName,
    isCampaignShareable,
  } = props

  const actions = useActions()
  const mutations = useMutations()
  const createOrderMutation = useCreateOrder()
  const createCampaignMutation = useCreateCampaign()
  const [isShareModalOpen, setIsShareModalOpen] = useState(false)
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false)

  const handleCreateOrder = useCallback(
    async (selectedLines: CampaignLineFragment[] | null) => {
      setIsOrderModalOpen(!isOrderModalOpen)
      const {
        createOrder: { order },
      } = await createOrderMutation.mutateAsync({
        order: {
          lines: selectedLines?.map(line => ({
            card: line.card?.id,
            giftVariation: line.giftVariation?.id,
            sendDelay: omit(line.sendDelay, '__typename'),
          })),
          campaign: campaignId,
        },
      })
      actions.createdOrder(order)
      actions.openOrder(order.id)
    },
    [actions, isOrderModalOpen, campaignId, createOrderMutation],
  )

  const handleCreateCampaign = async (
    selectedLines: CampaignLineFragment[],
    name: string,
  ) => {
    setIsOrderModalOpen(!isOrderModalOpen)

    const lines = selectedLines.map(line => ({
      card: line.card?.id ?? null,
      giftVariation: line.giftVariation?.id ?? null,
      sendDelay: omit(line.sendDelay, '__typename'),
    }))

    const {
      createCampaign: { campaign },
    } = await createCampaignMutation.mutateAsync({
      campaign: { name, lines, isShareable: isCampaignShareable },
    })
    actions.openCampaign(campaign.id)
  }

  const handleShare = async (
    recipientId: string,
    shouldAllowShare: boolean,
  ) => {
    setIsShareModalOpen(!isShareModalOpen)

    await mutations.shareCampaign({
      campaign: campaignId,
      username: recipientId,
      allowShare: shouldAllowShare,
    })
  }

  return (
    <>
      <div css={styles.campaignHeader}>
        {!hasValidSequence && (
          <Blinker
            style={{ bottom: 7 }}
            messagePosition={{
              position: 'absolute',
              top: -18,
              right: -7,
            }}
            message={
              'There must be at least one immediate card when there is a delay sequence'
            }
          />
        )}
        <div css={styles.campaignActions}>
          {isCampaignShareable && campaignLines.length > 0 && (
            <Button
              id={'share_campaign_btn'}
              title={'Share Campaign'}
              onClick={() => setIsShareModalOpen(!isShareModalOpen)}
              outlineWidth="2px"
              outlineColor={pinkColor}
              textColor={pinkColor}
              backgroundColor="white"
              textSize="0.875rem"
            />
          )}
          {campaignLines.length > 0 && (
            <Button
              id={'create_order_btn'}
              title={'Send Order'}
              onClick={() => setIsOrderModalOpen(!isOrderModalOpen)}
            />
          )}
        </div>
      </div>
      {isShareModalOpen && (
        <CampaignShare
          onClose={() => setIsShareModalOpen(!isShareModalOpen)}
          onSubmit={handleShare}
          campaignTitle={campaignName}
          lines={campaignLines.map(line => ({
            giftVariation: {
              defaultImageUrl: line.giftVariation?.defaultImageUrl ?? '',
            },
            card: {
              id: line.card?.id ?? '',
              isHorizontal: line.card?.isHorizontal ?? false,
              frontPreviewUrl: line.card?.frontPreviewUrl ?? '',
            },
          }))}
        />
      )}
      <CreateOrderModal
        key={campaignId}
        isOpen={isOrderModalOpen}
        onClose={() => setIsOrderModalOpen(!isOrderModalOpen)}
        onCreateCampaign={handleCreateCampaign}
        onSubmit={handleCreateOrder}
        lines={campaignLines}
      />
    </>
  )
}

export default CampaignHeaderActions
