import React from 'react'
import { Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import {
  CheckoutLineFragment,
  SaleorOrderLineFragment,
} from 'src/saleor_graphql/generated/graphql'

type ProductsSummaryProps = {
  products?: (CheckoutLineFragment | SaleorOrderLineFragment)[]
  label?: string
}

export const ProductSummary = (props: ProductsSummaryProps) => {
  const { products = [], label = 'Selected Product(s)' } = props
  const labelStyles = {
    fontSize: '10px',
    color: '#171717',
    fontWeight: 700,
  }

  return (
    <Flex
      width="100%"
      flexDirection="column"
      style={{
        gap: '1rem',
        fontFamily: 'Montserrat, san serif',
      }}
    >
      <Text
        type="body"
        style={{ fontSize: '18px', color: '#686868', fontWeight: 700 }}
      >
        {label}
      </Text>
      <Flex
        flexDirection="column"
        backgroundColor="#fff"
        style={{
          overflow: 'hidden',
          boxShadow: '0 1px 2px 0 rgba(0,0,0,0.05)',
          borderRadius: '1rem',
          border: '4px solid #fff',
        }}
      >
        <Flex
          flexDirection="row"
          alignItems="center"
          height="32px"
          inset="12px"
          backgroundColor="#E5E7EB"
          style={{
            gap: '0.5rem',
          }}
        >
          <Div style={{ flexGrow: 1 }}>
            <Text type="body" style={{ ...labelStyles }}>
              Item(s): {products.length}
            </Text>
          </Div>
          <Flex
            justifyContent="center"
            width="4rem"
            style={{
              flex: 'none',
            }}
          >
            <Text type="body" style={{ ...labelStyles }}>
              Quantity
            </Text>
          </Flex>
          <Flex
            justifyContent="center"
            width="4rem"
            style={{
              flex: 'none',
            }}
          >
            <Text type="body" style={{ ...labelStyles }}>
              Total
            </Text>
          </Flex>
        </Flex>
        {products.map((line, index) => {
          const [main] = line.variant?.media ?? []
          const isIndexPrime = index % 2 === 0
          const hasDiscount =
            'undiscountedTotalPrice' in line &&
            line.totalPrice.net.amount !== line.undiscountedTotalPrice.amount
          return (
            <Flex
              key={line.id}
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-start"
              inset="12px"
              backgroundColor={isIndexPrime ? '#fff' : '#F9FAFB'}
              style={{
                gap: '0.5rem',
              }}
            >
              <img
                style={{ height: '32px', width: '32px', borderRadius: '4px' }}
                src={main?.url}
                alt="#"
              />
              <Div style={{ flexGrow: 1 }}>
                <Text
                  type="body"
                  style={{ fontSize: '14px', color: '#323232' }}
                >
                  {line.variant?.name}
                </Text>
              </Div>
              <Flex
                justifyContent="center"
                width="4rem"
                style={{
                  flex: 'none',
                }}
              >
                <Div
                  height="fit-content"
                  width="fit-content"
                  inset="0px 16px"
                  backgroundColor={isIndexPrime ? '#F9FAFB' : '#fff'}
                  style={{
                    borderRadius: '4px',
                    fontSize: '14px',
                    fontWeight: 700,
                    border: '1px solid #F3F4F6',
                    color: '#6B7280',
                  }}
                >
                  <b style={{ fontFamily: 'Montserrat, san serif' }}>
                    {line.quantity}
                  </b>
                </Div>
              </Flex>
              <Flex
                justifyContent="center"
                width="4rem"
                style={{
                  flex: 'none',
                  flexDirection: 'column',
                }}
              >
                {hasDiscount && (
                  <Text
                    type="body"
                    content={`$${line.undiscountedTotalPrice.amount.toFixed(
                      2,
                    )}`}
                    style={{
                      fontSize: '14px',
                      fontWeight: 700,
                      color: '#323232',
                    }}
                    isStrikeThrough={true}
                  />
                )}
                <Text
                  type="body"
                  content={`$${line.totalPrice.net.amount.toFixed(2)}`}
                  style={{
                    fontSize: '14px',
                    fontWeight: 700,
                    color: '#323232',
                  }}
                />
              </Flex>
            </Flex>
          )
        })}
      </Flex>
    </Flex>
  )
}
