import { useActions, useEffect, useQueryParams } from 'src/hooks'
import {
  GS_AFFILIATE_ID,
  GS_SALEOR_MONTHLY_AFFILIATE_ID,
} from 'src/marketing/plans/constants'
import { usePlans, useProductVariants } from 'src/react_query/queries/hooks'
import { getPlanDescriptions } from 'src/saleor/utils/getPlanDescriptions'
import useHandleProductActions from './useHandleProductActions'
import { CheckoutLineInput } from 'src/saleor_graphql/generated/graphql'

const matchingPlans = [
  { initial: '15494', reccuring: '14547' },
  { initial: '15495', reccuring: '15394' },
  { initial: '15493', reccuring: '15395' },
  { initial: '15506', reccuring: '13705' },
  { initial: GS_SALEOR_MONTHLY_AFFILIATE_ID, reccuring: GS_AFFILIATE_ID },
]

const useAddProductFromQueryParam = () => {
  const queryParams = useQueryParams()
  const actions = useActions()
  const plansQuery = usePlans({ suspense: true })
  const plans = plansQuery.data!
  const planDescriptions = getPlanDescriptions(plans)
  const productActions = useHandleProductActions()
  const isJoiningThroughPackageParam =
    queryParams.isJoiningAffiliate && queryParams.package === 'pro'
  const isMutatingCheckout =
    productActions.isCreatingCheckout || productActions.isUpdatingCheckout
  const productIds = queryParams.promoPlanId

  const plansArr = productIds ? productIds.split('-').filter(el => el) : []

  const plansStripeArr = plansArr
    .map(pid => plans.find(plan => pid === plan.id)?.stripeId ?? '')
    .filter(el => el)

  const saleorVariantIdsArr = plansArr
    .map(pa => planDescriptions[pa]?.saleorVariantId ?? '')
    .filter(el => el)

  const correctedPlansStripeIdsArr =
    plansStripeArr.length > 0
      ? plansStripeArr.map(
          sId => matchingPlans.find(mp => mp.reccuring === sId)?.initial ?? sId,
        )
      : isJoiningThroughPackageParam
      ? [GS_SALEOR_MONTHLY_AFFILIATE_ID]
      : []

  const checkoutProducts = queryParams.checkoutProducts
  const checkoutProductsSkus =
    checkoutProducts?.map(value => value.productSku) ?? []

  const { data: dataVs } = useProductVariants(
    {
      first: 100,
      filter: {
        sku:
          checkoutProductsSkus.length > 0
            ? checkoutProductsSkus
            : correctedPlansStripeIdsArr,
      },
    },
    correctedPlansStripeIdsArr.length > 0 || checkoutProductsSkus.length > 0,
  )

  const variants: CheckoutLineInput[] | undefined =
    checkoutProductsSkus.length > 0
      ? dataVs?.pages[0]?.edges.map(value => {
          return {
            variantId: value.node.id,
            quantity:
              checkoutProducts?.find(prod => prod.productSku === value.node.sku)
                ?.quantity ?? 1,
          }
        })
      : saleorVariantIdsArr.length > 0 &&
        saleorVariantIdsArr.length === plansStripeArr.length
      ? saleorVariantIdsArr.map(value => {
          return { variantId: value, quantity: 1 }
        })
      : correctedPlansStripeIdsArr.length > 0
      ? dataVs?.pages[0]?.edges.map(value => {
          return { variantId: value.node.id, quantity: 1 }
        })
      : undefined

  useEffect(() => {
    if (variants && variants.length > 0 && !isMutatingCheckout) {
      productActions.addToCart(variants)
      actions.checkoutOpenState(true)
      queryParams.removeQueryParams([
        'checkoutProducts',
        'promo',
        'package',
        'affiliatejoin',
      ])
    }
  }, [actions, queryParams, productActions, isMutatingCheckout, variants])

  return {
    productActions,
  }
}

export default useAddProductFromQueryParam
