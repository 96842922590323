import { Maybe } from '../../../__generated__/globalTypes'

import creditCardBillingAddressFragmentRawString from '../CreditCardBillingAddressFragment.graphql'

export const creditCardBillingAddressFragmentString = creditCardBillingAddressFragmentRawString

export type CreditCardBillingAddressFragment = {
  city: Maybe<string>
  country: Maybe<string>
  line1: Maybe<string>
  line2: Maybe<string>
  postalcode: Maybe<string>
  state: Maybe<string>
}

export const isCreditCardBillingAddressFragment = (
  fragment: any,
): fragment is CreditCardBillingAddressFragment =>
  fragment &&
  (fragment.city === null || typeof fragment.city === 'string') &&
  (fragment.country === null || typeof fragment.country === 'string') &&
  (fragment.line1 === null || typeof fragment.line1 === 'string') &&
  (fragment.line2 === null || typeof fragment.line2 === 'string') &&
  (fragment.postalcode === null || typeof fragment.postalcode === 'string') &&
  (fragment.state === null || typeof fragment.state === 'string')
