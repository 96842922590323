import { CancelPendingCardsData } from 'src/legacy_graphql'
import {
  CardStatus,
  CardType,
  ProductionInfoFragment,
  RecipientFragment,
} from 'src/graphql/generated/graphql'
import { SearchFilter } from 'src/chrome/FilteredSearchBar/types'
import { CardHistoryRoute } from 'src/dashboard/routes/CardHistoryRoute'

export interface Props {
  initialFilters?: SearchFilter[]
  initialSection?: Section
  route: CardHistoryRoute
}
export type ProductionRecipient = RecipientFragment & {
  productionId?: string | undefined
  status?: CardStatus | undefined
}

export type RecipientCard = {
  id: string
  type: CardType
  isHorizontal: boolean
  frontPreviewUrl: string
  insidePreviewUrl: string | null
  flapPreviewUrl: string | null
  backPreviewUrl: string
  isNewEditorCard: boolean
  panels: {
    width: number
    height: number
  }[]
}

export enum Steps {
  Idle,
  Progress,
  Error,
  EditRecipient,
  SetDate,
  View,
  ConfirmCancelCards,
  CancelCards,
  CancelCardsSummary,
  CancelErrored,
  ConfirmRetryFailedCards,
  RetryFailedCards,
}

interface IdleStep {
  type: Steps.Idle
}

interface ProgressStep {
  type: Steps.Progress
  message: string
}

interface ErrorStep {
  type: Steps.Error
  message: string
}

interface EditRecipientStep {
  type: Steps.EditRecipient
  recipient: RecipientFragment
}

interface SetDateStep {
  type: Steps.SetDate
  info: ProductionInfoFragment
}

interface ViewStep {
  type: Steps.View
  recipient: ProductionRecipient
  card?: RecipientCard
}

interface RetryFailedCardsStep {
  type: Steps.RetryFailedCards
  userConfirmed: boolean
  ids: string[]
}

interface CancelCardsStep {
  type: Steps.CancelCards
  userConfirmed: boolean
  ids: string[]
}

interface CancelCardsSummaryStep {
  type: Steps.CancelCardsSummary
  ids: string[]
  refundInfo: CancelPendingCardsData['cancelPendingCards']['refundInfo']
}

interface CancelErroredStep {
  type: Steps.CancelErrored
  confirmed: boolean
}

export type Step =
  | IdleStep
  | EditRecipientStep
  | SetDateStep
  | ViewStep
  | ProgressStep
  | ErrorStep
  | CancelCardsStep
  | CancelErroredStep
  | CancelCardsSummaryStep
  | RetryFailedCardsStep

export type Section =
  | 'All'
  | 'Printing'
  | 'Pending'
  | 'Fulfilled'
  | 'Held'
  | 'Rejected'
  | 'Payment Error'
