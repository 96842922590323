import {
  Button,
  Div,
  Flex,
  Input,
  Select,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { formatCountriesAndRegions } from 'src/helpers/formatCountriesAndRegions'
import { usePersistedUserData, useSelector, useState } from 'src/hooks'
import { AffiliateFormInfo } from 'src/marketing/plans/components/AffiliateForm/AffiliateForm'
import { FlexRow } from 'src/styled'
import ProfileAvatar from 'src/chrome/ProfileAvatar/ProfileAvatar'
import { useAffiliateCountries } from 'src/react_query'

interface AffiliateAddressProps {
  affiliateInfo: AffiliateFormInfo
  onFinishAccount: (info: AffiliateFormInfo) => void
  onBack: () => void
}

interface AffiliateAddressFormState {
  firstName: string
  lastName: string
  address1: string
  address2: string
  city: string
  state: string
  postalCode: string
  country: string
  profileImage: string
}

const AffiliateAddress: React.FC<AffiliateAddressProps> = props => {
  const { affiliateInfo, onFinishAccount, onBack } = props

  const { account } = usePersistedUserData()

  const [details, setDetails] = useState({
    firstName: affiliateInfo?.user.firstName || account?.firstName || '',
    lastName: affiliateInfo?.user.lastName || account?.lastName || '',
    address1:
      affiliateInfo?.user.address1 || account?.shippingAddress?.address1 || '',
    address2:
      affiliateInfo?.user.address2 || account?.shippingAddress?.address2 || '',
    city: affiliateInfo?.user.city || account?.shippingAddress?.city || '',
    state: affiliateInfo?.user.state || account?.shippingAddress?.state || '',
    postalCode:
      affiliateInfo?.user.postalCode ||
      account?.shippingAddress?.postalCode ||
      '',
    country:
      affiliateInfo?.user.country ||
      account?.shippingAddress?.country ||
      'United States',
    profileImage:
      affiliateInfo?.user.profileImage || account?.profileImageUrl || '',
  })

  const [selectedCountryOptionIndex, setSelectedCountryOptionIndex] = useState<
    number
  >()
  const [selectedRegionOptionIndex, setSelectedRegionOptionIndex] = useState<
    number
  >()

  const handleChange = (
    name: keyof AffiliateAddressFormState,
    value: string,
  ) => {
    setDetails({ ...details, [name]: value })
  }
  const { data: affiliateCountries } = useAffiliateCountries()
  const { countries, regions, isRegionsUnavailable } = affiliateCountries
    ? formatCountriesAndRegions(affiliateCountries, details.country)
    : { countries: [], regions: undefined, isRegionsUnavailable: false }

  const isMobile = useSelector(state => state.window.isMobile)

  const isDisabled =
    !details.firstName ||
    !details.lastName ||
    !details.address1 ||
    !details.city ||
    !details.state ||
    !regions?.includes(details.state) ||
    !details.postalCode ||
    !details.country

  const onSubmit = () => {
    if (isDisabled) {
      return
    }
    onFinishAccount({
      ...affiliateInfo,
      user: {
        ...affiliateInfo.user,
        firstName: details.firstName,
        lastName: details.lastName,
        address1: details.address1,
        address2: details.address2,
        city: details.city,
        state: details.state,
        postalCode: details.postalCode,
        country: details.country,
        profileImage: details.profileImage,
      },
    })
    return
  }

  const countryOptions = (countries || ['Select a country']).map(country => {
    const countryObject = {
      label: country,
      value: country.split(' ').join('_').toLowerCase(),
    }
    return countryObject
  })

  const regionOptions = (regions || ['Select a region']).map(region => {
    const regionObject = {
      label: region,
      value: region.split(' ').join('_').toLowerCase(),
    }
    return regionObject
  })

  return (
    <Div data-step="affiliate-address-step">
      <Flex width="100%" flexDirection="column">
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignContent="center"
          alignItems="center"
        >
          <ProfileAvatar
            size={75}
            style={
              {
                fontSize: '3.7rem',
                color: '#fff',
                letterSpacing: 1,
              } as React.CSSProperties
            }
            isEditable={true}
            onSaveImageString={profileImage =>
              handleChange('profileImage', profileImage)
            }
            image={details.profileImage}
            name={`${details.firstName} ${details.lastName}`}
          />
          <Spacer space={'x1'} />
          <Text type="caption">Upload Avatar (optional)</Text>
        </Flex>
        <Spacer space={'x3'} />
        <Input
          onChange={firstName => handleChange('firstName', firstName)}
          type="text"
          name="firstName"
          value={details.firstName}
          placeholder="First Name"
          focusColor="primaryBrand"
          isFullWidth={true}
        />
        <Spacer space={'x1'} />
        <Input
          onChange={lastName => handleChange('lastName', lastName)}
          type="text"
          name="lastName"
          value={details.lastName}
          placeholder="Last Name"
          focusColor="primaryBrand"
          isFullWidth={true}
        />
        <Spacer space={'x1'} />
        <Input
          onChange={address1 => handleChange('address1', address1)}
          type="text"
          name="address1"
          value={details.address1}
          placeholder="Address 1"
          focusColor="primaryBrand"
          isFullWidth={true}
        />
        <Spacer space={'x1'} />
        <Input
          onChange={address2 => handleChange('address2', address2)}
          type="text"
          name="address2"
          value={details.address2}
          placeholder="Address 2"
          focusColor="primaryBrand"
          isFullWidth={true}
        />
        <Spacer space={'x1'} />
        <Input
          onChange={city => handleChange('city', city)}
          type="text"
          name="city"
          value={details.city}
          placeholder="City"
          focusColor="primaryBrand"
          isFullWidth={true}
        />
        <Spacer space={'x1'} />
        <FlexRow>
          <Div width="47%">
            <Select
              alignment="left"
              dropDownMaxHeight={'200px'}
              options={countryOptions}
              type="text"
              selectedOptionIndex={selectedCountryOptionIndex}
              onChange={index => {
                setSelectedCountryOptionIndex(index)
                handleChange('country', countryOptions[index].label)
              }}
            />
          </Div>
          {!isRegionsUnavailable && (
            <Div width="47%" style={{ marginLeft: '6%' }}>
              <Select
                alignment="left"
                dropDownMaxHeight={'200px'}
                options={regionOptions}
                type="text"
                selectedOptionIndex={selectedRegionOptionIndex}
                onChange={index => {
                  setSelectedRegionOptionIndex(index)
                  handleChange('state', regionOptions[index].label)
                }}
              />
            </Div>
          )}
        </FlexRow>

        <Spacer space={'x1'} />
        <Input
          onChange={postalCode => handleChange('postalCode', postalCode)}
          type="text"
          name="postalCode"
          value={details.postalCode}
          placeholder="Postal Code"
          focusColor="primaryBrand"
          isFullWidth={true}
        />
      </Flex>
      <Flex width="100%" flexDirection="column">
        <Spacer space={isMobile ? 'x1' : 'x4'} />
        <Div width="100%" id="continue_btn">
          <Button
            fullWidth={true}
            type={'primary'}
            onClick={onSubmit}
            title={'Continue'}
            disabled={isDisabled}
          />
        </Div>
        <Spacer space={isMobile ? 'x1' : 'x2'} />
        <Div width="100%" id="back_btn">
          <Button
            fullWidth={true}
            type={'accent'}
            outlined={true}
            onClick={() => onBack()}
            title={'Back to Account Details'}
          />
        </Div>
      </Flex>
    </Div>
  )
}

export default AffiliateAddress
