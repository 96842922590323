import { Div, Flex, PricingTile } from '@sendoutcards/quantum-design-ui'
import React from 'react'

import { getPlansDescriptions } from 'src/legacy_graphql'
import { style } from './pricingTableStyles'
import { usePlansSubscriptions, useQueries, useSelector } from 'src/hooks'
import { PlansDescription } from '../../types'

type PlanTileGroupProps = {
  onSelect: (planId: string, isKeepButton?: boolean) => void
  shouldAddSpacer?: boolean
}

const PlanTileGroup: React.FC<PlanTileGroupProps> = props => {
  const { onSelect, shouldAddSpacer = false } = props

  const [plansDescriptionFragments] = useQueries(getPlansDescriptions())
  const plansDescriptions = plansDescriptionFragments
    .map(PlansDescription.fromPlansDescriptionFragment)
    .filter(value => !value.description.isPromotional)
    .sort((a, b) => (a.description?.order ?? 0) - (b.description?.order ?? 0))

  const plansDescriptionsNoBasic = plansDescriptions.filter(
    plansDescription => plansDescription.description.plan !== 'Basic',
  )

  const basicPlansDescription = plansDescriptions.find(
    plansDescription => plansDescription.description.plan === 'Basic',
  )

  const { currentPlan, newPlan, currentSubscription } = usePlansSubscriptions()

  const currentPlansDescription = plansDescriptions.find(
    plansDescription => plansDescription.id === currentPlan?.id,
  )

  const newPlansDescription = plansDescriptions.find(
    plansDescription => plansDescription.id === newPlan?.id,
  )

  const isDowngraded =
    newPlansDescription &&
    currentPlansDescription &&
    (newPlansDescription.description.order ?? 0) <
      (currentPlansDescription.description.order ?? 0)

  const isSmallDesktop = useSelector(state => state.window.width <= 1120)
  const isLargeDesktop = useSelector(state => state.window.width >= 2440)

  return (
    <Div
      inset={{ horizontal: 'x3' }}
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: '50px',
        alignContent: 'flex-start',
        justifyContent: 'space-evenly',
        width: '100%',
      }}
    >
      {shouldAddSpacer && <div style={style.planSpacer} />}
      {plansDescriptionsNoBasic.map(plansDescription => {
        const description = plansDescription.description

        const isCurrentPlan = currentPlan?.id === plansDescription.id

        const isPlanSetToCancel =
          isCurrentPlan &&
          currentSubscription &&
          currentSubscription.isSetToCancel

        const isNewPlan = newPlan?.id === plansDescription.id

        const isPreviousPlan = isDowngraded && isCurrentPlan

        const shouldShowPurchaseNow = !isNewPlan && !isCurrentPlan

        const shouldShowKeep = isPreviousPlan || isPlanSetToCancel

        const shouldShowCancel =
          isCurrentPlan &&
          currentSubscription &&
          !currentSubscription.isSetToCancel

        const handlePlanUpgrade = () => {
          if (shouldShowPurchaseNow) {
            return {
              text: 'Purchase Now',
              onClick: () => {
                onSelect(plansDescription.id)
              },
            }
          } else if (shouldShowKeep) {
            return {
              text: 'Keep',
              onClick: () => {
                onSelect(plansDescription.id, true)
              },
            }
          } else if (shouldShowCancel) {
            return {
              text: 'Cancel',
              onClick: () =>
                basicPlansDescription && onSelect(basicPlansDescription.id),
            }
          } else {
            return
          }
        }

        const planFeatures = description?.details?.map(detail => {
          if (detail.innerDetails) {
            return {
              feature: detail.detail,
              subFeatures: detail.innerDetails.map(d => d.detail),
              isNotIncluded: false,
            }
          }
          return detail.detail
        })
        if (planFeatures && description) {
          return (
            <>
              <Flex
                outset="x3"
                height={isLargeDesktop ? '100%' : 'auto'}
                width={isSmallDesktop ? '100%' : '45%'}
                maxWidth={isLargeDesktop ? '520px' : undefined}
              >
                <PricingTile
                  isFullLength={true}
                  featuredItemStyles={{
                    backgroundColor: 'secondaryBrand',
                    textColor: 'white',
                    accentColor: 'inverseBody',
                  }}
                  accentColor="secondaryBrand"
                  description={description.description}
                  title={description.plan}
                  features={planFeatures}
                  billingInterval={isCurrentPlan ? 'Current' : 'Monthly'}
                  isFeatured={isCurrentPlan}
                  primaryAction={handlePlanUpgrade()}
                />
              </Flex>
            </>
          )
        }
        return <> </>
      })}
    </Div>
  )
}

export default PlanTileGroup
