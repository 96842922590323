import React, { ChangeEvent, FC } from 'react'
import { Moment } from 'moment'
import range from 'lodash/range'

import { currentYear, months as originalMonths } from 'src/helpers/dates'
import styles from './styles'
import { Text } from '@sendoutcards/quantum-design-ui'

type PickerHeadingProps = {
  date: Moment
  changeMonth: (month: number) => void
  changeYear: (year: number) => void
  shouldHideYear?: boolean
}

const months = originalMonths.filter(m => !!m)
const years = [...range(currentYear - 6, currentYear + 5)]

const handleChange = (
  event: ChangeEvent<HTMLSelectElement>,
  callback: (value: number) => void,
): void => {
  callback(parseInt(event.currentTarget.value, 10))
}

const PickerHeading: FC<PickerHeadingProps> = props => {
  const { date, changeMonth, changeYear, shouldHideYear } = props
  return (
    <nav id={'picker_heading'} css={styles.heading}>
      {/* eslint-disable-next-line */}
      <a
        id={'calendar_left_arrow'}
        onMouseDown={() => changeMonth(date.month() - 1)}
        css={styles.leftArrow}
      >
        &#8249;
      </a>
      <Text type="title">
        <select
          value={date.month()}
          onChange={e => handleChange(e, changeMonth)}
          css={{ ...(styles.select as {}), marginRight: 12 }}
        >
          {months.map((month, index) => (
            <option key={month || index} value={index}>
              {month}
            </option>
          ))}
        </select>
        {!shouldHideYear && (
          <select
            value={date.format('YYYY')}
            onChange={e => handleChange(e, changeYear)}
            css={{ ...(styles.select as {}), fontSize: 16 }}
          >
            {years.map(year => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        )}
      </Text>
      {/* eslint-disable-next-line */}
      <a
        id={'calendar_right_arrow'}
        onMouseDown={() => changeMonth(date.month() + 1)}
        css={styles.rightArrow}
      >
        &#8250;
      </a>
    </nav>
  )
}

export default PickerHeading
