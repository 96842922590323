import { Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'

type ShoppingCartButtonProps = {
  cartQuantity?: number
  onClick: () => void
  isOpen?: boolean
}

export const ShoppingCartButton = (props: ShoppingCartButtonProps) => {
  const { cartQuantity, onClick, isOpen } = props

  const buttonStyles = {
    background: isOpen ? '#000' : '#fff',
    element: isOpen ? '#fff' : '#000',
  }

  return (
    <Flex
      backgroundColor={buttonStyles.background}
      inset="x1_5"
      onClick={onClick}
      justifyContent="center"
      alignItems="center"
      cursor="pointer"
      position="relative"
      style={{
        borderRadius: '30px',
        boxShadow:
          'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px',
      }}
    >
      {cartQuantity !== undefined && cartQuantity > 0 && (
        <Flex
          justifyContent="center"
          alignItems="center"
          width="fit-content"
          height="fit-content"
          inset={{ vertical: 'x_25', horizontal: 'x_75' }}
          backgroundColor={buttonStyles.element}
          style={{
            borderRadius: '30px',
            boxShadow: isOpen
              ? 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
              : '',
          }}
          position="absolute"
          top="1px"
          right="1px"
        >
          <Text
            type="body"
            content={cartQuantity}
            lineHeight={1.2}
            style={{
              fontSize: '10px',
              color: buttonStyles.background,
              fontFamily: 'Montserrat, san serif',
              fontWeight: 700,
            }}
          />
        </Flex>
      )}
      <Icon name="shopping_cart_2" size={24} color={buttonStyles.element} />
    </Flex>
  )
}
