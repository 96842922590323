import React from 'react'
import { BlobType, SectionContainer } from './components/SectionContainer'
import { FeatureCardGrid } from './components/featureCard/FeatureCardGrid'
import { GetPricingPageData } from 'src/legacy_graphql'

type FeatureCardGridSectionProps = {
  featureGrids: GetPricingPageData['pricingPage'][0]['sections']
}

export const FeatureCardGridSection = (props: FeatureCardGridSectionProps) => {
  const { featureGrids } = props
  return (
    <>
      {featureGrids
        .filter(section => section.pricinggrid)
        .map((section, index) => {
          const blobType = section.pricinggrid?.uiBlobs
            ? {
                blobStart: 'center',
                blobs: JSON.parse(section.pricinggrid.uiBlobs),
              }
            : undefined
          return (
            <SectionContainer key={index} blobType={blobType as BlobType}>
              {section.pricinggrid && (
                <FeatureCardGrid gridData={section.pricinggrid} />
              )}
            </SectionContainer>
          )
        })}
    </>
  )
}
