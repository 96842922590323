import { Operation } from '../../../__generated__/globalTypes'

import {
  MinimalSendableCardFragment,
  minimalSendableCardFragmentString,
} from '../../fragments/__generated__/MinimalSendableCardFragment'

import { sendableCardImageFragmentString } from '../../fragments/__generated__/SendableCardImageFragment'

import getFeaturedCardsRawString from '../GetFeaturedCards.graphql'

const getFeaturedCardsString = [
  getFeaturedCardsRawString,
  minimalSendableCardFragmentString,
  sendableCardImageFragmentString,
].join('\n\n')

export type GetFeaturedCardsData = {
  featuredCards: MinimalSendableCardFragment[]
}

export type GetFeaturedCards = Operation<GetFeaturedCardsData>

export const GetFeaturedCards = (): GetFeaturedCards => ({
  query: getFeaturedCardsString,
  variables: undefined,
})
