import React, { FC } from 'react'
import {
  Dialog,
  Div,
  Flex,
  LoadingSpinner,
  Select,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import ReactCountryFlag from 'react-country-flag'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { Button } from 'src/design_system/components/Button/Button'
import {
  useEffect,
  usePersistedUserData,
  useSelector,
  useState,
} from 'src/hooks'
import useHandleProductActions from 'src/pricing_page/hooks/useHandleProductActions'
import { Portal } from 'src/portal/portal'
import {
  GS_SALEOR_ANNUAL_AFFILIATE_ID,
  GS_SALEOR_MONTHLY_AFFILIATE_ID,
} from 'src/marketing/plans/constants'
import { Transition } from 'src/chrome'
import { useCheckout, useProductVariant } from 'src/react_query/queries/hooks'
import { Toggle } from 'src/design_system/components/Toggle/Toggle'
import { AFFILIATE_BRANDING } from 'src/app/constants'

export const supportedAffiliateCountries = [
  { iso: 'AU', name: 'Australia' },
  { iso: 'NZ', name: 'New Zealand' },
  { iso: 'CA', name: 'Canada' },
  { iso: 'GB', name: 'United Kingdom' },
  { iso: 'IE', name: 'Ireland' },
  { iso: 'SG', name: 'Singapore' },
  { iso: 'US', name: 'United States' },
]

export const affiliateCountryOptionsForQDS = [
  ...[{ value: '0', label: 'United States' }],
  ...supportedAffiliateCountries
    .filter(c => c.name !== 'United States')
    .map((country, i) => ({
      value: `${i + 1}`,
      label: country.name,
    }))
    .sort((a, b) => a.label.localeCompare(b.label)),
]

type SinglePlanTileType = {
  action: { title: string; onClick: () => void }
  isActivated: boolean
  isDangerState?: boolean
  shouldDisplayWhatShouldIDo?: boolean
  onDangerCallback?: () => void
}

export const SinglePlanTile: FC<SinglePlanTileType> = ({
  action,
  isActivated,
  isDangerState,
  shouldDisplayWhatShouldIDo,
  onDangerCallback,
}) => {
  const isTablet = useSelector(state => state.window.width <= 815)
  const {
    controlData: { isLoggedIn },
  } = usePersistedUserData()
  const [shouldConfirmCountry, setShouldConfirmCountry] = useState(false)
  const [confirmedCountryIndex, setConfirmedCountryIndex] = useState<
    number | undefined
  >(undefined)
  const { data } = useCheckout()

  const [billingInterval, setBillingInterval] = useState<'monthly' | 'annual'>(
    'annual',
  )

  const activeSku =
    billingInterval === 'monthly'
      ? GS_SALEOR_MONTHLY_AFFILIATE_ID
      : GS_SALEOR_ANNUAL_AFFILIATE_ID

  const {
    addToCart,
    isCreatingCheckout,
    isUpdatingCheckout,
    checkIsInCart,
    removeProductFromCart,
  } = useHandleProductActions(activeSku)

  const { data: variant } = useProductVariant({
    sku: activeSku,
  })
  const variantId = variant?.id
  const isInCart = checkIsInCart(data)

  useEffect(() => {
    const isAnnualAffiliateInCart = data?.lines.some(
      line => line.variant.sku === GS_SALEOR_ANNUAL_AFFILIATE_ID,
    )
    if (isAnnualAffiliateInCart) {
      setBillingInterval('annual')
    }
  }, [data, setBillingInterval])

  const shouldDisplayOutlinedVersion = isActivated || isInCart

  const outlinedWrapperStyle = shouldDisplayOutlinedVersion
    ? {
        borderColor: '#4AB5D9',
        borderWidth: 'bold' as const,
        borderStyle: 'solid' as const,
      }
    : {}

  const leftPanelTextColor = shouldDisplayOutlinedVersion
    ? '#4AB5D9'
    : isDangerState
    ? '#6B7280'
    : '#FFF'

  const isSelectingAnnualInterval = billingInterval === 'annual'

  const features = [
    'Promptings Pro App',
    'Promptings Process Sales Pages, Ebook, Email, and Upsell Funnel System',
    'Personalized Web Pages',
    'Online Business Office',
    'Weekly Newsletters',
    isSelectingAnnualInterval &&
      '41% discount by purchasing this annual option',
  ].filter(Boolean)

  const onBeforeAddToCart = () => {
    if (!isLoggedIn) {
      setShouldConfirmCountry(true)
    } else {
      addToCart(variantId)
    }
  }

  const renderSupportedCountries = (iso: string, name: string) => {
    return (
      <Flex
        alignItems="center"
        borderRadius="circle"
        inset={{ vertical: 'x1', horizontal: 'x1_5' }}
        backgroundColor="rgb(238, 241, 243)"
      >
        <ReactCountryFlag
          countryCode={iso}
          svg={true}
          style={{
            width: '24px',
            height: '19px',
            borderRadius: '1px',
            marginRight: '4px',
          }}
        />
        <Text type="body">{name}</Text>
      </Flex>
    )
  }

  return (
    <Flex>
      {isUpdatingCheckout ||
        (isCreatingCheckout && <Transition message={'Creating checkout...'} />)}
      <Flex
        flexDirection="column"
        maxWidth="90%"
        outset={{ horizontal: 'auto', bottom: 'x5' }}
      >
        <Text type="subtitle" alignment="center" color="primaryBody">
          • Discover the Opportunity •
        </Text>
        <Flex
          justifyContent="center"
          outset={{ vertical: 'x4' }}
          flexDirection={isTablet ? 'column' : 'row'}
          rowGap={isTablet ? 'x2' : undefined}
        >
          <Text
            type="heroTitle"
            alignment="center"
            outset={{ right: 'x3' }}
            color="primaryBody"
            weight="regular"
          >
            Promptings
          </Text>
          <Text type="heroTitle" alignment="center" color="primaryBody">
            {AFFILIATE_BRANDING.capitalized}
          </Text>
        </Flex>
        <Text type="largeBody" alignment="center">
          Become a Promptings {AFFILIATE_BRANDING.capitalized} today and begin
          your journey of Gratitude Sharing as a business.
        </Text>
        <Flex
          maxWidth={isTablet ? '100%' : '740px'}
          flexWrap="wrap"
          columnGap="x2"
          rowGap="x1_5"
          justifyContent="center"
          outset={{ horizontal: 'auto', vertical: 'x3' }}
        >
          {supportedAffiliateCountries
            .slice()
            .sort((a, b) => (a.iso === 'US' ? -1 : b.iso === 'US' ? 1 : 0))
            .map(country =>
              renderSupportedCountries(country.iso, country.name),
            )}
        </Flex>
        <Text type="largeBody" alignment="center" outset={{ bottom: 'x3' }}>
          {AFFILIATE_BRANDING.capitalizedContracted} with turn-key systems like
          this could easily cost $200 - $300/month, plus you would have to
          create your own content. We provide all of the marketing content and
          the automated online sales system for you!
        </Text>
        <Flex
          borderRadius="medium"
          overflow="hidden"
          style={{ boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)' }}
          position="relative"
          transition={{ ease: 'easeIn', duration: 0.2 }}
          {...outlinedWrapperStyle}
          whileHover={{ scale: 1.01 }}
          maxWidth="90%"
          outset={{ horizontal: 'auto' }}
          flexDirection={isTablet ? 'column' : 'row'}
        >
          {!shouldDisplayOutlinedVersion && (
            <Flex
              position="absolute"
              width="100%"
              height="2px"
              top={0}
              style={{
                background: isSelectingAnnualInterval
                  ? 'linear-gradient(99.46deg, #A168E9 -1.16%, #D1A3E6 104.4%), #FFFFFF'
                  : 'linear-gradient(90deg, #277AD4 -56%, #4AB5D9 58.83%, #67E5DD 152%)',
              }}
            />
          )}
          <Flex
            style={{
              background: shouldDisplayOutlinedVersion
                ? 'rgb(249, 250, 251)'
                : isDangerState
                ? '#FFF'
                : !isDangerState && isSelectingAnnualInterval
                ? 'linear-gradient(99.46deg, #A168E9 -1.16%, #D1A3E6 104.4%), #FFFFFF'
                : 'linear-gradient(90deg, #277AD4 -56%, #4AB5D9 58.83%, #67E5DD 152%)',
              WebkitTextFillColor: leftPanelTextColor,
            }}
            flexDirection="column"
            flexGrow={1}
            inset={{ horizontal: 'x4', vertical: 'x5' }}
            rowGap="x1_5"
          >
            <Text
              type="largeBody"
              color="accent"
              weight="bold"
              outset={{ bottom: 'x1_5' }}
            >
              Promptings {AFFILIATE_BRANDING.capitalized} Inclusions:
            </Text>
            {features.map((feature, i) => (
              <Flex alignItems="flex-start" key={`feat${i}`}>
                <Div minWidth="24px">
                  <Icon name="v_check" color={leftPanelTextColor} size={24} />
                </Div>
                <Text
                  type="body"
                  color="accent"
                  weight="semiBold"
                  outset={{ left: 'x1_5' }}
                >
                  {feature}
                </Text>
              </Flex>
            ))}
          </Flex>
          <Flex
            width={isTablet ? undefined : '400px'}
            maxWidth={isTablet ? '100%' : '400px'}
            flexDirection="column"
            inset="x4"
            style={{
              background: isDangerState
                ? 'linear-gradient(90deg, #277AD4 -56%, #4AB5D9 58.83%, #67E5DD 152%)'
                : '#FFF',
            }}
          >
            <Flex
              width="100%"
              justifyContent="center"
              alignItems="center"
              outset={{ bottom: 'x2' }}
            >
              <Flex width="168px">
                <Toggle
                  onTitle="Monthly"
                  offTitle="Annual"
                  isSelected={billingInterval === 'annual'}
                  onToggle={(isAnnual: boolean) =>
                    setBillingInterval(isAnnual ? 'annual' : 'monthly')
                  }
                  isDisabled={isInCart}
                />
              </Flex>
            </Flex>

            <Flex justifyContent="space-between" alignItems="center">
              <Icon
                name="promptings_logo"
                size={32}
                color={isDangerState ? '#FFF' : undefined}
                gradientTransform="rotate(90)"
                gradient={
                  !isDangerState
                    ? [
                        { offset: '-56%', color: '#277AD4' },
                        { offset: '58.3%', color: '#4AB5D9' },
                        { offset: '152%', color: '#67E5DD' },
                      ]
                    : undefined
                }
              />
            </Flex>
            <Text
              type="largeBody"
              weight="bold"
              content={`Promptings ${AFFILIATE_BRANDING.capitalized}`}
              outset={{ top: 'x1_5', bottom: 'x2' }}
              color={isDangerState ? 'accent' : 'primaryHeading'}
            />
            <Flex alignItems="flex-end" outset={{ bottom: 'x2' }}>
              <Text
                type="title"
                color={isDangerState ? 'accent' : 'primaryBody'}
                weight="regular"
              >
                {isSelectingAnnualInterval ? '$99' : '$14'}
              </Text>
              <Text
                type="body"
                color={isDangerState ? 'accent' : 'primaryBody'}
                outset={{ bottom: '5px' }}
              >
                {isSelectingAnnualInterval ? '/yr' : '/mo'}
              </Text>
            </Flex>
            <Flex outset={{ top: 'auto' }}>
              {isCreatingCheckout || isUpdatingCheckout ? (
                <Flex justifyContent="center" alignItems="center" width="100%">
                  <LoadingSpinner size="large" />
                </Flex>
              ) : (
                <>
                  {!isInCart && !isActivated && !isDangerState && (
                    <Button
                      title={action.title}
                      width="100%"
                      textColor={
                        isSelectingAnnualInterval ? '#A168E9' : '#4AB5D9'
                      }
                      borderRadius="0.75rem"
                      outlineColor={
                        isSelectingAnnualInterval ? '#A168E9' : '#4AB5D9'
                      }
                      outlineWidth="3px"
                      backgroundColor="#fff"
                      padding="1.5rem"
                      onClick={onBeforeAddToCart}
                    />
                  )}
                  {isInCart && !isActivated && !isDangerState && (
                    <Button
                      title="Remove from Cart"
                      width="100%"
                      textColor="rgba(248, 113, 113, 1)"
                      icon={{
                        name: 'trash',
                        color: 'rgba(248, 113, 113, 1)',
                      }}
                      borderRadius="0.75rem"
                      backgroundColor="#fff"
                      shouldDisplayBoxShadow={true}
                      boxShadow="0px 0px 12px 0px rgba(0, 0, 0, 0.04);"
                      padding="1.5rem"
                      onClick={() => removeProductFromCart(data)}
                    />
                  )}
                  {isActivated && !isDangerState && (
                    <Flex
                      width="100%"
                      borderRadius="small"
                      inset="x2"
                      justifyContent="center"
                      alignItems="center"
                      backgroundColor="rgb(238, 241, 243)"
                    >
                      <Icon name="v_check" color="#000" size={20} />
                      <Text
                        type="caption"
                        color="primaryHeading"
                        outset={{ left: 'x1' }}
                        weight="bold"
                      >
                        You're a {AFFILIATE_BRANDING.capitalized}
                      </Text>
                    </Flex>
                  )}
                </>
              )}
              {isDangerState && (
                <Flex
                  flexDirection="column"
                  rowGap="x2"
                  justifyContent="center"
                  alignItems="center"
                  outset={{ top: 'x3' }}
                >
                  <Flex
                    width="100%"
                    borderRadius="small"
                    inset="x1"
                    justifyContent="center"
                    alignItems="center"
                    backgroundColor="rgb(250, 250, 251)"
                    style={{
                      WebkitTextFillColor: 'rgba(248, 113, 113, 1)',
                    }}
                  >
                    <Icon
                      name="info"
                      color="rgba(248, 113, 113, 1)"
                      size={20}
                    />
                    <Text type="caption" outset={{ left: 'x1' }} weight="bold">
                      Currently Unavailable in your Country
                    </Text>
                  </Flex>
                  <Flex
                    style={{ textDecoration: 'underline' }}
                    onClick={onDangerCallback}
                    cursor="pointer"
                  >
                    <Text type="caption" alignment="center" weight="bold">
                      I'm a resident from the list above
                    </Text>
                  </Flex>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
        {shouldDisplayWhatShouldIDo && (
          <Flex
            outset={{ vertical: 'x5' }}
            justifyContent="center"
            style={{
              background:
                '-webkit-linear-gradient(90deg, #277AD4 -56%, #4AB5D9 58.83%, #67E5DD 152%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              textDecoration: 'underline',
              textDecorationColor: '#4AB5D9',
            }}
          >
            <Div
              cursor="pointer"
              onClick={() => console.log('What should I do?')}
            >
              <Text type="largeBody" alignment="center">
                My Country isn't listed in the list above; What can I do?
              </Text>
            </Div>
          </Flex>
        )}
      </Flex>
      {shouldConfirmCountry && (
        <Portal>
          <Dialog
            isOpen={shouldConfirmCountry}
            onClose={() => {
              setConfirmedCountryIndex(undefined)
              setShouldConfirmCountry(false)
            }}
          >
            <Text type="subtitle">Please confirm your country:</Text>
            <Spacer space="x3" />
            <Select
              type="text"
              selectedOptionIndex={confirmedCountryIndex}
              options={affiliateCountryOptionsForQDS}
              onChange={selectedOptionIndex =>
                setConfirmedCountryIndex(selectedOptionIndex)
              }
            />
            <Spacer space="x3" />
            <Button
              isDisabled={confirmedCountryIndex === undefined}
              onClick={() => {
                setShouldConfirmCountry(false)
                addToCart(variantId)
              }}
              title="Confirm my Country Selection"
              width="100%"
            />
          </Dialog>
        </Portal>
      )}
    </Flex>
  )
}
