import { Maybe } from '../../../__generated__/globalTypes'

import { AddressFragment, isAddressFragment } from './AddressFragment'

import recipientFragmentRawString from '../RecipientFragment.graphql'

export const recipientFragmentString = recipientFragmentRawString

export type RecipientFragment = {
  __typename: 'Recipient'
  id: string
  paymentFailure: Maybe<{
    id: string
  }>
  contact: Maybe<{
    __typename: 'Contact'
    id: string
    firstName: string
    lastName: string
  }>
  address: Maybe<
    AddressFragment & {
      userVerified: boolean
      errorMessages: string[]
    }
  >
  gift: Maybe<{
    name: string
    defaultImageUrl: string
  }>
  card: Maybe<{
    __typename: 'Card'
    frontPreviewUrl: string
    isHorizontal: boolean
    id: string
    sendableCard: Maybe<{
      __typename: 'SendableCard'
      id: string
      title: string
    }>
  }>
  recipientLine: Maybe<{
    __typename: 'RecipientLine'
    id: string
  }>
  cardQuantity: number
}

export const isRecipientFragment = (
  fragment: any,
): fragment is RecipientFragment =>
  fragment &&
  fragment.__typename === 'Recipient' &&
  typeof fragment.id === 'string' &&
  (fragment.paymentFailure === null ||
    (fragment.paymentFailure &&
      typeof fragment.paymentFailure.id === 'string')) &&
  (fragment.contact === null ||
    (fragment.contact &&
      fragment.contact.__typename === 'Contact' &&
      typeof fragment.contact.id === 'string' &&
      typeof fragment.contact.firstName === 'string' &&
      typeof fragment.contact.lastName === 'string')) &&
  (fragment.address === null ||
    ((isAddressFragment(fragment.address) as boolean) &&
      typeof fragment.address.userVerified === 'boolean' &&
      Array.isArray(fragment.address.errorMessages) &&
      fragment.address.errorMessages
        .slice(0, 5)
        .reduce(
          (accum: any, next: any) => accum && typeof next === 'string',
          true,
        ))) &&
  (fragment.gift === null ||
    (fragment.gift &&
      typeof fragment.gift.name === 'string' &&
      typeof fragment.gift.defaultImageUrl === 'string')) &&
  (fragment.card === null ||
    (fragment.card &&
      fragment.card.__typename === 'Card' &&
      typeof fragment.card.frontPreviewUrl === 'string' &&
      typeof fragment.card.isHorizontal === 'boolean' &&
      typeof fragment.card.id === 'string' &&
      (fragment.card.sendableCard === null ||
        (fragment.card.sendableCard &&
          fragment.card.sendableCard.__typename === 'SendableCard' &&
          typeof fragment.card.sendableCard.id === 'string' &&
          typeof fragment.card.sendableCard.title === 'string')))) &&
  (fragment.recipientLine === null ||
    (fragment.recipientLine &&
      fragment.recipientLine.__typename === 'RecipientLine' &&
      typeof fragment.recipientLine.id === 'string')) &&
  typeof fragment.cardQuantity === 'number'
