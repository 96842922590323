import React from 'react'

import { Campaign, CreateCampaign } from 'src/campaigns/components'
import Campaigns from 'src/campaigns/containers/Campaigns'
import { CreateCampaignRoute } from 'src/campaigns/routes/CreateCampaignRoute'
import { CampaignsRoute } from 'src/campaigns/routes/CampaignsRoute'
import { CampaignRoute } from 'src/campaigns/routes/CampaignRoute'

type CampaignsRouterProps = {
  route: CampaignsRoute
}

const CampaignsRouter: React.FC<CampaignsRouterProps> = ({ route }) => {
  switch (route.subroute.path) {
    case CreateCampaignRoute.path:
      return <CreateCampaign route={route.subroute} />
    case CampaignRoute.path:
      return <Campaign route={route.subroute} />
    default:
      return <Campaigns route={route} />
  }
}

export default CampaignsRouter
