import React from 'react'
import { Div, Flex, Loader } from '@sendoutcards/quantum-design-ui'
import { CampaignSubcategoryProductLoader } from './CampaignSubcategoryProductLoader'
export const CampaignSubcategoryLoader = (props: {
  productLoaderCount: number
}) => {
  return (
    <Div>
      <Loader width={200} height={24}>
        <rect width={120} height={24} rx={4} ry={4} />
      </Loader>
      <Flex
        inset={{ vertical: 'x2' }}
        style={{ gap: '16px' }}
        flexWrap="wrap"
        justifyContent={{ xSmall: 'center', medium: 'flex-start' }}
        width="100%"
      >
        {[...Array(props.productLoaderCount).keys()].map(num => (
          <CampaignSubcategoryProductLoader key={num} />
        ))}
      </Flex>
    </Div>
  )
}
