import { sortCosts } from 'src/helpers'
import { PartialLine } from './multitouch'
import recipientCount from './recipientCount'
import formatCost from './formatCost'
import { OrderWithPartialLines } from 'src/redux/reducers/orders'
import {
  CurrencyType,
  ItemizedCostFragment,
  LabeledPrice,
} from 'src/graphql/generated/graphql'

export const getCosts = (cost: ItemizedCostFragment) => {
  if (!cost) {
    return []
  }
  return sortCosts(
    cost.entries
      .filter(
        entry =>
          entry.label.indexOf('Card Upgrades') > -1 ||
          entry.currency.indexOf('CARDTOKEN') > -1 ||
          entry.label.indexOf('Volume Discount') > -1 ||
          entry.label.indexOf('Shipping') > -1 ||
          entry.label.indexOf('Processing') > -1 ||
          entry.label.indexOf('Gift Handling Fees') > -1,
      )
      .map(entry => ({
        label:
          entry.label === 'Volume Discount' ? `*${entry.label}` : entry.label,
        amount: entry.currency === 'CARDTOKEN' ? entry.amount : entry.asString,
      })),
  )
}

export const getSummedCardCost = (labeledPrices: LabeledPrice[]): number => {
  return labeledPrices
    .filter(e => e.label === 'Card Cost' && e.currency === CurrencyType.Usd)
    .reduce((prev, curr) => {
      return prev + curr.amount
    }, 0)
}

export const getLineCardBaseCost = (line: PartialLine): number => {
  return getSummedCardCost(line.baseCost?.entries ?? [])
}

export const getLineCardCost = (line: PartialLine): number => {
  return getSummedCardCost(line.cost?.entries ?? [])
}

export const getCardBaseCostFormatted = (line: PartialLine): string => {
  const baseCardCost = getLineCardBaseCost(line)
  return formatCost(baseCardCost)
}

export const getLineCardCostTotalNoDiscounts = (
  line: PartialLine,
  order: OrderWithPartialLines,
) => {
  const cardCostTotal = getLineCardBaseCost(line)
  return cardCostTotal * recipientCount(order)
}
