import { Operation } from '../../../__generated__/globalTypes'

import {
  GroupFragment,
  groupFragmentString,
} from '../../fragments/__generated__/GroupFragment'

import groupsQueryRawString from '../GroupsQuery.graphql'

const groupsQueryString = [groupsQueryRawString, groupFragmentString].join(
  '\n\n',
)

export type GroupsQueryData = {
  groups: GroupFragment[]
}

export type GroupsQueryVariables = {
  offset: number
  limit: number
}

export type GroupsQuery = Operation<GroupsQueryData, GroupsQueryVariables>

export const GroupsQuery = ({
  offset,
  limit,
}: GroupsQueryVariables): GroupsQuery => ({
  query: groupsQueryString,
  variables: {
    offset,
    limit,
  },
})
