import { Flex } from '@sendoutcards/quantum-design-ui'
import React, { FC } from 'react'
import { Tab, TabProps } from './Tab'

type TabBarProps = {
  tabs: TabProps[]
  activeTabIndex: number
  barBackgroundColor?: string
  activeTabBackgroundColor?: string
  activeTabTextColor?: string
  radius?: string
  height?: string
  shouldHideText?: boolean
}

export const TabBar: FC<TabBarProps> = ({
  tabs,
  activeTabIndex,
  radius = '100px',
  height = '62px',
  activeTabBackgroundColor,
  activeTabTextColor,
  barBackgroundColor = '#f5f6f7',
  children,
  shouldHideText = false,
}) => {
  return (
    <Flex>
      <Flex
        rowGap="x2"
        justifyContent="flex-start"
        backgroundColor={barBackgroundColor}
        inset="x_75"
        style={{ borderRadius: radius }}
        height={height}
      >
        {tabs.map((tab, index) => {
          return (
            <Tab
              key={index}
              icon={tab.icon}
              radius={radius}
              isActive={index === activeTabIndex}
              activeTabBackgroundColor={activeTabBackgroundColor}
              activeTabTextColor={activeTabTextColor}
              tabBarBackground={barBackgroundColor}
              title={tab.title}
              shouldHideText={shouldHideText}
              onClick={tab.onClick}
            />
          )
        })}
      </Flex>
      {children}
    </Flex>
  )
}
