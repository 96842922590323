import useActions from './useActions'

export const useRemoveSearchParams = () => {
  const actions = useActions()
  const newURL = window.location.pathname
  const queryParams = window.location.search

  return (params?: string[]) => {
    if (params) {
      actions.removeRouteArgs(params)
    } else {
      actions.clearRouteArgs()
    }
    const remainingParams = params
      ? queryParams
          .replace('?', '')
          .split('&')
          .filter(value => !params.find(param => value.split('=')[0] === param))
      : []
    const newQueryParams =
      remainingParams.length > 0 ? `?${remainingParams.join('&')}` : ''
    window.history.replaceState(
      {},
      document.title,
      `${newURL}${newQueryParams}`,
    )
  }
}
