import { CreateGroupInput, Operation } from '../../../__generated__/globalTypes'

import {
  DetailedGroupFragment,
  detailedGroupFragmentString,
} from '../../fragments/__generated__/DetailedGroupFragment'

import { optionalYearDateFragmentString } from '../../fragments/__generated__/OptionalYearDateFragment'

import { groupFragmentString } from '../../fragments/__generated__/GroupFragment'

import { contactFragmentString } from '../../fragments/__generated__/ContactFragment'

import createGroupRawString from '../CreateGroup.graphql'

const createGroupString = [
  createGroupRawString,
  detailedGroupFragmentString,
  optionalYearDateFragmentString,
  groupFragmentString,
  contactFragmentString,
].join('\n\n')

export type CreateGroupData = {
  createGroup: {
    group: DetailedGroupFragment
  }
}

export type CreateGroupVariables = {
  group: CreateGroupInput
}

export type CreateGroup = Operation<CreateGroupData, CreateGroupVariables>

export const CreateGroup = ({ group }: CreateGroupVariables): CreateGroup => ({
  query: createGroupString,
  variables: {
    group: CreateGroupInput(group),
  },
})
