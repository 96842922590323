import React from 'react'

import {
  BlobPositionType,
  GlassBackgroundContainer,
  GlassBlobType,
} from './GlassBackground'

export type BlobType = {
  blobs: GlassBlobType[]
  blobStart: BlobPositionType
}

type SectionContainerProps = {
  blobType?: BlobType
  children: React.ReactNode
}

export const SectionContainer = (props: SectionContainerProps) => {
  const { blobType, children } = props
  return (
    <>
      {blobType ? (
        <GlassBackgroundContainer {...blobType}>
          {children}
        </GlassBackgroundContainer>
      ) : (
        children
      )}
    </>
  )
}
