import { Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
// @src imports
import { Icon } from 'src/chrome'

import styles from '../GiftRibbon/giftRibbon.module.scss'

interface Props {
  onClick?: () => void
}

const MoreIcon: React.FC<Props> = props => {
  const { onClick } = props

  return (
    <div className={styles.more} onClick={() => onClick?.()}>
      <Text type="body" color="primaryHeading">
        SEE ALL
      </Text>
      <Icon icon={'CARROTRIGHT'} size={12} color={''} />
    </div>
  )
}

export default MoreIcon
