import React from 'react'
import { SVGProps } from './types'

export const Visa: React.FC<SVGProps> = ({ size = 24, onClick }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#a)">
        <path
          d="M13.159 6.325s.204 1.017.25 1.23h-.897c.089-.243.43-1.19.43-1.19-.006.009.088-.248.142-.407zm2.84-4.137v9.625a1.3 1.3 0 0 1-1.287 1.312H1.838a1.3 1.3 0 0 1-1.287-1.312V2.188A1.3 1.3 0 0 1 1.838.875h12.874a1.3 1.3 0 0 1 1.287 1.313M4.641 9.056l1.695-4.243h-1.14L4.142 7.71l-.115-.588-.376-1.952c-.062-.271-.252-.348-.488-.359H1.428l-.019.085q.637.167 1.132.468l.96 3.691zm2.532.006.675-4.25H6.77l-.673 4.25zm3.752-1.39c.005-.483-.285-.852-.904-1.156-.378-.194-.609-.325-.609-.525.005-.18.196-.366.62-.366.351-.009.608.076.802.16l.096.047.148-.918a2.6 2.6 0 0 0-.966-.18c-1.065 0-1.813.579-1.818 1.405-.008.61.536.949.944 1.154.415.207.558.344.558.527-.006.285-.338.416-.647.416-.429 0-.66-.068-1.01-.227l-.143-.068-.15.954c.252.117.719.221 1.201.227 1.132.003 1.87-.569 1.878-1.45m3.787 1.39-.87-4.25h-.833c-.258 0-.454.077-.564.353l-1.6 3.897h1.131l.225-.637h1.384c.032.15.129.637.129.637z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M.55 0h15.448v14H.55z" />
        </clipPath>
      </defs>
    </svg>
  )
}
