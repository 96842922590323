import { AccessLevel, Route } from 'src/routes/Route'
import { Subroute } from 'src/routes/Subroute'
import {
  closeEditContact,
  openEditContact,
  toggleIsNewContactModalOpen,
} from 'src/redux/actions'
import { GroupsRoute } from './GroupsRoute'
import { EditContactRoute } from './EditContactRoute'

const RelationshipManagerSubroute = Subroute(GroupsRoute, EditContactRoute)
export type RelationshipManagerSubroute = Subroute<
  typeof RelationshipManagerSubroute
>

export const RelationshipManagerRoute = Route({
  path: '/relationship-manager',
  accessLevel: AccessLevel.PRIVATE,
  init: (
    subroute?: RelationshipManagerSubroute,
    isNewContactModalOpen?: boolean,
  ) => ({
    subroute,
    isNewContactModalOpen,
  }),
  fromPath: () => ({ isNewContactModalOpen: false }),
  reducer: (state, action) => {
    switch (action.type) {
      case toggleIsNewContactModalOpen.type:
        return {
          ...state,
          isNewContactModalOpen: !state.isNewContactModalOpen,
        }
      case openEditContact.type:
        return {
          ...state,
          subroute: EditContactRoute(action.contactId),
        }
      case closeEditContact.type:
        return {
          ...state,
          subroute: undefined,
        }
      default:
        return state
    }
  },
  subroute: RelationshipManagerSubroute,
})
export type RelationshipManagerRoute = Route<typeof RelationshipManagerRoute>
