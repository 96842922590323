import { Operation } from '../../../__generated__/globalTypes'

import {
  ItemCategoryFragment,
  itemCategoryFragmentString,
} from '../../fragments/__generated__/ItemCategoryFragment'

import { campaignFragmentString } from '../../fragments/__generated__/CampaignFragment'

import { itemFragmentString } from '../../fragments/__generated__/ItemFragment'

import { amountFragmentString } from '../../fragments/__generated__/AmountFragment'

import { sendDelayFragmentString } from '../../fragments/__generated__/SendDelayFragment'

import { minimalPanelFragmentString } from '../../fragments/__generated__/MinimalPanelFragment'

import { giftVariationFragmentString } from '../../fragments/__generated__/GiftVariationFragment'

import { campaignLineFragmentString } from '../../fragments/__generated__/CampaignLineFragment'

import { priceFragmentString } from '../../fragments/__generated__/PriceFragment'

import getItemCategoryRawString from '../GetItemCategory.graphql'

const getItemCategoryString = [
  getItemCategoryRawString,
  itemCategoryFragmentString,
  campaignFragmentString,
  itemFragmentString,
  amountFragmentString,
  sendDelayFragmentString,
  minimalPanelFragmentString,
  giftVariationFragmentString,
  campaignLineFragmentString,
  priceFragmentString,
].join('\n\n')

export type GetItemCategoryData = {
  itemCategory: ItemCategoryFragment & {
    children: ItemCategoryFragment[]
  }
}

export type GetItemCategoryVariables = {
  id: string
}

export type GetItemCategory = Operation<
  GetItemCategoryData,
  GetItemCategoryVariables
>

export const GetItemCategory = ({
  id,
}: GetItemCategoryVariables): GetItemCategory => ({
  query: getItemCategoryString,
  variables: {
    id,
  },
})
