import { Args, CardPaperType, CardType } from 'src/graphql/generated/graphql'
import { useSelector, useQuerySponsor } from '.'
import { useRemoveSearchParams } from './useRemoveSearchParams'

export type SOCQueryParams =
  | 'join'
  | 'affiliatejoin'
  | 'promo'
  | 'payment'
  | 'postcardPromo'
  | 'shoppingcartcategory'
  | 'shoppingcartproduct'
  | 'bulkOrderPromo'
  | 'package'
  | 'promotion'
  | 'planSelected'
  | 'identifier'
  | 'goToPricing'
  | 'showUpsale'
  | 'selected'
  | 'resetPassword'
  | 'checkoutProducts'
  | 't'

type BulkOrderPromo = {
  cardId: string
  cardType: CardType
  paperType: CardPaperType
}

export type CheckoutProduct = {
  productSku: string
  quantity: number
}

export type CheckoutProducts = CheckoutProduct[]

type Package = 'free' | 'pro'

type QueryParams = Args & {
  join?: true | undefined
  sponsor?: string | undefined
  isJoiningAffiliate?: true | undefined
  isJoiningPromo?: true | undefined
  promoPlanId?: string
  payment?: true | undefined
  client?: string
  shoppingCartCategory?: string | undefined
  shoppingCartProduct?: string | undefined
  isNewJoiningAffiliateFlow?: boolean
  postcardPromo?: boolean
  bulkOrderPromo?: BulkOrderPromo
  package?: Package
  promotion?: true
  planSelected?: true
  identifier?: string
  goToPricing: true | undefined
  showUpsale: true | undefined
  selected: string | undefined
  payment_intent: string | undefined
  payment_intent_client_secret: string | undefined
  checkout: string | undefined
  resetPassword?: true
  checkoutProducts?: CheckoutProducts
  token?: string
  removeQueryParams: (params?: SOCQueryParams[]) => void
}

const bulkOrderPromoParser = (arg?: string): BulkOrderPromo | undefined => {
  if (!arg) return undefined
  const bulkData = arg.split('__')
  if (bulkData.length !== 3) return undefined
  const cardId = bulkData[0]

  const cardTypeAvailable = [CardType.Flatcard, CardType.TwoPanel]
  const cardType =
    cardTypeAvailable.find(el => bulkData[1] === el) ?? CardType.Flatcard

  const paperTypes = [CardPaperType.Std, CardPaperType.Pre, CardPaperType.Hvy]
  const paperType =
    paperTypes.find(el => bulkData[2] === el) ?? CardPaperType.Std

  return {
    cardId,
    cardType,
    paperType,
  }
}

const checkoutProductsParser = (arg?: string): CheckoutProducts | undefined => {
  if (!arg) return undefined

  return JSON.parse(decodeURI(arg)) as CheckoutProducts
}

const useQueryParams = (): QueryParams => {
  const removeQueryParams = useRemoveSearchParams()
  const args = useSelector(state => state.route.args)
  const sponsor = useQuerySponsor()

  return {
    ...args,
    join: 'join' in args ? true : undefined,
    isJoiningAffiliate: 'affiliatejoin' in args ? true : undefined,
    isJoiningPromo: 'promo' in args ? true : undefined,
    promoPlanId: 'promo' in args ? args.promo : undefined,
    sponsor: sponsor?.genealogyId,
    payment: 'payment' in args ? true : undefined,
    postcardPromo: 'postcardPromo' in args,
    shoppingCartCategory:
      'shoppingcartcategory' in args ? args.shoppingcartcategory : undefined,
    shoppingCartProduct:
      'shoppingcartproduct' in args ? args.shoppingcartproduct : undefined,
    isNewJoiningAffiliateFlow:
      'affiliatejoin' in args &&
      ('shoppingcartproduct' in args || 'shoppingcartcategory' in args),
    bulkOrderPromo:
      'bulkOrderPromo' in args
        ? bulkOrderPromoParser(args.bulkOrderPromo)
        : undefined,
    package: 'package' in args ? (args.package as Package) : undefined,
    promotion: 'promotion' in args ? true : undefined,
    planSelected: 'planSelected' in args ? true : undefined,
    identifier: 'identifier' in args ? args.identifier : undefined,
    goToPricing: 'goToPricing' in args ? true : undefined,
    showUpsale: 'showUpsale' in args ? true : undefined,
    selected: 'selected' in args ? args.selected : undefined,
    payment_intent: 'payment_intent' in args ? args.payment_intent : undefined,
    payment_intent_client_secret:
      'payment_intent_client_secret' in args
        ? args.payment_intent_client_secret
        : undefined,
    checkout: 'checkout' in args ? args.checkout : undefined,
    resetPassword: 'resetPassword' in args ? true : undefined,
    checkoutProducts:
      'checkoutProducts' in args
        ? checkoutProductsParser(args.checkoutProducts)
        : undefined,
    token: args.t,
    removeQueryParams,
  }
}

export default useQueryParams
