const removeUsersnap = () => {
  // Note: Currently we are using usersnap for beta testers for the editor, but we only display it within the editor.
  //       As for the soc-checkout beta test, we will be displaying the usersnap everywhere, so for now, we will stop
  //       removing the usersnap button from other places.
  // const buttons = document.querySelectorAll(
  //   "iframe[name='us-entrypoint-button']",
  // )
  // buttons.forEach(button => button.remove())
}

export default removeUsersnap
