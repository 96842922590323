import {
  Maybe,
  MarketingPricingGridRowItemIconChoices,
  Operation,
} from '../../../__generated__/globalTypes'

import getPricingPageString from '../GetPricingPage.graphql'

export type GetPricingPageData = {
  pricingPage: {
    id: string
    heroTitle: string
    heroSubtitle: string
    heroDescription: string
    featuredChallengeId: {
      id: string
    }
    uiBlobs: Maybe<string>
    sections: {
      order: number
      plantilesection: Maybe<{
        order: number
        title: string
        subtitle: string
        description: string
        disclaimer: string
        planIds: {
          id: string
        }[]
        uiBlobs: Maybe<string>
        featureChart: Maybe<string>
      }>
      pricinggrid: Maybe<{
        title: string
        subtitle: string
        description: string
        disclaimer: string
        order: number
        uiBlobs: Maybe<string>
        rows: {
          title: string
          items: {
            icon: MarketingPricingGridRowItemIconChoices
            title: string
            itemDescription: string
          }[]
        }[]
      }>
    }[]
  }[]
}

export type GetPricingPage = Operation<GetPricingPageData>

export const GetPricingPage = (): GetPricingPage => ({
  query: getPricingPageString,
  variables: undefined,
})
