import { Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { useSelector } from 'src/hooks'
import { LeaderType } from '../LeaderBoard'
import { RankSVG } from '../svg/rank'
import { LeaderAvatar } from './LeaderAvatar'

type EarnerListItemType = LeaderType & { rank: string }

export function EarnerListItem({
  profileImageUrl,
  firstName,
  lastName,
  dateJoined,
  earnedCount,
  rank,
}: EarnerListItemType) {
  const isSmallMobile = useSelector(state => state.window.width < 475)
  const isMobile = useSelector(state => state.window.width <= 767)
  return (
    <Flex width="100%" inset="x2" minWidth="280px">
      <Flex
        justifyContent="center"
        alignItems="center"
        style={{ display: isMobile ? 'none' : 'initial' }}
      >
        <Flex position="relative">
          <RankSVG />
          <Div
            position="absolute"
            left="50%"
            top="50%"
            transform="translate(-50%, -50%)"
          >
            <Text
              type="caption"
              weight="semiBold"
              color="primaryBody"
              content={rank}
            />
          </Div>
        </Flex>
      </Flex>
      <Flex
        width="100%"
        outset={{ left: isMobile ? '0' : '14%' }}
        justifyContent="flex-start"
        alignItems="center"
      >
        <LeaderAvatar
          profileImageUrl={profileImageUrl ?? ''}
          firstName={firstName}
          lastName={lastName}
          size={'45px'}
          badge={
            isMobile
              ? {
                  type: 'silver',
                  size: '22px',
                  placement: rank,
                  color: '#323338',
                  textSize: 'footnote',
                  isBold: false,
                }
              : undefined
          }
        />
        <Flex outset={{ left: 'x2', bottom: 'x_25' }} flexDirection="column">
          <Flex>
            <Text
              lineHeight={1.25}
              type={isSmallMobile ? 'caption' : 'body'}
              color="primaryHeading"
              weight="bold"
              content={`${firstName} ${lastName} `}
            />
          </Flex>
          {dateJoined && (
            <Flex>
              <Text
                lineHeight={1.25}
                type={isSmallMobile ? 'caption' : 'body'}
                style={{ color: '#C2B7D5' }}
                content={`Date Joined: ${dateJoined}`}
              />
            </Flex>
          )}
        </Flex>
      </Flex>
      <Flex
        justifyContent="center"
        alignItems="center"
        outset={{ right: 'x1' }}
      >
        <Text
          outset={{ left: 'x2' }}
          type="body"
          content={earnedCount}
          color="primaryHeading"
          weight="semiBold"
        />
      </Flex>
    </Flex>
  )
}
