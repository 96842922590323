import useQueryParams from './useQueryParams'
import { useUsers } from 'src/react_query'

const useSponsorFromQueryParam = () => {
  const { sponsor: qPSponsor } = useQueryParams()

  const fetchSponsor = useUsers(
    {
      search: qPSponsor ?? '',
      offset: 0,
      limit: 10,
      isAffiliate: false, // includes RC types
    },
    !!qPSponsor,
  )

  const sponsor =
    Array.isArray(fetchSponsor.data) && fetchSponsor.data.length > 0
      ? fetchSponsor.data[0]
      : undefined

  return {
    hasSponsorQP: !!qPSponsor,
    fetchSponsor,
    sponsor,
  }
}

export default useSponsorFromQueryParam
