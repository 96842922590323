import React from 'react'
import {
  AnimatedCheckMark,
  Button,
  Dialog,
  Div,
  Flex,
  Icon,
  Input,
  PhoneInput,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'

// @src imports
import { Button as ChromeButton } from 'src/chrome'

import {
  useActions,
  useDebounce,
  useEffect,
  useEmailValidation,
  usePersistedUserData,
  useState,
  useVertical,
} from 'src/hooks'

import styles from '../styles/account.module.scss'
import { LoginJoinForgot } from 'src/user/components/JoinModal/JoinModal'

import { parsePhoneNumber } from 'libphonenumber-js'
import { ButtonColorChoices } from 'src/theme/buttonColors'
import { sanitizeEmail } from 'src/helpers/sanitizeEmail'
import { getFormattedNumber } from 'src/onboarding/components/PhoneNumberVerification'
import useUsernameValidation from 'src/hooks/useUsernameValidation'
import { sanitizeUsername } from 'src/helpers/sanitizeUsername'
import usePhoneNumberCountries from 'src/hooks/usePhoneNumberCountries'
import { PhoneVerificationTerms } from './PhoneVerificationTerms'
import { useAccountQuery, useUpdateAccount } from 'src/react_query'

interface Props {
  onToggleJoinLogin?: (name: LoginJoinForgot) => void
}

const UserProfileForm: React.FC<Props> = props => {
  const { onToggleJoinLogin } = props

  const { phoneVerification } = usePersistedUserData()
  const { data: account } = useAccountQuery({ suspense: true })
  const actions = useActions()
  const updateAccountMutation = useUpdateAccount()
  const vertical = useVertical()

  if (!account) {
    throw new Error('Could not to load account')
  }

  const phoneNumberCountries = usePhoneNumberCountries()

  const getParsedPhoneNumber = () => {
    try {
      return parsePhoneNumber(account.phoneNumber, 'US')
    } catch {
      return undefined
    }
  }

  const parsedPhoneNumber = getParsedPhoneNumber()

  const [details, setDetails] = useState({
    email: account.email ?? '',
    username: account.username ?? '',
    password: '',
    firstName: account.firstName ?? '',
    lastName: account.lastName ?? '',
    phoneNumber: account.phoneNumber ?? '',
  })

  const [selectedCountryIndex, setSelectedCountryIndex] = useState(
    phoneNumberCountries.findIndex(
      country => country.isoCode === (parsedPhoneNumber?.country ?? 'US'),
    ),
  )
  const [nationalPhoneNumber, setNationalPhoneNumber] = useState(
    parsedPhoneNumber ? parsedPhoneNumber.nationalNumber.toString() : '',
  )

  const onChangePhoneNumberCountry = (newCountryCode: number) => {
    setSelectedCountryIndex(newCountryCode)
    const newPhone = `+${phoneNumberCountries[newCountryCode].callingCode}${nationalPhoneNumber}`
    setDetails({
      ...details,
      phoneNumber: newPhone,
    })
  }

  const onChangeNationalPhoneNumber = (newNationalNumber: string) => {
    const formattedNewNationalNumber = newNationalNumber.replace(/\D/g, '')
    const newPhone = `+${phoneNumberCountries[selectedCountryIndex].callingCode}${formattedNewNationalNumber}`
    setNationalPhoneNumber(formattedNewNationalNumber)
    setDetails({
      ...details,
      phoneNumber: newPhone,
    })
  }

  const debouncedEmail = useDebounce(details.email, 500)
  const emailValidation = useEmailValidation(debouncedEmail)

  const [isSaving, setIsSaving] = useState(0)
  const [isUpdateSuccessful, setIsUpdateSuccessful] = useState(false)

  const debouncedUsername = useDebounce(details.username, 500)
  const usernameValidation = useUsernameValidation(debouncedUsername)

  const onChangeDetails = (field: string, value: string) => {
    const sanitizedValue =
      field === 'username'
        ? sanitizeUsername(value)
        : field === 'email'
        ? sanitizeEmail(value)
        : value
    setDetails({
      ...details,
      [field]: sanitizedValue,
    })
  }

  const isPhoneInvalid = details.phoneNumber.search(/[^0-9 ()+-]+/) !== -1
  const isFormInvalid = isPhoneInvalid

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setIsSaving(s => s + 1)

    const userInfoUpdate = {
      ...details,
    }

    try {
      await updateAccountMutation.mutateAsync({ account: userInfoUpdate })
      setDetails(d => d && { ...d, password: '' })
      setIsUpdateSuccessful(true)
    } finally {
      setIsSaving(s => s - 1)
    }
  }

  useEffect(() => {
    const dialogTimeout = setTimeout(() => {
      setIsUpdateSuccessful(false)
    }, 3000)

    return () => {
      clearTimeout(dialogTimeout)
    }
  }, [isUpdateSuccessful, setIsUpdateSuccessful])

  const accountReady = account && account.username

  return (
    <form className={styles.userForm} onSubmit={onSubmit}>
      <Dialog isOpen={isUpdateSuccessful}>
        <Div outset={{ horizontal: 'auto' }}>
          <AnimatedCheckMark size="large" filled={true} />
        </Div>
        <Spacer space="x2" />
        <Text type="largeBody" alignment="center">
          Account updated successfully
        </Text>
      </Dialog>
      <Div width="100%">
        <Div width="100%" outset={{ vertical: 'x1' }}>
          <Div display="flex">
            <Div width="50%">
              <Text type="footnote" content="First Name" />
              <Input
                type="text"
                onChange={value => onChangeDetails('firstName', value)}
                value={details.firstName}
                placeholder="First Name"
                isFullWidth={true}
              />
            </Div>
            <Spacer space="x2" orientation="horizontal" />
            <Div width="50%">
              <Text type="footnote" content="Last Name" />
              <Input
                type="text"
                onChange={value => onChangeDetails('lastName', value)}
                value={details.lastName}
                placeholder="Last Name"
                isFullWidth={true}
              />
            </Div>
          </Div>
        </Div>
        <Div width="100%" outset={{ vertical: 'x1' }} position="relative">
          <Text type="footnote" content="Phone" />
          <Div>
            <PhoneInput
              countries={phoneNumberCountries}
              selectedCountryIndex={selectedCountryIndex}
              onSelectedCountryIndexChange={onChangePhoneNumberCountry}
              phoneNumber={getFormattedNumber(
                phoneNumberCountries[selectedCountryIndex].isoCode,
                nationalPhoneNumber,
              )}
              onPhoneNumberChange={onChangeNationalPhoneNumber}
              maxHeight="185px"
              message={
                isPhoneInvalid
                  ? {
                      type: 'danger',
                      content: 'Phone numbers may only be numbers.',
                    }
                  : undefined
              }
            />
          </Div>
          {account &&
          !account.verifiedPhoneNumber?.nationalNumber &&
          details.phoneNumber !== '' ? (
            <>
              <ChromeButton
                buttonColor={vertical.id as ButtonColorChoices}
                blinking={phoneVerification.unverifiedAccountsFound}
                type={'button'}
                id={'verify_acct_button'}
                style={{
                  marginTop: '1rem',
                }}
                innerStyle={{
                  height: 35,
                }}
                title={'Verify Phone Number'}
                onClick={() => {
                  const tempNum = parsePhoneNumber(details.phoneNumber, 'US')
                  actions.setUnverifiedAccountsFound(false)
                  actions.openPhoneNumberVerification(
                    {
                      countryIsoCode: tempNum.country ? tempNum.country : 'US',
                      nationalNumber: tempNum.nationalNumber.toString(),
                    },
                    false,
                  )
                }}
              />
              <PhoneVerificationTerms />
            </>
          ) : (
            <Div
              display="flex"
              flexDirection="row"
              columnGap="x_5"
              inset={{ top: 'x1_5' }}
            >
              <Icon primaryColor="success" name="complete" size="small" />
              <Text
                type="body"
                weight="semiBold"
                whiteSpace="nowrap"
                content={'Account Verified'}
              />
            </Div>
          )}
        </Div>
        <Div width="100%" outset={{ vertical: 'x1' }}>
          <Text type="footnote" content="Email" />
          <Input
            type="email"
            onChange={value => onChangeDetails('email', value)}
            value={details.email}
            placeholder="Email"
            message={
              emailValidation.errorMessage
                ? { type: 'danger', content: emailValidation.errorMessage }
                : undefined
            }
            isFullWidth={true}
            isLoading={emailValidation.isLoading}
          />
        </Div>
        <Div width="100%" outset={{ vertical: 'x1' }}>
          <Text type="footnote" content="Username" />
          <Input
            type="text"
            onChange={value => onChangeDetails('username', value)}
            value={details.username}
            placeholder="Username"
            message={
              !usernameValidation.isValid &&
              !!details.username &&
              !usernameValidation.isLoading
                ? { type: 'danger', content: 'This username is taken' }
                : undefined
            }
            isFullWidth={true}
            isLoading={usernameValidation.isLoading}
          />
        </Div>
        <Div width="100%" outset={{ vertical: 'x1' }}>
          <Text
            type="footnote"
            content={accountReady ? 'Change Password' : 'Password'}
          />
          <Input
            type="password"
            onChange={value => onChangeDetails('password', value)}
            value={details.password}
            placeholder={accountReady ? 'New Password' : 'Password'}
            isFullWidth={true}
          />
        </Div>
      </Div>
      <Flex width="100%" flexDirection="column">
        <Spacer space="x2" />
        <Div width="100%">
          <Button
            fullWidth={true}
            type={'secondary'}
            onClick="submitForm"
            title={
              isSaving
                ? 'Saving...'
                : account && account.username
                ? 'Save'
                : 'Join'
            }
            disabled={
              isSaving > 0 ||
              isFormInvalid ||
              details.email !== debouncedEmail ||
              !emailValidation.isValid ||
              emailValidation.isLoading ||
              details.username !== debouncedUsername ||
              !usernameValidation.isValid ||
              usernameValidation.isLoading ||
              !!!details.firstName ||
              !!!details.lastName ||
              !!!details.email ||
              !!!details.username ||
              (!accountReady && details.password === '')
            }
          />
        </Div>
        <Spacer space="x1" />
        {onToggleJoinLogin && (
          <Button
            hover={true}
            type="secondary"
            outlined={true}
            onClick={() => onToggleJoinLogin?.('login')}
            title="Return to Login"
          />
        )}
      </Flex>
    </form>
  )
}

export default UserProfileForm
