import { Operation } from '../../../__generated__/globalTypes'

import {
  UserImageFragment,
  userImageFragmentString,
} from '../../fragments/__generated__/UserImageFragment'

import { imageFragmentString } from '../../fragments/__generated__/ImageFragment'

import getImagesRawString from '../GetImages.graphql'

const getImagesString = [
  getImagesRawString,
  userImageFragmentString,
  imageFragmentString,
].join('\n\n')

export type GetImagesData = {
  images: UserImageFragment[]
}

export type GetImagesVariables = {
  offset: number
  limit: number
}

export type GetImages = Operation<GetImagesData, GetImagesVariables>

export const GetImages = ({
  offset,
  limit,
}: GetImagesVariables): GetImages => ({
  query: getImagesString,
  variables: {
    offset,
    limit,
  },
})
