import React, { FC } from 'react'
import { SVGProps } from './types'

export const ShareSettingsIcon: FC<SVGProps> = ({
  size = 24,
  onClick,
  color = '#323232',
}) => {
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick && (() => onClick())}
      viewBox="0 0 24 24"
    >
      <path
        d="M10 14.75H6.2C2.78 14.75 1.25 13.22 1.25 9.80005V6.19995C1.25 2.77995 2.78 1.25 6.2 1.25H9.8C13.22 1.25 14.75 2.77995 14.75 6.19995V10C14.75 10.41 14.42 10.74 14.01 10.75C11.61 10.79 10.79 11.62 10.75 14.01C10.74 14.42 10.41 14.75 10 14.75ZM6.2 2.75C3.62 2.75 2.75 3.61995 2.75 6.19995V9.80005C2.75 12.38 3.62 13.25 6.2 13.25H9.29C9.53 10.81 10.81 9.54004 13.25 9.29004V6.19995C13.25 3.61995 12.38 2.75 9.8 2.75H6.2Z"
        fill={color}
      />
      <path
        d="M5.19952 6.63014C4.96952 6.63014 4.72952 6.52019 4.58952 6.31019 4.34952 5.97019 4.42952 5.50015 4.76952 5.26015 5.54952 4.71015 6.59951 4.70013 7.38951 5.24013 7.72951 5.47013 7.81952 5.94017 7.58952 6.28017 7.35952 6.62017 6.88951 6.71012 6.54951 6.48012 6.27951 6.30012 5.91952 6.30013 5.64952 6.49013 5.49952 6.59013 5.34952 6.63014 5.19952 6.63014zM9.19952 6.63014C8.96952 6.63014 8.72952 6.52019 8.58952 6.31019 8.34952 5.97019 8.42952 5.50015 8.76952 5.26015 9.54952 4.71015 10.5995 4.70013 11.3895 5.24013 11.7295 5.47013 11.8195 5.94017 11.5895 6.28017 11.3595 6.62017 10.8895 6.71012 10.5495 6.48012 10.2795 6.30012 9.91952 6.30013 9.64952 6.49013 9.49952 6.59013 9.34952 6.63014 9.19952 6.63014zM8.16078 12.1702H5.84078C5.13078 12.1702 4.55078 11.5901 4.55078 10.8801 4.55078 8.98013 6.10078 7.43018 8.00078 7.43018 8.78078 7.43018 9.55078 7.70006 10.1608 8.19006 10.4808 8.45006 10.5408 8.92011 10.2808 9.24011 10.0208 9.56011 9.55078 9.61011 9.23078 9.36011 8.88078 9.08011 8.46078 8.93018 8.01078 8.93018 7.01078 8.93018 6.18078 9.69017 6.07078 10.6702H8.17078C8.58078 10.6702 8.92078 11.0102 8.92078 11.4202 8.92078 11.8302 8.57078 12.1702 8.16078 12.1702zM17.8 22.75H14.2C10.78 22.75 9.25 21.22 9.25 17.8V14C9.31 10.81 10.81 9.31 13.99 9.25H17.8C21.22 9.25 22.75 10.78 22.75 14.2V17.8C22.75 21.22 21.22 22.75 17.8 22.75zM14 10.75C11.62 10.79 10.79 11.62 10.75 14.01V17.8C10.75 20.38 11.62 21.25 14.2 21.25H17.8C20.38 21.25 21.25 20.38 21.25 17.8V14.2C21.25 11.62 20.38 10.75 17.8 10.75H14z"
        fill={color}
      />
      <path
        d="M14.0895 14.6301C13.6195 14.6301 13.1595 14.4901 12.7695 14.2101 12.4295 13.9701 12.3495 13.5002 12.5895 13.1602 12.8295 12.8202 13.2995 12.7401 13.6395 12.9801 13.9095 13.1701 14.2695 13.1701 14.5395 12.9901 14.8795 12.7601 15.3495 12.8401 15.5795 13.1901 15.8095 13.5301 15.7295 14.0001 15.3795 14.2301 14.9895 14.5001 14.5395 14.6301 14.0895 14.6301zM18.0895 14.6301C17.6195 14.6301 17.1595 14.4901 16.7695 14.2101 16.4295 13.9701 16.3495 13.5002 16.5895 13.1602 16.8295 12.8202 17.2995 12.7401 17.6395 12.9801 17.9095 13.1701 18.2695 13.1701 18.5395 12.9901 18.8795 12.7601 19.3495 12.8401 19.5795 13.1901 19.8095 13.5301 19.7295 14.0001 19.3795 14.2301 18.9895 14.5001 18.5395 14.6301 18.0895 14.6301zM16.0008 20.1702C14.1008 20.1702 12.5508 18.6201 12.5508 16.7201 12.5508 16.0101 13.1308 15.4302 13.8408 15.4302H18.1608C18.8708 15.4302 19.4508 16.0101 19.4508 16.7201 19.4508 18.6201 17.9008 20.1702 16.0008 20.1702zM14.0608 16.9302C14.1608 17.9102 14.9908 18.6702 16.0008 18.6702 17.0108 18.6702 17.8308 17.9102 17.9408 16.9302H14.0608z"
        fill={color}
      />
    </svg>
  )
}
