import React from 'react'
import moment, { Moment } from 'moment'

import Paper from '@material-ui/core/Paper'
import { CalendarDateRangePicker, CloseButton } from 'src/chrome'
import { useRef, useState } from 'src/hooks'

import { DateSearchFilter, SearchFilter } from '../types'
import FilterChip from './FilterChip'

import styles from '../filteredSearchBar.module.scss'

type Props = {
  filters: SearchFilter[]
  filter: DateSearchFilter
  onClear: () => void
  updateFilter: <T extends SearchFilter>(filter: T, update: Partial<T>) => void
  deleteFilter: (filter: SearchFilter) => void
  onClose: () => void
}

const DateRangeInput: React.FC<Props> = props => {
  const {
    updateFilter,
    onClear,
    filters,
    filter,
    deleteFilter,
    onClose,
  } = props

  const [dates, setDates] = useState<{
    start: moment.Moment
    end: moment.Moment
  }>()
  const dropdownRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [inputValue, setInputValue] = useState('')

  const handleClear = () => {
    setInputValue('')
    onClear()
  }

  const handleChangeDate = (start?: Moment, end?: Moment) =>
    setDates({
      start: start ?? moment(),
      end: end ?? start ?? moment(),
    })

  const definedFilters = filters.filter(filter => filter.value !== undefined)

  return (
    <>
      <div className={styles.filterChips}>
        <div className={styles.filterTypeContainer} ref={dropdownRef}>
          <Paper style={{ width: '100%' }}>
            <CalendarDateRangePicker
              onCancel={onClose}
              onChangeDate={handleChangeDate}
              onSubmit={(start, end) => {
                updateFilter(filter, {
                  value: {
                    start: start ?? moment(),
                    end: end ?? start ?? moment(),
                  },
                })
                onClose()
              }}
              shouldHideYear={true}
            />
          </Paper>
        </div>

        <div className={styles.chips}>
          {filters.map(filter => (
            <FilterChip
              key={Math.random()}
              filter={filter}
              onDelete={deleteFilter}
            />
          ))}
        </div>
      </div>

      <input
        style={{ height: 45 }}
        type="text"
        className={styles.searchInput}
        placeholder={
          dates
            ? `${dates.start.format('MMM D')} - ${dates.end.format('MMM D')}`
            : 'Pick dates'
        }
        disabled={true}
        ref={inputRef}
      />

      {(definedFilters.length > 0 || inputValue) && (
        <CloseButton onClose={() => handleClear()} size={18} />
      )}
    </>
  )
}

export default DateRangeInput
