import { Maybe } from '../../../__generated__/globalTypes'

import {
  SendableCardImageFragment,
  isSendableCardImageFragment,
} from './SendableCardImageFragment'

import sendableCardVariationFragmentRawString from '../SendableCardVariationFragment.graphql'

export const sendableCardVariationFragmentString = sendableCardVariationFragmentRawString

export type SendableCardVariationFragment = {
  __typename: 'SendableCard'
  id: string
  frontImage: SendableCardImageFragment
  insideRightImage: Maybe<SendableCardImageFragment>
  variationColor: string
}

export const isSendableCardVariationFragment = (
  fragment: any,
): fragment is SendableCardVariationFragment =>
  fragment &&
  fragment.__typename === 'SendableCard' &&
  typeof fragment.id === 'string' &&
  (isSendableCardImageFragment(fragment.frontImage) as boolean) &&
  (fragment.insideRightImage === null ||
    (isSendableCardImageFragment(fragment.insideRightImage) as boolean)) &&
  typeof fragment.variationColor === 'string'
