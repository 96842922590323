import React from 'react'

type TrophyType = {
  height?: string
  width?: string
  backgroundColor?: string
}

export function Trophy({
  height = '8px',
  width = '10px',
  backgroundColor = '#DCAD52',
}: TrophyType) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.603516"
        y="0.103455"
        width="29.7931"
        height="29.7931"
        rx="14.8966"
        fill={backgroundColor}
      />
      <path
        d="M15.2143 15V11.7H14.6443L14.8843 10.8H16.1143V15H15.2143Z"
        fill="white"
      />
      <path
        d="M15.6002 19.9C15.3269 19.9 15.1002 19.6733 15.1002 19.4V18C15.1002 17.7267 15.3269 17.5 15.6002 17.5C15.8736 17.5 16.1002 17.7267 16.1002 18V19.4C16.1002 19.6733 15.8736 19.9 15.6002 19.9Z"
        fill="white"
      />
      <path
        d="M19.4336 22.1667H11.767V21C11.767 19.9867 12.587 19.1667 13.6003 19.1667H17.6003C18.6136 19.1667 19.4336 19.9867 19.4336 21V22.1667ZM12.767 21.1667H18.4336V21C18.4336 20.54 18.0603 20.1667 17.6003 20.1667H13.6003C13.1403 20.1667 12.767 20.54 12.767 21V21.1667Z"
        fill="white"
      />
      <path
        d="M19.6003 22.1666H11.6003C11.3269 22.1666 11.1003 21.94 11.1003 21.6666C11.1003 21.3933 11.3269 21.1666 11.6003 21.1666H19.6003C19.8736 21.1666 20.1003 21.3933 20.1003 21.6666C20.1003 21.94 19.8736 22.1666 19.6003 22.1666ZM19.7871 15.2934C19.6471 15.2934 19.5071 15.2334 19.4071 15.12C19.2805 14.9734 19.2471 14.7667 19.3271 14.5934C19.5538 14.0734 19.6671 13.52 19.6671 12.94V10.94C19.6671 10.7067 19.6271 10.48 19.5471 10.24C19.5405 10.22 19.5338 10.1934 19.5271 10.1667C19.5071 10.0667 19.5005 9.96669 19.5005 9.87335C19.5005 9.60002 19.7271 9.37335 20.0005 9.37335H20.4005C21.5938 9.37335 22.5671 10.3734 22.5671 11.6067C22.5671 12.6267 22.1471 13.6334 21.4205 14.36C21.4071 14.3734 21.3671 14.4067 21.3605 14.4134C20.9671 14.74 20.5205 15.1067 19.9205 15.2734C19.8738 15.2867 19.8338 15.2934 19.7871 15.2934ZM20.6205 10.3934C20.6538 10.5734 20.6671 10.76 20.6671 10.94V12.94C20.6671 13.2134 20.6471 13.4734 20.6071 13.74C20.6471 13.7067 20.6805 13.68 20.7205 13.6467C21.2538 13.1134 21.5671 12.3667 21.5671 11.6067C21.5671 11.0067 21.1605 10.5 20.6205 10.3934ZM11.2203 15.2667C11.1669 15.2667 11.1203 15.26 11.0669 15.24C10.5203 15.0667 10.0069 14.7467 9.58026 14.32C8.81359 13.4734 8.43359 12.5467 8.43359 11.5667C8.43359 10.3534 9.38693 9.40005 10.6003 9.40005H11.0336C11.2003 9.40005 11.3603 9.48671 11.4536 9.62671C11.5469 9.76671 11.5603 9.94671 11.4936 10.1C11.3869 10.34 11.3336 10.6134 11.3336 10.9V12.9C11.3336 13.4734 11.4469 14.0334 11.6803 14.5667C11.7603 14.7467 11.7203 14.9534 11.5936 15.1C11.4936 15.2067 11.3603 15.2667 11.2203 15.2667ZM10.3669 10.42C9.82693 10.5267 9.43359 10.9934 9.43359 11.5667C9.43359 12.2934 9.72693 12.9934 10.3069 13.6334C10.3336 13.6667 10.3669 13.6934 10.4003 13.72C10.3536 13.4467 10.3336 13.1734 10.3336 12.9V10.9C10.3336 10.74 10.3469 10.58 10.3669 10.42Z"
        fill="white"
      />
      <path
        d="M15.5 18.1666C12.6534 18.1666 10.3334 15.8466 10.3334 13V11C10.3334 9.25331 11.7534 7.83331 13.5 7.83331H17.5C19.2467 7.83331 20.6667 9.25331 20.6667 11V13C20.6667 15.8466 18.3467 18.1666 15.5 18.1666ZM13.5 8.83331C12.3067 8.83331 11.3334 9.80665 11.3334 11V13C11.3334 15.3 13.2 17.1666 15.5 17.1666C17.8 17.1666 19.6667 15.3 19.6667 13V11C19.6667 9.80665 18.6934 8.83331 17.5 8.83331H13.5Z"
        fill="white"
      />
    </svg>
  )
}
