import {
  Button,
  Dialog,
  Div,
  Flex,
  Icon,
  Spacer,
  Span,
  Text,
} from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { useSelector } from 'src/hooks'
import ItemThumbnail, { ItemThumbnailProps } from './components/ItemThumbnail'

interface ReplaceOrderItemDialogProps {
  title: string
  subtitle: string
  onCancel?: () => void
  onConfirm: () => void
  onClose?: () => void
  isOpen: boolean
  currentItem: ItemThumbnailProps
  swapToItem?: ItemThumbnailProps
}

const ReplaceOrderItemDialog: React.FC<ReplaceOrderItemDialogProps> = props => {
  const {
    title,
    subtitle,
    onCancel,
    onConfirm,
    onClose,
    isOpen,
    currentItem,
    swapToItem,
  } = props

  const { width } = useSelector(state => state.window)
  const isInitialBreakPoint = width <= 670
  const isSecondaryBreakPoint = width <= 550

  const titleType = isInitialBreakPoint ? 'largeBody' : 'subtitle'
  const descriptionType = isInitialBreakPoint ? 'footnote' : 'caption'
  const sizeType = isInitialBreakPoint ? 'small' : 'medium'
  const columnGap = isSecondaryBreakPoint
    ? 'x0'
    : isInitialBreakPoint
    ? 'x5'
    : 'x8'
  const rowGap = isSecondaryBreakPoint ? 'x1_5' : 'x0'

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      maxWidth="900px"
      width="100%"
      borderRadius="large"
    >
      <Flex flexDirection="column" id="replace_order_dialog">
        <Flex
          width="100%"
          justifyContent="center"
          alignItems="center"
          inset={{ vertical: 'x5' }}
          flexDirection={isSecondaryBreakPoint ? 'column' : 'row'}
          columnGap={columnGap}
          rowGap={rowGap}
        >
          <ItemThumbnail {...currentItem} />
          <Span
            style={{ transform: isSecondaryBreakPoint ? 'rotate(90deg)' : '' }}
          >
            <Icon
              orientation="left"
              name="swap"
              size={isSecondaryBreakPoint ? 'medium' : 'large'}
              primaryColor="primaryHeadingText"
            />
          </Span>
          {swapToItem && <ItemThumbnail {...swapToItem} />}
        </Flex>
        <Div maxWidth="400px" outset="0 auto">
          <Text
            type={titleType}
            content={title}
            alignment="center"
            weight="bold"
            color="primaryHeading"
            lineHeight={2.5}
          />
          <Text
            type={descriptionType}
            content={subtitle}
            alignment="center"
            lineHeight={1.2}
          />
        </Div>
        <Flex
          width={isInitialBreakPoint ? '100%' : '70%'}
          outset="0 auto"
          inset={{ top: 'x5', bottom: 'x3' }}
          flexDirection={isSecondaryBreakPoint ? 'column-reverse' : 'row'}
          rowGap={isSecondaryBreakPoint ? 'x2' : 'x0'}
        >
          <Button
            size={sizeType}
            title="Keep Current Gift"
            type="danger"
            outlined={true}
            onClick={onCancel}
            fullWidth={true}
          />
          {!isSecondaryBreakPoint && (
            <Spacer space="x5" orientation="horizontal" />
          )}
          <Button
            size={sizeType}
            id="replace_order_confirm_btn"
            title="Confirm and Continue"
            type="secondary"
            onClick={onConfirm}
            fullWidth={true}
          />
        </Flex>
      </Flex>
    </Dialog>
  )
}

export default ReplaceOrderItemDialog
