import React from 'react'
import {
  Button,
  Div,
  Flex,
  Icon,
  Image,
  LoadingSpinner,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { useActions, useCallback, useSelector, useState } from 'src/hooks'
import { LoginForm } from 'src/user/components'
import { useCreateSparseUser } from 'src/react_query'

export type GuestOrLoginFormType = {
  asset?: {
    image: string
    title: string
    description?: string
  }
  onComplete?: () => void
}

export const GuestOrLoginForm: React.FC<GuestOrLoginFormType> = ({
  asset,
  onComplete,
}) => {
  const [shouldShowLoginScreen, setShouldShowLoginScreen] = useState(false)
  const [didForgetPassword, setDidForgetPassword] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const isSheetMobile = useSelector(state => state.window.width < 770)

  const createSparseUserMutation = useCreateSparseUser()
  const actions = useActions()

  const handleCreateSparseUser = useCallback(async () => {
    setIsLoading(true)
    const {
      createSparseUser: { account },
    } = await createSparseUserMutation.mutateAsync({})

    setIsLoading(false)
    actions.updatedAccount(account)
    onComplete?.()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSparseUserMutation])

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      width="100%"
    >
      {isLoading ? (
        <Flex
          justifyContent="center"
          alignItems="center"
          minHeight={isSheetMobile ? '372px' : '314px'}
          flexDirection="column"
        >
          <Div outset={{ bottom: 'x4' }}>
            <LoadingSpinner size="large" />
          </Div>

          <Text
            type={isSheetMobile ? 'body' : 'subtitle'}
            content="Setting up Guest Account . . ."
          />
        </Flex>
      ) : (
        <>
          {shouldShowLoginScreen && (
            <Flex
              onClick={() => setShouldShowLoginScreen(false)}
              position="absolute"
              top="x2"
              left="x2"
              justifyContent="center"
              alignItems="center"
              cursor="pointer"
            >
              <Icon
                size="small"
                primaryColor="primaryBodyText"
                name="leftChevron"
              />
              <Text type="body" content="Back" />
            </Flex>
          )}
          <Flex
            width="100%"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            {asset && (
              <Flex
                width="100%"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                outset={{ bottom: 'x3' }}
              >
                <Image
                  width="150px"
                  height="150px"
                  isActive={true}
                  image={{ url: asset.image }}
                />

                <Text
                  alignment={isSheetMobile ? 'center' : 'left'}
                  type="subtitle"
                  weight="bold"
                  whiteSpace="normal"
                  content={asset.title}
                  outset={{ bottom: 'x2', top: 'x4' }}
                />
              </Flex>
            )}
          </Flex>
          {shouldShowLoginScreen ? (
            <LoginForm
              hasFreeCardFlow={false}
              onComplete={onComplete}
              didForgetLoginInfo={didForgetPassword}
              setForgotPassword={setDidForgetPassword}
            />
          ) : (
            <Flex
              flexDirection={isSheetMobile ? 'column' : 'row'}
              width="100%"
              outset={{ bottom: 'x2' }}
            >
              <Div outset={{ right: 'x1' }} width="100%">
                <Button
                  fullWidth={true}
                  title="Login"
                  onClick={() => setShouldShowLoginScreen(true)}
                />
              </Div>
              <Div
                outset={isSheetMobile ? { top: 'x2' } : { left: 'x1' }}
                width="100%"
              >
                <Button
                  onClick={handleCreateSparseUser}
                  outlined={true}
                  fullWidth={true}
                  title="Continue as Guest"
                />
              </Div>
            </Flex>
          )}
        </>
      )}
    </Flex>
  )
}
