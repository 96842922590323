import { NormalizedData } from 'src/legacy_graphql'
import { OperationFactory, Query, QueryFactory } from './Query'
import { updatedSparseResults } from './PaginatedQuery'

export const ListQuery = <
  Data,
  Typename extends keyof NormalizedData,
  Result extends { __typename: Typename; id: string },
  Variables
>({
  operation,
  mapData,
  resultTypename,
  isValidResult,
  filter,
  orderBy,
}: {
  operation: OperationFactory<Data, Variables>
  mapData: (data: Data) => Result[]
  resultTypename: Typename
  isValidResult: (result: unknown) => result is Result
  filter: (
    result: Result,
    variables: Omit<Variables, 'offset' | 'limit'>,
  ) => boolean
  orderBy: (result: Result) => string
}): QueryFactory<Result[], Variables> => {
  return Query({
    operation,
    mapResult: result => result.map(mapData),
    comapVariables: variables => variables,
    updateState: (results, allChanges, variables) =>
      updatedSparseResults(
        results,
        allChanges,
        variables,
        resultTypename,
        isValidResult,
        filter,
        orderBy,
      ).results,
    mapState: results => results,
  })
}
