import { Interpolation } from '@emotion/css'

const styles = {
  container: {
    position: 'fixed',
    top: 65,
    right: 10,
    zIndex: 1001,
    minWidth: 300,
    '@media (max-width: 599px)': {
      top: 0,
      right: 0,
      width: '100%',
    },
  } as Interpolation,
  bodyContainer: {
    display: 'flex',
    maxWidth: 600,
    boxShadow: '0 4px 10px 0 #bdbaba',
  } as Interpolation,
  iconContainer: {
    flex: '1 1 20%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  } as Interpolation,
  body: {
    background: '#fff',
    flex: '1 1 80%',
    display: 'flex',
    alignItems: 'center',
    padding: 15,
  } as Interpolation,
  alertText: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    marginLeft: 20,
  } as Interpolation,
}

export default styles
