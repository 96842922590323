import {
  CardFragment,
  CardType,
  ColorFragment,
  FontFragment,
  LayoutFragment,
  StickerFragment,
  TextAlignment,
  UserImageFragment,
} from 'src/graphql/generated/graphql'
import {
  EditorElement,
  EditorFullBleed,
  EditorImage,
  EditorPanel,
} from 'src/editor/EditorCard'
import {
  Card,
  Element,
  FullBleed,
  Panel,
} from '@sendoutcards/editor/dist/types/card'
import { Color, Image, Layout } from '@sendoutcards/editor/dist/types/primary'

export const isInsideRightPanel = (
  cardType: CardType,
  fullBleedLocation: number,
  panelLocation: number,
) => {
  switch (cardType) {
    case CardType.Postcard:
    case CardType.Flatcard:
      return fullBleedLocation === 1 && panelLocation === 0
    case CardType.ThreePanel:
      return fullBleedLocation === 2 && panelLocation === 0
    case CardType.TwoPanel:
    case CardType.TwoPanelBig:
    default:
      return fullBleedLocation === 1 && panelLocation === 1
  }
}

export const updateFullBleed = (
  fullBleeds: EditorFullBleed[],
  fullBleedIndex: number,
  update: (fullBleed: EditorFullBleed) => EditorFullBleed,
) =>
  fullBleeds.map((fullBleed, index) =>
    index === fullBleedIndex ? update(fullBleed) : fullBleed,
  )

export const updatePanel = (
  fullBleeds: EditorFullBleed[],
  fullBleedIndex: number,
  panelIndex: number,
  update: (panel: EditorPanel) => EditorPanel,
) =>
  updateFullBleed(fullBleeds, fullBleedIndex, fullBleed => ({
    ...fullBleed,
    panels: fullBleed.panels.map((panel, index) =>
      index === panelIndex ? update(panel) : panel,
    ),
  }))

export const updateElement = (
  fullBleeds: EditorFullBleed[],
  fullBleedIndex: number,
  panelIndex: number | undefined,
  elementIndex: number,
  update: (element: EditorElement, panel?: EditorPanel) => EditorElement,
) =>
  typeof panelIndex === 'number'
    ? updatePanel(fullBleeds, fullBleedIndex, panelIndex, panel => ({
        ...panel,
        elements: panel.elements.map((element, index) =>
          index === elementIndex ? update(element, panel) : element,
        ),
      }))
    : updateFullBleed(fullBleeds, fullBleedIndex, fullBleed => ({
        ...fullBleed,
        backgroundElement: fullBleed.backgroundElement
          ? update(fullBleed.backgroundElement)
          : null,
      }))

export const updateElementImage = (
  fullBleeds: EditorFullBleed[],
  fullBleedIndex: number,
  panelIndex: number | undefined,
  elementIndex: number,
  update: (image: EditorImage) => EditorImage,
) =>
  updateElement(
    fullBleeds,
    fullBleedIndex,
    panelIndex,
    elementIndex,
    element => ({
      ...element,
      image: element.image ? update(element.image) : null,
    }),
  )

export const newEditorColor = ({ red, green, blue }: ColorFragment): Color => ({
  r: red,
  g: green,
  b: blue,
})

export const userImageFragmentToImageType = (
  image: UserImageFragment,
): Image => ({
  width: image.width,
  height: image.height,
  id: image.id,
  url: image.url,
  thumbnailUrl: image.url, // TODO smallThumb is too low resolution, can we get something in between smallThumb & url?
})

export const stickerFragmentToImageType = (
  sticker: StickerFragment,
): Image => ({
  width: sticker.image.width,
  height: sticker.image.height,
  id: sticker.id,
  url: sticker.image.url,
  thumbnailUrl: sticker.image.url, // TODO smallThumb is too low resolution, can we get something in between smallThumb & url?
})

const newEditorElement = (fonts: FontFragment[]) => (
  element: EditorElement,
): Element => {
  const elementCopy = { ...element }
  return {
    z: elementCopy.z,
    rotation: elementCopy.rotation,
    locked: elementCopy.locked,
    x: elementCopy.x,
    y: elementCopy.y,
    width: elementCopy.width,
    height: elementCopy.height,
    draggable: element.draggable ?? false,
    image: element.image
      ? {
          ...element.image,
          image: element.image.image
            ? {
                ...element.image.image,
                thumbnailUrl: element.image.image.smallThumb,
              }
            : null,
        }
      : null,
    text: element.text && {
      fragments: element.text.map(({ fontId, ...textFragment }) => ({
        ...textFragment,
        color: newEditorColor(textFragment.color),
        signatureFontId: textFragment.signatureFontId,
        signatureFontFamily: fonts.find(
          font => font.id === textFragment.signatureFontId,
        )?.fontName,
        fontFamily:
          fonts.find(font => font.id === fontId)?.fontName || 'HelveticaV2',
      })),
    },
  }
}

export const newEditorPanel = (fonts: FontFragment[]) => (
  panel: EditorPanel,
): Panel => {
  const panelCopy = { ...panel }
  return {
    name: panelCopy.name,
    isLocked: panelCopy.isLocked,
    x: panelCopy.x,
    y: panelCopy.y,
    width: panelCopy.width,
    height: panelCopy.height,
    backgroundColor:
      panel.backgroundColor && newEditorColor(panel.backgroundColor),
    elements: panel.elements.map(newEditorElement(fonts)),
  }
}
export const newEditorCard = (
  card: CardFragment,
  fullBleeds: EditorFullBleed[],
  fonts: FontFragment[],
  variationID: string | null,
): Card => {
  // Spreading the card object into an implicit return "bloats" the "Sendogo"
  // Card with uneccessary properties that exist on the "SOC" Card object
  const cardCopy = { ...card }
  return {
    backPanelLocation: cardCopy.backPanelLocation,
    type: cardCopy.type,
    orientation: cardCopy.isHorizontal ? 'LANDSCAPE' : 'PORTRAIT',
    fullBleeds: fullBleeds.map(fullBleed => ({
      ...fullBleed,
      backgroundElement:
        fullBleed.backgroundElement &&
        newEditorElement(fonts)(fullBleed.backgroundElement),
      panels: fullBleed.panels.map(newEditorPanel(fonts)),
    })),
    variationState:
      cardCopy.detailedSendableCard?.variations && variationID
        ? {
            variations: [
              {
                id: cardCopy.detailedSendableCard.id,
                color: cardCopy.detailedSendableCard.variationColor,
                frontImage: {
                  id:
                    cardCopy.panels[0].elements.find(
                      e => e.locked && e.image && e.image.image,
                    )?.image?.image?.id ?? '',
                  height: cardCopy.detailedSendableCard.frontImage.height,
                  width: cardCopy.detailedSendableCard.frontImage.width,
                  url:
                    cardCopy.panels[0].elements.find(
                      e => e.locked && e.image && e.image.image,
                    )?.image?.image?.url ?? '',
                  thumbnailUrl:
                    cardCopy.detailedSendableCard.frontImage.smallThumb,
                },
                insideRightImage: cardCopy.detailedSendableCard.insideRightImage
                  ? {
                      url: cardCopy.detailedSendableCard.insideRightImage.url,
                      height:
                        cardCopy.detailedSendableCard.insideRightImage.height,
                      width:
                        cardCopy.detailedSendableCard.insideRightImage.width,
                      thumbnailUrl:
                        cardCopy.detailedSendableCard.insideRightImage
                          .smallThumb,
                      id: cardCopy.detailedSendableCard.insideRightImage.id,
                    }
                  : undefined,
              },
              ...cardCopy.detailedSendableCard.variations.map(v => ({
                id: v.id,
                color: v.variationColor,
                frontImage: {
                  id:
                    v.cardTemplate?.panels[0].elements.find(
                      e => e.locked && e.image && e.image.image,
                    )?.image?.image?.id ?? '',
                  height: v.frontImage.height,
                  width: v.frontImage.width,
                  url:
                    v.cardTemplate?.panels[0].elements.find(
                      e => e.locked && e.image && e.image.image,
                    )?.image?.image?.url ?? '',
                  thumbnailUrl: v.frontImage.smallThumb,
                },
                insideRightImage: v.insideRightImage
                  ? {
                      id: v.insideRightImage?.id,
                      height: v.insideRightImage?.height,
                      width: v.insideRightImage?.width,
                      url: v.insideRightImage?.url,
                      thumbnailUrl: v.insideRightImage.smallThumb,
                    }
                  : undefined,
              })),
            ],
            selectedVariationID: variationID,
          }
        : undefined,
  }
}

export const newEditorLayout = (fonts: FontFragment[]) => (
  layout: LayoutFragment,
): Layout => ({
  ...layout,
  panel: newEditorPanel(fonts)({
    ...layout.panel,
    backgroundColor: layout.panel.backgroundColor ?? null,
    x: 0,
    y: 0,
    elements: layout.panel.elements.map(EditorElement),
  }),
})

const editorColor = ({ r, g, b }: Color): ColorFragment => ({
  __typename: 'Color',
  red: r,
  green: g,
  blue: b,
})

const editorFontId = (
  signatureFontFamily: string | undefined,
  fonts: FontFragment[],
  fontFamily: string,
): string => {
  const signatureFont = fonts.find(
    font => font.fontName === signatureFontFamily,
  )?.id
  const regularFont = fonts.find(font => font.fontName === fontFamily)?.id
  const defaultFont = fonts.find(font => font.fontName === 'HelveticaV2')?.id

  if (signatureFontFamily && signatureFont) {
    return (
      fonts.find(font => font.fontName === signatureFontFamily)?.id ??
      fonts[0].id
    )
  } else if (regularFont) {
    return regularFont
  } else if (defaultFont) {
    return defaultFont
  } else {
    return fonts[0].id
  }
}

const editorElement = (
  element: Element,
  fonts: FontFragment[],
): EditorElement => ({
  __typename: 'Element',
  ...element,
  draggable: element.draggable ?? false,
  image: element.image && {
    ...element.image,
    image: element.image.image && {
      ...element.image.image,
      smallThumb: element.image.image.url,
      __typename: 'Image',
    },
  },
  text:
    element.text &&
    element.text.fragments.map(({ fontFamily, ...fragment }) => ({
      ...fragment,
      text: fragment.signatureFontFamily ? 'æ' : fragment.text,
      color: editorColor(fragment.color),
      fontId: editorFontId(fragment.signatureFontFamily, fonts, fontFamily),
      textAlign:
        fragment.textAlign === 'LEFT'
          ? TextAlignment.Left
          : fragment.textAlign === 'CENTER'
          ? TextAlignment.Center
          : fragment.textAlign === 'RIGHT'
          ? TextAlignment.Right
          : undefined,
    })),
})

export const editorPanel = (
  panel: Panel,
  fonts: FontFragment[],
): EditorPanel => ({
  ...panel,
  backgroundColor: panel.backgroundColor && editorColor(panel.backgroundColor),
  elements: panel.elements.map(element => editorElement(element, fonts)),
})

export const editorFullBleed = (fonts: FontFragment[]) => (
  fullBleed: FullBleed,
): EditorFullBleed => ({
  ...fullBleed,
  panels: fullBleed.panels.map(panel => editorPanel(panel, fonts)),
  backgroundElement:
    fullBleed.backgroundElement &&
    editorElement(fullBleed.backgroundElement, fonts),
})
