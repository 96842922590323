import React from 'react'
import { SVGProps } from './types'

export const Info: React.FC<SVGProps> = ({
  size = 24,
  color = '#323232',
  onClick,
}) => {
  return (
    <svg
      width={`${size}px`}
      height="auto"
      viewBox="0 0 9 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g clipPath="url(#clip0_1339_89595)">
        <path
          d="M4.2632 6.10352V3.36908H5.05608V6.10352H4.2632ZM4.65964 2.98789C4.51394 2.98789 4.39534 2.94553 4.30386 2.86082C4.21237 2.77611 4.16663 2.67107 4.16663 2.5457C4.16663 2.42033 4.21237 2.31529 4.30386 2.23058C4.39534 2.14587 4.51394 2.10352 4.65964 2.10352C4.80534 2.10352 4.92393 2.14418 5.01542 2.2255C5.10691 2.30343 5.15265 2.40508 5.15265 2.53045C5.15265 2.6626 5.10691 2.77272 5.01542 2.86082C4.92732 2.94553 4.80873 2.98789 4.65964 2.98789Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.5 4C0.5 1.79086 2.29086 0 4.5 0C6.70914 0 8.5 1.79086 8.5 4C8.5 6.20914 6.70914 8 4.5 8C2.29086 8 0.5 6.20914 0.5 4ZM4.5 0.666667C2.65905 0.666667 1.16667 2.15905 1.16667 4C1.16667 5.84095 2.65905 7.33333 4.5 7.33333C6.34095 7.33333 7.83333 5.84095 7.83333 4C7.83333 2.15905 6.34095 0.666667 4.5 0.666667Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1339_89595">
          <rect width="8" height="8" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
