import React, { FC } from 'react'
// @src imports
import { Icon, SuspenseBoundary } from 'src/chrome'

import { FlexColumn } from 'src/styled'

import { useQueryFutures } from 'src/hooks'

// relative imports
import BaseCreditCard from '../BaseCreditCard/BaseCreditCard'

import styles from './styles'
import {
  Accordion,
  Div,
  HStack,
  LoadingSpinner,
  Spacer,
  Text,
  VStack,
} from '@sendoutcards/quantum-design-ui'
import { getDetailedReferralCredits } from 'src/legacy_graphql'
import { Currency } from 'src/graphql/generated/graphql'
import { useCreditsBalance } from 'src/react_query'

const CreditsUnavailable: FC = () => (
  <Text type="caption" color="danger">
    Your credits balance is unavailable at the moment, try again later.
  </Text>
)

const ReferralCredits: FC = () => {
  const { data: credits, isLoading } = useCreditsBalance()
  const [detailedCredits] = useQueryFutures(getDetailedReferralCredits())

  if (isLoading) {
    return <LoadingSpinner size="medium" />
  }

  const isUnavailable =
    credits === undefined || credits.currency === Currency.Unavailable

  const accordionSections =
    detailedCredits.value && detailedCredits.value?.length > 0
      ? [
          {
            key: 1,
            heading: <Text weight="bold" type="caption" content={'Details'} />,
            body: (
              <VStack gap={'x1'} inset={'x0'}>
                {detailedCredits.value.map(detail => (
                  <Div key={detail.id}>
                    <HStack justify="space-between" gap={'x1'} inset={'x0'}>
                      <Text type="body" weight="bold" color="primaryHeading">
                        {detail.amount.description}
                      </Text>
                      <HStack justify="flex-end" gap="x_5" shouldWrap={true}>
                        <Text type="body">Expires on</Text>
                        <Text type="body" weight="bold" color="primaryHeading">
                          {detail.expirationDate}
                        </Text>
                      </HStack>
                    </HStack>
                  </Div>
                ))}
              </VStack>
            ),
          },
        ]
      : null

  return (
    <>
      {isUnavailable ? (
        <CreditsUnavailable />
      ) : (
        <>
          <Spacer space="x3" />
          <span css={styles.amount}>{credits.description}</span>
          <Text type="caption" weight="bold">
            Credits
          </Text>
          {accordionSections ? (
            <Div css={{ width: '100%' }}>
              <Accordion sections={accordionSections} />
            </Div>
          ) : (
            <Spacer space="x3" />
          )}
        </>
      )}
    </>
  )
}

const ReferralCreditsCard: FC = () => {
  return (
    <BaseCreditCard
      css={{
        backgroundColor: '#fff',
        border: 'none',
        height: 'auto !important',
      }}
    >
      <FlexColumn css={styles.container} id={'referral_credits_card'}>
        <div css={styles.header}>
          <div css={styles.flex}>
            <Icon icon={'MONEY'} />
            <Text
              type="caption"
              color="primaryHeading"
              outset={{ top: 'x_25' }}
              inset={{ left: 'x1' }}
              weight="bold"
            >
              Credits
            </Text>
          </div>
        </div>
        <div css={{ ...styles.flex, ...styles.contents }}>
          <FlexColumn css={styles.flexColumn}>
            <SuspenseBoundary
              unresolved={
                <Div outset={{ vertical: 'x2' }}>
                  <LoadingSpinner size="medium" />
                </Div>
              }
              failure={() => <CreditsUnavailable />}
            >
              <ReferralCredits />
            </SuspenseBoundary>
          </FlexColumn>
        </div>
      </FlexColumn>
    </BaseCreditCard>
  )
}

export default ReferralCreditsCard
