import { Maybe, Operation } from '../../../__generated__/globalTypes'

import bulkDeleteSendableCardsString from '../BulkDeleteSendableCards.graphql'

export type BulkDeleteSendableCardsData = {
  bulkDeleteSendableCards: {
    successful: boolean
    failedCount: Maybe<number>
  }
}

export type BulkDeleteSendableCardsVariables = {
  cards: string[]
}

export type BulkDeleteSendableCards = Operation<
  BulkDeleteSendableCardsData,
  BulkDeleteSendableCardsVariables
>

export const BulkDeleteSendableCards = ({
  cards,
}: BulkDeleteSendableCardsVariables): BulkDeleteSendableCards => ({
  query: bulkDeleteSendableCardsString,
  variables: {
    cards,
  },
})
