import React from 'react'
import { useActions } from 'src/hooks'
import { ReplaceCampaignCardRoute } from '../../routes/ReplaceCampaignCardRoute'
import { CampaignRoute } from 'src/campaigns/routes/CampaignRoute'
import CardPicker from 'src/card_picker/CardPicker'
import { buildInput } from '../../utils'
import { CardActionsProps } from '../CampaignDetail/Campaign'
import { Text } from '@sendoutcards/quantum-design-ui'
import { useUpdateCampaign } from 'src/react_query'
import Editor from 'src/editor/containers/Editor/Editor'
import { FullWindowModal } from 'src/chrome'
import { CardRoute } from 'src/card/routes/CardRoute'

type Props = CardActionsProps & {
  reload: () => void
  route: ReplaceCampaignCardRoute
}

const ReplaceCampaignCard: React.FC<Props> = props => {
  const { editableCampaign, campaignRoute, resetRoute, reload, route } = props
  const actions = useActions()
  const updateCampaignMutation = useUpdateCampaign()
  const { cardId: oldCardId } = route

  const handleReplace = async (cardId: string, newCardId: string) => {
    const campaignInput = buildInput(editableCampaign)
    await updateCampaignMutation.mutateAsync({
      campaign: {
        ...campaignInput,
        lines: (campaignInput.lines || []).map(line => ({
          ...line,
          card: line.card === cardId ? newCardId : line.card,
        })),
      },
    })
    reload()
    resetRoute()
  }

  const replaceCardContext = {
    headerText: `Campaign: ${editableCampaign.name}`,
    headerSubText: `Replacing card`,
    lines: editableCampaign.lines,
    activeCardId: oldCardId,
    footerLabel: () => (
      <Text type="caption" color="primaryHeading" weight="bold">
        {editableCampaign.lines.length} card
        {editableCampaign.lines.length > 1 && 's'}
      </Text>
    ),
  }

  if (route.subroute && route.subroute.path === CardRoute.path) {
    const newCardId = route.subroute.cardId
    return (
      <FullWindowModal isReallyFull={true}>
        <Editor
          context={replaceCardContext}
          cardId={newCardId}
          onAfterSave={() => handleReplace(oldCardId, newCardId)}
          onClose={actions.dismissedCatalogSubroute}
          onBackToCatalog={actions.dismissedCatalogSubroute}
          saveButtonLabel="Replace Card"
        />
      </FullWindowModal>
    )
  }

  return (
    <CardPicker
      onSelectCard={selectedCardId =>
        actions.openCampaigns(
          CampaignRoute(
            campaignRoute.campaignId,
            ReplaceCampaignCardRoute(oldCardId, CardRoute(selectedCardId)),
          ),
        )
      }
      context={replaceCardContext}
      catalogSubroute={route.subroute}
      onClose={resetRoute}
      onCatalogNavigate={subroute =>
        actions.openCampaigns(
          CampaignRoute(
            campaignRoute.campaignId,
            ReplaceCampaignCardRoute(oldCardId, subroute),
          ),
        )
      }
    />
  )
}

export default ReplaceCampaignCard
