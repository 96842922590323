import React from 'react'
import posed from 'react-pose'

import { useMemo } from 'src/hooks'

import { PosedReactComponent } from '../PoseFade/PoseFade'
import { createPropsGetter, wrappedInjectSheet } from 'src/helpers'
import { CSSClasses } from 'src/app/types'

// types __________________________________________________
type ComponentProps = {
  isOpen: boolean
} & Partial<DefaultProps>

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps = {
  edge: 'bottom' as 'top' | 'bottom' | 'left' | 'right',
  shouldAnimateWithParent: false,
}

const getProps = createPropsGetter(defaultProps)

type Props = {
  classes: CSSClasses<typeof styles>
} & ComponentProps
// types __________________________________________________

// styles _________________________________________________
const styles = {
  container: {
    width: 'fit-content',
    height: 'fit-content',
    zIndex: 1000,
    overflow: 'hidden',
  },
}
// styles _________________________________________________

const getAnimatorProps = (props: Props) => {
  const { edge } = getProps(props)
  const isTopOrBottom = edge === 'top' || edge === 'bottom'
  const anchorEdge = isTopOrBottom ? 'left' : 'top'
  const closed = isTopOrBottom ? '-100vh' : '-100vw'

  return {
    opened: {
      position: 'fixed',
      [edge]: isTopOrBottom ? '0vh' : '0vw',
      [anchorEdge]: '0%',
      zIndex: 1000,
      transition: ({ i }: { i: number }) => ({
        type: 'spring',
        damping: 28,
        stiffness: 190,
      }),
    },
    closed: {
      position: 'fixed',
      [edge]: closed,
      [anchorEdge]: isTopOrBottom ? '0vh' : '0vw',
      zIndex: 1000,
      transition: ({ i }: { i: number }) => ({
        type: 'spring',
        damping: 28,
        stiffness: 100,
      }),
    },
    props: { i: 0 },
  }
}

// component ______________________________________________
const Slide: React.FC<Props> = props => {
  const Animator = useMemo<PosedReactComponent>(
    () => posed.div(getAnimatorProps(props)),
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [props.edge],
  ) as PosedReactComponent

  const { classes, children, isOpen, shouldAnimateWithParent } = getProps(props)
  return (
    <div className={classes.container}>
      <Animator
        pose={isOpen ? 'opened' : 'closed'}
        withParent={shouldAnimateWithParent}
      >
        {children}
      </Animator>
    </div>
  )
}

// export _________________________________________________
export default wrappedInjectSheet<React.FC<ComponentProps>>(styles, Slide)
