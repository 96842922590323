import { Card, Div, Flex, Icon, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import {
  SelectedItemCard,
  SelectedItemCardProps,
} from '../shared/SelectedItemCard'
import { useState } from 'src/hooks'

type StepType = {
  children: React.ReactNode
  content: string[]
}

type StoryTellingType = {
  selectedItem: SelectedItemCardProps
  steps: StepType[]
  action: { title: string; onClick: () => void }
  onClose: () => void
}

export const StorytellingModal: React.FC<StoryTellingType> = ({
  selectedItem,
  action,
  onClose,
  steps,
}) => {
  const [currentStep, setCurrentStep] = useState<number>(0)

  const nextStep = () => {
    setCurrentStep(prevStep => (prevStep + 1) % steps.length)
  }

  const prevStep = () => {
    setCurrentStep(prevStep => (prevStep - 1 + steps.length) % steps.length)
  }

  return (
    <Flex
      justifyContent="end"
      height="initial"
      alignItems="center"
      width="100%"
    >
      <Flex
        backgroundColor="rgba(252, 251, 251, 0.8)"
        position="absolute"
        style={{ backdropFilter: 'blur(1px)' }}
        top="x0"
        left="x0"
        width="100%"
        height="100%"
      />
      <Card
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        width="100%"
        style={{ padding: '0' }}
        position="absolute"
        left="50%"
        top="50%"
        transform={'translate(-50%, -50%)'}
        backgroundColor="white"
        maxWidth="560px"
      >
        <Flex
          position="absolute"
          top="12px"
          right="12px"
          onClick={onClose}
          cursor="pointer"
        >
          <Icon name="close" size="xSmall" primaryColor="primaryHeadingText" />
        </Flex>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <SelectedItemCard
            id={selectedItem.id}
            icon={selectedItem.icon}
            title={selectedItem.title}
            description={selectedItem.description}
            price={selectedItem.price}
            recurrence={selectedItem.recurrence}
            backgroundColor={selectedItem.backgroundColor}
          />
          {steps[currentStep] && (
            <Flex
              flexDirection="column"
              width="100%"
              justifyContent="center"
              alignItems="center"
              inset={{ top: 'x2', bottom: '112px', horizontal: 'x4' }}
            >
              {steps[currentStep].children}
              {steps[currentStep].content.map((paragraph, index) => (
                <Text
                  key={index}
                  outset={{ vertical: 'x2' }}
                  alignment="center"
                  type="body"
                  content={paragraph}
                />
              ))}
            </Flex>
          )}
          <Flex position="absolute" bottom="8%" right="24px">
            <Flex
              justifyContent="center"
              alignItems="center"
              onClick={prevStep}
              backgroundColor="#F3F4F6"
              borderRadius="12px"
              boxShadow="light"
              width="44px"
              height="44px"
              outset={{ right: 'x1' }}
            >
              <Icon
                name={'chevron'}
                orientation="left"
                size="xSmall"
                primaryColor="primaryHeadingText"
              />
            </Flex>
            <Flex
              justifyContent="center"
              alignItems="center"
              onClick={nextStep}
              backgroundColor="#F3F4F6"
              borderRadius="12px"
              boxShadow="light"
              width="44px"
              height="44px"
              outset={{ left: 'x1' }}
            >
              <Icon
                name={'chevron'}
                orientation="right"
                size="xSmall"
                primaryColor="primaryHeadingText"
              />
            </Flex>
          </Flex>
          <Flex
            flexDirection="column"
            width="10px"
            position="absolute"
            top="30%"
            right="24px"
          >
            {steps.map((step, index) => (
              <Div
                key={index}
                style={{
                  width: '14px',
                  height: currentStep === index ? '30px' : '14px',
                  borderRadius: currentStep === index ? '7px' : '50%',
                  cursor: 'pointer',
                }}
                outset="x1"
                borderColor={currentStep === index ? '#383838' : '#919191'}
                borderWidth="bold"
                borderStyle="solid"
                backgroundColor={currentStep === index ? '#383838' : 'none'}
                onClick={() => setCurrentStep(index)}
              />
            ))}
          </Flex>
        </Flex>
      </Card>
    </Flex>
  )
}
