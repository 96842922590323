import { Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'

import { Button } from 'src/chrome'
import { CardCategoryFragment } from 'src/legacy_graphql'

import styles from './search.module.scss'
import { useActions } from 'src/hooks'

type SearchTagsProps = {
  buttonClick?: () => void
  categories: CardCategoryFragment[] ///// array
  className?: string
  onClick?: (category: CardCategoryFragment) => void
  selectedTag?: CardCategoryFragment
}

const SearchTags: React.FC<SearchTagsProps> = props => {
  const { className, categories, selectedTag, onClick } = props
  const actions = useActions()
  return (
    <div
      className={
        className ? `${styles.searchTags} ${className}` : styles.searchTags
      }
    >
      <Button
        title={'Build Your Own'}
        id={'create_your_own_card_btn'}
        className={styles.buildYourOwn}
        buttonColor={'blue'}
        style={{ order: 3 }}
        onClick={() => actions.toggleIsBlankCardModalOpen()}
      />
      {categories.map((category, i: number) => {
        const isActive = selectedTag === category
        const activeStyle: React.CSSProperties = {
          fontWeight: 'bold',
          color: '#333',
          order: i + 1,
        }
        return (
          <div
            id={`${category.description
              .split(' ')
              .join('_')
              .toLowerCase()}_catalog_btn`}
            key={i}
            onClick={() => {
              onClick?.(category)
            }}
            style={i === 3 ? {} : isActive ? activeStyle : { order: i + 1 }}
          >
            <Text type="caption" alignment="center">
              {category.description}
            </Text>
          </div>
        )
      })}
    </div>
  )
}

export default SearchTags
