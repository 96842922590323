import React from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'src/hooks'

type CarouselProps = {
  images: { url: string; alt: string }[]
}

export const CampaignCarousel: React.FC<CarouselProps> = ({ images }) => {
  const [[current, direction], setCurrent] = useState([0, 0])

  const imageVariants = {
    enter: (direction: number) => ({
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    }),
    center: {
      x: 0,
      opacity: 1,
    },
    exit: (direction: number) => ({
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    }),
  }

  const swipeConfidenceThreshold = 10000
  const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity
  }

  const paginate = (newDirection: number) => {
    let mutableNextIndex = current + newDirection
    if (mutableNextIndex < 0) {
      mutableNextIndex = images.length - 1
    } else if (mutableNextIndex >= images.length) {
      mutableNextIndex = 0
    }
    setCurrent([mutableNextIndex, newDirection])
  }
  interface CarouselStyles {
    container: React.CSSProperties
    image: React.CSSProperties
    next: React.CSSProperties
    prev: React.CSSProperties
  }
  const carouselStyles: CarouselStyles = {
    container: {
      position: 'relative',
      width: '200px',
      height: '200px',
      overflow: 'hidden',
    },
    image: {
      position: 'absolute',
      cursor: 'grab',
      maxWidth: '100%',
      maxHeight: '100%',
      width: 'auto',
      height: 'auto',
      margin: 'auto',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
    },
    next: {
      position: 'absolute',
      top: '50%',
      width: '30px',
      height: '30px',
      background: 'rgba(255, 255, 255, 0.8)',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      right: '10px',
    },
    prev: {
      position: 'absolute',
      top: '50%',
      width: '30px',
      height: '30px',
      background: 'rgba(255, 255, 255, 0.8)',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      left: '10px',
      transform: 'rotate(180deg)',
    },
  }

  return (
    <div className="carousel" style={carouselStyles.container}>
      <AnimatePresence initial={false} custom={direction}>
        <motion.img
          key={current}
          src={images[current].url}
          custom={direction}
          variants={imageVariants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: 'spring', stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 },
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x)
            if (swipe < -swipeConfidenceThreshold) {
              paginate(1)
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1)
            }
          }}
          style={carouselStyles.image}
        />
      </AnimatePresence>
      <div
        className="next"
        onClick={() => paginate(1)}
        style={carouselStyles.next}
      >
        {'‣'}
      </div>
      <div
        className="prev"
        onClick={() => paginate(-1)}
        style={carouselStyles.prev}
      >
        {'‣'}
      </div>
    </div>
  )
}
