import React from 'react'

type TrendTriangleType = {
  trend: 'up' | 'down'
  height?: number
  width?: number
}

export function TrendTriangle({
  trend,
  height = 8,
  width = 10,
}: TrendTriangleType) {
  const backgroundColor = trend === 'down' ? '#FF7474' : '#40AC6B'
  const direction = trend === 'up' ? 'rotate(180deg)' : 'rotate(0)'
  return (
    <svg
      style={{ transform: direction }}
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.13397 1C4.51887 0.333334 5.48113 0.333333 5.86603 1L8.89711 6.25C9.28201 6.91667 8.80089 7.75 8.03109 7.75H1.96891C1.19911 7.75 0.717986 6.91667 1.10289 6.25L4.13397 1Z"
        fill={backgroundColor}
      />
    </svg>
  )
}
