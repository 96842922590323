import { Result } from '../utils/Result'
import { Action } from 'src/redux/action'
import { Operation } from 'src/legacy_graphql'
import { Icons, IconType as ChromeIconType } from 'src/chrome/Icon/icons'
import { IconType } from '@sendoutcards/quantum-design-ui'

export type SmallOrLarge = 'small' | 'large'

export type CSSClasses<styles> = { [key in keyof styles]: string }

// Icon Stuff
export type IconTypeOrIconString = ChromeIconType | Icons

export interface Icon {
  svg: IconType
  color?: string
  size?: string
  viewBox?: string
}

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export type Task<Data = any, Variables = any> = {
  operation: Operation<Data, Variables>
  hitCDNCache?: boolean
  isFormData?: boolean
  completion: (result: Result<Data>) => Action
}

export const Task = <Data, Variables>(
  operation: Operation<Data, Variables>,
  completion: (result: Result<Data>) => Action,
  hitCDNCache?: boolean,
  isFormData?: boolean,
): Task<Data> => ({
  operation,
  completion,
  hitCDNCache,
  isFormData,
})
