import paperCostsFragmentRawString from '../PaperCostsFragment.graphql'

export const paperCostsFragmentString = paperCostsFragmentRawString

export type PaperCostsFragment = {
  value: string
  upgrade: string
}

export const isPaperCostsFragment = (
  fragment: any,
): fragment is PaperCostsFragment =>
  fragment &&
  typeof fragment.value === 'string' &&
  typeof fragment.upgrade === 'string'
