export const getFormatedDate = (date: string) => {
  const dateObject = new Date(date)
  const dayOfMonth = dateObject.getUTCDate()
  const dayOfWeekNumber = dateObject.getUTCDay()
  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const monthIndex = dateObject.getUTCMonth()
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const year = dateObject.getUTCFullYear()

  return {
    weekday: daysOfWeek[dayOfWeekNumber],
    day: dayOfMonth,
    month: monthNames[monthIndex],
    year: year,
  }
}
