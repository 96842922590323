import { OrderWithPartialLines } from 'src/redux/reducers/orders'

const onlyHasGift = (order: OrderWithPartialLines) => {
  if (!order.lines) {
    return false
  } // this means they are editing a pending card
  if (order.lines.length > 1) {
    return false
  }
  return (
    order.lines.length === 1 &&
    !order.lines[0].card &&
    !!order.lines[0].giftVariation
  )
}

export default onlyHasGift
