import React from 'react'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

import { FontFragment } from 'src/graphql/generated/graphql'
import { textEditor } from 'src/helpers'

import { Api } from '../api'
import { Steps } from '../types'

type Props = {
  api: Api
  signatures: FontFragment[]
}

const InsertSignature: React.FC<Props> = props => {
  const { signatures, api } = props
  const utils =
    api.step.type === Steps.EditText
      ? textEditor(api.updateText, api.step, api.isMobile)
      : undefined

  return signatures.length ? (
    <TextField
      SelectProps={{
        MenuProps: {
          style: {
            maxHeight: 200,
          },
        },
      }}
      label="Insert Signature"
      select={true}
      value={null}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        utils?.insertSignature(event.target.value)
      }
      id="insert_signature_text_field"
    >
      {signatures.map((signature, index) => (
        <MenuItem
          key={signature.fontName}
          value={signature.id}
          id={`signature_${index}`}
        >
          <span style={{ fontFamily: signature.fontName, fontSize: '40px' }}>
            æ
          </span>
        </MenuItem>
      ))}
    </TextField>
  ) : null
}

export default InsertSignature
