import { Route } from 'src/routes/Route'
import { Section } from 'src/card_history/types'
import { SearchFilter } from 'src/chrome/FilteredSearchBar/types'
import { Subroute } from 'src/routes/Subroute'

export const CardHistoryEditCardRoute = Route({
  path: '/:cardId/edit',
  init: (cardId: string) => ({ cardId }),
  fromPath: ({ cardId }) => (cardId ? { cardId } : undefined),
  hideSidebar: true,
})

export const CardHistoryResendCardRoute = Route({
  path: '/:cardId/resend',
  init: (cardId: string) => ({ cardId }),
  fromPath: ({ cardId }) => (cardId ? { cardId } : undefined),
  hideSidebar: true,
})

export const CardHistorySubroute = Subroute(
  CardHistoryEditCardRoute,
  CardHistoryResendCardRoute,
)

export type CardHistorySubroute = Subroute<typeof CardHistorySubroute>

export const CardHistoryRoute = Route({
  path: '/card-history',
  init: (
    section?: Section,
    filters?: SearchFilter[],
    subroute?: CardHistorySubroute,
  ) => ({ subroute, section, filters }),
  fromPath: () => ({
    section: undefined,
    filters: undefined,
    subroute: undefined,
  }),
  subroute: CardHistorySubroute,
})
export type CardHistoryRoute = Route<typeof CardHistoryRoute>
