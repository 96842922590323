import React, { ButtonHTMLAttributes, CSSProperties } from 'react'

import { Icon, Icons, IconType } from 'src/chrome'
import { ButtonColorChoices } from 'src/theme/buttonColors'

import styles from './styles'

const keyframes = `@keyframes blinking-animation {
  0% {
    background-color: #ffffff;
    color: #e841b3;
  }
  12.5% {
    background-color: #e9d9fd;
    color: #e480d8;
  }
  25% {
    background-color: #e2b0f0;
    color: #ffffff;
  }
  37.5% {
    background-color: #e480d8;
    color: #e9d9fd;
  }
  50% {
    background-color: #e841b3;
    color: #ffffff;
  }
  62.5% {
    background-color: #e480d8;
    color: #e9d9fd;
  }
  75% {
    background-color: #e2b0f0;
    color: #ffffff;
  }
  87.5% {
    background-color: #e9d9fd;
    color: #e480d8;
  }
  100% {
    background-color: #ffffff;
    color: #e841b3;
  }
  }`

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  id?: string
  buttonColor?: ButtonColorChoices
  title?: string
  titleStyle?: CSSProperties
  innerStyle?: CSSProperties
  icon?: IconType | Icons
  iconSize?: number
  iconColor?: string
  blinking?: boolean
}

const Button: React.FC<ButtonProps> = props => {
  const {
    id,
    buttonColor,
    disabled: isDisabled,
    icon,
    iconColor,
    iconSize,
    innerStyle,
    title,
    titleStyle,
    blinking: isBlinking,
    ...restProps
  } = props

  return (
    <>
      <style children={keyframes} />
      <button
        id={id}
        css={theme => styles.gradientButton(theme, buttonColor, isDisabled)}
        {...restProps}
      >
        <div
          css={theme => styles.inner(theme, buttonColor, isBlinking)}
          style={innerStyle}
        >
          {icon && <Icon icon={icon} size={iconSize} color={iconColor} />}
          <span style={titleStyle}>{title}</span>
        </div>
      </button>
    </>
  )
}

export default Button
