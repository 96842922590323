import React from 'react'
import {
  ComparisonColumn,
  ComparisonRow,
  ComparisonTable,
} from './components/ComparisonTable'
import { Flex, Text } from '@sendoutcards/quantum-design-ui'
import {
  activePlans,
  tableFeatures,
} from 'src/points_conversion/data/pointsConversionData'
import {
  ActivePlan,
  FeatureContent,
  FeatureSet,
  FeatureType,
  PlanData,
} from 'src/points_conversion/data/type'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
// import { useCurrentPlanSku } from 'src/hooks/useCurrentPlanSku'
import { StampsIncludedIcon } from './Icons/StampsIncludedIcon'
import { StampsExcluded } from './Icons/StampsExcluded'
import { usePlansSubscriptions } from 'src/hooks'

type ComparisonChartProps = {
  activePlans: { [key in ActivePlan]: PlanData<ActivePlan> }
  tableFeature: FeatureType[]
  selectedPlanData: PlanData<ActivePlan>
  setSelectedPlanData: React.Dispatch<
    React.SetStateAction<PlanData<ActivePlan>>
  >
}

export const ComparisonChart: React.FC<ComparisonChartProps> = () => {
  const allPlans = { ...activePlans }

  const { getCurrentPlanSku } = usePlansSubscriptions()
  const currentPlanSku = getCurrentPlanSku()
  const [currentPlanData] = (Object.keys(allPlans) as Array<
    keyof typeof allPlans
  >)
    .filter(key => {
      const plan = allPlans[key]
      return plan.sku === currentPlanSku
    })
    .map(key => allPlans[key])

  const planColumns = (Object.keys(activePlans) as Array<
    keyof typeof activePlans
  >).map(planKey => {
    const id = activePlans[planKey].id

    const backgroundColor = activePlans[planKey].tableHeadingBgColor

    return (
      <ComparisonColumn
        key={planKey}
        backgroundColor={backgroundColor ?? '#8B8B8B'}
        heading={planKey}
        isActive={currentPlanData?.id === id}
      >
        {(Object.keys(activePlans[planKey].tableFeatures) as Array<
          keyof FeatureSet
        >).map((featureKey, index) => {
          const feature = activePlans[planKey].tableFeatures[featureKey]

          const mainFeature =
            typeof feature.main === 'object' ? (
              <Flex
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                <Text
                  type="caption"
                  content={feature.main.content}
                  outset={{ right: 'x1' }}
                />
                {feature.main.type === 'stamps_excluded' ? (
                  <StampsExcluded />
                ) : (
                  <StampsIncludedIcon />
                )}
              </Flex>
            ) : feature.main === 'excluded' ? (
              <Icon name="x" color="#e6525d" size={14} />
            ) : feature.main === 'included' ? (
              <Icon name="check" color="#20966f" size={16} />
            ) : feature.main === 'included_with_stamps' ? (
              <Icon name="double_check" color="#20966f" size={14} />
            ) : (
              <Text
                type="caption"
                alignment="center"
                content={feature.main}
                style={{ fontSize: '14px', fontWeight: 400, color: '#606060' }}
              />
            )

          const secondaryFeature = feature.secondary?.content

          return (
            <ComparisonRow
              key={featureKey}
              alignment="center"
              backgroundColor={index % 2 ? '#f8f8f8' : '#fcfcfc'}
            >
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {mainFeature}
                {secondaryFeature && (
                  <>
                    <Text
                      type="footnote"
                      alignment="center"
                      content={secondaryFeature}
                      style={{
                        fontSize: '10px',
                        color: '#676767',
                      }}
                      whiteSpace="nowrap"
                    />
                  </>
                )}
              </div>
            </ComparisonRow>
          )
        })}
      </ComparisonColumn>
    )
  })

  const aggregateFeatures = () => {
    const map = new Map<FeatureType, FeatureContent['main'][]>()
    ;(Object.keys(activePlans) as Array<keyof typeof activePlans>).forEach(
      key => {
        ;(Object.keys(activePlans[key].tableFeatures) as Array<
          keyof FeatureSet
        >).forEach(featureKey => {
          const content = activePlans[key].tableFeatures[featureKey]

          const mapValue = map.get(featureKey)

          if (mapValue) {
            map.set(featureKey, [...mapValue, content.main])
          } else {
            map.set(featureKey, [content.main])
          }
        })
      },
    )
    return map
  }

  const titleColumn = (
    <ComparisonColumn hasTitleRows={true}>
      {Object.keys(tableFeatures).map((featureKey, index) => {
        const feature = tableFeatures[
          featureKey as keyof typeof tableFeatures
        ] as FeatureType

        const aggregatedFeatures = aggregateFeatures()
        const hasStampsIncluded = aggregatedFeatures.get(feature)?.some(val => {
          if (typeof val === 'object') {
            return val.type === 'stamps_included'
          }
          return false
        })
        const hasStampsExcluded = aggregatedFeatures.get(feature)?.some(val => {
          if (typeof val === 'object') {
            return val.type === 'stamps_excluded'
          }
          return false
        })

        return (
          <ComparisonRow
            key={featureKey}
            backgroundColor={index % 2 ? '#f8f8f8' : '#fcfcfc'}
          >
            <Flex inset={{ left: 'x1' }} flexDirection="column">
              <Text
                type="caption"
                weight="semiBold"
                color="primaryHeading"
                content={feature}
              />
              <Flex>
                {hasStampsIncluded && (
                  <Flex justifyContent="center" alignItems={'center'}>
                    <StampsIncludedIcon />
                    <Text
                      outset={{ horizontal: 'x_75' }}
                      type="footnote"
                      content="Stamps Included"
                      style={{ fontSize: '10px' }}
                    />
                  </Flex>
                )}
                {hasStampsExcluded && (
                  <Flex justifyContent="center" alignItems={'center'}>
                    <StampsExcluded />
                    <Text
                      outset={{ left: 'x_75' }}
                      type="footnote"
                      content="Stamps Not Included"
                      style={{ fontSize: '10px' }}
                    />
                  </Flex>
                )}
              </Flex>
            </Flex>
          </ComparisonRow>
        )
      })}
    </ComparisonColumn>
  )

  const columns = [titleColumn, ...planColumns]
  return (
    <Flex>
      <ComparisonTable>{columns}</ComparisonTable>
    </Flex>
  )
}
