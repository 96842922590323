import React, { FC } from 'react'
import { SVGProps } from './types'

export const GooglePay: FC<SVGProps> = ({ size, onClick }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 62 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path d="M28.951 11.673v7.076h-2.26V1.275h5.993c1.519 0 2.814.503 3.874 1.509 1.083 1.006 1.625 2.234 1.625 3.684 0 1.485-.542 2.713-1.625 3.707-1.048.995-2.343 1.486-3.874 1.486H28.95v.012Zm0-8.246V9.52h3.78c.895 0 1.648-.305 2.237-.901.6-.597.907-1.322.907-2.14 0-.807-.306-1.52-.907-2.117-.589-.62-1.33-.924-2.237-.924h-3.78v-.012Zm15.143 2.971c1.672 0 2.99.444 3.956 1.333.966.889 1.449 2.105 1.449 3.65v7.368h-2.155v-1.661h-.094c-.93 1.368-2.179 2.046-3.733 2.046-1.33 0-2.438-.385-3.333-1.17-.894-.783-1.342-1.753-1.342-2.923 0-1.24.471-2.222 1.413-2.947.942-.737 2.202-1.1 3.768-1.1 1.343 0 2.45.246 3.309.737v-.515c0-.783-.306-1.438-.93-1.988a3.204 3.204 0 0 0-2.19-.819c-1.26 0-2.261.527-2.991 1.591l-1.99-1.24c1.095-1.579 2.72-2.362 4.863-2.362Zm-2.92 8.678c0 .585.247 1.076.753 1.462a2.782 2.782 0 0 0 1.755.585c.953 0 1.801-.351 2.543-1.053.742-.702 1.119-1.52 1.119-2.468-.707-.55-1.684-.83-2.944-.83-.919 0-1.684.222-2.296.655-.624.456-.93 1.006-.93 1.65Zm20.618-8.292L54.256 24h-2.332l2.803-6.023-4.97-11.193h2.462l3.58 8.584h.046l3.486-8.584h2.46Z" />
      <path
        d="M19.959 10.246c0-.733-.066-1.433-.189-2.107h-9.476V12h5.456a4.64 4.64 0 0 1-2.025 3.109v2.504h3.249c1.897-1.744 2.984-4.322 2.984-7.367Z"
        fill="#4285F4"
      />
      <path
        d="M13.727 15.109c-.904.606-2.069.96-3.431.96-2.632 0-4.864-1.761-5.664-4.136H1.281v2.583a10.095 10.095 0 0 0 9.015 5.518c2.725 0 5.014-.89 6.68-2.422l-3.249-2.503Z"
        fill="#34A853"
      />
      <path
        d="M4.317 10.018c0-.667.111-1.312.315-1.917V5.518H1.281a9.913 9.913 0 0 0-1.073 4.5c0 1.618.388 3.146 1.073 4.499l3.351-2.582a6.005 6.005 0 0 1-.315-1.917Z"
        fill="#FABB05"
      />
      <path
        d="M10.296 3.965c1.487 0 2.819.509 3.87 1.503l2.88-2.857C15.295.993 13.016 0 10.295 0A10.097 10.097 0 0 0 1.28 5.518l3.351 2.583c.8-2.375 3.032-4.136 5.664-4.136Z"
        fill="#E94235"
      />
    </svg>
  )
}
