import React, { FC } from 'react'
import { SponsorFragment } from 'src/legacy_graphql'
import { SelectableCard, UserCard } from '@sendoutcards/quantum-design-ui'
import { formatLocation } from 'src/helpers'

type SponsorCardProps = {
  sponsor: SponsorFragment
  isActive: boolean
  onClick?: () => void
}

export const SponsorCard: FC<SponsorCardProps> = ({
  sponsor,
  isActive,
  onClick,
}) => {
  return (
    <SelectableCard isActive={isActive} onClick={onClick}>
      <UserCard
        profileImage={sponsor.storefront?.mugshot?.url}
        firstName={sponsor.firstName}
        lastName={sponsor.lastName}
        caption={formatLocation(
          sponsor.shippingAddress?.city,
          sponsor.shippingAddress?.state,
        )}
        size="small"
        active={isActive}
      />
    </SelectableCard>
  )
}
