import { Operation } from '../../../__generated__/globalTypes'

import {
  MinimalSendableCardFragment,
  minimalSendableCardFragmentString,
} from '../../fragments/__generated__/MinimalSendableCardFragment'

import { sendableCardImageFragmentString } from '../../fragments/__generated__/SendableCardImageFragment'

import favoriteCardRawString from '../FavoriteCard.graphql'

const favoriteCardString = [
  favoriteCardRawString,
  minimalSendableCardFragmentString,
  sendableCardImageFragmentString,
].join('\n\n')

export type FavoriteCardData = {
  favoriteSendableCard: {
    sendableCard: MinimalSendableCardFragment
  }
}

export type FavoriteCardVariables = {
  id: string
}

export type FavoriteCard = Operation<FavoriteCardData, FavoriteCardVariables>

export const FavoriteCard = ({ id }: FavoriteCardVariables): FavoriteCard => ({
  query: favoriteCardString,
  variables: {
    id,
  },
})
