import { Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { useActions } from 'src/hooks'
import { CollapsibleBanner } from '../collapsable_banner/CollapsableBanner'

type UpDownBannerProps = {
  changeType?: 'Upgrade' | 'Downgrade' | 'Neutral'
}

export const UpDownBanner = (props: UpDownBannerProps) => {
  const { changeType } = props
  const actions = useActions()

  const upgradeTitle =
    'We made changes to your shopping cart regarding your subscription upgrade.'
  const downgradeTitle =
    'We made changes to your shopping cart regarding your subscription downgrade.'
  const neutralTitle =
    'We made changes to your shopping cart regarding your subscription change.'

  const upgradeContent = (
    <Flex flexDirection="column">
      <Text type="caption">
        Subscription upgrades must be done through your{' '}
        <div
          style={{ display: 'inline-block' }}
          onClick={() => actions.openAccount()}
        >
          <Text type="caption" weight="bold" color="anchorBlue">
            Dashboard
          </Text>
        </div>
      </Text>
    </Flex>
  )
  const downgradeContent = (
    <Flex flexDirection="column">
      <Text type="caption">
        Subscription downgrades must be done through your{' '}
        <div
          style={{ display: 'inline-block' }}
          onClick={() => actions.openAccount()}
        >
          <Text type="caption" weight="bold" color="anchorBlue">
            Dashboard
          </Text>
        </div>
      </Text>
    </Flex>
  )
  const neutralContent = (
    <Flex flexDirection="column">
      <Text type="caption">
        Subscription changes must be done through your{' '}
        <div
          style={{ display: 'inline-block' }}
          onClick={() => actions.openAccount()}
        >
          <Text type="caption" weight="bold" color="anchorBlue">
            Dashboard
          </Text>
        </div>
      </Text>
    </Flex>
  )

  const bannerData =
    changeType === 'Downgrade'
      ? { title: downgradeTitle, content: downgradeContent }
      : changeType === 'Upgrade'
      ? { title: upgradeTitle, content: upgradeContent }
      : { title: neutralTitle, content: neutralContent }

  return (
    <CollapsibleBanner
      title={bannerData.title}
      type="prompt"
      childrenWrapperType="relative"
    >
      {bannerData.content}
    </CollapsibleBanner>
  )
}
