import React from 'react'
import Chip from '@material-ui/core/Chip'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { SearchFilter, SearchFilterType } from '../types'
import { asDefinedValue } from '../helpers'

type Props = {
  filter: SearchFilter
  onDelete: (filter: SearchFilter) => void
}

const chipColor = '#F7F7F7'
const useStyles = makeStyles({
  chip: {
    margin: 4,
    backgroundColor: chipColor,
  },
  deleteIcon: {
    pointerEvents: 'initial',
  },
})

const FilterChip: React.FC<Props> = props => {
  const { filter, onDelete } = props

  const classes = useStyles()

  const styles = {
    filterType: {
      padding: '2px 5px 2px 10px',
      backgroundColor: chipColor,
      borderRadius: 15,
    },
    wrapper: {
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center',
    } as React.CSSProperties,
  }

  if (filter.value === undefined) return null

  const label: string = (() => {
    switch (filter.type) {
      case SearchFilterType.BOOLEAN:
        return filter.name
      case SearchFilterType.GROUP: {
        const value = asDefinedValue(filter)
        return `${filter.name}: ${value.map(group => group.name).join(', ')}`
      }
      case SearchFilterType.GROUP_HISTORY: {
        const value = asDefinedValue(filter)
        return `${filter.name}: ${value.groupName}`
      }
      case SearchFilterType.CONTACT: {
        const value = asDefinedValue(filter)
        return `${filter.name}: ${value
          .map(contact => `${contact.firstName} ${contact.lastName}`)
          .join(', ')}`
      }
      case SearchFilterType.DATERANGE: {
        const value = asDefinedValue(filter)
        return `${filter.name}: ${value.start.format(
          'MMM D',
        )}-${value.end.format('MMM D')}`
      }
      case SearchFilterType.COMPANY: {
        const value = asDefinedValue(filter)
        return `${filter.name}: ${value}`
      }
      case SearchFilterType.REQUEST_STATUS: {
        const value = asDefinedValue(filter)
        return `${filter.name}: ${value}`
      }
    }
  })()

  return (
    <div style={styles.wrapper}>
      <Chip
        onDelete={() => onDelete(filter)}
        classes={{
          root: classes.chip,
          deleteIcon: classes.deleteIcon,
        }}
        label={label}
      />
    </div>
  )
}

export default FilterChip
