import React, { FC } from 'react'
import { StripeSourceFragment } from 'src/graphql/generated/graphql'
import {
  Flex,
  Icon as QIcon,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { MethodCard } from 'src/upsale/components/checkout/payment/MethodCard'

export type PaymentMethodProps = {
  cardHolderName: string
  handlePaymentMethod: () => void
  paymentMethod?: StripeSourceFragment
}

export const PaymentMethod: FC<PaymentMethodProps> = ({
  cardHolderName,
  paymentMethod,
  handlePaymentMethod,
}) => {
  return paymentMethod ? (
    <MethodCard
      title="Payment"
      last4={
        paymentMethod.__typename === 'CreditCard' ? paymentMethod.last4 : '****'
      }
      type={'Visa'}
      name={cardHolderName}
      exp={
        paymentMethod.__typename === 'CreditCard'
          ? `${paymentMethod.expMonth}/${paymentMethod.expYear}`
          : '****'
      }
    />
  ) : (
    <Flex
      backgroundColor="foreground"
      flexDirection="row"
      width="100%"
      borderRadius="default"
      justifyContent="center"
      alignItems="center"
      inset="x3"
      style={{ outline: '1px solid black' }}
      onClick={handlePaymentMethod}
      cursor="pointer"
    >
      <QIcon size="small" name="creditCard" primaryColor="primaryHeadingText" />
      <Spacer space="x1" orientation="horizontal" />
      <Text
        type="body"
        weight="semiBold"
        content="Add Payment Method"
        color="primaryHeading"
        style={{ textDecoration: 'underline' }}
      />
    </Flex>
  )
}
