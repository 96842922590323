import { Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import HoverTooltip, {
  HoverTooltipProps,
} from 'src/chrome/HoverTooltip/HoverTooltip'

import RemainingRewards from '../subscriptions/RemainingRewards'

import styles from './feature.module.scss'
interface SubscriptionFeature {
  label?: string
  remaining?: number
  total?: number
  tooltip?: HoverTooltipProps
}

interface FeatureProps {
  feature: SubscriptionFeature
}

const Feature: React.FC<FeatureProps> = ({
  feature: {
    label = '',
    remaining = 0,
    total = 0,
    tooltip = { shouldDisplayTooltip: false },
  },
}) => {
  if (remaining >= 0 && total > 0) {
    return (
      <RemainingRewards
        label={label}
        remaining={remaining}
        total={total}
        tooltip={tooltip}
      />
    )
  } else if (remaining >= 0) {
    return (
      <div className={styles.status} style={{ marginTop: 8 }}>
        <div className={styles.details}>
          <Text type="caption" weight="bold" color="primaryHeading">
            {label}
          </Text>
          <div className={styles.amount}>
            <HoverTooltip {...tooltip}>
              <Text type="caption" weight="bold" color="primaryHeading">
                {remaining}
              </Text>
            </HoverTooltip>
          </div>
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default Feature
