import { Operation } from '../../../__generated__/globalTypes'

import {
  MarketingParagraphFragment,
  marketingParagraphFragmentString,
} from '../../fragments/__generated__/MarketingParagraphFragment'

import getAffiliateUpsaleDialogMarketingContentRawString from '../GetAffiliateUpsaleDialogMarketingContent.graphql'

const getAffiliateUpsaleDialogMarketingContentString = [
  getAffiliateUpsaleDialogMarketingContentRawString,
  marketingParagraphFragmentString,
].join('\n\n')

export type GetAffiliateUpsaleDialogMarketingContentData = {
  affiliateProTitle: MarketingParagraphFragment
  affiliateProTitleFreeAffiliate: MarketingParagraphFragment
  affiliateProSecondaryTitle: MarketingParagraphFragment
  affiliateProSecondaryTitleFreeAffiliate: MarketingParagraphFragment
  affiliateProSubtitle: MarketingParagraphFragment
  affiliateProSubtitleFreeAffiliate: MarketingParagraphFragment
}

export type GetAffiliateUpsaleDialogMarketingContent = Operation<
  GetAffiliateUpsaleDialogMarketingContentData
>

export const GetAffiliateUpsaleDialogMarketingContent = (): GetAffiliateUpsaleDialogMarketingContent => ({
  query: getAffiliateUpsaleDialogMarketingContentString,
  variables: undefined,
})
