import { Maybe, Operation } from '../../../__generated__/globalTypes'

import {
  MinimalCampaignFragment,
  minimalCampaignFragmentString,
} from '../../fragments/__generated__/MinimalCampaignFragment'

import getPaginatedCampaignsRawString from '../GetPaginatedCampaigns.graphql'

const getPaginatedCampaignsString = [
  getPaginatedCampaignsRawString,
  minimalCampaignFragmentString,
].join('\n\n')

export type GetPaginatedCampaignsData = {
  paginatedCampaigns: {
    campaigns: MinimalCampaignFragment[]
    count: number
  }
}

export type GetPaginatedCampaignsVariables = {
  search?: Maybe<string>
  shareable?: Maybe<boolean>
  offset: number
  limit: number
}

export type GetPaginatedCampaigns = Operation<
  GetPaginatedCampaignsData,
  GetPaginatedCampaignsVariables
>

export const GetPaginatedCampaigns = ({
  search,
  shareable,
  offset,
  limit,
}: GetPaginatedCampaignsVariables): GetPaginatedCampaigns => ({
  query: getPaginatedCampaignsString,
  variables: {
    search,
    shareable,
    offset,
    limit,
  },
})
