import React from 'react'

import { RelatedContactFragment } from 'src/legacy_graphql'
import { useState } from 'src/hooks'

import SectionTitle from '../SectionTitle'
import {
  ContactAddress,
  ContactDates,
  ContactNames,
  ContactNumbers,
} from '../details/DetailViews'
import styles from './contactDetails.module.scss'
import { Text } from '@sendoutcards/quantum-design-ui'

type Props<T extends RelatedContactFragment> = {
  contact: T
  isExpanded: boolean
  isExpandingAllowed: boolean
  onDelete?: (id: string) => void
  onSeparateContact?: (id: string) => void
  onGoToContact?: (id: string) => void
  onEdit: () => void
  contactCategory?: string
  isReadOnly?: boolean
}

const ContactDetails = <T extends RelatedContactFragment>({
  contact,
  isExpandingAllowed,
  onDelete,
  onGoToContact,
  onSeparateContact,
  onEdit,
  contactCategory,
  isExpanded,
  isReadOnly = false,
}: Props<T>) => {
  const [showExpanded, setShowExpanded] = useState(isExpanded)
  const editProps = Object.assign({}, isReadOnly ? {} : { onEdit })
  if (!showExpanded) {
    return (
      <section
        id={contactCategory === 'spouse' ? 'spouse_contact_details' : undefined}
      >
        <ContactNames weight="bold" {...contact} />
        <ContactDates
          birthday={contact.birthday ?? undefined}
          anniversary={contact.anniversary ?? undefined}
        />
        <div className={styles.actions}>
          {!isReadOnly && isExpandingAllowed && (
            <span onClick={() => setShowExpanded(x => !x)}>
              <Text type="caption" color="primaryBrand">
                See More Info
              </Text>
            </span>
          )}
          {!isReadOnly && onEdit && (
            <span
              onClick={() => onEdit()}
              id={contactCategory === 'spouse' ? 'edit_spouse_btn' : undefined}
            >
              <Text type="caption" color="primaryBrand">
                Edit
              </Text>
            </span>
          )}
          {onGoToContact && (
            <span onClick={() => onGoToContact(contact.id)}>
              <Text type="caption" color="primaryBrand">
                Go To
              </Text>
            </span>
          )}
          {!isReadOnly && onDelete && (
            <span
              onClick={() => onDelete(contact.id)}
              id={
                contactCategory === 'spouse' ? 'delete_spouse_btn' : undefined
              }
            >
              <Text type="caption" color="primaryBrand">
                Delete
              </Text>
            </span>
          )}
          {onSeparateContact && (
            <span onClick={() => onSeparateContact(contact.id)}>
              <Text type="caption" color="primaryBrand">
                Separate
              </Text>
            </span>
          )}
        </div>
      </section>
    )
  }

  return (
    <div style={{ marginBottom: 25 }}>
      <div>
        <section>
          <SectionTitle
            title={'Name'}
            id={'edit_contact_name_btn'}
            {...editProps}
          />
          <ContactNames {...contact} />
        </section>

        <section>
          <SectionTitle
            title={'Address'}
            id="edit_contact_address_btn"
            {...editProps}
          />
          <ContactAddress {...contact} />
        </section>

        <section>
          <SectionTitle title={'Contact'} {...editProps} />
          <ContactNumbers {...contact} />
        </section>

        <section>
          <SectionTitle title={'Important Dates'} {...editProps} />
          <ContactDates
            birthday={contact.birthday ?? undefined}
            anniversary={contact.anniversary ?? undefined}
          />
        </section>

        <div className={styles.actions}>
          {!isReadOnly && isExpandingAllowed && (
            <span onClick={() => setShowExpanded(false)}>
              <Text type="caption" color="primaryBrand">
                See Less Info
              </Text>
            </span>
          )}
          {!isReadOnly && onGoToContact && (
            <span onClick={() => onGoToContact(contact.id)}>
              <Text type="caption" color="primaryBrand">
                Go To
              </Text>
            </span>
          )}
          {!isReadOnly && onDelete && (
            <span onClick={() => onDelete(contact.id)}>Delete</span>
          )}
        </div>
      </div>
    </div>
  )
}

export default ContactDetails
