import { CurrencyType } from '../../../__generated__/globalTypes'

import labeledPriceFragmentRawString from '../LabeledPriceFragment.graphql'

export const labeledPriceFragmentString = labeledPriceFragmentRawString

export type LabeledPriceFragment = {
  __typename: 'LabeledPrice'
  label: string
  asString: string
  currency: CurrencyType
  amount: number
}

export const isLabeledPriceFragment = (
  fragment: any,
): fragment is LabeledPriceFragment =>
  fragment &&
  fragment.__typename === 'LabeledPrice' &&
  typeof fragment.label === 'string' &&
  typeof fragment.asString === 'string' &&
  (fragment.currency === 'POINT' ||
    fragment.currency === 'EXPENSE' ||
    fragment.currency === 'FREE' ||
    fragment.currency === 'USD' ||
    fragment.currency === 'CREDIT' ||
    fragment.currency === 'CARDTOKEN') &&
  typeof fragment.amount === 'number'
