import React from 'react'
import { Div, Tab, Tabs } from '@sendoutcards/quantum-design-ui'
import { useAccount } from 'src/hooks'
import AffiliateLinks from './AffiliateLinks'
import { FlexRow } from 'src/styled'

interface VerticalTabsProps {
  toggleAlert: () => void
}

const tabs = [
  {
    title: 'SendOutCards',
    baseUrl: 'https://www.SendOutCards.com/u/',
    tabKey: 'one',
    id: 'soc',
  },
  {
    title: 'Promptings',
    baseUrl: 'https://www.promptings.com/?sponsor=',
    tabKey: 'four',
    id: 'promptings',
  },
  {
    title: 'Promptings Academy',
    baseUrl: 'https://www.PromptingsAcademy.com/?sponsor=',
    tabKey: 'five',
    id: 'promptingsacademy',
  },
]

const VerticalTabs: React.FC<VerticalTabsProps> = props => {
  const { toggleAlert } = props
  const account = useAccount()
  const gid = account.genealogyId.toString()
  return (
    <FlexRow>
      <Tabs defaultActiveKey={'one'} height="400px">
        {tabs.map((tab, index) => (
          <Tab key={`tab_${index}`} tabKey={tab.tabKey} title={tab.title}>
            <Div width="100%">
              <AffiliateLinks
                isSoc={tab.title === 'SendOutCards'}
                baseUrl={tab.baseUrl}
                slug={
                  account.storefront ? (account.storefront.slug as string) : gid
                }
                onCopy={toggleAlert}
              />
            </Div>
          </Tab>
        ))}
      </Tabs>
    </FlexRow>
  )
}

export default VerticalTabs
