import React from 'react'
import Button from '@material-ui/core/Button'

import { Button as ChromeButton } from 'src/chrome'

interface Props {
  errors?: string[]
  back?: {
    onClick: () => void
    disabled: boolean
    title?: string
  }
  next?: {
    onClick: () => void
    disabled: boolean
    title?: string
  }
}

const BottomControls: React.FC<Props> = props => {
  const { errors, next, back } = props

  return (
    <div style={{ marginTop: 25, width: '100%' }}>
      {errors &&
        errors.map(error => (
          <div
            key={error}
            style={{
              textAlign: 'right',
              color: 'red',
              padding: '0 15px',
              marginBottom: '1rem',
              width: '100%',
            }}
          >
            {error}
          </div>
        ))}

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {back && (
          <Button onClick={back.onClick} disabled={back.disabled}>
            {back.title || 'Back'}
          </Button>
        )}

        {next && (
          <ChromeButton
            id="bottom-control-0"
            title={next.title || 'Next'}
            onClick={next.onClick}
            disabled={next.disabled}
            buttonColor={next.disabled ? undefined : 'pink'}
            style={{ marginLeft: 15 }}
          />
        )}
      </div>
    </div>
  )
}

export default BottomControls
