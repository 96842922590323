import React from 'react'

import { ContactFragment } from 'src/legacy_graphql'
import { Button } from 'src/chrome'
import { useActions } from 'src/hooks'

import { ParsedRow } from '../columns'
import { Props as BaseProps } from '../helpers'
import { FlexColumn, FlexRow } from 'src/styled'

interface Props {
  parsedRows: Partial<ParsedRow>[]
  importedCustomers: ContactFragment[]
  additionalAction?: BaseProps['additionalAction']
  onImportMore: () => void
}

const CompleteStep: React.FC<Props> = props => {
  const {
    parsedRows,
    importedCustomers,
    additionalAction,
    onImportMore,
  } = props

  const actions = useActions()

  return (
    <FlexColumn>
      <h4 style={{ textAlign: 'center' }}>
        {`Imported ${importedCustomers.length} of ${parsedRows.length} contacts`}
      </h4>
      <FlexRow css={{ justifyContent: 'center', marginTop: '25px' }}>
        <Button
          title={'Import another file'}
          buttonColor={'pink'}
          onClick={onImportMore}
          style={{ width: '200px' }}
        />
        <Button
          title={'Go to catalog'}
          onClick={() => actions.openCatalog()}
          style={{ width: 180, marginLeft: 20 }}
        />
        {additionalAction && (
          <Button
            title={additionalAction.title}
            buttonColor={'pink'}
            style={{ width: 144 }}
            onClick={() => additionalAction.onClick(importedCustomers)}
          />
        )}
      </FlexRow>
    </FlexColumn>
  )
}

export default CompleteStep
