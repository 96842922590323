import React from 'react'
import { SVGProps } from './types'

export const Warning: React.FC<SVGProps> = ({
  size = 24,
  color = '#323232',
  onClick,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_964_22713)">
        <path
          d="M9 11C9 11.5523 8.55229 12 8 12C7.44772 12 7 11.5523 7 11C7 10.4477 7.44772 10 8 10C8.55229 10 9 10.4477 9 11Z"
          fill={color}
        />
        <path
          d="M7.70962 4C7.32507 4 7.02027 4.32445 7.04425 4.70825L7.31379 9.02079C7.32477 9.19647 7.47045 9.33333 7.64647 9.33333H8.3535C8.52952 9.33333 8.67521 9.19647 8.68619 9.02079L8.95572 4.70825C8.97971 4.32445 8.6749 4 8.29035 4H7.70962Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8ZM8 1.33333C4.3181 1.33333 1.33333 4.3181 1.33333 8C1.33333 11.6819 4.3181 14.6667 8 14.6667C11.6819 14.6667 14.6667 11.6819 14.6667 8C14.6667 4.3181 11.6819 1.33333 8 1.33333Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_964_22713">
          <rect width={size} height={size} fill="transparent" />
        </clipPath>
      </defs>
    </svg>
  )
}
