import React from 'react'

import { Icon } from 'src/chrome'
import { CampaignLineCard } from 'src/campaigns/components'
import { Item } from 'src/campaign_store/types'

import styles from './ribbon.module.scss'
import { Div, Text } from '@sendoutcards/quantum-design-ui'

type Props = {
  shouldWrapContent?: boolean
  handleCategoryClick?: () => void
  isScrollBarEnabled?: boolean
  id?: string | number
  items?: Item[]
  onPurchase?: (item: Item) => void
  onItemClick?: (item: Item) => void
  isSeeAllButtonEnabled: boolean
  title?: string
}

const isItem = (itemOrCategory: {
  __typename: string
}): itemOrCategory is Item => itemOrCategory.__typename === 'Item'

const CampaignRibbon: React.FC<Props> = props => {
  const {
    shouldWrapContent,
    handleCategoryClick,
    isScrollBarEnabled = true,
    id,
    items,
    onPurchase,
    onItemClick,
    isSeeAllButtonEnabled,
    title,
  } = props

  const titleFormatter = (title?: string) => {
    return title?.split(' ')[0].toLowerCase() ?? 'default_title'
  }

  return (
    <div className={styles.ribbon} style={{ padding: 0 }}>
      <div className={styles.ribbonInfo}>
        {title && (
          <>
            <Div onClick={() => handleCategoryClick?.()} cursor="pointer">
              <Text type="largeBody" color="primaryHeading">
                {title}
              </Text>
            </Div>
          </>
        )}
        {isSeeAllButtonEnabled && !shouldWrapContent && (
          <h6 className={styles.more} onClick={() => handleCategoryClick?.()}>
            SEE ALL <Icon icon={'CARROTRIGHT'} size={12} color={''} />
          </h6>
        )}
      </div>
      <div
        className={styles.itemContainer}
        style={{
          ...(shouldWrapContent && {
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
          }),
          ...(!isScrollBarEnabled && { overflowX: 'hidden' }),
        }}
      >
        {items &&
          items.filter(isItem).map((item, index) => {
            return item.campaign ? (
              <CampaignLineCard
                id={`campaign_${titleFormatter(title)}_${index}`}
                isActive={false}
                isCampaign={true}
                lines={item.campaign.lines.map(line => ({
                  card: line?.card ?? null,
                  giftVariation: line?.giftVariation ?? null,
                }))}
                total={`$${item.price}`}
                key={item.id}
                title={item.campaign.name}
                buttons={{
                  primaryButton: {
                    label: 'View Details',
                    onClick: () => onItemClick?.(item),
                  },
                  secondaryButton: {
                    id: `$campaign_${id}_${index}`,
                    label: 'Buy',
                    onClick: () => onPurchase?.(item),
                  },
                }}
              />
            ) : (
              <CampaignLineCard
                isActive={false}
                isCampaign={true}
                image={item.image ?? undefined}
                total={`$${item.price}`}
                key={item.id}
                title={item.name ?? item.description ?? undefined}
                buttons={{
                  primaryButton: {
                    label: 'View Items',
                    onClick: () => onItemClick?.(item),
                  },
                  secondaryButton: {
                    id: `campaign_${id}_${index}`,
                    label: 'Buy',
                    onClick: () => onPurchase?.(item),
                  },
                }}
              />
            )
          })}
      </div>
    </div>
  )
}

export default CampaignRibbon
