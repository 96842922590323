import {
  insertionProperties,
  insertionTextAlign,
  paragraphStart,
} from 'src/redux/selectors/text'
import { TextEditorState, TextProperties } from 'src/editor/text/types'
import { Api } from 'src/editor/api'
import { Step, Steps, TextFragment } from 'src/editor/types'
import { TextAlignment } from 'src/graphql/generated/graphql'

const textEditor = (
  updateText: Api['updateText'],
  step: Step & { type: Steps.EditText },
  isMobile: Api['isMobile'],
) => {
  const handleUpdateText = (
    cb: (state: TextEditorState) => TextEditorState,
    shouldNotSelectText?: boolean,
  ) => {
    updateText(
      step.fullBleedIndex,
      step.panelIndex,
      step.elementIndex,
      step.textSelection,
      cb,
      shouldNotSelectText,
    )
  }

  const apply = (state: TextEditorState, fragments: TextFragment[]) => {
    const start = state.selection.start + fragments.length
    return {
      text: [
        ...state.text.slice(0, state.selection.start),
        ...fragments,
        ...state.text.slice(state.selection.end),
      ],
      selection: {
        ...state.selection,
        start,
        end: start,
      },
    }
  }

  const insertFragments = (fragments: TextFragment[]) =>
    handleUpdateText(state => apply(state, fragments))

  const insertCharacter = (character: string) =>
    handleUpdateText(state =>
      apply(state, [{ ...insertionProperties(state), text: character }]),
    )

  const insertPlaceholder = (placeholder: string) =>
    insertCharacter(placeholder)

  const insertText = (text: string) =>
    handleUpdateText(state =>
      apply(
        state,
        [...text].map(character => ({
          ...insertionProperties(state),
          text: character,
        })),
      ),
    )

  const insertSignature = (signatureFontId: string) =>
    handleUpdateText(state =>
      apply(state, [{ ...insertionProperties(state), signatureFontId }]),
    )

  const insertNewLine = () =>
    handleUpdateText(state =>
      apply(state, [
        {
          ...insertionProperties(state),
          textAlign: insertionTextAlign(state),
        },
      ]),
    )

  const deleteFragments = () =>
    handleUpdateText(state =>
      apply(
        state.selection.start === state.selection.end &&
          state.selection.start > 1
          ? {
              selection: {
                ...state.selection,
                start: state.selection.start - 1,
              },
              text: state.text,
            }
          : state,
        [],
      ),
    )

  const deleteForwardFragments = () =>
    handleUpdateText(state => {
      if (state.selection.start === state.selection.end) {
        return {
          text: [
            ...state.text.slice(0, state.selection.start),
            ...state.text.slice(state.selection.start + 1),
          ],
          selection: state.selection,
        }
      }
      return apply(state, [])
    })

  const updateProperties = (properties: TextProperties) => {
    return handleUpdateText(aState => {
      const state = {
        ...aState,
        selection: isMobile
          ? { ...aState.selection, start: 1, end: aState.text.length }
          : { ...aState.selection },
      }
      if (state.selection.start === state.selection.end) {
        if (state.text[state.selection.start - 1].textAlign) {
          return {
            ...state,
            text: [
              ...state.text.slice(0, state.selection.start - 1),
              {
                ...state.text[state.selection.start - 1],
                ...properties,
              },
              ...state.text.slice(state.selection.end),
            ],
          }
        } else {
          return {
            ...state,
            selection: {
              ...state.selection,
              overrideProperties: {
                ...state.selection.overrideProperties,
                ...properties,
              },
            },
          }
        }
      } else {
        return {
          ...state,
          text: state.text.map((fragment, index) => {
            return (state.selection.start <= index &&
              index < state.selection.end) ||
              (index === state.selection.start - 1 && fragment.textAlign)
              ? { ...fragment, ...properties }
              : fragment
          }),
        }
      }
    }, isMobile)
  }

  const updateTextAlign = (textAlign: TextAlignment) =>
    handleUpdateText(
      state => ({
        ...state,
        text: [
          ...state.text.slice(0, paragraphStart(state)),
          ...state.text
            .slice(paragraphStart(state), state.selection.end)
            .map(fragment =>
              fragment.textAlign ? { ...fragment, textAlign } : fragment,
            ),
          ...state.text.slice(state.selection.end),
        ],
      }),
      isMobile,
    )

  const replaceAllText = (text: string, signatureId?: string) =>
    handleUpdateText(state => {
      const frags = [...text].map(character => ({
        ...insertionProperties(state),
        text: character,
      }))

      const sig = signatureId
        ? [
            { ...insertionProperties(state), text: '\n' },
            {
              ...insertionProperties(state),
              signatureFontId: signatureId,
            },
          ]
        : []

      const wholeText = [...frags, ...sig]

      const appp = apply(
        {
          selection: {
            ...state.selection,
            start: 1,
            end: state.text.length,
          },
          text: state.text,
        },
        wholeText,
      )
      return appp
    })

  return {
    insertFragments,
    insertCharacter,
    insertPlaceholder,
    insertText,
    insertSignature,
    insertNewLine,
    deleteFragments,
    deleteForwardFragments,
    updateProperties,
    updateTextAlign,
    replaceAllText,
  }
}

export default textEditor
