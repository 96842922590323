import {
  Anchor,
  Button,
  Div,
  Flex,
  Icon,
  Separator,
  Text,
} from '@sendoutcards/quantum-design-ui'
import moment from 'moment'
import React from 'react'
import { ProgressBar } from 'src/design_system/components/ProgressBar/ProgressBar'
import { useMemo } from 'src/hooks'
import { PlanFragment } from 'src/legacy_graphql'
import { SubscriptionType } from 'src/user/fragments'

type CurrentPlanCardProps = {
  title: string
  price: string
  description?: string
  currentSubscription?: SubscriptionType
  newPlan?: PlanFragment
  recurrence?: 'month' | 'year'
  changeAction?: {
    title: string
    onClick: () => void
    isDisabled?: boolean
  }
  renewAction?: {
    title: string
    onClick: () => void
    isDisabled?: boolean
  }
  cancelAction?: {
    title: string
    onClick: () => void
    isDisabled?: boolean
  }
}

export const CurrentPlanCard: React.FC<CurrentPlanCardProps> = ({
  title,
  description = 'The perfect way to get started',
  price,
  currentSubscription,
  cancelAction,
  changeAction,
  renewAction,
  recurrence = 'month',
  newPlan,
}) => {
  const renewalDate = currentSubscription?.currentPeriodEnd

  const cancelDate = currentSubscription?.isSetToCancel
    ? currentSubscription.currentPeriodEnd
    : undefined

  const getSubscriptionProgress = useMemo(() => {
    if (!renewalDate) return
    const today = new Date()
    const renewalDateTime = new Date(renewalDate)
    const totalDays = 30
    const progressDate = cancelDate ? new Date(cancelDate) : renewalDateTime

    const remainingDays = Math.round(
      (progressDate.getTime() - today.getTime()) / (1000 * 3600 * 24),
    )
    const percentage = ((totalDays - remainingDays) / totalDays) * 100
    return {
      remainingDays,
      percentage,
      progressDate,
    }
  }, [cancelDate, renewalDate])

  return (
    <Flex
      flexDirection="column"
      backgroundColor={'foreground'}
      borderRadius="default"
      inset={{ vertical: 'x2', horizontal: 'x2_5' }}
      boxShadow="light"
    >
      <Flex id="planInfoContainer" flexDirection="column">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          outset={{ bottom: 'x3' }}
        >
          <Div>
            <Text
              type="body"
              color="primaryHeading"
              weight="bold"
              content={title}
            />
            <Text type="caption" content={description} />
          </Div>
          <Flex alignItems="flex-end">
            <Text type="subtitle" weight="bold" content={`${price}`} />
            <Text type="caption" weight="semiBold" content={`/${recurrence}`} />
          </Flex>
        </Flex>
        <Flex flexDirection={'column'} outset={{ bottom: 'x3' }}>
          {currentSubscription && (
            <Flex justifyContent="space-between" outset={{ bottom: 'x1' }}>
              <Text
                type="footnote"
                content={`${getSubscriptionProgress?.remainingDays} Days`}
                weight="semiBold"
                color="primaryHeading"
              />
              <Text
                type="footnote"
                color={cancelDate && !newPlan ? 'danger' : 'primaryBody'}
                content={`${getSubscriptionProgress?.remainingDays} ${
                  cancelDate && !newPlan
                    ? 'days until account is converted.'
                    : cancelDate && newPlan
                    ? 'days until account is converted.'
                    : 'days until renewal'
                }`}
              />
            </Flex>
          )}
          {getSubscriptionProgress && (
            <ProgressBar
              percentage={getSubscriptionProgress.percentage ?? ''}
              fillColor="#ffb854"
            />
          )}
          {cancelDate && (
            <Flex outset={{ top: 'x1' }} flexDirection="column">
              <Text
                type="footnote"
                color={cancelDate && !newPlan ? 'danger' : 'success'}
                weight="semiBold"
                content={`Will ${
                  cancelDate && !newPlan ? 'Cancel' : 'Change'
                } on: ${moment(currentSubscription?.currentPeriodEnd).format(
                  'MM/DD/YYYY',
                )}`}
              />
              {newPlan && (
                <Flex alignItems="center" outset={{ top: 'x_5' }}>
                  <Icon
                    name="swap"
                    size={'xSmall'}
                    primaryColor="primaryHeadingText"
                  />
                  <Text
                    type="footnote"
                    content={'Switching to: '}
                    color="primaryBody"
                    outset={{ horizontal: 'x_5' }}
                  />
                  <Text
                    type="footnote"
                    weight="bold"
                    content={`${newPlan?.title ?? 'Basic'}`}
                    style={{ color: '#be9cdd' }}
                  />
                </Flex>
              )}
            </Flex>
          )}
        </Flex>
      </Flex>
      <Separator orientation="horizontal" />
      <Flex
        id="ctaContainer"
        width="100%"
        justifyContent={'space-between'}
        alignItems="center"
        outset={{ top: 'x2' }}
      >
        {cancelAction && (
          <Anchor
            title={cancelAction.title}
            size="xSmall"
            onClick={cancelAction.onClick}
          />
        )}
        <Flex columnGap="x1" style={{ marginLeft: 'auto' }}>
          {renewAction && (
            <Button
              title={renewAction.title}
              size="small"
              type="smoke"
              onClick={renewAction.onClick}
              disabled={renewAction.isDisabled}
            />
          )}
          {changeAction && (
            <Button
              title={changeAction.title}
              size="small"
              type="smoke"
              onClick={changeAction.onClick}
              disabled={changeAction.isDisabled}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}
