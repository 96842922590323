import { Route } from 'src/routes/Route'
import { GiftStoreSubroute } from 'src/gift_store/routes/GiftStoreRoute'
import { GiftRoute } from 'src/gift_store/routes/GiftRoute'
import {
  dismissChildCategory,
  selectChildCategory,
  selectGiftToView,
  selectHighLevelCategory,
} from 'src/redux/actions/giftstore'
import { GiftCategoryRoute } from 'src/gift_store/routes/GiftCategoryRoute'

export const AddGiftToCardRoute = Route({
  path: '/line/:lineIndex/add-gift-to-card',
  init: (
    lineIndex?: string,
    subroute?: GiftStoreSubroute,
    highLevelCategory?: string,
  ) => ({
    lineIndex,
    highLevelCategory,
    subroute,
  }),
  fromPath: ({ lineIndex, highLevelCategory }) => ({
    lineIndex,
    highLevelCategory,
  }),
  subroute: GiftStoreSubroute,
  reducer: (route, action) => {
    switch (action.type) {
      case dismissChildCategory.type:
        return { ...route, subroute: undefined }
      case selectHighLevelCategory.type:
        return {
          ...route,
          subroute: undefined,
          highLevelCategory: action.categoryId,
        }
      case selectChildCategory.type:
        return { ...route, subroute: GiftCategoryRoute(action.categoryId) }
      case selectGiftToView.type:
        return {
          ...route,
          subroute: route.subroute ? route.subroute : GiftRoute(action.giftId),
        }
      default:
        return route
    }
  },
})

export type AddGiftToCardRoute = Route<typeof AddGiftToCardRoute>
