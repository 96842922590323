import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import { Set } from 'immutable'

// @src imports
import {
  useActions,
  useEffect,
  useMutations,
  useQueries,
  useSelector,
  useState,
} from 'src/hooks'

import { Button, Confirm, DefaultError, Pagination } from 'src/chrome'
import {
  getPaginatedDraftedOrders,
  MinimalOrderFragment,
} from 'src/legacy_graphql'

// relative imports
import DraftedOrder from './DraftedOrder'
import suspenseBoundary from 'src/chrome/SuspenseBoundary/suspenseBoundaryHOC'
import { Text } from '@sendoutcards/quantum-design-ui'
import { AddOrderCardRoute } from 'src/orders/routes/AddOrderCardRoute'

const styles = {
  orderPreviewBody: {
    display: 'flex',
    flexDirection: 'column' as const,
  },
  selectedMenu: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '85%',
    margin: '0 auto',
    paddingTop: '15px',
    transition: 'display 200ms',
  },
  draftedOrders: {
    display: 'flex',
    margin: '0 auto',
    flexWrap: 'wrap' as const,
    justifyContent: 'center',
  },
  loading: {
    marginTop: '5px',
    textAlign: 'center' as const,
  },
}

const DraftedOrders: React.FC = () => {
  const actions = useActions()

  const { width } = useSelector(state => state.window)
  const pagesToShow = width <= 600 ? 4 : 5
  const pageSize = 20

  const [
    isDeleteConfirmationModalOpen,
    setIsDeleteConfirmationModalOpen,
  ] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedDraftedOrders, setSelectedDraftedOrders] = useState(
    Set<string>(),
  )

  const mutations = useMutations()

  const [{ results: draftedOrders, count: draftedOrdersCount }] = useQueries(
    getPaginatedDraftedOrders({
      page: currentPage,
    }),
  )

  const isEveryDraftedOrderSelected =
    selectedDraftedOrders.size === draftedOrders.length

  useEffect(() => {
    actions.clearOrder()
  }, [actions])

  const toggleModal = () =>
    setIsDeleteConfirmationModalOpen(prevState => !prevState)

  const deleteSelectedDraftedOrders = () => {
    mutations.deleteOrders({ orders: Array.from(selectedDraftedOrders) })
    setSelectedDraftedOrders(Set())
    toggleModal()
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const handleSelect = (orderId: string) =>
    setSelectedDraftedOrders(prevState =>
      prevState.has(orderId)
        ? prevState.delete(orderId)
        : prevState.add(orderId),
    )

  const handleSelectAll = (event: React.ChangeEvent) =>
    setSelectedDraftedOrders(
      Set(
        isEveryDraftedOrderSelected ? [] : draftedOrders.map(order => order.id),
      ),
    )

  const openOrder = (order: MinimalOrderFragment) => {
    if (order.minimalLines.length > 0) {
      actions.openOrder(order.id)
    } else {
      actions.openOrder(order.id, AddOrderCardRoute())
    }
  }

  return (
    <div>
      {!!draftedOrders.length && (
        <div css={styles.orderPreviewBody}>
          {selectedDraftedOrders.size > 0 && (
            <div css={styles.selectedMenu}>
              <div style={{ display: 'flex' }}>
                <Checkbox
                  checked={isEveryDraftedOrderSelected}
                  onChange={handleSelectAll}
                  style={{ width: 35 }}
                />
                <Text
                  type="caption"
                  color="primaryHeading"
                >{`Selected ${selectedDraftedOrders.size} of ${draftedOrders.length}`}</Text>
              </div>
              <Button
                title={'Delete'}
                buttonColor={'pink'}
                onClick={toggleModal}
              />
            </div>
          )}
          <div css={styles.draftedOrders}>
            {draftedOrders.map(order => (
              <DraftedOrder
                key={order.id}
                order={order}
                isOrderSelected={selectedDraftedOrders.has(order.id)}
                hasSelections={selectedDraftedOrders.size > 0}
                onSelectOrder={handleSelect}
                onOpenOrder={openOrder}
              />
            ))}
          </div>
          <Pagination
            onPageChange={handlePageChange}
            count={draftedOrdersCount}
            pagesToShow={pagesToShow}
            pageSize={pageSize}
            currentPage={currentPage}
          />
        </div>
      )}
      {isDeleteConfirmationModalOpen && (
        <Confirm
          title={`Delete ${selectedDraftedOrders.size} orders`}
          message={'Are you sure you want to delete the selected orders?'}
          confirmTitle={'Delete'}
          onConfirm={deleteSelectedDraftedOrders}
          onDecline={toggleModal}
        />
      )}
    </div>
  )
}

export default suspenseBoundary({
  component: DraftedOrders,
  unresolved: (
    <Text type="body" alignment="center" color="primaryHeading">
      Loading drafted orders...
    </Text>
  ),
  failure: DefaultError,
})
