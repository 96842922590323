import React from 'react'

import { useActions } from 'src/hooks'
import { Transition } from 'src/chrome'
import { ReplaceOrderCardRoute } from 'src/orders/routes/ReplaceOrderCardRoute'
import { AddCardToGiftRoute } from 'src/orders/routes/AddCardToGiftRoute'
import { AddOrderCardRoute } from 'src/orders/routes/AddOrderCardRoute'
import { orderCostWithUpgrades } from 'src/helpers/payments'
import { CardFragment } from 'src/graphql/generated/graphql'
import CardPicker from 'src/card_picker'
import {
  buildUpdateOrderInput,
  OrderWithPartialLines,
} from 'src/redux/reducers/orders'
import { OrderApiType } from 'src/orders/api'
import { useUpdateOrder } from 'src/react_query'
import { UpdateOrderInput } from 'src/graphql/generated/graphql'
import Editor from 'src/editor/containers/Editor/Editor'
import { FullWindowModal } from 'src/chrome'
import { CardRoute } from 'src/card/routes/CardRoute'

type Props = {
  subroute: ReplaceOrderCardRoute | AddCardToGiftRoute | AddOrderCardRoute
  order: OrderWithPartialLines
  orderApi: OrderApiType
  onReload: () => Promise<void>
  onClose: () => void
  isLoading: boolean
}

const AddCard: React.FC<Props> = props => {
  const { order, subroute, onReload, onClose, isLoading, orderApi } = props

  const actions = useActions()
  const updateOrderMutation = useUpdateOrder()
  const cardCount = order.lines.length ?? 0
  const context = {
    footerLabel: (card: CardFragment) => (
      <div style={{ display: 'flex' }}>
        <b>
          {cardCount} card{cardCount > 1 ? 's' : ''}
        </b>
        <div style={{ marginLeft: '1rem' }}>
          Total: {orderCostWithUpgrades(order, card)}
        </div>
      </div>
    ),
    lines: order.lines,
  }

  const { applyChanges, saveButtonLabel, actionContext } = (() => {
    switch (subroute.path) {
      case AddCardToGiftRoute.path:
        const lineIndexNumber = subroute.lineIndex ? +subroute.lineIndex : 0
        return {
          applyChanges: (orderInput: UpdateOrderInput, cardId: string) => ({
            ...orderInput,
            lines: (orderInput.lines || []).map((line, index) => ({
              ...line,
              card: index === lineIndexNumber ? cardId : line.card,
            })),
          }),
          saveButtonLabel: 'Add to gift',
          actionContext: 'addingToGift',
        }
      case AddOrderCardRoute.path:
        return {
          applyChanges: (orderInput: UpdateOrderInput, cardId: string) => ({
            ...orderInput,
            lines: (orderInput.lines || []).concat([
              {
                card: cardId,
                giftVariation: undefined,
                sendDelay: null,
              },
            ]),
          }),
          saveButtonLabel: 'Add to order',
          actionContext: 'addingToContext',
        }
      case ReplaceOrderCardRoute.path:
        return {
          applyChanges: (orderInput: UpdateOrderInput, newCardId: string) => ({
            ...orderInput,
            lines: (orderInput.lines || []).map((line, index) => ({
              ...line,
              card: line.card === subroute.cardId ? newCardId : line.card,
            })),
          }),
          saveButtonLabel: 'Replace',
          actionContext: 'replacing',
        }
    }
  })()

  const handleAdd = async (cardId: string) => {
    const orderInput = buildUpdateOrderInput(order)
    await updateOrderMutation.mutateAsync({
      order: applyChanges(orderInput, cardId),
    })
  }

  const onAfterSave = async (cardId: string) => {
    await handleAdd(cardId)
    await onReload()
  }

  if (subroute.subroute && subroute.subroute.path === CardRoute.path) {
    const cardId = subroute.subroute.cardId
    return (
      <FullWindowModal isReallyFull={true}>
        <Editor
          handleAddToOrder={handleAdd}
          context={context}
          cardId={cardId}
          onAfterSave={() => onAfterSave(cardId)}
          onClose={actions.dismissedCatalogSubroute}
          onBackToCatalog={actions.dismissedCatalogSubroute}
          saveButtonLabel={saveButtonLabel}
          orderApi={orderApi}
          onReload={onReload}
        />
      </FullWindowModal>
    )
  }

  return (
    <>
      {isLoading && <Transition message={'Loading your order...'} />}
      <CardPicker
        context={context}
        onSelectCard={selectedCardId =>
          actions.openOrder(order.id, {
            ...subroute,
            subroute: CardRoute(selectedCardId),
          })
        }
        onClose={onClose}
        catalogSubroute={subroute.subroute}
        onCatalogNavigate={catalogSubroute => {
          actions.openOrder(order.id, {
            ...subroute,
            subroute: catalogSubroute,
          })
        }}
        orderApi={orderApi}
        actionContext={actionContext}
      />
    </>
  )
}

export default AddCard
