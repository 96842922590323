import { Operation } from '../../../__generated__/globalTypes'

import {
  ChildContactFragment,
  childContactFragmentString,
} from '../../fragments/__generated__/ChildContactFragment'

import { relatedContactFragmentString } from '../../fragments/__generated__/RelatedContactFragment'

import { contactFragmentString } from '../../fragments/__generated__/ContactFragment'

import { optionalYearDateFragmentString } from '../../fragments/__generated__/OptionalYearDateFragment'

import getChildrenRawString from '../GetChildren.graphql'

const getChildrenString = [
  getChildrenRawString,
  childContactFragmentString,
  relatedContactFragmentString,
  contactFragmentString,
  optionalYearDateFragmentString,
].join('\n\n')

export type GetChildrenData = {
  contact: {
    children: ChildContactFragment[]
  }
}

export type GetChildrenVariables = {
  contactId: string
}

export type GetChildren = Operation<GetChildrenData, GetChildrenVariables>

export const GetChildren = ({
  contactId,
}: GetChildrenVariables): GetChildren => ({
  query: getChildrenString,
  variables: {
    contactId,
  },
})
