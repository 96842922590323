import {
  Maybe,
  NotificationType,
  Operation,
} from '../../../__generated__/globalTypes'

import {
  NotificationFragment,
  notificationFragmentString,
} from '../../fragments/__generated__/NotificationFragment'

import getNotificationsRawString from '../GetNotifications.graphql'

const getNotificationsString = [
  getNotificationsRawString,
  notificationFragmentString,
].join('\n\n')

export type GetNotificationsData = {
  notifications: NotificationFragment[]
}

export type GetNotificationsVariables = {
  types?: Maybe<Maybe<NotificationType>[]>
  acknowledged?: Maybe<boolean>
}

export type GetNotifications = Operation<
  GetNotificationsData,
  GetNotificationsVariables
>

export const GetNotifications = ({
  types,
  acknowledged,
}: GetNotificationsVariables = {}): GetNotifications => ({
  query: getNotificationsString,
  variables: {
    types,
    acknowledged,
  },
})
