import { Flex, Separator } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import {
  PlanDetail,
  PlanIdType,
} from 'src/pricing_page/components/pricingTile/PlanTiles'
import { Features } from 'src/pricing_page/components/pricingTile/components/Tile'
import { usePlansSubscriptions, useSelector, useState } from 'src/hooks'
import { PricingSection } from 'src/saleor/components/price_breakdown/components/PricingSection'
import { SubscriptionSelectionTile } from 'src/SubscriptionChanger/components/SubscriptionSelectionTile'
import { SubscriptionFeaturesList } from 'src/SubscriptionChanger/components/SubscriptionFeaturesList'
import {
  FullAccessFeatureType,
  PlanFragment,
} from 'src/graphql/generated/graphql'
import { useFeatureAccess } from 'src/hooks/useFeatureAccess'
import { usePlans } from 'src/react_query'

type SubscriptionSelectionProps = {
  planIds: PlanIdType[]
  currentPlan?: PlanFragment
  onSelectPlan: (plan: PlanFragment) => void
}

export const SubscriptionSelection: React.FC<SubscriptionSelectionProps> = ({
  planIds: subscriptionPlanIds,
  currentPlan,
  onSelectPlan,
}) => {
  const [stagedPlan, setStagedPlan] = useState<PlanFragment | undefined>()
  const plansQuery = usePlans({ suspense: true })
  const plans = plansQuery.data
  const { newPlan } = usePlansSubscriptions()
  const isMobile = useSelector(state => state.window.width <= 1600)

  const containsCurrentPlan = subscriptionPlanIds.find(
    id => id.id === currentPlan?.id,
  )
  const finalSubscriptionIdList = containsCurrentPlan
    ? subscriptionPlanIds
    : [...subscriptionPlanIds, { id: currentPlan?.id }]
  const { hasFeatureAccess } = useFeatureAccess([
    FullAccessFeatureType.PremiumSubscriptionOptions,
  ])

  const activeSubscriptions: PlanFragment[] = finalSubscriptionIdList
    .map(id =>
      plans?.find((plan: PlanFragment) => id.id === plan.id && plan.id !== '1'),
    )
    .filter(
      (plan: PlanFragment | undefined): plan is PlanFragment =>
        !!plan && (!hasFeatureAccess || plan.id !== '161'),
    )

  const getOrderFromDescription = (description: string | null) => {
    if (!description) return 0
    try {
      const parsedDescription = JSON.parse(description)
      return parsedDescription.order ?? 0
    } catch (error) {
      return 0
    }
  }

  const isDowngraded =
    newPlan &&
    currentPlan &&
    (getOrderFromDescription(newPlan.description) ?? 0) <
      (getOrderFromDescription(currentPlan.description) ?? 0)
  const planDescriptionJson = stagedPlan?.description
    ? JSON.parse(stagedPlan.description)
    : ''

  const planFeatures: Features[] =
    planDescriptionJson?.details?.map((detail: PlanDetail) => {
      if (detail.innerDetails) {
        return {
          feature: detail.detail,
          subFeatures: detail.innerDetails.map((d: PlanDetail) => d.detail),
          isNotIncluded: false,
        }
      }
      return detail.detail
    }) || []

  const subscriptionAmount = stagedPlan?.price.amount ?? 0

  const convertAmountToCurrency = () => Math.round(subscriptionAmount / 100)

  const isSelectedPackage = false // TODO Wire this up with the task to select packages

  const handlePlanSelection = (plan: PlanFragment) => {
    setStagedPlan(plan)
    onSelectPlan(plan)
  }

  return (
    <Flex flexDirection="column" width="100%">
      <Flex
        width="100%"
        justifyContent="center"
        alignItems="stretch"
        columnGap="x3"
        outset={{ bottom: 'x3' }}
        flexDirection={isMobile ? 'column' : undefined}
      >
        <Flex
          width={isMobile ? '100%' : '50%'}
          flexDirection="column"
          outset={{ right: 'x3' }}
          style={{ flex: 1, alignItems: 'stretch' }}
        >
          {activeSubscriptions.map(plan => {
            if (!plan.description) return null
            const description = JSON.parse(plan.description)
            const isCurrentPlan = currentPlan?.id === plan.id
            const isPreviousPlan = isDowngraded && isCurrentPlan
            const planPillData = description.planPillData // Note the json should be of shape {text: string, backgroundColor: string, textColor: string}
            const planIndicatorBackground = description.indicatorBackground
            const planFeatures: Features[] =
              description?.details?.map((detail: PlanDetail) => {
                if (detail.innerDetails) {
                  return {
                    feature: detail.detail,
                    subFeatures: detail.innerDetails.map(
                      (d: PlanDetail) => d.detail,
                    ),
                    isNotIncluded: false,
                  }
                }
                return detail.detail
              }) || []

            return (
              planFeatures && (
                <SubscriptionSelectionTile
                  key={plan.id}
                  plan={plan}
                  onSelect={() => handlePlanSelection(plan)}
                  isSelected={plan.id === stagedPlan?.id}
                  isPreviousPlan={isPreviousPlan}
                  pill={
                    planPillData && {
                      text: planPillData.text,
                      backgroundColor: planPillData.backgroundColor,
                      textColor: planPillData.textColor,
                    }
                  }
                  indicatorBackground={planIndicatorBackground}
                />
              )
            )
          })}
        </Flex>
        <Flex
          id="subFeatureListContainer"
          width={isMobile ? '100%' : '50%'}
          flexDirection="column"
        >
          {planFeatures.length > 0 && (
            <Flex
              outset={{ bottom: 'x1' }}
              backgroundColor="#f2f2f2"
              borderRadius="medium"
              inset={{ vertical: 'x3', horizontal: 'x2' }}
              style={{
                border: '1px solid #e7e6e7',
                flex: 1,
                alignItems: 'stretch',
                justifyContent: 'center',
              }}
            >
              <SubscriptionFeaturesList features={planFeatures} />
            </Flex>
          )}
          {isSelectedPackage && (
            <>
              <PricingSection
                hasInvertedBackground={false}
                title="Total Today"
                subtitle="(package)"
              />
              <Separator orientation="horizontal" />
            </>
          )}
          <PricingSection
            hasInvertedBackground={false}
            title="Total Ongoing"
            subtitle="(subscription)"
            price={{
              __typename: 'Money',
              currency: stagedPlan?.price.currency ?? 'USD',
              amount: convertAmountToCurrency(),
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}
