import {
  Anchor,
  Div,
  LoadingSpinner,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { AnimatePresence } from 'framer-motion'
import FeaturedCardsGrid from 'src/catalog/components/FeaturedCardsGrid/FeaturedCardsGrid'
import { ToasterNotification } from 'src/editor/components/MobileEditorToolbar/components/ToasterNotification'
import {
  ContactRequestStatusEnum,
  UpdateRequestedContactInput,
} from 'src/graphql/generated/graphql'
import { getSponsorSuffix } from 'src/helpers/getSponsorSuffix'
import { useActions, useQueryParams, useSelector, useState } from 'src/hooks'
import { CreateContactInput } from 'src/legacy_graphql'
import {
  useContactRequestByToken,
  useSubmitRequestedContactMarketingContent,
  useUpdateRequestedContact,
} from 'src/react_query'
import { AddressForm } from '../components'
import { RequestSuccess } from '../components/RequestSuccess'
import { SubmitRequestedContactRoute } from '../routes/SubmitRequestedContact'

type SubmitRequestedContactProps = {
  route: SubmitRequestedContactRoute
}

const SubmitRequestedContact: React.FC<SubmitRequestedContactProps> = props => {
  const baseRoute = useSelector(state => state.route)
  const actions = useActions()

  const { token } = useQueryParams()

  const contactRequestQuery = useContactRequestByToken(
    { token: token ?? '' },
    { enabled: !!token },
  )
  const marketingContentQuery = useSubmitRequestedContactMarketingContent()
  const updateRequestedContactMutation = useUpdateRequestedContact()

  const [isSuccessOpen, setIsSuccessOpen] = useState(false)

  if (!token || contactRequestQuery.isError) {
    console.error('Error on submit contact screen')
    return (
      <CenterWrapper>
        <Text type="title">{'There was an error loading this page.'}</Text>
      </CenterWrapper>
    )
  }

  if (contactRequestQuery.isLoading || marketingContentQuery.isLoading) {
    return (
      <CenterWrapper>
        <LoadingSpinner size="large" />
      </CenterWrapper>
    )
  }

  const fullName = (firstName: string, lastName: string) => {
    return lastName ? `${firstName} ${lastName}` : firstName
  }

  const firstName = contactRequestQuery.data?.firstName ?? ''
  const lastName = contactRequestQuery.data?.lastName ?? ''
  const name = fullName(firstName, lastName)

  const requester = contactRequestQuery.data?.requester
  const requesterName = fullName(
    requester?.firstName ?? '',
    requester?.lastName ?? '',
  )

  const initialContact =
    firstName || lastName
      ? {
          firstName,
          lastName,
          address1: null,
          address2: null,
          company: null,
          city: null,
          state: null,
          country: null,
          postalCode: null,
        }
      : undefined

  const onSubmit = async (normalContactInput: CreateContactInput) => {
    const requestedContactInput: UpdateRequestedContactInput = {
      firstName: normalContactInput.firstName,
      lastName: normalContactInput.lastName,
      companyName: normalContactInput.companyName,
      address1: normalContactInput.address1,
      address2: normalContactInput.address2,
      city: normalContactInput.city,
      state: normalContactInput.state,
      postalCode: normalContactInput.postalCode,
      country: normalContactInput.country,
    }

    try {
      await updateRequestedContactMutation.mutateAsync({
        token: token,
        contact: requestedContactInput,
      })
    } catch (e) {
      console.error('Error updating requested contact', e)
      return
    }

    setIsSuccessOpen(true)
  }

  const marketingContent = marketingContentQuery.data
  const replacements = {
    name,
    requester: requesterName,
  }

  if (contactRequestQuery.data?.status === ContactRequestStatusEnum.Expired) {
    return (
      <CenterWrapper>
        <Text type="title" alignment="center">
          This address request is expired.
        </Text>
        <Spacer space="x1"></Spacer>
        <Text type="subtitle" alignment="center">
          <Anchor
            href="/?postcardPromo&verify"
            target="_self"
            size="xLarge"
            color="primaryBrand"
          >
            Click here
          </Anchor>{' '}
          to send your first free card.
        </Text>
      </CenterWrapper>
    )
  }

  const title = formatMarketingContent(
    marketingContent?.submitRequestedContactTitle.content ?? 'Hey {name}!',
    replacements,
  )
  const subtitle =
    contactRequestQuery.data?.status === ContactRequestStatusEnum.Completed
      ? formatMarketingContent(
          marketingContent?.submitRequestedContactCompletedSubtitle.content ??
            'Your address has been saved and {requester} can already send you cards! Fill out the form again if you want to update it.',
          replacements,
        )
      : formatMarketingContent(
          marketingContent?.submitRequestedContactSubtitle.content ??
            '{requester} is requesting your address to send you cards. If you would like to provide your address, please fill out the form below.',
          replacements,
        )

  const successTitle = formatMarketingContent(
    marketingContent?.submitRequestedContactSuccessTitle.content ??
      'Address Submitted!',
    replacements,
  )
  const successBody = formatMarketingContent(
    marketingContent?.submitRequestedContactSuccessBody.content ??
      'Your address has been added to their contact manager.',
    replacements,
  )

  // Add sponsor to the query args so that it gets persisted
  if (baseRoute.args.sponsor === undefined) {
    const sponsorSuffix = getSponsorSuffix(requester)
    if (sponsorSuffix) {
      actions.openRoute(baseRoute, {
        ...baseRoute.args,
        sponsor: sponsorSuffix,
      })
    }
  }

  const goToSendYourFirstFreeCard = () => {
    window.open('/?postcardPromo&verify', '_self')
  }

  // When changing this component, remember to check both the public and the private pages.
  return (
    <CenterWrapper>
      <Div
        position="fixed"
        style={{
          inset: '0',
          filter: 'blur(4px)',
        }}
      >
        <FeaturedCardsGrid />
      </Div>
      <Div
        display="flex"
        flexDirection="column"
        inset="x3"
        backgroundColor="#FFF"
        borderRadius="medium"
        maxWidth="650px"
        zIndex={1}
        style={{
          margin: '2rem',
        }}
      >
        <Text type="title" alignment="center">
          {title}
        </Text>
        <Text type="subtitle" alignment="center">
          {subtitle}
        </Text>
        <AddressForm
          shouldShowDates={false}
          isTitleVisible={false}
          contact={initialContact}
          style={{ paddingTop: '25px' }}
          onSubmit={onSubmit}
          isSaving={updateRequestedContactMutation.isLoading}
        />
      </Div>
      {isSuccessOpen && (
        <RequestSuccess
          isOpen={true}
          title={successTitle}
          description={successBody}
          onClose={() => {
            goToSendYourFirstFreeCard()
          }}
          primaryAction={{
            title: 'Send a card',
            onClick: () => {
              goToSendYourFirstFreeCard()
            },
          }}
        />
      )}
      {updateRequestedContactMutation.error && (
        <AnimatePresence>
          <ToasterNotification
            backgroundColor={{
              swatch: 'danger',
              shade: '_500',
            }}
            icon={{
              size: 'xSmall',
              name: 'information',
              primaryColor: 'inverseHeadingText',
              iconContainerColor: { swatch: 'success', shade: '_400' },
            }}
            label={{
              color: 'inverseHeading',
              type: 'footnote',
              content:
                'There was an error saving your address. Please contact support.',
            }}
          />
        </AnimatePresence>
      )}
    </CenterWrapper>
  )
}

const CenterWrapper: React.FC = (props: { children?: React.ReactNode }) => {
  // When logged in flex-grow doesn't make it fill the screen as the parent
  // is not a flex container. As it has no top bar, using 100dvh directly is fine.
  const isAuthenticated = !!useSelector(state => state.user.account)

  return (
    <Div
      flexGrow={1}
      minHeight={isAuthenticated ? '100dvh' : '300px'}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      {props?.children}
    </Div>
  )
}

const formatMarketingContent = (
  content: string,
  replacements: {
    [key: string]: string
  },
): string => {
  return Object.entries(replacements).reduce(
    (acc, [key, value]) => acc.replaceAll(`{${key}}`, value),
    content,
  )
}

export default SubmitRequestedContact
