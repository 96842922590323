import { Maybe, Operation } from '../../../__generated__/globalTypes'

import cancelPendingCardsString from '../CancelPendingCards.graphql'

export type CancelPendingCardsData = {
  cancelPendingCards: {
    refundInfo: {
      pointsBefore: Maybe<number>
      expenseBefore: Maybe<string>
      pointsAfter: Maybe<number>
      expenseAfter: Maybe<string>
    }
  }
}

export type CancelPendingCardsVariables = {
  productionIds: string[]
}

export type CancelPendingCards = Operation<
  CancelPendingCardsData,
  CancelPendingCardsVariables
>

export const CancelPendingCards = ({
  productionIds,
}: CancelPendingCardsVariables): CancelPendingCards => ({
  query: cancelPendingCardsString,
  variables: {
    productionIds,
  },
})
