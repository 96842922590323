import { Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { IconType } from 'src/design_system/atoms/icons/types'
import { Button } from 'src/design_system/components/Button/Button'

export type EmptyWidgetProps = {
  icon: IconType
  title: string
  description: string
  action: {
    title: string
    onClick: () => void
    titleColor?: string
    backgroundColor?: string
  }
  contentMaxWidth?: string
}

export const EmptyWidget: React.FC<EmptyWidgetProps> = ({
  icon,
  title,
  description,
  action,
  contentMaxWidth,
}) => {
  return (
    <Flex flexDirection="column" rowGap="x1" alignItems="center">
      <Flex
        width="100%"
        flexDirection={'column'}
        alignItems="center"
        justifyContent="center"
        inset={{ top: 'x2' }}
      >
        <Icon name={icon} size={20} color="#424548" />
        <Text
          content={title}
          type="largeBody"
          color="primaryHeading"
          weight="bold"
        />
      </Flex>
      <Flex
        flexDirection="column"
        alignItems="center"
        rowGap="x2"
        maxWidth={contentMaxWidth}
      >
        <Text
          type="caption"
          content={description}
          alignment="center"
          style={{ lineHeight: 1.5 }}
        />
        <Button
          title={action.title}
          textColor={action.titleColor ?? '#FFFFFF'}
          background={action.backgroundColor ?? '#424548'}
          onClick={action.onClick}
          borderRadius="8px"
          textSize="12px"
        />
      </Flex>
    </Flex>
  )
}
