import Papa from 'papaparse'
import startCase from 'lodash/startCase'
import toLower from 'lodash/toLower'

import { compact } from 'src/helpers'
import { ContactFragment } from 'src/legacy_graphql'

import COLUMNS, { Column, Field, MappedRow, ParsedRow, Row } from './columns'

export const IMPORT_CHUNK_SIZE = 50

export interface Props {
  rowLimit?: number
  additionalAction?: {
    onClick: (importedCustomers: ContactFragment[]) => void
    title: string
  }
}

export type ColumnDefaults = { country: string }

export type FieldMapping = Record<string, typeof COLUMNS[0]['apiName']>

export const getMappedColumn = (field: string, mapping: FieldMapping) => {
  return COLUMNS.find(x => x.apiName === mapping[field])
}

export const getMappedField = (mapping: FieldMapping, columnApiName: string) =>
  Object.keys(mapping).find(key => mapping[key] === columnApiName)

export enum Steps {
  Upload,
  ReadFile,
  MapHeaders,
  ProcessFile,
  EditData,
  Import,
  Completed,
}

interface UploadStep {
  type: Steps.Upload
}

interface ReadFileStep {
  type: Steps.ReadFile
}

interface MapHeadersStep {
  type: Steps.MapHeaders
  rows: Row[]
  fields: string[]
}

interface ProcessFileStep {
  type: Steps.ProcessFile
}

interface EditDataStep {
  type: Steps.EditData
  rows: Row[]
  fields: string[]
  mapping: FieldMapping
  defaults: ColumnDefaults
  dateFormat: string
}

interface ImportStep {
  type: Steps.Import
  parsedRows: Partial<ParsedRow>[]
}

interface CompletedStep {
  type: Steps.Completed
  parsedRows: Partial<ParsedRow>[]
  importedContacts: ContactFragment[]
}

export type Step =
  | UploadStep
  | ReadFileStep
  | MapHeadersStep
  | ProcessFileStep
  | EditDataStep
  | ImportStep
  | CompletedStep

/* Because rows have no unique id and can be replaced during the editing
   process, there is no way to properly identify them thoughout the import
   funnel. For that reason, we always reference rows by their index in the
   initial imported data array, which is what we store here as the key */
export type CheckedMap = Record<string, boolean | undefined>

export const stepperSteps = [
  {
    title: 'Select File',
    description: `Use a CSV or tab-delimited file to import your contacts.
                  We will attempt to auto-match your column header names.
                  You will be able to match and verify the column headers on the next step.`,
  },
  {
    title: 'Preview File & Map Headers',
    description: `We attempt to auto-map your headers to match required fields.
                  Please verify and adjust these mappings manually, if necessary.`,
  },
  {
    title: 'Read Full File',
    description: `Reading File`,
  },
  {
    title: 'Edit File',
    description: `You may correct data on individual contacts; when finished, select all contacts you would like to import`,
  },
  {
    title: 'Import Contacts',
    description: `Importing contacts to SendOutCards`,
  },
  {
    title: 'Import Contacts',
    description: `Contacts imported`,
  },
]

export const getStepperIndex = (step: Step): number => {
  switch (step.type) {
    case Steps.Upload:
    case Steps.ReadFile:
      return 0
    case Steps.MapHeaders:
      return 1
    case Steps.ProcessFile:
      return 2
    case Steps.EditData:
      return 3
    case Steps.Import:
      return 4
    case Steps.Completed:
      return 5
  }
}

export const getStepHash = (step: Step) => {
  switch (step.type) {
    case Steps.Upload:
    case Steps.ReadFile:
      return 'upload'
    case Steps.MapHeaders:
      return 'map-headers'
    case Steps.EditData:
      return 'edit-data'
    case Steps.Import:
    case Steps.Completed:
    case Steps.ProcessFile:
      return undefined
  }
}

export const exampleCsv = (() => {
  const encoding = `data:text/csv;charset=utf-8,`
  const headers = COLUMNS.map(header => header.display).join(',')
  const data = COLUMNS.map(header => header.example).join(',')

  return `${encoding}\n${headers}\n${data}\n`
})()

export const LOCAL_STORAGE_KEY = 'imported-csv'

export const parseCsvFromFile = (
  file: File,
  callback: (rows: Row[], fields: string[]) => void,
) => {
  Papa.parse(file, {
    header: true,
    complete: result => callback(result.data as Row[], result.meta.fields),
  })
}

export const generateMapping = (fields: string[]): FieldMapping =>
  fields.reduce((mapping, field) => {
    const normalized = field.toLowerCase()
    const match = COLUMNS.find(header => header.matches.includes(normalized))
    return match ? { ...mapping, [field]: match.apiName } : mapping
  }, {})

export type MappedColumnWithField<T extends Field> = {
  field: string
  column: Column<T>
}

export const getMappedColumns = (
  mapping: FieldMapping,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
): MappedColumnWithField<any>[] =>
  compact(
    ...COLUMNS.map(column => {
      const field = Object.keys(mapping).find(
        field => mapping[field] === column.apiName,
      )
      return field ? { field, column } : undefined
    }),
  )

export const getMissingRequiredColumns = (mapping: FieldMapping) => {
  const mappedColumns = getMappedColumns(mapping)

  return COLUMNS.filter(column => column.mappingRequired).filter(
    column =>
      !mappedColumns.some(mappedColumn => mappedColumn.column === column),
  )
}

/* Given a raw row from the CSV, produces a row with the standard
   keys (as Column['apiName']), fixing the data along the way */
export const getMappedRow = (
  mapping: FieldMapping,
  row: Row,
  defaults: ColumnDefaults,
) =>
  getMappedColumns(mapping).reduce(
    <T extends Field>(
      mappedRow: MappedRow,
      mappedColumn: MappedColumnWithField<T>,
    ) => {
      const { column, field } = mappedColumn
      const rawValue = row[field]

      const value = (() => {
        if (column.apiName === 'country') {
          if (!rawValue) return defaults.country
          if (
            [
              'usa',
              'u.s.a',
              'united states',
              'america',
              'united states of america',
              "'merica",
              'us',
            ].includes(rawValue.trim().toLowerCase())
          ) {
            return 'United States'
          }

          return rawValue === 'SendOutCards'
            ? rawValue
            : startCase(toLower(rawValue ?? '').trim())
        }

        return (rawValue ?? '').trim()
      })()

      return { ...mappedRow, [column.apiName]: value }
    },
    {},
  )

export const getDateColumns = (mapping: FieldMapping) =>
  getMappedColumns(mapping).filter(
    mappedColumn => mappedColumn.column.type === 'date',
  )

export const hasAnyDateColumn = (mapping: FieldMapping) =>
  getDateColumns(mapping).length > 0

export const getRowError = (
  row: MappedRow,
  mappedColumns: MappedColumnWithField<Field>[],
  dateFormat: string,
  defaults: ColumnDefaults,
) =>
  mappedColumns.reduce(
    <T extends Field>(
      error: string | undefined,
      mappedColumn: MappedColumnWithField<T>,
    ) => {
      const { column } = mappedColumn

      const value =
        (row[column.apiName] ?? '') ||
        (() => {
          switch (column.apiName) {
            case 'country':
              return defaults.country
            default:
              return ''
          }
        })()

      return column.getError(row, value, column, dateFormat) || error
    },
    undefined as string | undefined,
  )
