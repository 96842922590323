import { QueryFunctionContext, QueryKey } from '@tanstack/react-query'

export type PaginatedQueryFnArgs = QueryFunctionContext<QueryKey, string | null>

export const connectionFactory = (
  direction: 'forward' | 'backward',
  results: number,
  cursor?: string,
) => ({
  after: direction === 'forward' ? cursor : undefined,
  before: direction === 'backward' ? cursor : undefined,
  first: direction === 'forward' ? results : undefined,
  last: direction === 'backward' ? results : undefined,
})
