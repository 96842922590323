import React from 'react'

import styles from './editableTable.module.scss'

type TableHeaderProps = {}

const TableHeader: React.FC<TableHeaderProps> = props => (
  <thead>
    <tr className={styles.header}>{props.children}</tr>
  </thead>
)

export default TableHeader
