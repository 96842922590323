import socialSharingRawString from '../SocialSharing.graphql'

export const socialSharingString = socialSharingRawString

export type SocialSharing = {
  __typename: 'SocialSharing'
  welcomeMessage: string
  shareLink: string
}

export const isSocialSharing = (fragment: any): fragment is SocialSharing =>
  fragment &&
  fragment.__typename === 'SocialSharing' &&
  typeof fragment.welcomeMessage === 'string' &&
  typeof fragment.shareLink === 'string'
