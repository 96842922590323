import React from 'react'
// @src imports
import FontFace from 'src/editor/components/FontFace/FontFace'
import { Button, Transition } from 'src/chrome'
import { Footer } from 'src/styled'
import { CardType } from 'src/graphql/generated/graphql'
import {
  getDetailedLayouts,
  getImages,
  getInfiniteDetailedLayouts,
  LayoutCategory as LegacyLayoutCategory,
  LayoutDimensions as LegacyLayoutDimensions,
  Owner as LegacyOwner,
} from 'src/legacy_graphql'
import { GlobalEditor, GlobalEditorProvider } from '@sendoutcards/editor'
// relative imports

import EditorToolBar from '../../components/EditorToolBar/EditorToolBar'
import EditorTabs from '../EditorTabs/EditorTabs'
import EditorDrawer from '../EditorDrawer/EditorDrawer'
import ContextBar from '../../components/ContextBar/ContextBar'
import EditorFooterContents from '../../components/EditorFooterContents/EditorFooterContents'
import styles from './styles'
import { Props, Steps } from '../../types'
import apiFactory from '../../api'
import {
  useAccount,
  useActions,
  useCallback,
  useDeviceInfo,
  useEffect,
  useMeasurements,
  useMutations,
  useQueries,
  useSelector,
  useState,
} from 'src/hooks'
import { panelInput } from 'src/editor/CardUpdate'
import range from 'lodash/range'
import {
  editorFullBleed,
  editorPanel,
  newEditorCard,
  newEditorColor,
  newEditorLayout,
  stickerFragmentToImageType,
  userImageFragmentToImageType,
} from '../../utils'
import {
  Button as QDButton,
  ConfirmDialog,
  Dialog,
  Flex,
  HStack,
  Icon,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { getCardDimensions, isNotNullOrUndefined } from 'src/helpers'
import { selectedVariationId as getSelectedVariationId } from '../../../redux/selectors/editor'
import removeUsersnap from 'src/helpers/removeUsersnap'
import WindowWidthNotSupported from 'src/editor/components/WindowWidthNotSupported/WindowWidthNotSupported'
import { Card } from '@sendoutcards/editor/dist/types/card'
import { MobileContextBar } from './components/MobileContextBar'
import { ToasterNotification } from '../../components/MobileEditorToolbar/components/ToasterNotification'
import { AnimatePresence } from 'framer-motion'
import { Portal } from 'src/portal/portal'
import useAndroidOrientation from 'src/hooks/useAndroidOrientation'
import { countLineItems } from 'src/orders/helpers'
import {
  MiniCart,
  MiniCartProps,
} from 'src/orders/components/MiniCart/MiniCart'
import {
  useCreateOrUpdateBackPanel,
  useStickers,
  useUpdateAccount,
} from 'src/react_query'
import {
  layoutDimensionsToLegacy,
  layoutFragmentFromLegacy,
} from 'src/graphql/compat'
import omit from 'lodash/omit'
import { Color } from '@sendoutcards/editor/dist/types/primary'
import { factoryFBForNewCardEditor } from 'src/editor/EditorCard'

const hasSpanView = (cardType: CardType): boolean => {
  switch (cardType) {
    case CardType.TwoPanel:
    case CardType.ThreePanel:
    case CardType.TwoPanelBig:
      return true
    default:
      return false
  }
}

type leaveOptions = 'catalog' | 'cardHistory' | 'myAccount'

const Editor: React.FC<Props> = props => {
  const api = apiFactory(props)

  const {
    context,
    saveButtonLabel,
    saveButtonBackground,
    saveButtonColor,
    secondaryAction,
    onBackToCatalog,
    orderApi,
    handleAddToOrder,
    onReload,
  } = props
  const {
    step,
    isMobile,
    resolveLocation,
    card,
    fullBleeds,
    setFullBleeds,
    fonts,
    isSaving,
    setIsSaving,
    panelView,
    setPanelView,
    handleSave,
    handleSaveAndSend,
    setSelectedBackLayoutId,
    toasterNotification,
    setToasterNotification,
    canShowNewCardEditor,
    errorState,
    setErrorState,
  } = api

  const hasContextBar = !!context?.lines
  const handleSecondaryAction = async () => {
    await handleSave()
    removeUsersnap()
    secondaryAction?.onClick()
  }
  const newSecondaryAction = secondaryAction
    ? {
        label: secondaryAction?.label,
        onClick: () =>
          handleSecondaryAction().catch(error => console.error(error)),
      }
    : undefined

  // TODO: React Query Refactor, not sure if this is what we want to do
  // but RQ returns potentially undefined results
  if (!card) throw Error('No card provided!')

  const [shouldShowNewCardEditor] = useState(card.isNewEditorCard)
  const [showConfirmVisible, setShowConfirmVisible] = useState(false)
  const [selectedVariationID] = useState(
    getSelectedVariationId(card, fullBleeds),
  )
  const allFonts = fonts.all ?? []

  // NOTE: This is a temporary tool for local test of the new card editor
  // Switch this to true when using a local build of the current soc-editor
  const shouldUseNewWIPCardEditor = false

  const [newCard, setNewCard] = useState<Card>(
    newEditorCard(
      card,
      shouldUseNewWIPCardEditor ? factoryFBForNewCardEditor(card) : fullBleeds,
      allFonts,
      selectedVariationID ?? null,
    ),
  )

  const [shouldShowConfirmLeave, setShouldShowConfirmLeave] = useState(false)
  const [savingTransitionMessage, setSavingTransitionMessage] = useState(
    'Saving your card...',
  )

  const [leaveTo, setLeaveTo] = useState<leaveOptions>('catalog')
  const showNewCardEditor = shouldShowNewCardEditor && canShowNewCardEditor
  const mutations = useMutations()
  const createOrUpdateBackPanelMutation = useCreateOrUpdateBackPanel()
  const actions = useActions()
  const updateAccountMutation = useUpdateAccount()
  const account = useAccount()
  const { orders } = useSelector(state => state)
  const { windowWidth, windowHeight } = useMeasurements()
  const deviceInfo = useDeviceInfo()
  const isLandscapeiPhone = windowHeight < windowWidth ? true : false
  const { orientation: androidOrientation } = useAndroidOrientation()
  const [shouldShowLandscapeWarning, setShouldShowLandscapeWarning] = useState(
    false,
  )
  const [mainCss, setMainCss] = useState(styles.main)

  const [images, layouts, backPanels, systemBackPanels] = useQueries(
    showNewCardEditor ? getImages() : undefined,
    showNewCardEditor
      ? getDetailedLayouts({
          category: LegacyLayoutCategory.BASIC_LAYOUTS,
          offset: 0,
          limit: 100,
          dimensions: layoutDimensionsToLegacy(getCardDimensions(card)),
        })
      : undefined,
    showNewCardEditor
      ? getInfiniteDetailedLayouts({
          category: LegacyLayoutCategory.BACK_PANELS,
          owner: LegacyOwner.USER,
          dimensions: LegacyLayoutDimensions.VERTICAL,
        })
      : undefined,
    showNewCardEditor
      ? getInfiniteDetailedLayouts({
          category: LegacyLayoutCategory.BACK_PANELS,
          owner: LegacyOwner.SYSTEM,
        })
      : undefined,
  )

  const {
    data: stickers,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useStickers()

  const miniCartProps: MiniCartProps = {
    orderId: orders.order ? orders.order.id : '',
    editorCard: api.card,
    containerId: 'editor_context_bar',
    button: {
      count: orders.order && countLineItems(orders.order.lines),
    },
    checkoutAction: {
      onCheckout: () =>
        handleSaveAndSend().catch(error => console.error(error)),
      title: 'Proceed to Cart',
    },
    previewZIndex: 1002,
    orderPreviewActionOverride: {
      onAddGift: {
        title: 'How would you like to proceed?',
        description:
          'Would you like to add this card to your order and also add a gift or discard this card and proceed to the gift store?',
        accept: {
          title: 'Add card to order and proceed to add a gift',
          shouldUseDefaultAction: true,
          onClick: async () => {
            setIsSaving(true)
            try {
              await handleSave()
              setIsSaving(true)
              setSavingTransitionMessage('Saving your card...')
              await handleAddToOrder?.(card.id)
              setIsSaving(true)
              setSavingTransitionMessage('Adding your card to your order...')
              await onReload?.()
            } catch (error) {
              console.error(error)
            } finally {
              setIsSaving(false)
            }
          },
        },
        decline: {
          title: 'Discard current card and continue to gift store',
          shouldUseDefaultAction: true,
        },
      },
    },
    orderApi: orderApi,
  }

  useEffect(() => {
    if (!showNewCardEditor) {
      removeUsersnap()
    }
  }, [showNewCardEditor])

  useEffect(() => {
    setNewCard(
      newEditorCard(
        card,
        shouldUseNewWIPCardEditor
          ? factoryFBForNewCardEditor(card)
          : fullBleeds,
        allFonts,
        selectedVariationID ?? null,
      ),
    )
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (deviceInfo.device === 'AndroidPhone') {
      if (/landscape/i.test(androidOrientation)) {
        setShouldShowLandscapeWarning(true)
      } else {
        setShouldShowLandscapeWarning(false)
      }
    }
    if (deviceInfo.device === 'iPhone') {
      if (isLandscapeiPhone) {
        setShouldShowLandscapeWarning(true)
      } else {
        setShouldShowLandscapeWarning(false)
      }
    }
  }, [isLandscapeiPhone, androidOrientation, deviceInfo.device])

  // Add/Removed toast notification clear
  useEffect(() => {
    if (toasterNotification) {
      setTimeout(() => {
        setToasterNotification(null)
      }, 2800)
    }
  }, [toasterNotification, setToasterNotification])

  const updateIosHeight = useCallback(() => {
    setMainCss({
      ...(styles.main as Record<string, unknown>),
      height: `${window.innerHeight}px`,
    })
  }, [])

  useEffect(() => {
    if (deviceInfo.os === 'iOS' && !CSS.supports('height', '100dvh')) {
      updateIosHeight()
      window.addEventListener('resize', updateIosHeight)
      return () => {
        window.removeEventListener('resize', updateIosHeight)
      }
    } else if (
      (deviceInfo.os === 'MacOS' && deviceInfo.browser === 'Safari') ||
      !CSS.supports('height', '100dvh')
    ) {
      setMainCss({
        ...(styles.main as Record<string, unknown>),
        height: '100vh',
      })
    }
    return
  }, [deviceInfo.os, deviceInfo.browser, updateIosHeight])

  if (showNewCardEditor && layouts && backPanels && systemBackPanels) {
    const mutableContextZoneOptions = [
      {
        onClick: () => handleSave().catch(error => console.error(error)),
        title: 'Save Changes',
      },
      {
        onClick: () => handleSaveAndSend().catch(error => console.error(error)),
        title: 'Save and Send',
      },
    ]
    if (newSecondaryAction)
      mutableContextZoneOptions.push({
        onClick: () => newSecondaryAction.onClick(),
        title: newSecondaryAction.label,
      })
    // tslint:disable-next-line
    const fullName = `${account.firstName} ${account.lastName}`

    const setBackPanelDefaultLayout = async (id: string) => {
      const result = await updateAccountMutation.mutateAsync({
        account: { settings: { defaultBackPanelId: id } },
      })
      actions.updatedAccount(result.updateAccount.account)
    }

    const confirmedLeave = () => {
      setShouldShowConfirmLeave(false)
      api.onClose()
      switch (leaveTo) {
        case 'cardHistory':
          actions.openCardHistory()
          return
        case 'myAccount':
          actions.openAccount()
          return
        case 'catalog':
          actions.openCatalog()
          return
        default:
          return
      }
    }

    return (
      <GlobalEditorProvider>
        {windowWidth < 960 && shouldShowNewCardEditor && (
          <WindowWidthNotSupported
            isOpen={shouldShowNewCardEditor}
            closeEditor={api.onClose}
            title={
              'Oops! You need a larger screen in order to edit/complete this card.'
            }
            subtitle={`You can either exit to the Card Catalog and we will save your progress or simply use a larger screen to return full card personalization capabilities for completion.`}
          />
        )}
        {shouldShowConfirmLeave && (
          <ConfirmDialog
            title="You have made unsaved changes to this card"
            description="This card will be available in your Account under 'Drafted Cards'. Any unsaved changes will be lost."
            primaryAction="decline"
            isOpen={shouldShowConfirmLeave}
            accept={{
              title: 'Continue without saving',
              onClick: confirmedLeave,
            }}
            decline={{
              title: 'Return to card editor',
              onClick: () => {
                setShouldShowConfirmLeave(false)
              },
            }}
            onClose={() => {
              setShouldShowConfirmLeave(false)
            }}
          />
        )}
        <GlobalEditor
          colorPalettes={{
            palette: api.designColors?.palette?.map(color =>
              newEditorColor(color),
            ),
            isLoading: api.designColors.isLoading,
          }}
          defaultBackPanel={[
            account.settings?.defaultBackPanel?.id ?? '',
            setBackPanelDefaultLayout,
          ]}
          userInfo={{
            id: account.id,
            name: fullName,
            email: account.email,
          }}
          card={newCard}
          fonts={fonts.fonts}
          signatures={fonts.signatures}
          placeholders={[
            'First Name',
            'Last Name',
            'Spouse Name',
            'First Name & Spouse Name',
          ]}
          layouts={layouts.map(layout =>
            newEditorLayout(allFonts)(layoutFragmentFromLegacy(layout)),
          )}
          defaultBackPanels={{
            ...systemBackPanels,
            results: systemBackPanels.results.map(layout =>
              newEditorLayout(allFonts)(layoutFragmentFromLegacy(layout)),
            ),
          }}
          customBackPanels={{
            ...backPanels,
            results: backPanels.results.map(layout =>
              newEditorLayout(allFonts)(layoutFragmentFromLegacy(layout)),
            ),
          }}
          images={{
            ...images,
            hasMore: images?.hasMore ?? false,
            loadMore: images?.loadMore ?? (() => {}),
            isLoadingMore: images?.isLoadingMore ?? false,
            results:
              images?.results.map(result =>
                userImageFragmentToImageType(result),
              ) ?? [],
          }}
          stickers={{
            hasMore: hasNextPage ?? false,
            isLoadingMore: isFetchingNextPage,
            loadMore: fetchNextPage,
            results:
              stickers?.pages
                .flatMap(page => page.results)
                .map(result => stickerFragmentToImageType(result)) ?? [],
          }}
          onCreateBackPanel={async (panel, layoutId) => {
            const { backgroundColor, ...rest } = panel
            const bgColor = omit(backgroundColor, ['__typename']) as Color
            await createOrUpdateBackPanelMutation.mutateAsync({
              panel: panelInput(
                editorPanel({ backgroundColor: bgColor, ...rest }, allFonts),
              ),
              layoutId,
            })
          }}
          onSaveBackPanel={async (panel, layoutId) => {
            const { backgroundColor, ...rest } = panel
            const bgColor = omit(backgroundColor, ['__typename']) as Color
            await createOrUpdateBackPanelMutation.mutateAsync({
              panel: panelInput(
                editorPanel({ backgroundColor: bgColor, ...rest }, allFonts),
              ),
              layoutId,
            })
          }}
          onDeleteBackPanel={id => mutations.deleteLayout({ id })}
          onSelectBackLayout={setSelectedBackLayoutId}
          onUploadImages={files =>
            range(files.length)
              .map(index => files.item(index))
              .filter(isNotNullOrUndefined)
              .forEach(image_file =>
                mutations.uploadImage({
                  image_file,
                  tags: ['prompt-editor'],
                }),
              )
          }
          onDeleteImage={id => mutations.deleteImage({ id })}
          onSave={({ fullBleeds }) => {
            setFullBleeds(fullBleeds.map(editorFullBleed(allFonts)))
          }}
          contextZones={{
            save: {
              isLoading: isSaving,
              loadingMessage: 'Saving Changes',
              options: mutableContextZoneOptions,
            },
            clientNavigation: [
              {
                section: {
                  title: 'Menu',
                  description: '',
                  links: [
                    {
                      title: 'Back To Catalog',
                      icon: 'catalog',
                      onClick: () => {
                        setShouldShowConfirmLeave(true)
                        setLeaveTo('catalog')
                      },
                    },
                    {
                      title: 'Card History',
                      icon: 'envelope',
                      onClick: () => {
                        setShouldShowConfirmLeave(true)
                        setLeaveTo('cardHistory')
                      },
                    },
                    {
                      title: 'My Account',
                      icon: 'user',
                      onClick: () => {
                        setShouldShowConfirmLeave(true)
                        setLeaveTo('myAccount')
                      },
                    },
                  ],
                },
              },
            ],
            primary: (
              <HStack justify="flex-end" gap="x3">
                <QDButton
                  type="primary"
                  size="xSmall"
                  onClick={() =>
                    handleSaveAndSend().catch(error => console.error(error))
                  }
                >
                  {'Save & Send'}
                </QDButton>
              </HStack>
            ),
            externalActions: (
              <HStack justify="flex-end" gap="x2_5" outset={{ left: 'x2' }}>
                <MiniCart {...miniCartProps} height="31px" />
                {context?.footerLabel(card)}
              </HStack>
            ),
          }}
          isUsingOldFlattener={true}
        />
      </GlobalEditorProvider>
    )
  }

  return (
    <div
      className="order-editor"
      css={() => styles.container(step.type !== Steps.Idle)}
    >
      {shouldShowLandscapeWarning && (
        <Portal attachToContainerId="app">
          <Dialog
            isOpen={shouldShowLandscapeWarning}
            zIndex={10001}
            insetOverride="x4"
          >
            <Flex justifyContent="center">
              <Icon name="imageSwap" size={50} primaryColor="primaryBodyText" />
            </Flex>
            <Spacer space="x2" />
            <Text
              type="subtitle"
              weight="bold"
              color="primaryHeading"
              alignment="center"
            >
              Landscape mode not supported
            </Text>
            <Spacer space="x2" />
            <Text type="body" alignment="center">
              We currently do not support landscape mode for mobile devices. For
              a better experience please switch to portrait mode.
            </Text>
            <Spacer space="x2" />
            <QDButton
              type="primary"
              onClick={() => setShouldShowLandscapeWarning(false)}
              fullWidth={true}
            >
              Ok
            </QDButton>
          </Dialog>
        </Portal>
      )}
      <FontFace fonts={api.fonts} />
      {isSaving && <Transition message={savingTransitionMessage} />}
      {errorState && (
        <Dialog isOpen={true} onClose={() => setErrorState(undefined)}>
          <Flex flexDirection="column" alignItems="center" rowGap="x2">
            <Text type="subtitle" alignment="center">
              {errorState.title}
            </Text>
            <Text type="body" alignment="center">
              For further assistance, contact the Customer Success team at (801)
              463-3800 to resolve this issue.
            </Text>
            <Text type="body">Report error code: {`${errorState.error}`}</Text>
            <Button title="Ok" onClick={() => setErrorState(undefined)} />
          </Flex>
        </Dialog>
      )}
      <div css={styles.sidebar}>
        <EditorToolBar
          api={api}
          setShowConfirmVisible={setShowConfirmVisible}
          showConfirmVisible={showConfirmVisible}
          onBackToCatalog={onBackToCatalog}
        />
      </div>
      <div css={styles.drawerContainer}>
        {step.type !== Steps.Idle && <EditorDrawer api={api} />}
      </div>
      <div id={'main'} css={mainCss}>
        {!isMobile && context?.headerText && (
          <ContextBar
            lines={context?.lines}
            title={context?.headerText}
            subtitle={context?.headerSubText}
            activeCardId={card.id}
          />
        )}
        <>
          <div css={styles.headerContainer} id="promo-modal-container">
            {isMobile && (
              <MobileContextBar
                miniCartProps={miniCartProps}
                onExit={() => setShowConfirmVisible(true)}
                cardType={card.type}
                panelLocation={resolveLocation(card.type)}
                isFullBleed={panelView === 'fullbleed'}
                onChangePanelView={() =>
                  setPanelView(panelView === 'panel' ? 'fullbleed' : 'panel')
                }
              />
            )}
            {!isMobile && hasSpanView(card.type) && (
              <Button
                title={panelView === 'fullbleed' ? 'Panel View' : 'Span View'}
                onClick={() =>
                  setPanelView(
                    panelView === 'fullbleed' ? 'panel' : 'fullbleed',
                  )
                }
                style={{
                  position: 'absolute',
                  right: 15,
                  zIndex: 102,
                  ...(hasContextBar || context?.headerText ? { top: 90 } : {}),
                }}
              />
            )}
          </div>
          <EditorTabs
            saveButtonLabel={saveButtonLabel}
            api={api}
            swipeableViewWidth={
              isMobile
                ? '100%'
                : step.type !== Steps.Idle
                ? 'calc(100vw - 380px)'
                : 'calc(100vw - 65px)'
            }
          />
        </>
        {!isMobile && card && (
          <Footer css={styles.footer}>
            <EditorFooterContents
              label={context?.footerLabel(card)}
              saveButtonLabel={saveButtonLabel}
              saveButtonBackground={saveButtonBackground}
              saveButtonColor={saveButtonColor}
              onSave={() => {
                handleSaveAndSend().catch(error => console.error(error))
              }}
              secondaryAction={newSecondaryAction}
              miniCartProps={miniCartProps}
            />
          </Footer>
        )}
      </div>

      <AnimatePresence>
        {isMobile && toasterNotification && (
          <ToasterNotification
            backgroundColor={{
              swatch: toasterNotification.type,
              shade: '_500',
            }}
            icon={{
              size: 'xSmall',
              name: toasterNotification.iconName,
              primaryColor: 'inverseHeadingText',
              iconContainerColor: { swatch: 'success', shade: '_400' },
            }}
            label={{
              color: 'inverseHeading',
              type: 'footnote',
              content: toasterNotification.content,
            }}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default Editor
