import React, { FC } from 'react'
import { Button, Div, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import { useActions } from 'src/hooks'
import { useRemoveSearchParams } from 'src/hooks/useRemoveSearchParams'

export const SamePlanNotification: FC<{}> = () => {
  const actions = useActions()
  const clearSearchParams = useRemoveSearchParams()

  const continueToAccount = () => {
    clearSearchParams(['join', 'planSelected', 'promo'])
    actions.clearRouteArgs()
    actions.openAccount()
  }

  return (
    <Div>
      <Spacer space="x4" />
      <Text type="largeBody" alignment="center" weight="semiBold">
        Good news!
      </Text>
      <Text type="body" alignment="center">
        You already have the selected plan active!
      </Text>
      <Spacer space="x4" />
      <Button type="success" fullWidth={true} onClick={continueToAccount}>
        Continue to Account
      </Button>
    </Div>
  )
}

export default SamePlanNotification
