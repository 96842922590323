export type DrawerStateTypes =
  | 'images'
  | 'facebookImages'
  | 'filters'
  | 'templates'
  | 'text'
  | 'background'
  | 'Closed'

const DrawerState = {
  images: 'images' as DrawerStateTypes,
  facebookImages: 'facebookImages' as DrawerStateTypes,
  filters: 'filters' as DrawerStateTypes,
  templates: 'templates' as DrawerStateTypes,
  text: 'text' as DrawerStateTypes,
  background: 'background' as DrawerStateTypes,
  closed: 'Closed' as DrawerStateTypes,
}

export default DrawerState
