import React from 'react'

import { EditableCell, EditableTable, TableHeader, TableRow } from 'src/chrome'

import { FieldMapping, getMappedColumn } from '../helpers'
import { Row } from '../columns'

type ImportedListProps = {
  contacts: Row[]
  userFields: string[]
  mapping: FieldMapping
}

const ImportedList: React.FC<ImportedListProps> = props => {
  const { contacts, userFields, mapping } = props

  return (
    <div>
      <EditableTable>
        <TableHeader>
          {userFields.map(field => (
            <th key={field} style={{ padding: '5px 6px' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ fontWeight: 500, whiteSpace: 'nowrap' }}>
                  {getMappedColumn(field, mapping)?.display ?? '(Not Assigned)'}
                </span>
                <span style={{ fontWeight: 300, fontSize: 13 }}>{field}</span>
              </div>
            </th>
          ))}
        </TableHeader>

        {contacts.map((contact, index) => (
          <tbody key={index}>
            <TableRow>
              {userFields.map((field, fieldIndex) => (
                <EditableCell
                  key={Math.random()}
                  cellId={{ row: fieldIndex, cell: field }}
                  value={contact[field]}
                />
              ))}
            </TableRow>
          </tbody>
        ))}
      </EditableTable>
    </div>
  )
}

export default ImportedList
