import styled, { ObjectInterpolation } from '@emotion/styled'

export const cssStyles = <
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  Styles extends { [key: string]: ObjectInterpolation<any> }
>(
  styles: Styles,
) => styles

export const FlexColumn = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

export const FlexRow = styled.div({ display: 'flex', flexDirection: 'row' })

export const Footer = styled.footer({
  width: '100%',
  height: 50,
  background: '#fff',
})

export const OrderOverviewTitle = styled.h5({
  fontFamily: 'Montserrat',
  display: 'inline-block',
  '&:after': {
    content: '""',
    width: '50%',
    position: 'relative',
    height: 3,
    backgroundImage: 'linear-gradient(115deg, #ff5689, #e687ff)',
    display: 'block',
  },
})

// ClipboardLink
export const Link = styled.div({ display: 'flex', width: '100%' })

export const Clipboard = styled.div({
  padding: 4,
  borderTopRightRadius: 5,
  borderBottomRightRadius: 5,
  backgroundColor: '#BE9CDD',

  cursor: 'pointer',
  border: '1px solid #BE9CDD',
})

export const Input = styled.input({
  height: 40,
  border: '1px solid rgba(0, 0, 0, .20)',
  borderTopLeftRadius: 5,
  borderBottomLeftRadius: 5,
  padding: 5,
  fontSize: 14,
  width: '100%',
})

// ContactType
export const H4 = styled.h4({
  color: 'black',
  opacity: 0.25,
  fontSize: 13,
  position: 'absolute',
  left: 14,
  top: -8,
})

// Notification
export const Line = styled.div({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  p: { marginRight: 5 },
  span: {
    fontSize: '1.5rem',
    fontWeight: 500,
    textTransform: 'capitalize',
    marginRight: '1rem',
  },
})

// RoundedFooter
export const FairUse = styled.a({
  color: '#ff5689',
  display: 'inline-block',
})

// MenuItem
export const MenuItemLink = styled.a({
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
})

// SponsorCard
export const SponsorName = styled.span({
  textOverflow: 'ellipsis',
  fontWeight: 500,
  flex: 'auto',
  fontSize: '1.75rem',
})

// AffiliateLinks
export const Description = styled.div({
  padding: '15px 0 4px 0',
  fontSize: 14,
})

// Subscription
export const Details = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: '5px 0 0 0',
  alignItems: 'flex-end',
  width: '100%',
})
export const SubDate = styled.div({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  padding: '5px 30px',
  justifyContent: 'space-between',
})
export const MoreInfo = styled.a({
  paddingLeft: '1rem',
  textDecoration: 'underline',
  color: '#6f8394',
  '&:hover': {
    color: 'hotpink',
  },
})
