import React from 'react'

import {
  Div,
  Flex,
  Icon,
  IconColors,
  IconType,
  Text,
  TextColor,
  TextType,
} from '@sendoutcards/quantum-design-ui'
import {
  LiteralUnion,
  Responsive,
} from '@sendoutcards/quantum-design-ui/dist/src/helpers/hoc-types/entityValueTypes'
import { BackgroundColor } from '@sendoutcards/quantum-design-ui/dist/src/helpers/hoc-types/hocBaseUnion'
import { SizeType } from '@sendoutcards/quantum-design-ui/dist/src/types/global'
import { Portal } from '@material-ui/core'

export type ToasterNotificationProps = {
  icon: {
    size: LiteralUnion<SizeType> | number
    name: IconType
    primaryColor: LiteralUnion<IconColors>
    iconContainerColor: BackgroundColor
  }
  label: {
    color: TextColor
    type: TextType | Responsive<TextType>
    content: string | number
  }
  backgroundColor: BackgroundColor
}

export const ToasterNotification: React.FC<ToasterNotificationProps> = ({
  icon,
  label,
  backgroundColor,
}) => {
  return (
    <Portal>
      <Div
        position={'fixed'}
        bottom={'95px'}
        width="100%"
        style={{
          borderRadius: 15,
          margin: '0 auto',
          zIndex: 3000,
        }}
        transition={{
          type: 'spring',
          damping: 10,
          mass: 0.75,
          stiffness: 100,
        }}
        initial={{ opacity: 0, scale: 0.75 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0 }}
      >
        <Flex
          id="notificationCardMainContainer"
          backgroundColor={backgroundColor}
          outset="0 auto"
          height="35px"
          width="fit-content"
          alignItems="center"
          justifyContent="flex-start"
          flexDirection="row"
          flexWrap="nowrap"
          borderRadius="default"
          zIndex={2000}
          boxShadow={'mediumDark'}
        >
          <Div
            display="flex"
            outset={{ left: 'x1' }}
            inset="x_25"
            justifyContent="center"
            alignItems="center"
            borderStyle="solid"
            backgroundColor={icon.iconContainerColor}
            borderRadius="small"
          >
            <Icon
              name={icon.name}
              size={icon.size}
              primaryColor={icon.primaryColor}
            />
          </Div>
          <Div
            justifyContent="center"
            alignItems="center"
            inset={{ left: 'x1' }}
            width="100%"
          >
            <Text
              weight="bold"
              color={label.color}
              type={label.type}
              content={label.content}
              inset={{ right: 'x2' }}
            />
          </Div>
        </Flex>
      </Div>
    </Portal>
  )
}
