import { Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { CreditCardLogo } from 'src/payments/components'
import { CreditCardBrand } from 'src/payments/components/CardLogo/CreditCardLogo'
import CreditCard from 'src/payments/components/CreditCard/CreditCard'

type MethodCardProps = {
  title: string
  last4: string
  type?: CreditCardBrand
  name: string
  exp: string
}

const MethodLogoText: React.FC<{ last4: string; type: CreditCardBrand }> = ({
  last4,
  type,
}) => {
  return (
    <Flex flexDirection="row" alignItems="center" justifyContent="center">
      <CreditCardLogo brand={type ?? 'Visa'} />
      <Text
        content={`Ending In ${last4}`}
        type="body"
        weight="bold"
        color="primaryHeading"
      />
    </Flex>
  )
}
export const MethodCard: React.FC<MethodCardProps> = ({
  title,
  last4,
  type,
  name,
  exp,
}) => {
  return (
    <Flex
      position="relative"
      borderRadius="21px"
      backgroundColor="foreground"
      inset="x2"
    >
      <MethodLogoText last4={last4} type={type ?? 'Visa'} />
      <Div position="absolute" right="16px" top="-16px">
        <CreditCard
          cardType={type ?? 'Visa'}
          last4={last4}
          name={name}
          exp={exp}
          width={'125px'}
          height="auto"
        />
      </Div>
    </Flex>
  )
}
