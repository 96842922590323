import { Flex, StackedCards, Text } from '@sendoutcards/quantum-design-ui'
import React, { FC } from 'react'
import { Button } from 'src/design_system/molecules/button/Button'
import { useSelector } from 'src/hooks'

type BulkEligibleProps = {
  stackedCardImage?: string
  onClick?: () => void
  isHorizontal?: boolean
}

export const BulkSendEligible: FC<BulkEligibleProps> = ({
  stackedCardImage,
  onClick,
  isHorizontal,
}) => {
  const shouldStackBulkSection = useSelector(state => state.window.width < 540)
  return (
    <Flex
      width="100%"
      inset={{ horizontal: 'x3', vertical: 'x2' }}
      backgroundColor="#323334"
      borderRadius="16px"
      flexDirection="column"
      rowGap="x1"
    >
      <Flex flexDirection={shouldStackBulkSection ? 'column' : 'row'}>
        <Flex
          justifyContent="center"
          alignItems="center"
          outset={shouldStackBulkSection ? { vertical: 'x2' } : { right: 'x5' }}
        >
          <StackedCards
            width={!isHorizontal ? 100 : 160}
            height={!isHorizontal ? 160 : 100}
            stackCount={5}
            backgroundImage={stackedCardImage}
          />
        </Flex>
        <Flex
          width="100%"
          flexDirection="column"
          justifyContent="center"
          alignItems={shouldStackBulkSection ? 'center' : undefined}
          rowGap="x1"
        >
          <Text
            alignment={shouldStackBulkSection ? 'center' : 'left'}
            type="largeBody"
            weight="bold"
            content="Bulk Send Option Available"
            color="inverseHeading"
          />
          <Text
            alignment={shouldStackBulkSection ? 'center' : 'left'}
            type="caption"
            color="inverseHeading"
            content="Turn your custom card into a bulk order! Select the quantity you need using the slider and we will mail them directly to you with blank white envelopes, then you can send them how and when you wish. Stamps not included"
            lineHeight={1.4}
          />
          <Text
            type="footnote"
            style={{ fontSize: '9px' }}
            content="*Stamps not included."
            color="inverseHeading"
          />
          <Button
            title={{ content: 'Add Bulk Option', fontSize: '13px' }}
            onClick={onClick}
            padding="12px 16px"
            borderRadius="small"
            fill="#fff"
          />
        </Flex>
      </Flex>
    </Flex>
  )
}
