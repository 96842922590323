import React from 'react'
import { Icon } from 'src/chrome'

import * as SendDelayOptions from 'src/orders/sendDelayOptions'

import styles from './cardThumbnailTile.module.scss'
import { CampaignLineFragment } from 'src/graphql/generated/graphql'
import { Text } from '@sendoutcards/quantum-design-ui'

interface Props {
  activeLine: CampaignLineFragment
  line: CampaignLineFragment
  onCardSelect: () => void
  isCloseButtonEnabled: boolean
}

const CardThumbnailTile: React.FC<Props> = props => {
  const { activeLine, line, onCardSelect, isCloseButtonEnabled } = props
  return (
    <div
      style={{ width: line.card?.isHorizontal ? 126.2 : 90.4 }}
      className={
        activeLine.card?.id === line.card?.id
          ? `${styles.cardContainer} ${styles.active}`
          : styles.cardContainer
      }
    >
      <div className={styles.card} onClick={() => onCardSelect()}>
        <img
          style={{ display: 'none' }}
          alt="Used for onLoad capabilities to set/unset loading icon"
          src={line.card?.frontPreviewUrl ?? ''}
        />
        <div
          className={
            activeLine.card?.id === line.card?.id
              ? line.card?.isHorizontal
                ? `${styles.landscape} ${styles.active}`
                : `${styles.portrait} ${styles.active}`
              : line.card?.isHorizontal
              ? styles.landscape
              : styles.portrait
          }
          style={{
            display: 'flex',
            backgroundImage: `url(${line.card?.frontPreviewUrl ?? ''})`,
          }}
        >
          {isCloseButtonEnabled && (
            <button className={styles.closeButton}>
              <Icon icon={'CLOSE'} size={13} color="#F262B1" />
            </button>
          )}
        </div>
        {line.giftVariation && (
          <div
            className={
              activeLine.card?.id === line.card?.id
                ? `${styles.active} ${styles.gift}`
                : styles.gift
            }
            style={{
              backgroundImage: `url(${line.giftVariation.defaultImageUrl})`,
            }}
          />
        )}
      </div>
      <div className={styles.delayDescription}>
        <Text type="caption">
          {SendDelayOptions.ribbonDescription(line.sendDelay)}
        </Text>
      </div>
    </div>
  )
}

export default CardThumbnailTile
