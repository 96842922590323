import React from 'react'
import { CircularProgress } from '@material-ui/core'
// @src imports
import { DefaultError } from 'src/chrome'
import { getImages, ImageFragment } from 'src/legacy_graphql'
import { useMutations, useQueries, useSelector } from 'src/hooks'
import Images from './Images'
import suspenseBoundary from 'src/chrome/SuspenseBoundary/suspenseBoundaryHOC'
import { Api, ToasterNotification } from '../api'

import UploadFooter from './UploadFooter'
import { IsMemoizedFuction } from 'src/hooks/dependencies'

type Props = {
  api: Api
  onSelect?: ((image: ImageFragment) => void) & IsMemoizedFuction
}

const SOCImages: React.FC<Props> = props => {
  const isMobile = useSelector(state => state.window.isMobile)

  const {
    api: { isImageSettingExpanded, setToasterNotification },
    onSelect,
  } = props
  const mutations = useMutations()
  const [{ results: images, isLoadingMore, hasMore, loadMore }] = useQueries(
    getImages(),
  )

  const imageDeletedToasterNotification: ToasterNotification = {
    iconName: 'squareCheck',
    content: 'Image deleted successfully',
    type: 'success',
  }

  const handleDeleteImage = (id: string) => {
    try {
      mutations.deleteImage({ id })
    } catch (err) {
      console.log(err)
    } finally {
      if (isMobile) setToasterNotification(imageDeletedToasterNotification)
    }
  }

  return (
    <>
      <Images
        result={images.map(image => ({
          ...image,
          smallThumb: image.smallThumb ? image.smallThumb : image.url,
        }))}
        isLoading={isLoadingMore}
        hasMore={hasMore}
        loadMore={loadMore}
        onSelect={onSelect}
        deleteImage={handleDeleteImage}
        isExpanded={isImageSettingExpanded}
        isMobile={isMobile}
      />
      {!isMobile && <UploadFooter api={props.api} onSelect={onSelect} />}
    </>
  )
}
export default suspenseBoundary({
  component: SOCImages,
  unresolved: (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress />
    </div>
  ),
  failure: DefaultError,
})
