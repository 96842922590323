import React from 'react'
import { Set } from 'immutable'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import TextField from '@material-ui/core/TextField'

import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import { Button as ChromeButton, Dialog } from 'src/chrome'
import { CampaignLineFragment } from 'src/graphql/generated/graphql'
import { useEffect, useSelector, useState } from 'src/hooks'

import styles from './createOrderModal.module.scss'
import {
  Anchor,
  Button,
  Div,
  Flex,
  Text,
} from '@sendoutcards/quantum-design-ui'

interface Props {
  lines: CampaignLineFragment[]
  onCreateCampaign: (
    lines: CampaignLineFragment[],
    campaignName: string,
  ) => void
  onSubmit: (lines: CampaignLineFragment[] | null, campaignName: string) => void
  onClose: () => void
  isOpen: boolean
}

const CreateOrderModal: React.FC<Props> = props => {
  const { lines, onCreateCampaign, onSubmit, onClose, isOpen } = props

  const { isMobile } = useSelector(state => state.window)

  const [campaignName, setCampaignName] = useState('')
  const [
    isCreateCampaignOverlayOpen,
    setIsCreateCampaignOverlayOpen,
  ] = useState(false)
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false)
  const [selected, setSelected] = useState(Set())

  const handleChange = (index: number) => {
    const newSet = selected.has(index)
      ? selected.delete(index)
      : selected.add(index)
    setSelected(newSet)
    setIsSelectAllChecked(newSet.size === lines.length)
  }

  const handleClose = () => {
    setIsSelectAllChecked(false)
    setSelected(Set())
    onClose()
  }

  const handleCreateCampaign = () => {
    const selectedLines: CampaignLineFragment[] = selected
      .toArray()
      .map(value => lines[value])
    onCreateCampaign(selectedLines, campaignName)
  }

  const handleSelectAll = () => setIsSelectAllChecked(!isSelectAllChecked)

  useEffect(() => {
    // After 'isSelectAllChecked' state is changed, we need to set all the selected cards appropriately
    setSelected(
      Set(isSelectAllChecked ? lines.map((line, index) => index) : []),
    )
  }, [isSelectAllChecked, lines])

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault()

    const selectedLines =
      selected.size === lines.length
        ? null
        : selected.toArray().map(value => lines[value])
    onSubmit(selectedLines, campaignName)
  }

  const handleTextInput = (event: React.ChangeEvent<HTMLInputElement>) =>
    setCampaignName(event.currentTarget.value)

  const showOverlay = () =>
    setIsCreateCampaignOverlayOpen(!isCreateCampaignOverlayOpen)

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      scroll={'paper'}
      BackdropProps={{ style: { backdropFilter: 'blur(4px)' } }}
    >
      <DialogTitle disableTypography={true}>
        <Div display="flex">
          <Text
            outset={{ right: 'x_5' }}
            type="caption"
            content={'Select items:'}
          />
          <Text type="caption" weight="bold" color="primaryHeading">
            {selected.size} selected
          </Text>
        </Div>
      </DialogTitle>
      <DialogContent
        style={{ position: 'relative' }}
        className={styles.content}
      >
        <Div className={styles.delayForm}>
          <form onSubmit={handleSubmit} className={styles.formContainer}>
            <Div
              outset={{ bottom: 'x4' }}
              className={styles.linesContainer}
              id={'tour-linesContainer'}
            >
              {lines.map((line, index) => {
                return (
                  <Div
                    key={index}
                    className={
                      line.card?.isHorizontal
                        ? `${styles.tileContainer} ${styles.horizontal}`
                        : styles.tileContainer
                    }
                    onClick={() => handleChange(index)}
                  >
                    <div
                      className={
                        line.card?.isHorizontal
                          ? `${styles.card} ${styles.horizontal}`
                          : styles.card
                      }
                      style={
                        selected.has(index)
                          ? {
                              border: '2px solid #ff5689',
                              backgroundImage: `url(${
                                line.card?.frontPreviewUrl ?? ''
                              })`,
                            }
                          : {
                              backgroundImage: `url(${
                                line.card?.frontPreviewUrl ?? ''
                              })`,
                            }
                      }
                    />
                    {line.giftVariation && (
                      <div
                        className={styles.gift}
                        style={{
                          backgroundImage: `url(${line.giftVariation.defaultImageUrl})`,
                        }}
                      />
                    )}
                  </Div>
                )
              })}
            </Div>
            <Flex alignContent="center" className={styles.actionButton}>
              <FormControlLabel
                control={
                  <Checkbox
                    id={'select_all_btn'}
                    checked={isSelectAllChecked}
                    onChange={handleSelectAll}
                  />
                }
                label="Select All"
              />
            </Flex>
          </form>
        </Div>
        {isCreateCampaignOverlayOpen && (
          <div className={styles.overlay}>
            <div>
              <Text type="body">Name your new campaign</Text>
              <TextField
                label={'Name'}
                InputLabelProps={{
                  classes: {
                    root: styles.nameInputLabel,
                  },
                }}
                InputProps={{
                  classes: {
                    root: styles.nameInput,
                    underline: styles.disabledNameInput,
                  },
                }}
                value={campaignName}
                onChange={handleTextInput}
              />
              <div style={{ display: 'flex', paddingTop: 10 }}>
                <ChromeButton
                  title={'Close'}
                  onClick={showOverlay}
                  style={{ marginRight: 10, width: 100 }}
                />
                <ChromeButton
                  title={'Submit'}
                  buttonColor={'pink'}
                  disabled={campaignName.length < 1}
                  onClick={handleCreateCampaign}
                />
              </div>
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Flex
          width="100%"
          justifyContent="center"
          alignContent="center"
          flexDirection={isMobile ? 'column' : 'row'}
          outset="x2"
        >
          <Flex
            position="absolute"
            top="x1"
            right={isMobile ? 'x1_5' : 'x_5'}
            outset={isMobile ? { vertical: 'x1' } : { horizontal: 'x1' }}
          >
            <Anchor
              key={'Cancel create order'}
              onClick={handleClose}
              title="Cancel"
              color="danger"
            />
          </Flex>
          <Flex outset={isMobile ? { vertical: 'x1' } : { horizontal: 'x1' }}>
            <Button
              backgroundColorOverride="#677789"
              key={'Create Campaign Button'}
              disabled={selected.size < 1}
              onClick={showOverlay}
              type="secondary"
              title="Copy to New"
              fullWidth={true}
            />
          </Flex>
          <Flex outset={isMobile ? { vertical: 'x1' } : { horizontal: 'x1' }}>
            <Button
              type="success"
              key={'Submit created order'}
              disabled={selected.size < 1 || isCreateCampaignOverlayOpen}
              onMouseEvent={handleSubmit}
              id="submit_order_btn"
              fullWidth={true}
              title="Send Campaign"
            />
          </Flex>
        </Flex>
      </DialogActions>
    </Dialog>
  )
}
export default CreateOrderModal
