import { Div, Flex, Icon, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { useQueries } from 'src/hooks'
import { getFairUseMarketingContent } from 'src/legacy_graphql/queryDeclarations'
import SenderImage from './SenderImage'

interface Props {
  cap: number
  onClose: () => void
}

const OrderWarningBanner: React.FC<Props> = props => {
  const { cap, onClose } = props

  const [fairUseMarketingContent] = useQueries(getFairUseMarketingContent())
  const fairUsePolicyLink = fairUseMarketingContent.fairUsePolicyLink.url

  return (
    <Flex
      width="100%"
      maxWidth="1200px"
      justifyContent="flex-start"
      alignItems="center"
      inset={{ top: 'x2', horizontal: 'x5' }}
      outset={{ vertical: 'x3', horizontal: 'auto' }}
      style={{
        backgroundColor: '#FEFBF0',
        borderRadius: '40px',
        border: '4px solid #ffcc66',
      }}
    >
      <Flex>
        <Div width="244px" height="153px" style={{ marginTop: 'auto' }}>
          <SenderImage />
        </Div>
        <Spacer space="x2" orientation="horizontal" />
        <Flex flexDirection="column" inset={{ bottom: 'x2' }}>
          <Text type="largeBody" weight="bold" style={{ color: '#C79D3E' }}>
            {`Great job! You have acted on your promptings and sent ${cap} Heartfelt
            Cards to those who needed to hear from you.`}
          </Text>
          <Spacer space="x1_5" />
          <Text type="body" style={{ color: '#C79D3E' }}>
            For the remainder of the day, your cards will be marked as System
            Cards as you have reached your maximum on Heartfelt Card sends
            today. You may continue sending at no additional cost tomorrow
            according to the{' '}
            <a
              href={fairUsePolicyLink}
              style={{
                fontFamily: 'inherit',
                color: '#C79D3E',
                fontWeight: 500,
                textDecoration: 'underline',
              }}
              data-mktcontent="fairUsePolicyLink"
            >
              Fair Use Policy
            </a>
            . <b style={{ font: 'inherit', fontWeight: 500 }}>Happy sending!</b>
          </Text>
        </Flex>
        <Spacer space="x2" orientation="horizontal" />
        <Div
          minWidth="x3"
          maxHeight="x4"
          inset={{ top: 'x1' }}
          onClick={onClose}
          cursor="pointer"
        >
          <Icon name="close" size="small" primaryColor="#C79D3E" />
        </Div>
      </Flex>
    </Flex>
  )
}

export default OrderWarningBanner
