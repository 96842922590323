import {
  AccountFragment,
  Amount,
  Currency,
  CurrencyType,
  DetailedOrderFragment,
} from 'src/graphql/generated/graphql'

const creditCardRequired = (
  order: Pick<DetailedOrderFragment, 'cost'>,
  account: Pick<AccountFragment, 'points' | 'expense'>,
  credits: Amount,
  isUsingCredits: boolean,
) => {
  const points = account.points.amount

  const expense = account.expense.amount

  const creditsAmount =
    credits.currency === Currency.Unavailable ? 0 : credits.amount
  const creditsAndExpenses = creditsAmount + expense

  for (const { currency, amount } of order.cost.total) {
    if (currency === CurrencyType.Point && amount > points) {
      return true
    } else if (
      isUsingCredits &&
      currency === CurrencyType.Usd &&
      amount > creditsAndExpenses
    ) {
      return true
    } else if (currency === CurrencyType.Expense && amount > expense) {
      return true
    } else if (currency === CurrencyType.Usd && amount > expense) {
      return true
    }
  }

  return false
}

export default creditCardRequired
