import React from 'react'
import TextField from '@material-ui/core/TextField'
import Avatar from '@material-ui/core/Avatar'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'

import CircularProgress from '@material-ui/core/CircularProgress'
// @src imports
import { Icon } from 'src/chrome'
import { useDebounce, useQueryFutures, useState } from 'src/hooks'
import {
  getPaginatedCampaigns,
  MinimalCampaignFragment,
} from 'src/legacy_graphql'

import styles from './GroupAddCreate/groupAddCreate.module.scss'
import groupSelectStyles from './GroupSelect/groupSelect.module.scss'
import { Button, Text } from '@sendoutcards/quantum-design-ui'

type Props = {
  onClose: () => void
  onSubmit: (campaign: MinimalCampaignFragment) => void
}

const SelectCampaign: React.FC<Props> = props => {
  const { onClose, onSubmit } = props

  const [page, setPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const [selected, setSelected] = useState<MinimalCampaignFragment>()

  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  const [{ value: campaigns, isUnresolved: isLoading }] = useQueryFutures(
    getPaginatedCampaigns({ search: debouncedSearchTerm, page }),
  )

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (!selected) return
    onSubmit(selected)
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.container}>
        <span className={styles.close} onClick={() => onClose()}>
          <Icon icon={'CLOSE'} color={'#6f8394'} size={18} />
        </span>
        <div className={styles.body}>
          <div className={styles.formInner}>
            <div className={styles.field}>
              <div className={styles.chooseGroupBlock}>
                <div className={groupSelectStyles.container}>
                  <div className={styles.textInput}>
                    <TextField
                      placeholder="Search for campaign"
                      fullWidth={true}
                      onChange={e => setSearchTerm(e.target.value)}
                    />
                  </div>
                  <div
                    className={styles.groupList}
                    style={{
                      height: 300,
                      overflow: 'auto',
                    }}
                  >
                    <List>
                      {campaigns?.results?.map(campaign => {
                        return (
                          <ListItem
                            key={campaign.id}
                            style={
                              campaign.id === selected?.id
                                ? { backgroundColor: '#CCCCCC' }
                                : {}
                            }
                            onClick={() => setSelected(campaign)}
                          >
                            <ListItemAvatar>
                              <Avatar
                                src={
                                  campaign.minimalLines[0]?.card
                                    ?.frontPreviewUrl ?? ''
                                }
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={<Text type="body">{campaign.name}</Text>}
                            />
                          </ListItem>
                        )
                      })}
                      {!isLoading && campaigns && (
                        <ListItem onClick={() => setPage(x => x + 1)}>
                          <ListItemText
                            primary={
                              <Text type="body">
                                {campaigns.results.length < campaigns.count
                                  ? 'Click to get more'
                                  : ''}
                              </Text>
                            }
                            secondary={
                              <Text type="body">
                                showing {campaigns.results.length} of{' '}
                                {campaigns.count}
                              </Text>
                            }
                          />
                        </ListItem>
                      )}
                      {isLoading && (
                        <ListItem>
                          <CircularProgress />
                        </ListItem>
                      )}
                    </List>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.actions}>
          <Button
            id={'submit_btn'}
            title="Submit"
            backgroundColorOverride="#e841b3"
            onClick={'submitForm'}
            disabled={!selected}
            fontWeight="extraBold"
            size="small"
          />
        </div>
      </div>
    </form>
  )
}

export default SelectCampaign
