import { Maybe } from '../../../__generated__/globalTypes'

import draftFragmentRawString from '../DraftFragment.graphql'

export const draftFragmentString = draftFragmentRawString

export type DraftFragment = {
  __typename: 'Card'
  id: string
  frontPreviewUrl: string
  insidePreviewUrl: Maybe<string>
  isHorizontal: boolean
  updatedAt: string
}

export const isDraftFragment = (fragment: any): fragment is DraftFragment =>
  fragment &&
  fragment.__typename === 'Card' &&
  typeof fragment.id === 'string' &&
  typeof fragment.frontPreviewUrl === 'string' &&
  (fragment.insidePreviewUrl === null ||
    typeof fragment.insidePreviewUrl === 'string') &&
  typeof fragment.isHorizontal === 'boolean' &&
  typeof fragment.updatedAt === 'string'
