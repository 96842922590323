import React from 'react'
import fromPairs from 'lodash/fromPairs'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import { Button, Icon } from 'src/chrome'
import { DetailedContactFragment } from 'src/legacy_graphql'
import { Group } from 'src/contacts/contactTypes'
import { useMemo } from 'src/hooks'

import GroupSelect from '../GroupSelect/GroupSelect'
import styles from './contactGroupsForm.module.scss'
import { Text } from '@sendoutcards/quantum-design-ui'

type Props = {
  groups: Group[]
  contact: DetailedContactFragment
  isSaving: boolean
  isSaveRequired: boolean
  onSave: (contactInput: DetailedContactFragment) => void
  onAddGroup: (group: Group) => void
  onRemoveGroup: (group: Group) => void
  onCancel: (id: string) => void
}

const ContactGroupsForm: React.FC<Props> = props => {
  const {
    groups,
    contact,
    isSaving,
    isSaveRequired,
    onSave,
    onAddGroup,
    onRemoveGroup,
    onCancel,
  } = props

  const contactGroupIds = fromPairs(
    contact.groups.map(group => [group.id, true]),
  )

  const availableGroups = useMemo(
    () => groups.filter(group => !contactGroupIds[group.id]),
    [groups, contactGroupIds],
  )

  const selectedGroups = useMemo(
    () => groups.filter(group => contactGroupIds[group.id]),
    [groups, contactGroupIds],
  )

  const handleSave = () => {
    onSave({
      ...contact,
      groups: selectedGroups.map(group => ({ __typename: 'Group', ...group })),
    })
  }

  return (
    <section className={styles.container}>
      <div className={styles.selectorBlock}>
        <div className={styles.allGroups}>
          <Text type="body" color="primaryHeading">
            Available groups
          </Text>
          <GroupSelect groups={availableGroups} onGroupChange={onAddGroup} />
        </div>
        <div className={styles.contactGroups}>
          <Text type="body" color="primaryHeading">
            Current Groups
          </Text>
          <div className={styles.selectedGroups}>
            <List>
              {selectedGroups.map(group => (
                <ListItem key={group.id}>
                  <ListItemText
                    primary={
                      <Text type="body" color="primaryHeading">
                        {group.name}
                      </Text>
                    }
                    secondary={
                      group.description ? (
                        <Text type="caption">{group.description}</Text>
                      ) : (
                        <React.Fragment />
                      )
                    }
                  />
                  <ListItemIcon>
                    <Icon
                      icon={'CLOSE'}
                      color="#ff5689"
                      size={15}
                      onClick={() => onRemoveGroup(group)}
                      style={{ top: 4 }}
                    />
                  </ListItemIcon>
                </ListItem>
              ))}
            </List>
          </div>
        </div>
      </div>
      {isSaveRequired && (
        <div className={styles.actions}>
          <Button
            title={'Cancel'}
            onClick={() => onCancel(contact.id)}
            style={{ marginRight: 20 }}
          />
          <Button
            title={isSaving ? 'Saving...' : 'Save'}
            buttonColor={'pink'}
            onClick={handleSave}
            disabled={isSaving}
          />
        </div>
      )}
    </section>
  )
}

export default ContactGroupsForm
