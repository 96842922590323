import { Maybe } from '../../../__generated__/globalTypes'

import {
  GiftVariationFragment,
  isGiftVariationFragment,
} from './GiftVariationFragment'

import giftFragmentRawString from '../GiftFragment.graphql'

export const giftFragmentString = giftFragmentRawString

export type GiftFragment = {
  __typename: 'Gift'
  name: string
  id: string
  realId: string
  defaultImageUrl: string
  defaultVariation: Maybe<GiftVariationFragment>
}

export const isGiftFragment = (fragment: any): fragment is GiftFragment =>
  fragment &&
  fragment.__typename === 'Gift' &&
  typeof fragment.name === 'string' &&
  typeof fragment.id === 'string' &&
  typeof fragment.realId === 'string' &&
  typeof fragment.defaultImageUrl === 'string' &&
  (fragment.defaultVariation === null ||
    (isGiftVariationFragment(fragment.defaultVariation) as boolean))
