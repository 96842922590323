import React from 'react'
import {
  Div,
  Flex,
  Icon,
  StackedCards,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { AnimatePresence } from 'framer-motion'
import { useSelector } from 'src/hooks'
import { OrderDetailsSection } from '../OrderDetail/OrderDetailsSection'

type BulkOrderNotEligibleProps = {
  stackedCardImage: string
}

export const BulkOrderNotEligible: React.FC<BulkOrderNotEligibleProps> = ({
  stackedCardImage,
}) => {
  const shouldStackBulkSection = useSelector(state => state.window.width < 480)

  return (
    <div>
      <AnimatePresence>
        <OrderDetailsSection>
          <Flex
            width="100%"
            inset={{ horizontal: 'x3', vertical: 'x2' }}
            backgroundColor="foreground"
            borderRadius="12px"
            borderColor={{ swatch: 'danger', shade: 'base' }}
            borderStyle="solid"
            borderWidth="default"
          >
            <Flex>
              <Div right="x1" position="absolute" top="x1">
                <Icon
                  name="information"
                  size="small"
                  primaryColor="secondaryBrand"
                />
              </Div>
              <Flex
                flexDirection={shouldStackBulkSection ? 'column' : 'row'}
                alignItems="center"
              >
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  outset={
                    shouldStackBulkSection
                      ? { vertical: 'x2' }
                      : { right: 'x4' }
                  }
                >
                  <StackedCards
                    width={100}
                    height={75}
                    stackCount={5}
                    backgroundImage={stackedCardImage}
                  />
                </Flex>
                <Flex
                  width="50%"
                  flexDirection="column"
                  justifyContent={
                    shouldStackBulkSection ? 'center' : 'flex-start'
                  }
                >
                  <Flex justifyItems="center" alignItems="center">
                    <Icon
                      primaryColor="danger"
                      size="small"
                      name="closeCircle"
                    />
                    <Text
                      outset={{ left: 'x1' }}
                      alignment={shouldStackBulkSection ? 'center' : 'left'}
                      type="largeBody"
                      weight="bold"
                      content="Bulk Option Unavailable"
                    />
                  </Flex>
                  <Text
                    alignment={shouldStackBulkSection ? 'center' : 'left'}
                    type="caption"
                    content="Your order contains card type(s), multiple cards, or gifts - which are not compatible with bulk sending. Select a single, flat or 2-panel card to create a bulk order."
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </OrderDetailsSection>
      </AnimatePresence>
    </div>
  )
}
