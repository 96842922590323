import React from 'react'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import { Text } from '@sendoutcards/quantum-design-ui'

interface AddDefaultsProps {
  defaults: string[]
  countries: string[]
  country: string
  handleChange: (name: string, value: string) => void
}

const AddDefaults: React.FC<AddDefaultsProps> = ({
  defaults,
  countries,
  country,
  handleChange,
}) => (
  <div style={{ marginTop: 50, padding: 10 }}>
    <Text type="caption" color="primaryBrand">
      *You have some required columns that have not been mapped to a column in
      your file. You can select a default that will be applied to ALL rows or
      change your file and restart.
    </Text>
    <div>
      {defaults &&
        defaults.map((def, index) => {
          if (def === 'country') {
            return (
              <div key={index}>
                <Text type="caption">Country</Text>
                <TextField
                  select={true}
                  fullWidth={true}
                  value={country}
                  placeholder={'Select a country'}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    typeof event.target.value == 'string' &&
                    handleChange('country', event.target.value)
                  }
                  name="default"
                  SelectProps={{
                    MenuProps: {
                      style: {
                        maxHeight: 200,
                      },
                    },
                  }}
                  style={{ width: '100%', paddingRight: '15px' }}
                >
                  {countries.map(country => (
                    <MenuItem key={country} value={country}>
                      {country}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            )
          } else {
            return <div />
          }
        })}
    </div>
  </div>
)

export default AddDefaults
