import React, { FC } from 'react'
import { useState } from 'src/hooks'
// relative imports
import Payment, { PaymentInfo } from '../containers/Payment'
import { Dialog, Text } from '@sendoutcards/quantum-design-ui'

interface Props {
  isOpen: boolean
  paymentInfo?: PaymentInfo
  onClose?: () => void
  onSuccess?: () => void
  onError?: (error: Error | string) => void
}

const PaymentModal: FC<Props> = props => {
  const [isPurchasing, setIsPurchasing] = useState(false)
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false)

  const { isOpen, paymentInfo, onClose, onSuccess, onError } = props

  return (
    <Dialog
      closeButtonId="payment_modal"
      isOpen={isOpen}
      onClose={isPurchasing ? undefined : onClose}
      insetOverride={isPaymentSuccessful ? 'x0' : undefined}
      maxWidth={isPaymentSuccessful ? '850px' : 'calc(100vw - 32px)'}
      maxHeight="calc(100vh - 64px)"
      shouldScroll={true}
    >
      {!isPaymentSuccessful && (
        <Text
          type="title"
          color="primaryHeading"
          weight="bold"
          outset={{ bottom: 'x2' }}
        >
          {'Checkout'}
        </Text>
      )}
      <Payment
        paymentInfo={paymentInfo}
        onPurchasingChange={setIsPurchasing}
        onPurchaseSuccess={() => {
          onSuccess?.()
          setIsPaymentSuccessful(true)
        }}
        onPurchaseError={onError}
        onClose={() => {
          onClose?.()
          setIsPaymentSuccessful(false)
        }}
      />
    </Dialog>
  )
}

export default PaymentModal
