import React from 'react'
// @src imports
import SOCImages from 'src/editor/images/SOCImages'
import { useSelector, useState } from 'src/hooks'
// relative imports
import ScaleSlider from './ScaleSlider'
import TextBarMobile from './TextBarMobile/TextBarMobile'
import { ToasterNotification } from 'src/editor/api'

import { Api } from '../api'
import { Steps } from '../types'
import {
  Div,
  Flex,
  Icon,
  IconType,
  Spacer,
  Text,
  TextType,
} from '@sendoutcards/quantum-design-ui'
import { SizeType } from '@sendoutcards/quantum-design-ui/dist/src/types/global'
import {
  LiteralUnion,
  Responsive,
} from '@sendoutcards/quantum-design-ui/dist/src/helpers/hoc-types/entityValueTypes'
import { Portal } from '@material-ui/core'
import { ImageFragment } from 'src/legacy_graphql'
import { IsMemoizedFuction } from 'src/hooks/dependencies'

const items: {
  name: Control
  icon: IconType
  size: LiteralUnion<SizeType> | number
  type: TextType | Responsive<TextType>
}[] = [
  {
    name: 'Images' as const,
    icon: 'image' as const,
    size: 'small',
    type: 'caption',
  },
  {
    name: 'Scale' as const,
    icon: 'size' as const,
    size: 'small',
    type: 'caption',
  },
  {
    name: 'Remove' as const,
    icon: 'delete' as const,
    size: 'small',
    type: 'caption',
  },
]

type Props = {
  api: Api & { step: { type: Steps.EditPicture | Steps.EditBackgroundPicture } }
  onSelect?: ((image: ImageFragment) => void) & IsMemoizedFuction
  closeDuvet?: () => void
}

type Control = 'Images' | 'Scale' | 'Remove'

const MinimalImageOptions: React.FC<Props> = props => {
  const { api, onSelect, closeDuvet } = props
  const {
    removePicture,
    step,
    editPicture,
    removeBackgroundPicture,
    updateElementImage,
    editBackgroundPicture,
    getSelectedElement,
    setToasterNotification,
    isImageScaleActive,
    setIsImageScaleActive,
  } = api
  const isMobile = useSelector(state => state.window.isMobile)
  const [tool, setTool] = useState<Control>(
    isImageScaleActive ? 'Scale' : 'Images',
  )

  const imageAddedToasterNotification: ToasterNotification = {
    iconName: 'squareCheck',
    content: 'Image added to element',
    type: 'success',
  }

  const imageRemovedToasterNotification: ToasterNotification = {
    iconName: 'squareCheck',
    content: 'Image removed from element',
    type: 'success',
  }

  const handleControlClicked = (control: Control | 'Close') => {
    api.setIsImageScaleActive(false)
    switch (control) {
      case 'Remove':
        setTool('Images')
        switch (step.type) {
          case Steps.EditPicture:
            removePicture(
              step.fullBleedIndex,
              step.panelIndex,
              step.elementIndex,
            )
            if (isMobile) {
              setToasterNotification(imageRemovedToasterNotification)
            }
            break
          case Steps.EditBackgroundPicture:
            removeBackgroundPicture(step.fullBleedIndex)
            if (isMobile) {
              setToasterNotification(imageRemovedToasterNotification)
            }
            break
        }
        break
      case 'Close':
        break
      case 'Images':
        setTool('Images')
        break
      case 'Scale':
        setIsImageScaleActive(true)
        setTool('Scale')
        break
    }
  }

  const imageScale = getSelectedElement(step)?.image?.scale || 1

  const setScale = (scale: number) => {
    if (step.type === Steps.EditPicture) {
      updateElementImage(
        step.fullBleedIndex,
        step.panelIndex,
        step.elementIndex,
        image => ({
          ...image,
          scale,
          position: api.getCorrectedImagePosition(scale, image.position),
        }),
      )
    }
  }

  const onEdit = (source: 'own' | 'social') => {
    switch (step.type) {
      case Steps.EditPicture:
        editPicture(
          step.fullBleedIndex,
          step.panelIndex,
          step.elementIndex,
          source,
        )
        if (isMobile) {
          setToasterNotification(imageAddedToasterNotification)
          closeDuvet?.()
        }
        break
      case Steps.EditBackgroundPicture:
        editBackgroundPicture(step.fullBleedIndex, source)
        if (isMobile) {
          setToasterNotification(imageAddedToasterNotification)
          closeDuvet?.()
        }
        break
    }
  }

  const onScaleBack = () => {
    setTool('Images')
    setIsImageScaleActive(false)
  }

  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <div style={{ height: '100%' }}>
        {tool === 'Images' && (
          <>
            <div onClick={() => onEdit('own')} style={{ paddingBottom: '8px' }}>
              {step.source === 'own' && (
                <SOCImages api={api} onSelect={onSelect} />
              )}
            </div>
          </>
        )}
        {step.type === Steps.EditPicture && tool === 'Scale' && (
          <div
            style={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              paddingBottom: 38,
              flexDirection: 'column',
            }}
          >
            <Div
              style={{ alignSelf: 'flex-start' }}
              display="flex"
              alignItems="center"
              onClick={onScaleBack}
            >
              <Icon
                name="chevron"
                size="small"
                primaryColor="default"
                orientation={'left'}
              />
              <Text type="caption">Back</Text>
            </Div>
            <Spacer space="x2" />
            <ScaleSlider
              scale={imageScale}
              onChange={scale => setScale(scale)}
              isMobile={true}
            />
          </div>
        )}
      </div>
      <Portal>
        <Flex
          zIndex={1001} // Finally appears after 1000
          position="fixed"
          bottom={'66px'}
          left="0"
          backgroundColor="foreground"
          inset={{ top: '8px', bottom: '16px' }}
          width="100%"
          display={isImageScaleActive ? 'none' : 'relative'}
        >
          <TextBarMobile
            tool={tool}
            onClick={handleControlClicked}
            items={items}
            style={{ position: 'initial' }}
          />
        </Flex>
      </Portal>
    </div>
  )
}

export default MinimalImageOptions
