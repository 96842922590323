import fastDeepEqual from 'fast-deep-equal'

interface ObjectMap<Key, Value> {
  entries(): [Key, Value][]
  keys(): Key[]

  get(key: Key): Value
  set(key: Key, value: Value): ObjectMap<Key, Value>
  setIfNotPresent(key: Key, value: Value): ObjectMap<Key, Value>
  contains(key: Key): boolean

  update(key: Key, update: (value: Value) => Value): ObjectMap<Key, Value>

  mapValues<NewValue>(
    transform: (value: Value, key: Key) => NewValue,
  ): ObjectMap<Key, NewValue>
}

const defaultPrettyPrintKey = <Key>(key: Key) =>
  JSON.stringify(key, undefined, 2)

const getValue = <Key, Value>(
  key: Key,
  entries: [Key, Value][],
): Value | undefined =>
  entries.find(([otherKey]) => fastDeepEqual(key, otherKey))?.[1]

const makeObjectMap = <Key, Value>(
  entries: [Key, Value][],
  getDefaultValue: (key: Key) => Value,
  prettyPrintKey: (key: Key) => string,
): ObjectMap<Key, Value> => ({
  ...entries.reduce(
    (object, [key, value]) => ({ ...object, [prettyPrintKey(key)]: value }),
    {},
  ),
  entries: () => entries,
  keys: () => entries.map(([key]) => key),
  get: (key: Key) => getValue(key, entries) ?? getDefaultValue(key),
  set: (key: Key, value: Value) =>
    makeObjectMap(entries, getDefaultValue, prettyPrintKey).update(
      key,
      () => value,
    ),
  // tslint:disable-next-line:only-arrow-functions
  setIfNotPresent: function (key: Key, value: Value) {
    return getValue(key, entries) === undefined
      ? // tslint:disable-next-line:no-invalid-this
        this.update(key, () => value)
      : // tslint:disable-next-line:no-invalid-this
        this
  },
  contains: (key: Key) => entries.findIndex(entry => entry[0] === key) !== -1,
  // tslint:disable-next-line:only-arrow-functions
  update: function (key: Key, update: (value: Value) => Value) {
    const indexIfPresent = entries.findIndex(([otherKey]) =>
      fastDeepEqual(key, otherKey),
    )
    if (indexIfPresent === -1) {
      return makeObjectMap(
        [...entries, [key, update(getDefaultValue(key))]],
        getDefaultValue,
        prettyPrintKey,
      )
    }
    const value = entries[indexIfPresent][1]
    const updatedValue = update(entries[indexIfPresent][1])
    if (value === updatedValue) {
      // tslint:disable-next-line:no-invalid-this
      return this
    }
    return makeObjectMap(
      [
        ...entries.slice(0, indexIfPresent),
        [key, updatedValue],
        ...entries.slice(indexIfPresent + 1, entries.length),
      ],
      getDefaultValue,
      prettyPrintKey,
    )
  },
  mapValues: <NewValue>(transform: (value: Value, key: Key) => NewValue) =>
    makeObjectMap(
      [
        ...entries.map(([key, value]): [Key, NewValue] => [
          key,
          transform(value, key),
        ]),
      ],
      (key: Key) => transform(getDefaultValue(key), key),
      prettyPrintKey,
    ),
})

const ObjectMap = <Key, Value>(
  defaultValue: Value,
  prettyPrintKey: (key: Key) => string = defaultPrettyPrintKey,
): ObjectMap<Key, Value> =>
  makeObjectMap([], () => defaultValue, prettyPrintKey)

export default ObjectMap
