import { Operation } from '../../../__generated__/globalTypes'

import {
  AmountFragment,
  amountFragmentString,
} from '../../fragments/__generated__/AmountFragment'

import getDetailedReferralCreditsRawString from '../GetDetailedReferralCredits.graphql'

const getDetailedReferralCreditsString = [
  getDetailedReferralCreditsRawString,
  amountFragmentString,
].join('\n\n')

export type GetDetailedReferralCreditsData = {
  account: {
    detailedReferralCredits: {
      __typename: 'DetailedReferralCredits'
      id: number
      amount: AmountFragment
      expirationDate: string
    }[]
  }
}

export type GetDetailedReferralCredits = Operation<
  GetDetailedReferralCreditsData
>

export const GetDetailedReferralCredits = (): GetDetailedReferralCredits => ({
  query: getDetailedReferralCreditsString,
  variables: undefined,
})
