import { useRef } from 'react'
import { useState } from './useState'
import { useEffect } from '.'

const useContainerWidth = (): [React.RefObject<HTMLDivElement>, number] => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [containerWidth, setContainerWidth] = useState(0)

  useEffect(() => {
    const handleResize = () => {
      setContainerWidth(containerRef.current?.clientWidth || 0)
    }

    const resizeObserver = new ResizeObserver(handleResize)

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current)
    }

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  return [containerRef, containerWidth]
}

export default useContainerWidth
