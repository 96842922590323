import { Operation } from '../../../__generated__/globalTypes'

import deleteContactString from '../DeleteContact.graphql'

export type DeleteContactData = {
  deleteContact: {
    deleted: boolean
  }
}

export type DeleteContactVariables = {
  id: string
}

export type DeleteContact = Operation<DeleteContactData, DeleteContactVariables>

export const DeleteContact = ({
  id,
}: DeleteContactVariables): DeleteContact => ({
  query: deleteContactString,
  variables: {
    id,
  },
})
