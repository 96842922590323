export const getPlanPillDescription = (id: string) => {
  switch (id) {
    case '6':
      return 'Top Seller'
    case '128':
      return 'Most Popular'
    case '129':
      return 'Incredible Value'
    case '133':
      return '36% Off Retail'
    case '134':
      return '46% Off Retail'
    case '135':
      return '52% Off Retail'
    case '136':
      return '59% Off Retail'
    case '137':
      return '62% Off Retail'
    default:
      return ''
  }
}
