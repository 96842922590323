import { Div, Flex, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import React, { FC } from 'react'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { IconType } from 'src/design_system/atoms/icons/types'

type FeatureCardProps = {
  icon: IconType
  iconColor?: string
  iconSize?: number
  isStacked?: boolean
  title: string
  description: string
  footnote?: string
  minWidth?: string
}

export const FeatureCard: FC<FeatureCardProps> = ({
  icon,
  iconColor = '#404040',
  isStacked = false,
  iconSize = 42,
  title,
  description,
  footnote,
  minWidth,
}) => {
  return (
    <Flex
      justifyContent="space-between"
      inset="x0"
      alignItems="center"
      backgroundColor="foreground"
      style={{
        border: '4px solid rgba(245, 245, 245, 0.3)',
        flex: 1,
      }}
      borderRadius={'medium'}
      flexDirection={isStacked ? 'column' : 'row'}
      flexWrap={isStacked ? 'wrap' : 'nowrap'}
      minWidth={minWidth}
    >
      <Flex
        alignItems="center"
        justifyContent={isStacked ? 'center' : 'flex-start'}
        outset="x_75"
        inset={{ vertical: 'x1', horizontal: 'x2' }}
        backgroundColor="foreground"
        flexDirection={isStacked ? 'column' : 'row'}
      >
        <Div outset={isStacked ? { bottom: 'x3' } : { right: 'x2' }}>
          <Icon name={icon} color={iconColor} size={iconSize} />
        </Div>
        <Flex flexDirection="column">
          <Text type="body" content={title} weight={'bold'} />
          <Spacer space="x1" />
          <Text type="caption" content={description} />
          {footnote && (
            <Text type="footnote" weight="bold" color="primaryHeading" />
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}
