import React from 'react'
import { Flex, IconType, TextType } from '@sendoutcards/quantum-design-ui'
import { ElementSettings } from '../ElementSettings/ElementSettings'
import { SizeType } from '@sendoutcards/quantum-design-ui/dist/src/types/global'
import {
  LiteralUnion,
  Responsive,
} from '@sendoutcards/quantum-design-ui/dist/src/helpers/hoc-types/entityValueTypes'

export type TextBarMobileItems<T extends string> = {
  name: T
  icon: IconType
  size: LiteralUnion<SizeType> | number
  type: TextType | Responsive<TextType>
}
interface Props<T extends string> {
  tool: string | number
  items: TextBarMobileItems<T>[]
  style?: React.CSSProperties
  onClick?: (controlName: T) => void
}

const TextBarMobile = <T extends string>(props: Props<T>) => {
  const { tool, items, onClick } = props
  return (
    <Flex
      width="100%"
      justifyContent="flex-start"
      overflowX="auto"
      alignItems="center"
    >
      {items.map((item, index) => (
        <Flex
          key={index}
          onClick={() => onClick?.(item.name)}
          display="flex"
          justifyContent="space-evenly"
          width="100%"
          alignItems="center"
          cursor="pointer"
          minWidth="74px"
          inset={{ bottom: 'x1' }}
        >
          <ElementSettings
            icon={{ name: item.icon, size: item.size }}
            label={{ content: item.name, type: item.type }}
            isSelected={tool === item.name}
            onClick={() => onClick?.(item.name)}
          />
        </Flex>
      ))}
    </Flex>
  )
}

export default TextBarMobile
