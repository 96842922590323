import React from 'react'
import {
  Button,
  ConfirmDialog,
  Div,
  Flex,
  Spacer,
  Text,
  VStack,
} from '@sendoutcards/quantum-design-ui'
import { useActions, useRef, useSelector, useState } from 'src/hooks'
import { OrderApiType } from 'src/orders/api'
import {
  OrderPreviewSection,
  OrderPreviewSectionProps,
} from './OrderPreviewSection'
import { CardFragment } from 'src/graphql/generated/graphql'
import { CartWithCounter } from '../OrderContextBar/components/CartWithCounter'
import { getSubTotal } from 'src/orders/helpers'
import { formatCost } from 'src/helpers'
import { EditOrderCardRoute } from 'src/orders/routes/EditOrderCardRoute'
import { Portal } from 'src/portal/portal'
import NewEditorOptionModal from 'src/chrome/NewEditorOptionModal/NewEditorOptionModal'

type OrderPreviewActionKeys =
  | keyof Omit<
      OrderPreviewSectionProps,
      'isOpen' | 'setIsOpen' | 'cardItem' | 'giftItem' | 'inset'
    >
  | keyof OrderPreviewSectionProps['giftItem']
  | keyof OrderPreviewSectionProps['cardItem']
  | 'onCheckout'
  | 'onContinueShopping'

export type ActionOverride = {
  title: string
  description: string
  accept: {
    title: string
    onClick?: () => Promise<void>
    shouldUseDefaultAction?: boolean
    id?: string
  }
  decline: {
    title: string
    onClick?: () => void
    shouldUseDefaultAction?: boolean
    id?: string
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T
}

export type OrderPreviewActionOverride = PartialRecord<
  OrderPreviewActionKeys,
  ActionOverride
>

export type OrderPreviewProps = {
  onContinueShopping: () => void
  orderItemCount?: number
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  zIndex?: number
  orderApi?: OrderApiType
  handleOutsideClick: () => void
  actionOverride?: OrderPreviewActionOverride
  checkoutAction: {
    onCheckout: () => void
    title?: string
  }
  editorCard?: CardFragment
}

export const OrderPreview: React.FC<OrderPreviewProps> = ({
  onContinueShopping,
  isOpen,
  setIsOpen,
  zIndex,
  orderItemCount,
  orderApi,
  handleOutsideClick,
  actionOverride,
  checkoutAction,
  editorCard,
}) => {
  const isMobile = useSelector(state => state.window.isMobile)
  const { width } = useSelector(state => state.window)
  const actions = useActions()

  const mutableModifyingRef = useRef(false)

  const modifyOrder = (callback: () => void) => {
    mutableModifyingRef.current = true
    // WARNING: This used to be called asynchronously in the callback of
    // setState, now that we're using `useState` we don't have
    // this functionality anymore.
    callback()
  }

  const {
    order,
    canShowNewCardEditor,
    handleUpdatePaperType,
    handleRemoveCardFromLine,
    handleAddGiftToOrderLine,
    handleRemoveGiftFromOrderLine,
    handleAddCardToOrderLine,
    toggleSendDelayModal,
    handleConvertCardAndUpdateOrder,
    handleAddCard: onAddCard,
    handleReplaceCard,
  } = orderApi ?? {}

  const MAX_WIDTH = 648
  const MIN_WIDTH = 320
  const dynamicWidth =
    width < MAX_WIDTH ? (width <= MIN_WIDTH ? MIN_WIDTH : width) : MAX_WIDTH
  const formattedTotal = formatCost(getSubTotal(orderApi?.order))

  const [
    shouldShowEditorChoiceModal,
    setShouldShowEditorChoiceModal,
  ] = useState<boolean>(true)

  const [cardIdToEdit, setCardIdToEdit] = useState<string | undefined>()

  const [activeActionOverride, setActiveActionOverride] = useState<
    {
      isOpen: boolean
      defaultAction: (() => void) | null
    } & { actionOverride?: ActionOverride }
  >({
    isOpen: false,
    defaultAction: null,
    actionOverride: undefined,
  })

  const lookUpOverride = (
    key: OrderPreviewActionKeys,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    defaultArgs?: any[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cb?: (...args: any[]) => void,
  ) => {
    if (actionOverride?.[key]) {
      setActiveActionOverride({
        isOpen: true,
        defaultAction: cb
          ? () => (defaultArgs ? cb(...defaultArgs) : cb())
          : null,
        actionOverride: actionOverride[key],
      })
    } else {
      defaultArgs ? cb?.(...defaultArgs) : cb?.()
    }
  }

  const handleGoEditCard = (orderId: string, cardId: string) => {
    actions.openOrder(orderId, EditOrderCardRoute(cardId))
  }

  const handleEditCard = (card: CardFragment) => {
    if (canShowNewCardEditor && !card.isNewEditorCard) {
      setShouldShowEditorChoiceModal(true)
      setCardIdToEdit(card.id)
    } else if (order) {
      handleGoEditCard(order.id, card.id)
    }
  }

  return (
    <>
      {shouldShowEditorChoiceModal && order && cardIdToEdit && (
        <Portal>
          <NewEditorOptionModal
            title={
              'Try it out! Would you like to edit\nyour card in the new editor'
            }
            description={
              'Since this card was created in the current card\neditor we can copy it and allow you to try editing it\nin the new editor. Would you like to try?'
            }
            tryNewEditor={{
              title: 'Copy Card to New Editor',
              onClick: handleConvertCardAndUpdateOrder,
            }}
            useCurrentEditor={{
              title: 'Edit in Current Editor',
              onClick: () => {
                handleGoEditCard(order.id, cardIdToEdit)
                setShouldShowEditorChoiceModal(false)
                setIsOpen(false)
              },
            }}
            isOpen={shouldShowEditorChoiceModal}
            onClose={() => {
              setShouldShowEditorChoiceModal(false)
              setCardIdToEdit(undefined)
            }}
            cardId={cardIdToEdit}
          />
        </Portal>
      )}
      {activeActionOverride.actionOverride && (
        <ConfirmDialog
          hasStackedActions={true}
          maxWidth="none"
          zIndex={1002}
          accept={{
            id: 'add_card_and_proceed_to_gift_store',
            onClick: async () => {
              setActiveActionOverride({
                isOpen: false,
                defaultAction: null,
              })
              const action = activeActionOverride.actionOverride
              await action?.accept.onClick?.()
              if (
                activeActionOverride.actionOverride?.accept
                  .shouldUseDefaultAction
              ) {
                activeActionOverride.defaultAction?.()
              }
              setActiveActionOverride({
                isOpen: false,
                defaultAction: null,
                actionOverride: undefined,
              })
            },
            title: activeActionOverride.actionOverride.accept.title,
          }}
          decline={{
            id: 'abandon_card_and_proceed_to_gift_store',
            onClick: () => {
              const action = activeActionOverride.actionOverride
              if (action?.decline.shouldUseDefaultAction) {
                activeActionOverride?.defaultAction?.()
              }
              action?.decline.onClick?.()
              setActiveActionOverride({
                isOpen: false,
                defaultAction: null,
                actionOverride: undefined,
              })
            },
            title: activeActionOverride.actionOverride.decline.title,
          }}
          onClose={() =>
            setActiveActionOverride({
              isOpen: false,
              defaultAction: null,
              actionOverride: undefined,
            })
          }
          title={activeActionOverride.actionOverride.title}
          description={activeActionOverride.actionOverride.description}
          isOpen={activeActionOverride.isOpen}
        />
      )}
      <Flex
        outsideClick={() => {
          if (isOpen) {
            handleOutsideClick()
          }
        }}
        zIndex={zIndex}
        position="fixed"
        bottom="58px"
        right="0px"
        initial={{ x: dynamicWidth }}
        animate={isOpen ? { x: 0 } : { x: dynamicWidth }}
        transition={{
          damping: 15,
          stiffness: 100,
          type: 'spring',
        }}
        id="order-preview"
        flexDirection="column"
        backgroundColor="background"
        borderRadius="large"
        height="calc(100vh - 76px)"
        width={`${dynamicWidth}px`}
        inset="x1"
        boxShadow="mediumLight"
      >
        <Flex
          justifyContent="space-between"
          inset={{ horizontal: 'x1', top: 'x1', bottom: 'x1_5' }}
        >
          <VStack gap="x1">
            <Text
              type="largeBody"
              content="My Shopping Cart"
              color="primaryHeading"
              weight="bold"
              lineHeight={1}
            />
            {order?.id && (
              <Text
                lineHeight={1}
                type="caption"
                content={`Order number: ${order.id}`}
              />
            )}
          </VStack>
          <Flex
            inset="x1_5"
            outset={{ right: 'x2' }}
            borderRadius="14px"
            justifyContent="center"
            alignItems="center"
            backgroundColor={'#F5F6F7'}
            borderColor={'#E0E0E0'}
            borderStyle="solid"
            borderWidth="default"
          >
            <CartWithCounter count={orderItemCount} isActive={false} />
          </Flex>
        </Flex>
        <Div height="100%" overflow="auto" inset={{ bottom: '108px' }}>
          <OrderPreviewSection
            editorCard={editorCard}
            api={orderApi}
            onAddAnotherCard={onAddCard}
            cardItem={{
              onEditCard: card =>
                lookUpOverride('onEditCard', [card], handleEditCard),
              onUpdatePaperType: (paperType, card) =>
                lookUpOverride(
                  'onUpdatePaperType',
                  [card, paperType],
                  handleUpdatePaperType,
                ),
              onToggleSendDelay: () =>
                lookUpOverride(
                  'onToggleSendDelay',
                  undefined,
                  toggleSendDelayModal,
                ),
              onRemoveCard: line =>
                lookUpOverride(
                  'onRemoveCard',
                  [line],
                  handleRemoveCardFromLine,
                ),
            }}
            giftItem={{
              onAddGift: (line, index) =>
                lookUpOverride(
                  'onAddGift',
                  [line, index, modifyOrder],
                  handleAddGiftToOrderLine,
                ),
              onChangeGift: (line, index) =>
                lookUpOverride(
                  'onChangeGift',
                  [line, index, modifyOrder],
                  handleAddCardToOrderLine,
                ),

              onRemoveGift: line =>
                lookUpOverride(
                  'onRemoveCard',
                  [line, modifyOrder],
                  handleRemoveGiftFromOrderLine,
                ),
            }}
            onAddCard={(line, index) =>
              lookUpOverride(
                'onAddCard',
                [line, index, modifyOrder],
                handleAddCardToOrderLine,
              )
            }
            isOpen={isOpen}
            setIsOpen={(isOpen: boolean) => setIsOpen(isOpen)}
            onRemoveCard={line =>
              lookUpOverride('onRemoveCard', [line], handleRemoveCardFromLine)
            }
            onRemoveLine={line =>
              lookUpOverride('onRemoveLine', [line], actions.removeLine)
            }
            onReplaceCard={cardId => {
              handleReplaceCard?.(cardId)
            }}
          />
        </Div>
        <Flex
          position="relative"
          borderRadius={{ top: 'large' }}
          backgroundColor=" #545256"
          bottom="54px"
          width="100%"
          justifyContent="space-between"
          inset={{ top: 'x2_5', bottom: 'x6', horizontal: 'x4' }}
        >
          <Text
            color="inverseHeading"
            type="largeBody"
            weight="bold"
            content="Subtotal:"
          />
          <Text
            color="inverseHeading"
            type="largeBody"
            weight="bold"
            content={formattedTotal}
          />
        </Flex>
        <Flex
          position="absolute"
          bottom="16px"
          borderRadius="medium"
          boxShadow="mediumDark"
          backgroundColor="background"
          width={'calc(100% - 16px)'}
          flexDirection="row"
          inset="x2"
        >
          <Button
            size={isMobile ? 'xSmall' : 'large'}
            fullWidth={true}
            title="Continue Shopping"
            onClick={() =>
              lookUpOverride(
                'onContinueShopping',
                undefined,
                onContinueShopping,
              )
            }
            outlined={true}
            textColorOverride="#323334"
            backgroundColorOverride="#323334"
          />
          <Spacer space="x1" orientation="horizontal" />
          <Button
            size={isMobile ? 'xSmall' : 'large'}
            type="success"
            fullWidth={true}
            title={checkoutAction?.title ?? 'Proceed to Cart'}
            id="order_preview_checkout_btn"
            onClick={() =>
              lookUpOverride('onCheckout', undefined, checkoutAction.onCheckout)
            }
            backgroundColorOverride="#53b66e"
          />
        </Flex>
      </Flex>
    </>
  )
}
