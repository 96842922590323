import React from 'react'
import { Div, Spacer, Text } from '@sendoutcards/quantum-design-ui'
// @src imports
import { PricingTable } from 'src/marketing/plans/components'

const styles = {
  plansContainer: {
    width: '100%',
    fontFamily: "'Open Sans', sans-serif",
  },
  planCopy: {
    maxWidth: '1050px',
    textAlign: 'center' as const,
    margin: '0 auto',
    '@media (max-width: 1025px)': {
      padding: '20px 30px 0',
      textAlign: 'left' as const,
      marginBottom: '0',
    },
  },
  pricingTable: {
    background: '#f7f7f7',
    marginBottom: '50px',
  },
}

interface Props {
  title?: string
  message?: string
  onSelect: (planId: string, isKeepButton?: boolean) => void
}

const Plans: React.FC<Props> = props => {
  const {
    title = 'Reach out in kindness with Heartfelt Prompting Card Subscriptions.',
    message = 'We have the perfect way to act on promptings and reach out in kindness, no matter how you choose to use SendOutCards. Start making a difference by selecting the plan that will work best for you. If you change your mind later, no worries — you can always switch plans or cancel.',
    onSelect,
  } = props

  return (
    <Div outset={{ horizontal: 'x3' }}>
      <Div css={styles.planCopy}>
        <Spacer space="x2" />
        <Text type="subtitle" alignment="center">
          {title}
        </Text>
        <Spacer space="x2" />
        <Text type="body" alignment="center">
          {message}
        </Text>
      </Div>
      <Spacer space="x6" />
      <Div css={styles.pricingTable}>
        <Div className={'desktopTable'}>
          <PricingTable onSelect={onSelect} />
        </Div>
      </Div>
    </Div>
  )
}

export default Plans
