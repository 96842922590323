import { CatalogSubroute } from 'src/catalog/routes/CatalogRoute'
import { OrderLine } from 'src/editor/types'
import {
  CustomCardFragment,
  MinimalSendableCardFragment,
} from 'src/legacy_graphql'
import { IconTypeOrIconString } from 'src/app/types'
import { OrderApiType } from 'src/orders/api'
// @src imports

export type FilterButton = {
  __typename: 'FilterButton'
  name: string
  onClick: () => void
  id: string
  icon: {
    icon: IconTypeOrIconString
    color: string
    viewBox: string
    size: number
  }
  uniqueHandler: boolean
}

export type Props = {
  onNavigate: (subroute?: CatalogSubroute) => void
  subroute: CatalogSubroute | undefined
  onSelect: (id: string) => void
  context?: {
    headerText?: string
    headerSubText?: string
    lines?: OrderLine[]
    activeCardId?: string
  }
  onCancel?: () => void
  orderApi?: OrderApiType
  actionContext?: string
}

export const isMinimalSendableCardFragment = (
  value: MinimalSendableCardFragment | CustomCardFragment,
): value is MinimalSendableCardFragment => value.__typename === 'SendableCard'
