import { Operation } from '../../../__generated__/globalTypes'

import {
  CampaignShareFragment,
  campaignShareFragmentString,
} from '../../fragments/__generated__/CampaignShareFragment'

import getSharedCampaignsRawString from '../GetSharedCampaigns.graphql'

const getSharedCampaignsString = [
  getSharedCampaignsRawString,
  campaignShareFragmentString,
].join('\n\n')

export type GetSharedCampaignsData = {
  campaignShares: CampaignShareFragment[]
}

export type GetSharedCampaignsVariables = {
  offset: number
  limit: number
}

export type GetSharedCampaigns = Operation<
  GetSharedCampaignsData,
  GetSharedCampaignsVariables
>

export const GetSharedCampaigns = ({
  offset,
  limit,
}: GetSharedCampaignsVariables): GetSharedCampaigns => ({
  query: getSharedCampaignsString,
  variables: {
    offset,
    limit,
  },
})
