import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
// @src imports
import { Button } from 'src/chrome'
import {
  CustomCardFragment,
  MinimalSendableCardFragment,
} from 'src/legacy_graphql'

import styles from './cardFlip.module.scss'
import { useEffect, useState } from 'src/hooks'
import { DetailedSendableCardFragment } from 'src/graphql/generated/graphql'

interface Props {
  card:
    | MinimalSendableCardFragment
    | DetailedSendableCardFragment
    | CustomCardFragment
  customCardSize?: string
  isFlipped?: boolean
  isButtonEnabled?: boolean
  handleFavoriteCard: (id: string, isFavorite: boolean) => void
}

const rotateY = {
  transform: 'rotateY(180deg)',
}

const rotateX = {
  transform: 'rotateX(180deg)',
}

const CardFlip: React.FC<Props> = props => {
  const {
    card,
    customCardSize,
    isButtonEnabled = true,
    handleFavoriteCard,
  } = props

  const [isFlipped, setIsFlipped] = useState(props.isFlipped ?? false)

  useEffect(() => {
    setIsFlipped(props.isFlipped ?? false)
  }, [props.isFlipped])

  const handleChange = (id: string, isFavorite: boolean) =>
    handleFavoriteCard(id, isFavorite)

  const handleFlip = () => setIsFlipped(!isFlipped)

  const isLandscape = card.isHorizontal
  const cardOrientationStyles = isLandscape ? styles.landscape : styles.portrait
  const isFavorite = card.__typename === 'SendableCard' && card.isFavorite
  const isDraftedCard = card.__typename === 'Card'
  const cardFrontImage =
    card.__typename === 'Card'
      ? card.frontPreviewUrl
      : card.frontImage.mediumThumb
  const cardInsideImage =
    card.__typename === 'SendableCard' &&
    'insideRightImage' in card &&
    card.insideRightImage &&
    card.insideRightImage.mediumThumb

  return (
    <div>
      <div
        className={
          !customCardSize
            ? `${styles.flipContainer} ${cardOrientationStyles}`
            : `${styles.flipContainer} ${customCardSize} ${cardOrientationStyles}`
        }
      >
        <div
          className={styles.flipper}
          style={
            isFlipped && !isLandscape
              ? rotateY
              : isFlipped && isLandscape
              ? rotateX
              : {}
          }
        >
          <div
            className={
              !customCardSize
                ? styles.front
                : `${styles.front} ${customCardSize}`
            }
            style={{ backgroundImage: `url(${cardFrontImage})` }}
            id={`card_flip_${card.id}`}
          >
            {!isDraftedCard && (
              <Checkbox
                checkedIcon={
                  <FavoriteIcon
                    style={{
                      fill: '#f36CC2',
                      position: 'absolute',
                    }}
                    fontSize="default"
                  />
                }
                icon={
                  <FavoriteBorderIcon
                    style={{
                      fill: '#f36CC2',
                      position: 'absolute',
                    }}
                    fontSize="default"
                  />
                }
                onClick={event => {
                  event.stopPropagation()
                  handleFavoriteCard(card.id, isFavorite)
                }}
                defaultChecked={isFavorite}
                value={card.id}
                style={{
                  display: 'flex',
                  width: 35,
                  height: 35,
                  position: 'absolute',
                  top: 10,
                  right: 10,
                  borderRadius: 100,
                  background: '#FFFFFFA3',
                  border: '1px solid #fff',
                  boxShadow:
                    '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
                }}
              />
            )}
          </div>
          {cardInsideImage && (
            <div
              className={
                customCardSize
                  ? `${styles.back} ${customCardSize}`
                  : styles.back
              }
              style={{ backgroundImage: `url(${cardInsideImage})` }}
            >
              {!isDraftedCard && (
                <Checkbox
                  checkedIcon={
                    <FavoriteIcon
                      style={{
                        fill: '#f36CC2',
                        position: 'absolute',
                      }}
                      fontSize="default"
                    />
                  }
                  icon={
                    <FavoriteBorderIcon
                      style={{
                        fill: '#f36CC2',
                        position: 'absolute',
                      }}
                      fontSize="default"
                    />
                  }
                  onChange={() => handleChange(card.id, isFavorite)}
                  defaultChecked={isFavorite}
                  value={card.id}
                  style={{
                    display: 'flex',
                    width: 35,
                    height: 35,
                    position: 'absolute',
                    top: 10,
                    right: 10,
                    borderRadius: 100,
                    background: '#FFFFFFA3',
                    border: '1px solid #fff',
                    boxShadow:
                      '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
                  }}
                />
              )}
            </div>
          )}
        </div>
      </div>
      {cardInsideImage && isButtonEnabled && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 10,
          }}
        >
          <Button
            id={isFlipped ? 'reasons_see_front_btn' : 'reasons_see_inside_btn'}
            title={isFlipped ? 'See Front' : 'See Inside'}
            onClick={handleFlip}
          />
        </div>
      )}
    </div>
  )
}

export default CardFlip
