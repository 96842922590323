import {
  Div,
  Flex,
  Separator,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'

type AppLinkProps = {
  direction?: 'row' | 'column'
  size?: number
  maxWidth?: string
}

export const AppLinks = (props: AppLinkProps) => {
  const { direction = 'row', size = 150, maxWidth = '320px' } = props
  return (
    <Flex flexDirection="column" justifyContent="center" alignItems="center">
      <Separator
        orientation="horizontal"
        caption="Did you know?"
        shouldCaptionWrap={false}
      />
      <Text
        type="largeBody"
        weight="bold"
        content="We have an app"
        color="primaryHeading"
        inset={{ top: 'x2', bottom: 'x1' }}
      />
      <Div maxWidth={maxWidth}>
        <Text
          type="body"
          content="Download now to access card and gift sending while on-the-go!"
          inset={{ bottom: 'x4' }}
          lineHeight={1.4}
          alignment="center"
        />
      </Div>
      <Flex flexDirection={direction} columnGap="x2">
        <Div
          onClick={() =>
            window.open(
              'https://apps.apple.com/us/app/sendoutcards/id878652134',
            )
          }
          cursor="pointer"
        >
          <Icon name="apple_store" size={size} />
        </Div>
        <Spacer
          orientation={direction === 'row' ? 'horizontal' : 'vertical'}
          space="x2"
        />
        <Div
          onClick={() =>
            window.open(
              'https://play.google.com/store/search?q=sendoutcards+android+app&c=apps&hl=en&gl=US',
            )
          }
          cursor="pointer"
        >
          <Icon name="google_play_store" size={size} />
        </Div>
      </Flex>
    </Flex>
  )
}
