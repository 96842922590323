import { Token } from '@stripe/stripe-js'
import useActions from './useActions'
import { useState } from './useState'
import { parseError } from 'src/utils/parseError'
import { CCAddress } from 'src/payments/components/CreditCardForm/CreditCardForm'
import useAccount from './useAccount'
import { useUpdateAccount } from 'src/react_query'
import { AccountFragment, AccountInput } from 'src/graphql/generated/graphql'

const useSubmitCard = () => {
  const acc = useAccount()
  const updateAccountMutation = useUpdateAccount()
  const actions = useActions()
  const [isAddingCard, setIsAddingCard] = useState(false)
  const [addCCardError, setAddCCardError] = useState<string | undefined>(
    undefined,
  )

  const submitCard = async (
    token?: Token,
    address?: CCAddress,
    onStart?: () => void,
    onSuccess?: (updatedAccount: AccountFragment) => void,
    onFail?: () => void,
    onFinally?: () => void,
  ) => {
    onStart?.()
    setIsAddingCard(true)

    const names = address?.name.split(/\s+/) ?? []
    const firstName = acc.firstName
      ? acc.firstName
      : names.length >= 1
      ? names?.[0]
      : ''
    const lastName = acc.lastName
      ? acc.lastName
      : names.length >= 2
      ? names?.slice(1).join(' ')
      : ''

    const shouldAddReturnAddress = !!!acc.shippingAddress
    const account: AccountInput = {
      stripeToken: token ? token.id : null,
      shippingAddress: shouldAddReturnAddress
        ? {
            firstName: firstName,
            lastName: lastName,
            address1: address?.line1 ?? '',
            address2: address?.line2 ?? '',
            city: address?.city ?? '',
            state: address?.state ?? '',
            country: address?.country ?? 'United States',
            postalCode: address?.postalCode ?? '',
            company: '',
          }
        : null,
    }

    try {
      const {
        updateAccount: { account: updatedAccount },
      } = await updateAccountMutation.mutateAsync({ account })
      actions.updatedAccount(updatedAccount)
      setAddCCardError(undefined)
      onSuccess?.(updatedAccount)
    } catch (err) {
      onError(parseError(err))
      onFail?.()
    } finally {
      setIsAddingCard(false)
      onFinally?.()
    }
  }

  const onError = (error: string) => {
    try {
      const errorMessage = error.split(': ')[1]
      setAddCCardError(errorMessage)
    } catch {
      setAddCCardError('Failed to add card. Contact Support for assistance.')
    }
  }

  return {
    submitCard,
    onError,
    isAddingCard,
    addCCardError,
  }
}

export default useSubmitCard
