import { Route } from 'src/routes/Route'
import { Subroute } from 'src/routes/Subroute'
import { CatalogCardsRoute } from 'src/catalog/routes/CatalogCardsRoute'
import { CardCategoryRoute } from 'src/catalog/routes/CardCategoryRoute'
import { CardCollectionRoute } from 'src/catalog/routes/CardCollectionRoute'
import { CustomCardRoute } from 'src/catalog/routes/CustomCardRoute'
import { CustomCardsRoute } from 'src/catalog/routes/CustomCardsRoute'
import { FavoriteCardsRoute } from 'src/catalog/routes/FavoriteCardsRoute'
import { LegacyPicturePlusCardsRoute } from 'src/catalog/routes/LegacyPicturePlusCardsRoute'
import { PhotoDropCardsRoute } from 'src/catalog/routes/PhotoDropCardsRoute'
import { SendableCardRoute } from 'src/catalog/routes/SendableCardRoute'
import { CardRoute } from 'src/card/routes/CardRoute'

const AddCardToGiftSubRoute = Subroute(
  CardCategoryRoute,
  CardCollectionRoute,
  CustomCardRoute,
  CustomCardsRoute,
  FavoriteCardsRoute,
  LegacyPicturePlusCardsRoute,
  PhotoDropCardsRoute,
  SendableCardRoute,
  CatalogCardsRoute,
  CardRoute,
)
type AddCardToGiftSubRoute = Subroute<typeof AddCardToGiftSubRoute>

export const AddCardToGiftRoute = Route({
  path: '/line/:lineIndex/add-card-to-gift',
  init: (lineIndex?: string, subroute?: AddCardToGiftSubRoute) => ({
    lineIndex,
    subroute,
  }),
  fromPath: ({ lineIndex }) => ({ lineIndex }),
  subroute: AddCardToGiftSubRoute,
})

export type AddCardToGiftRoute = Route<typeof AddCardToGiftRoute>
