import React from 'react'

import {
  Alert,
  DefaultError,
  Drawer,
  EditableTable,
  FullWindowModal,
  Pagination,
  SuspenseBoundary,
  TableHeader,
  Transition,
} from 'src/chrome'
import PlanBuyer from 'src/plan-buyer/containers/PlanBuyer'
import Plans from 'src/plans/containers/Plans'
import { useMeasurements, useMemo, useQueries, useState } from 'src/hooks'

import EditableRow from './EditableRow'
import BottomControls from './BottomControls'
import {
  CheckedMap,
  ColumnDefaults,
  FieldMapping,
  getMappedColumns,
  getMappedRow,
  getRowError,
} from '../helpers'
import { MappedRow, Row } from '../columns'
import styles from '../importContacts.module.scss'
import { getDetailedCountries } from 'src/legacy_graphql'
import { formatCountriesAndRegions } from 'src/helpers/formatCountriesAndRegions'
import { Spacer, Text } from '@sendoutcards/quantum-design-ui'

interface Props {
  initialRows: Row[]
  mapping: FieldMapping
  defaults: ColumnDefaults
  dateFormat: string
  onBack: () => void
  onImport: (mappedRows: MappedRow[], checkedRows: CheckedMap) => void
  rowLimit?: number
}

const VALID_PAGE_SIZE = 20
const INVALID_PAGE_SIZE = 10

const paginate = <T extends {}>(page: number, pageSize: number, list: T[]) =>
  list.slice((page - 1) * pageSize, pageSize * page)

const allSelectedMap = (rows: MappedRow[]): CheckedMap =>
  rows.reduce(
    (checked: CheckedMap, _row, index) => ({ ...checked, [index]: true }),
    {},
  )

enum UpsellSteps {
  Idle,
  ChoosePlan,
  BuyPlan,
}

interface IdleUpsellStep {
  type: UpsellSteps.Idle
}

interface ChoosePlanUpsellStep {
  type: UpsellSteps.ChoosePlan
}

interface BuyPlanUpsellStep {
  type: UpsellSteps.BuyPlan
  initialPlanId: string
}

type UpsellPlanStep = IdleUpsellStep | ChoosePlanUpsellStep | BuyPlanUpsellStep

const EditDataStep: React.FC<Props> = props => {
  const {
    initialRows,
    mapping,
    dateFormat,
    defaults,
    onImport,
    onBack,
    rowLimit,
  } = props

  const { windowWidth } = useMeasurements()
  const [detailedCountries] = useQueries(getDetailedCountries())
  const { countries } = formatCountriesAndRegions(detailedCountries)
  const [invalidPage, setInvalidPage] = useState(1)
  const [isLimitWarningVisible, setIsLimitWarningVisible] = useState(true)

  const [upsellStep, setUpsellStep] = useState<UpsellPlanStep>({
    type: UpsellSteps.Idle,
  })

  const [validPage, setValidPage] = useState(1)

  const [rows, setRows] = useState<MappedRow[]>(
    initialRows.map(row => getMappedRow(mapping, row, defaults)),
  )

  const [checkedRows, setCheckedRows] = useState(allSelectedMap(rows))
  const mappedColumns = getMappedColumns(mapping)

  const toggleChecked = (index: number) =>
    setCheckedRows(x => ({
      ...x,
      [index]: !x[index],
    }))

  const [valid, invalid, validCheckedRows] = useMemo(() => {
    const mutableValid: { index: number; row: typeof rows[0] }[] = []
    const mutableInvalid: {
      index: number
      row: typeof rows[0]
      error: string
    }[] = []
    let mutableValidCheckedRows = 0

    rows.forEach((row, index) => {
      const error = getRowError(row, mappedColumns, dateFormat, defaults)

      if (error) {
        mutableInvalid.push({ index, row, error })
      } else {
        if (rowLimit && mutableValid.length >= rowLimit) {
          return
        }
        if (checkedRows[index]) {
          mutableValidCheckedRows += 1
        }
        mutableValid.push({ index, row })
      }
    })

    return [mutableValid, mutableInvalid, mutableValidCheckedRows]
  }, [rows, checkedRows, dateFormat, mappedColumns, rowLimit, defaults])

  const isEveryRowSelected = validCheckedRows >= valid.length

  const toggleSelectAll = () => {
    if (isEveryRowSelected) {
      setCheckedRows({})
    } else {
      setCheckedRows(allSelectedMap(rows))
    }
  }

  const updateValue = (value: string, row: number, columnApiName: string) =>
    setRows(x => {
      const mutableRows = x.slice()
      mutableRows[row] = { ...mutableRows[row], [columnApiName]: value }
      return mutableRows
    })

  const startImport = () => {
    onImport(rows, checkedRows)
  }

  if (!countries) {
    return (
      <Transition
        subMessage={undefined}
        style={{
          position: 'absolute',
          height: '100%',
        }}
      />
    )
  }

  return (
    <div className={styles.flexColumn}>
      {isLimitWarningVisible && rowLimit && rows.length > rowLimit && (
        <Alert
          isOpen={true}
          title={'This import is limited to 100 contacts'}
          message={[
            'You are currently on a limited subscription.',
            'In order to access the full functionality of the relationship manager,',
            'please upgrade to a Premium or Enterprise plan.',
          ].join(' ')}
          onClose={() => setIsLimitWarningVisible(false)}
          type={'info'}
        >
          <span
            onClick={() => {
              setIsLimitWarningVisible(false)
              setUpsellStep({
                type: UpsellSteps.ChoosePlan,
              })
            }}
          >
            Click here to view your plan options
          </span>
        </Alert>
      )}

      {upsellStep.type === UpsellSteps.ChoosePlan && (
        <SuspenseBoundary unresolved={''} failure={DefaultError}>
          <FullWindowModal
            close={() => setUpsellStep({ type: UpsellSteps.Idle })}
          >
            <Plans
              onSelect={planId =>
                setUpsellStep({
                  type: UpsellSteps.BuyPlan,
                  initialPlanId: planId,
                })
              }
            />
          </FullWindowModal>
        </SuspenseBoundary>
      )}

      {upsellStep.type === UpsellSteps.BuyPlan && (
        <SuspenseBoundary unresolved={''} failure={DefaultError}>
          <Drawer
            openCloseControlClicked={() =>
              setUpsellStep({ type: UpsellSteps.Idle })
            }
            isOpen={true}
            title={'Purchase Your Subscription Package'}
            isFullScreen={windowWidth < 1060}
          >
            <PlanBuyer
              initialPlanId={upsellStep.initialPlanId}
              // plan has been bought, re-attempt the purchasing process
              onAfterPurchase={() => {
                // nothing to do about the plan, once the account is reloaded
                // rowLimit should be undefined
                setUpsellStep({ type: UpsellSteps.Idle })
              }}
              onCancel={() => setUpsellStep({ type: UpsellSteps.Idle })}
            />
          </Drawer>
        </SuspenseBoundary>
      )}

      {invalid.length > 0 && (
        <div className={styles.contactTable} style={{ marginBottom: 70 }}>
          <div className={styles.flexColumn}>
            <Text type="caption" color="danger" alignment="center">
              There were some errors while reading your file.
            </Text>
            <Text type="body" color="danger">
              * {invalid.length} invalid record(s)
            </Text>
            <Text type="footnote">Please correct fields marked in red</Text>
            <Spacer space="x2" />
            <EditableTable>
              <TableHeader>
                {mappedColumns.map(mappedColumn => (
                  <th
                    key={mappedColumn.column.apiName}
                    style={{ padding: '5px 6px' }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <span style={{ fontWeight: 500, whiteSpace: 'nowrap' }}>
                        {mappedColumn.column.display}
                      </span>
                      <span style={{ fontWeight: 300, fontSize: 13 }}>
                        {mappedColumn.field}
                      </span>
                    </div>
                  </th>
                ))}
              </TableHeader>

              <tbody>
                {paginate(invalidPage, INVALID_PAGE_SIZE, invalid).map(
                  ({ index, row, error }) => {
                    return (
                      // WARNING: make sure all props here are stable / immutable
                      // for memoization to work
                      <EditableRow
                        key={index}
                        dateFormat={dateFormat}
                        countries={countries}
                        index={index}
                        mappedColumns={mappedColumns}
                        onEditCell={updateValue}
                        row={row}
                        isChecked={false}
                      />
                    )
                  },
                )}
              </tbody>
            </EditableTable>

            <Pagination
              onPageChange={setInvalidPage}
              count={invalid.length}
              pagesToShow={5}
              pageSize={INVALID_PAGE_SIZE}
              currentPage={invalidPage}
            />
          </div>
        </div>
      )}

      {valid.length > 0 && (
        <div className={styles.contactTable}>
          <Text type="caption" color="primaryBrand">
            {`Ready to import ${validCheckedRows} of ${valid.length}`}
          </Text>
          <Spacer space="x2" />
          <EditableTable>
            <TableHeader>
              <th
                key={0}
                onClick={toggleSelectAll}
                style={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  fontWeight: 500,
                  fontSize: 14,
                  color: '#ff5689',
                }}
              >
                {isEveryRowSelected ? 'Deselect All' : 'Select All'}
              </th>

              {mappedColumns.map(mappedColumn => (
                <th
                  key={mappedColumn.column.apiName}
                  style={{ padding: '5px 6px' }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ fontWeight: 500, whiteSpace: 'nowrap' }}>
                      {mappedColumn.column.display}
                    </span>
                    <span style={{ fontWeight: 300, fontSize: 13 }}>
                      {mappedColumn.field}
                    </span>
                  </div>
                </th>
              ))}
            </TableHeader>

            <tbody>
              {paginate(validPage, VALID_PAGE_SIZE, valid).map(
                ({ row, index }) => {
                  return (
                    // WARNING: make sure all props here are stable / immutable
                    // for memoization to work
                    <EditableRow
                      key={index}
                      dateFormat={dateFormat}
                      countries={countries}
                      index={index}
                      mappedColumns={mappedColumns}
                      onToggleCheck={toggleChecked}
                      onEditCell={updateValue}
                      row={row}
                      isChecked={!!checkedRows[index]}
                    />
                  )
                },
              )}
            </tbody>
          </EditableTable>

          <Pagination
            onPageChange={setValidPage}
            count={valid.length}
            pagesToShow={5}
            pageSize={VALID_PAGE_SIZE}
            currentPage={validPage}
          />
        </div>
      )}

      <BottomControls
        back={{
          disabled: false,
          onClick: onBack,
        }}
        next={{
          onClick: startImport,
          disabled: !valid.length,
          title: 'Import!',
        }}
      />
    </div>
  )
}

export default EditDataStep
