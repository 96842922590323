import { ItemObject, Operation } from '../../../__generated__/globalTypes'

import createPurchaseString from '../CreatePurchase.graphql'

export type CreatePurchaseData = {
  createPurchase: {
    purchaseIntent: {
      id: string
      subtotal: string
      tax: string
      total: string
    }
  }
}

export type CreatePurchaseVariables = {
  itemsToPurchase: ItemObject[]
}

export type CreatePurchase = Operation<
  CreatePurchaseData,
  CreatePurchaseVariables
>

export const CreatePurchase = ({
  itemsToPurchase,
}: CreatePurchaseVariables): CreatePurchase => ({
  query: createPurchaseString,
  variables: {
    itemsToPurchase: itemsToPurchase.map(x => ItemObject(x)),
  },
})
