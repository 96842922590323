import React from 'react'
import {
  Anchor,
  Button,
  Div,
  Flex,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'

const AddSponsor = (props: {
  onFindMySponsor: () => void
  onIDontKnowAnyone: () => void
}) => {
  const { onFindMySponsor, onIDontKnowAnyone } = props

  return (
    <Flex flexDirection="column" height="100%" alignItems="space-between">
      <Text type="title" weight="bold">
        Who showed you this product?
      </Text>
      <Spacer space="x2" />
      <Text type="caption">
        Who shared SendOutCards with you? We'd love to congratulate them!
      </Text>
      <Spacer space="x8" />
      <Flex
        flexDirection="column"
        alignItems="center"
        width={'100%'}
        outset={{ top: 'auto' }}
      >
        <Div>
          <Button
            fullWidth={true}
            title={'Find My Sponsor'}
            onClick={onFindMySponsor}
            hover={true}
          />
        </Div>
        <Spacer space="x2" />
        <Anchor size={'small'} isDecorated={true} onClick={onIDontKnowAnyone}>
          I don't know anyone
        </Anchor>
      </Flex>
    </Flex>
  )
}

export default AddSponsor
