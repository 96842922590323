import React from 'react'
import Hammer from 'react-hammerjs'
// @src imports
import { Icon } from 'src/chrome'
import Animate from 'src/chrome/Fade/animations'
import SliderConfig, { Entry } from 'src/chrome/SliderConfig/SliderConfig'
import { panoramaStyles } from 'src/chrome/PanoramaSlide/panoramaSlideStyles'

import styles from './panoramaSlider.module.scss'
import { useCallback, useEffect, useMemo, useRef, useState } from 'src/hooks'

interface Props {
  activeContainer?: JSX.Element
  sliderId: string
  config: Entry[] // array
  containerRef?: React.MutableRefObject<HTMLDivElement | null>
  containerStyles?: React.CSSProperties
  marginSize: number
  leftControl?: JSX.Element
  rightControl?: JSX.Element
  sliderDivStyles?: React.CSSProperties
  isVertical: boolean
  verticalMaxContainerHeight?: number // defaults to 600px
}

const PanoramaSlide: React.FC<Props> = props => {
  const {
    config,
    containerRef,
    isVertical,
    marginSize,
    sliderId,
    leftControl,
    rightControl,
    activeContainer,
    containerStyles,
    sliderDivStyles,
    verticalMaxContainerHeight,
    children,
  } = props

  const shouldStyleArrows = false
  const mutableSlide = useRef<HTMLDivElement>(null)
  const mutablePerfContainer = useRef<HTMLDivElement>(null)

  const [index, setIndex] = useState(0)
  const [scale, setScale] = useState(1)

  const mutablePosition = useRef(0)
  const mutableBusy = useRef(false)

  const slider = useMemo(() => SliderConfig(config, marginSize, isVertical), [
    config,
    marginSize,
    isVertical,
  ])

  const marginDivs = useMemo(() => slider.marginDivList(), [slider])

  const animateToIndex = useCallback(
    (currentPosition: number, currentIndex: number) => {
      if (!mutableSlide.current || !mutablePerfContainer.current) return

      setIndex(currentIndex)
      mutablePosition.current = slider.sumLengthToIndex(currentIndex) * -1

      const slideAnimation = isVertical ? Animate.slide.y : Animate.slide.x

      slideAnimation(
        [mutableSlide.current, mutablePerfContainer.current],
        3,
        [currentPosition, mutablePosition.current],
        () => {
          mutableBusy.current = false
        },
      ).animate()
    },
    [slider, isVertical],
  )

  useEffect(() => {
    if (!containerRef?.current) {
      return
    }
    setScale(
      slider.calculateScale(
        containerRef.current.clientHeight,
        containerRef.current.clientWidth,
      ),
    )
  }, [slider, containerRef])

  useEffect(() => {
    animateToIndex(mutablePosition.current, 0)
  }, [animateToIndex, sliderId])

  const getTransform = useCallback(
    (delta: number) => {
      const newTransform = mutablePosition.current + delta
      if (!config) {
        return
      }
      const firstSection =
          (isVertical ? config[0].height : config[0].width) || 1,
        minTransform = slider.totalLength * -1,
        maxTransform = firstSection - 1

      return Math.max(Math.min(newTransform, maxTransform), minTransform)
    },
    [slider, isVertical, config],
  )

  const handleRelease = useCallback(
    ({ deltaX, deltaY }: { deltaY: number; deltaX: number }) => {
      const delta = isVertical ? deltaY : deltaX
      const currentPosition = getTransform(delta)
      if (!mutableBusy.current && currentPosition) {
        mutableBusy.current = true

        animateToIndex(
          currentPosition,
          slider.getSnapIndex(currentPosition * -1),
        )
      }
    },
    [animateToIndex, getTransform, slider, isVertical],
  )

  const handleSlide = useCallback(
    ({ deltaX, deltaY }: { deltaY: number; deltaX: number }) => {
      if (!mutableBusy.current) {
        const delta = isVertical ? deltaY : deltaX
        const newTransform = getTransform(delta)
        const translate = `translate${
          isVertical ? 'Y' : 'X'
        }(${newTransform}px)`

        if (mutableSlide.current)
          mutableSlide.current.style.transform = translate

        if (mutablePerfContainer.current)
          mutablePerfContainer.current.style.transform = translate
      }
    },
    [getTransform, isVertical],
  )

  const handleSwipe = useCallback(
    ({ deltaX, deltaY }: { deltaY: number; deltaX: number }) => {
      const delta = isVertical ? deltaY : deltaX
      const currentPosition = getTransform(delta)
      if (!mutableBusy.current && currentPosition) {
        mutableBusy.current = true
        const snapIndex = slider.getSnapIndex(currentPosition * -1)
        animateToIndex(
          currentPosition,
          Math.max(
            Math.min(
              snapIndex === index
                ? delta <= 0
                  ? snapIndex + 1
                  : snapIndex - 1
                : snapIndex,
              slider.totalSections - 1,
            ),
            0,
          ),
        )
      }
    },
    [animateToIndex, getTransform, slider, isVertical, index],
  )

  const sliderClicked = (direction: 'left' | 'right') => {
    animateToIndex(
      mutablePosition.current,
      direction === 'left' ? index - 1 : index + 1,
    )
  }

  const totalLength = slider.totalLength,
    maxElementHeight = slider.maxElementHeight,
    maxElementWidth = slider.maxElementWidth

  const viewBox = isVertical ? '2 0 32 32' : '0 0 400 1024'
  const iconSize = isVertical ? 30 : 23

  const {
    perfContainerStyle,
    slideStyle,
    slideMarginStyle,
    containerStyle,
    scaleStyle,
    blurLeftStyle,
    blurRightStyle,
    arrowStyle,
    bottomBlurStyle,
  } = panoramaStyles(
    isVertical,
    marginSize,
    containerRef?.current?.clientWidth ?? 800,
    containerRef?.current?.clientHeight ?? 400,
    totalLength,
    maxElementHeight,
    maxElementWidth,
    verticalMaxContainerHeight,
    scale,
    shouldStyleArrows,
  )
  return (
    <div
      style={{ ...containerStyle, ...containerStyles }}
      className={styles.container}
    >
      {activeContainer && (
        <div
          style={{ transform: `scale(${scale || 1})`, position: 'absolute' }}
        >
          {activeContainer}
        </div>
      )}
      <div style={scaleStyle}>
        <Hammer
          onPan={handleSlide}
          onPanEnd={handleRelease}
          onSwipe={handleSwipe}
        >
          <div>
            <div
              ref={mutableSlide}
              style={{ ...slideStyle, ...sliderDivStyles }}
            >
              {React.Children.map(children, (child, index) => (
                <div
                  key={index}
                  style={{ ...(!isVertical && { display: 'flex' }) }}
                >
                  {child}
                  <div style={slideMarginStyle} />
                </div>
              ))}
            </div>
            <div ref={mutablePerfContainer} style={perfContainerStyle}>
              {marginDivs}
            </div>
          </div>
        </Hammer>
        {slider.fadingLabels(index)}
        {isVertical && <div style={bottomBlurStyle} />}
      </div>
      {index > 0 && (
        <div onClick={() => sliderClicked('left')}>
          {leftControl ? (
            <div>{leftControl}</div>
          ) : (
            <div style={blurLeftStyle}>
              <span style={{ ...arrowStyle }}>
                <Icon
                  icon={isVertical ? 'CARROTUP' : 'CARROTLEFT'}
                  size={iconSize}
                  color={'black'}
                  viewBox={viewBox}
                />
              </span>
            </div>
          )}
        </div>
      )}
      {index < slider.totalSections - 1 && (
        <div onClick={() => sliderClicked('right')}>
          {rightControl ? (
            <div>{rightControl}</div>
          ) : (
            <div style={blurRightStyle}>
              <span
                style={{ ...arrowStyle, paddingRight: isVertical ? 15 : 0 }}
              >
                <Icon
                  icon={isVertical ? 'CARROTDOWN' : 'CARROTRIGHT'}
                  size={iconSize}
                  color={'black'}
                  viewBox={viewBox}
                />
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default PanoramaSlide
