import stripeOrderFragmentRawString from '../StripeOrderFragment.graphql'

export const stripeOrderFragmentString = stripeOrderFragmentRawString

export type StripeOrderFragment = {
  __typename: 'StripeOrder'
  id: string
  total: string
  subtotal: string
  discount: string
  shipping: string
  tax: string
}

export const isStripeOrderFragment = (
  fragment: any,
): fragment is StripeOrderFragment =>
  fragment &&
  fragment.__typename === 'StripeOrder' &&
  typeof fragment.id === 'string' &&
  typeof fragment.total === 'string' &&
  typeof fragment.subtotal === 'string' &&
  typeof fragment.discount === 'string' &&
  typeof fragment.shipping === 'string' &&
  typeof fragment.tax === 'string'
