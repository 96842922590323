import React from 'react'
// @src imports
import { Address } from 'src/chrome'
import { AddressForm } from 'src/contacts/components'
import { AddressFragment } from 'src/legacy_graphql'
import { useEffect, useSelector, useState } from 'src/hooks'
import styles from './returnAddress.module.scss'
import { OrderWithPartialLines } from 'src/redux/reducers/orders'
import {
  Div,
  Flex,
  Text,
  Tooltip,
  Transition,
} from '@sendoutcards/quantum-design-ui'
import { emptyShippingAddress } from 'src/app/constants'
import { Button as DSButton } from 'src/design_system/molecules/button/Button'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import HoverTooltip from 'src/chrome/HoverTooltip/HoverTooltip'

interface ReturnAddressProps {
  showRequired: boolean
  onSave: (address: AddressFragment) => void
  order: OrderWithPartialLines
  toggleAddressBlinker: (toggle: boolean) => void
  id?: string
  heading: { title: string; subtitle?: string }
}

const ReturnAddress: React.FC<ReturnAddressProps> = props => {
  const {
    showRequired,
    onSave,
    order,
    toggleAddressBlinker,
    heading = { title: 'Return Address' },
  } = props
  const { isSaving } = useSelector(state => state.orders)
  const { isMobile } = useSelector(state => state.window)

  const [isEditing, setIsEditing] = useState(
    !order.returnAddress || !order.returnAddress.address1,
  )

  const [address, setAddress] = useState(
    order.returnAddress || emptyShippingAddress,
  )

  const [hasInitializedSave, setHasInitializedSave] = useState(false)

  useEffect(() => {
    setAddress(order.returnAddress || emptyShippingAddress)
  }, [order.returnAddress])

  const handleChange: React.ReactEventHandler<HTMLInputElement> = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setAddress({
      ...address,
      [event.target.name]: event.target.value,
    })
  }

  const handleCountryChange = (country: string) =>
    setAddress({
      ...address,
      country: country,
    })

  const handleStateChange = (state: string) =>
    setAddress({
      ...address,
      state: state,
    })

  const handleSave = () => {
    onSave(address)
    setIsEditing(false)
    setHasInitializedSave(true)
  }

  useEffect(() => {
    if (!isSaving && hasInitializedSave) {
      setTimeout(() => {
        setHasInitializedSave(false)
      }, 2300)
    }
  }, [hasInitializedSave, isSaving])

  return (
    <>
      <Flex borderRadius="large" width="100%" id={props.id}>
        {hasInitializedSave ? (
          <Transition
            loadedStatus={'success'}
            title={
              isSaving ? 'Saving your address...' : 'Address saved successfully'
            }
            isLoading={isSaving}
          />
        ) : (
          <div className={styles.container}>
            <div className={styles.header}>
              <Div inset={{ bottom: 'x1' }}>
                <Text
                  type="largeBody"
                  weight="semiBold"
                  color="primaryHeading"
                  content={heading.title}
                  style={{ fontSize: '1.25rem', fontWeight: 600 }}
                  whiteSpace="nowrap"
                />
                <Text
                  type="body"
                  content={heading.subtitle}
                  whiteSpace="normal"
                  outset={{ right: 'x1' }}
                />
              </Div>
              {showRequired && (
                <Div display="flex" position="absolute" top="8px">
                  <Tooltip
                    background="dark"
                    direction="bottom"
                    title="Please make sure you have a return address"
                  />
                </Div>
              )}
              {!order.isSent && (
                <Div display="flex" width="100%" justifyContent="flex-end">
                  <DSButton
                    id={'edit_or_cancel_return_address_btn'}
                    title={
                      !isMobile
                        ? {
                            content: `${
                              isEditing ? 'Cancel Edit' : 'Edit'
                            } Return Address`,
                            fontSize: '12px',
                          }
                        : undefined
                    }
                    onClick={() => {
                      setIsEditing(e => !e)
                    }}
                    fill="#f5f6f7"
                    padding="10px 16px"
                    borderRadius="small"
                    gap="x_5"
                  >
                    <HoverTooltip
                      shouldDisplayTooltip={isMobile}
                      title={`${
                        isEditing ? 'Cancel Edit' : 'Edit'
                      } Return Address`}
                      background="dark"
                    >
                      <Icon
                        name={isEditing ? 'cancel' : 'edit'}
                        size={isEditing ? 14 : 17}
                      />
                    </HoverTooltip>
                  </DSButton>
                </Div>
              )}
            </div>
            {isEditing ? (
              <AddressForm
                toggleAddressBlinker={toggleAddressBlinker}
                contact={address}
                onChange={handleChange}
                onCountryChange={handleCountryChange}
                onStateChange={handleStateChange}
                shouldShowDates={false}
                isTitleVisible={false}
                onSubmit={handleSave}
                alignAction="flex-end"
              />
            ) : (
              <Address address={address} />
            )}
          </div>
        )}
      </Flex>
    </>
  )
}

export default ReturnAddress
