import { Label } from 'src/graphql/generated/graphql'
import useAccount from './useAccount'

type UserLabelKeys =
  | 'zeroPoint'
  | 'affiliate'
  | 'staff'
  | 'productLaunch'
  | 'giftingCommittee'
  | 'nowsiteActive'
  | 'nowsiteNeedSetup'
  | 'affiliateMissingW9'
  | 'affiliateMissingW8'
  | 'needsGsAffiliateOptIn'
  | 'needsCustomerReferralOptIn'
  | 'shouldHideRevolutionOffice'
  | 'gsaSubscriptionPaymentFailure'
  | 'priorAffiliate'
  | 'hasInvalidEmail'
  | 'hasDuplicateEmail'
  | 'hasDuplicateEmailClaimed'
  | 'hasFreeCampaignStore'
  | 'hasRelationshipMarketing'

type UserLabels = { readonly [Key in UserLabelKeys]: boolean }

const useUserLabels = (): UserLabels => {
  const account = useAccount()
  const labels = account.labels

  return {
    zeroPoint: labels.includes(Label.ZeroPoint),
    affiliate: labels.includes(Label.Affiliate),
    staff: labels.includes(Label.Staff),
    productLaunch: labels.includes(Label.ProductLaunch),
    giftingCommittee: labels.includes(Label.GiftingCommittee),
    nowsiteActive: labels.includes(Label.NowsiteActive),
    nowsiteNeedSetup: labels.includes(Label.NowsiteNeedSetup),
    affiliateMissingW9: labels.includes(Label.AffiliateMissingW9),
    affiliateMissingW8: labels.includes(Label.AffiliateMissingW8),
    needsGsAffiliateOptIn: labels.includes(Label.NeedsGsAffiliateOptIn),
    needsCustomerReferralOptIn: labels.includes(
      Label.NeedsCustomerReferralOptIn,
    ),
    shouldHideRevolutionOffice: labels.includes(
      Label.ShouldHideRevolutionOffice,
    ),
    gsaSubscriptionPaymentFailure: labels.includes(
      Label.GsaSubscriptionPaymentFailure,
    ),
    priorAffiliate: labels.includes(Label.PriorAffiliate),
    hasInvalidEmail: labels.includes(Label.HasInvalidEmail),
    hasDuplicateEmail: labels.includes(Label.HasDuplicateEmail),
    hasDuplicateEmailClaimed: labels.includes(Label.HasDuplicateEmailClaimed),
    hasFreeCampaignStore: labels.includes(Label.HasFreeCampaignStore),
    hasRelationshipMarketing: labels.includes(Label.HasRelationshipMarketing),
  }
}

export default useUserLabels
