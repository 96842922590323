import { Operation } from '../../../__generated__/globalTypes'

import {
  MarketingParagraphFragment,
  marketingParagraphFragmentString,
} from '../../fragments/__generated__/MarketingParagraphFragment'

import getAffiliateJoinMarketingContentRawString from '../GetAffiliateJoinMarketingContent.graphql'

const getAffiliateJoinMarketingContentString = [
  getAffiliateJoinMarketingContentRawString,
  marketingParagraphFragmentString,
].join('\n\n')

export type GetAffiliateJoinMarketingContentData = {
  priceCost: MarketingParagraphFragment
  priceCostFreeAffiliate: MarketingParagraphFragment
  agreement: MarketingParagraphFragment
  agreementFreeAffiliate: MarketingParagraphFragment
}

export type GetAffiliateJoinMarketingContent = Operation<
  GetAffiliateJoinMarketingContentData
>

export const GetAffiliateJoinMarketingContent = (): GetAffiliateJoinMarketingContent => ({
  query: getAffiliateJoinMarketingContentString,
  variables: undefined,
})
