import React from 'react'
// @src imports
import { Button, Icon } from 'src/chrome'
import { getPaperTypes } from 'src/app/constants'
import { CardPanelView } from 'src/orders/components'
import { useState } from 'src/hooks'
import * as SendDelayOptions from 'src/orders/sendDelayOptions'

import CardThumbnailTile from '../CardThumbnailTile/CardThumbnailTile'
import styles from './storeCampaignPreview.module.scss'
import {
  CampaignLineFragment,
  CardPaperType,
  CardType,
  ItemFragment,
} from 'src/graphql/generated/graphql'
interface Props {
  item: ItemFragment
  isUserStaff?: boolean
  onPurchase?: (item: ItemFragment) => void
  onClose: () => void
  isLoading?: boolean
}

const StoreCampaignPreview: React.FC<Props> = props => {
  const { item, isUserStaff, onPurchase, onClose } = props
  const campaign = item?.campaign

  const [activeLine, setActiveLine] = useState((campaign?.lines ?? [])[0])

  const changeActiveLine = (line: CampaignLineFragment) => {
    setActiveLine(line)
  }

  const lines = campaign?.lines ?? []
  const name = campaign?.name ?? ''
  const paperTypes = getPaperTypes(activeLine.card?.type ?? CardType.Flatcard)
  const paperType = paperTypes.find(
    paperType =>
      paperType.value === (activeLine.card?.paperType ?? CardPaperType.Std),
  )
  return (
    <div className={styles.campaignPreviewContainer}>
      <div className={styles.modal}>
        <div className={styles.campaignHeader}>
          <div className={styles.sideScrollContainer}>
            <div className={styles.scrollable}>
              {lines.map((line, index) => (
                <CardThumbnailTile
                  key={line.card?.id ?? index}
                  line={line}
                  activeLine={activeLine}
                  isCloseButtonEnabled={false}
                  onCardSelect={() => {
                    if (activeLine.card?.id !== line.card?.id) {
                      changeActiveLine(line)
                    }
                  }}
                />
              ))}
            </div>
          </div>
          {isUserStaff && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 5,
              }}
            >
              <p className={styles.subHeader}>Id: {item.id}</p>
            </div>
          )}
          <div
            style={{
              position: 'absolute',
              top: 10,
              right: 15,
              cursor: 'pointer',
              zIndex: 100,
            }}
            onClick={() => onClose()}
          >
            <Icon icon={'CLOSE'} size={18} color={'#6f8394'} />
          </div>
        </div>
        <div className={styles.cardsContainer}>
          <div className={styles.cardAndDetailsContainer}>
            <div className={styles.previewContainer}>
              {activeLine?.card && (
                <div className={styles.card}>
                  <CardPanelView
                    card={{
                      ...activeLine.card,
                      panels: activeLine.card.minimalPanels,
                    }}
                    sliderMaxContainerHeight={444}
                    shouldRotateBackPanels={true}
                  />
                </div>
              )}
            </div>
            <div className={styles.rightPanel}>
              <div className={styles.content}>
                <div className={styles.campaignDetails}>
                  <h4>{name}</h4>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className={styles.subHeader}>
                      <Icon icon={'TWOPANEL'} size={23} color={'#F171CC'} />
                      {lines.length} {`Card${lines.length !== 1 ? 's' : ''}`}
                    </div>
                    <h5 className={styles.price}>${item.price}</h5>
                  </div>
                </div>
                <div className={styles.cardDetails}>
                  <h4 style={{ color: 'rgb(115, 115, 115)', fontSize: 16 }}>
                    Card
                  </h4>
                  {activeLine && (
                    <div className={styles.cardDescription}>
                      <span>{activeLine.name}</span>
                      <span>
                        Send Delay:{' '}
                        {SendDelayOptions.ribbonDescription({
                          ...activeLine.sendDelay,
                        })}
                      </span>
                      {activeLine.card?.sendableCard && (
                        <p>{activeLine.card.sendableCard.title}</p>
                      )}
                      {paperType?.icon && (
                        <span>
                          Paper Type:{' '}
                          <Icon
                            icon={paperType.icon}
                            size={paperType.iconSize}
                            color={'#F171CC'}
                            viewBox={paperType.viewBox}
                          />{' '}
                          {paperType.label}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.footer}>
                <div className={styles.actions}>
                  {onPurchase && (
                    <Button
                      title={'Buy'}
                      buttonColor={'pink'}
                      style={{ marginLeft: 'auto', width: 126 }}
                      onClick={() => onPurchase(item)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.modalWindow} onClick={() => onClose()} />
    </div>
  )
}

export default StoreCampaignPreview
