import React, { CSSProperties } from 'react'

// @src imports
import { useActions, useMemo, useMutations, useState } from 'src/hooks'
import { Pagination, RwdHeader, RwdTable, Transition } from 'src/chrome'

import styles from './invites.module.scss'
import {
  Dialog,
  List,
  ListItem,
  LoadingSpinner,
  Text,
} from '@sendoutcards/quantum-design-ui'
import Prospect from './Prospect'
import { Set } from 'immutable'
import InvitesActionBar from './InvitesActionBar'
import { AddOrderCardRoute } from 'src/orders/routes/AddOrderCardRoute'
import { usePaginatedInvites } from 'src/react_query'
import { InviteFragment } from 'src/graphql/generated/graphql'

const selectAllStyles: CSSProperties = {
  textDecoration: 'underline',
  cursor: 'pointer',
}

const PAGE_SIZE = 20

const Invites: React.FC = () => {
  const [page, setPage] = useState(1)

  const paginatedInvitesQuery = usePaginatedInvites({ page })
  const { results: invites, count } = paginatedInvitesQuery.data ?? {}

  const [selected, setSelected] = useState(Set<string>())
  const [loading, setLoading] = useState(0)

  const [showErrorDialog, setShowErrorDialog] = useState(false)
  const [contactsToSend, setContactsToSend] = useState(Set<string>())

  const [invitesMissingInfo, setInvitesMissingInfo] = useState<
    InviteFragment[]
  >([])

  const actions = useActions()
  const mutations = useMutations()

  const selectionMap = useMemo(() => {
    const mutableMap: Record<string, true> = {}

    selected.forEach(x => (mutableMap[x] = true))
    return mutableMap
  }, [selected])

  const isEveryInviteSelected = useMemo(
    () => invites && invites.every(invite => selectionMap[invite.id]),
    [invites, selectionMap],
  )

  const prepareProspects = useMemo(() => {
    setContactsToSend(x => x.clear())
    setInvitesMissingInfo([])
    const contactsToCreate =
      invites
        ?.filter(invite => {
          if (selected.includes(invite.id)) {
            if (invite.contact) {
              const contactId = invite.contact.id
              setContactsToSend(x => x.add(contactId))
            } else {
              return true
            }
          }
          return false
        })
        .map(invites => invites.id) ?? []
    return contactsToCreate
  }, [selected, invites])

  if (paginatedInvitesQuery.isLoading) {
    return <LoadingSpinner size="medium" />
  }

  if (invites === undefined || count === undefined) {
    return <></>
  }

  const toggleSelectAll = () => {
    if (isEveryInviteSelected) {
      // Use a map here to avoid O(N^2) as there can be over 10k contacts
      const mutableLoadedInvitesMap: Record<string, true> = {}
      invites.forEach(x => (mutableLoadedInvitesMap[x.id] = true))

      setSelected(selected.filter(id => !mutableLoadedInvitesMap[id]))
    } else {
      setSelected(selected.union(invites.map(x => x.id)))
    }
  }

  const onSelect = (invite: InviteFragment) =>
    setSelected(x =>
      x.contains(invite.id) ? x.delete(invite.id) : x.add(invite.id),
    )

  const createOrder = async (contacts: string[]) => {
    setLoading(x => x + 1)
    try {
      const {
        createOrder: { order },
      } = await mutations.createOrder({ order: { contacts } })
      actions.openOrder(order.id, AddOrderCardRoute())
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(x => x - 1)
    }
  }

  const createMissingContacts = async (inviteIds: string[]) => {
    const {
      createContactsWithInvites,
    } = await mutations.createContactsWithInvites({
      inviteIds: inviteIds,
    })
    return createContactsWithInvites
  }

  const sendCard = async () => {
    setLoading(x => x + 1)
    if (prepareProspects.length > 0) {
      const createdContacts = await createMissingContacts(prepareProspects)
      if (createdContacts.failedInviteIds.length > 0) {
        const failedInvites = invites.filter(invite =>
          createdContacts.failedInviteIds.includes(invite.id),
        )
        setInvitesMissingInfo(failedInvites)
        setShowErrorDialog(true)
        setLoading(x => x - 1)
        return
      }
      createOrder(contactsToSend.toArray().concat(createdContacts.contactIds))
    } else {
      createOrder(contactsToSend.toArray())
    }
    setLoading(x => x - 1)
  }

  return (
    <div className={styles.outerContainer}>
      {loading > 0 && <Transition message="One Moment..." />}
      <InvitesActionBar
        title="Invites"
        totalCount={count}
        selectedCount={selected.toArray().length}
        onSendCard={sendCard}
      />
      <Dialog
        isOpen={showErrorDialog}
        onClose={() => setShowErrorDialog(false)}
      >
        <Text type="body">
          The following are missing either name, phone, or address information.
          Please make the needed changes and try again.
        </Text>
        <List alignment={'left'} orientation={'vertical'}>
          {invitesMissingInfo?.map(invite => (
            <ListItem
              orientation={'vertical'}
              key={invite.id}
              css={{ listStyle: 'inside' }}
            >
              {invite.socProContact?.lastName ?? invite.prospect?.lastName},{' '}
              {invite.socProContact?.firstName ?? invite.prospect?.firstName}
            </ListItem>
          ))}
        </List>
        {/* TODO: need a way for user to actually edit prospect info */}
      </Dialog>

      <div className={styles.container}>
        <section className={`${styles.column} ${styles.order2}`}>
          <RwdTable>
            <RwdHeader>
              <th
                id={'select_all_btn'}
                style={selectAllStyles}
                onClick={() => toggleSelectAll()}
              >
                <Text type="body" color="primaryBrand">
                  {isEveryInviteSelected ? 'Deselect All' : 'Select All'}
                </Text>
              </th>
              <th>
                <Text type="body" color="primaryBrand">
                  Name
                </Text>
              </th>
              <th>
                <Text type="body" color="primaryBrand">
                  Date
                </Text>
              </th>
              <th>
                <Text type="body" color="primaryBrand">
                  Phone
                </Text>
              </th>
              <th>
                <Text type="body" color="primaryBrand">
                  Email
                </Text>
              </th>
              <th>
                <Text type="body" color="primaryBrand">
                  Address
                </Text>
              </th>
              <th>
                <Text type="body" color="primaryBrand">
                  Activity
                </Text>
              </th>
              <th>
                <Text type="body" color="primaryBrand">
                  Stream
                </Text>
              </th>
              <th />
            </RwdHeader>
            <tbody>
              {invites.map((invite, index) => {
                return (
                  <Prospect
                    key={index}
                    index={index}
                    invite={invite}
                    isChecked={selected.includes(invite.id)}
                    onSelect={onSelect}
                  />
                )
              })}
            </tbody>
          </RwdTable>
        </section>
      </div>
      <div>
        <Pagination
          onPageChange={setPage}
          count={count}
          pagesToShow={5}
          pageSize={PAGE_SIZE}
          currentPage={page}
        />
      </div>
    </div>
  )
}

export default Invites
