import { Interpolation } from '@emotion/css'

import { Theme } from 'src/theme/theme'
import gradients, { GradientChoices } from 'src/theme/gradients'
import buttonColors, { ButtonColorChoices } from 'src/theme/buttonColors'
import colors from 'src/theme/colors'

const buttonRadius = '40px'

const getGradient = (theme: Theme, buttonColor?: ButtonColorChoices) => {
  if (theme && buttonColor) {
    return {
      ...buttonColors[buttonColor],
    }
  }
  return {
    height: '32px',
    padding: '1px',
    borderRadius: buttonRadius,
    color: colors.pink2,
    '& svg': {
      fill: colors.pink2,
    },
    background: gradients.pink,
  }
}
const styles = {
  gradientButton: (
    theme: Theme,
    buttonColor?: ButtonColorChoices,
    disabled?: boolean,
    blinking?: boolean,
  ) =>
    ({
      display: 'flex',
      height: '32px',
      borderRadius: buttonRadius,
      border: 'none',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
      cursor: 'pointer',
      appearance: 'none',
      margin: '0',
      ...(disabled && {
        cursor: 'not-allowed',
        userSelect: 'none',
        pointerEvents: 'none',
        opacity: 0.675,
      }),
      '&:focus': { outline: 0 },
      '& svg': {
        fill: '#fff',
        marginRight: '7px !important',
      },
      '&:hover': {
        boxShadow: 'none',
      },
      ...getGradient(theme, buttonColor),
    } as Interpolation),
  disabled: { opacity: 0.675 },

  inner: (theme: Theme, gradient?: GradientChoices, blinking?: boolean) =>
    ({
      '&:hover': {
        background: gradient ? gradients[gradient] : gradients.pink,
        color: '#fff',
        '& span': {
          fontWeight: 'bold',
          whiteSpace: 'nowrap',
          lineHeight: '32px',
          color: !gradient && !blinking ? gradients.pink : undefined,
          ...(blinking && {
            animation: `blinking-animation`,
            animationDuration: '2s',
            animationIterationCount: 'infinite',
          }),
        },
        '& svg': {
          fill: '#fff !important',
        },
      },
      width: '100%',
      borderRadius: buttonRadius,
      padding: '0 30px',
      height: 30,
      background: gradient ? gradients[gradient] : '#fff',
      textAlign: 'center',
      ...(blinking && {
        animation: `blinking-animation`,
        animationDuration: '2s',
        animationIterationCount: 'infinite',
      }),
      '& span': {
        whiteSpace: 'nowrap',
        lineHeight: '32px',
        fontWeight: 'bold',
        letterSpacing: '0.04em',
        textAlign: 'center',
        fontSize: '15px',
      },
    } as Interpolation),
}

export default styles
