import React from 'react'
import moment from 'moment'
// @src imports
import { Button, Icon } from 'src/chrome'
import { IconTypeOrIconString } from 'src/app/types'

import styles from './reminder.module.scss'
import { Flex, Text } from '@sendoutcards/quantum-design-ui'
import CloseButton from 'src/chrome/CloseButton/CloseButton'
import { Maybe, MinimalReminderFragment } from 'src/graphql/generated/graphql'
import { useMemo } from 'src/hooks'

const gradients = {
  pink: 'linear-gradient(115deg, #ff5689, #e687ff)',
  blue: 'linear-gradient(115deg, #6db0ff, #7de8f6)',
  orange: 'linear-gradient(28deg, #DD2476 0%, #FE5030 100%)',
}

const getGreetings = (name: string, type: string) => {
  const list = [
    `Wish ${name} a happy ${type}`,
    `Celebrate with ${name}`,
    `${name}'s ${type} is coming up!`,
  ]

  const greeting = list[Math.floor(Math.random() * list.length)]
  return greeting
}

const properties = (
  reminder: MinimalReminderFragment,
): {
  color: keyof typeof gradients
  icon: IconTypeOrIconString
  iconColor: string
  viewBox: string
} => {
  if (reminder.newType === 'ANNIVERSARY') {
    return {
      color: 'pink',
      icon: 'CHEERS',
      iconColor: '#F758A1',
      viewBox: '0 0 33 32',
    }
  } else if (reminder.newType === 'BIRTHDAY') {
    return {
      color: 'blue',
      icon: 'CONFETTI',
      iconColor: '#6db0ff',
      viewBox: '0 0 31 32',
    }
  } else {
    return {
      color: 'orange',
      icon: 'CAMPAIGNS',
      iconColor: '#DD2476',
      viewBox: '0 0 25 32',
    }
  }
}

interface ReminderProps {
  onDismiss: (reminder: MinimalReminderFragment) => void
  onSendCard: (
    contact: {
      id: string
      firstName: string
      lastName: string
      birthday?: Maybe<{
        day: number
        month: number
      }>
      anniversary?: Maybe<{
        day: number
        month: number
      }>
    },
    reminderId: string,
  ) => void
  openCampaign: (campaignId: string) => void
  onOpenContact: (contactId: string) => void
  reminder: MinimalReminderFragment
  openOrder: (orderId: string) => void
  onOpenHistory: (orderId: string, contactId: string) => void
}

const Reminder: React.FC<ReminderProps> = props => {
  const {
    onDismiss,
    onSendCard,
    openCampaign,
    onOpenContact,
    reminder,
    openOrder,
    onOpenHistory,
  } = props
  const { campaign } = reminder
  const { color, icon, viewBox, iconColor } = properties(reminder)

  const celebrationDate =
    reminder.type === 'BIRTHDAY'
      ? {
          day: reminder.contact.birthday?.day,
          month: reminder.contact.birthday?.month,
        }
      : {
          day: reminder.contact.anniversary?.day,
          month: reminder.contact.anniversary?.month,
        }

  const getCelebrationDateYear = (
    currentMonthIndex: number,
    celebrationMonthIndex: number,
    currentYear: number,
  ) => {
    const celebrationYear =
      currentMonthIndex > celebrationMonthIndex ? currentYear : currentYear + 1
    return celebrationYear
  }

  const isMailDeliveryTimeSufficient = (
    celebrationDay: number | undefined,
    celebrationMonth: number | undefined,
  ) => {
    const isUSContact =
      reminder.contact.country === 'United States' ? true : false
    const daysLimit = isUSContact ? 7 : 14

    if (celebrationDay !== undefined && celebrationMonth !== undefined) {
      // month index adjustment
      const celebrationMonthIndex = celebrationMonth - 1
      const today = new Date()
      const celebrationDate = new Date(
        getCelebrationDateYear(
          today.getMonth(),
          celebrationMonthIndex,
          today.getFullYear(),
        ),
        celebrationMonthIndex,
        celebrationDay,
      )

      if (
        Math.round((celebrationDate.getTime() - today.getTime()) / 86400000) <
        daysLimit
      ) {
        return false
      }
    }
    return true
  }

  const greetings = useMemo(() => {
    return getGreetings(reminder.contact.firstName, reminder.title)
  }, [reminder.contact.firstName, reminder.title])

  return (
    <div className={styles.reminder}>
      <div className={styles.leftContainer}>
        <Icon icon={icon} viewBox={viewBox} color={iconColor} size={35} />
        <div
          className={styles.date}
          style={{ backgroundImage: gradients[color] }}
        >
          <Text type="footnote" color="accent" alignment="center">
            {reminder.eventDate
              ? moment(reminder.eventDate).format('MMM Do')
              : reminder.newType === 'CAMPAIGN'
              ? 'Campaign'
              : null}
          </Text>
        </div>
      </div>
      {campaign === null || campaign === undefined ? (
        <div className={styles.body}>
          <div className={styles.details}>
            <Text
              type="largeBody"
              color="primaryHeading"
            >{`${reminder.contact.firstName} ${reminder.contact.lastName}`}</Text>
            <Text type="caption" color="primaryHeading">
              {reminder.title}
            </Text>
          </div>
          <div className={styles.description}>
            {!isMailDeliveryTimeSufficient(
              celebrationDate.day,
              celebrationDate.month,
            ) && (
              <Text type="caption" color="danger">
                Hurry and send a card, mail delivery times may not be
                sufficient.
              </Text>
            )}
            <Text type="body">{greetings}</Text>
            <Text type="caption">{reminder.message ?? ''}</Text>
          </div>
          <div className={styles.actionsContainer}>
            <Button
              title={'Open Contact'}
              buttonColor={'pink'}
              className={styles.sendButton}
              onClick={() => onOpenContact(reminder.contact.id)}
              style={{ marginRight: '20px' }}
            />
            {!reminder.order && (
              <Button
                title={'Send a Card'}
                buttonColor={'pink'}
                className={styles.sendButton}
                onClick={() => onSendCard(reminder.contact, reminder.id)}
                style={{ marginRight: '20px' }}
              />
            )}
            {reminder.order && !reminder.order.isSent && (
              <Button
                title={'Continue Order'}
                buttonColor={'pink'}
                className={styles.sendButton}
                onClick={() =>
                  openOrder(reminder.order ? reminder.order.id : '')
                }
                style={{ marginRight: '20px' }}
              />
            )}
            {reminder.order && reminder.order.isSent && (
              <Button
                title={'Card History'}
                buttonColor={'pink'}
                className={styles.sendButton}
                onClick={() =>
                  onOpenHistory(
                    reminder.order ? reminder.order?.id : '',
                    reminder.contact.id,
                  )
                }
                style={{ marginRight: '20px' }}
              />
            )}
          </div>
        </div>
      ) : (
        <div className={styles.body}>
          <div className={styles.details} style={{ flexGrow: 1 }}>
            <Text type="largeBody" color="primaryHeading">
              {campaign.name}
            </Text>
          </div>
          <Button
            title={'View Campaign'}
            onClick={() => openCampaign(campaign.id)}
            style={{ marginRight: '20px' }}
          />
        </div>
      )}
      <Flex alignItems="center">
        <CloseButton size={24} onClose={() => onDismiss(reminder)} />
      </Flex>
    </div>
  )
}

export default Reminder
