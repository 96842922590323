import React from 'react'
// @src imports
import {
  useAccount,
  useActions,
  useCallback,
  useDebounce,
  useEffect,
  useState,
  useSubscriptions,
} from 'src/hooks'

import tileStyles from 'src/chrome/styles/detailView.module.scss'
import {
  BulkSend,
  Button,
  ConfirmDialog,
  Div,
  Flex,
  Section,
} from '@sendoutcards/quantum-design-ui'
import { OrderWithPartialLines } from 'src/redux/reducers/orders'
import BulkOrderShippingAddress from '../BulkOrderShippingAddress/BulkOrderShippingAddress'
import { AddressFragment } from 'src/legacy_graphql'
import { bulkReturnAddress } from 'src/app/constants'
import { BulkOrderNotEligible } from './BulkOrderNotEligible'
import { PartialLine } from 'src/helpers/multitouch'
import { OrderApiType } from 'src/orders/api'
import { BulkSendEligible } from './BulkSendEligible'
import {
  AddressInput,
  CardFragment,
  CardType,
  CurrencyType,
} from 'src/graphql/generated/graphql'
import {
  useRevertBulkOrder,
  useUpdateAccount,
  useUpdateOrder,
} from 'src/react_query'
import omit from 'lodash/omit'

interface ReturnAddressProps {
  order: OrderWithPartialLines
  onChangeCard: (card: CardFragment) => void
  onRemoveCard: (line: PartialLine) => void
  api: OrderApiType
}

const BulkOrder: React.FC<ReturnAddressProps> = props => {
  const { order, api } = props

  const account = useAccount()
  const actions = useActions()
  const updateOrderMutation = useUpdateOrder()
  const updateAccountMutation = useUpdateAccount()
  const revertOrder = useRevertBulkOrder()
  const {
    userBulkCardCost: bulkCardCost,
    basicCardCost,
    basicBulkCardCost,
  } = useSubscriptions()

  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false)
  const [shippingAddress, setShippingAddress] = useState<
    AddressInput | undefined
  >(
    order.bulkUpgradeShippingAddress
      ? omit(order.bulkUpgradeShippingAddress, '__typename')
      : undefined,
  )
  const [quantity, setQuantity] = useState<number>(
    order.bulkUpgradeCardQty ? order.bulkUpgradeCardQty : 100,
  )
  const debouncedQuantity = useDebounce(quantity, 1500)

  const orderCard = order.lines.length > 0 ? order.lines[0].card : undefined
  const lineBaseCost =
    order.lines.length > 0 ? order.lines[0].baseCost : undefined
  const unitPrice: number =
    lineBaseCost &&
    lineBaseCost.entries[0] &&
    [CurrencyType.Usd, CurrencyType.Expense, CurrencyType.Credit].includes(
      lineBaseCost.entries[0].currency,
    ) &&
    lineBaseCost.entries[0].amount
      ? lineBaseCost.entries[0].amount / 100
      : basicCardCost

  const convertIntoBulkOrder = async () => {
    updateBulkOrder(true)
  }

  const updateBulkOrder = useCallback(
    async (shouldConvert?: boolean) => {
      const shouldUpdateAddresses =
        shouldConvert &&
        account.shippingAddress &&
        account.shippingAddress.address1

      if (
        (order.isBulk && debouncedQuantity !== order.bulkUpgradeCardQty) ||
        shouldConvert
      ) {
        const { updateOrder } = await updateOrderMutation.mutateAsync({
          order: {
            id: order.id,
            bulkUpgradeCardQty: shouldConvert ? quantity : debouncedQuantity,
            bulkUpgradeShippingAddress: shouldUpdateAddresses
              ? omit(account.shippingAddress, '__typename')
              : shippingAddress,
            returnAddress: shouldUpdateAddresses
              ? bulkReturnAddress
              : undefined,
          },
        })
        actions.savedOrder(updateOrder.order)
      }
    },
    [
      quantity,
      debouncedQuantity,
      updateOrderMutation,
      order.id,
      order.isBulk,
      shippingAddress,
      order.bulkUpgradeCardQty,
      account.shippingAddress,
      actions,
    ],
  )

  const updateBulkAddress = async (
    shippingAddressFragment: AddressFragment,
    phoneNumber?: string,
  ) => {
    const shippingAddress: AddressInput = omit(
      shippingAddressFragment,
      '__typename',
    )
    setShippingAddress(shippingAddress)
    const shouldUpdatePhoneNumber = account.phoneNumber === '' && phoneNumber

    const hasShippingAddress =
      account.shippingAddress && account.shippingAddress.address1

    if (
      shouldUpdatePhoneNumber ||
      !hasShippingAddress ||
      !account.firstName ||
      !account.lastName
    ) {
      const {
        updateAccount: { account: updatedAccount },
      } = await updateAccountMutation.mutateAsync({
        account: {
          firstName: !account.firstName ? shippingAddress.firstName : undefined,
          lastName: !account.lastName ? shippingAddress.lastName : undefined,
          phoneNumber: shouldUpdatePhoneNumber ? phoneNumber : undefined,
          shippingAddress: !hasShippingAddress ? shippingAddress : undefined,
        },
      })
      actions.updatedAccount(updatedAccount)
    }

    const { updateOrder } = await updateOrderMutation.mutateAsync({
      order: {
        id: order.id,
        bulkUpgradeShippingAddress: shippingAddress,
        returnAddress: bulkReturnAddress,
      },
    })
    actions.savedOrder(updateOrder.order)
  }

  const isDisabled = () => {
    const acceptedCardTypes = [CardType.TwoPanel, CardType.Flatcard]
    const lines = order.lines
    const hasGift = lines.some(line => !!line.giftVariation)
    const hasIncompatibleCardType = lines.some(
      line => line?.card?.type && !acceptedCardTypes.includes(line.card.type),
    )
    const hasIncompatibleSendDate = !lines.every(
      line => line?.sendDelay.type === 'IMM',
    )

    const isEmptyOrder = order.lines.length === 0
    const cards = order.lines.filter(line => !!line.card)
    const hasMoreThanOneCardOnOrder = cards.length > 1

    return (
      hasGift ||
      hasIncompatibleCardType ||
      hasIncompatibleSendDate ||
      hasMoreThanOneCardOnOrder ||
      isEmptyOrder
    )
  }

  const getCardBaseCost = () => {
    const baseCardCost = (order.lines.length > 0 && order.lines[0].baseCost
      ? order.lines[0].baseCost.entries
      : []
    ).find(
      value =>
        value.label === 'Card Cost' && value.currency === CurrencyType.Usd,
    )?.amount
    return baseCardCost
      ? Math.min(baseCardCost / 100, bulkCardCost)
      : bulkCardCost
  }

  const getCardUpgradesBaseCost = () =>
    order.lines.length > 0
      ? ((order.lines[0].baseCost?.entries ?? []).find(
          value =>
            value.label === 'Card Upgrades' &&
            value.currency === CurrencyType.Usd,
        )?.amount ?? 0.0) / 100
      : 0.0

  const getSalePrice = () => {
    return getCardBaseCost() + getCardUpgradesBaseCost()
  }

  const getUnitPrice = () => {
    const cardUpgrades: number = getCardUpgradesBaseCost()
    if (basicBulkCardCost === bulkCardCost) {
      return basicCardCost + cardUpgrades
    }
    return Math.max(basicBulkCardCost, unitPrice) + cardUpgrades
  }

  useEffect(() => {
    updateBulkOrder()
  }, [updateBulkOrder, debouncedQuantity])

  return (
    <Flex flexDirection="column">
      <ConfirmDialog
        isOpen={isAddDialogOpen}
        title="Confirm to Continue"
        description="When converting your order to a bulk order the name insert, send date features, and gift options are not available. Need to make edits? Not to worry! This can be changed by clicking cancel to go back to edit your order."
        accept={{
          title: 'Continue',
          onClick: () => {
            convertIntoBulkOrder()
            setIsAddDialogOpen(!isAddDialogOpen)
          },
        }}
        decline={{
          title: 'Cancel',
          onClick: () => setIsAddDialogOpen(!isAddDialogOpen),
        }}
        requireScroll={false}
      />
      {!order.isBulk && !isDisabled() ? (
        <BulkSendEligible
          stackedCardImage={orderCard?.frontPreviewUrl}
          onClick={() => setIsAddDialogOpen(true)}
          isHorizontal={orderCard?.isHorizontal}
        />
      ) : !isDisabled() ? (
        <Section style={{ marginTop: 0 }} position="relative">
          <Div backgroundColor="foreground" borderRadius="medium">
            <BulkSend
              sliderImage={orderCard?.frontPreviewUrl}
              sliderOrientation={
                orderCard?.isHorizontal ? 'horizontal' : 'vertical'
              }
              disableMessage="Your order contains a card that is not compatible for bulk sending or has multiple cards in the order. Select a single flat or 2-panel card to add the bulk option to your order."
              quantity={quantity}
              setQuantity={(quantity: number) => {
                setQuantity(quantity)
              }}
              configurationSections={[
                {
                  title: 'Envelopes',
                  description: '',
                  tag: 'Bulk',
                  activeConfigurationId: 'blank',
                  setActiveConfigurationId: (
                    activeConfigurationId: string,
                  ) => {},
                  configurationList: [
                    {
                      graphicType: 'blank',
                      configurationId: 'blank',
                      configurationTitle: 'Blank Envelopes',
                      configurationDescription: '',
                    },
                  ],
                },
              ]}
              isActive={order.isBulk}
              setIsActive={() => {}}
              salePrice={getSalePrice()}
              hasIncrement={true}
              title="Bulk Send Option"
              description="Turn your custom card into a bulk order! Select the quantity you need using the slider and we`ll mail them directly to you with white blank envelopes. From there, you can send them when and how you you wish. Stamps not included."
              quantityDescription="Cards"
              minQuantity={10}
              maxQuantity={400}
              sliderFill="primaryBrand"
              sliderWidth={200}
              unitPrice={getUnitPrice()}
              isConfigurable={!isDisabled()}
              removeDialog={{
                isOpen: false,
                description:
                  'By removing the bulk option your order will be converted back to a standard order based on your current subscription. ',
                title: 'Confirm to Continue',
                accept: {
                  title: 'Continue',
                  onClick: () => revertOrder.mutateAsync({ id: order.id }),
                },
                decline: {
                  title: 'Cancel',
                  onClick: () => {},
                },
                primaryAction: 'decline',
              }}
              secondaryAction={
                order.isBulk && (
                  <Div
                    position={'absolute'}
                    zIndex={4}
                    outset={{
                      top: orderCard?.isHorizontal ? '148px' : '234px',
                      left: '134px',
                    }}
                  >
                    <Button
                      id="order_preview_view_edit"
                      size="small"
                      title="View/Edit"
                      onClick={() => {
                        api.setIsShowingCardPreview(true)
                      }}
                      type="shadow"
                    />
                  </Div>
                )
              }
            />
          </Div>
        </Section>
      ) : (
        <BulkOrderNotEligible stackedCardImage="https://www.sendoutcards.com/thumbnail/0F2c_sYzVLRz9gYGid4o5W1tBtA=/fit-in/700x0/system_images/2019/12/4/0fab54d8-4b71-4923-89da-a257e7c182ce.png" />
      )}
      {order.isBulk && (
        <section
          className={`${tileStyles.overviewCard}`}
          style={{
            marginTop: '20px',
            backgroundColor: '#fff',
            position: 'relative',
            display: 'flex',
            padding: '25px 20px',
            borderRadius: '16px',
          }}
        >
          <BulkOrderShippingAddress onSave={updateBulkAddress} order={order} />
        </section>
      )}
    </Flex>
  )
}

export default BulkOrder
