import { useEffect, useState } from './'
import { AnyMemoized } from './dependencies'

type UsePropChange = <T extends AnyMemoized>(
  prop: T,
  callback: () => void,
) => void

const usePropChange: UsePropChange = (prop, callback) => {
  const [isAlreadyRendered, setIsAlreadyRendered] = useState(false)

  useEffect(() => {
    if (!isAlreadyRendered) {
      setIsAlreadyRendered(true)
    } else {
      callback()
    }
    /* eslint-disable-next-line */
  }, [prop])
}

export default usePropChange
