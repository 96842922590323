import React, { FC } from 'react'
import { SVGProps } from './types'

export const SmileCardWHearts: FC<SVGProps> = ({ size, color, onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 48 32"
      width={size}
      height={size}
      onClick={onClick}
    >
      <path
        fill={color}
        d="M47.61 3.529c.026-.747-.366-1.466-.981-1.821-.744-.452-1.423-.516-2.067-.193-.21-.533-.648-1.074-1.63-1.32-.996-.245-1.978.331-2.29 1.338-.011.04-.011.079-.011.118-.164.633-.057 1.373.352 2.216.267.537 1.252 2.455 1.44 2.796.008.01.018.01.026.022.05.074.113.138.195.17.054.022.11.029.167.025.018 0 .036-.007.054-.007.028-.003.053-.003.082-.014.202-.071.782-.299 1.398-.537.533-.207 1.074-.42 1.33-.513.143-.05.256-.096.392-.167h.01s.004-.003.007-.003c0 0 .008 0 .011-.004.004 0 .007-.007.01-.007.926-.47 1.474-1.227 1.502-2.092l.004-.007ZM6.994 5.094c.306-.992.143-1.914-.455-2.533-.523-.537-1.306-.757-1.992-.562-.84.22-1.363.666-1.576 1.352-.53-.22-1.22-.28-2.082.253-.871.54-1.141 1.647-.636 2.572.021.035.05.06.074.089.342.558.947.996 1.836 1.288.573.181 2.633.807 3.01.91.025.007.053.004.078.007.018 0 .036.008.053.004.057 0 .11-.014.164-.036a.412.412 0 0 0 .189-.192c.007-.01.017-.014.024-.025.09-.195.331-.772.587-1.38l.555-1.313a2.52 2.52 0 0 0 .157-.405v-.004c0-.003 0-.01.004-.014v-.014l.01.003ZM27.126 26.208c.68-.782.904-1.693.601-2.5-.26-.701-.889-1.22-1.597-1.317-.857-.139-1.515.057-1.988.601-.395-.416-1.004-.75-2.007-.608-1.014.15-1.708 1.05-1.615 2.099.003.04.021.078.032.114.085.65.466 1.295 1.16 1.92.451.396 2.084 1.8 2.39 2.046.022.018.047.025.072.04.014.007.028.017.046.024a.431.431 0 0 0 .163.033c.093 0 .175-.04.253-.097.01-.007.021-.003.032-.014.16-.142.612-.573 1.092-1.024.416-.395.836-.794 1.039-.975.114-.1.206-.189.306-.31v-.003c0-.004.007-.007.01-.01 0 0 0-.008.008-.012l.003-.007Z"
      />
      <path
        fill={color}
        d="M42.232 11.114a.404.404 0 0 0-.047-.15c-.01-.021-.032-.039-.046-.057a1.938 1.938 0 0 0-1.213-.978c-.057-.043-.11-.085-.181-.128l-1.484-.929v-4.3a2.204 2.204 0 0 0-2.202-2.203h-8.207L25.682.388c-.41-.256-.953-.384-1.494-.384s-1.082.128-1.494.384l-3.17 1.981h-8.47A2.204 2.204 0 0 0 8.85 4.571v4.476c-.8.501-1.387.871-1.405.886-.63.434-.993.683-1.195.96-.022.025-.047.046-.064.078-.004.011-.004.018-.007.029-.121.21-.164.448-.164.797v18.268c0 1.07.868 1.942 1.942 1.942h32.456c1.07 0 1.942-.868 1.942-1.942V11.797c0-.239-.046-.47-.128-.68l.004-.003Zm-1.466-.31c.139.05.256.132.363.228l-1.868.996V9.872l1.505.932ZM23.16 1.131c.256-.16.63-.252 1.028-.252s.772.092 1.028.252l1.974 1.235h-6.005L23.16 1.13ZM9.734 4.568c0-.73.594-1.32 1.32-1.32h26.005c.73 0 1.32.594 1.32 1.32v7.542c0 .124.053.235.135.316l-11.75 6.28a.454.454 0 0 0-.154.142c-1.515-.818-3.212-.83-4.742-.046a.447.447 0 0 0-.117-.096L9.773 12.361c.01-.341-.004-.853-.04-.925v-6.87Zm-1.954 6.2 1.071-.672v1.473c0 .05.014.178.036.33l-1.63-.864c.146-.128.32-.23.523-.266Zm33.697 19.293a1.06 1.06 0 0 1-1.06 1.06H7.958a1.06 1.06 0 0 1-1.06-1.06V11.84l14.134 7.482c-.025.018-.05.032-.075.05L9.388 28.435a.441.441 0 0 0 .545.694l11.565-9.061c1.686-1.299 3.697-1.299 5.376 0l11.569 9.065a.437.437 0 0 0 .27.092.441.441 0 0 0 .27-.786l-11.572-9.065s-.007-.003-.01-.007l14.073-7.52v18.218l.003-.004Z"
      />
      <path
        fill={color}
        d="M28.503 10.62a1.761 1.761 0 1 0 0-3.523 1.761 1.761 0 0 0 0 3.522ZM18.994 10.01a.873.873 0 0 1-.676-.316.884.884 0 0 1 .114-1.241c1.647-1.374 3.251-.566 3.87.053.345.342.345.9 0 1.245a.876.876 0 0 1-1.238.007c-.206-.192-.765-.572-1.505.047a.88.88 0 0 1-.562.202l-.003.004ZM27.546 11.612h-5.909c-1.256 0-1.953 1.42-1.17 2.38 2.209 2.696 5.823 2.696 8.029 0l.135-.164c.73-.893.082-2.213-1.088-2.213l.003-.003Zm-3.227 3.522s-1.76 0-2.643-.883c0 0 2.473-2.643 5.639 0 0 0-.356.883-3 .883h.004Z"
      />
    </svg>
  )
}
