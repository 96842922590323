import {
  Button,
  Checkbox,
  Flex,
  PhoneInput,
  Text,
} from '@sendoutcards/quantum-design-ui'
import React, { FC } from 'react'
import {
  AccountFragment,
  VerifyPhoneNumberSuccessFragment,
} from 'src/graphql/generated/graphql'
import { getMachineCountryCode } from 'src/helpers'
import {
  createPhoneNumberInput,
  getValidatedNumber,
} from 'src/helpers/createPhoneNumberInput'
import {
  useActions,
  useDebounce,
  useEffect,
  useMutations,
  usePersistedUserData,
  useState,
} from 'src/hooks'
import { Memoized } from 'src/hooks/dependencies'
import usePhoneNumberCountries from 'src/hooks/usePhoneNumberCountries'
import IncorrectCode from 'src/onboarding/components/IncorrectCode'
import { getFormattedNumber } from 'src/onboarding/components/PhoneNumberVerification'
import Result from 'src/utils/Result'
import CodeVerification from './CodeVerification'

type Step =
  | { type: 'entry' }
  | { type: 'verification' }
  | { type: 'incorrectCode' }

type LoginPhoneInputProps = {
  phoneNumber: string
  loginErrorMessage?: string
  didForgetLoginInfo?: boolean
  handleSetPhoneNumber: (phoneNumber: string) => void
  handleIsCheckingPhoneNumber?: Memoized<
    (isCheckingPhoneNumber: boolean) => void
  >
  onLoginSuccess: (account: AccountFragment) => void
}

export const LoginPhoneInput: FC<LoginPhoneInputProps> = ({
  phoneNumber,
  loginErrorMessage,
  didForgetLoginInfo,
  handleSetPhoneNumber,
  onLoginSuccess,
}) => {
  const { controlData, phoneVerification } = usePersistedUserData()
  const mutations = useMutations()
  const actions = useActions()

  const loginPhoneNumber = !controlData.isLoggedIn
    ? phoneVerification.loginPhoneNumber
    : undefined

  const phoneNumberCountries = usePhoneNumberCountries()

  const [step, setStep] = useState<Step>({
    type: 'entry',
  })
  const [isLoading, setIsLoading] = useState(false)
  const [isRememberMeChecked, setIsRememberMeChecked] = useState(true)
  const [isCountrySelectedManually, setIsCountrySelectedManually] = useState<
    boolean
  >(false)
  const [loginWithPhonenumberError, setLoginWithPhonenumberError] = useState<
    string
  >()
  const [isPhoneNumberChanging, setIsPhoneNumberChanging] = useState(false)

  const [selectedCountryIndex, setSelectedCountryIndex] = useState<number>(
    phoneNumberCountries.findIndex(country => {
      if (loginPhoneNumber) {
        return country.isoCode === loginPhoneNumber.countryIsoCode
      }
      return country.isoCode === 'US'
    }),
  )

  const selectedCountry = phoneNumberCountries[selectedCountryIndex]
  const selectedCountryCode = selectedCountry.isoCode
  const formattedNumber = getFormattedNumber(selectedCountryCode, phoneNumber)
  const validatedNumber = getValidatedNumber(selectedCountryCode, phoneNumber)

  const debouncedPhoneNumber = useDebounce(phoneNumber, 500)
  const phoneNumberInput = createPhoneNumberInput(
    debouncedPhoneNumber,
    selectedCountry,
  )

  const errorMessage =
    !didForgetLoginInfo && loginErrorMessage
      ? loginErrorMessage
      : !isPhoneNumberChanging &&
        debouncedPhoneNumber !== '' &&
        !phoneNumberInput.nationalNumber
      ? 'Please enter a valid phone number'
      : loginWithPhonenumberError ?? ''

  const handleLoginWithPhonenumber = async () => {
    try {
      setIsLoading(true)
      const {
        requestPhoneNumberVerification: result,
      } = await mutations.requestPhoneNumberVerification({
        phoneNumber: phoneNumberInput,
        doNotShowDefaultTransition: true,
      })
      if (result.__typename === 'RequestPhoneNumberVerificationSuccess') {
        setLoginWithPhonenumberError(undefined)
        setStep({ type: 'verification' })
      } else {
        setLoginWithPhonenumberError(
          `Failed to send code to: ${formattedNumber}`,
        )
      }
    } catch (error) {
      setLoginWithPhonenumberError(
        error?.toString() ?? 'Failed to request phone number verification.',
      )
    } finally {
      setIsLoading(false)
    }
  }

  const onVerificationSuccess = (success: VerifyPhoneNumberSuccessFragment) => {
    if (success.verifiedAccount.id && !controlData.isLoggedIn) {
      actions.loginDone(Result(success.verifiedAccount), true)
    }
    onLoginSuccess(success.verifiedAccount)
  }

  const onVerificationFailure = () => {
    setStep({ type: 'incorrectCode' })
  }

  const onNeedNewCode = () => {
    setStep({ type: 'entry' })
  }

  const onTryAgain = () => {
    setStep({ type: 'verification' })
  }

  useEffect(() => {
    const updateCountryCode = async () => {
      for await (const countryCode of getMachineCountryCode()) {
        const index = phoneNumberCountries.findIndex(country => {
          return country.isoCode === countryCode
        })
        if (index !== -1 && !loginPhoneNumber && !isCountrySelectedManually) {
          setSelectedCountryIndex(index)
        }
      }
    }
    if (!loginPhoneNumber && !isCountrySelectedManually) {
      updateCountryCode()
    }
  }, [phoneNumberCountries, loginPhoneNumber, isCountrySelectedManually])

  useEffect(() => {
    if (debouncedPhoneNumber !== phoneNumber) {
      setIsPhoneNumberChanging(true)
      setLoginWithPhonenumberError(undefined)
    } else {
      setIsPhoneNumberChanging(false)
    }
  }, [
    debouncedPhoneNumber,
    phoneNumber,
    setIsPhoneNumberChanging,
    setLoginWithPhonenumberError,
  ])

  return (
    <div
      style={{
        maxWidth: '400px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        ...((!validatedNumber && debouncedPhoneNumber !== '') || errorMessage
          ? { marginBottom: '3rem' }
          : {}),
      }}
    >
      {step.type === 'entry' && (
        <>
          <Text type="body" outset={{ vertical: 'x2' }}>
            We are part of Promptings Company, so if you <i>already</i> have an
            account from any of our product streams you can use those
            credentials to sign in
          </Text>
          <div
            style={
              (!validatedNumber && debouncedPhoneNumber !== '') || errorMessage
                ? { marginBottom: '3rem' }
                : undefined
            }
          >
            <PhoneInput
              countries={phoneNumberCountries}
              selectedCountryIndex={selectedCountryIndex}
              onSelectedCountryIndexChange={index => {
                setIsCountrySelectedManually(true)
                setSelectedCountryIndex(index)
              }}
              phoneNumber={formattedNumber}
              onPhoneNumberChange={handleSetPhoneNumber}
              maxHeight="125px"
              message={
                errorMessage
                  ? { type: 'danger', content: errorMessage }
                  : undefined
              }
            />
          </div>
          <Flex outset={{ vertical: 'x2' }}>
            <Checkbox
              onChange={setIsRememberMeChecked}
              isChecked={isRememberMeChecked}
              label="Remember Me"
              type="primary"
            />
          </Flex>
          <div style={{ marginTop: '1rem' }}>
            <Button
              hover={true}
              onClick={handleLoginWithPhonenumber}
              type={'primary'}
              fullWidth={true}
              backgroundColorOverride="#00aeef"
              title={
                !isLoading
                  ? 'Send me a verification code'
                  : 'Sending you a verification code'
              }
              disabled={phoneNumberInput.nationalNumber === undefined}
            />
          </div>
        </>
      )}
      {step.type === 'verification' && (
        <CodeVerification
          // phoneNumber={{ countryIsoCode: '1', nationalNumber: '12345' }} // TODO: will be passing phoneNumber soon
          phoneNumber={phoneNumberInput}
          onSuccess={onVerificationSuccess}
          onFailure={onVerificationFailure}
          onNeedNewCode={onNeedNewCode}
        />
      )}
      {step.type === 'incorrectCode' && (
        <IncorrectCode
          phoneNumber={phoneNumberInput}
          getNewCode={onNeedNewCode}
          tryAgain={onTryAgain}
        />
      )}
    </div>
  )
}
