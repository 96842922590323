import React from 'react'
import {
  Div,
  Input,
  PhoneInput,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { getFormattedNumber } from 'src/onboarding/components/PhoneNumberVerification'
import { useQueries } from 'src/hooks'
import { getMarketingContent } from 'src/legacy_graphql'
import { PhoneNumberCountries } from 'src/hooks/usePhoneNumberCountries'

const LoginInput = (props: {
  selectedCountryIndex: number
  setSelectedCountryIndex: (index: number) => void
  phoneNumberCountries: PhoneNumberCountries[]
  shouldLoginWithPhone: boolean
  username: string
  password: string
  phoneNumber: string
  errorMessage: string
  onChange: (
    name: 'username' | 'password' | 'phoneNumber',
    value: string,
  ) => void
}) => {
  const {
    selectedCountryIndex,
    setSelectedCountryIndex,
    phoneNumberCountries,
  } = props

  const [marketingContent] = useQueries(
    props.shouldLoginWithPhone ? getMarketingContent() : undefined,
  )
  const complianceMessage = marketingContent?.complianceMessage

  return (
    <>
      {props.shouldLoginWithPhone ? (
        <Div width="100%" inset={{ bottom: 'x1' }}>
          <div style={{ textAlign: 'right', fontSize: '12px' }}>
            <span
              style={{ color: 'silver' }}
              data-mktcontent="complianceMessage"
            >
              {complianceMessage?.content}
            </span>
            <PhoneInput
              maxHeight="216px"
              selectedCountryIndex={selectedCountryIndex}
              onSelectedCountryIndexChange={setSelectedCountryIndex}
              countries={phoneNumberCountries}
              onPhoneNumberChange={phoneNumber =>
                props.onChange(
                  'phoneNumber',
                  getFormattedNumber(
                    phoneNumberCountries[selectedCountryIndex].isoCode,
                    phoneNumber,
                  ),
                )
              }
              phoneNumber={props.phoneNumber}
              message={{
                type: 'danger',
                content: props.errorMessage,
              }}
            />
          </div>
        </Div>
      ) : (
        <Div width="100%">
          <Input
            onChange={username => props.onChange('username', username)}
            type="text"
            name="username"
            value={props.username}
            placeholder="Username"
            icon="user"
            isFullWidth={true}
            id="username_input"
          />
          <Text
            type="footnote"
            content={'Usernames are case sensitive'}
            outset="x1"
          />
          <Spacer space="x1" />
          <Input
            onChange={password => props.onChange('password', password)}
            type="password"
            name="password"
            value={props.password}
            placeholder="Password"
            message={{
              type: 'danger',
              content: props.errorMessage,
            }}
            icon="lock"
            isFullWidth={true}
            id="password_input"
          />
        </Div>
      )}
    </>
  )
}

export default LoginInput
