import { useCreateUserLoginLink } from 'src/react_query'
import { AccountFragment, Args, UserType } from 'src/graphql/generated/graphql'
import { buildShoppingCartURL } from 'src/user/components/LoginForm/ShoppingCartUtils'
import {
  useCallback,
  useEffect,
  usePersistedUserData,
  useQueryParams,
  useState,
  useVertical,
} from '.'
import useRedirectUriBuilder from './useRedirectUriBuilder'

export const byDesignSSOBaseLink =
  'https://shop.bydesign.com/SendOutCards/#/seamless/'

const useRedirectEffect = () => {
  const { account } = usePersistedUserData()
  const createUserLoginLinkMutation = useCreateUserLoginLink()
  const vertical = useVertical()
  const queryParams = useQueryParams()
  const [isRedirecting, setIsredirecting] = useState(false)
  const isJoiningAffiliate = queryParams.isJoiningAffiliate
  const redirectUriBuilder = useRedirectUriBuilder()
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const shouldRedirectToPromptingsAcademy =
    queryParams.client === 'promptingsacademy'

  const redirect = useCallback(
    async (account: AccountFragment) => {
      const queryParamsRedirectUri = queryParams.redirect_uri ?? ''

      const isByDesignRedirect = queryParamsRedirectUri.includes(
        'shop.bydesign.com',
      )
      const shouldUseSSOBaseLink =
        isByDesignRedirect && !queryParamsRedirectUri.includes('seamless')

      const createUserLinkParams: Args = shouldUseSSOBaseLink
        ? {
            redirect_uri: byDesignSSOBaseLink,
          }
        : {
            redirect_uri: queryParams.redirect_uri,
          }

      const {
        createUserLoginLink: { link },
      } = await createUserLoginLinkMutation.mutateAsync({
        verticalId: vertical.graphqlVerticalID,
        queryParams: createUserLinkParams,
      })

      const redirectUri = link || redirectUriBuilder(account)

      const shouldBuildByDesignRedirectLink =
        queryParams.shoppingCartCategory || queryParams.shoppingCartProduct

      const shouldGoToByDesign =
        shouldBuildByDesignRedirectLink || isByDesignRedirect

      if (redirectUri) {
        setIsredirecting(true)
        // window.location.href = redirectUri
        if (shouldGoToByDesign) {
          // The following is a **hacky** way to get around directing a user to a specific page
          // in the ByDesignShopping cart, given that we can't SSO and redirect in 1 call to
          // their site. So we'll open the SSO link in a new tab, wait (so that the new tab
          // can set the proper local storage tokens), then we open the actual page to the
          // specific product or category (and let the authentication get picked up via local
          // storage tokens). Hoping we can get rid of this one day, pending ByDesign updating
          // their site.
          const iframe = document.createElement('iframe')
          // eslint-disable-next-line
          iframe.src = redirectUri // tslint:disable-line
          document.body.appendChild(iframe)

          setTimeout(() => {
            window.location.href = shouldBuildByDesignRedirectLink
              ? buildShoppingCartURL(
                  queryParams.shoppingCartCategory,
                  queryParams.shoppingCartProduct,
                  account.sponsor?.genealogyId,
                )
              : decodeURIComponent(queryParamsRedirectUri)
          }, 5000)
        } else {
          if (redirectUri.startsWith('https://')) {
            window.location.replace(redirectUri)
          } else {
            window.location.href = redirectUri
          }
        }
      }
    },
    [
      vertical.graphqlVerticalID,
      queryParams,
      redirectUriBuilder,
      setIsredirecting,
      createUserLoginLinkMutation,
    ],
  )

  useEffect(() => {
    if (
      account &&
      (queryParams.redirect_uri || shouldRedirectToPromptingsAcademy) &&
      (!isJoiningAffiliate ||
        account.type === UserType.Gsa ||
        queryParams.shoppingCartCategory ||
        queryParams.shoppingCartProduct) &&
      !isRedirecting
    ) {
      redirect(account)
    }
  }, [
    account,
    queryParams,
    redirect,
    isJoiningAffiliate,
    isRedirecting,
    shouldRedirectToPromptingsAcademy,
  ])
}

export default useRedirectEffect
