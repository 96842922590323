import {
  CurrencyType,
  ItemizedCostFragment,
} from 'src/graphql/generated/graphql'
import { formatCost } from 'src/helpers'

const getCondensedTotal = (cost: ItemizedCostFragment): string => {
  const freeItem = cost.total.find(item => item.currency === CurrencyType.Free)
  if (freeItem) {
    return '$0.00'
  }
  const pointsItem = cost.total.find(
    item => item.currency === CurrencyType.Point,
  )
  const dollarAmount = formatCost(
    cost.total
      .filter(
        item =>
          item.currency === CurrencyType.Usd ||
          item.currency === CurrencyType.Expense,
      )
      .reduce((acc, currentItem) => acc + currentItem.amount, 0),
  )
  return pointsItem ? `${pointsItem.asString} + ${dollarAmount}` : dollarAmount
}

export default getCondensedTotal
