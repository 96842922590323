import React, { Fragment } from 'react'
import { Switch } from '@material-ui/core'

// @src imports
import {
  useAccount,
  useActions,
  useEffect,
  useIsAddressValid,
  usePropChange,
  useState,
  useSubscriptions,
} from 'src/hooks'
import { Button } from 'src/chrome'
import { formatAmount } from 'src/helpers/payments'
import {
  BaseCreditCard,
  EmptyCard,
  ExpenseBucketCard,
  ExpenseReloadForm,
  ValidateAddress,
} from 'src/payments/components'
import { FlexColumn } from 'src/styled'
// relative imports
import AddExpense from '../AddExpense/AddExpense'

import styles from './styles'
import { Text } from '@sendoutcards/quantum-design-ui'
import HoverTooltip from 'src/chrome/HoverTooltip/HoverTooltip'
import { ExpenseReloadFragment } from 'src/graphql/generated/graphql'
import { useUpdateAccount } from 'src/react_query'
import { CheckoutProducts } from 'src/hooks/useQueryParams'

type EditingState = 'none' | 'editing' | 'view'

const ExpenseBucket: React.FC = () => {
  const account = useAccount()
  const actions = useActions()
  const updateAccountMutation = useUpdateAccount()

  const isAddressValid = useIsAddressValid()
  const { expenseReload } = account
  const [editingContext, setEditingContext] = useState<EditingState>('editing')
  const [isSaving, setIsSaving] = useState(false)
  const [manualExpenseAmount, setManualExpenseAmount] = useState(0)
  const [purchaseAmount, setPurchaseAmount] = useState(
    expenseReload ? expenseReload.purchaseAmount : 1000, // Backend sends the purchaseAmount in cents
  )
  const [threshold, setThreshold] = useState(
    expenseReload ? expenseReload.threshold : 500, // Backend sends the threshold in cents
  )
  const [isEnabled, setIsEnabled] = useState(
    expenseReload ? expenseReload.isEnabled || expenseReload.isRequired : false,
  )
  const [isDisabled, setIsDisabled] = useState(true)
  const [shouldUpdateAddress, setShouldUpdateAddress] = useState(false)
  const currentExpenseReload = {
    purchaseAmount,
    threshold,
    isEnabled,
  } as ExpenseReloadFragment

  const handleChange = (
    name: 'purchaseAmount' | 'threshold',
    value: number,
  ) => {
    switch (name) {
      case 'purchaseAmount':
        setPurchaseAmount(value)
        break
      case 'threshold':
        setThreshold(value)
        break
    }
  }

  const handleClick = (shouldValidateAddress: boolean) => {
    if (!isAddressValid && shouldValidateAddress) {
      setShouldUpdateAddress(true)
    } else if (isEnabled) {
      handleUpdateExpense()
    } else {
      purchaseExpense()
    }
    setEditingContext('view')
  }

  const handleUpdateAddress = () => {
    setShouldUpdateAddress(false)
    handleClick(false)
  }

  const handleUpdateExpense = async () => {
    setIsSaving(true)
    try {
      const {
        updateAccount: { account },
      } = await updateAccountMutation.mutateAsync({
        account: {
          expenseReload: {
            ...currentExpenseReload,
            purchaseAmount: purchaseAmount / 100, // Backend expects the value in Dollars
            threshold: threshold / 100, // Backend expects the value in Dollars
          },
        },
      })
      setIsSaving(false)
      actions.updatedAccount(account)
    } catch (error) {
      setIsSaving(false)
    }
  }

  const purchaseExpense = async () => {
    const ITEM_CODE = '11132'
    const checkoutProducts: CheckoutProducts = [
      { productSku: ITEM_CODE, quantity: manualExpenseAmount },
    ]

    actions.openPricing({
      checkoutProducts: encodeURI(JSON.stringify(checkoutProducts)),
    })
  }

  const getToolTipMessage = (noStripeSource: boolean) =>
    noStripeSource
      ? 'You must add a credit card to enable purchasing expense.'
      : !account.shippingAddress?.address1
      ? 'You must add an address to your account to enable purchasing expense.'
      : `Your bucket is too full to allow any refills at this time. Please check back when your bucket is less than $${formatAmount(
          5000,
        )}`

  usePropChange(isEnabled, handleUpdateExpense)

  const buttonTitle = () =>
    !isSaving && !isEnabled ? 'Purchase' : isSaving ? 'Saving...' : 'Done'

  const expenseBalance = account.expense.amount
  const limitDifference = Math.ceil(50 - expenseBalance / 100)
  const manualExpenseLimit = Math.min(
    limitDifference > 0 ? limitDifference : 0,
    50,
  )
  const hasNoStripeSource = !account.stripeSource
  const isOverBucketLimit = expenseBalance > 5000
  const toolTipMessage = getToolTipMessage(hasNoStripeSource)

  const { isExpenseReloadRequired, currentPlan } = useSubscriptions()

  useEffect(() => {
    setIsDisabled(
      !account.stripeSource ||
        isOverBucketLimit ||
        !account.shippingAddress?.address1,
    )
  }, [account.stripeSource, account.shippingAddress, isOverBucketLimit])

  return (
    <Fragment>
      {editingContext === 'editing' ? (
        <div css={{ position: 'relative', zIndex: 2 }}>
          <ExpenseBucketCard
            expenseReload={account.expenseReload || currentExpenseReload}
            balance={expenseBalance}
          />
          <div css={styles.formCard}>
            <FlexColumn>
              {isExpenseReloadRequired ? (
                <div css={styles.flex}>
                  <Text
                    type="body"
                    color="primaryHeading"
                    lineHeight={2.3}
                    alignment="center"
                  >
                    Your {currentPlan.title} subscription requires an expense
                    account.
                  </Text>
                </div>
              ) : (
                <div css={styles.flex}>
                  <Text type="body" color="primaryHeading" lineHeight={2.3}>
                    Enable Auto-Reload
                  </Text>
                  <Switch
                    checked={isEnabled}
                    disabled={expenseReload?.isRequired}
                    onChange={() => setIsEnabled(!isEnabled)}
                  />
                </div>
              )}
              <div css={styles.flex}>
                {isEnabled ? (
                  <ExpenseReloadForm
                    expenseReload={currentExpenseReload}
                    onChange={handleChange}
                  />
                ) : (
                  <AddExpense
                    expenseAmount={manualExpenseAmount}
                    expenseLimit={manualExpenseLimit}
                    onChange={setManualExpenseAmount}
                  />
                )}
              </div>
            </FlexColumn>
            <div css={styles.purchaseButtonContainer}>
              <HoverTooltip
                shouldDisplayTooltip={isDisabled}
                isFullWidth={true}
                background="dark"
                title={toolTipMessage}
              >
                <Button
                  buttonColor={'pink'}
                  title={buttonTitle()}
                  disabled={
                    isDisabled ||
                    isSaving ||
                    (!isEnabled && manualExpenseAmount === 0)
                  }
                  onClick={() => handleClick(true)}
                  css={styles.purchaseButton}
                />
              </HoverTooltip>
            </div>
          </div>
        </div>
      ) : editingContext === 'none' ? (
        <BaseCreditCard onClick={() => setEditingContext('editing')}>
          <EmptyCard message={'Add Expense Bucket'} />
        </BaseCreditCard>
      ) : editingContext === 'view' ? (
        <Fragment>
          <ExpenseBucketCard
            expenseReload={account.expenseReload ?? currentExpenseReload}
            balance={account.expense.amount}
          />
          <div css={styles.editExpenseContainer}>
            <Button
              onClick={() => setEditingContext('editing')}
              title={'Manage Expense'}
            />
          </div>
        </Fragment>
      ) : null}
      {shouldUpdateAddress && (
        <ValidateAddress
          isOpen={!isAddressValid && shouldUpdateAddress}
          onClose={() => setShouldUpdateAddress(false)}
          account={account}
          onSubmit={() => handleUpdateAddress()}
        />
      )}
    </Fragment>
  )
}

export default ExpenseBucket
