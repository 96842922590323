import { SmallOrLarge } from 'src/app/types'

const sizes = {
  dollars: {
    small: 30,
    large: 65,
  },
  cents: {
    small: 17,
    large: 24,
  },
  centMarginBottom: {
    small: 4,
    large: 10,
  },
  dollarMarginTop: {
    small: 4,
    large: 20,
  },
}

const styles = {
  dollars: (size: SmallOrLarge, color: string) => ({
    // fontSize: 65,
    fontWeight: 600,
    fontSize: sizes.dollars[size],
    color,
  }),
  cents: (size: SmallOrLarge, color: string) => ({
    marginTop: 'auto',
    fontWeight: 600,
    fontSize: sizes.cents[size],
    marginBottom: sizes.centMarginBottom[size],
    color,
  }),
  dollarSign: (size: SmallOrLarge) => ({
    marginTop: sizes.dollarMarginTop[size],
  }),
}

export default styles
