import { Div, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'

export const PhoneVerificationTerms: React.FC = () => {
  return (
    <Div>
      <Text
        type="footnote"
        content="*By verifying your phone number, you also agree to allow Promptings to send text messages. Dont worry! We will never spam you."
        alignment="left"
        inset={{ top: 'x1', bottom: 'x1_5' }}
      />
      <Text
        type="footnote"
        content="Standard text messaging and data rates may apply."
        alignment="left"
      />
    </Div>
  )
}
