import React, { FC } from 'react'
import { SVGProps } from './types'

export const StopWatch: FC<SVGProps> = ({ size, color, onClick }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 9 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M4.50815 10.0002C4.34556 9.97598 4.182 9.9568 4.02039 9.92647C3.01973 9.73823 2.19023 9.25 1.54281 8.46415C0.855229 7.62937 0.54039 6.66075 0.582303 5.5802C0.653069 3.75508 1.93621 2.15695 3.69658 1.69612C3.89328 1.64466 4.09642 1.61766 4.29643 1.5793C4.32763 1.57324 4.35862 1.56717 4.39352 1.56032V0.78112C4.26836 0.78112 4.14321 0.784251 4.01824 0.780337C3.86268 0.775445 3.74532 0.701282 3.67592 0.561566C3.60983 0.428503 3.62094 0.295831 3.7034 0.172552C3.7769 0.0627746 3.88315 0.00250478 4.01493 0.00211342C4.53057 0.000156611 5.0462 -0.00101748 5.56164 0.00250478C5.77491 0.00387455 5.94315 0.183314 5.94159 0.39328C5.94003 0.600115 5.77569 0.77251 5.5669 0.780142C5.44097 0.784838 5.31484 0.780924 5.18208 0.780924V1.56521C6.03984 1.65092 6.80423 1.96714 7.48479 2.52797C7.61365 2.39451 7.739 2.2593 7.87059 2.13034C7.98444 2.01861 8.12305 1.98534 8.27433 2.04052C8.42093 2.09394 8.50631 2.20431 8.52834 2.35851C8.54706 2.49 8.4991 2.60174 8.4067 2.6941C8.28271 2.81816 8.15755 2.94066 8.03474 3.06198C8.16223 3.25081 8.29324 3.42751 8.40631 3.61517C9.86178 6.03418 8.51177 9.24452 5.77043 9.88127C5.55989 9.93019 5.34252 9.94995 5.12847 9.98381C5.10312 9.98772 5.07837 9.99457 5.05322 10H4.50834L4.50815 10.0002ZM4.78945 9.21948C6.67732 9.21928 8.21779 7.67555 8.22189 5.77999C8.22598 3.88306 6.68434 2.3315 4.7914 2.32759C2.90178 2.32367 1.35644 3.87366 1.35585 5.77334C1.35527 7.6734 2.89613 9.22006 4.78945 9.21967V9.21948Z"
        fill={color}
      />
      <path
        d="M6.72748 4.25869C6.67914 4.34733 6.64775 4.45281 6.57971 4.52227C6.08046 5.0332 5.57515 5.53845 5.06907 6.04252C4.9059 6.20513 4.66456 6.20317 4.51152 6.0478C4.3581 5.89185 4.36044 5.65331 4.5238 5.48835C5.02423 4.9831 5.52642 4.47961 6.02918 3.97671C6.15337 3.85246 6.30055 3.81665 6.46587 3.88474C6.61052 3.94443 6.70526 4.09099 6.70331 4.24558C6.7113 4.24989 6.7193 4.25439 6.72729 4.25869H6.72748Z"
        fill={color}
      />
    </svg>
  )
}
