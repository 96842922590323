import React from 'react'

// @src imports
import { useEffect, useLoading, useState } from 'src/hooks'
import { Button, Fade, ProfileAvatar } from 'src/chrome'

import { Flex, Input, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import {
  AccountFragment,
  StorefrontFragment,
} from 'src/graphql/generated/graphql'

type PersonalSiteOptionsProps = {
  storefront: StorefrontFragment
  account: AccountFragment
  onSave: (storefront: StorefrontFragment) => Promise<void>
  onSaveMugshot: (imageSource: File) => void
}

const PersonalSiteOptions: React.FC<PersonalSiteOptionsProps> = props => {
  const {
    storefront,
    account: { firstName, lastName },
    onSave,
    onSaveMugshot,
  } = props

  const [data, setData] = useState(storefront)
  const loading = useLoading()

  const isDirty = data !== storefront

  const onChange = (name: string, value: string) => {
    setData({
      ...data,
      [name]: value,
    })
  }

  const reset = () => setData(storefront)

  const save = async () => {
    loading.start()
    try {
      await onSave(data)
    } finally {
      loading.end()
    }
  }

  useEffect(() => {
    setData(storefront)
  }, [storefront])

  return (
    <div>
      <div>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <ProfileAvatar
            size={100}
            style={{
              fontSize: '2.4rem',
              color: '#fff',
              letterSpacing: 1,
            }}
            account={{
              firstName,
              lastName,
              profileImageUrl: storefront.mugshot?.url,
            }}
            isEditable={true}
            onSave={onSaveMugshot}
            avatarStyles={{ alignItems: 'flex-start' }}
          />
          <Text type="body">Sharing Picture</Text>
          <Text type="caption">
            For best results, image dimensions should be 300x300 pixels.
          </Text>
        </div>
        <Flex
          justifyContent="flex-start"
          width="100%"
          maxWidth={'500px'}
          flexDirection="column"
          outset={{ top: 'x3' }}
        >
          <Input
            type="text"
            name="slug"
            value={data.slug ?? ''}
            isFullWidth={true}
            label="Your personalized URL"
            onChange={e => onChange('slug', e)}
          />
          <Spacer space="x2_5" />
          <Input
            type="text"
            name="biography"
            value={data.biography ?? ''}
            label="Your personalized story"
            onChange={e => onChange('biography', e)}
            isFullWidth={true}
          />
        </Flex>
      </div>
      <Fade fadeIn={4} fadeOut={4} isVisible={isDirty}>
        <div style={{ display: 'flex', marginTop: 25 }}>
          <Button
            title={'Reset'}
            disabled={loading.isLoading}
            onClick={reset}
            style={{ marginRight: 50 }}
          />
          <Button
            title={loading.isLoading ? 'Saving...' : 'Save'}
            disabled={loading.isLoading}
            buttonColor={'pink'}
            onClick={save}
          />
        </div>
      </Fade>
    </div>
  )
}

export default PersonalSiteOptions
