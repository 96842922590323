import { Route } from 'src/routes/Route'
import { Subroute } from 'src/routes/Subroute'
import { CatalogCardsRoute } from 'src/catalog/routes/CatalogCardsRoute'
import { CardCategoryRoute } from 'src/catalog/routes/CardCategoryRoute'
import { CardCollectionRoute } from 'src/catalog/routes/CardCollectionRoute'
import { CustomCardRoute } from 'src/catalog/routes/CustomCardRoute'
import { CustomCardsRoute } from 'src/catalog/routes/CustomCardsRoute'
import { FavoriteCardsRoute } from 'src/catalog/routes/FavoriteCardsRoute'
import { LegacyPicturePlusCardsRoute } from 'src/catalog/routes/LegacyPicturePlusCardsRoute'
import { PhotoDropCardsRoute } from 'src/catalog/routes/PhotoDropCardsRoute'
import { SendableCardRoute } from 'src/catalog/routes/SendableCardRoute'
import { CardRoute } from 'src/card/routes/CardRoute'

const ReplaceOrderCardSubRoute = Subroute(
  CardCategoryRoute,
  CardCollectionRoute,
  CustomCardRoute,
  CustomCardsRoute,
  FavoriteCardsRoute,
  LegacyPicturePlusCardsRoute,
  PhotoDropCardsRoute,
  SendableCardRoute,
  CatalogCardsRoute,
  CardRoute,
)
type ReplaceOrderCardSubRoute = Subroute<typeof ReplaceOrderCardSubRoute>

export const ReplaceOrderCardRoute = Route({
  path: '/card/:cardId/replace',
  init: (cardId: string, subroute?: ReplaceOrderCardSubRoute) => ({
    cardId,
    subroute,
  }),
  fromPath: ({ cardId }) => (cardId ? { cardId } : undefined),
  subroute: ReplaceOrderCardSubRoute,
})

export type ReplaceOrderCardRoute = Route<typeof ReplaceOrderCardRoute>
