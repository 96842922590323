import React, { FC } from 'react'
import { formatAmount } from 'src/helpers/payments'
import { formatCost } from 'src/helpers'

const styles = {
  card: {
    '@media (max-width: 668px)': {
      width: '100%',
    },
  },
}

type ExpenseBucketCardProps = {
  expenseReload?: { purchaseAmount: number; isEnabled: boolean }
  balance?: number
  width?: string
  height?: string
}

const ExpenseBucketCard: FC<ExpenseBucketCardProps> = props => {
  const { expenseReload, balance, width = '354.89', height = '224.09' } = props

  return (
    <svg
      css={styles.card}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 354.89 224.09"
    >
      <defs>
        <clipPath id="a">
          <rect width={354.89} height={224.09} fill="none" />
        </clipPath>
        <clipPath id="b">
          <rect width={354.89} height={224.09} rx="18.72" fill="none" />
        </clipPath>
        <linearGradient
          id="c"
          x1="0.637"
          y1="1.03"
          x2="0.363"
          y2="-0.03"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#21d4fd" />
          <stop offset="1" stopColor="#b721ff" />
        </linearGradient>
        <linearGradient
          id="d"
          x1="0.5"
          y1="1"
          x2="0.5"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#fec561" />
          <stop offset="1" stopColor="#ea3bfd" />
        </linearGradient>
        <linearGradient
          id="f"
          x1="-0.104"
          y1="-0.15"
          x2="1.104"
          y2="1.15"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#3b7ffb" />
          <stop offset="1" stopColor="#70e2fd" />
        </linearGradient>
        <linearGradient id="g" x1="-0.15" y1="-0.067" x2="1.15" y2="1.067" />
      </defs>
      <g clipPath="url(#a)">
        <g fill="#d8d8d8" stroke="#979797" strokeWidth="0.572">
          <rect width={354.318} height={224.09} rx="18.72" stroke="none" />
          <rect
            x="0.286"
            y="0.286"
            width="354.318"
            height="223.518"
            rx="18.434"
            fill="none"
          />
        </g>
        <g clipPath="url(#b)">
          <g transform="translate(-42.336 -3.118)">
            <path
              d="M0,0H354.89V251.592H0Z"
              transform="translate(42.336 3.118)"
              fill="url(#c)"
            />
            <path
              d="M1459.387,1982.014h185.092c-13.873-33.052-35.044-72.767-62.469-75.347-114.4-10.764.257-93.8-76.35-128.654-39.86-18.136-33.677-77.4-46.273-41.694S1459.387,1982.014,1459.387,1982.014Z"
              transform="translate(-1417.052 -1725.092)"
              opacity="0.502"
              fill="url(#d)"
            />
            <path
              d="M1424.964,1986.041H1602.41c-13.3-31.687-33.6-69.761-59.888-72.235-109.672-10.319.246-89.925-73.2-123.339-38.214-17.386-32.285-74.2-44.361-39.972S1424.964,1986.041,1424.964,1986.041Z"
              transform="translate(-1419.597 -1724.035)"
              fill="url(#d)"
            />
            <path
              d="M1620.124,1793.846c60.135,31.2,85.273,64.864,65.557,104.912-32.261,65.529-25.04,40.94-7.64,80.834h106.714V1728H1607.309C1614.36,1753.083,1579.253,1772.639,1620.124,1793.846Z"
              transform="translate(-1406.175 -1724.882)"
              opacity="0.502"
              fill="url(#f)"
            />
            <path
              d="M1670.588,1793.846c60.134,31.2,85.273,64.864,65.556,104.912-32.26,65.529-25.04,40.94-7.64,80.834h71.718V1728h-142.45C1664.824,1753.083,1629.717,1772.639,1670.588,1793.846Z"
              transform="translate(-1402.419 -1724.882)"
              fill="url(#g)"
            />
            <g transform="translate(62.552 23.295)">
              <g transform="translate(0)">
                <text
                  transform="translate(10 10)"
                  fill="#fff"
                  fontSize="14"
                  fontFamily="Montserrat-Bold, Montserrat"
                  fontWeight="700"
                >
                  <tspan x="0" y="0">
                    Expense Bucket
                  </tspan>
                </text>
                <g transform="translate(36.744 68.637)">
                  <text
                    transform="translate(120.064 16)"
                    fill="#fff"
                    fontSize="10"
                    fontFamily="Montserrat-SemiBold, Montserrat"
                    fontWeight="600"
                  >
                    <tspan x="-47.141" y="0">
                      Available Balance
                    </tspan>
                    <tspan y="0" fontSize="12">
                      {' '}
                    </tspan>
                    <tspan
                      fontSize="24"
                      fontFamily="Montserrat-Bold, Montserrat"
                      fontWeight="700"
                    >
                      <tspan x="-34.932" y="32">
                        {formatCost(balance ?? 0)}
                      </tspan>
                    </tspan>
                  </text>
                  <g transform="translate(-46 85.053)">
                    <text
                      transform="translate(7.788 33.12)"
                      fill="#fff"
                      fontSize="12"
                      fontFamily="Montserrat-Bold, Montserrat"
                      fontWeight="700"
                      letterSpacing="0.023em"
                    >
                      <tspan x="0" y="0">
                        $
                      </tspan>
                      <tspan y="0" fontSize="16">
                        {formatAmount(expenseReload?.purchaseAmount ?? 0)}
                      </tspan>
                    </text>
                    <text
                      transform="translate(8 7)"
                      fill="#fff"
                      fontSize="10"
                      fontFamily="Montserrat-SemiBold, Montserrat"
                      fontWeight="600"
                      letterSpacing="0.023em"
                    >
                      <tspan x="0" y="0">
                        Bucket Amount
                      </tspan>
                    </text>
                  </g>
                  <g transform="translate(225.668 85.053)">
                    <text
                      transform="translate(-6.234 33.12)"
                      fill="#fff"
                      fontSize="16"
                      fontFamily="Montserrat-Bold, Montserrat"
                      fontWeight="700"
                    >
                      <tspan x="0" y="0">
                        {expenseReload?.isEnabled ? 'On' : 'Off'}
                      </tspan>
                    </text>
                    <text
                      transform="translate(25 7)"
                      fill="#fff"
                      fontSize="10"
                      fontFamily="Montserrat-SemiBold, Montserrat"
                      fontWeight="600"
                    >
                      <tspan x="-31.63" y="0">
                        Auto Reload
                      </tspan>
                    </text>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default ExpenseBucketCard
