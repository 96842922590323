import { SelectedAttribute } from 'src/saleor_graphql/generated/graphql'

export const extractAttributesBySlug = (
  slug: string,
  attributes: SelectedAttribute[],
) => {
  return (
    attributes
      .find(attr => attr.attribute.slug === slug)
      ?.values.flatMap(val => val.reference) ?? []
  )
}
