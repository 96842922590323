import { Maybe, Operation } from '../../../__generated__/globalTypes'

import {
  ImageFragment,
  imageFragmentString,
} from '../../fragments/__generated__/ImageFragment'

import createImageWithUrlRawString from '../CreateImageWithURL.graphql'

const createImageWithUrlString = [
  createImageWithUrlRawString,
  imageFragmentString,
].join('\n\n')

export type CreateImageWithURLData = {
  uploadImage: {
    image: ImageFragment
  }
}

export type CreateImageWithURLVariables = {
  url?: Maybe<string>
}

export type CreateImageWithURL = Operation<
  CreateImageWithURLData,
  CreateImageWithURLVariables
>

export const CreateImageWithURL = ({
  url,
}: CreateImageWithURLVariables = {}): CreateImageWithURL => ({
  query: createImageWithUrlString,
  variables: {
    url,
  },
})
