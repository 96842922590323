import deepmerge from 'deepmerge'
import { NormalizedData } from 'src/legacy_graphql'
import memoizeOne from 'memoize-one'
import get from 'lodash/get'

const MAX_DEPTH = 32

export const gatherNormalizedData = memoizeOne(
  (value: unknown): Partial<NormalizedData> => {
    const mutableData: Partial<NormalizedData> = {}
    addNormalizedData(value, mutableData, 0)
    return mutableData
  },
)

const addNormalizedData = (
  value: unknown,
  mutableData: Partial<NormalizedData> = {},
  depth: number,
) => {
  if (typeof value !== 'object' || value === null) {
    return
  }
  if (isNormalizable(value)) {
    const normalizedObject = normalizeObject(value, depth + 1)
    const __typename = normalizedObject.__typename as keyof NormalizedData
    const id = normalizedObject.id
    const mutableTypeData = mutableData[__typename]
    if (mutableTypeData) {
      const previouslyNormalizedObject = mutableTypeData[id]
      if (previouslyNormalizedObject) {
        mutableTypeData[id] = deepmerge(
          normalizedObject,
          previouslyNormalizedObject,
          deepmergeOptions,
        )
      } else {
        mutableTypeData[id] = normalizedObject
      }
    } else {
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      mutableData[__typename] = { [id]: normalizedObject } as any
    }
  }
  Object.values(value).forEach(value =>
    addNormalizedData(value, mutableData, depth + 1),
  )
}

const fastMapValues = <T extends object, U>(
  object: T,
  transform: (value: T[keyof T]) => U,
): { [key in keyof T]: U } => {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const mutableMapped: any = {}
  Object.keys(object).forEach(key => {
    mutableMapped[key] = transform(object[key as keyof T])
  })
  return mutableMapped
}

const normalizeObject = <T extends object>(object: T, depth: number) =>
  fastMapValues(object, value => normalizeValue(value, depth))

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const normalizeValue = (value: unknown, depth: number = 0): any => {
  if (depth > MAX_DEPTH) {
    console.warn('Reached max recursion depth value normalizing value')
    return undefined
  } else if (typeof value != 'object' || value == null) {
    return value
  } else if (Array.isArray(value)) {
    return value.map(v => normalizeValue(v, depth + 1))
  } else if (isNormalizable(value)) {
    return normalize(value)
  } else {
    return normalizeObject(value, depth + 1)
  }
}

export const deepmergeOptions: deepmerge.Options = {
  arrayMerge: (destination, source) => source,
}

const denormalizeObject = (
  object: object,
  data: NormalizedData,
  depth: number,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
): any => fastMapValues(object, value => denormalizeValue(value, data, depth))

export const denormalizeValue = (
  value: unknown,
  data: NormalizedData,
  depth: number = 0,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
): any => {
  if (depth > MAX_DEPTH) {
    console.warn('Reached max recursion depth value denormalizing value')
    return undefined
  } else if (typeof value != 'object' || value == null) {
    return value
  } else if (Array.isArray(value)) {
    return value.map(x => denormalizeValue(x, data, depth + 1))
  } else if (isNormalized(value)) {
    return denormalizeObject(
      data[value.__typename][value.id] as object,
      data,
      depth + 1,
    )
  } else {
    return denormalizeObject(value, data, depth + 1)
  }
}

type Normalizable<K extends keyof NormalizedData> = {
  __typename: K
  id: string
}

const isNormalizable = (
  value: unknown,
): value is Normalizable<keyof NormalizedData> =>
  typeof get(value, '__typename') === 'string' &&
  typeof get(value, 'id') === 'string'

type KeyOf<T> = T extends Normalizable<infer K> ? K : never

type Normalized<T extends Normalizable<keyof NormalizedData>> = {
  __typename: KeyOf<T>
  id: string
  isNormalized: true
}

const isNormalized = (
  value: unknown,
): value is Normalized<Normalizable<keyof NormalizedData>> =>
  typeof get(value, '__typename') === 'string' &&
  typeof get(value, 'id') === 'string' &&
  get(value, 'isNormalized') === true

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const normalize = <T extends Normalizable<any>>(value: T): Normalized<T> => ({
  __typename: value.__typename,
  id: value.id,
  isNormalized: true,
})
