import React from 'react'

import { ContactFragment } from 'src/legacy_graphql'
import { Transition } from 'src/chrome'

import { IMPORT_CHUNK_SIZE } from '../helpers'
import { ParsedRow } from '../columns'
import { Hooks } from '../hooks'
import { useEffect, useState } from 'src/hooks'

interface Props {
  parsedRows: Partial<ParsedRow>[]
  importChunk: Hooks['importChunk']
  onComplete: (
    importedContacts: ContactFragment[],
    parsedRows: Partial<ParsedRow>[],
  ) => void
  rowLimit?: number
}

const processRemainingRows = async (
  remainingRows: Partial<ParsedRow>[],
  importChunk: Hooks['importChunk'],
) => {
  const [chunk, rest] = [
    remainingRows.slice(0, IMPORT_CHUNK_SIZE),
    remainingRows.slice(IMPORT_CHUNK_SIZE),
  ]

  const { contacts: success, failedContacts: failed } = await importChunk(chunk)

  return { chunk, rest, success, failed }
}

const ImportStep: React.FC<Props> = props => {
  const { parsedRows, importChunk, onComplete, rowLimit } = props
  const [importedCount, setImportedCount] = useState(0)

  const importedRows = rowLimit ? parsedRows.slice(0, rowLimit) : parsedRows

  useEffect(() => {
    ;(async () => {
      // TODO: failedContacts are ignored
      let mutableRemaining = importedRows
      let mutableImported: ContactFragment[] = []

      while (mutableRemaining.length) {
        const result = await processRemainingRows(mutableRemaining, importChunk)
        mutableRemaining = result.rest
        mutableImported = mutableImported.concat(result.success)
        setImportedCount(mutableImported.length)
      }

      onComplete(mutableImported, importedRows)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Transition
      message={
        importedCount
          ? `Imported ${importedCount} of ${importedRows.length}`
          : `Importing ${importedRows.length} Customers...`
      }
    />
  )
}

export default ImportStep
