import React from 'react'
import { PlanTileGroup } from 'src/marketing/plans/components'

type PricingTableProps = {
  selectedColumn?: number
  onSelect: (planId: string, isKeepButton?: boolean) => void
}

const PricingTable: React.FC<PricingTableProps> = props => {
  const { onSelect } = props

  return (
    <>
      <PlanTileGroup onSelect={onSelect} />
    </>
  )
}

export default PricingTable
