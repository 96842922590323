import React from 'react'
import {
  Anchor,
  Button,
  ConfirmDialog,
  Flex,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { useState } from 'src/hooks'
import { AppLinks } from 'src/upsale/components/appLinks/AppLinks'

const FreeCardSuccess = (props: {
  onSignUp: () => void
  onNoThanks: () => void
}) => {
  const { onSignUp, onNoThanks } = props
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  return (
    <>
      <Flex flexDirection="column" height="100%" justifyContent="space-between">
        <Text type="title" weight="bold">
          Your message of kindness has been sent!
        </Text>
        <Spacer space="x2" />
        <Text type="caption" alignment="left">
          We’re so excited you sent out your first card! Watch this video to see
          the life of your card... How it's made and mailed out after you hit
          send! We’d love for you to create a login so you can view the card you
          just sent and send more cards and messages of kindness into the world!
        </Text>
        <Spacer space="x6" />
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <Button
            fullWidth={true}
            title={'Save my account'}
            onClick={onSignUp}
          />

          {/* Instead of allowing a user to "delete an account" have yes or no options
            1. Yes brings in create account form [username, password]
            2. No takes user to a flatpage that thanks the user for trying sendoutcards and tries to upsale them
            to create an account.
          */}
          <Anchor
            inset={{ top: 'x2' }}
            cursor="pointer"
            size="xSmall"
            onClick={() => setIsDialogOpen(true)}
          >
            Remove my account information
          </Anchor>
        </Flex>
        <AppLinks />
      </Flex>
      <ConfirmDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        zIndex={4}
        description="You will be unable to log back into your account if you continue. Are you sure you want to continue removing access?"
        title="Oh, no! Are you sure?"
        accept={{
          title: 'Yes, log me out',
          onClick: onNoThanks,
        }}
        decline={{
          title: 'Save my account',
          onClick: onSignUp,
        }}
      />
    </>
  )
}

export default FreeCardSuccess
