import { ContactFragment } from 'src/legacy_graphql'

const isFullContact = (contact: ContactFragment) => {
  return [
    'firstName',
    'lastName',
    'address1',
    'city',
    'state',
    'postalCode',
  ].every(key => contact.hasOwnProperty(key))
}

export default isFullContact
