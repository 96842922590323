import React from 'react'
import { Sheet, Stepper } from '@sendoutcards/quantum-design-ui'
import {
  useAccount,
  useActions,
  usePersistedUserData,
  useQueryParams,
  useState,
  useSubscriptions,
  useVertical,
} from 'src/hooks'
import { compact } from 'src/helpers'
import FreeCardNotification from '../free-card-notification/FreeCardNotification'
import GracePeriodNotification from '../grace-period-notification/GracePeriodNotification'
import {
  Label,
  OverridableUserLabel,
  UserLabelOverrideAction,
} from 'src/graphql/generated/graphql'
import {
  useCreateAffiliate,
  useUpdateAccount,
  useUpdateUserLabelOverrides,
} from 'src/react_query'
import ProgramOptIn, { ActionKeys } from './ProgramOptIn'
import NonActionStep, { ContentType } from './NonActionStep'
import OrphanStep from './OrphanStep'

type Step = {
  available: boolean
  skippable?: true
  onBackAction?: () => void
}

// We need this for QDS compatibility
export type StepWrapperProps = {
  order?: number
}

const hasBeen24Hours = (lastActionsReviewTimestamp?: string) => {
  if (!lastActionsReviewTimestamp) {
    return true
  }
  const initialTimeStamp = parseInt(lastActionsReviewTimestamp, 10)
  const now = Date.now() / 1000
  const twentyFourHours = 86400
  return now - initialTimeStamp > twentyFourHours
}

const ProgressiveProfileActions = () => {
  const account = useAccount()
  const vertical = useVertical()
  const queryParams = useQueryParams()
  const {
    mutateAsync: updateUserLabelOverridesMutation,
  } = useUpdateUserLabelOverrides()
  const actions = useActions()
  const {
    controlData: {
      progressiveProfilingDisplayedAt: lastActionsReviewTimestamp,
    },
  } = usePersistedUserData()
  const [canContinue, setCanContinue] = useState(false)
  const [activeItemIndex, setActiveItemIndex] = useState(0)
  const [affiliateOptInResponse, setAffiliateOptInResponse] = useState('')
  const [custOptInResponse, setCustOptInResponse] = useState('')
  const [selectedSponsorId, setSelectedSponsorId] = useState<
    string | undefined
  >()
  const [isSheetOpen, setIsSheetOpen] = useState(true)
  const { isInPromptingsGracePeriod } = useSubscriptions()
  const createAffiliateMutation = useCreateAffiliate()
  const { mutateAsync: updateAccountMutation } = useUpdateAccount()

  const onStepsComplete = async () => {
    actions.setProgressiveProfilingDisplayedAt((Date.now() / 1000).toString())
    setIsSheetOpen(false)
    // Affiliate or Customer optIn
    if (
      affiliateOptInResponse === ActionKeys.optIn ||
      custOptInResponse === ActionKeys.optIn
    ) {
      const {
        createDistributor: { account: updatedAccount, error },
      } = await createAffiliateMutation.mutateAsync({
        account: { username: account.username },
        govId1: '',
        affiliate: affiliateOptInResponse === ActionKeys.optIn,
        verticalId: vertical.graphqlVerticalID,
        queryParams: {
          redirect_uri: queryParams.redirect_uri,
        },
      })
      if (updatedAccount) {
        actions.updatedAccount(updatedAccount)
      } else if (error) {
        console.log(error)
      }
    }

    // Affiliate optOut
    if (affiliateOptInResponse === ActionKeys.optOut) {
      updateUserLabelOverridesMutation({
        overrides: [
          {
            label: OverridableUserLabel.NeedsGsAffiliateOptIn,
            action: UserLabelOverrideAction.Exclude,
          },
        ],
      })
    }

    // Customer optOut
    if (custOptInResponse === ActionKeys.optOut) {
      updateUserLabelOverridesMutation({
        overrides: [
          {
            label: OverridableUserLabel.NeedsCustomerReferralOptIn,
            action: UserLabelOverrideAction.Exclude,
          },
        ],
      })
    }

    // Add sponsor to an orphan account
    if (account.isOrphan && selectedSponsorId) {
      const {
        updateAccount: { account: updatedAccount },
      } = await updateAccountMutation({
        account: {
          sponsor: selectedSponsorId,
        },
      })
      if (updatedAccount) {
        actions.updatedAccount(updatedAccount)
      }
    }
  }

  const completionMessage = 'Setup Complete!'

  const isMissingTaxForm =
    account.labels.includes(Label.AffiliateMissingW8) ||
    account.labels.includes(Label.AffiliateMissingW9)

  const allSteps: Step[] = [
    { available: account.isQualifiedForFirstFreeCard, skippable: true },
    {
      available: account.labels.includes(Label.NeedsCustomerReferralOptIn),
    },
    {
      available: account.labels.includes(Label.NeedsGsAffiliateOptIn),
    },
    { available: isMissingTaxForm },
    { available: isInPromptingsGracePeriod, skippable: true },
    {
      available: account.isOrphan,
      skippable: true,
      onBackAction: () => {
        setSelectedSponsorId(undefined)
      },
    },
  ]

  const availableSteps = allSteps.filter(step => step.available)
  const skippableIndexes = availableSteps.map((step, sIndex) => {
    if (step.skippable) return sIndex
    return null
  })

  const shouldSkippable = skippableIndexes.includes(activeItemIndex)

  const children = compact(
    account.isQualifiedForFirstFreeCard && (
      <FreeCardNotification
        onComplete={() => {}}
        dismissModal={() => setIsSheetOpen(false)}
        order={1}
      />
    ),
    account.labels.includes(Label.NeedsCustomerReferralOptIn) &&
      account.type !== 'C' && (
        <ProgramOptIn
          message="customerReferralOptInMessage"
          onComplete={() => setCanContinue(true)}
          setResponse={setCustOptInResponse}
          order={2}
        />
      ),
    account.labels.includes(Label.NeedsGsAffiliateOptIn) && (
      <ProgramOptIn
        message="greenerStillAffiliateOptInMessage"
        onComplete={() => setCanContinue(true)}
        setResponse={setAffiliateOptInResponse}
        order={3}
      />
    ),
    isMissingTaxForm && (
      <NonActionStep
        content={ContentType.TaxInfo}
        onComplete={() => setCanContinue(true)}
        order={4}
      />
    ),
    isInPromptingsGracePeriod && (
      <GracePeriodNotification
        onComplete={() => {}}
        dismissModal={() => setIsSheetOpen(false)}
        order={5}
      />
    ),
    account.isOrphan && (
      <OrphanStep
        onComplete={sponsorId => {
          setSelectedSponsorId(sponsorId)
          setCanContinue(true)
        }}
        order={6}
      />
    ),
  )

  if (children.length === 0 || !hasBeen24Hours(lastActionsReviewTimestamp)) {
    return null
  }

  const onBack = (step: number) => {
    availableSteps[step].onBackAction?.()
  }

  return (
    <Sheet isOpen={isSheetOpen} zIndex={500}>
      <Stepper
        canContinue={canContinue}
        setCanContinue={setCanContinue}
        activeItemIndex={activeItemIndex}
        setActiveItemIndex={step => {
          if (activeItemIndex > step) {
            onBack(step)
          }
          setActiveItemIndex(step)
        }}
        onStepsComplete={onStepsComplete}
        completionMessage={completionMessage}
        isSkippable={shouldSkippable}
        skipButton={{ title: 'Later', type: 'primary' }}
      >
        {children}
      </Stepper>
    </Sheet>
  )
}

export default ProgressiveProfileActions
