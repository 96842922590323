import { Operation } from '../../../__generated__/globalTypes'

import {
  MarketingParagraphFragment,
  marketingParagraphFragmentString,
} from '../../fragments/__generated__/MarketingParagraphFragment'

import {
  MarketingMultiParagraphFragment,
  marketingMultiParagraphFragmentString,
} from '../../fragments/__generated__/MarketingMultiParagraphFragment'

import getAccountCreatedContentsRawString from '../GetAccountCreatedContents.graphql'

const getAccountCreatedContentsString = [
  getAccountCreatedContentsRawString,
  marketingParagraphFragmentString,
  marketingMultiParagraphFragmentString,
].join('\n\n')

export type GetAccountCreatedContentsData = {
  accountCreatedTitle: MarketingParagraphFragment
  accountCreatedBody: MarketingMultiParagraphFragment
}

export type GetAccountCreatedContents = Operation<GetAccountCreatedContentsData>

export const GetAccountCreatedContents = (): GetAccountCreatedContents => ({
  query: getAccountCreatedContentsString,
  variables: undefined,
})
