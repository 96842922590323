import { PriceFragment, isPriceFragment } from './PriceFragment'

import {
  LabeledPriceFragment,
  isLabeledPriceFragment,
} from './LabeledPriceFragment'

import itemizedCostFragmentRawString from '../ItemizedCostFragment.graphql'

export const itemizedCostFragmentString = itemizedCostFragmentRawString

export type ItemizedCostFragment = {
  total: PriceFragment[]
  entries: LabeledPriceFragment[]
}

export const isItemizedCostFragment = (
  fragment: any,
): fragment is ItemizedCostFragment =>
  fragment &&
  Array.isArray(fragment.total) &&
  fragment.total
    .slice(0, 5)
    .reduce(
      (accum: any, next: any) => accum && (isPriceFragment(next) as boolean),
      true,
    ) &&
  Array.isArray(fragment.entries) &&
  fragment.entries
    .slice(0, 5)
    .reduce(
      (accum: any, next: any) =>
        accum && (isLabeledPriceFragment(next) as boolean),
      true,
    )
