import { Button } from '@sendoutcards/quantum-design-ui'
import React from 'react'

import { FontFragment } from 'src/graphql/generated/graphql'

type SignatureListProps = {
  className: string
  onClick: (signatureId: string) => void
  signatures: FontFragment[]
  isAndroidChrome: boolean
  hasSignature: boolean
  onRemoveSignature: () => void
}

const SignatureList: React.FC<SignatureListProps> = props => {
  const {
    className,
    onClick,
    signatures,
    isAndroidChrome,
    hasSignature,
    onRemoveSignature,
  } = props
  return (
    <div className={className}>
      <ul>
        {signatures.length > 0 ? (
          <>
            {signatures.map((signature, index) => (
              <li
                key={`signature_${index}`}
                style={
                  {
                    listStyleType: 'none',
                    fontFamily: signature.fontName,
                    fontSize: '40px',
                    cursor: 'pointer',
                  } as React.CSSProperties
                }
                onClick={() => {
                  onClick(signature.id)
                }}
              >
                æ
              </li>
            ))}
            {isAndroidChrome && hasSignature && (
              <li
                onClick={onRemoveSignature}
                style={{ listStyle: 'none', marginTop: '10px' }}
              >
                <Button
                  type="primary"
                  onClick={onRemoveSignature}
                  fullWidth={true}
                >
                  Remove Signature
                </Button>
              </li>
            )}
          </>
        ) : (
          <li>You don't have any signatures.</li>
        )}
      </ul>
    </div>
  )
}

export default SignatureList
