import React from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
// @src imports
import { LEGACY_SITE_URL } from 'src/app/api'
import { Icon, NavLink, ProfileAvatar, Transition } from 'src/chrome'
import { BottomNav, defaultItems } from 'src/chrome/BottomNav/BottomNav'
import suspenseBoundary from 'src/chrome/SuspenseBoundary/suspenseBoundaryHOC'
import { FlexColumn } from 'src/styled'
import { OrdersRoute } from 'src/orders/routes/OrdersRoute'
import {
  useAccount,
  useActions,
  useRoute,
  useSelector,
  useState,
} from 'src/hooks'
// relative imports

import theme from 'src/chrome/styles/Chrome.module.scss'
import { ConfirmDialog, Text } from '@sendoutcards/quantum-design-ui'
import { AddOrderGiftRoute } from 'src/orders/routes/AddOrderGiftRoute'

const linkTitle: React.CSSProperties = {
  position: 'relative',
  top: 27,
  fontSize: 11,
  color: '#646464',
}

const SideBar: React.FC = () => {
  const account = useAccount()

  const { height, width } = useSelector(state => state.window)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [showConfirmVisible, setShowConfirmVisible] = useState(false)
  const [buttonClicked, setButtonClicked] = useState('')
  const actions = useActions()
  const route = useRoute()
  const { order } = useSelector(state => state.orders)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const performNavigation = (buttonId: string) => {
    switch (buttonId) {
      case 'goto_catalog_btn':
        actions.openCatalog()
        break
      case 'goto_pricing_btn':
        actions.openPricing()
        break
      case 'goto_campaigns_btn':
        actions.openCampaigns()
        break
      case 'goto_contacts_btn':
        actions.openRelationshipManager()
        break
      case 'goto_gift_store_btn':
        route.subroute.path === OrdersRoute.path && order?.id
          ? actions.openOrder(order.id, AddOrderGiftRoute())
          : actions.openGiftStore()
        break
      case 'goto_account_btn':
        actions.openAccount()
        break
      case 'goto_notifications_btn':
        actions.openNotifications()
        break
      case 'goto_payment_settings_btn':
        actions.openPaymentSettings()
        break
      case 'goto_card_history_btn':
        actions.openCardHistory()
        break
      case 'goto_drafted_orders_btn':
        actions.openDraftedCards()
        break
      case 'goto_drafted_cards_btn':
        actions.openDraftedCards()
        break
      case 'logout_btn':
        actions.logout()
        break
    }
  }

  const clickNavButton = (buttonId: string) => {
    const shouldShowDialog: boolean = route.subroute.path === OrdersRoute.path

    if (shouldShowDialog) {
      setButtonClicked(buttonId)
      setShowConfirmVisible(true)
      return
    }
    performNavigation(buttonId)
  }

  return (
    <nav className={theme.sideBar}>
      {width > 756 ? (
        height > 880 ? (
          <div className={theme.fullHeight}>
            <NavLink
              id="goto_catalog_btn"
              className="active"
              onClick={() => clickNavButton('goto_catalog_btn')}
            >
              <Icon icon={'SOCLOGO'} size={32} />
              <span style={linkTitle}>Catalog</span>
            </NavLink>
            <NavLink
              id={'goto_campaigns_btn'}
              className="active"
              onClick={() => clickNavButton('goto_campaigns_btn')}
            >
              <Icon icon={'CLIPBOARD'} />
              <span style={linkTitle}>Campaigns</span>
            </NavLink>
            <NavLink
              id={'goto_contacts_btn'}
              className="active"
              onClick={() => clickNavButton('goto_contacts_btn')}
            >
              <Icon icon={'CONTACT'} />
              <span style={linkTitle}>Contacts</span>
            </NavLink>
            <NavLink
              id={'goto_gift_store_btn'}
              className="active"
              onClick={() => performNavigation('goto_gift_store_btn')}
            >
              <Icon icon={'TAG'} />
              <span style={linkTitle}>Gifts</span>
            </NavLink>
            <NavLink
              id={'goto_pricing_btn'}
              className="active"
              onClick={() => performNavigation('goto_pricing_btn')}
            >
              <Icon icon={'DOLLARSIGN'} />
              <span style={linkTitle}>Pricing</span>
            </NavLink>
            <FlexColumn style={{ justifyContent: 'center', marginTop: 40 }}>
              <button
                id="avatar_btn"
                aria-controls="drop-down-menu"
                aria-haspopup="true"
                onClick={handleClick}
                style={{ border: 'none', background: 'none' }}
              >
                <ProfileAvatar
                  account={account}
                  size={32}
                  style={{
                    zIndex: 300,
                    fontSize: '1.1rem',
                    color: '#fff',
                    letterSpacing: 1,
                  }}
                  isEditable={false}
                />
              </button>
              <Menu
                id="drop-down-menu"
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
                keepMounted={false}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  id="goto_account_btn"
                  onClick={() => clickNavButton('goto_account_btn')}
                  style={{ fontSize: 'initial', padding: '3px 16px' }}
                >
                  <Text type="body">{`Account #${account.genealogyId}`}</Text>
                </MenuItem>
                {!account.isNewUser && (
                  <MenuItem
                    onClick={() =>
                      (window.location.href = `${LEGACY_SITE_URL}/main-menu/?access_token=${account.token}`)
                    }
                    style={{ fontSize: 'initial', padding: '3px 16px' }}
                  >
                    <Text type="body">{'Legacy Site'}</Text>
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() =>
                    (window.location.href = `${LEGACY_SITE_URL}/resources/`)
                  }
                  style={{ fontSize: 'initial', padding: '3px 16px' }}
                >
                  <Text type="body">{'Resource Center'}</Text>
                </MenuItem>
                <MenuItem
                  id="logout_btn"
                  onClick={() => clickNavButton('logout_btn')}
                  style={{ fontSize: 'initial', padding: '3px 16px' }}
                >
                  <Text type="body">{'Logout'}</Text>
                </MenuItem>
              </Menu>
              <span
                style={{ ...linkTitle, textAlign: 'center', marginTop: -15 }}
              >
                Account
              </span>
            </FlexColumn>
          </div>
        ) : (
          <BottomNav items={defaultItems} isSidebarEnabled={true} />
        )
      ) : (
        <div className={theme.mobile}>
          <BottomNav items={defaultItems} />
        </div>
      )}
      {showConfirmVisible && (
        <ConfirmDialog
          title="You are leaving the order checkout page."
          description="Your card is saved in your Account under Drafted Orders."
          primaryAction="decline"
          isOpen={showConfirmVisible}
          accept={{
            title: 'Continue and navigate away.',
            onClick: () => {
              performNavigation(buttonClicked)
              actions.clearOrder()
              setShowConfirmVisible(false)
            },
          }}
          decline={{
            title: 'Cancel and stay on this page.',
            onClick: () => setShowConfirmVisible(false),
          }}
          onClose={() => {
            setShowConfirmVisible(false)
          }}
        />
      )}
    </nav>
  )
}

export default suspenseBoundary({
  component: SideBar,
  unresolved: <Transition message={'Loading sidebar...'} />,
  failure: error => `Failed loading sidebar: ${error}`,
})
