import React from 'react'
import { CreateContactInput } from 'src/legacy_graphql'

import { CompanyShenanigans } from '../../types'
import { Div, Flex, Input, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import styles from './contactGroupsForm.module.scss'

type Props<T extends CreateContactInput> = {
  contact: T
  onChange: (update: Partial<T>) => void
  fields: object
  isCompanyVisible?: boolean
}

const NameForm = <T extends CreateContactInput & CompanyShenanigans>(
  props: Props<T>,
) => {
  const {
    contact: { firstName, lastName, companyName, company },
    onChange,
    isCompanyVisible = true,
  } = props

  const handleChange = <K extends keyof T>(key: K, value: T[K]) =>
    onChange(({ [key]: value } as unknown) as Partial<T>)

  const inputStyle = styles.input

  return (
    <>
      <Spacer space="x2" />
      <Flex columnGap="x2" rowGap="x2" flexWrap="wrap">
        <Div className={inputStyle}>
          <Text type="caption">First</Text>
          <Input
            type="text"
            value={firstName ? firstName : ''}
            onChange={value => handleChange('firstName', value)}
            placeholder="First Name"
            name="first_name_field"
            maxLength={50}
          />
        </Div>
        <Div className={inputStyle}>
          <Text type="caption">Last</Text>
          <Input
            type="text"
            value={lastName ? lastName : ''}
            onChange={value => handleChange('lastName', value)}
            placeholder="Last Name"
            name="last_name_field"
            maxLength={50}
          />
        </Div>
        {isCompanyVisible && (
          <Div className={inputStyle}>
            <Text type="caption">Company</Text>
            <Input
              type="text"
              value={(companyName ?? company) || ''}
              onChange={value => {
                handleChange('companyName', value)
                handleChange('company', value)
              }}
              placeholder="Company Name"
              name="company_name_field"
              maxLength={50}
            />
          </Div>
        )}
      </Flex>
    </>
  )
}

export default NameForm
