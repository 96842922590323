import React from 'react'

import get from 'lodash/get'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'

import styles from './editCard.module.scss'
import { usePersistedUserData, useState } from 'src/hooks'
import { Button, Div, Icon, Spacer } from '@sendoutcards/quantum-design-ui'
import { Portal } from 'src/portal/portal'
import {
  EmailValidationStep,
  VerifyClient,
} from 'src/dashboard/components/VerifyClient'
import { ValidationError } from 'src/email_verification/VerifyEmail/ValidationFailureEmailVerification'
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
  Token,
} from '@stripe/stripe-js'
import { useValidateEmailVerification } from 'src/react_query'

interface StripeCardFormProps {
  onSuccess: (token: Token) => void
  onError: (msg: string) => void
  isSubmitButtonVisible: boolean
  isAddingCard: boolean
  shouldValidateEmail?: boolean
}

const StripeCardForm: React.FC<StripeCardFormProps> = props => {
  const {
    onError,
    onSuccess,
    children,
    isAddingCard,
    isSubmitButtonVisible,
    shouldValidateEmail = false,
  } = props

  const [isComplete, setIsComplete] = useState(false)
  const [shouldVerifyEmail, setShouldVerifyEmail] = useState(false)
  const [validationFailure, setValidationFailure] = useState<ValidationError>()
  const [event, setEvent] = useState<React.FormEvent<HTMLFormElement>>()

  const validateEmailMutation = useValidateEmailVerification()
  const { account } = usePersistedUserData()
  const stripe = useStripe()
  const elements = useElements()

  const onSubmit = async (
    formEvent: React.FormEvent<HTMLFormElement>,
    validationCode?: string,
  ) => {
    if (formEvent) {
      formEvent.preventDefault()
    }

    if (!stripe || !elements || !account) {
      return
    }

    if (shouldValidateEmail) {
      if (!validationCode) {
        setEvent(formEvent)
        setShouldVerifyEmail(true)
        return
      } else {
        setShouldVerifyEmail(false)
      }

      try {
        const {
          validateEmailVerificationCode,
        } = await validateEmailMutation.mutateAsync({
          email: account.email,
          code: validationCode ?? '',
        })

        if (
          validateEmailVerificationCode &&
          validateEmailVerificationCode.__typename ===
            'ValidateEmailVerificationCodeFailure'
        ) {
          setValidationFailure({
            type: validateEmailVerificationCode.failureType,
            message: validateEmailVerificationCode.message,
          })
          setShouldVerifyEmail(true)
          return
        }
      } catch (error) {
        console.log('Something went wrong: ', error)
        return
      }
    }

    const cardNumberElement = elements.getElement(CardNumberElement)

    if (cardNumberElement) {
      const { error, token } = await stripe.createToken(cardNumberElement)

      if (error) {
        const message = get(error, 'message', 'There has been a payment error.')
        return onError(message)
      }

      if (token) {
        return onSuccess(token)
      }
    }
  }

  const handleOnChange = (
    event:
      | StripeCardNumberElementChangeEvent
      | StripeCardExpiryElementChangeEvent
      | StripeCardCvcElementChangeEvent,
  ) => setIsComplete(event.complete)

  const stripeElementWrapperProps = {
    borderRadius: '12px',
    backgroundColor: 'background',
    maxHeight: '50px',
    inset: '0.5rem',
    outset: { bottom: '0.5rem' },
  }
  const stripeElementWithIconsProps = {
    style: {
      display: 'grid',
      gridTemplateColumns: '12px auto',
      columnGap: '12px',
      alignItems: 'center',
      width: '90px',
    },
  }
  const stripeElementProps = {
    onChange: handleOnChange,
    style: {
      base: {
        fontFamily: "'Montserrat',sans-serif",
        fontWeight: '500',
        color: '#6f8394',
        lineHeight: '24px',
      },
    },
  }

  return (
    <form id={'addCardForm'} onSubmit={onSubmit}>
      {shouldVerifyEmail && (
        <Portal>
          <VerifyClient
            onClose={() => {
              setShouldVerifyEmail(false)
            }}
            email={account?.email ?? ''}
            confirm={{
              onSubmit: async code => {
                if (event) {
                  onSubmit(event, code)
                }
              },
            }}
            initialStep={
              validationFailure
                ? EmailValidationStep.ValidationError
                : undefined
            }
            validationFailure={validationFailure}
          />
        </Portal>
      )}
      <Div>
        <Div {...stripeElementWrapperProps}>
          <CardNumberElement
            onChange={stripeElementProps.onChange}
            options={{
              showIcon: true,
              placeholder: 'Credit Card Number',
              style: stripeElementProps.style,
            }}
          />
        </Div>
        <Div
          display="flex"
          columnGap="0.5rem"
          css={{
            '@media (max-width: 480px)': { justifyContent: 'space-between' },
          }}
        >
          <Div {...stripeElementWrapperProps} {...stripeElementWithIconsProps}>
            <Icon
              name="campaigns"
              size="xSmall"
              primaryColor="primaryBodyText"
            />
            <CardExpiryElement
              onChange={stripeElementProps.onChange}
              options={{
                style: stripeElementProps.style,
                placeholder: 'EXP',
              }}
            />
          </Div>
          <Div {...stripeElementWrapperProps} {...stripeElementWithIconsProps}>
            <Icon name="lock" size="xSmall" primaryColor="primaryBodyText" />
            <CardCvcElement
              onChange={stripeElementProps.onChange}
              options={{
                style: stripeElementProps.style,
                placeholder: 'CVC',
              }}
            />
          </Div>
        </Div>
      </Div>
      {children}
      {!children && isSubmitButtonVisible && (
        <>
          <Spacer space="x2_5" />
          <Button
            type={'primary'}
            title={isAddingCard ? 'Adding Card...' : 'Add Card'}
            id={'add_cc_btn'}
            disabled={isAddingCard || !isComplete}
            onClick="submitForm"
          />
        </>
      )}
    </form>
  )
}

interface Props {
  onSuccess: (token: Token) => void
  onError: (error: string) => void
  isSubmitButtonVisible: boolean
  isAddingCard: boolean
  shouldValidateEmail?: boolean
}

const EditCard: React.FC<Props> = props => {
  const {
    isAddingCard,
    children,
    onError,
    onSuccess,
    isSubmitButtonVisible,
    shouldValidateEmail,
  } = props

  return (
    <div className={styles.walletContents}>
      <div className={styles.payment}>
        <StripeCardForm
          onSuccess={onSuccess}
          onError={onError}
          isSubmitButtonVisible={isSubmitButtonVisible}
          isAddingCard={isAddingCard}
          shouldValidateEmail={shouldValidateEmail}
        >
          {children}
        </StripeCardForm>
      </div>
    </div>
  )
}

export default EditCard
