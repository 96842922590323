import React from 'react'

import { Button, Dialog } from 'src/chrome'

import styles from './createOrderModal.module.scss'
import DialogContent from '@material-ui/core/DialogContent'

interface Props {
  onClose: () => void
  isOpen: boolean
  onSubmit: () => void
  title: string
}

const FormModal: React.FC<Props> = props => {
  const { children, onClose, isOpen, title, onSubmit } = props

  return (
    <Dialog open={isOpen} onClose={onClose} scroll={'paper'}>
      <DialogContent className={styles.formContainer}>
        <div className={styles.delayForm}>
          <form
            onSubmit={event => {
              event.preventDefault()
              onSubmit()
            }}
            className={styles.formContainer}
          >
            <div className={`${styles.flex} ${styles.modalHeader}`}>
              <h6>{title}</h6>
            </div>
            <div className={styles.formFields} style={{ padding: '20px 0' }}>
              {children}
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                title={'Cancel'}
                type={'button'}
                onClick={onClose}
                style={{ marginRight: '15px' }}
              />
              <Button type={'submit'} title={'Submit'} buttonColor={'pink'} />
            </div>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default FormModal
