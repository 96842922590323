import { Interpolation } from '@emotion/css'

const primary = '#f568b8'

const arrow: Interpolation = {
  position: 'absolute',
  cursor: 'pointer',
  left: '10px',
  fontSize: '32px',
  lineHeight: '1',
  top: '16px',
  width: '30px',
  textAlign: 'center',
  display: 'inline-block',
  color: 'transparentize(#fff, 0.6)',
  userSelect: 'none',
  '&:hover': {
    color: '#fff',
  },
}

const styles = {
  heading: {
    margin: '-15px -15px 15px',
    padding: '0 15px',
    backgroundColor: primary,
    color: '#fff',
    height: 70,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  } as Interpolation,
  leftArrow: arrow,
  rightArrow: {
    ...arrow,
    left: 'auto',
    right: 10,
  },
  title: {
    position: 'relative',
    textAlign: 'center',
    cursor: 'pointer',
    fontWeight: 400,
    fontSize: 30,
    lineHeight: 66,
    userSelect: 'none',
    marginBottom: 0,
    '> small': {
      fontWeight: 300,
      fontSize: '60%',
    },
  } as Interpolation,
  select: {
    appearance: 'none',
    border: 'none',
    background: primary,
    textAlign: 'right',
    direction: 'rtl',
    fontSize: 22,
    fontWeight: 250,
    color: '#fff',
    width: 'fit-content',
    cursor: 'pointer',
    '> option': {
      fontSize: 12,
    },
  } as Interpolation,
}

export default styles
