import React from 'react'
import Switch from '@material-ui/core/Switch'
// @src imports

import { PlanPaymentInfo } from 'src/payments/containers/Payment'
import { AccountFragment } from 'src/graphql/generated/graphql'
import { Button } from 'src/chrome'
import {
  BuyButton,
  ExpenseReloadForm,
  PaymentMethod,
  PlanContext,
  ShippingAddress,
} from 'src/payments/components'
import { useState } from 'src/hooks'
// relative imports
import styles from '../../payments/containers/styles'
import { Spacer, Text } from '@sendoutcards/quantum-design-ui'

export interface ExpenseReload {
  purchaseAmount: number
  threshold: number
}

type Props = {
  account: AccountFragment
  paymentInfo: PlanPaymentInfo
  onCancel: () => void
  onPurchase: (expenseReload: ExpenseReload | undefined) => void
}

const Payment: React.FC<Props> = props => {
  const { paymentInfo, account, onCancel, onPurchase } = props

  const isExpenseReloadRequired =
    paymentInfo.items?.find(item => item.isExpenseReloadRequired) !== undefined

  const [isExpenseReloadEnabled, setIsExpenseReloadEnabled] = useState(false)

  const [expenseReload, setExpenseReload] = useState<ExpenseReload>({
    purchaseAmount: 10,
    threshold: 100,
  })

  const showExpenseReload = isExpenseReloadRequired || isExpenseReloadEnabled

  return (
    <div style={{ position: 'relative' }}>
      <div css={styles.paymentWrapper} style={{ padding: '0 25px' }}>
        <div css={styles.accountColumn}>
          <ShippingAddress
            account={account}
            isEditable={true}
            title={'Return Address'}
          />

          <div css={styles.sections}>
            <div css={styles.sectionTitle}>
              <Text type="body">Payment Method</Text>
            </div>

            <PaymentMethod
              account={account}
              paymentInfo={paymentInfo}
              onError={error => console.error(error)}
              isMixedOrder={false}
              canUseCredits={false}
              isUsingCredits={false}
              setIsUsingCredits={(value: boolean) => {}}
            />
          </div>
        </div>

        <div css={styles.orderColumn}>
          <PlanContext plans={paymentInfo.items}>
            <>
              <Spacer space="x5" />
              <Text type="caption" inset={{ left: 'x1' }}>
                {expenseReload
                  ? 'Configure your Expense Bucket'
                  : 'Add an Expense Bucket'}
              </Text>
              {!isExpenseReloadRequired && (
                <Switch
                  checked={isExpenseReloadEnabled}
                  onChange={() => setIsExpenseReloadEnabled(x => !x)}
                />
              )}
              {showExpenseReload && (
                <>
                  <ExpenseReloadForm
                    expenseReload={{
                      ...expenseReload,
                      isEnabled: true,
                      isRequired: false,
                      __typename: 'ExpenseReload',
                    }}
                    onChange={(name, value) =>
                      setExpenseReload(x => ({ ...x, [name]: value }))
                    }
                  />
                  <Spacer space="x3" />
                  <Text type="footnote" inset="x2">
                    {`*We will charge $${expenseReload.purchaseAmount} of expense to your card today`}
                  </Text>
                </>
              )}
            </>
          </PlanContext>
        </div>

        <div css={styles.paymentAction}>
          <Button onClick={onCancel} style={{ width: 126 }} title="Cancel" />

          <BuyButton
            account={account}
            onPurchase={() =>
              onPurchase(showExpenseReload ? expenseReload : undefined)
            }
            order={undefined}
            purchaseType={paymentInfo.purchaseType}
          />
        </div>
      </div>
    </div>
  )
}

export default Payment
