import React from 'react'
import {
  AnimatedCheckMark,
  Flex,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { VerifyPhoneNumberSuccessFragment } from 'src/graphql/generated/graphql'

const TimedSuccess = (props: {
  success: VerifyPhoneNumberSuccessFragment
  onCompletion: (success: VerifyPhoneNumberSuccessFragment) => void
}) => {
  const { success, onCompletion } = props

  setTimeout(() => {
    onCompletion(success)
  }, 2000)

  return (
    <Flex flexDirection="column" alignItems="center" justifyContent="center">
      <Text
        type="title"
        color="primaryHeading"
        alignment="center"
        weight="bold"
      >
        Phone Number Verified Successfully
      </Text>
      <Spacer space="x6" />
      <AnimatedCheckMark size="xLarge" filled={true} />
      <Spacer space="x6" />
      <Text type="largeBody" alignment="center">
        Returning to checkout...
      </Text>
    </Flex>
  )
}

export default TimedSuccess
