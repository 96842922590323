import React from 'react'
import uniqBy from 'lodash/uniqBy'
import orderBy from 'lodash/orderBy'
import { Set } from 'immutable'
import Checkbox from '@material-ui/core/Checkbox'

import { drawerState } from 'src/orders/drawerState'
import {
  DefaultError,
  Icon,
  Pagination,
  RwdCell,
  RwdHeader,
  RwdRow,
  RwdTable,
  Search,
  Transition,
} from 'src/chrome'
import { AddressFooter, AddressFormModal } from 'src/contacts/components'
import {
  CreateContactInput,
  getAllGroups,
  getContactsIds,
  getGroup,
  getPaginatedContacts,
  getPaginatedGroups,
} from 'src/legacy_graphql'
import {
  useActions,
  useCallback,
  useEffect,
  useMemo,
  useMutations,
  useQueries,
  useRef,
  useSelector,
  useState,
} from 'src/hooks'

import styles from './addressBook.module.scss'
import uuid from 'src/utils/uuid'
import suspenseBoundary from 'src/chrome/SuspenseBoundary/suspenseBoundaryHOC'
import { Div, Text } from '@sendoutcards/quantum-design-ui'

const PAGE_SIZE = 20

const AddressBook: React.FC<{}> = () => {
  const mutations = useMutations()
  const orders = useSelector(state => state.orders)
  const actions = useActions()
  const [context, setContext] = useState<'contacts' | 'groups'>('contacts')
  const addressRef = useRef<HTMLDivElement>(null)
  const [search, setSearch] = useState('')
  const [contactPage, setContactPage] = useState(1)
  const [groupPage, setGroupPage] = useState(1)
  const [selectedContactIds, setSelectedContactIds] = useState(
    Set(orders.order?.contacts.map(contact => contact.id) ?? []),
  )
  const [selectedGroupIds, setSelectedGroupSet] = useState(Set<string>())
  const [activeGroupId, setActiveGroupId] = useState<string>()
  const selectedGroupArray = Array.from(selectedGroupIds)

  const [
    contactsIds,
    allGroups,
    activeGroup,
    { results: contacts, count: contactsCount },
    { results: groups, count: groupsCount },
    ...detailedGroups
  ] = useQueries(
    getContactsIds({ search, showSecondaryContacts: true }),
    getAllGroups(),
    activeGroupId !== undefined ? getGroup({ id: activeGroupId }) : undefined,
    getPaginatedContacts({
      search,
      page: contactPage,
      showSecondaryContacts: true,
    }),
    getPaginatedGroups({
      search,
      page: groupPage,
    }),
    ...selectedGroupArray.map(id => getGroup({ id })),
  )

  const isEveryContactSelected = useMemo(
    () => selectedContactIds.count() === contactsCount,
    [selectedContactIds, contactsCount],
  )

  const toggleAllContacts = useCallback(() => {
    setSelectedContactIds(
      isEveryContactSelected
        ? Set()
        : selectedContactIds.union(
            Set.of(...contactsIds.map(contact => contact.id)),
          ),
    )
  }, [selectedContactIds, contactsIds, isEveryContactSelected])

  const toggleContact = useCallback((id: string) => {
    setSelectedContactIds(x => (x.has(id) ? x.delete(id) : x.add(id)))
  }, [])

  const toggleGroup = useCallback((id: string) => {
    setSelectedGroupSet(x => (x.has(id) ? x.delete(id) : x.add(id)))
  }, [])

  const selectedContacts = useMemo(
    () => [...selectedContactIds.values()].map(id => ({ id })),
    [selectedContactIds],
  )
  const selectedGroups = useMemo(
    () => allGroups.filter(group => selectedGroupIds.has(group.id)),
    [allGroups, selectedGroupIds],
  )

  const handleAddToOrder = useCallback(() => {
    // tslint:disable-next-line: no-non-null-assertion
    document.querySelector('body')!.style.overflowY = 'initial'

    const groupMembers = detailedGroups.flatMap(group => group.contacts)

    const sortedContacts = orderBy(
      uniqBy([...selectedContacts, ...groupMembers], 'id'),
      ['lastName'],
      ['asc'],
    ).map(contact => ({
      ...contact,
      __typename: 'Contact' as const,
    }))

    actions.updateRecipients(sortedContacts, [])
    actions.closeDrawer()
    setSearch('')
  }, [selectedContacts, actions, detailedGroups])

  const createContact = (contact: CreateContactInput) => {
    const id = contact.id ?? uuid()
    mutations.createContact({
      contact: {
        ...contact,
        id,
      },
    })
    setSelectedContactIds(selectedContactIds.add(id))
    actions.openAddressDrawer()
  }

  useEffect(() => {
    setContactPage(1)
    setGroupPage(1)
  }, [search])

  return (
    <div
      className={`${styles.addressBook} ${styles.addressPadding}`}
      tabIndex={-1}
      ref={addressRef}
    >
      <div
        className={styles.addressSearchContainer}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Search
          id="search_bar"
          value={search}
          hint={`Search ${context === 'groups' ? 'Groups' : 'Contacts'}`}
          className={styles.addressSearch}
          style={{ margin: '35px 12px 35px 0' }}
          autocomplete={'off'}
          onSearch={setSearch}
          onClear={() => setSearch('')}
        />
        <button
          id={context === 'contacts' ? 'open_groups_btn' : 'open_contacts_btn'}
          onClick={() =>
            setContext(context === 'contacts' ? 'groups' : 'contacts')
          }
          style={{
            border: 'none',
            padding: 5,
            borderRadius: 5,
            background: '#e8e8e8',
            cursor: 'pointer',
          }}
        >
          <Icon icon={'FUNNEL'} size={12} />
          <Div display="inline-block">
            <Text
              type="footnote"
              color="primaryHeading"
              outset={{ left: 'x_5' }}
            >
              {context === 'contacts' ? 'View Groups' : 'View Contacts'}
            </Text>
          </Div>
        </button>
      </div>
      {context === 'contacts' && (
        <>
          {contacts.length > 0 && (
            <div>
              <RwdTable>
                <RwdHeader>
                  <th
                    onClick={toggleAllContacts}
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                  >
                    <Text type="body" color="primaryBrand">
                      {isEveryContactSelected ? 'Deselect All' : 'Select All'}
                    </Text>
                  </th>
                  <th>
                    <Text type="body" color="primaryBrand">
                      Name
                    </Text>
                  </th>
                  <th>
                    <Text type="body" color="primaryBrand">
                      Company Name
                    </Text>
                  </th>
                  <th>
                    <Text type="body" color="primaryBrand">
                      Address
                    </Text>
                  </th>
                </RwdHeader>
                <tbody>
                  {contacts.map((contact, index) => (
                    <React.Fragment key={contact.id}>
                      <RwdRow>
                        <RwdCell mapToHeader="Select">
                          <Checkbox
                            id={`select_recipient_btn_${index}`}
                            name={contact.lastName}
                            checked={selectedContactIds.has(contact.id)}
                            onChange={e => toggleContact(contact.id)}
                            size="small"
                          />
                        </RwdCell>
                        <RwdCell mapToHeader="Name">
                          <Text type="body" color="primaryHeading">
                            {`${contact.firstName} ${contact.lastName}`}
                          </Text>
                        </RwdCell>
                        <RwdCell mapToHeader="Company Name">
                          <Text
                            type="body"
                            color="primaryHeading"
                            content={contact.company}
                          />
                        </RwdCell>
                        <RwdCell mapToHeader="Address">
                          <Text type="body" color="primaryHeading">
                            {`${contact.address1} ${contact.address2} ${contact.city}, ${contact.state} ${contact.postalCode}`}
                          </Text>
                        </RwdCell>
                      </RwdRow>
                      <tr className={styles.tableSpacer} />
                    </React.Fragment>
                  ))}
                </tbody>
              </RwdTable>
            </div>
          )}
          {contactsCount === 0 && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Text type="largeBody" color="primaryHeading" alignment="center">
                No matching contacts found
              </Text>
            </div>
          )}
        </>
      )}
      {context === 'groups' && (
        <>
          {groups.length > 0 && (
            <RwdTable>
              <RwdHeader>
                <th>
                  <Text type="body" color="primaryBrand">
                    Select
                  </Text>
                </th>
                <th>
                  <Text type="body" color="primaryBrand">
                    Group Name
                  </Text>
                </th>
                <th>
                  <Text type="body" color="primaryBrand">
                    Action
                  </Text>
                </th>
              </RwdHeader>
              <tbody>
                {groups.map(group => {
                  return (
                    <React.Fragment key={group.id.toString()}>
                      <RwdRow>
                        <RwdCell mapToHeader="Select">
                          <Checkbox
                            id={
                              group.name === 'test'
                                ? 'select_group_btn'
                                : undefined
                            }
                            name={group.name}
                            checked={selectedGroupIds.has(group.id)}
                            onChange={() => toggleGroup(group.id)}
                            size="small"
                          />
                        </RwdCell>
                        <RwdCell mapToHeader="Group">
                          <Text type="body" color="primaryHeading">
                            {group.name}
                          </Text>
                        </RwdCell>
                        <RwdCell mapToHeader="Action">
                          <button
                            style={{
                              border: 'none',
                              padding: 5,
                              borderRadius: 5,
                              background: '#e8e8e8',
                              cursor: 'pointer',
                            }}
                            onClick={() => setActiveGroupId(group.id)}
                          >
                            <Text type="caption" color="primaryHeading">
                              View Members
                            </Text>
                          </button>
                        </RwdCell>
                      </RwdRow>
                      {activeGroupId === group.id &&
                        (activeGroup && activeGroup.id === group.id ? (
                          <RwdRow>
                            <RwdCell mapToHeader="" colSpan={3}>
                              <RwdTable>
                                <tbody
                                  className={styles.addressDisplay}
                                  key={'Group Contacts ' + group.id}
                                >
                                  {activeGroup.contacts.map(contact => (
                                    <RwdRow key={'Group Contact ' + contact.id}>
                                      <RwdCell mapToHeader="Name">
                                        <Text
                                          type="body"
                                          color="primaryHeading"
                                        >
                                          {`${contact.firstName} ${contact.lastName}`}
                                        </Text>
                                      </RwdCell>
                                      <RwdCell mapToHeader="Address">
                                        <Text
                                          type="body"
                                          color="primaryHeading"
                                        >
                                          {`${contact.address1} ...`}
                                        </Text>
                                      </RwdCell>
                                    </RwdRow>
                                  ))}
                                </tbody>
                              </RwdTable>
                            </RwdCell>
                          </RwdRow>
                        ) : (
                          <RwdRow>
                            <RwdCell mapToHeader="" colSpan={3}>
                              <Text type="caption" alignment="center">
                                Loading group members...
                              </Text>
                            </RwdCell>
                          </RwdRow>
                        ))}
                      <tr className={styles.tableSpacer} />
                    </React.Fragment>
                  )
                })}
              </tbody>
            </RwdTable>
          )}
          {groupsCount === 0 && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Text type="largeBody" color="primaryHeading" alignment="center">
                No matching groups found
              </Text>
            </div>
          )}
        </>
      )}
      {orders.drawerState === drawerState.createAddress && (
        <AddressFormModal
          close={actions.openAddressDrawer}
          onSubmit={contact => createContact({ ...contact, id: uuid() })}
        />
      )}
      <Pagination
        onPageChange={context === 'groups' ? setGroupPage : setContactPage}
        count={(context === 'groups' ? groupsCount : contactsCount) ?? 0}
        pagesToShow={5}
        pageSize={PAGE_SIZE}
        currentPage={context === 'groups' ? groupPage : contactPage}
      />
      <AddressFooter
        contacts={selectedContacts}
        groups={selectedGroups}
        onAttach={handleAddToOrder}
      />
    </div>
  )
}

export default suspenseBoundary({
  component: AddressBook,
  unresolved: <Transition message={'Loading Addresses...'} />,
  failure: DefaultError,
})
