import { Maybe, NewReminderType } from '../../../__generated__/globalTypes'

import { ContactFragment, isContactFragment } from './ContactFragment'

import { CampaignFragment, isCampaignFragment } from './CampaignFragment'

import reminderFragmentRawString from '../ReminderFragment.graphql'

export const reminderFragmentString = reminderFragmentRawString

export type ReminderFragment = {
  __typename: 'Reminder'
  id: string
  eventDate: Maybe<string>
  expireDate: Maybe<string>
  title: string
  message: Maybe<string>
  contact: ContactFragment
  campaign: Maybe<CampaignFragment>
  newType: NewReminderType
}

export const isReminderFragment = (
  fragment: any,
): fragment is ReminderFragment =>
  fragment &&
  fragment.__typename === 'Reminder' &&
  typeof fragment.id === 'string' &&
  (fragment.eventDate === null || typeof fragment.eventDate === 'string') &&
  (fragment.expireDate === null || typeof fragment.expireDate === 'string') &&
  typeof fragment.title === 'string' &&
  (fragment.message === null || typeof fragment.message === 'string') &&
  (isContactFragment(fragment.contact) as boolean) &&
  (fragment.campaign === null ||
    (isCampaignFragment(fragment.campaign) as boolean)) &&
  (fragment.newType === 'ANNIVERSARY' ||
    fragment.newType === 'BIRTHDAY' ||
    fragment.newType === 'CAMPAIGN')
