import React from 'react'

import styles from './blinker.module.scss'
import { useEffect, useRef, useState } from 'src/hooks'
import { Text } from '@sendoutcards/quantum-design-ui'

interface BlinkerProps {
  message: string
  messagePosition: React.CSSProperties
  style?: React.CSSProperties
}

const Blinker: React.FC<BlinkerProps> = props => {
  const { message, messagePosition, style } = props
  const [shouldShowMessage, setShouldShowMessage] = useState(true)
  const blinker = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (blinker.current) {
      handleScrollToElement(blinker)
    }
  }, [])

  const handleScrollToElement = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      window.scrollTo(0, ref.current.offsetTop)
    }
  }

  const handleToggleMessage = () => setShouldShowMessage(m => !m)

  return (
    <div
      className={styles.blinkerContainer}
      ref={blinker}
      onClick={handleToggleMessage}
      style={shouldShowMessage ? style : { ...style, display: 'none' }}
    >
      {shouldShowMessage && (
        <div
          className={styles.blinkerMessage}
          style={messagePosition}
          onClick={handleToggleMessage}
        >
          <div className={styles.message}>
            <Text type="caption" color="accent" alignment="center">
              {message}
            </Text>
          </div>
        </div>
      )}
      <div
        className={styles.blinker}
        style={{
          width: 48,
          height: 48,
        }}
        onClick={handleToggleMessage}
      >
        <div className={styles.circle} />
        <div className={styles.circle} />
      </div>
    </div>
  )
}

export default Blinker
