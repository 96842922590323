import React, { ReactElement } from 'react'
import { Icon } from 'src/chrome'
import Badge from '@material-ui/core/Badge'

import { IconTypeOrIconString } from 'src/app/types'
import styles from './cardOptions.module.scss'
import { Div, Spacer, Text } from '@sendoutcards/quantum-design-ui'

import { useSelector } from 'src/hooks'
import { CardPaperType, CardType } from 'src/graphql/generated/graphql'

export type CardOption<Value> = {
  value: Value
  label: string
  gradient?: string
  icon?: IconTypeOrIconString
  iconSize?: number
  viewBox?: string
  upgrade?: string
  canBeBulk?: boolean
}

type CardOptionsProps<Value> = {
  selectedOption: Value
  options: CardOption<Value>[]
  optionTitle: string
  selectOption: (value: Value) => void
}

const CardOptions = <Value extends {}>(
  props: CardOptionsProps<Value>,
): ReactElement => {
  const { optionTitle, options, selectOption, selectedOption } = props

  const isMobile = useSelector(state => state.window.isMobile)

  return (
    <div className={styles.CardOptionsToggle}>
      <Div display="inline-block" inset={{ horizontal: 'x_5' }}>
        <Text type="body" color="primaryHeading">{`${optionTitle}`}</Text>
      </Div>
      <Spacer space="x3" />
      <div className={styles.cardConfigOptions}>
        <div className={styles.overScroll}>
          {options.map(option => {
            const isActive = option.value === selectedOption
            const optionGradient = {
              backgroundImage: option.gradient,
            }
            return (
              <Div
                width={isMobile ? '100%' : undefined}
                id={`${option.value}_btn`}
                className={
                  selectedOption === option.value
                    ? `${styles.CardOptions} ${styles.activeOption}`
                    : styles.CardOptions
                }
                onClick={() => selectOption(option.value)}
                key={option.value.toString()}
              >
                <div className={styles.CardOptionsOption}>
                  <div className={styles.optionImageWrapper}>
                    <div>
                      {option.icon && (
                        <Icon
                          icon={option.icon}
                          color={
                            option.value === selectedOption ? '#fff' : '#6F8394'
                          }
                          size={option.iconSize ? option.iconSize : 23}
                          viewBox={option.viewBox && option.viewBox}
                        />
                      )}
                      {option.gradient && <div style={optionGradient} />}
                    </div>
                  </div>
                  <Text
                    type="caption"
                    color={isActive ? 'accent' : 'primaryHeading'}
                    weight={isActive ? 'bold' : 'regular'}
                  >
                    {option.label}
                  </Text>
                </div>
                {option.upgrade && (
                  <Badge
                    badgeContent={option.upgrade}
                    color="secondary"
                    overlap="circle"
                    children={null}
                    classes={{
                      badge: styles.CardBadge,
                    }}
                  />
                )}
              </Div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export const CardTypeOptions: React.FC<CardOptionsProps<CardType>> = props =>
  CardOptions(props)

export const PaperTypeOptions: React.FC<CardOptionsProps<
  CardPaperType
>> = props => CardOptions(props)
