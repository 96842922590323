import React from 'react'

import { Icon } from 'src/chrome'
import { useCallback } from 'src/hooks'

import styles from './search.module.scss'
import { Memoized } from 'src/hooks/dependencies'

interface SearchProps {
  id?: string
  className?: string
  onSearch: Memoized<(searchTerm: string) => void>
  autocomplete?: 'on' | 'off'
}

const InstantSearch: React.FC<SearchProps> = props => {
  const { id, className, onSearch, autocomplete } = props

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => onSearch(e.target.value),
    [onSearch],
  )

  return (
    <div className={`${styles.searchField} ${className}`}>
      <div className={styles.search}>
        <Icon icon={'SEARCH'} size={18} color={'#ff5689'} />
        <div className={styles.selectedTag}>Search:</div>
        <input
          id={id}
          type="text"
          onChange={handleSearch}
          className={styles.searchInput}
          autoComplete={autocomplete}
        />
      </div>
    </div>
  )
}

export default InstantSearch
