import {
  Dialog,
  Div,
  FeatureBadge,
  Flex,
  Icon,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import React from 'react'

interface WindowWidthNotSupportedProps {
  isOpen: boolean
  title: string
  subtitle: string
  closeEditor: () => void
}

const WindowWidthNotSupported: React.FC<WindowWidthNotSupportedProps> = ({
  isOpen,
  title,
  subtitle,
  closeEditor,
}) => {
  return (
    <Dialog isOpen={isOpen}>
      <Flex flexDirection="column" alignItems="center">
        <Flex justifyContent="center">
          <Icon name="imageSwap" size={50} primaryColor="primaryBodyText" />
        </Flex>
        <Spacer space="x5" />
        <Text
          type="subtitle"
          alignment="center"
          color="primaryHeading"
          weight="bold"
        >
          {title}
        </Text>
        <Spacer space="x1" />
        <Text type="footnote" alignment="center" color="primaryBody">
          {subtitle}
        </Text>
        <Spacer space="x5" />
        <Div>
          <FeatureBadge
            title="Back to Catalog"
            icon="tenDoor"
            onClick={() => closeEditor()}
            backgroundColor="danger"
          />
        </Div>
      </Flex>
    </Dialog>
  )
}

export default WindowWidthNotSupported
