import React from 'react'
import {
  Anchor,
  Button,
  ConfirmDialog,
  Div,
  Flex,
  IconLink,
  PhoneInput,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { getMarketingContent, PhoneNumberInput } from 'src/legacy_graphql'
import {
  useActions,
  useEffect,
  usePersistedUserData,
  useQueries,
  useQueryParams,
  useState,
} from 'src/hooks'
import { getFormattedNumber } from './PhoneNumberVerification'
import {
  createPhoneNumberInput,
  getValidatedNumber,
} from 'src/helpers/createPhoneNumberInput'
import { getMachineCountryCode } from 'src/helpers'
import usePhoneNumberCountries from 'src/hooks/usePhoneNumberCountries'
import { PhoneVerificationTerms } from 'src/dashboard/components/PhoneVerificationTerms'

const PhoneNumberEntry = (props: {
  requestVerificationCode: (phoneNumber: PhoneNumberInput) => void
  onVerificationRequested: (phoneNumber: PhoneNumberInput) => void
  phoneNumber?: PhoneNumberInput
  failureMessage?: string
  onDeclinePhoneNumber?: () => void
}) => {
  const {
    requestVerificationCode,
    failureMessage,
    onDeclinePhoneNumber,
  } = props
  const { account } = usePersistedUserData()
  const [isDialogOpen, setDialogIsOpen] = useState(false)

  const phoneNumberCountries = usePhoneNumberCountries()
  const [{ complianceMessage, whyMyPhoneNumberMessage }] = useQueries(
    getMarketingContent(),
  )

  const [selectedCountryIndex, setSelectedCountryIndex] = useState(
    phoneNumberCountries.findIndex(
      country =>
        country.isoCode === (props.phoneNumber?.countryIsoCode ?? 'US'),
    ),
  )
  const [phoneNumber, setPhoneNumber] = useState(
    props.phoneNumber?.nationalNumber ?? '',
  )
  const [isCountrySelectedManually, setIsCountrySelectedManually] = useState<
    boolean
  >(false)
  const selectedCountry = phoneNumberCountries[selectedCountryIndex]
  const selectedCountryCode = selectedCountry.isoCode
  const formattedNumber = getFormattedNumber(selectedCountryCode, phoneNumber)
  const validatedNumber = getValidatedNumber(selectedCountryCode, phoneNumber)
  const actions = useActions()
  const queryParams = useQueryParams()

  const onSubmit = async (event?: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault()

    if (!validatedNumber) {
      return
    }

    const phoneNumberInput = createPhoneNumberInput(
      phoneNumber,
      selectedCountry,
    )

    requestVerificationCode(phoneNumberInput)
  }

  useEffect(() => {
    const updateCountryCode = async () => {
      for await (const countryCode of getMachineCountryCode()) {
        const index = phoneNumberCountries.findIndex(country => {
          return country.isoCode === countryCode
        })
        if (index !== -1 && !isCountrySelectedManually) {
          setSelectedCountryIndex(index)
        }
      }
    }
    if (!isCountrySelectedManually) {
      updateCountryCode()
    }
  }, [phoneNumberCountries, isCountrySelectedManually])

  return (
    <form onSubmit={onSubmit} autoComplete="on">
      <Flex flexDirection="column">
        <Text type="title" weight="bold">
          {account
            ? "Let's verify your phone number"
            : 'Welcome to SendOutCards!'}
        </Text>
        <Spacer space="x2" />
        <Text type="caption">
          {account &&
          !account.isTrialAffiliate &&
          !account.isQualifiedForFirstFreeCard
            ? 'Verify your phone number so you can use it to log in in the future. Enter your number below to receive a text message with a verification code.'
            : 'We’re so glad you’re here! Enter your phone number below and we will send you a code that you can use to send a free card to anyone in the world.'}
        </Text>
        <Spacer space="x4" />
        <div style={{ textAlign: 'right' }}>
          <span
            style={{
              fontSize: '12px',
              color: 'silver',
            }}
            data-mktcontent="complianceMessage"
          >
            {complianceMessage.content}
          </span>
          <PhoneInput
            countries={phoneNumberCountries}
            selectedCountryIndex={selectedCountryIndex}
            onSelectedCountryIndexChange={index => {
              setIsCountrySelectedManually(true)
              setSelectedCountryIndex(index)
            }}
            phoneNumber={formattedNumber}
            onPhoneNumberChange={setPhoneNumber}
            maxHeight="125px"
            message={
              failureMessage
                ? {
                    type: 'danger',
                    content: failureMessage,
                  }
                : undefined
            }
          />
        </div>
        <Spacer space="x2" />
        <Div outset={failureMessage ? { top: 'x6' } : undefined}>
          <IconLink
            icon={'information'}
            title={'Why my phone number?'}
            fill="primaryBodyText"
            onClick={() => setDialogIsOpen(true)}
            size="small"
          />
        </Div>
        <Spacer space="x9" />
        <Flex flexDirection="column" alignItems="center">
          <Div width={'230px'} id="enter_phone_number_btn">
            <Button
              fullWidth={true}
              title={`${!formattedNumber ? 'Enter Phone Number' : 'Send Code'}`}
              onClick="submitForm"
              hover={true}
              disabled={phoneNumber === ''}
            />
          </Div>
          <Spacer space="x2" />
          {(!account || onDeclinePhoneNumber) && (
            <Anchor
              size={'small'}
              isDecorated={true}
              onClick={() => {
                onDeclinePhoneNumber ?? actions.closePhoneNumberVerification()
              }}
            >
              No thanks, I'll pay for my card
            </Anchor>
          )}
          {queryParams.postcardPromo && (
            <>
              <Spacer space="x2" />
              <Div width={'230px'} id="login-with-account-btn">
                <Button
                  fullWidth={true}
                  hover={true}
                  title="Log into existing account"
                  outlined={true}
                  onClick={() => {
                    window.location.href =
                      'https://app.sendoutcards.com/dashboard/account?client=soc&redirect_uri=https%3A%2F%2Fapp.sendoutcards.com%2Fcatalog'
                  }}
                  type="secondary"
                />
              </Div>
            </>
          )}
        </Flex>
        <PhoneVerificationTerms />
      </Flex>
      <ConfirmDialog
        isOpen={isDialogOpen}
        title={whyMyPhoneNumberMessage.title}
        description={whyMyPhoneNumberMessage.content}
        accept={{ title: 'Got it', onClick: () => setDialogIsOpen(false) }}
        primaryAction={'accept'}
        onClose={() => setDialogIsOpen(false)}
        data-mktcontent="whyMyPhoneNumberMessage"
      />
    </form>
  )
}

export default PhoneNumberEntry
