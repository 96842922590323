import React from 'react'
import {
  Anchor,
  Button,
  Div,
  Flex,
  Input,
  Separator,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
// @src imports
import { Fade } from 'src/chrome'
import { useState, useVertical } from 'src/hooks'
import { parseError } from 'src/utils/parseError'
import { useMagicLink } from 'src/react_query'

interface ForgotPasswordFormState {
  email: string
  isGettingLink: boolean
  errorMessage: string
  didSendEmailSuccessfully: boolean
}

const ForgotPasswordForm = (props: { toggleForgotPassword: () => void }) => {
  const { toggleForgotPassword } = props
  const { mutateAsync: send } = useMagicLink()
  const vertical = useVertical()
  const [values, setValues] = useState<ForgotPasswordFormState>({
    email: '',
    isGettingLink: false,
    errorMessage: '',
    didSendEmailSuccessfully: false,
  })
  const {
    email,
    isGettingLink,
    errorMessage,
    didSendEmailSuccessfully,
  } = values

  const paths = ['pricing', 'catalog', 'store']
  const currentUrl = window.location.href
  const redirectUri = paths.some(path => currentUrl.includes(path))
    ? currentUrl
    : 'https://app.sendoutcards.com/dashboard/account?resetPassword'

  const handleChange = (name: keyof ForgotPasswordFormState, value: string) => {
    setValues({ ...values, [name]: value })
  }

  const sendMagicLink: React.ReactEventHandler<HTMLFormElement> = async event => {
    event.preventDefault()
    if (values.email) {
      setValues({ ...values, isGettingLink: true })
      try {
        const {
          sendMagicLink: { message, success: didSendEmailSuccessfully },
        } = await send({
          email: values.email,
          verticalId: vertical.graphqlVerticalID,
          redirectUri,
        })
        setValues({
          ...values,
          errorMessage: message || '',
          didSendEmailSuccessfully,
          isGettingLink: false,
        })
      } catch (error) {
        setValues({
          ...values,
          errorMessage: parseError(error),
          isGettingLink: false,
        })
      }
    }
  }

  return (
    <>
      {!didSendEmailSuccessfully && (
        <Flex flexDirection="column">
          <Text type="title" weight="semiBold">
            Reset Your Password
          </Text>
          <Spacer space="x4" />
          <Text type="footnote">
            <p>Enter your username or email below.</p>
            <p>
              You will receive an email with a link where you can reset your
              password.
            </p>
          </Text>
          <Spacer space="x4" />
          <form onSubmit={sendMagicLink}>
            <Div width="100%">
              <Input
                onChange={email => handleChange('email', email)}
                type="text"
                name="email"
                value={email}
                icon="user"
                placeholder="Username or Email"
                isFullWidth={true}
              />
            </Div>
            <Spacer space="x4" />
            <Div width="100%">
              <Button
                hover={true}
                fullWidth={true}
                title={!isGettingLink ? 'Reset Password' : 'Sending...'}
                disabled={!email}
                type={'success'}
                onClick="submitForm"
              />
            </Div>
          </form>
        </Flex>
      )}
      <Fade
        fadeIn={1000}
        fadeOut={3000}
        isVisible={!isGettingLink && didSendEmailSuccessfully}
      >
        <Spacer space="x2" />
        <Flex flexDirection="column">
          <Text type="caption">
            A reset link has been sent to your email! If you do not receive the
            email within the next few minutes, please try again or contact our
            Customer Success department for assistance.
          </Text>
          <Flex flexDirection="column" width="100%" alignItems="center">
            <Anchor
              size="small"
              href={`mailto:support@sendoutcards.com`}
            >{`support@sendoutcards.com`}</Anchor>
            <Anchor
              size="small"
              href={`tel:(801) 463-3800`}
            >{`(801) 463-3800`}</Anchor>
          </Flex>
        </Flex>
      </Fade>
      <Fade
        fadeIn={1000}
        fadeOut={3000}
        isVisible={
          !isGettingLink && !didSendEmailSuccessfully && !!errorMessage
        }
      >
        <Spacer space="x2" />
        <Text type="caption">{`Sorry! ${errorMessage}`}</Text>
      </Fade>
      <Spacer space="x4" />
      <Separator orientation="horizontal" dashed={true} caption="Or" />
      <Spacer space="x4" />
      <Button
        hover={true}
        fullWidth={true}
        title="Return to Login"
        onClick={toggleForgotPassword}
        type="secondary"
        outlined={true}
      />
    </>
  )
}

export default ForgotPasswordForm
