import { Flex } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { DeleteIcon } from 'src/design_system/icons/deleteIcon'

type RemoveCardType = {
  title: string
  isMobile: boolean
  onClick?: () => void
  color: string
  size: number
  id?: string
}

export const RemoveButton: React.FC<RemoveCardType> = ({
  title,
  isMobile,
  onClick,
  color,
  size,
  id,
}) => {
  return (
    <Flex
      cursor="pointer"
      onClick={onClick}
      alignItems="center"
      inset={isMobile ? 'x1' : undefined}
      borderRadius="4px"
      backgroundColor="foreground"
      id={id}
      flexDirection={isMobile ? 'column' : 'row'}
      boxShadow={isMobile ? '0px 2px 4px 0px rgba(0,0,0,0.04)' : undefined}
    >
      <DeleteIcon size={isMobile ? 18 : size + 3} color={color} />
      {!isMobile && (
        <p
          style={{
            fontSize: `${size}px`,
            fontWeight: 'bold',
            fontFamily: 'Montserrat-Bold, Montserrat',
            color: color,
            textDecoration: 'underline',
            paddingLeft: '4px',
          }}
        >
          {title}
        </p>
      )}
    </Flex>
  )
}
