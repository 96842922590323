import { useLayoutEffect } from 'react'

const useIntercomeEffect = () => {
  useLayoutEffect(() => {
    if (!!window.Intercom) {
      window.Intercom('update', {
        hide_default_launcher: true,
        vertical_padding: 145,
      })
    }
  })
}

export default useIntercomeEffect
