import { Route } from 'src/routes/Route'
import { Subroute } from 'src/routes/Subroute'
import { CustomCardEditRoute } from './CustomCardEditRoute'

export const CardSubroute = Subroute(CustomCardEditRoute)
export type CardSubroute = Subroute<typeof CardSubroute>

export const CardRoute = Route({
  path: '/card/:cardId',
  init: (cardId: string, subroute?: CardSubroute) => ({ cardId, subroute }),
  fromPath: ({ cardId }) => (cardId ? { cardId } : undefined),
  subroute: CardSubroute,
  hideSidebar: true,
})
export type CardRoute = Route<typeof CardRoute>
