import { Div, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import {
  CampaignRibbon,
  ProductPreviewModal,
} from 'src/campaign_store/components'
import { Category, Item } from 'src/campaign_store/types'

const styles = {
  featuredItems: {
    background: '#fff',
    padding: '35px 50px 80px',
    h1: {
      width: '100%',
      fontSize: '1.5em',
    },
    '@media (max-width: 850px)': {
      padding: '80px 20px',
    },
  },
  blockText: {
    width: '100%',
    marginBottom: '25px',
    marginLeft: '18px',
    p: {
      color: '#6f8394',
    },
  },
}

type Props = {
  accountTools?: Category | null | undefined
  activeItem?: Item | null
  purchase: (item: Item) => void
  showProductPreview: (item: Item) => void
  closeProductPreview: () => void
}

const CampaignStoreAddOn: React.FC<Props> = props => {
  const {
    accountTools,
    activeItem,
    purchase,
    showProductPreview,
    closeProductPreview,
  } = props

  return (
    <Div css={styles.featuredItems}>
      <Div css={styles.blockText}>
        <Text type="subtitle">Campaign Store Add-ons:</Text>
        <Text type="caption">
          Here are a few tools that will help you take full advantage of the
          SendOutCards system.
        </Text>
      </Div>
      {accountTools && (
        <CampaignRibbon
          id={accountTools.id}
          key={accountTools.id}
          items={accountTools.items}
          isSeeAllButtonEnabled={accountTools.items.length >= 10}
          onItemClick={showProductPreview}
          onPurchase={purchase}
        />
      )}
      {activeItem && (
        <ProductPreviewModal
          product={activeItem}
          onClose={closeProductPreview}
          onPurchase={purchase}
        />
      )}
    </Div>
  )
}

export default CampaignStoreAddOn
