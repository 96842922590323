import { Maybe } from '../../../__generated__/globalTypes'

import { AddressFragment, isAddressFragment } from './AddressFragment'

import prospectFragmentRawString from '../ProspectFragment.graphql'

export const prospectFragmentString = prospectFragmentRawString

export type ProspectFragment = {
  __typename: 'Prospect'
  id: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  address: Maybe<AddressFragment>
  ordersSent: number
}

export const isProspectFragment = (
  fragment: any,
): fragment is ProspectFragment =>
  fragment &&
  fragment.__typename === 'Prospect' &&
  typeof fragment.id === 'string' &&
  typeof fragment.firstName === 'string' &&
  typeof fragment.lastName === 'string' &&
  typeof fragment.email === 'string' &&
  typeof fragment.phoneNumber === 'string' &&
  (fragment.address === null ||
    (isAddressFragment(fragment.address) as boolean)) &&
  typeof fragment.ordersSent === 'number'
