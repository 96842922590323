import { CardPaperType, CurrencyType } from 'src/graphql/generated/graphql'

type BulkPromo = {
  bulkCardCost: number
  paperType: CardPaperType
}

const getTotal = (
  cost: {
    total: Array<{ asString: string; amount: number; currency?: string }>
  },
  bulkPromo?: BulkPromo,
  multiplier: number = 1,
): string => {
  if (!cost) {
    return '$0.00'
  }
  if (bulkPromo) {
    const extraCost = [CardPaperType.Hvy, CardPaperType.Pre].includes(
      bulkPromo.paperType,
    )
      ? '+ $0.50 (card upgrade)'
      : ''
    return `$${bulkPromo.bulkCardCost} ${extraCost}`
  }
  return cost.total
    .map(x => {
      if (multiplier) {
        if (x.currency === CurrencyType.Point) {
          return x.asString.replace(
            String(x.amount),
            String(x.amount * multiplier),
          )
        } else {
          return x.asString.replace(
            (x.amount / 100).toFixed(2),
            ((x.amount / 100) * multiplier).toFixed(2),
          )
        }
      }
      return x.asString
    })
    .join(' + ')
}

export default getTotal
