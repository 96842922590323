import { Operation } from '../../../__generated__/globalTypes'

import {
  ContactShareFragment,
  contactShareFragmentString,
} from '../../fragments/__generated__/ContactShareFragment'

import { optionalYearDateFragmentString } from '../../fragments/__generated__/OptionalYearDateFragment'

import { contactFragmentString } from '../../fragments/__generated__/ContactFragment'

import getSharedContactsRawString from '../GetSharedContacts.graphql'

const getSharedContactsString = [
  getSharedContactsRawString,
  contactShareFragmentString,
  optionalYearDateFragmentString,
  contactFragmentString,
].join('\n\n')

export type GetSharedContactsData = {
  contactShares: ContactShareFragment[]
}

export type GetSharedContactsVariables = {
  offset: number
  limit: number
}

export type GetSharedContacts = Operation<
  GetSharedContactsData,
  GetSharedContactsVariables
>

export const GetSharedContacts = ({
  offset,
  limit,
}: GetSharedContactsVariables): GetSharedContacts => ({
  query: getSharedContactsString,
  variables: {
    offset,
    limit,
  },
})
