import React from 'react'
import { Flex } from '@sendoutcards/quantum-design-ui'

import { useCallback } from 'src/hooks'
import { EmailPreferences } from 'src/dashboard/components'
import { useUpdateAccountSettings } from 'src/react_query'
import { AccountSettingsInput } from 'src/graphql/generated/graphql'

export const DashboardSettings = () => {
  const updateAccountSettingsMutation = useUpdateAccountSettings()
  const handleUpdateSetting = useCallback(
    async <
      SettingName extends keyof Omit<AccountSettingsInput, 'defaultBackPanelId'>
    >(
      settingName: SettingName,
      value: boolean | number,
    ) => {
      updateAccountSettingsMutation.mutate({
        [settingName]: value,
      })
    },
    [updateAccountSettingsMutation],
  )

  return (
    <Flex flexDirection={'column'} width="100%">
      <EmailPreferences onToggle={handleUpdateSetting} />
    </Flex>
  )
}
