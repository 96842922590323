import {
  Maybe,
  CardType,
  CardPaperType,
} from '../../../__generated__/globalTypes'

import {
  MinimalPanelFragment,
  isMinimalPanelFragment,
} from './MinimalPanelFragment'

import {
  GiftVariationFragment,
  isGiftVariationFragment,
} from './GiftVariationFragment'

import { SendDelayFragment, isSendDelayFragment } from './SendDelayFragment'

import campaignLineFragmentRawString from '../CampaignLineFragment.graphql'

export const campaignLineFragmentString = campaignLineFragmentRawString

export type CampaignLineFragment = {
  __typename: 'CampaignLine'
  name: Maybe<string>
  card: Maybe<{
    __typename: 'Card'
    id: string
    type: CardType
    isHorizontal: boolean
    isNewEditorCard: boolean
    paperType: CardPaperType
    tinyThumb: string
    frontPreviewUrl: string
    insidePreviewUrl: Maybe<string>
    flapPreviewUrl: Maybe<string>
    backPreviewUrl: string
    sendableCard: Maybe<{
      __typename: 'SendableCard'
      id: string
      title: string
    }>
    minimalPanels: MinimalPanelFragment[]
  }>
  giftVariation: Maybe<GiftVariationFragment>
  sendDelay: SendDelayFragment
}

export const isCampaignLineFragment = (
  fragment: any,
): fragment is CampaignLineFragment =>
  fragment &&
  fragment.__typename === 'CampaignLine' &&
  (fragment.name === null || typeof fragment.name === 'string') &&
  (fragment.card === null ||
    (fragment.card &&
      fragment.card.__typename === 'Card' &&
      typeof fragment.card.id === 'string' &&
      (fragment.card.type === 'POSTCARD' ||
        fragment.card.type === 'FLATCARD' ||
        fragment.card.type === 'TWO_PANEL' ||
        fragment.card.type === 'THREE_PANEL' ||
        fragment.card.type === 'TWO_PANEL_BIG') &&
      typeof fragment.card.isHorizontal === 'boolean' &&
      typeof fragment.card.isNewEditorCard === 'boolean' &&
      (fragment.card.paperType === 'STD' ||
        fragment.card.paperType === 'PRE' ||
        fragment.card.paperType === 'HVY') &&
      typeof fragment.card.tinyThumb === 'string' &&
      typeof fragment.card.frontPreviewUrl === 'string' &&
      (fragment.card.insidePreviewUrl === null ||
        typeof fragment.card.insidePreviewUrl === 'string') &&
      (fragment.card.flapPreviewUrl === null ||
        typeof fragment.card.flapPreviewUrl === 'string') &&
      typeof fragment.card.backPreviewUrl === 'string' &&
      (fragment.card.sendableCard === null ||
        (fragment.card.sendableCard &&
          fragment.card.sendableCard.__typename === 'SendableCard' &&
          typeof fragment.card.sendableCard.id === 'string' &&
          typeof fragment.card.sendableCard.title === 'string')) &&
      Array.isArray(fragment.card.minimalPanels) &&
      fragment.card.minimalPanels
        .slice(0, 5)
        .reduce(
          (accum: any, next: any) =>
            accum && (isMinimalPanelFragment(next) as boolean),
          true,
        ))) &&
  (fragment.giftVariation === null ||
    (isGiftVariationFragment(fragment.giftVariation) as boolean)) &&
  (isSendDelayFragment(fragment.sendDelay) as boolean)
