import Action from 'src/redux/action'
import { editedCampaign, editingCampaign } from 'src/redux/actions/campaigns'
import {
  CampaignContext,
  ContextState,
  NoContext,
  PricingContext,
} from 'src/context/types'
import { checkoutOpenState } from 'src/redux/actions/pricing'

const context = (
  context: ContextState = NoContext(),
  action: Action,
): ContextState => {
  switch (action.type) {
    case editingCampaign.type:
      return CampaignContext(action.campaign, action.line)
    case editedCampaign.type:
      return NoContext()
    case checkoutOpenState.type:
      return PricingContext(action.isCheckoutOpen)
    default:
      return context
  }
}

export default context
