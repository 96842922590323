import { CurrencyType } from 'src/graphql/generated/graphql'
import { compact, formatCost } from 'src/helpers'

const sumCurrencyTypes = (
  entries: {
    currency: CurrencyType
    amount: number
  }[],
  multiplier = 1,
) => {
  type Types = {
    [K in CurrencyType]: number | undefined
  }
  const types: {
    [K in CurrencyType]: number | undefined
  } = entries
    .map(entry => ({
      ...entry,
      currency:
        entry.currency === CurrencyType.Expense
          ? CurrencyType.Usd
          : entry.currency,
    }))
    .reduce(
      (types: Types, entry) => ({
        ...types,
        [entry.currency]:
          (types[entry.currency] ?? 0) + entry.amount * multiplier,
      }),
      {} as Types,
    )
  return compact(
    types.FREE && 'FREE',
    types.POINT && `${types.POINT} points`,
    types.USD && `${formatCost(types.USD)}`,
  ).join(' + ')
}

export default sumCurrencyTypes
