import React from 'react'
import {
  useSelector,
  useState,
  useSubscriptions,
  useUserLabels,
  useVertical,
} from 'src/hooks'
import {
  Anchor,
  Button,
  ButtonType,
  Div,
  Flex,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { perform } from 'src/app/api'
import {
  GetPromptingsAcademyLoginUrl,
  GetPromptingsAcademyLoginUrlData,
} from 'src/legacy_graphql'
import { UiCard } from 'src/chrome'

interface StreamLinksCardProps {
  isAffiliate: boolean
  isSRP: boolean
}

const websiteLinks = [
  { title: 'www.promptings.com', href: 'https://www.promptings.com/' },
  {
    title: 'www.promptingsacademy.com',
    href: 'https://www.promptingsacademy.com/',
  },

  { title: 'www.sendoutcards.com', href: 'https://www.sendoutcards.com/' },
]

const subscriptionLinks = [
  {
    title: 'SendOutCards',
    domainName: 'SendOutCards',
    brand: 'soc',
    type: 'secondary',
  },
  {
    title: 'Promptings Way Courses',
    domainName: 'PromptingsAcademy',
    brand: 'promptingsAcademy',
    type: 'secondary',
  },
  {
    title: 'Stream Virtual Assistant',
    domainName: 'streamvirtualassistant',
    brand: 'streamVA',
    type: 'secondary',
  },
]

const getLinkDestination = async (domainName: string): Promise<string> => {
  switch (domainName) {
    case 'PromptingsAcademy':
      const { account } = await perform<GetPromptingsAcademyLoginUrlData>(
        GetPromptingsAcademyLoginUrl(),
      )
      return account.promptinguLoginUrl ?? 'https://promptingsacademy.com'
    default:
      return `https://www.${domainName}.com/`
  }
}

const StreamLinksCard: React.FC<StreamLinksCardProps> = () => {
  const { hasStreamVASubscription, hasSocSubscription } = useSubscriptions()
  const windowWidth = useSelector(state => state.window.width)
  const userLabels = useUserLabels()
  const vertical = useVertical()
  const [isRedirecting, setIsRedirecting] = useState(false)

  const renderSubscriptionButtons = () =>
    subscriptionLinks.map(link => {
      if (link.brand === vertical.brand) {
        return null
      }

      const isDisabled =
        isRedirecting ||
        (link.domainName === 'SendOutCards' && !hasSocSubscription) ||
        (link.domainName === 'PromptingsAcademy' &&
          !userLabels.hasRelationshipMarketing) ||
        (link.domainName === 'streamvirtualassistant' &&
          !hasStreamVASubscription)

      return (
        <Div
          key={link.brand}
          inset={{
            horizontal: 'x1',
            vertical: windowWidth <= 588 ? 'x_5' : 'x0',
          }}
        >
          <Button
            title={link.title}
            size="small"
            onClick={async () => {
              setIsRedirecting(true)
              const url = await getLinkDestination(link.domainName)
              window.open(url, '_blank')
              setIsRedirecting(false)
            }}
            disabled={isDisabled}
            disabledCaption={
              isRedirecting
                ? 'redirecting...'
                : 'You must be a paid subscriber to use this Stream.'
            }
            type={link.type as ButtonType}
          />
        </Div>
      )
    })

  return (
    <UiCard style={{ width: '100%' }} title="Stream Links">
      <Flex flexDirection="column">
        <Section title="Website Links:" links={websiteLinks} />
        <Section
          title="Subscription Links:"
          customContent={renderSubscriptionButtons()}
        />
      </Flex>
    </UiCard>
  )
}

const Section: React.FC<{
  title: string
  links?: typeof websiteLinks
  customContent?: React.ReactNode
}> = ({ title, links, customContent }) => (
  <Flex flexDirection="column" inset={{ vertical: 'x1' }}>
    <Text
      type="body"
      color="primaryHeading"
      outset={{ top: 'x1' }}
      weight="semiBold"
      content={title}
    />
    <Flex flexWrap="wrap">
      {links
        ? links.map((link, index) => (
            <Div key={index} inset={{ horizontal: 'x1' }}>
              <Anchor
                target="_blank"
                href={link.href}
                title={link.title}
                color="anchorBlue"
                isDecorated={true}
              />
            </Div>
          ))
        : customContent}
    </Flex>
  </Flex>
)

export default StreamLinksCard
