const styles = {
  paymentModal: {
    maxWidth: 'initial !important',
    '@media (max-width: 1075px)': {
      width: '90% !important',
    },
    '@media (max-width: 850px)': {
      width: '100% !important',
    },
  },
  sectionTitle: {
    borderBottom: '1px solid #333' as const,
    paddingBottom: 10,
    marginBottom: 10,
    h5: {
      fontFamily: 'Dosis',
      color: 'rgba(0, 0, 0, 0.68)',
      fontWeight: 500,
      textTransform: 'uppercase' as const,
      fontSize: '16px',
      letterSpacing: '0.85px',
    },
  },

  paymentWrapper: {
    display: 'flex',
    flexWrap: 'wrap' as const,
  },
  accountColumn: {
    width: 'calc(50% - 25px)',
    paddingRight: 25,
    display: 'flex' as const,
    flexDirection: 'column' as const,
    '@media (max-width: 715px)': {
      width: '100%',
      padding: 0,
      paddingTop: 0,
      paddingBottom: 15,
    },
  },
  orderColumn: {
    width: 'calc(50% - 25px)',
    paddingLeft: 25,
    '@media (max-width: 715px)': {
      width: '100%',
      padding: 0,
      paddingBottom: 25,
    },
  },
  paymentAction: {
    display: 'grid',
    width: '100%',
    paddingTop: '1rem',
    gridTemplateColumns: 'auto max-content min-content',
    zIndex: 10,
    '@media (max-width: 715px)': {
      padding: 15,
      position: 'fixed' as const,
      bottom: 0,
      left: 0,
      background: '#fff',
      borderTop: '0.5px solid rgba(0, 0, 0, 0.25)',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  sections: {
    marginTop: 10,
  },

  paymentTitle: {
    fontSize: '3.2rem',
  },
}

export default styles
