import { Maybe, Operation } from '../../../__generated__/globalTypes'

import {
  MinimalContactFragment,
  minimalContactFragmentString,
} from '../../fragments/__generated__/MinimalContactFragment'

import getPaginatedMinimalContactsRawString from '../GetPaginatedMinimalContacts.graphql'

const getPaginatedMinimalContactsString = [
  getPaginatedMinimalContactsRawString,
  minimalContactFragmentString,
].join('\n\n')

export type GetPaginatedMinimalContactsData = {
  paginatedContacts: {
    contacts: MinimalContactFragment[]
    count: number
  }
}

export type GetPaginatedMinimalContactsVariables = {
  search?: Maybe<string>
  showSecondaryContacts?: Maybe<boolean>
  contacts?: Maybe<string[]>
  offset: number
  limit: number
}

export type GetPaginatedMinimalContacts = Operation<
  GetPaginatedMinimalContactsData,
  GetPaginatedMinimalContactsVariables
>

export const GetPaginatedMinimalContacts = ({
  search,
  showSecondaryContacts,
  contacts,
  offset,
  limit,
}: GetPaginatedMinimalContactsVariables): GetPaginatedMinimalContacts => ({
  query: getPaginatedMinimalContactsString,
  variables: {
    search,
    showSecondaryContacts,
    contacts,
    offset,
    limit,
  },
})
