import React from 'react'
import orderBy from 'lodash/orderBy'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'

import { SearchFilter } from '../types'
import { Icon, Text } from '@sendoutcards/quantum-design-ui'
import { useState } from 'src/hooks'

type Props = {
  filters: SearchFilter[]
  highlightIndex: number | undefined
  onSelect: (filter: SearchFilter) => void
  onClick: () => void
}

const FilterList: React.FC<Props> = props => {
  const { filters, highlightIndex, onSelect, onClick } = props
  const [hoverStates, setHoverStates] = useState(
    new Array(filters.length).fill(false),
  )
  const [, setSelectedIndex] = useState(0)

  const handleMouseEnter = (index: number) => {
    setHoverStates(prevStates => {
      const updatedStates = [...prevStates].map((_hoverState, i) => index === i)
      return updatedStates
    })
  }
  const handleMouseLeave = (index: number) => {
    setHoverStates(prevStates => {
      const updatedStates = [...prevStates].map((_hoverState, i) => index === i)
      return updatedStates
    })
  }

  return (
    <div
      style={{
        maxHeight: 300,
        overflow: 'auto',
        width: '100%',
        borderRadius: '16px',
      }}
      /* onMouseDown / onTouchStart is necessary to capture the click before onBlur
         hides the content of this element */
      onMouseDown={onClick}
      onTouchStart={onClick}
    >
      <List>
        {orderBy(filters, 'name').map((filter, index) => {
          return (
            /* onMouseDown / onTouchStart is necessary to capture the click before onBlur
             hides the content of this element */
            <div
              id={`filter_chip_${filter.name.split(' ')[0].toLowerCase()}`}
              key={filter.name}
              onMouseDown={() => onSelect(filter)}
              onTouchStart={() => onSelect(filter)}
            >
              <ListItem
                data-index={index}
                style={{
                  cursor: 'pointer',
                  background: index === highlightIndex ? '#eee' : '',
                  backgroundColor: hoverStates[index] ? '#f5f6f7' : '#fff',
                  transition: 'background-color 0.3s',
                }}
                onClick={() => onSelect(filter)}
                onSelect={() => onSelect(filter)}
                onMouseEnter={() => {
                  handleMouseEnter(index)
                  setSelectedIndex(index)
                }}
                onMouseLeave={() => handleMouseLeave(index)}
              >
                {filter.icon && (
                  <ListItemIcon>
                    <Icon
                      name={filter.icon.svg}
                      primaryColor={filter.icon.color ?? ''}
                      size={filter.icon.size ?? ''}
                    />
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={
                    <Text type="footnote" color="primaryHeading">
                      {filter.name}
                    </Text>
                  }
                />
              </ListItem>
            </div>
          )
        })}
      </List>
    </div>
  )
}

export default FilterList
