import React from 'react'
import { Icon, Icons, IconType } from 'src/chrome'

import { TextAlignment } from 'src/graphql/generated/graphql'

import styles from './textAlignment.module.scss'

type TextAlignmentProps = {
  textAlign?: TextAlignment
  selectTextAlign: (textAlign: TextAlignment) => void
}

type AlignProps = {
  value: TextAlignment
  icon: IconType | Icons
} & TextAlignmentProps

const Align: React.FC<AlignProps> = props => {
  const { icon, selectTextAlign, textAlign, value } = props
  return (
    <div onClick={() => selectTextAlign(value)}>
      <Icon
        icon={icon}
        viewBox={'0 0 46 32'}
        color={value === textAlign ? '#E841B3' : '#6f8394'}
        size={23}
      />
    </div>
  )
}

const TextAlignmentComponent = (props: {
  textAlign?: TextAlignment
  selectTextAlign: (textAlign: TextAlignment) => void
}) => (
  <div className={styles.alignmentControls}>
    <Align value={TextAlignment.Left} icon={'ALIGNLEFT'} {...props} />
    <Align value={TextAlignment.Center} icon={'ALIGNCENTER'} {...props} />
    <Align value={TextAlignment.Right} icon={'ALIGNRIGHT'} {...props} />
  </div>
)

export default TextAlignmentComponent
