export const offeredAffiliateStripeSkus = [
  { id: 'GS00001' }, // Monthly affiliate
  { id: 'GS00012' }, // Yearly affiliate
  { id: 'GS00016' }, // Trial affiliate (90day)
  { id: '14757' }, // Legacy SOC affiliate
]
export const basicPlanId = { id: '1' }

export const offeredSubscriptionPlanIds = {
  monthly: { id: '2' },
  monthlyPlus: { id: '161' },
  monthlyPremium: { id: '6' },
  monthlyPremiumPlus: { id: '156' },
}

export const offeredAffiliatePlanIds = {
  annualAffiliate: { id: '165' },
  monthlyAffiliate: { id: '112' },
  legacySocAffiliate: { id: '114' },
  trialAffiliate: { id: '113' },
}

export const defaultSubscriptionIds = [
  offeredSubscriptionPlanIds.monthly,
  offeredSubscriptionPlanIds.monthlyPlus,
]
export const premiereSubscriptionIds = [
  offeredSubscriptionPlanIds.monthlyPremium,
  offeredSubscriptionPlanIds.monthlyPremiumPlus,
]
export const affiliateUpgradePlanIds = [
  offeredAffiliatePlanIds.annualAffiliate,
  offeredAffiliatePlanIds.monthlyAffiliate,
]
// Saleor Variant and Bundle Items
export const affiliateTrialAffiliateAddonVariantId = 'UHJvZHVjdFZhcmlhbnQ6NTEy'
export const gratitudeAffiliateBundleVariantIds = [
  'UHJvZHVjdFZhcmlhbnQ6NTA1', // 99 Yearly Affiliate
  'UHJvZHVjdFZhcmlhbnQ6MTk1', // 37 monthly sub +
]
