import { ResetPasswordRoute } from './routes/ResetPassword'

type ResetPasswordProps = {
  route?: ResetPasswordRoute
}

const ResetPassword: React.FC<ResetPasswordProps> = props => {
  return (
    <div>
      <p> Welcome to the Reset Password page</p>
    </div>
  )
}

export default ResetPassword
