import planDescriptionFragmentRawString from '../PlanDescriptionFragment.graphql'

export const planDescriptionFragmentString = planDescriptionFragmentRawString

export type PlanDescriptionFragment = {
  __typename: 'PlansDescription'
  id: string
  description: string
}

export const isPlanDescriptionFragment = (
  fragment: any,
): fragment is PlanDescriptionFragment =>
  fragment &&
  fragment.__typename === 'PlansDescription' &&
  typeof fragment.id === 'string' &&
  typeof fragment.description === 'string'
