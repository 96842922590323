import React, { Dispatch, SetStateAction, useRef } from 'react'
import { useCallback, useEffect } from '.'
import { Memoized } from './dependencies'

type UseStateReturnType<S> = [S, Memoized<Dispatch<SetStateAction<S>>>]

function useIsComponentMounted() {
  const mutable_isMounted = useRef(false)
  useEffect(() => {
    mutable_isMounted.current = true
    return () => {
      mutable_isMounted.current = false
    }
  }, [])
  return mutable_isMounted
}

export function useState<S>(initialState: S | (() => S)): UseStateReturnType<S>

export function useState<S = undefined>(): UseStateReturnType<S | undefined>

export function useState<S = undefined>(
  initialState?: S | (() => S),
): UseStateReturnType<S | undefined> {
  const isComponentMounted = useIsComponentMounted()
  // eslint-disable-next-line no-restricted-properties
  const [state, setState] = React.useState(initialState)
  const newSetState = useCallback(
    (newState: S) => {
      if (isComponentMounted.current) {
        setState(newState)
      }
    },
    [isComponentMounted],
  )
  return [state, newSetState] as UseStateReturnType<S | undefined>
}
