import React from 'react'

import { Fade, Icon } from 'src/chrome'
import styles from './styles'
import { useEffect, useRef } from 'src/hooks'

type AlertType = 'info' | 'success' | 'warning' | 'error'

interface AlertProps {
  isOpen: boolean
  type: AlertType
  title: string
  message?: string
  timeout?: number
  onClose: () => void
  children?: JSX.Element | JSX.Element[]
}

const iconTypes = {
  info: {
    backgroundColor: 'linear-gradient(115deg, #02C3E2, #02C3E2)',
  },
  success: {
    backgroundColor: 'linear-gradient(109deg, #56DCDE, #61E2AD)',
  },
  warning: {
    backgroundColor: 'linear-gradient(109deg, #E094C0, #D5776C)',
  },
  error: {
    backgroundColor: 'linear-gradient(28deg, #dd2476 0%, #fe5030 100%)',
  },
}

const Alert: React.FC<AlertProps> = props => {
  const { isOpen, timeout, onClose, type, title, message } = props

  const mutableTimeout = useRef<NodeJS.Timer | null>(null)

  useEffect(() => {
    if (isOpen && timeout) {
      mutableTimeout.current = setTimeout(onClose, timeout)
    }

    return () => {
      mutableTimeout.current && clearTimeout(mutableTimeout.current)
    }
  })

  const alertType = iconTypes[type]

  return (
    <Fade isVisible={isOpen} fadeIn={500} fadeOut={100}>
      <div css={styles.container}>
        <div key={'alert'} style={{ position: 'relative' }}>
          <span
            onClick={() => onClose()}
            style={{ position: 'absolute', top: 3, right: 9 }}
          >
            <Icon icon={'CLOSE'} size={8} />
          </span>
          <div css={styles.bodyContainer}>
            <div
              css={styles.iconContainer}
              style={{ background: alertType.backgroundColor }}
            >
              <Icon icon={'INFO'} color={'#fff'} />
            </div>
            <div css={styles.body}>
              <div css={styles.alertText}>
                <h6 style={{ fontSize: 18, letterSpacing: 0.5 }}>{title}</h6>
                <span
                  style={{
                    display: 'block',
                    letterSpacing: 0.5,
                    marginTop: 5,
                    fontWeight: 300,
                  }}
                >
                  {message}
                </span>
                <div style={{ marginTop: 15 }}>{props.children}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  )
}

export default Alert
