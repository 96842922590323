import React, { FC } from 'react'
import { SVGProps } from './types'

export const CardHistoryIcon: FC<SVGProps> = ({
  size = 24,
  onClick,
  color = '#323232',
}) => {
  return (
    <svg
      height={size}
      width={size}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick && (() => onClick())}
    >
      <path
        d="M14.9697 22.75H8.96973C3.53973 22.75 1.21973 20.43 1.21973 15V9C1.21973 3.57 3.53973 1.25 8.96973 1.25H14.9697C20.3997 1.25 22.7197 3.57 22.7197 9V15C22.7197 20.43 20.4097 22.75 14.9697 22.75ZM8.96973 2.75C4.35973 2.75 2.71973 4.39 2.71973 9V15C2.71973 19.61 4.35973 21.25 8.96973 21.25H14.9697C19.5797 21.25 21.2197 19.61 21.2197 15V9C21.2197 4.39 19.5797 2.75 14.9697 2.75H8.96973Z"
        fill={color}
      />
      <path
        d="M11.4502 18.0599C11.2102 18.0599 10.6302 17.9699 10.2902 17.0999L9.15023 14.2199C8.99023 13.8099 8.42023 13.4299 7.99023 13.4299L1.99023 13.4499C1.58023 13.4499 1.24023 13.1199 1.24023 12.6999C1.24023 12.2899 1.57023 11.9499 1.99023 11.9499L7.99023 11.9299H8.00023C9.05023 11.9299 10.1702 12.6899 10.5602 13.6699L11.4702 15.9699L13.5302 10.7499C13.8402 9.96989 14.3902 9.85989 14.6202 9.83989C14.8502 9.82989 15.4102 9.87989 15.8002 10.6199L16.8402 12.5899C17.0202 12.9299 17.5602 13.2599 17.9502 13.2599H22.0102C22.4202 13.2599 22.7602 13.5999 22.7602 14.0099C22.7602 14.4199 22.4202 14.7599 22.0102 14.7599H17.9502C17.0002 14.7599 15.9602 14.1299 15.5202 13.2899L14.7402 11.8099L12.6402 17.0999C12.2702 17.9599 11.6802 18.0599 11.4502 18.0599Z"
        fill={color}
      />
    </svg>
  )
}
