import {
  applyMiddleware,
  compose,
  createStore,
  Dispatch,
  MiddlewareAPI,
  Store as ReduxStore,
} from 'redux'
import { persistStore } from 'redux-persist'

import LogRocket from 'src/logrocket/logrocket'
import { isMobile, WINDOW_RESIZE } from 'src/redux/reducers/window'
import { tasksMiddleware } from 'src/app/tasks'
import { startListeningToBrowserHistory } from 'src/redux/browserHistory'
import { segmentMiddleware } from 'src/redux/middleware/segmentAnalytics'
import { userMiddleware } from 'src/redux/middleware/user'
import Action from './action'
import { appReducer, State } from './reducers'

export type Middleware = (
  api: MiddlewareAPI<Dispatch<Action>, State>,
) => (nextDispatch: Dispatch<Action>) => (action: Action) => Action

const composeEnhancers = (...args: Function[]) =>
  process.env.NODE_ENV === 'production'
    ? compose(...args)
    : window && !!window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(...args)
    : compose(...args)

export const Store: ReduxStore<State, Action> = createStore(
  appReducer,
  {} as State,
  composeEnhancers(
    applyMiddleware(
      LogRocket.reduxMiddleware(),
      segmentMiddleware,
      tasksMiddleware,
      userMiddleware,
    ),
  ),
)

export const persistor = persistStore(Store)

startListeningToBrowserHistory()

window.addEventListener('resize', () =>
  Store.dispatch({
    type: WINDOW_RESIZE,
    isMobile: isMobile(window),
    width: window.innerWidth,
    height: window.innerHeight,
  }),
)
