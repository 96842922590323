import {
  Div,
  Flex,
  LoadingSpinner,
  Text,
} from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { CertifiedCoachBadge } from 'src/coaching/svg/certifiedCoachBadge'
import { CoachingBadgeType } from 'src/coaching/svg/coachingBadgeSVGs'
import { Clipboard } from './components/Clipboard'
import { useContainerWidth, useSelector } from 'src/hooks'
import { Button as DSButton } from 'src/design_system/components/Button/Button'
import { useByDesignLoginLink } from 'src/react_query'

interface Props {
  title: string
  date: string
  location: string
  badge: CoachingBadgeType
  code?: {
    title: string
    value: string
  }
  action?: {
    onClick?: () => void
    onBydesignRedirect?: string
    title: string
  }
}

const CoachingEventCard: React.FC<Props> = props => {
  const { title, date, location, code, action, badge } = props

  const {
    isLoading: isCreatingBydesignLoginLink,
    data: byDesignLoginLink,
  } = useByDesignLoginLink()

  const onActionClick = () => {
    if (action?.onClick) {
      action.onClick()
    }
    if (action?.onBydesignRedirect) {
      window.open(`${byDesignLoginLink}/${action?.onBydesignRedirect}`)
    }
  }

  const [containerRef, containerWidth] = useContainerWidth()
  const isMobileBreakpoint = containerWidth <= 225

  const isMobile = useSelector(state => state.window.isMobile)

  return (
    <>
      {isCreatingBydesignLoginLink ? (
        <LoadingSpinner size="medium" />
      ) : (
        <Div
          ref={containerRef}
          display="flex"
          backgroundColor="foreground"
          borderRadius="default"
          flexDirection={isMobile ? 'column' : 'row'}
          alignItems="center"
          inset={{ vertical: 'x3', horizontal: 'x3' }}
          justifyContent="flex-start"
          rowGap="x1_5"
          columnGap="x1_5"
          minWidth="300px"
          maxHeight="500px"
          style={{
            boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.08)',
          }}
        >
          <Flex flexDirection="column" width="300px">
            <Text
              type="body"
              color="anchorBlue"
              content={title}
              whiteSpace="normal"
              alignment={isMobile ? 'center' : 'left'}
              weight="extraBold"
              style={{
                fontWeight: 700,
                fontSize: isMobileBreakpoint ? '.875rem' : '1rem',
              }}
            />
            <Flex
              flexDirection="column"
              alignItems={isMobile ? 'center' : 'flex-start'}
            >
              <Text
                type="footnote"
                color="primaryBody"
                content="Next Event"
                weight="bold"
                style={{ fontWeight: 700 }}
              />
              <Text
                type="footnote"
                color="primaryBody"
                content={date}
                weight="bold"
                style={{ color: '#bebebe', fontWeight: 700 }}
              />
              <Text
                type="footnote"
                color="primaryBody"
                content={location}
                weight="bold"
                style={{ color: '#bebebe', fontWeight: 700 }}
              />
            </Flex>
            {action && (
              <Flex
                outset={{ top: 'x1' }}
                width="100%"
                style={{ transform: isMobileBreakpoint ? 'scale(.9)' : '' }}
              >
                <DSButton
                  title={action.title}
                  onClick={onActionClick}
                  width="100%"
                  backgroundColor="#02c5e3"
                />
              </Flex>
            )}
          </Flex>
          <Flex
            flexDirection="column"
            width="100%"
            justifyContent="center"
            alignItems={isMobile ? 'center' : 'flex-end'}
          >
            <CertifiedCoachBadge
              badge={badge}
              size={isMobileBreakpoint ? 110 : 150}
            />
            <Flex
              flexDirection="column"
              rowGap="x1"
              justifyContent="space-evenly"
            >
              {code && (
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  style={{ transform: isMobileBreakpoint ? 'scale(.9)' : '' }}
                >
                  <Text
                    type="footnote"
                    content={code?.title}
                    weight="bold"
                    style={{ color: '#bebebe', fontWeight: 700 }}
                  />
                  <Clipboard copyText={code?.value} />
                </Flex>
              )}
            </Flex>
          </Flex>
        </Div>
      )}
    </>
  )
}

export default CoachingEventCard
