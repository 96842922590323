import { Operation } from '../../../__generated__/globalTypes'

import {
  CardCategoryFragment,
  cardCategoryFragmentString,
} from '../../fragments/__generated__/CardCategoryFragment'

import { sendableCardImageFragmentString } from '../../fragments/__generated__/SendableCardImageFragment'

import { minimalSendableCardFragmentString } from '../../fragments/__generated__/MinimalSendableCardFragment'

import getCardCategoriesRawString from '../GetCardCategories.graphql'

const getCardCategoriesString = [
  getCardCategoriesRawString,
  cardCategoryFragmentString,
  sendableCardImageFragmentString,
  minimalSendableCardFragmentString,
].join('\n\n')

export type GetCardCategoriesData = {
  cardCategories: CardCategoryFragment[]
}

export type GetCardCategories = Operation<GetCardCategoriesData>

export const GetCardCategories = (): GetCardCategories => ({
  query: getCardCategoriesString,
  variables: undefined,
})
