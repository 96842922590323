import React from 'react'
import { Div, List, ListItem } from '@sendoutcards/quantum-design-ui'
import { useSelector } from 'src/hooks'
import { SponsorFragment } from 'src/legacy_graphql'
import NoResultsPlaceholder from 'src/onboarding/components/NoResultsPlaceHolder'
import SearchForSponsorPlaceholder from 'src/onboarding/components/SearchForSponsorPlaceHolder'
import { SponsorCard } from 'src/onboarding/components/SponsorCard'
import { useUsers } from 'src/react_query'

const SponsorSearchResult = (props: {
  search: string
  selectedSponsor?: SponsorFragment
  setSelectedSponsor: (sponsor?: SponsorFragment) => void
}) => {
  const { search, selectedSponsor, setSelectedSponsor } = props
  const { data: filteredSponsors } = useUsers(
    {
      search: search,
      offset: 0,
      limit: 10,
      isAffiliate: true, // only Distributor types
    },
    search.length > 0,
  )
  const { isMobile } = useSelector(state => state.window)
  const maxLength = isMobile ? 10 : 12
  const formatName = (name: string) => {
    if (name.length === 0) return ' '
    if (name.length > maxLength) return `${name.substring(0, maxLength)}...`
    return name
  }

  return (
    <Div overflow="auto" flexDirection="column" height="100%" width="100%">
      <List listStyle="none" alignment="left" orientation="vertical">
        {filteredSponsors &&
          filteredSponsors.length > 0 &&
          filteredSponsors.map(sponsor => {
            return (
              <ListItem
                key={sponsor.id}
                orientation="vertical"
                inset={{ top: 'x1' }}
              >
                <SponsorCard
                  sponsor={{
                    ...(sponsor as SponsorFragment),
                    firstName: formatName(sponsor.firstName),
                    lastName: formatName(sponsor.lastName),
                  }}
                  onClick={() => {
                    selectedSponsor !== sponsor
                      ? setSelectedSponsor(sponsor as SponsorFragment)
                      : setSelectedSponsor(undefined)
                  }}
                  isActive={selectedSponsor?.id === sponsor.id}
                />
              </ListItem>
            )
          })}
      </List>
      {search.length > 0 &&
        filteredSponsors &&
        filteredSponsors.length === 0 && (
          <NoResultsPlaceholder search={search} />
        )}
      {filteredSponsors === undefined && <SearchForSponsorPlaceholder />}
    </Div>
  )
}

export default SponsorSearchResult
