import moment from 'moment'

import { Icon } from 'src/app/types'
import {
  ContactRequestStatusEnum,
  GetPaginatedContactsData,
  GetPaginatedGroupsData,
  MinimalContactFragment,
} from 'src/legacy_graphql'

export enum SearchFilterType {
  DATERANGE,
  BOOLEAN,
  GROUP,
  GROUP_HISTORY,
  CONTACT,
  COMPANY,
  REQUEST_STATUS,
}

interface SearchFilterBase {
  name: string
  type: SearchFilterType
  icon?: Icon
}

export interface DateSearchFilter extends SearchFilterBase {
  type: SearchFilterType.DATERANGE
  value?: {
    start: moment.Moment
    end: moment.Moment
  }
}

interface BooleanSearchFilter extends SearchFilterBase {
  type: SearchFilterType.BOOLEAN
  value?: boolean
}

interface GroupSearchFilter extends SearchFilterBase {
  type: SearchFilterType.GROUP
  value?: GetPaginatedGroupsData['paginatedGroups']['groups']
}

export interface GroupHistorySearchFilter extends SearchFilterBase {
  type: SearchFilterType.GROUP_HISTORY
  value?: {
    contacts: GetPaginatedContactsData['paginatedContacts']['contacts']
    groupName: string
  }
}

export interface ContactSearchFilter extends SearchFilterBase {
  type: SearchFilterType.CONTACT
  value?: MinimalContactFragment[]
}

export interface CompanySearchFilter extends SearchFilterBase {
  type: SearchFilterType.COMPANY
  value?: string
}

export interface RequestStatusSearchFilter extends SearchFilterBase {
  type: SearchFilterType.REQUEST_STATUS
  value?: ContactRequestStatusEnum
}

export type SearchFilter =
  | DateSearchFilter
  | BooleanSearchFilter
  | GroupSearchFilter
  | GroupHistorySearchFilter
  | ContactSearchFilter
  | CompanySearchFilter
  | RequestStatusSearchFilter
