import React, { FC } from 'react'
import { SVGProps } from './types'

export const Heartfelt: FC<SVGProps> = ({
  size = 24,
  onClick,
  color = '#323232',
}) => {
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick && (() => onClick())}
    >
      <path
        d="M 1.6 17.217 C 0.937 17.217 0.4 17.217 0.4 17.217 L 0.4 0.783 C 0.4 0.783 0.937 0.783 1.6 0.783 L 10.4 0.783 C 11.063 0.783 11.6 0.783 11.6 0.783 L 11.6 17.217 C 11.6 17.217 11.063 17.217 10.4 17.217 Z"
        fill="transparent"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray=""
      />
      <path
        d="M 6 12 C 5.899 12 5.801 11.988 5.72 11.96 C 4.476 11.552 2.5 10.107 2.5 7.971 C 2.5 6.883 3.421 6 4.554 6 C 5.105 6 5.619 6.205 6 6.572 C 6.382 6.204 6.903 5.998 7.446 6 C 8.579 6 9.5 6.886 9.5 7.971 C 9.5 10.11 7.524 11.552 6.28 11.96 C 6.199 11.988 6.101 12 6 12 Z M 4.554 6.466 C 3.692 6.466 2.988 7.141 2.988 7.971 C 2.988 10.094 5.127 11.276 5.88 11.521 C 5.938 11.54 6.065 11.54 6.124 11.521 C 6.873 11.276 9.015 10.097 9.015 7.971 C 9.015 7.141 8.312 6.466 7.449 6.466 C 6.954 6.466 6.495 6.687 6.199 7.069 C 6.107 7.188 5.899 7.188 5.808 7.069 C 5.514 6.689 5.049 6.465 4.554 6.466 Z"
        fill={color}
      />
    </svg>
  )
}
