import { Operation } from '../../../__generated__/globalTypes'

import deletePanelLayoutString from '../DeletePanelLayout.graphql'

export type DeletePanelLayoutData = {
  deletePanelLayout: {
    deleted: boolean
  }
}

export type DeletePanelLayoutVariables = {
  id: string
}

export type DeletePanelLayout = Operation<
  DeletePanelLayoutData,
  DeletePanelLayoutVariables
>

export const DeletePanelLayout = ({
  id,
}: DeletePanelLayoutVariables): DeletePanelLayout => ({
  query: deletePanelLayoutString,
  variables: {
    id,
  },
})
