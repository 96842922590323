import React from 'react'

import Editor from 'src/editor/containers/Editor/Editor'
import { Transition } from 'src/chrome'
import { EditOrderCardRoute } from 'src/orders/routes/EditOrderCardRoute'
import { orderCostWithUpgrades } from 'src/helpers/payments'
import { OrderWithPartialLines } from 'src/redux/reducers/orders'
import { useActions } from 'src/hooks'
import { OrderApiType } from 'src/orders/api'

type Props = {
  subroute: EditOrderCardRoute
  order: OrderWithPartialLines
  onReload: () => Promise<void>
  onClose: () => void
  isLoading: boolean
  orderApi?: OrderApiType
}

const EditCard: React.FC<Props> = props => {
  const { order, subroute, onReload, onClose, isLoading, orderApi } = props

  const actions = useActions()

  const cardCount = order?.lines.length ?? 0
  if (!order) return <Transition message={'Loading your order...'} />

  const handleSaved = async () => {
    await onReload()
    onClose()
  }

  const closeAndReturnToCatalog = () => {
    actions.openCatalog()
  }

  return (
    <>
      {isLoading && <Transition message={'Reloading your order...'} />}
      <Editor
        cardId={subroute.cardId}
        onAfterSave={handleSaved}
        onClose={onClose}
        context={{
          headerText: `Order: ${order.id}`,
          lines: order.lines,
          footerLabel: card => (
            <div style={{ display: 'flex' }}>
              <b>
                {cardCount} card{cardCount > 1 ? 's' : ''}
              </b>
              <div style={{ marginLeft: '1rem' }}>
                Total: {orderCostWithUpgrades(order, card)}
              </div>
            </div>
          ),
        }}
        saveButtonLabel="Proceed to Cart"
        saveButtonBackground="#32b769"
        saveButtonColor="#FFFFFF"
        secondaryAction={{
          label: 'Save & Close Card Editor',
          onClick: closeAndReturnToCatalog,
        }}
        orderApi={orderApi}
      />
    </>
  )
}

export default EditCard
