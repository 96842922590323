import { Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'

import styles from './loadingIndicator.module.scss'

interface LoadingIndicatorProps {
  message: string
  messageStyle?: React.CSSProperties
  subMessage?: string | string[]
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = props => {
  const { message, messageStyle, subMessage } = props

  const subMessageContent = () => {
    if (Array.isArray(subMessage)) {
      return subMessage.map((msg, index) => (
        <Text
          key={index}
          type="subtitle"
          color="primaryBody"
          alignment="center"
          weight="regular"
          content={msg}
        />
      ))
    }
    return (
      <Text
        type="subtitle"
        color="primaryBody"
        alignment="center"
        weight="regular"
        content={subMessage ?? ''}
      />
    )
  }

  return (
    <div className={styles.loadingContainer}>
      <div className={styles.loadingIndicator}>
        <div className={styles.circle} />
        <div className={styles.circle} />
        <div className={styles.circle} />
      </div>
      <div style={messageStyle} css={{ width: '100%' }}>
        <Text
          type="title"
          color="primaryBody"
          alignment="center"
          weight="bold"
          content={message}
          outset={{ top: 'x2_5' }}
        />
      </div>
      {subMessageContent()}
    </div>
  )
}

export default LoadingIndicator
