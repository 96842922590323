import { Maybe } from '../../../__generated__/globalTypes'

import storefrontFragmentRawString from '../StorefrontFragment.graphql'

export const storefrontFragmentString = storefrontFragmentRawString

export type StorefrontFragment = {
  __typename: 'Storefront'
  mugshot: Maybe<{
    url: string
  }>
  biography: Maybe<string>
  slug: Maybe<string>
}

export const isStorefrontFragment = (
  fragment: any,
): fragment is StorefrontFragment =>
  fragment &&
  fragment.__typename === 'Storefront' &&
  (fragment.mugshot === null ||
    (fragment.mugshot && typeof fragment.mugshot.url === 'string')) &&
  (fragment.biography === null || typeof fragment.biography === 'string') &&
  (fragment.slug === null || typeof fragment.slug === 'string')
