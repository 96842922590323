import { Operation } from '../../../__generated__/globalTypes'

import {
  PlanDescriptionFragment,
  planDescriptionFragmentString,
} from '../../fragments/__generated__/PlanDescriptionFragment'

import getPlansDescriptionsRawString from '../GetPlansDescriptions.graphql'

const getPlansDescriptionsString = [
  getPlansDescriptionsRawString,
  planDescriptionFragmentString,
].join('\n\n')

export type GetPlansDescriptionsData = {
  plansDescriptions: PlanDescriptionFragment[]
}

export type GetPlansDescriptions = Operation<GetPlansDescriptionsData>

export const GetPlansDescriptions = (): GetPlansDescriptions => ({
  query: getPlansDescriptionsString,
  variables: undefined,
})
