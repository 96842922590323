import { CountryCode, parsePhoneNumber } from 'libphonenumber-js'
import { PhoneNumberCountryFragment } from 'src/legacy_graphql'

export const getValidatedNumber = (countryCode: string, text: string) => {
  if (text === '') return undefined
  try {
    const phoneNumber = parsePhoneNumber(text, countryCode as CountryCode)
    return phoneNumber.isValid() ? phoneNumber : undefined
  } catch {
    return undefined
  }
}

export const createPhoneNumberInput = (
  phoneNumber: string,
  selectedCountry: PhoneNumberCountryFragment,
) => {
  const selectedCountryCode = selectedCountry.isoCode
  const validatedNumber = getValidatedNumber(selectedCountryCode, phoneNumber)
  return {
    countryIsoCode: selectedCountryCode,
    nationalNumber: validatedNumber?.nationalNumber as string,
  }
}
