import {
  UpdateCoachPublicInfoDisplayInput,
  Operation,
} from '../../../__generated__/globalTypes'

import {
  PromptingsCoachFragment,
  promptingsCoachFragmentString,
} from '../../fragments/__generated__/PromptingsCoachFragment'

import updateCoachPublicInfoDisplayRawString from '../UpdateCoachPublicInfo.graphql'

const updateCoachPublicInfoDisplayString = [
  updateCoachPublicInfoDisplayRawString,
  promptingsCoachFragmentString,
].join('\n\n')

export type UpdateCoachPublicInfoDisplayData = {
  updateCoachPublicInfoDisplay: {
    promptingCoach: PromptingsCoachFragment
  }
}

export type UpdateCoachPublicInfoDisplayVariables = {
  publicContactInfo: UpdateCoachPublicInfoDisplayInput
}

export type UpdateCoachPublicInfoDisplay = Operation<
  UpdateCoachPublicInfoDisplayData,
  UpdateCoachPublicInfoDisplayVariables
>

export const UpdateCoachPublicInfoDisplay = ({
  publicContactInfo,
}: UpdateCoachPublicInfoDisplayVariables): UpdateCoachPublicInfoDisplay => ({
  query: updateCoachPublicInfoDisplayString,
  variables: {
    publicContactInfo: UpdateCoachPublicInfoDisplayInput(publicContactInfo),
  },
})
