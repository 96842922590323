import { Operation } from '../../../__generated__/globalTypes'

import {
  DetailedContactFragment,
  detailedContactFragmentString,
} from '../../fragments/__generated__/DetailedContactFragment'

import { calendarDateFragmentString } from '../../fragments/__generated__/CalendarDateFragment'

import { contactFragmentString } from '../../fragments/__generated__/ContactFragment'

import { optionalYearDateFragmentString } from '../../fragments/__generated__/OptionalYearDateFragment'

import { relatedContactFragmentString } from '../../fragments/__generated__/RelatedContactFragment'

import { noteFragmentString } from '../../fragments/__generated__/NoteFragment'

import getContactRawString from '../GetContact.graphql'

const getContactString = [
  getContactRawString,
  detailedContactFragmentString,
  calendarDateFragmentString,
  contactFragmentString,
  optionalYearDateFragmentString,
  relatedContactFragmentString,
  noteFragmentString,
].join('\n\n')

export type GetContactData = {
  contact: DetailedContactFragment
}

export type GetContactVariables = {
  id: string
}

export type GetContact = Operation<GetContactData, GetContactVariables>

export const GetContact = ({ id }: GetContactVariables): GetContact => ({
  query: getContactString,
  variables: {
    id,
  },
})
