import { useEffect, usePersistedUserData } from '.'

const useProfitWellEffect = () => {
  const { account } = usePersistedUserData()
  useEffect(() => {
    if (account && window.profitwell) {
      window.profitwell('start', {
        user_email: account.email,
        user_id: account.stripeCustomerId ?? null,
      })
    }
  }, [account])
}

export default useProfitWellEffect
