import React from 'react'

// @src imports
import {
  CardType,
  ImageFragment,
  SendableCardWithTemplateFragment,
} from 'src/graphql/generated/graphql'

// relative imports
import {
  ComputedFullBleed,
  ComputedPanel,
  frontImage as getFrontImage,
  insideRightImage as getInsideRightImage,
  selectedVariationId as getSelectedVariationId,
  variations as getVariations,
} from '../../../redux/selectors/editor'
import ImageElement from '../../components/ImageElement'
import PostCardSleeve from '../../components/PostCardSleeve'
import VariationToggle from '../../components/VariationToggle/VariationToggle'
import TextElement from '../../components/TextElement'
import PrintBarCode from '../../components/PrintBarCode/PrintBarCode'

import styles from './styles'
import { EditorImage, factoryFullBleeds } from '../../EditorCard'
import * as Utils from '../../utils'
import { Api } from '../../api'
import { useCallback, useSelector, useState } from 'src/hooks'
import { Portal } from 'src/portal/portal'

type PanelProps = {
  panel: ComputedPanel
  hasBackgroundSpread?: boolean
  index: number
  api: Api
  fullBleed: ComputedFullBleed
}

const Panel: React.FC<PanelProps> = props => {
  const { api, panel, hasBackgroundSpread, index, fullBleed } = props
  const {
    selectPanel,
    step,
    fullBleeds,
    updateCard,
    card,
    panelView,
    resolveLocation,
    setFullBleeds,
  } = api

  const [shouldPortal, setShouldPortal] = useState(false)
  const isMobile = useSelector(state => state.window.isMobile)

  // TODO: React Query Refactor - No sure if this is how we want to handle possibly undefined results
  if (!card) throw Error('No card provided!')
  const variations = getVariations(card)
  const selectedVariationId = getSelectedVariationId(card, fullBleeds)

  const handleSelectVariation = async (
    variation: SendableCardWithTemplateFragment,
  ) => {
    const editorImage = (image: ImageFragment): EditorImage => ({
      image: {
        __typename: 'Image',
        id: image.id,
        width: image.width,
        height: image.height,
        url: image.url,
        smallThumb: image.smallThumb,
      },
      position: { x: 0.5, y: 0.5 },
      scale: 1,
      filter: null,
    })

    const frontImage = getFrontImage(variation)
    const insideRightImage = getInsideRightImage(variation)
    const updatedCard = await updateCard(
      card,
      fullBleeds.map((fullBleed, fullBleedLocation) => ({
        ...fullBleed,
        panels: fullBleed.panels.map((panel, panelLocation) => ({
          ...panel,
          elements: panel.elements.map(element => ({
            ...element,
            image: element.image
              ? fullBleedLocation === 0 &&
                panelLocation === 0 &&
                element.locked &&
                frontImage
                ? editorImage(frontImage)
                : insideRightImage &&
                  Utils.isInsideRightPanel(
                    card.type,
                    fullBleedLocation,
                    panelLocation,
                  ) &&
                  element.locked
                ? editorImage(insideRightImage)
                : element.image
              : element.image,
          })),
        })),
      })),
    )
    setFullBleeds(factoryFullBleeds(updatedCard))
  }

  const handleSelect = () => selectPanel(step.fullBleedIndex, index)

  const handleMobileEditing = useCallback(
    (isEditingText: boolean) => {
      if (isMobile) {
        setShouldPortal(isEditingText)
      } else {
        setShouldPortal(false)
      }
    },
    [isMobile],
  )

  const orientation = card.isHorizontal ? 'LANDSCAPE' : 'PORTRAIT'
  const isFlatHorizontal = card.type === CardType.Flatcard && card.isHorizontal

  const barcodeStyles = isFlatHorizontal
    ? {
        display: 'flex',
        right: '2px',
        top: '50%',
        width: '4%',
        transform: 'translateY(-50%)',
      }
    : {
        flexDirection: 'column',
      }

  return (
    <div
      id={`${panel.name}_panel`}
      key={panel.location}
      css={() => ({
        ...styles.container(
          panel.isSelected,
          card.type,
          panel.name,
          orientation,
        ),
        ...styles.panelBackground(!!hasBackgroundSpread, panel.backgroundColor),
      })}
      onClick={!panel.isCustom ? () => handleSelect() : undefined}
    >
      <div css={styles.panelContent}>
        {/* Loop through panel elements and initialize editor components */}
        {panel.elements.map(element => {
          const elementDisplayStyles: React.CSSProperties = {
            position: 'absolute',
            top: Math.max(element.y * 100, 0) + '%',
            left: Math.max(element.x * 100, 0) + '%',
            width: Math.min(element.width * 100, 100) + '%',
            height: Math.min(element.height * 100, 100) + '%',
            transform:
              element.rotation !== 0
                ? `rotate(${element.rotation}deg)`
                : 'none',
            transformOrigin: '0% 0%',
          }
          return (
            <div key={element.location}>
              {element.image && (
                <ImageElement
                  key={element.location}
                  api={api}
                  panel={panel}
                  element={element}
                  elementDisplayStyles={elementDisplayStyles}
                />
              )}
              {element.text && (
                <>
                  {!shouldPortal ? (
                    <TextElement
                      api={api}
                      key={element.location}
                      panel={panel}
                      fullBleed={fullBleed}
                      element={element}
                      elementText={element.text}
                      elementDisplayStyles={elementDisplayStyles}
                      handleMobileEditing={handleMobileEditing}
                    />
                  ) : (
                    <Portal attachToContainerId="mobile-text-editor-wrapper">
                      <TextElement
                        api={api}
                        key={element.location}
                        panel={panel}
                        fullBleed={fullBleed}
                        element={element}
                        elementText={element.text}
                        elementDisplayStyles={{
                          position: 'initial',
                          width: '100%',
                          height: '30vh',
                          cursor: 'text',
                          overflow: 'auto',
                          margin: '1rem 0',
                          boxShadow:
                            ' inset 0px 1px 2px 0px rgba(0, 0, 0, 0.25)',
                          backgroundColor: ' #f2f2f2',
                          borderRadius: ' 6px',
                        }}
                        handleMobileEditing={handleMobileEditing}
                      />
                    </Portal>
                  )}
                </>
              )}
              {variations && element.locked && panel.name !== 'Back' && (
                <VariationToggle
                  variations={variations}
                  selectedVariationId={selectedVariationId}
                  selectVariation={handleSelectVariation}
                  style={{
                    left:
                      panelView === 'fullbleed' &&
                      resolveLocation(card.type) !== 'Front' &&
                      resolveLocation(card.type) !== 'Back'
                        ? '0'
                        : '50%',
                  }}
                />
              )}
            </div>
          )
        })}
      </div>
      {card.type === CardType.Postcard && panel.name === 'Back' && (
        <PostCardSleeve />
      )}
      {panel.name === 'Back' && card.type !== CardType.Postcard && (
        <PrintBarCode
          width={isFlatHorizontal ? '75%' : undefined}
          height={isFlatHorizontal ? 'auto' : undefined}
          style={{
            display: 'flex',
            alignItems: 'center',
            ...(barcodeStyles as React.CSSProperties),
          }}
        />
      )}
    </div>
  )
}

export default Panel
