import React from 'react'

import { Icon } from 'src/chrome'
import MenuItem, { MenuItemType } from 'src/chrome/HelpMenu/MenuItem'
import { useSelector, useState } from 'src/hooks'

import styles from './helpmenu.module.scss'

const HelpMenu: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { tourId } = useSelector(state => state.helpmenu)
  const [isIntercomOpen, setIsIntercomOpen] = useState(false)
  const [isIntercomOnHideSetup, setIsIntercomOnHideSetup] = useState(false)

  const toggleMenu = () => {
    setIsOpen(open => !open)
    handleIntercom(false)
  }

  const setupIntercomOnHide = () => {
    if (!isIntercomOnHideSetup) {
      window.Intercom('onHide', () => {
        handleIntercom(false)
      })
      setIsIntercomOnHideSetup(true)
    }
  }

  const handleIntercom = (shouldOpen: boolean) => {
    if (shouldOpen) {
      if (window.Intercom) {
        setupIntercomOnHide()
        window.Intercom('show')
        setIsIntercomOpen(shouldOpen)
      } else {
        console.log(
          'Intercom does not exist on the window, please check your intercom config script',
        )
      }
    } else {
      window.Intercom('hide')
      setIsIntercomOpen(shouldOpen)
    }
  }

  const launchTips = () => {
    window.Appcues.show(tourId)
  }

  const items: MenuItemType[] = [
    {
      title: 'Terms',
      icon: {
        icon: 'LIST',
        color: '#333',
        size: 16,
        viewBox: '0 0 1024 1154',
      },
      link:
        'https://help.sendoutcards.com/sendoutcards/en/articles/432937-what-are-the-sendoutcards-policies-and-procedures',
    },
    {
      title: 'Privacy',
      icon: {
        icon: 'COG',
        color: '#333',
        size: 17,
        viewBox: '0 0 1024 1154',
      },
      link: 'https://www.sendoutcards.com/resources/privacy-policy/',
    },
    {
      title: 'Help',
      icon: {
        icon: 'LIST',
        color: '#333',
        size: 16,
      },
      link: 'https://help.sendoutcards.com',
    },
    {
      title: 'Message',
      onClick: () => handleIntercom(!isIntercomOpen),
      icon: {
        icon: 'CHAT',
        color: '#333',
        size: 18,
        viewBox: '0 0 1024 1154',
      },
    },
    {
      title: 'Tips',
      onClick: launchTips,
      disabled: !tourId,
      icon: {
        icon: 'INFO',
        color: '#333',
        size: 18,
      },
    },
  ]
  return (
    <div className={styles.floatingMenu}>
      <div
        className={
          isOpen ? `${styles.helpMenu} ${styles.open}` : styles.helpMenu
        }
      >
        <div className={styles.menuItems}>
          {items.map((item: MenuItemType, index: number) => {
            return item.disabled ? null : <MenuItem key={index} item={item} />
          })}
        </div>
        <div className={styles.mainButton} onClick={toggleMenu}>
          <Icon
            icon={isOpen ? 'CLOSE' : 'QUESTIONMARK'}
            color={'#FFF'}
            size={20}
            viewBox={isOpen ? '' : '0 0 22 32'}
            isCentered={true}
          />
          <p>{isOpen ? 'close' : 'Help'}</p>
        </div>
      </div>
      <div
        className={
          isIntercomOpen
            ? `${styles.intercomLauncher} ${styles.intercomOpen} assad`
            : styles.intercomLauncher
        }
        onClick={() => handleIntercom(!isIntercomOpen)}
      >
        <div className={styles.intercomOpenBtn}>
          <svg
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 28 32"
            width="28"
            height="32"
          >
            <path d="M28,32 C28,32 23.2863266,30.1450667 19.4727818,28.6592 L3.43749107,28.6592 C1.53921989,28.6592 0,27.0272 0,25.0144 L0,3.6448 C0,1.632 1.53921989,0 3.43749107,0 L24.5615088,0 C26.45978,0 27.9989999,1.632 27.9989999,3.6448 L27.9989999,22.0490667 L28,22.0490667 L28,32 Z M23.8614088,20.0181333 C23.5309223,19.6105242 22.9540812,19.5633836 22.5692242,19.9125333 C22.5392199,19.9392 19.5537934,22.5941333 13.9989999,22.5941333 C8.51321617,22.5941333 5.48178311,19.9584 5.4277754,19.9104 C5.04295119,19.5629428 4.46760991,19.6105095 4.13759108,20.0170667 C3.97913051,20.2124916 3.9004494,20.4673395 3.91904357,20.7249415 C3.93763774,20.9825435 4.05196575,21.2215447 4.23660523,21.3888 C4.37862552,21.5168 7.77411059,24.5386667 13.9989999,24.5386667 C20.2248893,24.5386667 23.6203743,21.5168 23.7623946,21.3888 C23.9467342,21.2215726 24.0608642,20.9827905 24.0794539,20.7254507 C24.0980436,20.4681109 24.0195551,20.2135019 23.8614088,20.0181333 Z" />
          </svg>
        </div>
        <div className={styles.intercomCloseBtn}>
          <svg
            focusable="false"
            viewBox="0 0 16 14"
            width="28"
            height="25"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M.116 4.884l1.768-1.768L8 9.232l6.116-6.116 1.768 1.768L8 12.768.116 4.884z"
            />
          </svg>
        </div>
      </div>
    </div>
  )
}

export default HelpMenu
