import React, { FC } from 'react'

type BaseCreditCard = {
  onClick?: () => void
}

const BaseCreditCard: FC<BaseCreditCard> = props => {
  const aspectRatio = 1.586
  const { onClick, ...rest } = props
  const styles = {
    baseCreditCard: {
      width: 354,
      height: 354 / aspectRatio,
      borderRadius: 15,
      border: 'solid 2px #929090',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: onClick ? 'pointer' : 'default',
      '@media (max-width: 668px)': {
        width: '100%',
        height: 'calc((100vw - 82px) / 1.586)',
      },
    },
  }
  return (
    <div css={styles.baseCreditCard} onClick={() => onClick?.()} {...rest}>
      {props.children}
    </div>
  )
}

export default BaseCreditCard
