import React from 'react'
import Icon from '@material-ui/core/Icon'
// @src imports
import { getTotal } from 'src/helpers'
import { useState } from 'src/hooks'
import * as SendDelayOptions from 'src/orders/sendDelayOptions'

import { OrderLine } from '../../types'
import { Div, Spacer, Text } from '@sendoutcards/quantum-design-ui'

interface Props {
  title: string
  subtitle?: string
  lines?: OrderLine[]
  activeCardId?: string
}

const styles = {
  contextBar: {
    top: 0,
    position: 'absolute' as const,
    width: '100%',
    zIndex: 110,
    display: 'flex',
    flexWrap: 'wrap' as const,
    marginLeft: '10px',
    padding: '0 15px',
    height: '70px',
    background: 'white',
    boxShadow: '2px 1px 10px rgba(99, 114, 130, 0.16)',
    alignItems: 'center' as const,
    '@media (max-width: 568px)': {
      display: 'block',
    },
    '@media (max-width: 350px)': {
      height: '93px',
    },
  },
  contextTitle: {
    marginRight: 'auto',
    fontWeight: 'bold' as const,
    fontFamily: '"Open Sans", sans-serif',
    display: 'flex',
    '@media (max-width: 568px)': {
      display: 'inline-block',
      marginTop: '7px',
    },
    '@media (max-width: 350px)': {
      marginTop: '20px',
    },
  },
  contextActions: {
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'space-evenly',
    marginBottom: '5px',
    userSelect: 'none' as const,
    cursor: 'pointer',
    '@media (max-width: 568px)': {
      position: 'absolute' as const,
      right: '15px',
      top: '38px',
    },
  },
  contextActionsIcon: {
    top: 3,
    fontSize: '1rem',
  },
  contextDrawer: {
    width: '100%',
    overflowX: 'auto' as const,
    overflowY: 'hidden' as const,
    position: 'absolute' as const,
    display: 'flex',
    flexWrap: 'nowrap' as const,
    top: '73px',
    minHeight: '300px',
    maxHeight: '300px',
    zIndex: 100,
    background: 'white',
    boxShadow: '0 2px 5px 1px #e2e2e2',
    left: 0,
    animation: 'slideDown 0.25s cubic-bezier(0.4, 0, 0.2, 1)',
    '@keyframes slideDown': {
      '0%': {
        minHeight: '0px',
        maxHeight: '0px',
      },
      '100%': {
        minHeight: '300px',
        maxHeight: '300px',
      },
    },
    '@media (max-width: 350px)': {
      top: '96px',
    },
  },
  drawerContents: {
    display: 'flex',
    padding: '25px',
    '> div': {
      cursor: 'pointer',
      img: {
        width: '100%',
      },
    },
    '> div:not(:last-child)': {
      marginRight: '25px',
    },
  },
  portrait: {
    position: 'relative' as const,
    width: '158px',
    height: '221.2px',
  },
  landscape: {
    position: 'relative' as const,
    width: '221.2px',
    height: '158px',
  },
}

const ContextBar: React.FC<Props> = props => {
  const { title, subtitle, lines, activeCardId } = props

  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(x => !x)

  return (
    <div css={styles.contextBar}>
      <div css={styles.contextTitle}>
        <Text type="body" weight="bold" color="primaryHeading">
          {title}
        </Text>
        {subtitle && (
          <>
            <Spacer space="x2" orientation="horizontal" />
            <Text type="caption" outset={{ top: '3px' }}>
              {subtitle}
            </Text>
          </>
        )}
      </div>
      {lines && (
        <>
          <div css={styles.contextActions} onClick={() => toggle()}>
            <span>
              <Div display="inline-block">
                <Text type="caption" color="primaryHeading">{`${
                  isOpen ? 'Hide' : 'View'
                } Cards`}</Text>
              </Div>
              <Icon className="material-icons" css={styles.contextActionsIcon}>
                {isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
              </Icon>
            </span>
          </div>

          {isOpen && (
            <div css={styles.contextDrawer}>
              <div css={styles.drawerContents}>
                {lines.map(line => {
                  return (
                    <div
                      key={Math.random()}
                      style={
                        activeCardId === line.card?.id
                          ? { position: 'relative', border: '1px solid black' }
                          : { position: 'relative' }
                      }
                      css={
                        line.card?.isHorizontal
                          ? styles.landscape
                          : styles.portrait
                      }
                    >
                      <img src={line.card?.frontPreviewUrl ?? ''} alt="" />
                      <span
                        style={{
                          position: 'absolute',
                          bottom: '-30px',
                          left: '50%',
                          transform: 'translateX(-50%)',
                        }}
                      >
                        <Text type="caption" color="primaryHeading">
                          {line.sendDelay !== undefined
                            ? SendDelayOptions.ribbonDescription(line.sendDelay)
                            : line.card && line.card.cost
                            ? getTotal(line.card.cost)
                            : ''}
                        </Text>
                      </span>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default ContextBar
