import React, { FC } from 'react'
import { SVGProps } from './types'

export const Ticket: FC<SVGProps> = ({ size, color, onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 19 18"
      width={size}
      height={size}
      onClick={onClick}
    >
      <path
        fill={color}
        d="M8.291 0h2.953c.311.12.416.35.416.675-.005 5.55-.005 11.1 0 16.65 0 .325-.106.556-.416.675H8.29c-.19-.083-.306-.23-.41-.408-.912-1.569-3.19-1.569-4.103 0-.103.177-.22.325-.41.409H.416C.106 17.88 0 17.65 0 17.325c.005-5.55.005-11.1 0-16.65C0 .35.105.119.416 0h2.953c.189.084.306.231.41.409.912 1.568 3.187 1.568 4.103 0 .103-.177.22-.326.41-.409zm2.294 1.058h-.2c-.487 0-.973.004-1.46-.003a.323.323 0 00-.3.15c-.663.898-1.552 1.397-2.671 1.432-1.24.038-2.22-.474-2.953-1.47-.034-.046-.094-.104-.142-.105-.59-.007-1.181-.004-1.78-.004v15.884c.599 0 1.184.003 1.77-.005.053-.001.12-.06.156-.11a3.437 3.437 0 015.644.001.242.242 0 00.23.116c.369-.006.738-.002 1.108-.002h.597V1.058z"
      />
      <path
        fill={color}
        d="M8.216 10.846V7.453c0-.319.198-.547.48-.565a.53.53 0 01.575.492c.004.035.002.07.002.106 0 2.244 0 4.487.002 6.731 0 .252-.084.448-.32.558-.351.164-.737-.095-.738-.502-.004-1.142-.001-2.285-.001-3.427zm-4.772.014c0 1.137.003 2.273 0 3.41-.002.38-.325.63-.672.531a.52.52 0 01-.382-.494c-.004-2.302-.004-4.604 0-6.907 0-.299.243-.517.535-.514a.514.514 0 01.517.53c.004 1.148.002 2.296.002 3.444zm2.914-.523v2.881a.512.512 0 01-.304.493.495.495 0 01-.532-.05c-.165-.118-.222-.287-.222-.489.003-1.516.002-3.033.002-4.549V7.481c0-.36.207-.593.525-.595.32-.001.531.232.531.588v2.863zm-.541-5.041c-.164 0-.328.005-.491 0-.329-.013-.554-.235-.552-.536.002-.3.23-.519.56-.524a26.32 26.32 0 011 0c.322.006.546.222.552.515.006.303-.217.53-.543.543-.175.008-.351.002-.526.002zm-2.89-1.061c.17 0 .34-.005.509.001.319.013.537.232.537.53 0 .295-.219.52-.538.528-.345.01-.69.01-1.035 0-.316-.009-.541-.239-.54-.53 0-.291.225-.515.541-.527.175-.007.35-.002.526-.002zm5.816 1.061c-.17 0-.34.006-.509-.001-.329-.013-.55-.235-.547-.54.004-.294.225-.512.547-.519.339-.007.678-.007 1.017 0 .319.007.545.227.55.519.005.298-.224.526-.55.54-.169.007-.339 0-.508 0z"
      />
    </svg>
  )
}
