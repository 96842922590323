import { Operation } from '../../../__generated__/globalTypes'

import {
  StorefrontFragment,
  storefrontFragmentString,
} from '../../fragments/__generated__/StorefrontFragment'

import updateStorefrontMugshotRawString from '../UpdateStorefrontMugshot.graphql'

const updateStorefrontMugshotString = [
  updateStorefrontMugshotRawString,
  storefrontFragmentString,
].join('\n\n')

export type UpdateStorefrontMugshotData = {
  updateStorefrontMugshot: {
    storefront: StorefrontFragment
  }
}

export type UpdateStorefrontMugshot = Operation<UpdateStorefrontMugshotData>

export const UpdateStorefrontMugshot = (): UpdateStorefrontMugshot => ({
  query: updateStorefrontMugshotString,
  variables: undefined,
})
