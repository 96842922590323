import React from 'react'

import { RouteMeta } from 'src/routes/Route'
import { findRoute } from 'src/routes/utilities'
import { useSelector } from '../../hooks'

interface NavLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  activeClassName?: string // If you specify an activeClassName you must also specify a route
  onClick: () => void
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  route?: RouteMeta<any, any, []>
}

const NavLink: React.FC<NavLinkProps> = props => {
  const { activeClassName, children, className, id, onClick, route } = props

  const selectedRoute = useSelector(state => state.route)

  return (
    /* eslint-disable-next-line */
    <a
      id={id}
      className={
        route && findRoute(selectedRoute, route)
          ? activeClassName
            ? activeClassName
            : className
          : className
      }
      onClick={() => onClick()}
      style={{ cursor: 'pointer' }}
    >
      {children}
    </a>
  )
}

export default NavLink
