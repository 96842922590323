import React, { FC } from 'react'
import { SVGProps } from './types'

export const CatalogCard: FC<SVGProps> = ({ size, color, onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 63 90"
      width={size}
      height={size}
      onClick={onClick}
    >
      <path
        fill={color}
        d="M61.48 11.742h-6.838V1.53A1.504 1.504 0 0053.172 0c-.105 0-.21 0-.315.03L1.2 10.362c-.705.135-1.2.78-1.2 1.5V88.5c0 .825.675 1.5 1.5 1.5h59.98c.824 0 1.499-.675 1.499-1.5V13.24c0-.825-.675-1.5-1.5-1.5zm-9.837-8.383v8.383H9.657l41.986-8.383zm8.337 83.642H2.999v-72.26H59.98V87z"
      />
      <path
        fill={color}
        d="M11.006 51.628h39.182c1.515 0 1.785-.584 1.785-2.264V26.872c0-1.725-.27-2.295-1.83-2.295H10.812c-1.125 0-1.605.555-1.605 1.83v23.047c0 1.724.375 2.16 1.784 2.16l.015.014zm.75-4.063v-18.91l15.895 9.448-15.895 9.447v.015zM37.922 32a687.571 687.571 0 01-6.927 4.093c-.225.135-.51.165-.66.075a2843.85 2843.85 0 01-12.67-7.512l-1.41-.84h28.67l-7.018 4.169.015.015zm-7.017 8.067c4.018 2.354 8.082 4.783 12.026 7.123l2.099 1.244H16.21s.09-.06.12-.09l1.47-.87c4.048-2.413 8.246-4.918 12.37-7.347.225-.135.555-.165.735-.06zm2.639-1.964l15.88-9.447v18.879l-15.88-9.447v.015zm16.989 22.102H35.568c-1.47 0-1.77.885-1.77 2.22V76.82c0 1.334.3 2.22 1.77 2.22h14.905c1.5 0 1.815-.87 1.815-2.19V62.365c0-1.125-.21-2.16-1.755-2.175v.015zm-1.184 3.6v11.65H36.738v-11.65h12.61zm-38.568 7.587h14.68c1.125 0 1.77-.63 1.785-1.74 0-.555-.15-1.004-.45-1.32-.315-.314-.78-.48-1.364-.48H10.825c-1.094 0-1.784.676-1.799 1.74 0 .54.15.99.465 1.305.315.315.765.48 1.29.48v.015zm-.794-7.798c.315.135.645.15.914.15h14.456c.18 0 .374 0 .57-.03.81-.12 1.334-.84 1.319-1.8-.015-.944-.585-1.619-1.41-1.694H10.751c-.15 0-.314 0-.464.03-.705.12-1.155.69-1.245 1.56-.075.855.285 1.53.945 1.814v-.03zM25.761 75.53c-.72-.015-1.424-.015-2.144-.015H10.541c-.9.075-1.529.81-1.514 1.8 0 1.02.66 1.724 1.575 1.724H25.52c.255 0 .57 0 .84-.15.645-.345.96-1.02.855-1.86-.12-.944-.645-1.499-1.47-1.514l.015.015z"
      />
    </svg>
  )
}
