import { Maybe } from '../../../__generated__/globalTypes'

import membershipInviteFragmentRawString from '../MembershipInviteFragment.graphql'

export const membershipInviteFragmentString = membershipInviteFragmentRawString

export type MembershipInviteFragment = {
  __typename: 'MembershipInvite'
  organizationName: string
  organizationOwnerName: Maybe<string>
  createdAt: string
}

export const isMembershipInviteFragment = (
  fragment: any,
): fragment is MembershipInviteFragment =>
  fragment &&
  fragment.__typename === 'MembershipInvite' &&
  typeof fragment.organizationName === 'string' &&
  (fragment.organizationOwnerName === null ||
    typeof fragment.organizationOwnerName === 'string') &&
  typeof fragment.createdAt === 'string'
