import React from 'react'
// @src imports
import { Icon } from 'src/chrome'
import {
  CustomCardFragment,
  MinimalSendableCardFragment,
} from 'src/legacy_graphql'
// relative imports
import CardFlip from '../CardFlip/CardFlip'

import styles from './headers.module.scss'

interface Props {
  title: string
  textColor: string
  backgroundStartColor: string
  backgroundEndColor: string
  card: MinimalSendableCardFragment | CustomCardFragment
  handleFavoriteCard: (id: string, isFavorite: boolean) => void
  onClose: () => void
}

const CollectionHeader: React.FC<Props> = props => {
  const {
    backgroundEndColor,
    backgroundStartColor,
    card,
    handleFavoriteCard,
    onClose,
    textColor,
    title,
  } = props

  return (
    <div
      className={styles.reasonToSend}
      style={{
        backgroundImage: `linear-gradient(115deg, ${backgroundStartColor}, ${backgroundEndColor})`,
      }}
    >
      <div className={styles.closeCollection} onClick={onClose}>
        <Icon icon={'ARROWLEFT'} color={textColor} size={18} />
        <span style={{ color: textColor, fontSize: '0.8rem' }}>Back</span>
      </div>
      <div className={styles.reasonBackground}>
        <div className={styles.featureContent}>
          <div className={styles.feature}>
            <CardFlip
              card={card}
              handleFavoriteCard={handleFavoriteCard}
              isButtonEnabled={true}
            />
          </div>
          <div className={styles.reason}>
            <h2
              style={textColor ? { color: textColor, fontSize: '2.8rem' } : {}}
            >
              {title}
            </h2>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CollectionHeader
