import React from 'react'
import {
  formatAmount,
  getRemainingCharges,
  getTodaysCharges,
} from 'src/helpers/payments'
import { Tooltip } from 'src/chrome'
import { OrderWithPartialLines } from 'src/redux/reducers/orders'
import {
  formatCost,
  getCondensedTotal,
  getCosts,
  getGiftTotal,
  getTotal,
  hasExpenseBucket as getHasExpenseBucket,
  recipientCount,
  willUsePoints,
} from 'src/helpers'

import styles from './invoice.module.scss'
import { useAccount } from 'src/hooks'
import { Spacer, Text } from '@sendoutcards/quantum-design-ui'
import {
  getCardBaseCostFormatted,
  getLineCardCost,
  getLineCardCostTotalNoDiscounts,
} from 'src/helpers/getCosts'
import { Amount } from 'src/graphql/generated/graphql'

interface InvoiceProps {
  order: OrderWithPartialLines
  credits?: Amount
  neededCurrency?: { points: number; expense: number; credits: number }
  isUsingCredits?: boolean
}

const tooltipMessages = {
  today: `We charge your credit card the day the cards are sent.
  Today's charges shows the total charge for cards, gifts, and shipping today.`,
  remaining: `The charges that remain through the life of the order, since your credit card is only
  charged once the cards are sent, you will not be charged the full amount today. Depending on subscription
  or shipping cost changes, you can expect this amount to be charged.`,
  expenseRoundup: `Expense purchase is rounded up to the nearest dollar`,
  unaltered: `Expense and/or Credit Balances will stay unaltered until the cards are printed`,
}

const Invoice: React.FC<InvoiceProps> = props => {
  const { order, neededCurrency, credits, isUsingCredits } = props
  const account = useAccount()
  const isFreeOrder = getTotal(order.cost) === 'FREE'
  const isBulk = order.isBulk

  const remainingCharges = getRemainingCharges(order.lines)
  const hasExpenseBucket = getHasExpenseBucket(account)

  const tieredPricing = (neededPoints: number | undefined = 0) => {
    return neededPoints < 100
      ? 0.66
      : neededPoints < 200
      ? 0.59
      : neededPoints < 350
      ? 0.49
      : 0.43
  }

  const pointPricing =
    account.pointPrice === 66
      ? tieredPricing(neededCurrency?.points)
      : account.pointPrice / 100

  const addPlural = (value: number) => (value !== 1 ? 's' : '')

  const getPointsExpenseCredits = () => {
    if (
      !isBulk &&
      neededCurrency &&
      ((hasExpenseBucket && neededCurrency.expense > 0) ||
        (willUsePoints(order) && neededCurrency.points > 0))
    ) {
      return (
        <div className={styles.neededCurrencyGrid}>
          <Text type="caption">Current points &amp; expense:</Text>
          <Text type="caption">{`${account.points.amount} point${addPlural(
            account.points.amount,
          )}`}</Text>
          <Text type="caption">{account.expense.description}</Text>
          <Text type="caption">Needed points &amp; expense:</Text>
          <Text type="caption">
            {neededCurrency.points
              ? `${neededCurrency.points} point${addPlural(
                  neededCurrency.points,
                )} @ ${pointPricing} cents/point`
              : '0 points'}
          </Text>
          <Text type="caption">
            {neededCurrency.expense
              ? `$${formatAmount(neededCurrency.expense)}`
              : '$0.00'}
          </Text>
          <Text type="caption" weight="bold" inset={{ right: 'x_5' }}>
            Charges to your card:
          </Text>
          <Text type="caption" weight="bold">{`$${
            neededCurrency.points
              ? formatAmount(neededCurrency.points * pointPricing)
              : '0.00'
          } (points) + tax`}</Text>
          <div className={styles.expenseInfo}>
            <Text type="caption" weight="bold" outset={{ right: 'x1' }}>{`$${
              neededCurrency.expense
                ? formatAmount(neededCurrency.expense, true)
                : '0.00'
            } (expense) + tax`}</Text>
            <Tooltip
              hoverIcon={'INFO'}
              iconSize={12}
              message={`${tooltipMessages.expenseRoundup}, ${tooltipMessages.unaltered}`}
              position={'left'}
            />
          </div>
        </div>
      )
    }
    if (neededCurrency && hasExpenseBucket && neededCurrency.expense > 0) {
      return (
        <div className={styles.neededCurrency2ColumnGrid}>
          <Text type="caption">Current expense:</Text>
          <Text type="caption">{account.expense.description}</Text>
          <Text type="caption">Needed expense:</Text>
          <Text type="caption">
            {neededCurrency.expense
              ? `$${formatAmount(neededCurrency.expense)}`
              : '$0.00'}
          </Text>
          <Text type="caption" weight="bold" inset={{ right: 'x_5' }}>
            Charges to your card:
          </Text>
          <div className={styles.expenseInfo}>
            <Text type="caption" weight="bold" outset={{ right: 'x1' }}>{`$${
              neededCurrency.expense
                ? formatAmount(neededCurrency.expense, true)
                : '0.00'
            } (expense) + tax`}</Text>
            <Tooltip
              hoverIcon={'INFO'}
              iconSize={12}
              message={`${tooltipMessages.expenseRoundup}, ${tooltipMessages.unaltered}`}
              position={'left'}
            />
          </div>
        </div>
      )
    }
    if (
      !isBulk &&
      neededCurrency &&
      credits &&
      credits.amount > 0 &&
      neededCurrency.credits > 0
    ) {
      return (
        <div className={styles.neededCurrency2ColumnGrid}>
          <Text type="caption">Current credits:</Text>
          <Text type="caption">{credits.description}</Text>
          <Text type="caption">Needed credits:</Text>
          <Text type="caption">
            {`$${formatAmount(neededCurrency.credits)}`}
          </Text>
          <Text type="caption" weight="bold" inset={{ right: 'x_5' }}>
            Charges to your card:
          </Text>
          <div className={styles.expenseInfo}>
            <Text type="caption" weight="bold" outset={{ right: 'x1' }}>{`${
              isUsingCredits
                ? `$${formatAmount(neededCurrency.credits)}`
                : getCondensedTotal(order.cost)
            } + tax`}</Text>
            <Tooltip
              hoverIcon={'INFO'}
              iconSize={12}
              message={tooltipMessages.unaltered}
              position={'left'}
            />
          </div>
        </div>
      )
    }
    return <div />
  }

  return (
    <div className={styles.invoiceContainer}>
      <div className={styles.details}>
        <div className={styles.detailsHeader}>
          <span className={`${styles.cell} ${styles.card}`}>
            <Text type="caption">Item</Text>
          </span>
          <div className={styles.priceTotalContainer}>
            <span className={`${styles.cell} ${styles.price}`}>
              <Text type="caption">Price</Text>
            </span>
            <span className={`${styles.cell} ${styles.total}`}>
              <Text type="caption">Total</Text>
            </span>
          </div>
        </div>
        {order.lines.map(line => {
          const lineCardCostTotalNoDiscounts = getLineCardCostTotalNoDiscounts(
            line,
            order,
          )
          const lineCardCostTotals = getLineCardCost(line)
          const hasDiscounts = lineCardCostTotalNoDiscounts > lineCardCostTotals
          const isCardCostFree =
            line.cost?.entries.some(
              price => price.label === 'Card Cost' && price.asString === '0.00',
            ) ?? false

          const isGiftCostFree =
            line.cost?.entries.some(
              price => price.label === 'Gift Cost' && price.asString === '0.00',
            ) ?? false

          return (
            <div key={line.card?.id} className={styles.line}>
              <div className={styles.cardLine}>
                <div
                  style={{ display: 'flex', alignItems: 'center' }}
                  className={`${styles.cell} ${styles.card}`}
                >
                  <div
                    style={{
                      backgroundImage: `url(${line.card?.panels[0].previewUrl})`,
                    }}
                    className={
                      line.card?.isHorizontal
                        ? `${styles.cardThumbnail} ${styles.landscape}`
                        : `${styles.cardThumbnail} ${styles.portrait}`
                    }
                  />
                  <div className={styles.cardTitle}>
                    <Text type="caption">
                      {line.card?.detailedSendableCard?.title ?? 'Custom Card'}
                    </Text>
                  </div>
                </div>
                <div className={styles.priceTotalContainer}>
                  <div
                    className={`${styles.itemCost} ${styles.cell} ${styles.price}`}
                  >
                    <Text
                      type="caption"
                      color="primaryHeading"
                      isStrikeThrough={isCardCostFree}
                    >
                      {`${recipientCount(order)} @ ${getCardBaseCostFormatted(
                        line,
                      )}`}
                    </Text>
                  </div>
                  <div
                    className={`${styles.itemCost} ${styles.cell} ${styles.total}`}
                  >
                    {hasDiscounts && (
                      <Text
                        type="caption"
                        color="primaryHeading"
                        isStrikeThrough={hasDiscounts}
                      >
                        {formatCost(lineCardCostTotalNoDiscounts)}
                      </Text>
                    )}
                    <Text type="caption" color="primaryHeading">
                      {formatCost(lineCardCostTotals)}
                    </Text>
                  </div>
                </div>
              </div>
              {line.giftVariation && (
                <div className={styles.giftLine}>
                  <div
                    style={{ display: 'flex', alignItems: 'center' }}
                    className={`${styles.cell} ${styles.card}`}
                  >
                    <div
                      style={{
                        backgroundImage: `url(${line.giftVariation.defaultImageUrl})`,
                      }}
                      className={styles.giftThumbnail}
                    />
                    <div className={styles.giftTitle}>
                      <Text type="caption">
                        {line.giftVariation.name || 'Gift'}
                      </Text>
                    </div>
                  </div>
                  <div className={styles.priceTotalContainer}>
                    <div
                      className={`${styles.itemCost} ${styles.cell} ${styles.price}`}
                    >
                      <Text
                        type="caption"
                        color="primaryHeading"
                        isStrikeThrough={isGiftCostFree}
                      >
                        {`${recipientCount(order)} @ ${getGiftTotal(
                          line.giftVariation,
                        )}`}
                      </Text>
                    </div>
                    <div
                      className={`${styles.itemCost} ${styles.cell} ${styles.total}`}
                    >
                      <Text
                        type="caption"
                        color="primaryHeading"
                        isStrikeThrough={isFreeOrder || isGiftCostFree}
                      >
                        {getGiftTotal(
                          line.giftVariation,
                          recipientCount(order),
                        )}
                      </Text>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )
        })}
        {getCosts(order.cost).map(entry => (
          <div key={entry.label} className={styles.totals}>
            <Text
              type="caption"
              color="primaryHeading"
              weight="bold"
            >{`${entry.label}:`}</Text>
            <Spacer space="x1" orientation="horizontal" />
            <Text
              type="caption"
              color="primaryHeading"
            >{`${entry.amount}`}</Text>
            <Spacer space="x2_5" orientation="horizontal" />
          </div>
        ))}
        <div className={styles.grandTotal}>
          <div className={styles.totalItem}>
            <Text type="largeBody" color="primaryHeading" weight="bold">
              {remainingCharges === '$0.00' ? 'Total:' : 'Estimated Total:'}
            </Text>
            <h5 id={'item_total'}>
              <Text type="largeBody" color="primaryHeading" weight="bold">
                {getCondensedTotal(order.cost)}
              </Text>
            </h5>
          </div>
          {!hasExpenseBucket && remainingCharges !== '$0.00' && (
            <>
              <div className={styles.totalItem} style={{ marginTop: 25 }}>
                <Tooltip hoverIcon={'INFO'} message={tooltipMessages.today} />
                <Spacer space="x1" orientation="horizontal" />
                <Text type="largeBody" color="primaryHeading" weight="bold">
                  Today's Charges:{' '}
                </Text>
                <Spacer space="x1" orientation="horizontal" />
                <Text type="largeBody" color="primaryHeading" weight="bold">
                  {getTodaysCharges(order.lines)}
                </Text>
              </div>
              <div className={styles.totalItem}>
                <Tooltip
                  hoverIcon={'INFO'}
                  message={tooltipMessages.remaining}
                />
                <Spacer space="x1" orientation="horizontal" />
                <Text type="largeBody" color="primaryHeading" weight="bold">
                  Remaining Charges:{' '}
                </Text>
                <Spacer space="x1" orientation="horizontal" />
                <Text type="largeBody" color="primaryHeading" weight="bold">
                  {remainingCharges}
                </Text>
              </div>
            </>
          )}
          {order.cost?.entries?.find(e => e.label === 'Volume Discount') && (
            <>
              <Spacer space="x1_5" />
              <Text type="caption">Volume discount:</Text>
              <Text type="caption">
                By purchasing over 25 cards you receive a discount. This is
                reflected in the volume discount deduction from the total amount
                shown above.
              </Text>
              <Spacer space="x1_5" />
            </>
          )}
          {getPointsExpenseCredits()}
        </div>
      </div>
    </div>
  )
}

export default Invoice
