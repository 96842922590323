import React from 'react'
import { useActions } from 'src/hooks'
import {
  AnimatedCheckMark,
  Button,
  Div,
  Flex,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'

const ThankYou = (props: {
  chosenSponsor: string | undefined
  onClose: () => void
  shouldFinishAccountSetup?: boolean
}) => {
  const actions = useActions()
  const { onClose, shouldFinishAccountSetup } = props
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      width="100%"
    >
      <AnimatedCheckMark size={'large'} filled={false} />
      <Spacer space="x4" />
      <Text type="title" weight={'semiBold'}>
        Thank you!
      </Text>
      <Spacer space="x2" />
      <Flex flexDirection="column">
        <>
          <Flex>
            {!shouldFinishAccountSetup ? (
              <Text type="caption" alignment="center">
                Congratulations, your account is set up and your purchase is
                confirmed.
              </Text>
            ) : (
              <Text type="caption" alignment="center">
                Congratulations, your account is set up and you may now complete
                your purchase.
              </Text>
            )}
          </Flex>
        </>
      </Flex>
      <Spacer space="x5" />
      <Div>
        <Button
          hover={true}
          title={
            !shouldFinishAccountSetup
              ? 'Browse Card Catalog'
              : 'Finish My Purchase'
          }
          onClick={onClose}
          size="medium"
        />
      </Div>
      {!shouldFinishAccountSetup && (
        <div style={{ margin: '20px 0 0 0' }}>
          <Div>
            <Button
              hover={true}
              title="Back to my Account"
              onClick={() => actions.openAccount()}
              size="medium"
            />
          </Div>
        </div>
      )}
    </Flex>
  )
}

export default ThankYou
