import { Interpolation } from '@emotion/core'
import { Div, Flex, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import React, { FC } from 'react'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'

const styles = {
  flyingPlane: {
    animation: 'floatAnim 1.6s ease-in-out infinite alternate',
    position: 'relative',
    '@keyframes floatAnim': {
      '0%': {
        top: '-15px',
      },
      '100%': {
        top: '15px',
      },
    },
  } as Interpolation,
}

export const ProcessingPurchase: FC = () => {
  return (
    <>
      <Flex
        backgroundColor="rgba(245,245,244,0.6)"
        position="absolute"
        style={{ backdropFilter: 'blur(4px)' }}
        top="x0"
        left="x0"
        width="100%"
        height="100%"
        borderRadius="12px"
      />
      <Flex
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        inset="x5"
      >
        <Div css={styles.flyingPlane}>
          <Icon name="paper_plane_flying" size={170} />
        </Div>
        <Spacer space="x5" />
        <Text type="subtitle" alignment="center" weight="bold">
          Hang tight while we send your order through the system!
        </Text>
      </Flex>
    </>
  )
}
