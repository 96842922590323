/* tslint:disable:no-invalid-this */
import anime, { AnimeAnimParams } from 'animejs'
import { noop } from 'src/helpers/appHelpers'

const timing: Record<string, number> = {
  t1: 200,
  t2: 300,
  t3: 500,
  t4: 750,
  t6: 1000,
}

const animations = {
  fade: {
    in(el: HTMLElement, durationIndex: number, delay = 0, cb = noop) {
      const animeObj: AnimeAnimParams = {
        targets: el,
        opacity: 1,
        duration: timing['t' + durationIndex] || durationIndex,
        complete: cb,
        delay,
        easing: 'easeInOutQuart',
        animate() {
          return anime(this)
        },
      }
      return animeObj
    },
    out(el: HTMLElement, durationIndex: number, delay = 0, cb = noop) {
      const animeObj: AnimeAnimParams = {
        targets: el,
        opacity: 0,
        duration: timing['t' + durationIndex] || durationIndex,
        complete: cb,
        delay,
        easing: 'easeInOutQuart',
        animate() {
          return anime(this)
        },
      }
      return animeObj
    },
  },
  slide: {
    fromLeft(mutableEl: HTMLElement, durationIndex: number, cb = noop) {
      mutableEl.style.transform = 'translateX(-50px)'
      const animeObj: AnimeAnimParams = {
        targets: mutableEl,
        translateX: 0,
        opacity: 1,
        duration: timing['t' + durationIndex] || durationIndex,
        complete: cb,
        elasticity: (el: HTMLElement, index: number, l: number) => 0,
        loop: true,
        animate() {
          return anime(this)
        },
      }
      return animeObj
    },
    toRight(el: HTMLElement, durationIndex: number, cb = noop) {
      return anime({
        targets: el,
        translateX: 50,
        opacity: 0,
        duration: timing['t' + durationIndex],
        complete: cb,
      } as AnimeAnimParams)
    },
    x(
      el: HTMLElement | HTMLElement[],
      durationIndex: number,
      x: number | number[],
      cb = noop,
    ) {
      const animeObj: AnimeAnimParams = {
        targets: el,
        translateX: x,
        duration: timing['t' + durationIndex],
        complete: cb,
        elasticity: 0,
        easing: 'easeInQuad',
        animate() {
          return anime(this)
        },
      }
      return animeObj
    },
    y(
      el: HTMLElement | HTMLElement[],
      durationIndex: number,
      y: number | number[],
      cb = noop,
    ) {
      const animeObj: AnimeAnimParams = {
        targets: el,
        translateY: y,
        duration: timing['t' + durationIndex],
        complete: cb,
        elasticity: 0,
        easing: 'easeInQuad',
        animate() {
          return anime(this)
        },
      }
      return animeObj
    },
  },
  timeline(sequenceObjsArray: anime.AnimeAnimParams[]) {
    const timeline = anime.timeline()
    sequenceObjsArray.reduce((time, obj) => {
      const { animate, ...rest } = obj
      return time.add(rest)
    }, timeline)
    return timeline
  },
}

export default animations
