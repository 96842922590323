import React from 'react'
import Button from '@material-ui/core/Button'

import { Button as ChromeButton, Confirm } from 'src/chrome'
import { useActions, useMutations, useState } from 'src/hooks'
import { Text } from '@sendoutcards/quantum-design-ui'
import { cssStyles } from 'src/styled'

const styles = cssStyles({
  footer: {
    position: 'fixed',
    bottom: 0,
    left: '65px',
    height: '50px',
    width: 'calc(100% - 65px)',
    padding: 0,
    background: '#fff',
    boxShadow: '0 2px 28px -2px #dedede',
    zIndex: 105,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '17px',
    '@media (max-width: 756px)': {
      left: 0,
      width: '100%',
      bottom: '65px',
    },
  },
  footerActions: {
    display: 'flex',
    justifyContent: 'space-evenly',
    minWidth: '250px',
  },
})

type CampaignFooterProps = {
  cards: number
  isSaved: boolean
  isSaving: boolean
  isInvalid: boolean
  editableCampaignId: string
  name: string
  onSave: () => void
}

const CampaignFooter: React.FC<CampaignFooterProps> = props => {
  const {
    cards,
    isSaved,
    isSaving,
    isInvalid,
    editableCampaignId,
    name,
    onSave,
  } = props

  const actions = useActions()
  const mutations = useMutations()

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const handleDelete = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen)
    mutations.deleteCampaign({ id: editableCampaignId })
    actions.openCampaigns()
  }

  return (
    <>
      <footer css={styles.footer}>
        <Text type="body" weight="bold" color="primaryHeading">
          {cards === 1 ? `${cards} card` : `${cards} cards`}
        </Text>
        <div css={styles.footerActions}>
          <Button onClick={() => setIsDeleteModalOpen(!isDeleteModalOpen)}>
            Delete Campaign
          </Button>
          <ChromeButton
            id="save_campaign_btn"
            title={isSaving ? 'Saving...' : 'Save'}
            buttonColor={'pink'}
            disabled={(isSaved && isInvalid) || isSaving}
            onClick={onSave}
          />
        </div>
      </footer>
      {isDeleteModalOpen && (
        <Confirm
          title={'Delete Campaign: ' + name}
          message={'Are you sure you want to permanently delete this campaign?'}
          confirmTitle={'Delete'}
          onDecline={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
          onConfirm={handleDelete}
        />
      )}
    </>
  )
}

export default CampaignFooter
