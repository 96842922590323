import * as mutations from 'src/legacy_graphql/mutationDeclarations'
import { useStore } from 'react-redux'
import { useMemo } from '.'
import mapValues from 'lodash/mapValues'

type Mutations = typeof mutations

type MappedMutations = {
  [Key in keyof Mutations]: ReturnType<Mutations[Key]>
}

const useMutations = () => {
  const store = useStore()
  return useMemo(() => mapValues(mutations, mutation => mutation(store)), [
    store,
  ]) as MappedMutations
}

export default useMutations
