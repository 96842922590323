import React from 'react'

import { AddressFormModal } from 'src/contacts/components'
import {
  Blinker,
  DefaultError,
  Icon,
  Pagination,
  RwdCell,
  RwdHeader,
  RwdRow,
  RwdTable,
  Search,
  Tooltip,
  Transition,
} from 'src/chrome'
import {
  ContactFragment,
  CreateContactInput,
  getDetailedCountries,
  getPaginatedContacts,
} from 'src/legacy_graphql'
import {
  buildUpdateOrderInput,
  OrderWithPartialLines,
} from 'src/redux/reducers/orders'
import { isFullContact } from 'src/helpers'
import {
  useAccount,
  useActions,
  useMutations,
  useQueries,
  useSelector,
  useState,
} from 'src/hooks'

import { getDelayTypes, getIssues } from '../../InvalidContacts'
import styles from './recipients.module.scss'
import suspenseBoundary from 'src/chrome/SuspenseBoundary/suspenseBoundaryHOC'
import { FlexRow } from 'src/styled'
import { Div, Flex, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import { FormHeader } from 'src/orders/components/FormHeader'
import { Button } from 'src/design_system/molecules/button/Button'
import { Icon as DSIcon } from 'src/design_system/atoms/icons/components/Icon'
import HoverTooltip from 'src/chrome/HoverTooltip/HoverTooltip'
import { ORDER_DETAIL_RECIPIENTS } from 'src/orders/constants'
import { useUpdateOrder } from 'src/react_query'

type Props = {
  shouldBlinkIfEmpty?: boolean
  order: OrderWithPartialLines
  openAddressBook: () => void
  openImportManager: () => void
}

const Recipients: React.FC<Props> = props => {
  const {
    shouldBlinkIfEmpty,
    order,
    openAddressBook,
    openImportManager,
  } = props
  const actions = useActions()
  const orders = useSelector(state => state.orders)
  const [contactBeingEdited, setContactBeingEdited] = useState<
    ContactFragment
  >()

  const ids = order.contacts.map(contact => contact.id)

  const [pageNumber, setPageNumber] = useState(1)
  const [search, setSearch] = useState('')
  const mutations = useMutations()
  const { mutateAsync } = useUpdateOrder()
  const { isQualifiedForFirstFreeCard } = useAccount()

  const [{ results: page, count, pageSize }, detailedCountries] = useQueries(
    getPaginatedContacts({
      search,
      contacts: ids,
      showSecondaryContacts: true,
      page: pageNumber,
    }),
    getDetailedCountries(),
  )

  const USStates =
    detailedCountries
      .find(detailedCountry => detailedCountry.postalName === 'United States')
      ?.subdivisions.map(sub => sub.abbreviationOrName)
      .concat(['AA', 'AE', 'AP']) ?? []

  const sendDelayTypes = getDelayTypes(order.lines)

  const handleSaveContact = async (contact: CreateContactInput) => {
    if (!contact.id) return
    setContactBeingEdited(undefined)
    mutations.updateContact({
      contact: {
        id: contact.id,
        firstName: contact.firstName,
        lastName: contact.lastName,
        companyName: contact.companyName,
        address1: contact.address1,
        address2: contact.address2,
        city: contact.city,
        state: contact.state,
        postalCode: contact.postalCode,
        country: contact.country,
        birthday: contact.birthday,
        anniversary: contact.anniversary,
      },
    })
    if (orders.order) {
      actions.setIsUpdatingOrder(true)
      try {
        const result = await mutateAsync({
          order: buildUpdateOrderInput(orders.order),
        })
        actions.savedOrder(result.updateOrder.order)
      } catch (error) {
        console.log(`Failed to update order: ${error}`)
      } finally {
        actions.setIsUpdatingOrder(false)
      }
    }
  }

  const isRecipientBreakPoint = useSelector(state => state.window.width < 970)
  const { isMobile } = useSelector(state => state.window)
  const isSmallMobile = useSelector(state => state.window.width < 475)

  const shouldDisableAddRecipients =
    isQualifiedForFirstFreeCard && order.contacts.length >= 1

  return (
    <Flex
      flexDirection="column"
      className={styles.attachedAddressContainer}
      borderRadius="16px"
      inset="x3"
      backgroundColor="foreground"
      width="100%"
    >
      <Flex
        id={ORDER_DETAIL_RECIPIENTS}
        flexDirection={isSmallMobile ? 'column' : 'row'}
        width="100%"
      >
        <FormHeader
          step={1}
          title="Add Recipient(s)"
          description={
            'Select the contact(s) you would like this order to be sent to.'
          }
        />
        {!isSmallMobile && <Spacer space="x2" orientation="horizontal" />}
        {!order.isSent && (
          <Flex
            justifyContent={isSmallMobile ? 'center' : 'flex-end'}
            width={isSmallMobile ? '100%' : '50%'}
            height="fit-content"
            inset={{ vertical: 'x2' }}
            justifyItems="center"
            flexDirection="row"
            alignItems={isSmallMobile ? 'center' : undefined}
            columnGap="x2"
          >
            <Button
              id={'add_recipients_btn'}
              title={
                !isRecipientBreakPoint
                  ? {
                      content: 'Add Recipients',
                      fontSize: '12px',
                    }
                  : undefined
              }
              onClick={openAddressBook}
              fill="#f5f6f7"
              gap="x_5"
              padding={isRecipientBreakPoint ? '8px 12px' : '12px 16px'}
              borderRadius="small"
            >
              <HoverTooltip
                shouldDisplayTooltip={isRecipientBreakPoint}
                title="Upload Contacts"
                background="dark"
              >
                <DSIcon name="contact" size={isRecipientBreakPoint ? 17 : 15} />
              </HoverTooltip>
            </Button>
            <Button
              id={'upload_contacts_btn'}
              title={
                !isRecipientBreakPoint
                  ? {
                      content: 'Upload Recipients',
                      fontSize: '12px',
                      shouldUnderLine: true,
                    }
                  : undefined
              }
              onClick={openImportManager}
              gap="x_5"
              padding={isRecipientBreakPoint ? '8px 12px' : '12px 16px'}
              borderRadius="small"
              boxShadow={
                isRecipientBreakPoint
                  ? ' rgba(0, 0, 0, 0.04) 0px 3px 5px'
                  : undefined
              }
            >
              <HoverTooltip
                shouldDisplayTooltip={isRecipientBreakPoint}
                title="Upload Contacts"
                background="dark"
              >
                <DSIcon name="upload" size={isRecipientBreakPoint ? 19 : 17} />
              </HoverTooltip>
            </Button>
            {shouldBlinkIfEmpty && !order.contacts.length && (
              <Blinker
                style={{ bottom: 7 }}
                messagePosition={{
                  position: 'absolute',
                  top: -95,
                  right: 85,
                }}
                message={'Please add recipients to your order'}
              />
            )}
          </Flex>
        )}
      </Flex>
      {order.contacts.length <= 0 && (
        <Div
          display="flex"
          justifyContent="center"
          width="100%"
          backgroundColor="#FCFCFC"
          inset={{ vertical: 'x4' }}
          borderRadius="small"
          boxShadow="rgba(0, 0, 0, 0.06) 0px 1px 2px 0px inset"
        >
          <Button
            id={'upload_contacts_btn'}
            title={{
              content: 'Add Recipient(s)',
              fontSize: isMobile ? '13px' : '17px',
            }}
            onClick={openAddressBook}
            fill="#fff"
            padding={isMobile ? '16px 12px' : '24px 44px'}
            borderRadius="small"
            borderColor="#000"
            isOutlined={true}
          >
            <DSIcon name="user" size={isMobile ? 14 : 17} />
          </Button>
        </Div>
      )}
      {order.contacts.length > 0 && (
        <div className={`${styles.addressBook} ${styles.selectedAddresses}`}>
          {!shouldDisableAddRecipients && (
            <Search
              value={search}
              hint={'Find Attached'}
              className={styles.addressSearch}
              onSearch={setSearch}
              onClear={() => setSearch('')}
              autocomplete="off"
            />
          )}
          <FlexRow>
            <div className={styles.type}>
              <Text type="caption" color="accent">{`${
                order.contacts.length
              } Recipient${order.contacts.length === 1 ? '' : 's'}`}</Text>
            </div>
            {isQualifiedForFirstFreeCard && order.contacts.length > 1 && (
              <Text type="body" color="danger" outset={{ left: 'x1' }}>
                In order for the card to be free, you must only have 1
                recipient.
              </Text>
            )}
          </FlexRow>
          <RwdTable>
            <RwdHeader>
              <th>
                <Text type="body" color="primaryBrand">
                  Name
                </Text>
              </th>
              <th>
                <Text type="body" color="primaryBrand">
                  Address
                </Text>
              </th>
              <th>
                <Text type="body" color="primaryBrand">
                  Actions
                </Text>
              </th>
            </RwdHeader>
            <tbody className={styles.addressDisplay}>
              {page.map((contact, index) => {
                const contactIssues = getIssues(
                  contact,
                  sendDelayTypes,
                  USStates,
                )
                const address1 = contact.address1 ? `${contact.address1},` : ''
                const address2 = contact.address2 ? `${contact.address2},` : ''

                return (
                  <React.Fragment key={contact.id}>
                    <RwdRow id={`contact_${index}`}>
                      <RwdCell mapToHeader="Name">
                        <Text type="body" color="primaryHeading">
                          {contact.firstName} {contact.lastName}
                        </Text>
                      </RwdCell>
                      <RwdCell mapToHeader="Address">
                        <Text type="body" color="primaryHeading">
                          {isFullContact(contact) &&
                            `${address1} ${address2} ${contact.city}, ${contact.state} ${contact.postalCode}, ${contact.country}`}
                        </Text>
                      </RwdCell>
                      <RwdCell
                        mapToHeader="Actions"
                        className={styles.actionItems}
                      >
                        <span
                          onClick={() => {
                            actions.removeContact(contact)
                          }}
                        >
                          <Icon icon={'CLOSECIRCLE'} size={17} />
                        </span>
                        <span onClick={() => setContactBeingEdited(contact)}>
                          <Icon icon={'EDIT'} size={19} />
                        </span>
                        {contactIssues.length > 0 && (
                          <div style={{ display: 'inline-block' }}>
                            <Tooltip
                              id={`contact_issue_${index}`}
                              iconColor={'#fe5030'}
                              hoverIcon={'USER'}
                              iconSize={20}
                              message={`Recipient Issues `}
                              isIconPulsingAnimationEnabled={true}
                            >
                              <div className={`${styles.issueList}`}>
                                {contactIssues.map(
                                  (issue: string, index: number) => (
                                    <span key={index} className={styles.issue}>
                                      <Text type="caption" color="accent">
                                        {issue}
                                      </Text>
                                    </span>
                                  ),
                                )}
                              </div>
                            </Tooltip>
                          </div>
                        )}
                      </RwdCell>
                    </RwdRow>
                    <tr className={styles.tableSpacer} />
                  </React.Fragment>
                )
              })}
            </tbody>
          </RwdTable>
          <Pagination
            onPageChange={setPageNumber}
            count={count}
            pagesToShow={5}
            pageSize={pageSize}
            currentPage={pageNumber}
          />
        </div>
      )}
      {contactBeingEdited && (
        <AddressFormModal
          contact={contactBeingEdited}
          close={() => setContactBeingEdited(undefined)}
          onSubmit={handleSaveContact}
        />
      )}
    </Flex>
  )
}

export default suspenseBoundary({
  component: Recipients,
  unresolved: <Transition message={'Loading recipients...'} />,
  failure: DefaultError,
})
