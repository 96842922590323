import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'

type ContactCheckboxProps = {
  contact: { id: string; firstName: string; lastName: string }
  onCheck: (contactId: string) => void
  isChecked: boolean
}

const ContactCheckbox = ({
  contact,
  onCheck,
  isChecked,
}: ContactCheckboxProps) => (
  <FormControlLabel
    control={
      <Checkbox
        checked={isChecked}
        onChange={() => onCheck(contact.id)}
        onClick={() => onCheck(contact.id)}
        icon={<CheckBoxOutlineBlankIcon style={{ fill: '#ED6ACF' }} />}
      />
    }
    label={`${contact.firstName} ${contact.lastName}`}
  />
)

export default ContactCheckbox
