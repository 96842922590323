import React, { Fragment } from 'react'
import ResizeDetector from 'react-resize-detector'
import { Flex, Spacer, Text } from '@sendoutcards/quantum-design-ui'
// @src imports
import { Button, Icon } from 'src/chrome'
import { CardType } from 'src/graphql/generated/graphql'
// relative imports
import Panel from './Panel/Panel'
import ImageElement from '../components/ImageElement'
import {
  ComputedFullBleed,
  getSinglePanelIndex,
  getSinglePanelIndexMap,
  variations as getVariations,
} from '../../redux/selectors/editor'

import styles from '../styles/components/panel.module.scss'
import { Api } from '../api'
import { Steps } from '../types'

type Props = {
  api: Api
  fullBleed: ComputedFullBleed
}

const elementDisplayStyles: React.CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
}

const FullBleed: React.FC<Props> = props => {
  const { api, fullBleed } = props
  const {
    step,
    panelView,
    fullBleeds,
    card,
    selectPanel,
    selectFullBleed,
    updateFullBleed,
    resetStep,
    resolveLocation,
    isMobile,
  } = api

  // TODO: React Query Refactor - not sure if this is how we want to handle undefined results
  if (!card) throw Error('No card provided!')

  const { fullBleedIndex, panelIndex } = step
  const numFullBleeds = fullBleeds.length - 1
  const numPanels =
    card.type === 'THREE_PANEL'
      ? numFullBleeds + 2
      : card.type === 'TWO_PANEL' || card.type === 'TWO_PANEL_BIG'
      ? numFullBleeds + 1
      : numFullBleeds

  const backgroundElement = fullBleed.backgroundElement

  const singlePanelIndex = getSinglePanelIndex(
    fullBleeds,
    fullBleedIndex,
    panelIndex,
  )

  const currentIndex: number =
    panelView === 'fullbleed' ? fullBleedIndex : singlePanelIndex

  const setPanelIndex =
    panelView === 'fullbleed'
      ? selectFullBleed
      : (index: number) => {
          const { fullBleedIndex, panelIndex } = getSinglePanelIndexMap(
            fullBleeds,
          )[index]

          selectPanel(fullBleedIndex, panelIndex)
        }

  const removeSpan = () =>
    updateFullBleed(step.fullBleedIndex, fullBleed => ({
      ...fullBleed,
      backgroundElement: fullBleed.backgroundElement && {
        ...fullBleed.backgroundElement,
        image: {
          image: null,
          position: { x: 0.5, y: 0.5 },
          scale: 1,
          filter: null,
        },
      },
    }))

  return (
    <div id={'fullbleed-container'}>
      <div
        className={`${
          card.isHorizontal
            ? `${styles.fullBleed} ${styles.horizontalCard}`
            : styles.fullBleed
        } ${step.type !== Steps.Idle && styles.openDrawer}`}
      >
        <div css={{ position: 'relative' }}>
          <Fragment>
            {panelView === 'fullbleed' &&
              backgroundElement &&
              (!card.detailedSendableCard ||
                (card.detailedSendableCard &&
                  !card.detailedSendableCard.insideRightImage)) && (
                <Fragment>
                  <div
                    className={
                      card.isHorizontal
                        ? `${styles.fullBleedPanelContainer} ${styles.horizontalContainer}`
                        : styles.fullBleedPanelContainer
                    }
                    style={{
                      ...(panelIndex === null
                        ? {
                            zIndex: 102,
                            background: '#fff',
                          }
                        : {}),
                    }}
                  >
                    <div className={styles.panelContent}>
                      <ImageElement
                        api={api}
                        element={backgroundElement}
                        elementDisplayStyles={elementDisplayStyles}
                      />
                    </div>
                  </div>
                  {panelIndex === undefined &&
                    backgroundElement.image &&
                    backgroundElement.image.image && (
                      <div className={styles.imageSpanActions}>
                        <Button
                          title={'Remove'}
                          className={styles.spanningAction}
                          onClick={() => {
                            removeSpan()
                            resetStep()
                          }}
                        />
                        <Button
                          title={'Done'}
                          className={styles.spanningAction}
                          onClick={() => resetStep()}
                        />
                      </div>
                    )}
                </Fragment>
              )}
          </Fragment>
          <Flex
            position="absolute"
            bottom={!api.isMobile ? '-30px' : '-45px'}
            cursor="pointer"
            width="100%"
            justifyContent={'center'}
            inset={{
              left: resolveLocation(card.type) === 'Front' ? 'x5' : 'x0',
              right: resolveLocation(card.type) === 'Back' ? 'x5' : 'x0',
            }}
          >
            {currentIndex > 0 && (
              <div
                id={'carrot_left_btn'}
                onClick={() => setPanelIndex(currentIndex - 1)}
                css={{ marginRight: '12px' }}
              >
                <Flex
                  width={'40px'}
                  height={'40px'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  backgroundColor={{ swatch: 'primaryBrand', shade: '_100' }}
                  borderRadius={'medium'}
                  boxShadow={'mediumLight'}
                  cursor={'pointer'}
                >
                  <Icon
                    icon={'CARROTLEFT'}
                    color="#fff"
                    style={{ paddingLeft: '5px' }}
                    size={20}
                  />
                </Flex>
              </div>
            )}
            <Flex
              backgroundColor={{ swatch: 'primaryBrand', shade: '_100' }}
              borderRadius="default"
              inset={{ horizontal: 'x2', vertical: 'x_5' }}
              justifyContent="center"
              alignItems="center"
              width={
                resolveLocation(card.type) === 'Front' || 'Back'
                  ? 'unset'
                  : '100%'
              }
            >
              <Text
                type="caption"
                content="Location:"
                weight="bold"
                color="inverseBody"
                lineHeight={0}
                isInline={true}
              />
              <Spacer space="x_5" orientation="horizontal" />
              <Text
                lineHeight={0}
                type="caption"
                content={`${resolveLocation(card.type)}`}
                color="inverseBody"
                isInline={true}
                whiteSpace="nowrap"
              />
            </Flex>
            {currentIndex <
              (panelView === 'fullbleed' ? numFullBleeds : numPanels) && (
              <div
                id={'carrot_right_btn'}
                onClick={() => setPanelIndex(currentIndex + 1)}
                css={{ marginLeft: '12px' }}
              >
                <Flex
                  width={'40px'}
                  height={'40px'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  backgroundColor={{ swatch: 'primaryBrand', shade: '_100' }}
                  borderRadius={'medium'}
                  boxShadow={'mediumLight'}
                  cursor={'pointer'}
                >
                  <Icon
                    icon={'CARROTRIGHT'}
                    color="#fff"
                    style={{ paddingLeft: '10px' }}
                    size={20}
                  />
                </Flex>
              </div>
            )}
          </Flex>
          <ResizeDetector
            handleWidth={true}
            handleHeight={true}
            querySelector={'#editor-window'}
          >
            {({
              width: fullContainerWidth,
              height: fullContainerHeight,
            }: {
              width: number
              height: number
            }) => {
              // Cap the width & height so it doesn't disappear on mobile
              const containerWidth = Math.max(fullContainerWidth - 100, 300)
              const containerHeight = Math.max(fullContainerHeight - 75, 500)

              const width =
                panelView === 'fullbleed'
                  ? fullBleed.width
                  : fullBleed.panels[0].width * fullBleed.width
              const height =
                panelView === 'fullbleed'
                  ? fullBleed.height
                  : fullBleed.panels[0].height * fullBleed.height
              const maxByHeight = {
                height: containerHeight,
                width: (containerHeight / height) * width,
              }
              const maxByWidth = {
                height: (containerWidth / width) * height,
                width: containerWidth,
              }
              const canHeightFit =
                maxByHeight.height <= containerHeight &&
                maxByHeight.width <= containerWidth
              const canWidthFit =
                maxByWidth.height <= containerHeight &&
                maxByWidth.width <= containerWidth

              const heightArea = maxByHeight.height * maxByHeight.width
              const widthArea = maxByWidth.height * maxByWidth.width

              const shouldLimitHeight =
                canHeightFit && canWidthFit
                  ? heightArea > widthArea
                  : canHeightFit

              const calculatedWidth = shouldLimitHeight
                ? maxByHeight.width
                : maxByWidth.width

              const hasVariations = getVariations(card)

              const isInnerFullBleed =
                panelView === 'fullbleed' &&
                resolveLocation(card.type) !== 'Front' &&
                resolveLocation(card.type) !== 'Back'

              const isHorizontalCard = card.isHorizontal

              const isThreePanelCard = card.type === 'THREE_PANEL'

              return (
                <div
                  style={{
                    width:
                      hasVariations && !isMobile
                        ? calculatedWidth - 110
                        : hasVariations && isMobile && isInnerFullBleed
                        ? calculatedWidth + 40
                        : hasVariations && isMobile
                        ? calculatedWidth - 30
                        : isInnerFullBleed && !isHorizontalCard
                        ? calculatedWidth + 20
                        : isThreePanelCard &&
                          isHorizontalCard &&
                          isInnerFullBleed
                        ? calculatedWidth - 50
                        : calculatedWidth - 30,
                    paddingBottom: '1.5rem',
                  }}
                  className={`
                    ${styles.fullBleedPanels}
                    ${card.isHorizontal ? styles.horizontalCard : ''}
                    ${
                      card.type === CardType.ThreePanel &&
                      fullBleedIndex === 1 &&
                      !card.isHorizontal
                        ? styles.threePanelFullBleedPortrait
                        : card.type === CardType.ThreePanel &&
                          fullBleedIndex === 1 &&
                          card.isHorizontal
                        ? styles.threePanelFullBleedLandscape
                        : ''
                    }
                  `}
                >
                  {fullBleed.panels.map((panel, index) => (
                    <Panel
                      key={panel.location}
                      api={api}
                      fullBleed={fullBleed}
                      panel={panel}
                      index={panel.location}
                      hasBackgroundSpread={
                        !!(
                          backgroundElement &&
                          backgroundElement.image &&
                          backgroundElement.image.image
                        )
                      }
                    />
                  ))}
                </div>
              )
            }}
          </ResizeDetector>
        </div>
      </div>
    </div>
  )
}

export default FullBleed
