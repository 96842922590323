import React from 'react'
import { Div } from '@sendoutcards/quantum-design-ui'
import { useState } from 'src/hooks'

interface CloseButtonProps {
  size: number
  onClose: () => void
  id?: string
}

const CloseButton: React.FC<CloseButtonProps> = props => {
  const [isHovering, setIsHovering] = useState(false)
  const { size, onClose, id } = props
  const buttonSize = `${size}px`
  const lineSize = `${size - 8}px`

  const lineStyle = {
    width: '2px',
    background: '#fff',
    height: lineSize,
    position: 'absolute' as const,
  }

  const buttonStyle = {
    width: buttonSize,
    height: buttonSize,
    background: 'rgba(0,0,0,0.75)',
    color: '#fff',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 0 3px rgba(0,0,0,0.2)',
    cursor: 'pointer',
    padding: '2px',
    position: 'relative' as const,
  }

  const styles = {
    button: {
      ...buttonStyle,
    },
    buttonHover: {
      ...buttonStyle,
      background: 'rgba(0,0,0,0.55)',
      color: 'rgba(255, 255, 255, 0.7)',
    },
    lineOne: {
      ...lineStyle,
      transform: 'rotate(45deg)',
    },
    lineTwo: {
      ...lineStyle,
      transform: 'rotate(-45deg)',
    },
  }

  return (
    <Div
      onHoverStart={() => setIsHovering(true)}
      onHoverEnd={() => setIsHovering(false)}
      id={id || ''}
    >
      <div
        style={isHovering ? styles.buttonHover : styles.button}
        onClick={() => onClose()}
      >
        <div style={styles.lineOne} />
        <div style={styles.lineTwo} />
      </div>
    </Div>
  )
}

export default CloseButton
