import { MinimalSponsorFragment } from 'src/graphql/generated/graphql'

export const getSponsorSuffix = (
  sponsor: MinimalSponsorFragment | undefined,
): string => {
  if (!sponsor) return ''

  return sponsor.genealogyId
    ? `${sponsor.genealogyId}`
    : sponsor.storefront && sponsor.storefront.slug
    ? `${sponsor.storefront.slug}`
    : ''
}
