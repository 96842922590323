import React from 'react'

import {
  Banner,
  Div,
  Flex,
  Icon,
  Span,
  useColorSwatch,
} from '@sendoutcards/quantum-design-ui'
import { useActions, useSelector, useState } from 'src/hooks'
import { DefaultError, SuspenseBoundary } from 'src/chrome'
import DropDownBanner from 'src/dashboard/components/DropDownBanner/DropDownBanner'
import { useMarketingBanners } from 'src/react_query'

const textStyles = {
  fontFamily: 'Montserrat',
  fontSize: '0.875rem',
  lineHeight: '1.5',
}

const styles = {
  innerBanner: (textColor: string) => ({
    maxHeight: '150px',
    overflow: 'auto',
    p: {
      ...textStyles,
      color: textColor,
    },
    a: {
      ...textStyles,
    },
  }),
}

interface IAnnouncementBannersProps {}

const AnnouncementBanners: React.FC<IAnnouncementBannersProps> = () => {
  return (
    <SuspenseBoundary unresolved={false} failure={DefaultError}>
      <GenerateBannerComponents />
    </SuspenseBoundary>
  )
}

const GenerateBannerComponents: React.FC = () => {
  const account = useSelector(state => state.user.account)
  const actions = useActions()
  const bannersQuery = useMarketingBanners()
  const banners = bannersQuery.data
  const [showPaymentHoldBanner, setShowPaymentHoldBanner] = useState(
    account?.settings?.paymentHold,
  )
  const { grayScale } = useColorSwatch()
  if (!banners || banners.length === 0) {
    return null
  }

  return (
    <div
      style={{
        marginTop: '10px',
        marginBottom: '10px',
        paddingLeft: '1em',
        paddingRight: '1em',
        position: 'relative',
      }}
    >
      {showPaymentHoldBanner && (
        <DropDownBanner
          timeout={30000}
          zIndex={6}
          actionButton={{
            title: 'Update',
            onClick: () => {
              setShowPaymentHoldBanner(false)
              actions.openPaymentSettings()
            },
          }}
          dismissButton={{
            title: 'Dismiss',
            onClick: () => setShowPaymentHoldBanner(false),
          }}
          title="Hello! A recent payment was unsuccessful. Please update your payment settings now to ensure your cards and gifts are sent when expected."
          icon="exclamation"
          status="danger"
          setShowPaymentHoldBanner={setShowPaymentHoldBanner}
        />
      )}
      {banners.map((banner, index) => (
        <Div key={banner.title} inset={{ vertical: 'x1' }}>
          <Banner status={'success'}>
            <Flex justifyContent="flex-start" alignItems="center" width="100%">
              <Span
                display="flex"
                justifyContent="center"
                alignContent="center"
              >
                <Icon
                  name="exclamation"
                  primaryColor="primaryBodyText"
                  size="small"
                />
              </Span>
              <div
                css={styles.innerBanner(grayScale.base)}
                dangerouslySetInnerHTML={{ __html: banner.htmlContent }}
              />
            </Flex>
          </Banner>
        </Div>
      ))}
    </div>
  )
}

export default AnnouncementBanners
