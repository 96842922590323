import React from 'react'
import {
  Banner,
  Button,
  Div,
  Flex,
  Icon,
  IconType,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { useEffect } from 'src/hooks'
import { Memoized } from 'src/hooks/dependencies'

type StatusType = 'success' | 'warning' | 'danger'

type DropDownBannerProps = {
  title: string
  icon?: IconType
  dismissButton: {
    onClick: () => void
    title: string
  }
  actionButton: {
    onClick: () => void
    title: string
  }
  setShowPaymentHoldBanner: Memoized<(show: boolean) => void>
  zIndex?: number
  timeout?: number
  status: StatusType
}

const DropDownBanner: React.FC<DropDownBannerProps> = ({
  dismissButton,
  actionButton,
  setShowPaymentHoldBanner,
  zIndex = 0,
  timeout,
  title,
  icon,
  status,
}) => {
  useEffect(() => {
    if (typeof timeout === 'number') {
      const timer: NodeJS.Timeout | null = setTimeout(() => {
        setShowPaymentHoldBanner(false)
      }, timeout)
      return () => (timer ? clearTimeout(timer) : undefined)
    }
    return () => undefined
  }, [timeout, setShowPaymentHoldBanner])

  return (
    <Div
      position="absolute"
      top="0px"
      width="calc(100% - 2em)"
      zIndex={zIndex}
      initial={{ y: '-110%', opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: '-100%', opacity: 0, transition: { delay: 0, duration: 0.3 } }}
      left="0"
      outset={{ horizontal: '1em' }}
      transition={{
        duration: 0.3,
        delay: 2,
        damping: 15,
        stiffness: 100,
        type: 'spring',
      }}
    >
      <Banner status={status}>
        <Div outset={{ left: 'x2' }}>
          <Flex alignItems="center">
            {icon && (
              <Icon name={icon} primaryColor="primaryBodyText" size="medium" />
            )}
            <Text type="caption" content={title} color="primaryBody" />
          </Flex>
          <Flex columnGap="x2">
            <Button
              type="accent"
              textColorOverride="primaryBody"
              title={actionButton.title}
              size="xSmall"
              onClick={() => actionButton.onClick()}
            />
            <Button
              type="accent"
              textColorOverride="primaryBody"
              title={dismissButton.title}
              size="xSmall"
              onClick={() => dismissButton.onClick()}
            />
          </Flex>
        </Div>
      </Banner>
    </Div>
  )
}

export default DropDownBanner
