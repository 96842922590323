import React from 'react'

import { useEffect, useRef } from './'
import { AnyMemoized } from './dependencies'

const useEffectOnChange = (
  effect: React.EffectCallback,
  deps: AnyMemoized[],
) => {
  const mutableExecuted = useRef(false)
  const mutableEffectRef = useRef(effect)
  mutableEffectRef.current = effect

  useEffect(() => {
    if (!mutableExecuted.current) {
      mutableExecuted.current = true
      return
    }

    return mutableEffectRef.current()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps])
}

export default useEffectOnChange
