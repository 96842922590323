import {
  Button,
  Div,
  DropDownMenu,
  Flex,
  Icon,
  LinkColumnType,
} from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { useState } from 'src/hooks'
import { Portal } from 'src/portal/portal'
import JoinModal from 'src/user/components/JoinModal/JoinModal'

type LoginFormOptions = 'join' | 'login' | undefined

const StaticNavAccountSection: React.FC = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [shouldShowDialog, setShouldShowDialog] = useState<LoginFormOptions>(
    undefined,
  )
  const width = window.innerWidth
  const isMobile = width < 600

  const mobileDropdownLinks: LinkColumnType = {
    title: 'My Account',
    links: [
      {
        title: 'Login',
        href: '#',
        onClick: () => {
          setShouldShowDialog('login')
        },
      },
      {
        title: 'Join Now',
        href: '#',
        onClick: () => {
          setShouldShowDialog('join')
        },
      },
    ],
  }

  const onCloseModal = () => {
    setShouldShowDialog(undefined)
  }

  return (
    <>
      {shouldShowDialog && (
        <Portal>
          <Div zIndex={500} position="relative">
            <JoinModal
              initialAction={shouldShowDialog}
              onClose={onCloseModal}
              onAfterSave={onCloseModal}
              onLogin={onCloseModal}
            />
          </Div>
        </Portal>
      )}
      {isMobile ? (
        <Flex position="relative">
          <Icon name="user" size="xSmall" primaryColor="primaryBodyText" />
          <Icon
            name={'chevron'}
            orientation={isDropdownOpen ? 'up' : 'down'}
            size={'small'}
            onClick={() => {
              setIsDropdownOpen(!isDropdownOpen)
            }}
            primaryColor={'primaryBodyText'}
          />
          <DropDownMenu
            right={-24}
            top={48}
            isOpen={isDropdownOpen}
            linkColumn={mobileDropdownLinks}
          />
        </Flex>
      ) : (
        <Div width="200px">
          <Flex columnGap="x2">
            <Button
              size="xSmall"
              type="shadow"
              hover={true}
              onClick={() => {
                setShouldShowDialog('login')
              }}
            >
              Login
            </Button>

            <Button
              hover={true}
              outlined={true}
              size="xSmall"
              onClick={() => {
                setShouldShowDialog('join')
              }}
            >
              Join Now
            </Button>
          </Flex>
        </Div>
      )}
    </>
  )
}

export default StaticNavAccountSection
