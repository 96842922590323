import { Maybe, NotificationType } from '../../../__generated__/globalTypes'

import notificationFragmentRawString from '../NotificationFragment.graphql'

export const notificationFragmentString = notificationFragmentRawString

export type NotificationFragment = {
  __typename: 'Notification'
  id: string
  message: Maybe<string>
  createdAt: string
  acceptText: Maybe<string>
  denyText: Maybe<string>
  link: Maybe<string>
  linkText: Maybe<string>
  data: Maybe<string>
  type: Maybe<NotificationType>
}

export const isNotificationFragment = (
  fragment: any,
): fragment is NotificationFragment =>
  fragment &&
  fragment.__typename === 'Notification' &&
  typeof fragment.id === 'string' &&
  (fragment.message === null || typeof fragment.message === 'string') &&
  typeof fragment.createdAt === 'string' &&
  (fragment.acceptText === null || typeof fragment.acceptText === 'string') &&
  (fragment.denyText === null || typeof fragment.denyText === 'string') &&
  (fragment.link === null || typeof fragment.link === 'string') &&
  (fragment.linkText === null || typeof fragment.linkText === 'string') &&
  (fragment.data === null || typeof fragment.data === 'string') &&
  (fragment.type === null ||
    fragment.type === 'ANNIVERSARY' ||
    fragment.type === 'BIRTHDAY' ||
    fragment.type === 'CAMPAIGN' ||
    fragment.type === 'REJECTED_CARDS' ||
    fragment.type === 'HELD_PRODUCTION_INFOS' ||
    fragment.type === 'CONTACT_REQUEST_COMPLETED')
