import { Maybe, Operation } from '../../../__generated__/globalTypes'

import deleteGroupString from '../DeleteGroup.graphql'

export type DeleteGroupData = {
  deleteGroup: {
    deleted: Maybe<boolean>
  }
}

export type DeleteGroupVariables = {
  id: string
}

export type DeleteGroup = Operation<DeleteGroupData, DeleteGroupVariables>

export const DeleteGroup = ({ id }: DeleteGroupVariables): DeleteGroup => ({
  query: deleteGroupString,
  variables: {
    id,
  },
})
