import { AccessLevel, Route } from 'src/routes/Route'
import { Subroute } from 'src/routes/Subroute'

const SubmitRequestedContactSubroute = Subroute()
export type SubmitRequestedContactSubroute = Subroute<
  typeof SubmitRequestedContactSubroute
>

export const SubmitRequestedContactRoute = Route({
  path: '/submit-contact',
  accessLevel: AccessLevel.PUBLIC,
  init: (subroute?: SubmitRequestedContactSubroute) => ({
    subroute,
  }),
  fromPath: () => ({}),
  subroute: undefined,
})
export type SubmitRequestedContactRoute = Route<
  typeof SubmitRequestedContactRoute
>
