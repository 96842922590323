import { LabeledPrice } from 'src/graphql/generated/graphql'

const isCardCostFree = (entries: LabeledPrice[]) => {
  for (const item of entries) {
    if (item.label === 'Card Cost' && item.asString === 'FREE') {
      return true
    }
  }
  return false
}

export default isCardCostFree
