import { Anchor, Avatar, Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { Widget } from 'src/dashboard/components/Widgets/Widget'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { useActions } from 'src/hooks'
import CreditCard from 'src/payments/components/CreditCard/CreditCard'
import { useAccountQuery } from 'src/react_query'

type AccountSettingsWidgetProps = {
  onToggleAccountDrawer: () => void
}

export const AccountSettingsWidget: React.FC<AccountSettingsWidgetProps> = ({
  onToggleAccountDrawer,
}) => {
  const actions = useActions()
  const { data, isLoading } = useAccountQuery()

  const isCreditCardOnFile = data?.stripeSource?.__typename === 'CreditCard'

  const paymentData =
    data?.stripeSource?.__typename === 'CreditCard'
      ? {
          cardType: data.stripeSource.brand,
          last4: data.stripeSource.last4,
          name: `${data.firstName} ${data.lastName}`,
          exp: `${data.stripeSource.expMonth}/${data.stripeSource.expYear}`,
        }
      : {
          cardType: 'visa',
          last4: '0000',
          name: 'dane joe',
          exp: '05/30',
        }

  return (
    <Widget
      alignment="start"
      paddingOverride="24px"
      isLoading={isLoading}
      isEmpty={false}
      emptyState={{
        title: 'Settings Missing',
        description:
          'There’s always a reason to reach out to someone, You never know the impact your simple act could make on someones life.',
        icon: 'exp_calendar',
        contentMaxWidth: '480px',
        action: {
          title: 'Send a card today',
          onClick: () => alert('Method called from Home.tsx'),
        },
      }}
    >
      <Flex width="100%" flexDirection="column" justifyContent="flex-start">
        <Flex id="account_widget_header" width="100%" alignItems="center">
          <Flex id="user_info_card">
            <Avatar size="small" avatarImage={data?.profileImageUrl} />
            <Flex
              id="user_info"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="flex-start"
              outset={{ left: 'x1' }}
            >
              <Text
                type="largeBody"
                color="primaryHeading"
                weight="bold"
                style={{ lineHeight: 1.2 }}
                alignment="left"
                content={`${data?.firstName ?? ''} ${data?.lastName ?? ''}`}
              />

              <Flex>
                <Text
                  type="footnote"
                  content="User ID:"
                  color="primaryBody"
                  outset={{ right: 'x_75' }}
                />
                <Text
                  type="footnote"
                  content={data?.username}
                  color="primaryHeading"
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <Flex
          width="100%"
          alignItems="center"
          inset={{ horizontal: 'x2', vertical: 'x1_5' }}
          outset={{ top: 'x3' }}
          style={{ backgroundColor: '#eef0f2', borderRadius: 16 }}
          id="account_address_card"
        >
          <Flex
            style={{
              backgroundRepeat: 'no-repeat',
              height: 42,
              width: 75,
              backgroundSize: 'contain',
              backgroundImage:
                'url(https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/images/map.png)',
            }}
          />
          <Flex outset={{ left: 'x2' }} id="shipping_address">
            {data?.shippingAddress ? (
              <Flex flexWrap="wrap">
                <Flex width="100%">
                  <Text
                    weight="semiBold"
                    type="caption"
                    content={`${data.shippingAddress.firstName} ${data.shippingAddress.lastName}`}
                  />
                </Flex>
                <Text
                  content={data.shippingAddress.address1}
                  type="caption"
                  lineHeight={1}
                />
                <Text
                  content={data.shippingAddress.address2}
                  type="caption"
                  lineHeight={1}
                />
                <Flex width="100%" outset={{ top: 'x_5' }}>
                  <Text
                    content={`${data.shippingAddress.city}, ${data.shippingAddress.state}, ${data.shippingAddress.postalCode}`}
                    type="caption"
                    lineHeight={1}
                  />
                </Flex>
              </Flex>
            ) : (
              <Anchor title="Add Address" onClick={() => alert('open')} />
            )}
          </Flex>
          <Flex
            onClick={onToggleAccountDrawer}
            inset="x1"
            style={{
              marginLeft: 'auto',
              backgroundColor: '#404040',
              borderRadius: 10,
            }}
            cursor="pointer"
          >
            <Icon name="edit" size={14} color="white" />
          </Flex>
        </Flex>

        <Flex
          width="100%"
          alignItems="center"
          inset={{ horizontal: 'x2', vertical: 'x1_5' }}
          outset={{ top: 'x1' }}
          style={{ backgroundColor: '#eef0f2', borderRadius: 16 }}
          id="account_payment_card"
        >
          <Flex
            style={{
              height: 42,
              width: 75,
            }}
          >
            <CreditCard
              cardType={paymentData.cardType}
              last4={paymentData.last4}
              name={paymentData.name}
              exp={paymentData.exp}
              width="100%"
              height="100%"
            />
          </Flex>
          <Flex outset={{ left: 'x2' }} id="shipping_address">
            {isCreditCardOnFile ? (
              <Flex flexWrap="wrap">
                <Flex width="100%">
                  <Text
                    weight="semiBold"
                    type="caption"
                    content={`${paymentData.cardType} ending in ${paymentData.last4}`}
                  />
                </Flex>
                <Text content={paymentData.exp} type="caption" lineHeight={1} />
              </Flex>
            ) : (
              <Anchor
                size="xSmall"
                isDecorated={true}
                title="Add Payment Method"
                onClick={() => actions.openPaymentSettings()}
              />
            )}
          </Flex>
          <Flex
            onClick={() => actions.openPaymentSettings()}
            inset="x1"
            style={{
              marginLeft: 'auto',
              backgroundColor: '#404040',
              borderRadius: 10,
            }}
            cursor="pointer"
          >
            <Icon name="edit" size={14} color="white" />
          </Flex>
        </Flex>
      </Flex>
    </Widget>
  )
}
