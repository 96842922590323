import { Operation } from '../../../__generated__/globalTypes'

import deleteOrderString from '../DeleteOrder.graphql'

export type DeleteOrderData = {
  deleteOrder: {
    deleted: boolean
  }
}

export type DeleteOrderVariables = {
  id: string
}

export type DeleteOrder = Operation<DeleteOrderData, DeleteOrderVariables>

export const DeleteOrder = ({ id }: DeleteOrderVariables): DeleteOrder => ({
  query: deleteOrderString,
  variables: {
    id,
  },
})
