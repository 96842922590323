import { Dispatch, SetStateAction } from 'react'
import { AnyMemoized, Memoized } from './dependencies'
import { useEffect, useState } from '.'
import getLocalStorage from 'src/helpers/getLocalStorage'

const usePersistedState = <S extends AnyMemoized>(
  key: string,
  defaultValue: S,
): [S, Memoized<Dispatch<SetStateAction<S | undefined>>>] => {
  const [value, setValue] = useState<S>()

  const storage = getLocalStorage()

  useEffect(() => {
    if (!storage) return
    const possibleValue = storage.getItem(key) ?? null
    if (possibleValue !== null) {
      setValue(JSON.parse(possibleValue))
    }
  }, [key, storage])

  useEffect(() => {
    if (!storage) return
    if (value !== undefined) {
      storage.setItem(key, JSON.stringify(value))
    } else {
      storage.removeItem(key)
    }
  }, [key, value, storage])

  return [value ?? defaultValue, setValue]
}

export default usePersistedState
