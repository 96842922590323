import React from 'react'
import TextField from '@material-ui/core/TextField'

import { Button } from 'src/chrome'

import styles from './editableTable.module.scss'
import { useEffect, useState } from 'src/hooks'

type CellId = { row: number; cell: string }

interface Props {
  cellId?: CellId
  value?: string
  hoverMessage?: string
  onEditCell?: (value?: string, cellId?: CellId) => void
  hasIssue?: boolean
  shouldUseSelectField?: boolean
  selectFieldHint?: string
  selectOptions?: JSX.Element[]
  style?: React.CSSProperties
}

const EditableCell: React.FC<Props> = props => {
  const { onEditCell, cellId } = props

  const [isEditing, setIsEditing] = useState(false)
  const [value, setValue] = useState<string | undefined>('')

  const editCell = () => {
    setIsEditing(true)
  }

  const finishedEditing = () => {
    if (!onEditCell) {
      return
    }
    if (cellId) {
      onEditCell(value, cellId)
    } else {
      onEditCell(value)
    }
    setIsEditing(false)
  }

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
    finishedEditing()
  }

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  const {
    shouldUseSelectField,
    selectFieldHint,
    selectOptions,
    style,
    hasIssue,
    hoverMessage,
    children,
  } = props

  const isEditable = !!props.onEditCell
  const issueStyle =
    !isEditing && hasIssue ? { background: 'rgba(255, 86, 137, .20)' } : {}
  const cellStyle = style ? { ...style, ...issueStyle } : issueStyle

  const regex = new RegExp(' |@|[.]|-|:|/', 'g')
  const valueId = value?.replaceAll(regex, '')

  return (
    <td style={cellStyle} className={styles.cell}>
      {children ? (
        <>{children}</>
      ) : (
        <div>
          {isEditing ? (
            <div>
              {shouldUseSelectField ? (
                <TextField
                  select={true}
                  placeholder={selectFieldHint}
                  value={value}
                  style={{ background: '#f8f8ff' }}
                  onChange={handleSelect}
                >
                  {selectOptions}
                </TextField>
              ) : (
                <div style={{ display: 'flex', background: '#f8f8ff' }}>
                  <input
                    type="text"
                    value={value}
                    onChange={handleValueChange}
                  />
                  <Button
                    title={'Done'}
                    onClick={finishedEditing}
                    buttonColor={'pink'}
                    style={{ color: '#fff', fontSize: 13 }}
                  />
                </div>
              )}
            </div>
          ) : (
            <div className={styles.cellBody}>
              <span
                id={`id_${valueId}`}
                className={styles.cellValue}
                style={{ cursor: isEditable ? 'pointer' : 'default' }}
                onClick={isEditable ? editCell : () => {}}
              >
                {value}
              </span>
              {hoverMessage && (
                <p className={styles.hoverMessage}>{hoverMessage}</p>
              )}
            </div>
          )}
        </div>
      )}
    </td>
  )
}

export default EditableCell
