import React from 'react'
// @src imports

import { textEditor } from 'src/helpers'
import { ComputedElementText } from './types'
import {
  useCallback,
  useEffect,
  useMutationObservable,
  useRef,
  useState,
} from 'src/hooks'

import { Api } from '../api'
import { Steps } from '../types'
import TextEditorParagraphAndroid from './TextEditorParagraphAndroid'
import { rgbString } from 'src/redux/selectors/text'

const fragmentsFormat = 'application/vnd.sendoutcards.textfragments+json'

const selectionIndex = (
  node: Node,
  offset: number,
  isStart: boolean,
  selectionUpperBound: number,
) => {
  if (node.nodeName === '#text') {
    const spanIndex = parseInt(
      (node.parentElement && node.parentElement.dataset.index) || '1',
      10,
    )
    if (offset === 0) {
      return spanIndex
    } else if (offset === (node.textContent && node.textContent.length)) {
      return spanIndex + 1
    } else {
      return isStart ? spanIndex : spanIndex + 1
    }
  } else if (node.nodeName === 'P' && node instanceof HTMLElement) {
    return parseInt(node.dataset.index || '1', 10) + offset + 1
  } else {
    return isStart ? 1 : selectionUpperBound
  }
}

type TextEditorAndroidProps = {
  textEditorId: string
  elementText: ComputedElementText
  selectText: (start: number, end: number) => void
  fontScale: number
  api: Api
  isSelected?: boolean
  isLocked: boolean
}

const TextEditorAndroid: React.FC<TextEditorAndroidProps> = props => {
  const {
    textEditorId,
    elementText,
    selectText,
    fontScale,
    api,
    isSelected,
    isLocked,
  } = props
  const { paragraphs, selectionUpperBound, selection } = elementText
  const { updateText, step } = api
  const utils =
    step.type === Steps.EditText
      ? textEditor(updateText, step, api.isMobile)
      : undefined

  const mutableRef = useRef<HTMLDivElement>()
  const mutableDummyInputRef = useRef<HTMLTextAreaElement>(null)
  const ref = mutableRef.current
  const [isFocused, setIsFocused] = useState(false)
  const [shouldEditContent, setShouldEditContent] = useState(!isLocked)
  const [isMobileEditorOpen, setIsMobileEditorOpen] = useState(true)
  const [isChangeExternal, setIsChangeExternal] = useState(false)
  // We debounce the content that will show on the textarea for a better view experience
  const [isDebounceTA, setIsDebounceTA] = useState(false)
  const [hasSetParagrapgSig, setHasSetParagraphSig] = useState(false)

  useEffect(() => {
    if (isSelected && !hasSetParagrapgSig) {
      // While we do not have a signature assigned into the api,
      // we take it from the paragraphs fragments
      api.setMobileTextElementSigid(
        elementText.paragraphs
          .map(paragraph => {
            const lastFrag = paragraph.fragments.slice(-1)[0]
            setHasSetParagraphSig(true)
            if (lastFrag && lastFrag.signatureFontId) {
              return lastFrag.signatureFontId
            }
            return undefined
          })
          .find(element => element !== undefined),
      )
    }
    if (!isSelected) {
      setHasSetParagraphSig(false)
    }
  }, [hasSetParagrapgSig, elementText, api, isSelected])

  // Mobile text can't have different color, fonts, etc for each character
  // so we take the style from the first one and apply to all
  const paragraphZero = elementText.paragraphs[0]

  const getCurrentTextAsString = () => {
    return elementText.paragraphs
      .map(paragraph => {
        return paragraph.fragments
          .map((fragment, index) => {
            if (
              api.mobileTextElementSigId === undefined ||
              api.mobileTextElementSigId === 'removeSignature'
            )
              return fragment.text
            else {
              const parLength = paragraph.fragments.length
              // Here we remove the break line extra break line and the signature
              // as this string is for the textarea
              if (index === parLength - 2 || index === parLength - 1) {
                return undefined
              } else {
                return fragment.text
              }
            }
          })
          .join('')
      })
      .join('\n')
  }

  const fontFamily = (fontId: string): string =>
    (
      api.fonts.fonts.find(font => font.id === fontId) ||
      api.fonts.signatures.find(font => font.id === fontId) || {
        fontName: 'HelveticaV2',
      }
    ).fontName

  useEffect(() => {
    if (isSelected && !isDebounceTA) {
      setTimeout(() => {
        setIsDebounceTA(true)
      }, 250)
    } else if (!isSelected && isDebounceTA) {
      setIsDebounceTA(false)
    }
  }, [isSelected, isDebounceTA])

  const onListMutation = useCallback((mutationList: MutationRecord[]) => {
    if (mutationList[0].target.firstChild?.nodeType === 3) {
      setIsChangeExternal(true)
    }
  }, [])

  useMutationObservable(ref, onListMutation, {
    config: {
      childList: true,
      characterData: true,
      subtree: false,
    },
  })

  useEffect(() => {
    // When on mobile, lets not force focus upon text properties changes or keyboard dismissal
    if (api.isMobile && !isFocused) {
      return
    }

    if (!ref || !selection) {
      return
    }

    const startNode = ref.childNodes[selection.start.paragraph]
    const endNode = ref.childNodes[selection.end.paragraph]

    if (!startNode || !endNode) {
      return
    }

    const range = document.createRange()

    range.setStart(
      startNode,
      Math.max(
        0,
        Math.min(selection.start.offset, startNode.childNodes.length),
      ),
    )

    range.setEnd(
      endNode,
      Math.max(0, Math.min(selection.end.offset, endNode.childNodes.length)),
    )

    const windowSelection = window.getSelection()

    if (windowSelection == null) {
      return
    }

    windowSelection.removeAllRanges()
    mutableDummyInputRef.current?.focus()
  }, [ref, selection, isFocused, textEditorId, api.isMobile])

  const copy = (event: React.ClipboardEvent) => {
    if (!selection) {
      return
    }
    event.preventDefault()
    event.clipboardData.setData('text', selection.text)
    event.clipboardData.setData(
      fragmentsFormat,
      JSON.stringify(selection.fragments),
    )
  }

  const saveSelected = () => {
    const windowSelection = window.getSelection()

    if (windowSelection == null) {
      return
    }

    const range = windowSelection.getRangeAt(0)

    const start = selectionIndex(
      range.startContainer,
      range.startOffset,
      true,
      selectionUpperBound,
    )

    const end = selectionIndex(
      range.endContainer,
      range.endOffset,
      false,
      selectionUpperBound,
    )

    selectText(start, end)
  }

  const externalChangesDetected = useCallback((preventTextEdition: boolean) => {
    setShouldEditContent(preventTextEdition)
  }, [])

  const onSelect = (fragmentIndex: number) => {
    selectText(fragmentIndex, fragmentIndex)
  }

  useEffect(() => {
    if (api.isMobile && !isSelected) {
      setIsMobileEditorOpen(false)
    } else {
      setIsMobileEditorOpen(true)
    }
  }, [isSelected, api.isMobile])

  if (isChangeExternal) {
    setTimeout(() => {
      setIsChangeExternal(false)
    }, 10)
    return <span />
  }

  return (
    <>
      {isDebounceTA && (
        <>
          <textarea
            ref={api.mobileTextAreaRef}
            style={{
              width: '100%',
              height: '30vh',
              border: 'none',
              fontFamily: fontFamily(paragraphZero.fontId),
              fontSize: paragraphZero.fontSize * fontScale,
              color: rgbString(paragraphZero.color),
              textAlign: paragraphZero.textAlign.toLowerCase() as
                | 'left'
                | 'center'
                | 'right',
              lineHeight: 1.4,
              minHeight: '1.4em',
              margin: '0',
              resize: 'none',
              backgroundColor: 'transparent',
              padding: '10px',
            }}
          >
            {getCurrentTextAsString()}
          </textarea>
          {api.mobileTextElementSigId &&
            api.mobileTextElementSigId !== 'removeSignature' && (
              <div
                style={{
                  width: '100%',
                  padding: '10px',
                  fontFamily: fontFamily(api.mobileTextElementSigId),
                  fontSize: paragraphZero.fontSize * fontScale,
                  color: rgbString(paragraphZero.color),
                  textAlign: paragraphZero.textAlign.toLowerCase() as
                    | 'left'
                    | 'center'
                    | 'right',
                }}
              >
                æ
              </div>
            )}
        </>
      )}
      <div
        id={textEditorId}
        ref={ref => (mutableRef.current = ref ?? undefined)}
        contentEditable={
          api.isMobile
            ? shouldEditContent && isMobileEditorOpen
            : shouldEditContent
        }
        suppressContentEditableWarning={true}
        spellCheck="false"
        autoCapitalize="off"
        autoCorrect="off"
        style={{
          whiteSpace: 'pre-wrap',
          outline: '0px',
          width: '100%',
          display: isDebounceTA ? 'none' : undefined,
        }}
        onClick={event => {
          event.stopPropagation()
          event.preventDefault()
          saveSelected()
        }}
        onCut={event => {
          copy(event)
          utils?.deleteFragments()
        }}
        onCopy={copy}
        onPaste={event => {
          event.preventDefault()
          const fragments = event.clipboardData.getData(fragmentsFormat)
          const text = event.clipboardData.getData('text')
          if (fragments) {
            utils?.insertFragments(JSON.parse(fragments))
          } else if (text) {
            utils?.insertText(text)
          }
        }}
        onKeyDown={event => {
          if (event.key === 'Backspace') {
            event.preventDefault()
            utils?.deleteFragments()
          } else if (event.key === 'Delete') {
            event.preventDefault()
            utils?.deleteForwardFragments()
          } else if (event.key === 'Enter') {
            event.preventDefault()
            utils?.insertNewLine()
          } else if (event.key === 'Tab') {
            event.preventDefault()
            utils?.insertCharacter('\t')
          }
        }}
        onKeyPress={event => {
          if (event.nativeEvent.metaKey || event.nativeEvent.ctrlKey) {
            return
          }
          event.preventDefault()
          utils?.insertCharacter(event.nativeEvent.key)
        }}
        onFocus={() => {
          setIsFocused(true)
        }}
        onBlur={() => {
          // when there's an active selection do not force focus
          if (selection && selection.start.index !== selection.end.index) {
            return
          }
          setIsFocused(false)
        }}
      >
        {paragraphs.map(paragraph => (
          <TextEditorParagraphAndroid
            key={paragraph.index}
            paragraph={paragraph}
            fontScale={fontScale}
            api={api}
            externalChangesDetected={externalChangesDetected}
            onSelect={onSelect}
          />
        ))}
      </div>
    </>
  )
}

export default TextEditorAndroid
