import { NormalizedData } from 'src/legacy_graphql'
import { IsUnresolved } from 'src/utils/Future'
import { Set } from 'immutable'
import range from 'lodash/range'
import {
  filterSparseArray,
  insertIntoSortedSparseArray,
} from 'src/utils/sparseArrayUtils'
import { Changes, OperationFactory, Query, QueryFactory } from './Query'

const pagingVariables = <
  Variables extends { offset?: number | null } | { limit?: number | null }
>(
  pageSize: number,
) => ({
  page,
  ...variables
}: Omit<Variables, 'offset' | 'limit'> & { page: number }): Variables =>
  (({
    ...variables,
    offset: (page - 1) * pageSize,
    limit: pageSize,
  } as unknown) as Variables)

export const updatedSparseResults = <
  Typename extends keyof NormalizedData,
  Result extends { __typename: Typename; id: string },
  Variables
>(
  results: Result[],
  allChanges: Changes,
  variables: Omit<Variables, 'offset' | 'limit'>,
  resultTypename: Typename,
  isValidResult: (result: unknown) => result is Result,
  filter: (
    result: Result,
    variables: Omit<Variables, 'offset' | 'limit'>,
  ) => boolean,
  orderBy: (result: Result) => string,
) => {
  const changes = allChanges[resultTypename]

  if (changes === undefined) {
    return { results, numberCreated: 0, numberDeleted: 0 }
  }

  const { created, updated, deleted: deletedResults } = changes as {
    created: unknown[]
    updated: unknown[]
    deleted: Set<string>
  }

  const updatedResults = [...created, ...updated].map(possibleResult => {
    if (isValidResult(possibleResult)) {
      return possibleResult
    } else {
      throw new IsUnresolved()
    }
  })

  const changedResults = Set.union([
    Set(updatedResults.map(result => result.id)),
    deletedResults,
  ])

  return {
    results: insertIntoSortedSparseArray(
      filterSparseArray(results, result => !changedResults.contains(result.id)),
      updatedResults.filter(result => filter(result, variables)),
      orderBy,
    ),
    numberCreated: created.length,
    numberDeleted: deletedResults.count(),
  }
}

export type PaginatedState<
  Result extends { __typename: keyof NormalizedData; id: string }
> = { results: Result[]; count: number }

export type PaginatedView<
  Result extends { __typename: keyof NormalizedData; id: string }
> = { results: Result[]; count: number; pageSize: number }

export const PaginatedQuery = <
  Data,
  Typename extends keyof NormalizedData,
  Result extends { __typename: Typename; id: string },
  Variables extends { offset: number; limit: number }
>({
  operation,
  mapData,
  pageSize,
  resultTypename,
  isValidResult,
  filter,
  orderBy,
}: {
  operation: OperationFactory<Data, Variables>
  mapData: (data: Data) => PaginatedState<Result>
  pageSize: number
  resultTypename: Typename
  isValidResult: (result: unknown) => result is Result
  filter: (
    result: Result,
    variables: Omit<Variables, 'offset' | 'limit'>,
  ) => boolean
  orderBy: (result: Result) => string
}): QueryFactory<
  PaginatedState<Result>,
  Variables,
  Omit<Variables, 'offset' | 'limit'> & { page: number },
  PaginatedView<Result>
> => {
  const paginateState = (
    { results, count }: PaginatedState<Result>,
    offset: number,
  ) => {
    const mutableResults = Array<Result>()
    results.forEach(
      (result, index) => (mutableResults[index + offset] = result),
    )
    return {
      results: mutableResults,
      count,
    }
  }

  const mergeState = (
    { results: newResults, count }: PaginatedState<Result>,
    { results: currentResults }: PaginatedState<Result>,
  ) => {
    const newResultIds = Set(newResults.map(result => result.id))
    const mutableResults = filterSparseArray(
      currentResults,
      result => !newResultIds.contains(result.id),
    )
    newResults.forEach((result, index) => (mutableResults[index] = result))
    return {
      results: mutableResults,
      count,
    }
  }

  return Query({
    operation,
    comapVariables: pagingVariables(pageSize),
    mapResult: (result, offset, limit, previousState) =>
      result
        .map(mapData)
        .map(state => paginateState(state, offset ?? 0))
        .map(state =>
          previousState ? mergeState(state, previousState) : state,
        ),
    updateState: (state, allChanges, variables) => {
      const { results, numberCreated, numberDeleted } = updatedSparseResults(
        state.results,
        allChanges,
        variables,
        resultTypename,
        isValidResult,
        filter,
        orderBy,
      )

      return {
        results,
        count: state.count + numberCreated - numberDeleted,
      }
    },
    mapState: ({ results, count }, offset = 0, limit = pageSize) => {
      const mutableResults = Array<Result>()

      range(offset, Math.min(offset + limit, count)).forEach(index => {
        const result = results[index]
        if (result === undefined) {
          throw new IsUnresolved()
        }
        mutableResults.push(result)
      })

      return {
        results: mutableResults,
        count,
        pageSize,
      }
    },
  })
}
