import React from 'react'

import LoadingIndicator from '../LoadingIndicator/LoadingIndicator'
import styles from './transition.module.scss'

interface TransitionProps {
  message?: string
  subMessage?: string | string[]
  messageStyle?: React.CSSProperties
  style?: React.CSSProperties
}

const Transition: React.FC<TransitionProps> = props => {
  const {
    message = 'Your Promptings are Loading!',
    messageStyle,
    style,
  } = props

  const subMessages = [
    'You are changing lives, one card at a time - feels good, doesn’t it? Following your promptings helps make a difference in the world. Keep it up!',
  ]

  return (
    <div id="transition" className={styles.modalWindow} style={style}>
      <LoadingIndicator
        message={message}
        messageStyle={messageStyle}
        subMessage={subMessages}
      />
    </div>
  )
}

export default Transition
