import React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { useAccount } from 'src/hooks'
import { useItem } from 'src/react_query'

import { ProductPreviewModal, StoreCampaignPreview } from '..'
import styles from '../../containers/campaignStore.module.scss'

interface Props {
  // item: Item
  id: string
  onClose: () => void
  onPurchase?: (id: string) => void
}

const ItemComponent: React.FC<Props> = props => {
  const { onClose, onPurchase } = props
  const { data } = useItem({ itemCode: props.id })
  const item = data?.item

  const account = useAccount()

  /* Items can model both a product (when the "campaign" attribute is null)
     or an actual campaigns (when "campaign" is set). Render the proper component
     accordingly */

  if (!item) {
    return <div>Could not retrieve item</div>
  }

  return (
    <div>
      {item.campaign ? (
        <TransitionGroup>
          <CSSTransition
            classNames={styles.store}
            timeout={{ enter: 500, exit: 300 }}
          >
            <>
              <StoreCampaignPreview
                item={item}
                onClose={onClose}
                onPurchase={
                  onPurchase ? item => onPurchase(item.id) : undefined
                }
                isUserStaff={account.isStaff}
              />
            </>
          </CSSTransition>
        </TransitionGroup>
      ) : (
        <ProductPreviewModal
          product={item}
          onClose={onClose}
          onPurchase={product => onPurchase?.(product.id)}
        />
      )}
    </div>
  )
}

export default ItemComponent
