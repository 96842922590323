import uniqBy from 'lodash/uniqBy'
import React from 'react'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

import { stepperSteps } from '../helpers'
import { Spacer, Text } from '@sendoutcards/quantum-design-ui'

interface Props {
  stepIndex: number
}

const ImportStepper: React.FC<Props> = props => {
  const { children, stepIndex } = props

  return (
    <div style={{ width: '85%', margin: 'auto' }}>
      <Stepper activeStep={stepIndex}>
        {uniqBy(stepperSteps, x => x.title).map(step => (
          <Step key={step.title}>
            <StepLabel>
              <Text type="caption">{step.title}</Text>
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      <Text type="largeBody" alignment="center">
        {stepperSteps[stepIndex].description}
      </Text>
      <Spacer space="x4" />
      {children}
    </div>
  )
}

export default ImportStepper
