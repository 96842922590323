import { Maybe } from '../../../__generated__/globalTypes'

import {
  CreditCardBillingAddressFragment,
  isCreditCardBillingAddressFragment,
} from './CreditCardBillingAddressFragment'

import creditCardFragmentRawString from '../CreditCardFragment.graphql'

export const creditCardFragmentString = creditCardFragmentRawString

export type CreditCardFragment = {
  __typename: 'CreditCard'
  id: string
  brand: string
  last4: string
  expMonth: number
  expYear: number
  object: string
  billingAddress: Maybe<CreditCardBillingAddressFragment>
}

export const isCreditCardFragment = (
  fragment: any,
): fragment is CreditCardFragment =>
  fragment &&
  fragment.__typename === 'CreditCard' &&
  typeof fragment.id === 'string' &&
  typeof fragment.brand === 'string' &&
  typeof fragment.last4 === 'string' &&
  typeof fragment.expMonth === 'number' &&
  typeof fragment.expYear === 'number' &&
  typeof fragment.object === 'string' &&
  (fragment.billingAddress === null ||
    (isCreditCardBillingAddressFragment(fragment.billingAddress) as boolean))
