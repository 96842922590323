import { getPlanAddonsDescriptions } from 'src/legacy_graphql'
import useQueries from './useQueries'
import { PlansDescription } from 'src/marketing/plans/types'

export const usePlanAddons = () => {
  const [planAddonsDescriptions] = useQueries(getPlanAddonsDescriptions())
  const addonOptions = planAddonsDescriptions
    .map(PlansDescription.fromPlansDescriptionFragment)
    .sort(
      (a, b) =>
        (a.description?.order ?? 10_000) - (b.description?.order ?? 10_000),
    )
  const [promotionalAddons, baseAddons] = addonOptions.reduce(
    (acc: [PlansDescription[], PlansDescription[]], option) => {
      const [promotional, base] = acc
      if (option.description && option.description.isPromotional) {
        return [[...promotional, option], base]
      } else {
        return [promotional, [...base, option]]
      }
    },
    [[], []],
  )
  return {
    promotionalAddons,
    baseAddons,
  }
}
