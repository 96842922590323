import {
  CardFragment,
  CardType,
  ElementFragment,
  ElementPictureFragment,
  Maybe,
  PanelFragment,
} from 'src/graphql/generated/graphql'

type ValidatedPanel = {
  panel: number
  isBlank: boolean
}

type ValidatedCardPanels = {
  front: boolean | null
  inside: boolean | null
  flap: boolean | null
  back: boolean | null
}

type BooleanObject = {
  [key: string]: boolean
}

const customCardImages = ['93616348', '93616310']

/*
 * Flag blank front, flap, or back panels.
 * Flag if all inside panels blank.
 * Inside-panel system images are excluded
 * Flag custom card default images 93616348/93616310 on front
 */
const validateCardPanels = (card: CardFragment): ValidatedPanel[] =>
  card.panels
    .filter(panel => !panel.isFullBleed)
    .map(panel => {
      return {
        panel: panel.location,
        isBlank:
          !panel.elements ||
          (panel.location === 0 && isEmptyFront(panel)) ||
          (!!panel.elements &&
            panel.elements
              .filter(
                element =>
                  !isInsidePanelSystemImage(
                    element,
                    panel.location,
                    card.backPanelLocation,
                    card.type,
                  ),
              )
              .reduce(
                (sum: boolean, element) => sum && isEmptyElement(element),
                true,
              )),
      }
    })

const customCardDefault = (element: ElementFragment) =>
  !!element &&
  !!element.image &&
  !!element.image.image &&
  customCardImages.includes(element.image.image.id)

const isEmptyFront = (panel: PanelFragment) =>
  !!panel &&
  !!panel.elements &&
  panel.elements.some(element => customCardDefault(element))

const isInsidePanelSystemImage = (
  element: ElementFragment,
  panelLocation: number,
  backPanelLocation: number,
  type: CardType,
) =>
  ((type === CardType.Flatcard || type === CardType.Postcard) &&
    panelLocation === backPanelLocation &&
    isSystemElement(element)) ||
  (panelLocation !== 0 &&
    panelLocation !== backPanelLocation &&
    isSystemElement(element))

const isSystemImage = (image: ElementPictureFragment) =>
  !!image && !!image.image && /system_images/.test(image.image.url)

const isSystemElement = (element: ElementFragment) =>
  !!element && !!element.image && isSystemImage(element.image)

const emptyTextElement = (element: ElementFragment): boolean =>
  !!element &&
  !!element.text &&
  !element.text.paragraphs.some(paragraph =>
    paragraph.textFragments.some(textFragment => textFragment.text),
  )

const emptyImageElement = (element: ElementFragment): boolean =>
  !!element && !!element.image && !element.image.image

const isEmptyElement = (element: ElementFragment) =>
  emptyTextElement(element) || emptyImageElement(element)

/* Returns front, inside, flap, back panel info based on card type */
export const getBlankPanels = (
  card: Maybe<CardFragment>,
): ValidatedCardPanels => {
  if (!card) {
    return {
      front: null,
      inside: null,
      flap: null,
      back: null,
    }
  }
  const validatedPanels = validateCardPanels(card)
  const defaultPanels = { front: null, inside: null, flap: null, back: null }

  const isBlankPanel = (location: number): boolean =>
    validatedPanels.find(validatedPanel => validatedPanel.panel === location)
      ?.isBlank ?? true

  const isFrontPanelBlank = isBlankPanel(0)
  const isBackPanelBlank = isBlankPanel(card.backPanelLocation)

  if (card.type === CardType.Flatcard || card.type === CardType.Postcard) {
    return {
      front: isFrontPanelBlank,
      inside: null,
      flap: null,
      back: isBackPanelBlank,
    }
  }

  const isInsidePanel1Blank = isBlankPanel(1)
  const isInsidePanel2Blank = isBlankPanel(2)

  if (card.type === CardType.TwoPanel || card.type === CardType.TwoPanelBig) {
    return {
      front: isFrontPanelBlank,
      inside: isInsidePanel1Blank && isInsidePanel2Blank,
      flap: null,
      back: isBackPanelBlank,
    }
  }
  if (card.type === CardType.ThreePanel) {
    return {
      front: isFrontPanelBlank,
      inside: isInsidePanel1Blank && isInsidePanel2Blank && isBlankPanel(3),
      flap: isBlankPanel(4),
      back: isBackPanelBlank,
    }
  }
  return defaultPanels
}

export const hasBlankPanels = (validatedPanels: ValidatedCardPanels): boolean =>
  Object.keys(validatedPanels).reduce(
    (hasBlanks: boolean, panel: string) =>
      hasBlanks || (validatedPanels as BooleanObject)[panel],
    false,
  )
