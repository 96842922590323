import { Maybe, Operation } from '../../../__generated__/globalTypes'

import {
  DetailedContactFragment,
  detailedContactFragmentString,
} from '../../fragments/__generated__/DetailedContactFragment'

import {
  InviteFragment,
  inviteFragmentString,
} from '../../fragments/__generated__/InviteFragment'

import { noteFragmentString } from '../../fragments/__generated__/NoteFragment'

import { addressFragmentString } from '../../fragments/__generated__/AddressFragment'

import { relatedContactFragmentString } from '../../fragments/__generated__/RelatedContactFragment'

import { socProContactFragmentString } from '../../fragments/__generated__/SocProContactFragment'

import { contactFragmentString } from '../../fragments/__generated__/ContactFragment'

import { optionalYearDateFragmentString } from '../../fragments/__generated__/OptionalYearDateFragment'

import { prospectFragmentString } from '../../fragments/__generated__/ProspectFragment'

import { calendarDateFragmentString } from '../../fragments/__generated__/CalendarDateFragment'

import createContactWithInviteRawString from '../CreateContactWithInvite.graphql'

const createContactWithInviteString = [
  createContactWithInviteRawString,
  detailedContactFragmentString,
  inviteFragmentString,
  noteFragmentString,
  addressFragmentString,
  relatedContactFragmentString,
  socProContactFragmentString,
  contactFragmentString,
  optionalYearDateFragmentString,
  prospectFragmentString,
  calendarDateFragmentString,
].join('\n\n')

export type CreateContactWithInviteData = {
  createContactWithInvite: {
    contact: DetailedContactFragment & {
      invite: Maybe<InviteFragment>
    }
  }
}

export type CreateContactWithInviteVariables = {
  inviteId: string
}

export type CreateContactWithInvite = Operation<
  CreateContactWithInviteData,
  CreateContactWithInviteVariables
>

export const CreateContactWithInvite = ({
  inviteId,
}: CreateContactWithInviteVariables): CreateContactWithInvite => ({
  query: createContactWithInviteString,
  variables: {
    inviteId,
  },
})
