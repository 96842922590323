import campaignShareFragmentRawString from '../CampaignShareFragment.graphql'

export const campaignShareFragmentString = campaignShareFragmentRawString

export type CampaignShareFragment = {
  __typename: 'CampaignShare'
  id: string
  fromName: string
  dateShared: string
  campaign: {
    __typename: 'Campaign'
    id: string
    name: string
  }
}

export const isCampaignShareFragment = (
  fragment: any,
): fragment is CampaignShareFragment =>
  fragment &&
  fragment.__typename === 'CampaignShare' &&
  typeof fragment.id === 'string' &&
  typeof fragment.fromName === 'string' &&
  typeof fragment.dateShared === 'string' &&
  fragment.campaign &&
  fragment.campaign.__typename === 'Campaign' &&
  typeof fragment.campaign.id === 'string' &&
  typeof fragment.campaign.name === 'string'
