import {
  Div,
  List,
  ListItem,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { DefaultError } from 'src/chrome'
import suspenseBoundary from 'src/chrome/SuspenseBoundary/suspenseBoundaryHOC'
import { getMinimalContacts } from 'src/legacy_graphql'
import { DetailedOrderFragment } from 'src/graphql/generated/graphql'
import { useQueries } from 'src/hooks'

type CampaignRecipientsProps = {
  order: DetailedOrderFragment | undefined
}

const CampaignRecipients: React.FC<CampaignRecipientsProps> = props => {
  const { order } = props

  const [recipients] = useQueries(
    order
      ? getMinimalContacts({
          contacts: order?.contacts.map(contact => contact.id),
        })
      : undefined,
  )

  const formatName = (index: number, ...args: string[]): string => {
    if (args.length === 0) {
      return ''
    }
    const fullName = args.map(
      name => name.charAt(0).toUpperCase() + name.slice(1),
    )
    return `${index + 1}) ${fullName.join(' ')}`
  }

  return (
    <Div css={{ height: '100%', maxHeight: '700px', overflow: 'auto' }}>
      <Text type={'largeBody'} alignment={'left'}>
        Recipients ({order?.contacts.length}):
      </Text>
      <Spacer space={'x2_5'} />
      <List
        orientation={'vertical'}
        alignment={'center'}
        css={{ marginLeft: '15px' }}
      >
        {recipients &&
          recipients.map((recipient, index) => (
            <ListItem orientation={'horizontal'} key={recipient.id}>
              <Text type={'body'}>
                {formatName(index, recipient.firstName, recipient.lastName)}
              </Text>
            </ListItem>
          ))}
      </List>
    </Div>
  )
}

export default suspenseBoundary({
  component: CampaignRecipients,
  unresolved: <Text type="subtitle">Loading recipients...</Text>,
  failure: DefaultError,
})
