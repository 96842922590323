import { useActions, useState } from 'src/hooks'
import { SignUpRoute } from './routes/SignUp'
import { BasicCreateAccountForm } from 'src/saleor/components/account/checkoutModal/BasicCreateAccountForm'

type SignUpProps = {
  route?: SignUpRoute
}

const SignUp: React.FC<SignUpProps> = props => {
  const actions = useActions()
  const [isSponsorExpanded, setIsSponsorExpanded] = useState(false)

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: '80px',
        height: '100dhv',
        width: '100dwv',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div style={{ margin: '0 auto', minWidth: '300px', maxWidth: '600px' }}>
        <BasicCreateAccountForm
          shouldStackForm={true}
          handleAlreadyUser={actions.openSignIn}
          handleSuccessfullAccCreation={() => actions.openAccount()}
          isSponsorExpanded={isSponsorExpanded}
          handleIsSponsorExpanded={isExpanded => {
            setIsSponsorExpanded(isExpanded)
          }}
        />
        <div
          id="checkout-find-my-sponsor-container"
          style={{ display: 'flex', width: '100%' }}
        />
      </div>
    </div>
  )
}

export default SignUp
