import { Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'

import { formatAmount } from 'src/helpers'

interface RowProps {
  label: string
  amount: string
}

const Row: React.FC<RowProps> = props => {
  const { label, amount } = props
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Text type="caption">{label}</Text>
      <Text type="caption" color="primaryHeading">
        {formatAmount(amount)}
      </Text>
    </div>
  )
}

export interface StripeOrder {
  subtotal: string
  discount: string
  shipping: string
  tax: string
  total: string
}

interface StripeOrderProps {
  stripeOrder: StripeOrder
}

const StripeOrderTotal: React.FC<StripeOrderProps> = props => {
  const { subtotal, discount, shipping, tax, total } = props.stripeOrder
  return (
    <>
      <Row label={'Subtotal:'} amount={subtotal} />
      {discount !== '0.00' && <Row label={'Discount:'} amount={discount} />}
      <Row label={'Shipping:'} amount={shipping} />
      <Row label={'Tax:'} amount={tax} />
      <Row label={'Total:'} amount={total} />
    </>
  )
}

export default StripeOrderTotal
