import { Maybe } from '../../../__generated__/globalTypes'

import { ContactFragment, isContactFragment } from './ContactFragment'

import {
  RelatedContactFragment,
  isRelatedContactFragment,
} from './RelatedContactFragment'

import { NoteFragment, isNoteFragment } from './NoteFragment'

import detailedContactFragmentRawString from '../DetailedContactFragment.graphql'

export const detailedContactFragmentString = detailedContactFragmentRawString

export type DetailedContactFragment = ContactFragment & {
  homePhone: Maybe<string>
  cellPhone: Maybe<string>
  workPhone: Maybe<string>
  faxNumber: Maybe<string>
  website: string
  emailAddress: Maybe<string>
  parent: Maybe<RelatedContactFragment>
  spouse: Maybe<RelatedContactFragment>
  children: RelatedContactFragment[]
  groups: {
    __typename: 'Group'
    id: string
    name: string
  }[]
  notes: NoteFragment[]
}

export const isDetailedContactFragment = (
  fragment: any,
): fragment is DetailedContactFragment =>
  (isContactFragment(fragment) as boolean) &&
  (fragment.homePhone === null || typeof fragment.homePhone === 'string') &&
  (fragment.cellPhone === null || typeof fragment.cellPhone === 'string') &&
  (fragment.workPhone === null || typeof fragment.workPhone === 'string') &&
  (fragment.faxNumber === null || typeof fragment.faxNumber === 'string') &&
  typeof fragment.website === 'string' &&
  (fragment.emailAddress === null ||
    typeof fragment.emailAddress === 'string') &&
  (fragment.parent === null ||
    (isRelatedContactFragment(fragment.parent) as boolean)) &&
  (fragment.spouse === null ||
    (isRelatedContactFragment(fragment.spouse) as boolean)) &&
  Array.isArray(fragment.children) &&
  fragment.children
    .slice(0, 5)
    .reduce(
      (accum: any, next: any) =>
        accum && (isRelatedContactFragment(next) as boolean),
      true,
    ) &&
  Array.isArray(fragment.groups) &&
  fragment.groups
    .slice(0, 5)
    .reduce(
      (accum: any, next: any) =>
        accum &&
        next &&
        next.__typename === 'Group' &&
        typeof next.id === 'string' &&
        typeof next.name === 'string',
      true,
    ) &&
  Array.isArray(fragment.notes) &&
  fragment.notes
    .slice(0, 5)
    .reduce(
      (accum: any, next: any) => accum && (isNoteFragment(next) as boolean),
      true,
    )
