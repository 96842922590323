import React from 'react'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'

import { useRef, useState } from 'src/hooks'
import { Text } from '@sendoutcards/quantum-design-ui'

import { RequestStatusSearchFilter, SearchFilter } from '../types'
import FilterChip from '../lib/FilterChip'

import styles from '../filteredSearchBar.module.scss'
import { CloseButton } from 'src/chrome'
import { ContactRequestStatusEnum } from 'src/legacy_graphql'

type Props = {
  deleteFilter: (filter: SearchFilter) => void
  filters: SearchFilter[]
  onClear: () => void
  resetEditedFilter: () => void
  updateFilter: <T extends SearchFilter>(filter: T, update: Partial<T>) => void
  filter: RequestStatusSearchFilter
}

const ContactRequestFilter: React.FC<Props> = props => {
  const {
    filters,
    deleteFilter,
    onClear,
    resetEditedFilter,
    updateFilter,
    filter,
  } = props

  const dropdownRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [inputValue, setInputValue] = useState('')

  const definedFilters = filters.filter(filter => filter.value !== undefined)

  const handleClear = () => {
    setInputValue('')
    onClear()
  }

  const handleUpdateFilter = (value: ContactRequestStatusEnum) => {
    updateFilter(filter, { value })
    resetEditedFilter()
  }

  return (
    <>
      <div className={styles.filterChips}>
        <div className={styles.filterTypeContainer} ref={dropdownRef}>
          <Paper style={{ width: 200 }}>
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <Text type="footnote" color="primaryHeading">
                      {ContactRequestStatusEnum.PENDING}
                    </Text>
                  }
                  disableTypography={true}
                  onClick={() =>
                    handleUpdateFilter(ContactRequestStatusEnum.PENDING)
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Text type="footnote" color="primaryHeading">
                      {ContactRequestStatusEnum.COMPLETED}
                    </Text>
                  }
                  disableTypography={true}
                  onClick={() =>
                    handleUpdateFilter(ContactRequestStatusEnum.COMPLETED)
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Text type="footnote" color="primaryHeading">
                      {ContactRequestStatusEnum.EXPIRED}
                    </Text>
                  }
                  disableTypography={true}
                  onClick={() =>
                    handleUpdateFilter(ContactRequestStatusEnum.EXPIRED)
                  }
                />
              </ListItem>
            </List>
          </Paper>
        </div>

        <div className={styles.chips}>
          {filters.map(filter => {
            return (
              <FilterChip
                key={Math.random()}
                filter={filter}
                onDelete={deleteFilter}
              />
            )
          })}

          <p
            style={{
              padding: '2px 5px 2px 10px',
              backgroundColor: '#F7F7F7',
              borderRadius: 15,
            }}
          >
            {filter.name}:
          </p>
        </div>
      </div>

      <input
        style={{ height: 45 }}
        type="text"
        className={styles.searchInput}
        placeholder={'Choose a status'}
        disabled={true}
        ref={inputRef}
      />

      {(definedFilters.length > 0 || inputValue) && (
        <CloseButton onClose={() => handleClear()} size={18} />
      )}
    </>
  )
}

export default ContactRequestFilter
