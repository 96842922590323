import React from 'react'

import styles from './responsiveTable.module.scss'

const RwdTable: React.FC<{}> = props => {
  const { children } = props
  return <table className={styles.rwdTable}>{children}</table>
}

export default RwdTable
