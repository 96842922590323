import { formatAmount } from 'src/helpers/payments'

const formatCost = (cost: number) => `$${formatAmount(cost)}`

/**
 * Will return a negative formatted cost
 * @param cost Positive cost to be formatted in cents
 */
export const formatCostToNegative = (cost: number) => `-$${formatAmount(cost)}`

export default formatCost
