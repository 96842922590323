import { Operation } from '../../../__generated__/globalTypes'

import {
  StripeOrderFragment,
  stripeOrderFragmentString,
} from '../../fragments/__generated__/StripeOrderFragment'

import createCampaignOrderRawString from '../CreateCampaignOrder.graphql'

const createCampaignOrderString = [
  createCampaignOrderRawString,
  stripeOrderFragmentString,
].join('\n\n')

export type CreateCampaignOrderData = {
  createCampaignOrder: {
    stripeOrder: StripeOrderFragment
  }
}

export type CreateCampaignOrderVariables = {
  itemCode: string
}

export type CreateCampaignOrder = Operation<
  CreateCampaignOrderData,
  CreateCampaignOrderVariables
>

export const CreateCampaignOrder = ({
  itemCode,
}: CreateCampaignOrderVariables): CreateCampaignOrder => ({
  query: createCampaignOrderString,
  variables: {
    itemCode,
  },
})
