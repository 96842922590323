import React, { FC } from 'react'
import { SVGProps } from './types'

export const Edit: FC<SVGProps> = ({ size, color, onClick }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick && (() => onClick())}
    >
      <path
        d="M14 22.75H8C2.57 22.75 0.25 20.43 0.25 15V9C0.25 3.57 2.57 1.25 8 1.25H10C10.41 1.25 10.75 1.59 10.75 2C10.75 2.41 10.41 2.75 10 2.75H8C3.39 2.75 1.75 4.39 1.75 9V15C1.75 19.61 3.39 21.25 8 21.25H14C18.61 21.25 20.25 19.61 20.25 15V13C20.25 12.59 20.59 12.25 21 12.25C21.41 12.25 21.75 12.59 21.75 13V15C21.75 20.43 19.43 22.75 14 22.75Z"
        fill={color}
      />
      <path
        d="M7.49935 17.6901C6.88935 17.6901 6.32935 17.4701 5.91935 17.0701C5.42935 16.5801 5.21935 15.8701 5.32935 15.1201L5.75935 12.1101C5.83935 11.5301 6.21935 10.7801 6.62935 10.3701L14.5093 2.49006C16.4993 0.500058 18.5193 0.500058 20.5093 2.49006C21.5993 3.58006 22.0893 4.69006 21.9893 5.80006C21.8993 6.70006 21.4193 7.58006 20.5093 8.48006L12.6293 16.3601C12.2193 16.7701 11.4693 17.1501 10.8893 17.2301L7.87935 17.6601C7.74935 17.6901 7.61935 17.6901 7.49935 17.6901ZM15.5693 3.55006L7.68935 11.4301C7.49935 11.6201 7.27935 12.0601 7.23935 12.3201L6.80935 15.3301C6.76935 15.6201 6.82935 15.8601 6.97935 16.0101C7.12935 16.1601 7.36935 16.2201 7.65935 16.1801L10.6693 15.7501C10.9293 15.7101 11.3793 15.4901 11.5593 15.3001L19.4393 7.42006C20.0893 6.77006 20.4293 6.19006 20.4793 5.65006C20.5393 5.00006 20.1993 4.31006 19.4393 3.54006C17.8393 1.94006 16.7393 2.39006 15.5693 3.55006Z"
        fill={color}
      />
      <path
        d="M18.8496 9.82978C18.7796 9.82978 18.7096 9.81978 18.6496 9.79978C16.0196 9.05978 13.9296 6.96978 13.1896 4.33978C13.0796 3.93978 13.3096 3.52978 13.7096 3.40978C14.1096 3.29978 14.5196 3.52978 14.6296 3.92978C15.2296 6.05978 16.9196 7.74978 19.0496 8.34978C19.4496 8.45978 19.6796 8.87978 19.5696 9.27978C19.4796 9.61978 19.1796 9.82978 18.8496 9.82978Z"
        fill={color}
      />
    </svg>
  )
}
