import React, { useRef } from 'react'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import {
  DefaultError,
  Dialog,
  Icon,
  SuspenseBoundary,
  Transition,
} from 'src/chrome'
import { getInfiniteMinimalContacts } from 'src/legacy_graphql'
import { useQueries, useScroll, useState } from 'src/hooks'

import styles from './shareContact.module.scss'
import { CircularProgress, DialogTitle } from '@material-ui/core'
import DialogContent from '@material-ui/core/DialogContent'
import {
  Button,
  HStack,
  Input,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'

type Props = {
  contactsIds: string[]
  onSubmit: (username: string) => void
  onClose?: () => void
}

const ShareContact: React.FC<Props> = props => {
  const { onSubmit, onClose, contactsIds } = props

  const triggerElement = useRef<HTMLDivElement>(null)

  const [{ results: contacts, ...scrollOptions }] = useQueries(
    getInfiniteMinimalContacts({
      contacts: contactsIds,
      showSecondaryContacts: true,
    }),
  )

  const [username, setUsername] = useState('')

  const handleScroll = useScroll({
    ...scrollOptions,
    triggerElement,
  })

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    onSubmit(username)
  }

  return (
    <div>
      <Dialog open={true} onClose={onClose}>
        <DialogTitle>
          <div style={{ display: 'flex', color: '#333' }}>
            <Text type="caption">Share Contacts</Text>
            <div style={{ marginLeft: 'auto' }}>
              <span className={styles.close} onClick={() => onClose?.()}>
                <Icon icon={'CLOSE'} color={'#6f8394'} size={18} />
              </span>
            </div>
          </div>
        </DialogTitle>
        <DialogContent className={styles.shareContainer}>
          <div className={styles.shareBody}>
            <div className={styles.contacts}>
              <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                <HStack gap="x1" justify="flex-start">
                  <Input
                    placeholder="Username"
                    type="text"
                    value={username}
                    onChange={value => setUsername(value)}
                  />
                  <Button onClick="submitForm">Share</Button>
                </HStack>
              </form>
              <Spacer space="x2" />
              <Text type="body">{`Sharing ${contactsIds.length} Contacts`}</Text>
              <div className={styles.contactList} onScroll={handleScroll}>
                <List style={{ padding: 0 }}>
                  {contacts.map(contact => (
                    <ListItem key={contact.id}>
                      <ListItemText
                        primary={
                          <Text type="caption">
                            {contact.firstName} {contact.lastName}
                          </Text>
                        }
                        disableTypography={true}
                      />
                    </ListItem>
                  ))}
                </List>
                {scrollOptions.hasMore && (
                  <div
                    ref={triggerElement}
                    style={{ background: 'transparent', boxShadow: 'none' }}
                  >
                    <CircularProgress />
                  </div>
                )}
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

const ShareContactWithTransition: React.FC<Props> = props => (
  <SuspenseBoundary
    unresolved={<Transition message={'Loading ...'} />}
    failure={DefaultError}
  >
    <ShareContact {...props} />
  </SuspenseBoundary>
)

export default ShareContactWithTransition
