import React, { FC } from 'react'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import { PlanFragment } from 'src/legacy_graphql'
import { Text } from '@sendoutcards/quantum-design-ui'

type PlanContextProps = {
  plans: Array<PlanFragment>
}

const PlanContext: FC<PlanContextProps> = props => {
  const { children, plans } = props
  return (
    <div style={{ textAlign: 'left' }}>
      {plans.map(plan => {
        return (
          <List key={plan.title}>
            <ListItem id={'plan_type'}>
              <ListItemText
                primary={
                  <Text type="caption">{`${plan.title} Subscription`}</Text>
                }
                secondary={
                  <Text type="caption">{`${plan.price.description}/'Month`}</Text>
                }
                disableTypography={true}
              />
            </ListItem>
          </List>
        )
      })}
      {children}
    </div>
  )
}

export default PlanContext
