import {
  CreateCampaignInput,
  Operation,
} from '../../../__generated__/globalTypes'

import {
  CampaignFragment,
  campaignFragmentString,
} from '../../fragments/__generated__/CampaignFragment'

import { sendDelayFragmentString } from '../../fragments/__generated__/SendDelayFragment'

import { campaignLineFragmentString } from '../../fragments/__generated__/CampaignLineFragment'

import { minimalPanelFragmentString } from '../../fragments/__generated__/MinimalPanelFragment'

import { giftVariationFragmentString } from '../../fragments/__generated__/GiftVariationFragment'

import { amountFragmentString } from '../../fragments/__generated__/AmountFragment'

import { priceFragmentString } from '../../fragments/__generated__/PriceFragment'

import createCampaignRawString from '../CreateCampaign.graphql'

const createCampaignString = [
  createCampaignRawString,
  campaignFragmentString,
  sendDelayFragmentString,
  campaignLineFragmentString,
  minimalPanelFragmentString,
  giftVariationFragmentString,
  amountFragmentString,
  priceFragmentString,
].join('\n\n')

export type CreateCampaignData = {
  createCampaign: {
    campaign: CampaignFragment
  }
}

export type CreateCampaignVariables = {
  campaign: CreateCampaignInput
}

export type CreateCampaign = Operation<
  CreateCampaignData,
  CreateCampaignVariables
>

export const CreateCampaign = ({
  campaign,
}: CreateCampaignVariables): CreateCampaign => ({
  query: createCampaignString,
  variables: {
    campaign: CreateCampaignInput(campaign),
  },
})
