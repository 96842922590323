export type Maybe<T> = T | null
export type If<T, V> = { __typename: T } & V
export type Operation<Data, Variables = undefined> = {
  query: string
  variables: Variables
}
export type ById<T> = { [id: string]: T | undefined }

export enum Currency {
  SOC = 'SOC',
  USD = 'USD',
  UNAVAILABLE = 'UNAVAILABLE',
}

export enum CardType {
  POSTCARD = 'POSTCARD',
  FLATCARD = 'FLATCARD',
  TWO_PANEL = 'TWO_PANEL',
  THREE_PANEL = 'THREE_PANEL',
  TWO_PANEL_BIG = 'TWO_PANEL_BIG',
}

export enum CardPaperType {
  STD = 'STD',
  PRE = 'PRE',
  HVY = 'HVY',
}

export enum ContactRequestStatusEnum {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  EXPIRED = 'EXPIRED',
}

export enum CardSendType {
  HEARTFELT = 'HEARTFELT',
  SYSTEM = 'SYSTEM',
}

export enum CurrencyType {
  POINT = 'POINT',
  EXPENSE = 'EXPENSE',
  FREE = 'FREE',
  USD = 'USD',
  CREDIT = 'CREDIT',
  CARDTOKEN = 'CARDTOKEN',
}

export enum LayoutCategory {
  BASIC_LAYOUTS = 'BASIC_LAYOUTS',
  BACK_PANELS = 'BACK_PANELS',
}

export enum Owner {
  USER = 'USER',
  SYSTEM = 'SYSTEM',
}

export enum LayoutDimensions {
  VERTICAL = 'VERTICAL',
  HORIZONTAL = 'HORIZONTAL',
  POSTCARD = 'POSTCARD',
}

export enum ReminderType {
  ANNIVERSARY = 'ANNIVERSARY',
  BIRTHDAY = 'BIRTHDAY',
  PROJECT = 'PROJECT',
}

export enum NewReminderType {
  ANNIVERSARY = 'ANNIVERSARY',
  BIRTHDAY = 'BIRTHDAY',
  CAMPAIGN = 'CAMPAIGN',
}

export enum NotificationType {
  ANNIVERSARY = 'ANNIVERSARY',
  BIRTHDAY = 'BIRTHDAY',
  CAMPAIGN = 'CAMPAIGN',
  REJECTED_CARDS = 'REJECTED_CARDS',
  HELD_PRODUCTION_INFOS = 'HELD_PRODUCTION_INFOS',
  CONTACT_REQUEST_COMPLETED = 'CONTACT_REQUEST_COMPLETED',
}

export enum TextAlignment {
  LEFT = 'LEFT',
  CENTER = 'CENTER',
  RIGHT = 'RIGHT',
}

export enum PlansPlanIconChoices {
  A_10_CARD = 'A_10_CARD',
  A_15_CARD = 'A_15_CARD',
  A_20_CARD = 'A_20_CARD',
  A_25_CARD = 'A_25_CARD',
  BROCHURE = 'BROCHURE',
  CANCEL = 'CANCEL',
  CARD = 'CARD',
  CARD_ERROR = 'CARD_ERROR',
  CHECK = 'CHECK',
  CONTACT = 'CONTACT',
  DANGER = 'DANGER',
  DATABASE = 'DATABASE',
  DOUBLE_CHECK = 'DOUBLE_CHECK',
  EDIT = 'EDIT',
  EMPTY_GIFT = 'EMPTY_GIFT',
  GIFT = 'GIFT',
  HEARTFELT = 'HEARTFELT',
  INFO = 'INFO',
  MULTIPLE_CARDS = 'MULTIPLE_CARDS',
  PACKAGE = 'PACKAGE',
  PAPER_PLANE = 'PAPER_PLANE',
  PAPER_PLANE_FLYING = 'PAPER_PLANE_FLYING',
  PAPER_PLANE_WITH_HEART = 'PAPER_PLANE_WITH_HEART',
  REPLACE = 'REPLACE',
  SEARCH = 'SEARCH',
  SHOPPING_CART = 'SHOPPING_CART',
  UPLOAD = 'UPLOAD',
  USER = 'USER',
  V_CHECK = 'V_CHECK',
  X = 'X',
  SMILE_CARD = 'SMILE_CARD',
  SMILE_CARD_W_HEARTS = 'SMILE_CARD_W_HEARTS',
  SMILE_CARD_W_HEART = 'SMILE_CARD_W_HEART',
  SOLOPRENEUR = 'SOLOPRENEUR',
  ENTREPRENEUR = 'ENTREPRENEUR',
  BUSINESSPRENEUR = 'BUSINESSPRENEUR',
  CARD_PACK = 'CARD_PACK',
  TIME_BLOCK = 'TIME_BLOCK',
  EXP_CALENDAR = 'EXP_CALENDAR',
  CHECK_MARK = 'CHECK_MARK',
  TICKET = 'TICKET',
  AUTOMATED_CARD = 'AUTOMATED_CARD',
  CAMPAIGN_CARD = 'CAMPAIGN_CARD',
  GROUP_SEND_CARD = 'GROUP_SEND_CARD',
  SCHEDULE_CARD = 'SCHEDULE_CARD',
  MULTI_TOUCH_CARD = 'MULTI_TOUCH_CARD',
  CATALOG_CARD = 'CATALOG_CARD',
  PHOTO_DROP_CARD = 'PHOTO_DROP_CARD',
  ENHANCED_STORAGE = 'ENHANCED_STORAGE',
  PREMIUM_STORAGE = 'PREMIUM_STORAGE',
  PERSONAL_SIGNATURE = 'PERSONAL_SIGNATURE',
  PERSONAL_HAND_WRITING = 'PERSONAL_HAND_WRITING',
  ESSENTIAL_PACKS = 'ESSENTIAL_PACKS',
}

export enum SendDelayType {
  IMM = 'IMM',
  BIR = 'BIR',
  ANN = 'ANN',
  SPE = 'SPE',
}

export enum SendDelayDelayType {
  DAY = 'DAY',
  WEE = 'WEE',
  MON = 'MON',
}

export enum SendDelayTimeType {
  BEF = 'BEF',
  AFT = 'AFT',
}

export enum VerifyPhoneNumberFailureType {
  CODE_EXPIRED = 'CODE_EXPIRED',
  INCORRECT_CODE = 'INCORRECT_CODE',
  ALREADY_VERIFIED = 'ALREADY_VERIFIED',
  TOO_MANY_ATTEMPTS = 'TOO_MANY_ATTEMPTS',
  UNVERIFIED_ACCOUNT_FOUND = 'UNVERIFIED_ACCOUNT_FOUND',
  NO_VERIFIED_ACCOUNTS_FOUND = 'NO_VERIFIED_ACCOUNTS_FOUND',
}

export type CreateCampaignInput = {
  id?: Maybe<string>
  name: string
  order?: Maybe<string>
  lines?: Maybe<LineInput[]>
  returnAddress?: Maybe<AddressInput>
  isShareable?: Maybe<boolean>
}

export const CreateCampaignInput = ({
  id,
  name,
  order,
  lines,
  returnAddress,
  isShareable,
}: CreateCampaignInput): CreateCampaignInput => ({
  id,
  name,
  order,
  lines: lines && lines.map(x => LineInput(x)),
  returnAddress: returnAddress && AddressInput(returnAddress),
  isShareable,
})

export type LineInput = {
  card?: Maybe<string>
  giftVariation?: Maybe<string>
  sendDelay?: Maybe<SendDelayInput>
}

export const LineInput = ({
  card,
  giftVariation,
  sendDelay,
}: LineInput = {}): LineInput => ({
  card,
  giftVariation,
  sendDelay: sendDelay && SendDelayInput(sendDelay),
})

export type SendDelayInput = {
  type?: Maybe<SendDelayType>
  delayNumber?: Maybe<number>
  delayType?: Maybe<SendDelayDelayType>
  timeType?: Maybe<SendDelayTimeType>
  specificDate?: Maybe<string>
}

export const SendDelayInput = ({
  type,
  delayNumber,
  delayType,
  timeType,
  specificDate,
}: SendDelayInput = {}): SendDelayInput => ({
  type,
  delayNumber,
  delayType,
  timeType,
  specificDate,
})

export type AddressInput = {
  firstName: string
  lastName: string
  company: string
  address1: string
  address2: string
  city: string
  state: string
  postalCode: string
  country: string
}

export const AddressInput = ({
  firstName,
  lastName,
  company,
  address1,
  address2,
  city,
  state,
  postalCode,
  country,
}: AddressInput): AddressInput => ({
  firstName,
  lastName,
  company,
  address1,
  address2,
  city,
  state,
  postalCode,
  country,
})

export type CreateContactInput = {
  firstName?: Maybe<string>
  lastName?: Maybe<string>
  companyName?: Maybe<string>
  address1?: Maybe<string>
  address2?: Maybe<string>
  city?: Maybe<string>
  state?: Maybe<string>
  postalCode?: Maybe<string>
  country?: Maybe<string>
  birthday?: Maybe<OptionalYearDateInput>
  anniversary?: Maybe<OptionalYearDateInput>
  homePhone?: Maybe<string>
  cellNumber?: Maybe<string>
  cellPhone?: Maybe<string>
  workPhone?: Maybe<string>
  faxNumber?: Maybe<string>
  emailAddress?: Maybe<string>
  website?: Maybe<string>
  websites?: Maybe<string[]>
  groupName?: Maybe<string>
  groups?: Maybe<string[]>
  spouse?: Maybe<string>
  parent?: Maybe<string>
  isPrimary?: Maybe<boolean>
  notes?: Maybe<NoteInput[]>
  id?: Maybe<string>
  spouseName?: Maybe<string>
  spouseBirthday?: Maybe<OptionalYearDateInput>
  spouseContact?: Maybe<CreateSubContactInput>
  children?: Maybe<Maybe<CreateSubContactInput>[]>
}

export const CreateContactInput = ({
  firstName,
  lastName,
  companyName,
  address1,
  address2,
  city,
  state,
  postalCode,
  country,
  birthday,
  anniversary,
  homePhone,
  cellNumber,
  cellPhone,
  workPhone,
  faxNumber,
  emailAddress,
  website,
  websites,
  groupName,
  groups,
  spouse,
  parent,
  isPrimary,
  notes,
  id,
  spouseName,
  spouseBirthday,
  spouseContact,
  children,
}: CreateContactInput = {}): CreateContactInput => ({
  firstName,
  lastName,
  companyName,
  address1,
  address2,
  city,
  state,
  postalCode,
  country,
  birthday: birthday && OptionalYearDateInput(birthday),
  anniversary: anniversary && OptionalYearDateInput(anniversary),
  homePhone,
  cellNumber,
  cellPhone,
  workPhone,
  faxNumber,
  emailAddress,
  website,
  websites,
  groupName,
  groups,
  spouse,
  parent,
  isPrimary,
  notes: notes && notes.map(x => NoteInput(x)),
  id,
  spouseName,
  spouseBirthday: spouseBirthday && OptionalYearDateInput(spouseBirthday),
  spouseContact: spouseContact && CreateSubContactInput(spouseContact),
  children: children && children.map(x => x && CreateSubContactInput(x)),
})

export type OptionalYearDateInput = {
  day: number
  month: number
  year?: Maybe<number>
}

export const OptionalYearDateInput = ({
  day,
  month,
  year,
}: OptionalYearDateInput): OptionalYearDateInput => ({
  day,
  month,
  year,
})

export type NoteInput = {
  id: string
  text: string
  date?: Maybe<CalendarDateInput>
}

export const NoteInput = ({ id, text, date }: NoteInput): NoteInput => ({
  id,
  text,
  date: date && CalendarDateInput(date),
})

export type CalendarDateInput = {
  day: number
  month: number
  year: number
}

export const CalendarDateInput = ({
  day,
  month,
  year,
}: CalendarDateInput): CalendarDateInput => ({
  day,
  month,
  year,
})

export type CreateSubContactInput = {
  firstName?: Maybe<string>
  lastName?: Maybe<string>
  companyName?: Maybe<string>
  address1?: Maybe<string>
  address2?: Maybe<string>
  city?: Maybe<string>
  state?: Maybe<string>
  postalCode?: Maybe<string>
  country?: Maybe<string>
  birthday?: Maybe<OptionalYearDateInput>
  anniversary?: Maybe<OptionalYearDateInput>
  homePhone?: Maybe<string>
  cellNumber?: Maybe<string>
  cellPhone?: Maybe<string>
  workPhone?: Maybe<string>
  faxNumber?: Maybe<string>
  emailAddress?: Maybe<string>
  website?: Maybe<string>
  websites?: Maybe<string[]>
  groupName?: Maybe<string>
  groups?: Maybe<string[]>
  spouse?: Maybe<string>
  parent?: Maybe<string>
  isPrimary?: Maybe<boolean>
  notes?: Maybe<NoteInput[]>
  id?: Maybe<string>
}

export const CreateSubContactInput = ({
  firstName,
  lastName,
  companyName,
  address1,
  address2,
  city,
  state,
  postalCode,
  country,
  birthday,
  anniversary,
  homePhone,
  cellNumber,
  cellPhone,
  workPhone,
  faxNumber,
  emailAddress,
  website,
  websites,
  groupName,
  groups,
  spouse,
  parent,
  isPrimary,
  notes,
  id,
}: CreateSubContactInput = {}): CreateSubContactInput => ({
  firstName,
  lastName,
  companyName,
  address1,
  address2,
  city,
  state,
  postalCode,
  country,
  birthday: birthday && OptionalYearDateInput(birthday),
  anniversary: anniversary && OptionalYearDateInput(anniversary),
  homePhone,
  cellNumber,
  cellPhone,
  workPhone,
  faxNumber,
  emailAddress,
  website,
  websites,
  groupName,
  groups,
  spouse,
  parent,
  isPrimary,
  notes: notes && notes.map(x => NoteInput(x)),
  id,
})

export type CreateGroupInput = {
  id?: Maybe<string>
  name: string
  description?: Maybe<string>
  members?: Maybe<string[]>
  groups?: Maybe<string[]>
}

export const CreateGroupInput = ({
  id,
  name,
  description,
  members,
  groups,
}: CreateGroupInput): CreateGroupInput => ({
  id,
  name,
  description,
  members,
  groups,
})

export type CreateOrderInput = {
  campaign?: Maybe<string>
  lines?: Maybe<LineInput[]>
  contacts?: Maybe<string[]>
  contactsFromGroups?: Maybe<string[]>
  groups?: Maybe<string[]>
  returnAddress?: Maybe<AddressInput>
  bulkUpgradeShippingAddress?: Maybe<AddressInput>
  bulkUpgradeCardQty?: Maybe<number>
  id?: Maybe<string>
}

export const CreateOrderInput = ({
  campaign,
  lines,
  contacts,
  contactsFromGroups,
  groups,
  returnAddress,
  bulkUpgradeShippingAddress,
  bulkUpgradeCardQty,
  id,
}: CreateOrderInput = {}): CreateOrderInput => ({
  campaign,
  lines: lines && lines.map(x => LineInput(x)),
  contacts,
  contactsFromGroups,
  groups,
  returnAddress: returnAddress && AddressInput(returnAddress),
  bulkUpgradeShippingAddress:
    bulkUpgradeShippingAddress && AddressInput(bulkUpgradeShippingAddress),
  bulkUpgradeCardQty,
  id,
})

export type PanelInput = {
  location: number
  isFullBleed: boolean
  backgroundColor?: Maybe<ColorInput>
  backgroundColorObject?: Maybe<ColorInput>
  elements: ElementInput[]
}

export const PanelInput = ({
  location,
  isFullBleed,
  backgroundColor,
  backgroundColorObject,
  elements,
}: PanelInput): PanelInput => ({
  location,
  isFullBleed,
  backgroundColor: backgroundColor && ColorInput(backgroundColor),
  backgroundColorObject:
    backgroundColorObject && ColorInput(backgroundColorObject),
  elements: elements.map(x => ElementInput(x)),
})

export type ColorInput = {
  red: number
  green: number
  blue: number
}

export const ColorInput = ({ red, green, blue }: ColorInput): ColorInput => ({
  red,
  green,
  blue,
})

export type ElementInput = {
  x: number
  y: number
  z: number
  width: number
  height: number
  rotation: number
  locked: boolean
  draggable?: Maybe<boolean>
  sticker?: Maybe<ElementStickerInput>
  image?: Maybe<ElementPictureInput>
  text?: Maybe<ElementTextInput>
}

export const ElementInput = ({
  x,
  y,
  z,
  width,
  height,
  rotation,
  locked,
  draggable,
  sticker,
  image,
  text,
}: ElementInput): ElementInput => ({
  x,
  y,
  z,
  width,
  height,
  rotation,
  locked,
  draggable,
  sticker: sticker && ElementStickerInput(sticker),
  image: image && ElementPictureInput(image),
  text: text && ElementTextInput(text),
})

export type ElementStickerInput = {
  sticker?: Maybe<string>
  position?: Maybe<PositionInput>
  scale?: Maybe<number>
}

export const ElementStickerInput = ({
  sticker,
  position,
  scale,
}: ElementStickerInput = {}): ElementStickerInput => ({
  sticker,
  position: position && PositionInput(position),
  scale,
})

export type PositionInput = {
  x: number
  y: number
}

export const PositionInput = ({ x, y }: PositionInput): PositionInput => ({
  x,
  y,
})

export type ElementPictureInput = {
  image?: Maybe<string>
  position?: Maybe<PositionInput>
  scale?: Maybe<number>
  filter?: Maybe<string>
}

export const ElementPictureInput = ({
  image,
  position,
  scale,
  filter,
}: ElementPictureInput = {}): ElementPictureInput => ({
  image,
  position: position && PositionInput(position),
  scale,
  filter,
})

export type ElementTextInput = {
  paragraphs?: Maybe<ParagraphInput[]>
}

export const ElementTextInput = ({
  paragraphs,
}: ElementTextInput = {}): ElementTextInput => ({
  paragraphs: paragraphs && paragraphs.map(x => ParagraphInput(x)),
})

export type ParagraphInput = {
  textFragments: TextFragmentInput[]
  alignment: TextAlignment
}

export const ParagraphInput = ({
  textFragments,
  alignment,
}: ParagraphInput): ParagraphInput => ({
  textFragments: textFragments.map(x => TextFragmentInput(x)),
  alignment,
})

export type TextFragmentInput = {
  text: string
  textColor: ColorInput
  font: string
  fontSize: number
  isReplacement: boolean
}

export const TextFragmentInput = ({
  text,
  textColor,
  font,
  fontSize,
  isReplacement,
}: TextFragmentInput): TextFragmentInput => ({
  text,
  textColor: ColorInput(textColor),
  font,
  fontSize,
  isReplacement,
})

export type UpdateContactInput = {
  firstName?: Maybe<string>
  lastName?: Maybe<string>
  companyName?: Maybe<string>
  address1?: Maybe<string>
  address2?: Maybe<string>
  city?: Maybe<string>
  state?: Maybe<string>
  postalCode?: Maybe<string>
  country?: Maybe<string>
  birthday?: Maybe<OptionalYearDateInput>
  anniversary?: Maybe<OptionalYearDateInput>
  homePhone?: Maybe<string>
  cellNumber?: Maybe<string>
  cellPhone?: Maybe<string>
  workPhone?: Maybe<string>
  faxNumber?: Maybe<string>
  emailAddress?: Maybe<string>
  website?: Maybe<string>
  websites?: Maybe<string[]>
  groupName?: Maybe<string>
  groups?: Maybe<string[]>
  spouse?: Maybe<string>
  parent?: Maybe<string>
  isPrimary?: Maybe<boolean>
  notes?: Maybe<NoteInput[]>
  id: string
}

export const UpdateContactInput = ({
  firstName,
  lastName,
  companyName,
  address1,
  address2,
  city,
  state,
  postalCode,
  country,
  birthday,
  anniversary,
  homePhone,
  cellNumber,
  cellPhone,
  workPhone,
  faxNumber,
  emailAddress,
  website,
  websites,
  groupName,
  groups,
  spouse,
  parent,
  isPrimary,
  notes,
  id,
}: UpdateContactInput): UpdateContactInput => ({
  firstName,
  lastName,
  companyName,
  address1,
  address2,
  city,
  state,
  postalCode,
  country,
  birthday: birthday && OptionalYearDateInput(birthday),
  anniversary: anniversary && OptionalYearDateInput(anniversary),
  homePhone,
  cellNumber,
  cellPhone,
  workPhone,
  faxNumber,
  emailAddress,
  website,
  websites,
  groupName,
  groups,
  spouse,
  parent,
  isPrimary,
  notes: notes && notes.map(x => NoteInput(x)),
  id,
})

export type ItemObject = {
  itemCode: string
  quantity?: Maybe<number>
}

export const ItemObject = ({ itemCode, quantity }: ItemObject): ItemObject => ({
  itemCode,
  quantity,
})

export enum RequestEmailVerificationFailureType {
  INVALID_EMAIL = 'INVALID_EMAIL',
  TOO_MANY_ATTEMPTS = 'TOO_MANY_ATTEMPTS',
}

export type PhoneNumberInput = {
  countryIsoCode: string
  nationalNumber: string
  extension?: Maybe<string>
  numberOfLeadingZeros?: Maybe<number>
  preferredDomesticCarrierCode?: Maybe<string>
}

export const PhoneNumberInput = ({
  countryIsoCode,
  nationalNumber,
  extension,
  numberOfLeadingZeros,
  preferredDomesticCarrierCode,
}: PhoneNumberInput): PhoneNumberInput => ({
  countryIsoCode,
  nationalNumber,
  extension,
  numberOfLeadingZeros,
  preferredDomesticCarrierCode,
})

export enum RequestPhoneNumberVerificationFailureType {
  INVALID_PHONE_NUMBER = 'INVALID_PHONE_NUMBER',
  TOO_MANY_ATTEMPTS = 'TOO_MANY_ATTEMPTS',
  GENERIC_ERROR = 'GENERIC_ERROR',
}

export type UpdateCampaignInput = {
  id: string
  name?: Maybe<string>
  lines?: Maybe<LineInput[]>
  returnAddress?: Maybe<AddressInput>
}

export const UpdateCampaignInput = ({
  id,
  name,
  lines,
  returnAddress,
}: UpdateCampaignInput): UpdateCampaignInput => ({
  id,
  name,
  lines: lines && lines.map(x => LineInput(x)),
  returnAddress: returnAddress && AddressInput(returnAddress),
})

export type UpdateCoachPublicInfoDisplayInput = {
  isEmailPublic?: Maybe<boolean>
  isPhoneNumberPublic?: Maybe<boolean>
}

export const UpdateCoachPublicInfoDisplayInput = ({
  isEmailPublic,
  isPhoneNumberPublic,
}: UpdateCoachPublicInfoDisplayInput = {}): UpdateCoachPublicInfoDisplayInput => ({
  isEmailPublic,
  isPhoneNumberPublic,
})

export type UpdateGroupInput = {
  id: string
  name?: Maybe<string>
  description?: Maybe<string>
  members?: Maybe<string[]>
  newMembers?: Maybe<string[]>
}

export const UpdateGroupInput = ({
  id,
  name,
  description,
  members,
  newMembers,
}: UpdateGroupInput): UpdateGroupInput => ({
  id,
  name,
  description,
  members,
  newMembers,
})

export type UpdateInviteInput = {
  inviteId: string
  contactId: string
}

export const UpdateInviteInput = ({
  inviteId,
  contactId,
}: UpdateInviteInput): UpdateInviteInput => ({
  inviteId,
  contactId,
})

export type UpdateOrderInput = {
  campaign?: Maybe<string>
  lines?: Maybe<LineInput[]>
  contacts?: Maybe<string[]>
  contactsFromGroups?: Maybe<string[]>
  groups?: Maybe<string[]>
  returnAddress?: Maybe<AddressInput>
  bulkUpgradeShippingAddress?: Maybe<AddressInput>
  bulkUpgradeCardQty?: Maybe<number>
  id: string
}

export const UpdateOrderInput = ({
  campaign,
  lines,
  contacts,
  contactsFromGroups,
  groups,
  returnAddress,
  bulkUpgradeShippingAddress,
  bulkUpgradeCardQty,
  id,
}: UpdateOrderInput): UpdateOrderInput => ({
  campaign,
  lines: lines && lines.map(x => LineInput(x)),
  contacts,
  contactsFromGroups,
  groups,
  returnAddress: returnAddress && AddressInput(returnAddress),
  bulkUpgradeShippingAddress:
    bulkUpgradeShippingAddress && AddressInput(bulkUpgradeShippingAddress),
  bulkUpgradeCardQty,
  id,
})

export type RecipientInput = {
  id: string
  address: AddressInput
}

export const RecipientInput = ({
  id,
  address,
}: RecipientInput): RecipientInput => ({
  id,
  address: AddressInput(address),
})

export type UpdateReminderInput = {
  id: string
  orderId: string
}

export const UpdateReminderInput = ({
  id,
  orderId,
}: UpdateReminderInput): UpdateReminderInput => ({
  id,
  orderId,
})

export type CardBillingAddressInput = {
  addressCity?: Maybe<string>
  addressCountry?: Maybe<string>
  addressLine1?: Maybe<string>
  addressLine2?: Maybe<string>
  addressZip?: Maybe<string>
  addressState?: Maybe<string>
}

export const CardBillingAddressInput = ({
  addressCity,
  addressCountry,
  addressLine1,
  addressLine2,
  addressZip,
  addressState,
}: CardBillingAddressInput = {}): CardBillingAddressInput => ({
  addressCity,
  addressCountry,
  addressLine1,
  addressLine2,
  addressZip,
  addressState,
})

export enum MarketingPricingGridRowItemIconChoices {
  A_10_CARD = 'A_10_CARD',
  A_15_CARD = 'A_15_CARD',
  A_20_CARD = 'A_20_CARD',
  A_25_CARD = 'A_25_CARD',
  BROCHURE = 'BROCHURE',
  CANCEL = 'CANCEL',
  CARD = 'CARD',
  CARD_ERROR = 'CARD_ERROR',
  CHECK = 'CHECK',
  CONTACT = 'CONTACT',
  DANGER = 'DANGER',
  DATABASE = 'DATABASE',
  DOUBLE_CHECK = 'DOUBLE_CHECK',
  EDIT = 'EDIT',
  EMPTY_GIFT = 'EMPTY_GIFT',
  GIFT = 'GIFT',
  HEARTFELT = 'HEARTFELT',
  INFO = 'INFO',
  MULTIPLE_CARDS = 'MULTIPLE_CARDS',
  PACKAGE = 'PACKAGE',
  PAPER_PLANE = 'PAPER_PLANE',
  PAPER_PLANE_FLYING = 'PAPER_PLANE_FLYING',
  PAPER_PLANE_WITH_HEART = 'PAPER_PLANE_WITH_HEART',
  REPLACE = 'REPLACE',
  SEARCH = 'SEARCH',
  SHOPPING_CART = 'SHOPPING_CART',
  UPLOAD = 'UPLOAD',
  USER = 'USER',
  V_CHECK = 'V_CHECK',
  X = 'X',
  SMILE_CARD = 'SMILE_CARD',
  SMILE_CARD_W_HEARTS = 'SMILE_CARD_W_HEARTS',
  SMILE_CARD_W_HEART = 'SMILE_CARD_W_HEART',
  SOLOPRENEUR = 'SOLOPRENEUR',
  ENTREPRENEUR = 'ENTREPRENEUR',
  BUSINESSPRENEUR = 'BUSINESSPRENEUR',
  CARD_PACK = 'CARD_PACK',
  TIME_BLOCK = 'TIME_BLOCK',
  EXP_CALENDAR = 'EXP_CALENDAR',
  CHECK_MARK = 'CHECK_MARK',
  TICKET = 'TICKET',
  AUTOMATED_CARD = 'AUTOMATED_CARD',
  CAMPAIGN_CARD = 'CAMPAIGN_CARD',
  GROUP_SEND_CARD = 'GROUP_SEND_CARD',
  SCHEDULE_CARD = 'SCHEDULE_CARD',
  MULTI_TOUCH_CARD = 'MULTI_TOUCH_CARD',
  CATALOG_CARD = 'CATALOG_CARD',
  PHOTO_DROP_CARD = 'PHOTO_DROP_CARD',
  ENHANCED_STORAGE = 'ENHANCED_STORAGE',
  PREMIUM_STORAGE = 'PREMIUM_STORAGE',
  PERSONAL_SIGNATURE = 'PERSONAL_SIGNATURE',
  PERSONAL_HAND_WRITING = 'PERSONAL_HAND_WRITING',
  ESSENTIAL_PACKS = 'ESSENTIAL_PACKS',
}

export type NormalizedContact = {
  lastName?: string
  notes?: {
    __typename: 'Note'
    id: string
  }[]
  __typename: 'Contact'
  faxNumber?: Maybe<string>
  invite?: Maybe<{
    __typename: 'Invite'
    id: string
  }>
  parent?: Maybe<{
    lastName: string
    faxNumber: Maybe<string>
    city: string
    cellPhone: Maybe<string>
    homePhone: Maybe<string>
    anniversary: Maybe<{
      __typename: 'OptionalYearDate'
      day: number
      month: number
      year: Maybe<number>
    }>
    state: string
    country: Maybe<string>
    emailAddress: Maybe<string>
    postalCode: string
    isPrimary: boolean
    website: string
    id: string
    firstName: string
    company: string
    workPhone: Maybe<string>
    birthday: Maybe<{
      __typename: 'OptionalYearDate'
      day: number
      month: number
      year: Maybe<number>
    }>
    address1: string
    address2: string
  }>
  city?: string
  cellPhone?: Maybe<string>
  contactRequest?: Maybe<{
    id: string
    status: ContactRequestStatusEnum
    token: string
    expiresAt: string
  }>
  homePhone?: Maybe<string>
  anniversary?: Maybe<{
    __typename: 'OptionalYearDate'
    day: number
    month: number
    year: Maybe<number>
  }>
  state?: string
  groups?: {
    __typename: 'Group'
    id: string
  }[]
  spouse?: Maybe<{
    lastName: string
    faxNumber: Maybe<string>
    city: string
    cellPhone: Maybe<string>
    homePhone: Maybe<string>
    anniversary: Maybe<{
      __typename: 'OptionalYearDate'
      day: number
      month: number
      year: Maybe<number>
    }>
    state: string
    country: Maybe<string>
    emailAddress: Maybe<string>
    postalCode: string
    isPrimary: boolean
    website: string
    id: string
    firstName: string
    company: string
    workPhone: Maybe<string>
    birthday: Maybe<{
      __typename: 'OptionalYearDate'
      day: number
      month: number
      year: Maybe<number>
    }>
    address1: string
    address2: string
  }>
  country?: Maybe<string>
  emailAddress?: Maybe<string>
  postalCode?: string
  isPrimary?: boolean
  website?: string
  id: string
  firstName?: string
  company?: string
  workPhone?: Maybe<string>
  birthday?: Maybe<{
    __typename: 'OptionalYearDate'
    day: number
    month: number
    year: Maybe<number>
  }>
  address1?: string
  address2?: string
  children?: {
    lastName: string
    faxNumber: Maybe<string>
    city: string
    cellPhone: Maybe<string>
    homePhone: Maybe<string>
    anniversary: Maybe<{
      __typename: 'OptionalYearDate'
      day: number
      month: number
      year: Maybe<number>
    }>
    state: string
    country: Maybe<string>
    emailAddress: Maybe<string>
    postalCode: string
    isPrimary: boolean
    website: string
    id: string
    firstName: string
    company: string
    workPhone: Maybe<string>
    birthday: Maybe<{
      __typename: 'OptionalYearDate'
      day: number
      month: number
      year: Maybe<number>
    }>
    address1: string
    address2: string
  }[]
}

export type NormalizedStripeOrder = {
  __typename: 'StripeOrder'
  id: string
  total: string
  subtotal: string
  discount: string
  shipping: string
  tax: string
}

export type NormalizedGiftVariation = {
  points?: {
    __typename: 'Amount'
    amount: number
    currency: Currency
    description: string
  }
  packaging?: Maybe<string>
  __typename: 'GiftVariation'
  price?: {
    __typename: 'Price'
    asString: string
    currency: CurrencyType
    amount: number
  }
  name?: string
  isSendable?: boolean
  shipping?: Maybe<string>
  defaultImageUrl?: string
  contents?: Maybe<string>
  id: string
  description?: Maybe<string>
  isEligibleFreeGift?: boolean
  imageUrls?: string[]
}

export type NormalizedMarketingParagraph = {
  __typename: 'MarketingParagraph'
  createdAt: string
  updatedAt: string
  id: string
  title: string
  content: string
}

export type NormalizedCreditCard = {
  __typename: 'CreditCard'
  id: string
  brand: string
  last4: string
  expMonth: number
  expYear: number
  object: string
  billingAddress: Maybe<{
    city: Maybe<string>
    country: Maybe<string>
    line1: Maybe<string>
    line2: Maybe<string>
    postalcode: Maybe<string>
    state: Maybe<string>
  }>
}

export type NormalizedInvite = {
  contact: Maybe<{
    __typename: 'Contact'
    id: string
  }>
  hasSentCard: boolean
  __typename: 'Invite'
  prospect: Maybe<{
    __typename: 'Prospect'
    id: string
  }>
  utmStream: Maybe<string>
  hasProspectJoined: boolean
  socProContact: Maybe<{
    __typename: 'SocProContact'
    id: string
  }>
  id: string
  createdAt: string
}

export type NormalizedPlan = {
  color: Maybe<string>
  __typename: 'Plan'
  isExpenseReloadRequired: boolean
  stripeId: string
  price: {
    __typename: 'Amount'
    amount: number
    currency: Currency
    description: string
  }
  bulkCardCost: number
  backgroundStartColor: string
  giftDiscount: number
  backgroundEndColor: string
  isAddon: boolean
  subtitle: Maybe<string>
  title: string
  icon: PlansPlanIconChoices
  id: string
  description: Maybe<string>
  cardCost: number
}

export type NormalizedLayout = {
  __typename: 'Layout'
  id: string
  category: Maybe<LayoutCategory>
  owner: Owner
  dimensions: Maybe<LayoutDimensions>
  thumbnail: {
    url: string
  }
  panel: {
    isFullBleed: boolean
    __typename: 'Panel'
    width: number
    previewUrl: string
    height: number
    name: string
    isLocked: boolean
    backgroundColor: Maybe<{
      __typename: 'Color'
      red: number
      green: number
      blue: number
    }>
    location: number
    elements: {
      __typename: 'Element'
      width: number
      height: number
      locked: boolean
      text: Maybe<{
        paragraphs: {
          __typename: 'Paragraph'
          textFragments: {
            __typename: 'TextFragment'
            text: string
            textColor: {
              __typename: 'Color'
              red: number
              green: number
              blue: number
            }
            font: {
              __typename: 'Font'
              id: string
            }
            fontSize: number
            isReplacement: boolean
          }[]
          alignment: TextAlignment
        }[]
      }>
      draggable: Maybe<boolean>
      x: number
      y: number
      z: number
      image: Maybe<{
        __typename: 'ElementPicture'
        image: Maybe<{
          __typename: 'Image'
          id: string
        }>
        position: {
          x: number
          y: number
        }
        scale: number
        filter: Maybe<{
          name: string
        }>
      }>
      rotation: number
    }[]
  }
}

export type NormalizedNotification = {
  __typename: 'Notification'
  message: Maybe<string>
  linkText: Maybe<string>
  data: Maybe<string>
  acceptText: Maybe<string>
  denyText: Maybe<string>
  type: Maybe<NotificationType>
  link: Maybe<string>
  id: string
  createdAt: string
}

export type NormalizedUser = {
  lastName: string
  __typename: 'User'
  promptingsCoach?: Maybe<{
    isEmailPublic: boolean
    isPhoneNumberPublic: boolean
  }>
  shippingAddress?: Maybe<{
    city: string
    state: string
    country: string
  }>
  storefront?: Maybe<{
    __typename: 'Storefront'
    mugshot: Maybe<{
      url: string
    }>
    biography: Maybe<string>
    slug: Maybe<string>
  }>
  username?: string
  dateJoined?: string
  id: string
  firstName: string
  formattedPhoneNumber?: string
  genealogyId?: number
  email: string
}

export type NormalizedProspect = {
  __typename: 'Prospect'
  id: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  address: Maybe<{
    lastName: string
    __typename: 'Address'
    city: string
    state: string
    country: string
    postalCode: string
    firstName: string
    company: string
    address1: string
    address2: string
  }>
  ordersSent: number
}

export type NormalizedFlag = {
  __typename: 'Flag'
  id: string
  name: string
}

export type NormalizedRecipientLine = {
  __typename: 'RecipientLine'
  id: string
}

export type NormalizedDetailedReferralCredits = {
  __typename: 'DetailedReferralCredits'
  id: number
  amount: {
    __typename: 'Amount'
    amount: number
    currency: Currency
    description: string
  }
  expirationDate: string
}

export type NormalizedCardCategory = {
  __typename: 'CardCategory'
  id: string
  description: string
  cards: {
    __typename: 'SendableCard'
    id: string
  }[]
}

export type NormalizedGiftCategory = {
  __typename: 'GiftCategory'
  id: string
  name: string
  children: {
    id: string
    name: string
    children: {
      id: string
      name: string
      children: {
        id: string
        name: string
      }[]
    }[]
  }[]
}

export type NormalizedOrder = {
  sendType?: CardSendType
  isSent?: boolean
  returnAddress?: Maybe<{
    lastName: string
    __typename: 'Address'
    city: string
    state: string
    country: string
    postalCode: string
    firstName: string
    company: string
    address1: string
    address2: string
  }>
  __typename: 'Order'
  invalidContacts?: {
    __typename: 'Contact'
    id: string
  }[]
  isSendable?: boolean
  cost?: {
    total: {
      __typename: 'Price'
      asString: string
      currency: CurrencyType
      amount: number
    }[]
    entries: {
      __typename: 'LabeledPrice'
      label: string
      asString: string
      currency: CurrencyType
      amount: number
    }[]
  }
  updatedAt?: string
  campaign?: Maybe<{
    __typename: 'Campaign'
    id: string
  }>
  contacts?: {
    __typename: 'Contact'
    id: string
  }[]
  groups?: {
    __typename: 'Group'
    id: string
  }[]
  warnings?: {
    __typename: 'Error'
    code: Maybe<number>
    message: Maybe<string>
  }[]
  invalidSendDelayContacts?: {
    __typename: 'Contact'
    id: string
  }[]
  id: string
  createdAt?: string
  bulkUpgradeShippingAddress?: Maybe<{
    lastName: string
    __typename: 'Address'
    city: string
    state: string
    country: string
    postalCode: string
    firstName: string
    company: string
    address1: string
    address2: string
  }>
  isBulk?: boolean
  bulkUpgradeCardQty?: Maybe<number>
  minimalLines?: {
    __typename: 'Line'
    card: Maybe<{
      __typename: 'Card'
      id: string
    }>
    giftVariation: Maybe<{
      __typename: 'GiftVariation'
      id: string
    }>
  }[]
  lines?: {
    __typename: 'Line'
    card: Maybe<{
      __typename: 'Card'
      id: string
    }>
    giftVariation: Maybe<{
      __typename: 'GiftVariation'
      id: string
    }>
    sendDelay: {
      __typename: 'SendDelay'
      type: SendDelayType
      delayNumber: Maybe<number>
      delayType: SendDelayDelayType
      timeType: SendDelayTimeType
      specificDate: string
    }
    cost: {
      total: {
        __typename: 'Price'
        asString: string
        currency: CurrencyType
        amount: number
      }[]
      entries: {
        __typename: 'LabeledPrice'
        label: string
        asString: string
        currency: CurrencyType
        amount: number
      }[]
    }
    baseCost: {
      total: {
        __typename: 'Price'
        asString: string
        currency: CurrencyType
        amount: number
      }[]
      entries: {
        __typename: 'LabeledPrice'
        label: string
        asString: string
        currency: CurrencyType
        amount: number
      }[]
    }
    recipientCount: number
  }[]
}

export type NormalizedFont = {
  __typename: 'Font'
  id: string
  fontName: string
}

export type NormalizedCampaignShare = {
  __typename: 'CampaignShare'
  id: string
  fromName: string
  dateShared: string
  campaign: {
    __typename: 'Campaign'
    id: string
  }
}

export type NormalizedCampaign = {
  __typename: 'Campaign'
  id: string
  name: string
  isShareable?: boolean
  lines?: {
    __typename: 'CampaignLine'
    name: Maybe<string>
    card: Maybe<{
      __typename: 'Card'
      id: string
    }>
    giftVariation: Maybe<{
      __typename: 'GiftVariation'
      id: string
    }>
    sendDelay: {
      __typename: 'SendDelay'
      type: SendDelayType
      delayNumber: Maybe<number>
      delayType: SendDelayDelayType
      timeType: SendDelayTimeType
      specificDate: string
    }
  }[]
  minimalLines?: {
    __typename: 'CampaignLine'
    card: Maybe<{
      __typename: 'Card'
      id: string
    }>
    giftVariation: Maybe<{
      __typename: 'GiftVariation'
      id: string
    }>
  }[]
  createdAt?: string
}

export type NormalizedGift = {
  id: string
  realId: string
  __typename: 'Gift'
  name: string
  defaultImageUrl: string
  defaultVariation: Maybe<{
    __typename: 'GiftVariation'
    id: string
  }>
  variations?: {
    __typename: 'GiftVariation'
    id: string
  }[]
}

export type NormalizedCard = {
  detailedSendableCard?: Maybe<{
    __typename: 'SendableCard'
    id: string
  }>
  insidePreviewUrl?: Maybe<string>
  __typename: 'Card'
  paperType?: CardPaperType
  cost?: {
    total: {
      __typename: 'Price'
      asString: string
      currency: CurrencyType
      amount: number
    }[]
    entries: {
      __typename: 'LabeledPrice'
      label: string
      asString: string
      currency: CurrencyType
      amount: number
    }[]
  }
  backPreviewUrl?: string
  isHorizontal?: boolean
  updatedAt?: string
  panels?: {
    isFullBleed: boolean
    __typename: 'Panel'
    width: number
    previewUrl: string
    height: number
    name: string
    isLocked: boolean
    backgroundColor: Maybe<{
      __typename: 'Color'
      red: number
      green: number
      blue: number
    }>
    location: number
    elements: {
      __typename: 'Element'
      width: number
      height: number
      locked: boolean
      text: Maybe<{
        paragraphs: {
          __typename: 'Paragraph'
          textFragments: {
            __typename: 'TextFragment'
            text: string
            textColor: {
              __typename: 'Color'
              red: number
              green: number
              blue: number
            }
            font: {
              __typename: 'Font'
              id: string
            }
            fontSize: number
            isReplacement: boolean
          }[]
          alignment: TextAlignment
        }[]
      }>
      draggable: Maybe<boolean>
      x: number
      y: number
      z: number
      image: Maybe<{
        __typename: 'ElementPicture'
        image: Maybe<{
          __typename: 'Image'
          id: string
        }>
        position: {
          x: number
          y: number
        }
        scale: number
        filter: Maybe<{
          name: string
        }>
      }>
      rotation: number
    }[]
  }[]
  frontPreviewUrl?: string
  minimalPanels?: {
    __typename: 'Panel'
    name: string
    previewUrl: string
    isFullBleed: boolean
    width: number
    height: number
  }[]
  backPanelLocation?: number
  sendableCard?: Maybe<{
    __typename: 'SendableCard'
    id: string
  }>
  isNewEditorCard?: boolean
  paperUpgradeCosts?: {
    value: string
    upgrade: string
  }[]
  showInPicturePlus?: boolean
  tinyThumb?: string
  type?: CardType
  id: string
  flapPreviewUrl?: Maybe<string>
}

export type NormalizedBankAccount = {
  __typename: 'BankAccount'
  id: string
  bankName: string
  object: string
  last4: string
  accountHolderName: string
}

export type NormalizedItemCategory = {
  children?: {
    __typename: 'ItemCategory'
    id: string
  }[]
  __typename: 'ItemCategory'
  id: string
  name: string
  backgroundImageUrl: Maybe<string>
  items: {
    __typename: 'Item'
    id: string
  }[]
}

export type NormalizedNote = {
  __typename: 'Note'
  id: string
  text: string
  date: Maybe<{
    __typename: 'CalendarDate'
    day: number
    month: number
    year: number
  }>
}

export type NormalizedReminder = {
  contact: {
    id: string
    firstName: string
    lastName: string
    country: Maybe<string>
    birthday: Maybe<{
      day: number
      month: number
    }>
    anniversary: Maybe<{
      day: number
      month: number
    }>
  }
  __typename: 'Reminder'
  message: Maybe<string>
  eventDate: Maybe<string>
  order: Maybe<{
    __typename: 'Order'
    id: string
  }>
  campaign: Maybe<{
    id: string
    name: string
  }>
  title: string
  newType: NewReminderType
  type: ReminderType
  id: string
}

export type NormalizedSendableCard = {
  variations?: {
    __typename: 'SendableCard'
    id: string
  }[]
  __typename: 'SendableCard'
  isFavorite?: boolean
  cardTemplate?: Maybe<{
    __typename: 'Card'
    id: string
  }>
  isPremium?: boolean
  cost?: {
    total: {
      __typename: 'Price'
      asString: string
      currency: CurrencyType
      amount: number
    }[]
    entries: {
      __typename: 'LabeledPrice'
      label: string
      asString: string
      currency: CurrencyType
      amount: number
    }[]
  }
  isHorizontal?: boolean
  variationColor?: string
  frontImage?: {
    __typename: 'Image'
    id: string
  }
  title: string
  id: string
  insideRightImage?: Maybe<{
    __typename: 'Image'
    id: string
  }>
  description?: string
}

export type NormalizedPlansDescription = {
  __typename: 'PlansDescription'
  id: string
  description: string
}

export type NormalizedItem = {
  __typename: 'Item'
  id: string
  description: Maybe<string>
  itemCode: string
  price: number
  name: Maybe<string>
  image: Maybe<string>
  campaign: Maybe<{
    __typename: 'Campaign'
    id: string
  }>
}

export type NormalizedRecipient = {
  contact: Maybe<{
    __typename: 'Contact'
    id: string
  }>
  paymentFailure: Maybe<{
    id: string
  }>
  __typename: 'Recipient'
  recipientLine: Maybe<{
    __typename: 'RecipientLine'
    id: string
  }>
  gift: Maybe<{
    name: string
    defaultImageUrl: string
  }>
  card: Maybe<{
    __typename: 'Card'
    id: string
  }>
  address: Maybe<{
    lastName: string
    __typename: 'Address'
    city: string
    state: string
    userVerified: boolean
    errorMessages: string[]
    country: string
    postalCode: string
    firstName: string
    company: string
    address1: string
    address2: string
  }>
  id: string
  cardQuantity: number
}

export type NormalizedSocProContact = {
  lastName: string
  zip: string
  __typename: 'SocProContact'
  city: string
  metadataBlob: string
  homePhone: string
  state: string
  address: string
  smsPhone: string
  country: string
  socContact: Maybe<{
    __typename: 'Contact'
    id: string
  }>
  fullName: string
  id: string
  contactId: string
  firstName: string
  email: string
  workPhone: string
  address2: string
}

export type NormalizedImage = {
  mediumThumb?: string
  path?: string
  __typename: 'Image'
  width: number
  height: number
  smallThumb: string
  url: string
  stickerUrl?: Maybe<string>
  isSticker?: boolean
  id: string
  createdAt?: string
}

export type NormalizedMarketingMultiParagraph = {
  __typename: 'MarketingMultiParagraph'
  createdAt: string
  updatedAt: string
  id: string
  title: string
  content: string
}

export type NormalizedCollection = {
  backgroundImageUrl: Maybe<string>
  cards: {
    __typename: 'SendableCard'
    id: string
  }[]
  __typename: 'Collection'
  reasonToSend: boolean
  backgroundStartColor: string
  textColor: string
  backgroundEndColor: string
  title: string
  id: string
  description: string
}

export type NormalizedGroup = {
  __typename: 'Group'
  id: string
  name: string
  contacts?: {
    __typename: 'Contact'
    id: string
  }[]
  description?: string
  members?: {
    __typename: 'Contact'
    id: string
  }[]
}

export type NormalizedContactShare = {
  __typename: 'ContactShare'
  id: string
  contact: {
    __typename: 'Contact'
    id: string
  }
  fromUser: {
    __typename: 'User'
    id: string
  }
  createdAt: string
}

export type NormalizedData = {
  Contact: ById<NormalizedContact>
  StripeOrder: ById<NormalizedStripeOrder>
  GiftVariation: ById<NormalizedGiftVariation>
  MarketingParagraph: ById<NormalizedMarketingParagraph>
  CreditCard: ById<NormalizedCreditCard>
  Invite: ById<NormalizedInvite>
  Plan: ById<NormalizedPlan>
  Layout: ById<NormalizedLayout>
  Notification: ById<NormalizedNotification>
  User: ById<NormalizedUser>
  Prospect: ById<NormalizedProspect>
  Flag: ById<NormalizedFlag>
  RecipientLine: ById<NormalizedRecipientLine>
  DetailedReferralCredits: ById<NormalizedDetailedReferralCredits>
  CardCategory: ById<NormalizedCardCategory>
  GiftCategory: ById<NormalizedGiftCategory>
  Order: ById<NormalizedOrder>
  Font: ById<NormalizedFont>
  CampaignShare: ById<NormalizedCampaignShare>
  Campaign: ById<NormalizedCampaign>
  Gift: ById<NormalizedGift>
  Card: ById<NormalizedCard>
  BankAccount: ById<NormalizedBankAccount>
  ItemCategory: ById<NormalizedItemCategory>
  Note: ById<NormalizedNote>
  Reminder: ById<NormalizedReminder>
  SendableCard: ById<NormalizedSendableCard>
  PlansDescription: ById<NormalizedPlansDescription>
  Item: ById<NormalizedItem>
  Recipient: ById<NormalizedRecipient>
  SocProContact: ById<NormalizedSocProContact>
  Image: ById<NormalizedImage>
  MarketingMultiParagraph: ById<NormalizedMarketingMultiParagraph>
  Collection: ById<NormalizedCollection>
  Group: ById<NormalizedGroup>
  ContactShare: ById<NormalizedContactShare>
}

export const NormalizedData = (): NormalizedData => ({
  Contact: {},
  StripeOrder: {},
  GiftVariation: {},
  MarketingParagraph: {},
  CreditCard: {},
  Invite: {},
  Plan: {},
  Layout: {},
  Notification: {},
  User: {},
  Prospect: {},
  Flag: {},
  RecipientLine: {},
  DetailedReferralCredits: {},
  CardCategory: {},
  GiftCategory: {},
  Order: {},
  Font: {},
  CampaignShare: {},
  Campaign: {},
  Gift: {},
  Card: {},
  BankAccount: {},
  ItemCategory: {},
  Note: {},
  Reminder: {},
  SendableCard: {},
  PlansDescription: {},
  Item: {},
  Recipient: {},
  SocProContact: {},
  Image: {},
  MarketingMultiParagraph: {},
  Collection: {},
  Group: {},
  ContactShare: {},
})
