import { Flex, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { Button } from 'src/chrome'

import styles from './uiCard.module.scss'
import { ButtonColorChoices } from 'src/theme/buttonColors'

interface UICardAction {
  id?: string
  title: string
  gradient?: string
  onClick?: () => void
}

interface UICardProps {
  actions?: UICardAction[]
  className?: string
  contentStyle?: React.CSSProperties
  style?: React.CSSProperties
  title?: string
  description?: string
  buttonColor?: ButtonColorChoices
}

const UiCard: React.FC<UICardProps> = props => {
  const {
    actions,
    children,
    className,
    contentStyle,
    style,
    title,
    description,
    buttonColor,
  } = props
  return (
    <div
      className={className ? `${styles.uiCard} ${className}` : styles.uiCard}
      style={style}
    >
      {title && (
        <div className={styles.uiCardHeader}>
          <Flex flexDirection={'column'}>
            <Text
              type="largeBody"
              weight="bold"
              color="primaryHeading"
            >{`${title}`}</Text>
            <Spacer space="x_5" />
            {description && <Text type="caption" content={description} />}
          </Flex>
          {actions &&
            actions.map(action => (
              <Button
                key={action.id}
                id={action.id}
                title={action.title}
                buttonColor={buttonColor}
                onClick={action.onClick}
              />
            ))}
        </div>
      )}
      <div className={styles.uiCardContent} style={contentStyle}>
        {children}
      </div>
    </div>
  )
}

export default UiCard
