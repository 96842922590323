import { Div, Flex, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'

import styles from './confirm.module.scss'

interface Props {
  title: string
  onConfirm: () => void
  onDecline: () => void
  message?: string
  confirmTitle?: string
  declineTitle?: string
  childType?: 'image' | 'form'
  isModalEnabled?: boolean
  wrapperClassName?: string
  containerClassName?: string
  confirmMessageClassName?: string
  actionsClassName?: string
  id?: string
}

const Confirm: React.FC<Props> = props => {
  const {
    title,
    id,
    message,
    confirmTitle,
    declineTitle,
    childType,
    isModalEnabled,
    wrapperClassName,
    containerClassName,
    confirmMessageClassName,
    actionsClassName,
    children,
    onConfirm,
    onDecline,
  } = props
  return (
    <Div className={`${styles.confirmWrapper} ${wrapperClassName}`}>
      <Flex
        className={`${styles.confirmContainer} ${containerClassName}`}
        flexDirection="column"
      >
        <Div>
          <Spacer space="x2" />
          <Text type="body" color="primaryBrand" alignment="center">
            {title}
          </Text>
        </Div>
        <Div className={`${styles.confirmBody} ${confirmMessageClassName}`}>
          {childType === 'image' && children && (
            <Div className={styles.confirmImage}>{children}</Div>
          )}
          {childType === 'form' && children && (
            <Div className={styles.confirmForm}>{children}</Div>
          )}
          <Text type="caption" alignment="center">
            {message}
          </Text>
        </Div>
        <Div className={`${styles.confirmActions} ${actionsClassName}`}>
          <Div className={styles.decline} onClick={() => onDecline()}>
            <Text type="caption" alignment="center" color="primaryHeading">
              {declineTitle || 'Cancel'}
            </Text>
          </Div>
          <Div
            id={id ?? 'confirm_btn'}
            className={styles.accept}
            onClick={() => onConfirm()}
          >
            <Text type="caption" alignment="center" color="primaryHeading">
              {confirmTitle ?? 'Continue'}
            </Text>
          </Div>
        </Div>
      </Flex>
      <Div
        className={styles.backdrop}
        onClick={isModalEnabled ? () => onDecline() : () => {}}
      />
    </Div>
  )
}

export default Confirm
