import React, { Suspense } from 'react'
import {
  Flex,
  LoadingSpinner,
  Sheet,
  Transition,
  VideoPlayer,
} from '@sendoutcards/quantum-design-ui'
import {
  useAccount,
  useActions,
  useCallback,
  usePersistedUserData,
  useQueries,
  useState,
} from 'src/hooks'
import AddSponsor from './components/AddSponsor'
import FindMySponsor from './components/FindMySponsor'
import FreeCardSuccess from './components/FreeCardSuccess'
import SelectMySponsor from './components/SelectMySponsor'
import ThankYou from './components/ThankYou'
import CreateAccount from './components/CreateAccount'
import FeaturedCardsGrid from 'src/catalog/components/FeaturedCardsGrid/FeaturedCardsGrid'
import PhoneNumberVerification from './components/PhoneNumberVerification'
import AddPhoneNumberForFreeCard from './components/AddPhoneNumberForFreeCard'
import { getPossibleSponsors } from 'src/legacy_graphql'
import { Memoized } from 'src/hooks/dependencies'
import { sendoutcardsPromotionsGenealogyId } from 'src/app/constants'
import { useUpdateAccount } from 'src/react_query'

type Step =
  | { type: 'success' }
  | { type: 'create' }
  | { type: 'selectMySponsor' }
  | { type: 'addSponsor' }
  | { type: 'findMySponsor' }
  | { type: 'optionToAddPhoneNumber' }
  | { type: 'addPhoneNumber' }
  | { type: 'thankYou' }

const SendFreeCardSuccess = (props: {
  orderNumber?: string
  onClose: Memoized<() => void>
  onCloseAndPay: () => void
}) => {
  const { orderNumber, onClose, onCloseAndPay } = props
  const [step, setStep] = useState<Step>({ type: 'create' })
  const [isUpdating, setIsUpdating] = useState(false)
  const [chosenSponsor, setChosenSponsor] = useState<string>()
  const actions = useActions()
  const updateAccountMutation = useUpdateAccount()
  const { phoneVerification } = usePersistedUserData()
  const { verifiedPhoneNumber } = useAccount()
  const [sponsors] = useQueries(getPossibleSponsors())
  const hasMultiplePossibleSponsors =
    sponsors.filter(
      sponsor => sponsor.genealogyId !== sendoutcardsPromotionsGenealogyId,
    ).length >= 1

  const goToPricingPage = useCallback(() => {
    actions.clearRouteArgs()
    actions.openPricing()
  }, [actions])

  const handleChooseSponsor = useCallback(
    async (sponsorId?: string) => {
      if (isUpdating) {
        return
      }
      try {
        setIsUpdating(true)
        const {
          updateAccount: { account },
        } = await updateAccountMutation.mutateAsync({
          account: sponsorId
            ? { sponsor: sponsorId }
            : { sponsorGenealogyId: String(sendoutcardsPromotionsGenealogyId) },
        })
        actions.updatedAccount(account)
        const sponsor = account.sponsor
        setChosenSponsor(
          sponsorId ? `${sponsor?.firstName} ${sponsor?.lastName}` : undefined,
        )
        if (orderNumber) {
          goToPricingPage()
        } else {
          onClose()
        }
      } catch (error) {
        console.log(`Failed to update account with sponsor id ${sponsorId}`)
      } finally {
        setIsUpdating(false)
      }
    },
    [
      isUpdating,
      setIsUpdating,
      actions,
      setChosenSponsor,
      updateAccountMutation,
      orderNumber,
      onClose,
      goToPricingPage,
    ],
  )

  const handleKeepCurrentSponsor = useCallback(() => {
    if (orderNumber) {
      goToPricingPage()
    } else {
      onClose()
    }
  }, [orderNumber, onClose, goToPricingPage])

  const handleHasNoPossibleSponsors = useCallback(() => {
    setStep({ type: 'addSponsor' })
  }, [setStep])

  const handleSelectOrAddSponsor = () => {
    if (hasMultiplePossibleSponsors) {
      setStep({ type: 'selectMySponsor' })
    } else {
      setStep({ type: 'findMySponsor' })
    }
  }

  return (
    <Sheet
      isOpen={true}
      setIsOpen={onClose}
      backgroundElement={
        step.type === 'success' || step.type === 'create' ? (
          <VideoPlayer
            height="100%"
            maxHeight="1200px"
            hasShadow={true}
            poster="https://images.unsplash.com/photo-1526614180703-827d23e7c8f2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1575&q=80"
            videoId={'z2hzmqllwf#'}
            width="100%"
          />
        ) : (
          <FeaturedCardsGrid />
        )
      }
      zIndex={10001}
    >
      <Flex
        minHeight="436px"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        {isUpdating ? (
          <Transition isLoading={isUpdating} title="Updating your account" />
        ) : (
          <>
            {step.type === 'success' && (
              <FreeCardSuccess
                onSignUp={() => {
                  setStep({ type: 'create' })
                }}
                onNoThanks={actions.logout}
              />
            )}
            {step.type === 'create' && (
              <CreateAccount
                orderNumber={orderNumber}
                isOrderFree={true}
                onCreatedAccount={() => {
                  if (!verifiedPhoneNumber) {
                    setStep({ type: 'optionToAddPhoneNumber' })
                  } else {
                    setStep({ type: 'selectMySponsor' })
                  }
                }}
              />
            )}
            {step.type === 'selectMySponsor' && (
              <Suspense
                fallback={
                  <Flex
                    width="100%"
                    height="100%"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <LoadingSpinner size="large" />
                  </Flex>
                }
              >
                <SelectMySponsor
                  onSelectedSponsor={handleChooseSponsor}
                  onHasNoPossibleSponsors={handleHasNoPossibleSponsors}
                  onFindMySponsor={() => {
                    setStep({ type: 'findMySponsor' })
                  }}
                />
              </Suspense>
            )}
            {step.type === 'addSponsor' && (
              <AddSponsor
                onFindMySponsor={() => {
                  setStep({ type: 'findMySponsor' })
                }}
                onIDontKnowAnyone={handleChooseSponsor}
              />
            )}
            {step.type === 'findMySponsor' && (
              <FindMySponsor
                onSelectedSponsor={handleChooseSponsor}
                onKeepMyCurrentSponsor={handleKeepCurrentSponsor}
              />
            )}
            {step.type === 'optionToAddPhoneNumber' && (
              <AddPhoneNumberForFreeCard
                onClose={() => onCloseAndPay}
                onAddPhoneNumber={() => {
                  setStep({ type: 'addPhoneNumber' })
                }}
                onDeclinePhoneNumber={handleSelectOrAddSponsor}
              />
            )}
            {step.type === 'addPhoneNumber' && (
              <>
                <PhoneNumberVerification
                  loginPhoneNumber={phoneVerification.loginPhoneNumber}
                  sponsorGenealogyIdOrSlug={undefined}
                  onSuccess={handleSelectOrAddSponsor}
                  onDeclinePhoneNumber={() => {
                    setStep({ type: 'selectMySponsor' })
                  }}
                />
              </>
            )}
            {step.type === 'thankYou' &&
              (orderNumber ? (
                <ThankYou
                  chosenSponsor={chosenSponsor}
                  onClose={() => actions.openCatalog()}
                />
              ) : (
                <ThankYou
                  chosenSponsor={chosenSponsor}
                  onClose={() => onCloseAndPay()}
                  shouldFinishAccountSetup={true}
                />
              ))}
          </>
        )}
      </Flex>
    </Sheet>
  )
}

export default SendFreeCardSuccess
