import React from 'react'

const SenderImage = () => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width="244px"
    height="153px"
    viewBox="0 0 1024 256"
    enable-background="new 0 0 1024 256"
    xmlSpace="preserve"
  >
    <g>
      <g>
        <g>
          <path
            fill="#F6C243"
            d="M704.051,370.223c14.355-6.192,22.352-18.479,29.487-31.358c16.603-29.971,27.065-62.474,38.407-94.6
			   c14.378-40.726,27.292-82.06,47.196-120.57c6.445-12.471,14.546-24.17,22.701-35.646c6.201-8.725,15.493-13.342,26.136-14.969
			   c-0.214,2.131-0.027,4.402-0.718,6.366c-2.127,6.047-4.561,11.988-6.929,17.949c-3.506,8.824-0.806,14.614,9.248,17.687
			   c6.524,1.994,13.639,2.115,20.51,2.892c4.874,0.551,8.606-1.822,10.466-6.055c2.24-5.1,3.956-10.481,5.37-15.879
			   c1.472-5.615,3.62-10.335,9.792-11.938c9.059,13.44,11.595,28.709,12.452,44.446c2.644,48.57-7.917,95.204-21.104,141.383
			   c-11.332,39.684-26.046,78.179-45.081,114.776c-11.396,21.909-25.01,42.664-37.616,63.945c-0.841,1.419-1.581,2.898-2.367,4.349
			   c-100.667,0-201.333,0-302,0c3.423-15.776,6.768-31.569,10.291-47.322c3.449-15.424,7.145-30.794,10.58-46.221
			   c0.756-3.394,0.87-6.932,1.28-10.403c9.063-31.933,18.12-63.867,27.192-95.798c8.028-28.256,15.896-56.559,24.276-84.71
			   c1.505-5.055,0.42-6.34-4.229-7.849c-52.318-16.98-104.56-34.193-156.815-51.363c-33.471-10.998-66.954-21.959-100.367-33.128
			   c-4.464-1.492-6.136-0.594-7.411,3.98c-8.027,28.777-16.378,57.464-24.567,86.196c-9.644,33.835-19.208,67.693-28.852,101.528
			   c-10.876,38.158-21.76,76.314-32.78,114.431c-0.902,3.12-0.461,4.683,2.397,6.419c24.578,14.928,49.028,30.065,73.564,45.063
			   c8.629,5.274,17.389,10.334,26.089,15.491c1.451,1.228,2.902,2.457,4.353,3.685c-103.667,0-207.333,0-311,0
			   c-1.02-1.489-1.933-3.067-3.076-4.455c-19.972-24.256-25.3-51.766-19.167-82.24c4.746-23.586,13.808-45.428,25.339-66.294
			   c23.225-42.029,53.55-78.78,86.411-113.445c15.882-16.755,32.696-32.677,49.737-48.266c16.278-14.89,33.096-29.233,50.218-43.149
			   c30.059-24.429,61.281-47.328,95.288-66.024c13.191-7.252,27.156-13.159,41.001-19.141c23.31-10.073,47.534-15.51,73.134-12.74
			   c-6.579,3.268-8.717,9.057-7.775,15.727c1.289,9.119,2.361,18.474,5.349,27.096c3.952,11.401,10.218,22.178,21.777,27.403
			   c7.383,3.337,15.721,5.352,23.801,6.082c5.656,0.511,12.971-1.179,15.386-7.202c3.206-7.993,5.973-16.753,6.115-25.23
			   c0.168-9.965-2.448-20.052-8.577-28.606c0.999,0.289,1.998,0.577,2.997,0.866c8.909,6.341,18.211,12.204,26.641,19.128
			   c15.572,12.789,26.234,29.331,34.986,47.371c4.476,9.227,10.919,17.484,15.659,26.603c15.48,29.781,31.79,59.214,45.555,89.783
			   c20.729,46.036,39.523,92.942,59.274,139.421c3.638,8.562,7.917,16.851,11.901,25.267c-0.504,1.596-1.89,3.667-1.337,4.691
			   c1.292,2.39,3.343,4.464,5.389,6.334c11.051,10.099,30.304,3.516,39.955-2.215c0.917-0.545,1.387-2.934,1.023-4.156
			   c-0.238-0.796-2.464-1.595-3.618-1.387c-1.896,0.342-3.652,1.484-5.452,2.32C716.262,369.17,710.481,371.267,704.051,370.223z
				M246.5,210.53c-0.881-1.081-1.631-2.6-2.84-3.204c-0.514-0.257-2.164,1.256-2.972,2.233c-0.818,0.989-1.331,2.273-1.81,3.493
			   c-6.892,17.562-13.808,35.115-20.611,52.711c-6.872,17.774-13.618,35.597-20.433,53.393c-3.556,9.286-7.082,18.583-10.753,27.823
			   c-0.895,2.253-1.182,5.418-5.165,4.516c-11.741-2.66-23.405-6.409-35.298-7.443c-12.924-1.124-26.253-0.897-39.07,1.002
			   c-12.137,1.798-23.882,6.392-35.683,10.125c-1.575,0.498-2.465,3.161-3.672,4.822c1.999,0.457,4.329,1.818,5.944,1.226
			   c12.456-4.56,24.986-8.443,38.167-10.683c15.17-2.578,30.152-2.048,44.955,0.897c13.812,2.748,27.264,7.125,39.495,14.573
			   c1.107,0.674,3.161-0.205,4.775-0.362c-0.504-1.87-0.451-4.312-1.644-5.473c-1.708-1.662-4.542-2.113-6.469-3.626
			   c-1.003-0.788-1.884-3.041-1.475-4.124c8.822-23.334,17.793-46.612,26.823-69.867c7.981-20.552,16.085-41.056,24.093-61.598
			   C244.116,217.739,245.149,214.424,246.5,210.53z"
          />
          <path
            fill="#FEFEFE"
            d="M340.647,449.315c-8.7-5.157-17.46-10.217-26.089-15.491c-24.536-14.997-48.987-30.135-73.564-45.063
			   c-2.858-1.736-3.299-3.299-2.397-6.419c11.02-38.117,21.905-76.273,32.78-114.431c9.643-33.835,19.208-67.693,28.852-101.528
			   c8.189-28.732,16.54-57.419,24.567-86.196c1.276-4.574,2.947-5.472,7.411-3.98c33.414,11.169,66.897,22.13,100.367,33.128
			   c52.256,17.17,104.498,34.383,156.815,51.363c4.65,1.509,5.734,2.794,4.229,7.849c-8.38,28.151-16.248,56.454-24.276,84.71
			   c-9.072,31.93-18.128,63.865-27.192,95.798c-0.293,0.592-0.687,1.156-0.865,1.781c-7.432,26.076-14.898,52.143-22.226,78.248
			   c-2.222,7.916-4.051,15.942-6.061,23.917c-51.667,0-103.333,0-155,0c0.4-0.675,0.639-1.578,1.224-1.99
			   c8.823-6.205,17.745-12.27,26.54-18.515c18.941-13.449,37.804-27.008,56.737-40.469c6.168-4.385,7.116-7.354,4.376-14.32
			   c-6.258-15.911-12.545-31.811-18.684-47.769c-7.862-20.436-15.505-40.956-23.453-61.358c-1.503-3.858-1.663-5.903,2.79-7.96
			   c24.891-11.499,49.642-23.304,74.374-35.145c22.333-10.692,44.575-21.576,66.87-32.35c4.791-2.315,9.778-4.267,14.402-6.87
			   c2.852-1.605,4.57-4.333,2.926-7.961c-1.686-3.721-4.684-3.871-7.976-2.828c-2.052,0.65-4.039,1.547-5.987,2.477
			   c-36.261,17.294-72.51,34.614-108.766,51.919c-13.965,6.665-27.961,13.263-41.895,19.992c-2.553,1.233-3.807,0.986-4.705-1.94
			   c-1.314-4.28-3.062-8.43-4.67-12.617c-13.88-36.133-27.76-72.266-41.675-108.386c-1.543-4.004-2.848-8.227-5.174-11.748
			   c-1.119-1.693-4.76-3.168-6.526-2.572c-1.73,0.584-3.224,3.829-3.534,6.083c-0.288,2.092,0.971,4.478,1.812,6.632
			   c5.539,14.182,11.283,28.285,16.707,42.51c9.977,26.168,19.715,52.428,29.675,78.602c7.086,18.62,14.372,37.164,21.571,55.74
			   c9.649,24.895,19.217,49.821,29.045,74.645c1.396,3.526,0.736,5.646-2.087,7.653c-9.46,6.724-18.887,13.495-28.349,20.216
			   C382.602,419.564,361.621,434.435,340.647,449.315z"
          />
          <path
            fill="#2A3050"
            d="M436.939-9.021c-17.828-12.194-25.317-29.734-26.958-50.684c-3.198-40.835,7.514-77.722,31.157-110.86
			   c8.432-11.819,19.608-20.223,34.793-21.484c3.439-0.286,7.007,0.651,10.396,0.175c3.959-0.556,7.787-2.043,11.672-3.126
			   c6.667,0,13.333,0,20,0c3.905,1.559,7.85,3.025,11.708,4.694c11.174,4.834,17.777,13.329,20.536,25.17
			   c1.531,6.572,3.75,12.985,5.703,19.457c1.453,4.817,1.814,9.277,0.163,14.473c-1.426,4.488-0.116,9.856,0.039,14.834
			   c0.223,7.127,0.64,14.251,0.722,21.38c0.308,26.467,6.061,51.855,14.979,76.628c0.726,2.017,1.491,4.093,2.654,5.869
			   c7.659,11.696-0.585,20.009-10.828,21.162c-4.943,0.556-10.129-0.604-15.148-1.361c-2.41-0.363-4.731-1.494-7.018-2.454
			   c-3.511-1.475-7.292-4.781-10.335-4.175c-2.814,0.56-4.672,5.365-7.192,8.076c-4.513,4.853-10.827,4.628-16.657,5.086
			   c-5.078,0.4-10.24-0.265-15.365-0.459c-1-0.288-1.999-0.577-2.997-0.863c-0.547-0.554-1.096-1.11-1.644-1.666
			   c1.041-8.935,2.082-17.87,3.123-26.805c1.15-9.36,2.484-18.703,3.361-28.088c0.354-3.793,1.316-3.814,4.548-2.513
			   c6.578,2.648,13.287,5.193,20.172,6.793c7.46,1.734,13.993-0.696,18.642-7.16c1.119-1.196,2.395-2.28,3.334-3.604
			   c13.159-18.558,13.523-39.055,9.385-60.896c-0.478-2.078-1.792-6.646-1.824-7.032c-0.137-1.687-0.418-3.644-1.41-4.902
			   c-1.064-1.35-3.129-1.862-4.47-3.061c-0.831-0.743-1.129-2.083-1.667-3.155c1.178-0.355,2.338-0.872,3.542-1.01
			   c0.848-0.097,1.754,0.322,3.3,0.654c-0.635-2.95-1.13-5.249-2.016-9.366c-1.151,5.364-3.094,7.846-6.729,4.719
			   c-2.452-2.11-4.252-4.977-6.298-7.451c-2.144,6.061-4.765,7.022-9.581,2.722c-3.553-3.173-6.475-7.051-9.685-10.608
			   c-2.383-2.641-4.906-7.514-7.136-7.386c-3.441,0.198-6.779,3.695-9.972,6.049c-1.569,1.157-2.871,2.778-4.027,4.38
			   c-11.343,15.72-19.029,33.275-22.328,52.179c-1.623,9.3-4.135,17.961-7.717,26.493C453.581-48.462,445.251-28.745,436.939-9.021z
			   "
          />
          <path
            fill="#D6D5D6"
            d="M340.647,449.315c20.975-14.879,41.955-29.751,62.921-44.642c9.463-6.721,18.889-13.492,28.349-20.216
			   c2.822-2.006,3.483-4.126,2.087-7.653c-9.828-24.824-19.397-49.75-29.045-74.645c-7.2-18.577-14.486-37.12-21.571-55.74
			   c-9.961-26.175-19.698-52.434-29.675-78.602c-5.424-14.225-11.168-28.328-16.707-42.51c-0.841-2.154-2.1-4.54-1.812-6.632
			   c0.31-2.254,1.805-5.499,3.534-6.083c1.767-0.596,5.408,0.879,6.526,2.572c2.326,3.52,3.631,7.743,5.174,11.748
			   c13.915,36.119,27.795,72.253,41.675,108.386c1.609,4.188,3.357,8.337,4.67,12.617c0.898,2.926,2.152,3.173,4.705,1.94
			   c13.934-6.728,27.93-13.327,41.895-19.992c36.256-17.304,72.505-34.624,108.766-51.919c1.948-0.929,3.935-1.827,5.987-2.477
			   c3.292-1.043,6.29-0.893,7.976,2.828c1.644,3.628-0.074,6.355-2.926,7.961c-4.624,2.603-9.611,4.555-14.402,6.87
			   c-22.295,10.774-44.537,21.657-66.87,32.35c-24.732,11.841-49.483,23.646-74.374,35.145c-4.453,2.057-4.293,4.102-2.79,7.96
			   c7.948,20.402,15.591,40.922,23.453,61.358c6.139,15.957,12.426,31.858,18.684,47.769c2.74,6.966,1.792,9.935-4.376,14.32
			   c-18.933,13.461-37.796,27.019-56.737,40.469c-8.795,6.245-17.716,12.31-26.54,18.515c-0.585,0.411-0.824,1.315-1.224,1.99
			   c-4.333,0-8.667,0-13,0C343.549,451.772,342.098,450.543,340.647,449.315z"
          />
          <path
            fill="#E2B041"
            d="M513,453c2.009-7.976,3.839-16.002,6.061-23.917c7.327-26.106,14.794-52.172,22.226-78.248
			   c0.178-0.625,0.571-1.189,0.865-1.781c-0.41,3.472-0.524,7.009-1.279,10.403c-3.435,15.427-7.131,30.797-10.58,46.221
			   C526.768,421.431,523.423,437.224,520,453C517.667,453,515.333,453,513,453z"
          />
          <path
            fill="#ECBF8F"
            d="M915.716,84.101c-6.172,1.603-8.32,6.322-9.792,11.938c-1.415,5.398-3.13,10.779-5.37,15.879
			   c-1.86,4.234-5.592,6.606-10.466,6.055c-6.871-0.777-13.986-0.898-20.51-2.892c-10.054-3.072-12.753-8.862-9.248-17.687
			   c2.368-5.961,4.802-11.901,6.929-17.949c0.691-1.963,0.504-4.235,0.718-6.366c1.211-6.799,1.891-13.747,3.756-20.361
			   c2.968-10.526,7.235-20.698,9.965-31.275c1.718-6.656,1.572-13.786,2.412-20.684c0.18-1.48,1.007-2.881,1.536-4.318
			   c1.395,0.753,3.169,1.185,4.122,2.312c6.372,7.536,9.139,16.45,9.254,26.205c0.158,13.469,0.654,13.728,12.414,7.155
			   c14.776-8.259,29.632-16.376,44.492-24.484c2.325-1.269,4.794-2.35,7.312-3.157c1.289-0.413,2.833-0.025,4.263,0.001
			   c-0.527,1.622-0.573,3.767-1.667,4.773c-4.146,3.812-8.677,7.2-12.966,10.861c-1.742,1.487-3.233,3.268-4.839,4.914
			   c-0.07,0.127-0.141,0.253-0.211,0.38c0.049-0.146,0.099-0.293,0.148-0.439c4.706-1.971,9.452-3.853,14.107-5.937
			   c5.307-2.375,10.458-5.108,15.816-7.353c4.957-2.077,6.466-0.779,6.294,4.527c-0.037,1.143,0.429,2.815,1.256,3.387
			   c4.523,3.128,2.699,6.116,0.076,9.152c-0.977,1.131-2.082,2.153-3.161,3.191c-1.588,1.528-1.823,2.585,0.105,4.411
			   c3.771,3.573,3.513,5.85-0.977,8.563c-13.635,8.238-27.41,16.243-41.105,24.38C932.135,74.183,923.936,79.16,915.716,84.101z"
          />
          <path
            fill="#E2AD3C"
            d="M704.051,370.223c6.43,1.044,12.211-1.053,17.885-3.685c1.801-0.836,3.557-1.977,5.452-2.32
			   c1.154-0.208,3.381,0.59,3.618,1.387c0.365,1.222-0.106,3.611-1.023,4.156c-9.651,5.732-28.904,12.314-39.955,2.215
			   c-2.047-1.87-4.098-3.945-5.389-6.334c-0.553-1.023,0.832-3.095,1.337-4.691c0.369,0.326,0.913,0.58,1.083,0.989
			   C690.298,369.705,696.005,372.348,704.051,370.223z"
          />
          <path
            fill="#E2AD3B"
            d="M246.5,210.53c-1.352,3.893-2.384,7.209-3.642,10.436c-8.007,20.542-16.112,41.046-24.093,61.598
			   c-9.03,23.255-18.001,46.533-26.823,69.867c-0.409,1.083,0.472,3.337,1.475,4.124c1.926,1.512,4.76,1.964,6.469,3.626
			   c1.193,1.161,1.141,3.603,1.644,5.473c-1.614,0.158-3.668,1.037-4.775,0.362c-12.23-7.448-25.682-11.826-39.495-14.573
			   c-14.804-2.945-29.786-3.476-44.955-0.897c-13.181,2.24-25.711,6.124-38.167,10.683c-1.615,0.591-3.945-0.77-5.944-1.226
			   c1.207-1.661,2.097-4.324,3.672-4.822c11.801-3.733,23.546-8.327,35.683-10.125c12.817-1.899,26.146-2.126,39.07-1.002
			   c11.893,1.034,23.557,4.783,35.298,7.443c3.983,0.902,4.27-2.263,5.165-4.516c3.671-9.24,7.197-18.538,10.753-27.823
			   c6.815-17.796,13.561-35.619,20.433-53.393c6.803-17.596,13.719-35.149,20.611-52.711c0.479-1.22,0.993-2.504,1.81-3.493
			   c0.808-0.978,2.458-2.49,2.972-2.233C244.869,207.93,245.62,209.449,246.5,210.53z"
          />
          <path
            fill="#B49647"
            d="M487.319,10.85c0.548,0.556,1.097,1.112,1.644,1.666c6.128,8.551,8.743,18.639,8.576,28.603
			   c-0.143,8.478-2.91,17.237-6.115,25.23c-2.415,6.023-9.73,7.713-15.386,7.202c-8.08-0.73-16.418-2.745-23.801-6.082
			   c-11.56-5.225-17.825-16.002-21.777-27.403c-2.989-8.622-4.06-17.977-5.349-27.096c-0.943-6.669,1.196-12.459,7.775-15.727
			   c0.363-0.051,0.727-0.102,1.09-0.154c-0.455,9.925,1.165,19.518,5.105,28.62c5.052,11.67,10.734,22.971,22.744,29.228
			   c13.431,6.997,23.7,1.482,25.087-13.415c0.2-2.147,0.133-4.32,0.158-6.482C487.16,26.979,487.237,18.914,487.319,10.85z"
          />
          <path
            fill="#EBBD8E"
            d="M487.319,10.85c-0.082,8.064-0.16,16.129-0.251,24.193c-0.024,2.161,0.042,4.335-0.158,6.482
			   c-1.387,14.897-11.656,20.412-25.087,13.415c-12.01-6.257-17.692-17.558-22.744-29.228c-3.941-9.102-5.561-18.695-5.105-28.62
			   c0.989-2.037,1.977-4.074,2.965-6.111c8.311-19.724,16.641-39.441,24.927-59.177c3.582-8.533,6.094-17.193,7.717-26.493
			   c3.299-18.904,10.985-36.459,22.328-52.179c1.157-1.603,2.458-3.223,4.027-4.38c3.193-2.354,6.531-5.852,9.972-6.049
			   c2.23-0.128,4.753,4.745,7.136,7.386c3.21,3.557,6.132,7.436,9.685,10.608c4.816,4.301,7.437,3.34,9.581-2.722
			   c2.047,2.474,3.846,5.342,6.298,7.451c3.635,3.128,5.577,0.645,6.729-4.719c0.886,4.117,1.381,6.416,2.016,9.366
			   c-1.546-0.332-2.453-0.751-3.3-0.654c-1.203,0.138-2.363,0.655-3.542,1.01c0.537,1.072,0.835,2.412,1.667,3.155
			   c1.341,1.199,3.407,1.711,4.47,3.061c0.991,1.258,1.273,3.216,1.41,4.902c0.031,0.386,1.445,5.508,1.824,7.032
			   c4.137,21.841,3.773,42.338-9.385,60.896c-0.939,1.324-2.215,2.408-3.334,3.604c-0.831-0.024-1.675-0.158-2.489-0.056
			   c-12.67,1.576-24.223-1.229-34.457-8.927c-5.668-4.264-11.114-8.825-16.814-13.046c-1.764-1.307-4.032-1.933-6.069-2.871
			   c-0.777,2.227-1.62,4.434-2.296,6.691c-0.228,0.763-0.159,1.673-0.017,2.479c1.801,10.193,2.867,20.607,5.764,30.482
			   c1.715,5.846,6.339,10.839,9.656,16.216C489.401-7.02,488.36,1.915,487.319,10.85z M524.811-59.988
			   c5.478-0.323,9.19-2.537,11.64-6.752c1.826-3.142,3.329-6.486,4.794-9.82c1.725-3.926,0.163-5.615-3.972-5.498
			   c-9.798,0.278-19.081-2.008-28.067-5.753c-4.154-1.731-5.654-0.808-5.055,3.781c1.081,8.28,5.25,15.033,11.946,19.947
			   C518.637-62.221,521.886-61.325,524.811-59.988z M514.978-132.404c0.018,0.142,0.036,0.283,0.055,0.425
			   c1.325,0,2.682,0.178,3.968-0.045c1.595-0.277,3.131-0.887,4.693-1.353c-1.195-1.362-2.127-3.283-3.63-3.99
			   c-6.4-3.01-12.202-0.078-17.917,2.503c-0.61,0.276-1.257,1.928-0.978,2.388c0.462,0.759,1.706,1.551,2.552,1.482
			   C507.487-131.3,511.228-131.906,514.978-132.404z M518.084-118.686c-2.619-2.455-4.023-3.771-5.428-5.088
			   c-1.526,2.035-3.621,3.894-4.326,6.182c-0.327,1.061,2.052,2.956,3.204,4.473C513.339-114.654,515.146-116.189,518.084-118.686z
				M534.573-98.349c-2.118,1.679-3.49,3.167-5.184,4c-1.869,0.919-4.058,1.164-6.055,1.855c-0.701,0.243-1.597,0.849-1.747,1.452
			   c-0.114,0.456,0.691,1.437,1.304,1.73C528.328-86.711,534.762-91.181,534.573-98.349z"
          />
          <path
            fill="#E4AA77"
            d="M490.442-15.955c-3.316-5.377-7.94-10.37-9.656-16.216c-2.897-9.875-3.963-20.289-5.764-30.482
			   c-0.142-0.806-0.212-1.716,0.017-2.479c0.676-2.257,1.518-4.464,2.296-6.691c2.037,0.938,4.305,1.565,6.069,2.871
			   c5.699,4.221,11.145,8.782,16.814,13.046c10.234,7.698,21.787,10.503,34.457,8.927c0.815-0.101,1.659,0.032,2.489,0.056
			   c-4.65,6.464-11.182,8.893-18.642,7.16c-6.885-1.6-13.594-4.145-20.172-6.793c-3.231-1.301-4.193-1.28-4.548,2.513
			   C492.926-34.657,491.592-25.315,490.442-15.955z"
          />
          <path
            fill="#FEFEFE"
            d="M947.97,24.962c-0.049,0.146-0.099,0.293-0.148,0.439c0.07-0.127,0.14-0.253,0.211-0.38
			   C948.032,25.022,947.97,24.962,947.97,24.962z"
          />
          <path
            fill="#FEFDFC"
            d="M524.811-59.988c-2.925-1.337-6.174-2.233-8.713-4.096c-6.696-4.914-10.865-11.666-11.946-19.947
			   c-0.599-4.589,0.901-5.512,5.055-3.781c8.987,3.745,18.269,6.031,28.067,5.753c4.134-0.117,5.697,1.572,3.972,5.498
			   c-1.465,3.334-2.968,6.678-4.794,9.82C534.002-62.526,530.289-60.311,524.811-59.988z"
          />
          <path
            fill="#353953"
            d="M514.978-132.404c-3.75,0.498-7.491,1.104-11.257,1.409c-0.846,0.069-2.089-0.723-2.552-1.482
			   c-0.28-0.46,0.367-2.112,0.978-2.388c5.715-2.581,11.517-5.513,17.917-2.503c1.503,0.707,2.435,2.628,3.63,3.99
			   c-1.562,0.466-3.099,1.076-4.693,1.353c-1.286,0.224-2.643,0.045-3.968,0.045C515.014-132.121,514.996-132.262,514.978-132.404z"
          />
          <path
            fill="#EBBD8E"
            d="M518.084-118.686c-2.938,2.497-4.744,4.032-6.55,5.567c-1.152-1.517-3.531-3.412-3.204-4.473
			   c0.705-2.288,2.8-4.147,4.326-6.182C514.06-122.457,515.465-121.14,518.084-118.686z"
          />
          <path
            fill="#CF727B"
            d="M534.573-98.349c0.188,7.168-6.245,11.638-11.683,9.038c-0.613-0.293-1.417-1.275-1.304-1.73
			   c0.151-0.604,1.046-1.21,1.747-1.452c1.997-0.691,4.187-0.936,6.055-1.855C531.084-95.182,532.455-96.671,534.573-98.349z"
          />
        </g>
      </g>
      <ellipse
        fill="#282F4F"
        cx="511.563"
        cy="-119.563"
        rx="3.813"
        ry="5.188"
      />
      <ellipse
        fill="#282F4F"
        cx="544.063"
        cy="-113.313"
        rx="3.313"
        ry="5.438"
      />
    </g>
  </svg>
)

export default SenderImage
