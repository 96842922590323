import React from 'react'

import PlanContentsItem from '../PlanContentsItem/PlanContentsItem'

import styles from '../PlanContentsGrid/planContentsGrid.module.scss'
import { Div, Spacer, Text } from '@sendoutcards/quantum-design-ui'

const AutomatedGSendsAndCampaignGrid: React.FC = props => {
  return (
    <Div className={styles.featureGridContainer}>
      <Div className={styles.featureGrid}>
        <Text type="subtitle" weight="bold">
          System Cards:
        </Text>
        <Spacer space="x1" />
        <Div className={styles.featureRow}>
          <PlanContentsItem
            title={'Campaign'}
            text={
              'A campaign is a card template you create and save to send to multiple people at once or sent one at a time. Example: A new customer welcome or thank you for your business card.'
            }
            icon={'CATALOG'}
            iconSize={33}
          />
          <PlanContentsItem
            title={'Group Sends'}
            text={
              'A group sends is when you create a card and then send to two or more people. Example: sending out invitations or holiday cards.'
            }
            icon={'GROUP'}
            iconSize={34}
          />
        </Div>
        <Div className={styles.featureRow}>
          <PlanContentsItem
            title={'Scheduled Campaign'}
            text={
              'Any card with a send date other than immediate. Example: scheduling your holiday or birthday cards.'
            }
            icon={'CALENDAR2'}
            iconSize={32}
            iconViewBox={'0 0 1050 1050'}
          />
          <PlanContentsItem
            title={'Multiple Touch Campaign'}
            text={
              'A multiple touch campaign is a campaign that is sent to one or more persons over a series of dates, immediate and/or schedule. Example: sending or scheduling your thank you, birthday, anniversary, or holiday card sends.'
            }
            icon={'LIST'}
            iconSize={34}
          />
        </Div>
      </Div>
    </Div>
  )
}

export default AutomatedGSendsAndCampaignGrid
