import { Maybe } from '../../../__generated__/globalTypes'

import { CardFragment, isCardFragment } from './CardFragment'

import {
  GiftVariationFragment,
  isGiftVariationFragment,
} from './GiftVariationFragment'

import { SendDelayFragment, isSendDelayFragment } from './SendDelayFragment'

import {
  ItemizedCostFragment,
  isItemizedCostFragment,
} from './ItemizedCostFragment'

import lineFragmentRawString from '../LineFragment.graphql'

export const lineFragmentString = lineFragmentRawString

export type LineFragment = {
  __typename: 'Line'
  card: Maybe<CardFragment>
  giftVariation: Maybe<GiftVariationFragment>
  sendDelay: SendDelayFragment
  cost: ItemizedCostFragment
  baseCost: ItemizedCostFragment
  recipientCount: number
}

export const isLineFragment = (fragment: any): fragment is LineFragment =>
  fragment &&
  fragment.__typename === 'Line' &&
  (fragment.card === null || (isCardFragment(fragment.card) as boolean)) &&
  (fragment.giftVariation === null ||
    (isGiftVariationFragment(fragment.giftVariation) as boolean)) &&
  (isSendDelayFragment(fragment.sendDelay) as boolean) &&
  (isItemizedCostFragment(fragment.cost) as boolean) &&
  (isItemizedCostFragment(fragment.baseCost) as boolean) &&
  typeof fragment.recipientCount === 'number'
