import {
  ConfirmDialog,
  Div,
  Flex,
  LoadingSpinner,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import React, { FC } from 'react'
import { useActions, useMutations, useSelector, useState } from 'src/hooks'
import { AddOrderCardRoute } from 'src/orders/routes/AddOrderCardRoute'
import { Welcome } from 'src/dashboard/components/Widgets/Reminders/components/Welcome'
import { WeekDayView } from 'src/dashboard/components/Widgets/Reminders/components/WeekDayView'
import { Widget } from 'src/dashboard/components/Widgets/Widget'
import { ReminderListCard } from 'src/dashboard/components/Widgets/Reminders/components/ReminderListCard'
import { Button } from 'src/design_system/components/Button/Button'
import { useCreateOrder, useReminders } from 'src/react_query'
import {
  DayInfoType,
  useGetCurrentWeekDays,
} from 'src/dashboard/hooks/useCurrentWeekDays'
import { MinimalReminderFragment } from 'src/graphql/generated/graphql'

type RemindersListWidgetProps = {
  firstName?: string
}

export const RemindersListWidget: FC<RemindersListWidgetProps> = ({
  firstName,
}) => {
  const [selectedReminder, setSelectedReminder] = useState<
    MinimalReminderFragment | undefined
  >()
  const actions = useActions()
  const mutations = useMutations()
  const createOrderMutation = useCreateOrder()

  const isMobile = useSelector(state => state.window.width <= 574)

  const currentWeekDays: DayInfoType[] = useGetCurrentWeekDays()
  const currentDay = currentWeekDays.find(day => day.isToday)

  const { data, isLoading } = useReminders({
    offset: 0,
    limit: 5,
  })

  const reminders = data?.reminders.filter(
    reminder => !(reminder.type === 'PROJECT' && !reminder.campaign),
  )

  const handleCreateOrder = async (
    contact: { id: string },
    reminderId: string,
  ) => {
    const {
      createOrder: { order },
    } = await createOrderMutation.mutateAsync({
      order: { contacts: [contact.id] },
    })

    await mutations.updateReminder({
      reminder: { id: reminderId, orderId: order.id },
    })
    actions.openOrder(order.id, AddOrderCardRoute())
  }
  const weekDayEvents = reminders?.map(reminder => reminder.eventDate ?? '')

  const getGreeting = (firstName: string | null | undefined): string => {
    const currentHour = new Date().getHours()
    let mutableGreeting

    if (currentHour < 12) {
      mutableGreeting = 'Good Morning'
    } else if (currentHour < 18) {
      mutableGreeting = 'Good Afternoon'
    } else {
      mutableGreeting = 'Good Evening'
    }

    return firstName ? `${mutableGreeting}, ${firstName}` : mutableGreeting
  }

  return (
    <Flex
      width="100%"
      flexDirection="column"
      inset="x2"
      backgroundColor="foreground"
      style={{ borderRadius: 32 }}
    >
      <Welcome
        isMobile={isMobile}
        backgroundColor="#F7F1E0"
        graphicUrl="https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/images/Card.png"
        title={getGreeting(firstName)}
        description={'Share some gratitude today!'}
        action={{
          title: 'Send a Card',
          onClick: () => actions.openCatalog(),
        }}
      />
      <Flex flexDirection="column" inset={{ horizontal: 'x_75' }}>
        <WeekDayView weekDayEventList={weekDayEvents} isMobile={isMobile} />
        <Text
          type="caption"
          weight="bold"
          color="primaryHeading"
          content="Upcoming Reminders"
          style={{ marginLeft: 4 }}
        />
        <Spacer space="x2_5" />
        {isLoading ? (
          <Div style={{ marginTop: 16, marginBottom: 16 }}>
            <LoadingSpinner size="large" />
          </Div>
        ) : !reminders?.length ? (
          <Widget
            shouldHideShadow={true}
            backgroundColor="#F8F9FA"
            isEmpty={true}
            emptyState={{
              title: `No ${currentDay?.month} Reminders`,
              description:
                'Birthdays and Anniversaries can be added to each of your contacts so you never forget a special occasion again!',
              icon: 'exp_calendar',
              action: {
                title: 'Add a reminder',
                onClick: () => alert('Fired from RemindersListWidget.tsx'),
              },
            }}
          />
        ) : (
          reminders.slice(0, 5).map(reminder => {
            const name = `${reminder.contact.firstName} ${reminder.contact.lastName}`
            const type = reminder.type.toLowerCase()
            return (
              <ReminderListCard
                key={reminder.id}
                type={type}
                name={name}
                date={reminder.eventDate ?? ''}
                onClick={() => setSelectedReminder(reminder)}
              />
            )
          })
        )}
        {/* TODO: Refactor this to have the dateBadge in the top center */}
        {selectedReminder && (
          <ConfirmDialog
            isOpen={!!selectedReminder}
            onClose={() => setSelectedReminder(undefined)}
            title={`It's Time to Celebrate ${
              selectedReminder.contact.firstName
            }'s ${
              selectedReminder.type.charAt(0).toUpperCase() +
              selectedReminder.type.slice(1).toLowerCase()
            }!`}
            description="Get creative and fun sending a card and gift that will surely make them feel special."
            primaryAction="accept"
            accept={{
              title: 'Create Card',
              onClick: () =>
                handleCreateOrder(
                  selectedReminder.contact,
                  selectedReminder.id,
                ),
            }}
            decline={{
              title: 'Send Later',
              onClick: () => setSelectedReminder(undefined),
            }}
          >
            {/* <DateBadge
            type={selectedReminder.type.toLowerCase()}
            date={selectedReminder.eventDate ?? ''}
          /> */}
          </ConfirmDialog>
        )}
        <Flex width="100%" justifyContent="center">
          <Button
            borderRadius="12px"
            title="View All Reminders"
            textSize="12px"
            background="#be9cdd"
            onClick={() => actions.openNotifications()}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}
