import { Maybe, Operation } from '../../../__generated__/globalTypes'

import {
  MinimalContactFragment,
  minimalContactFragmentString,
} from '../../fragments/__generated__/MinimalContactFragment'

import getAllContactsRawString from '../GetAllContacts.graphql'

const getAllContactsString = [
  getAllContactsRawString,
  minimalContactFragmentString,
].join('\n\n')

export type GetAllContactsData = {
  paginatedContacts: {
    allContacts: MinimalContactFragment[]
  }
}

export type GetAllContactsVariables = {
  showSecondaryContacts?: Maybe<boolean>
}

export type GetAllContacts = Operation<
  GetAllContactsData,
  GetAllContactsVariables
>

export const GetAllContacts = ({
  showSecondaryContacts,
}: GetAllContactsVariables = {}): GetAllContacts => ({
  query: getAllContactsString,
  variables: {
    showSecondaryContacts,
  },
})
