import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
// @src imports
import { CampaignGrid } from 'src/campaigns/components'
import { DefaultError, Pagination } from 'src/chrome'
import {
  getPaginatedCampaigns,
  MinimalCampaignFragment,
} from 'src/legacy_graphql'
import { useEffect, useQueries, useState } from 'src/hooks'
import suspenseBoundary from 'src/chrome/SuspenseBoundary/suspenseBoundaryHOC'

import styles from './campaignsTab.module.scss'
import { Spacer, Text } from '@sendoutcards/quantum-design-ui'

type Props = {
  page: number
  pageSize: number
  emptyTitle: string
  emptySubtitle: string
  setPage: (page: number) => void
  onPreview: (campaign: MinimalCampaignFragment) => void
  openCampaign: (id: string) => void
  children?: JSX.Element | JSX.Element[]
  searchTerm: string
  isShareable: boolean
}

const campaignCountDescription = (
  page: number,
  pageSize: number,
  elementCount: number,
): string => {
  return `Showing ${page > 1 ? (page - 1) * pageSize : 1}-
  ${Math.min(
    (page > 1 ? page : 1) * pageSize,
    elementCount,
  )} of ${elementCount}`
}

const CampaignsTabContent: React.FC<Props> = props => {
  const {
    page,
    pageSize,
    emptyTitle,
    emptySubtitle,
    setPage,
    onPreview,
    openCampaign,
    children,
    searchTerm,
    isShareable,
  } = props

  const queryArgs = {
    search: searchTerm,
    page: page,
    shareable: isShareable,
  }

  const [messageTitle, setMessageTitle] = useState(emptyTitle)
  const [messageSubtitle, setMessageSubtitle] = useState(emptySubtitle)

  const [campaigns] = useQueries(
    !!queryArgs ? getPaginatedCampaigns(queryArgs) : undefined,
  )

  useEffect(() => {
    if (!!campaigns && campaigns.count === 0 && searchTerm !== '') {
      setMessageTitle('No campaigns found.')
      setMessageSubtitle('')
    } else {
      setMessageTitle(emptyTitle)
      setMessageSubtitle(emptySubtitle)
    }
  }, [campaigns, emptyTitle, emptySubtitle, searchTerm])

  return (
    <div className={styles.tabBody}>
      {!!campaigns && campaigns.count > 0 ? (
        <>
          <Text type="caption">
            {campaignCountDescription(page, pageSize, campaigns.count)}
          </Text>
          {
            <CampaignGrid
              campaigns={campaigns?.results}
              onPreview={onPreview}
              openCampaign={openCampaign}
            />
          }
          <Pagination
            onPageChange={setPage}
            currentPage={page}
            count={campaigns.count}
            pagesToShow={5}
            pageSize={pageSize}
          />
        </>
      ) : (
        <div className={styles.emptyMessage}>
          <Text
            type="subtitle"
            color="primaryHeading"
            inset={{ horizontal: 'x4' }}
            alignment="center"
          >
            {messageTitle}
          </Text>
          <Text type="body" color="primaryHeading" alignment="center">
            {messageSubtitle}
          </Text>
          <Spacer space="x1" />
          {children}
        </div>
      )}
    </div>
  )
}

export default suspenseBoundary({
  component: CampaignsTabContent,
  unresolved: (
    <CircularProgress
      style={{
        marginLeft: 'calc(50% - 20px)',
        marginTop: '100px',
      }}
    />
  ),
  failure: DefaultError,
})
