import React from 'react'
import { Icon } from '../../atoms/icons/components/Icon'
import { IconProps } from '../../atoms/icons/types'

type buttonProps = {
  title: string
  id?: string
  outlineColor?: string
  outlineWidth?: string
  background?: string
  backgroundColor?: string
  textColor?: string
  textWeight?: number
  textSize?: string
  padding?: string
  border?: string
  borderRadius?: string
  shouldDisplayBoxShadow?: boolean
  boxShadow?: string
  width?: string
  height?: string
  whiteSpace?: 'nowrap' | 'normal' | 'pre-wrap'
  icon?: IconProps
  onClick?: () => void
  isDisabled?: boolean
  isSubmit?: boolean
}

/**
 * We may want to use rem units when customizing the button.
 *
 * Standard rem to px is 0.25rem = 4px.
 */
export const Button: React.FC<buttonProps> = ({
  title,
  id,
  outlineColor,
  outlineWidth,
  background,
  backgroundColor = 'hsl(323,100%,51%)',
  textColor = '#FFF',
  textWeight = 600,
  textSize = '1rem',
  padding = '0.75rem 1rem',
  border = 'none',
  borderRadius = '0.5rem',
  shouldDisplayBoxShadow = false,
  boxShadow = '0px 0.25rem 1.5rem rgba(0, 0, 0, 0.25)',
  width = 'max-content',
  height = 'min-content',
  whiteSpace = 'normal',
  icon,
  onClick,
  isDisabled = false,
  isSubmit,
}) => {
  const style = {
    cursor: isDisabled ? 'initial' : 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: background ?? undefined,
    backgroundColor: isDisabled
      ? '#CACACA'
      : background
      ? undefined
      : backgroundColor,
    padding,
    border,
    borderRadius,
    width,
    height,
    outlineColor,
    outlineWidth,
    outlineStyle: outlineColor ? 'solid' : undefined,
    outlineOffset: `-${outlineWidth}`,
    boxShadow: shouldDisplayBoxShadow ? boxShadow : undefined,
    color: textColor,
    fontSize: textSize,
    fontWeight: textWeight,
    whiteSpace: whiteSpace,
  }
  return (
    <button
      css={{ ...style }}
      onClick={onClick}
      disabled={isDisabled}
      type={isSubmit ? 'submit' : 'button'}
      {...(id ? { id: id } : {})}
    >
      {icon && (
        <>
          <Icon {...icon} />
          <span css={{ marginLeft: '0.5rem' }} />
        </>
      )}

      {title}
    </button>
  )
}
