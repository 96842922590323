import React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import Paper from '@material-ui/core/Paper'

import { Icon, SimpleLoadingSpinner } from 'src/chrome'

import styles from './modal.module.scss'
import { useEffect, useSelector, useState } from 'src/hooks'
import { Text } from '@sendoutcards/quantum-design-ui'

interface ModalProps {
  animationStyle?: 'slide' | 'fade' // fade or slide, slide is default
  bodyChildren?: React.ReactNode
  footerChildren?: React.ReactNode
  imageUrl?: string
  onClose: (object: object) => void
  title: string
  bodyStyles?: React.CSSProperties
  mobileBodyStyles?: React.CSSProperties
  containerStyles?: React.CSSProperties
  mobileContainerStyles?: React.CSSProperties
}

const Modal: React.FC<ModalProps> = props => {
  const [isTransitioning, setIsTransitioning] = useState(false)
  const [isLoadingImage, setIsLoadingImage] = useState(false)

  const {
    bodyChildren,
    footerChildren,
    imageUrl,
    onClose,
    title,
    bodyStyles,
    mobileBodyStyles,
    containerStyles,
    mobileContainerStyles,
  } = props

  const isMobile = useSelector(state => state.window.isMobile)

  useEffect(() => {
    transitionNow(changeTransitionTrue)
  }, [])

  const transitionNow = (cb: () => void) => {
    setTimeout(cb, 100)
  }
  const changeTransitionTrue = () => {
    setIsTransitioning(true)
    return true
  }

  const handleClose = () => {
    setIsTransitioning(false)
    setTimeout(() => onClose({}), 200)
  }

  const style = {
    height: '100%',
    width: '100%',
  }

  const animationStyle =
    (props.animationStyle && styles[props.animationStyle]) || styles.slide
  return (
    <div key={'modal'}>
      <div
        className={`${animationStyle} ${styles.modal}`}
        style={
          mobileContainerStyles && isMobile
            ? mobileContainerStyles
            : containerStyles
        }
      >
        <TransitionGroup>
          <CSSTransition classNames={styles} timeout={200}>
            <>
              {isTransitioning && (
                <Paper style={style}>
                  <span
                    id={'contact-info-tabs-close-btn'}
                    className={styles.closeButton}
                    onClick={handleClose}
                  >
                    <Icon icon={'CLOSE'} size={16} />
                  </span>
                  <div className={styles.modalContent}>
                    {imageUrl && (
                      <div className={styles.imageContainer}>
                        {isLoadingImage && <SimpleLoadingSpinner />}
                        <img
                          src={imageUrl}
                          onLoad={() => {
                            setIsLoadingImage(false)
                          }}
                          onError={() => {
                            setIsLoadingImage(false)
                          }}
                          alt={title}
                          style={isLoadingImage ? { visibility: 'hidden' } : {}}
                        />
                      </div>
                    )}
                    <div className={styles.rightContainer}>
                      <div className={styles.content}>
                        <Text type="subtitle" lineHeight={2}>
                          {title}
                        </Text>
                        <div
                          className={styles.body}
                          style={
                            mobileBodyStyles && isMobile
                              ? mobileBodyStyles
                              : bodyStyles
                          }
                        >
                          {bodyChildren}
                        </div>
                        <div className={styles.footer}>{footerChildren}</div>
                      </div>
                    </div>
                  </div>
                </Paper>
              )}
            </>
          </CSSTransition>
        </TransitionGroup>
      </div>
      <div className={styles.overlay} />
    </div>
  )
}

export default Modal
