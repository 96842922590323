import { Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'

type CenteredTitleHeaderProps = {
  headers: React.ReactNode[]
  width: number | string
  readonly subtitle?: string | null
}

const CenteredTitleHeader: React.FC<CenteredTitleHeaderProps> = props => {
  const { headers, subtitle = '' } = props
  return (
    <div
      style={
        {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          borderBottom: '1px solid #e7e7e7',
          height: '100px',
          width: props.width,
        } as React.CSSProperties
      }
    >
      <div>{headers}</div>
      <div
        style={{
          cursor: 'default',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Text type="largeBody" weight="bold" color="primaryHeading">
          {subtitle}
        </Text>
      </div>
    </div>
  )
}

export default CenteredTitleHeader
