import { Button, Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { AccountSubscriptionChanger } from 'src/SubscriptionChanger/AccountSubscriptionChanger'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { useState } from 'src/hooks'
import { useFeatureAccess } from 'src/hooks/useFeatureAccess'
import { FullAccessFeatureType } from 'src/graphql/generated/graphql'
import {
  defaultSubscriptionIds,
  premiereSubscriptionIds,
} from 'src/helpers/supportedUpsaleProducts'

type Action = {
  execute: () => void
  title: string
}

type TextContent = {
  title: string
  subTitle?: string
  description: string
}

type ImageSettings = {
  url: string
  height: string
  width: string
  top?: string
  bottom?: string
  right?: string
  left?: string
}

type LockedFeatureModalProps = {
  onClose?: () => void
  image: ImageSettings
  textContent: TextContent
  featureAccessToGrant: FullAccessFeatureType[]
  actions?: {
    mainAction: Action
    secondaryAction?: Action
  }
}

export const LockedFeatureModal: React.FC<LockedFeatureModalProps> = ({
  onClose,
  image,
  actions,
  textContent,
  featureAccessToGrant,
}) => {
  const [isCloseHovering, setIsCloseHovering] = useState(false)
  const [isProceedHovering, setIsProceedHovering] = useState(false)
  const [isSelectingSubscription, setIsSelectingSubscription] = useState(false)

  const { hasFeatureAccess } = useFeatureAccess([
    FullAccessFeatureType.PremiumSubscriptionOptions,
  ])

  const subscriptionIds = hasFeatureAccess
    ? [...defaultSubscriptionIds, ...premiereSubscriptionIds]
    : defaultSubscriptionIds

  return isSelectingSubscription ? (
    <AccountSubscriptionChanger
      subscriptionPlanIds={subscriptionIds}
      isOpen={isSelectingSubscription}
      onClose={() => setIsSelectingSubscription(false)}
      featureAccessToGrant={featureAccessToGrant}
    />
  ) : (
    <Flex
      id="modal-overlay"
      width="100vw"
      height="100vh"
      position="fixed"
      justifyContent="center"
      alignItems="center"
      zIndex={102}
      top="0px"
      style={{
        background: 'rgba(145, 145, 145, 0.1)',
        backdropFilter: 'blur(20px)',
      }}
    >
      <Flex
        id="modal-main-container"
        height="auto"
        width="100%"
        minWidth="380px"
        overflowY="auto"
        maxWidth="528px"
        maxHeight="90vh"
        position="absolute"
        style={{
          background: 'rgba(255, 255, 255, 0.70)',
          boxShadow: '0px 4px 32px rgba(0, 0, 0, 0.08)',
          borderRadius: 20,
          backdropFilter: 'blur(40px)',
          flexDirection: 'column',
          marginBottom: '148px',
          marginTop: '84px',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'inline-flex',
        }}
      >
        {onClose && (
          <Flex
            height="32px"
            width="32px"
            backgroundColor={isCloseHovering ? '#fff' : '#EDEEF1'}
            justifyContent="center"
            alignItems="center"
            borderRadius="8px"
            position="absolute"
            top="16px"
            zIndex={107}
            onHoverStart={() => setIsCloseHovering(true)}
            onHoverEnd={() => setIsCloseHovering(false)}
            right="24px"
            onClick={onClose}
            cursor="pointer"
          >
            <Icon name="x" size={isCloseHovering ? 16 : 12} />
          </Flex>
        )}
        <Flex
          width="100%"
          height="auto"
          inset={{ vertical: '16px', horizontal: '24px' }}
          justifyContent="center"
          alignItems="center"
          minHeight="280px"
          position="relative"
          overflow="hidden"
        >
          <div
            style={{
              backgroundPosition: 'top',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              width: image.width,
              height: image.height,
              backgroundImage: `url(${image.url})`,
              position: 'absolute',
              top: image.top,
              left: image.left,
              right: image.right,
              bottom: image.bottom,
              overflow: 'hidden',
            }}
          />
        </Flex>
        <Flex
          backgroundColor="#fff"
          inset={{ vertical: '20px', horizontal: '24px' }}
          width="100%"
          height="auto"
          rowGap="8px"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Text
            type="title"
            alignment="center"
            style={{
              textDecorationColor: '#404040',
              fontSize: 48,
              fontWeight: 700,
              color: '#404040',
            }}
          >
            {textContent.title}
          </Text>
          <Text
            type="subtitle"
            alignment="center"
            style={{
              textDecorationColor: '#404040',
              fontSize: 24,
              fontWeight: 700,
              color: '#404040',
              maxWidth: '362px',
            }}
          >
            {textContent.subTitle}
          </Text>
          <Text
            type="body"
            alignment="center"
            style={{
              textDecorationColor: '#404040',
              fontSize: 18,
              fontWeight: 500,
              color: '#6B7280',
            }}
          >
            {textContent.description}
          </Text>
          <Flex
            maxWidth="378px"
            width="100%"
            outset={{ vertical: '8px' }}
            onHoverStart={() => setIsProceedHovering(true)}
            onHoverEnd={() => setIsProceedHovering(false)}
          >
            <Button
              backgroundColorOverride={
                isProceedHovering ? '#23c3ff' : '#00AEEF'
              }
              fullWidth={true}
              onClick={() => setIsSelectingSubscription(true)}
            >
              <Text
                type="body"
                style={{
                  fontSize: 16,
                  fontWeight: 700,
                  color: 'white',
                }}
              >
                {actions?.mainAction.title}
              </Text>
            </Button>
          </Flex>
          {actions?.secondaryAction && onClose && (
            <Flex onClick={actions.secondaryAction.execute} cursor="pointer">
              <Text
                style={{
                  fontWeight: 700,
                  color: '#404040',
                  textDecorationLine: 'underline',
                }}
                type="body"
              >
                {actions.secondaryAction.title}
              </Text>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}
