import React from 'react'
import { Interpolation } from '@emotion/css'

import { Button } from 'src/chrome'
import CloseButton from '../CloseButton/CloseButton'
import { Flex, Text } from '@sendoutcards/quantum-design-ui'

const styles = {
  wrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,.16)',
    minHeight: '50px',
    alignItems: 'center',
    padding: 20,
    borderRadius: 2,
    backgroundColor: '#fff',
    marginTop: 10,
    '@media (max-width: 600px)': {
      flexDirection: 'column',
    },
  } as Interpolation,
  rightActions: {
    display: 'flex',
    marginRight: 0,
    marginTop: 0,
    '@media (max-width: 600px)': {
      marginTop: 20,
      marginRight: 0,
    },
  } as Interpolation,
  leftPart: {
    width: '60%',
    '@media (max-width: 600px)': {
      width: '100%',
    },
  } as Interpolation,
  dismissButton: {
    marginLeft: '20px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
}

interface NotificationProps {
  message: string
  actionText: string
  onClose: () => void
  onAccept: () => void
}

const Notification: React.FC<NotificationProps> = props => {
  const { message, actionText, onClose, onAccept } = props

  return (
    <div css={styles.wrapper}>
      <div css={styles.leftPart}>
        <Text type="body" color="primaryHeading">
          {message}
        </Text>
      </div>
      <Flex alignItems="center">
        <Button
          onClick={() => onAccept()}
          title={actionText}
          style={{ marginRight: '20px' }}
        />
        <CloseButton size={24} onClose={() => onClose()} />
      </Flex>
    </div>
  )
}

export default Notification
