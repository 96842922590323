import { compact } from '.'

const formatLocation = (
  city?: string,
  state?: string,
  country?: string,
  fallbackMessage: string = 'Location Unknown',
) => compact(city, state, country).join(', ') || fallbackMessage

export default formatLocation
