import React from 'react'
import { RawCreateAccountForm } from 'src/dashboard/components/RawCreateAccountForm'
import { Button, Flex } from '@sendoutcards/quantum-design-ui'
import { useState } from 'src/hooks/useState'
import useMutations from 'src/hooks/useMutations'
import useActions from 'src/hooks/useActions'
import {
  useCallback,
  useDebounce,
  useEmailValidation,
  useSelector,
} from 'src/hooks'
import { sendoutcardsPromotionsGenealogyId } from 'src/app/constants'
import { parseError } from 'src/utils/parseError'
import { useUpdateAccount } from 'src/react_query'

type InputType = {
  error: string | null | undefined
  value: string
}

type CreateAccountFormState = {
  email: InputType
  username: InputType
  password: InputType
}

interface Props {
  shouldNotAutoComplete?: boolean
  handleSuccessfulAccCreation?: () => void
  shouldStackForm?: boolean
}

export const BasicCreateAccountForm: React.FC<Props> = props => {
  const {
    shouldNotAutoComplete,
    handleSuccessfulAccCreation,
    shouldStackForm = false,
  } = props
  const [createAccountFormState, setCreateAccountFormState] = useState<
    CreateAccountFormState
  >({
    email: { error: undefined, value: '' },
    username: { error: null, value: '' },
    password: { error: null, value: '' },
  })

  const debouncedEmail = useDebounce(createAccountFormState.email.value, 500)
  const emailValidation = useEmailValidation(debouncedEmail)

  const [universalError, setUniversalError] = useState<string | null>(null)

  const [isLoading, setIsLoading] = useState(false)

  const hasRequiredFields = (
    username: string,
    password: string,
    email: string,
  ) => {
    if (!username || !password || !email) {
      setCreateAccountFormState({
        ...createAccountFormState,
        password: {
          ...createAccountFormState.password,
          error: !password ? 'Password is required' : null,
        },
        email: {
          ...createAccountFormState.email,
          error: !email ? 'Email is required' : null,
        },
        username: {
          ...createAccountFormState.username,
          error: !username ? 'Username is required' : null,
        },
      })
      return false
    }
    return true
  }

  const mutations = useMutations()
  const updateAccountMutation = useUpdateAccount()
  const actions = useActions()
  const { isMobile, width } = useSelector(state => state.window)
  const shouldStackDetails = isMobile || width <= 756
  const [sponsorGenealogyId, setSponsorGenealogyId] = useState<string>()

  const handleSponsorChanges = useCallback(
    (userId: string, genealogyId?: string) => {
      setSponsorGenealogyId(genealogyId)
    },
    [],
  )

  const onSubmit = async (
    username: string,
    password: string,
    email: string,
  ) => {
    if (!hasRequiredFields(username, password, email)) return
    try {
      setIsLoading(true)
      const {
        validateUsername: { valid: isValidUsername },
      } = await mutations.validateUsername({
        username,
        doNotShowDefaultTransition: true,
      })
      if (isValidUsername) {
        try {
          const account = (
            await updateAccountMutation.mutateAsync({
              account: {
                username,
                password,
                email,
                sponsorGenealogyId: sponsorGenealogyId
                  ? sponsorGenealogyId
                  : String(sendoutcardsPromotionsGenealogyId),
              },
            })
          ).updateAccount.account
          actions.updatedAccount(account)
          handleSuccessfulAccCreation?.()
        } catch (error) {
          setUniversalError(parseError(error))
        }
      } else {
        setCreateAccountFormState({
          ...createAccountFormState,
          username: {
            ...createAccountFormState.username,
            error: 'Username is already taken',
          },
        })
      }
    } catch (error) {
      setUniversalError(parseError(error))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Flex
      backgroundColor="foreground"
      borderRadius="large"
      width="100%"
      flexDirection="column"
      inset={{ vertical: 'x3' }}
    >
      <RawCreateAccountForm
        data={{
          email: createAccountFormState.email.value,
          username: createAccountFormState.username.value,
          password: createAccountFormState.password.value,
        }}
        isLoading={isLoading}
        setData={input => {
          if (isLoading) return
          setCreateAccountFormState({
            ...createAccountFormState,
            email: { value: input.email, error: null },
            username: {
              value: input.username,
              error: null,
            },
            password: {
              value: input.password,
              error: null,
            },
          })
        }}
        errors={{
          email: emailValidation.errorMessage,
          username: createAccountFormState.username.error,
          password: createAccountFormState.password.error,
          universal: universalError,
        }}
        shouldNotAutoComplete={shouldNotAutoComplete}
        shouldStackForm={shouldStackForm}
        handleSponsorChanges={handleSponsorChanges}
        isLoadingEmailValidation={emailValidation.isLoading}
      />
      <Flex
        outset={{ top: 'x3' }}
        justifyContent={shouldStackForm ? 'center' : 'flex-end'}
        alignItems="center"
      >
        <Button
          fullWidth={shouldStackDetails ? true : false}
          disabled={
            isLoading || !emailValidation.isValid || emailValidation.isLoading
          }
          size={shouldStackForm ? 'medium' : 'small'}
          disabledCaption={'Please wait while we create your account'}
          title={isLoading ? 'Creating Account' : 'Create Account'}
          onClick={() => {
            onSubmit(
              createAccountFormState.username.value,
              createAccountFormState.password.value,
              createAccountFormState.email.value,
            )
          }}
        />
      </Flex>
    </Flex>
  )
}
