import { Location } from 'history'

import { BaseRoute } from 'src/routes/BaseRoute'
import Action from 'src/redux/action'
import { openRoute } from 'src/redux/actions/routes'
import { FourOFourRoute } from 'src/404/routes/404Route'

const getArgs = (
  location: Location<object | null>,
): { [key: string]: string | undefined } =>
  location.search
    .split('?')
    .slice(-1)[0]
    .split('&')
    .map(keyValueString => keyValueString.split('='))
    .reduce((args, [key, value]) => ({ ...args, [key]: value }), {})

const redirect = (pathname: string) => {
  if (pathname === '/') {
    return '/dashboard/account'
  }
  return pathname.replace('campaign-store', 'campaigns/store')
}

const actionForLocation = (location: Location<object | null>): Action => {
  const route = BaseRoute.fromPath(redirect(location.pathname), location)
  const args = getArgs(location)
  if (route) {
    return openRoute(route, args)
  } else {
    return openRoute(BaseRoute(FourOFourRoute), args)
  }
}

export default actionForLocation
