import React from 'react'

type DeleteIconProps = {
  size: number
  color: string
}

export const DeleteIcon: React.FC<DeleteIconProps> = ({ size, color }) => {
  return (
    <svg
      width={`${size}px`}
      height="auto"
      viewBox="0 0 36 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fontWeight: 'bold' }}
    >
      <path
        d="M31.4996 10.4283C31.4696 10.4283 31.4246 10.4283 31.3796 10.4283C23.4446 9.63334 15.5246 9.33334 7.67951 10.1283L4.61951 10.4283C3.98951 10.4883 3.43451 10.0383 3.37451 9.40834C3.31451 8.77834 3.76451 8.23834 4.37951 8.17834L7.43951 7.87834C15.4196 7.06834 23.5046 7.38334 31.6046 8.17834C32.2196 8.23834 32.6696 8.79334 32.6096 9.40834C32.5646 9.99334 32.0696 10.4283 31.4996 10.4283Z"
        fill={color}
      />
      <path
        d="M12.7511 8.91337C12.6911 8.91337 12.6311 8.91337 12.5561 8.89837C11.9561 8.79337 11.5361 8.20837 11.6411 7.60837L11.9711 5.64337C12.2111 4.20337 12.5411 2.20837 16.036 2.20837H19.966C23.476 2.20837 23.806 4.27837 24.031 5.65837L24.361 7.60837C24.466 8.22337 24.046 8.80837 23.446 8.89837C22.831 9.00337 22.246 8.58337 22.156 7.98337L21.826 6.03337C21.616 4.72837 21.571 4.47337 19.981 4.47337H16.051C14.4611 4.47337 14.4311 4.68337 14.2061 6.01837L13.8611 7.96837C13.7711 8.52337 13.2911 8.91337 12.7511 8.91337ZM22.8156 34.4585H13.1856C7.95058 34.4585 7.74058 31.5635 7.57558 29.2235L6.60058 14.1185C6.55558 13.5035 7.03558 12.9635 7.65058 12.9185C8.28058 12.8885 8.80558 13.3535 8.85058 13.9685L9.82558 29.0735C9.99058 31.3535 10.0506 32.2085 13.1856 32.2085H22.8156C25.9656 32.2085 26.0256 31.3535 26.1756 29.0735L27.1506 13.9685C27.1956 13.3535 27.7356 12.8885 28.3506 12.9185C28.9656 12.9635 29.4456 13.4885 29.4006 14.1185L28.4256 29.2235C28.2606 31.5635 28.0506 34.4585 22.8156 34.4585Z"
        fill={color}
      />
      <path
        d="M20.4901 26.2084H15.4952C14.8801 26.2084 14.3701 25.6984 14.3701 25.0834C14.3701 24.4684 14.8801 23.9584 15.4952 23.9584H20.4901C21.1051 23.9584 21.6151 24.4684 21.6151 25.0834C21.6151 25.6984 21.1051 26.2084 20.4901 26.2084ZM21.75 20.2084H14.25C13.635 20.2084 13.125 19.6984 13.125 19.0834C13.125 18.4684 13.635 17.9584 14.25 17.9584H21.75C22.365 17.9584 22.875 18.4684 22.875 19.0834C22.875 19.6984 22.365 20.2084 21.75 20.2084Z"
        fill={color}
      />
    </svg>
  )
}
