import phoneNumberCountryFragmentRawString from '../PhoneNumberCountryFragment.graphql'

export const phoneNumberCountryFragmentString = phoneNumberCountryFragmentRawString

export type PhoneNumberCountryFragment = {
  __typename: 'PhoneNumberCountry'
  name: string
  isoCode: string
  callingCode: number
}

export const isPhoneNumberCountryFragment = (
  fragment: any,
): fragment is PhoneNumberCountryFragment =>
  fragment &&
  fragment.__typename === 'PhoneNumberCountry' &&
  typeof fragment.name === 'string' &&
  typeof fragment.isoCode === 'string' &&
  typeof fragment.callingCode === 'number'
