import React, { FC } from 'react'
import { withTheme } from 'emotion-theming'

import { Icon } from 'src/chrome'
import { formatWithLeadingZero } from 'src/helpers'
import { SmallOrLarge } from 'src/app/types'
import { Theme } from 'src/theme/theme'

import styles from './styles'
import { Text, TextColor } from '@sendoutcards/quantum-design-ui'

type FormattedPriceProps = {
  dollars: number | string
  cents: number | string
  color?: TextColor
  readonly size?: SmallOrLarge
  theme: Theme
}

const FormattedPrice: FC<FormattedPriceProps> = props => {
  const { dollars, cents, size = 'large', color = 'primaryBrand' } = props

  // TODO: remove this once the Icon component is updated to QDS Icon
  const getQDSColor = (color: TextColor) => {
    switch (color) {
      case 'primaryBrand':
        return 'hsl(323,100%,51%)'
      case 'primaryBody':
        return 'hsl(0,0%,38%)'
      case 'accent':
        return '#FFF'
      default:
        return 'hsl(0,0%,38%)'
    }
  }

  return (
    <div css={{ display: 'flex' }}>
      <div css={() => styles.dollarSign(size)}>
        <Icon
          icon={'DOLLARSIGN'}
          size={size === 'large' ? 18 : 12}
          color={getQDSColor(color)}
        />
      </div>
      <Text type="title" color={color}>
        {dollars}
      </Text>
      <Text
        type="caption"
        color={color}
        outset={{ top: 'auto', bottom: 'x_5' }}
      >{`.${formatWithLeadingZero(cents)}`}</Text>
    </div>
  )
}

export default withTheme(FormattedPrice)
