import { cssStyles } from 'src/styled'

export const sharedStyles = cssStyles({
  itemContainer: {
    margin: '0 auto',
    '> div': {
      position: 'relative',
      margin: '22px',
      width: '280px',
      height: '385px',
      boxShadow: '0 4px 5px 0 #d4d4d4',
      perspective: '800px',
      borderRadius: '3px',
      transformStyle: 'preserve-3d',
      transition: [
        'transform 5s, -webkit-transform 5s',
        'width 500ms ease-in-out',
      ],
      '@media (max-width: 1023px)': {
        margin: '12px',
        width: '245px !important',
        height: '340px !important',
      },
    },
  },
  itemOuterContainer: {
    margin: '0 auto',
  },
})

export const styles = cssStyles({
  itemContainer: {
    '> div': {
      ':hover': {
        h1: {
          whiteSpace: 'normal',
          textOverflow: 'initial',
        },
        'section > div:last-child': {
          maxHeight: '200px',
          transitionDelay: '0s',
        },
        ':after': {
          content: '""',
          width: '100%',
          height: '100%',
          backgroundImage: 'linear-gradient(115deg, #ff5689, #e687ff)',
          opacity: '0.5',
          borderRadius: '3px',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
      },
      '@media (max-width: 1023px)': {
        margin: '12px',
        width: '245px !important',
        height: '340px !important',
      },
    },
  },
  itemView: {
    backgroundColor: '#eeefef',
    width: '280px',
    height: '385px',
    borderRadius: '3px',
    overflow: 'hidden',
    '@media (max-width: 1023px)': {
      width: '245px !important',
      height: '340px !important',
    },
  },
  description: {
    maxHeight: '0px',
    overflow: 'hidden',
    color: '#777777',
    fontSize: '16px',
    lineHeight: '1.8em',
    transition: 'all 0.3s ease 0.2s',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
  },
  button: {
    width: '50%',
    textAlign: 'center',
    borderTop: '1px solid #edeeef',
    padding: '7px 0',
    fontWeight: 400,
    cursor: 'pointer',
    '&:first-of-type': {
      borderRight: '1px solid #edeeef',
    },
  },
  card: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    borderRadius: '3px',
    backgroundPosition: 'center',
  },
  horizontalCard: {
    backgroundSize: 'contain',
    height: '238px',
  },
  giftThumbnail: {
    position: 'absolute',
    backgroundSize: 'cover',
    top: '15px',
    right: '12px',
    width: '100px',
    height: '100px',
    borderRadius: '5px',
    boxShadow:
      '0 4px 9px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  },
  itemDetails: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    background: '#fff',
    width: '100%',
    padding: '24px 18px 50px',
    boxSizing: 'border-box',
    height: 'auto',
    borderRadius: '0 0 3px 3px',
    transition: 'all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s',
    zIndex: 2,
  },
  category: {
    position: 'absolute',
    top: '-30px',
    left: 0,
    padding: '8px 20px',
    color: '#fff',
    fontSize: '13px',
    fontWeight: 600,
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    background: ['#ff5689', 'linear-gradient(115deg, #ff5689, #e687ff)'],
  },
  title: {
    lineHeight: 'initial',
    margin: '0',
    padding: '0 0 2px',
    color: '#333333',
    fontSize: '20px',
    fontWeight: 700,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxHeight: '56px',
  },
  subTitle: {
    lineHeight: 'initial',
    margin: '0',
    padding: '10px 0 20px',
    color: '#646464',
    fontSize: '16px',
    fontWeight: 600,
  },

  activeItemView: {
    boxShadow: '0 10px 20px 0 rgba(239,146,251,.3)',
  },
  loader: {
    position: 'absolute',
    top: '25%',
    left: 'calc(50% - 22px)',
  },
})
