const buildShoppingCartURL = (
  shoppingCartCategory: string | undefined,
  shoppingCartProduct: string | undefined,
  genealogyId: number | undefined,
) => {
  // 0 is the MarketShow value for GreenerStill. "&MarketShow=0" will display GreenerStill logo in the shopping cart.
  // Hard coded for now, could potentially by dynamic if this functionality is expanded in the future.
  const marketShow = 0

  if (shoppingCartCategory) {
    return `https://shop.bydesign.com/SendOutCards/#/shop/from/${genealogyId}?categoryID=${shoppingCartCategory}&MarketShow=${marketShow}`
  }

  return `https://shop.bydesign.com/SendOutCards/#/shop/detail/${shoppingCartProduct}/from/${genealogyId}?MarketShow=${marketShow}`
}

export { buildShoppingCartURL }
