import onlyIdsContactFragmentRawString from '../OnlyIdsContactFragment.graphql'

export const onlyIdsContactFragmentString = onlyIdsContactFragmentRawString

export type OnlyIdsContactFragment = {
  __typename: 'Contact'
  id: string
}

export const isOnlyIdsContactFragment = (
  fragment: any,
): fragment is OnlyIdsContactFragment =>
  fragment &&
  fragment.__typename === 'Contact' &&
  typeof fragment.id === 'string'
