import { AccessLevel, Route } from 'src/routes/Route'
import { toggleIsNewGroupModalOpen } from 'src/redux/actions'

export const GroupsRoute = Route({
  path: '/groups',
  accessLevel: AccessLevel.PRIVATE,
  init: (isNewGroupModalOpen: boolean = false) => ({
    isNewGroupModalOpen,
  }),
  fromPath: () => ({ isNewGroupModalOpen: false }),
  reducer: (state, action) => {
    if (action.type === toggleIsNewGroupModalOpen.type) {
      return {
        ...state,
        isNewGroupModalOpen: !state.isNewGroupModalOpen,
      }
    } else {
      return state
    }
  },
})
export type GroupsRoute = Route<typeof GroupsRoute>
