import React from 'react'
import Menu from '@material-ui/core/Menu'

import MenuItem from './menu-item'
interface Props {
  anchorEl?: Element
  onClose: () => void
  onToggleSelectAll: () => void
  onSendCard: () => void
  onViewGroups: () => void
  onStartCampaign: () => void
  onAddToGroup?: () => void
  onDelete: () => void
  onShare?: () => void
  onExport?: () => void
  isEverySelected: boolean
  isAnySelected: boolean
  isOpen: boolean
}

const ContactMenu: React.FC<Props> = props => {
  const {
    anchorEl,
    onClose,
    onToggleSelectAll,
    onSendCard,
    onViewGroups,
    onStartCampaign,
    onAddToGroup,
    onDelete,
    onShare,
    onExport,
    isEverySelected,
    isAnySelected,
    isOpen,
  } = props

  return (
    <Menu
      onClick={onClose}
      id="icon-menu"
      anchorEl={anchorEl}
      keepMounted={true}
      open={isOpen}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      onClose={onClose}
      PaperProps={{
        style: {
          width: '100%',
          height: '50%',
        },
      }}
      css={{
        '.MuiMenu-list': {
          display: 'grid',
        },
      }}
    >
      <MenuItem
        show={['sm', 'md']}
        onClick={onViewGroups}
        icon={'FUNNEL'}
        text="View Contacts"
      />
      <MenuItem
        show={['sm']}
        onClick={onToggleSelectAll}
        icon={'CHECKMARK'}
        iconViewBox={'0 0 1456 800'}
        text={isEverySelected ? 'Deselect All' : 'Select All'}
      />
      <MenuItem
        show={['sm']}
        onClick={!isAnySelected ? () => {} : onSendCard}
        isDisabled={!isAnySelected}
        icon={'SEND'}
        text="Send Card"
      />
      <MenuItem
        show={['sm']}
        onClick={!isAnySelected ? () => {} : onStartCampaign}
        isDisabled={!isAnySelected}
        iconViewBox={'0 0 1400 1400'}
        icon={'ENVELOPE'}
        text="Campaign"
      />
      <MenuItem
        show={['sm']}
        id={'delete_contact_btn'}
        onClick={!isAnySelected ? () => {} : onDelete}
        isDisabled={!isAnySelected}
        icon={'TRASH'}
        text="Delete"
      />
      {onAddToGroup && (
        <MenuItem
          id={'add_to_group_btn'}
          show={['sm']}
          onClick={!isAnySelected ? () => {} : onAddToGroup}
          isDisabled={!isAnySelected}
          icon={'ADDUSER'}
          text="Add to group"
        />
      )}
      {onShare && (
        <MenuItem
          id={'share_contact_btn'}
          onClick={!isAnySelected ? () => {} : onShare}
          isDisabled={!isAnySelected}
          icon={'GROUP'}
          iconViewBox={'0 0 60 32'}
          text="Share"
        />
      )}
      {onExport && (
        <MenuItem
          id={'export_csv_btn'}
          onClick={!isAnySelected ? () => {} : onExport}
          isDisabled={!isAnySelected}
          icon={'NEWFILE'}
          text="Export"
        />
      )}
    </Menu>
  )
}

export default ContactMenu
