import isNumber from 'lodash/isNumber'
import omit from 'lodash/omit'

import { computedElementText } from 'src/redux/selectors/text'
import {
  EditorElement,
  EditorFullBleed,
  EditorPanel,
} from '../../editor/EditorCard'
import {
  ComputedElementText,
  defaultTextSelection,
  TextSelection,
} from 'src/editor/text/types'
import {
  CardFragment,
  ElementFragment,
  PanelFragment,
  SendableCardWithTemplateFragment,
} from 'src/graphql/generated/graphql'
import { Step, Steps } from 'src/editor/types'

export const isCustomCard = <SendableCard>(
  card:
    | {
        sendableCard: SendableCard | null
      }
    | undefined,
) => !!card && card.sendableCard === null

export const variations = (card: CardFragment) =>
  card &&
  card.detailedSendableCard &&
  card.detailedSendableCard.variations.length > 0
    ? [card.detailedSendableCard, ...card.detailedSendableCard.variations]
    : undefined

const variationImage = (panel: PanelFragment | EditorPanel | undefined) => {
  if (!panel) {
    return undefined
  }
  const element = (panel.elements as (ElementFragment | EditorElement)[]).find(
    element => element.locked && element.image && element.image.image,
  )
  return element && element.image ? element.image.image : undefined
}

export const frontImage = ({
  cardTemplate,
  frontImage,
}: SendableCardWithTemplateFragment) =>
  cardTemplate ? variationImage(cardTemplate.panels[0]) : frontImage

export const insideRightImage = ({
  cardTemplate,
  insideRightImage,
}: SendableCardWithTemplateFragment) =>
  cardTemplate
    ? variationImage(
        cardTemplate.panels.find(
          panel => panel.location === cardTemplate.backPanelLocation - 1,
        ),
      )
    : insideRightImage

export const selectedVariationId = (
  card: CardFragment,
  fullBleeds: EditorFullBleed[],
) => {
  const cardVariations = variations(card)
  if (!cardVariations) {
    return undefined
  }
  const frontPanel = fullBleeds[0].panels[0]
  const variation = cardVariations.find(variation => {
    const image = variationImage(frontPanel)
    if (!image) {
      return false
    }
    return frontImage(variation)?.id === image.id
  })
  return variation ? variation.id : undefined
}

type PanelInterface = {
  location: number
  fullBleed: FullBleedInterface
  width: number
  height: number
  isCustom: boolean
}

const computedElements = (
  elements: EditorElement[],
  panel: PanelInterface,
  selection: Selection,
) =>
  elements.map(
    (element, location): ComputedElement => {
      const points = {
        x: element.x * panel.width,
        y: element.y * panel.height,
        width: element.width * panel.width,
        height: element.height * panel.height,
      }
      const isSelected =
        panel.fullBleed.location === selection.fullBleed &&
        panel.location === selection.panel &&
        location === selection.element
      return {
        ...element,
        text:
          element.text != null
            ? computedElementText(
                {
                  text: element.text,
                  selection: selection.textSelection,
                },
                isSelected,
              )
            : null,
        location,
        isSelected,
        points,
        isCustom: !!(panel.isCustom && element.text),
      }
    },
  )

type FullBleedInterface = {
  location: number
  width: number
  height: number
  isCustom: boolean
}

type Selection = {
  fullBleed: number
  panel?: number
  element?: number
  textSelection: TextSelection
}

export type ComputedPanel = Omit<EditorPanel, 'elements'> & {
  location: number
  isSelected: boolean
  isCustom: boolean
  points: Dimensions
  elements: ComputedElement[]
}

const computedPanels = (
  panels: EditorPanel[],
  fullBleed: FullBleedInterface,
  selection: Selection,
) =>
  panels.map(
    (panel, location): ComputedPanel => {
      const points = {
        x: panel.x * fullBleed.width,
        y: panel.y * fullBleed.height,
        width: panel.width * fullBleed.width,
        height: panel.height * fullBleed.height,
      }
      return {
        ...panel,
        location,
        isSelected:
          fullBleed.location === selection.fullBleed &&
          location === selection.panel,
        isCustom: fullBleed.isCustom,
        points,
        elements: computedElements(
          panel.elements,
          {
            location,
            fullBleed,
            width: points.width,
            height: points.height,
            isCustom: fullBleed.isCustom,
          },
          selection,
        ),
      }
    },
  )

type Dimensions = { x: number; y: number; width: number; height: number }

export type ComputedElement = Omit<EditorElement, 'text'> & {
  location: number
  isSelected: boolean
  points: Dimensions
  text: ComputedElementText | null
  isCustom: boolean
}

export type ComputedFullBleed = Omit<
  EditorFullBleed,
  'panels' | 'backgroundElement'
> & {
  location: number
  backgroundElement: ComputedElement | null
  panels: ComputedPanel[]
}

export const getComputedFullBleeds = (
  card: CardFragment,
  fullBleeds: EditorFullBleed[],
  step: Step,
) =>
  fullBleeds.map(
    (fullBleed, location): ComputedFullBleed => ({
      ...fullBleed,
      location,
      backgroundElement: fullBleed.backgroundElement
        ? {
            ...omit(fullBleed.backgroundElement, 'text'),
            text: null,
            location: 0,
            isSelected: !isNumber(step.type === Steps.EditBackgroundPicture),
            points: {
              x: 0,
              y: 0,
              width: fullBleed.width,
              height: fullBleed.height,
            },
            isCustom:
              isCustomCard({
                ...card,
                sendableCard: card.detailedSendableCard,
              }) && location === 0,
          }
        : null,
      panels: computedPanels(
        fullBleed.panels,
        {
          location,
          width: fullBleed.width,
          height: fullBleed.height,
          isCustom:
            isCustomCard({
              ...card,
              sendableCard: card.detailedSendableCard,
            }) && location === 0,
        },
        {
          fullBleed: step.fullBleedIndex,
          panel: step.panelIndex,
          element: step.elementIndex,
          textSelection: step.textSelection || defaultTextSelection,
        },
      ),
    }),
  )

export const getComputedSingleFullBleeds = (
  card: CardFragment,
  fullBleeds: EditorFullBleed[],
  step: Step,
) =>
  getComputedFullBleeds(card, fullBleeds, step).flatMap(fullbleed =>
    fullbleed.panels.map(panel => ({
      ...fullbleed,
      panels: [panel],
    })),
  )

type PanelLocation = {
  fullBleedIndex: number
  panelIndex: number
}

export const getSinglePanelIndexMap = (
  fullBleeds: EditorFullBleed[],
): PanelLocation[] =>
  fullBleeds.flatMap((fullBleed, fullBleedIndex) =>
    fullBleed.panels.map((_, panelIndex) => ({
      fullBleedIndex,
      panelIndex,
    })),
  )

export const getSinglePanelIndex = (
  fullBleeds: EditorFullBleed[],
  fullBleedIndex: number,
  panelIndex: number | undefined,
): number =>
  getSinglePanelIndexMap(fullBleeds).findIndex(
    (location: PanelLocation) =>
      location.fullBleedIndex === fullBleedIndex &&
      location.panelIndex === panelIndex,
  )
