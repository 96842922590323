import detailedCountryFragmentRawString from '../DetailedCountryFragment.graphql'

export const detailedCountryFragmentString = detailedCountryFragmentRawString

export type DetailedCountryFragment = {
  __typename: 'Country'
  postalName: string
  alpha2: string
  subdivisions: {
    __typename: 'Subdivision'
    abbreviationOrName: string
  }[]
}

export const isDetailedCountryFragment = (
  fragment: any,
): fragment is DetailedCountryFragment =>
  fragment &&
  fragment.__typename === 'Country' &&
  typeof fragment.postalName === 'string' &&
  typeof fragment.alpha2 === 'string' &&
  Array.isArray(fragment.subdivisions) &&
  fragment.subdivisions
    .slice(0, 5)
    .reduce(
      (accum: any, next: any) =>
        accum &&
        next &&
        next.__typename === 'Subdivision' &&
        typeof next.abbreviationOrName === 'string',
      true,
    )
