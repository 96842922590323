import React from 'react'
// tslint:disable-next-line: no-import-side-effect
import '@emotion/core'
import { ThemeProvider } from 'emotion-theming'
import { PersistGate } from 'redux-persist/integration/react'
// tslint:disable-next-line: no-import-side-effect
import 'array-flat-polyfill'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { MuiThemeProvider } from '@material-ui/core/styles'
// @src imports
// LogRocket needs to be imported before App as it customizes `window.fetch`
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import LogRocket from 'src/logrocket/logrocket'
import muiTheme from 'src/chrome/muiTheme'
import theme from 'src/theme/theme'
import { persistor, Store } from 'src/redux/store'
import App from 'src/app/App'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

if (process.env.NODE_ENV === 'development') {
  window.onerror = (event, source, lineno, colno, error) => {
    console.error('window error:', event, source, error)
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus:
        process.env.REACT_APP_USE_REFETCH_ON_WINDOW_FOCUS === 'true',
    },
  },
})
export const getQueryClient = () => queryClient

render(
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={false} />
    <Provider store={Store}>
      <PersistGate persistor={persistor}>
        <MuiThemeProvider theme={muiTheme}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </MuiThemeProvider>
      </PersistGate>
    </Provider>
  </QueryClientProvider>,
  document.getElementById('app'),
)
// TypeForm Script
;(() => {
  setTimeout(() => {
    const d = document
    const gi = d.getElementById
    const ce = d.createElement
    const gt = d.getElementsByTagName
    const id = 'typef_orm_share'
    const b = 'https://embed.typeform.com/'
    if (!gi.call(d, id)) {
      const mutableJS = ce.call<Document, [string], HTMLScriptElement>(
        d,
        'script',
      )
      mutableJS.id = id
      mutableJS.src = b + 'embed.js'
      const q = gt.call(d, 'script')[0]
      q && q.parentNode && q.parentNode.insertBefore(mutableJS, q)
    }
  }, 1000)
})()
