const executeWhenNoChangeAfterTimeout = (
  timeout: number | undefined,
  func: () => void,
  time = 500,
) => {
  clearTimeout(timeout)
  return (setTimeout(func, time) as unknown) as number
}

export default executeWhenNoChangeAfterTimeout
