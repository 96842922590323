import { Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import React, { FC } from 'react'
import { getFormatedDate } from 'src/helpers/formatDate'
import { DateBadge } from './DateBadge'

type ReminderListCardProps = {
  type: string
  name: string
  date: string
  onClick: () => void
}

export const ReminderListCard: FC<ReminderListCardProps> = ({
  type,
  name,
  date,
  onClick,
}) => {
  const typeColor =
    type === 'birthday'
      ? '#BE9CDD'
      : type === 'anniversary'
      ? '#f6ce81'
      : '#616161'
  const weekDay = getFormatedDate(date)

  return (
    <Flex
      width="100%"
      alignItems="center"
      style={{
        marginBottom: '16px',
      }}
    >
      <DateBadge
        day={weekDay.day}
        weekday={weekDay.weekday}
        month={weekDay.month}
        isActive={false}
        color="#BE9CDD"
        hasEvent={true}
      />

      <Flex
        width="100%"
        alignItems="center"
        outset={{ left: 'x1_5' }}
        inset={{ vertical: 'x2', horizontal: 'x1_5' }}
        style={{ borderRadius: '12px', backgroundColor: '#f8f9fa' }}
      >
        <Div
          width="3px"
          height="35px"
          backgroundColor={typeColor}
          borderRadius="circle"
          outset={{ right: 'x1_5' }}
        />
        <Flex flexDirection="column">
          <Text
            type="body"
            weight="semiBold"
            content={name}
            color="primaryHeading"
            lineHeight={1}
            outset={{ bottom: 'x_75' }}
          />
          <Flex>
            <Text
              type="caption"
              weight="regular"
              content={`${getFormatedDate(date).month} · `}
              color="primaryBody"
              lineHeight={1}
            />
            <Text
              outset={{ left: 'x_25' }}
              type="caption"
              weight="regular"
              content={type}
              style={{ color: typeColor }}
              lineHeight={1}
            />
          </Flex>
        </Flex>

        <Div style={{ marginLeft: 'auto' }} onClick={onClick}>
          Act
        </Div>
      </Flex>
    </Flex>
  )
}
