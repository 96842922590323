import { useEffect } from '.'
import { useState } from './useState'

type Device = 'AndroidPhone' | 'Desktop' | 'iPad' | 'iPhone' | 'Other'
type OS = 'Android' | 'iOS' | 'Linux' | 'MacOS' | 'Windows' | 'Other'
type Browser = 'Chrome' | 'Edge' | 'Firefox' | 'Safari' | 'Other'

type DeviceInfo = {
  device: Device
  os: OS
  browser: Browser
}

const useDeviceInfo = (): DeviceInfo => {
  const [device, setDevice] = useState<Device>('Other')
  const [os, setOS] = useState<OS>('Other')
  const [browser, setBrowser] = useState<Browser>('Other')

  const userAgent = navigator.userAgent

  useEffect(() => {
    // Note: if/else order is important due different userAgent returns
    // Identifying Device and OS.
    if (/windows/i.test(navigator.userAgent)) {
      setDevice('Desktop')
      setOS('Windows')
    } else if (/android/i.test(navigator.userAgent)) {
      setDevice('AndroidPhone')
      setOS('Android')
    } else if (/iphone/i.test(navigator.userAgent)) {
      setDevice('iPhone')
      setOS('iOS')
    } else if (
      /ipad/i.test(navigator.userAgent) ||
      // iPad safari has Macintosh instead of iPad in user agent
      (/macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints > 1)
    ) {
      setDevice('iPad')
      setOS('iOS')
    } else if (/macintosh/i.test(navigator.userAgent)) {
      setDevice('Desktop')
      setOS('MacOS')
    } else if (/linux/i.test(navigator.userAgent)) {
      setDevice('Desktop')
      setOS('Linux')
    }

    // Identifying Browser
    if (/edg\//i.test(navigator.userAgent)) {
      setBrowser('Edge')
    } else if (/chrom/i.test(navigator.userAgent)) {
      setBrowser('Chrome')
    } else if (/firefox/i.test(navigator.userAgent)) {
      setBrowser('Firefox')
    } else if (/safari/i.test(navigator.userAgent)) {
      setBrowser('Safari')
    }
  }, [userAgent])

  return { device, os, browser }
}

export default useDeviceInfo
