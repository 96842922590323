import { OrderWithPartialLines } from 'src/redux/reducers/orders'
import creditCardRequired from './creditCardRequired'
import { AccountFragment, Amount } from 'src/graphql/generated/graphql'

const creditCardNeeded = (
  order: OrderWithPartialLines,
  account: Pick<AccountFragment, 'stripeSource' | 'points' | 'expense'>,
  credits: Amount,
  isUsingCredits: boolean,
) =>
  creditCardRequired(order, account, credits, isUsingCredits) &&
  !account.stripeSource

export default creditCardNeeded
