import { Maybe } from '../../../__generated__/globalTypes'

import { StorefrontFragment, isStorefrontFragment } from './StorefrontFragment'

import minimalSponsorFragmentRawString from '../MinimalSponsorFragment.graphql'

export const minimalSponsorFragmentString = minimalSponsorFragmentRawString

export type MinimalSponsorFragment = {
  __typename: 'User'
  id: string
  firstName: string
  lastName: string
  email: string
  genealogyId: number
  storefront: Maybe<StorefrontFragment>
}

export const isMinimalSponsorFragment = (
  fragment: any,
): fragment is MinimalSponsorFragment =>
  fragment &&
  fragment.__typename === 'User' &&
  typeof fragment.id === 'string' &&
  typeof fragment.firstName === 'string' &&
  typeof fragment.lastName === 'string' &&
  typeof fragment.email === 'string' &&
  typeof fragment.genealogyId === 'number' &&
  (fragment.storefront === null ||
    (isStorefrontFragment(fragment.storefront) as boolean))
