import React from 'react'
import AvatarEditor from 'react-avatar-editor'
// @src imports
import { SimpleLoadingSpinner } from 'src/chrome'
import { useSelector, useState } from 'src/hooks'
// relative imports
import ImageControls from './ImageControls'
import ScaleSlider from './ScaleSlider'
import { ComputedElement, ComputedPanel } from '../../redux/selectors/editor'

import { Api } from '../api'
import styles from '../styles/components/_imageEditor.module.scss'
import { EditorImage, Position } from '../EditorCard'
import { IMAGE_SETTING_OPEN_VALUE } from '../constants'
import { Flex } from '@sendoutcards/quantum-design-ui'
import { Portal } from 'src/portal/portal'

interface Props {
  element: ComputedElement
  elementDisplayStyles: React.CSSProperties
  // if panel is undefined, we are editing a fullbleed background image
  panel?: ComputedPanel
  api: Api
}

const ImageElement: React.FC<Props> = props => {
  const { api, children, element, elementDisplayStyles, panel } = props
  const {
    step,
    updateElementImage,
    editPicture,
    editBackgroundPicture,
    setEditorDuvetOpenValue,
  } = api

  const [isLoaded, setIsLoaded] = useState(false)
  const [previousImage, setPreviousImage] = useState<EditorImage | undefined>(
    undefined,
  )

  const { isMobile } = useSelector(state => state.window)

  const setImagePosition = (position: Position) => {
    return updateElementImage(
      step.fullBleedIndex,
      panel?.location,
      element.location,
      image => ({
        ...image,
        position,
      }),
    )
  }

  const setImageScale = (scale: number) => {
    return updateElementImage(
      step.fullBleedIndex,
      panel?.location,
      element.location,
      image => ({
        ...image,
        scale,
        // when the scale changes to 1 from anything less than 1
        // it looks like it is fine on the editor, but it
        // is actually positioned wrong, this fix below forces it to center
        // if the user likes this position, it will be fine when
        // flattened, if they want to manually move it, it will still
        // be updated as usual
        position:
          scale >= 0.96 && scale <= 1.04 ? { x: 0.5, y: 0.5 } : image.position,
      }),
    )
  }

  const photoUrl = element.image?.image?.url

  const isUserImage = photoUrl && photoUrl.indexOf('user_images') > -1

  const handleSelectImage = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()

    if (element.locked) {
      return
    }

    if (panel) {
      editPicture(step.fullBleedIndex, panel.location, element.location, 'own')
    } else {
      editBackgroundPicture(step.fullBleedIndex, 'own')
    }
  }

  // Image changed since last render.
  if (element.image !== previousImage) {
    const currentImage = element.image

    if (
      previousImage &&
      currentImage &&
      ((previousImage.image &&
        currentImage.image &&
        previousImage.image.url !== currentImage.image.url) ||
        previousImage.filter !== currentImage.filter)
    ) {
      setIsLoaded(false)
    }

    setPreviousImage(currentImage ?? undefined)
  }

  const image = element.image
  const imageUrl = photoUrl || ''

  const defaultStyle: React.CSSProperties = {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: imageUrl ? 'transparent' : '#F7F7F7',
  }

  const style =
    panel && panel.location === 0 && panel.elements.length > 1 && element.locked
      ? // This allows for interaction with the layer behind this one.
        ({
          ...elementDisplayStyles,
          pointerEvents: 'none',
          cursor: 'none',
        } as React.CSSProperties)
      : elementDisplayStyles

  const shouldDisplayImageControls =
    !element.locked && !element.isSelected && panel

  return (
    <div
      style={panel ? style : { position: 'relative', top: 0, left: 0 }}
      className={element.isSelected ? styles.activeImage : ''}
      onClick={event => element.isSelected && event.stopPropagation()}
    >
      {shouldDisplayImageControls && !isUserImage && (
        <ImageControls
          id={`open_image_drawer_btn_${panel?.location ?? element.location}`}
          onClick={e => {
            setEditorDuvetOpenValue(IMAGE_SETTING_OPEN_VALUE)
            handleSelectImage(e)
          }}
        />
      )}
      {shouldDisplayImageControls && (
        <div
          style={
            {
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 101,
            } as React.CSSProperties
          }
          onClick={handleSelectImage}
        />
      )}
      {children}
      <AvatarEditor
        key={image && image.image ? image.image.id : undefined}
        image={image && image.filterUrl ? image.filterUrl : imageUrl}
        width={element.points.width}
        height={element.points.height}
        scale={(image && image.scale) ?? undefined}
        position={(image && image.position) ?? undefined}
        onPositionChange={setImagePosition}
        onImageReady={() => {
          setIsLoaded(true)
        }}
        crossOrigin=""
        rotate={0}
        border={0}
        style={
          {
            ...defaultStyle,
            opacity: isLoaded || !image?.image ? 1 : 0,
            transition: 'opacity 500ms',
          } as React.CSSProperties
        }
      />
      {!isLoaded && image && image.image && !element.locked && (
        <div className={styles.progressLoader}>
          <SimpleLoadingSpinner />
        </div>
      )}
      {element.isSelected && image && image.image && !isMobile && (
        <Portal attachToContainerId="main">
          <Flex
            position="absolute"
            top="75px"
            left="22px"
            backgroundColor="foreground"
            boxShadow="mediumDark"
            id="desktop-image-slider"
            height="auto"
            width="200px"
            inset="x1"
            borderRadius="default"
          >
            <ScaleSlider
              scale={image.scale}
              onChange={setImageScale}
              isMobile={true}
            />
          </Flex>
        </Portal>
      )}
    </div>
  )
}

export default ImageElement
