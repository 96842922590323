import { Currency } from '../../../__generated__/globalTypes'

import amountFragmentRawString from '../AmountFragment.graphql'

export const amountFragmentString = amountFragmentRawString

export type AmountFragment = {
  __typename: 'Amount'
  amount: number
  currency: Currency
  description: string
}

export const isAmountFragment = (fragment: any): fragment is AmountFragment =>
  fragment &&
  fragment.__typename === 'Amount' &&
  typeof fragment.amount === 'number' &&
  (fragment.currency === 'SOC' ||
    fragment.currency === 'USD' ||
    fragment.currency === 'UNAVAILABLE') &&
  typeof fragment.description === 'string'
