import { getPhoneNumberCountries } from 'src/legacy_graphql'
import useQueries from './useQueries'

export type PhoneNumberCountries = {
  __typename: 'PhoneNumberCountry'
  name: string
  isoCode: string
  callingCode: number
  hasSeparator?: boolean
}

const usePhoneNumberCountries = () => {
  const [phoneNumberCountries] = useQueries(getPhoneNumberCountries())

  const featuredCountries: PhoneNumberCountries[] = [
    {
      __typename: 'PhoneNumberCountry',
      callingCode: 1,
      isoCode: 'US',
      name: 'United States',
    },
    {
      __typename: 'PhoneNumberCountry',
      callingCode: 1,
      isoCode: 'CA',
      name: 'Canada',
    },
    {
      __typename: 'PhoneNumberCountry',
      callingCode: 61,
      isoCode: 'AU',
      name: 'Australia',
    },
    {
      __typename: 'PhoneNumberCountry',
      callingCode: 64,
      isoCode: 'NZ',
      name: 'New Zealand',
    },
    {
      __typename: 'PhoneNumberCountry',
      callingCode: 65,
      isoCode: 'SG',
      name: 'Singapore',
    },
    {
      __typename: 'PhoneNumberCountry',
      callingCode: 44,
      isoCode: 'GB',
      name: 'United Kingdom',
    },
    {
      __typename: 'PhoneNumberCountry',
      callingCode: 353,
      isoCode: 'IE',
      name: 'Ireland',
      hasSeparator: true,
    },
  ]

  const phoneNumberCountriesCollection = [
    ...featuredCountries,
    ...(phoneNumberCountries as PhoneNumberCountries[]),
  ]

  return phoneNumberCountriesCollection
}

export default usePhoneNumberCountries
