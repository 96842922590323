import { Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'

import { Icon, Icons } from 'src/chrome'

import styles from './tooltip.module.scss'

interface Props {
  id?: string
  message: string
  hoverIcon: Icons
  iconSize?: number
  iconColor?: string
  isIconPulsingAnimationEnabled?: boolean
  position?: 'top' | 'bottom' | 'left' | 'right'
  children?: React.ReactNode
}

const Tooltip: React.FC<Props> = props => {
  const {
    message,
    hoverIcon,
    iconSize,
    iconColor,
    children,
    isIconPulsingAnimationEnabled = false,
    position = 'top',
    id,
  } = props
  return (
    <div id={id} className={styles.container}>
      <span
        className={
          isIconPulsingAnimationEnabled
            ? `${styles.pulse} ${styles.icon}`
            : styles.icon
        }
      >
        <Icon icon={hoverIcon} size={iconSize || 15} color={iconColor} />
      </span>
      <div className={`${styles.tooltip} ${styles[position]}`}>
        <Text type="footnote" color="accent">
          {message}
        </Text>
        {children}
      </div>
    </div>
  )
}

export default Tooltip
