import { CardFragment } from 'src/graphql/generated/graphql'
import { useEffect, useState } from 'src/hooks'
import { didCardFlattenFail } from '../helpers'

const useDidFlattenFail = (card?: CardFragment) => {
  const [didFlattenFail, setDidFlattenFail] = useState(false)

  useEffect(() => {
    ;(async () => {
      if (card) {
        const didIt = await didCardFlattenFail(card)
        setDidFlattenFail(didIt)
      }
    })()
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  return {
    didFlattenFail,
  }
}

export default useDidFlattenFail
