import React from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import { Button, Icon } from 'src/chrome'
import { noop } from 'src/helpers/appHelpers'

import styles from './cardHistoryActions.module.scss'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Typography from '@material-ui/core/Typography'
import { useState } from 'src/hooks'

const mobileMenuItemsStyles: React.CSSProperties = {
  marginLeft: 15,
  color: 'black',
}
const pink = '#ff5689'
const disabled = '#6f8394'
const iconSize = 20

interface Props {
  isSelectAllChecked: boolean
  count: number
  onRetryFailed?: () => void
  onCancel?: () => void
  onSelectAll: () => void
}

const CardHistoryActions: React.FC<Props> = props => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isOpen = Boolean(anchorEl)
  const {
    isSelectAllChecked,
    count,
    onSelectAll,
    onCancel,
    onRetryFailed,
  } = props

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget)

  const handleMenuClose = () => setAnchorEl(null)

  return (
    <div className={styles.container}>
      <div className={styles.desktop}>
        {onCancel && (
          <Button
            id={'cancel_cards_card_history'}
            title={`Cancel ${count} Card${count > 1 ? 's' : ''}`}
            buttonColor={'pink'}
            style={{ marginRight: 15 }}
            onClick={onCancel}
          />
        )}
        {onRetryFailed && (
          <Button
            key={2}
            id={'send_paused_cards'}
            title={'Send Immediately'}
            buttonColor={'pink'}
            onClick={onRetryFailed}
          />
        )}
      </div>
      <div className={styles.mobile}>
        <IconButton
          aria-label="more"
          aria-controls="icon-menu"
          aria-haspopup="true"
          onClick={handleMenuClick}
          id="more_actions_btn"
        >
          <MoreVertIcon fontSize="large" />
        </IconButton>
        <Menu
          id="icon-menu"
          anchorEl={anchorEl}
          keepMounted={true}
          open={isOpen}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          onClose={handleMenuClose}
          PaperProps={{
            style: {
              width: 200,
            },
          }}
        >
          <MenuItem id="more_select_all_btn" key={1} onClick={onSelectAll}>
            <ListItemIcon>
              <Icon
                icon={'CHECKMARK'}
                color={pink}
                size={iconSize}
                viewBox={'0 0 1456 800'}
              />
            </ListItemIcon>
            <Typography style={mobileMenuItemsStyles} variant="inherit">
              {isSelectAllChecked ? 'Deselect All' : 'Select All'}
            </Typography>
          </MenuItem>
          <MenuItem
            id="cancel_card_btn"
            key={2}
            onClick={onCancel ? () => onCancel() : noop}
          >
            <ListItemIcon>
              <Icon
                icon={'TRASH'}
                color={onCancel ? pink : disabled}
                size={iconSize}
              />
            </ListItemIcon>
            <Typography style={mobileMenuItemsStyles} variant="inherit">
              Cancel Card
            </Typography>
          </MenuItem>
          <MenuItem id="send_immediately" key={3} onClick={onRetryFailed}>
            <ListItemIcon>
              <Icon
                icon={'SEND'}
                color={onRetryFailed ? disabled : pink}
                size={iconSize}
              />
            </ListItemIcon>
            <Typography style={mobileMenuItemsStyles} variant="inherit">
              Send Immediately
            </Typography>
          </MenuItem>
        </Menu>
      </div>
    </div>
  )
}

export default CardHistoryActions
