import { Operation } from '../../../__generated__/globalTypes'

import {
  FlagFragment,
  flagFragmentString,
} from '../../fragments/__generated__/FlagFragment'

import getFlagsRawString from '../GetFlags.graphql'

const getFlagsString = [getFlagsRawString, flagFragmentString].join('\n\n')

export type GetFlagsData = {
  flags: FlagFragment[]
}

export type GetFlags = Operation<GetFlagsData>

export const GetFlags = (): GetFlags => ({
  query: getFlagsString,
  variables: undefined,
})
