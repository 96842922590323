import { Flex } from '@sendoutcards/quantum-design-ui'
import React, { FC } from 'react'
import { FeatureItem, FeatureItemProps } from './FeatureItem'
import { useSelector } from 'src/hooks'

type FeatureChartProps = {
  columnOne: FeatureItemProps[]
  columnTwo: FeatureItemProps[]
}

export const FeatureItemChart: FC<FeatureChartProps> = ({
  columnOne,
  columnTwo,
}) => {
  const windowWidth = useSelector(state => state.window.width)
  const isMobile = windowWidth <= 1000
  const width = isMobile ? '100%' : '50%'
  return (
    <Flex
      justifyContent={isMobile ? 'center' : 'space-between'}
      alignItems={'center'}
      inset={{ horizontal: 'x2_5', vertical: 'x3' }}
      style={{ backgroundColor: 'rgba(184, 184, 184, 0.14)' }}
      borderRadius={'medium'}
      columnGap="x1"
      flexDirection={isMobile ? 'column' : 'row'}
      flexWrap={isMobile ? 'wrap' : 'nowrap'}
    >
      <Flex width={width} flexDirection="column">
        {columnOne.map((feature, index) => {
          return <FeatureItem key={index} {...feature} />
        })}
      </Flex>
      <Flex width={width} flexDirection="column">
        {columnTwo.map((feature, index) => {
          return <FeatureItem key={index} {...feature} />
        })}
      </Flex>
    </Flex>
  )
}
