import { Maybe } from '../../../__generated__/globalTypes'

import { ContactFragment, isContactFragment } from './ContactFragment'

import {
  RelatedContactFragment,
  isRelatedContactFragment,
} from './RelatedContactFragment'

import childContactFragmentRawString from '../ChildContactFragment.graphql'

export const childContactFragmentString = childContactFragmentRawString

export type ChildContactFragment = ContactFragment & {
  parent: Maybe<RelatedContactFragment>
  homePhone: Maybe<string>
  cellPhone: Maybe<string>
  workPhone: Maybe<string>
  faxNumber: Maybe<string>
  website: string
  emailAddress: Maybe<string>
}

export const isChildContactFragment = (
  fragment: any,
): fragment is ChildContactFragment =>
  (isContactFragment(fragment) as boolean) &&
  (fragment.parent === null ||
    (isRelatedContactFragment(fragment.parent) as boolean)) &&
  (fragment.homePhone === null || typeof fragment.homePhone === 'string') &&
  (fragment.cellPhone === null || typeof fragment.cellPhone === 'string') &&
  (fragment.workPhone === null || typeof fragment.workPhone === 'string') &&
  (fragment.faxNumber === null || typeof fragment.faxNumber === 'string') &&
  typeof fragment.website === 'string' &&
  (fragment.emailAddress === null || typeof fragment.emailAddress === 'string')
