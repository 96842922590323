import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import range from 'lodash/range'

import { Icon } from 'src/chrome'
import { Flex, Text } from '@sendoutcards/quantum-design-ui'

interface PaginationProps {
  onPageChange: (page: number) => void
  count: number
  pagesToShow: number
  pageSize: number
  currentPage: number
}

const Pagination = (props: PaginationProps) => {
  const { onPageChange, count, pageSize, currentPage, pagesToShow } = props

  const iconColor = '#6F8394'

  const pagesCount = Math.ceil(count / pageSize)
  const lowerBound =
    Math.floor((currentPage - 1) / pagesToShow) * pagesToShow + 1
  const buttonList = range(
    lowerBound,
    Math.min(lowerBound + pagesToShow, pagesCount + 1),
  )

  const handleRangeChange = (type: 'previous' | 'next') =>
    onPageChange(
      type === 'previous'
        ? buttonList[0] - 1
        : buttonList[buttonList.length - 1] + 1,
    )

  const handleBoundary = (direction: 'first' | 'last') =>
    direction === 'first' ? onPageChange(1) : onPageChange(pagesCount)

  const handlePreviousClick = () => onPageChange(currentPage - 1)
  const handleNextClick = () => onPageChange(currentPage + 1)

  if (pagesCount === 0) {
    return null
  }

  return (
    <Flex alignItems="first baseline" inset="x1">
      <Tooltip title="Go to first">
        <IconButton
          disabled={props.currentPage === 1}
          onClick={() => handleBoundary('first')}
        >
          <Icon icon={'CHEVRONLEFT'} color={iconColor} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Previous">
        <IconButton
          disabled={props.currentPage === 1}
          onClick={handlePreviousClick}
        >
          <Icon icon={'CARROTLEFT'} color={iconColor} />
        </IconButton>
      </Tooltip>
      {buttonList.indexOf(1) < 0 && (
        <Button onClick={() => handleRangeChange('previous')}>
          <Text type="body">. . .</Text>
        </Button>
      )}
      {buttonList.map(page => (
        <div key={page}>
          <Button onClick={() => onPageChange(page)}>
            <Text
              type="body"
              color={
                page === props.currentPage ? 'primaryBrand' : 'primaryBody'
              }
              weight={page === props.currentPage ? 'bold' : 'regular'}
            >
              {page}
            </Text>
          </Button>
        </div>
      ))}
      {buttonList.indexOf(pagesCount) < 0 && (
        <Button onClick={() => handleRangeChange('next')}>
          <Text type="body">. . .</Text>
        </Button>
      )}
      <Tooltip title="Next">
        <IconButton
          onClick={handleNextClick}
          disabled={props.currentPage === pagesCount}
        >
          <Icon icon={'CARROTRIGHT'} color={iconColor} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Go to end">
        <IconButton
          disabled={props.currentPage === pagesCount}
          onClick={() => handleBoundary('last')}
        >
          <Icon icon={'CHEVRONRIGHT'} color={iconColor} />
        </IconButton>
      </Tooltip>
    </Flex>
  )
}

export default Pagination
