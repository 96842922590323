// This is where you can modify the Table's titles, or add/remove features
export enum FeatureType {
  ISF = 'Initial Subscription Fee',
  OMSF = 'Ongoing Monthly Subscription Fee',
  HPC = 'Heartfelt Prompting Cards',
  SC = 'System Cards',
  AC = 'Additional Cards',
  PC = 'Postcards',
  DSOG = 'Discount on Gifts',
  SOS = 'Savings on Shipping',
  MGF = 'Months Good For',
  CSFD = 'Campaign Store Free Downloads',
  CM = 'Contact Manager',
  PS = 'Premium Storage',
}

export enum ActivePlan {
  BASIC = 'Basic Plan',
  HRFLT = 'Heartfelt Subscription',
  HRFLT_PLUS = 'Heartfelt Plus Subscription',
  HRFLT_PLUS_GIFT = 'Heartfelt Plus Gifts Subscription',
  CRD_PKG_100 = '100 Card Package',
  CRD_PKG_250 = '250 Card Package',
  CRD_PKG_500 = '500 Card Package',
  CRD_PKG_1000 = '1000 Card Package',
  CRD_PKG_2000 = '2000 Card Package',
}

export enum LegacyPlan {
  LGCY_980 = '$9.80 Legacy Subscription',
  LGCY_39 = '$39 Legacy Subscription',
}

export type MainContent =
  | {
      type: 'stamps_included' | 'stamps_excluded'
      content: string
    }
  | ('included' | 'excluded' | (string & Record<never, never>))

export type FeatureContent = {
  main: MainContent
  secondary?: {
    content: string
    info?: string // Not sure if this is supposed to give additional context or link to external page OR just shows up when secondary is there. If so remove this
  }
}

export type FeatureSet = {
  [key in FeatureType]: FeatureContent
}

export type PlanData<T> = {
  id: string
  name: T
  sku: string
  tableFeatures: FeatureSet
  tableHeadingBgColor?: string
  card: {
    price: string
    features: string[]
    incentive?: {
      title: string
      subtitle: string
      icon: string // Make this a specific Icon name?
    }
  }
}

export type ActivePlans = {
  [key in ActivePlan]: PlanData<key>
}
export type LegacyPlans = {
  [key in LegacyPlan]: PlanData<key>
}
