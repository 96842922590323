import { Operation } from '../../../__generated__/globalTypes'

import {
  PhoneNumberCountryFragment,
  phoneNumberCountryFragmentString,
} from '../../fragments/__generated__/PhoneNumberCountryFragment'

import getPhoneNumberCountriesRawString from '../GetPhoneNumberCountries.graphql'

const getPhoneNumberCountriesString = [
  getPhoneNumberCountriesRawString,
  phoneNumberCountryFragmentString,
].join('\n\n')

export type GetPhoneNumberCountriesData = {
  phoneNumberCountries: PhoneNumberCountryFragment[]
}

export type GetPhoneNumberCountries = Operation<GetPhoneNumberCountriesData>

export const GetPhoneNumberCountries = (): GetPhoneNumberCountries => ({
  query: getPhoneNumberCountriesString,
  variables: undefined,
})
