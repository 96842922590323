import { Maybe, Operation } from '../../../__generated__/globalTypes'

import bulkDeleteContactsString from '../BulkDeleteContacts.graphql'

export type BulkDeleteContactsData = {
  bulkDeleteContacts: {
    successful: boolean
    failedCount: Maybe<number>
  }
}

export type BulkDeleteContactsVariables = {
  contacts: string[]
}

export type BulkDeleteContacts = Operation<
  BulkDeleteContactsData,
  BulkDeleteContactsVariables
>

export const BulkDeleteContacts = ({
  contacts,
}: BulkDeleteContactsVariables): BulkDeleteContacts => ({
  query: bulkDeleteContactsString,
  variables: {
    contacts,
  },
})
