import React from 'react'
import { Flex, Spacer, Text, TextType } from '@sendoutcards/quantum-design-ui'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { IconType } from 'src/design_system/atoms/icons/types'
type TagType = {
  icon: IconType
  iconColor: string
  title: string
  titleSize?: TextType
  titleColor?: string
}
type ThemeType = 'light' | 'dark'

export type CallToActionCardProps = {
  theme?: ThemeType // Determines the content section color scheme
  tag: TagType
  title: string
  titleSize?: TextType
  caption?: string
  captionSize?: TextType
  graphic: React.ReactNode
  graphicBackground?: { color: string; image?: string } // Use this if you need to customize the graphic containers background
  graphicOrder?: 1 | 2
  graphicPadding?: string
  isStacked?: boolean
  isContentCentered?: boolean
  actions?: React.ReactNode
  maxWidth?: string
  onClick?: () => void
}
const ActionTag: React.FC<TagType> = ({
  icon,
  iconColor,
  title,
  titleColor,
  titleSize = 'caption',
}) => {
  return (
    <Flex alignItems="center" style={{ gap: '8px' }}>
      <Icon size={18} name={icon} color={iconColor} />
      <Text
        weight="regular"
        type={titleSize}
        content={title}
        style={{ color: titleColor, letterSpacing: '.3px' }}
      />
    </Flex>
  )
}
const CallToActionCard: React.FC<CallToActionCardProps> = ({
  theme = 'light',
  tag,
  title,
  titleSize = 'subtitle',
  caption,
  captionSize = 'body',
  graphicBackground = {
    image: '',
    color: '#FAFAFA',
  },
  graphicOrder = 1,
  graphicPadding,
  isStacked,
  actions,
  graphic,
  maxWidth,
  onClick,
  isContentCentered,
}) => {
  const DARK = '#383838'
  const LIGHT = '#FFF'
  const themes = {
    dark: {
      background: DARK,
      title: LIGHT,
      copy: '#FAFAFA',
    },
    light: {
      background: LIGHT,
      title: DARK,
      copy: '#757D8B',
    },
  }
  const appliedTheme = themes[theme]
  const containerWidth = isStacked ? '100%' : '50%'
  const isGraphicSecond = graphicOrder > 1
  return (
    <Flex
      width="100%"
      borderRadius={'default'}
      overflow="hidden"
      flexDirection={isStacked ? 'column' : 'row'}
      maxWidth={maxWidth}
      cursor="pointer"
      onClick={onClick}
    >
      <Flex
        width={containerWidth}
        inset={'x3'}
        flexDirection="column"
        justifyContent="center"
        backgroundColor={appliedTheme.background}
        order={isGraphicSecond ? 2 : 1}
        alignItems={isContentCentered ? 'center' : 'initial'}
      >
        <ActionTag
          icon={tag.icon}
          iconColor={tag.iconColor}
          title={tag.title}
          titleColor={appliedTheme.title}
        />
        <Spacer space="x1_5" />
        <Text
          type={titleSize}
          content={title}
          alignment={isContentCentered ? 'center' : 'left'}
          weight="bold"
          style={{ color: appliedTheme.title, letterSpacing: '.3px' }}
        />
        {caption && (
          <Text
            alignment={isContentCentered ? 'center' : 'left'}
            type={captionSize}
            content={caption}
            style={{
              color: appliedTheme.copy,
              letterSpacing: '.15px',
              marginTop: '8px',
              marginBottom: '16px',
              lineHeight: 1.6,
            }}
          />
        )}

        {actions && <Flex outset={{ top: 'x2' }}>{actions}</Flex>}
      </Flex>
      <Flex
        width={containerWidth}
        height={'100%'}
        style={{
          backgroundImage: `url(${graphicBackground.image})`,
          backgroundColor: graphicBackground.color,
          backgroundSize: 'cover',
        }}
        inset={graphicPadding ? graphicPadding : { vertical: 'x3' }}
        overflow="hidden"
        justifyContent={'flex-start'}
        rowGap="x3"
        order={isGraphicSecond ? 1 : 2}
      >
        {graphic}
      </Flex>
    </Flex>
  )
}
export default CallToActionCard
