import React from 'react'
import {
  useAccount,
  useActions,
  useDebounce,
  useEmailValidation,
  useState,
} from 'src/hooks'
import {
  Anchor,
  Button,
  Dialog,
  Flex,
  Input,
  Spacer,
  Text,
  VStack,
} from '@sendoutcards/quantum-design-ui'
import { Label } from 'src/graphql/generated/graphql'
import { VerifyEmail } from '../VerifyEmail/VerifyEmail'
import getEmailVerificationLabel from 'src/helpers/getEmailVerificationLabel'
import { queryKeyStore } from 'src/react_query'
import { useQueryClient } from '@tanstack/react-query'

enum Steps {
  Main,
  VerifyEmail,
  ChangeEmail,
  Finished,
}

export type EmailVerificationType =
  | Label.HasDuplicateEmail
  | Label.HasInvalidEmail
  | Label.HasDuplicateEmailClaimed

type DuplicateOrInvalidEmailBlockerProps = {
  type: EmailVerificationType
  email: string
  onSkip?: () => void
}

export const DuplicateOrInvalidEmailBlocker: React.FC<DuplicateOrInvalidEmailBlockerProps> = ({
  type,
  email,
  onSkip,
}) => {
  const [step, setStep] = useState<Steps>(
    type === Label.HasDuplicateEmail ? Steps.Main : Steps.ChangeEmail,
  )
  const [newEmail, setNewEmail] = useState('')

  const actions = useActions()
  const account = useAccount()
  const debouncedEmail = useDebounce(newEmail, 500)
  const emailValidation = useEmailValidation(debouncedEmail)
  const queryClient = useQueryClient()

  const MainContext = () => {
    const getCopy = () => {
      if (type === Label.HasDuplicateEmail) {
        return (
          <VStack gap="x1">
            <Text
              outset={{ top: 'x4' }}
              type="body"
              content={`Hello ${account?.firstName}!  An account with this email address already exists. Click the "Already a User" link to login to that existing account, request a password, or enter a different email address above to join.`}
            />
            <Text
              type="body"
              content="If you have any questions, please contact our Customer Support team at 801-463-3800"
            />
          </VStack>
        )
      }
      if (type === Label.HasInvalidEmail) {
        return (
          <VStack gap="x1">
            <Text
              outset={{ top: 'x4' }}
              type="body"
              content={`Hello ${account?.firstName}! Our records indicate an issue with the email address in your account. To ensure you are receiving the latest account updates and purchase confirmations, please change the following email address: ${email}`}
            />
            <Text
              type="body"
              content="Verification of the updated email address will need to be completed by January 1, 2023."
            />
            <Text
              type="body"
              content="You may choose to opt out of receiving marketing emails from your account settings."
            />
          </VStack>
        )
      }
      if (type === Label.HasDuplicateEmailClaimed) {
        return (
          <VStack gap="x1">
            <Text
              type="body"
              content={`Hello ${account?.firstName}!  The email address you have entered in your account has been verified by another account/user. For security reasons, we require unique email addresses on each account. Please enter a new, valid email address for this account.`}
            />
            <Text
              type="body"
              content="Verification of the updated email address will need to be completed by January 1, 2023."
            />
            <Text
              type="body"
              content="If your email address has been fraudulently claimed/verified by another user, please contact our Customer Support team at 801-463-3800."
            />
          </VStack>
        )
      }
      return undefined
    }
    return (
      <VStack gap="x2" outset={{ bottom: 'x3' }}>
        {getCopy()}
        {onSkip && (
          <Anchor
            title="Skip This For Now"
            size="xSmall"
            isDecorated={true}
            position="absolute"
            bottom="16px"
            left="50%"
            transform={'translate(-50%, 0%)'}
            onClick={onSkip}
          />
        )}
      </VStack>
    )
  }

  return (
    <Dialog
      height="fit-content"
      closeButtonId="email_verification_dialog_close_button"
      isOpen={true}
      width="100vh"
      maxWidth="580px"
    >
      {type === Label.HasDuplicateEmail && step !== Steps.Main && (
        <Flex position="absolute" top="x2" left="x3">
          <Anchor
            title=" < Back"
            onClick={() => {
              setStep(Steps.Main)
            }}
          />
        </Flex>
      )}
      <Flex
        minHeight="360px"
        width="100%"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        {(step === Steps.ChangeEmail || step === Steps.Main) && <MainContext />}
        {step === Steps.ChangeEmail ? (
          <Flex
            backgroundColor="foreground"
            borderRadius="default"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            width="100%"
          >
            <Text
              alignment="center"
              type="body"
              weight="semiBold"
              content={
                type === Label.HasDuplicateEmail
                  ? 'Enter Your Preferred Email Address'
                  : type === Label.HasInvalidEmail
                  ? 'Enter a Valid Email Address'
                  : 'Enter a New Email Address'
              }
            />
            <Flex
              bottom="x8"
              justifyContent="center"
              alignContent="center"
              flexDirection="column"
              width="90%"
              outset={{ top: 'x2' }}
            >
              <Input
                icon="envelope"
                placeholder="Email"
                onChange={setNewEmail}
                value={newEmail}
                type={'text'}
                isFullWidth={true}
                isLoading={emailValidation.isLoading}
                message={
                  emailValidation.errorMessage
                    ? {
                        type: 'danger',
                        content: emailValidation.errorMessage,
                      }
                    : undefined
                }
              />

              <Flex
                justifyContent="center"
                outset={{ top: 'x2', bottom: onSkip ? 'x2' : undefined }}
              >
                <Button
                  title={'Verify New Email Address'}
                  onClick={() => setStep(Steps.VerifyEmail)}
                  disabled={!emailValidation.isValid}
                  fullWidth={true}
                />
              </Flex>
            </Flex>
          </Flex>
        ) : step === Steps.VerifyEmail ? (
          <Flex
            backgroundColor="foreground"
            borderRadius="default"
            justifyContent="space-evenly"
            alignItems="center"
            flexDirection="column"
            inset={{ vertical: 'x1_5' }}
          >
            <VerifyEmail
              email={emailValidation.isValid ? newEmail : email}
              onComplete={verifiedEmail => {
                actions.updatedAccount({
                  ...account,
                  email: verifiedEmail,
                })
                setStep(Steps.Finished)
              }}
            />
          </Flex>
        ) : step === Steps.Main ? (
          <Flex alignItems="center" justifyContent="center">
            <Flex
              height="100%"
              flexDirection="column"
              width="100%"
              alignItems="center"
              justifyContent="center"
            >
              {type !== Label.HasDuplicateEmailClaimed && (
                <VStack gap="x2">
                  <Text
                    type="body"
                    content="The following email address is listed in your account:"
                  />
                  <Text
                    weight="bold"
                    outset={{ bottom: 'x3' }}
                    type="body"
                    content={email}
                  />
                </VStack>
              )}
              <Flex
                flexDirection="column"
                width="100%"
                outset={{ bottom: 'x3' }}
                justifyContent="center"
                alignItems="center"
              >
                {type !== Label.HasDuplicateEmailClaimed && (
                  <Flex outset={{ bottom: 'x2' }}>
                    <Button
                      fullWidth={true}
                      id="confirm_email_verification_btn"
                      size="medium"
                      title="Verify Email"
                      onClick={() => setStep(Steps.VerifyEmail)}
                    />
                  </Flex>
                )}
                <Flex>
                  <Anchor
                    alignItems="center"
                    alignContent="center"
                    isDecorated={true}
                    width="100%"
                    title="Change Email"
                    onClick={() => setStep(Steps.ChangeEmail)}
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        ) : (
          <Flex
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Text type="title" content="Thank you!" />
            <Spacer space="x2" />
            <Text
              alignment="center"
              type="body"
              content={
                'Your email address has been verified and updated in your account.'
              }
            />
            <Spacer space="x2" />
            <Button
              outlined={true}
              type="shadow"
              title="Close and Continue"
              onClick={() => {
                // React query cache invalidation
                queryClient.invalidateQueries(queryKeyStore.account.detail)
                actions.updatedAccount({
                  ...account,
                  labels: account.labels.filter(
                    label => !getEmailVerificationLabel([label]),
                  ),
                })
              }}
            />
          </Flex>
        )}
      </Flex>
    </Dialog>
  )
}
