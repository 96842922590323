import { ActionCreator } from '@sendoutcards/core'
import Result from 'src/utils/Result'
import { GraphQLObject, Query } from 'src/legacy_graphql/Query'
import { NormalizedData, Operation } from 'src/legacy_graphql'
import { Set } from 'immutable'

export const prioritizedQueries = ActionCreator(
  'PRIORITIZED_QUERIES',
  (queries: Query<GraphQLObject, unknown>[], increment: number = 1) => ({
    queries,
    increment,
  }),
)

export const deprioritizedQueries = ActionCreator(
  'DEPRIORITIZED_QUERIES',
  (queries: Query<GraphQLObject, unknown>[], decrement: number = 1) => ({
    queries,
    decrement,
  }),
)

export const invalidatedQueries = ActionCreator(
  'INVALIDATED_QUERIES',
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  (...queries: Query<any, any>[]) => ({ queries }),
)

export const invalidatedQueriesByName = ActionCreator(
  'INVALIDATED_QUERIES_BY_NAME',
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  (...queryNames: string[]) => ({ queryNames }),
)

export const completedQuery = ActionCreator(
  'COMPLETED_QUERY',
  (
    operation: Operation<unknown, unknown>,
    offset: number | null | undefined,
    limit: number | null | undefined,
    result: Result<GraphQLObject>,
  ) => ({
    operation,
    offset,
    limit,
    result,
  }),
)

export const loadMore = ActionCreator(
  'LOAD_MORE',
  (operation: Operation<unknown, unknown>) => ({ operation }),
)

export type DeletedResources = {
  [typename: string]: Set<string> | undefined
}

type StrictOrPartial = 'Strict' | 'Partial'

type NormalizedResourceType<
  Resource,
  Strictness extends StrictOrPartial
> = Resource extends {
  __typename: infer Typename
}
  ? Strictness extends 'Strict'
    ? { [Key in keyof Resource]-?: Exclude<Resource[Key], undefined> }
    : Partial<Resource> & {
        __typename: Typename
        id: string
      }
  : never

export type NormalizedResource<
  Strictness extends StrictOrPartial = 'Partial'
> = Exclude<
  NormalizedResourceType<
    NormalizedData[keyof NormalizedData][string],
    Strictness
  >,
  undefined
>

export type StrictNormalizedResource = NormalizedResource<'Strict'>

export const startedBlockingMutation = ActionCreator(
  'STARTED_BLOCKING_MUTATION',
  (transitionMessage: string) => ({ transitionMessage }),
)

export const scheduledOptimisticMutation = ActionCreator(
  'SCHEDULED_OPTIMISTIC_MUTATION',
  (
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    operation: Operation<any, any>,
    createdResources: StrictNormalizedResource[],
    updatedResources: NormalizedResource[],
    deletedResources: DeletedResources,
    failureMessage: string,
  ) => ({
    operation,
    createdResources,
    updatedResources,
    deletedResources,
    failureMessage,
  }),
)

export const mutationSucceeded = ActionCreator(
  'MUTATION_SUCCEEDED',
  (
    data: GraphQLObject,
    didCreateResources: boolean,
    deletedResources: DeletedResources,
    wasOptimistic: boolean,
    shouldBlockGatheringNormalizedData?: boolean,
  ) => ({
    data,
    didCreateResources,
    deletedResources,
    wasOptimistic,
    shouldBlockGatheringNormalizedData,
  }),
)

export const mutationFailed = ActionCreator(
  'MUTATION_FAILED',
  (wasOptimistic: boolean, failureMessage: string) => ({
    wasOptimistic,
    failureMessage,
  }),
)
