import React from 'react'
// @src imports
import {
  selectedColor,
  selectedFontId,
  selectedFontSize,
  selectedTextAlign,
} from 'src/redux/selectors/text'
import SignatureList from 'src/editor/components/SignatureList/SignatureList'
import FontList from 'src/editor/components/FontList/FontList'
import { useDeviceInfo, useState } from 'src/hooks'
import { defaultTextSelection } from 'src/editor/text/types'
import { defaultFragment } from 'src/editor/text/ElementText'
import { textEditor } from 'src/helpers'
// relative imports
import ColorPicker from '../ColorPicker/ColorPicker'
import FontSizeSlider from '../FontSizeSlider/FontSizeSlider'
import InsertNames from '../InsertNames/InsertNames'
import TextAlignmentComponent from '../TextAlignment/TextAlignment'
import TextBarMobile, {
  TextBarMobileItems,
} from '../TextBarMobile/TextBarMobile'
import { Api } from '../../api'
import { Steps } from '../../types'
import {
  Button,
  Dialog,
  Div,
  Flex,
  Icon,
  Separator,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'

import styles from './minimalTextOptions.module.scss'
import { Portal } from 'src/portal/portal'
import { TextAlignment } from 'src/graphql/generated/graphql'

type ControlName =
  | 'Alignment'
  | 'Size'
  | 'Font'
  | 'Color'
  | 'Name Tag'
  | 'Signature'
  | 'None'

type Props = {
  api: Api & { step: { type: Steps.EditText } }
  closeDuvet?: () => void
}

const MinimalTextOptions: React.FC<Props> = props => {
  const { api, closeDuvet } = props

  const deviceInfo = useDeviceInfo()
  const isAndroidChrome =
    deviceInfo.os === 'Android' && deviceInfo.browser === 'Chrome'
  const colorPickerMaxHeight = window.innerHeight - 200 < 580 ? 300 : undefined

  const selectedElement = api.getSelectedElement(api.step)
  const textState = {
    selection: api.step.textSelection ?? defaultTextSelection,
    text: selectedElement.text ?? [defaultFragment],
  }

  const items: TextBarMobileItems<ControlName>[] = [
    {
      name: 'Alignment',
      icon: 'alignCenter',
      size: 'small',
      type: 'caption',
    },
    {
      name: 'Size',
      icon: 'size',
      size: 'small',
      type: 'caption',
    },
    {
      name: 'Font',
      icon: 'editorText',
      size: 'small',
      type: 'caption',
    },
    {
      name: 'Color',
      icon: 'background',
      size: 'small',
      type: 'caption',
    },
    {
      name: 'Signature',
      icon: 'signaturePen',
      size: 'small',
      type: 'caption',
    },
    ...(!isAndroidChrome
      ? [
          {
            name: 'Name Tag',
            icon: 'userTag',
            size: 'small',
            type: 'caption',
          } as TextBarMobileItems<ControlName>,
        ]
      : []),
  ]

  const font = [...api.fonts.fonts, ...api.fonts.signatures]

  const textAlign = selectedTextAlign(textState)
  const fontSize = selectedFontSize(textState)
  const fontId = selectedFontId(textState)
  const color = selectedColor(textState)
  const utils = textEditor(api.updateText, api.step, api.isMobile)

  const currentFont = font.length
    ? font.find(_ => _.id === fontId) || font[0]
    : {
        id: 'f631775b-a440-48f7-8115-bbfb0124728f',
        fontName: 'HelveticaV2',
        displayName: 'Arial',
      }

  const rgbToHex = (r: number, g: number, b: number) =>
    '#' + [r, g, b].map(x => x.toString(16).padStart(2, '0')).join('')

  const defaultColor = color
    ? rgbToHex(color.red, color.green, color.blue)
    : '#fff'

  const [activeControl, setActiveControl] = useState<ControlName>('None')

  const onOkAndClose = () => {
    if (isAndroidChrome && api.mobileTextAreaRef.current) {
      utils.replaceAllText(
        api.mobileTextAreaRef.current?.value,
        api.mobileTextElementSigId === 'removeSignature'
          ? undefined
          : api.mobileTextElementSigId,
      )
      api.setMobileTextElementSigid(undefined)
    }
    closeDuvet?.()
  }

  return (
    <div className={styles.minimalTextBar}>
      <TextBarMobile
        tool={activeControl}
        onClick={activeControl =>
          setActiveControl(activeControl as ControlName)
        }
        items={items}
      />
      <Separator orientation="horizontal" />
      <Spacer space="x4" />
      <Button
        title="Ok"
        onClick={onOkAndClose}
        type="primary"
        fullWidth={true}
        id="close_mobile_text_editor_btn"
      />
      <div>
        {activeControl === 'Alignment' && (
          <Portal attachToContainerId="app">
            <Dialog
              isOpen={activeControl === 'Alignment'}
              onClose={() => setActiveControl('None')}
              insetOverride="x4"
              zIndex={1001}
            >
              <Text type="body" weight="semiBold">
                Alignment
              </Text>
              <Flex>
                <Text
                  alignment="center"
                  type="footnote"
                  content="Select your alignment:"
                  whiteSpace="nowrap"
                  color="primaryBody"
                />
                <Text
                  type="body"
                  color="primaryHeading"
                  content={textAlign}
                  alignment="center"
                  lineHeight={1.3}
                  inset={{ left: 'x_5' }}
                />
              </Flex>
              <Separator orientation="horizontal" />
              <Flex
                width="100%"
                justifyContent="center"
                alignItems="center"
                height="100px"
              >
                <TextAlignmentComponent
                  textAlign={textAlign}
                  selectTextAlign={(textAlign: TextAlignment) => {
                    utils.updateTextAlign(textAlign)
                    setActiveControl('None')
                  }}
                />
              </Flex>
            </Dialog>
          </Portal>
        )}
        {activeControl === 'Size' && (
          <Portal attachToContainerId="app">
            <Dialog
              isOpen={activeControl === 'Size'}
              onClose={() => setActiveControl('None')}
              insetOverride="x4"
              zIndex={1001}
            >
              <Text type="body" weight="semiBold">
                Size
              </Text>
              <Flex>
                <Text
                  alignment="center"
                  type="footnote"
                  content="Select your font size:"
                  whiteSpace="nowrap"
                  color="primaryBody"
                />
                <Text
                  type="largeBody"
                  color="primaryHeading"
                  content={`${fontSize}px`}
                  alignment="center"
                  lineHeight={1}
                  inset={{ left: 'x_5' }}
                />
              </Flex>
              <Separator orientation="horizontal" />
              <Flex
                width="100%"
                justifyContent="center"
                alignItems="center"
                height="100px"
              >
                <FontSizeSlider
                  fontSize={fontSize}
                  selectFontSize={fontSize =>
                    utils.updateProperties({ fontSize })
                  }
                />
              </Flex>
            </Dialog>
          </Portal>
        )}
        {activeControl === 'Font' && (
          <Portal attachToContainerId="app">
            <Dialog
              isOpen={activeControl === 'Font'}
              onClose={() => setActiveControl('None')}
              insetOverride="x4"
              zIndex={1001}
            >
              <Text type="body" weight="semiBold">
                Font
              </Text>
              <Flex>
                <Text
                  alignment="center"
                  type="footnote"
                  content="Select your font:"
                  whiteSpace="nowrap"
                  color="primaryBody"
                />
                <Text
                  type="body"
                  content={currentFont.displayName}
                  color="primaryBrand"
                  alignment="center"
                  style={{ fontFamily: currentFont.fontName }}
                  lineHeight={1.6}
                  inset={{ left: 'x_5' }}
                />
              </Flex>
              <Separator orientation="horizontal" />
              <Div overflowY="auto" height="200px" width="100%">
                <FontList
                  fonts={api.fonts}
                  fontId={fontId}
                  selectFont={fontId => {
                    utils.updateProperties({ fontId })
                    setActiveControl('None')
                  }}
                />
              </Div>
              <Separator orientation="horizontal" />
            </Dialog>
          </Portal>
        )}
        {activeControl === 'Color' && (
          <Portal attachToContainerId="app">
            <Dialog
              isOpen={activeControl === 'Color'}
              onClose={() => setActiveControl('None')}
              insetOverride="x4"
              zIndex={1001}
            >
              <Text type="body" weight="semiBold">
                Color
              </Text>
              <Flex>
                <Text
                  alignment="center"
                  type="footnote"
                  content="Select your font color:"
                  whiteSpace="nowrap"
                  color="primaryBody"
                />
                <Flex width="100%" inset={{ left: 'x_5' }}>
                  <Icon
                    name="editorText"
                    size="small"
                    primaryColor={defaultColor}
                  />
                </Flex>
              </Flex>
              <Separator orientation="horizontal" />
              <Flex width="100%" justifyContent="center" alignItems="center">
                <Div
                  maxHeight={
                    colorPickerMaxHeight
                      ? `${colorPickerMaxHeight}px`
                      : undefined
                  }
                  overflow={colorPickerMaxHeight ? 'auto' : undefined}
                  width="100%"
                >
                  <ColorPicker
                    selectedColor={color}
                    selectColor={color => utils.updateProperties({ color })}
                    designColors={api.designColors}
                  />
                </Div>
              </Flex>
            </Dialog>
          </Portal>
        )}
        {activeControl === 'Name Tag' && !isAndroidChrome && (
          <Portal attachToContainerId="app">
            <Dialog
              isOpen={activeControl === 'Name Tag'}
              onClose={() => setActiveControl('None')}
              insetOverride="x4"
              zIndex={1001}
            >
              <Text type="body" weight="semiBold">
                Variable
              </Text>
              <Separator orientation="horizontal" />
              <InsertNames
                insertPlaceholder={placeholder => {
                  utils.insertPlaceholder(placeholder)
                  setActiveControl('None')
                }}
              />
            </Dialog>
          </Portal>
        )}
        {activeControl === 'Signature' && (
          <Portal attachToContainerId="app">
            <Dialog
              isOpen={activeControl === 'Signature'}
              onClose={() => setActiveControl('None')}
              insetOverride="x4"
              zIndex={1001}
            >
              <Text type="body" weight="semiBold">
                Signature
              </Text>
              <Separator orientation="horizontal" />
              <SignatureList
                signatures={api.fonts.signatures}
                className={styles.fontList}
                onClick={id => {
                  if (isAndroidChrome) {
                    api.setMobileTextElementSigid(id)
                  } else {
                    utils.insertSignature(id)
                  }
                  setActiveControl('None')
                }}
                isAndroidChrome={isAndroidChrome}
                hasSignature={
                  !(
                    api.mobileTextElementSigId === undefined ||
                    api.mobileTextElementSigId === 'removeSignature'
                  )
                }
                onRemoveSignature={() => {
                  if (isAndroidChrome) {
                    api.setMobileTextElementSigid('removeSignature')
                  }
                  setActiveControl('None')
                }}
              />
            </Dialog>
          </Portal>
        )}
      </div>
    </div>
  )
}

export default MinimalTextOptions
