import { Div, Flex, Icon, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { EarnerListItem } from './EarnerListItem'
import LeaderBoardListHeader from './LeaderboardListHeader'
import { LeaderType } from '../LeaderBoard'
import UserMetricPill from './UserMetricPill'
import { useAccount, useState } from 'src/hooks'
import { LeaderListEmpty } from '../svg/leaderListEmpty'
import { LeaderboardLeaderBoardTypeChoices } from 'src/graphql/generated/graphql'

type LeaderListProps = {
  title: string
  metricDescription: string
  placementTitle?: string
  leaderList?: LeaderType[]
  authUserEarnedCount: number
  isListOpen: boolean
  leaderboardType: LeaderboardLeaderBoardTypeChoices
}

const LeaderList: React.FC<LeaderListProps> = ({
  title,
  metricDescription,
  placementTitle,
  leaderList,
  authUserEarnedCount,
  isListOpen,
  leaderboardType,
}) => {
  const [isMinifiedState, setIsMinifiedState] = useState(isListOpen)
  // const formatDate = (dateString: string): string => {
  //   const date = new Date(dateString)
  //   const month = String(date.getMonth() + 1).padStart(2, '0')
  //   const day = String(date.getDate()).padStart(2, '0')
  //   const year = String(date.getFullYear() % 100).padStart(2, '0')
  //   return `${month}/${day}/${year}`
  // }
  const account = useAccount()

  return (
    <Flex
      flexDirection="column"
      position="relative"
      bottom="40px"
      borderRadius="42px"
      zIndex={2}
      backgroundColor="background"
      inset={{ top: 'x1', horizontal: 'x0' }}
      width="100%"
      boxShadow={'light'}
      maxHeight={!isMinifiedState ? '195px' : '425px'}
      overflow="hidden"
      style={{ transition: 'max-height 0.35s ease-in-out' }}
    >
      <Flex
        flexDirection="row"
        justifyContent="center"
        flexWrap="wrap"
        onClick={() => setIsMinifiedState(!isMinifiedState)}
      >
        <LeaderBoardListHeader
          title={title}
          metricDescription={metricDescription}
          placementTitle={placementTitle}
        />
        <Flex
          onClick={() => setIsMinifiedState(!isMinifiedState)}
          style={{ cursor: 'pointer', padding: '1px 16px' }}
          backgroundColor={'#DDDFDF'}
          borderRadius="circle"
          alignItems="center"
          flexDirection={'row'}
          inset={{ bottom: 'x1' }}
        >
          <Text
            type="footnote"
            weight="semiBold"
            style={{ letterSpacing: '.35px' }}
            content={!isMinifiedState ? 'View List' : 'Hide List'}
          />
          <Spacer space="x1" orientation="horizontal" />
          <Div
            style={{
              transform: `rotate(${isMinifiedState ? '90deg' : '-90deg'})`,
            }}
          >
            <Icon size={15} primaryColor="primaryHeading" name="chevron" />
          </Div>
        </Flex>
      </Flex>
      <Flex
        flexDirection="column"
        inset={{ bottom: '70px' }}
        overflow={!isMinifiedState ? 'hidden' : 'auto'}
        opacity={!isMinifiedState ? 0 : 1}
        style={{
          opacity: `${!isMinifiedState ? 0 : 1}`,
          transition: 'opacity 0.65 ease-in-out',
        }}
      >
        {leaderList && leaderList.length > 0 ? (
          leaderList.map((leader, index) => {
            const {
              profileImageUrl,
              firstName,
              lastName,
              earnedCount,
              // dateJoined,
            } = leader

            return (
              <EarnerListItem
                key={index}
                profileImageUrl={profileImageUrl}
                firstName={firstName}
                lastName={lastName}
                earnedCount={earnedCount}
                // dateJoined={formatDate(dateJoined) ?? ''}
                dateJoined=""
                rank={`${index + 4}`}
              />
            )
          })
        ) : (
          <Flex
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <LeaderListEmpty />
            <Spacer space="x2" />
            <Text
              type="body"
              content="This challenge currently has no results. Please check back soon..."
              style={{ color: '#9096A1', width: '250px' }}
              alignment="center"
            />
          </Flex>
        )}
      </Flex>
      <Flex position="sticky" bottom="10px" justifyContent={'center'}>
        <UserMetricPill
          profileImageUrl={account.profileImageUrl}
          firstName={account.firstName}
          lastName={account.lastName}
          earnedCount={authUserEarnedCount ?? 0}
          dateJoined={account.dateJoined}
          title="My Current Sales"
          leaderboardType={leaderboardType}
        />
      </Flex>
    </Flex>
  )
}

export default LeaderList
