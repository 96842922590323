// @src imports
import { OrderWithPartialLines } from 'src/redux/reducers/orders'
import {
  creditCardRequired,
  dollarsAndCents,
  formatCost,
  willUseExpense,
  willUsePoints,
} from 'src/helpers'
import { PartialLine } from 'src/helpers/multitouch'
import {
  AccountFragment,
  Amount,
  CardFragment,
  CurrencyType,
  LabeledPrice,
  PriceFragment,
  SendDelayType,
} from 'src/graphql/generated/graphql'

export const isMixedPurchase = (
  order: OrderWithPartialLines,
  account: AccountFragment,
  credits: Amount,
  isUsingCredits: boolean,
) => {
  return (
    (willUsePoints(order) || willUseExpense(account) || isUsingCredits) &&
    creditCardRequired(order, account, credits, isUsingCredits)
  )
}

export const isOrderFree = (order: OrderWithPartialLines) =>
  order.cost.total[0]?.currency === 'FREE'

export const getPointsExpenseCreditsNeeded = (
  order: OrderWithPartialLines,
  account: AccountFragment,
  credits: Amount,
  isUsingCredits: boolean,
) => {
  const sum = order.cost.total.reduce(
    (
      total: { points: number; expense: number; credits: number }, // accumulator
      { currency, amount }: { currency: string; amount: number }, // current value
    ) => ({
      points:
        currency === CurrencyType.Point ? total.points + amount : total.points,
      expense:
        currency === CurrencyType.Expense || currency === CurrencyType.Usd
          ? total.expense + amount
          : total.expense,
      credits:
        currency === CurrencyType.Expense || currency === CurrencyType.Usd
          ? total.credits + amount
          : total.credits,
    }),
    { points: 0, expense: 0, credits: 0 }, // accumulator initial value
  )

  return {
    points: Math.max(0, sum.points - account.points.amount), // return 0 if the user's points cover the order total
    expense: Math.max(
      0,
      sum.expense -
        account.expense.amount -
        (isUsingCredits ? credits.amount : 0),
    ), // return 0 if the user's expenses cover the order total
    credits: Math.max(0, sum.credits - credits.amount), // return 0 if the user's credits cover the order total
  }
}

export const baseCost = (
  entries: LabeledPrice[],
  isBulk?: boolean,
  bulkQuantity?: number | null,
) => {
  if (!entries) {
    return
  }
  const cardCost = entries.filter(e => e.label === 'Card Cost')
  if (cardCost.length > 0) {
    return isBulk && bulkQuantity
      ? `$${(cardCost[0].amount / 100).toFixed(2)}`
      : cardCost[0].asString
  }
  return ''
}

export const upgradeCost = (entries: LabeledPrice[]) => {
  if (!entries) {
    return
  }
  const upgrades = entries.filter(e => e.label === 'Card Upgrades')
  if (upgrades.length > 0) {
    return upgrades[0].asString
  }
  return ''
}

const usdTotal = (total: PriceFragment[]): number =>
  total.reduce(
    (usdTotal, total) =>
      total.currency === CurrencyType.Usd ? usdTotal + total.amount : 0,
    0,
  )

const getLineTodaysCharges = (line: PartialLine): number =>
  line.sendDelay.type === SendDelayType.Imm && line.sendDelay.delayNumber === 0
    ? usdTotal(line.cost?.total ?? [])
    : 0

const getTodaysUnformattedCharges = (lines: PartialLine[]): number =>
  lines.reduce((usdTotal, line) => usdTotal + getLineTodaysCharges(line), 0)

const formatUSD = (usd: number) => `$${(usd / 100).toFixed(2)}`

export const getTodaysCharges = (lines: PartialLine[]) =>
  formatUSD(getTodaysUnformattedCharges(lines))

const getLineRemainingCharges = (line: PartialLine): number =>
  line.sendDelay.type !== SendDelayType.Imm ||
  (line.sendDelay.type === SendDelayType.Imm &&
    (line.sendDelay.delayNumber ?? 0) > 0)
    ? usdTotal(line.cost?.total ?? [])
    : 0

const getRemainingUnformattedCharges = (lines: PartialLine[]): number =>
  lines.reduce((usdTotal, line) => usdTotal + getLineRemainingCharges(line), 0)

export const getRemainingCharges = (lines: PartialLine[]) =>
  formatUSD(getRemainingUnformattedCharges(lines))

type Cost = {
  points: number
  amount: number
}

const Cost = (cost: { points?: number; amount?: number } = {}): Cost => ({
  points: cost.points ?? 0,
  amount: cost.amount ?? 0,
})

const addCosts = (...costs: Cost[]): Cost =>
  costs.reduce(
    (total, cost) => ({
      points: total.points + cost.points,
      amount: total.amount + cost.amount,
    }),
    Cost(),
  )

export const cardCost = (card: Pick<CardFragment, 'cost'> | null): Cost =>
  card?.cost
    ? addCosts(
        ...card.cost.total.map(total =>
          total.currency === CurrencyType.Point
            ? Cost({ points: total.amount })
            : Cost({ amount: total.amount }),
        ),
      )
    : Cost()

export const orderCostWithUpgrades = (
  order: OrderWithPartialLines,
  newCard: CardFragment | null = null,
): string | undefined => {
  // the active card is used to check we are replacing a card
  // so its cost isn't counted twice
  const { points, amount }: Cost = addCosts(
    order.lines
      .filter(line => (newCard ? line.card?.id !== newCard.id : true))
      .reduce(
        (cost: Cost, line) =>
          addCosts(cost, cardCost(line.card as CardFragment)),
        Cost(),
      ),
    cardCost(newCard),
  )

  const formattedAmt = amount > 0 ? formatCost(amount) : null

  return `${
    points > 0
      ? `${points} points ${formattedAmt ? ' + ' + formattedAmt : ''}`
      : formattedAmt
  }`
}

export const formatAmount = (amount: number, roundToNextDollar?: boolean) => {
  const { dollars, cents } = dollarsAndCents(amount)
  return roundToNextDollar && cents !== '00'
    ? `${parseInt(dollars, 10) + 1}.${'00'}`
    : `${dollars}.${cents}`
}
