import { getPlans } from 'src/legacy_graphql'
import {
  Label,
  PlanFragment,
  StripeSubscriptionFragment,
  SubscriptionStatus,
  UserType,
} from 'src/graphql/generated/graphql'
import { useAccount, useQueries } from '.'

const resolveBasicSubscription = (
  plan: PlanFragment,
  subscriptions: StripeSubscriptionFragment[],
): StripeSubscriptionFragment[] =>
  plan &&
  (plan.title === 'Basic' || plan.title === 'Legacy') &&
  subscriptions.filter(s => s.name === 'Basic' || s.name === 'Legacy')
    .length === 0
    ? [
        ...subscriptions,
        {
          __typename: 'StripeSubscription',
          id: plan.id,
          sku: 'limited',
          price: 0,
          interval: 'month',
          name: 'Pay As You Go',
          status: SubscriptionStatus.Active,
          startDate: '',
          currentPeriodEnd: '',
          intervalCount: 0,
          isSetToCancel: false,
          isRenewable: false,
          features: null,
          metadata: null,
        },
      ]
    : subscriptions

export const getSubscriptions = (
  plan: PlanFragment,
  subscriptions: StripeSubscriptionFragment[],
): StripeSubscriptionFragment[] =>
  resolveBasicSubscription(plan, subscriptions).map(
    (subscription): StripeSubscriptionFragment => ({
      ...subscription,
      name: subscription.name,
      features: null,
    }),
  )

const useSubscriptions = () => {
  const {
    stripeSubscription: currentSubscription,
    subscriptions: allSubscriptions,
    plan: currentPlan,
    activePlansAndAddons,
    labels,
    cardCost: userCardCost,
    bulkCardCost: userBulkCardCost,
    giftDiscount: userGiftDiscount,
    hasUnlimitedHeartfeltSends,
    heartfeltSends,
    dailyHeartfeltSends,
    systemSends,
    cardPackageSends,
    groupSends,
    scheduledSends,
    employeeSends,
    cardSends,
    pointsTransitionSends,
    isExpenseReloadRequired,
    type: userType,
    heartfeltSystemSends,
  } = useAccount()

  const [plans] = useQueries(getPlans())
  const basicPlan = plans.find(value => value.id === '1')

  const newPlanStripeId = currentSubscription?.metadata?.planOnCancel
  const newPlan = plans.find(plan => plan.stripeId === newPlanStripeId)
  const isInPromptingsGracePeriod = Boolean(
    labels.find(label => label === Label.GsaSubscriptionPaymentFailure),
  )

  const hasStreamVASubscription =
    allSubscriptions.find(value => value?.name.startsWith('Stream VA')) !==
    undefined

  const hasSocSubscription =
    userType === UserType.Gsa ||
    activePlansAndAddons.filter(plan => plan?.description).length > 0

  const isSendogoRep =
    allSubscriptions.filter(value => value.name.startsWith('Sendogo')).length >=
    1

  // Card Sends available, except Heartfelts
  const hasAvailableCardSends =
    groupSends > 0 ||
    scheduledSends > 0 ||
    systemSends > 0 ||
    employeeSends > 0 ||
    pointsTransitionSends > 0 ||
    cardPackageSends > 0
  const hasAvailableHeartfeltSends =
    heartfeltSends > 0 ||
    dailyHeartfeltSends > 0 ||
    hasUnlimitedHeartfeltSends ||
    heartfeltSystemSends > 0 ||
    cardSends > 0
  // heartfeltSends are available for users of the heartfelt plan
  const hasAvailableHeartfeltSendTokens =
    heartfeltSends > 0 || dailyHeartfeltSends > 0 || hasUnlimitedHeartfeltSends
  const hasAvailableSystemSendTokens = systemSends > 0
  const hasAvailableCardPackageSends = cardPackageSends > 0
  const hasAvailableHeartfeltSystemSends =
    heartfeltSystemSends > 0 || cardSends > 0

  const giftDiscountString = `${userGiftDiscount * 100}%`
  const giftDiscountPlanTitle = activePlansAndAddons.reduce((prev, current) => {
    return prev && current && prev?.giftDiscount > current?.giftDiscount
      ? prev
      : current
  })?.title

  const basicCardCost = basicPlan?.cardCost ?? 0
  const basicBulkCardCost = basicPlan?.bulkCardCost ?? 0
  const basicGiftDiscount = basicPlan?.giftDiscount ?? 0

  return {
    activePlansAndAddons,
    allSubscriptions: getSubscriptions(currentPlan, allSubscriptions),
    currentSubscription,
    currentPlan,
    newPlan,
    isInPromptingsGracePeriod,
    hasStreamVASubscription,
    hasSocSubscription,
    isExpenseReloadRequired,
    /**
     * Card Sends available, except Heartfelts
     */
    hasAvailableCardSends,
    /**
     * Heartfelt Sends includes:
     * - heartfeltSends
     * - dailyHeartfeltSends
     * - unlimitedHeartfeltSends
     * - heartfeltSystemSends
     */
    hasAvailableHeartfeltSends,
    hasUnlimitedHeartfeltSends,
    hasAvailableSystemSendTokens,
    hasAvailableCardPackageSends,
    hasAvailableHeartfeltSendTokens,
    hasAvailableHeartfeltSystemSends,
    giftDiscountString,
    giftDiscountPlanTitle,
    basicCardCost,
    basicBulkCardCost,
    basicGiftDiscount,
    userCardCost,
    userBulkCardCost,
    userGiftDiscount,
    isSendogoRep,
  }
}

export default useSubscriptions
