import { Maybe } from '../../../__generated__/globalTypes'

import { CampaignFragment, isCampaignFragment } from './CampaignFragment'

import itemFragmentRawString from '../ItemFragment.graphql'

export const itemFragmentString = itemFragmentRawString

export type ItemFragment = {
  __typename: 'Item'
  id: string
  description: Maybe<string>
  itemCode: string
  price: number
  name: Maybe<string>
  image: Maybe<string>
  campaign: Maybe<CampaignFragment>
}

export const isItemFragment = (fragment: any): fragment is ItemFragment =>
  fragment &&
  fragment.__typename === 'Item' &&
  typeof fragment.id === 'string' &&
  (fragment.description === null || typeof fragment.description === 'string') &&
  typeof fragment.itemCode === 'string' &&
  typeof fragment.price === 'number' &&
  (fragment.name === null || typeof fragment.name === 'string') &&
  (fragment.image === null || typeof fragment.image === 'string') &&
  (fragment.campaign === null ||
    (isCampaignFragment(fragment.campaign) as boolean))
