import React from 'react'
import { Div, IncrementSetting } from '@sendoutcards/quantum-design-ui'

import styles from '../styles/components/_imageEditor.module.scss'
interface ScaleSliderProps {
  scale: number
  onChange: (scale: number) => void
  isMobile: boolean
  layout?: 'block' | 'inline' | 'inlineBlock'
}

const ScaleSlider: React.FC<ScaleSliderProps> = props => {
  const { scale, onChange, isMobile, layout = 'inlineBlock' } = props
  const roundedScale = Math.round(scale * 10)
  const updateScale = (value: number) => {
    if (value !== roundedScale) {
      onChange(value / 10)
    }
  }

  return (
    <Div
      width="400px"
      maxWidth="100%"
      id={'active_img_scale_slider'}
      className={
        isMobile ? undefined : `${styles.imageScale} ${styles.desktop}`
      }
    >
      <IncrementSetting
        title="Image Size"
        layout={layout}
        value={roundedScale}
        setValue={updateScale}
        min={1}
        max={20}
      />
    </Div>
  )
}

export default ScaleSlider
