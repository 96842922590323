import React from 'react'
import { Banner, Flex, Icon, Span, Text } from '@sendoutcards/quantum-design-ui'
import { useQueries } from 'src/hooks'
import { getMarketingParagraphById } from 'src/legacy_graphql'

export const MarketingParagraphBanner = (props: { id: string }) => {
  const [paragraph] = useQueries(getMarketingParagraphById({ id: props.id }))

  return (
    <Banner status={'success'}>
      <Flex justifyContent="flex-start" alignItems="center" width="100%">
        <Span display="flex" justifyContent="center" alignContent="center">
          <Icon
            name="exclamation"
            primaryColor="primaryBodyText"
            size="small"
          />
        </Span>
        <Text type="body">{paragraph.content} </Text>
      </Flex>
    </Banner>
  )
}
