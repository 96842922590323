import { CreditCardBillingAddressFragment } from 'src/graphql/generated/graphql'
import getCountryCodeFromPostalName from 'src/helpers/getCountryCodeFromPostalName'
import { AddressInput, DetailedCountryFragment } from 'src/legacy_graphql'

export const navigateAndScroll = (hash: string) => {
  if (window.location.hash !== `#${hash}`) {
    window.location.hash = hash // Directly sets the hash
  }
  scrollToElementById(hash)
}

const scrollToElementById = (id: string) => {
  const element = document.getElementById(id)
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' })
  } else {
    console.error(`Element with ID "${id}" not found.`)
  }
}

export const mapToCheckoutAddress = (
  address: Omit<AddressInput, 'firstName' | 'lastName' | 'company'>,
  detailedCountries: DetailedCountryFragment[],
) => ({
  streetAddress1: address.address1,
  streetAddress2: address.address2,
  city: address.city,
  countryArea: address.state,
  postalCode: address.postalCode,
  country: getCountryCodeFromPostalName(address.country, detailedCountries),
})

export const mapStripeSourceAddress = (
  stripeBilling?: CreditCardBillingAddressFragment,
) => {
  return stripeBilling &&
    stripeBilling.line1 &&
    stripeBilling.country &&
    stripeBilling.city &&
    stripeBilling.line1 &&
    stripeBilling.postalcode &&
    stripeBilling.state
    ? {
        address1: stripeBilling.line1,
        address2: stripeBilling.line2 ?? '',
        state: stripeBilling.state,
        city: stripeBilling.city,
        country: stripeBilling.country,
        postalCode: stripeBilling.postalcode,
      }
    : null
}
