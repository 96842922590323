import React from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { Button } from 'src/chrome'

import styles from './modalFooter.module.scss'
import { GiftFragment } from 'src/legacy_graphql'
import { Flex, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import Price from 'src/chrome/Price/Price'
import useAccountHasGiftSends from 'src/hooks/useAccountHasGiftSends'

type ModalFooterProps = {
  index: number
  gift: GiftFragment
  addGift: (gift: GiftFragment) => void
  handleChange: (value: number) => void
  isMobile: boolean
  isChangingGift: boolean
}

const ModalFooter: React.FC<ModalFooterProps> = props => {
  const { addGift, gift, index, handleChange, isMobile, isChangingGift } = props
  const { hasAvailableGiftSends } = useAccountHasGiftSends()
  const isEligibleFree =
    hasAvailableGiftSends && gift.defaultVariation?.isEligibleFreeGift

  const onTabChange = (event: React.ChangeEvent<{}>, value: number) =>
    handleChange(value)

  return (
    <>
      <Tabs
        className={styles.tabsContainer}
        value={index}
        onChange={onTabChange}
      >
        <Tab
          value={0}
          label={<Text type="body">Description</Text>}
          style={{ fontSize: 'unset' }}
        />
        <Tab
          value={1}
          label={<Text type="body">Detail</Text>}
          style={{ fontSize: 'unset' }}
        />
        <Tab
          value={2}
          label={<Text type="body">Packaging</Text>}
          style={{ fontSize: 'unset' }}
        />
        <Tab
          value={3}
          label={<Text type="body">Shipping</Text>}
          style={{ fontSize: 'unset' }}
        />
      </Tabs>
      <div className={styles.priceAndButtonWrapper}>
        <div className={styles.priceContainer}>
          <Spacer space="x1" />
          <Text type="caption">PRICE:</Text>
          <Flex
            alignItems="center"
            columnGap="x1_5"
            flexDirection={isMobile ? 'column' : 'row'}
          >
            <Price
              price={
                gift.defaultVariation
                  ? gift.defaultVariation?.price.asString
                  : ''
              }
              discountPrice={isEligibleFree ? 'Free' : undefined}
              discountMessage={
                isEligibleFree
                  ? 'FREE as part of your Heartfelt Plus Gifts subscription. Send up to 5 monthly!'
                  : undefined
              }
              direction="row"
              tooltipDirection="bottom"
            />
            <Text type="body" alignment="center">
              {isEligibleFree
                ? '(FREE as part of your Heartfelt Plus Gifts subscription. Send up to 5 monthly!)'
                : '(Sales Tax Included)'}
            </Text>
          </Flex>
        </div>
        <div className={styles.selectGiftButtonContainer}>
          <Button
            id={'add_gift_btn'}
            className={styles.selectGiftButton}
            title={isChangingGift ? 'Change Gift' : 'Add Gift'}
            buttonColor={'blue'}
            onClick={() => addGift(gift)}
          />
        </div>
      </div>
    </>
  )
}

export default ModalFooter
