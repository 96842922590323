import React from 'react'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'

import { useEffect, useRef, useState } from 'src/hooks'
import { Text } from '@sendoutcards/quantum-design-ui'

import { CompanySearchFilter, SearchFilter } from '../types'
import FilterChip from '../lib/FilterChip'

import styles from '../filteredSearchBar.module.scss'
import { CloseButton } from 'src/chrome'

type Props = {
  deleteFilter: (filter: SearchFilter) => void
  filters: SearchFilter[]
  onClear: () => void
  resetEditedFilter: () => void
  updateFilter: <T extends SearchFilter>(filter: T, update: Partial<T>) => void
  filter: CompanySearchFilter
  autocomplete?: 'on' | 'off'
}

const CompanyFilterInput: React.FC<Props> = props => {
  const {
    filters,
    deleteFilter,
    onClear,
    resetEditedFilter,
    autocomplete,
    updateFilter,
    filter,
  } = props

  const dropdownRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [inputValue, setInputValue] = useState('')
  const [isFocused, setIsFocused] = useState(false)

  const definedFilters = filters.filter(filter => filter.value !== undefined)

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const { key } = e

    switch (key) {
      case 'Backspace':
        if (inputValue === '') {
          resetEditedFilter()
        }
        break
      case 'Enter':
        updateFilter(filter, { value: inputValue })
        resetEditedFilter()
    }
  }

  return (
    <>
      <div className={styles.filterChips}>
        {isFocused && (
          <div className={styles.filterTypeContainer} ref={dropdownRef}>
            <Paper style={{ width: 200 }}>
              <List>
                <ListItem>
                  <ListItemText
                    primary={
                      <Text type="footnote" color="primaryHeading">
                        Hit enter to search
                      </Text>
                    }
                    disableTypography={true}
                  />
                </ListItem>
              </List>
            </Paper>
          </div>
        )}

        <div className={styles.chips}>
          {filters.map(filter => {
            return (
              <FilterChip
                key={Math.random()}
                filter={filter}
                onDelete={deleteFilter}
              />
            )
          })}

          <p
            style={{
              padding: '2px 5px 2px 10px',
              backgroundColor: '#F7F7F7',
              borderRadius: 15,
            }}
          >
            {filter.name}:
          </p>
        </div>
      </div>

      <input
        id={'contact_search_bar'}
        style={{ height: 45 }}
        type="text"
        value={inputValue}
        ref={inputRef}
        onChange={e => setInputValue(e.target.value)}
        className={styles.searchInput}
        placeholder="Type to search by company"
        onKeyDown={handleKeyDown}
        onBlur={() => setIsFocused(false)}
        onFocus={() => setIsFocused(true)}
        autoComplete={autocomplete}
      />

      {(definedFilters.length > 0 || inputValue) && (
        <CloseButton onClose={() => onClear()} size={18} />
      )}
    </>
  )
}

export default CompanyFilterInput
