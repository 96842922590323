import React from 'react'
import {
  Anchor,
  Flex,
  Loader,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import { useQueries } from 'src/hooks'
import {
  getMarketingContent,
  GetMarketingContentData,
} from 'src/legacy_graphql'
import suspenseBoundary from 'src/chrome/SuspenseBoundary/suspenseBoundaryHOC'
import { DefaultError } from 'src/chrome'
import { StepWrapperProps } from './ProgressiveProfileActions'
import { AFFILIATE_BRANDING } from 'src/app/constants'

export const ActionKeys = {
  optOut: 'optOut',
  optIn: 'optIn',
  remindMeLater: 'remindMeLater',
}

type Props = StepWrapperProps & {
  message: keyof GetMarketingContentData
  onComplete: () => void
  setResponse: (value: string) => void
}

const ProgramOptIn = (props: Props) => {
  const { message: messageParagraphName, onComplete, setResponse } = props

  const [marketingContent] = useQueries(getMarketingContent())

  const message = marketingContent[messageParagraphName]

  const altMarketingCopy = {
    promptingsInfo: {
      title: 'Promptings',
      caption: 'Click here to learn more about the benefits of',
      href:
        'https://www.sendoutcards.com/resources/benefits-greener-still-business/',
    },
    termsAndConditions: {
      title: 'Terms and Conditions',
      caption: 'By joining Promptings, you are agreeing to the new ',
      href:
        'https://www.sendoutcards.com/resources/documents/470/Greener_Still_Affiliate_Agreement_Documents_Feb_1_2021.pdf',
    },
  }

  const isCustomer = messageParagraphName === 'customerReferralOptInMessage'

  return (
    <>
      <Text type="title" data-mktcontent={messageParagraphName}>
        {message.title}
      </Text>
      <Spacer space="x2" />
      <Flex width="100%" flexDirection="column" overflowY="auto" height="50%">
        {!isCustomer && (
          <div>
            <Text type="body">
              Things have changed around here and we are excited to share with
              you. SendOutCards is excited to announce the launch of its parent
              company, Promptings, and five new income streams! As a
              SendOutCards {AFFILIATE_BRANDING.capitalized}, you now have the
              opportunity to earn more income by joining Promptings.
            </Text>
            <Spacer space="x1" />
            <Text type="body">
              {altMarketingCopy.promptingsInfo.caption}
              <Anchor
                color="primaryBrand"
                title={altMarketingCopy.promptingsInfo.title}
                href={altMarketingCopy.promptingsInfo.href}
              />
            </Text>
            <Spacer space="x1" />
            <Text type="body">
              {altMarketingCopy.termsAndConditions.caption}
              <Anchor
                color="primaryBrand"
                title={altMarketingCopy.termsAndConditions.title}
                href={altMarketingCopy.termsAndConditions.href}
              />
            </Text>
          </div>
        )}
        {isCustomer && (
          <div>
            <Text type="body">
              SendOutCards is excited to announce the launch of its parent
              company, Promptings, and five new income streams! To find out
              more, click{' '}
              <Anchor
                color="primaryBrand"
                title="here!"
                href="https://www.sendoutcards.com/resources/customer-referral-program/"
              />
            </Text>
            <Spacer space="x1" />
            <Text type="body">
              SendOutCards has a great new customer referral program where you
              can share our products in exchange for store credits that you can
              use to purchase products or subscriptions. All you have to do is
              share your customer referral link to ensure that all purchases are
              connected to your account! 
            </Text>
          </div>
        )}
      </Flex>
      <Spacer space="x2" />
      <Flex style={{ marginLeft: '25px', marginTop: 'auto' }}>
        <RadioGroup
          name="opt-in-option"
          onChange={event => {
            onComplete()
            setResponse(event?.target.value)
          }}
        >
          <FormControlLabel
            value={ActionKeys.optIn}
            control={<Radio />}
            label={
              isCustomer
                ? 'Upgrade to Customer Referral Program'
                : 'Start your journey with Promptings'
            }
          />
          {isCustomer && (
            <Text type="footnote">
              By clicking upgrade, you are agreeing to our{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.sendoutcards.com/resources/documents/471/Greener_Still_Customer_Agreement_Documents_Feb_1_2021.pdf"
              >
                terms.
              </a>
            </Text>
          )}
          <FormControlLabel
            value={ActionKeys.optOut}
            control={<Radio />}
            label={
              isCustomer
                ? 'Opt Out'
                : `No, continue as a SendOutCards ${AFFILIATE_BRANDING.capitalized}`
            }
          />
          <FormControlLabel
            value={ActionKeys.remindMeLater}
            control={<Radio />}
            label="Remind Me Later"
          />
        </RadioGroup>
      </Flex>
      <Spacer space="x2" />
    </>
  )
}

export default suspenseBoundary({
  component: ProgramOptIn,
  unresolved: <Loader width={50} height={50} />,
  failure: DefaultError,
})
