import { ActionCreator } from '@sendoutcards/core'
import { GiftVariationFragment } from 'src/graphql/generated/graphql'
import { PartialLine } from 'src/helpers/multitouch'

export const selectHighLevelCategory = ActionCreator(
  'SELECT_HIGH_LEVEL_CATEGORY',
  (categoryId: string) => ({ categoryId }),
)

export const selectGift = ActionCreator('SELECT_GIFT', (giftId: string) => ({
  giftId,
}))

export const selectChildCategory = ActionCreator(
  'SELECT_CHILD_CATEGORY',
  (categoryId: string) => ({
    categoryId,
  }),
)

export const dismissChildCategory = ActionCreator('DISMISS_CHILD_CATEGORY')

export const deselectGift = ActionCreator('DESELECT_GIFT')

export const deselectGiftFromLine = ActionCreator(
  'DESELECT_GIFT_FROM_LINE',
  (line: PartialLine) => ({
    line,
  }),
)

export const saveGift = ActionCreator(
  'SAVE_GIFT',
  (giftVariation: GiftVariationFragment) => ({
    giftVariation,
  }),
)

export const updateGift = ActionCreator(
  'UPDATE_GIFT',
  (giftVariation: GiftVariationFragment) => ({
    giftVariation,
  }),
)

export const selectGiftToView = ActionCreator(
  'SELECT_GIFT_TO_VIEW',
  (giftId: string) => ({ giftId }),
)

export const closeGiftBeingViewed = ActionCreator('CLOSE_GIFT_BEING_VIEWED')
