import range from 'lodash/range'
import moment from 'moment'

export const months = [
  null,
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const days = (month: number | null | undefined) => {
  if (month && [4, 6, 9, 11].includes(month)) {
    return [null, ...range(1, 31)]
  } else if (month && [2].includes(month)) {
    return [null, ...range(1, 30)]
  }
  return [null, ...range(1, 32)]
}
export const currentYear = parseInt(moment().format('YYYY'), 10)
export const years = [null, ...range(currentYear + 1, currentYear - 105, -1)]

export const dateFmtOptions = [
  null,
  'M-D',
  'M-D-YY',
  'M-D-YYYY',
  'D-M',
  'D-M-YY',
  'D-M-YYYY',
  'YY-M-D',
  'YYYY-M-D',
  'YY-D-M',
  'YYYY-D-M',
  'M/D',
  'M/D/YY',
  'M/D/YYYY',
  'D/M',
  'D/M/YY',
  'D/M/YYYY',
  'YY/M/D',
  'YYYY/M/D',
  'YY/D/M',
  'YYYY/D/M',
]

/*
  Date Validation fragments
  mmdd && ddmm ensure month/day combinations are valid (e.g. 4/30 and not 4/31)
  We don't validate leap years
*/
const mmdd = /(?:(0[469]|\b[469]\b|11)(?:-|\/)(0[1-9]|[12][0-9]|30|\b[1-9]\b)|(?:(0[13578]|10|12|\b[13578]\b)(?:-|\/)(0[1-9]|[12][0-9]|3[01]|\b[1-9]\b)|(?:(02|\b2\b)(?:-|\/)(0[1-9]|[12][0-9]|\b[1-9]\b))))/
  .source
const ddmm = /(?:(0[1-9]|[12][0-9]|30|\b[1-9]\b)(?:-|\/)(0[469]|11|\b[469]\b)|(?:(0[1-9]|[12][0-9]|3[0-1]|\b[1-9]\b)(?:-|\/)(0[13578]|10|12|\b[13578]\b))|(?:(0[1-9]|[12][0-9]|\b[1-9]\b)(?:-|\/)(02|\b2\b)))/
  .source
const yy = /(\d{2})/.source
const yyyy = /((?:19|20)\d{2})/.source
const delim = /(?:-|\/)/.source

const dateRgx = {
  mmdd: new RegExp(`^${mmdd}$`),
  ddmm: new RegExp(`^${ddmm}$`),
  mmddyy: new RegExp(`^${mmdd}${delim}${yy}$`),
  ddmmyy: new RegExp(`^${ddmm}${delim}${yy}$`),
  mmddyyyy: new RegExp(`^${mmdd}${delim}${yyyy}$`),
  ddmmyyyy: new RegExp(`^${ddmm}${delim}${yyyy}$`),
  yymmdd: new RegExp(`^${yy}${delim}${mmdd}$`),
  yyddmm: new RegExp(`^${yy}${delim}${ddmm}$`),
  yyyymmdd: new RegExp(`^${yyyy}${delim}${mmdd}$`),
  yyyyddmm: new RegExp(`^${yyyy}${delim}${ddmm}$`),
}

export const formatOptionalYearDate = ({
  day,
  month,
  year,
}: {
  day: number | null
  month: number | null
  year: number | null
}) => {
  if (day === null || month === null) {
    return ''
  }
  const adjustedYear = year ?? 1236
  const date = new Date(adjustedYear, month - 1, day)
  if (adjustedYear && adjustedYear !== 1236) {
    return moment(date).format('MMMM Do, YYYY')
  } else {
    return moment(date).format('MMMM Do')
  }
}

const dateDelimiter = (date: string) => {
  const delimiterMatch = date.match(/(-|\/)/)
  if (delimiterMatch) {
    return delimiterMatch[0]
  }
  return null
}

const formatTwoDigitYear = (year: string) => {
  const yearInt = parseInt(year, 10)
  if (yearInt >= 0 && yearInt <= currentYear % 100) {
    return `20${year.toString()}`
  } else {
    return `19${year}`
  }
}

export const findDateFormat = (date: string) => {
  const delimiter = dateDelimiter(date)
  if (!delimiter) {
    return null
  }

  const mmddMatch = date.match(dateRgx.mmdd)
  if (mmddMatch) {
    return `M${delimiter}D`
  }
  const mmddyyMatch = date.match(dateRgx.mmddyy)
  if (mmddyyMatch) {
    return `M${delimiter}D${delimiter}YY`
  }
  const mmddyyyyMatch = date.match(dateRgx.mmddyyyy)
  if (mmddyyyyMatch) {
    return `M${delimiter}D${delimiter}YYYY`
  }
  const yymmddMatch = date.match(dateRgx.yymmdd)
  if (yymmddMatch) {
    return `YY${delimiter}M${delimiter}D`
  }
  const yyyymmddMatch = date.match(dateRgx.yyyymmdd)
  if (yyyymmddMatch) {
    return `YYYY${delimiter}M${delimiter}D`
  }

  return null
}

const formatRegexes: { [format: string]: RegExp | undefined } = {
  'M-D': dateRgx.mmdd,
  'D-M': dateRgx.ddmm,
  'M-D-YY': dateRgx.mmddyy,
  'D-M-YY': dateRgx.ddmmyy,
  'M-D-YYYY': dateRgx.mmddyyyy,
  'D-M-YYYY': dateRgx.ddmmyyyy,
  'YY-M-D': dateRgx.yymmdd,
  'YY-D-M': dateRgx.yyddmm,
  'YYYY-M-D': dateRgx.yyyymmdd,
  'YYYY-D-M': dateRgx.yyyyddmm,
}

type SOCDate = {
  day: string
  month: string
  year: string | null
}

const dayIndex = (format: string[]): number => format.indexOf('D')

const monthIndex = (format: string[]): number => format.indexOf('M')

const yearIndex = (format: string[]): number => format.indexOf('YYYY')

const twoDigitYearIndex = (format: string[]): number => format.indexOf('YY')

const dateWithMatch = (match: string[], format: string[]): SOCDate | null => {
  const day = match[dayIndex(format)]
  const month = match[monthIndex(format)]
  const year = match[yearIndex(format)]
  const twoDigitYear = match[twoDigitYearIndex(format)]
  return day && month
    ? {
        day,
        month,
        year: year
          ? year
          : twoDigitYear
          ? formatTwoDigitYear(twoDigitYear)
          : null,
      }
    : null
}

// Javascript doesn't allow duplicate named capture groups,
// so we filter out undefined groups in match list
const filteredMatch = (dateString: string, match: RegExpMatchArray): string[] =>
  match.filter(m => m !== undefined && m !== dateString)

const dateWithRegex = (
  dateString: string,
  regex: RegExp,
  format: string,
): SOCDate | null => {
  const match = dateString.match(regex)
  return (
    match && dateWithMatch(filteredMatch(dateString, match), format.split('-'))
  )
}

export const createDateObject = (
  dateString: string,
  uncleanFormat: string,
): SOCDate | null => {
  const format = uncleanFormat.replace(/\//g, '-')
  const regex = formatRegexes[format]
  return regex ? dateWithRegex(dateString, regex, format) : null
}
