import React from 'react'
import {
  Anchor,
  Button,
  Flex,
  PinInput,
  Spacer,
  Text,
  Transition,
} from '@sendoutcards/quantum-design-ui'
import { PhoneNumberInput } from 'src/legacy_graphql'
import {
  useActions,
  usePersistedUserData,
  useQueryParams,
  useState,
  useVertical,
} from 'src/hooks'
import { getFormattedNumber } from 'src/onboarding/components/PhoneNumberVerification'
import { usePhoneNumberVerification } from 'src/react_query'
import {
  VerifyPhoneNumberFailureFragment,
  VerifyPhoneNumberSuccessFragment,
} from 'src/graphql/generated/graphql'

const CodeVerification = (props: {
  phoneNumber: PhoneNumberInput
  sponsorGenealogyIdOrSlug?: string
  onSuccess: (
    success: VerifyPhoneNumberSuccessFragment,
    possibleRedirectUri: string | null | undefined,
  ) => void
  onFailure: (failure: VerifyPhoneNumberFailureFragment) => void
  onNeedNewCode: (phoneNumber: PhoneNumberInput) => void
}) => {
  const { phoneNumber, sponsorGenealogyIdOrSlug, onNeedNewCode } = props
  const [verificationCode, setVerificationCode] = useState('')
  const [failureMessage, setFailureMessage] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)
  const phoneNumberVerificationMutation = usePhoneNumberVerification()
  const { controlData } = usePersistedUserData()
  const formattedNumber = getFormattedNumber(
    phoneNumber.countryIsoCode,
    phoneNumber.nationalNumber,
  )
  const actions = useActions()
  const vertical = useVertical()
  const queryParams = useQueryParams()

  const verifyPhoneNumber = async (verificationCode: string) => {
    if (verificationCode.length !== 6) {
      return setFailureMessage('Verification code must have 6 digits.')
    }
    setIsLoading(true)
    try {
      const {
        verifyPhoneNumber: result,
        createUserLoginLink: { link },
      } = await phoneNumberVerificationMutation.mutateAsync({
        phoneNumber,
        verificationCode,
        sponsorGenealogyIdOrSlug,
        shouldNotCreateNewAccount: true,
        shouldReassignNumber: false,
        verticalId: vertical.graphqlVerticalID,
        queryParams: {
          redirect_uri: queryParams.redirect_uri,
        },
      })
      if (result.__typename === 'VerifyPhoneNumberSuccess') {
        props.onSuccess(result, link)
      } else if (
        result.__typename === 'VerifyPhoneNumberFailure' &&
        result.failureType === 'UNVERIFIED_ACCOUNT_FOUND'
      ) {
        actions.setUnverifiedAccountsFound(true)
        actions.closePhoneNumberVerification()
      } else if (
        result.__typename === 'VerifyPhoneNumberFailure' &&
        result.failureType === 'NO_VERIFIED_ACCOUNTS_FOUND'
      ) {
        actions.setNoPhoneFound(true)
        actions.closePhoneNumberVerification()
      } else {
        props.onFailure(result)
      }
    } catch (error) {
      setFailureMessage(error?.toString() ?? 'Failed to verify phone number.')
    } finally {
      setIsLoading(false)
    }
  }
  if (isLoading) {
    return <Transition isLoading={isLoading} title="Verifying Code..." />
  }
  return (
    <form
      onSubmit={event => {
        event.preventDefault()
        verifyPhoneNumber(verificationCode)
      }}
      autoComplete="on"
    >
      <Text type="title" weight="bold">
        Enter your code
      </Text>
      <Spacer space="x2" />
      <Text type="caption">
        <>
          Type in the code we just sent to
          <Text type="caption" weight="semiBold">
            {` ${formattedNumber} `}
          </Text>
        </>
      </Text>
      <Spacer space="x4" />
      <PinInput
        length={6}
        hasFocus={true}
        onChange={setVerificationCode}
        onComplete={verifyPhoneNumber}
        message={
          failureMessage
            ? {
                type: 'danger',
                content: failureMessage,
              }
            : undefined
        }
      />
      <Spacer space="x8" />
      <Flex flexDirection="column" alignItems="center">
        <Button
          title={controlData.isLoggedIn ? 'Verify' : 'Verify Code'}
          onClick="submitForm"
          fullWidth={true}
        />
        <Spacer space="x2" />
        <Anchor
          title="I didn't receive a code"
          onClick={() => onNeedNewCode(phoneNumber)}
        />
      </Flex>
    </form>
  )
}

export default CodeVerification
