import { Operation } from '../../../__generated__/globalTypes'

import shareContactString from '../ShareContact.graphql'

export type ShareContactData = {
  shareContact: {
    shared: boolean
  }
}

export type ShareContactVariables = {
  contact: string
  username: string
}

export type ShareContact = Operation<ShareContactData, ShareContactVariables>

export const ShareContact = ({
  contact,
  username,
}: ShareContactVariables): ShareContact => ({
  query: shareContactString,
  variables: {
    contact,
    username,
  },
})
