import React from 'react'
import { Div } from '@sendoutcards/quantum-design-ui'

export type GreetingCardImageLinkType = {
  previewUrl: string
  cardId: string
  isHorizontal?: boolean
  rotation?: number
}
type cardPositionOffset = {
  top?: string
  right?: string
  bottom?: string
  left?: string
}

type GreetingCardImageLinkProps = GreetingCardImageLinkType & {
  width: string
  height: string
  radius?: number
  zIndex?: number
  offset?: cardPositionOffset
  scale?: number
}

export const GreetingCardImage: React.FC<GreetingCardImageLinkProps> = ({
  previewUrl,
  cardId,
  width,
  height,
  radius = 6,
  rotation,
  zIndex = 1,
  offset,
  scale,
}) => {
  return (
    <Div
      width={width}
      height={height}
      backgroundImage={previewUrl}
      backgroundSize="cover"
      borderRadius={`${radius}px`}
      transform={
        scale
          ? `scale(${scale})`
          : rotation
          ? `rotate(${rotation}deg)`
          : 'initial'
      }
      zIndex={zIndex}
      // onClick={() => {
      //   window.open(formattedCatalogUrl, '_blank')
      // }}
      boxShadow={'light'}
      top={offset?.top}
      right={offset?.right}
      bottom={offset?.bottom}
      left={offset?.left}
      position="relative"
    />
  )
}
