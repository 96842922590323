import {
  Maybe,
  RecipientInput,
  Operation,
} from '../../../__generated__/globalTypes'

import {
  RecipientFragment,
  recipientFragmentString,
} from '../../fragments/__generated__/RecipientFragment'

import { addressFragmentString } from '../../fragments/__generated__/AddressFragment'

import updateRecipientRawString from '../UpdateRecipient.graphql'

const updateRecipientString = [
  updateRecipientRawString,
  recipientFragmentString,
  addressFragmentString,
].join('\n\n')

export type UpdateRecipientData = {
  updateRecipient: {
    success: Maybe<boolean>
    recipient: RecipientFragment
  }
}

export type UpdateRecipientVariables = {
  recipient: RecipientInput
  updateContactToo: boolean
  userOverrideInvalidAddress: boolean
}

export type UpdateRecipient = Operation<
  UpdateRecipientData,
  UpdateRecipientVariables
>

export const UpdateRecipient = ({
  recipient,
  updateContactToo,
  userOverrideInvalidAddress,
}: UpdateRecipientVariables): UpdateRecipient => ({
  query: updateRecipientString,
  variables: {
    recipient: RecipientInput(recipient),
    updateContactToo,
    userOverrideInvalidAddress,
  },
})
