import React from 'react'

const styles = {
  card: {
    '@media (max-width: 668px)': {
      width: '100%',
    },
  },
}

type CardType = string
interface CreditCardProps {
  cardType: CardType
  last4: string
  name: string
  exp: string
  width?: string
  height?: string
}
const CreditCard: React.FC<CreditCardProps> = ({
  cardType,
  last4,
  name,
  exp,
  width,
  height,
}) => (
  <svg
    css={styles.card}
    width={width ?? 354.894}
    height={height ?? 224.087}
    viewBox="0 0 354.89 224.09"
  >
    <defs>
      <linearGradient
        id="prefix__a"
        x1={0.5}
        x2={0.5}
        y2={1}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#3d3093" />
        <stop offset={1} stopColor="#ff10ec" />
      </linearGradient>
      <linearGradient
        id="prefix__d"
        x2={1}
        y1={0.5}
        y2={0.5}
        xlinkHref="#prefix__a"
      />
      <linearGradient
        id="prefix__b"
        x1={0.5}
        x2={0.5}
        y1={1}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#fdb677" />
        <stop offset={1} stopColor="#ff10ec" />
      </linearGradient>
      <linearGradient
        id="prefix__e"
        x1={1.22}
        x2={-0.22}
        y1={-0.09}
        y2={1.09}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#b465da" />
        <stop offset={0.248} stopColor="#cf6cc9" />
        <stop offset={0.747} stopColor="#ee609c" />
        <stop offset={1} stopColor="#ee609c" />
      </linearGradient>
      <linearGradient
        id="prefix__f"
        x2={1}
        y1={0.5}
        y2={0.5}
        xlinkHref="#prefix__b"
      />
      <clipPath id="prefix__c">
        <rect width={354.894} height={224.087} fill="none" rx={18} />
      </clipPath>
    </defs>
    <rect width={354.894} height={224.087} fill="url(#prefix__a)" rx={18} />
    <g clipPath="url(#prefix__c)">
      <path
        fill="url(#prefix__d)"
        d="M1908.466 1190.969c-36.229-42.5 60.1-73.391 95.624-73.391s76.154-8.932 76.154 26.687-45.594 157.9-76.154 102.3-59.39-13.094-95.624-55.596z"
        opacity={0.384}
        transform="translate(-1867.802 -1043.05)"
      />
      <path
        fill="url(#prefix__b)"
        d="M1870 1291.086c46.761-24.064 54.435 65.236 92.882-55.352 16.168-50.71 78.784-64.975 88.016-88.217 25.2-63.444-80.1-110.209-80.1-110.209H1870z"
        opacity={0.4}
        transform="translate(-1870 -1048.772)"
      />
      <path
        fill="url(#prefix__e)"
        d="M1983.857 1283.6h232.818v-142.58s-33.764 63.753-123.72 63.753-109.098 78.827-109.098 78.827z"
        transform="translate(-1861.781 -1041.286)"
      />
      <path
        fill="url(#prefix__f)"
        d="M2221.136 1015.393s-370.657-117.611-246.24 42.264c63.652 81.792 77.655 2.718 120.429 93.653 25.373 53.94 105.018-54.722 125.811-54.722z"
        transform="translate(-1864.271 -1053.363)"
      />
      <text
        fill="#fff"
        fontFamily="Karla-Regular, Karla"
        fontSize={25}
        transform="translate(21.444 139.868)"
      >
        <tspan x={0} y={0}>
          {`**** **** **** ${last4}`}
        </tspan>
      </text>
      <text
        fill="#fff"
        fontFamily="Karla-Bold, Karla"
        fontSize={21}
        fontWeight={700}
        transform="translate(21.444 49.021)"
      >
        <tspan x={0} y={0}>
          {`${cardType}`}
        </tspan>
      </text>
      <g fill="#fff" fontFamily="Karla-Bold, Karla" fontWeight={700}>
        <text fontSize={14} transform="translate(21.444 199.56)">
          <tspan x={0} y={0}>
            {`${name}`}
          </tspan>
        </text>
        <text fontSize={10} transform="translate(21.444 176.261)">
          <tspan x={0} y={0}>
            {'Card Holder name'}
          </tspan>
        </text>
      </g>
      <g fill="#fff" fontFamily="Karla-Bold, Karla" fontWeight={700}>
        <text fontSize={15} transform="translate(275.552 200.56)">
          <tspan x={0} y={0}>
            {`${exp}`}
          </tspan>
        </text>
        <text fontSize={10} transform="translate(275.552 176.261)">
          <tspan x={0} y={0}>
            {'Expiry date'}
          </tspan>
        </text>
      </g>
      <path
        fill="#fff"
        d="M318.51 56.602a15.348 15.348 0 01-9.189-3.052 15.119 15.119 0 01-9.18 3.052c-8.2 0-14.871-6.394-14.871-14.251s6.671-14.249 14.871-14.249a15.116 15.116 0 019.18 3.051 15.347 15.347 0 019.189-3.051c8.2 0 14.871 6.392 14.871 14.249s-6.671 14.251-14.871 14.251zm0-23.47a9.83 9.83 0 00-5.687 1.788 13.715 13.715 0 010 14.865 9.831 9.831 0 005.688 1.788 9.439 9.439 0 009.623-9.22 9.438 9.438 0 00-9.624-9.223z"
      />
      <path
        fill="#ead158"
        d="M62.187 75.34v20.714l-5.556 5.649H26.999a5.619 5.619 0 01-5.556-5.649V75.34a5.619 5.619 0 015.556-5.649h29.632a5.62 5.62 0 015.556 5.649z"
      />
      <path
        fill="#3a3847"
        d="M58.531 80.468a.918.918 0 00-.912-.923h-9.124a.923.923 0 000 1.847h9.124a.917.917 0 00.912-.924zm0 9.232a.917.917 0 00-.912-.923h-9.124a.923.923 0 000 1.846h9.124a.917.917 0 00.912-.923zm-25.545-9.232a.918.918 0 00-.913-.923h-7.3a.923.923 0 000 1.847h7.3a.917.917 0 00.913-.924zm0 9.232a.917.917 0 00-.913-.923h-7.3a.923.923 0 000 1.846h7.3a.917.917 0 00.913-.923z"
      />
      <path
        fill="#3a3847"
        d="M38.46 91.547a.917.917 0 00-.912-.923h-1.827a.919.919 0 01-.912-.923v-9.232a.919.919 0 01.912-.923.923.923 0 000-1.846 2.756 2.756 0 00-2.737 2.77v9.232a2.757 2.757 0 002.737 2.77h1.825a.917.917 0 00.914-.925zm10.948-1.848v-9.232a.912.912 0 10-1.825 0v9.232a.919.919 0 01-.912.923h-1.825a.923.923 0 000 1.847h1.825a2.757 2.757 0 002.737-2.77z"
      />
      <path
        fill="#3a3847"
        d="M45.758 95.239v-3.693a.912.912 0 10-1.825 0v3.693a.912.912 0 101.825 0zm-7.298 0v-3.693a.912.912 0 10-1.825 0v3.693a.912.912 0 101.825 0z"
      />
      <path
        fill="#3a3847"
        d="M47.315 97.738a.93.93 0 000-1.305l-1.825-1.847a.9.9 0 00-1.29 0 .93.93 0 000 1.306l1.825 1.846a.9.9 0 001.29 0zm-10.947 0l1.825-1.846a.93.93 0 000-1.306.9.9 0 00-1.29 0l-1.825 1.847a.93.93 0 000 1.305.9.9 0 001.29 0zm9.39-19.117v-3.693a.912.912 0 10-1.825 0v3.693a.912.912 0 101.825 0zm-7.298 0v-3.693a.912.912 0 10-1.825 0v3.693a.912.912 0 101.825 0z"
      />
      <path
        fill="#3a3847"
        d="M45.491 75.582l1.825-1.846a.93.93 0 000-1.306.9.9 0 00-1.29 0l-1.825 1.846a.93.93 0 000 1.305.9.9 0 001.29 0zm-7.299 0a.93.93 0 000-1.305l-1.825-1.846a.9.9 0 00-1.29 0 .93.93 0 000 1.306l1.825 1.846a.9.9 0 001.29 0zm.268 3.04a.917.917 0 00-.912-.923h-1.825a.923.923 0 000 1.846h1.825a.917.917 0 00.912-.923z"
      />
      <path d="M46.025 97.738l-1.82-1.841a1.378 1.378 0 01-.052-.056l-.006-.007-.008-.01-.01-.013a.927.927 0 01-.194-.6v-3.665a.917.917 0 01.912-.922h1.825a.92.92 0 00.913-.924v-9.232a.917.917 0 01.912-.924h9.124a.924.924 0 010 1.847h-8.214v7.385h8.211a.924.924 0 010 1.847h-8.367a2.745 2.745 0 01-2.581 1.846h-.908v2.39l1.558 1.575a.932.932 0 010 1.306.905.905 0 01-1.29 0zm-10.948 0a.93.93 0 010-1.306l1.557-1.573v-2.39h-.912a2.756 2.756 0 01-2.737-2.77v-9.231a2.756 2.756 0 012.737-2.77h.912v-2.387l-1.556-1.576a.93.93 0 010-1.306.9.9 0 011.289 0l1.82 1.841a.907.907 0 01.052.056l.005.006.008.01.01.013a.927.927 0 01.194.6v3.667a.917.917 0 01-.912.922h-1.822a.92.92 0 00-.913.924V89.7a.92.92 0 00.913.924h1.825a.9.9 0 01.25.035.921.921 0 01.662.887v3.665a.927.927 0 01-.194.6l-.011.013-.008.009-.006.007a.907.907 0 01-.052.056l-1.82 1.841a.9.9 0 01-1.289 0zm-10.303-7.115a.924.924 0 010-1.847h7.3a.924.924 0 010 1.847zm0-9.232a.924.924 0 010-1.847h7.3a.924.924 0 010 1.847zm19.16-2.77v-3.662a.928.928 0 01.194-.6l.01-.012.009-.01v-.006l.052-.057 1.826-1.844a.9.9 0 011.29 0 .932.932 0 010 1.306l-1.553 1.575v3.311a.912.912 0 11-1.825 0z" />
    </g>
  </svg>
)

export default CreditCard
