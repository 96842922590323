import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Set } from 'immutable'
// src imports
import { CardPanelView } from 'src/orders/components'
import { CatalogRibbon } from 'src/catalog/components'
import { Button, Confirm, Transition } from 'src/chrome'
import {
  useActions,
  useFlag,
  useMutations,
  useQueries,
  useState,
} from 'src/hooks'
import { getDraftedCards, getFlags } from 'src/legacy_graphql'
// style imports
import chromeStyles from 'src/chrome/styles/Chrome.module.scss'
import styles from './drafts.module.scss'
import suspenseBoundary from '../../chrome/SuspenseBoundary/suspenseBoundaryHOC'
import NewEditorOptionModal from 'src/chrome/NewEditorOptionModal/NewEditorOptionModal'
import { CardFragment } from 'src/graphql/generated/graphql'
import { useGetCard } from 'src/react_query'

type Props = {
  isWrapContentEnabled?: boolean
}

const DraftedCards: React.FC<Props> = props => {
  const { isWrapContentEnabled } = props

  const actions = useActions()
  const mutations = useMutations()

  const [
    shouldShowEditorChoiceModal,
    setShouldShowEditorChoiceModal,
  ] = useState<boolean>(false)

  const [
    isDeleteConfirmationModalOpen,
    setIsDeleteConfirmationModalOpen,
  ] = useState(false)

  const [selectedDraftedCardIds, setSelectedDraftedCardIds] = useState<
    Set<string>
  >(Set())

  const [previewedDraftedCardId, setPreviewedDraftedCardId] = useState<
    string | undefined
  >(undefined)

  // TODO: move this to react query.
  // The new query is named GetDraftedCards, but it's not an infinite query yet
  const [
    {
      results: draftedCards,
      isLoadingMore: isLoadingMoreDraftedCards,
      hasMore: hasMoreDraftedCards,
      loadMore: loadMoreDraftedCards,
    },
    flags,
  ] = useQueries(getDraftedCards(), getFlags())

  const { data } = useGetCard(
    {
      id: previewedDraftedCardId,
    },
    { suspense: true },
  )

  const previewedDraftedCard = data?.card

  const { newCardEditor: canShowNewCardEditor } = useFlag(flags)

  const isEveryDraftedCardSelected =
    selectedDraftedCardIds.size === draftedCards.length

  const previewDraftedCardWithId = (draftId: string) => {
    setPreviewedDraftedCardId(draftId)
  }

  const clearPreviewedDraftedCardId = () => {
    setPreviewedDraftedCardId(undefined)
  }

  const openPreviewedDraftedCard = () => {
    if (previewedDraftedCardId) {
      if (!previewedDraftedCard?.isNewEditorCard && canShowNewCardEditor) {
        setShouldShowEditorChoiceModal(true)
      } else {
        openCardInEditor(previewedDraftedCardId)
      }
    }
  }

  const createdCardInNewEditor = (card: CardFragment) => {
    openCardInEditor(card.id)
    setShouldShowEditorChoiceModal(false)
  }

  const openCardInEditor = (cardId: string) => {
    actions.openCard(cardId)
    clearPreviewedDraftedCardId()
  }

  const deleteSelectedDraftedCards = () => {
    mutations.deleteCards({ cards: Array.from(selectedDraftedCardIds) })
    setSelectedDraftedCardIds(Set())
    toggleDeleteConfirmationModal()
  }

  const handleSelectDraftedCardWithId = (cardId: string) => {
    const set = selectedDraftedCardIds.has(cardId)
      ? selectedDraftedCardIds.delete(cardId)
      : selectedDraftedCardIds.add(cardId)
    setSelectedDraftedCardIds(set)
  }

  const handleSelectAllDraftedCards = () => {
    setSelectedDraftedCardIds(
      Set(isEveryDraftedCardSelected ? [] : draftedCards.map(card => card.id)),
    )
  }

  const toggleDeleteConfirmationModal = () => {
    setIsDeleteConfirmationModalOpen(x => !x)
  }

  return (
    <div>
      {isLoadingMoreDraftedCards && (
        <div className={chromeStyles.centeredLoader}>
          <CircularProgress />
        </div>
      )}

      {canShowNewCardEditor && shouldShowEditorChoiceModal && (
        <NewEditorOptionModal
          title={
            'Try it out! Would you like to edit\nyour card in the new editor'
          }
          description={
            'Since this card was created in the current card\neditor we can copy it and allow you to try editing it\nin the new editor. Would you like to try?'
          }
          tryNewEditor={{
            title: 'Copy Card to New Editor',
            onClick: createdCardInNewEditor,
          }}
          useCurrentEditor={{
            title: 'Edit in Current Editor',
            onClick: () => {
              setShouldShowEditorChoiceModal(false)
              if (previewedDraftedCardId)
                openCardInEditor(previewedDraftedCardId)
            },
          }}
          isOpen={shouldShowEditorChoiceModal}
          onClose={() => {
            setShouldShowEditorChoiceModal(false)
          }}
          cardId={previewedDraftedCardId}
        />
      )}

      <div className={styles.body}>
        {selectedDraftedCardIds.size > 0 && (
          <div className={styles.selectedMenu}>
            <div style={{ display: 'flex' }}>
              <Checkbox
                checked={isEveryDraftedCardSelected}
                onChange={handleSelectAllDraftedCards}
                style={{ width: 35 }}
              />
              <span>{`Selected ${selectedDraftedCardIds.size} of ${draftedCards.length}`}</span>
            </div>
            <Button
              title={'Delete'}
              buttonColor={'pink'}
              onClick={toggleDeleteConfirmationModal}
            />
          </div>
        )}
        <div>
          <CatalogRibbon
            isWrapContentEnabled={isWrapContentEnabled}
            items={draftedCards}
            onClick={previewDraftedCardWithId}
            type={'drafts'}
            loadMore={hasMoreDraftedCards ? loadMoreDraftedCards : undefined}
            isLoadingMore={isLoadingMoreDraftedCards}
            hasMore={hasMoreDraftedCards}
            selectedSet={selectedDraftedCardIds}
            handleCheckbox={handleSelectDraftedCardWithId}
          />
        </div>
      </div>
      {previewedDraftedCardId && (
        <Confirm
          title={'Would you like to finish your card?'}
          confirmTitle={'Finish Card'}
          onConfirm={openPreviewedDraftedCard}
          onDecline={clearPreviewedDraftedCardId}
          childType={'image'}
          containerClassName={styles.confirmContainer}
          wrapperClassName={styles.confirmWrapper}
          isModalEnabled={true}
          confirmMessageClassName={styles.confirmMessage}
        >
          <div style={{ minHeight: 200, minWidth: '100%' }}>
            {previewedDraftedCard && (
              <CardPanelView
                card={previewedDraftedCard}
                sliderMaxContainerHeight={750}
              />
            )}
          </div>
        </Confirm>
      )}
      {isDeleteConfirmationModalOpen && (
        <Confirm
          title={`Delete ${selectedDraftedCardIds.size} cards`}
          message={'Are you sure you want to delete the selected cards?'}
          confirmTitle={'Delete'}
          onConfirm={deleteSelectedDraftedCards}
          onDecline={toggleDeleteConfirmationModal}
        />
      )}
    </div>
  )
}

export default suspenseBoundary({
  component: DraftedCards,
  unresolved: <Transition />,
  failure: error => `Failed loading contacts: ${error}`,
})
