import { Maybe } from '../../../__generated__/globalTypes'

import {
  OptionalYearDateFragment,
  isOptionalYearDateFragment,
} from './OptionalYearDateFragment'

import relatedContactFragmentRawString from '../RelatedContactFragment.graphql'

export const relatedContactFragmentString = relatedContactFragmentRawString

export type RelatedContactFragment = {
  id: string
  firstName: string
  lastName: string
  address1: string
  address2: string
  company: string
  city: string
  state: string
  postalCode: string
  country: Maybe<string>
  isPrimary: boolean
  birthday: Maybe<OptionalYearDateFragment>
  anniversary: Maybe<OptionalYearDateFragment>
  homePhone: Maybe<string>
  cellPhone: Maybe<string>
  workPhone: Maybe<string>
  faxNumber: Maybe<string>
  website: string
  emailAddress: Maybe<string>
}

export const isRelatedContactFragment = (
  fragment: any,
): fragment is RelatedContactFragment =>
  fragment &&
  typeof fragment.id === 'string' &&
  typeof fragment.firstName === 'string' &&
  typeof fragment.lastName === 'string' &&
  typeof fragment.address1 === 'string' &&
  typeof fragment.address2 === 'string' &&
  typeof fragment.company === 'string' &&
  typeof fragment.city === 'string' &&
  typeof fragment.state === 'string' &&
  typeof fragment.postalCode === 'string' &&
  (fragment.country === null || typeof fragment.country === 'string') &&
  typeof fragment.isPrimary === 'boolean' &&
  (fragment.birthday === null ||
    (isOptionalYearDateFragment(fragment.birthday) as boolean)) &&
  (fragment.anniversary === null ||
    (isOptionalYearDateFragment(fragment.anniversary) as boolean)) &&
  (fragment.homePhone === null || typeof fragment.homePhone === 'string') &&
  (fragment.cellPhone === null || typeof fragment.cellPhone === 'string') &&
  (fragment.workPhone === null || typeof fragment.workPhone === 'string') &&
  (fragment.faxNumber === null || typeof fragment.faxNumber === 'string') &&
  typeof fragment.website === 'string' &&
  (fragment.emailAddress === null || typeof fragment.emailAddress === 'string')
