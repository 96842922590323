import { Div, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'

type ProgressBarProps = {
  percentage: number
  metricCount?: string
  fillColor?: string
  barColor?: string
  isVertical?: boolean
  barHeight?: string
  border?: string
}

export const ProgressBar = (props: ProgressBarProps) => {
  const {
    percentage,
    fillColor = 'linear-gradient(74deg, #34E3DF 16.27%, #02AEEE 86.38%)',
    barColor = '#E0DEDE',
    isVertical = false,
    barHeight = '10px',
    border = 'none',
    metricCount,
  } = props

  return (
    <Div
      position="relative"
      width={isVertical ? barHeight : '100%'}
      height={isVertical ? '100%' : barHeight}
      overflow="hidden"
      style={{ borderRadius: '10px', border: border }}
      backgroundColor={barColor}
    >
      <Div
        position="absolute"
        width={isVertical ? '100%' : `${percentage}%`}
        height={isVertical ? `${percentage}%` : '100%'}
        style={{
          background: fillColor,
          transition: 'width 0.3s ease',
          borderRadius: 'inherit',
          left: 0,
          bottom: 0,
        }}
      />
      {metricCount && (
        <Div
          position="absolute"
          style={
            isVertical
              ? { bottom: 0, left: '50%', transform: 'translateX(-50%' }
              : { left: 8, top: '50%', transform: 'translateY(-50%' }
          }
        >
          <Text type="caption" content={metricCount} weight="bold" />
        </Div>
      )}
    </Div>
  )
}
