import React from 'react'
import { SVGProps } from './types'

export const PromptingsLogo: React.FC<SVGProps> = ({
  size = 24,
  color = '#323232',
  gradient,
  gradientTransform,
  onClick,
}) => {
  return (
    <svg
      width={`${size}px`}
      height="auto"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      {gradient && (
        <defs>
          <linearGradient
            id="iconGradient"
            gradientTransform={gradientTransform}
          >
            {gradient?.map((gd, i) => (
              <stop offset={gd.offset} stopColor={gd.color} key={`igd${i}`} />
            ))}
          </linearGradient>
        </defs>
      )}
      <path
        d="M19.8651 6.04783C15.8455 7.56685 14.9388 11.7747 13.9108 15.1619C12.4368 15.2885 11.1568 15.6333 9.51787 16.3579L9.48393 16.3972C10.604 16.2357 12.4708 16.3186 13.4454 16.7289C12.8247 19.0031 12.3108 21.4213 10.9628 23.355C9.43545 25.7164 5.50796 25.9696 3.65574 23.7827C1.85685 21.7617 3.14662 18.2436 5.80858 17.3662C2.85085 18.1912 1.20712 21.8447 2.90903 24.2498C4.70792 26.8993 9.35787 27.4275 12.015 25.5505C14.5703 23.5863 14.9776 20.3868 15.6612 17.6281C15.7243 17.3749 15.7825 17.1218 15.8406 16.8686C19.8651 16.7115 24.6848 17.0083 27.4631 13.7651C31.6718 8.86761 25.7854 3.33718 19.8651 6.04783ZM24.3454 13.6385C21.9258 14.8345 18.9826 14.8258 16.2673 15.1183C16.7086 13.4028 17.2419 11.6917 18.1099 10.1509C19.322 7.99025 22.6871 6.58473 25.1308 7.81565C27.6667 9.19498 26.6437 12.508 24.3454 13.6385Z"
        fill={gradient ? "url('#iconGradient')" : color}
      />
    </svg>
  )
}
