import { Store } from 'redux'
import { State } from 'src/redux/reducers'
import { GraphQLObject, OperationFactory } from 'src/legacy_graphql/Query'
import Action from 'src/redux/action'
import {
  mutationFailed,
  mutationSucceeded,
  NormalizedResource,
  startedBlockingMutation,
  StrictNormalizedResource,
} from 'src/redux/actions/graphql'
import {
  DeletedResources,
  scheduledOptimisticMutation,
} from 'src/redux/actions'
import { NormalizedData } from 'src/legacy_graphql'
import { Set } from 'immutable'
import mapValues from 'lodash/mapValues'
import pickBy from 'lodash/pickBy'
import { perform } from 'src/app/api'
import omit from 'lodash/omit'

type OptimisticMutationArguments<Data, Variables> = {
  operation: OperationFactory<Data, Variables>
  createdResources: (Variables: Variables) => StrictNormalizedResource[]
  updatedResources: (variables: Clean<Variables>) => NormalizedResource[]
  deletedResources: (variables: Clean<Variables>) => DeletedResources
  failureMessage: string
}

type UnboundOptimisticMutation<Variables> = (
  store: Store<State, Action>,
) => (variables: Variables) => void

const OptimisticMutation = <Data, Variables>({
  operation,
  createdResources,
  updatedResources,
  deletedResources,
  failureMessage,
}: OptimisticMutationArguments<Data, Variables>): UnboundOptimisticMutation<
  Variables
> => store => variables => {
  const cleanedVariables = clean(variables)
  store.dispatch(
    scheduledOptimisticMutation(
      operation(variables),
      createdResources(variables),
      updatedResources(cleanedVariables),
      deletedResources(cleanedVariables),
      failureMessage,
    ),
  )
}

export const OptimisticCreateMutation = <Data, Variables>(
  args: Pick<
    OptimisticMutationArguments<Data, Variables>,
    'operation' | 'failureMessage' | 'createdResources'
  > &
    Pick<
      Partial<OptimisticMutationArguments<Data, Variables>>,
      'updatedResources'
    >,
) =>
  OptimisticMutation<Data, Variables>({
    ...args,
    updatedResources: args.updatedResources ?? (() => []),
    deletedResources: () => ({}),
  })

export const OptimisticUpdateMutation = <Data, Variables>(
  args: Pick<
    OptimisticMutationArguments<Data, Variables>,
    'operation' | 'failureMessage' | 'updatedResources'
  > &
    Pick<
      Partial<OptimisticMutationArguments<Data, Variables>>,
      'createdResources'
    >,
) =>
  OptimisticMutation<Data, Variables>({
    ...args,
    createdResources: args.createdResources ?? (() => []),
    deletedResources: () => ({}),
  })

export const OptimisticDeleteMutation = <Data, Variables>({
  resourceTypename,
  resourceIds,
  ...args
}: Pick<
  OptimisticMutationArguments<Data, Variables>,
  'operation' | 'failureMessage'
> & {
  resourceTypename: keyof NormalizedData
  resourceIds: (variables: Clean<Variables>) => string[]
}) =>
  OptimisticMutation<Data, Variables>({
    ...args,
    updatedResources: variables => [],
    createdResources: variables => [],
    deletedResources: variables => ({
      [resourceTypename]: Set(resourceIds(variables)),
    }),
  })

type BlockingMutationArguments<Data, Variables> = {
  operation: OperationFactory<Data, Variables>
  transitionMessage: string
  failureMessage: string
  isFormData?: boolean
}

const BlockingMutationFactory = (creatingResources: boolean) => <
  Data,
  Variables
>({
  operation,
  transitionMessage,
  failureMessage,
  isFormData,
}: BlockingMutationArguments<Data, Variables>) => (
  store: Store<State, Action>,
) => async (
  variables: Variables & {
    doNotShowDefaultTransition?: boolean
    shouldBlockGatheringNormalizedData?: boolean
  },
) => {
  if (!variables.doNotShowDefaultTransition) {
    store.dispatch(startedBlockingMutation(transitionMessage))
  }
  try {
    const data = await perform(
      operation(omit(variables, 'doNotShowDefaultTransition') as Variables),
      false,
      isFormData,
    )
    store.dispatch(
      mutationSucceeded(
        (data as unknown) as GraphQLObject,
        creatingResources,
        {},
        false,
        variables.shouldBlockGatheringNormalizedData,
      ),
    )
    return data
  } catch (error) {
    store.dispatch(mutationFailed(false, failureMessage))
    throw error
  }
}

export const BlockingCreateMutation = BlockingMutationFactory(true)
export const BlockingUpdateMutation = BlockingMutationFactory(false)

type Clean<Input> = Input extends Array<infer T>
  ? Array<Clean<T>>
  : Input extends {}
  ? {
      [key in keyof Input]: Exclude<Clean<Input[key]>, undefined | null>
    }
  : Input

const clean = <Input>(input: Input): Clean<Input> => {
  if (Array.isArray(input)) {
    return (input
      .filter(value => value !== undefined)
      .map(clean) as unknown) as Clean<Input>
  } else if (input !== null && typeof input === 'object') {
    const cleanedObject = pickBy(
      (input as unknown) as object,
      value => value !== undefined,
    )
    return mapValues(cleanedObject, clean) as Clean<Input>
  } else {
    return input as Clean<Input>
  }
}
