import { Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'

import { Icon } from 'src/chrome'
import { cssStyles } from 'src/styled'

import { sharedStyles } from './styles'

const styles = cssStyles({
  addIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    cursor: 'pointer',
  },
})

interface Props {
  onAddCard: () => void
}

const CampaignLineAddCard: React.FC<Props> = props => {
  const { onAddCard } = props

  return (
    <div
      css={sharedStyles.itemContainer}
      style={{ cursor: 'pointer' }}
      id={'add_card_to_campaigns'}
      onClick={() => onAddCard()}
    >
      <div>
        <div css={styles.addIcon}>
          <Icon icon={'ADD'} />
          <Text type="caption" color="primaryHeading">
            Add a Card
          </Text>
        </div>
      </div>
    </div>
  )
}

export default CampaignLineAddCard
