import React from 'react'

import { Button, Modal } from 'src/chrome'
import { Div, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import { Item } from 'src/campaign_store/types'

type ProductPreviewModalProps = {
  product: Item
  onClose: () => void
  onPurchase?: (product: Item) => void
}

const ProductPreviewModal: React.FC<ProductPreviewModalProps> = props => {
  const { product, onClose, onPurchase } = props
  const bodyChildren = (
    <div>
      <Text type="caption">{product.description}</Text>
      <Spacer space="x4" />
      <Div display="flex">
        <Text type="subtitle">Price: </Text>
        <Spacer space="x2" orientation="horizontal" />
        <Text type="subtitle" color="primaryBrand">
          {product.price}
        </Text>
      </Div>
    </div>
  )
  const footerChildren = (
    <div>
      {onPurchase && (
        <Button
          title={'Buy'}
          style={{ marginLeft: 'auto', width: 126 }}
          onClick={() => onPurchase?.(product)}
        />
      )}
    </div>
  )

  return (
    <div>
      <Modal
        title={product?.name ?? 'Product'}
        imageUrl={product.image ?? undefined}
        bodyChildren={bodyChildren}
        footerChildren={footerChildren}
        onClose={onClose}
        animationStyle={'fade'}
      />
    </div>
  )
}

export default ProductPreviewModal
