import { useCallback, useState } from './'

const useLoading = () => {
  const [loading, setLoading] = useState(0)

  const start = useCallback(() => setLoading(x => x + 1), [])
  const end = useCallback(() => setLoading(x => x - 1), [])

  return { start, end, isLoading: loading > 0 }
}

export default useLoading
