import { Div, Flex, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { TopEarnerBadge } from './TopEarnerBadge'
import { useSelector } from 'src/hooks'
import { WinnerToggle, WinnerToggleProps } from './WinnerToggle'
import Confetti from 'react-confetti'
import { LeaderFragment } from 'src/graphql/generated/graphql'

type TopLeadersType = {
  title: string
  description?: string
  metricDescription: string
  leaders?: LeaderFragment[]
  dateRange: string
  isPastChallenge: boolean
  gradient?: string
} & WinnerToggleProps

const TopLeaders: React.FC<TopLeadersType> = ({
  title,
  description,
  metricDescription,
  leaders,
  dateRange,
  onToggle,
  isSelected,
  isPastChallenge,
  gradient,
}) => {
  const isSmallMobile = useSelector(state => state.window.width <= 390)
  const isMobile = useSelector(state => state.window.width <= 570)
  const isTablet = useSelector(state => state.window.width <= 991)
  const emptyLeader: LeaderFragment & { isEmptyState?: boolean } = {
    isEmptyState: true,
    __typename: 'Leader',
    profileImageUrl: '',
    firstName: '',
    lastName: '',
    earnedCount: 0,
    dateJoined: '',
  }
  const emptyStateLeaders = [...Array(3)].map(() => ({ ...emptyLeader }))
  const leaderArray: (LeaderFragment & { isEmptyState?: boolean })[] =
    leaders && leaders.length === 3 ? leaders : emptyStateLeaders

  return (
    <Flex
      borderRadius={{ top: '42px' }}
      position="relative"
      width="100%"
      flexDirection="column"
      boxShadow="light"
      style={{
        background: `linear-gradient(${gradient})`,
      }}
      alignItems="center"
      inset={{ bottom: '60px' }}
    >
      <Flex
        width={isSmallMobile ? '200px' : '300px'}
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Text
            weight="bold"
            alignment="center"
            outset={{ top: '50px' }}
            style={{ color: '#885677' }}
            type={isSmallMobile ? 'body' : 'largeBody'}
            content={title}
          />
          {description && (
            <Text
              weight="extraBold"
              alignment="center"
              outset={{ bottom: '8px' }}
              color="primaryHeading"
              type={isSmallMobile ? 'largeBody' : 'subtitle'}
              content={description}
            />
          )}
        </Flex>
        <Text
          weight="regular"
          alignment="center"
          type="body"
          content={dateRange}
          style={{ color: '#5a5a5a' }}
        />
        {!isMobile && <Spacer space="x4" orientation="vertical" />}
      </Flex>
      {!isSelected && (
        <Div position={'absolute'} width="100%" top="50px">
          <Confetti
            width={500}
            height={570}
            numberOfPieces={200}
            gravity={0.1}
            colors={['#dd9a18', '#dcad52', '#EB4FAD', '#4FBCEB']}
            initialVelocityY={20}
            confettiSource={{ x: 250, y: 285, w: 0, h: 0 }}
            recycle={false}
          />
        </Div>
      )}
      <Div
        display="grid"
        alignItems="center"
        justifyItems="center"
        style={{
          gridTemplateColumns: 'repeat(3, 1fr)',
          columnGap: isMobile ? '0' : '1.5rem',
        }}
        inset={{ bottom: isTablet ? '145px' : '120px' }}
        backgroundColor="transparent"
        zIndex={2}
      >
        {leaderArray.map((leader, index) => {
          const mappedType =
            index === 0 ? 'gold' : index === 1 ? 'silver' : 'bronze'
          const {
            profileImageUrl,
            firstName,
            lastName,
            earnedCount,
            dateJoined,
          } = leader
          return (
            <TopEarnerBadge
              key={index}
              profileImageUrl={profileImageUrl}
              firstName={firstName}
              lastName={lastName}
              earnedCount={earnedCount}
              dateJoined={dateJoined}
              type={mappedType}
              metricDescription={metricDescription}
              isEmptyState={leader?.isEmptyState ?? false}
              isPastChallenge={!isSelected}
            />
          )
        })}
      </Div>
      {isPastChallenge && (
        <WinnerToggle onToggle={onToggle} isSelected={isSelected} />
      )}
    </Flex>
  )
}
export default TopLeaders
