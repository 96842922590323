import * as LogRocket from 'logrocket'

// LogRocket should be initialized before wrapping fetch.
// See more at https://docs.logrocket.com/docs/troubleshooting-sessions#other-network-libraries
if (process.env.NODE_ENV === 'production') {
  LogRocket.init('0xqcnb/soc', {
    shouldParseXHRBlob: true,
    network: {
      requestSanitizer: request => {
        // Redact passwords in GraphQL queries
        const passwordFieldRegex = /"password":\s*"((\\"|[^"])*)"/
        return {
          ...request,
          body:
            request.body && typeof request.body === 'string'
              ? request.body.replace(
                  passwordFieldRegex,
                  '"password":"********"',
                )
              : request.body,
        }
      },
    },
  })
}

export default LogRocket
