import React from 'react'
import { coachingBadges, CoachingBadgeType } from './coachingBadgeSVGs'

type CertifiedCoachBadgeType = {
  badge: CoachingBadgeType
  onClick?: () => void
  size: number
}

export const CertifiedCoachBadge: React.FC<CertifiedCoachBadgeType> = ({
  badge,
  onClick,
  size,
}) => coachingBadges[badge](size, size)
