import React from 'react'

interface Props {
  pointsRefunded: number
  isCreditCardPayment: boolean
  expenseRefunded: number
}

const Refund: React.FunctionComponent<Props> = props => {
  const { pointsRefunded, isCreditCardPayment, expenseRefunded } = props
  const spanStyle = {
    color: 'black',
    opacity: 0.5,
    display: 'block',
  }
  return (
    <div>
      {pointsRefunded > 0 && (
        <span style={spanStyle}>
          Points Refunded: <strong>{pointsRefunded}</strong>
        </span>
      )}
      {expenseRefunded > 0 && (
        <span style={spanStyle}>
          Expense Refunded: <strong>${expenseRefunded.toFixed(2)}</strong>
        </span>
      )}
      {isCreditCardPayment && (
        <span style={spanStyle}>
          Pending charges to credit card have been canceled!
        </span>
      )}
    </div>
  )
}

export default Refund
