import {
  CreateContactInput,
  Operation,
} from '../../../__generated__/globalTypes'

import {
  ContactFragment,
  contactFragmentString,
} from '../../fragments/__generated__/ContactFragment'

import { optionalYearDateFragmentString } from '../../fragments/__generated__/OptionalYearDateFragment'

import createContactsRawString from '../CreateContacts.graphql'

const createContactsString = [
  createContactsRawString,
  contactFragmentString,
  optionalYearDateFragmentString,
].join('\n\n')

export type CreateContactsData = {
  createContacts: {
    contacts: ContactFragment[]
    failedContacts: ContactFragment[]
  }
}

export type CreateContactsVariables = {
  contacts: CreateContactInput[]
}

export type CreateContacts = Operation<
  CreateContactsData,
  CreateContactsVariables
>

export const CreateContacts = ({
  contacts,
}: CreateContactsVariables): CreateContacts => ({
  query: createContactsString,
  variables: {
    contacts: contacts.map(x => CreateContactInput(x)),
  },
})
