import { Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
// @src imports
import { ProfileAvatar } from 'src/chrome'
import { DetailedContactFragment } from 'src/legacy_graphql'

import styles from './detailHeader.module.scss'

interface DetailHeaderProps {
  contact: DetailedContactFragment
}

const DetailHeader: React.FC<DetailHeaderProps> = props => {
  const { contact } = props
  return (
    <div className={styles.container}>
      <div className={styles.avatar}>
        <ProfileAvatar
          account={contact}
          size={100}
          style={
            {
              fontSize: '2.5rem',
              color: '#fff',
              letterSpacing: 1,
            } as React.CSSProperties
          }
        />
      </div>
      <div className={styles.details}>
        <Text type="title">{`${contact.firstName} ${contact.lastName}`}</Text>
        <div className={styles.contactInfo}>
          {contact.homePhone ? (
            <div>
              <Text type="caption">Phone</Text>
              <a href={`tel:${contact.homePhone}`}>{`${contact.homePhone}`}</a>
            </div>
          ) : null}
          {contact.emailAddress ? (
            <div>
              <Text type="caption">Email</Text>
              <a
                href={`mailto:${contact.emailAddress}`}
              >{`${contact.emailAddress}`}</a>
            </div>
          ) : null}
          <div>
            <Text type="caption">Location</Text>
            <Text
              type="body"
              color="primaryHeading"
            >{`${contact.city}, ${contact.state}`}</Text>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DetailHeader
