import { makeStyles } from '@material-ui/core'
import { cssStyles } from 'src/styled'

const lightGray = '#e8e8e8'

export const drawerStyles = makeStyles(theme => ({
  campaignDrawer: { textTransform: 'none' },
  drawerPaper: {
    zIndex: 111,
    left: 'initial !important',
    right: 0,
    width: '350px',
    maxWidth: '100% !important',
    animation: '$slideIn 0.6s cubic-bezier(0.4, 0, 0.2, 1)',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      animationName: '$slideInMobile',
    },
  },
  '@keyframes slideIn': {
    '0%': {
      right: '-350px',
    },
    '40%': {
      right: 0,
      width: '360px',
    },
    '60%': {
      width: '340px',
    },
  },
  '@keyframes slideInMobile': {
    '0%': {
      right: '-100%',
    },
    '45%': {
      right: 0,
    },
  },
}))

export const styles = cssStyles({
  drawerContents: {
    overflowY: 'auto',
    height: 'calc(100vh - 50px)',
  },
  drawerHeader: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    backgroundColor: lightGray,
    span: {
      color: '#6f8394 !important',
      fontWeight: 400,
    },
    '@media (max-width: 600px)': {
      position: 'fixed',
      width: '100%',
      zIndex: 10,
    },
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 600px)': {
      minHeight: '55px',
    },
  },
  cardThumbnail: {
    backgroundSize: 'cover',
    marginRight: '25px',
  },
  details: {
    padding: '10px',
    '@media (max-width: 600px)': {
      marginTop: '155px',
    },
  },
  gift: {
    margin: 'auto',
    textAlign: 'center',
  },
  giftImage: {
    margin: 'auto',
    marginBottom: '7px',
    height: '195px',
    width: '195px',
    backgroundSize: 'cover',
  },
  giftDescription: {
    display: 'flex',
    justifyContent: 'center',
  },
  giftTitle: {
    marginRight: '10px',
  },
  sendDelayInfo: {
    paddingLeft: '15px',
    position: 'relative',
    ':hover': {
      span: {
        display: 'block',
        position: 'absolute',
        bottom: '23px',
        right: 0,
        height: 'auto',
        width: '294px',
        background: '#fff',
        boxShadow: '0 2px 9px 0 rgba(0, 0, 0, 0.3)',
        borderRadius: '2px',
        transition: 'none',
        zIndex: 1,
        padding: '15px',
        div: {
          height: 'auto',
          width: 'auto',
        },
      },
    },
  },
  sendDelayTooltip: {
    display: 'none',
    transition: 'none',
    width: 0,
    height: 0,
    div: {
      width: 0,
      height: 0,
    },
  },
  drawerActions: {
    display: 'flex',
    justifyContent: 'space-evenly',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: '10px 0',
    backgroundColor: lightGray,
    '@media (max-width: 420px)': {
      position: 'fixed',
      background: '#fff',
      bottom: '0px !important',
      width: '100%',
      padding: '7px 0',
      zIndex: 1000,
    },
  },
  drawerActionsButton: {
    width: '150px',
    margin: 0,
  },
  subTitle: {
    lineHeight: 'initial',
    margin: 0,
    padding: '0 0 20px',
    color: '#646464',
    fontSize: '16px',
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '> span': {
      margin: '0 5px',
    },
  },
})
