import pick from 'lodash/pick'

import { TextFragment } from 'src/editor/types'
import {
  ColorFragment,
  ElementTextFragment,
  ParagraphFragment,
  TextAlignment,
  TextFragmentFragment,
} from 'src/graphql/generated/graphql'

export const defaultFragment: TextFragment = {
  fontId: 'f631775b-a440-48f7-8115-bbfb0124728f',
  fontSize: 16,
  color: { __typename: 'Color', red: 0, green: 0, blue: 0 },
  textAlign: TextAlignment.Center,
}

export const signatureCharacter = 'æ'

type IncompleteFragment = {
  fontId?: string | null
  fontSize?: number
  color?: ColorFragment | null
  text?: string | null
  signatureFontId?: string | null
  textAlign?: TextAlignment | null
}

const isCompleteFragment = (
  fragment: IncompleteFragment,
): fragment is TextFragment =>
  !!(fragment.fontId && fragment.fontSize && fragment.color)

const fragments = (paragraphs: ParagraphFragment[]): TextFragment[] => {
  const incompleteFragments = incompleteFragmentsForParagraphs(paragraphs)
  if (incompleteFragments.length === 0) {
    return [defaultFragment]
  }
  const firstCompleteFragment = incompleteFragments.find(isCompleteFragment)
  return incompleteFragments.reduce(
    ({ lastProperties, fragments }, fragment) => {
      const completeFragment: TextFragment = {
        fontId: fragment.fontId ?? lastProperties.fontId,
        fontSize: fragment.fontSize ?? lastProperties.fontSize,
        color: fragment.color ?? lastProperties.color,
        textAlign: fragment.textAlign ?? undefined,
        text: fragment.text ?? undefined,
        signatureFontId: fragment.signatureFontId ?? undefined,
      }
      return {
        lastProperties: pick(completeFragment, 'fontId', 'fontSize', 'color'),
        fragments: [...fragments, completeFragment],
      }
    },
    {
      lastProperties: pick(
        firstCompleteFragment ?? defaultFragment,
        'fontId',
        'fontSize',
        'color',
      ),
      fragments: Array<TextFragment>(),
    },
  ).fragments
}

const incompleteFragment = (
  text: string,
  textFragment: TextFragmentFragment,
): IncompleteFragment => ({
  fontId: text === signatureCharacter ? undefined : textFragment.font.id,
  fontSize: textFragment.fontSize,
  color: textFragment.textColor,
  text,
  signatureFontId:
    text === signatureCharacter ? textFragment.font.id : undefined,
})

const incompleteFragmentsForParagraphs = (
  paragraphs: ParagraphFragment[],
): IncompleteFragment[] =>
  paragraphs.flatMap(paragraph => [
    { textAlign: paragraph.alignment },
    ...paragraph.textFragments.flatMap(textFragment =>
      textFragment.isReplacement
        ? [incompleteFragment(textFragment.text, textFragment)]
        : [...textFragment.text].map(text =>
            incompleteFragment(text, textFragment),
          ),
    ),
  ])

const ElementText = (elementText: ElementTextFragment) =>
  fragments(elementText.paragraphs)

export default ElementText
