// @ts-ignore: implicitly has an 'any' type
import injectSheet from 'react-jss'

function wrappedInjectSheet<T>(
  styles: { [key: string]: object },
  component: object,
): T {
  return injectSheet(styles)(component)
}

export default wrappedInjectSheet
