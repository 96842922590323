import React, { FC } from 'react'
import { Div, Text } from '@sendoutcards/quantum-design-ui'
import {
  HOCSpacingKeys,
  LiteralUnion,
  Responsive,
} from '@sendoutcards/quantum-design-ui/dist/src/helpers/hoc-types/entityValueTypes'
import { BorderRadius } from '@sendoutcards/quantum-design-ui/dist/src/helpers/hoc-types/hocBaseUnion'

type OrientationType = 'row' | 'column' | 'row-reverse' | 'column-reverse'

type TitleProps = {
  content: string
  fontSize?: string
  fontColor?: string
  shouldUnderLine?: boolean
}

export type ButtonProps = {
  title?: TitleProps
  fill?: string
  padding?: string
  fontSize?: string
  onClick?: () => void
  id?: string
  borderRadius?: BorderRadius
  isOutlined?: boolean
  borderColor?: string
  textColorOverride?: string
  isDisabled?: boolean
  gap?: Responsive<LiteralUnion<HOCSpacingKeys>> | LiteralUnion<HOCSpacingKeys>
  orientation?: OrientationType
  isFullWidth?: boolean
  boxShadow?: string
}

export const Button: FC<ButtonProps> = ({
  title,
  fill,
  padding,
  onClick,
  id,
  borderRadius,
  isOutlined,
  borderColor,
  isDisabled,
  gap = 'x1',
  orientation = 'row',
  isFullWidth,
  boxShadow,
  children,
}) => {
  return (
    <Div
      id={id}
      display="flex"
      justifyContent="center"
      alignItems="center"
      onClick={isDisabled ? undefined : onClick}
      inset={padding}
      backgroundColor={isOutlined && !borderColor ? 'transparent' : fill}
      borderColor={isOutlined ? borderColor ?? fill : undefined}
      borderStyle={isOutlined ? 'solid' : 'none'}
      borderWidth="default"
      borderRadius={borderRadius}
      opacity={isDisabled ? 0.35 : 1}
      cursor="pointer"
      flexDirection={orientation}
      columnGap={gap}
      rowGap={gap}
      height="fit-content"
      width={isFullWidth ? '100%' : 'fit-content'}
      boxShadow={boxShadow}
    >
      {children}
      {title && (
        <Text
          type="body"
          content={title.content}
          style={{
            fontSize: title.fontSize,
            fontWeight: 700,
            color: title.fontColor ?? '#000',
            textDecoration: title.shouldUnderLine ? 'underline' : 'none',
          }}
          color="primaryHeading"
          lineHeight={1.2}
          whiteSpace="nowrap"
        />
      )}
    </Div>
  )
}
