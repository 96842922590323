import React from 'react'
import {
  DirectionType,
  Div,
  Flex,
  Icon,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { useSelector } from 'src/hooks'
import HoverTooltip from '../HoverTooltip/HoverTooltip'
import {
  HOCSpacingKeys,
  LiteralUnion,
  SpacingDirection,
} from '@sendoutcards/quantum-design-ui/dist/src/helpers/hoc-types/entityValueTypes'

export interface PriceProps {
  /**
   * The main price of something. When used in combination with discountPrice
   * it will become a scratched price underneath the main price display.
   */
  price: string
  /**
   * When NOT undefined will be displayed as the main price tag and
   * the price prop will be displayed scratched underneath.
   */
  discountPrice?: string
  discountMessage?: string
  priceBreakDown?: {
    prices: string[]
    tooltipMessage?: string
  }
  direction?: 'column' | 'row'
  tooltipDirection?: DirectionType
  inset?: SpacingDirection | LiteralUnion<HOCSpacingKeys>
}

/**
 *
 * This component displays a Price allowing to also display discounted
 * prices with the original price scratched for reference.
 * @param price The main price of something. When used in combination with discountPrice it will
 * become a scratched price underneath the main price display.
 * @param discountPrice When NOT undefined will be displayed as the
 * main price tag and the price prop value will be displayed scratched underneath.
 */
const Price: React.FC<PriceProps> = props => {
  const {
    price,
    discountPrice,
    discountMessage,
    priceBreakDown,
    direction = 'column',
    tooltipDirection,
    inset,
  } = props
  const { width } = useSelector(state => state.window)
  const isMobile = width <= 542

  const maxLength = 11
  const typePrice = isMobile
    ? 'body'
    : (price && !discountPrice && price.length > maxLength) ||
      (discountPrice && discountPrice.length > maxLength)
    ? 'largeBody'
    : 'subtitle'
  const typeStrikedThrough = isMobile
    ? 'caption'
    : price && discountPrice && price.length > maxLength
    ? 'body'
    : 'largeBody'

  return (
    <Flex flexDirection={direction} inset={inset} alignItems="center">
      <Text
        type={typePrice}
        color="primaryHeading"
        weight="semiBold"
        content={discountPrice ? discountPrice : price}
        whiteSpace="nowrap"
      />
      {discountPrice && (
        <HoverTooltip
          tooltipDirection={tooltipDirection}
          tooltipChildren={
            <div style={isMobile ? { width: '190px' } : undefined}>
              <Text
                type="footnote"
                color="accent"
                whiteSpace={isMobile ? 'normal' : 'pre'}
              >
                {discountMessage}
              </Text>
            </div>
          }
          shouldDisplayTooltip={discountMessage ? true : false}
          isFullWidth={false}
          background="dark"
          shouldSupportOnTapAsHover={true}
        >
          <Flex
            justifyContent="center"
            alignItems="center"
            inset={{ left: direction === 'row' ? 'x1' : 'x0' }}
          >
            <Icon name="information" size="small" primaryColor="danger" />
            <Text
              type={typeStrikedThrough}
              isStrikeThrough={true}
              color="danger"
              content={price}
              whiteSpace="nowrap"
              style={{ MozUserSelect: 'none' }}
              outset={{ left: 'x_5' }}
            />
          </Flex>
        </HoverTooltip>
      )}
      {priceBreakDown && (
        <HoverTooltip
          title={priceBreakDown.tooltipMessage}
          shouldDisplayTooltip={true}
          isFullWidth={true}
          background="dark"
        >
          <Div
            position="relative"
            left="-10px"
            style={{ MozUserSelect: 'none' }}
          >
            <Text
              type="footnote"
              alignment="center"
              content={`(${priceBreakDown.prices.join(' + ')})`}
            />
          </Div>
        </HoverTooltip>
      )}
    </Flex>
  )
}

export default Price
