import { ConfirmDialog } from '@sendoutcards/quantum-design-ui'
import React, { FC } from 'react'

export type ReplaceCardDialogProps = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  onAccept: () => void
  onDecline: () => void
}

export const ReplaceCardDialog: FC<ReplaceCardDialogProps> = ({
  isOpen,
  setIsOpen,
  onAccept,
  onDecline,
}) => {
  return (
    <ConfirmDialog
      title={'Are you sure you would like to replace this card?'}
      description={
        'Any edits you made to the card you have been creating will be lost.'
      }
      onClose={() => setIsOpen(false)}
      isOpen={isOpen}
      accept={{
        id: 'continue_replace_card_btn',
        title: 'Continue',
        onClick: onAccept,
      }}
      decline={{
        title: 'Cancel',
        onClick: onDecline,
      }}
    />
  )
}
