import { Div, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'

import { Icon } from 'src/chrome'

type Props = {
  title: string
  onEdit?: () => void
  id?: string
}

const style: React.CSSProperties = {
  marginLeft: 15,
  top: -6,
  cursor: 'pointer',
}

const SectionTitle: React.FC<Props> = props => {
  const { title, onEdit, id } = props

  return (
    <>
      <div>
        <Div display="inline-block">
          <Text type="body" weight="bold">
            {`${title}`}
          </Text>
        </Div>

        {onEdit && (
          <span onClick={() => onEdit()} style={style}>
            <Icon icon={'EDIT'} color={'black'} size={16} id={id} />
          </span>
        )}
      </div>
      <Spacer space="x_5" />
    </>
  )
}

export default SectionTitle
