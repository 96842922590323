import { Flex, Icon, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import HoverTooltip from 'src/chrome/HoverTooltip/HoverTooltip'
import { TotalDiscountsPlanIcon } from 'src/coaching/svg/TotalDiscountsPlanIcon'
import { formatCostToNegative } from 'src/helpers/formatCost'
import { useSelector } from 'src/hooks'

export type DiscountItem = {
  title: string
  subtitle?: string
  info?: string
  value: number
  shouldNotFormat?: boolean
}

type TotalDiscountsType = {
  title: string
  planDescription: string
  discountData: DiscountItem[]
}

export const TotalDiscounts: React.FC<TotalDiscountsType> = ({
  title,
  planDescription,
  discountData,
}) => {
  const discountTotal = discountData.reduce(
    (total, item) => total + item.value,
    0,
  )
  const isMobile = useSelector(state => state.window.width < 625)
  const isSmallMobile = useSelector(state => state.window.width < 490)

  return (
    <Flex
      id="total_discount_parent_container"
      flexDirection="column"
      width="100%"
      backgroundColor="foreground"
      borderRadius="8px"
      style={{ border: '2px dashed rgba(32, 150, 111, 0.3)' }}
      inset={{ vertical: '16px', horizontal: '12px' }}
    >
      <Flex
        id="title_and_total_discount_container"
        outset={{ bottom: '8px' }}
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Text
          type="largeBody"
          weight="bold"
          content={title}
          style={{
            fontSize: isSmallMobile ? '13pt' : isMobile ? '15pt' : '17pt',
            color: '#323232',
            marginRight: '16px',
          }}
        />
        <Flex>
          <Text
            alignment="right"
            type="largeBody"
            weight="bold"
            content={discountTotal ? formatCostToNegative(discountTotal) : ''}
            style={{
              fontSize: isSmallMobile ? '13pt' : isMobile ? '15pt' : '17pt',
              color: '#323232',
            }}
          />
        </Flex>
      </Flex>
      <Flex
        id="plan_description_container"
        alignItems="center"
        width="100%"
        outset={{ bottom: '8px' }}
      >
        <TotalDiscountsPlanIcon />
        <Text
          outset={{ left: 'x_5' }}
          type="caption"
          content={planDescription}
          color="primaryBody"
          style={{
            fontSize: isMobile ? '11pt' : '13pt',
            fontWeight: 'bold',
            color: '#686868',
          }}
        />
      </Flex>
      {discountData?.map(item => (
        <Flex
          id="discount_data_container"
          inset={{ left: '12px' }}
          key={item.title}
          justifyContent="space-between"
          alignItems="center"
        >
          <HoverTooltip
            tooltipChildren={
              <div style={isMobile ? { width: '190px' } : undefined}>
                <Text
                  type="footnote"
                  color="accent"
                  whiteSpace={isMobile ? 'normal' : 'pre'}
                >
                  {item.info}
                </Text>
              </div>
            }
            shouldDisplayTooltip={item.info ? true : false}
            isFullWidth={false}
            background="dark"
            shouldSupportOnTapAsHover={true}
          >
            <Flex flexDirection="column" outset={{ bottom: '8px' }}>
              <Flex alignItems="center">
                <Text
                  type="caption"
                  content={item.title}
                  style={{
                    fontSize: '11px',
                    fontWeight: 700,
                    cursor: 'default',
                  }}
                />
                {item.info && (
                  <Icon
                    name="information"
                    size="xSmall"
                    primaryColor="primaryBodyText"
                  />
                )}
              </Flex>
              {item.subtitle && (
                <Text
                  outset={{ top: '-8px' }}
                  type="caption"
                  content={item.subtitle}
                  style={{ fontSize: '11px', fontWeight: 500 }}
                />
              )}
            </Flex>
          </HoverTooltip>
          <Flex outset={{ bottom: '8px' }}>
            <Text
              alignment="right"
              type="caption"
              outset={{ left: 'x1' }}
              content={
                item.shouldNotFormat
                  ? `-${item.value}`
                  : formatCostToNegative(item.value)
              }
              style={{
                fontSize: isMobile ? '13px' : '15px',
                fontWeight: 700,
              }}
            />
          </Flex>
        </Flex>
      ))}
    </Flex>
  )
}
