import { Div, Text, VStack } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { useSelector } from 'src/hooks'

interface Props {
  step: number
  title: string
  description: string
}

export const FormHeader: React.FC<Props> = props => {
  const { step, title, description } = props

  const isRecipientBreakPoint = useSelector(state => state.window.width < 820)
  const isTextWrapPoint = useSelector(state => state.window.width > 890)

  return (
    <Div>
      <VStack gap="x1">
        <Text
          content={`Step ${step}`}
          type="caption"
          color="secondaryBrand"
          weight="bold"
          lineHeight={1}
          style={{ fontSize: '.75rem' }}
        />
        <Text
          content={title}
          type={isRecipientBreakPoint ? 'largeBody' : 'subtitle'}
          color="primaryHeading"
          weight="bold"
        />
        <Text
          content={description}
          type={isRecipientBreakPoint ? 'caption' : 'body'}
          color="primaryBody"
          lineHeight={1.4}
          whiteSpace={isTextWrapPoint ? 'nowrap' : 'normal'}
          outset={{ bottom: isRecipientBreakPoint ? 'x1' : 'x3' }}
        />
      </VStack>
    </Div>
  )
}
