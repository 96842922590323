import React, { FC } from 'react'

import { SmallOrLarge } from 'src/app/types'
import { FormattedPrice } from 'src/marketing/plans/components'

import styles from './styles'
import { Text, TextColor } from '@sendoutcards/quantum-design-ui'

type SubscriptionPriceProps = {
  dollars: string | number
  cents: string | number
  per: string
  color?: TextColor
  size?: SmallOrLarge
}

const SubscriptionPrice: FC<SubscriptionPriceProps> = props => {
  const { dollars, cents, per, color = 'primaryBrand', size = 'large' } = props
  return (
    <div css={styles.wrapper}>
      <FormattedPrice
        dollars={dollars}
        cents={cents}
        color={color}
        size={size}
      />
      <Text type="caption" color={color}>
        {' '}
        {`/ ${per}`}
      </Text>
    </div>
  )
}

export default SubscriptionPrice
