import {
  UpdateInviteInput,
  Operation,
} from '../../../__generated__/globalTypes'

import {
  InviteFragment,
  inviteFragmentString,
} from '../../fragments/__generated__/InviteFragment'

import { calendarDateFragmentString } from '../../fragments/__generated__/CalendarDateFragment'

import { detailedContactFragmentString } from '../../fragments/__generated__/DetailedContactFragment'

import { noteFragmentString } from '../../fragments/__generated__/NoteFragment'

import { addressFragmentString } from '../../fragments/__generated__/AddressFragment'

import { relatedContactFragmentString } from '../../fragments/__generated__/RelatedContactFragment'

import { socProContactFragmentString } from '../../fragments/__generated__/SocProContactFragment'

import { contactFragmentString } from '../../fragments/__generated__/ContactFragment'

import { optionalYearDateFragmentString } from '../../fragments/__generated__/OptionalYearDateFragment'

import { prospectFragmentString } from '../../fragments/__generated__/ProspectFragment'

import updateInviteRawString from '../UpdateInvite.graphql'

const updateInviteString = [
  updateInviteRawString,
  inviteFragmentString,
  calendarDateFragmentString,
  detailedContactFragmentString,
  noteFragmentString,
  addressFragmentString,
  relatedContactFragmentString,
  socProContactFragmentString,
  contactFragmentString,
  optionalYearDateFragmentString,
  prospectFragmentString,
].join('\n\n')

export type UpdateInviteData = {
  updateInvite: {
    invite: InviteFragment
  }
}

export type UpdateInviteVariables = {
  invite: UpdateInviteInput
}

export type UpdateInvite = Operation<UpdateInviteData, UpdateInviteVariables>

export const UpdateInvite = ({
  invite,
}: UpdateInviteVariables): UpdateInvite => ({
  query: updateInviteString,
  variables: {
    invite: UpdateInviteInput(invite),
  },
})
