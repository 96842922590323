import { Maybe, Operation } from '../../../__generated__/globalTypes'

import {
  GiftVariationFragment,
  giftVariationFragmentString,
} from '../../fragments/__generated__/GiftVariationFragment'

import { priceFragmentString } from '../../fragments/__generated__/PriceFragment'

import { amountFragmentString } from '../../fragments/__generated__/AmountFragment'

import getGiftRawString from '../GetGift.graphql'

const getGiftString = [
  getGiftRawString,
  giftVariationFragmentString,
  priceFragmentString,
  amountFragmentString,
].join('\n\n')

export type GetGiftData = {
  gift: {
    id: string
    realId: string
    __typename: 'Gift'
    name: string
    defaultImageUrl: string
    defaultVariation: Maybe<GiftVariationFragment>
    variations: GiftVariationFragment[]
  }
}

export type GetGiftVariables = {
  id?: Maybe<string>
  slug?: Maybe<string>
}

export type GetGift = Operation<GetGiftData, GetGiftVariables>

export const GetGift = ({ id, slug }: GetGiftVariables = {}): GetGift => ({
  query: getGiftString,
  variables: {
    id,
    slug,
  },
})
