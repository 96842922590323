import {
  Div,
  Flex,
  Input,
  LoadingSpinner,
  Text,
} from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { useSelector, useState } from 'src/hooks'
import { Memoized } from 'src/hooks/dependencies'
import AccountSponsorSelector from 'src/onboarding/components/AccountSponsorSelector'
import { CreateAccountPhoneInput } from './CreateAccountPhoneInput'

type InputType = {
  email: string
  username: string
  password: string
  phoneNumber: string
  firstName: string
  lastName: string
}
type ErrorType = {
  email: string | undefined
  username: string | null | undefined
  password: string | null | undefined
  phoneNumber: string | null | undefined
  firstName: string | null | undefined
  lastName: string | null | undefined
  universal?: string | null | undefined
}

interface Props {
  setData: (input: InputType) => void
  data: InputType
  errors?: ErrorType
  isLoading: boolean
  isLoadingEmailValidation: boolean
  isLoadingUsernameValidation: boolean
  shouldNotAutoComplete?: boolean
  shouldStackForm?: boolean
  handleSponsorChanges?: Memoized<
    (userId: string, genealogyId?: string) => void
  >
  handleIsCheckingPhoneNumber: Memoized<
    (isCheckingPhoneNumber: boolean) => void
  >
  handleIsPhoneNumberTaken: Memoized<(isTaken: boolean) => void>
  handleIsPhoneNumberInvalid: Memoized<(isInvalid: boolean) => void>
  displayFields?: {
    name?: boolean
    username?: boolean
    password?: boolean
    email?: boolean
    phoneNumber?: boolean
  }
}

export const RawCreateAccountForm: React.FC<Props> = props => {
  const {
    setData,
    data,
    isLoading,
    isLoadingEmailValidation,
    isLoadingUsernameValidation,
    errors,
    shouldNotAutoComplete,
    shouldStackForm = false,
    handleSponsorChanges,
    handleIsCheckingPhoneNumber,
    handleIsPhoneNumberTaken,
    handleIsPhoneNumberInvalid,
    displayFields,
  } = props
  const isMobile = useSelector(state => state.window.width < 980)
  const shouldStack = isMobile || shouldStackForm

  const [phoneNumber, setPhoneNumber] = useState('')
  const handleSetPhoneNumber = (pNumber: string) => {
    setPhoneNumber(pNumber)
    setData({ ...data, phoneNumber: pNumber })
  }

  return (
    <Flex flexDirection="column">
      {isLoading ? (
        <Flex
          justifyContent="center"
          alignItems="center"
          minHeight={shouldStack ? '372px' : '314px'}
          flexDirection="column"
        >
          <Div outset={{ bottom: 'x4' }}>
            <LoadingSpinner size="large" />
          </Div>
          <Text
            type={shouldStack ? 'body' : 'subtitle'}
            content="Creating account . . ."
          />
        </Flex>
      ) : (
        <>
          {(!displayFields || displayFields.name) && (
            <Flex
              justifyContent="space-between"
              width="100%"
              flexDirection="row"
            >
              <Div width="50%" outset={{ right: 'x1_5', vertical: 'x_75' }}>
                <Input
                  message={
                    errors?.firstName && !errors?.universal
                      ? { type: 'danger', content: errors.firstName }
                      : undefined
                  }
                  type="text"
                  onChange={firstName => setData({ ...data, firstName })}
                  value={data.firstName}
                  placeholder="First Name"
                  isFullWidth={true}
                />
              </Div>
              <Div width="50%" outset={{ vertical: 'x_75' }}>
                <Input
                  message={
                    errors?.lastName && !errors?.universal
                      ? { type: 'danger', content: errors.lastName }
                      : undefined
                  }
                  type="text"
                  onChange={lastName => setData({ ...data, lastName })}
                  value={data.lastName}
                  placeholder="Last Name"
                  isFullWidth={true}
                />
              </Div>
            </Flex>
          )}
          <Flex
            justifyContent="space-between"
            width="100%"
            flexDirection={shouldStack ? 'column' : 'row'}
          >
            {(!displayFields || displayFields.email) && (
              <Div
                width={shouldStack ? '100%' : 'calc(100% / 2)'}
                outset={shouldStack ? { vertical: 'x_75' } : { right: 'x1_5' }}
              >
                <Input
                  message={
                    errors?.email && !errors?.universal
                      ? { type: 'danger', content: errors.email }
                      : undefined
                  }
                  type="email"
                  onChange={email => setData({ ...data, email })}
                  value={data.email}
                  placeholder="Email"
                  isFullWidth={true}
                  isLoading={isLoadingEmailValidation}
                />
              </Div>
            )}
            {(!displayFields || displayFields.phoneNumber) && (
              <Div width="100%" outset={{ vertical: 'x_75' }}>
                <CreateAccountPhoneInput
                  phoneNumber={phoneNumber}
                  handleSetPhoneNumber={handleSetPhoneNumber}
                  handleIsCheckingPhoneNumber={handleIsCheckingPhoneNumber}
                  handleIsPhoneNumberTaken={handleIsPhoneNumberTaken}
                  handleIsPhoneNumberInvalid={handleIsPhoneNumberInvalid}
                />
              </Div>
            )}
            {(!displayFields || displayFields.username) && (
              <Div
                width={shouldStack ? '100%' : 'calc(100% / 2)'}
                outset={shouldStack ? { vertical: 'x_75' } : { left: 'x1_5' }}
              >
                <Input
                  message={
                    errors?.username && !errors?.universal
                      ? { type: 'danger', content: errors.username }
                      : undefined
                  }
                  isFullWidth={true}
                  type="text"
                  onChange={username => setData({ ...data, username })}
                  value={data.username}
                  placeholder="Username"
                  autoComplete={shouldNotAutoComplete ? 'false' : undefined}
                  isLoading={isLoadingUsernameValidation}
                />
              </Div>
            )}
          </Flex>
          {(!displayFields || displayFields.password) && (
            <Div width="100%" outset={{ top: shouldStack ? 'x_75' : 'x2' }}>
              <Input
                message={
                  errors?.password && !errors?.universal
                    ? { type: 'danger', content: errors.password }
                    : undefined
                }
                type="password"
                onChange={password => setData({ ...data, password })}
                value={data.password}
                placeholder="Password"
                isFullWidth={true}
                autoComplete={
                  shouldNotAutoComplete ? 'new-password' : undefined
                }
              />
            </Div>
          )}
          {handleSponsorChanges && (
            <Div
              maxWidth={shouldStack ? '100%' : '450px'}
              outset={{ top: shouldStack ? 'x1_5' : 'x2' }}
            >
              <AccountSponsorSelector
                isAffiliateJoining={false}
                handleSponsorChanges={handleSponsorChanges}
              />
            </Div>
          )}
        </>
      )}
      {errors?.universal && (
        <Text
          outset={{ top: 'x1', left: 'x_5' }}
          content={errors?.universal?.toString()}
          type="caption"
          color="danger"
        />
      )}
    </Flex>
  )
}
