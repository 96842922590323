import { Maybe, Operation } from '../../../__generated__/globalTypes'

import getPromptingsAcademyLoginUrlString from '../GetPromptingsAcademyLoginUrl.graphql'

export type GetPromptingsAcademyLoginUrlData = {
  account: {
    promptinguLoginUrl: Maybe<string>
  }
}

export type GetPromptingsAcademyLoginUrl = Operation<
  GetPromptingsAcademyLoginUrlData
>

export const GetPromptingsAcademyLoginUrl = (): GetPromptingsAcademyLoginUrl => ({
  query: getPromptingsAcademyLoginUrlString,
  variables: undefined,
})
