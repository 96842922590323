import React from 'react'
import MaterialMenuItem from '@material-ui/core/MenuItem'

import { Icon, Icons } from 'src/chrome'

import styles from '../contactActionBar.module.scss'
import { HStack, Text } from '@sendoutcards/quantum-design-ui'

interface Props {
  onClick: () => void
  icon: Icons
  text: string
  isDisabled?: boolean
  iconViewBox?: string
  show?: Array<'sm' | 'md'>
  id?: string
}

const MenuItem: React.FC<Props> = props => {
  const { onClick, isDisabled, icon, iconViewBox, text, show = [], id } = props

  return (
    <MaterialMenuItem
      id={id}
      className={`
      ${show.length ? styles.hide : ''}
      ${show.includes('sm') ? styles.showSm : ''}
      ${show.includes('md') ? styles.showMd : ''}
    `}
      onClick={onClick}
      css={{
        '.MuiMenuItem-root': {
          display: 'flex',
          'align-items': 'center',
          'flex-wrap': 'nowrap',
        },
      }}
    >
      <HStack gap="x2" justify="start">
        <Icon
          icon={icon}
          color={isDisabled ? '#6f8394' : '#ff5689'}
          size={20}
          viewBox={iconViewBox}
        />
        <Text type="body" color="primaryHeading">
          {text}
        </Text>
      </HStack>
    </MaterialMenuItem>
  )
}

export default MenuItem
