import React from 'react'
import { CardFragment } from 'src/graphql/generated/graphql'
import { useActions, useState } from 'src/hooks'
import { OrderApiType } from 'src/orders/api'
import CartButton from 'src/orders/components/OrderContextBar/components/ViewCartButton'
import {
  OrderPreview,
  OrderPreviewActionOverride,
} from 'src/orders/components/OrderPreview/OrderPreview'

export type MiniCartProps = {
  containerId: string
  orderId: string
  previewZIndex: number
  button: {
    onClick?: () => void
    count?: number
  }
  height?: string
  orderApi?: OrderApiType
  orderPreviewActionOverride?: OrderPreviewActionOverride
  isMobile?: boolean
  backgroundColor?: string
  actionType?: 'mobile' | 'desktop'
  checkoutAction?: {
    onCheckout?: () => void
    title?: string
  }
  editorCard?: CardFragment
}
export const MiniCart = (props: MiniCartProps) => {
  const {
    orderId,
    button,
    height,
    previewZIndex,
    orderApi,
    orderPreviewActionOverride,
    isMobile = false,
    checkoutAction,
    backgroundColor,
    actionType,
    editorCard,
  } = props
  const actions = useActions()
  const [isOpen, setIsOpen] = useState(false)
  const [isOPHandlingOutsideClick, setIsOPHandlingOutsideClick] = useState(
    false,
  )

  const handleOrderPreviewOutsideClick = () => {
    setIsOPHandlingOutsideClick(true)
    setIsOpen(false)
  }

  return (
    <>
      <CartButton
        isMobile={isMobile}
        id="view_mini_cart_btn"
        isActive={isOpen}
        total={button.count}
        onClick={() => {
          if (isOPHandlingOutsideClick) {
            setIsOPHandlingOutsideClick(false)
          } else {
            setIsOpen(!isOpen)
          }
          button.onClick?.()
        }}
        height={height}
        backgroundColor={backgroundColor}
        actionType={actionType}
      />
      <OrderPreview
        actionOverride={orderPreviewActionOverride}
        zIndex={previewZIndex}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        onContinueShopping={() => setIsOpen(false)}
        checkoutAction={{
          title: checkoutAction?.title,
          onCheckout: checkoutAction?.onCheckout
            ? () => {
                setIsOpen(false)
                checkoutAction.onCheckout?.()
              }
            : () => {
                setIsOpen(false)
                actions.openOrder(orderId)
              },
        }}
        orderItemCount={button.count}
        orderApi={orderApi}
        handleOutsideClick={handleOrderPreviewOutsideClick}
        editorCard={editorCard}
      />
    </>
  )
}
