export const panoramaStyles = (
  vertical: boolean,
  margin: number,
  containerWidth: number,
  containerHeight: number,
  totalLength: number,
  elementHeight: number,
  elementWidth: number,
  verticalContainerHeight: number | undefined,
  scale: number,
  shouldStyleArrows = true,
) => {
  const { height, width, scaleWidth, flexDirection } = vertical
    ? {
        height: totalLength,
        width: '100%',
        flexDirection: 'column',
        scaleWidth: elementWidth + margin * 2,
      }
    : {
        height: Math.min(elementHeight * scale, window.innerHeight - 125),
        width: totalLength,
        flexDirection: 'row',
        scaleWidth: elementWidth + margin * 4,
      }

  const edgeStyle: React.CSSProperties = {
      width: margin,
      height: (elementHeight + margin) * scale,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      top: 0,
    },
    centerContainer: React.CSSProperties =
      vertical && scaleWidth < containerWidth
        ? { display: 'flex', justifyContent: 'center' }
        : {}

  const containerStyle: React.CSSProperties = {
    ...centerContainer,
    width: '100%',
    height: height + margin,
    marginLeft: 'auto',
    marginRight: 'auto',
    overflow: 'hidden',
    position: 'relative',
  }

  return {
    perfContainerStyle: {
      flexDirection,
      display: 'flex',
      width,
      height,
      position: 'relative',
      bottom: height,
    } as React.CSSProperties,
    slideStyle: {
      width,
      height,
      ...(!vertical && { display: 'flex' }),
      flexDirection,
      marginLeft: 'auto',
      marginRight: 'auto',
      position: 'relative',
    } as React.CSSProperties,
    slideMarginStyle: {
      height: vertical ? margin : height,
      width: vertical ? width : margin,
    } as React.CSSProperties,
    containerStyle,
    scaleStyle: {
      transform: `scale(${scale || 1})`,
      transformOrigin: 'left top',
      padding: `5px ${margin}px 0`,
      width: scaleWidth,
    },
    blurLeftStyle: {
      ...edgeStyle,
      left: 0,
      marginRight: 'auto',
      ...(shouldStyleArrows && {
        backgroundImage:
          'linear-gradient(90deg, rgba(255,255,255, 0.95), rgba(255, 255, 255, 0))',
      }),
    },
    blurRightStyle: {
      ...edgeStyle,
      right: 0,
      marginLeft: 'auto',
      ...(shouldStyleArrows && {
        backgroundImage:
          'linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.95))',
      }),
    },
    arrowStyle: {
      zIndex: 2,
    },
    bottomBlurStyle: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      height: 25,
      background:
        'linear-gradient(0deg, rgb(244, 244, 244), rgba(255, 255, 255, 0.1))',
    } as React.CSSProperties,
  }
}
