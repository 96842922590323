import React from 'react'
import Icon from '@material-ui/core/Icon'
import { green, yellow } from '@material-ui/core/colors'
import CircularProgress from '@material-ui/core/CircularProgress'

import { getTotal, recipientCount } from 'src/helpers'
import {
  Button,
  DefaultError,
  RwdCell,
  RwdHeader,
  RwdRow,
  RwdTable,
} from 'src/chrome'

import styles from './campaignOrders.module.scss'
import suspenseBoundary from 'src/chrome/SuspenseBoundary/suspenseBoundaryHOC'
import {
  Anchor,
  Button as QDSButton,
  Div,
  Flex,
  LoadingSpinner,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { DetailedOrderFragment } from 'src/graphql/generated/graphql'
import { useCampaignOrders } from 'src/react_query'

type CampaignOrdersProps = {
  campaignId: string
  onResend: (order: DetailedOrderFragment) => void
  onViewInvoice: (order: DetailedOrderFragment) => void
  openOrder: (orderId: string) => void
  onViewRecipients: (order: DetailedOrderFragment | undefined) => void
}

const CampaignOrders: React.FC<CampaignOrdersProps> = props => {
  const {
    campaignId,
    onResend,
    onViewInvoice,
    openOrder,
    onViewRecipients,
  } = props

  const {
    data,
    fetchNextPage,
    isLoading: isInititialLoad,
    isFetchingNextPage,
    hasNextPage,
  } = useCampaignOrders({
    id: campaignId,
  })

  const isLoading = isInititialLoad || isFetchingNextPage
  const orders = data?.pages.flatMap(page => page.results)

  return (
    <div className={styles.listContainer}>
      <RwdTable>
        <RwdHeader>
          <th>
            <Text type="caption" color="primaryBrand">
              Order #
            </Text>
          </th>
          <th>
            <Text type="caption" color="primaryBrand">
              Status
            </Text>
          </th>
          <th>
            <Text type="caption" color="primaryBrand">
              Contacts
            </Text>
          </th>
          <th>
            <Text type="caption" color="primaryBrand">
              Total
            </Text>
          </th>
          <th>
            <Text type="caption" color="primaryBrand" alignment="center">
              Actions
            </Text>
          </th>
        </RwdHeader>
        <tbody>
          {orders && orders.length > 0
            ? orders.map((order: DetailedOrderFragment, index: number) => {
                return (
                  <React.Fragment key={order.id}>
                    <RwdRow>
                      <RwdCell mapToHeader="Order #">
                        <Text type="caption" color="primaryHeading">
                          {order.id}
                        </Text>
                      </RwdCell>
                      <RwdCell mapToHeader="Status">
                        <Icon
                          className="material-icons"
                          style={{
                            top: 4,
                            marginRight: 7,
                            color: order.isSent ? green[500] : yellow[500],
                          }}
                        >
                          {order.isSent ? 'check' : 'error'}
                        </Icon>
                        <Div display="inline-block">
                          <Text type="caption" color="primaryHeading">
                            {order.isSent ? 'sent' : 'created'}
                          </Text>
                        </Div>
                      </RwdCell>
                      <RwdCell mapToHeader="Contacts">
                        <Anchor
                          onClick={() => onViewRecipients(order)}
                          isDecorated={true}
                        >
                          <Text type="caption" color="primaryHeading">
                            {recipientCount(order)}{' '}
                            {`recipient${
                              recipientCount(order) !== 1 ? 's' : ''
                            }`}
                          </Text>
                        </Anchor>
                      </RwdCell>
                      <RwdCell mapToHeader="Total">
                        <Text type="caption" color="primaryHeading">
                          {getTotal(order.cost)}
                        </Text>
                      </RwdCell>
                      <RwdCell
                        mapToHeader="Actions"
                        className={styles.editOrder}
                      >
                        {!order.isSent ? (
                          <Button
                            title="Edit Order"
                            style={{ margin: 'auto' }}
                            onClick={() => openOrder(order.id)}
                          />
                        ) : (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <Button
                              id={`resend_order_${index}`}
                              title="Resend Order"
                              style={{ marginRight: 25 }}
                              onClick={() => onResend(order)}
                            />
                            <Button
                              title="View Invoice"
                              onClick={() => onViewInvoice(order)}
                            />
                          </div>
                        )}
                      </RwdCell>
                    </RwdRow>
                    <tr style={{ height: 2 }} />
                  </React.Fragment>
                )
              })
            : !isLoading && (
                <section className={styles.emptyOrder}>
                  <Text type="largeBody" color="primaryHeading">
                    You haven't created any orders from this Campaign yet.
                  </Text>
                </section>
              )}
        </tbody>
      </RwdTable>
      {hasNextPage && (
        <Flex justifyContent={'center'}>
          {isLoading ? (
            <LoadingSpinner size="medium" />
          ) : (
            <QDSButton
              onClick={() => fetchNextPage()}
              outlined={true}
              size="small"
            >
              Load More Orders
            </QDSButton>
          )}
        </Flex>
      )}
    </div>
  )
}

export default suspenseBoundary({
  component: CampaignOrders,
  unresolved: (
    <CircularProgress
      style={{
        marginLeft: 'calc(50% - 20px)',
      }}
    />
  ),
  failure: DefaultError,
})
