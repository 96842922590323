import { elementInViewport, executeWhenNoChangeAfterTimeout } from 'src/helpers'
import { useRef } from '.'

type Options = {
  triggerElement: React.RefObject<Element>
  isLoadingMore?: boolean
  hasMore?: boolean
  loadMore?: () => void
}

const useScroll = ({
  triggerElement,
  isLoadingMore,
  hasMore,
  loadMore,
}: Options) => {
  const mutableTimeout = useRef<number | undefined>(undefined)

  const tryLoadingMore = () => {
    if (
      !isLoadingMore &&
      elementInViewport(triggerElement.current) &&
      hasMore &&
      loadMore
    ) {
      loadMore()
    }
  }

  return () => {
    mutableTimeout.current = executeWhenNoChangeAfterTimeout(
      mutableTimeout.current,
      tryLoadingMore,
      250,
    )
  }
}

export default useScroll
