import { useDispatch } from 'react-redux'

import { Query } from 'src/legacy_graphql/Query'
import Action from 'src/redux/action'

import { useCallback } from '.'
import Future from 'src/utils/Future'
import { NormalizedData, Operation } from 'src/legacy_graphql'
import ObjectMap from 'src/utils/ObjectMap'
import { denormalizeValue } from 'src/normalized-data/normalization'
import { Dispatch } from 'redux'
import { Memoized } from './dependencies'

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
type Result<Q> = Q extends Query<any, any, infer View>
  ? Future<Exclude<View, undefined>>
  : undefined

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
type Results<Qs> = Qs extends (Query<any, any> | undefined)[]
  ? { [K in keyof Qs]: Result<Qs[K]> }
  : never

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const useGetQueryFutures = <Qs extends (Query<any, any> | undefined)[]>(
  ...optionalQueries: Qs
) => {
  const dispatch = useDispatch() as Memoized<Dispatch<Action>>

  const getQueryFutures = useCallback(
    (
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      queryStates: ObjectMap<Operation<any, any>, Future<any>>,
      normalizedData: NormalizedData,
    ): Memoized<Results<Qs>> =>
      (optionalQueries.map(query =>
        query
          ? queryStates
              .get(query.operation)
              ?.map(state =>
                query.mapState(
                  denormalizeValue(state, normalizedData),
                  query.offset ?? undefined,
                  query.limit ?? undefined,
                  query.operation,
                  dispatch,
                ),
              ) ?? Future()
          : undefined,
      ) as unknown) as Memoized<Results<Qs>>,
    [optionalQueries, dispatch],
  )
  return getQueryFutures
}

export default useGetQueryFutures
