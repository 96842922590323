import { createMiddleware } from 'redux-beacon'
import Segment, { identifyUser } from '@redux-beacon/segment'
import { loadedUser } from 'src/redux/actions/user'
import Action from 'src/redux/action'

const eventsMap = {
  // '*': trackEvent(action => ({
  //   name: action.type,
  //   properties: { ...action },
  // })),
  [loadedUser.type]: identifyUser(anyAction => {
    const action = anyAction as Action
    if (action.type !== loadedUser.type || !action.account.value) {
      return {}
    }
    const { id, username, firstName, lastName, email } = action.account.value
    return {
      userId: id,
      traits: {
        id,
        username,
        firstName,
        lastName,
        email,
      },
    }
  }),
}

const segment = Segment()
export const segmentMiddleware = createMiddleware(eventsMap, segment)

export const trackPath = (path: string) => window.analytics.page({ url: path })
