import { Interpolation } from '@emotion/css'

const mq = ['@media(max-width: 568px)']

const styles = {
  container: (drawerOpen: boolean): Interpolation => ({
    display: 'grid',
    gridTemplate: drawerOpen ? '100vh / 65px 315px 1fr' : '100% / 65px 0px 1fr',
    [mq[0]]: {
      height: '100vh',
      gridTemplate: `
      "main" 1fr
      "toolbar" 65px
      `,
    },
  }),
  sidebar: {
    [mq[0]]: {
      gridArea: 'toolbar',
    },
  },
  main: {
    height: '100dvh',
    position: 'relative',
    display: 'grid',
    gridTemplate: '80px 1fr 50px / 1fr',
    [mq[0]]: {
      gridArea: 'main',
      gridTemplate: '80px 1fr / 1fr',
      height: 'initial',
    },
  } as Interpolation,
  drawerContainer: {
    [mq[0]]: {
      gridArea: 'drawer',
    },
  },
  headerContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  } as Interpolation,
  footer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  } as Interpolation,
  textCenter: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    height: '100%',
    transform: 'translate(-50%, -50%)',
    color: '#b4b4b4',
    zIndex: 1,
  } as Interpolation,
  flexButton: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '28px',
  },
}

export default styles
