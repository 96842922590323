import { Div, Flex, Icon, Text } from '@sendoutcards/quantum-design-ui'
import React, { FC } from 'react'
import { Icon as ChromeIcon } from 'src/chrome'
import startCase from 'lodash/startCase'
import { CardType } from '@sendoutcards/editor/dist/types/card'
import {
  MiniCart,
  MiniCartProps,
} from 'src/orders/components/MiniCart/MiniCart'

export type MobileContextBarType = {
  cardType: CardType
  panelLocation?: string | null
  onExit: () => void
  isFullBleed?: boolean
  onChangePanelView: () => void
  miniCartProps: MiniCartProps
}

export const MobileContextBar: FC<MobileContextBarType> = ({
  cardType,
  panelLocation,
  onExit,
  isFullBleed,
  onChangePanelView,
  miniCartProps,
}) => {
  return (
    <Flex
      width="100%"
      height="65px"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      overflow="hidden"
      boxShadow="light"
      inset={{ left: 'x1' }}
      outset="0 0 auto 0"
      backgroundColor="foreground"
      position="fixed"
      top="0px"
      zIndex={1000}
    >
      <Flex
        onClick={onExit}
        height="100%"
        alignItems="center"
        borderStyle={{ right: 'solid' }}
        borderColor="#dbdbdbb3"
        borderWidth="default"
        inset={{ right: 'x1' }}
      >
        <Flex flexWrap="wrap" justifyContent="center">
          <ChromeIcon icon={'ARROWLEFT'} size={17} color={'#6f8394'} />
          <Text
            type="caption"
            style={{ fontSize: '.625rem', lineHeight: '.9rem' }}
          >
            Catalog
          </Text>
        </Flex>
      </Flex>
      <Flex width="100%" justifyContent="center" flexWrap="nowrap">
        <Text type="caption" weight="semiBold" style={{ fontSize: '.725rem' }}>
          {`${startCase(cardType)}:`}
        </Text>
        <Text
          type="caption"
          weight="bold"
          color="primaryHeading"
          style={{ fontSize: '.725rem' }}
        >
          {`\u00A0${panelLocation}`}
        </Text>
      </Flex>
      <Flex height="100%" alignItems="end" justifyContent="space-between">
        <Flex
          width="100%"
          height="100%"
          alignItems="center"
          backgroundColor="#fff"
          borderStyle={{ left: 'solid', right: 'solid' }}
          borderColor="#dbdbdbb3"
          borderWidth="default"
        >
          <Div
            onClick={onChangePanelView}
            inset={{ horizontal: 'x2', top: 'x2' }}
          >
            <Text type="caption" style={{ fontSize: '.625rem' }}>
              <Icon
                name={isFullBleed ? 'frameWidth' : 'viewSinglePanel'}
                size="small"
                primaryColor="primaryBodyText"
              />
            </Text>
          </Div>
        </Flex>
        <Flex
          width="100%"
          height="100%"
          alignItems="center"
          backgroundColor="#53b66e"
        >
          <MiniCart
            {...miniCartProps}
            isMobile={true}
            backgroundColor="#53b66e"
            actionType="mobile"
          />
        </Flex>
      </Flex>
    </Flex>
  )
}
