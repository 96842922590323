import React from 'react'

import { Transition } from 'src/chrome'

import styles from './importContacts.module.scss'
import ImportStepper from './lib/ImportStepper'
import { Props, Steps } from './helpers'
import UploadStep from './lib/UploadStep'
import MapHeadersStep from './lib/MapHeadersStep'
import EditDataStep from './lib/EditDataStep'
import ImportStep from './lib/ImportStep'
import CompleteStep from './lib/CompleteStep'
import Hooks from './hooks'
import { FlexColumn } from 'src/styled'

const ImportManager: React.FC<Props> = props => {
  const { additionalAction, rowLimit } = props

  const hooks = Hooks()

  return (
    <FlexColumn css={{ backgroundColor: 'white' }}>
      <ImportStepper stepIndex={hooks.stepperIndex}>
        <div className={styles.section}>
          {(() => {
            switch (hooks.step.type) {
              case Steps.Upload:
                return <UploadStep onUpload={hooks.readFile} />

              case Steps.ReadFile:
                return <Transition message={'Reading File...'} />

              case Steps.MapHeaders:
                return (
                  <MapHeadersStep
                    rows={hooks.step.rows}
                    onBack={() =>
                      hooks.setStep({
                        type: Steps.Upload,
                      })
                    }
                    fields={hooks.step.fields}
                    processFile={hooks.processFile}
                  />
                )

              case Steps.ProcessFile:
                return <Transition message={'Processing File...'} />

              case Steps.EditData: {
                const {
                  mapping,
                  defaults,
                  dateFormat,
                  fields,
                  rows,
                } = hooks.step

                return (
                  <EditDataStep
                    rowLimit={rowLimit}
                    dateFormat={hooks.step.dateFormat}
                    defaults={hooks.step.defaults}
                    mapping={hooks.step.mapping}
                    initialRows={hooks.step.rows}
                    onBack={() =>
                      hooks.setStep({
                        type: Steps.MapHeaders,
                        fields,
                        rows,
                      })
                    }
                    onImport={(mappedRows, checkedRows) =>
                      hooks.startImport(
                        mappedRows,
                        checkedRows,
                        mapping,
                        defaults,
                        dateFormat,
                      )
                    }
                  />
                )
              }

              case Steps.Import:
                return (
                  <ImportStep
                    parsedRows={hooks.step.parsedRows}
                    importChunk={hooks.importChunk}
                    onComplete={hooks.cleanup}
                    rowLimit={rowLimit}
                  />
                )

              case Steps.Completed:
                return (
                  <CompleteStep
                    additionalAction={additionalAction}
                    importedCustomers={hooks.step.importedContacts}
                    onImportMore={() =>
                      hooks.setStep({
                        type: Steps.Upload,
                      })
                    }
                    parsedRows={hooks.step.parsedRows}
                  />
                )
            }
          })()}
        </div>
      </ImportStepper>
    </FlexColumn>
  )
}

export default ImportManager
