import { createDateObject } from '../helpers/dates'
import { OptionalYearDateInput } from 'src/legacy_graphql'

export interface ParsedRow {
  firstName: string
  lastName: string
  companyName: string
  emailAddress: string
  address1: string
  address2: string
  city: string
  state: string
  postalCode: string
  country: string
  homePhone: string
  cellPhone: string
  workPhone: string
  faxNumber: string
  website: string
  birthday: OptionalYearDateInput
  anniversary: OptionalYearDateInput
  groupName: string
  spouseName: string
  spouseBirthday: OptionalYearDateInput
}

export type MappedRow = {
  [key in keyof ParsedRow]?: string | undefined
}

export type Field = keyof ParsedRow

export type Column<T extends Field> = {
  display: string
  apiName: T
  mappingRequired: boolean
  matches: string[]
  example: string
  type: ParsedRow[T] extends OptionalYearDateInput ? 'date' : 'string' | 'phone'
  maxLength: null | number
  required: (row: MappedRow) => boolean
  getError: (
    row: MappedRow,
    rawValue: string,
    column: Column<T>,
    dateFormat: string,
  ) => string | undefined
  parse: (
    rawValue: string,
    column: Column<T>,
    dateFormat: string,
  ) => ParsedRow[T]
}

export type Row = {
  [field: string]: string | undefined
}

const toInt = (number: string) => {
  const int = parseInt(number, 10)
  if (Number.isNaN(int)) {
    throw new Error(`invalid input for number: ${number}`)
  }
  return int
}

const identity = (x: string) => x
function parseDate<F extends Field>(
  value: string,
  _column: Column<F>,
  dateFormat: string,
): OptionalYearDateInput {
  const date = createDateObject(value, dateFormat)
  if (!date) throw new Error(`invalid date: ${value} for format ${dateFormat}`)
  return {
    day: toInt(date.day),
    month: toInt(date.month),
    year: date.year ? toInt(date.year) : undefined,
  }
}

function globalValidate<F extends Field>(
  row: MappedRow,
  value: string,
  column: Column<F>,
) {
  return column.maxLength && value.length > column.maxLength
    ? `${column.display} maximum length of ${column.maxLength} exceeded`
    : column.required(row) && !value
    ? `Missing ${column.display}`
    : undefined
}

function validateDate<F extends Field>(
  row: MappedRow,
  value: string,
  column: Column<F>,
  dateFormat: string,
): string | undefined {
  const presenceError = globalValidate(row, value, column)
  if (presenceError) return presenceError
  if (!value) return undefined

  const date = createDateObject(value, dateFormat)
  return date === null
    ? `Invalid date ${value} for format ${dateFormat}`
    : undefined
}

const firstName: Column<'firstName'> = {
  display: 'First Name',
  apiName: 'firstName',
  mappingRequired: true,
  matches: ['first', 'first name', 'firstname', 'first_name'],
  example: 'John',
  type: 'string',
  maxLength: 100,
  required: () => true,
  parse: identity,
  getError: globalValidate,
}

const lastName: Column<'lastName'> = {
  display: 'Last Name',
  apiName: 'lastName',
  mappingRequired: true,
  matches: ['last', 'last name', 'lastname', 'last_name'],
  example: 'Doe',
  type: 'string',
  maxLength: 100,
  required: () => true,
  parse: identity,
  getError: globalValidate,
}

const companyName: Column<'companyName'> = {
  display: 'Company Name',
  apiName: 'companyName',
  mappingRequired: false,
  matches: [
    'business',
    'business name',
    'company',
    'company name',
    'company_name',
  ],
  example: 'ACME Store',
  type: 'string',
  maxLength: 255,
  required: () => false,
  parse: identity,
  getError: globalValidate,
}

const emailAddress: Column<'emailAddress'> = {
  display: 'Email',
  apiName: 'emailAddress',
  mappingRequired: false,
  matches: ['email', 'email address', 'email_address'],
  example: 'john.doe@example.com',
  type: 'string',
  maxLength: null,
  required: () => false,
  parse: identity,
  getError: globalValidate,
}

const address1: Column<'address1'> = {
  display: 'Address 1',
  apiName: 'address1',
  mappingRequired: true,
  matches: ['address line 1', 'address', 'address 1', 'address1', 'address_1'],
  example: '1234 East 456 West',
  type: 'string',
  maxLength: 100,
  required: () => true,
  parse: identity,
  getError: globalValidate,
}

const address2: Column<'address2'> = {
  display: 'Address 2',
  apiName: 'address2',
  mappingRequired: false,
  matches: ['address line 2', 'address 2', 'address2', 'address_2'],
  example: '',
  type: 'string',
  maxLength: 100,
  required: () => false,
  parse: identity,
  getError: globalValidate,
}

const city: Column<'city'> = {
  display: 'City',
  apiName: 'city',
  mappingRequired: true,
  matches: ['city', 'town'],
  example: 'Some City',
  type: 'string',
  maxLength: 50,
  required: () => true,
  parse: identity,
  getError: globalValidate,
}

const state: Column<'state'> = {
  display: 'State',
  apiName: 'state',
  mappingRequired: true,
  matches: ['state', 'province'],
  example: 'Some State',
  type: 'string',
  maxLength: 100,
  required: () => false,
  parse: identity,
  getError: globalValidate,
}

const postalCode: Column<'postalCode'> = {
  display: 'Postal Code',
  apiName: 'postalCode',
  mappingRequired: true,
  matches: [
    'postal code',
    'zip code',
    'zip',
    'postalcode',
    'postal_code',
    'zip_code',
  ],
  example: '12345',
  type: 'string',
  maxLength: 100,
  required: row =>
    row.country ? ['United States', 'USA'].includes(row.country) : false,
  parse: identity,
  getError: globalValidate,
}

export const country: Column<'country'> = {
  display: 'Country',
  apiName: 'country',
  mappingRequired: false,
  matches: ['country'],
  example: 'United States',
  type: 'string',
  maxLength: null,
  required: () => true,
  parse: identity,
  getError: globalValidate,
}

const homePhone: Column<'homePhone'> = {
  display: 'Home Phone',
  apiName: 'homePhone',
  mappingRequired: false,
  matches: ['home phone', 'home number'],
  example: '900-000-0000',
  type: 'phone',
  maxLength: null,
  required: () => false,
  parse: identity,
  getError: globalValidate,
}

const cellPhone: Column<'cellPhone'> = {
  display: 'Cell Phone',
  apiName: 'cellPhone',
  mappingRequired: false,
  matches: [
    'cell number',
    'cell',
    'cell phone',
    'phone',
    'mobile',
    'mobile phone',
    'mobile number',
  ],
  example: '1-234-567-8999',
  type: 'phone',
  maxLength: null,
  required: () => false,
  parse: identity,
  getError: globalValidate,
}

const workPhone: Column<'workPhone'> = {
  display: 'Work Phone',
  apiName: 'workPhone',
  mappingRequired: false,
  matches: ['work number', 'work', 'work phone'],
  example: '1-234-567-8999',
  type: 'phone',
  maxLength: null,
  required: () => false,
  parse: identity,
  getError: globalValidate,
}

const faxNumber: Column<'faxNumber'> = {
  display: 'Fax Number',
  apiName: 'faxNumber',
  mappingRequired: false,
  matches: ['fax', 'fax number'],
  example: '1-234-567-8999',
  type: 'phone',
  maxLength: null,
  required: () => false,
  parse: identity,
  getError: globalValidate,
}

const website: Column<'website'> = {
  display: 'Website',
  apiName: 'website',
  mappingRequired: false,
  matches: ['website', 'web'],
  example: 'https://www.johnstore.com',
  type: 'string',
  maxLength: null,
  required: () => false,
  parse: identity,
  getError: globalValidate,
}

const birthday: Column<'birthday'> = {
  display: 'Birthday',
  apiName: 'birthday',
  mappingRequired: false,
  matches: ['birthday'],
  example: '12/21',
  type: 'date',
  maxLength: null,
  required: () => false,
  getError: validateDate,
  parse: parseDate,
}

const anniversary: Column<'anniversary'> = {
  display: 'Anniversary',
  apiName: 'anniversary',
  mappingRequired: false,
  matches: ['anniversary'],
  example: '11/07',
  type: 'date',
  maxLength: null,
  required: () => false,
  getError: validateDate,
  parse: parseDate,
}

const groupName: Column<'groupName'> = {
  display: 'Group',
  apiName: 'groupName',
  mappingRequired: false,
  matches: ['group', 'label'],
  example: 'Christmas Card Contacts',
  type: 'string',
  maxLength: null,
  required: () => false,
  parse: identity,
  getError: globalValidate,
}

const spouseName: Column<'spouseName'> = {
  display: 'Spouse First Name',
  apiName: 'spouseName',
  mappingRequired: false,
  matches: [
    'spouse name',
    'spouse',
    'spouse first name',
    'spousename',
    'spouse_name',
  ],
  example: 'Mary',
  type: 'string',
  maxLength: null,
  required: () => false,
  parse: identity,
  getError: globalValidate,
}

const spouseBirthday: Column<'spouseBirthday'> = {
  display: 'Spouse Birthday',
  apiName: 'spouseBirthday',
  mappingRequired: false,
  matches: ['spouse birthday', 'spouse_birthday'],
  example: '01/30',
  type: 'date',
  maxLength: null,
  required: () => false,
  getError: validateDate,
  parse: parseDate,
}

const columns = [
  firstName,
  lastName,
  companyName,
  emailAddress,
  address1,
  address2,
  city,
  state,
  postalCode,
  country,
  homePhone,
  cellPhone,
  workPhone,
  faxNumber,
  website,
  birthday,
  anniversary,
  groupName,
  spouseName,
  spouseBirthday,
]

export default columns
