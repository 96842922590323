import React from 'react'
import { CircularProgress } from '@material-ui/core'

// @src imports
import { DefaultError, SuspenseBoundary, Transition } from 'src/chrome'
import SOCImages from 'src/editor/images/SOCImages'
import FacebookImages from 'src/editor/components/FacebookImages/FacebookImages'
import Layouts from 'src/editor/components/Layouts/Layouts'
// relative imports
import BackgroundPicker from '../../components/BackgroundPicker/BackgroundPicker'
import MinimalImageOptions from '../../components/MinimalImageOptions'
import TextControls from '../../components/TextControls/TextControls'
import { Api } from '../../api'
import { Step, Steps } from '../../types'
import styles from '../../styles/components/Drawer.module.scss'
import { useCallback, useEffect, useSelector, useState } from 'src/hooks'
import CloseButton from 'src/chrome/CloseButton/CloseButton'
import { Button, Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import UploadFooter from 'src/editor/images/UploadFooter'
import { FontFragment } from 'src/graphql/generated/graphql'
import { ImageFragment } from 'src/legacy_graphql'
import MinimalTextOptions from 'src/editor/components/MinimalTextOptions/MinimalTextOptions'
import { Duvet } from 'src/editor/components/Duvet/Duvet'
import { SheetHeader } from './mobileComponents/SheetHeader'
import {
  IMAGE_SETTING_EXPANDED_OPEN_VALUE,
  IMAGE_SETTING_OPEN_VALUE,
  IMAGE_SETTING_SCALE_TOOL_VALUE,
  TEXT_SETTING_OPEN_VALUE,
} from 'src/editor/constants'

const hideToolBar: React.CSSProperties = {
  bottom: 0,
  zIndex: 999,
}

const drawerTriggerText = (step: Step) => {
  switch (step.type) {
    case Steps.EditPicture:
    case Steps.EditBackgroundPicture:
      return (
        <Flex position="sticky" top="0">
          <Text type="caption" weight="bold" content="Your photos" />
        </Flex>
      )
    case Steps.EditLayout:
      return (
        <Flex position="sticky" top="0" flexWrap="wrap">
          <Text type="caption" weight="bold" content="Select a layout" />
        </Flex>
      )
    case Steps.EditText:
      return (
        <Flex position="sticky" top="0">
          <Text type="caption" weight="bold" content="Add your Message" />
        </Flex>
      )
    case Steps.EditBackgroundColor:
      return (
        <Flex position="sticky" top="0">
          <Text type="caption" weight="bold" content="Spice up your panel" />
        </Flex>
      )
    case Steps.Idle:
      return ''
  }
}

const DrawerContent: React.FC<{
  api: Api
  isMobile: boolean
  fonts: { fonts: FontFragment[]; signatures: FontFragment[] }
  closeDuvet?: () => void
}> = ({ api, isMobile, fonts, closeDuvet }) => {
  const {
    step,
    resolveLocation,
    card,
    selectedLayoutId,
    setSelectedLayoutId,
    isImageScaleActive,
    setElementImage,
  } = api

  const [shouldUseRegularTransition, setShouldUseRegularTransition] = useState<
    boolean
  >(false)

  const onChangeTransitionType = useCallback(
    (shouldUseRegularTransition: boolean) => {
      setShouldUseRegularTransition(shouldUseRegularTransition)
    },
    [setShouldUseRegularTransition],
  )

  const onSelect = useCallback(
    (image: ImageFragment) => {
      switch (step.type) {
        case Steps.EditPicture:
          setElementImage(
            step.fullBleedIndex,
            step.panelIndex,
            step.elementIndex,
            image,
          )
          break
        case Steps.EditBackgroundPicture:
          setElementImage(step.fullBleedIndex, undefined, 0, image)
          break
      }
    },
    [step], // eslint-disable-line
  )

  // TODO: React Query Refactor - Not sure if we want to throw here, but RQ has potentially undefined results
  if (!card) throw Error('No card provided!')

  switch (step.type) {
    case Steps.EditPicture:
    case Steps.EditBackgroundPicture:
      switch (step.source) {
        case 'own':
          return isMobile ? (
            <>
              {!isImageScaleActive && (
                <SheetHeader
                  title="Image Settings"
                  elementLocation={`${resolveLocation(card.type)}`}
                  actionItem={<UploadFooter api={api} onSelect={onSelect} />}
                />
              )}
              <MinimalImageOptions
                api={{ ...api, step }}
                closeDuvet={closeDuvet}
                onSelect={onSelect}
              />
            </>
          ) : (
            <SOCImages api={{ ...api, step }} onSelect={onSelect} />
          )
        case 'social':
          return isMobile ? (
            <Flex>
              <MinimalImageOptions
                api={{ ...api, step }}
                closeDuvet={closeDuvet}
                onSelect={onSelect}
              />
              <UploadFooter api={api} onSelect={onSelect} />
            </Flex>
          ) : (
            <FacebookImages api={{ ...api, step }} />
          )
      }
      break
    case Steps.EditLayout:
      return (
        <>
          {isMobile && (
            <SheetHeader
              title="Add a Layout"
              elementLocation={`${resolveLocation(card.type)}`}
            />
          )}
          <SuspenseBoundary
            unresolved={
              shouldUseRegularTransition ? (
                <Transition message="Updating your layout..." />
              ) : (
                <div className={styles.Loader}>
                  <CircularProgress />
                </div>
              )
            }
            failure={DefaultError}
          >
            {selectedLayoutId !== '10' && !isMobile && (
              <Div inset={{ bottom: 'x5', left: '1.7rem' }}>
                <Button
                  title="Remove Layout"
                  size="small"
                  type="primary"
                  onClick={() => {
                    setSelectedLayoutId('10')
                  }}
                />
              </Div>
            )}

            <Div
              overflowY="auto"
              height="100%"
              inset={{ bottom: isMobile ? '144px' : 'x0' }}
            >
              <Layouts
                api={{ ...api, step }}
                onChangeTransitionType={onChangeTransitionType}
              />
            </Div>
          </SuspenseBoundary>
        </>
      )
    case Steps.EditBackgroundColor:
      return <BackgroundPicker api={{ ...api, step }} />
    case Steps.EditText:
      return isMobile ? (
        <>
          <Text
            type="largeBody"
            color="primaryHeading"
            content="Add your message"
            alignment="left"
            weight="bold"
          />
          <Flex>
            <Text
              type="caption"
              color="primaryBody"
              content="Element Location:"
            />
            <Text
              type="caption"
              color="primaryHeading"
              content={`\u00A0${resolveLocation(card.type)}`}
              weight="semiBold"
            />
          </Flex>
          <div
            id="mobile-text-editor-wrapper"
            style={{ position: 'relative' }}
          />
          <Div height="100%">
            <MinimalTextOptions
              api={{ ...api, step }}
              closeDuvet={closeDuvet}
            />
          </Div>
        </>
      ) : (
        <TextControls api={{ ...api, step }} />
      )
    case Steps.Idle:
      return null
  }
}

interface Props {
  api: Api
}

const EditorDrawer: React.FC<Props> = props => {
  const { api } = props
  const {
    step,
    setIdleStep,
    editorDuvetOpenValue,
    setEditorDuvetOpenValue,
    setIsImageSettingExpanded,
    isImageSettingExpanded,
    isImageScaleActive,
  } = api

  const { isMobile } = useSelector(state => state.window)
  const onCloseSheet = () => {
    setIdleStep(step.fullBleedIndex, step.panelIndex ?? 0)
    setEditorDuvetOpenValue(0)
  }

  useEffect(() => {
    if (
      step.type === Steps.EditBackgroundPicture ||
      step.type === Steps.EditPicture
    ) {
      if (isImageScaleActive) {
        setEditorDuvetOpenValue(IMAGE_SETTING_SCALE_TOOL_VALUE)
      } else if (isImageSettingExpanded) {
        setEditorDuvetOpenValue(IMAGE_SETTING_EXPANDED_OPEN_VALUE)
      } else {
        setEditorDuvetOpenValue(IMAGE_SETTING_OPEN_VALUE)
      }
    } else if (step.type === Steps.EditText) {
      if (isMobile) setEditorDuvetOpenValue(TEXT_SETTING_OPEN_VALUE)
    }
  }, [
    isImageSettingExpanded,
    isImageScaleActive,
    setEditorDuvetOpenValue,
    step.type,
    isMobile,
  ])

  return (
    <section
      style={isMobile ? { overflowY: 'auto' } : undefined}
      className="openDrawer"
    >
      <div
        className={`${styles.imageDrawer}`}
        style={
          step.type === Steps.EditPicture || step.type === Steps.EditText
            ? hideToolBar
            : {}
        }
      >
        {step.type !== Steps.EditText && !isMobile && (
          <div className={styles.drawerBarTop}>
            <span className={styles.drawerTitle}>
              <span>{drawerTriggerText(step)}</span>
              <CloseButton
                size={24}
                onClose={onCloseSheet}
                id="close_editor_drawer"
              />
            </span>
          </div>
        )}
        {isMobile ? (
          <Duvet
            isOpen={true}
            openValue={editorDuvetOpenValue}
            onClose={onCloseSheet}
            headerOffset={isImageScaleActive ? '18px' : '55px'}
            directionArrow={
              (step.type === Steps.EditBackgroundPicture ||
                step.type === Steps.EditPicture) &&
              !isImageScaleActive
                ? {
                    direction: isImageSettingExpanded ? 'down' : 'up',
                    onClick: () =>
                      setIsImageSettingExpanded(!isImageSettingExpanded),
                  }
                : undefined
            }
          >
            <DrawerContent
              api={api}
              isMobile={isMobile}
              fonts={api.fonts}
              closeDuvet={() => onCloseSheet()}
            />
          </Duvet>
        ) : (
          <DrawerContent api={api} isMobile={isMobile} fonts={api.fonts} />
        )}
      </div>
    </section>
  )
}

export default EditorDrawer
