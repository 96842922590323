import React from 'react'
import { useActions } from 'src/hooks'
import { EditCampaignCardRoute } from '../../routes/EditCampaignCardRoute'
import { CampaignRoute } from 'src/campaigns/routes/CampaignRoute'
import Editor from 'src/editor/containers/Editor/Editor'
import { CardActionsProps } from '../CampaignDetail/Campaign'
import { Text } from '@sendoutcards/quantum-design-ui'

const EditCampaignCard: React.FC<
  CardActionsProps & { route: EditCampaignCardRoute }
> = props => {
  const { editableCampaign, campaignRoute, resetRoute, route } = props
  const actions = useActions()

  return (
    <Editor
      cardId={route.cardId}
      onAfterSave={async () =>
        actions.openCampaigns(CampaignRoute(campaignRoute.campaignId))
      }
      onClose={resetRoute}
      context={{
        headerText: `Campaign: ${editableCampaign.name}`,
        footerLabel: () => (
          <Text type="caption" color="primaryHeading" weight="bold">
            {editableCampaign.lines.length} card
            {editableCampaign.lines.length > 1 && 's'}
          </Text>
        ),
        lines: editableCampaign.lines,
      }}
      saveButtonLabel="Update Campaign"
    />
  )
}

export default EditCampaignCard
