import React from 'react'
import AvatarEditor from 'react-avatar-editor'
import UserAvatar from 'react-user-avatar'

import { Button, Icon } from 'src/chrome'
import { AccountFragment } from 'src/graphql/generated/graphql'
import { noop } from 'src/helpers/appHelpers'
import { useRef, useState } from 'src/hooks'
import { useUpdateAccount } from 'src/react_query'

import styles from './profileAvatar.module.scss'

interface Props {
  account?: Pick<AccountFragment, 'firstName' | 'lastName'> & {
    profileImageUrl?: AccountFragment['profileImageUrl']
  }
  avatarWrapperStyles?: React.CSSProperties
  avatarStyles?: React.CSSProperties
  isEditable?: boolean
  image?: string
  name?: string
  size: number
  style?: React.CSSProperties
  onSave?: (imageFile: File) => void
  onSaveImageString?: (profileImage: string) => void
}

const ProfileAvatar: React.FC<Props> = props => {
  const { onSave, onSaveImageString } = props

  const useUpdateAccountMutation = useUpdateAccount()
  const file = useRef<HTMLInputElement>(null)
  const profileImageEditor = useRef<AvatarEditor>(null)

  const [uploadedFile, setUploadedFile] = useState<File>()
  const [tempImage, setTempImage] = useState<string>('')

  const focusFileInput = () => file.current?.click()

  const convertCanvasToImage = (canvas: HTMLCanvasElement) => {
    if (onSave && uploadedFile) {
      onSave(uploadedFile)
      clearUpload()
    } else {
      const mutableImage = new Image()
      mutableImage.src = canvas.toDataURL('image/png')
      setUploadedFile(undefined)
      setTempImage(mutableImage.src)

      if (onSaveImageString) {
        onSaveImageString(mutableImage.src)
      } else {
        useUpdateAccountMutation.mutate({
          account: { profileImage: mutableImage.src },
        })
      }
    }
  }

  const handleCroppedImage = () => {
    if (profileImageEditor.current) {
      const canvas: HTMLCanvasElement = profileImageEditor.current.getImageScaledToCanvas()
      convertCanvasToImage(canvas)
    }
  }

  const clearUpload = () => setUploadedFile(undefined)

  const validImageUrl = (url: string) =>
    /^http.*?=\/.+$/.test(url) || /^data:image.*/.test(url)

  const {
    account,
    avatarWrapperStyles,
    avatarStyles,
    isEditable,
    image,
    name,
    size,
    style,
  } = props
  const user = account

  const fullName = user
    ? `${user.firstName}${user.lastName ? ` ${user.lastName}` : ''}`
    : name
    ? name
    : ''
  const userImage = user?.profileImageUrl ?? image ?? ''
  const actionStyles: React.CSSProperties = {
    width: size,
    height: size,
  }

  return (
    <div
      className={styles.avatarAction}
      style={avatarWrapperStyles}
      onClick={isEditable ? focusFileInput : noop}
    >
      {!uploadedFile ? (
        <div className={styles.avatar} style={avatarStyles}>
          <UserAvatar
            name={fullName || 'A'}
            src={
              tempImage ? tempImage : validImageUrl(userImage) ? userImage : ''
            }
            size={size}
            style={style}
          />
          <div
            className={
              isEditable
                ? `${styles.profileActionHover} ${styles.hover}`
                : `${styles.profileActionHover}`
            }
            style={actionStyles}
          >
            <Icon icon={'PHOTOS'} size={18} color={'#fff'} />
            <p>Edit</p>
          </div>
        </div>
      ) : (
        <div className={styles.avatar}>
          <AvatarEditor
            image={uploadedFile}
            width={size}
            height={size}
            scale={1.2}
            borderRadius={100}
            rotate={0}
            ref={profileImageEditor}
          />
          <div className={styles.uploadActions}>
            <Button
              title={'Cancel'}
              onClick={clearUpload}
              style={{ marginRight: 20 }}
            />
            <Button
              title={'Save'}
              buttonColor={'pink'}
              onClick={handleCroppedImage}
            />
          </div>
        </div>
      )}

      <input
        style={{ display: 'none' }}
        type="file"
        id={'file-upload'}
        accept=".jpg, .jpeg, .png, .gif"
        onClick={uploadedFile ? ev => ev.preventDefault() : undefined}
        onChange={event => {
          event.preventDefault()
          if (!event.target.files || event.target.files.length === 0) {
            return
          }
          const uploadedFile: File = event.target.files[0]
          const { name: fileName } = uploadedFile
          const fileExtension = fileName
            .slice(fileName.lastIndexOf('.') + 1)
            .toLowerCase()

          if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension))
            setUploadedFile(uploadedFile)
        }}
        ref={file}
      />
    </div>
  )
}

export default ProfileAvatar
