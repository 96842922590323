// eslint-disable-next-line no-restricted-imports
import { useMemo } from 'react'
import { AnyMemoized, Memoized } from './dependencies'
import useMemoizedDeps from './useMemoizedDeps'

/**
 * `useMemo` will only recompute the memoized value when one of the `deps` has changed.
 */
const useMemoDeepCompare = <T>(
  factory: () => T,
  deps: AnyMemoized[] | undefined,
) =>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useMemo(factory, useMemoizedDeps(deps)) as Memoized<T>

export default useMemoDeepCompare
