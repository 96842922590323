import React from 'react'
// @src imports

import { textEditor } from 'src/helpers'
import { ComputedElementText } from './types'
import {
  useCallback,
  useEffect,
  useMutationObservable,
  useRef,
  useSelector,
  useState,
} from 'src/hooks'

import { Api } from '../api'
import { Steps } from '../types'
import TextEditorParagraph from './TextEditorParagraph'
import { Portal } from 'src/portal/portal'
import { Tooltip } from '@sendoutcards/quantum-design-ui'
import { AnimatePresence, motion } from 'framer-motion'

const fragmentsFormat = 'application/vnd.sendoutcards.textfragments+json'

const selectionIndex = (
  node: Node,
  offset: number,
  isStart: boolean,
  selectionUpperBound: number,
) => {
  if (node.nodeName === '#text') {
    const spanIndex = parseInt(
      (node.parentElement && node.parentElement.dataset.index) || '1',
      10,
    )
    if (offset === 0) {
      return spanIndex
    } else if (offset === (node.textContent && node.textContent.length)) {
      return spanIndex + 1
    } else {
      return isStart ? spanIndex : spanIndex + 1
    }
  } else if (node.nodeName === 'P' && node instanceof HTMLElement) {
    return parseInt(node.dataset.index || '1', 10) + offset + 1
  } else {
    return isStart ? 1 : selectionUpperBound
  }
}

type TextEditorProps = {
  textEditorId: string
  elementText: ComputedElementText
  selectText: (start: number, end: number) => void
  fontScale: number
  api: Api
  isSelected?: boolean
  isLocked: boolean
  panelName: string
}

const TextEditor: React.FC<TextEditorProps> = props => {
  const {
    textEditorId,
    elementText,
    selectText,
    fontScale,
    api,
    isSelected,
    panelName,
    isLocked,
  } = props
  const { paragraphs, selectionUpperBound, selection } = elementText
  const { updateText, step } = api
  const { width } = useSelector(state => state.window)

  const utils =
    step.type === Steps.EditText
      ? textEditor(updateText, step, api.isMobile)
      : undefined

  const mutableRef = useRef<HTMLDivElement>()
  const boundingBoxRef = useRef<HTMLDivElement>(null)
  const ref = mutableRef.current
  const [isFocused, setIsFocused] = useState(false)
  const [shouldEditContent, setShouldEditContent] = useState(!isLocked)
  const [isMobileEditorOpen, setIsMobileEditorOpen] = useState(true)
  const [isChangeExternal, setIsChangeExternal] = useState(false)
  const [hasExceededBounds, setHasExceededBounds] = useState(false)

  const onListMutation = useCallback((mutationList: MutationRecord[]) => {
    if (mutationList[0].target.firstChild?.nodeType === 3) {
      setIsChangeExternal(true)
    }
  }, [])

  const checkBounds = useCallback(() => {
    requestAnimationFrame(() => {
      const boundingBoxRects = boundingBoxRef.current?.getClientRects().item(0)
      const textEditorRects = mutableRef.current?.getClientRects().item(0)

      if (
        textEditorRects &&
        boundingBoxRects &&
        textEditorRects?.height > boundingBoxRects?.height
      ) {
        setHasExceededBounds(true)
      } else {
        setHasExceededBounds(false)
      }
    })
  }, [])

  useMutationObservable(ref, onListMutation, {
    config: {
      childList: true,
      characterData: true,
      subtree: false,
    },
  })

  useEffect(() => {
    // When on mobile, lets not force focus upon text properties changes or keyboard dismissal
    if (api.isMobile && !isFocused) {
      return
    }

    if (!ref || !selection) {
      return
    }

    const startNode = ref.childNodes[selection.start.paragraph]
    const endNode = ref.childNodes[selection.end.paragraph]

    if (!startNode || !endNode) {
      return
    }

    const range = document.createRange()

    range.setStart(
      startNode,
      Math.max(
        0,
        Math.min(selection.start.offset, startNode.childNodes.length),
      ),
    )

    range.setEnd(
      endNode,
      Math.max(0, Math.min(selection.end.offset, endNode.childNodes.length)),
    )

    const windowSelection = window.getSelection()

    if (windowSelection == null) {
      return
    }

    windowSelection.removeAllRanges()
    windowSelection.addRange(range)
  }, [ref, selection, isFocused, textEditorId, api.isMobile])

  const copy = (event: React.ClipboardEvent) => {
    if (!selection) {
      return
    }
    event.preventDefault()
    event.clipboardData.setData('text', selection.text)
    event.clipboardData.setData(
      fragmentsFormat,
      JSON.stringify(selection.fragments),
    )
  }

  const saveSelected = () => {
    const windowSelection = window.getSelection()

    if (windowSelection == null) {
      return
    }

    const range = windowSelection.getRangeAt(0)

    const start = selectionIndex(
      range.startContainer,
      range.startOffset,
      true,
      selectionUpperBound,
    )

    const end = selectionIndex(
      range.endContainer,
      range.endOffset,
      false,
      selectionUpperBound,
    )

    if (
      !selection ||
      selection.start.index !== start ||
      selection.end.index !== end
    ) {
      selectText(start, end)
    }
  }

  const externalChangesDetected = useCallback((preventTextEdition: boolean) => {
    setShouldEditContent(preventTextEdition)
  }, [])

  useEffect(() => {
    if (api.isMobile && !isSelected) {
      setIsMobileEditorOpen(false)
    } else {
      setIsMobileEditorOpen(true)
    }
  }, [isSelected, api.isMobile])

  if (isChangeExternal) {
    setTimeout(() => {
      setIsChangeExternal(false)
    }, 10)
    return <span />
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        whiteSpace: 'pre-wrap',
        outline: '0px',
        border: isSelected ? '1px dashed #CCC' : undefined,
        borderRadius: '5px',
        transition: 'width 0.3s ease-in-out',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
      ref={boundingBoxRef}
    >
      <AnimatePresence>
        {hasExceededBounds && (
          <Portal
            attachToContainerId={
              api.isMobile ? 'mobile-text-editor-wrapper' : `${panelName}_panel`
            }
            wrapperStyles={{
              display: 'flex',
              top: api.isMobile ? (width >= 422 ? '-42px' : '-66px') : '-48px',
              left: '0px',
              position: 'absolute',
              width: '100%',
            }}
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              style={{
                display: 'flex',
                width: '100%',
              }}
            >
              <Tooltip
                title="You have characters that exceed the text box. This may impact the layout and size of the text when printed."
                direction="bottom"
                whiteSpace={api.isMobile ? 'normal' : 'nowrap'}
                background="dark"
              />
            </motion.div>
          </Portal>
        )}
      </AnimatePresence>

      <div
        id={textEditorId}
        ref={ref => (mutableRef.current = ref ?? undefined)}
        contentEditable={
          api.isMobile
            ? shouldEditContent && isMobileEditorOpen
            : shouldEditContent
        }
        suppressContentEditableWarning={true}
        spellCheck="false"
        autoCapitalize="off"
        autoCorrect="off"
        style={{
          width: '100%',
        }}
        onClick={event => {
          event.stopPropagation()
          event.preventDefault()
          saveSelected()
        }}
        onCut={event => {
          copy(event)
          utils?.deleteFragments()
        }}
        onCopy={copy}
        onPaste={event => {
          event.preventDefault()
          const fragments = event.clipboardData.getData(fragmentsFormat)
          const text = event.clipboardData.getData('text')
          if (fragments) {
            utils?.insertFragments(JSON.parse(fragments))
          } else if (text) {
            utils?.insertText(text)
          }
          checkBounds()
        }}
        onKeyDown={event => {
          if (event.key === 'Backspace') {
            event.preventDefault()
            utils?.deleteFragments()
          } else if (event.key === 'Delete') {
            event.preventDefault()
            utils?.deleteForwardFragments()
          } else if (event.key === 'Enter') {
            event.preventDefault()
            utils?.insertNewLine()
          } else if (event.key === 'Tab') {
            event.preventDefault()
            utils?.insertCharacter('\t')
          }
          checkBounds()
        }}
        onKeyPress={event => {
          if (event.nativeEvent.metaKey || event.nativeEvent.ctrlKey) {
            saveSelected()
            return
          }
          event.preventDefault()
          utils?.insertCharacter(event.nativeEvent.key)
          saveSelected()
          checkBounds()
        }}
        onSelect={() => saveSelected()}
        onFocus={() => {
          setIsFocused(true)
        }}
        onBlur={() => {
          // when there's an active selection do not force focus
          if (selection && selection.start.index !== selection.end.index) {
            return
          }
          setIsFocused(false)
        }}
      >
        {paragraphs.map(paragraph => (
          <TextEditorParagraph
            key={paragraph.index}
            paragraph={paragraph}
            fontScale={fontScale}
            api={api}
            externalChangesDetected={externalChangesDetected}
          />
        ))}
      </div>
    </div>
  )
}

export default TextEditor
