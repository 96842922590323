import { BaseRoute } from 'src/routes/BaseRoute'
import { ActionCreator } from '@sendoutcards/core'

export const openRoute = ActionCreator(
  'OPEN_ROUTE',
  (route: BaseRoute, args: { [key: string]: string | undefined }) => ({
    route,
    args,
  }),
)

export const clearRouteArgs = ActionCreator('CLEAR_ROUTE_ARGS')

export const removeRouteArg = ActionCreator(
  'REMOVE_ROUTE_ARG',
  (queryParam: string) => ({ queryParam }),
)

export const removeRouteArgs = ActionCreator(
  'REMOVE_ROUTE_ARGS',
  (queryParams: string[]) => ({ queryParams }),
)
