import {
  Maybe,
  CreateContactInput,
  Operation,
} from '../../../__generated__/globalTypes'

import {
  DetailedContactFragment,
  detailedContactFragmentString,
} from '../../fragments/__generated__/DetailedContactFragment'

import {
  InviteFragment,
  inviteFragmentString,
} from '../../fragments/__generated__/InviteFragment'

import { noteFragmentString } from '../../fragments/__generated__/NoteFragment'

import { addressFragmentString } from '../../fragments/__generated__/AddressFragment'

import { relatedContactFragmentString } from '../../fragments/__generated__/RelatedContactFragment'

import { socProContactFragmentString } from '../../fragments/__generated__/SocProContactFragment'

import { contactFragmentString } from '../../fragments/__generated__/ContactFragment'

import { optionalYearDateFragmentString } from '../../fragments/__generated__/OptionalYearDateFragment'

import { prospectFragmentString } from '../../fragments/__generated__/ProspectFragment'

import { calendarDateFragmentString } from '../../fragments/__generated__/CalendarDateFragment'

import createContactWithSpouseChildrenRawString from '../CreateContactWithSpouseChildren.graphql'

const createContactWithSpouseChildrenString = [
  createContactWithSpouseChildrenRawString,
  detailedContactFragmentString,
  inviteFragmentString,
  noteFragmentString,
  addressFragmentString,
  relatedContactFragmentString,
  socProContactFragmentString,
  contactFragmentString,
  optionalYearDateFragmentString,
  prospectFragmentString,
  calendarDateFragmentString,
].join('\n\n')

export type CreateContactWithSpouseChildrenData = {
  createContactWithSpouseChildren: {
    contacts: Maybe<
      (DetailedContactFragment & {
        invite: Maybe<InviteFragment>
      })[]
    >
  }
}

export type CreateContactWithSpouseChildrenVariables = {
  contact: CreateContactInput
}

export type CreateContactWithSpouseChildren = Operation<
  CreateContactWithSpouseChildrenData,
  CreateContactWithSpouseChildrenVariables
>

export const CreateContactWithSpouseChildren = ({
  contact,
}: CreateContactWithSpouseChildrenVariables): CreateContactWithSpouseChildren => ({
  query: createContactWithSpouseChildrenString,
  variables: {
    contact: CreateContactInput(contact),
  },
})
