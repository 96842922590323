import React from 'react'

type HeartFeltStepOneType = {
  height?: number
  width?: number
  primaryColor?: string
  secondaryColor?: string
}

const HeartFeltStepOne: React.FC<HeartFeltStepOneType> = ({
  height = 276,
  width = 358,
  primaryColor = '#29D2E1',
  secondaryColor = '#56DEE2',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 358 276"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#a)">
      <path
        d="M238.38 56.428c5.583 3.65 10.794 7.735 15.137 12.809-9.368 1.3-16.75 6.002-22.085 13.675-5.956 8.601-7.073 18.626-7.693 28.65-.62 9.344.372 18.749-.558 28.155-.559 5.879-1.862 11.448-4.033 16.893-1.738 6.179-7.161 17.137-6.079 27.846.868 8.601 4.652 15.593 13.213 18.192 3.102.928 6.328 1.547 9.368 2.661 4.901 1.794 9.244 4.27 9.492 10.396-13.524 11.509-29.158 18.811-46.528 21.905-25.684 4.579-49.754-.124-72.025-13.799-.497-.31-.869-.681-1.303-1.052.868-.557.93-1.609 1.179-2.475 1.613-5.755 5.273-9.715 10.67-12.191 3.846-1.794 7.817-3.032 11.787-4.393 4.777-1.609 8.995-3.898 12.47-7.673 3.35-3.651 4.28-8.354 2.357-12.871-1.799-4.208-5.273-6.868-8.561-9.777-2.916-2.599-6.204-4.826-8.872-7.611-3.846-3.96-6.203-8.601-4.466-14.356 1.303-4.331 3.97-7.797 6.886-11.014 6.762-7.611 11.725-15.841 10.981-26.546-.373-5.322-2.544-9.653-6.887-12.809-5.273-3.775-11.601-4.332-17.68-5.57-11.911-2.413-19.542-8.168-20.473-19.8.187-.248.311-.496.497-.681 18.859-19.06 41.813-29.393 68.551-31.002 17.805-1.052 34.741 2.661 50.746 10.52.683.37 1.365 1.052 2.296.495 0 .928.496.928 1.116.618-.434.31-.186.62 0 .805.062.062.373-.124.559-.186l-.062.186Zm-84.557 23.39h20.472c.621 0 1.551 0 1.799-.31.559-.68-.248-1.36-.62-1.917-2.481-3.59-5.956-5.446-10.236-5.322-1.799 0-2.792-.371-3.784-1.918-3.661-5.755-10.733-8.478-17.371-6.993-6.762 1.547-11.787 7.24-12.655 14.109-.249 1.98.31 2.413 2.171 2.413 6.762-.062 13.462 0 20.224 0v-.062Z"
        fill={primaryColor}
      />
      <path
        d="M236.644 55.252c-.93.557-1.613-.185-2.295-.495-16.006-7.92-32.942-11.571-50.747-10.519-26.738 1.547-49.692 11.88-68.551 31.001-.186.186-.31.433-.496.681-7.32 6.992-12.718 15.346-17.433 24.195-.682 1.237-.372 2.289.186 3.403 2.544 5.012 6.266 8.787 11.167 11.386 4.157 2.166 8.375 4.146 12.47 6.373 8.127 4.394 11.104 10.705 10.298 18.812-.496 4.702-1.551 9.343-2.358 14.046-1.178 6.683-1.675 13.242 3.226 18.811 1.924 2.166 4.033 4.146 6.018 6.25 6.142 6.436 4.839 13.49-3.288 17.264-3.784 1.795-7.879 2.104-12.035 2.29-5.335.247-10.671.619-15.199 4.022-1.117.866-2.296 1.609-2.048 3.342-3.287-2.661-5.583-6.188-8.002-9.53-42.31-59.589-7.879-143.869 64.146-156.987 25.932-4.703 50.126 0 72.584 13.86.806.496 1.861.805 2.295 1.857l.062-.062Z"
        fill="#FDFDFD"
      />
      <path
        d="M105.684 206.113c-.248-1.67.931-2.475 2.048-3.341 4.528-3.342 9.864-3.775 15.199-4.022 4.156-.186 8.189-.495 12.035-2.29 8.065-3.836 9.43-10.89 3.288-17.264-1.985-2.104-4.157-4.084-6.018-6.25-4.901-5.569-4.404-12.19-3.226-18.811.807-4.703 1.862-9.344 2.358-14.047.806-8.106-2.172-14.417-10.298-18.811-4.095-2.227-8.375-4.208-12.47-6.373-4.963-2.599-8.685-6.436-11.167-11.386-.558-1.114-.868-2.166-.186-3.403 4.715-8.85 10.174-17.203 17.433-24.195.93 11.633 8.623 17.326 20.472 19.801 6.08 1.238 12.408 1.795 17.681 5.569 4.342 3.094 6.514 7.426 6.886 12.809.744 10.705-4.219 18.935-10.981 26.546-2.916 3.28-5.583 6.745-6.886 11.015-1.737 5.816.62 10.457 4.467 14.356 2.729 2.784 5.955 5.012 8.871 7.611 3.288 2.908 6.762 5.569 8.561 9.777 1.923 4.579.993 9.22-2.357 12.871-3.474 3.774-7.755 6.002-12.47 7.673-3.97 1.361-8.002 2.599-11.787 4.393-5.397 2.537-9.057 6.435-10.67 12.19-.248.867-.31 1.857-1.179 2.475-6.452-4.022-12.469-8.539-17.37-14.479-.497-.619-1.737-.372-1.613-1.547 0-.433 0-.805-.621-.867Z"
        fill={secondaryColor}
      />
      <path
        d="M268.098 88.791c6.514 10.643 10.298 22.215 12.159 34.467 3.35 21.905-.248 42.758-10.546 62.436-.372.68-.745 1.237-1.179 1.918-2.357-1.176-4.28-2.97-6.39-4.517-4.094-2.971-8.437-4.146-13.462-2.971-3.412.805-6.886 1.857-10.422 2.104-8.747.557-13.214-5.012-10.608-12.994.806-2.414 2.171-4.579 3.846-6.436 6.824-7.796 9.43-16.955 9.119-27.165-.186-7.054-.558-14.17-.496-21.224 0-6.126.807-12.252 6.018-16.522 6.39-5.198 13.648-8.292 21.961-9.034v-.062Z"
        fill="#FEFDFD"
      />
      <path
        d="M268.097 88.79c-8.251.743-15.571 3.837-21.961 9.035-5.211 4.27-6.017 10.334-6.017 16.522 0 7.054.31 14.17.496 21.224.31 10.148-2.296 19.368-9.12 27.165-1.675 1.918-3.039 4.022-3.846 6.436-2.606 7.982 1.861 13.551 10.608 12.994 3.537-.247 6.949-1.299 10.423-2.104 5.025-1.175 9.429 0 13.462 2.971 2.109 1.546 4.032 3.341 6.39 4.517-5.894 11.014-13.773 20.42-23.451 28.278-.248-6.126-4.59-8.601-9.491-10.395-3.04-1.114-6.266-1.733-9.368-2.661-8.561-2.599-12.407-9.591-13.214-18.193-.806-7.982 2.73-18.412 4.405-22.771 1.675-4.359.887-2.579 1.675-5.136 2.171-5.445 3.474-11.076 4.032-16.893.931-9.406 0-18.811.559-28.155.682-10.024 1.737-20.049 7.692-28.65 5.335-7.735 12.656-12.376 22.086-13.675a79.885 79.885 0 0 1 14.516 19.492h.124Z"
        fill={secondaryColor}
      />
      <path
        d="M153.823 79.819h-20.224c-1.861 0-2.419-.433-2.171-2.413.93-6.807 5.955-12.562 12.655-14.109 6.638-1.485 13.711 1.238 17.371 6.992.993 1.547 1.985 1.98 3.784 1.919 4.281-.124 7.755 1.732 10.236 5.321.373.557 1.179 1.238.621 1.919-.248.37-1.179.309-1.799.309h-20.473v.062Z"
        fill="#FDFDFD"
      />
      <path
        d="M219.15 156.734s.062.186.124.248c1.178 1.361 3.412 2.66 2.791 4.393-.434 1.176-3.04.186-4.59.495-12.16 0-24.257-.124-36.416 0-2.73 0-3.04-.804-2.544-3.218 1.489-7.054 6.266-11.942 13.338-13.366 6.266-1.237 12.904 1.609 16.626 7.364.869 1.361 1.675 1.98 3.35 1.795 2.73-.248 5.087.99 7.321 2.351v-.062Z"
        fill="#FEFDFD"
      />
      <path
        d="M180.19 239.157c55.745 0 100.935-45.075 100.935-100.677 0-55.603-45.19-100.677-100.935-100.677-55.744 0-100.934 45.074-100.934 100.677 0 55.602 45.19 100.677 100.934 100.677Z"
        stroke={secondaryColor}
        strokeMiterlimit={10}
      />
      <path
        d="M9.845 139.715c-4.102-7.155-6.702-14.811-8.656-22.756C-.36 110.657.14 104.243.043 97.893c-.097-6.205 3.068-11.572 6.944-16.294 8.914-10.846 19.91-18.776 33.767-21.967 9.608-2.207 19.152-1.37 28.163 2.837 10.141 4.722 19.475 10.83 28.05 17.97 5.571 4.658 8.93 11.056 11.885 17.631 2.568 5.722 4.296 11.701 4.764 17.89.404 5.318-.985 10.508-3.552 15.262-5.927 10.959-15.277 17.825-26.775 22.047-3.359 1.225-6.815 2.176-10.238 3.207-1.486.452-2.826 1.016-2.326 2.885 1.648 6.27-.872 12.523.275 19.227-2.794-1.821-4.296-4.319-6.072-6.414-3.004-3.578-5.62-7.494-8.268-11.362-.92-1.338-1.68-2.031-3.456-1.483-1.89.58-3.827-.435-5.749-.435-2.955 0-5.894.338-8.85.532-4.376-.226-19.345-3.224-28.776-19.695l.016-.016Z"
        fill="#E9F2F2"
      />
      <g
        style={{
          mixBlendMode: 'multiply',
        }}
        opacity={0.5}
        fill="#000"
      >
        <path d="M66.172 77.763c.969.42 2.034.677 2.89 1.257 1.857 1.274 2.616 3.16 2.616 5.383v49.349c0 2.224-.759 4.061-2.535 5.431-1.179.903-2.52 1.29-4.005 1.29H40.431c-3.715 0-6.444-2.643-6.54-6.35V84.468c0-2.256.742-4.158 2.616-5.448.855-.58 1.921-.854 2.89-1.257h26.807-.032Zm2.341 31.379V84.468c0-2.32-1.243-3.546-3.569-3.546H40.592c-2.325 0-3.569 1.241-3.569 3.546v49.349c0 2.272 1.244 3.513 3.537 3.513h24.4c.388 0 .792 0 1.163-.097 1.502-.37 2.374-1.595 2.374-3.288v-24.803h.016Z" />
        <path d="M52.736 135.219c-1.728 0-3.133-1.418-3.117-3.127 0-1.708 1.438-3.126 3.15-3.126 1.727 0 3.18 1.466 3.148 3.175a3.12 3.12 0 0 1-3.165 3.078h-.016Zm2.616-49.107h-3.004c-.985 0-1.68-.693-1.663-1.58 0-.854.694-1.53 1.647-1.53 2.051 0 4.086-.017 6.137 0 .936 0 1.647.692 1.647 1.547 0 .886-.71 1.547-1.696 1.563h-3.068Zm-8.365-3.111c.84 0 1.583.725 1.583 1.547s-.727 1.548-1.567 1.564c-.84 0-1.582-.725-1.582-1.547s.726-1.548 1.566-1.564Z" />
      </g>
      <path
        d="M350.974 69.676c1.718 7.917 2.318 15.768-1.199 23.423-2.529 5.517-6.322 9.973-11.136 13.613-6.711 5.076-13.843 9.401-21.997 11.605-4.457 1.207-8.964.538-13.373-.849-5.333-1.665-10.665-3.33-15.074-6.986-1.119-.93-1.589.082-2.189.653-2.593 2.448-4.814 5.321-8.007 7.084-.146.081-.243.228-.389.294-1.427.701-3.145 2.236-4.377 1.371-1.378-.98.503-2.579.778-3.95.584-2.938 1.378-5.827 2.075-8.749.324-1.355-.032-2.089-1.475-2.791-9.92-4.848-16.031-12.846-17.766-23.75-1.248-7.818 1.654-14.69 6.533-20.794 9.223-11.523 21.591-17.66 35.596-20.729 2.804-.62 5.9-.783 8.753-1.191 4.701-.67 9.353-.702 13.956-.425 10.277.62 17.944 5.762 22.84 14.951 3.712 6.317 5.414 12.454 6.451 17.22Z"
        fill="#E9F2F2"
      />
      <g
        style={{
          mixBlendMode: 'multiply',
        }}
        opacity={0.5}
        fill="#000"
      >
        <path d="M276.669 88.643c.325-.735.892-1.028 1.67-.98.454.033.924 0 1.426 0-.032-.13-.048-.228-.064-.326-.13-.653-.341-1.306-.357-1.959-.032-7.263-.016-14.51 0-21.774 0-2.84 2.01-4.896 4.83-4.896h39.989c2.788 0 4.798 2.056 4.798 4.864v21.823c0 .734-.21 1.469-.34 2.252h1.588c.924 0 1.443.522 1.427 1.436 0 .817.016 1.633-.097 2.433a5.047 5.047 0 0 1-4.782 4.26H282.1c-2.399 0-4.231-1.176-5.042-3.232-.162-.392-.259-.816-.372-1.24v-2.71l-.017.049Zm27.427-.963h19.857c1.588 0 2.431-.849 2.431-2.432V63.75c0-1.583-.843-2.432-2.415-2.432h-39.616c-1.605 0-2.432.833-2.432 2.481v21.334c0 1.73.795 2.546 2.529 2.546h19.646Zm24.93 2.644h-49.795c.016.31 0 .588.064.849.276 1.305 1.265 2.056 2.756 2.056h44.479c1.572-.032 2.642-1.273 2.496-2.889v-.016Z" />
        <path d="M304.161 65.677h-.973c-.697-.032-1.232-.538-1.264-1.224-.033-.637.421-1.273 1.086-1.322a12.98 12.98 0 0 1 2.253 0c.697.065 1.167.702 1.102 1.371-.065.686-.583 1.175-1.297 1.192h-.907v-.017Z" />
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.038 0h357.923v276H.038z" />
      </clipPath>
    </defs>
  </svg>
)
export default HeartFeltStepOne
