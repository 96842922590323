import {
  Maybe,
  ContactRequestStatusEnum,
} from '../../../__generated__/globalTypes'

import {
  OptionalYearDateFragment,
  isOptionalYearDateFragment,
} from './OptionalYearDateFragment'

import contactFragmentRawString from '../ContactFragment.graphql'

export const contactFragmentString = contactFragmentRawString

export type ContactFragment = {
  __typename: 'Contact'
  id: string
  firstName: string
  lastName: string
  address1: string
  address2: string
  company: string
  city: string
  state: string
  postalCode: string
  country: Maybe<string>
  isPrimary: boolean
  birthday: Maybe<OptionalYearDateFragment>
  anniversary: Maybe<OptionalYearDateFragment>
  contactRequest: Maybe<{
    id: string
    status: ContactRequestStatusEnum
    token: string
    expiresAt: string
  }>
}

export const isContactFragment = (fragment: any): fragment is ContactFragment =>
  fragment &&
  fragment.__typename === 'Contact' &&
  typeof fragment.id === 'string' &&
  typeof fragment.firstName === 'string' &&
  typeof fragment.lastName === 'string' &&
  typeof fragment.address1 === 'string' &&
  typeof fragment.address2 === 'string' &&
  typeof fragment.company === 'string' &&
  typeof fragment.city === 'string' &&
  typeof fragment.state === 'string' &&
  typeof fragment.postalCode === 'string' &&
  (fragment.country === null || typeof fragment.country === 'string') &&
  typeof fragment.isPrimary === 'boolean' &&
  (fragment.birthday === null ||
    (isOptionalYearDateFragment(fragment.birthday) as boolean)) &&
  (fragment.anniversary === null ||
    (isOptionalYearDateFragment(fragment.anniversary) as boolean)) &&
  (fragment.contactRequest === null ||
    (fragment.contactRequest &&
      typeof fragment.contactRequest.id === 'string' &&
      (fragment.contactRequest.status === 'PENDING' ||
        fragment.contactRequest.status === 'COMPLETED' ||
        fragment.contactRequest.status === 'EXPIRED') &&
      typeof fragment.contactRequest.token === 'string' &&
      typeof fragment.contactRequest.expiresAt === 'string'))
