import React, { FC } from 'react'
import { SVGProps } from './types'

export const CheckMark: FC<SVGProps> = ({ size, color, onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 19 18"
      width={size}
      height={size}
      onClick={onClick}
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M14.78.22a.75.75 0 010 1.06l-9 9a.75.75 0 01-1.06 0l-4.5-4.5a.75.75 0 111.06-1.06l3.97 3.97L13.72.22a.75.75 0 011.06 0z"
        clipRule="evenodd"
      />
    </svg>
  )
}
