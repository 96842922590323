import React from 'react'

import { Flex, Separator, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import { Button } from 'src/design_system/components/Button/Button'
import { SummaryItem } from './SummaryItem'
import { IconType } from 'src/design_system/atoms/icons/types'
import { getPlanDescriptions } from 'src/saleor/utils/getPlanDescriptions'
import {
  usePlans,
  useProductVariant,
  useProductVariants,
} from 'src/react_query/queries/hooks'
import { PlanFragment } from 'src/graphql/generated/graphql'
import getRecurrence from 'src/helpers/getRecurrence'

type StepSubscriptionProps = {
  packagePlan: PlanFragment
  selectedPlan: PlanFragment
  selectedAddons?: string[]
  onBack: () => void
  onContinue: (selectedPlanVariantId: string) => void
  isMobile: boolean
}

export const StepSubscription: React.FC<StepSubscriptionProps> = ({
  packagePlan,
  selectedPlan,
  selectedAddons,
  onBack,
  onContinue,
  isMobile,
}) => {
  const plansQuery = usePlans({ suspense: true })
  const plans = plansQuery.data!
  const planDescriptions = getPlanDescriptions(plans)
  const variantIdOnPlan = planDescriptions[selectedPlan.id]?.saleorVariantId

  const { data } = useProductVariant(
    { sku: selectedPlan.stripeId },
    !!!variantIdOnPlan,
  )

  const { data: addonData } = useProductVariants(
    {
      first: 10,
      ids: selectedAddons,
    },
    selectedAddons && selectedAddons.length > 0,
  )

  const selectedPlanVariantId = variantIdOnPlan ?? data?.id

  return (
    <>
      <Flex
        style={{
          padding: '1rem 2rem 2rem 2rem',
          paddingBottom: isMobile ? '80px' : '2rem',
        }}
      >
        <Flex flexDirection="column" width="100%">
          <Text
            type="caption"
            weight="bold"
            content="Your Package & Subscription:"
            style={{ color: '#D1D5DB' }}
          />
          <Spacer space="x1" />
          <SummaryItem
            title={packagePlan.title}
            icon={packagePlan.icon.toLowerCase() as IconType}
            itemColor={packagePlan.backgroundStartColor}
            price={packagePlan.price.description.split('.')[0]}
            perType="/pkg"
          />
          <Spacer space="x1" />
          <SummaryItem
            title={selectedPlan.title}
            icon={selectedPlan.icon.toLowerCase() as IconType}
            itemColor={selectedPlan.backgroundStartColor}
            price={selectedPlan.price.description.split('.')[0]}
            perType={getRecurrence(selectedPlan.id, selectedPlan.isAddon)}
          />
          <Spacer space="x1" />
          {addonData?.pages.map(page =>
            page?.edges.map(edge => {
              const price = edge.node.pricing?.price?.gross?.amount ?? 0
              const formattedPrice = `$${price.toFixed(2)}`
              return (
                <SummaryItem
                  key={edge.node.id}
                  title={edge.node.name}
                  icon={'smile_card_w_heart'}
                  itemColor={'#F075D4'}
                  price={formattedPrice}
                  perType="/trial"
                />
              )
            }),
          )}
          <Spacer space="x2" />
          <Flex
            flexDirection="column"
            borderRadius="medium"
            width="100%"
            backgroundColor="foreground"
            inset="x2"
          >
            <Flex justifyContent="space-between" alignItems="center">
              <Flex alignItems="baseline" columnGap="x_5">
                <Text
                  type="caption"
                  weight="extraBold"
                  content="Total Today"
                  style={{ color: '#404040' }}
                />
                <Text
                  type="footnote"
                  weight="bold"
                  content="(Package)"
                  style={{ color: '#D1D5DB', fontSize: '10px' }}
                />
              </Flex>
              <Text
                type="body"
                weight="semiBold"
                content={packagePlan.price.description}
              />
            </Flex>
            <Spacer space="x1" />
            <Separator orientation="horizontal" />
            <Spacer space="x1" />
            <Flex justifyContent="space-between" alignItems="center">
              <Flex alignItems="baseline" columnGap="x_5">
                <Text
                  type="caption"
                  weight="extraBold"
                  content="Total Ongoing"
                  style={{ color: '#404040' }}
                />
                <Text
                  type="footnote"
                  weight="bold"
                  content="(Subscription)"
                  style={{ color: '#D1D5DB', fontSize: '10px' }}
                />
              </Flex>
              <Text
                type="body"
                weight="semiBold"
                content={selectedPlan.price.description}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        justifyContent="flex-end"
        alignItems="center"
        inset={{ horizontal: 'x4', bottom: 'x2' }}
        style={{
          position: isMobile ? 'fixed' : undefined,
          bottom: isMobile ? 0 : undefined,
          right: isMobile ? 20 : undefined,
          backgroundColor: isMobile ? '#F8F5F6' : undefined,
        }}
      >
        <div onClick={onBack} style={{ cursor: 'pointer' }}>
          <Text
            type="body"
            weight="semiBold"
            style={{ textDecoration: 'underline' }}
            content="Back"
          />
        </div>
        <Spacer space="x2" orientation="horizontal" />
        <Button
          title="Yes, Add To My Cart"
          backgroundColor="#404040"
          textColor="#FFF"
          textSize="14px"
          padding="0.75rem 2rem"
          onClick={() => {
            if (selectedPlanVariantId) onContinue(selectedPlanVariantId)
          }}
          isDisabled={!selectedPlanVariantId}
        />
      </Flex>
    </>
  )
}
