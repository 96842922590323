import React from 'react'
import { dollarsAndCents } from 'src/helpers'
import { PlanFragment } from 'src/legacy_graphql'
import { SubscriptionPrice } from 'src/marketing/plans/components'
import { CenteredTitleHeader, PillTabsViewSlider } from 'src/chrome'
import { Text } from '@sendoutcards/quantum-design-ui'

type PlansSliderProps = {
  plans: PlanFragment[]
  tabs: string[]
  currentIndex: number
  onRightArrowClick: () => void
  onLeftArrowClick: () => void
  onTabClick: (name: string) => void
  shouldSkipPlansSliderAnimation: boolean
  isMediumScreen: boolean
}

const PlansSlider: React.FC<PlansSliderProps> = props => {
  const {
    plans,
    tabs,
    currentIndex,
    onRightArrowClick,
    onLeftArrowClick,
    onTabClick,
    shouldSkipPlansSliderAnimation,
    isMediumScreen,
  } = props
  return (
    <PillTabsViewSlider
      currentIndex={currentIndex}
      tabs={tabs}
      width="100%"
      onRightArrowClick={onRightArrowClick}
      onLeftArrowClick={onLeftArrowClick}
      onTabClick={onTabClick}
      arrowColor={'purple2'}
      shouldSkipAnimation={shouldSkipPlansSliderAnimation}
    >
      {plans.map(plan => {
        const amount = dollarsAndCents(plan.price.amount)
        return (
          <div
            key={plan.id}
            style={{
              width: '100%',
              height: isMediumScreen ? 500 : 720,
              padding: '30px 20%',
              backgroundImage: `linear-gradient(137deg, #F641B2
              , #FB4F62)`,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
            }}
          >
            <div>
              <CenteredTitleHeader
                headers={[
                  <Text
                    key={1}
                    type="title"
                    color="accent"
                    weight="bold"
                    css={{ textTransform: 'uppercase' }}
                  >
                    {plan.title}
                  </Text>,
                ]}
                subtitle={plan.subtitle}
                width={'100%'}
              />
              <SubscriptionPrice
                color="accent"
                dollars={amount.dollars}
                cents={amount.cents}
                per={'month'}
              />
            </div>
          </div>
        )
      })}
    </PillTabsViewSlider>
  )
}

export default PlansSlider
