import { SendableCardRoute } from 'src/catalog/routes/SendableCardRoute'
import { CustomCardRoute } from 'src/catalog/routes/CustomCardRoute'
import { CustomCardsRoute } from 'src/catalog/routes/CustomCardsRoute'
import { CardCollectionRoute } from 'src/catalog/routes/CardCollectionRoute'
import { CardCategoryRoute } from 'src/catalog/routes/CardCategoryRoute'
import { FavoriteCardsRoute } from 'src/catalog/routes/FavoriteCardsRoute'
import { LegacyPicturePlusCardsRoute } from 'src/catalog/routes/LegacyPicturePlusCardsRoute'
import { PhotoDropCardsRoute } from 'src/catalog/routes/PhotoDropCardsRoute'
import { setBodyOverflowHidden } from 'src/helpers'
import {
  useAppcues,
  useEffect,
  useMutations,
  usePersistedUserData,
  useQueries,
  useState,
} from 'src/hooks'
import { getCardCatalog, getSendableCards } from 'src/legacy_graphql'

import { Props } from './types'
import { CatalogCardsRoute } from './routes/CatalogCardsRoute'

const useApi = (props: Props) => {
  const { subroute, onNavigate } = props
  const { controlData } = usePersistedUserData()

  const [showPlans, setShowPlans] = useState(false)
  const [showBuildYourOwnModal, setShowBuildYourOwnModal] = useState(false)
  const previewedCard = (() => {
    // The responsibility of what is being picked should be moved
    // to the components that display the Cards vs SendableCards
    if (subroute && subroute.path === SendableCardRoute.path) {
      // if subroute is SendableCardRoute, we are on the global catalog and
      // a SendableCard has been picked
      return {
        type: 'SendableCard' as const,
        id: subroute.sendableCardId,
        onClose: () => onNavigate(),
      }
    }
    if (
      subroute &&
      'subroute' in subroute &&
      subroute?.subroute?.path === SendableCardRoute.path
    ) {
      // if subroute.subroute is SendableCardRoute, subroute is a card category
      // and a SendableCard has been picked
      return {
        type: 'SendableCard' as const,
        id: subroute.subroute.sendableCardId,
        onClose: () => onNavigate({ ...subroute, subroute: undefined }),
      }
    }
    if (subroute?.path === CustomCardRoute.path) {
      // if subroute is CustomCardRoute, the card being picked is a CustomCard
      return {
        type: 'Card' as const,
        id: subroute.cardId,
        onClose: () => onNavigate(CustomCardsRoute()),
      }
    }
    if (
      subroute &&
      'subroute' in subroute &&
      subroute?.subroute?.path === CustomCardRoute.path
    ) {
      // if subroute.subroute is CustomCardRoute, subroute is a card category
      // and a CustomCard has been picked
      return {
        type: 'Card' as const,
        id: subroute.subroute.cardId,
        onClose: () => onNavigate({ ...subroute, subroute: undefined }),
      }
    }
    return undefined
  })()

  const [search, setSearch] = useState('')

  const searchResultsQuery =
    search !== ''
      ? getSendableCards({
          search: search,
          category: undefined,
          collection: undefined,
          isPremium: undefined,
          favorite: undefined,
        })
      : undefined

  const mutations = useMutations()

  const cardCatalogQuery = getCardCatalog()
  const [cardCatalog, searchResults] = useQueries(
    cardCatalogQuery,
    searchResultsQuery,
  )

  const handleSearch = (value: string) => setSearch(value)

  const handleClearSearch = () => {
    setSearch('')
  }

  const handleFavoriteCard = async (id: string, isFavorite: boolean) => {
    try {
      if (isFavorite) {
        await mutations.unfavoriteCard({ id })
      } else {
        await mutations.favoriteCard({ id })
      }
    } catch (error) {
      console.error('Failed to favorite/unfavorite card.')
    }
  }

  const handleShowCollection = (id: string) => {
    onNavigate(CardCollectionRoute(id))
  }

  const handleShowCategory = (id: string) => {
    onNavigate(CardCategoryRoute(id))
  }

  const handleShowPremium = () => {
    onNavigate(PhotoDropCardsRoute())
  }

  const handleShowFavorites = () => {
    onNavigate(FavoriteCardsRoute())
  }

  const handleShowCatalogCards = () => {
    onNavigate(CatalogCardsRoute())
  }

  const handleShowCustom = () => {
    onNavigate(CustomCardsRoute())
  }

  const handleShowLegacyPicturePlus = () => {
    onNavigate(LegacyPicturePlusCardsRoute())
  }

  const handlePreview = (
    id: string,
    type: 'SendableCard' | 'Card' | 'Collection',
  ) => {
    switch (type) {
      case 'SendableCard':
        switch (subroute?.path) {
          case undefined:
          case SendableCardRoute.path:
          case CustomCardRoute.path:
          case CustomCardsRoute.path:
            onNavigate(SendableCardRoute(id))
            break
          case CardCollectionRoute.path:
            onNavigate(
              CardCollectionRoute(
                subroute.cardCollectionId,
                SendableCardRoute(id),
              ),
            )
            break
          case CardCategoryRoute.path:
            onNavigate(
              CardCategoryRoute(subroute.cardCategoryId, SendableCardRoute(id)),
            )
            break
          case FavoriteCardsRoute.path:
            onNavigate(FavoriteCardsRoute(SendableCardRoute(id)))
            break
          case LegacyPicturePlusCardsRoute.path:
            onNavigate(LegacyPicturePlusCardsRoute(SendableCardRoute(id)))
            break
          case PhotoDropCardsRoute.path:
            onNavigate(PhotoDropCardsRoute(SendableCardRoute(id)))
            break
        }
        break
      case 'Card':
        onNavigate(CustomCardRoute(id))
        break
      case 'Collection':
        onNavigate(CardCollectionRoute(id))
        break
    }
  }

  const shouldDisableScroll =
    !!previewedCard || !!controlData.isBlankCardModalOpen

  useAppcues('-L83V80t7XTfP4WxnT-4')

  useEffect(() => {
    setBodyOverflowHidden(shouldDisableScroll)

    return () => setBodyOverflowHidden(false)
  }, [shouldDisableScroll])

  return {
    search,
    handleSearch,
    handleFavoriteCard,
    cardCatalog,
    searchResults,
    previewedCard,
    showBuildYourOwnModal,
    setShowBuildYourOwnModal,
    showPlans,
    setShowPlans,
    handleClearSearch,
    handleShowCollection,
    handleShowCategory,
    handlePreview,
    handleShowFavorites,
    handleShowCustom,
    handleShowPremium,
    handleShowLegacyPicturePlus,
    shouldDisableScroll,
    handleShowCatalogCards,
  }
}

export default useApi

export type Api = ReturnType<typeof useApi>
