import React from 'react'
import { Flex, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import DraftedOrders from '../../DraftedOrders'
import DraftedCards from 'src/drafts/containers/DraftedCards'
import { useState } from 'src/hooks'
import { TabBar } from 'src/design_system/components/TabBar/TabBar'

/**
 *
 * TODO:
 * [] - Create a Ui that brings order history and card history under 1 UI
 * [] - Wire up route
 *
 */

export const DraftsView = () => {
  const [shouldShowDraftedOrders, setShouldShowDraftedOrders] = useState(false)
  const tabs = [
    {
      onClick: () => setShouldShowDraftedOrders(false),
      title: 'Drafted Cards',
    },
    {
      onClick: () => setShouldShowDraftedOrders(true),
      title: 'Drafted Orders',
    },
  ]

  const draftDescription = shouldShowDraftedOrders
    ? 'Continue editing a drafted order by selecting an unfinished creation below.'
    : 'Continue editing a drafted card by selecting an unfinished creation below.'
  return (
    <Flex flexDirection={'column'} width="100%">
      <Flex flexDirection="column">
        <Text
          type="subtitle"
          weight="bold"
          color="primaryHeading"
          content="My Drafts"
        />
        <Spacer space="x2_5" />
        <TabBar
          height="52px"
          tabs={tabs}
          activeTabIndex={shouldShowDraftedOrders ? 1 : 0}
          radius="12px"
          barBackgroundColor="#FFF"
          activeTabBackgroundColor="#404040"
          activeTabTextColor="#FFF"
        />
        <Spacer space="x4" />
        <Text
          type="largeBody"
          weight="bold"
          color="primaryHeading"
          content={shouldShowDraftedOrders ? 'Drafted Orders' : 'Drafted Cards'}
        />
        <Text type="body" color="primaryBody" content={draftDescription} />
        <Spacer space="x2_5" />
      </Flex>

      <Flex>
        {shouldShowDraftedOrders ? (
          <DraftedOrders />
        ) : (
          <DraftedCards isWrapContentEnabled={true} />
        )}
      </Flex>
    </Flex>
  )
}
