import { Maybe } from '../../../__generated__/globalTypes'

import promptingsCoachFragmentRawString from '../PromptingsCoachFragment.graphql'

export const promptingsCoachFragmentString = promptingsCoachFragmentRawString

export type PromptingsCoachFragment = {
  trainerId: Maybe<number>
  dateTrainingPurchased: Maybe<string>
  dateTrainingCompleted: Maybe<string>
  dateTrainingExpires: Maybe<string>
  dateCoachingPurchased: Maybe<string>
  dateAttendedCertification: Maybe<string>
  isEmailPublic: boolean
  isPhoneNumberPublic: boolean
  isCertified: boolean
  trainerEmail: Maybe<string>
}

export const isPromptingsCoachFragment = (
  fragment: any,
): fragment is PromptingsCoachFragment =>
  fragment &&
  (fragment.trainerId === null || typeof fragment.trainerId === 'number') &&
  (fragment.dateTrainingPurchased === null ||
    typeof fragment.dateTrainingPurchased === 'string') &&
  (fragment.dateTrainingCompleted === null ||
    typeof fragment.dateTrainingCompleted === 'string') &&
  (fragment.dateTrainingExpires === null ||
    typeof fragment.dateTrainingExpires === 'string') &&
  (fragment.dateCoachingPurchased === null ||
    typeof fragment.dateCoachingPurchased === 'string') &&
  (fragment.dateAttendedCertification === null ||
    typeof fragment.dateAttendedCertification === 'string') &&
  typeof fragment.isEmailPublic === 'boolean' &&
  typeof fragment.isPhoneNumberPublic === 'boolean' &&
  typeof fragment.isCertified === 'boolean' &&
  (fragment.trainerEmail === null || typeof fragment.trainerEmail === 'string')
