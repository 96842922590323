import React from 'react'

import CampaignLineCard from '../CampaignLine/CampaignLineCard'
import { MinimalCampaignFragment } from 'src/legacy_graphql'

type CampaignGridProps = {
  campaigns: MinimalCampaignFragment[]
  onPreview: (campaign: MinimalCampaignFragment) => void
  openCampaign: (campaignId: string) => void
}

const CampaignGrid: React.FC<CampaignGridProps> = props => {
  const { children, campaigns, onPreview, openCampaign } = props
  return (
    <div
      css={{
        width: '100%',
        maxWidth: '1028px',
        margin: 'auto',
        paddingBottom: '65px',
        display: 'flex',
        flexWrap: 'wrap',
        flex: '1 0 auto',
        justifyContent: 'left',
      }}
    >
      {children}
      {campaigns.map((campaign, index) => {
        return (
          <CampaignLineCard
            id={`campaign_line_card_${index}`}
            key={campaign.id}
            title={campaign.name}
            lines={campaign.minimalLines}
            buttons={{
              primaryButton: {
                label: 'View Details',
                onClick: () => onPreview(campaign),
              },
              secondaryButton: {
                label: 'Open Campaign',
                onClick: () => openCampaign(campaign.id),
              },
            }}
          />
        )
      })}
    </div>
  )
}

export default CampaignGrid
