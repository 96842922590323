import { Maybe } from '../../../__generated__/globalTypes'

import {
  MinimalSponsorFragment,
  isMinimalSponsorFragment,
} from './MinimalSponsorFragment'

import sponsorFragmentRawString from '../SponsorFragment.graphql'

export const sponsorFragmentString = sponsorFragmentRawString

export type SponsorFragment = MinimalSponsorFragment & {
  shippingAddress: Maybe<{
    city: string
    state: string
    country: string
  }>
}

export const isSponsorFragment = (fragment: any): fragment is SponsorFragment =>
  (isMinimalSponsorFragment(fragment) as boolean) &&
  (fragment.shippingAddress === null ||
    (fragment.shippingAddress &&
      typeof fragment.shippingAddress.city === 'string' &&
      typeof fragment.shippingAddress.state === 'string' &&
      typeof fragment.shippingAddress.country === 'string'))
