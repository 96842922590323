import { Route } from 'src/routes/Route'
import { Subroute } from 'src/routes/Subroute'
import { EditCampaignCardRoute } from './EditCampaignCardRoute'
import { AddCampaignCardRoute } from './AddCampaignCardRoute'
import { ReplaceCampaignCardRoute } from './ReplaceCampaignCardRoute'

export const CampaignSubroute = Subroute(
  AddCampaignCardRoute,
  EditCampaignCardRoute,
  ReplaceCampaignCardRoute,
)
export type CampaignSubroute = Subroute<typeof CampaignSubroute>

export const CampaignRoute = Route({
  path: '/:campaignId',
  init: (campaignId: string, subroute?: CampaignSubroute) => ({
    campaignId,
    subroute,
  }),
  fromPath: ({ campaignId }) =>
    campaignId ? { campaignId, subroute: undefined } : undefined,
  subroute: CampaignSubroute,
})
export type CampaignRoute = Route<typeof CampaignRoute>
