const createPropsGetter = <DP extends object>(defaultProps: DP) => {
  return <P extends Partial<DP>>(props: P) => {
    type PropsExcludingDefaults = Pick<P, Exclude<keyof P, keyof DP>>
    type RecomposedProps = DP & PropsExcludingDefaults
    if (typeof defaultProps === 'object' && typeof props === 'object') {
      return {
        ...(typeof defaultProps === 'object' ? defaultProps : {}),
        ...(typeof props === 'object' ? props : {}),
      } as RecomposedProps
    } else {
      throw new Error('wrong input type to createPropsGetter return function')
    }
  }
}

export default createPropsGetter
