import React from 'react'

import { FairUse } from 'src/styled'
import { Text } from '@sendoutcards/quantum-design-ui'
import { useQueries } from 'src/hooks'
import { getFairUseMarketingContent } from 'src/legacy_graphql'

interface Props {
  messages: string[]
}

const Disclaimer: React.FC<Props> = props => {
  const { messages } = props
  const [marketingContent] = useQueries(getFairUseMarketingContent())
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {messages &&
        messages.map((message, index) => (
          <Text key={index} type="footnote">
            {message}
          </Text>
        ))}
      <Text type="footnote">
        All Heartfelt Prompting Cards are subject to the {''}
        <FairUse
          target="blank"
          href={marketingContent.fairUsePolicyLink.url}
          onClick={e => e.stopPropagation()}
        >
          <Text
            type="footnote"
            color="primaryBrand"
            data-mktcontent="link:fairUsePolicyLink"
          >
            Fair Use Policy
          </Text>
        </FairUse>{' '}
        and all prices are in USD.
      </Text>
      <Text type="footnote">
        Cards included in the monthly subscriptions do not roll over and are
        only good during the monthly subscription period.
      </Text>
      <Text type="footnote">
        Cards included in the card packages do not roll over and are only good
        during the period of the package. Please refer to card packages for term
        details.
      </Text>
    </div>
  )
}

export default Disclaimer
