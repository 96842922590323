import React from 'react'
import Resizer from 'react-image-file-resizer'
// @src import
import {
  useCallback,
  useEffect,
  useMutations,
  useRef,
  useState,
} from '../../hooks'
import { IsMemoizedFuction } from 'src/hooks/dependencies'
import {
  ConfirmDialog,
  Div,
  UploadButton,
  UploadButtonRef,
} from '@sendoutcards/quantum-design-ui'
import { Api } from '../api'
import { ImageFragment, UserImageFragment } from 'src/legacy_graphql'

type UploadFooterProps = {
  api: Api
  onSelect?: ((image: ImageFragment) => void) & IsMemoizedFuction
}

const UploadFooter = (props: UploadFooterProps) => {
  const mutations = useMutations()
  const ref = useRef<UploadButtonRef>()
  const { onSelect } = props
  const [imageToUpload, setImageToUpload] = useState<File | null>()
  const [error, setError] = useState<string | undefined>()
  const updateElement = useCallback(
    (uploadedImage: UserImageFragment) => {
      onSelect?.(uploadedImage)
    },
    [onSelect],
  )

  const prepareImage = async (file: File): Promise<File> => {
    return new Promise(resolve =>
      Resizer.imageFileResizer(
        file,
        3500, // Max size for better resolution on big cards
        3500, // Max size for better resolution on big cards
        'PNG',
        100,
        0,
        uri => {
          if (uri instanceof File) {
            resolve(uri)
          } else {
            throw Error('Returned an invalid format!')
          }
        },
        'file',
      ),
    )
  }

  useEffect(() => {
    ;(async () => {
      if (!imageToUpload) return

      try {
        const image =
          imageToUpload.size > 5_000_000 // greater than 5MB
            ? await prepareImage(imageToUpload)
            : imageToUpload
        const uploadedImage = await mutations.uploadImage({
          image_file: image,
          tags: ['prompt-editor'],
        })
        if (uploadedImage.uploadImage.image) {
          updateElement?.(uploadedImage.uploadImage.image)
        }
      } catch (error) {
        console.error(error)
        if (error instanceof Error) {
          if (error.message.includes('413')) {
            setError('Image file is too big.')
          } else {
            setError(error.message)
          }
        }
      } finally {
        setImageToUpload(null)
        ref.current?.clearPreview()
      }
    })()
  }, [mutations, imageToUpload, updateElement])

  return (
    <Div outset={{ vertical: 'x1', horizontal: 'auto' }}>
      <ConfirmDialog
        accept={{
          title: 'OK',
          onClick: () => {
            setError(undefined)
          },
        }}
        onClose={() => {
          setError(undefined)
        }}
        title={'Upload Image Failed'}
        description={error ?? ''}
        isOpen={!!error}
      />
      <UploadButton
        width="162px"
        height="50px"
        title={imageToUpload ? 'Uploading' : 'Upload Image'}
        onUpload={file => {
          setImageToUpload(file)
        }}
        ref={ref}
        backgroundColor="background"
        boxShadow="light"
      />
    </Div>
  )
}

export default UploadFooter
