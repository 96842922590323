import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
// @src imports
import { Button } from 'src/chrome'
import { ImageFragment } from 'src/legacy_graphql'
import Images from 'src/editor/images/Images'
import { useEffect, useMutations, useState } from 'src/hooks'

import { Api } from '../../api'
import { Steps } from '../../types'

interface FacebookPhoto {
  id: string
  images: {
    source: string
  }[]
}

const getFacebookPhotos = (limit: number, after?: number | null) =>
  new Promise<{ images: FacebookPhoto[]; cursor: number | null }>(
    (resolve, reject) => {
      window.FB.api(
        '/me/photos',
        { fields: 'id,images', type: 'uploaded', limit, after },
        ({ data, paging, error }) => {
          if (error) {
            reject(error)
          } else {
            resolve({
              images: data,
              cursor: paging ? paging.cursors.after : null,
            })
          }
        },
      )
    },
  )

type Props = {
  api: Api & { step: { type: Steps.EditPicture | Steps.EditBackgroundPicture } }
}

type Image = {
  id: string
  photoUrl: string
  smallThumb: string
}

const FacebookImages: React.FC<Props> = props => {
  const {
    api: { step, setElementImage },
  } = props
  const [loginStatus, setLoginStatus] = useState<fb.LoginStatus>()
  const [images, setImages] = useState(Array<Image>())
  const [isLoading, setIsLoading] = useState(true)
  const [cursor, setCursor] = useState<number>()

  const mutations = useMutations()

  const flexButton: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }

  const onSelect = (image: ImageFragment) => {
    switch (step.type) {
      case Steps.EditPicture:
        setElementImage(
          step.fullBleedIndex,
          step.panelIndex,
          step.elementIndex,
          image,
        )
        break
      case Steps.EditBackgroundPicture:
        setElementImage(step.fullBleedIndex, undefined, 0, image)
        break
    }
  }

  useEffect(() => {
    if (!loginStatus) {
      window.FB.getLoginStatus(response => setLoginStatus(response.status))
    }
  }, [loginStatus])

  useEffect(() => {
    ;(async () => {
      if (!isLoading || loginStatus !== 'connected') return
      try {
        const result = await getFacebookPhotos(20, cursor)
        setImages(images => [
          ...images,
          ...result.images.map(({ id, images }) => ({
            id,
            photoUrl: images[0].source,
            smallThumb: images[images.length - 1].source,
          })),
        ])
        setCursor(result.cursor || undefined)
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [cursor, isLoading, loginStatus])

  const createPhoto = async (url: string) => {
    try {
      const {
        uploadImage: { image },
      } = await mutations.createImageWithURL({ url })
      onSelect(image as ImageFragment)
    } catch (error) {
      console.error(error)
    }
  }

  if (!loginStatus) {
    return <CircularProgress />
  }

  if (loginStatus !== 'connected') {
    return (
      <div style={flexButton}>
        <Button
          title={'Login with Facebook'}
          buttonColor={'facebook'}
          onClick={() =>
            window.FB.login(response => setLoginStatus(response.status), {
              scope: 'user_photos',
            })
          }
        />
      </div>
    )
  }

  return (
    <div style={{ height: '100%', overflowY: 'auto' }}>
      <Images
        result={images}
        isLoading={isLoading}
        hasMore={!!cursor}
        loadMore={() => setIsLoading(true)}
        onSelect={({ photoUrl }) => createPhoto(photoUrl)}
      />
    </div>
  )
}

export default FacebookImages
