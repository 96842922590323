import React from 'react'
import { TransitionGroup } from 'react-transition-group'

const AnimateHOC = <WrappedProps extends {}>(
  WrappedComponent: React.ComponentType<WrappedProps>,
) => {
  type Props = WrappedProps & {
    isVisible?: boolean
  }

  const AnimatedHOC: React.FC<Props> = props => {
    const isVisible: boolean = !!props.isVisible
    const { ...restProps } = props

    return (
      <TransitionGroup appear={isVisible} component={null}>
        {isVisible && <WrappedComponent {...restProps} />}
      </TransitionGroup>
    )
  }

  return AnimatedHOC
}

export default AnimateHOC
