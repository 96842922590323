import { Flex } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { AddOnTile } from './AddOnTile'
import { PlanIdType } from '../pricingTile/PlanTiles'
import { usePlanManagement } from 'src/saleor/utils/usePlanManagement'

type AddOnTilesProps = {
  planIds: PlanIdType[]
  layout?: 'scroll' | 'grid'
  onPurchaseNow: () => void
}

export const AddOnTiles = (props: AddOnTilesProps) => {
  const { planIds, layout = 'grid', onPurchaseNow } = props

  const { planDescriptions, activePlans: activeAddOns } = usePlanManagement(
    planIds,
  )

  const activePlansWithVariants = activeAddOns.map(plan => ({
    plan,
    variantId: planDescriptions[plan.id]?.saleorVariantId,
  }))

  return (
    <Flex
      flexDirection="row"
      columnGap={'24px'}
      rowGap="24px"
      width="100%"
      justifyContent={'center'}
      flexWrap={layout === 'grid' ? 'wrap' : 'nowrap'}
      alignItems="center"
    >
      {activePlansWithVariants.map(addOnWithVariant => {
        const { plan } = addOnWithVariant
        return (
          <AddOnTile
            key={plan.id}
            onPurchaseNow={onPurchaseNow}
            plan={plan}
            variantId={addOnWithVariant.variantId}
            stripeId={plan.stripeId}
          />
        )
      })}
    </Flex>
  )
}
