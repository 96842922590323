import { GroupFragment, isGroupFragment } from './GroupFragment'

import { ContactFragment, isContactFragment } from './ContactFragment'

import detailedGroupFragmentRawString from '../DetailedGroupFragment.graphql'

export const detailedGroupFragmentString = detailedGroupFragmentRawString

export type DetailedGroupFragment = GroupFragment & {
  contacts: ContactFragment[]
}

export const isDetailedGroupFragment = (
  fragment: any,
): fragment is DetailedGroupFragment =>
  (isGroupFragment(fragment) as boolean) &&
  Array.isArray(fragment.contacts) &&
  fragment.contacts
    .slice(0, 5)
    .reduce(
      (accum: any, next: any) => accum && (isContactFragment(next) as boolean),
      true,
    )
