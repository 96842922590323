import { useRef } from 'react'
import fastDeepEqual from 'fast-deep-equal'
import { AnyMemoized } from './dependencies'

type ReturnType<T extends AnyMemoized[] | undefined> = T extends undefined
  ? AnyMemoized[] | undefined
  : AnyMemoized[]

const REPORTING_THRESHOLD = 3

const reportPerformance = (
  originalDeps: AnyMemoized[] | undefined,
  newDeps: AnyMemoized[] | undefined,
) => {
  console.warn(
    `Deep comparing dependencies took longer than ${REPORTING_THRESHOLD} milliseconds. originalDeps: ${originalDeps}, newDeps: ${newDeps}`,
  )
  console.dir(originalDeps, newDeps)
  console.trace()
}

/**
 * Converts deep dependencies to shallow dependencies.
 */
const useMemoizedDeps = <T extends AnyMemoized[] | undefined>(
  newDeps: T,
): ReturnType<T> => {
  const mutableRef = useRef<AnyMemoized[]>()

  const start = new Date()
  const originalDeps = mutableRef.current

  if (!fastDeepEqual(originalDeps, newDeps)) {
    mutableRef.current = newDeps
  }

  const duration = new Date().getTime() - start.getTime()

  if (duration > REPORTING_THRESHOLD) {
    reportPerformance(originalDeps, newDeps)
  }

  if (process.env.NODE_ENV === 'development') {
    const changedDeps = newDeps?.reduce((accum, dependency, index) => {
      if (originalDeps && dependency !== originalDeps[index]) {
        return {
          ...accum,
          [index]: {
            before: originalDeps[index],
            after: dependency,
          },
        }
      }

      return accum
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    }, {} as Record<string, any>)

    if (changedDeps && Object.keys(changedDeps).length) {
      process.env.REACT_APP_USE_EFFECT_DEBUG &&
        console.log('[use-effect-debugger] ', changedDeps)
    }
  }

  return mutableRef.current as ReturnType<T>
}

export default useMemoizedDeps
