import debounce from 'lodash/debounce'
import { useEffect, useState } from './'

const useMeasurement = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  useEffect(() => {
    const listener = debounce(
      () => {
        setWindowHeight(window.innerHeight)
        setWindowWidth(window.innerWidth)
      },
      300,
      { leading: false, trailing: true },
    )

    window.addEventListener('resize', listener)

    return () => window.removeEventListener('resize', listener)
  }, [])

  return {
    windowWidth,
    windowHeight,
  }
}

export default useMeasurement
