import { Button, Div } from '@sendoutcards/quantum-design-ui'
import React from 'react'

import { Icon } from 'src/chrome'
import { useState } from 'src/hooks'

import styles from './floatingDrawer.module.scss'

type FloatingDrawerProps = {
  className: string
  isOpen: boolean
  onClick: () => void
  style?: React.CSSProperties
  triggerTitle: string
  triggerCloseTitle: string
}

const FloatingDrawer: React.FC<FloatingDrawerProps> = props => {
  const [isOpen, setIsOpen] = useState(props.isOpen)

  const toggleDrawer = () => setIsOpen(prevOpen => !prevOpen)

  const {
    children,
    className,
    onClick,
    style,
    triggerCloseTitle,
    triggerTitle,
  } = props

  return (
    <Div
      className={
        isOpen
          ? `${styles.floatingDrawer} ${styles.open}`
          : `${styles.floatingDrawer} ${className}`
      }
    >
      {!isOpen && (
        <Button
          type="primary"
          id="customize_btn"
          title={triggerTitle}
          onClick={
            onClick
              ? () => {
                  onClick()
                  toggleDrawer()
                }
              : toggleDrawer
          }
        />
      )}
      {isOpen && (
        <Div
          id="return_to_customize"
          className={styles.toggle}
          style={style}
          onClick={
            onClick
              ? () => {
                  onClick()
                  toggleDrawer()
                }
              : toggleDrawer
          }
        >
          <Icon
            icon={'PLUS'}
            style={{
              marginBottom: '-0.65rem',
              marginTop: '0.5rem',
            }}
          />
          <p>{triggerCloseTitle ? triggerCloseTitle : 'Close'}</p>
        </Div>
      )}
      <div className={styles.floatingMenu}>{children}</div>
    </Div>
  )
}

export default FloatingDrawer
