import { Route } from 'src/routes/Route'
import { Subroute } from 'src/routes/Subroute'
import { CatalogCardsRoute } from 'src/catalog/routes/CatalogCardsRoute'
import { CardCategoryRoute } from 'src/catalog/routes/CardCategoryRoute'
import { CardCollectionRoute } from 'src/catalog/routes/CardCollectionRoute'
import { CustomCardRoute } from 'src/catalog/routes/CustomCardRoute'
import { CustomCardsRoute } from 'src/catalog/routes/CustomCardsRoute'
import { FavoriteCardsRoute } from 'src/catalog/routes/FavoriteCardsRoute'
import { LegacyPicturePlusCardsRoute } from 'src/catalog/routes/LegacyPicturePlusCardsRoute'
import { PhotoDropCardsRoute } from 'src/catalog/routes/PhotoDropCardsRoute'
import { SendableCardRoute } from 'src/catalog/routes/SendableCardRoute'
import { CardRoute } from 'src/card/routes/CardRoute'

const AddCampaignCardSubRoute = Subroute(
  CardCategoryRoute,
  CardCollectionRoute,
  CustomCardRoute,
  CustomCardsRoute,
  FavoriteCardsRoute,
  LegacyPicturePlusCardsRoute,
  PhotoDropCardsRoute,
  SendableCardRoute,
  CatalogCardsRoute,
  CardRoute,
)
type AddCampaignCardSubRoute = Subroute<typeof AddCampaignCardSubRoute>

export const AddCampaignCardRoute = Route({
  path: '/cards/add',
  init: (subroute?: AddCampaignCardSubRoute) => ({ subroute }),
  fromPath: () => ({}),
  subroute: AddCampaignCardSubRoute,
})

export type AddCampaignCardRoute = Route<typeof AddCampaignCardRoute>
