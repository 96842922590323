import React from 'react'

type TableRowProps = {
  style?: React.CSSProperties
  onClick?: () => void
}

const TableRow: React.FC<TableRowProps> = props => {
  const { children, ...restProps } = props
  return <tr {...restProps}>{children}</tr>
}

export default TableRow
