import React from 'react'
import { IncrementSetting } from '@sendoutcards/quantum-design-ui'
import styles from './slider.module.scss'

const minFontSize = 10
const maxFontSize = 40

interface FontSizeSliderProps {
  fontSize: number
  selectFontSize: (size: number) => void
}

const FontSizeSlider: React.FC<FontSizeSliderProps> = props => {
  const { fontSize, selectFontSize } = props
  return (
    <div className={styles.scaleContainer}>
      <IncrementSetting
        layout="inlineBlock"
        value={fontSize}
        setValue={size => {
          if (size !== fontSize) {
            selectFontSize(size)
          }
        }}
        min={minFontSize}
        max={maxFontSize}
        title={'Font Size'}
      />
    </div>
  )
}

export default FontSizeSlider
