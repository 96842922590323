import { Maybe } from '../../../__generated__/globalTypes'

import { ColorFragment, isColorFragment } from './ColorFragment'

import { ElementFragment, isElementFragment } from './ElementFragment'

import panelFragmentRawString from '../PanelFragment.graphql'

export const panelFragmentString = panelFragmentRawString

export type PanelFragment = {
  __typename: 'Panel'
  location: number
  isFullBleed: boolean
  isLocked: boolean
  backgroundColor: Maybe<ColorFragment>
  name: string
  width: number
  height: number
  previewUrl: string
  elements: ElementFragment[]
}

export const isPanelFragment = (fragment: any): fragment is PanelFragment =>
  fragment &&
  fragment.__typename === 'Panel' &&
  typeof fragment.location === 'number' &&
  typeof fragment.isFullBleed === 'boolean' &&
  typeof fragment.isLocked === 'boolean' &&
  (fragment.backgroundColor === null ||
    (isColorFragment(fragment.backgroundColor) as boolean)) &&
  typeof fragment.name === 'string' &&
  typeof fragment.width === 'number' &&
  typeof fragment.height === 'number' &&
  typeof fragment.previewUrl === 'string' &&
  Array.isArray(fragment.elements) &&
  fragment.elements
    .slice(0, 5)
    .reduce(
      (accum: any, next: any) => accum && (isElementFragment(next) as boolean),
      true,
    )
