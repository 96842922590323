import React from 'react'
import {
  Button,
  Div,
  Flex,
  Input,
  Separator,
  Spacer,
  Text,
  Transition,
  VStack,
} from '@sendoutcards/quantum-design-ui'
import { useAccount, useActions, useDebounce, useState } from 'src/hooks'
import useEmailValidation from 'src/hooks/useEmailValidation'
import useUsernameValidation from 'src/hooks/useUsernameValidation'
import { sanitizeUsername } from 'src/helpers/sanitizeUsername'
import { useUpdateAccount } from 'src/react_query'
import omit from 'lodash/omit'

const CreateAccount = (props: {
  onCreatedAccount: () => void
  orderNumber?: string
  isOrderFree?: true
}) => {
  const { orderNumber, onCreatedAccount, isOrderFree } = props
  const updateAccountMutation = useUpdateAccount()
  const actions = useActions()
  const [email, setEmail] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [
    updateAccountFailureMessage,
    setUpdateAccountFailureMessage,
  ] = useState<string>()
  const { firstName, lastName, shippingAddress } = useAccount()

  const debouncedEmail = useDebounce(email, 500)
  const emailValidation = useEmailValidation(debouncedEmail)

  const handleUsernameChange = (value: string) => {
    setUsername(sanitizeUsername(value))
  }
  const debouncedUsername = useDebounce(username, 500)
  const usernameValidation = useUsernameValidation(debouncedUsername)

  const onSubmit = async (
    username: string,
    password: string,
    email: string,
  ) => {
    try {
      setIsLoading(true)
      if (
        !usernameValidation.isValid ||
        usernameValidation.isLoading ||
        !emailValidation.isValid ||
        emailValidation.isLoading
      ) {
        return
      }
      const {
        updateAccount: { account: updatedAccount },
      } = await updateAccountMutation.mutateAsync({
        account: {
          username,
          password,
          email,
          firstName,
          lastName,
          shippingAddress: omit(shippingAddress, '__typename'),
        },
      })
      actions.updatedAccount(updatedAccount)
      onCreatedAccount()
    } catch (error) {
      setUpdateAccountFailureMessage(
        error?.toString() ?? 'Failed to update account.',
      )
    } finally {
      setIsLoading(false)
    }
  }

  if (isLoading) {
    return <Transition title={'Creating your Account'} isLoading={isLoading} />
  }
  return (
    <form
      onSubmit={event => {
        event.preventDefault()
        onSubmit(debouncedUsername, password, debouncedEmail)
      }}
    >
      {orderNumber && isOrderFree && (
        <div>
          <Text type="title" weight="bold">
            Your card has been sent!
          </Text>
          <Spacer space="x2" />

          <Div>
            <Text type="caption">
              Order Number: <span>#{orderNumber}</span>
            </Text>
            <Separator orientation={'horizontal'} />
            <Spacer space="x2" />
          </Div>

          <Text type="caption">
            To continue, please finish setting up your account by entering your
            favorite username and password.
          </Text>
        </div>
      )}
      {!orderNumber && (
        <div>
          <Text type="title" weight="bold">
            Finish Setting Up Your Account
          </Text>
          <Spacer space="x2" />
          <Text type="caption">
            To complete your purchase, please finish setting up your account by
            entering your favorite username and password.
          </Text>
        </div>
      )}
      <Spacer space="x4" />
      <VStack gap="x2_5">
        <Input
          icon="envelope"
          placeholder="Email"
          onChange={setEmail}
          value={email}
          type={'text'}
          message={
            emailValidation.errorMessage && email
              ? {
                  type: 'danger',
                  content: emailValidation.errorMessage,
                  icon: 'information',
                }
              : undefined
          }
          isFullWidth={true}
          isLoading={emailValidation.isLoading}
        />
        <Input
          icon="user"
          placeholder="Username"
          onChange={handleUsernameChange}
          value={username}
          type={'text'}
          message={
            !usernameValidation.isValid &&
            !!username &&
            !usernameValidation.isLoading
              ? { type: 'danger', content: 'Username is already taken.' }
              : undefined
          }
          isFullWidth={true}
          isLoading={usernameValidation.isLoading}
        />
        <Input
          icon="lock"
          placeholder="Password"
          type="password"
          onChange={setPassword}
          value={password}
          message={
            updateAccountFailureMessage
              ? { type: 'danger', content: updateAccountFailureMessage }
              : undefined
          }
          isFullWidth={true}
        />
      </VStack>
      <Spacer space="x4" />
      <Flex justifyContent="center">
        <Button
          title={'Finish my account'}
          onClick={() => onSubmit(debouncedUsername, password, debouncedEmail)}
          disabled={
            !username ||
            !password ||
            !email ||
            email !== debouncedEmail ||
            emailValidation.isLoading ||
            !emailValidation.isValid ||
            username !== debouncedUsername ||
            usernameValidation.isLoading ||
            !usernameValidation.isValid
          }
        />
      </Flex>
      <Spacer space="x2" />
      <div style={{ margin: '20px 0 0 0' }}>
        <Flex justifyContent="center">
          <Button
            title={'Go Back to Catalog'}
            onClick={() => actions.openCatalog()}
          />
        </Flex>
      </div>
    </form>
  )
}
export default CreateAccount
