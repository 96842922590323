import { ActionCreator } from '@sendoutcards/core'

export const selectedCatalogCard = ActionCreator(
  'SELECTED_CATALOG_CARD',
  (dataType: 'SendableCard' | 'Card', id: string) => ({ dataType, id }),
)

export const selectedCardCategory = ActionCreator(
  'SELECTED_CARD_CATEGORY',
  (categoryId: string) => ({ categoryId }),
)

export const selectedCardCollection = ActionCreator(
  'SELECTED_CARD_COLLECTION',
  (collectionId: string) => ({ collectionId }),
)

export const selectedPhotoDropCards = ActionCreator('SELECTED_PHOTO_DROP_CARDS')

export const selectedStandardCards = ActionCreator('SELECTED_STANDARD_CARDS')

export const dismissedCatalogSubroute = ActionCreator(
  'DISMISSED_CATALOG_SUBROUTE',
)
