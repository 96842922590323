import { Interpolation } from '@emotion/css'
import { Theme } from 'src/theme/theme'

const styles = {
  paymentWrapper: {
    padding: 40,
    width: '80%',
    margin: 'auto',
    '@media (max-width: 668px)': {
      padding: 10,
      width: '100%',
    },
  } as Interpolation,
  cardsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  } as Interpolation,
  cardSection: {
    width: '400px',
    margin: '0 50px',
  },
  cardHeader: {
    marginTop: 50,
    marginBottom: 25,
  },
  cardFooter: {
    marginTop: 20,
  },
  link: {
    color: 'rgb(118, 184, 235)',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  formCard: (theme: Theme): Interpolation => ({
    background: theme.colors.white,
    padding: '63px 15px 25px 15px',
    borderRadius: 10,
    marginTop: -45,
    marginLeft: -15,
    zIndex: -1,
  }),
}

export default styles
