import {
  Button,
  Dialog,
  Div,
  Flex,
  Text,
} from '@sendoutcards/quantum-design-ui'
import React, { FC, ReactNode, Suspense } from 'react'
import { ErrorBoundary } from 'src/chrome'

export type SuspenseBoundaryProps = {
  unresolved: NonNullable<ReactNode>
  failure: (error: Error) => NonNullable<ReactNode>
}

export const DefaultError = (error: Error) => {
  // We are logging the error to the console to make it easier to devs and
  // support to categorize and identify the cause.
  console.error(`An error has occurred: ${error.toString()}\n${error.stack}`)
  console.dir(error)

  const is504 = error.toString().startsWith('Response 504')
  const dismissMessage = is504 ? 'Reload' : 'Back to Catalog'

  const redirect = () => {
    window.location.href = 'https://app.sendoutcards.com/catalog'
  }

  const dismissErrorDialog = () => {
    if (is504) {
      window.location.reload()
    } else {
      redirect()
    }
  }

  return (
    <Div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'white',
      }}
    >
      <Div>
        <Dialog isOpen={true}>
          <Flex flexDirection="column" alignItems="center" rowGap="x2">
            <Text type="subtitle" alignment="center">
              Sorry! An error has occurred.
            </Text>
            <Text type="body" alignment="center">
              For further assistance, contact the Customer Success team at (801)
              463-3800 to resolve this issue.
            </Text>
            <Text type="body">Report error code: "{error.toString()}"</Text>
            <Button onClick={() => dismissErrorDialog()}>
              {dismissMessage}
            </Button>
          </Flex>
        </Dialog>
      </Div>
    </Div>
  )
}
const SuspenseBoundary: FC<SuspenseBoundaryProps> = ({
  unresolved,
  failure,
  children,
}) => (
  <Suspense fallback={unresolved}>
    <ErrorBoundary fallback={failure}>{children}</ErrorBoundary>
  </Suspense>
)

export default SuspenseBoundary
