import orderBy from 'lodash/orderBy'
import moment from 'moment'

import { update } from 'src/helpers'
import {
  DetailedOrderFragment,
  LineFragment,
  Maybe,
  SendDelayDelayType,
  SendDelayFragment,
  SendDelayTimeType,
  SendDelayType,
} from 'src/graphql/generated/graphql'

export type PartialLine = Omit<
  LineFragment,
  'baseCost' | 'cost' | 'recipientCount'
> &
  Partial<Pick<LineFragment, 'baseCost' | 'cost' | 'recipientCount'>>

export const mapLines = <Line, Obj extends { lines: Line[] }>(
  obj: Obj,
  activeLine: Line,
  transform: (line: Line) => Line,
) => {
  const activeIndex = obj.lines.indexOf(activeLine)
  const newLines = update(obj.lines, activeIndex, transform)
  return {
    ...obj,
    lines: newLines,
  }
}

/*
  Sorts by sendDelay.type primarily,
  then sendDelay.delayType,
  sendDelay.delayNumber,
  sendDelay.specificDate,
  finally by card.id
*/
export const sortLines = <
  T extends {
    lines: { card?: Maybe<{ id: string }>; sendDelay: SendDelayFragment }[]
  }
>(
  obj: T,
): T => {
  const delayPriority = {
    IMM: 0,
    ANN: 1,
    BIR: 2,
    SPE: 3,
  }
  const intervalPriority = {
    DAY: 0,
    WEE: 1,
    MON: 2,
  }
  const sortedLines = orderBy(
    obj.lines,
    [
      e => {
        return delayPriority[e.sendDelay.type]
      },
      e => {
        return intervalPriority[e.sendDelay.delayType]
      },
      e => {
        return e.sendDelay.delayNumber
      },
      e => {
        return e.sendDelay.specificDate
      },
      e => {
        return e.card ? e.card.id : 0
      },
    ],
    ['asc', 'asc', 'asc', 'asc', 'asc'],
  )
  return { ...obj, lines: sortedLines }
}

export const addLine = <Card>(card: Card) => {
  return {
    giftVariation: null,
    sendDelay: {
      delayNumber: 0,
      delayType: SendDelayDelayType.Day,
      timeType: SendDelayTimeType.Bef,
      type: SendDelayType.Imm,
      specificDate: moment(new Date()).format('YYYY-MM-DD'),
    },
    card,
  }
}

export const findActiveIndex = (
  order: DetailedOrderFragment,
  activeLine: PartialLine,
) => {
  return order.lines.findIndex(item => item?.card?.id === activeLine?.card?.id)
}
