import React from 'react'

import { SendableCardWithTemplateFragment } from 'src/graphql/generated/graphql'
import styles from './variationToggle.module.scss'

type VariationToggleProps = {
  variations: SendableCardWithTemplateFragment[]
  selectedVariationId?: string
  selectVariation: (variation: SendableCardWithTemplateFragment) => void
  style?: React.CSSProperties
  className?: string
}

const VariationToggle: React.FC<VariationToggleProps> = props => {
  const { variations, selectedVariationId, selectVariation, style } = props
  return (
    <div id="variation_toggle" className={styles.variationToggle} style={style}>
      {variations.map(variation => {
        const hasHex = new RegExp('#')
        const isHexValue = hasHex.test(variation.variationColor)
        const variationColor: React.CSSProperties = {
          backgroundColor: isHexValue
            ? variation.variationColor
            : `#${variation.variationColor}`,
        }
        const activeBorderColor: React.CSSProperties = {
          borderColor: isHexValue
            ? variation.variationColor
            : `#${variation.variationColor}`,
        }
        const activeBorder =
          variation.id === selectedVariationId ? activeBorderColor : {}
        return (
          <div
            id={`variation_toggle_${variation.id}`}
            className={styles.variation}
            style={activeBorder}
            onClick={() => selectVariation(variation)}
            key={variation.id}
          >
            <div className={styles.variationColor} style={variationColor} />
          </div>
        )
      })}
    </div>
  )
}

export default VariationToggle
