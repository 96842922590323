import { Operation } from '../../../__generated__/globalTypes'

import {
  GroupFragment,
  groupFragmentString,
} from '../../fragments/__generated__/GroupFragment'

import getAllGroupsRawString from '../GetAllGroups.graphql'

const getAllGroupsString = [getAllGroupsRawString, groupFragmentString].join(
  '\n\n',
)

export type GetAllGroupsData = {
  groups: GroupFragment[]
}

export type GetAllGroups = Operation<GetAllGroupsData>

export const GetAllGroups = (): GetAllGroups => ({
  query: getAllGroupsString,
  variables: undefined,
})
