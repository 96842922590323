import {
  LEGACY_31,
  LEGACY_39,
  LEGACY_980_1,
  LEGACY_980_2,
} from 'src/marketing/plans/constants'
import { usePlans } from 'src/react_query'
import { useCallback, useSelector } from '.'
import { getSubscriptions } from './useSubscriptions'

const usePlansSubscriptions = () => {
  const {
    user: { account },
  } = useSelector(state => state)

  const { data: plans } = usePlans({ suspense: true })
  if (!plans) {
    throw new Error("Missing plans' data")
  }

  const newPlanStripeId = account?.stripeSubscription?.metadata?.planOnCancel
  const newPlan = plans.find(plan => plan.stripeId === newPlanStripeId)
  const allSubscriptions = account?.subscriptions
  const currentPlan = account?.plan

  const getCurrentPlanSku = useCallback(() => {
    const stripeId = currentPlan?.stripeId
    if (currentPlan && allSubscriptions && stripeId === 'legacy') {
      // CHECK FOR POINTS SUBSCRIPTIONS
      const activeLegacyPlanSku = getSubscriptions(
        currentPlan,
        allSubscriptions,
      )
        .map(subscription => subscription.sku)
        .filter(
          sku =>
            sku === LEGACY_39 ||
            sku === LEGACY_980_1 ||
            sku === LEGACY_980_2 ||
            sku === LEGACY_31,
        )
      const [legacyPlanSku] = activeLegacyPlanSku
      return legacyPlanSku === LEGACY_980_2
        ? LEGACY_980_1
        : legacyPlanSku === LEGACY_31
        ? LEGACY_39
        : legacyPlanSku
    }
    return stripeId
  }, [currentPlan, allSubscriptions])

  return {
    currentSubscription: account?.stripeSubscription,
    currentPlan,
    newPlan,
    getCurrentPlanSku,
  }
}

export default usePlansSubscriptions
