import React from 'react'

import { Flex, Icon, Text } from '@sendoutcards/quantum-design-ui'
import { useSelector } from 'src/hooks'

interface ImageControlsProps {
  id: string
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void
}

const ImageControls: React.FC<ImageControlsProps> = props => {
  const { id, onClick } = props

  const isMobile = useSelector(state => state.window.isMobile)

  return (
    <Flex justifyContent="center" width="100%" top="55%" position="absolute">
      <Flex
        justifyContent="space-evenly"
        alignItems="center"
        minWidth="40px"
        backgroundColor={{ swatch: 'primaryBrand', shade: 'base' }}
        inset={isMobile ? 'x_5' : 'x1'}
        borderRadius="circle"
        boxShadow="dark"
        id={id}
        onMouseEvent={e => onClick(e as React.MouseEvent<HTMLDivElement>)}
        zIndex={102}
      >
        <Flex inset={{ left: 'x1' }}>
          <Icon
            size="xSmall"
            primaryColor="inverseHeadingText"
            name="imageUpload"
          />
        </Flex>
        <Flex inset={{ right: 'x1' }}>
          <Text
            whiteSpace="nowrap"
            color="inverseHeading"
            type="caption"
            content="+ image"
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default ImageControls
