import React from 'react'

import { Icon, PoseFade, Slide } from 'src/chrome'
import { ColorChoices } from 'src/theme/colors'
import { createPropsGetter, wrappedInjectSheet } from 'src/helpers'
import { CSSClasses } from 'src/app/types'
import { Text } from '@sendoutcards/quantum-design-ui'

// types __________________________________________________
type ComponentProps = {
  isOpen: boolean
  openCloseControlClicked: () => void
  FooterComponent?: JSX.Element
} & Partial<DefaultProps>

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps = {
  edge: 'bottom' as 'top' | 'bottom' | 'left' | 'right',
  title: '' as string,
  backgroundColor: 'white' as ColorChoices,
  offset: 0,
  isFullScreen: false,
}

const getProps = createPropsGetter(defaultProps)

type Props = {
  classes: CSSClasses<typeof styles>
} & ComponentProps
// types __________________________________________________

// styles _________________________________________________
const FOOTER_HEIGHT = 45
const TOP_HEIGHT = 45
const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: (props: Props) => {
      const { edge } = getProps(props)
      if (edge === 'bottom') {
        return 'column-reverse'
      }
      if (edge === 'top') {
        return 'column'
      }
      if (edge === 'right') {
        return 'row-reverse'
      }
      return 'row'
    },
    boxShadow: '0 0px 3px 0 #d4d4d4',
    maxHeight: '100vh',
  },
  offsetDiv: {
    width: (props: Props) => {
      const { edge, offset } = getProps(props)
      if (edge === 'bottom' || edge === 'top') {
        return '100vw'
      }
      return offset
    },
    height: (props: Props) => {
      const { edge, offset } = getProps(props)
      if (edge === 'bottom' || edge === 'top') {
        return offset
      }
      return '100vh'
    },
  },
  drawer: {
    width: (props: Props) => {
      const { edge } = getProps(props)
      if (edge === 'bottom' || edge === 'top') {
        return '100vw'
      }
      return 300
    },
    height: (props: Props) => {
      const { edge } = getProps(props)
      if (edge === 'bottom' || edge === 'top') {
        return '100%'
      }
      return '100vh'
    },
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
  },
  top: {
    padding: '0 0 0 15px',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #e7e7e7',
    height: TOP_HEIGHT,
    alignItems: 'center',
  },
  bottom: {
    borderTop: '1px solid #e7e7e7',
    padding: '10px 0px 10px 0px',
    height: FOOTER_HEIGHT,
  },
  middle: {
    flexGrow: 1,
    height: (props: Props) => {
      const { isFullScreen, FooterComponent, offset } = getProps(props)
      return isFullScreen && FooterComponent && offset
        ? `calc(100vh - ${TOP_HEIGHT}px - ${FOOTER_HEIGHT}px - ${offset}px)`
        : isFullScreen && FooterComponent
        ? `calc(100vh - ${TOP_HEIGHT}px - ${FOOTER_HEIGHT}px)`
        : isFullScreen
        ? `calc(100vh - ${TOP_HEIGHT}px)`
        : '100%'
    },
    overflow: 'auto',
  },
  close: {
    cursor: 'pointer',
    display: 'inline-block',
    marginRight: (props: Props) => {
      const { edge } = getProps(props)
      if (edge === 'bottom' || edge === 'top') {
        return 30
      }
      return 10
    },
  },
}
// styles _________________________________________________

// component ______________________________________________
const Drawer: React.FC<Props> = props => {
  const {
    classes,
    isOpen,
    children,
    openCloseControlClicked,
    FooterComponent,
    title,
  } = getProps(props)
  return (
    <Slide isOpen={isOpen} edge={'bottom'}>
      <div className={classes.wrapper}>
        <div className={classes.offsetDiv} />
        <div className={classes.drawer}>
          <PoseFade isVisible={isOpen}>
            <div className={classes.top}>
              <Text type="subtitle" color="primaryBody">
                {title}
              </Text>
              <div
                className={classes.close}
                onClick={() => openCloseControlClicked()}
              >
                <Icon icon={'CLOSE'} color={'black'} size={15} />
              </div>
            </div>
          </PoseFade>
          <div className={classes.middle}>
            <PoseFade style={{ height: '100%' }} isVisible={isOpen}>
              {children}
            </PoseFade>
          </div>
          {FooterComponent && (
            <div className={classes.bottom}>
              <PoseFade isVisible={isOpen}>{FooterComponent} </PoseFade>
            </div>
          )}
        </div>
      </div>
    </Slide>
  )
}

// export _________________________________________________
export default wrappedInjectSheet<React.FC<ComponentProps>>(styles, Drawer)
