import {
  Div,
  Flex,
  Icon,
  Image,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import React from 'react'
import CardItemOption from 'src/chrome/CardItemOption/CardItemOption'
import Price, { PriceProps } from 'src/chrome/Price/Price'
import { useSelector } from 'src/hooks'
import { QuantityCounter } from '../QuantityCounter/QuantityCounter'
import MobileGiftLineItem from './components/MobileGiftLineItem'
import { Button } from 'src/design_system/molecules/button/Button'
import { Icon as DSIcon } from 'src/design_system/atoms/icons/components/Icon'

export type GiftLineItemCallbacksType = {
  onChangeGift?: () => void
  onRemoveGift?: () => void
  lineIndex: number
}

export type GiftLineItemProps = {
  giftItem?: {
    title?: string
    thumbnailURL: string
    price: PriceProps
    isSendable: boolean
  }
  quantity: number
} & GiftLineItemCallbacksType

const GiftLineItem: React.FC<GiftLineItemProps> = props => {
  const { giftItem, onChangeGift, onRemoveGift, lineIndex, quantity } = props

  const { width } = useSelector(state => state.window)
  const isMobile = width <= 1233

  const isGiftUnavailable = giftItem && !giftItem.isSendable

  return (
    <>
      {!isMobile ? (
        <Flex
          width="100%"
          justifyContent={'space-between'}
          alignItems="center"
          id={`gift_item_${lineIndex}_${giftItem?.title}`}
        >
          <Div display="flex" alignItems="center">
            <Div css={isGiftUnavailable ? { filter: 'grayscale(100%)' } : {}}>
              <Image
                width="128px"
                height="128px"
                image={{ url: giftItem?.thumbnailURL ?? '' }}
                isActive={true}
              />
            </Div>
            <Flex flexWrap="wrap" inset={{ left: 'x2' }}>
              <Text
                type="largeBody"
                weight="bold"
                color="primaryHeading"
                content={`${giftItem?.title}${
                  isGiftUnavailable ? ' (Gift currently unavailable)' : ''
                }`}
                lineHeight={1.5}
              />
              <Spacer orientation="vertical" space="x1" />
              <Button
                id={`change_gift_btn_${lineIndex}`}
                title={{ content: 'Swap Gift', fontColor: '#fff' }}
                padding="14px 20px"
                onClick={onChangeGift}
                fontSize="15px"
                borderRadius="small"
                fill="#ff059f"
              >
                <DSIcon name="replace" size={24} color="#fff" />
              </Button>
            </Flex>
          </Div>
          <Flex alignItems="center">
            <Div minWidth={'82px'}>
              <Price
                price={giftItem?.price.price ?? ''}
                discountPrice={
                  isGiftUnavailable ? '$-.--' : giftItem?.price.discountPrice
                }
                discountMessage={giftItem?.price.discountMessage}
              />
            </Div>
            <Spacer space="x1" orientation="horizontal" />
            {quantity > 1 && <QuantityCounter quantity={quantity} />}
            <Spacer orientation="horizontal" space="x4" />
            <CardItemOption
              width="48px"
              height="48px"
              borderRadius="medium"
              onClick={onRemoveGift}
              disabledMessage="Remove Gift"
            >
              <Icon
                name="close"
                primaryColor="primaryHeadingText"
                size="xSmall"
              />
            </CardItemOption>
          </Flex>
        </Flex>
      ) : (
        <MobileGiftLineItem
          quantity={quantity}
          lineIndex={lineIndex}
          giftItem={giftItem}
          onRemoveGift={onRemoveGift}
          onChangeGift={onChangeGift}
        />
      )}
    </>
  )
}
export default GiftLineItem
