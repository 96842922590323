import React from 'react'

import { Icon } from 'src/chrome'
import { Clipboard, Input, Link } from 'src/styled'

interface ClipboardLinkProps {
  name: string
  value: string
  onCopy?: () => void
}

const ClipboardLink: React.FC<ClipboardLinkProps> = props => {
  const { name, value } = props
  const copyToClipboard = (inputId: string) => {
    const input = document.getElementById(inputId) as HTMLInputElement
    input.select()
    document.execCommand('copy')
    input.blur()

    if (!!props.onCopy) {
      props.onCopy()
    }
  }

  return (
    <Link>
      <Input type="text" id={name} value={value} height="40px" />
      <Clipboard onClick={() => copyToClipboard(name)}>
        <Icon
          icon={'CLIPBOARD'}
          color={'#FFF'}
          viewBox={'-100 -10 1024 1024'}
        />
      </Clipboard>
    </Link>
  )
}

export default ClipboardLink
