import React from 'react'
import { Helmet } from 'react-helmet'
import { FontFragment } from 'src/graphql/generated/graphql'

const getFontList = (fonts: FontFragment[]) =>
  fonts
    .map(font => {
      return `
    @font-face {
      font-family: '${font.fontName}';
      src: url(${font.ttfFile});
    }
    #toolbar .ql-font span[data-label="${font.displayName}"]::before {
      font-family: "${font.fontName}";
    }
  `
    })
    .join('\n')

type Props = {
  fonts: { fonts: FontFragment[]; signatures: FontFragment[] }
}

const FontFace: React.FC<Props> = props => {
  const { fonts } = props
  const localFonts = [...fonts.fonts, ...fonts.signatures]

  return (
    <Helmet>
      <style type="text/css">{getFontList(localFonts)}</style>
    </Helmet>
  )
}

export default FontFace
