import { ColorFragment, isColorFragment } from './ColorFragment'

import textFragmentFragmentRawString from '../TextFragmentFragment.graphql'

export const textFragmentFragmentString = textFragmentFragmentRawString

export type TextFragmentFragment = {
  __typename: 'TextFragment'
  text: string
  textColor: ColorFragment
  font: {
    __typename: 'Font'
    id: string
    fontName: string
  }
  fontSize: number
  isReplacement: boolean
}

export const isTextFragmentFragment = (
  fragment: any,
): fragment is TextFragmentFragment =>
  fragment &&
  fragment.__typename === 'TextFragment' &&
  typeof fragment.text === 'string' &&
  (isColorFragment(fragment.textColor) as boolean) &&
  fragment.font &&
  fragment.font.__typename === 'Font' &&
  typeof fragment.font.id === 'string' &&
  typeof fragment.font.fontName === 'string' &&
  typeof fragment.fontSize === 'number' &&
  typeof fragment.isReplacement === 'boolean'
