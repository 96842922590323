import React from 'react'
// @src imports
import { signatureCharacter } from './ElementText'
import { Paragraph } from './types'
import { rgbString } from '../../redux/selectors/text'
import {
  useCallback,
  useEffect,
  useMutationObservable,
  useRef,
  useState,
} from 'src/hooks'

import { Api } from '../api'
import { Memoized } from 'src/hooks/dependencies'

type TextEditorProps = {
  paragraph: Paragraph
  fontScale: number
  api: Api
  externalChangesDetected: Memoized<(preventTextEdition: boolean) => void>
}

const TextEditorParagraph: React.FC<TextEditorProps> = props => {
  const { paragraph, fontScale, api, externalChangesDetected } = props
  const [previousText, setPreviousText] = useState(
    paragraph.fragments.map(elem => elem.text).join(''),
  )

  const [isChangeExternal, setIsChangeExternal] = useState(false)

  const mutableRef = useRef<HTMLDivElement>()
  const ref = mutableRef.current

  const isApiHandlingTextChanges = api.isApiHandlingTextChanges
  const finishedTextChanges = api.finishedTextChanges

  const onListMutation = useCallback((mutationList: MutationRecord[]) => {
    setPreviousText(
      mutationList[0].target.textContent !== null
        ? mutationList[0].target.textContent
        : '',
    )
  }, [])

  const fontFamily = (fontId: string): string =>
    (
      api.fonts.fonts.find(font => font.id === fontId) ||
      api.fonts.signatures.find(font => font.id === fontId) || {
        fontName: 'HelveticaV2',
      }
    ).fontName

  useMutationObservable(ref, onListMutation)

  useEffect(() => {
    // Changes to the DOM were made by an external plugin / source
    if (
      previousText !== paragraph.fragments.map(elem => elem.text).join('') &&
      !isApiHandlingTextChanges
    ) {
      setIsChangeExternal(true)
    } else {
      setPreviousText(paragraph.fragments.map(elem => elem.text).join(''))
      setIsChangeExternal(false)
      finishedTextChanges()
    }
  }, [
    paragraph,
    previousText,
    externalChangesDetected,
    isApiHandlingTextChanges,
    finishedTextChanges,
  ])

  if (isChangeExternal) {
    // If the DOM was changed externally, lets show a warning for the user for 5 seconds and then revert it to its original content
    externalChangesDetected(false)
    setTimeout(() => {
      setIsChangeExternal(false)
      setPreviousText(paragraph.fragments.map(elem => elem.text).join(''))
      externalChangesDetected(true)
    }, 10)
    return <div />
  }

  return (
    <p
      key={paragraph.index}
      data-index={paragraph.index}
      ref={ref => (mutableRef.current = ref ?? undefined)}
      style={{
        fontFamily: fontFamily(paragraph.fontId),
        fontSize: paragraph.fontSize * fontScale,
        color: rgbString(paragraph.color),
        textAlign: paragraph.textAlign.toLowerCase() as
          | 'left'
          | 'center'
          | 'right',
        lineHeight: 1.4,
        minHeight: '1.4em',
        margin: '0',
      }}
    >
      {paragraph.fragments.map(fragment => (
        <span
          key={fragment.index}
          data-index={fragment.index}
          style={{
            position: 'inherit',
            fontFamily: fontFamily(fragment.signatureFontId || fragment.fontId),
            fontSize: fragment.fontSize * fontScale,
            color: rgbString(fragment.color),
            textDecoration:
              fragment.text && fragment.text.length > 1 ? 'underline' : 'none',
            cursor: 'text',
          }}
        >
          {fragment.text ?? signatureCharacter}
        </span>
      ))}
    </p>
  )
}

export default TextEditorParagraph
