import minimalContactFragmentRawString from '../MinimalContactFragment.graphql'

export const minimalContactFragmentString = minimalContactFragmentRawString

export type MinimalContactFragment = {
  __typename: 'Contact'
  id: string
  firstName: string
  lastName: string
}

export const isMinimalContactFragment = (
  fragment: any,
): fragment is MinimalContactFragment =>
  fragment &&
  fragment.__typename === 'Contact' &&
  typeof fragment.id === 'string' &&
  typeof fragment.firstName === 'string' &&
  typeof fragment.lastName === 'string'
