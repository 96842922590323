import React from 'react'
import _DropzoneComponent, {
  DropzoneComponentConfig,
  DropzoneComponentProps,
} from 'react-dropzone-component'

import styles from '../importContacts.module.scss'

import { exampleCsv } from '../helpers'

const DropzoneComponent = (_DropzoneComponent as unknown) as new () => React.Component<
  DropzoneComponentProps
>

const dropzoneConfig: DropzoneComponentConfig = {
  iconFiletypes: ['.csv'],
  showFiletypeIcon: true,
  postUrl: 'no-url',
}

const djsConfig: Dropzone.DropzoneOptions = {
  autoProcessQueue: false,
  addRemoveLinks: true,
  dictDefaultMessage: 'Click or drag your CSV file here to upload',
}

interface Props {
  onUpload: (file: File) => void
}

const UploadStep: React.FC<Props> = props => {
  const { onUpload } = props

  return (
    <div>
      <div className={styles.importArea}>
        <p style={{ textAlign: 'center', marginBottom: '15px' }}>
          <a href={exampleCsv} download={'headers_for_SOC_Import.csv'}>
            Download a sample file here
          </a>
        </p>
        <div id="file_drop_zone">
          <DropzoneComponent
            config={dropzoneConfig}
            eventHandlers={{
              // changing step immediately and unmounting the component causes an error
              addedfile: file => {
                setTimeout(() => onUpload(file), 100)
              },
            }}
            djsConfig={djsConfig}
            className={styles.customDropzone}
          />
        </div>
      </div>
    </div>
  )
}

export default UploadStep
