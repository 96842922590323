import { ConfirmDialog, Flex, VStack } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { PartialLine } from 'src/helpers/multitouch'
import { useActions, useSelector, useState } from 'src/hooks'

import { OrdersRoute } from 'src/orders/routes/OrdersRoute'
import { ReplaceOrderCardRoute } from 'src/orders/routes/ReplaceOrderCardRoute'
import { Portal } from 'src/portal/portal'
import {
  OrdersSectionPropType,
  RemoveItemType,
} from '../OrdersSection//OrdersSection'
import { OrderPreviewItem } from './OrderPreviewItem'
import { CardFragment } from 'src/graphql/generated/graphql'

const getCurrentlyAddingByRoute = (route: OrdersRoute) => {
  const paths = route.subroute?.path.split('/') ?? []
  const isOnGiftRoute = paths.some(path => {
    return path === 'add-gift-to-card' || path === 'add-gift'
  })
  return isOnGiftRoute ? 'gift' : 'card'
}

export type OrderPreviewSectionProps = Omit<
  OrdersSectionPropType,
  'onChangeCard'
> & {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  onAddCard: (line: PartialLine, index: number) => void
  onReplaceCard: (cardId: string) => void
  editorCard?: CardFragment
}

export const OrderPreviewSection = (props: OrderPreviewSectionProps) => {
  const {
    onRemoveCard,
    giftItem,
    cardItem,
    onRemoveLine,
    onAddCard,
    onReplaceCard,
    editorCard,
  } = props
  const { order, activeLineIndex } = useSelector(state => state.orders)
  const { route } = useSelector(state => state)
  const currentlyAdding = getCurrentlyAddingByRoute(
    route.subroute as OrdersRoute, // TODO resolve type assertion
  )

  const ordersRoute = route.subroute as OrdersRoute

  const replaceRoute =
    ordersRoute.subroute?.path === ReplaceOrderCardRoute.path
      ? ordersRoute.subroute
      : undefined

  const actions = useActions()
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)
  const [isRemoveCardAlertVisible, setIsRemoveCardAlertVisible] = useState(
    false,
  )
  const [removeItemType, setRemoveItemType] = useState<RemoveItemType>(
    undefined,
  )
  const [lineToRemove, setLineToRemove] = useState<PartialLine | null>(null)
  const [lineToRemoveItem, setLineToRemoveItem] = useState<PartialLine | null>(
    null,
  )

  const handleRemoveItemFromLine = (
    removeItemType: RemoveItemType,
    line: PartialLine,
  ) => {
    setRemoveItemType(removeItemType)
    setLineToRemoveItem(line)
    setIsRemoveModalOpen(true)
  }

  const toggleRemoveAlert = (event?: React.MouseEvent, line?: PartialLine) => {
    if (event) {
      event.stopPropagation()
    }

    setIsRemoveCardAlertVisible(!isRemoveCardAlertVisible)
    setLineToRemove(line ?? null)
  }

  const handleRemoveLine = () => {
    if (!order) {
      return
    }

    if (order.lines.length > 1) {
      if (lineToRemove) {
        onRemoveLine(lineToRemove)
      }
    } else {
      actions.clearOrder()
      actions.openCatalog()
    }

    setIsRemoveCardAlertVisible(false)
    setLineToRemove(null)
  }

  const isCreatingNewItem =
    !replaceRoute?.cardId && activeLineIndex === undefined
  const newCardItemCreation = order
    ? {
        lineIndex: order.lines.length,
        isActive: true,
        title: `Card ${order.lines.length + 1}`,
        giftItem: {
          lineIndex: order.lines.length + 1,
        },
        cardItem: {},
        isAddingNewItem: true,
        shouldNotAllowRemoval: order.lines.length < 2,
      }
    : {
        lineIndex: 0,
        isActive: true,
        title: 'Card 1',
        giftItem: {
          lineIndex: 1,
        },
        cardItem: {},
        isAddingNewItem: true,
        shouldNotAllowRemoval: true,
      }

  const handleOnChangeCard = (card: CardFragment | null | undefined) => {
    if (card) cardItem.onEditCard?.(card)
  }
  return (
    <Flex
      backgroundColor="background"
      borderRadius="large"
      flexDirection="column"
      width="100%"
      inset="x1"
    >
      <VStack gap="x2">
        {order &&
          order.lines.map((line, index) => {
            const incrementedIndex = index + 1

            return (
              <OrderPreviewItem
                onAddCard={() => onAddCard(line, index)}
                onChangeCard={() => handleOnChangeCard(line.card)}
                onRemoveCard={() => handleRemoveItemFromLine('card', line)}
                key={index}
                lineIndex={index}
                isActive={index === activeLineIndex}
                orderLine={line}
                title={`Card ${incrementedIndex}`}
                onRemoveLineAlert={e => toggleRemoveAlert(e, line)}
                giftItem={{
                  lineIndex: index,
                  onChangeGift: () => giftItem.onChangeGift(line, index),
                  onRemoveGift: () => handleRemoveItemFromLine('gift', line),
                }}
                cardItem={{
                  onEditCard: () => handleOnChangeCard(line.card),
                  onRemoveCard: () => handleRemoveItemFromLine('card', line),
                  onReplaceCard: onReplaceCard,
                }}
                onAddGift={() => giftItem.onAddGift(line, index)}
                isCurrentlyAdding={currentlyAdding}
                shouldNotAllowRemoval={order.lines.length < 2}
                replaceCardId={replaceRoute?.cardId}
                orderId={order.id}
                editorCard={editorCard}
              />
            )
          })}
        {isCreatingNewItem && (
          <OrderPreviewItem
            {...newCardItemCreation}
            isCurrentlyAdding={currentlyAdding}
            replaceCardId={replaceRoute?.cardId}
            editorCard={editorCard}
          />
        )}
      </VStack>
      {isRemoveModalOpen && lineToRemoveItem && (
        <Portal>
          <ConfirmDialog
            zIndex={10000}
            isOpen={isRemoveModalOpen}
            title={
              removeItemType === 'card'
                ? 'Replace current card?'
                : 'Remove gift from order?'
            }
            description={
              removeItemType === 'card'
                ? 'Are you sure you want to replace this card with another card in the catalog?'
                : 'Are you sure you want to remove this gift from your order?'
            }
            accept={{
              id: 'confirm_remove_item_from_order_preview',
              title: 'Yes',
              onClick: () => {
                if (removeItemType === 'card') onRemoveCard(lineToRemoveItem)
                else if (removeItemType === 'gift')
                  giftItem.onRemoveGift(lineToRemoveItem)

                setIsRemoveModalOpen(false)
                setRemoveItemType(undefined)
              },
            }}
            decline={{
              title: 'No',
              onClick: () => {
                setIsRemoveModalOpen(false)
              },
            }}
          />
        </Portal>
      )}
      {isRemoveCardAlertVisible && lineToRemove && (
        <Portal>
          <ConfirmDialog
            zIndex={10000}
            title={'Remove card from order?'}
            description={
              'Are you sure you want to remove this card from your order?'
            }
            isOpen={isRemoveCardAlertVisible}
            accept={{
              id: 'confirm-remove-item',
              title: 'Yes',
              onClick: () => {
                handleRemoveLine()
                toggleRemoveAlert()
              },
            }}
            decline={{
              title: 'No',
              onClick: () => toggleRemoveAlert(),
            }}
          />
        </Portal>
      )}
    </Flex>
  )
}
