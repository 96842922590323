import { AddressFragment } from 'src/legacy_graphql'
import deepEqual from 'deep-equal'

const areAddressesEqual = (
  addressOne: AddressFragment,
  addressTwo: AddressFragment,
) => {
  return deepEqual(addressOne, addressTwo)
}

export default areAddressesEqual
