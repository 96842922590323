import { Button, Flex, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import { Token } from '@stripe/stripe-js'
import React from 'react'
import { useSelector, useState } from 'src/hooks'
import useSubmitCard from 'src/hooks/useSubmitCard'
import CreditCardForm, {
  CCAddress,
} from 'src/payments/components/CreditCardForm/CreditCardForm'

type PaymentMethodFormProps = {
  title: string
  onDismissModal: () => void
}

const styles = {
  bg: {
    background:
      'linear-gradient(34deg, rgba(228,228,228,0.95) 0%, rgba(213,220,223,0.95) 45%, rgba(243,251,253,0.95) 100%)',
  },
}

export const PaymentMethodForm: React.FC<PaymentMethodFormProps> = ({
  title,
  onDismissModal,
}) => {
  const isMobile = useSelector(state => state.window.width <= 520)
  const submitCard = useSubmitCard()
  const [isFormComplete, setIsFormComplete] = useState(false)

  const onSubmitCardSuccess = (token: Token, address: CCAddress) => {
    submitCard.submitCard(token, address, undefined, onDismissModal)
  }
  return (
    <Flex
      justifyContent="start"
      height="initial"
      alignItems="center"
      width="100%"
      flexDirection="column"
      overflow="auto"
    >
      <Flex
        backgroundColor="rgba(230, 230, 230, 0.95)"
        position="absolute"
        style={{ backdropFilter: 'blur(1px)' }}
        top="x0"
        left="x0"
        width="100%"
        height="100%"
        borderRadius="12px"
        css={styles.bg}
      />
      <Flex
        zIndex={5}
        top="0"
        left="0"
        right="0"
        bottom="0"
        inset="x2"
        flexDirection="column"
        width="100%"
      >
        <Text type="subtitle" content={title} outset={{ bottom: 'x2' }} />
        <CreditCardForm
          onSuccess={onSubmitCardSuccess}
          onError={submitCard.onError}
          isCCFormComplete={isComplete => {
            setIsFormComplete(isComplete)
          }}
          isLoading={submitCard.isAddingCard}
          overrideChildContCSS="span 3"
        >
          <Button
            title="Cancel"
            backgroundColorOverride="#404040"
            onClick={onDismissModal}
            outlined={true}
          />
          <Spacer space="x1" orientation="horizontal" />
          <Button
            title="Add Card"
            backgroundColorOverride="#404040"
            disabled={!isFormComplete}
            onClick="submitForm"
          />
        </CreditCardForm>
        {isMobile && <Spacer space="x3" />}
        {submitCard.addCCardError !== undefined && (
          <Text type="caption" color="danger">
            {submitCard.addCCardError}
          </Text>
        )}
      </Flex>
    </Flex>
  )
}
