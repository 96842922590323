import React from 'react'
import startCase from 'lodash/startCase'
import lowerCase from 'lodash/lowerCase'
import Checkbox from '@material-ui/core/Checkbox'

import { Set } from 'immutable'
// @src imports
import {
  Address,
  Icon,
  RwdCell,
  RwdHeader,
  RwdRow,
  RwdTable,
  Tooltip,
} from 'src/chrome'
import {
  CardStatus,
  ProductionInfoFragment,
  RecipientFragment,
} from 'src/graphql/generated/graphql'
import { ProductionRecipient } from 'src/card_history/types'

import styles from './historyTable.module.scss'
import { Anchor, Div, Flex, Text } from '@sendoutcards/quantum-design-ui'

interface ProductionInfoTableProps {
  history: ProductionInfoFragment[]
  hasSelectableCards: boolean
  selectName: 'Deselect All' | 'Select All'
  isMobile: boolean
  checkedItems: Set<string>
  onUpdateCheck: (id: string) => void
  onCardClicked: (recipient: ProductionRecipient) => void
  onSelectAllClick: () => void
  editRecipientAddressClicked: (recipient: RecipientFragment) => void
  onEditSendDate: (info: ProductionInfoFragment) => void
}

const ProductionInfoTable: React.FC<ProductionInfoTableProps> = props => {
  const {
    history,
    hasSelectableCards,
    selectName,
    checkedItems,
    isMobile,
    onUpdateCheck,
    onCardClicked,
    onSelectAllClick,
    editRecipientAddressClicked,
    onEditSendDate,
  } = props

  const shouldShowBulkColumn = history.some(
    item => item.recipient && item.recipient.cardQuantity > 1,
  )

  return (
    <div>
      {history.length < 1 ? (
        <Text
          type="subtitle"
          alignment="center"
          outset={{ top: 'x7' }}
          color="primaryHeading"
        >
          {`You have no cards here right now`}
        </Text>
      ) : (
        <RwdTable>
          <RwdHeader>
            {hasSelectableCards && (
              <th
                id="select_all_btn"
                onClick={() => onSelectAllClick()}
                className={styles.selectAllHeader}
              >
                <Text type="body" color="primaryBrand">
                  {isMobile ? 'Select' : selectName}
                </Text>
              </th>
            )}
            <th>
              <Text type="body" color="primaryBrand">
                Send Date
              </Text>
            </th>
            <th>
              <Text type="body" color="primaryBrand">
                Recipient
              </Text>
            </th>
            <th>
              <Text type="body" color="primaryBrand">
                Production Id
              </Text>
            </th>
            <th>
              <Text type="body" color="primaryBrand">
                Status
              </Text>
            </th>
            <th>
              <Text type="body" color="primaryBrand">
                Card
              </Text>
            </th>
            {shouldShowBulkColumn && (
              <th>
                <Text type="body" color="primaryBrand">
                  Bulk
                </Text>
              </th>
            )}
          </RwdHeader>
          {history.map((item: ProductionInfoFragment, index: number) => {
            const {
              id,
              dateToSend,
              status,
              rejectionReason,
              frontPreviewUrl,
              trackingInfo,
              recipient,
            } = item
            const isEditable =
              status === CardStatus.Pending || status === CardStatus.Held
            const isBulk = recipient && recipient.cardQuantity > 1
            return (
              <tbody key={index}>
                {recipient && (
                  <RwdRow>
                    {hasSelectableCards && (
                      <RwdCell
                        style={{ width: 90 }}
                        mapToHeader={isMobile ? 'Select' : selectName}
                      >
                        {isEditable ? (
                          <Checkbox
                            checked={checkedItems.contains(id)}
                            onChange={() => onUpdateCheck(id)}
                          />
                        ) : (
                          <div />
                        )}
                      </RwdCell>
                    )}
                    <RwdCell mapToHeader={'Send Date'}>
                      <Text type="body" color="primaryHeading">
                        {isEditable && !isBulk ? (
                          <>
                            {dateToSend}
                            <Icon
                              icon={'EDIT'}
                              size={20}
                              onClick={() => onEditSendDate(item)}
                              style={{
                                position: 'relative',
                                right: -8,
                                top: -2,
                              }}
                            />
                          </>
                        ) : (
                          dateToSend
                        )}
                      </Text>
                    </RwdCell>
                    <RwdCell mapToHeader={'Recipient'}>
                      <div
                        className={`${styles.addressBlockWrapper} ${
                          isEditable && !isBulk
                            ? styles.cursorPointer
                            : styles.cursorDefault
                        }`}
                        onClick={() =>
                          isEditable && !isBulk
                            ? editRecipientAddressClicked(recipient)
                            : () => {}
                        }
                      >
                        {recipient.address && (
                          <Address address={recipient.address} />
                        )}

                        {isEditable && !isBulk && (
                          <Icon
                            icon={'EDIT'}
                            size={20}
                            onClick={() =>
                              editRecipientAddressClicked(recipient)
                            }
                            style={{ position: 'relative', right: -8 }}
                          />
                        )}
                      </div>
                    </RwdCell>
                    <RwdCell mapToHeader={'Production Id'}>
                      <Text type="body" color="primaryHeading">
                        {id}
                      </Text>
                    </RwdCell>
                    <RwdCell mapToHeader={'Status'}>
                      <div style={{ display: 'flex' }}>
                        <div style={{ marginRight: 8 }}>
                          {trackingInfo?.url ? (
                            <div>
                              {status === CardStatus.Rejected
                                ? 'Rejected '
                                : ''}
                              <Anchor
                                href={trackingInfo.url}
                                target="_blank"
                                isDecorated={true}
                              >
                                View Tracking
                              </Anchor>
                            </div>
                          ) : (
                            <div style={{ maxWidth: 300 }}>
                              <Text type="body" color="primaryHeading">
                                {startCase(
                                  lowerCase(
                                    status === CardStatus.AwaitingFulfillment
                                      ? 'Printing'
                                      : status,
                                  ),
                                )}
                              </Text>
                              {recipient.address?.errorMessages &&
                              recipient.address?.errorMessages.length > 0 &&
                              status !== CardStatus.Fulfilled ? (
                                <Text type="caption">
                                  {'Address Error: ' +
                                    recipient.address.errorMessages.join(' ')}
                                </Text>
                              ) : null}
                            </div>
                          )}
                        </div>
                        {rejectionReason && (
                          <Tooltip
                            hoverIcon={'INFO'}
                            message={rejectionReason || 'Card was rejected'}
                          />
                        )}
                      </div>
                    </RwdCell>
                    <RwdCell mapToHeader={'Card'}>
                      <div
                        onClick={() =>
                          onCardClicked({
                            productionId: id,
                            ...recipient,
                            status: status,
                          })
                        }
                        className={`${styles.cardActions}`}
                      >
                        <div className={`${styles.cardPreview}`}>
                          <img
                            style={{ height: '150px' }}
                            id={`resend_card_btn_${index}`}
                            src={frontPreviewUrl}
                            alt={'card preview'}
                          />
                          {recipient.gift && (
                            <div
                              className={styles.giftPreview}
                              style={{
                                backgroundImage: `url(${recipient.gift.defaultImageUrl})`,
                              }}
                            />
                          )}
                        </div>
                        {recipient.card?.sendableCard && (
                          <Div maxWidth="125px">
                            <Text
                              type="footnote"
                              alignment="center"
                              color="primaryHeading"
                            >
                              {recipient.card.sendableCard.title}
                            </Text>
                          </Div>
                        )}
                      </div>
                    </RwdCell>
                    {shouldShowBulkColumn && (
                      <RwdCell mapToHeader={'Bulk'}>
                        {recipient.cardQuantity > 1 && (
                          <Flex
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Icon icon="CHECKCIRCLE" />
                            <Text type="body" color="primaryHeading">
                              {recipient.cardQuantity} cards
                            </Text>
                          </Flex>
                        )}
                      </RwdCell>
                    )}
                  </RwdRow>
                )}
                <tr className={styles.tableSpacer} />
              </tbody>
            )
          })}
        </RwdTable>
      )}
    </div>
  )
}

export default ProductionInfoTable
