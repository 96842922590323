import { setLogrocketUser } from 'src/helpers/appHelpers'
import { SALEOR_ACCESS_TOKEN_KEY } from 'src/saleor/utils/auth'
import { loadedUser, loginDone, logout, openRoute } from '../actions'
import { Middleware } from '../store'
import { getQueryClient } from 'src'

export const userMiddleware: Middleware = api => nextDispatch => action => {
  switch (action.type) {
    case openRoute.type:
      const persistedAccount = localStorage.getItem('persist:account')
      if (!persistedAccount) {
        break
      }
      const state = JSON.parse(persistedAccount)
      if (
        action.args.access_token &&
        action.args.access_token !== state.controlData.token
      ) {
        try {
          sessionStorage.removeItem('notifications')
          localStorage.removeItem(SALEOR_ACCESS_TOKEN_KEY)
          getQueryClient().clear()
        } catch {}
      }
      break
    case logout.type:
      try {
        sessionStorage.removeItem('notifications')
        localStorage.removeItem(SALEOR_ACCESS_TOKEN_KEY)
        getQueryClient().clear()
      } catch {}
      break
    case loadedUser.type:
      const id = action.account.value?.id ?? null
      setLogrocketUser(action.account)
      if (id && window.Appcues) {
        window.Appcues.identify(id)
      }
      break
    case loginDone.type:
      try {
        sessionStorage.clear()
        localStorage.removeItem(SALEOR_ACCESS_TOKEN_KEY)
        getQueryClient().clear()
      } catch {}
      setLogrocketUser(action.result)
      break
  }

  return nextDispatch(action)
}
