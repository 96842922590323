import { Div, Flex, Icon, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { useState } from 'src/hooks'

interface Props {
  copyText: string
}

export const Clipboard: React.FC<Props> = props => {
  const { copyText } = props

  const [isCopied, setIsCopied] = useState(false)

  const styles = {
    input: {
      backgroundColor: 'transparent',
      border: 'none',
      fontSize: '1rem',
      fontWeight: 700,
      color: '#000',
      padding: '0 0 0 1.5rem',
      margin: '0',
      fontFamily: 'Montserrat, sans-serif',
      maxWidth: '8.5rem',
    },
  }

  const copyTextToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text)
    } else {
      return document.execCommand('copy', true, text)
    }
  }

  const handleCopy = () => {
    copyTextToClipboard(copyText)
      // eslint-disable-next-line
      .then(() => {
        setIsCopied(true)
        setTimeout(() => {
          setIsCopied(false)
        }, 4000)
      })
      // eslint-disable-next-line
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <Div
      display="flex"
      backgroundColor="#daf6ff"
      inset={{ vertical: 'x_75', right: 'x_75' }}
      borderRadius="large"
    >
      <input
        type="text"
        value={copyText}
        onChange={() => {}}
        style={styles.input}
      />
      <Flex
        onClick={handleCopy}
        backgroundColor="foreground"
        borderRadius="medium"
        inset={{ vertical: 'x_25', horizontal: isCopied ? 'x_75' : 'x1_5' }}
        cursor="pointer"
        alignItems="center"
        justifyContent="center"
      >
        <Text
          type="footnote"
          content={isCopied ? 'COPIED' : 'COPY'}
          color="primaryHeading"
          weight="extraBold"
          style={{ fontSize: '.563rem' }}
        />
        <Spacer orientation="horizontal" space="x_5" />
        <Icon name="copy" size="xSmall" primaryColor="primaryHeadingText" />
      </Flex>
    </Div>
  )
}
