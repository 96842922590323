import {
  Maybe,
  ItemObject,
  Operation,
} from '../../../__generated__/globalTypes'

import {
  StripeOrderFragment,
  stripeOrderFragmentString,
} from '../../fragments/__generated__/StripeOrderFragment'

import createStripeOrderRawString from '../CreateStripeOrder.graphql'

const createStripeOrderString = [
  createStripeOrderRawString,
  stripeOrderFragmentString,
].join('\n\n')

export type CreateStripeOrderData = {
  createStripeOrder: {
    stripeOrder: StripeOrderFragment
  }
}

export type CreateStripeOrderVariables = {
  coupon?: Maybe<string>
  itemsToPurchase: ItemObject[]
}

export type CreateStripeOrder = Operation<
  CreateStripeOrderData,
  CreateStripeOrderVariables
>

export const CreateStripeOrder = ({
  coupon,
  itemsToPurchase,
}: CreateStripeOrderVariables): CreateStripeOrder => ({
  query: createStripeOrderString,
  variables: {
    coupon,
    itemsToPurchase: itemsToPurchase.map(x => ItemObject(x)),
  },
})
