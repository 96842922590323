import React, { FC } from 'react'
import { SVGProps } from './types'

export const TimeBlock: FC<SVGProps> = ({ size, color, onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 18 18"
      width={size}
      height={size}
      onClick={onClick}
    >
      <path
        fill={color}
        d="M14.64 1.993c-.073-.38-.132-.694-.195-1.008-.094-.473.14-.881.554-.967.453-.093.83.183.926.677.23 1.177.461 2.353.694 3.53.14.708-.29 1.136-.992.99-1.19-.245-2.38-.487-3.57-.73-.482-.098-.737-.344-.753-.735-.02-.479.368-.85.847-.774.526.083 1.045.204 1.568.308.03.007.06.012.153.03-.101-.088-.156-.137-.213-.184-2.301-1.887-5.588-2.152-8.276-.639-2.074 1.167-3.405 2.902-3.78 5.275-.535 3.385 1.208 7.713 5.954 8.582 2.657.486 5.014-.195 6.928-2.155 1.086-1.113 1.666-2.49 1.908-4.014.068-.425.082-.858.14-1.285.048-.35.345-.6.697-.623.318-.02.655.19.739.508.048.183.04.388.027.58-.107 1.596-.506 3.11-1.35 4.48-1.32 2.14-3.241 3.441-5.687 3.944-2.603.534-5.044.09-7.24-1.432C1.778 15.006.681 13.1.202 10.814-.935 5.367 2.87.947 7.577.151c2.53-.428 4.823.144 6.868 1.698.043.033.086.064.194.143z"
      />
      <path
        fill={color}
        d="M8.253 8.267v-.572c0-1.044-.002-2.087.002-3.13 0-.462.32-.793.75-.793.432-.001.754.328.755.79.003 1.474.003 2.95 0 4.424-.001.477-.305.785-.778.787-.98.005-1.961.004-2.942 0-.447-.001-.778-.315-.787-.735-.009-.418.33-.76.786-.768.643-.01 1.287-.002 1.93-.002l.284-.001z"
      />
    </svg>
  )
}
