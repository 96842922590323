import React from 'react'

import { ClipboardLink } from 'src/chrome'
import { Description, FlexColumn } from 'src/styled'

interface AffiliateLinksProps {
  onCopy: () => void
  slug: string
  baseUrl: string
  isSoc: boolean
}

const AffiliateLinks: React.FC<AffiliateLinksProps> = props => {
  const { slug, onCopy, baseUrl, isSoc } = props
  const link = `${baseUrl}${slug}`
  return (
    <div style={{ marginTop: 15 }}>
      <FlexColumn
        style={{
          marginTop: 15,
        }}
      >
        <Description>Directs user to home page</Description>
        <ClipboardLink name={'home'} value={`${link}`} onCopy={onCopy} />
        {isSoc && (
          <>
            <Description>Directs user to send a card</Description>
            <ClipboardLink
              name={'send'}
              value={`${link}/send`}
              onCopy={onCopy}
            />
            <Description>Directs user to business page</Description>
            <ClipboardLink
              name={'business'}
              value={`${link}/business`}
              onCopy={onCopy}
            />
            <Description>Directs user to pricing page</Description>
            <ClipboardLink
              name={'pricing'}
              value={`${link}/pricing`}
              onCopy={onCopy}
            />
          </>
        )}
      </FlexColumn>
    </div>
  )
}

export default AffiliateLinks
