import { Operation } from '../../../__generated__/globalTypes'

import {
  MarketingParagraphFragment,
  marketingParagraphFragmentString,
} from '../../fragments/__generated__/MarketingParagraphFragment'

import getAffiliateBonusInfoRawString from '../GetAffiliateBonusInfo.graphql'

const getAffiliateBonusInfoString = [
  getAffiliateBonusInfoRawString,
  marketingParagraphFragmentString,
].join('\n\n')

export type GetAffiliateBonusInfoData = {
  singleBonusInfo: MarketingParagraphFragment
  doubleBonusInfo: MarketingParagraphFragment
  pqvInformation: MarketingParagraphFragment
}

export type GetAffiliateBonusInfo = Operation<GetAffiliateBonusInfoData>

export const GetAffiliateBonusInfo = (): GetAffiliateBonusInfo => ({
  query: getAffiliateBonusInfoString,
  variables: undefined,
})
