export const categoryBackground = '#f1f1f1'
const categoryColor = '#717882'
export const boxShadow = '0px 8px 14px 0px rgba(216, 221, 230, 0.5)'
export const cardBoxShadow = '0 1px 6px rgba(0, 0, 0, 0.16)'

const cellMargin = '0 3px'
const featureMinWidth = 184

export const qdsColors = {
  primary: {
    gray: 'primaryBody',
    green: 'success',
    orange: 'danger',
    purple: 'primaryBrand',
    red: '#d67b78',
  },
  gradient: {
    gray: 'linear-gradient(109deg, #87B5C0, #7B9294)',
    green: 'linear-gradient(109deg, #56DCDE, #61E2AD)',
    orange: 'linear-gradient(109deg, #EB8498, #ECAB84)',
    purple: 'linear-gradient(109deg, #ff5689, #BE74DF)',
    red: 'linear-gradient(109deg, #E094C0, #D5776C)',
  },
  white: 'accent',
  active: 'success',
}

export const colors = {
  primary: {
    gray: 'rgba(113, 120, 130, 0.72)',
    green: '#5de0c0',
    orange: '#eb8498',
    purple: '#BE74DF',
    red: '#d67b78',
  },
  secondary: {
    gray: 'rgba(113, 120, 130, 0.72)',
    green: '#5de0c0',
    orange: '#ECAB84',
    purple: '#BE74DF',
    red: '#D4766A',
  },
  tertiary: {
    gray: 'rgba(113, 120, 130, 0.72)',
    green: '#61E2AD',
    orange: '#EB8498',
    purple: '#BE74DF',
    red: '#DF92BA',
  },
  gradient: {
    gray: 'linear-gradient(109deg, #87B5C0, #7B9294)',
    green: 'linear-gradient(109deg, #56DCDE, #61E2AD)',
    orange: 'linear-gradient(109deg, #EB8498, #ECAB84)',
    purple: 'linear-gradient(109deg, #ff5689, #BE74DF)',
    red: 'linear-gradient(109deg, #E094C0, #D5776C)',
  },
  white: '#fff',
  active: 'rgb(72,125,76)',
}

export const iconColors = {
  white: '#fff',
  green: '#29cd83',
  gray: '#717882',
}

export const flexCenter: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const flexColumn: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}

export const style = {
  planSpacer: {
    padding: '0 40px',
    minWidth: featureMinWidth,
    flex: '2 2 30%',
  },
  planHeader: {
    position: 'relative',
    margin: '22px 3px 7.5px',
    marginBottom: 0,
    padding: '15px 25px',
    borderRadius: '9px',
    boxShadow: '0 1px 6px rgba(0, 0, 0, 0.16)',
    flex: '1 1 20%',
    paddingBottom: 85,
  } as React.CSSProperties,
  chip: {
    position: 'absolute',
    top: '-45px',
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '3px 10px',
    width: 'fit-content',
    boxShadow: cardBoxShadow,
    borderRadius: 6,
    backgroundColor: colors.white,
  } as React.CSSProperties,
  chipLabel: {
    fontWeight: 500,
    color: 'rgb(60, 197, 130)',
    textAlign: 'center',
  } as React.CSSProperties,
  selectButton: {
    position: 'absolute',
    margin: '15px 0',
    bottom: 12,
  } as React.CSSProperties,
  buttonTitle: {
    color: 'black',
    fontWeight: 600,
  },
  titleBlock: {
    ...flexColumn,
    paddingBottom: 10,
  },
  planTitle: {
    fontWeight: 500,
    fontSize: 25,
  },
  planSubtitle: {
    fontWeight: 200,
    textAlign: 'center',
    lineHeight: '19px',
    fontSize: '1.37rem',
  } as React.CSSProperties,
  amountBlock: {
    ...flexColumn,
  },
  dollarAmount: {
    fontSize: 65,
    fontWeight: 600,
  },
  centsAmount: {
    marginTop: 'auto',
    marginBottom: 10,
    fontSize: 24,
    fontWeight: 600,
  },
  categoryHeader: {
    background: categoryBackground,
    color: categoryColor,
    padding: 15,
  },
  mobileCategoryHeader: {
    background: categoryBackground,
    color: categoryColor,
    boxShadow: cardBoxShadow,
    margin: cellMargin,
    padding: 15,
  },
  pricingCell: {
    ...flexCenter,
    padding: 10,
    margin: cellMargin,
    flex: '1 1 20%',
  },
  mobileActionBar: {
    ...flexCenter,
    background: 'transparent',
    margin: cellMargin,
    padding: '8px 15px',
    borderRadius: '0 0 4px 4px',
  },
}

export const feature: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: colors.white,
  padding: 10,
  margin: cellMargin,
  flex: '2 2 30%',
  minWidth: featureMinWidth,
}
