import { Operation } from '../../../__generated__/globalTypes'

import {
  PlanDescriptionFragment,
  planDescriptionFragmentString,
} from '../../fragments/__generated__/PlanDescriptionFragment'

import getPlanAddonsDescriptionsRawString from '../GetPlanAddonsDescriptions.graphql'

const getPlanAddonsDescriptionsString = [
  getPlanAddonsDescriptionsRawString,
  planDescriptionFragmentString,
].join('\n\n')

export type GetPlanAddonsDescriptionsData = {
  planAddonsDescriptions: PlanDescriptionFragment[]
}

export type GetPlanAddonsDescriptions = Operation<GetPlanAddonsDescriptionsData>

export const GetPlanAddonsDescriptions = (): GetPlanAddonsDescriptions => ({
  query: getPlanAddonsDescriptionsString,
  variables: undefined,
})
