import React from 'react'
import { Flex, Icon, Spacer, Text } from '@sendoutcards/quantum-design-ui'

const SearchForSponsorPlaceholder = () => {
  return (
    <>
      <Spacer space="x2" />
      <Flex
        outset={{ horizontal: 'auto' }}
        justifyContent="center"
        alignItems="center"
        inset="x4"
        flexDirection="column"
      >
        <Icon name={'search'} size="medium" primaryColor="default" />
        <Spacer space="x3" />
        <Text
          type="caption"
          color="primaryBody"
          outset={{ horizontal: 'auto' }}
          alignment={'center'}
        >
          Type in their first name, last name, etc. to search.
        </Text>
      </Flex>
    </>
  )
}

export default SearchForSponsorPlaceholder
