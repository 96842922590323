import React from 'react'
import { Button, Text } from '@sendoutcards/quantum-design-ui'
import { FlexColumn, FlexRow } from 'src/styled'
import { useSelector, useState } from 'src/hooks'
import FindMySponsor from './FindMySponsor'
import { StepWrapperProps } from './ProgressiveProfileActions'

type OrphanStepProps = StepWrapperProps & {
  onComplete: (sponsorId?: string) => void
}

const OrphanStep = (props: OrphanStepProps) => {
  const { onComplete } = props
  const { isMobile } = useSelector(s => s.window)

  const [isAddSponsorOpen, setIsAddSponsorOpen] = useState(false)

  const onNow = () => {
    setIsAddSponsorOpen(true)
  }

  const onSelectedSponsor = (sponsorId?: string) => {
    onComplete(sponsorId)
  }

  if (isAddSponsorOpen) {
    return (
      <FlexColumn
        style={{
          justifyContent: 'center',
          marginBottom: isMobile ? '65px' : undefined,
          padding: '0 8px',
        }}
      >
        <FindMySponsor
          onSelectedSponsor={onSelectedSponsor}
          isSponsorRequired={true}
        />
      </FlexColumn>
    )
  }

  return (
    <FlexColumn
      id="orphan_step_dialog"
      style={{
        justifyContent: 'center',
        marginBottom: isMobile ? '65px' : undefined,
      }}
    >
      <Text type="subtitle">Who showed you this product?</Text>
      <Text type="body">
        We celebrate and give back to the people who invite others to experience
        our product.
        <br />
        Please take a moment and tell us who showed you this product?
      </Text>
      <FlexRow
        style={{
          marginTop: '12px',
          marginBottom: '12px',
        }}
      >
        <div style={{ width: '30%' }}>
          <Button
            id="fix_it_now_btn"
            type="primary"
            title="Fix it now"
            onClick={onNow}
            fullWidth={true}
          />
        </div>
      </FlexRow>
    </FlexColumn>
  )
}

export default OrphanStep
