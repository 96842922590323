import React from 'react'
import LazyLoad from 'react-lazy-load'

import styles from './giftTile.module.scss'
import { GiftFragment } from 'src/legacy_graphql'
import { Flex, Text } from '@sendoutcards/quantum-design-ui'
import Price from 'src/chrome/Price/Price'
import useAccountHasGiftSends from 'src/hooks/useAccountHasGiftSends'

interface GiftTileProps {
  addGift: () => void
  id: string
  item: GiftFragment
  viewGift: () => void
  isChangingGift: boolean
}

const GiftTile: React.FC<GiftTileProps> = props => {
  const { item, addGift, viewGift, id, isChangingGift } = props
  const { hasAvailableGiftSends } = useAccountHasGiftSends()
  const title = item.name
  const titleLength = 35
  const displayTitle =
    title.length > titleLength
      ? title.substring(0, titleLength - 3) + '...'
      : title
  const isEligibleFree =
    hasAvailableGiftSends && item.defaultVariation?.isEligibleFreeGift

  return (
    <div id={id} className={styles.itemContainer}>
      <>
        <div className={styles.giftImageContainer}>
          <LazyLoad height={320} offset={300}>
            <div
              className={styles.giftImage}
              onClick={() => viewGift()}
              style={{
                backgroundImage: `url(${item.defaultImageUrl})`,
              }}
            />
          </LazyLoad>
        </div>
        <div className={styles.buttonContainer}>
          <div className={styles.viewButton} onClick={() => viewGift()}>
            <Text type="caption" color="accent">
              VIEW DETAILS
            </Text>
          </div>
          <div
            id={`${title.toLowerCase().split(' ').join('_')}_btn`}
            className={styles.addButton}
            onClick={() => addGift()}
          >
            <Text type="caption" color="primaryBrand">
              {isChangingGift ? 'CHANGE GIFT' : 'ADD GIFT'}
            </Text>
          </div>
        </div>
        <div className={styles.itemDetails}>
          <Text type="largeBody" inset="x0">
            {displayTitle}
          </Text>
          <div className={styles.priceContainer}>
            <Text type="caption">PRICE:</Text>
            <Flex>
              <Price
                price={
                  item.defaultVariation
                    ? item.defaultVariation?.price.asString
                    : ''
                }
                discountPrice={isEligibleFree ? 'Free' : undefined}
                discountMessage={
                  isEligibleFree
                    ? 'FREE as part of your Heartfelt Plus Gifts subscription. Send up to 5 monthly!'
                    : undefined
                }
                direction="row"
              />
            </Flex>
          </div>
        </div>
      </>
    </div>
  )
}

export default GiftTile
