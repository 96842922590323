import getLocalStorage from './getLocalStorage'

const localStorageKey = 'country-code'

const getCountryCodeFromBrowserLanguage = () => {
  // @ts-ignore: Intl.Locale was only added to typescript definitions on typescript 4.5
  const locale = new Intl.Locale(navigator.language)
  const region = locale.region
  return region?.length === 2 ? region : null
}

const getCountryCodeFromIp = async (): Promise<string> => {
  const response = await fetch('https://ipapi.co/json/')
  const jsonResponse = await response.json()
  return jsonResponse.country_code
}

const getCountryCodeFromLocalStorage = (): string | null => {
  const storage = getLocalStorage()
  if (storage) {
    const countryCodeJson = storage.getItem(localStorageKey)
    if (countryCodeJson) {
      const { countryCode, timestamp } = JSON.parse(countryCodeJson)
      // If cached data has more is older than a month, remove it
      if (timestamp < Date.now() - 30 * 86400 * 1000) {
        storage.removeItem(localStorageKey)
        return null
      }
      return countryCode
    }
  }
  return null
}

const setCountryCodeInLocalStorage = (countryCode: string) => {
  const storage = getLocalStorage()
  if (storage) {
    const countryCodeObject = {
      countryCode: countryCode,
      timestamp: Date.now(),
    }
    storage.setItem(localStorageKey, JSON.stringify(countryCodeObject))
  }
}

/**
 * Generator that returns the machine country code, in order of least precise to most precise.
 * - If the country code is cached, yields it and immediatly returns.
 * - Immediatly yields country code from browser language.
 * - Then fetches the code from a third-party API, caches and yields it.
 * If any step fails, it will just be skipped.
 */
async function* getMachineCountryCode() {
  // Checks to see if there's already a country code cached in local storage
  try {
    const countryCode = getCountryCodeFromLocalStorage()
    if (countryCode) {
      yield countryCode
      return
    }
  } catch (error) {
    console.error('Error getting user country code from local storage:', error)
  }
  // Gets country code from browser language
  try {
    const countryCode = getCountryCodeFromBrowserLanguage()
    if (countryCode) {
      yield countryCode
    }
  } catch (error) {
    console.error('Error getting user country code from locale:', error)
  }
  // Gets country code using remote service
  try {
    const countryCode = await getCountryCodeFromIp()
    if (countryCode) {
      setCountryCodeInLocalStorage(countryCode)
      yield countryCode
    }
  } catch (error) {
    console.error('Error getting user country code from IP:', error)
  }
}

export default getMachineCountryCode
