import { Route } from 'src/routes/Route'
import { Subroute } from 'src/routes/Subroute'
import { CardCategoryRoute } from 'src/catalog/routes/CardCategoryRoute'
import { CardCollectionRoute } from 'src/catalog/routes/CardCollectionRoute'
import { CustomCardRoute } from 'src/catalog/routes/CustomCardRoute'
import { CustomCardsRoute } from 'src/catalog/routes/CustomCardsRoute'
import { FavoriteCardsRoute } from 'src/catalog/routes/FavoriteCardsRoute'
import { LegacyPicturePlusCardsRoute } from 'src/catalog/routes/LegacyPicturePlusCardsRoute'
import { PhotoDropCardsRoute } from 'src/catalog/routes/PhotoDropCardsRoute'
import { SendableCardRoute } from 'src/catalog/routes/SendableCardRoute'
import {
  dismissedCatalogSubroute,
  selectedCardCategory,
  selectedCardCollection,
  selectedCatalogCard,
  selectedPhotoDropCards,
} from 'src/redux/actions/catalog'
import { CatalogCardsRoute } from './CatalogCardsRoute'

export const CatalogSubroute = Subroute(
  CardCategoryRoute,
  CardCollectionRoute,
  CustomCardRoute,
  CustomCardsRoute,
  FavoriteCardsRoute,
  LegacyPicturePlusCardsRoute,
  PhotoDropCardsRoute,
  SendableCardRoute,
  CatalogCardsRoute,
)
export type CatalogSubroute = Subroute<typeof CatalogSubroute>

export const CatalogRoute = Route({
  path: '/catalog',
  init: (
    subroute?: CatalogSubroute,
    isBlankCardModalOpen: boolean = false,
  ) => ({
    subroute,
    isBlankCardModalOpen,
  }),
  fromPath: () => ({ isBlankCardModalOpen: false }),
  subroute: CatalogSubroute,
  reducer: (route, action) => {
    switch (action.type) {
      case dismissedCatalogSubroute.type:
        return { ...route, subroute: undefined }
      case selectedCardCategory.type:
        return {
          ...route,
          subroute: CardCategoryRoute(action.categoryId),
          isBlankCardModalOpen: false,
        }
      case selectedCardCollection.type:
        return {
          ...route,
          subroute: CardCollectionRoute(action.collectionId),
          isBlankCardModalOpen: false,
        }
      case selectedPhotoDropCards.type:
        return {
          ...route,
          subroute: PhotoDropCardsRoute(),
          isBlankCardModalOpen: false,
        }
      case selectedCatalogCard.type:
        return route.subroute === undefined
          ? {
              ...route,
              subroute:
                action.dataType === 'Card'
                  ? CustomCardRoute(action.id)
                  : SendableCardRoute(action.id),
            }
          : route
      default:
        return route
    }
  },
})
export type CatalogRoute = Route<typeof CatalogRoute>
