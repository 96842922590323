import { getAccount } from 'src/react_query'
import useActions from './useActions'
import usePersistedUserData from './usePersistedUserData'

export const useForceAccountRefresh = () => {
  const actions = useActions()
  const { controlData } = usePersistedUserData()

  if (!controlData.token)
    return async () => {
      return
    }

  return async () => {
    try {
      const result = await getAccount()
      actions.refreshedAccount(result)
    } catch (e) {
      console.error('Failed to refresh account', e)
    }
  }
}
