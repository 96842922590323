import {
  CampaignFragment,
  UpdateCampaignInput,
} from 'src/graphql/generated/graphql'

export const buildInput = (campaign: {
  id: string
  name: string
  lines?: CampaignFragment['lines']
}): UpdateCampaignInput => ({
  id: campaign.id,
  name: campaign.name,
  lines: campaign.lines?.map(line => ({
    card: line.card?.id,
    giftVariation: line.giftVariation?.id,
    sendDelay: {
      ...line.sendDelay,
      __typename: undefined,
    },
  })),
})
