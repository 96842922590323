import xor from 'lodash/xor'
import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'

import {
  DefaultError,
  Dialog,
  Icon,
  RwdCell,
  RwdHeader,
  RwdRow,
  RwdTable,
  Transition,
} from 'src/chrome'
import { DetailedGroupFragment, getGroup } from 'src/legacy_graphql'

import styles from './groupMembers.module.scss'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useEffect, useQueries, useState } from 'src/hooks'
import suspenseBoundary from 'src/chrome/SuspenseBoundary/suspenseBoundaryHOC'
import { Div, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import { Button } from 'src/design_system/molecules/button/Button'

type GroupMembersProps = {
  onClose: () => void
  onRemove: (group: DetailedGroupFragment, ids: string[]) => void
  groupId: string
}

const GroupMembers: React.FC<GroupMembersProps> = props => {
  const { groupId, onClose, onRemove } = props

  const [selection, setSelection] = useState<string[]>([])
  const [group] = useQueries(getGroup({ id: groupId }))
  const toggle = (id: string) => setSelection(x => xor(x, [id]))

  const toggleAll = () =>
    setSelection(x =>
      x.length < group.contacts.length
        ? group.contacts.map(member => member.id)
        : [],
    )

  const handleRemove = () => onRemove(group, selection)

  useEffect(() => {
    setSelection([])
  }, [group])

  const isEveryGroupMemberSelected = selection.length === group.contacts.length

  return (
    <Dialog disableBackdropClick={true} open={true} onClose={onClose}>
      <DialogTitle>
        <Text
          type="largeBody"
          color="primaryHeading"
        >{`Group: ${group.name}`}</Text>
      </DialogTitle>
      <div className={styles.body}>
        <span className={styles.close} onClick={() => onClose()}>
          <Icon icon={'CLOSE'} color={'#6f8394'} size={18} />
        </span>

        {group.contacts.length > 0 && (
          <>
            <div className={styles.info}>
              <div className={styles.groupDetails}>
                <Spacer space="x2" orientation="horizontal" />
                <Text type="largeBody">
                  {`${group.contacts.length} members ${selection.length} selected`}
                </Text>
                <Spacer space="x1" orientation="horizontal" />
              </div>
              <div className={styles.actions}>
                <Button
                  id={'remove_contacts_from_group_btn'}
                  title={{
                    content: 'Remove',
                    fontColor: '#e841b3',
                    fontSize: '15px',
                  }}
                  padding="6px 30px"
                  onClick={handleRemove}
                  fill="#e841b3"
                  borderRadius="10px"
                  isDisabled={selection.length < 1}
                  isOutlined={true}
                >
                  <Icon icon={'REMOVE'} color="#e841b3" size={15} />
                </Button>
              </div>
            </div>
            <div className={styles.tableContainer}>
              <RwdTable>
                <RwdHeader>
                  <th
                    id={'select_all_group_memebers_btn'}
                    style={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    onClick={toggleAll}
                  >
                    <Text type="body" color="primaryBrand">
                      {isEveryGroupMemberSelected
                        ? 'Deselect All'
                        : 'Select All'}
                    </Text>
                  </th>
                  <th>
                    <Text type="body" color="primaryBrand">
                      First Name
                    </Text>
                  </th>
                  <th>
                    <Text type="body" color="primaryBrand">
                      Last Name
                    </Text>
                  </th>
                  <th>
                    <Text type="body" color="primaryBrand">
                      Address
                    </Text>
                  </th>
                </RwdHeader>
                <tbody>
                  {group.contacts.map(contact => {
                    return (
                      <React.Fragment key={contact.id}>
                        <RwdRow
                          style={
                            selection.includes(contact.id)
                              ? { backgroundColor: '#e8e8e8' }
                              : {}
                          }
                        >
                          <RwdCell mapToHeader="Select">
                            <div className={styles.contactActions}>
                              <Checkbox
                                name={contact.lastName}
                                checked={selection.includes(contact.id)}
                                onChange={() => toggle(contact.id)}
                                className={styles.checkbox}
                              />
                            </div>
                          </RwdCell>
                          <RwdCell mapToHeader="First Name">
                            <Text type="caption">{`${contact.firstName}`}</Text>
                          </RwdCell>
                          <RwdCell mapToHeader="Last Name">
                            <Text type="caption">{`${contact.lastName}`}</Text>
                          </RwdCell>
                          <RwdCell mapToHeader="Address">
                            <Text type="caption">
                              {`${contact.address1} ...`}
                            </Text>
                          </RwdCell>
                        </RwdRow>
                        <tr className={styles.tableSpacer} />
                      </React.Fragment>
                    )
                  })}
                </tbody>
              </RwdTable>
            </div>
          </>
        )}
        {group.contacts.length === 0 && (
          <Div className={styles.noMembers}>
            <Text type="body">
              {`${group.name} has no members yet, add them on the contacts page`}
            </Text>
          </Div>
        )}
      </div>
    </Dialog>
  )
}

export default suspenseBoundary({
  component: GroupMembers,
  unresolved: <Transition message={'Loading Group...'} />,
  failure: DefaultError,
})
