import React from 'react'
// @src imports
import { Address } from 'src/chrome'
import { AddressForm } from 'src/contacts/components'
import { AddressFragment, CreateContactInput } from 'src/legacy_graphql'
import { useAccount, useEffect, useSelector, useState } from 'src/hooks'
import { OrderWithPartialLines } from 'src/redux/reducers/orders'
import { Button, Div, Text } from '@sendoutcards/quantum-design-ui'
import { emptyShippingAddress } from 'src/app/constants'

interface BulkOrderShippingAddressProps {
  onSave: (address: AddressFragment, phoneNumber?: string) => void
  order: OrderWithPartialLines
}

const BulkOrderShippingAddress: React.FC<BulkOrderShippingAddressProps> = props => {
  const { onSave, order } = props

  const [isEditing, setIsEditing] = useState(
    !order.bulkUpgradeShippingAddress ||
      !order.bulkUpgradeShippingAddress.address1,
  )

  const account = useAccount()

  const hasPhoneNumber = account.phoneNumber !== ''

  const isRecipientBreakPoint = useSelector(state => state.window.width < 820)

  const [address, setAddress] = useState(
    order.bulkUpgradeShippingAddress || emptyShippingAddress,
  )

  const handleChange: React.ReactEventHandler<HTMLInputElement> = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setAddress({
      ...address,
      [event.target.name]: event.target.value,
    })
  }

  const handleCountryChange = (country: string) =>
    setAddress({
      ...address,
      country: country,
    })

  const handleStateChange = (state: string) =>
    setAddress({
      ...address,
      state: state,
    })

  const handleSave = (
    contact: CreateContactInput,
    isCheckboxChecked: boolean,
    isSecondaryCheckboxChecked: boolean,
    accountPhoneNumber?: string,
  ) => {
    onSave(address, accountPhoneNumber)
    setIsEditing(false)
  }

  useEffect(() => {
    setAddress(order.bulkUpgradeShippingAddress || emptyShippingAddress)
    setIsEditing(
      !order.bulkUpgradeShippingAddress ||
        !order.bulkUpgradeShippingAddress.address1,
    )
  }, [order.bulkUpgradeShippingAddress])

  return (
    <Div style={{ width: '100%' }}>
      <Div
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        outset={{ vertical: 'x4' }}
      >
        <Div>
          <Text
            alignment="left"
            color="primaryHeading"
            content="Shipping Address"
            type={isRecipientBreakPoint ? 'largeBody' : 'subtitle'}
            weight="bold"
            lineHeight={1.4}
            outset={{ bottom: 'x1' }}
          />
          <Text
            alignment="left"
            content="Please enter the address you would like us to ship your bulk order to"
            lineHeight={1.4}
            type={isRecipientBreakPoint ? 'caption' : 'body'}
            weight="regular"
          />
        </Div>
        {!order.isSent && (
          <Div>
            <Button
              outlined={true}
              size="small"
              title={isEditing ? 'Cancel' : 'Edit'}
              onClick={() => {
                setIsEditing(e => !e)
              }}
            />
          </Div>
        )}
      </Div>
      {isEditing ? (
        <AddressForm
          toggleAddressBlinker={() => {}}
          contact={address}
          onChange={handleChange}
          onCountryChange={handleCountryChange}
          onStateChange={handleStateChange}
          shouldShowDates={false}
          showPhoneNumber={!hasPhoneNumber}
          isTitleVisible={false}
          onSubmit={handleSave}
        />
      ) : (
        <Address address={address} />
      )}
    </Div>
  )
}

export default BulkOrderShippingAddress
