import { Maybe, Operation } from '../../../__generated__/globalTypes'

import {
  MinimalContactFragment,
  minimalContactFragmentString,
} from '../../fragments/__generated__/MinimalContactFragment'

import getMinimalContactsRawString from '../GetMinimalContacts.graphql'

const getMinimalContactsString = [
  getMinimalContactsRawString,
  minimalContactFragmentString,
].join('\n\n')

export type GetMinimalContactsData = {
  paginatedContacts: {
    allContacts: MinimalContactFragment[]
  }
}

export type GetMinimalContactsVariables = {
  search?: Maybe<string>
  contacts?: Maybe<string[]>
  groups?: Maybe<string[]>
  birthdayStartDate?: Maybe<string>
  birthdayEndDate?: Maybe<string>
  anniversaryStartDate?: Maybe<string>
  anniversaryEndDate?: Maybe<string>
  showSecondaryContacts?: Maybe<boolean>
  hasNoGroup?: Maybe<boolean>
}

export type GetMinimalContacts = Operation<
  GetMinimalContactsData,
  GetMinimalContactsVariables
>

export const GetMinimalContacts = ({
  search,
  contacts,
  groups,
  birthdayStartDate,
  birthdayEndDate,
  anniversaryStartDate,
  anniversaryEndDate,
  showSecondaryContacts,
  hasNoGroup,
}: GetMinimalContactsVariables = {}): GetMinimalContacts => ({
  query: getMinimalContactsString,
  variables: {
    search,
    contacts,
    groups,
    birthdayStartDate,
    birthdayEndDate,
    anniversaryStartDate,
    anniversaryEndDate,
    showSecondaryContacts,
    hasNoGroup,
  },
})
