import React from 'react'
import { Div, Flex, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import {
  DayInfoType,
  useGetCurrentWeekDays,
} from 'src/dashboard/hooks/useCurrentWeekDays'

import { DateBadge } from 'src/dashboard/components/Widgets/Reminders/components/DateBadge'

interface WeekDateViewProps {
  weekDayEventList?: string[]
  isMobile?: boolean
}

export const WeekDayView: React.FC<WeekDateViewProps> = ({
  weekDayEventList,
  isMobile,
}) => {
  const currentWeekDays: DayInfoType[] = useGetCurrentWeekDays()
  const currentDay = currentWeekDays.find(day => day.isToday)

  // Function to format days with events
  const formatDaysWithEvents = () => {
    if (!weekDayEventList) return []
    return currentWeekDays.map(dateObj => {
      const currentDate = new Date(dateObj.isoDate)
      const isEventDay = weekDayEventList.includes(
        currentDate.toISOString().split('T')[0],
      )
      return { ...dateObj, dayWithEvent: isEventDay }
    })
  }

  return (
    <Flex width="100%" flexDirection="column">
      <Text
        type="body"
        content={`${currentDay?.month} 2024`}
        weight="bold"
        color="primaryHeading"
        inset={{ top: 'x1' }}
        outset={{ left: 'x_25' }}
      />
      <Flex
        width="100%"
        justifyContent="space-between"
        outset={{ vertical: 'x3' }}
      >
        {currentWeekDays.map(weekDay => (
          <DateBadge
            key={weekDay.dayOfMonth}
            day={weekDay.dayOfMonth}
            weekday={weekDay.shortDayOfWeek}
            month={weekDay.month}
            isActive={weekDay.isToday}
            isMobile={isMobile}
            color="#BE9CDD"
            hasEvent={
              formatDaysWithEvents().find(
                day => day.dateString === weekDay.dateString,
              )?.dayWithEvent ?? false
            }
          />
        ))}
      </Flex>
      <Div inset={{ horizontal: 'x2' }}>
        <Div style={{ border: '1px solid #f3f3f3' }} />
      </Div>
      <Spacer space="x2_5" />
    </Flex>
  )
}
