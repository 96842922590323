import React from 'react'
import { Set } from 'immutable'
import { MinimalContactFragment } from 'src/legacy_graphql'
import { Checkbox, Text } from '@sendoutcards/quantum-design-ui'

interface Props {
  contacts: MinimalContactFragment[]
  isEveryContactSelected: boolean
  onSelectAll: () => void
  onSelect: (id: string) => void
  selected: Set<string>
}

const ContactSelector = (props: Props) => {
  const {
    contacts,
    onSelect,
    onSelectAll,
    isEveryContactSelected,
    selected,
  } = props

  const styles = {
    li: {
      display: 'flex',
      justifyContent: 'space-between',
      cursor: 'pointer',
      padding: '4px 0 4px 8px',
      transition: 'all 0.5s ease 0s',
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: '#f7f7f7',
      },
    },
  }

  return (
    <>
      <div
        onClick={e => {
          e.preventDefault()
          onSelectAll()
        }}
        css={styles.li}
      >
        <Text
          type="body"
          color="primaryHeading"
          weight="semiBold"
          style={{ userSelect: 'none' }}
        >
          Select All
        </Text>
        <Checkbox
          isChecked={isEveryContactSelected}
          onChange={onSelectAll}
          id="ContactSelectorSelectAll"
        />
      </div>

      <div
        style={{
          overflowY: 'auto',
          maxHeight: '300px',
          borderBottom: '2px solid #3b4045',
          scrollbarWidth: 'thin',
        }}
      >
        <ul>
          {contacts.map(contact => {
            const { id, firstName, lastName } = contact
            return (
              <li
                key={id}
                onClick={e => {
                  e.preventDefault()
                  onSelect(id)
                }}
                css={styles.li}
              >
                <Text
                  type="body"
                  color="primaryHeading"
                  weight="semiBold"
                  style={{ userSelect: 'none' }}
                >{`${firstName} ${lastName}`}</Text>
                <Checkbox
                  isChecked={selected.has(id)}
                  onChange={() => {}}
                  id={`ContactSelectorContactLi-${id}`}
                />
              </li>
            )
          })}
        </ul>
      </div>
    </>
  )
}

export default ContactSelector
