import React, { FC } from 'react'
import { Icons, ICONS, IconType } from './icons'

interface Props {
  icon: IconType | Icons
  size?: number
  width?: number
  height?: number
  color?: string
  stroke?: string
  strokeWidth?: string
  viewBox?: string
  className?: string
  onClick?: () => void
  isCentered?: boolean
  style?: React.CSSProperties
  id?: string
}

const Icon: FC<Props> = props => {
  const {
    icon: propsIcon,
    size,
    width,
    height,
    color,
    stroke,
    strokeWidth,
    viewBox,
    className,
    isCentered,
    onClick,
    style,
    id,
  } = props

  const styles = {
    svg: {
      display: 'inline-block',
      margin: isCentered ? '0 auto' : 'initial',
      verticalAlign: 'middle',
      padding: 1,
    },
    path: {
      fill: color,
      stroke: stroke,
    },
    primary: {
      fill: '#ff5689',
    },
    secondary: {
      fill: '#e687ff',
    },
    opacity: {
      opacity: '0.2',
      fill: '#e687ff',
    },
  }

  const icon: IconType =
    typeof propsIcon === 'string' ? ICONS[propsIcon] : propsIcon
  const iconPaths = icon.paths
  const iconPointer = onClick ? { cursor: 'pointer' } : {}

  return icon ? (
    <div
      id={id}
      onClick={() => onClick?.()}
      style={{
        display: 'inline-block',
        ...style,
        ...iconPointer,
      }}
    >
      <svg
        className={className}
        style={styles.svg}
        width={`${size ? size : width ? width : 23}px`}
        height={`${size ? size : height ? height : 23}px`}
        viewBox={
          viewBox ? viewBox : icon.viewBox ? icon.viewBox : '0 0 1024 1024'
        }
        strokeWidth={strokeWidth ? strokeWidth : ''}
      >
        <g transform={icon.transform}>
          {iconPaths.map((path, index) => {
            const pathStyles = { ...styles.path, ...path.style }
            return (
              <path
                key={index}
                style={path.style ? pathStyles : styles.path}
                d={path.d}
                transform={path && path.transform}
                className={path && path.className}
              />
            )
          })}
        </g>
      </svg>
    </div>
  ) : null
}

export default Icon
