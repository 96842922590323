import { Maybe, Operation } from '../../../__generated__/globalTypes'

import sendPausedRecipientsString from '../SendPausedRecipients.graphql'

export type SendPausedRecipientsData = {
  sendPausedRecipients: {
    success: Maybe<boolean>
  }
}

export type SendPausedRecipientsVariables = {
  recipientIds?: Maybe<string[]>
}

export type SendPausedRecipients = Operation<
  SendPausedRecipientsData,
  SendPausedRecipientsVariables
>

export const SendPausedRecipients = ({
  recipientIds,
}: SendPausedRecipientsVariables = {}): SendPausedRecipients => ({
  query: sendPausedRecipientsString,
  variables: {
    recipientIds,
  },
})
