import { Operation } from '../../../__generated__/globalTypes'

import acceptMembershipInviteString from '../AcceptMembershipInvite.graphql'

export type AcceptMembershipInviteData = {
  acceptMembershipInvite: {
    success: boolean
  }
}

export type AcceptMembershipInviteVariables = {
  organizationName: string
}

export type AcceptMembershipInvite = Operation<
  AcceptMembershipInviteData,
  AcceptMembershipInviteVariables
>

export const AcceptMembershipInvite = ({
  organizationName,
}: AcceptMembershipInviteVariables): AcceptMembershipInvite => ({
  query: acceptMembershipInviteString,
  variables: {
    organizationName,
  },
})
