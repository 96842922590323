import { ActionCreator } from '@sendoutcards/core'

import { CampaignFragment } from 'src/graphql/generated/graphql'

export const editingCampaign = ActionCreator(
  'EDITING_CAMPAIGN',
  (campaign: CampaignFragment, line?: number) => ({ campaign, line }),
)

export const editedCampaign = ActionCreator(
  'EDITED_CAMPAIGN',
  (campaignId: string) => ({ campaignId }),
)
