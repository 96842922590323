import React from 'react'

import {
  Div,
  Flex,
  LoadingSpinner,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { Button } from 'src/design_system/components/Button/Button'
import { useState } from 'src/hooks'
import { SubscriptionSelection } from './SubscriptionSelection'
import { useProductVariants } from 'src/react_query/queries/hooks'
import { PlanIdType } from '../pricingTile/PlanTiles'
import { SelectionCard } from 'src/design_system/components/SelectionCard/SelectionCard'
import { ProductVariantFragment } from 'src/saleor_graphql/generated/graphql'
import { PlanFragment } from 'src/graphql/generated/graphql'

type StepPackageProps = {
  premiumSubVariantIds: string[]
  addonVariantIds?: string[]
  isMobile: boolean
  onSkip: () => void
  onContinue: (plan: PlanFragment, addons?: string[]) => void
  shouldShowAddons?: boolean
}

export const StepPackage: React.FC<StepPackageProps> = ({
  isMobile,
  premiumSubVariantIds,
  addonVariantIds,
  onSkip,
  onContinue,
  shouldShowAddons = true,
}) => {
  const [selectedPlan, setSelectedPlan] = useState<PlanFragment | undefined>()
  const [isAddonSelected, setIsAddonSelected] = useState<boolean>(false)

  const handlePlanSelection = (plan: PlanFragment) => {
    setSelectedPlan(plan)
  }

  const { data: premiumSubs } = useProductVariants({
    first: 100,
    ids: premiumSubVariantIds,
  })

  const { data: addonsQueryData } = useProductVariants(
    { first: 10, ids: addonVariantIds },
    addonVariantIds && addonVariantIds.length > 0,
  )

  const addons =
    addonsQueryData?.pages
      .flatMap(page => page?.edges.flatMap(edge => edge.node))
      .filter((addon): addon is ProductVariantFragment => Boolean(addon)) ?? []

  const pSubsIds = premiumSubs
    ? premiumSubs.pages[0]?.edges.map(
        pSub =>
          ({
            id: pSub.node.metadata.find(k => k.key === 'plan_id')?.value ?? '',
          } as PlanIdType),
      )
    : []
  return (
    <>
      <Flex
        style={{
          padding: '1rem 2rem 2rem 2rem',
          paddingBottom: isMobile ? '80px' : '2rem',
        }}
      >
        <Flex flexDirection="column" width="100%">
          <Text
            type="caption"
            weight="bold"
            content="Subscription Plans:"
            style={{ color: '#D1D5DB' }}
          />
          <Spacer space="x1" />
          {pSubsIds && pSubsIds.length > 0 ? (
            <Flex flexDirection={'column'} width="100%">
              <SubscriptionSelection
                planIds={pSubsIds}
                onSelectPlan={handlePlanSelection}
              />
              {addons && addons.length > 0 && shouldShowAddons && (
                <Flex flexDirection="column" width="100%">
                  <Text
                    type="caption"
                    weight="bold"
                    content="Plan Addons:"
                    style={{ color: '#D1D5DB' }}
                  />
                  <Spacer space="x1" />
                  {addons.map(addon => (
                    <Div width="100%" key={addon.id}>
                      <SelectionCard
                        title={addon.name}
                        price={`$${addon.pricing?.price?.gross.amount.toFixed(
                          2,
                        )}`}
                        pill={`$${addon.pricing?.discount?.gross.amount} value`}
                        hasSelect={true}
                        isSelected={isAddonSelected}
                        onSelect={setIsAddonSelected}
                        occurrence="mo"
                      />
                    </Div>
                  ))}
                </Flex>
              )}
            </Flex>
          ) : (
            <Flex
              width="100%"
              alignItems="center"
              justifyContent="center"
              inset="x6"
            >
              <LoadingSpinner size="xLarge" />
            </Flex>
          )}
        </Flex>
      </Flex>
      <Flex
        justifyContent="flex-end"
        alignItems="center"
        width="100%"
        position="relative"
        boxShadow="mediumLight"
        inset={{
          horizontal: 'x4',
          vertical: 'x2',
        }}
        style={{
          position: isMobile ? 'fixed' : 'sticky',
          bottom: 0,
          right: isMobile ? 20 : undefined,
          backgroundColor: isMobile ? '#F8F5F6' : '#fff',
        }}
      >
        <div onClick={onSkip} style={{ cursor: 'pointer' }}>
          <Text
            type="body"
            weight="semiBold"
            style={{ textDecoration: 'underline' }}
            content="Skip this step"
          />
        </div>
        <Spacer space="x2" orientation="horizontal" />
        <Button
          title="Yes, I confirm"
          backgroundColor="#404040"
          textColor="#FFF"
          textSize="14px"
          padding="0.75rem 2rem"
          onClick={() => {
            if (selectedPlan)
              onContinue(
                selectedPlan,
                isAddonSelected ? addonVariantIds : undefined,
              )
          }}
          isDisabled={!selectedPlan}
        />
      </Flex>
    </>
  )
}
