import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
// @src imports
import { RwdCell, RwdHeader, RwdRow, RwdTable } from 'src/chrome'

import { Group } from '../../contactTypes'
import { Text } from '@sendoutcards/quantum-design-ui'

const selectAllStyles: React.CSSProperties = {
  textDecoration: 'underline',
  cursor: 'pointer',
}

interface Props {
  isEveryGroupSelected?: boolean
  groups?: Group[]
  onSelectAll?: () => void
  onSelect?: (group: Group) => void
  onViewGroup?: (group: Group) => void
  onEditGroup?: (group: Group) => void
  selectedGroups?: Record<string, true | undefined>
}

const Groups: React.FC<Props> = props => {
  const {
    isEveryGroupSelected,
    groups,
    onSelectAll,
    onSelect,
    selectedGroups = {},
  } = props
  return (
    <RwdTable>
      <RwdHeader>
        <th style={selectAllStyles} onClick={() => onSelectAll?.()}>
          <Text type="body" color="primaryBrand">
            {isEveryGroupSelected ? 'Deselect All' : 'Select All'}
          </Text>
        </th>
        <th>
          <Text type="body" color="primaryBrand">
            Group Name
          </Text>
        </th>
        <th>
          <Text type="body" color="primaryBrand">
            Description
          </Text>
        </th>
      </RwdHeader>
      <tbody>
        {(groups || []).map((group, i) => {
          const isSelected = !!selectedGroups[group.id]

          return (
            <React.Fragment key={group.id.toString()}>
              <RwdRow
                id={`group_${i}`}
                style={isSelected ? { backgroundColor: '#e8e8e8' } : {}}
              >
                <RwdCell mapToHeader="Select">
                  <Checkbox
                    id={`checkbox_${i}`}
                    name={group.name}
                    checked={isSelected}
                    onChange={() => onSelect && onSelect(group)}
                  />
                </RwdCell>
                <RwdCell mapToHeader="Group">
                  <Text type="body">{group.name}</Text>
                </RwdCell>
                <RwdCell mapToHeader="Description">
                  <Text type="body">{group.description}</Text>
                </RwdCell>
              </RwdRow>
              <tr style={{ height: 6 }} />
            </React.Fragment>
          )
        })}
      </tbody>
    </RwdTable>
  )
}

export default Groups
