import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import MenuItem from '@material-ui/core/MenuItem'
// @src imports
import { EditableCell, TableRow } from 'src/chrome'

import { Column, Field, Row } from '../columns'

interface Props {
  isChecked: boolean
  index: number
  onToggleCheck?: (index: number) => void
  row: Row
  countries: string[]
  onEditCell?: (value: string, row: number, field: string) => void
  mappedColumns: { field: string; column: Column<Field> }[]
  dateFormat: string
}

const EditableRow: React.FC<Props> = props => {
  const {
    row,
    isChecked,
    index,
    onToggleCheck,
    countries,
    onEditCell,
    dateFormat,
    mappedColumns,
  } = props

  return (
    <TableRow>
      {onToggleCheck && (
        <EditableCell>
          <Checkbox checked={isChecked} onClick={() => onToggleCheck(index)} />
        </EditableCell>
      )}
      {mappedColumns.map(mappedColumn => {
        const { column } = mappedColumn
        const value = row[column.apiName] ?? ''
        const error = column.getError(row, value, column, dateFormat)

        return (
          <EditableCell
            key={`${index} ${column.apiName}`}
            cellId={{ row: index, cell: column.apiName }}
            value={value}
            hoverMessage={error?.toString()}
            onEditCell={
              onEditCell &&
              ((value, cellId) =>
                onEditCell(value ?? '', index, column.apiName))
            }
            hasIssue={error !== undefined}
            shouldUseSelectField={column.apiName === 'country'}
            selectFieldHint={'Select Country'}
            selectOptions={countries.map(country => (
              <MenuItem key={country} value={country}>
                {country}
              </MenuItem>
            ))}
          />
        )
      })}
    </TableRow>
  )
}

export default React.memo(EditableRow)
