import { Div, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { MoneyFragment } from 'src/saleor_graphql/generated/graphql'

type PricingSectionProps = {
  title: string
  subtitle?: string
  price?: MoneyFragment
  hasInvertedBackground?: boolean
}

export const PricingSection = (props: PricingSectionProps) => {
  const { title, subtitle, price, hasInvertedBackground } = props

  const backgroundColor = hasInvertedBackground ? '#fff' : '#fcfcfc'

  const amount = price?.amount ?? 0

  return (
    <Div
      style={{
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        paddingTop: '0.75rem',
        paddingRight: '1.25rem',
        paddingBottom: '0.75rem',
        paddingLeft: '1.25rem',
        backgroundColor: backgroundColor,
        boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.04)',
        borderRadius: '12px',
      }}
    >
      <Div
        style={{
          textAlign: 'right',
          display: 'flex',
          justifyContent: 'justify-start',
          alignItems: 'items-start',
          flexDirection: 'column',
        }}
      >
        <Text
          type="body"
          style={{ fontWeight: 700, color: '#000', fontSize: '14px' }}
        >
          {title}
        </Text>
        {props.subtitle && (
          <Text
            type="body"
            style={{ fontWeight: 600, color: '#737373', fontSize: '10px' }}
            lineHeight={1.0}
          >
            {subtitle}
          </Text>
        )}
      </Div>
      <Div
        style={{
          textAlign: 'left',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Text
          type="body"
          style={{ fontWeight: 700, color: '#000', fontSize: '14px' }}
        >
          {amount >= 0 ? '$' : '-$'}
          {Math.abs(amount).toFixed(2)}
        </Text>
      </Div>
    </Div>
  )
}
