import { Maybe } from '../../../__generated__/globalTypes'

import errorRawString from '../Error.graphql'

export const errorString = errorRawString

export type Error = {
  __typename: 'Error'
  code: Maybe<number>
  message: Maybe<string>
}

export const isError = (fragment: any): fragment is Error =>
  fragment &&
  fragment.__typename === 'Error' &&
  (fragment.code === null || typeof fragment.code === 'number') &&
  (fragment.message === null || typeof fragment.message === 'string')
