import React, { useRef } from 'react'
import posed from 'react-pose'
import { PoseElementProps } from 'react-pose/lib/components/PoseElement/types'
export type PosedReactComponent = React.ComponentType<PoseElementProps>

const getAnimatorProps = (props: Props) => ({
  show: {
    opacity: 1,
    applyAtStart: { display: 'block' },
    transition: ({ i }: { i: number }) => ({
      delay: i * 50,
      ease: 'easeIn',
      duration: 400,
    }),
  },
  hide: {
    opacity: 0,
    applyAtEnd: { display: 'none' },
    transition: ({ i }: { i: number }) => ({
      delay: i * 50,
      ease: 'easeIn',
      duration: 300,
    }),
  },
  props: { i: 0 },
})

interface Props {
  isVisible: boolean
  style?: React.CSSProperties
  readonly delayMultiplier?: number
  readonly shouldAnimateWithParent?: boolean
}

const PoseFade: React.FC<Props> = props => {
  const Animator = useRef<PosedReactComponent>(
    posed.div(getAnimatorProps(props)),
  ).current

  const {
    children,
    isVisible,
    delayMultiplier = 0,
    shouldAnimateWithParent = false,
    style,
  } = props

  return (
    <Animator
      i={delayMultiplier}
      pose={isVisible ? `show` : `hide`}
      withParent={shouldAnimateWithParent}
      style={style}
    >
      {children}
    </Animator>
  )
}

export default PoseFade
