import React from 'react'
import { useSelector } from 'src/hooks'
import { Div, Image } from '@sendoutcards/quantum-design-ui'

type CardPreviewType = {
  previewUrl: string
  isHorizontal: boolean
}
type GreetingCardRibbonProps = {
  cards: CardPreviewType[]
  cardWidth: string
  cardHeight: string
  archBasePx?: number
  shouldDisableTransforms?: boolean
  shouldReverseArch?: boolean
}
export const GreetingCardRibbon: React.FC<GreetingCardRibbonProps> = ({
  cards,
  cardWidth,
  cardHeight,
  archBasePx,
  shouldReverseArch,
  shouldDisableTransforms,
}) => {
  const isTablet = useSelector(state => state.window.width < 950)
  const isMobile = useSelector(state => state.window.width <= 520)
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        width: '100%',
        position: 'relative',
        height: cardHeight,
      }}
    >
      {cards.map((card, index) => {
        const height = card.isHorizontal ? cardWidth : cardHeight
        const imageHeight = isMobile ? '50%' : isTablet ? '60%' : '100%'
        const aspectRatio = card.isHorizontal ? '1.4' : '0.7'
        const archMidPoint = Math.floor(cards.length / 2) // Get the midpoint
        const distanceFromMidpoint = Math.abs(index - archMidPoint) // Calculate the arch based on the distance from the midpoint
        const translateY =
          index < archMidPoint
            ? shouldReverseArch
              ? `-${distanceFromMidpoint * (archBasePx || 0)}px`
              : `${distanceFromMidpoint * (archBasePx || 0)}px`
            : shouldReverseArch
            ? `-${distanceFromMidpoint * (archBasePx || 0)}px`
            : `${distanceFromMidpoint * (archBasePx || 0)}px`
        const rotationDeg = index <= archMidPoint ? `15deg` : `-15deg`

        const baseStep = 100 / cards.length
        const leftPos = baseStep * index
        return (
          <Div
            key={index}
            width={`${baseStep}%`}
            height={height}
            transform={
              shouldDisableTransforms ? 'initial' : `rotate(${rotationDeg})`
            }
            position="absolute"
            top={translateY}
            left={`${leftPos}%`}
            display="flex"
            justifyContent="center"
          >
            <div
              key={index}
              style={{ height: imageHeight, aspectRatio: aspectRatio }}
            >
              <Image
                width="100%"
                height="100%"
                image={{ url: card.previewUrl }}
                isActive={true}
              />
            </div>
          </Div>
        )
      })}
    </div>
  )
}
