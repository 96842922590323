import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'

import { MinimalOrderFragment } from 'src/legacy_graphql'
import { Text } from '@sendoutcards/quantum-design-ui'

const styles = {
  card: {
    display: 'flex',
    position: 'relative' as const,
    flex: '1 1 auto',
    alignItems: 'center',
    justifyContent: 'center',
    width: '200px',
    height: '300px',
    margin: '15px',
    background: '#fff',
    boxShadow: '0 4px 20px 0 rgba(242, 79, 189, 0.16)',
    borderRadius: '4px',
    zIndex: 100,
    transition: '0.5s cubic-bezier(0.4, 0.4, 0, 1)',
    cursor: 'pointer',
    'svg path': {
      fill: '#f262b1',
    },
    span: {
      '> p': {
        textAlign: 'center' as const,
      },
    },
    ':hover': {
      transform: 'translateY(-6px)',
      boxShadow: '0 20px 60px 0 rgba(242, 79, 189, 0.26)',
      '> div > div:last-child': {
        display: 'initial',
      },
    },
    '@media (max-width: 767px)': {
      width: 'calc(50% - 15px)',
      margin: 0,
      marginLeft: '15px',
      marginBottom: '15px',
      flex: 'initial',
    },
    '@media (max-width: 500px)': {
      width: '100%',
      margin: 0,
      marginBottom: '15px',
      maxWidth: '214px',
      ':hover': {
        transform: 'none',
      },
      '> div': {
        backgroundSize: 'contain !important',
      },
    },
  },
  orderTile: {
    position: 'relative' as const,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    width: '100%',
    height: '100%',
  },
  cardCheckmark: {
    display: 'none',
  },
  orderDetail: {
    position: 'absolute' as const,
    bottom: 0,
    left: 0,
    backgroundColor: '#fff',
    width: '100%',
  },
  details: {
    padding: '20px',
  },
  orderButtons: {
    borderTop: '1px solid #ececec',
  },
  checkBoxStyle: {
    display: 'flex',
    width: 35,
    height: 35,
    position: 'absolute' as const,
    top: 10,
    left: 10,
    borderRadius: 100,
    background: '#FFFFFFA3',
    border: '1px solid #fff',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
  },
}

interface DraftedOrderProps {
  order: MinimalOrderFragment
  isOrderSelected: boolean
  hasSelections: boolean
  onSelectOrder: (orderId: string) => void
  onOpenOrder: (order: MinimalOrderFragment) => void
}

const DraftedOrder: React.FC<DraftedOrderProps> = props => {
  const {
    order,
    isOrderSelected,
    hasSelections,
    onSelectOrder,
    onOpenOrder,
  } = props

  const card = !!order.minimalLines.length && order.minimalLines[0].card

  const gift =
    !card && order.minimalLines[0] ? order.minimalLines[0].giftVariation : null

  const backgroundImage = {
    backgroundImage: `url(${
      gift ? gift.imageUrls[0] : card ? card.frontPreviewUrl : ''
    })`,
  }

  const checkbox = (
    <div
      className={'cardCheckmark'}
      css={styles.cardCheckmark}
      style={hasSelections ? { display: 'initial' } : {}}
    >
      <Checkbox
        checked={isOrderSelected}
        onClick={ev => {
          ev.stopPropagation()
          onSelectOrder(order.id)
        }}
        checkedIcon={
          <CheckCircleIcon
            style={{
              position: 'absolute',
            }}
          />
        }
        icon={
          <RadioButtonUncheckedIcon
            style={{
              position: 'absolute',
            }}
          />
        }
        style={styles.checkBoxStyle}
      />
    </div>
  )

  const continueOrder = (
    <div css={styles.orderButtons}>
      <Text
        type="caption"
        color="primaryHeading"
        weight="bold"
        inset={{ vertical: 'x1' }}
        alignment="center"
      >
        Continue Order
      </Text>
    </div>
  )

  return (
    <div css={styles.card} onClick={() => onOpenOrder(order)}>
      {(card && card.frontPreviewUrl) || gift ? (
        <div css={{ ...styles.orderTile, ...backgroundImage }}>
          <div css={styles.orderDetail}>
            <div css={styles.details}>
              <Text type="footnote" color="primaryHeading">
                My order #{order.id}
              </Text>
            </div>
            {continueOrder}
          </div>
          {checkbox}
        </div>
      ) : (
        <div>
          <Text type="caption" color="primaryHeading" alignment="center">
            No Card Selected
          </Text>
          <div css={styles.orderDetail}>{continueOrder}</div>
          {checkbox}
        </div>
      )}
    </div>
  )
}

export default DraftedOrder
