import { State } from 'src/redux/reducers'
import { Task } from '../app/types'
import sortBy from 'lodash/sortBy'
import {
  completedQuery,
  mutationFailed,
  mutationSucceeded,
} from 'src/redux/actions'
import { denormalizeValue } from 'src/normalized-data/normalization'
import Action from 'src/redux/action'
import Future from 'src/utils/Future'

const tasks = ({
  graphql: {
    queryStates,
    queryProperties,
    queryRequests,
    normalizedData,
    optimisticMutations,
  },
}: State): Task[] => {
  const optimisticMutation = optimisticMutations[0]

  if (optimisticMutation) {
    return [
      Task(optimisticMutation.operation, result =>
        result.match<Action>(
          data => mutationSucceeded(data, false, {}, true),
          error => mutationFailed(true, optimisticMutation.failureMessage),
        ),
      ),
    ]
  }

  const queries = queryRequests
    .entries()
    .flatMap(([operation, requests]) =>
      requests
        .entries()
        .map(([{ offset, limit }, requests]) => ({
          state: queryStates
            .get(operation)
            .map(state =>
              queryProperties
                .get(operation)
                .mapState(
                  denormalizeValue(state, normalizedData),
                  offset ?? undefined,
                  limit ?? undefined,
                  operation,
                  action => action,
                ),
            ),
          operation,
          offset,
          limit,
          requests,
          hitCDNCache: queryProperties.get(operation).hitCDNCache,
        }))
        .concat(
          queryStates.get(operation).value
            ? queryProperties
                .get(operation)
                .additionalPagesToLoad(queryStates.get(operation).value)
                .map(({ offset, limit }) => ({
                  state: Future(),
                  operation,
                  offset,
                  limit,
                  requests: 1,
                  hitCDNCache: queryProperties.get(operation).hitCDNCache,
                }))
            : [],
        ),
    )
    .filter(({ state }) => state.isUnresolved)

  const sortedQueries = sortBy(queries, ({ requests }) => requests)

  return sortedQueries.map(query =>
    Task(
      {
        query: query.operation.query,
        variables:
          typeof query.operation.variables === 'object'
            ? {
                ...query.operation.variables,
                offset: query.offset,
                limit: query.limit,
              }
            : query.operation.variables,
      },
      result =>
        completedQuery(query.operation, query.offset, query.limit, result),
      query.hitCDNCache,
    ),
  )
}

export default tasks
