import { UpdateOrderInput, Operation } from '../../../__generated__/globalTypes'

import {
  DetailedOrderFragment,
  detailedOrderFragmentString,
} from '../../fragments/__generated__/DetailedOrderFragment'

import { elementTextFragmentString } from '../../fragments/__generated__/ElementTextFragment'

import { paragraphFragmentString } from '../../fragments/__generated__/ParagraphFragment'

import { amountFragmentString } from '../../fragments/__generated__/AmountFragment'

import { itemizedCostFragmentString } from '../../fragments/__generated__/ItemizedCostFragment'

import { cardFragmentString } from '../../fragments/__generated__/CardFragment'

import { sendableCardWithTemplateFragmentString } from '../../fragments/__generated__/SendableCardWithTemplateFragment'

import { sendableCardFragmentString } from '../../fragments/__generated__/SendableCardFragment'

import { addressFragmentString } from '../../fragments/__generated__/AddressFragment'

import { lineFragmentString } from '../../fragments/__generated__/LineFragment'

import { labeledPriceFragmentString } from '../../fragments/__generated__/LabeledPriceFragment'

import { errorString } from '../../fragments/__generated__/Error'

import { paperCostsFragmentString } from '../../fragments/__generated__/PaperCostsFragment'

import { imageFragmentString } from '../../fragments/__generated__/ImageFragment'

import { sendDelayFragmentString } from '../../fragments/__generated__/SendDelayFragment'

import { elementFragmentString } from '../../fragments/__generated__/ElementFragment'

import { textFragmentFragmentString } from '../../fragments/__generated__/TextFragmentFragment'

import { elementPictureFragmentString } from '../../fragments/__generated__/ElementPictureFragment'

import { giftVariationFragmentString } from '../../fragments/__generated__/GiftVariationFragment'

import { colorFragmentString } from '../../fragments/__generated__/ColorFragment'

import { panelFragmentString } from '../../fragments/__generated__/PanelFragment'

import { priceFragmentString } from '../../fragments/__generated__/PriceFragment'

import { sendableCardImageFragmentString } from '../../fragments/__generated__/SendableCardImageFragment'

import updateOrderRawString from '../UpdateOrder.graphql'

const updateOrderString = [
  updateOrderRawString,
  detailedOrderFragmentString,
  elementTextFragmentString,
  paragraphFragmentString,
  amountFragmentString,
  itemizedCostFragmentString,
  cardFragmentString,
  sendableCardWithTemplateFragmentString,
  sendableCardFragmentString,
  addressFragmentString,
  lineFragmentString,
  labeledPriceFragmentString,
  errorString,
  paperCostsFragmentString,
  imageFragmentString,
  sendDelayFragmentString,
  elementFragmentString,
  textFragmentFragmentString,
  elementPictureFragmentString,
  giftVariationFragmentString,
  colorFragmentString,
  panelFragmentString,
  priceFragmentString,
  sendableCardImageFragmentString,
].join('\n\n')

export type UpdateOrderData = {
  updateOrder: {
    order: DetailedOrderFragment
  }
}

export type UpdateOrderVariables = {
  order: UpdateOrderInput
}

export type UpdateOrder = Operation<UpdateOrderData, UpdateOrderVariables>

export const UpdateOrder = ({ order }: UpdateOrderVariables): UpdateOrder => ({
  query: updateOrderString,
  variables: {
    order: UpdateOrderInput(order),
  },
})
