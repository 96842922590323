import { Operation } from '../../../__generated__/globalTypes'

import deleteCampaignString from '../DeleteCampaign.graphql'

export type DeleteCampaignData = {
  deleteCampaign: {
    deleted: boolean
  }
}

export type DeleteCampaignVariables = {
  id: string
}

export type DeleteCampaign = Operation<
  DeleteCampaignData,
  DeleteCampaignVariables
>

export const DeleteCampaign = ({
  id,
}: DeleteCampaignVariables): DeleteCampaign => ({
  query: deleteCampaignString,
  variables: {
    id,
  },
})
