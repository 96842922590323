import React from 'react'
import {
  Anchor,
  Button,
  Flex,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'

const AddPhoneNumberForFreeCard = (props: {
  onAddPhoneNumber: () => void
  onDeclinePhoneNumber: () => void
  onClose: () => void
}) => {
  const { onAddPhoneNumber, onDeclinePhoneNumber, onClose } = props

  return (
    <Flex flexDirection="column" justifyContent="center" alignItems="center">
      <div>
        <Text type="title" weight="bold">
          Finish Setting Up Your Account
        </Text>
        <Spacer space="x2" />
        <Text type="caption">
          Get your first card for free by adding your phone number!
        </Text>
      </div>
      <Spacer space="x4" />
      <Flex justifyContent="center">
        <Button
          hover={true}
          title="Add Phone Number"
          onClick={onAddPhoneNumber}
          type="secondary"
        />
        <Button
          hover={true}
          title={'Back to completing purchase'}
          onClick={onClose}
          size="medium"
        />
      </Flex>
      <Spacer space="x2" />
      <Anchor size={'small'} isDecorated={true} onClick={onDeclinePhoneNumber}>
        No thanks, I'll pay for my card
      </Anchor>
    </Flex>
  )
}
export default AddPhoneNumberForFreeCard
