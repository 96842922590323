import React from 'react'

interface RwdCellProps {
  mapToHeader: string
  className?: string
  style?: React.CSSProperties
  colSpan?: number
}

const RwdCell: React.FC<RwdCellProps> = props => {
  const { children, mapToHeader, colSpan, ...restProps } = props
  return (
    <td data-th={mapToHeader} colSpan={colSpan || 1} {...restProps}>
      {children}
    </td>
  )
}

export default RwdCell
