import { ConfirmDialog, PhoneInput } from '@sendoutcards/quantum-design-ui'
import React, { FC } from 'react'
import { getMachineCountryCode } from 'src/helpers'
import {
  createPhoneNumberInput,
  getValidatedNumber,
} from 'src/helpers/createPhoneNumberInput'
import {
  useDebounce,
  useEffect,
  useMutations,
  usePersistedUserData,
  useState,
  useVertical,
} from 'src/hooks'
import { Memoized } from 'src/hooks/dependencies'
import usePhoneNumberCountries, {
  PhoneNumberCountries,
} from 'src/hooks/usePhoneNumberCountries'
import { getFormattedNumber } from 'src/onboarding/components/PhoneNumberVerification'
import { Portal } from 'src/portal/portal'

// These are for Promptings Gratitude Consultant join only, will need ALL phoneNumberCountries for all other applications
const phoneNumberCountriesLimited: PhoneNumberCountries[] = [
  {
    __typename: 'PhoneNumberCountry',
    callingCode: 61,
    isoCode: 'AU',
    name: 'Australia',
  },
  {
    __typename: 'PhoneNumberCountry',
    callingCode: 1,
    isoCode: 'CA',
    name: 'Canada',
  },
  {
    __typename: 'PhoneNumberCountry',
    callingCode: 353,
    isoCode: 'IE',
    name: 'Ireland',
  },
  {
    __typename: 'PhoneNumberCountry',
    callingCode: 64,
    isoCode: 'NZ',
    name: 'New Zealand',
  },
  {
    __typename: 'PhoneNumberCountry',
    callingCode: 1,
    isoCode: 'US',
    name: 'United States',
  },
  {
    __typename: 'PhoneNumberCountry',
    callingCode: 44,
    isoCode: 'GB',
    name: 'United Kingdom',
  },
]

const phoneNumberCollisionCopy = {
  title: 'Phone Number Already Exists',
  message:
    'Because we use a single-sign-in process you only need one account and login for all of our streams. We ask for your phone number to ensure duplicate accounts are not being created. You can also use your phone number to log in instead of your username and password if you’d prefer.',
}

type CreateAccountPhoneInputProps = {
  phoneNumber: string
  handleSetPhoneNumber: (phoneNumber: string) => void
  handleIsCheckingPhoneNumber: Memoized<
    (isCheckingPhoneNumber: boolean) => void
  >
  handleIsPhoneNumberTaken: Memoized<(isTaken: boolean) => void>
  handleIsPhoneNumberInvalid: Memoized<(isInvalid: boolean) => void>
}

export const CreateAccountPhoneInput: FC<CreateAccountPhoneInputProps> = ({
  phoneNumber,
  handleSetPhoneNumber,
  handleIsCheckingPhoneNumber,
  handleIsPhoneNumberTaken,
  handleIsPhoneNumberInvalid,
}) => {
  const { account } = usePersistedUserData()
  const mutations = useMutations()
  const vertical = useVertical()
  const phoneNumberCountriesAll = usePhoneNumberCountries()
  const [isCountrySelectedManually, setIsCountrySelectedManually] = useState<
    boolean
  >(false)

  const [isPhoneNumberTaken, setIsPhoneNumberTaken] = useState(false)
  const [
    isPhoneNumberCollisionModalOpen,
    setIsPhoneNumberCollisionModalOpen,
  ] = useState(false)

  const phoneNumberCountries =
    vertical.id === 'promptings'
      ? phoneNumberCountriesLimited
      : phoneNumberCountriesAll

  const [selectedCountryIndex, setSelectedCountryIndex] = useState(
    phoneNumberCountries.findIndex(country => country.isoCode === 'US'),
  )

  const selectedCountry = phoneNumberCountries[selectedCountryIndex]
  const selectedCountryCode = selectedCountry.isoCode
  const formattedNumber = getFormattedNumber(selectedCountryCode, phoneNumber)
  const validatedNumber = getValidatedNumber(selectedCountryCode, phoneNumber)

  const debouncedPhoneNumber = useDebounce(phoneNumber, 500)
  const phoneNumberInput = createPhoneNumberInput(
    debouncedPhoneNumber,
    selectedCountry,
  )

  useEffect(() => {
    const updateCountryCode = async () => {
      for await (const countryCode of getMachineCountryCode()) {
        const index = phoneNumberCountries.findIndex(country => {
          return country.isoCode === countryCode
        })
        if (index !== -1 && !isCountrySelectedManually) {
          setSelectedCountryIndex(index)
        }
      }
    }
    if (!isCountrySelectedManually) {
      updateCountryCode()
    }
  }, [phoneNumberCountries, isCountrySelectedManually])

  useEffect(() => {
    setIsPhoneNumberTaken(false)
  }, [phoneNumber, selectedCountry])

  useEffect(() => {
    handleIsPhoneNumberInvalid(!validatedNumber && debouncedPhoneNumber !== '')
  }, [validatedNumber, debouncedPhoneNumber, handleIsPhoneNumberInvalid])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    let mutableCanceling = false
    const isPhoneNumberPristine =
      account && phoneNumberInput.nationalNumber === account.phoneNumber

    if (phoneNumberInput.nationalNumber && !isPhoneNumberPristine) {
      const validate = async () => {
        handleIsCheckingPhoneNumber?.(true)

        const {
          validatePhoneNumber: { isValid },
        } = await mutations.validatePhoneNumber({
          phoneNumber: phoneNumberInput,
          doNotShowDefaultTransition: true,
        })
        handleIsCheckingPhoneNumber?.(false)
        if (mutableCanceling) {
          return
        }
        setIsPhoneNumberTaken(!isValid)
        handleIsPhoneNumberTaken(!isValid)
      }
      validate()
    }

    return () => {
      mutableCanceling = true
    }
  }, [
    account,
    mutations,
    selectedCountry,
    phoneNumberInput,
    handleIsCheckingPhoneNumber,
    handleIsPhoneNumberTaken,
  ])

  return (
    <div
      style={
        (!validatedNumber && debouncedPhoneNumber !== '') || isPhoneNumberTaken
          ? { marginBottom: '3rem' }
          : undefined
      }
    >
      <PhoneInput
        countries={phoneNumberCountries}
        selectedCountryIndex={selectedCountryIndex}
        onSelectedCountryIndexChange={index => {
          setIsCountrySelectedManually(true)
          setSelectedCountryIndex(index)
        }}
        phoneNumber={formattedNumber}
        onPhoneNumberChange={handleSetPhoneNumber}
        maxHeight="125px"
        message={
          !validatedNumber && debouncedPhoneNumber !== ''
            ? {
                type: 'danger',
                content: 'Please enter a valid phone number.',
              }
            : isPhoneNumberTaken
            ? {
                type: 'danger',
                content:
                  'This phone number exists on another account. Please log in with those account credentials.',
                icon: 'information',
                onClick: () => setIsPhoneNumberCollisionModalOpen(true),
              }
            : undefined
        }
      />
      <Portal wrapperStyles={{ position: 'relative', zIndex: 12 }}>
        <ConfirmDialog
          zIndex={1}
          isOpen={isPhoneNumberCollisionModalOpen}
          title={phoneNumberCollisionCopy.title}
          description={phoneNumberCollisionCopy.message}
          accept={{
            title: 'Got it',
            onClick: () => setIsPhoneNumberCollisionModalOpen(false),
          }}
          primaryAction={'accept'}
          onClose={() => setIsPhoneNumberCollisionModalOpen(false)}
        />
      </Portal>
    </div>
  )
}
