import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Set } from 'immutable'
// @src imports
import {
  CardDimensions,
  cardPreviewConfig,
} from 'src/helpers/cardPreviewConfig'
import { PanoramaSlide } from 'src/chrome'
import { useCallback, useRef, useState } from 'src/hooks'

import styles from './cardPanelView.module.scss'
import { Entry } from 'src/chrome/SliderConfig/SliderConfig'

interface CardPanelViewProps {
  card: CardDimensions
  shouldRotateBackPanels?: boolean
  sliderMaxContainerHeight?: number
  maxHeight?: string
  width?: string
}

function getPanelName(panel: Entry, card: CardDimensions) {
  return (panel.names ?? []).join() + card.id
}

const CardPanelView: React.FC<CardPanelViewProps> = props => {
  const {
    card,
    shouldRotateBackPanels,
    sliderMaxContainerHeight,
    maxHeight,
    width,
  } = props

  const mutableContainerRef: React.MutableRefObject<HTMLDivElement | null> = useRef<
    HTMLDivElement
  >(null)
  const [loaded, setLoaded] = useState(Set())
  const cardConfig = cardPreviewConfig(card)

  const onLoad = useCallback(
    (panelName: string) => {
      setLoaded(loaded.add(panelName))
    },
    [loaded, setLoaded],
  )

  const backIndex = cardConfig ? cardConfig.length - 1 : null
  const shouldRotate = shouldRotateBackPanels && card.isHorizontal

  return (
    <div
      style={{
        width: width ?? '100%',
        maxHeight: maxHeight ?? '500px',
        overflow: 'hidden',
      }}
      ref={el => (mutableContainerRef.current = el ?? null)}
    >
      {cardConfig && (
        <PanoramaSlide
          sliderId={card.id}
          config={cardConfig}
          marginSize={25}
          containerRef={mutableContainerRef}
          isVertical={
            card.isHorizontal ||
            (!card.isHorizontal && card.type === 'POSTCARD')
          }
          verticalMaxContainerHeight={sliderMaxContainerHeight || 500}
        >
          {cardConfig.map((panel, i: number) => (
            <div
              key={i}
              style={
                shouldRotate && i === backIndex
                  ? {
                      transform: 'rotate(270deg)',
                      marginTop: `-${panel.width - panel.height}px`,
                    }
                  : {}
              }
            >
              <img
                src={panel.previewUrl}
                onLoad={() => onLoad(getPanelName(panel, card))}
                onError={() => onLoad(getPanelName(panel, card))}
                alt={(panel.names ?? []).join()}
                className={`${styles.cardShadow}`}
                style={
                  loaded.contains(getPanelName(panel, card))
                    ? {
                        width:
                          shouldRotate && i === backIndex
                            ? panel.height
                            : panel.width,
                        height:
                          shouldRotate && i === backIndex
                            ? panel.width
                            : panel.height,
                      }
                    : { display: 'none' }
                }
              />
              {!loaded.contains(getPanelName(panel, card)) && (
                <div
                  className={styles.cardShadow}
                  style={
                    {
                      width: panel.width,
                      height: panel.height,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    } as React.CSSProperties
                  }
                >
                  <CircularProgress />
                </div>
              )}
            </div>
          ))}
        </PanoramaSlide>
      )}
    </div>
  )
}

export default CardPanelView
