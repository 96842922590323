import React from 'react'
import CardPicker from 'src/card_picker/CardPicker'
import { useActions } from 'src/hooks'
import { AddCampaignCardRoute } from '../../routes/AddCampaignCardRoute'
import { CardActionsProps } from 'src/campaigns/components/CampaignDetail/Campaign'
import { CampaignRoute } from 'src/campaigns/routes/CampaignRoute'
import { buildInput } from '../../utils'
import { useUpdateCampaign } from 'src/react_query'
import Editor from 'src/editor/containers/Editor/Editor'
import { FullWindowModal } from 'src/chrome'
import { CardRoute } from 'src/card/routes/CardRoute'

const AddCampaignCard: React.FC<
  CardActionsProps & { route: AddCampaignCardRoute }
> = props => {
  const { editableCampaign, campaignRoute, resetRoute, route } = props
  const context = {
    headerText: `Campaign: ${editableCampaign?.name}`,
    headerSubText: `Adding card #${
      editableCampaign?.lines && editableCampaign?.lines?.length + 1
    }`,
    lines: editableCampaign?.lines,
    footerLabel: () => (
      <b>
        {editableCampaign?.lines.length} card
        {editableCampaign?.lines && editableCampaign.lines.length > 1 && 's'}
      </b>
    ),
  }

  const actions = useActions()
  const { mutateAsync: updateCampaign } = useUpdateCampaign()
  const handleAdd = async (cardId: string) => {
    if (editableCampaign) {
      const campaignInput = buildInput(editableCampaign)

      await updateCampaign({
        campaign: {
          ...campaignInput,
          lines: (campaignInput.lines || []).concat([
            {
              card: cardId,
              giftVariation: undefined,
              sendDelay: null,
            },
          ]),
        },
      })
    }
    resetRoute()
  }

  if (route.subroute && route.subroute?.path === CardRoute.path) {
    const cardId = route.subroute.cardId
    return (
      <FullWindowModal isReallyFull={true}>
        <Editor
          context={context}
          cardId={cardId}
          onAfterSave={() => handleAdd(cardId)}
          onClose={actions.dismissedCatalogSubroute}
          onBackToCatalog={actions.dismissedCatalogSubroute}
          saveButtonLabel="Add to campaign"
        />
      </FullWindowModal>
    )
  }

  return (
    <CardPicker
      context={context}
      catalogSubroute={route.subroute}
      onSelectCard={selectedCardId => {
        actions.openCampaigns(
          CampaignRoute(
            campaignRoute.campaignId,
            AddCampaignCardRoute(CardRoute(selectedCardId)),
          ),
        )
      }}
      onClose={resetRoute}
      onCatalogNavigate={subroute => {
        actions.openCampaigns(
          CampaignRoute(
            campaignRoute.campaignId,
            AddCampaignCardRoute(subroute),
          ),
        )
      }}
    />
  )
}

export default AddCampaignCard
