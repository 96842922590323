import React from 'react'
import {
  AnimatedCheckMark,
  Button,
  Clipboard,
  Flex,
  Separator,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { useAccount, useActions, useQueries } from 'src/hooks'
import { getMarketingContent } from 'src/legacy_graphql'
import { AppLinks } from 'src/upsale/components/appLinks/AppLinks'

interface PaymentSuccessProps {
  isBulk?: boolean
}

const PaymentSuccess: React.FC<PaymentSuccessProps> = props => {
  const { isBulk } = props
  const account = useAccount()
  const [marketingCopy] = useQueries(getMarketingContent())
  const affiliateUrl = account.affiliateUrl
  // URL with protocol (https://) stripped
  const affiliateLink = affiliateUrl.replace(/^\/\/|^.*?:(\/\/)?/, '')
  const textAlignment = window.innerWidth < 650 ? 'left' : 'center'
  const actions = useActions()

  return (
    <Flex
      minWidth="320px"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      id="sent_card_confirmation"
      inset={{ horizontal: 'x4', vertical: 'x6' }}
    >
      <AnimatedCheckMark size="xLarge" />
      <Flex
        id="title_wrapper"
        flexDirection="column"
        rowGap="x1"
        inset={{ top: 'x2' }}
      >
        <Text alignment="center" type="largeBody" content="Congratulations!" />
        <Text
          alignment="center"
          type="largeBody"
          style={{ color: '#51BFE1' }}
          content="Your order has been sent!"
        />
      </Flex>
      <Flex flexDirection="column" maxWidth="620px">
        <Flex
          width="100%"
          inset={{ horizontal: 'x3', top: 'x2', bottom: 'x1' }}
        >
          <Separator orientation="horizontal" />
        </Flex>
        <Flex flexDirection="column" rowGap="x1" inset={{ vertical: 'x2' }}>
          <Text
            alignment={textAlignment}
            type="caption"
            weight="semiBold"
            color="primaryBody"
            content={
              isBulk
                ? 'If you need to make any changes to your bulk order, you can make changes until 6 p.m. (MT).'
                : marketingCopy.immediateSendEditingMessage.content
            }
            data-mktcontent="immediateSendEditingMessage"
          />
          <Text
            alignment={textAlignment}
            type="caption"
            weight="semiBold"
            color="primaryBody"
            content={
              isBulk
                ? 'Bulk orders submitted after 6 p.m. (MT) have until 6 p.m. (MT) the following day to make changes.'
                : marketingCopy.futureSendEditingMessage.content
            }
            data-mktcontent="futureSendEditingMessage"
          />
        </Flex>
        <Spacer space="x1" />
        <Flex width="100%" inset={{ horizontal: 'x2' }}>
          <Separator orientation="horizontal" />
        </Flex>
        <Flex flexDirection="column" rowGap="x2" inset={{ top: 'x3' }}>
          <Text
            alignment="center"
            type="caption"
            weight="semiBold"
            content="Copy the link below to give someone else the opportunity to send
          kindness to the world!"
          />
          <Clipboard copyText={affiliateLink} />
          <Button
            onClick={actions.openCatalog}
            outlined={true}
            fullWidth={true}
            title="Send another card"
          />
          <Button
            fullWidth={true}
            title="Dashboard"
            onClick={() => actions.openAccount()}
          />
        </Flex>
      </Flex>
      <Spacer space="x2" orientation="vertical" />
      <AppLinks />
    </Flex>
  )
}

export default PaymentSuccess
