import React from 'react'

import { Dialog, Icon } from 'src/chrome'

import styles from './campaignShare.module.scss'
import { DialogTitle } from '@material-ui/core'
import DialogContent from '@material-ui/core/DialogContent'
import { useSelector, useState } from 'src/hooks'
import { CampaignLineCard } from '..'
import { CampaignLine } from '../CampaignLine/CampaignLineCard'
import {
  Checkbox,
  Flex,
  Input,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { Button } from 'src/design_system/components/Button/Button'

type CampaignShareProps = {
  campaignTitle: string
  lines?: CampaignLine[]
  onClose: () => void
  onSubmit: (user: string, shouldAllowShare: boolean) => void
}

const CampaignShare: React.FC<CampaignShareProps> = props => {
  const { campaignTitle, lines, onClose, onSubmit } = props
  const isMobile = useSelector(state => state.window.width <= 600)
  const [user, setUser] = useState('')
  const [shouldAllowShare, setShouldAllowShare] = useState(true)

  return (
    <div>
      <Dialog
        scroll={'paper'}
        open={true}
        onClose={onClose}
        BackdropProps={{ style: { backdropFilter: 'blur(4px)' } }}
        PaperProps={{ style: isMobile ? { margin: '32px 5px' } : {} }}
      >
        <DialogTitle disableTypography={true}>
          <div style={{ display: 'flex', color: '#333' }}>
            <Text type="caption" color="primaryHeading">
              Share campaign:{' '}
            </Text>
            <Spacer space="x1" orientation="horizontal" />
            <Text type="caption" weight="bold">
              {campaignTitle}
            </Text>
            <div style={{ marginLeft: 'auto' }}>
              <span onClick={onClose} style={{ cursor: 'pointer' }}>
                <Icon icon={'CLOSE'} color={'#6f8394'} size={18} />
              </span>
            </div>
          </div>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <Flex
            flexDirection={isMobile ? 'column' : 'row'}
            columnGap="x2"
            height="100%"
          >
            <Flex flexGrow={1} flexShrink={1}>
              <div style={{ margin: '0 auto' }}>
                <CampaignLineCard title={campaignTitle} lines={lines} />
              </div>
            </Flex>
            <Flex
              flexGrow={1}
              flexShrink={1}
              flexDirection="column"
              inset={{ vertical: 'x1_5' }}
            >
              <Flex flexDirection="column">
                <Text type="largeBody" color="primaryHeading">
                  Campaign Details:
                </Text>
                <ul
                  className={styles.campaignDetails}
                  style={{ marginBottom: '15px' }}
                >
                  <li style={{ marginLeft: '1.5rem' }}>
                    <Text type="body">
                      Touches:{' '}
                      {lines?.length
                        ? lines?.length
                        : 'This campaign has no cards'}
                    </Text>
                  </li>
                </ul>
              </Flex>

              <Flex flexDirection="column" rowGap="x1_5" flexGrow={2}>
                <div>
                  <Text type="caption" content="Share with:" />
                  <Input
                    name={'username'}
                    value={user}
                    placeholder={'Username'}
                    onChange={value => setUser(value)}
                    type="text"
                    isFullWidth={true}
                  />
                </div>
                <div className={styles.cb}>
                  <Checkbox
                    isChecked={shouldAllowShare}
                    id="campaign-share-allow-share-cb"
                    onChange={() => setShouldAllowShare(!shouldAllowShare)}
                    type="primary"
                    label="Allow user to share this campaign"
                  />
                </div>
                <Flex justifyContent="flex-end" outset={{ top: 'auto' }}>
                  <Button
                    title={'Share'}
                    onClick={() => onSubmit(user, shouldAllowShare)}
                    isDisabled={!user}
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </DialogContent>
      </Dialog>
    </div>
  )
}
export default CampaignShare
