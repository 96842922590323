import gradients from './gradients'
import colors from './colors'
import { CSSProperties } from 'react'

const buttonColors: ButtonColors = {
  orange: {
    backgroundImage: gradients.orange,
    color: colors.white,
  },
  pink: {
    backgroundImage: gradients.pink,
    boxShadow: '0 1px 15px 0 rgba(239, 146, 251, 0.3)',
    color: colors.white,
  },
  blue: {
    backgroundImage: gradients.blue,
    boxShadow: '0 2px 9px -4px rgb(23, 130, 234)',
    color: colors.white,
  },
  facebook: {
    backgroundImage: gradients.facebook,
    boxShadow: '0 3px 10px rgba(48, 58, 157, 0.2)',
    color: colors.white,
  },
  bella: {
    backgroundImage: gradients.bella,
    boxShadow: '0 3px 10px rgb(82, 90, 89)',
    color: colors.white,
  },
  blue42: {
    backgroundImage: gradients.blue42,
    boxShadow: '0 3px 10px rgb(53, 164, 188)',
    color: colors.white,
  },
  greenerstill: {
    backgroundImage: gradients.greenerstill,
    boxShadow: '0 3px 10px rgb(82, 193, 209)',
    color: colors.white,
  },
  promptingu: {
    backgroundImage: gradients.promptingu,
    boxShadow: '0 3px 10px rgb(169, 154, 103)',
    color: colors.white,
  },
  streammrkt: {
    backgroundImage: gradients.streammrkt,
    boxShadow: '0 3px 10px rgb(49, 74, 81)',
    color: colors.white,
  },
  streamva: {
    backgroundImage: gradients.streammrkt,
    boxShadow: '0 3px 10px rgb(31, 65, 119)',
    color: colors.white,
  },
  bellaPrimary: {
    backgroundImage: gradients.bellaPrimary,
    boxShadow: '0 3px 10px rgb(206, 142, 71)',
    color: colors.white,
  },
  blue42Primary: {
    backgroundImage: gradients.blue42Primary,
    boxShadow: '0 3px 10px rgb(20, 15, 238)',
    color: colors.white,
  },
  greenerstillPrimary: {
    backgroundImage: gradients.greenerstillPrimary,
    boxShadow: '0 3px 10px rgb(116, 196, 104)',
    color: colors.white,
  },
  promptinguPrimary: {
    backgroundImage: gradients.promptinguPrimary,
    boxShadow: '0 3px 10px rgb(31, 65, 119)',
    color: colors.white,
  },
  streammrktPrimary: {
    backgroundImage: gradients.streammrktPrimary,
    boxShadow: '0 3px 10px rgb(179, 128, 72)',
    color: colors.white,
  },
  streamvaPrimary: {
    backgroundImage: gradients.streammrktPrimary,
    boxShadow: '0 3px 10px rgb(97, 220, 232)',
    color: colors.white,
  },
}

export interface ButtonColors {
  orange: CSSProperties
  pink: CSSProperties
  blue: CSSProperties
  facebook: CSSProperties
  bella: CSSProperties
  blue42: CSSProperties
  greenerstill: CSSProperties
  promptingu: CSSProperties
  streammrkt: CSSProperties
  streamva: CSSProperties
  bellaPrimary: CSSProperties
  blue42Primary: CSSProperties
  greenerstillPrimary: CSSProperties
  promptinguPrimary: CSSProperties
  streammrktPrimary: CSSProperties
  streamvaPrimary: CSSProperties
}

export type ButtonColorChoices = keyof ButtonColors

export default buttonColors
