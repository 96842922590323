import React from 'react'
import { Set } from 'immutable'

import { Button, Modal } from 'src/chrome'
import { useLoading, useQueries, useState } from 'src/hooks'
import {
  getDetailedCountries,
  RelatedContactFragment,
  UpdateContactInput,
} from 'src/legacy_graphql'

import BasicAddressForm from '../forms/BasicAddressForm'
import ContactNumbersForm from '../forms/ContactNumbersForm'
import DateDropdown from '../DateDropdown/DateDropdown'
import NameForm from '../forms/NameForm'
import SectionTitle from '../SectionTitle'
import ContactCheckbox from '../ContactCheckbox'
import styles from '../ContactDetails/contactDetails.module.scss'
import {
  Anchor,
  Div,
  Separator,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { formatCountriesAndRegions } from 'src/helpers/formatCountriesAndRegions'

const field = {
  style: { marginTop: 15 },
  floatingLabelFixed: true,
  floatingLabelStyle: {
    color: 'black',
    fontWeight: 'bold',
  } as React.CSSProperties,
}

const { style, ...labels } = field

type Contact = Pick<
  UpdateContactInput,
  | 'id'
  | 'address1'
  | 'address2'
  | 'postalCode'
  | 'state'
  | 'city'
  | 'country'
  | 'birthday'
  | 'anniversary'
>

type Props<T extends Contact> = {
  contact: T
  isMinimal: boolean
  onCancel: () => void
  onSave: (
    contact: T,
    additionalContactToUpdate: string[],
    onSaved: () => void,
  ) => void
  linkedContacts?: RelatedContactFragment[]
}

export type OptionItems = {
  value: string
  label: string
  hasSeparator?: boolean
}

const ContactDetails = <T extends Contact>(props: Props<T>) => {
  const { onCancel, onSave, linkedContacts } = props

  const [contact, setContact] = useState(props.contact)
  const loading = useLoading()
  const [isMinimal, setIsMinimal] = useState(props.isMinimal)
  const [additionalContactToUpdate, setAdditionalContactToUpdate] = useState(
    Set<string>(),
  )
  const [detailedCountries] = useQueries(getDetailedCountries())
  const {
    countries,
    regions,
    isRegionsUnavailable,
  } = formatCountriesAndRegions(
    detailedCountries,
    contact.country ?? 'United States',
  )

  const isInvalidRegion = !isRegionsUnavailable
    ? contact.state
      ? !regions?.includes(contact.state)
      : true
    : false

  const handleChange = (update: Partial<T>) =>
    setContact(x => ({
      ...x,
      ...update,
    }))

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    e.stopPropagation()
    loading.start()

    onSave(
      {
        ...contact,
        state: isRegionsUnavailable ? '' : contact.state,
      },
      [...additionalContactToUpdate.values()],
      () => {
        loading.end()
      },
    )
  }

  const didContactChange =
    props.contact.address1 !== contact.address1 ||
    props.contact.address2 !== contact.address2 ||
    props.contact.postalCode !== contact.postalCode ||
    props.contact.state !== contact.state ||
    props.contact.city !== contact.city ||
    props.contact.country !== contact.country

  return (
    <form onSubmit={handleSubmit}>
      <Modal
        bodyStyles={{
          minHeight: '65vh',
        }}
        title={contact.id ? 'Edit Contact' : 'Create Contact'}
        onClose={onCancel}
        bodyChildren={
          <Div outset={{ bottom: 'x4' }}>
            <Div>
              {isInvalidRegion && (
                <Div outset={{ horizontal: '-8px' }}>
                  <Text type="body" weight="bold" color="danger">
                    Almost ready!
                  </Text>
                  <Separator orientation="horizontal" />
                  <Spacer orientation="vertical" space="x1_5" />
                  <Text type="body">
                    To ensure your order arrives at the correct address without
                    any delays when sending to this contact, please update the
                    information below to the necessary format. To do this,
                    select from the State drop-down and choose from the list.
                  </Text>
                </Div>
              )}

              <section>
                <SectionTitle title={'Name'} />
                <NameForm<T>
                  contact={contact}
                  onChange={handleChange}
                  fields={field}
                  isCompanyVisible={!isMinimal}
                />
              </section>

              {!isMinimal && (
                <section style={{ marginTop: '3rem' }}>
                  <SectionTitle title={'Address'} />
                  <BasicAddressForm
                    contact={contact}
                    fields={field}
                    countries={countries}
                    onChange={handleChange}
                    regions={regions}
                    isInvalidRegion={isInvalidRegion}
                    isRegionsUnavailable={isRegionsUnavailable}
                  />
                  {didContactChange &&
                    linkedContacts &&
                    linkedContacts.length > 0 && (
                      <div>
                        <Text type="body">
                          Select related contacts to update address
                        </Text>
                        {linkedContacts.map(linkedContact => (
                          <ContactCheckbox
                            key={linkedContact.id}
                            contact={linkedContact}
                            isChecked={additionalContactToUpdate.has(
                              linkedContact.id,
                            )}
                            onCheck={() =>
                              setAdditionalContactToUpdate(
                                additionalContactToUpdate.has(linkedContact.id)
                                  ? additionalContactToUpdate.delete(
                                      linkedContact.id,
                                    )
                                  : additionalContactToUpdate.add(
                                      linkedContact.id,
                                    ),
                              )
                            }
                          />
                        ))}
                      </div>
                    )}
                </section>
              )}

              {!isMinimal && (
                <section style={{ marginTop: '3rem' }}>
                  <SectionTitle title={'Contact'} />
                  <ContactNumbersForm
                    contact={contact}
                    fields={field}
                    onChange={handleChange}
                  />
                </section>
              )}

              <section style={{ marginTop: '3rem' }}>
                <SectionTitle title={'Important Dates'} />
                <div style={{ marginTop: '1rem' }}>
                  <DateDropdown
                    label="Birthday"
                    initialValue={contact.birthday}
                    onChange={date =>
                      handleChange({ birthday: date } as Partial<T>)
                    }
                    fields={labels}
                  />
                  <DateDropdown
                    label="Anniversary"
                    initialValue={contact.anniversary}
                    onChange={date =>
                      handleChange({ anniversary: date } as Partial<T>)
                    }
                    fields={labels}
                  />
                </div>
              </section>

              {isMinimal && (
                <div className={styles.actions}>
                  <Anchor
                    onClick={() => setIsMinimal(false)}
                    isDecorated={true}
                    color="primaryBrand"
                  >
                    Show All Fields
                  </Anchor>
                </div>
              )}
            </Div>
          </Div>
        }
        footerChildren={
          <div className={styles.footer} style={{ marginBottom: 75 }}>
            <Button
              type="button"
              title={'Cancel'}
              onClick={onCancel}
              disabled={loading.isLoading}
            />
            <Button
              id={'save_contact_btn'}
              type="submit"
              title={loading.isLoading ? 'Saving...' : 'Save'}
              buttonColor={'pink'}
              disabled={loading.isLoading || isInvalidRegion}
            />
          </div>
        }
      />
    </form>
  )
}

export default ContactDetails
