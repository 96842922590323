import React from 'react'

interface Props {
  label?: string
  containerStyle: React.CSSProperties
  readonly shouldStackContent?: boolean
  readonly isSplitBorderEnabled?: boolean
}

const SplitContentContainer: React.FC<Props> = props => {
  const {
    label,
    containerStyle,
    children,
    shouldStackContent = false,
    isSplitBorderEnabled = true,
  } = props
  return (
    <div
      css={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-evenly',
      }}
      style={
        shouldStackContent
          ? { ...containerStyle, flexDirection: 'column', alignItems: 'center' }
          : containerStyle
      }
    >
      {label && (
        <div
          style={{
            position: 'absolute',
            top: '0',
            right: '0',
            left: '0',
            marginRight: 'auto',
            marginLeft: 'auto',
            width: 'fit-content',
            padding: '0px 30px',
            borderRadius: '0 0 6px 6px',
            background: 'linear-gradient(111deg, #f237ef, #fc5252)',
          }}
        >
          <p
            style={{
              fontSize: '18px',
              fontWeight: 500,
              color: '#fff',
              margin: 0,
              padding: 0,
            }}
          >
            {label}
          </p>
        </div>
      )}
      {React.Children.map(children, child => (
        <div
          css={{
            position: 'relative',
            '&:not(:last-child):before': {
              content: "''",
              borderRight:
                !shouldStackContent && isSplitBorderEnabled
                  ? '1px solid #f237ef'
                  : '',
              position: 'absolute',
              height: '65%',
              right: '0',
              top: '25%',
            },
          }}
          style={
            !shouldStackContent
              ? { width: `${100 / React.Children.count(children)}%` }
              : {}
          }
        >
          {child}
        </div>
      ))}
    </div>
  )
}

export default SplitContentContainer
