import { Operation } from '../../../__generated__/globalTypes'

import {
  MinimalOrderFragment,
  minimalOrderFragmentString,
} from '../../fragments/__generated__/MinimalOrderFragment'

import getDraftedOrdersRawString from '../GetDraftedOrders.graphql'

const getDraftedOrdersString = [
  getDraftedOrdersRawString,
  minimalOrderFragmentString,
].join('\n\n')

export type GetDraftedOrdersData = {
  paginatedOrders: {
    orders: MinimalOrderFragment[]
    count: number
  }
}

export type GetDraftedOrdersVariables = {
  offset: number
  limit: number
}

export type GetDraftedOrders = Operation<
  GetDraftedOrdersData,
  GetDraftedOrdersVariables
>

export const GetDraftedOrders = ({
  offset,
  limit,
}: GetDraftedOrdersVariables): GetDraftedOrders => ({
  query: getDraftedOrdersString,
  variables: {
    offset,
    limit,
  },
})
