import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
// @src imports
import { getInfiniteGifts, GiftFragment } from 'src/legacy_graphql'
import suspenseBoundary from 'src/chrome/SuspenseBoundary/suspenseBoundaryHOC'
import { Icon } from 'src/chrome'

import GiftTiles from '../GiftTiles/GiftTiles'
import MoreIcon from '../MoreIcon/MoreIcon'
import NotFound from '../NotFound/NotFound'
import styles from './giftRibbon.module.scss'
import { Text } from '@sendoutcards/quantum-design-ui'
import { useQueries } from 'src/hooks'

interface Props {
  id?: number
  name: string
  categoryId?: string
  shouldWrapContent?: boolean
  viewGift: (slug: string) => void
  addGift: (gift: GiftFragment) => void
  search?: string
  onSelect?: () => void
  onBack?: () => void
  isChangingGift: boolean
  isEligibleFreeGift?: boolean
}

const GiftRibbon: React.FC<Props> = props => {
  const {
    onSelect,
    id,
    addGift,
    categoryId,
    name,
    shouldWrapContent,
    viewGift,
    search,
    onBack,
    isChangingGift,
    isEligibleFreeGift,
  } = props

  const [{ results: gifts, isLoadingMore, hasMore, loadMore }] = useQueries(
    getInfiniteGifts({
      category: categoryId,
      search,
      isEligibleFreeGift,
    }),
  )

  return (
    <div
      className={`${styles.GiftRibbon} ${
        shouldWrapContent && styles.gridPadding
      }`}
    >
      {shouldWrapContent && onBack && (
        <div className={styles.closeCollection} onClick={() => onBack()}>
          <Icon icon={'CARROTLEFT'} size={12} color={''} />
          <Text type="largeBody" color="primaryHeading">
            BACK
          </Text>
        </div>
      )}
      <div className={styles.ribbonInfo}>
        <div onClick={() => onSelect?.()} style={{ cursor: 'pointer' }}>
          <Text type="largeBody" color="primaryHeading">
            {name}
          </Text>
        </div>
        {!shouldWrapContent && hasMore && <MoreIcon onClick={onSelect} />}
      </div>
      {gifts.length === 0 && <NotFound />}
      <div
        className={styles.ribbonCards}
        style={
          shouldWrapContent
            ? { flexWrap: 'wrap', justifyContent: 'center' }
            : {}
        }
      >
        <GiftTiles
          isChangingGift={isChangingGift}
          id={id}
          viewGift={viewGift}
          addGift={addGift}
          gifts={gifts}
          isLoadingMore={isLoadingMore}
          hasMore={hasMore}
          loadMore={loadMore}
        />
      </div>
    </div>
  )
}

const Unresolved = () => (
  <div style={{ background: 'transparent', boxShadow: 'none' }}>
    <CircularProgress />
  </div>
)

export default suspenseBoundary({
  component: GiftRibbon,
  failure: error => `Failed loading gifts: ${error}`,
  unresolved: <Unresolved />,
})
