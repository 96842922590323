import React from 'react'
// @src imports
import { Button } from 'src/chrome'
import {
  selectedColor,
  selectedFontId,
  selectedFontSize,
  selectedTextAlign,
} from 'src/redux/selectors/text'
import FontList from 'src/editor/components/FontList/FontList'
import { defaultTextSelection } from 'src/editor/text/types'
import { textEditor } from 'src/helpers'
// relative imports
import { defaultFragment } from 'src/editor/text/ElementText'
import ColorPicker from '../ColorPicker/ColorPicker'
import FontSizeSlider from '../FontSizeSlider/FontSizeSlider'
import InsertNames from '../InsertNames/InsertNames'
import InsertSignature from '../InsertSignature'
import TextAlignmentComponent from '../TextAlignment/TextAlignment'
import { Api } from '../../api'
import { Steps } from '../../types'
import styles from './textControls.module.scss'
import { Flex } from '@sendoutcards/quantum-design-ui'

type TextControlsProps = {
  onRemoveElement?: () => void
  api: Api & { step: { type: Steps.EditText } }
}
const TextControls: React.FC<TextControlsProps> = props => {
  const { onRemoveElement, api } = props
  const {
    step,
    getSelectedElement,
    updateText,
    resetStep,
    canShowBulkOrderOptions,
  } = api
  const selectedElementText = getSelectedElement(step)?.text
  const textStateText =
    selectedElementText !== undefined &&
    selectedElementText !== null &&
    selectedElementText.length > 0
      ? selectedElementText
      : [defaultFragment]

  const textState = {
    selection: step.textSelection ?? defaultTextSelection,
    text: textStateText,
  }

  const textAlign = selectedTextAlign(textState)
  const fontSize = selectedFontSize(textState)
  const fontId = selectedFontId(textState)
  const color = selectedColor(textState)

  const utils = textEditor(updateText, step, api.isMobile)

  return (
    <div className={styles.textControlBar}>
      <div className={styles.controlsWrapper}>
        <div className={styles.control}>
          <h6>Tools</h6>
          <TextAlignmentComponent
            textAlign={textAlign}
            selectTextAlign={utils.updateTextAlign}
          />
          <FontSizeSlider
            fontSize={fontSize}
            selectFontSize={fontSize => utils.updateProperties({ fontSize })}
          />
        </div>
        <div className={styles.control}>
          <h6>Typography</h6>
          <FontList
            fonts={api.fonts}
            fontId={fontId}
            selectFont={fontId => utils.updateProperties({ fontId })}
          />
        </div>
        <div className={styles.control}>
          <h6>Color</h6>
          <ColorPicker
            selectedColor={color}
            selectColor={color => utils.updateProperties({ color })}
            removeColor={() =>
              utils.updateProperties({
                color: { __typename: 'Color', red: 0, green: 0, blue: 0 },
              })
            }
            designColors={api.designColors}
          />
        </div>
        <div className={styles.control}>
          {canShowBulkOrderOptions ? (
            <Flex width="100%">
              <h6>Insert</h6>
              <p className={styles.sidenote}>
                Name insert feature is not available with bulk orders.
              </p>
            </Flex>
          ) : (
            <h6>Insert</h6>
          )}
          <InsertNames insertPlaceholder={utils.insertPlaceholder} />
          <InsertSignature api={api} signatures={api.fonts.signatures} />
        </div>
        {onRemoveElement && (
          <div className={styles.control}>
            <h6>Textbox</h6>
            <Button
              title="Remove"
              style={{ margin: 'auto' }}
              icon={'TRASH'}
              iconSize={10}
              iconColor={'#E841B3'}
              onClick={onRemoveElement}
            />
          </div>
        )}
      </div>
      <div className={styles.buttonFlexBlock}>
        <Button
          id={'text_controls_done_btn'}
          title="Done"
          buttonColor={'blue'}
          onClick={resetStep}
        />
      </div>
    </div>
  )
}

export default TextControls
