import { Operation } from '../../../__generated__/globalTypes'

import {
  SponsorFragment,
  sponsorFragmentString,
} from '../../fragments/__generated__/SponsorFragment'

import { storefrontFragmentString } from '../../fragments/__generated__/StorefrontFragment'

import { minimalSponsorFragmentString } from '../../fragments/__generated__/MinimalSponsorFragment'

import getPossibleSponsorsRawString from '../GetPossibleSponsors.graphql'

const getPossibleSponsorsString = [
  getPossibleSponsorsRawString,
  sponsorFragmentString,
  storefrontFragmentString,
  minimalSponsorFragmentString,
].join('\n\n')

export type GetPossibleSponsorsData = {
  account: {
    possibleSponsors: SponsorFragment[]
  }
}

export type GetPossibleSponsors = Operation<GetPossibleSponsorsData>

export const GetPossibleSponsors = (): GetPossibleSponsors => ({
  query: getPossibleSponsorsString,
  variables: undefined,
})
