import React from 'react'
import { OrderApiType } from 'src/orders/api'
import OrderContextBar from 'src/orders/components/OrderContextBar/OrderContextBar'
import { MiniCartProps } from 'src/orders/components/MiniCart/MiniCart'

interface Props {
  onSave: () => void
  saveButtonLabel: string
  saveButtonBackground?: string
  saveButtonColor?: string
  label?: JSX.Element | null
  secondaryAction?: {
    label: string
    onClick: () => void
  }
  miniCartProps?: MiniCartProps
  orderApi?: OrderApiType
}

const EditorFooterContents: React.FC<Props> = props => {
  const {
    label,
    saveButtonLabel,
    saveButtonBackground,
    saveButtonColor,
    onSave,
    secondaryAction,
    miniCartProps,
  } = props
  const saveAction = secondaryAction && {
    id: 'editor_secondary_action_btn',
    label: secondaryAction.label,
    onClick: secondaryAction.onClick,
  }
  const extraActions = [
    {
      id: 'editor_primary_action_btn',
      label: saveButtonLabel,
      onClick: onSave,
      backgroundColor: saveButtonBackground,
      textColor: saveButtonColor,
    },
  ]

  return (
    <OrderContextBar
      miniCart={miniCartProps}
      nodeLabel={label}
      saveAction={saveAction}
      extraActions={extraActions}
    />
  )
}

export default EditorFooterContents
