import React from 'react'
import Tab from '@material-ui/core/Tab'
import MaterialTabs from '@material-ui/core/Tabs'

import suspenseBoundary from 'src/chrome/SuspenseBoundary/suspenseBoundaryHOC'
import { getGiftCategories } from 'src/legacy_graphql'
import { useQueries } from 'src/hooks'
import { Text } from '@sendoutcards/quantum-design-ui'

interface Props {
  value?: string
  onChange: (value: string) => void
}

const Tabs: React.FC<Props> = props => {
  const { value, onChange } = props

  const [categories] = useQueries(getGiftCategories())

  return (
    <MaterialTabs
      value={value}
      style={{ overflowY: 'auto' }}
      onChange={(e, value) => onChange(value)}
    >
      {categories.map(category => (
        <Tab
          id={category.name.replace(/\s/g, '_')}
          key={category.id}
          label={<Text type="body">{category.name}</Text>}
          value={category.id}
          style={{ fontSize: '1em' }}
        />
      ))}
    </MaterialTabs>
  )
}

export default suspenseBoundary({
  component: Tabs,
  unresolved: <MaterialTabs value={undefined} />,
  failure: error => 'Failed loading categories',
})
