import { Maybe } from '../../../__generated__/globalTypes'

import { StorefrontFragment, isStorefrontFragment } from './StorefrontFragment'

import coachFragmentRawString from '../CoachFragment.graphql'

export const coachFragmentString = coachFragmentRawString

export type CoachFragment = {
  __typename: 'User'
  id: string
  firstName: string
  lastName: string
  email: string
  formattedPhoneNumber: string
  genealogyId: number
  dateJoined: string
  storefront: Maybe<StorefrontFragment>
  promptingsCoach: Maybe<{
    isEmailPublic: boolean
    isPhoneNumberPublic: boolean
  }>
}

export const isCoachFragment = (fragment: any): fragment is CoachFragment =>
  fragment &&
  fragment.__typename === 'User' &&
  typeof fragment.id === 'string' &&
  typeof fragment.firstName === 'string' &&
  typeof fragment.lastName === 'string' &&
  typeof fragment.email === 'string' &&
  typeof fragment.formattedPhoneNumber === 'string' &&
  typeof fragment.genealogyId === 'number' &&
  typeof fragment.dateJoined === 'string' &&
  (fragment.storefront === null ||
    (isStorefrontFragment(fragment.storefront) as boolean)) &&
  (fragment.promptingsCoach === null ||
    (fragment.promptingsCoach &&
      typeof fragment.promptingsCoach.isEmailPublic === 'boolean' &&
      typeof fragment.promptingsCoach.isPhoneNumberPublic === 'boolean'))
