import { Flex } from '@sendoutcards/quantum-design-ui'
import {
  HOCSpacingKeys,
  LiteralUnion,
  SpacingDirection,
} from '@sendoutcards/quantum-design-ui/dist/src/helpers/hoc-types/entityValueTypes'
import { BorderRadius } from '@sendoutcards/quantum-design-ui/dist/src/helpers/hoc-types/hocBaseUnion'
import React from 'react'

type OrderDetailsSectionType = {
  invertBackgroundColor?: boolean
  borderRadius?: BorderRadius
  outset?: SpacingDirection | LiteralUnion<HOCSpacingKeys>
  inset?: SpacingDirection | LiteralUnion<HOCSpacingKeys>
}

export const OrderDetailsSection: React.FC<OrderDetailsSectionType> = ({
  invertBackgroundColor,
  children,
  borderRadius = '40px',
  outset,
  inset,
}) => {
  return (
    <Flex
      outset={outset}
      inset={inset}
      justifyContent="center"
      alignItems="center"
      borderRadius={borderRadius}
      width="100%"
      height="fit-content"
      backgroundColor={invertBackgroundColor ? 'foreground' : '#F2F2F2'}
    >
      {children}
    </Flex>
  )
}
