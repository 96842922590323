import { Maybe } from '../../../__generated__/globalTypes'

import optionalYearDateFragmentRawString from '../OptionalYearDateFragment.graphql'

export const optionalYearDateFragmentString = optionalYearDateFragmentRawString

export type OptionalYearDateFragment = {
  __typename: 'OptionalYearDate'
  day: number
  month: number
  year: Maybe<number>
}

export const isOptionalYearDateFragment = (
  fragment: any,
): fragment is OptionalYearDateFragment =>
  fragment &&
  fragment.__typename === 'OptionalYearDate' &&
  typeof fragment.day === 'number' &&
  typeof fragment.month === 'number' &&
  (fragment.year === null || typeof fragment.year === 'number')
