import { Entry } from 'src/chrome/SliderConfig/SliderConfig'
import { CardFragment } from 'src/graphql/generated/graphql'
import { compact } from 'src/helpers'

export type CardDimensions = Pick<
  CardFragment,
  | 'id'
  | 'isHorizontal'
  | 'type'
  | 'frontPreviewUrl'
  | 'flapPreviewUrl'
  | 'backPreviewUrl'
  | 'insidePreviewUrl'
> & {
  panels: Array<{
    width: CardFragment['panels'][0]['width']
    height: CardFragment['panels'][0]['height']
  }>
}

const getInfoIndex = (type: string) => {
  if (type === 'THREE_PANEL') {
    return 1
  } else if (type === 'TWO_PANEL' || type === 'TWO_PANEL_BIG') {
    return 0
  } else if (type === 'FLATCARD' || type === 'POSTCARD') {
    return 2
  }
  return -1
}

const getDimensions = (card: CardDimensions) => {
  const { width, height } = card.panels[0]
  return {
    height: card.type === 'TWO_PANEL_BIG' ? height / 2 : height,
    width: card.type === 'TWO_PANEL_BIG' ? width / 2 : width,
  }
}

const getScaledDimensions = (card: CardDimensions) => {
  const { width, height } = getDimensions(card)
  return card.isHorizontal
    ? { width: height, height: height * (height / width) }
    : { width, height }
}

export const cardPreviewConfig = (card: CardDimensions): Entry[] => {
  const portraitInsideNames = [
    ['Inside Left', 'Inside Right'],
    ['Inside Left', 'Inside Middle', 'Inside Right'],
    ['Back'],
  ]
  const horizontalInsideNames = [
    ['Inside Top', 'Inside Bottom'],
    ['Inside Top', 'Inside Middle', 'Inside Bottom'],
    ['Back'],
  ]
  const multiplier = [2, 3, 1]

  const { height, width } = getScaledDimensions(card)

  const infoIndex = getInfoIndex(card.type)

  const isHorizontal = card.isHorizontal
  const hasInside = card.type !== 'FLATCARD' && card.type !== 'POSTCARD'
  const isPostcard = card.type === 'POSTCARD'
  const isThreePanel = card.type === 'THREE_PANEL'

  return compact(
    {
      previewUrl: card.frontPreviewUrl,
      names: ['Front'],
      numSections: 1,
      width,
      height,
    },
    hasInside && {
      names: card.isHorizontal
        ? horizontalInsideNames[infoIndex]
        : portraitInsideNames[infoIndex],
      previewUrl: card.insidePreviewUrl ?? undefined,
      width: card.isHorizontal ? width : width * multiplier[infoIndex],
      height: card.isHorizontal ? height * multiplier[infoIndex] : height,
      numSections: multiplier[infoIndex] || 1,
    },
    isThreePanel && {
      previewUrl: card.flapPreviewUrl ?? undefined,
      names: ['Flap'],
      numSections: 1,
      width,
      height,
    },
    (!isHorizontal && isPostcard) || (isHorizontal && hasInside)
      ? {
          previewUrl: card.backPreviewUrl,
          names: ['Back'],
          numSections: 1,
          height: width,
          width: height,
        }
      : {
          previewUrl: card.backPreviewUrl,
          names: ['Back'],
          numSections: 1,
          width,
          height,
        },
  )
}
