import { Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { useSelector } from 'src/hooks'
import { TrendTriangle } from '../svg/trendTriangle'

export type LeaderBoardListHeaderType = {
  title: string
  placementTitle?: string
  metricDescription: string
}
const LeaderBoardListHeader: React.FC<LeaderBoardListHeaderType> = ({
  title,
  metricDescription,
  placementTitle = 'Rank',
}) => {
  const isSmallMobile = useSelector(state => state.window.width < 475)

  return (
    <Flex
      width="100%"
      inset="x2"
      minWidth="280px"
      style={{ paddingBottom: '4px' }}
    >
      <Flex justifyContent="center" alignItems="center">
        <Text
          content={placementTitle}
          type="caption"
          weight="semiBold"
          color="inverseHeading"
          style={{ letterSpacing: '2%', color: '#C2B7D5' }}
        />
      </Flex>
      <Flex width="100%" justifyContent="center" alignItems="center">
        <Text
          content={title}
          type={isSmallMobile ? 'caption' : 'body'}
          weight="bold"
          style={{ color: '#000000', marginRight: 8 }}
          lineHeight={1.2}
        />
        <TrendTriangle width={12} height={14} trend="up" />
        <TrendTriangle width={12} height={14} trend="down" />
      </Flex>
      <Flex
        justifyContent="center"
        alignItems="center"
        outset={{ right: 'x1' }}
      >
        <Text
          content={metricDescription}
          type="caption"
          weight="semiBold"
          color="inverseHeading"
          style={{ letterSpacing: '2%', color: '#C2B7D5' }}
        />
      </Flex>
    </Flex>
  )
}

export default LeaderBoardListHeader
