import { Div, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'

import { Icon, SplitContentContainer } from 'src/chrome'
import { useSelector } from 'src/hooks'
import { FlexColumn } from 'src/styled'

import planContentStyles from '../PlanContentsGrid/planContentsGrid.module.scss'

const styles = {
  column: {
    width: '50%',
    alignItems: 'center',
    margin: '15px auto',
  },
  iconWrapper: {
    width: 80,
    marginBottom: 20,
  },
}

const WaysToSend: React.FC = () => {
  const { width } = useSelector(state => state.window)

  const baseIconProps = {
    color: '#9C9CA0',
    size: 80,
  }

  return (
    <Div
      className={planContentStyles.featureGridContainer}
      style={{
        padding: '85px 0 135px 0',
      }}
    >
      <Div className={planContentStyles.featureGrid}>
        <Text type="subtitle" weight="bold">
          2 Ways to Send:
        </Text>
        <Spacer space="x7" />
        <SplitContentContainer
          isSplitBorderEnabled={false}
          containerStyle={{ width: '100%' }}
          shouldStackContent={(width || 1000) <= 800}
        >
          <FlexColumn css={styles.column}>
            <Div css={styles.iconWrapper}>
              <Icon
                {...baseIconProps}
                icon={'CATALOGCARD'}
                viewBox={'0 0 32 32'}
              />
            </Div>
            <Text type="largeBody" color="primaryHeading" weight="semiBold">
              Heartfelt Prompting Cards
            </Text>
            <Spacer space="x2" />
            <Div>
              <Text type="caption">
                A card with a unique heartfelt message sent to a single
                recipient with an immediate send date.
              </Text>
              <Spacer space="x2" />
              <Text type="footnote">Subject to fair use policy.</Text>
            </Div>
          </FlexColumn>
          <FlexColumn css={styles.column}>
            <Div css={styles.iconWrapper}>
              <Icon {...baseIconProps} icon={'GROUP'} viewBox={'0 0 60 30'} />
            </Div>
            <Text type="largeBody" color="primaryHeading" weight="semiBold">
              System Cards
            </Text>
            <Spacer space="x2" />
            <Div>
              <Text type="caption">• A card sent to multiple recipients</Text>
              <Text type="caption">
                • A card set with a future send date to one or more recipients
              </Text>
              <Text type="caption">
                • A card sent with Campaigns and Multiple-Touch Campaigns
              </Text>
            </Div>
          </FlexColumn>
        </SplitContentContainer>
      </Div>
    </Div>
  )
}

export default WaysToSend
