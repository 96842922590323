import { AccountFragment } from 'src/graphql/generated/graphql'
import { useCallback, useQueryParams, useVertical } from '.'

const useRedirectUriBuilder = () => {
  const vertical = useVertical()
  const { redirect_uri: redirectUri } = useQueryParams()

  return useCallback(
    (account: AccountFragment) => {
      if (vertical.id === 'promptingsacademy' && account.promptinguLoginUrl) {
        return account.promptinguLoginUrl
      }

      if (!redirectUri) {
        return undefined
      }

      const url = new URL(decodeURIComponent(redirectUri))

      if (vertical.id === 'promptings') {
        url.searchParams.append(
          account.isAffiliate ? 'repDID' : 'customerDID',
          account.genealogyId.toString(),
        )
      }

      return url.toString()
    },
    [vertical.id, redirectUri],
  )
}

export default useRedirectUriBuilder
