import React from 'react'
import {
  Anchor,
  Button,
  Flex,
  Icon,
  Text,
  Transition,
  TransitionProps,
} from '@sendoutcards/quantum-design-ui'
import { RequestEmailVerificationCodeData } from 'src/legacy_graphql'
import { useMutations, useState } from 'src/hooks'
import { parseError } from 'src/utils/parseError'

type RequestEmailVerificationProps = {
  onComplete: () => void
  onAlreadyRecieved?: () => void
  email: string
}

export const RequestEmailVerification = (
  props: RequestEmailVerificationProps,
) => {
  const { onComplete, email, onAlreadyRecieved } = props
  const [transition, setTransition] = useState<TransitionProps>({
    title: '',
    isLoading: false,
  })
  const mutations = useMutations()

  const requestVerification = async () => {
    setTransition({
      title: 'Sending code to',
      isLoading: true,
    })
    try {
      const {
        requestEmailVerificationCode,
      }: RequestEmailVerificationCodeData = await mutations.requestEmailVerificationCode(
        {
          email,
          doNotShowDefaultTransition: true,
        },
      )
      if (
        requestEmailVerificationCode.__typename ===
        'RequestEmailVerificationCodeSuccess'
      ) {
        setTransition({
          title: `Successfully sent code to ${email}`,
          isLoading: false,
          loadedStatus: 'success',
        })
        setTimeout(() => {
          onComplete()
        }, 3000)
      } else {
        setTransition({
          title: requestEmailVerificationCode.message,
          isLoading: false,
          loadedStatus: 'error',
        })
      }
    } catch (error) {
      setTransition({
        title: parseError(error),
        isLoading: false,
        loadedStatus: 'error',
      })
    }
  }

  return (
    <Flex
      justifyContent="space-evenly"
      flexDirection="column"
      alignItems="center"
    >
      {transition.loadedStatus || transition.isLoading ? (
        <Transition {...transition} />
      ) : (
        <>
          <div style={{ width: '60px' }}>
            <Icon name="envelope" size="60px" primaryColor="primaryBrand" />
          </div>
          <Text
            type="subtitle"
            content={'Verify Email'}
            weight="bold"
            outset={{ top: 'x3', bottom: 'x1' }}
          />
          <Text type="largeBody" content={email} color="anchorBlue" />
          <Text
            type="largeBody"
            content="Click below to request a one-time verification code to be emailed to the email above. This code will expire in 10 minutes."
            outset={{ top: 'x1', bottom: 'x2' }}
          />
          <Flex outset={'x2'}>
            <Button
              title="Get Code"
              onClick={() => {
                requestVerification()
              }}
            />
          </Flex>
          {onAlreadyRecieved && (
            <Anchor
              isDecorated={true}
              size="xSmall"
              title="Already received a code?"
              onClick={() => onAlreadyRecieved()}
            />
          )}
        </>
      )}
    </Flex>
  )
}
