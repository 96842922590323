import React, { createContext } from 'react'
import { useEffect, useState } from 'src/hooks'

type CheckoutStore = 'campaigns' | 'pricing'

export type CheckoutContextState = {
  checkout: string | null
  store: CheckoutStore | null
}

export const initialCheckoutState: CheckoutContextState = {
  checkout: '',
  store: null,
}

export type CheckoutContext = {
  state: CheckoutContextState
}

export const CheckoutContext = createContext<CheckoutContext>({
  state: initialCheckoutState,
})

export const CheckoutContextProvider = (props: {
  children: React.ReactElement
}) => {
  const [state, setState] = useState({
    checkout: new URLSearchParams(window.location.search).get('checkout'),
    store: new URLSearchParams(window.location.search).get(
      'store',
    ) as CheckoutStore | null,
  })

  useEffect(() => {
    const handleLocationChange = () => {
      setState({
        checkout: new URLSearchParams(window.location.search).get('checkout'),
        store: new URLSearchParams(window.location.search).get(
          'store',
        ) as CheckoutStore | null,
      })
    }

    window.addEventListener('update_checkout', handleLocationChange)
    return () => {
      window.removeEventListener('update_checkout', handleLocationChange)
    }
  }, [])

  return (
    <CheckoutContext.Provider value={{ state }}>
      {props.children}
    </CheckoutContext.Provider>
  )
}
