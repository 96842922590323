import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'

import { FlexColumn } from 'src/styled'
import { dateFmtOptions } from 'src/helpers/dates'

interface DateFormatProps {
  onSelect: (value: string | null) => void
  value: string | null
}

const DateFormat: React.FC<DateFormatProps> = props => {
  const { onSelect, value } = props
  return (
    <FlexColumn style={{ padding: 10 }}>
      <p style={{ color: 'rgb(255, 86, 137)' }}>
        Please select/verify the date format that matches your birthday and
        anniversary data.
      </p>
      <TextField
        select={true}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          onSelect(event.target.value || null)
        }
        value={value ?? ''}
      >
        {dateFmtOptions.map((option, index) => (
          <MenuItem key={`${option}${index}`} value={option || undefined}>
            {option}
          </MenuItem>
        ))}
      </TextField>
    </FlexColumn>
  )
}

export default DateFormat
