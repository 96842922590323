import React from 'react'
import posed from 'react-pose'

import { Icon, ICONS, Icons, IconType, PoseFade } from 'src/chrome'
import { useCallback, useEffect, useRef, useState } from 'src/hooks'
import colors, { ColorChoices } from 'src/theme/colors'
import { createPropsGetter, wrappedInjectSheet } from 'src/helpers'
import { CSSClasses } from 'src/app/types'
import RoundIconButton from './RoundIconButton'
import { PosedReactComponent } from '../PoseFade/PoseFade'

// types __________________________________________________
type ComponentProps = {
  width: number | string
  currentIndex: number
  onRightArrowClick?: () => void
  onLeftArrowClick?: () => void
  iconRight?: Icons | IconType
  iconLeft?: Icons | IconType
  iconSize?: number
  iconColor?: ColorChoices
} & Partial<DefaultProps>

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps = {
  shouldShowArrows: true,
  arrowColor: 'pink' as ColorChoices,
  arrowIconColor: 'white' as ColorChoices,
  shouldSkipAnimation: false,
  arrowTop: '48%',
  shouldAlwaysShowArrows: false,
}

const getProps = createPropsGetter(defaultProps)

type Props = {
  classes: CSSClasses<typeof styles>
} & ComponentProps
// types __________________________________________________

// styles _________________________________________________
const styles = {
  wrapper: {
    overflow: 'hidden',
    position: 'relative',
    width: (props: Props) => getProps(props).width,
  },
  window: {
    display: 'flex',
    position: 'relative',
  },
  child: {
    width: (props: Props) => getProps(props).width,
    flexShrink: 0,
  },
  leftArrow: {
    position: 'absolute',
    left: 20,
    top: (props: Props) => getProps(props).arrowTop,
    cursor: (props: Props) => (props.currentIndex > 0 ? 'pointer' : 'default'),
  },
  rightArrow: {
    position: 'absolute',
    right: 20,
    top: (props: Props) => getProps(props).arrowTop,
    cursor: (
      props: Props & { children: React.ReactNode }, // TODO: fix the props here so they are right
    ) =>
      props.currentIndex < React.Children.count(props.children) - 1
        ? 'pointer'
        : 'cursor',
  },
}
// styles _________________________________________________

type AnimatorProps = {
  currentIndex: number
  childCount: number
  width: number | string
  fast: boolean
}

const getWidthComponents = (
  width: number | string,
): { width: number; stringPart?: string } => {
  if (typeof width === 'string') {
    const percentage = parseInt(width.replace('%', ''), 10)
    const vw = parseInt(width.replace('vw', ''), 10)
    const int = parseInt(width, 10)
    return {
      width: percentage || vw || int || 100,
      stringPart: percentage ? '%' : vw ? 'vw' : undefined,
    }
  } else {
    return { width }
  }
}

const getX = (props: AnimatorProps) => {
  const { width, stringPart } = getWidthComponents(props.width)
  const returnX = props.currentIndex * width * -1
  if (stringPart) {
    return returnX + stringPart
  }
  return returnX
}

const getTransition = (props: AnimatorProps) => {
  if (props.fast) {
    return {
      duration: 10,
    }
  }
  return {
    type: 'spring',
    damping: 15,
    stiffness: 100,
  }
}

const getAnimatorProps = (props: Props) => ({
  slideTo: {
    x: getX,
    transition: getTransition,
  },
  still: {
    x: getX,
    transition: getTransition,
  },
})

// component ______________________________________________
const ViewSlider: React.FC<Props> = props => {
  const { onLeftArrowClick, onRightArrowClick, currentIndex, children } = props

  const SlidePose = useRef<PosedReactComponent>(
    posed.div(getAnimatorProps(props)),
  ).current

  const [poseState, setPoseState] = useState<'still' | 'slideTo'>('still')

  const handleArrowClick = useCallback(
    (type: 'left' | 'right') => {
      if (type === 'left' && currentIndex > 0) {
        onLeftArrowClick && onLeftArrowClick()
      }
      if (
        type === 'right' &&
        currentIndex < React.Children.count(children) - 1
      ) {
        onRightArrowClick && onRightArrowClick()
      }
    },
    [onLeftArrowClick, onRightArrowClick, currentIndex, children],
  )

  useEffect(() => {
    setPoseState('slideTo')
    setTimeout(() => setPoseState('still'), 100)
  }, [props.currentIndex])

  const {
    classes,
    shouldShowArrows,
    width,
    arrowIconColor,
    arrowColor,
    shouldSkipAnimation,
    shouldAlwaysShowArrows,
    iconLeft,
    iconRight,
    iconSize,
    iconColor,
  } = getProps(props)
  return (
    <div className={classes.wrapper}>
      <SlidePose
        className={classes.window}
        pose={poseState}
        childCount={React.Children.count(children)}
        currentIndex={currentIndex}
        width={width}
        fast={shouldSkipAnimation}
        withParent={false}
      >
        {React.Children.map(children, (child, index) => {
          return (
            <div key={index} className={classes.child}>
              {child}
            </div>
          )
        })}
      </SlidePose>
      {shouldShowArrows && (
        <>
          <PoseFade
            isVisible={shouldAlwaysShowArrows ? true : currentIndex > 0}
          >
            <div className={classes.leftArrow}>
              {iconLeft ? (
                <Icon
                  icon={iconLeft}
                  onClick={() => handleArrowClick('left')}
                  size={iconSize}
                  color={iconColor ? colors[iconColor] : colors.mainText}
                />
              ) : (
                <RoundIconButton
                  icon={ICONS.CHEVRONLEFT}
                  onClick={() => handleArrowClick('left')}
                  iconColor={arrowIconColor}
                  color={arrowColor}
                  iconRight={5}
                />
              )}
            </div>
          </PoseFade>
          <PoseFade
            isVisible={
              shouldAlwaysShowArrows
                ? true
                : currentIndex < React.Children.count(children) - 1
            }
          >
            <div className={classes.rightArrow}>
              {iconRight ? (
                <Icon
                  icon={iconRight}
                  onClick={() => handleArrowClick('right')}
                  size={iconSize}
                  color={iconColor ? colors[iconColor] : colors.mainText}
                />
              ) : (
                <RoundIconButton
                  icon={ICONS.CHEVRONRIGHT}
                  onClick={() => handleArrowClick('right')}
                  iconColor={arrowIconColor}
                  color={arrowColor}
                  iconRight={12}
                />
              )}
            </div>
          </PoseFade>
        </>
      )}
    </div>
  )
}

// export _________________________________________________
export default wrappedInjectSheet<React.FC<ComponentProps>>(styles, ViewSlider)
