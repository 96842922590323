import React from 'react'

import { Portal } from 'src/portal/portal'
import { motion } from 'framer-motion'
import { Icon } from '@sendoutcards/quantum-design-ui'
import { useSelector } from 'src/hooks'

type SimpleDrawerProps = {
  isOpen: boolean
  onStateControlClick: () => void
}

const SimpleDrawer: React.FC<SimpleDrawerProps> = props => {
  const { isOpen, onStateControlClick, children } = props
  const isMobile = useSelector(state => state.window.width < 500)

  const variants = {
    closed: {
      x: isMobile ? 'calc(100vw - 50px)' : '451px',
      transition: { type: 'spring', stiffness: 80, damping: 15, mass: 1 },
    },
    open: {
      x: '0px',
      transition: { type: 'spring', stiffness: 80, damping: 12, mass: 1 },
    },
  }

  const styles = {
    wrapper: {
      position: 'fixed' as const,
      top: 0,
      bottom: 0,
      right: 0,
      width: isMobile ? 'calc(100vw - 50px)' : '450px',
      background: '#fafbfc',
      boxShadow: '0px -8px 8px 0px rgba(0, 0, 0, 0.3)',
      zIndex: 100,
    },
    button: {
      position: 'absolute' as const,
      top: '50%',
      transform: 'translateY(-50%)',
      width: '25px',
      height: '80px',
      backgroundColor: 'rgba(15,15,15,0.65)',
      left: '-26px',
      borderRadius: '6px 0 0 6px',
      boxShadow: 'rgba(0, 0, 0, 0.3) 0px 3px 3px 0px',
      backdropFilter: 'blur(2px)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      transition: 'all 0.3s ease 0s',
      ':hover': {
        height: '90px',
      },
      '> svg': {
        transition: 'all 0.3s ease 0s',
        transform: isOpen ? 'rotate(180deg) !important' : undefined,
      },
    },
    innerWrapper: {
      overflow: 'auto',
      height: isMobile ? 'calc(100% - 65px)' : 'calc(100% - 95px)',
    },
  }

  return (
    <Portal>
      <motion.div
        initial={'closed'}
        animate={isOpen ? 'open' : 'closed'}
        variants={variants}
        css={styles.wrapper}
      >
        <div onClick={onStateControlClick} css={styles.button}>
          <Icon
            name="chevron"
            primaryColor="accent"
            orientation="left"
            size={32}
          />
        </div>
        <div css={styles.innerWrapper}>{children}</div>
      </motion.div>
    </Portal>
  )
}

export default SimpleDrawer
