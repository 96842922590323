import {
  authorizeSaleorOIDC,
  getSaleorAccessToken,
  SuccessfulOAuthResponse,
} from 'src/react_query/queries/fetching'
export const SALEOR_ACCESS_TOKEN_KEY = 'saleor_access_token'

export function base64URLEncode(buffer: Uint8Array): string {
  const encoded = btoa(String.fromCharCode.apply(null, Array.from(buffer)))
  return encoded.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
}

export async function sha256(str: string): Promise<Uint8Array> {
  const encoder = new TextEncoder()
  const data = encoder.encode(str)
  const hashBuffer = await crypto.subtle.digest('SHA-256', data)
  return new Uint8Array(hashBuffer)
}

export const generateCodeVerifier = (): string => {
  const randomBytes = new Uint8Array(32)
  crypto.getRandomValues(randomBytes)
  const verifier = base64URLEncode(randomBytes)
  return verifier
}

export const persistSaleorAccessToken = (oauth: SuccessfulOAuthResponse) => {
  const expiresInMs = oauth.expires_in * 1000
  const expiresAt = Date.now() + expiresInMs
  localStorage.setItem(
    SALEOR_ACCESS_TOKEN_KEY,
    JSON.stringify({ ...oauth, expires_at: expiresAt }),
  )
}

// In most scenarios use useSaleorAuth
export const executeSaleorAuth = async () => {
  const redirectUri = window.location.href.split('#')[0]
  const codeVerifier = generateCodeVerifier()
  const codeChallenge = base64URLEncode(await sha256(codeVerifier))
  const code = await authorizeSaleorOIDC({
    codeChallenge,
    redirectUri,
  })
  const data = await getSaleorAccessToken(code, codeVerifier, redirectUri)

  if (data.success) {
    persistSaleorAccessToken(data)
  }
  return data
}

type SaleorAuthentication = {
  access_token: string
  expires_in: number
  token_type: 'Bearer'
  refresh_token: string
  id_token: string
  expires_at: number
}

export const getPersistedSaleorAuth = () => {
  const persistedSaleorAccessToken: string | null = localStorage.getItem(
    SALEOR_ACCESS_TOKEN_KEY,
  )

  const accessToken = persistedSaleorAccessToken
    ? ((JSON.parse(
        persistedSaleorAccessToken,
      ) as unknown) as SaleorAuthentication)
    : null

  return accessToken
}
