import getLocalStorage from 'src/helpers/getLocalStorage'
import { useMemo, useEffect, useSelector, usePersistedUserData } from '.'

type QuerySponsor = {
  genealogyId: string
}

const useQuerySponsor = (): QuerySponsor | undefined => {
  const key = 'sponsor'
  const { account } = usePersistedUserData()
  const sponsorArg = useSelector(state => state.route.args.sponsor)

  // Save to local storage
  useEffect(() => {
    const storage = getLocalStorage()
    if (!storage || sponsorArg === undefined) {
      return
    }

    const newPersisted: QuerySponsor = {
      genealogyId: sponsorArg,
    }

    storage.setItem(key, JSON.stringify(newPersisted))
  })

  const querySponsor = useMemo(() => {
    if (sponsorArg !== undefined) {
      return {
        genealogyId: sponsorArg,
      }
    }
    const storage = getLocalStorage()
    const possibleValue = storage?.getItem(key) ?? undefined
    if (possibleValue !== undefined) {
      return JSON.parse(possibleValue)
    }

    return undefined
  }, [sponsorArg])

  return account ? undefined : querySponsor
}

export default useQuerySponsor
