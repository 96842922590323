import React from 'react'
// @src imports
import { UiCard } from 'src/chrome'

interface SharingInfoCardProps {}

const SharingInformationCard: React.FC<SharingInfoCardProps> = props => {
  const { children } = props
  return (
    <UiCard
      title={'Your Shareable Information'}
      description="This section contains the shareable information from your account all in one place."
    >
      {children}
    </UiCard>
  )
}

export default SharingInformationCard
