import { Theme } from 'src/theme/theme'
import { Interpolation } from '@emotion/css'

const styles = {
  formCard: (theme: Theme): Interpolation => ({
    background: theme.colors.white,
    padding: '63px 15px 25px 15px',
    borderRadius: 10,
    zIndex: -1,
    '@media (max-width: 668px)': {
      marginLeft: 0,
    },
  }),
  flex: {
    display: 'flex',
    justifyContent: 'space-around',
    width: 420,
    h6: {
      lineHeight: '36px',
    },
    '@media (max-width: 668px)': {
      width: 'auto',
    },
  },
  purchaseButtonContainer: {
    position: 'absolute' as const,
    bottom: '-15px',
    width: 'calc(100% + 15px)',
    '@media (max-width: 668px)': {
      left: 0,
      width: '100%',
    },
  },
  purchaseButton: {
    margin: '0 auto',
  },
  editExpenseContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30,
    width: 354,
    '@media (max-width: 668px)': {
      width: 'auto',
    },
  },
  disabled: {
    display: 'flex',
    width: 66,
    justifyContent: 'space-between',
    alignItems: 'end',
  },
}

export default styles
