import { graphqlClient } from 'src/graphql/client'
import {
  AddCheckoutLinesDocument,
  AddCheckoutLinesMutationVariables,
  AddCheckoutPromoCodeDocument,
  AddCheckoutPromoCodeMutationVariables,
  AttachCustomerDocument,
  AttachCustomerMutationVariables,
  CampaignStoreSubcategoriesDocument,
  CampaignStoreSubcategoriesQueryVariables,
  CampaignStoreSubcategoryDocument,
  CampaignStoreSubcategoryQueryVariables,
  CheckoutDocument,
  CheckoutQueryVariables,
  CompleteCheckoutDocument,
  CompleteCheckoutMutationVariables,
  CreateCheckoutDocument,
  CreateCheckoutMutationVariables,
  CreateCheckoutPaymentDocument,
  CreateCheckoutPaymentMutationVariables,
  DeleteCheckoutLinesDocument,
  DeleteCheckoutLinesMutationVariables,
  GetSaleorCustomerDocument,
  InitializeTransactionDocument,
  InitializeTransactionMutationVariables,
  ProductVariantDocument,
  ProductVariantQueryVariables,
  ProductVariantsDocument,
  ProductVariantsQueryVariables,
  RemoveCheckoutPromoCodeDocument,
  RemoveCheckoutPromoCodeMutationVariables,
  SaleorOrderDocument,
  SaleorOrderQueryVariables,
  SelectedAttribute,
  UpdateCheckoutBillingAddressDocument,
  UpdateCheckoutBillingAddressMutationVariables,
  UpdateCheckoutEmailDocument,
  UpdateCheckoutEmailMutationVariables,
  UpdateCheckoutLineQuantityDocument,
  UpdateCheckoutLineQuantityMutationVariables,
  UpdateSaleorCustomerDocument,
  UpdateSaleorCustomerMutationVariables,
} from 'src/saleor_graphql/generated/graphql'
import {
  GetAccountDocument,
  GetAccountCreatedContentsDocument,
  GetAffiliateCountriesDocument,
  GetAlertsDocument,
  GetAlertsQueryVariables,
  GetByDesignLoginLinkDocument,
  GetCampaignDocument,
  GetCampaignOrdersDocument,
  GetCampaignOrdersQueryVariables,
  GetCampaignQueryVariables,
  GetCardDocument,
  GetCardQueryVariables,
  GetCoachingBadgesDocument,
  GetCoachingBadgesQueryVariables,
  GetCoachingDashboardMarketingContentDocument,
  GetContactRequestByTokenDocument,
  GetContactRequestByTokenQueryVariables,
  GetContactRequestsDocument,
  GetCreditsBalanceDocument,
  GetDetailedCountriesDocument,
  GetDraftedCardsDocument,
  GetDraftedCardsQueryVariables,
  GetFlagsDocument,
  GetFontsDocument,
  GetImagesDocument,
  GetImagesQueryVariables,
  GetItemCategoriesDocument,
  GetItemDocument,
  GetItemQueryVariables,
  GetItemsDocument,
  GetItemsQueryVariables,
  GetLayoutDocument,
  GetLayoutQueryVariables,
  GetLayoutsDocument,
  GetLayoutsQueryVariables,
  GetLeaderboardMarketingContentDocument,
  GetLeaderBoardsDocument,
  GetMarketingBannersDocument,
  GetMarketingContentDocument,
  GetNotificationCountDocument,
  GetNotificationsDocument,
  GetNotificationsQueryVariables,
  GetOrderDocument,
  GetOrderQueryVariables,
  GetPaginatedInvitesDocument,
  GetPaginatedInvitesQueryVariables,
  GetPaginatedRecipientsDocument,
  GetPaginatedRecipientsQueryVariables,
  GetPlanMoveDocument,
  GetPlanMoveQueryVariables,
  GetPlansDocument,
  GetPromptingsCoachDiscountsDocument,
  GetPromptingsEventDatesDocument,
  GetReceivedPendingMembershipInvitesDocument,
  GetRemindersDocument,
  GetRemindersQueryVariables,
  GetSendableCardDocument,
  GetSendableCardQueryVariables,
  GetSentCardsCountDocument,
  GetSentCardsCountQueryVariables,
  GetSentCardsDocument,
  GetSentCardsQueryVariables,
  GetSharedCampaignsDocument,
  GetSharedCampaignsQueryVariables,
  GetSharedContactsDocument,
  GetSharedContactsQueryVariables,
  GetSponsorQueryVariables,
  GetStickersDocument,
  GetStickersQueryVariables,
  GetUsersQueryVariables,
} from 'src/graphql/generated/graphql'
import * as graphql from 'src/graphql/generated/graphql'
import { LEGACY_SITE_URL } from 'src/app/api'
import { Store } from 'src/redux/store'
import { getPersistedSaleorAuth } from 'src/saleor/utils/auth'
import { saleorGraphQLClient } from 'src/saleor_graphql/client'
import { extractAttributesBySlug } from '../utils'

export type SuccessfulOAuthResponse = {
  success: true
  access_token: string
  expires_in: number
  token_type: 'Bearer'
  refresh_token: string
  id_token: string
  error?: never
}
export type FailedOauthResponse = {
  success: false
  access_token?: never
  expires_in?: never
  token_type?: never
  id_token?: never
  error: string
}

export const getAccount = async () => {
  return (await graphqlClient.request(GetAccountDocument)).account
}

export const getAddPaymentSettingsDailyCap = async () => {
  return (
    await graphqlClient.request(graphql.GetAddPaymentSettingsDailyCapDocument)
  ).account
}

export const getAffiliateCountries = async () => {
  return await graphqlClient.request(GetAffiliateCountriesDocument)
}

export const getAlerts = async (variables: GetAlertsQueryVariables) =>
  await graphqlClient.request(GetAlertsDocument, variables)

export const getAvailableDailyHeartfelt = async () =>
  (await graphqlClient.request(graphql.GetAvailableDailyHeartfeltDocument))
    .account

export const getAvailableUnlimitedDailyHeartfelt = async () =>
  (
    await graphqlClient.request(
      graphql.GetAvailableUnlimitedDailyHeartfeltDocument,
    )
  ).account

export const getByDesignLoginLink = async () => {
  return (await graphqlClient.request(GetByDesignLoginLinkDocument))
    .bydesignLoginLink
}

export const getCard = async (variables: GetCardQueryVariables) =>
  await graphqlClient.request(GetCardDocument, variables)

export const getDetailedCountries = async () => {
  return (await graphqlClient.request(GetDetailedCountriesDocument))
    .detailedCountries
}

export const getSendableCard = async (
  variables: GetSendableCardQueryVariables,
) => await graphqlClient.request(GetSendableCardDocument, variables)

export const getSentCardsCount = async (
  variables: GetSentCardsCountQueryVariables,
) => await graphqlClient.request(GetSentCardsCountDocument, variables)

export const getDraftedCards = async (
  variables: GetDraftedCardsQueryVariables,
) =>
  await (await graphqlClient.request(GetDraftedCardsDocument, variables)).drafts

export const getItemCategories = async () =>
  (await graphqlClient.request(GetItemCategoriesDocument)).itemCategories

export const getItem = async (variables: GetItemQueryVariables) =>
  await graphqlClient.request(GetItemDocument, variables)

export const getItems = async (variables: GetItemsQueryVariables) =>
  await graphqlClient.request(GetItemsDocument, variables)

export const getLeaderBoards = async () =>
  await (await graphqlClient.request(GetLeaderBoardsDocument)).leaderBoards

export const getCampaign = async (variables: GetCampaignQueryVariables) =>
  (await graphqlClient.request(GetCampaignDocument, variables)).campaign

export const getSentCards = async (variables: GetSentCardsQueryVariables) =>
  await (await graphqlClient.request(GetSentCardsDocument, variables))
    .paginatedSentCards

export const getCampaignOrders = async (
  variables: GetCampaignOrdersQueryVariables,
) =>
  (await graphqlClient.request(GetCampaignOrdersDocument, variables)).campaign
    .orders

export const getPaginatedInvites = async (
  variables: GetPaginatedInvitesQueryVariables,
) => {
  return (await graphqlClient.request(GetPaginatedInvitesDocument, variables))
    .paginatedInvites
}

export const getFlags = async () =>
  await graphqlClient.request(GetFlagsDocument)

export const getMarketingContent = async () =>
  await graphqlClient.request(GetMarketingContentDocument)

export const getAffiliateJoinMarketingContent = async () =>
  await graphqlClient.request(graphql.GetAffiliateJoinMarketingContentDocument)

export const getLeaderBoardMarketingContent = async () =>
  await graphqlClient.request(GetLeaderboardMarketingContentDocument)

export const getCoachingDashboardMarketingContent = async () =>
  await graphqlClient.request(GetCoachingDashboardMarketingContentDocument)

export const getMarketingBanners = async () =>
  await (await graphqlClient.request(GetMarketingBannersDocument)).banners

export const getGratitudeDayMarketingContent = async () =>
  await graphqlClient.request(graphql.GetGratitudeDayMarketingContentDocument)

export const getCreditCardManagerMarketingContent = async () =>
  await graphqlClient.request(
    graphql.GetCreditCardManagerMarketingContentDocument,
  )

export const getContactRequesMarketingContent = async () =>
  await graphqlClient.request(graphql.GetContactRequestMarketingContentDocument)

export const getSubmitRequestedContactMarketingContent = async () =>
  await graphqlClient.request(
    graphql.GetSubmitRequestedContactMarketingContentDocument,
  )

export const getProductionInfo = async (
  variables: graphql.GetProductionInfoQueryVariables,
) => await graphqlClient.request(graphql.GetProductionInfoDocument, variables)

export const getPaginatedRecipients = async (
  variables: GetPaginatedRecipientsQueryVariables,
) => await graphqlClient.request(GetPaginatedRecipientsDocument, variables)

export const getLayouts = async (variables: GetLayoutsQueryVariables) =>
  await graphqlClient.request(GetLayoutsDocument, variables)

export const getLayout = async (variables: GetLayoutQueryVariables) =>
  await graphqlClient.request(GetLayoutDocument, variables)

export const getImages = async (variables: GetImagesQueryVariables) =>
  await graphqlClient.request(GetImagesDocument, variables)

export const getCreditsBalance = async () =>
  await graphqlClient.request(GetCreditsBalanceDocument)

export const getOrder = async (variables: GetOrderQueryVariables) =>
  await graphqlClient.request(GetOrderDocument, variables)

export const getFonts = async () =>
  await graphqlClient.request(GetFontsDocument)

export const getCoachingBadges = async (
  variables: GetCoachingBadgesQueryVariables,
) => {
  return await graphqlClient.request(GetCoachingBadgesDocument, variables)
}

export const getPlans = async () =>
  await (await graphqlClient.request(GetPlansDocument)).plans

export const getPlanMove = async (variables: GetPlanMoveQueryVariables) => {
  return await graphqlClient.request(GetPlanMoveDocument, variables)
}

export const getNotifications = async (
  variables: GetNotificationsQueryVariables,
) => {
  return await graphqlClient.request(GetNotificationsDocument, variables)
}

export const getNotificationCount = async () =>
  (await graphqlClient.request(GetNotificationCountDocument)).notificationCount

export const getReceivedPendingMembershipInvites = async () => {
  return await (
    await graphqlClient.request(GetReceivedPendingMembershipInvitesDocument)
  ).receivedPendingMembershipInvites
}

export const getReminders = async (variables: GetRemindersQueryVariables) => {
  return await graphqlClient.request(GetRemindersDocument, variables)
}

export const getSharedCampaigns = async (
  variables: GetSharedCampaignsQueryVariables,
) => {
  return await graphqlClient.request(GetSharedCampaignsDocument, variables)
}

export const getSharedContacts = async (
  variables: GetSharedContactsQueryVariables,
) => {
  return await graphqlClient.request(GetSharedContactsDocument, variables)
}

export const getContactRequests = async () => {
  return await graphqlClient.request(GetContactRequestsDocument)
}

export const getContactRequestByToken = async (
  variables: GetContactRequestByTokenQueryVariables,
) => {
  return (
    await graphqlClient.request(GetContactRequestByTokenDocument, variables)
  ).contactRequestByToken
}

export const getPromptingsCoachDiscounts = async () => {
  return await (
    await graphqlClient.request(GetPromptingsCoachDiscountsDocument)
  ).account.promptingsCoach?.discounts
}

export const getPromptingsEventDates = async () => {
  return await await graphqlClient.request(GetPromptingsEventDatesDocument)
}

export const getAccounCreatedContents = async () =>
  await graphqlClient.request(GetAccountCreatedContentsDocument)

export const getSponsor = async (variables: GetSponsorQueryVariables) =>
  (await graphqlClient.request(graphql.GetSponsorDocument, variables)).sponsor

export const getStickers = async (variables: GetStickersQueryVariables) =>
  (await graphqlClient.request(GetStickersDocument, variables)).stickers

export const getUsers = async (variables: GetUsersQueryVariables) =>
  (await graphqlClient.request(graphql.GetUsersDocument, variables)).users

export const getUserCardTokens = async () =>
  (await graphqlClient.request(graphql.GetUserCardTokensDocument)).account

export const authorizeSaleorOIDC = async ({
  codeChallenge,
  redirectUri,
}: {
  codeChallenge: string
  redirectUri: string
}): Promise<string> => {
  const token = Store.getState().user.controlData.token
  const headers = {
    Authorization: `Token ${token}`,
  }

  const url = `${LEGACY_SITE_URL}/soc_oauth2/authorize_frontend/?response_type=code&code_challenge=${codeChallenge}&code_challenge_method=S256&client_id=${
    process.env.REACT_APP_SALEOR_OIDC_CLIENT_ID
  }&redirect_uri=${encodeURIComponent(redirectUri)}`
  const promise = fetch(url, { headers })
  const response = await promise
  if (!response.ok) {
    throw new Error(
      `Failed to authorize: (${response.status}) ${response.body}`,
    )
  }

  const json = await response.json()
  return json.code
}

export const getSaleorAccessToken = async (
  authorizationCode: string,
  codeVerifier: string,
  redirectUri: string,
) => {
  const formData = new URLSearchParams()
  formData.append('client_id', `${process.env.REACT_APP_SALEOR_OIDC_CLIENT_ID}`)
  formData.append('code', authorizationCode)
  formData.append('code_verifier', codeVerifier)
  formData.append('redirect_uri', redirectUri)
  formData.append('grant_type', 'authorization_code')

  const url = `${LEGACY_SITE_URL}/oauth2/token/`

  try {
    const tokenResponse = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formData,
    })

    if (tokenResponse.status === 200) {
      const json = await tokenResponse.json()

      return ({
        success: true,
        ...json,
      } as unknown) as SuccessfulOAuthResponse
    }
    const json = await tokenResponse.json()

    return ({ success: false, ...json } as unknown) as FailedOauthResponse
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getSaleorRefreshToken = async (refreshToken: string) => {
  const token = Store.getState().user.controlData.token
  const formData = new URLSearchParams()
  formData.append('client_id', `${process.env.REACT_APP_SALEOR_OIDC_CLIENT_ID}`)
  formData.append('refresh_token', refreshToken)
  formData.append('grant_type', 'refresh_token')

  const url = `${LEGACY_SITE_URL}/oauth2/token/`

  try {
    const tokenResponse = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Token ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formData,
    })

    if (tokenResponse.status === 200) {
      const json = await tokenResponse.json()

      return ({
        success: true,
        ...json,
      } as unknown) as SuccessfulOAuthResponse
    }
    const json = await tokenResponse.json()

    return ({ success: false, ...json } as unknown) as FailedOauthResponse
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getCheckout = async (variables: CheckoutQueryVariables) => {
  const saleorAuth = getPersistedSaleorAuth()
  const headers: { Authorization: string } | {} = saleorAuth?.access_token
    ? { Authorization: `Bearer ${saleorAuth.access_token}` }
    : {}
  const res = await saleorGraphQLClient.request(
    CheckoutDocument,
    variables,
    headers,
  )
  return res.checkout
}

export const createCheckout = async (
  variables: CreateCheckoutMutationVariables,
) => {
  const saleorAuth = getPersistedSaleorAuth()
  const headers: { Authorization: string } | {} = saleorAuth?.access_token
    ? { Authorization: `Bearer ${saleorAuth.access_token}` }
    : {}
  const res = await saleorGraphQLClient.request(
    CreateCheckoutDocument,
    variables,
    headers,
  )
  return res.checkoutCreate
}

export const updateCheckoutBillingAddress = async (
  variables: UpdateCheckoutBillingAddressMutationVariables,
) => {
  const saleorAuth = getPersistedSaleorAuth()
  const headers: { Authorization: string } | {} = saleorAuth?.access_token
    ? { Authorization: `Bearer ${saleorAuth.access_token}` }
    : {}
  const res = await saleorGraphQLClient.request(
    UpdateCheckoutBillingAddressDocument,
    variables,
    headers,
  )
  return res.checkoutBillingAddressUpdate
}

export const addCheckoutLines = async (
  variables: AddCheckoutLinesMutationVariables,
) => {
  const saleorAuth = getPersistedSaleorAuth()
  const headers: { Authorization: string } | {} = saleorAuth?.access_token
    ? { Authorization: `Bearer ${saleorAuth.access_token}` }
    : {}
  const res = await saleorGraphQLClient.request(
    AddCheckoutLinesDocument,
    variables,
    headers,
  )

  return res.checkoutLinesAdd
}

export const addCheckoutPromoCode = async (
  variables: AddCheckoutPromoCodeMutationVariables,
) => {
  const saleorAuth = getPersistedSaleorAuth()
  const headers: { Authorization: string } | {} = saleorAuth?.access_token
    ? { Authorization: `Bearer ${saleorAuth.access_token}` }
    : {}
  const res = await saleorGraphQLClient.request(
    AddCheckoutPromoCodeDocument,
    variables,
    headers,
  )

  return res.checkoutAddPromoCode
}

export const removeCheckoutPromoCode = async (
  variables: RemoveCheckoutPromoCodeMutationVariables,
) => {
  const saleorAuth = getPersistedSaleorAuth()
  const headers: { Authorization: string } | {} = saleorAuth?.access_token
    ? { Authorization: `Bearer ${saleorAuth.access_token}` }
    : {}
  const res = await saleorGraphQLClient.request(
    RemoveCheckoutPromoCodeDocument,
    variables,
    headers,
  )

  return res.checkoutRemovePromoCode
}

export const getSaleorCustomer = async () => {
  const saleorAuth = getPersistedSaleorAuth()
  const headers: { Authorization: string } | {} = saleorAuth?.access_token
    ? { Authorization: `Bearer ${saleorAuth.access_token}` }
    : {}
  const res = await saleorGraphQLClient.request(
    GetSaleorCustomerDocument,
    undefined,
    headers,
  )

  return res.me
}

export const updateSaleorCustomer = async (
  variables: UpdateSaleorCustomerMutationVariables,
) => {
  const saleorAuth = getPersistedSaleorAuth()
  const headers: { Authorization: string } | {} = saleorAuth?.access_token
    ? { Authorization: `Bearer ${saleorAuth.access_token}` }
    : {}
  const res = await saleorGraphQLClient.request(
    UpdateSaleorCustomerDocument,
    variables,
    headers,
  )
  return res.customerUpdate
}

export const deleteCheckoutLines = async (
  variables: DeleteCheckoutLinesMutationVariables,
) => {
  const saleorAuth = getPersistedSaleorAuth()
  const headers: { Authorization: string } | {} = saleorAuth?.access_token
    ? { Authorization: `Bearer ${saleorAuth.access_token}` }
    : {}
  const res = await saleorGraphQLClient.request(
    DeleteCheckoutLinesDocument,
    variables,
    headers,
  )
  return res.checkoutLinesDelete
}

export const getProductVariant = async (
  variables: ProductVariantQueryVariables,
) => {
  const saleorAuth = getPersistedSaleorAuth()
  const headers: { Authorization: string } | {} = saleorAuth?.access_token
    ? { Authorization: `Bearer ${saleorAuth.access_token}` }
    : {}
  const res = await saleorGraphQLClient.request(
    ProductVariantDocument,
    variables,
    headers,
  )
  const premiumSubscriptionIds = res.productVariant?.attributes
    .find(attr => attr.attribute.slug === 'premium-subscription-products')
    ?.values.flatMap(val => val.reference)

  const [description] =
    res.productVariant?.attributes
      .find(attr => attr.attribute.slug === 'variant-description')
      ?.values.flatMap(value => value.name) ?? []

  const affiliateTrial = res.productVariant?.attributes
    ? extractAttributesBySlug(
        'affiliate-trial',
        res.productVariant?.attributes as SelectedAttribute[],
      ).filter((value): value is string => Boolean(value))
    : []

  const parsedAttributes = {
    premiumSubscriptionIds,
    description,
    affiliateTrial,
  }

  return {
    ...res.productVariant,
    parsedAttributes,
  }
}

export const createTransaction = async (
  variables: InitializeTransactionMutationVariables,
) => {
  const saleorAuth = getPersistedSaleorAuth()
  const headers: { Authorization: string } | {} = saleorAuth?.access_token
    ? { Authorization: `Bearer ${saleorAuth.access_token}` }
    : {}
  const res = await saleorGraphQLClient.request(
    InitializeTransactionDocument,
    variables,
    headers,
  )

  return res.transactionInitialize
}

export const createCheckoutPayment = async (
  variables: CreateCheckoutPaymentMutationVariables,
) => {
  const saleorAuth = getPersistedSaleorAuth()
  const headers: { Authorization: string } | {} = saleorAuth?.access_token
    ? { Authorization: `Bearer ${saleorAuth.access_token}` }
    : {}
  const res = await saleorGraphQLClient.request(
    CreateCheckoutPaymentDocument,
    variables,
    headers,
  )
  return res.checkoutPaymentCreate
}

export const completeCheckout = async (
  variables: CompleteCheckoutMutationVariables,
) => {
  const saleorAuth = getPersistedSaleorAuth()
  const headers: { Authorization: string } | {} = saleorAuth?.access_token
    ? { Authorization: `Bearer ${saleorAuth.access_token}` }
    : {}
  const res = await saleorGraphQLClient.request(
    CompleteCheckoutDocument,
    variables,
    headers,
  )
  return res.checkoutComplete
}

export const getSaleorOrder = async (variables: SaleorOrderQueryVariables) => {
  const saleorAuth = getPersistedSaleorAuth()
  const headers: { Authorization: string } | {} = saleorAuth?.access_token
    ? { Authorization: `Bearer ${saleorAuth.access_token}` }
    : {}
  const res = await saleorGraphQLClient.request(
    SaleorOrderDocument,
    variables,
    headers,
  )
  return res.order
}

export const getProductVariants = async (
  variables: ProductVariantsQueryVariables,
) => {
  const res = await saleorGraphQLClient.request(
    ProductVariantsDocument,
    variables,
  )
  return res.productVariants
}

export const updateCheckoutEmail = async (
  variables: UpdateCheckoutEmailMutationVariables,
) => {
  const saleorAuth = getPersistedSaleorAuth()
  const headers: { Authorization: string } | {} = saleorAuth?.access_token
    ? { Authorization: `Bearer ${saleorAuth.access_token}` }
    : {}
  const res = await saleorGraphQLClient.request(
    UpdateCheckoutEmailDocument,
    variables,
    headers,
  )
  return res.checkoutEmailUpdate
}

export const attachCustomerToCheckout = async (
  variables: AttachCustomerMutationVariables,
  token?: string,
) => {
  const saleorAuth = getPersistedSaleorAuth()
  const attachedHeaders: { Authorization: string } | {} = token
    ? { Authorization: `Bearer ${token}` }
    : saleorAuth?.access_token
    ? { Authorization: `Bearer ${saleorAuth.access_token}` }
    : {}

  const res = await saleorGraphQLClient.request(
    AttachCustomerDocument,
    variables,
    attachedHeaders,
  )
  return res.checkoutCustomerAttach
}

export const getCampaignStoreSubcategories = async (
  variables: CampaignStoreSubcategoriesQueryVariables,
) => {
  const res = await saleorGraphQLClient.request(
    CampaignStoreSubcategoriesDocument,
    variables,
  )
  return res.campaignStore
}

export const getCampaignStoreSubcategory = async (
  variables: CampaignStoreSubcategoryQueryVariables,
) => {
  const res = await saleorGraphQLClient.request(
    CampaignStoreSubcategoryDocument,
    variables,
  )

  return res.products
}

export const updateCheckoutLineQuantity = async (
  variables: UpdateCheckoutLineQuantityMutationVariables,
) => {
  const saleorAuth = getPersistedSaleorAuth()
  const headers: { Authorization: string } | {} = saleorAuth?.access_token
    ? { Authorization: `Bearer ${saleorAuth.access_token}` }
    : {}
  const res = await saleorGraphQLClient.request(
    UpdateCheckoutLineQuantityDocument,
    variables,
    headers,
  )
  return res.checkoutLinesUpdate
}
