import React from 'react'
import {
  Div,
  Flex,
  Icon,
  Select,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'

import { useSelector } from 'src/hooks'
import {
  getCardType,
  getPaperOptions,
  getSendDelayText,
  onPaperTypeSelected,
} from 'src/orders/helpers'
import { CardLineItemCallbacksType, CardLineItemProps } from '../CardLineItem'
import CardItemOption from 'src/chrome/CardItemOption/CardItemOption'
import Price from 'src/chrome/Price/Price'
import CardPreview from 'src/chrome/CardPreview/CardPreview'
import { Icon as DSIcon } from 'src/design_system/atoms/icons/components/Icon'
import { ImageErrorDisclaimer } from './ImageErrorDisclaimer'
import { CardSendType } from 'src/graphql/generated/graphql'
import { usePlans } from 'src/react_query'
import { basicPlanId } from 'src/helpers/supportedUpsaleProducts'
import { formatCost } from 'src/helpers'

type MobileCardLineItemProps = CardLineItemProps & CardLineItemCallbacksType

const MobileCardLineItem: React.FC<MobileCardLineItemProps> = props => {
  const {
    card,
    sendDelay,
    cost,
    onEditCard,
    order,
    onUpdatePaperType,
    onToggleSendDelay,
    lineIndex,
    quantity,
    onReplaceCard,
    didFlattenFail,
  } = props

  const { data: plans } = usePlans()
  const { width } = useSelector(state => state.window)
  const isMobile = width <= 542
  const isSmallMobile = width <= 426
  const smallMobileBodyText = isMobile ? 'footnote' : 'caption'

  const previewUrls = card.panels.map(panel => {
    return panel.previewUrl
  })

  const selectedPaperTypeOpt = card.paperUpgradeCosts
    .filter(paper => paper.value !== 'STA')
    .findIndex(paper => paper.value === card.paperType)

  const onView = () => {
    onEditCard?.(card)
  }

  const handleReplaceCard = () => {
    onReplaceCard?.(card.id)
  }

  const handleUpdatePaper = (optionsIndex: number) => {
    onPaperTypeSelected(card, optionsIndex, onUpdatePaperType)
  }

  const getSendType =
    order?.sendType === CardSendType.Heartfelt
      ? 'Heartfelt Prompting Card'
      : 'System Card'

  const basicCardCost = plans?.find(value => value.id === basicPlanId.id)
    ?.cardCost
  const formattedBasicCardCost = basicCardCost
    ? formatCost(basicCardCost * 100) // basicCardCost converted to cents
    : undefined

  return (
    <Flex width="100%" flexDirection="column">
      {quantity > 1 && (
        <Div
          position="absolute"
          zIndex={1}
          borderRadius="circle"
          width="fit-content"
          height="fit-content"
          backgroundColor="black"
          left={card.isHorizontal ? '208px' : '158px'}
          inset={{ vertical: 'x_25', horizontal: 'x_5' }}
        >
          <Text
            type="footnote"
            content={`x${quantity}`}
            color="inverseHeading"
            alignment="center"
          />
        </Div>
      )}
      <Div display="flex">
        <Flex justifyContent="flex-start">
          <CardPreview
            panelUrls={previewUrls}
            isHorizontal={card.isHorizontal}
            onView={onView}
            didFlattenFail={didFlattenFail}
          />
        </Flex>
        <Spacer space="x2" orientation="horizontal" />
        <Flex width="100%" flexWrap="wrap">
          <Flex flexDirection="column" width="100%">
            <Flex alignItems="center" justifyContent="space-between">
              <Text
                type={isMobile ? 'body' : 'largeBody'}
                weight="bold"
                color="primaryHeading"
                content={card.detailedSendableCard?.title}
                inset={{ left: 'x_5' }}
                lineHeight={1.5}
              />
              <Div
                cursor="pointer"
                display="flex"
                justifyContent="center"
                alignItems="center"
                width={'32px'}
                height="32px"
                borderColor={'#c2c2c2'}
                borderWidth="default"
                borderStyle="solid"
                borderRadius="small"
                inset={width <= 406 ? { horizontal: 'x2' } : 'x0'}
                onClick={handleReplaceCard}
              >
                <Div
                  id="replace_card_icon"
                  display="flex"
                  height="fit-content"
                  inset={{ left: 'x_5' }}
                >
                  <DSIcon name="replace" size={26} color={'#c2c2c2'} />
                </Div>
              </Div>
            </Flex>
            {!isSmallMobile && (
              <>
                <Flex inset={{ bottom: 'x1' }} flexWrap={'nowrap'}>
                  <Div
                    borderRadius="default"
                    inset={{ horizontal: 'x_75' }}
                    backgroundColor="background"
                  >
                    <Text
                      color="primaryHeading"
                      weight="semiBold"
                      type={smallMobileBodyText}
                      content={'Type:'}
                    />
                  </Div>
                  <Spacer space="x_5" orientation="horizontal" />
                  <Text
                    type={smallMobileBodyText}
                    isItalic={true}
                    content={getCardType(card)}
                    color="primaryHeading"
                  />
                </Flex>
                <Flex flexWrap={width <= 595 ? 'wrap' : 'nowrap'}>
                  <Div
                    borderRadius="default"
                    inset={{ horizontal: 'x_75' }}
                    backgroundColor="background"
                    maxHeight="28px"
                  >
                    <Text
                      color="primaryHeading"
                      weight="semiBold"
                      type={smallMobileBodyText}
                      content={'Send Type:'}
                      whiteSpace="nowrap"
                    />
                  </Div>
                  <Text
                    type={smallMobileBodyText}
                    isItalic={true}
                    content={getSendType}
                    color="primaryHeading"
                    whiteSpace="normal"
                    inset={{ left: 'x_5' }}
                  />
                </Flex>
              </>
            )}
            <Flex flexDirection="column">
              <Price
                inset={{ top: isSmallMobile ? 'x1' : 'x0', left: 'x_5' }}
                direction="row"
                price={cost.price}
                discountPrice={cost.discountPrice}
                discountMessage={cost.discountMessage}
              />
              {formattedBasicCardCost === cost.price && (
                <Text
                  outset={{ top: '-8px', left: '4px' }}
                  weight="bold"
                  style={{ fontSize: '10px' }}
                  type={'footnote'}
                  content="US Stamp Included"
                />
              )}
            </Flex>
          </Flex>
        </Flex>
      </Div>
      {didFlattenFail && (
        <>
          <Spacer space="x1" orientation="vertical" />
          <Div>
            <Div
              backgroundColor="#FFF5F4"
              display="inline-flex"
              justifyContent="center"
              alignItems="center"
              inset={{
                horizontal: 'x2',
                vertical: isSmallMobile ? 'x2' : 'x_5',
              }}
              outset={{ top: 'x1' }}
              borderRadius="large"
              flexDirection={isSmallMobile ? 'column' : 'row'}
            >
              <ImageErrorDisclaimer />
            </Div>
          </Div>
        </>
      )}
      <Spacer space="x2_5" orientation="vertical" />
      {isSmallMobile && (
        <>
          <Flex
            inset={{ vertical: 'x1_5', left: 'x2' }}
            borderRadius="default"
            backgroundColor="background"
          >
            <Text
              color="primaryHeading"
              weight="semiBold"
              type={smallMobileBodyText}
              content={'Type:'}
            />
            <Text
              type={smallMobileBodyText}
              isItalic={true}
              content={getCardType(card)}
              color="primaryHeading"
              inset={{ left: 'x_5' }}
            />
          </Flex>
          <Spacer space="x1" orientation="vertical" />
          <Flex
            borderRadius="default"
            inset={{ vertical: 'x1_5', left: 'x2' }}
            backgroundColor="background"
          >
            <Text
              color="primaryHeading"
              weight="semiBold"
              type={smallMobileBodyText}
              content={'Send Type:'}
              whiteSpace="nowrap"
            />
            <Text
              type={smallMobileBodyText}
              isItalic={true}
              content={getSendType}
              color="primaryHeading"
              whiteSpace="normal"
              inset={{ left: 'x_5' }}
            />
          </Flex>
          <Spacer space="x1" orientation="vertical" />
        </>
      )}
      <Select
        alignment="left"
        frontLabel="Paper:"
        onChange={handleUpdatePaper}
        type="text"
        options={getPaperOptions(card)}
        selectedOptionIndex={
          selectedPaperTypeOpt !== -1 ? selectedPaperTypeOpt : undefined
        }
      />
      <Spacer space="x1" orientation="vertical" />
      <CardItemOption
        onClick={onToggleSendDelay}
        id={`send_delay_${lineIndex}`}
        isDisabled={order?.isBulk}
        disabledMessage={
          'Scheduling not available: Bulk orders are sent immediately.'
        }
      >
        <Text type="caption" content="Schedule:" />
        <Spacer space="x_5" orientation="horizontal" />
        <Text
          type="caption"
          content={getSendDelayText(sendDelay)}
          weight="bold"
          lineHeight={1.2}
        />
        <Spacer space="x_5" orientation="horizontal" />
        <Div minWidth="20px">
          <Icon name="calendar" size="xSmall" primaryColor="primaryBodyText" />
        </Div>
      </CardItemOption>
      <Spacer space="x1" orientation="vertical" />
    </Flex>
  )
}

export default MobileCardLineItem
